import Switch, { SwitchProps } from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import './styles-switch.css'

const cssBaseClass = 'styled-switch'

export interface StyledSwitchProps extends SwitchProps {
    label?: string
    label2?: string
    isTitle?: boolean
    helperText?: string
}

export function StyledSwitch({ label, label2, helperText, isTitle = false, ...props }: StyledSwitchProps) {
    return (
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
            {label && (
                <Typography variant="body2" fontWeight={label2 != null && !props.checked ? 'bold' : 500}>
                    {label}
                </Typography>
            )}
            <Switch
                classes={{
                    thumb: `${cssBaseClass}-thumb`,
                    checked: `${cssBaseClass}-checked`,
                }}
                {...props}
            />
            {label2 && <Typography variant="body2" fontWeight={props.checked ? 'bold' : 500}>{label2}</Typography>}
            {helperText && <Typography variant="subtitle1" >{helperText}</Typography>}
        </Grid>
    )
}
