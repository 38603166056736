//* ======= Libraries
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { flushSync } from 'react-dom'
import Moveable, { MoveableManagerInterface, OnDrag, OnResize, OnRotate, Renderer } from 'react-moveable'
import { Box, useTheme, alpha, lighten, Skeleton, Tooltip, IconButton, ClickAwayListener } from '@mui/material'
//* ======= Components and features
import WidgetContent from 'features/report-designer/widgets/WidgetContent'
//* ======= Custom logic
import useReportStore, { useReportActiveSlide } from 'features/report-designer/store/reportDesignerStore'
import { ReportSlideType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import {
    getElementRelativePositionPercentage,
    getElementRelativeDimensionsPercentage,
    parseWidgetPosition,
    shouldCaptureKeydown,
} from 'features/report-designer/helpers/reportDesigner.helper'
//* ======= Assets and styles
import './WidgetContainer.css'
import CloseIcon from '@mui/icons-material/Close'
import usePreviousValue from 'hooks/usePreviousValue'
import { e } from 'mathjs'
import { debounce } from 'lodash'

/* =========================================
 * Helper functions
 */

export const scaleUpDimensions = (width: number, height: number, scaleFactor: number) => {
    return {
        width: Number((width * scaleFactor).toFixed(1)),
        height: Number((height * scaleFactor).toFixed(1)),
    }
}

export const retrieveBaseDimensions = (scaledWidth: number, scaledHeight: number, scaleFactor: number) => {
    return {
        width: Number((scaledWidth / scaleFactor).toFixed(1)),
        height: Number((scaledHeight / scaleFactor).toFixed(1)),
    }
}

export const getAbsolutePosition = (
    left: string | number,
    top: string | number,
    slideWidth: number,
    slideHeight: number
) => {
    return {
        left: (parseWidgetPosition(left) * slideWidth) / 100,
        top: (parseWidgetPosition(top) * slideHeight) / 100,
    }
}

export type WidgetContainerSlideDataType = {
    dimensions: ReportSlideType['dimensions']
    widgetElementsDict: Record<ReportWidgetType['id'], HTMLElement>
}

type AvailableZIndicesType = { baseLevel: number; maxLevel: number } & Record<`subLevel${string}`, number>

const CLOSE_BUTTON_POSITION: Record<
    ReportWidgetType['conditionalVisibility']['placement'],
    { top: string | number; right?: string | number; left?: string | number }
> = {
    'top-right-inside': { top: 1, right: 1 },
    'top-left-inside': { top: 1, left: 1 },
    'top-right-outside': { top: -15, right: -15 },
    'top-left-outside': { top: -15, left: -15 },
}

type Props = {
    widget: ReportWidgetType
    slideData: WidgetContainerSlideDataType
    exposeElement: (id: ReportWidgetType['id'], element: HTMLElement) => void
    onContextMenu: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        widget: ReportWidgetType,
        positionIndex: number
    ) => void
    // This is the position of the widget in its slide's list and is used for
    // defining z-index property.
    positionIndex: number
    opacity?: number
    conditionalVisible?: boolean
    updateFocusMode: (widgetId: ReportWidgetType['id'] | null) => void
}

function WidgetContainer({
    widget,
    slideData,
    exposeElement,
    onContextMenu,
    positionIndex,
    conditionalVisible,
    opacity = 1,
    updateFocusMode,
}: Props) {
    const {
        viewMode,
        activeWidgetId,
        secondarySelectedWidgetIds,
        activeSlideId,
        setActiveWidgetId,
        updateSecondarySelectedWidget,
        updateWidget,
        updateWidgetStyles,
        onWidgetReady,
        updateSlideVariable,
    } = useReportStore((store) => ({
        viewMode: store.viewMode,
        activeSlideId: store.activeSlideId,
        secondarySelectedWidgetIds: store.secondarySelectedWidgetIds,
        activeWidgetId: store.activeWidgetId,
        setActiveWidgetId: store.setActiveWidgetId,
        updateSecondarySelectedWidget: store.updateSecondarySelectedWidget,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        onWidgetReady: store.onWidgetReady,
        widgetToRender: store.widgetsToRender,
        updateSlideVariable: store.updateSlideVariable,
    }))

    const movableRef = React.useRef<Moveable | null>(null)

    const isLocked = useRef(widget.locked)

    useEffect(() => {
        isLocked.current = widget.locked
    }, [widget.locked])

    const muiTheme = useTheme()

    const [initialDataSnapshot, setInitialDataSnapshot] = useState<ReportWidgetType>(widget)

    const [isReady, setIsReady] = useState(false)

    const initialScaledWidgetDimensions = scaleUpDimensions(
        widget.dimensions.width,
        widget.dimensions.height,
        slideData.dimensions.currentScale
    )
    const [scaledDimensions, setScaledDimensions] =
        useState<ReportWidgetType['dimensions']>(initialScaledWidgetDimensions)

    const [containerNode, setContainerNode] = useState<HTMLElement | null>(null)
    const setStatefulRef = useCallback((element: HTMLElement) => {
        setContainerNode(element)
    }, [])

    // Update the style of the widget container box when the widget's position or dimensions are updated.
    // This is needed to support undo/redo actions where the widget's position and dimensions are updated.
    useEffect(() => {
        if (containerNode !== null) {
            const _scaledDimensions = scaleUpDimensions(
                widget.dimensions.width,
                widget.dimensions.height,
                slideData.dimensions.currentScale
            )

            containerNode.setAttribute(
                'style',
                `top: ${widget.position.top}; left: ${widget.position.left}; width: ${_scaledDimensions.width}px;
                
                height: ${widget.dimensions.autoHeight ? 'auto' : _scaledDimensions.height + 'px'};`
            )
            // Update the Moveable instance's rect after the widget's position and dimensions are updated.
            movableRef.current?.updateRect()
        }
    }, [widget.position, widget.dimensions, containerNode, slideData.dimensions.currentScale])

    const isActive = useMemo(() => {
        return activeWidgetId === widget.id && secondarySelectedWidgetIds.length === 0
    }, [activeWidgetId, widget.id, secondarySelectedWidgetIds])

    const isSelected = useMemo(() => {
        return widget.id === activeWidgetId || secondarySelectedWidgetIds.includes(widget.id)
    }, [secondarySelectedWidgetIds, widget.id, activeWidgetId])

    const otherWidgetElementsList = useMemo(() => {
        const selfRemovedList: HTMLElement[] = []

        for (const id in slideData.widgetElementsDict) {
            if (Object.prototype.hasOwnProperty.call(slideData.widgetElementsDict, id)) {
                const widgetElement = slideData.widgetElementsDict[id]

                if (id !== widget.id) {
                    selfRemovedList.push(widgetElement)
                }
            }
        }

        return selfRemovedList
    }, [widget, slideData.widgetElementsDict])

    /*
     * Available z-index levels for this widget container box.
     * The levels are calculated based on this widget's index within the parent slide's list.
     * There is always a "baseLevel" and a "maxLevel" property available on this object but
     * the number of "subLevel"s is dependant on the "zIndexSubLevelFactor".
     * Based on that factor, we could have properties like subLevel1, subLevel2 and so on.
     * The number of sub levels is always equal to zIndexSubLevelFactor - 2.
     */
    // ! Question: What is the purpose of this? if we want to make the selected widget on top, how does this help?
    const availableZIndices: AvailableZIndicesType = useMemo(() => {
        // Don't assign a value less than 2.
        const zIndexSubLevelFactor = 5

        const maxZIndexLevel = (positionIndex + 1) * zIndexSubLevelFactor
        const baseZIndexLevel = maxZIndexLevel - (zIndexSubLevelFactor - 1)

        let levels: Record<`subLevel${string}`, number> = {}

        for (let i = 1; i < zIndexSubLevelFactor - 1; i++) {
            levels[`subLevel${i}`] = baseZIndexLevel + i
        }

        return {
            baseLevel: baseZIndexLevel,
            ...levels,
            maxLevel: maxZIndexLevel,
        }
    }, [positionIndex])

    // Expose widget container ref to the parent (useful for Moveable 'elementGuidelines' property).
    useEffect(() => {
        if (containerNode !== null) {
            exposeElement(widget.id, containerNode)
        }
    }, [containerNode])

    // Calculate the scaled widget dimensions and update the state
    useEffect(() => {
        const newDimensions = scaleUpDimensions(
            widget.dimensions.width,
            widget.dimensions.height,
            slideData.dimensions.currentScale
        )

        setScaledDimensions(newDimensions)
    }, [widget.dimensions, slideData.dimensions])

    const onWidgetClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (viewMode === 'design') {
            if ((event.shiftKey || event.ctrlKey) && activeWidgetId !== null) {
                updateSecondarySelectedWidget({ widgetId: widget.id })
            } else if (isActive === false) {
                setActiveWidgetId({
                    widgetId: widget.id,
                })
            }
        }
    }

    const determineDraggablity = () => {
        if (widget.locked) return false
        if (isActive === true) {
            switch (widget.content.kind) {
                case 'text':
                    return false
                case 'table':
                    return false
                case 'network':
                    return false
                case 'insight':
                    return false
                case 'combined':
                    return false
                case 'panel':
                    return false

                default:
                    break
            }
        }

        return true
    }

    const onDragEnd = useCallback(
        debounce((isDrag: boolean, draggedElement: HTMLElement) => {
            if (isDrag) {
                if (activeSlideId === null) return

                const slideContainerElement = document.getElementById('report_slide_container')!

                const elementBoundingRect = draggedElement.getBoundingClientRect()

                // Element offset relative to slide.
                const elementRelativeOffsets = {
                    top: elementBoundingRect.top - slideData.dimensions.offsetTop + slideContainerElement.scrollTop,
                    left: elementBoundingRect.left - slideData.dimensions.offsetLeft + slideContainerElement.scrollLeft,
                }

                // Element position in percantage. Relative to slide.
                const elementRelativePositionPercentage = getElementRelativePositionPercentage(elementRelativeOffsets, {
                    width: slideData.dimensions.width,
                    height: slideData.dimensions.height,
                })

                // Check if the widget is a combined widget in grouped mode
                // if (widget.content.kind === 'combined' && widget.content.details.mode === 'group') {
                //     const originalTop = parseWidgetPosition(widget.position.top)
                //     const originalLeft = parseWidgetPosition(widget.position.left)

                //     const deltaTop = parseFloat(elementRelativePositionPercentage.top) - originalTop
                //     const deltaLeft = parseFloat(elementRelativePositionPercentage.left) - originalLeft

                //     // Update positions of inner widgets
                //     widget.content.details.widgets.forEach((innerWidgetItem) => {
                //         const innerWidgetId = innerWidgetItem.id
                //         const innerWidget = activeSlide?.widgets.find((_widget) => _widget.id === innerWidgetId)

                //         if (innerWidget === undefined) return

                //         const innerWidgetTop = parseWidgetPosition(innerWidget.position.top) + deltaTop
                //         const innerWidgetLeft = parseWidgetPosition(innerWidget.position.left) + deltaLeft

                //         // Update each inner widget's position

                //         updateWidget({
                //             slideId: activeSlideId,
                //             widgetId: innerWidgetId,
                //             data: {
                //                 position: {
                //                     top: innerWidgetTop + '%',
                //                     left: innerWidgetLeft + '%',
                //                 },
                //             },
                //         })
                //     })
                // }

                // Remove the tansform and set the relative position
                draggedElement.style.transform = ''
                draggedElement.style.top = elementRelativePositionPercentage.top + '%'
                draggedElement.style.left = elementRelativePositionPercentage.left + '%'

                setInitialDataSnapshot((prev) => ({
                    ...prev,
                    position: {
                        top: elementRelativePositionPercentage.top + '%',
                        left: elementRelativePositionPercentage.left + '%',
                    },
                }))

                updateWidget({
                    slideId: activeSlideId,
                    widgetId: widget.id,
                    data: {
                        position: {
                            top: elementRelativePositionPercentage.top + '%',
                            left: elementRelativePositionPercentage.left + '%',
                        },
                    },
                })
            }
        }, 100),
        [activeSlideId, slideData.dimensions, widget, widget.id]
    )

    const determineResizeability = () => {
        if (widget.locked) return false
        // These widget kinds will never be resizeable
        switch (widget.content.kind) {
            case 'info':
                return false

            default:
                break
        }

        return isActive
    }

    const determineResizeDirections = () => {
        switch (widget.content.kind) {
            case 'filter':
                return ['w', 'e']

            case 'horizontalLine':
                return ['w', 'e']

            case 'verticalLine':
                return ['n', 's']

            default:
                if (widget.dimensions.autoHeight) return ['w', 'e']
                else return ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']
        }
    }

    const onResize = (
        target: OnResize['target'],
        width: OnResize['width'],
        height: OnResize['height'],
        drag: OnResize['drag'],
        delta: OnResize['delta']
    ) => {
        const beforeTranslate = drag.beforeTranslate

        delta[0] && (target.style.width = `${width}px`)
        delta[1] && (target.style.height = `${height}px`)
        target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`
    }

    const onResizeEnd = useCallback(
        debounce((isResize: boolean, resizedElement: HTMLElement) => {
            if (isResize) {
                if (activeSlideId === null) return

                // We use the "offset-X" values instead of "client-X" to account for element's borders.
                const elementDimensions = {
                    width: resizedElement.offsetWidth,
                    height: resizedElement.offsetHeight,
                }

                // Element dimensions in percentage. Relative to slide dimensions.
                const elementRelativeDimensionsPercentage = getElementRelativeDimensionsPercentage(elementDimensions, {
                    width: slideData.dimensions.width,
                    height: slideData.dimensions.height,
                })

                // Dimensions based on the base slide size, without scaling
                const baseDimensions = retrieveBaseDimensions(
                    elementDimensions.width,
                    elementDimensions.height,
                    slideData.dimensions.currentScale
                )

                const elementBoundingRect = resizedElement.getBoundingClientRect()

                // Element offset relative to slide.
                const elementRelativeOffsets = {
                    top: Math.floor(elementBoundingRect.top) - slideData.dimensions.offsetTop,
                    left: Math.floor(elementBoundingRect.left) - slideData.dimensions.offsetLeft,
                }
                // Element position in percantage. Relative to slide.
                const elementRelativePositionPercentage = getElementRelativePositionPercentage(elementRelativeOffsets, {
                    width: slideData.dimensions.width,
                    height: slideData.dimensions.height,
                })

                //Remove the tansform and set the relative position
                resizedElement.style.transform = ''
                resizedElement.style.top = elementRelativePositionPercentage.top + '%'
                resizedElement.style.left = elementRelativePositionPercentage.left + '%'
                // We need to set the dimensions in percentage to keep the responsivness of the widget on slide resize.
                resizedElement.style.width = elementRelativeDimensionsPercentage.width + '%'
                resizedElement.style.height = elementRelativeDimensionsPercentage.height + '%'

                // Check if the widget is a combined widget in grouped mode
                // if (widget.content.kind === 'combined' && widget.content.details.mode === 'group') {
                //     const slideHeight = slideData.dimensions.height
                //     const slideWidth = slideData.dimensions.width

                //     const combinedWidgetOriginalPosition = getAbsolutePosition(
                //         widget.position.left,
                //         widget.position.top,
                //         slideWidth,
                //         slideHeight
                //     )

                //     const combinedWidgetNewPosition = getAbsolutePosition(
                //         elementRelativePositionPercentage.left + '%',
                //         elementRelativePositionPercentage.top + '%',
                //         slideWidth,
                //         slideHeight
                //     )

                //     const scaleWidth = baseDimensions.width / widget.dimensions.width
                //     const scaleHeight = baseDimensions.height / widget.dimensions.height

                //     widget.content.details.widgets.forEach((innerWidgetItem) => {
                //         const innerWidgetId = innerWidgetItem.id
                //         const innerWidget = activeSlide?.widgets.find((_widget) => _widget.id === innerWidgetId)
                //         if (!innerWidget) return

                //         const newInnerWidth = innerWidget.dimensions.width * scaleWidth
                //         const newInnerHeight = innerWidget.dimensions.height * scaleHeight

                //         const innerWidgetOriginalPosition = getAbsolutePosition(
                //             innerWidget.position.left,
                //             innerWidget.position.top,
                //             slideWidth,
                //             slideHeight
                //         )

                //         const newInnerLeft =
                //             (combinedWidgetNewPosition.left +
                //                 (innerWidgetOriginalPosition.left - combinedWidgetOriginalPosition.left) * scaleWidth) /
                //             slideWidth
                //         const newInnerTop =
                //             (combinedWidgetNewPosition.top +
                //                 (innerWidgetOriginalPosition.top - combinedWidgetOriginalPosition.top) * scaleHeight) /
                //             slideHeight

                //         // Update inner widget dimensions
                //         updateWidget({
                //             slideId: activeSlideId,
                //             widgetId: innerWidgetId,
                //             data: {
                //                 position: {
                //                     top: newInnerTop * 100 + '%',
                //                     left: newInnerLeft * 100 + '%',
                //                 },
                //                 dimensions: {
                //                     width: newInnerWidth,
                //                     height: newInnerHeight,
                //                 },
                //             },
                //         })
                //     })
                // }

                updateWidget({
                    slideId: activeSlideId,
                    widgetId: widget.id,
                    data: {
                        position: {
                            top: elementRelativePositionPercentage.top + '%',
                            left: elementRelativePositionPercentage.left + '%',
                        },
                        dimensions: {
                            width: baseDimensions.width,
                            height: baseDimensions.height,
                        },
                    },
                })
            }
        }, 100),
        [activeSlideId, slideData.dimensions, widget.id, widget.position, widget.dimensions, scaledDimensions]
    )

    const onRotateEnd = (isRotate: boolean, rotation: number) => {
        if (isRotate) {
            if (activeSlideId === null) return

            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.id,
                styles: {
                    rotation: rotation,
                },
            })
        }
    }

    const getOutlineValue = () => {
        if (viewMode === 'design') {
            // Active (selected)

            if (isSelected) {
                return `2px solid ${
                    widget.locked ? muiTheme.palette.error.main : lighten(muiTheme.palette.primary.main, 0.5)
                }`
            }
            // Idle (un-selected)
            else {
                // Don't show outline for separator lines.
                if (['horizontalLine', 'verticalLine'].includes(widget.content.kind)) {
                    return undefined
                } else {
                    return `1px dashed ${alpha(muiTheme.palette.common.border_1, 0.25)}`
                }
            }
        } else {
            return undefined
        }
    }

    const onReady = () => {
        setIsReady(true)
        onWidgetReady({ widgetId: widget.id })
    }

    const onCloseConditionalVisibility = () => {
        if (activeSlideId === null) return
        if (!widget.conditionalVisibility.isEnabled) return
        if (widget.conditionalVisibility.variableName === null || widget.conditionalVisibility.variableName === '')
            return
        updateSlideVariable({
            slideId: activeSlideId!,
            data: {
                variableName: widget.conditionalVisibility.variableName!,
                variableValue: null,
            },
        })
    }

    useEffect(() => {
        const keyboardMove = (evt: KeyboardEvent) => {
            if (shouldCaptureKeydown() || isLocked.current === true) return
            const changeAmount = evt.shiftKey ? 10 : 1

            if (viewMode === 'design' && isActive) {
                if (evt.ctrlKey) {
                    // if holding ctrl, resize the widget
                    let deltaWidth = 0
                    let deltaHeight = 0

                    switch (evt.key) {
                        case 'ArrowUp':
                            deltaHeight = -changeAmount
                            break
                        case 'ArrowDown':
                            deltaHeight = changeAmount
                            break
                        case 'ArrowLeft':
                            deltaWidth = -changeAmount
                            break
                        case 'ArrowRight':
                            deltaWidth = changeAmount
                            break
                        default:
                            return
                    }

                    movableRef.current?.request('resizable', { deltaWidth, deltaHeight }, true)
                } else {
                    // if not holding ctrl, move the widget
                    let deltaX = 0
                    let deltaY = 0

                    switch (evt.key) {
                        case 'ArrowUp':
                            deltaY = -changeAmount
                            break
                        case 'ArrowDown':
                            deltaY = changeAmount
                            break
                        case 'ArrowLeft':
                            deltaX = -changeAmount
                            break
                        case 'ArrowRight':
                            deltaX = changeAmount
                            break
                        default:
                            return
                    }

                    movableRef.current?.request(
                        'draggable',
                        {
                            deltaX,
                            deltaY,
                        },
                        true
                    )
                }
            }
        }
        window.addEventListener('keydown', keyboardMove)

        return () => {
            window.removeEventListener('keydown', keyboardMove)
        }
    }, [isActive, containerNode, viewMode])

    return (
        <>
            <ClickAwayListenerWithDisabled
                disabled={
                    !conditionalVisible ||
                    !widget.conditionalVisibility.isEnabled ||
                    !widget.conditionalVisibility.closeOnOutsideClick
                }
                onClickAway={() => {
                    onCloseConditionalVisibility()
                }}
            >
                <Box
                    ref={(element: HTMLElement) => setStatefulRef(element)}
                    id={`widget-${initialDataSnapshot.id}`}
                    onClick={onWidgetClick}
                    onDoubleClick={() => {
                        if (viewMode === 'design') {
                            setActiveWidgetId({ widgetId: widget.id })
                            updateFocusMode(widget.id)
                        }
                    }}
                    onContextMenu={(evt) => onContextMenu(evt, widget, positionIndex)}
                    sx={(theme) => ({
                        position: 'absolute',
                        top: initialDataSnapshot.position.top,
                        left: initialDataSnapshot.position.left,
                        zIndex:
                            // activeWidgetId === widget.id
                            // ? // If the widget is active, we want it to be on top of all other widgets.
                            // to do so, we need to compute the max possible value of z-index for all the widgets in the slide
                            // This can be computed by multiplying the total number of widgets multiply by the subLevelFactor
                            //   (activeSlide?.widgets.length ?? 0) * Object.keys(availableZIndices).length + 1
                            // :
                            availableZIndices.baseLevel,

                        width: scaledDimensions.width,
                        height: scaledDimensions.height,
                        overflow: 'hidden',
                        cursor: isActive ? undefined : 'default',
                        transform: `rotate(${initialDataSnapshot.styles.rotation}deg)`,
                        outline: getOutlineValue(),
                        borderRadius: widget.styles.border.isEnabled ? widget.styles.border.radius : undefined,
                        border: widget.styles.border.isEnabled
                            ? `${widget.styles.border.width} ${widget.styles.border.style} ${widget.styles.border.color}`
                            : '1px solid transparent',

                        backgroundColor: widget.styles.backgroundColor.isEnabled
                            ? widget.styles.backgroundColor.color
                            : undefined,
                        opacity: opacity,
                        transition: 'opacity 1s',
                        // if the widget is not visible, disable pointer events
                        pointerEvents: opacity === 0 ? 'none' : 'auto',
                    })}
                >
                    {!isReady &&
                        !widget.conditionalVisibility.isEnabled &&
                        ['network'].includes(widget.content.kind) && (
                            <Skeleton
                                variant="rectangular"
                                animation="pulse"
                                sx={(theme) => ({
                                    width: '100%',
                                    height: '100%',
                                })}
                            />
                        )}

                    <Box sx={{ opacity: isReady ? 1 : 0, width: '100%', height: '100%', transition: 'opacity 1s' }}>
                        {widget.conditionalVisibility.isEnabled && widget.conditionalVisibility.hasCloseButton && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    ...CLOSE_BUTTON_POSITION[widget.conditionalVisibility.placement],
                                    zIndex: 100,
                                }}
                            >
                                <Tooltip title="Close" placement="top">
                                    <IconButton onClick={onCloseConditionalVisibility}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        <WidgetContent
                            onReady={onReady}
                            widgetId={widget.id}
                            content={widget.content}
                            title={widget.title}
                            tooltip={widget.tooltip}
                            isActive={isActive}
                            slideWidthScaleFactor={slideData.dimensions.currentScale}
                            viewMode={viewMode}
                        />
                    </Box>
                </Box>
            </ClickAwayListenerWithDisabled>

            {/* (Out-of-flow) Moveable container
				========================================= */}
            {viewMode === 'design' && (
                <Moveable
                    target={containerNode}
                    flushSync={flushSync}
                    origin={false}
                    // When resize or scale, keeps a ratio of the width, height.
                    keepRatio={false}
                    /*  Snappable
                        =============================== */
                    snappable={true}
                    snapDirections={{
                        top: true,
                        left: true,
                        bottom: true,
                        right: true,
                        center: true,
                        middle: true,
                    }}
                    elementSnapDirections={{
                        top: true,
                        left: true,
                        bottom: true,
                        right: true,
                        center: true,
                        middle: true,
                    }}
                    elementGuidelines={otherWidgetElementsList}
                    verticalGuidelines={[8, slideData.dimensions.width - 8, slideData.dimensions.width / 2]}
                    horizontalGuidelines={[8, slideData.dimensions.height - 8, slideData.dimensions.height / 2]}
                    bounds={{
                        top: 0,
                        left: 0,
                        right: slideData.dimensions.width,
                        bottom: slideData.dimensions.height,
                    }}
                    /*  Draggable
                        =============================== */
                    draggable={determineDraggablity()}
                    throttleDrag={1}
                    onDrag={({ target, transform }: OnDrag) => {
                        target!.style.transform = transform
                    }}
                    onDragEnd={({ target, isDrag }) => onDragEnd(isDrag, target as HTMLElement)}
                    /*  Resizable
                        =============================== */
                    // Only one of resizable, scalable, or warpable can be used.
                    resizable={determineResizeability()}
                    renderDirections={determineResizeDirections()}
                    // Can resize by dragging "edge" lines. False shows the handlers instead.
                    edge={false}
                    throttleResize={1}
                    onResizeStart={({ target, clientX, clientY }) => {}}
                    onResize={({ target, width, height, drag, delta, direction, clientX, clientY }: OnResize) =>
                        onResize(target, width, height, drag, delta)
                    }
                    onResizeEnd={({ target, isDrag, clientX, clientY }) => onResizeEnd(isDrag, target as HTMLElement)}
                    /*  Rotatable
                        =============================== */
                    // rotatable={isActive}
                    rotatable={false}
                    throttleRotate={1}
                    onRotateStart={({ target, clientX, clientY }) => {}}
                    onRotate={({ target, delta, dist, transform, clientX, clientY, absoluteRotation }: OnRotate) => {
                        target!.style.transform = transform
                    }}
                    onRotateEnd={({ target, isDrag, clientX, clientY, datas, lastEvent }) => {
                        const lastOnRotateEvent = lastEvent as OnRotate

                        onRotateEnd(isDrag, lastOnRotateEvent.absoluteRotation)
                    }}
                    className="WidgetContainer"
                    ref={movableRef}
                />
            )}
        </>
    )
}

const ClickAwayListenerWithDisabled = ({ children, disabled, onClickAway }: any) => {
    return disabled ? (
        <>{children}</>
    ) : (
        <>
            <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
        </>
    )
}

export default WidgetContainer
