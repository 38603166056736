import React, { ReactElement, ReactNode, useState } from 'react'
import Draggable from 'react-draggable'
import BaseButton from 'components/base/BaseButton'
import Box from '@mui/material/Box'
import Zoom from '@mui/material/Zoom'
import { ClickAwayListener } from '@mui/material'

export interface VerticalActionItemProps {
    icon: ReactNode
    content: ReactNode
    disable?: boolean
    tooltip?: string
}

export default function NetowkrInfoWrapperComponent({
    icon,
    content,
    disable = false,
    tooltip,
}: VerticalActionItemProps) {
    const [open, setOpen] = useState<boolean>(false)
    const [isActive, setIsActive] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsActive(true)
        setOpen(!open)
    }



    return (
        // ToDo this clickaway listenr approach is working fine, but it is not the best approach.
        // ToDo A better approach is to use context or a shared variable, every time a component gets clicked, it will increment the zIndex stored in the global state.
        <ClickAwayListener onClickAway={() => setIsActive(false)} >
            <Box
                sx={{
                    position: 'relative',
                    zIndex: isActive ? 10 : 0

                }}
            >
                <BaseButton
                    onClick={handleClick}
                    disableElevation
                    sx={(theme) => ({
                        height: 48,
                        minWidth: 0,
                        width: 48,
                        borderRadius: 0,
                        // @Theme conditional
                        backgroundColor: open
                            ? theme.palette.primary.main
                            : theme.palette.mode === 'light'
                                ? theme.palette.common.bg_3
                                : theme.palette.common.bg_4,
                        color: open ? theme.palette.common.fill_4 : theme.palette.primary.main,

                        '&:hover': {
                            // @Theme conditional
                            backgroundColor: open
                                ? theme.palette.primary.main
                                : theme.palette.mode === 'light'
                                    ? theme.palette.common.bg_3
                                    : theme.palette.common.bg_4,
                        },
                    })}
                >
                    {icon}
                </BaseButton>

                {open && (
                    <Draggable>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                // '48' is the width of the button.
                                right: 48 + 8,
                            }}

                            onClick={() => {
                                setIsActive(true)
                            }}


                        >
                            <Zoom in>
                                <Box
                                    sx={(theme) => ({
                                        overflow: 'hidden',

                                        borderRadius: '4px',
                                        border: `1px solid ${theme.palette.common.border_2}`,
                                        filter: ' alpha(opacity = 95)',
                                        mozOpacity: 0.95,
                                        /* Mozilla */
                                        opacity: 0.95,
                                        // @Theme conditional
                                        backgroundColor:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.common.bg_1
                                                : theme.palette.common.bg_3,
                                    })}
                                >
                                    {content &&
                                        React.cloneElement(content as ReactElement, {
                                            onClose: () => setOpen(false),
                                        })}
                                </Box>
                            </Zoom>
                        </Box>
                    </Draggable>
                )}
            </Box>
        </ClickAwayListener>
    )
}
