//* ======= Libraries
import React, { useState, useEffect, useCallback } from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Select from '@mui/material/Select'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
//* ======= Custom logic
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'

export type CompareOperatorsType = '=' | '<' | '>' | '<=' | '>=' | '!='

const CompareOperators: CompareOperatorsType[] = ['=', '<', '>', '<=', '>=', '!=']

export type DynamicFilterItemType = {
    field: string
    operator: CompareOperatorsType
    value: any
}

export interface IDynamicFilterContent {
    fields: string[]
    onChange: (filters: DynamicFilterItemType[]) => void
    defaultValue?: DynamicFilterItemType[]
}

function FilterContentDynamic({
    fields,
    defaultValue = [],
    onChange: originalOnChange,
}: IDynamicFilterContent) {
    const [filters, setFilters] = useState<DynamicFilterItemType[]>(defaultValue)

    const onChange = useCallback(
        (newVal: DynamicFilterItemType[]) => {
            originalOnChange(newVal)
        },
        [originalOnChange]
    )

    //Change value on update
    useEffect(() => {
        onChange(filters)
    }, [filters, onChange])

    const addFilter = () => {
        setFilters((prevState) => [
            ...prevState,
            {
                field: '',
                operator: '=',
                value: '',
            },
        ])
    }

    const removeFilter = (filterIdx: number) => {
        setFilters((prevState) => prevState.filter((_filter, idx) => idx !== filterIdx))
    }

    const updateFilter = useCallback(
        (index: number, field: keyof DynamicFilterItemType) =>
            (value: DynamicFilterItemType[keyof DynamicFilterItemType]) => {
                setFilters((prevState) =>
                    prevState.map((_filter, idx) => {
                        if (idx === index) {
                            return {
                                ..._filter,
                                [field]: value,
                            }
                        } else {
                            return _filter
                        }
                    })
                )
            },
        [setFilters]
    )

    return (
        <Stack gap={2}>
            {/* Top add filter button
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={16} fontWeight={400} color="common.text_1">
                    Filter
                </Typography>

                <BaseButton label="Add" onClick={(e) => addFilter()} startIcon={<AddIcon />} />
            </Stack>

            {/* Filters list
                ========================================= */}
            {filters.length > 0 &&
                filters.map((_filter, filterIdx) => (
                    <Stack
                        key={filterIdx}
                        gap={1}
                        sx={(theme) => ({
                            paddingBottom: 2,

                            '&:not(:last-of-type)': {
                                borderBottom: `1px solid ${theme.palette.common.border_2}`,
                            },
                        })}
                    >
                        {/* Field Title and remove button
                            ========================================= */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                            <Typography fontSize={14} fontWeight={400} color="common.text_1">
                                Field
                            </Typography>

                            {/* Remove field button
                                ========================================= */}
                            <IconButton
                                onClick={(e) => removeFilter(filterIdx)}
                                sx={{
                                    color: 'common.trueBlack',
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>

                        {/* Field type select
                            ========================================= */}

                        <Select
                            id="filter-select"
                            onChange={(evt) => updateFilter(filterIdx, 'field')(evt.target.value)}
                            value={_filter.field}
                            displayEmpty
                            renderValue={(value) => {
                                if (value === '') {
                                    return 'Select an option'
                                } else {
                                    return value
                                }
                            }}
                            input={<BasicSelectInput />}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiSelect-icon': {
                                    color: 'primary.main',
                                },
                            }}
                        >
                            <MenuItem value={''}>
                                <em>Select an option</em>
                            </MenuItem>

                            {fields.map((_field, fieldIdx) => (
                                <MenuItem key={_field + fieldIdx} value={_field}>
                                    {_field}
                                </MenuItem>
                            ))}
                        </Select>

                        {/* operator and value select
                            ========================================= */}
                        <Stack direction="row" gap={2}>
                            {/* operator select
                            ========================================= */}
                            <Select
                                id="filter-op-select"
                                onChange={(evt) => updateFilter(filterIdx, 'operator')(evt.target.value)}
                                value={_filter.operator}
                                displayEmpty
                                renderValue={(value) => {
                                    return value
                                }}
                                input={<BasicSelectInput sx={{ height: '56px' }} />}
                                IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                        },
                                    },
                                }}
                                sx={{
                                    flex: 0.2,
                                    '& .MuiSelect-icon': {
                                        color: 'primary.main',
                                    },
                                }}
                            >
                                {CompareOperators.map((_field, fieldIdx) => (
                                    <MenuItem key={_field + fieldIdx} value={_field}>
                                        {_field}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* Value field
                            ========================================= */}
                            <BaseFilledTextField
                                sx={{ flex: 0.8 }}
                                value={_filter.value}
                                hiddenLabel
                                onChange={(evt) => updateFilter(filterIdx, 'value')(evt.target.value)}
                            />
                        </Stack>
                    </Stack>
                ))}
        </Stack>
    )
}

export default FilterContentDynamic
