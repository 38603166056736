//* ======= Libraries
import React, { useCallback, useState } from 'react'
import Cropper, { Area, Point } from 'react-easy-crop'
import { Stack, Slider } from '@mui/material'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import getCroppedImg from './crop-helper'
//* ======= Assets and styles

type Props = {
    // FileReader result string
    imageSource: string
    onCropComplete: (imageUrl: string) => void
    aspectRatio?: '16 / 9' | '4 / 3'
    isCircularCrop?: boolean
}

function ImageCropper({
    imageSource = '',
    onCropComplete,
    aspectRatio = '4 / 3',
    isCircularCrop = true,
}: Props) {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

    const cropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onConfirm = async () => {
        try {
            const croppedImage = await getCroppedImg(imageSource, croppedAreaPixels, 0)

            croppedImage && onCropComplete(croppedImage)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Stack alignItems="center" gap={3}>
            {/* Crop section
                ========================================= */}
            <Cropper
                image={imageSource}
                crop={crop}
                zoom={zoom}
                restrictPosition={false}
                aspect={aspectRatio === '16 / 9' ? 16 / 9 : aspectRatio === '4 / 3' ? 4 / 3 : 4 / 3}
                onCropChange={setCrop}
                onCropComplete={cropComplete}
                onZoomChange={setZoom}
                cropShape={isCircularCrop ? 'round' : 'rect'}
                style={{
                    containerStyle: {
                        position: 'relative',
                        width: `${(400 * 4) / 3}px`,
                        height: '400px',
                        background: '#333',
                    },
                }}
            />

            {/* Zoom slider and confirm button
                ========================================= */}
            <Stack
                alignSelf="stretch"
                justifyContent="center"
                gap={3}
                sx={{
                    paddingX: 2,
                }}
            >
                <Slider
                    aria-label="zoom"
                    value={zoom}
                    onChange={(evt, value) => setZoom(value as number)}
                    min={1}
                    max={20}
                    step={0.1}
                />

                <BaseButton
                    label="Confirm"
                    onClick={onConfirm}
                    variant="contained"
                    sx={{
                        alignSelf: 'center',

                        width: 185,
                        paddingY: 1.5,
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default ImageCropper
