//* ======= Libraries
import WebWorker from 'helpers/webWorkerHelper'
import React, { useState, useEffect, createContext, useContext, useReducer, useMemo } from 'react'
import { EdgeBaseSettings } from '../constants/defaultEdgeSettings'
import { NodeBaseSettings } from '../constants/defaultNodeSettings'
import {
    NodeType,
    NodeRenderType,
    NodeInfoConfigType,
    NodeInteractionConfigType,
    NodeIdType,
    NodeStyleType,
    EdgeType,
    EdgeRenderType,
    EdgeStyleType,
    DataSchemaType,
    FilterItemType,
    NetworkAnalyticsType,
    NetworkVizKindType,
    GraphActionType,
    TimePanelType,
    EdgeArrowType,
    LayoutKindType,
    LayoutSettingsType,
    GraphLegendType,
    NetworkStatusKindType,
    NodeAdjacentListType,
    NodeAttributeType,
    NetworkAnalyticsSettingsType,
    NetworkShrinkType,
    NodeGroupInfoType,
    NetworkSelectedItemType,
    ERGMSettingsType,
    ALAAMSettingsType,
    StatisticalTestType,
    NetworkKeyFrameType,
} from '../types/NetworkViz.types'
//* ======= Components and features
//* ======= Custom logic
import networkVizContextReducer, { NetworkVizContextActionType } from './NetworkVizContextReducer'
//* ======= Assets and styles

/*
 * Context initialization and type definition
 * =========================================
 */

export type NetworkVizContextType = {
    status: NetworkStatusKindType
    errorMessage?: string
    id: string | number
    title: string
    viewMode: 'design' | 'preview'
    //nodes
    nodes: Record<NodeIdType, NodeType>
    nodeRenders: NodeRenderType[]
    nodeStyle: NodeStyleType
    nodeInfoConfig: NodeInfoConfigType
    nodeInteractionConfig: NodeInteractionConfigType
    nodeGroupBy: NodeAttributeType[]
    nodeDataSchema: DataSchemaType
    nodeAdjacentList: NodeAdjacentListType
    nodeGroupInfo: NodeGroupInfoType
    //edges
    edges: Record<number | string, EdgeType>
    edgeRenders: EdgeRenderType[]
    edgeStyle: EdgeStyleType
    edgeArrowType: EdgeArrowType
    edgeGroupBy: string | null
    edgeDataSchema: DataSchemaType
    onlyShowReciprocatedEdges: boolean
    highlightReciprocatedEdges: boolean
    dimOneWayEdges: boolean
    //search
    searchResult: NodeIdType[]
    searchResultIndex: number
    //data
    filters: FilterItemType[]
    analytics: Record<string, NetworkAnalyticsType> | null
    analyticsSettings: {
        groupBy: string
        networks: Record<string, NetworkAnalyticsSettingsType>
        ergm: ERGMSettingsType
        alaam: ALAAMSettingsType
        assortativity: NodeAttributeType[]
    }
    statisticalTests: StatisticalTestType[]
    //graph
    networkShrink: NetworkShrinkType
    networkVizKind: NetworkVizKindType
    level: 'all' | NodeIdType
    presets: { [key: string]: string }
    legend: GraphLegendType
    //actions
    action: GraphActionType | null
    selectedItem: null | NetworkSelectedItemType
    //time panel
    timePanel: TimePanelType
    currentTimeFrame: Date
    //layout algorithm
    layoutKind: LayoutKindType
    layoutSettings: LayoutSettingsType
    // demo
    keyFrames: NetworkKeyFrameType[]
}

export const NetworkVizInitialState: NetworkVizContextType = {
    status: 'pending',
    // Placeholder: This property should be overwritten on initialization.
    id: -1,
    // Placeholder: This property should be overwritten on initialization.
    title: '',
    // Placeholder: This property should be overwritten on initialization.
    viewMode: 'design',
    //nodes
    nodes: {},
    nodeRenders: [],
    nodeStyle: { default: NodeBaseSettings },
    networkShrink: {
        enabled: false,
        edgeWidth: {
            range: {
                max: 8,
                min: 1,
            },
            scaled: true,
            threshold: 1,
        },
        nodeSize: {
            scaledBy: {
                source: 'info',
                field: 'Number of nodes',
            },
            threshold: 3,
            range: {
                min: 8,
                max: 28,
            },
            scaled: true,
        },
    },
    level: 'all',
    nodeGroupInfo: {},
    nodeInfoConfig: {
        fileds: {},
        hideNA: true,
    },
    nodeInteractionConfig: {
        directed: true,
        freeRoam: true,
        dimOtherNodesOnSelect: true,
        hideNonAdjacentNodesOnSelect: false,
        layer: 1,
        layerColor: false,
        zoomSelectedNode: false,
    },
    nodeGroupBy: [],
    nodeDataSchema: { fields: {}, groupByOptions: [], numericOptions: [] },
    nodeAdjacentList: {},
    //edges
    edges: {},
    edgeRenders: [],
    edgeStyle: { default: EdgeBaseSettings },
    edgeGroupBy: null,
    edgeArrowType: { arrow: { source: 'none', target: 'arrow' }, size: 2 },
    edgeDataSchema: { fields: {}, groupByOptions: [], numericOptions: [] },
    onlyShowReciprocatedEdges: false,
    highlightReciprocatedEdges: false,
    dimOneWayEdges: false,
    //search
    searchResult: [],
    searchResultIndex: -1,
    //data
    statisticalTests: [],
    filters: [],
    analytics: null,
    analyticsSettings: {
        groupBy: '',
        networks: {},
        ergm: {
            explanatoryAttributes: [],
            behavior: [],
            characteristics: [],
            networks: [],
        },
        alaam: {
            explanatoryAttributes: [],
            behavior: [],
            characteristics: [],
            networks: [],
            quantile: 0.75,
            targetAttributes: [],
        },
        assortativity: [],
    },
    //graph
    networkVizKind: '2d+',
    legend: { edges: [], nodes: [] },
    presets: {},
    //actions
    action: null,
    selectedItem: null,
    //time panel
    timePanel: { edgeAttribute: '', interval: 'months', nodeAttribute: '', show: false },
    currentTimeFrame: new Date(),
    //layout algorithm
    layoutKind: 'random',
    layoutSettings: {
        arf: {},
        circular: {},
        force: {
            attraction: 0.0005,
            repulsion: 0.1,
            gravity: 0.0001,
            inertia: 0.6,
            maxIteration: 500,
            maxMove: 200,
        },
        spring: {},
        sfdp: {
            vweight: null,
            eweight: 'None',
            groups: null,
            C: 0.2,
            K: 0,
            p: 2,
            theta: 0.6,
            max_level: 15,
            r: 1,
            gamma: 0.3,
            mu: 2,
            kappa: 1,
            cooling_step: 0.95,
            adaptive_cooling: true,
            epsilon: 0.01,
            max_iter: 0,
        },
        random: {},
        planar: {},
        noverlap: {
            gridSize: 20,
            margin: 5,
            expansion: 1.1,
        },
        kamadakawai: {},
        frl: {},
        'force-atlas': {
            adjustSize: true,
            barnesHutOptimize: true,
            barnesHutTheta: 0.5,
            gravity: 1,
            linLogMode: false,
            outboundAttractionDistribution: true,
            strongGravityMode: false,
            maxIteration: 500,
        },
    },
    keyFrames: [],
}

const NetworkVizCtx = createContext<NetworkVizContextType | null>(null)
const NetworkVizDispatchCtx = createContext<React.Dispatch<NetworkVizContextActionType> | null>(null)

/*
 * Provider component
 * =========================================
 */

type NetworkVizContextProps = {
    children: React.ReactNode
}

function NetworkVizContext({ children }: NetworkVizContextProps) {
    const [contextState, dispatch] = useReducer(networkVizContextReducer, NetworkVizInitialState)

    return (
        <NetworkVizDispatchCtx.Provider value={dispatch}>
            <NetworkVizCtx.Provider value={contextState}>{children}</NetworkVizCtx.Provider>
        </NetworkVizDispatchCtx.Provider>
    )
}

export default NetworkVizContext

/*
 * Custom hooks to facilitate usage of context value.
 * =========================================
 */

// Use context state
export function useNetworkVizContext() {
    const context = useContext(NetworkVizCtx)

    if (context === null) {
        throw new Error(
            'No context provider found for useNetworkVizContext. Make sure you are calling this hook from a descendant of <NetworkVizContext>.'
        )
    }

    return context
}

// Use context dispatch
export function useNetworkVizDispatch() {
    const dispatch = useContext(NetworkVizDispatchCtx)

    if (dispatch === null) {
        throw new Error(
            'No context provider found for useNetworkVizDispatch. Make sure you are calling this hook from a descendant of <NetworkVizContext>.'
        )
    }

    return dispatch
}
