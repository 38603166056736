import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useRef, useState } from 'react'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import RangeSliderSelector from 'components/widgets/RangeSliderSelector'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tab, Tabs } from '@mui/material'
import { StyledTextField } from 'features/StyledComponents/StyledTextField'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { FilterItemType, KeysOfUnion, DataSchemaItemType } from 'features/network-viz/types/NetworkViz.types'
import { useNetworkVizContext } from 'features/network-viz/context/NetworkVizContext'
import { NODE_ANALYTICS_METRICS } from 'features/network-viz/constants/NetworkViz.const'
import { isEqual, uniq } from 'lodash'
import NodeAttributeSelector from 'features/network-viz/helpers/NodeAttributeSelector'

function a11yProps(index: number) {
    return {
        id: `filter-type-tab-${index}`,
        'aria-controls': `filter-type-tabpanel-${index}`,
    }
}
interface FilterSettingsItemProps {
    filterItem: FilterItemType
    onDelete: () => void
    changeFilterItem: (index: number, newFilterItem: FilterItemType) => void
    index: number
}

export default function NetworkFilterSettingsItem({
    filterItem,
    onDelete,
    changeFilterItem,
    index,
}: FilterSettingsItemProps) {
    const { nodeDataSchema, edgeDataSchema, analytics } = useNetworkVizContext()

    const dataInfoRef = useRef<DataSchemaItemType>()
    const [_filterItem, setFilterItem] = useState<FilterItemType>(filterItem)

    const updateFilterItem = (field: KeysOfUnion<FilterItemType>) => (value: any) => {
        setFilterItem((pv) => ({ ...pv, [field]: value }))
    }

    const changeNumericFilterType = (e: React.SyntheticEvent, value: string) => {
        setFilterItem((pv) => {
            if (pv.attribute !== null && pv.type === 'number' && value === 'topK') {
                return {
                    ...pv,
                    type: 'number',
                    numericMode: 'topK',
                    topK: {
                        k: 10,
                        type: 'high',
                    },
                }
            } else {
                if (dataInfoRef.current?.type === 'number') {
                    return {
                        ...pv,
                        type: 'number',
                        numericMode: 'range',
                        range: {
                            max: dataInfoRef.current?.range.max,
                            min: dataInfoRef.current?.range.min,
                        },
                        values: {
                            max: dataInfoRef.current?.range.max,
                            min: dataInfoRef.current?.range.min,
                        },
                    }
                }
            }
            return { ...pv }
        })
    }

    const toggleTopKMode = (e: React.SyntheticEvent, value: string) => {
        setFilterItem((pv) => {
            if (pv.attribute !== null && pv.type === 'number' && pv.numericMode === 'topK') {
                return {
                    ...pv,
                    topK: {
                        ...pv.topK,
                        type: value === 'high' ? 'high' : 'low',
                    },
                }
            }
            return { ...pv }
        })
    }
    const updateTopKNumber = (value: string) => {
        setFilterItem((pv) => {
            if (pv.attribute !== null && pv.type === 'number' && pv.numericMode === 'topK') {
                return {
                    ...pv,
                    topK: {
                        ...pv.topK,
                        k: Number(value),
                    },
                }
            }
            return { ...pv }
        })
    }

    const deleteStringSelectedOption = (value: string) => () => {
        setFilterItem((pv) => {
            if (pv.attribute !== null && pv.type === 'string') {
                return {
                    ...pv,
                    values: pv.values.filter((f) => f !== value),
                }
            }
            return { ...pv }
        })
    }

    useEffect(() => {
        if (
            _filterItem.attribute !== null &&
            _filterItem.attribute.field !== '' &&
            (!('attribute' in filterItem) || !isEqual(filterItem.attribute, _filterItem.attribute))
        ) {
            const attrSelected = _filterItem.attribute
            const filterMode = _filterItem.filterMode
            let dataInfo: DataSchemaItemType | undefined = undefined
            if (attrSelected.source === 'analytic') {
                if (
                    [
                        'greedy_modularity_community',
                        'weakly_connected_component',
                        'strongly_connected_component',
                    ].includes(attrSelected.field)
                ) {
                    dataInfo = {
                        key: attrSelected.field,
                        type: 'string',
                        range: uniq(
                            Object.values(analytics?.[attrSelected.relationship].nodes ?? {}).map(
                                (n) => n[attrSelected.field]?.toString() ?? ''
                            )
                        ),
                    }
                } else {
                    dataInfo = {
                        key: attrSelected.field,
                        type: 'number',
                        range: {
                            values: [],
                            // ToDo analytics relation
                            max: analytics?.[attrSelected.relationship]?.schema[attrSelected.field]?.max || 10,
                            min: analytics?.[attrSelected.relationship]?.schema[attrSelected.field]?.min || 0,
                        },
                    }
                }
            } else if (attrSelected.source === 'edge') {
                dataInfo = edgeDataSchema.fields[attrSelected.field]
            } else if (attrSelected.source === 'info') {
                dataInfo = nodeDataSchema.fields[attrSelected.field]
            }

            dataInfoRef.current = dataInfo
            if (dataInfo === undefined) return
            switch (dataInfo.type) {
                case 'number':
                    setFilterItem({
                        id: _filterItem.id,
                        attribute: attrSelected,
                        filterMode,
                        type: 'number',
                        numericMode: 'range',
                        range: {
                            max: dataInfo.range.max,
                            min: dataInfo.range.min,
                        },
                        values: {
                            max: dataInfo.range.max,
                            min: dataInfo.range.min,
                        },
                    })
                    break
                case 'string':
                    setFilterItem({
                        id: _filterItem.id,
                        attribute: attrSelected,
                        filterMode,
                        range: [...dataInfo.range],
                        type: 'string',
                        values: [],
                    })
            }
        }
    }, [_filterItem.attribute])

    useEffect(() => {
        changeFilterItem(index, { ..._filterItem })
    }, [_filterItem])

    return (
        <Stack
            gap={1}
            spacing={1}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header and delete button
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1} minWidth={0}>
                {/* Attribute select
                ========================================= */}
                {/* <BaseSelectWithLabel
                    options={[
                        { value: 'analytics', label: "Analytics's Attributes", section: true },
                        ...NODE_ANALYTICS_METRICS,
                        { value: 'node', label: "Node's Attributes", section: true },
                        ...Object.keys(nodeDataSchema.fields).map((key) => ({
                            value: `node-${key}`,
                            label: key,
                        })),
                        {
                            value: 'edge',
                            label: "Edge's Attributes",
                            section: true,
                        },
                        ...Object.keys(edgeDataSchema.fields).map((key) => ({
                            value: `edge-${key}`,
                            label: key,
                        })),
                    ]}
                    value={_filterItem.attribute}
                    onChange={updateFilterItem('attribute')}
                    size="small"
                    sx={{ flexGrow: 1 }}
                /> */}

                <NodeAttributeSelector
                    label="Attribute"
                    attribute={'attribute' in _filterItem ? _filterItem.attribute : null}
                    onChange={updateFilterItem('attribute')}
                    analytics={analytics}
                    nodeAttributeOptions={Object.keys(nodeDataSchema.fields)}
                    edgeAttributeOptions={Object.keys(edgeDataSchema.fields)}
                />

                <IconButton onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </Stack>

            {/* Mode select
                ========================================= */}
            <FormControl>
                <FormLabel id={`label-filter-mode-${_filterItem.id}`}>Filter Mode</FormLabel>
                <RadioGroup
                    aria-labelledby={`label-filter-mode-${_filterItem.id}`}
                    row
                    value={_filterItem.filterMode}
                    onChange={(e) => updateFilterItem('filterMode')(e.target.value)}
                >
                    <FormControlLabel
                        slotProps={{
                            typography: {
                                sx: { fontSize: 14 },
                            },
                        }}
                        value={'hide'}
                        control={<Radio />}
                        label="Hide"
                    />
                    <FormControlLabel
                        slotProps={{
                            typography: {
                                sx: { fontSize: 14 },
                            },
                        }}
                        value={'dim'}
                        control={<Radio />}
                        label="Dim"
                    />
                    <FormControlLabel
                        slotProps={{
                            typography: {
                                sx: { fontSize: 14 },
                            },
                        }}
                        value={'filterHighlight'}
                        control={<Radio />}
                        label="Highlight"
                    />
                </RadioGroup>
            </FormControl>

            {_filterItem.attribute === null ? (
                /*  Loading
                    ========================================= */
                <Box></Box>
            ) : _filterItem.type === 'number' ? (
                /*  Number range selector
                    ========================================= */
                <>
                    <Box sx={{ borderColor: 'divider' }}>
                        <Tabs
                            variant="fullWidth"
                            value={_filterItem.numericMode}
                            onChange={changeNumericFilterType}
                            aria-label="filter type selector"
                        >
                            <Tab label="Range" value="range" {...a11yProps(0)} />
                            <Tab label="Top K" value="topK" {...a11yProps(1)}></Tab>
                        </Tabs>
                    </Box>

                    {_filterItem.numericMode === 'range' ? (
                        <RangeSliderSelector
                            min={_filterItem.range.min}
                            max={_filterItem.range.max}
                            values={[_filterItem.values.min, _filterItem.values.max]}
                            onChange={(values) => {
                                updateFilterItem('values')({
                                    min: values[0],
                                    max: values[1],
                                })
                            }}
                        />
                    ) : (
                        <Box padding={1}>
                            <FormControl>
                                <FormLabel id="label-topk-type-radio-buttons-group">Mode</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="label-topk-type-radio-buttons-group"
                                    name="topk-type-radio-buttons-group"
                                    value={_filterItem.topK.type}
                                    onChange={toggleTopKMode}
                                >
                                    <FormControlLabel value="high" control={<Radio />} label="High" />
                                    <FormControlLabel value="low" control={<Radio />} label="Low" />
                                </RadioGroup>
                            </FormControl>
                            <StyledTextField
                                label="K"
                                size="small"
                                type="number"
                                value={_filterItem.topK.k}
                                onChange={(e) => updateTopKNumber(e.target.value)}
                            />
                        </Box>
                    )}
                </>
            ) : _filterItem.type === 'string' ? (
                /*  Multiple text item selector
                    ========================================= */
                <Stack gap={1}>
                    {/* Multiple item select
                        ========================================= */}
                    <BaseSelectWithLabel
                        label="Select"
                        value={_filterItem.values}
                        options={_filterItem.range}
                        onChange={updateFilterItem('values')}
                        selectProps={{
                            multiple: true,
                        }}
                        size="small"
                    />

                    {/* Selected items chips
                        ========================================= */}
                    <Stack direction="row" alignItems="center" flexWrap="wrap" gap={0.5}>
                        {_filterItem.values.map((sf, index) => (
                            <Chip label={sf} onDelete={deleteStringSelectedOption(sf)} />
                        ))}
                    </Stack>
                </Stack>
            ) : (
                false
            )}
        </Stack>
    )
}
