import React, { useContext, useEffect } from 'react'
import { RootContext } from 'contexts/RootContext'
import { SurveyContext } from 'contexts/SurveyContex'
import { useParams } from 'react-router-dom'
import { GetSurveyReportService, UpdateSurveyHasReportService } from 'services/SurveyService'

type Props = {}

function SurveyReportDesigner({}: Props) {
    const { pid, sid } = useParams()
    //states

    const { survey, updateSurvey } = useContext(SurveyContext)
    const { setPath } = useContext(RootContext)

    useEffect(() => {
        if (!pid || !sid) return
        GetSurveyReportService(pid, sid).then((res) => {
            if (res.success) {
                setPath((p) => [...p, { title: 'Individual Report' }])
            }
        })
    }, [])

    //methods
    const disableSurveyReport = () => {
        if (pid == null || sid == null) return
        UpdateSurveyHasReportService(pid, sid, false).then(
            (res) =>
                res.success &&
                updateSurvey({
                    hasReport: false,
                })
        )
    }

    return <>TBD</>
}

export default SurveyReportDesigner
