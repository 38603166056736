//* ======= Libraries
import { Outlet } from 'react-router-dom'
import Stack from '@mui/material/Stack'

export default function SurveyInstanceRoutes() {
    return (
        <Stack
            component="main"
            spacing={1}
            sx={(theme) => ({
                height: '100%',
                // @Theme conditional
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.bg_4 : theme.palette.common.bg_1,
            })}
        >
            <Outlet />
        </Stack>
    )
}
