import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en/translation.json';
import fr from './fr/translation.json';


declare module 'react-i18next' {
    interface CustomTypeOptions {
        resources: typeof en;
    }
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: en,
            fr: fr
        },
        defaultNS: "common",
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });
