import { useEffect, useState } from 'react'
import {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from '../context/NetworkVizContext'
import { NetworkAnalyticsSettingsType } from '../types/NetworkViz.types'
import StyledDialog from 'components/dialog/StyledDialog'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseButton from 'components/base/BaseButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

type AnalyticsSettingsProps = {
    open: boolean
    onClose: () => void
}

const AnalyticsSettings = ({ open, onClose }: AnalyticsSettingsProps) => {
    const { analyticsSettings, nodeDataSchema, edgeDataSchema } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [network, setNetwork] = useState<string>('')
    const [localData, setLocalData] = useState<NetworkVizContextType['analyticsSettings']>(analyticsSettings)

    useEffect(() => {
        if (open) {
            setLocalData({ ...analyticsSettings })
        }
    }, [open])

    const saveSettings = () => {
        dispatchContext({
            type: 'ANALYTICS_SETTINGS_EDIT',
            payload: localData,
        })
        onClose()
    }

    const updateNetworkGroupBy = (field: string) => {
        setLocalData((pv) => {
            const tmp = { ...pv }
            tmp.groupBy = field
            const dataSchemaItem = edgeDataSchema.fields[field]
            let values: string[] = []
            if (dataSchemaItem != null) {
                switch (dataSchemaItem.type) {
                    case 'number':
                        values = dataSchemaItem.range.values
                        break
                    case 'string':
                        values = dataSchemaItem.range
                        break
                }
            }
            tmp.networks = {}
            for (const value of values) {
                tmp.networks[value] = {
                    nodeFilters: [],
                    relationType: 'neutral',
                }
            }
            return tmp
        })
    }

    const updateRelationType = (relation: NetworkAnalyticsSettingsType['relationType']) => {
        setLocalData((pv) => {
            const tmp = { ...pv }
            tmp.networks[network].relationType = relation
            return tmp
        })
    }

    // * filters manipulation

    const addFilterItems = () => {
        setLocalData((pv) => {
            const tmp = { ...pv }
            if (tmp.networks[network].nodeFilters === undefined) {
                tmp.networks[network].nodeFilters = []
            }
            tmp.networks[network].nodeFilters.push({
                field: '',
                values: [],
            })
            return tmp
        })
    }

    const removeFilterItems = (idx: number) => () => {
        setLocalData((pv) => {
            const tmp = { ...pv }
            tmp.networks[network].nodeFilters.splice(idx, 1)

            return tmp
        })
    }

    const updateFilterItems =
        (idx: number, field: keyof NetworkAnalyticsSettingsType['nodeFilters'][number]) => (value: any) => {
            setLocalData((pv) => {
                const tmp = { ...pv }
                tmp.networks[network].nodeFilters[idx][field] = value
                if (field === 'field') tmp.networks[network].nodeFilters[idx].values = []
                return tmp
            })
        }

    const getFieldValues = (field: string) => {
        const dataSchemaItem = nodeDataSchema.fields[field]
        if (dataSchemaItem == null) return []
        switch (dataSchemaItem.type) {
            case 'date':
                return []
            case 'number':
                return dataSchemaItem.range.values
            case 'string':
                return dataSchemaItem.range
        }
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Analytics Settings</DialogTitle>
            <DialogContent sx={{ minHeight: '10vh' }}>
                <Stack gap={1}>
                    <Stack gap={1} direction="row">
                        <BaseSelectWithLabel
                            label="Network Filed"
                            fullWidth
                            value={localData.groupBy}
                            onChange={updateNetworkGroupBy}
                            options={edgeDataSchema.groupByOptions}
                        />

                        <BaseSelectWithLabel
                            label="Network"
                            fullWidth
                            disabled={localData.groupBy === ''}
                            value={network}
                            onChange={(value) => setNetwork(value)}
                            options={Object.keys(localData.networks)}
                        />
                    </Stack>

                    {localData.networks[network] ? (
                        <>
                            <BaseSelectWithLabel
                                size="small"
                                label="Type of relationship"
                                value={localData.networks[network].relationType}
                                onChange={updateRelationType}
                                options={['positive', 'negative', 'neutral']}
                                fullWidth
                            />
                            <StyledWidgetAccordion title="Which nodes are invovled in this network">
                                {/* Filters header
                                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                >
                                    <Typography fontSize={14} fontWeight={500}>
                                        Filters
                                    </Typography>

                                    <BaseButton
                                        label="Add Filter"
                                        onClick={(evt) => addFilterItems()}
                                        startIcon={<AddIcon />}
                                    />
                                </Stack>

                                {/* Filters list
                                                    ========================================= */}
                                <Stack gap={1}>
                                    {localData.networks[network].nodeFilters.map((filter, filterIdx) => (
                                        /*  Filter item
                                                            ========================================= */
                                        <Stack key={filterIdx} direction="row" alignItems="center" gap={1}>
                                            {/* Field select
                                                                ========================================= */}
                                            <BaseSelectWithLabel
                                                label="Field"
                                                options={Object.keys(nodeDataSchema.fields)}
                                                value={filter.field}
                                                onChange={updateFilterItems(filterIdx, 'field')}
                                                fullWidth
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            />

                                            {/* Value select
                                                                ========================================= */}
                                            <BaseSelectWithLabel
                                                label="Value"
                                                options={getFieldValues(filter.field)}
                                                value={filter.values}
                                                onChange={updateFilterItems(filterIdx, 'values')}
                                                selectProps={{
                                                    multiple: true,
                                                }}
                                                fullWidth
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            />

                                            {/* Remove button
                                                                ========================================= */}
                                            <Tooltip title="Remove filter">
                                                <IconButton
                                                    onClick={removeFilterItems(filterIdx)}
                                                    sx={(theme) => ({
                                                        flexShrink: 0,

                                                        color: theme.palette.common.ung_pink,
                                                    })}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    ))}
                                </Stack>
                            </StyledWidgetAccordion>
                        </>
                    ) : (
                        <Typography>Please select a Network</Typography>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton color="secondary" onClick={onClose}>
                    Close
                </BaseButton>
                <BaseButton variant="contained" color="primary" onClick={saveSettings}>
                    Save and Run
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default AnalyticsSettings
