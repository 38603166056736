//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useHotkeys } from 'react-hotkeys-hook'
import { Stack, Box, IconButton, Typography, Tooltip, LinearProgress, Menu, MenuItem } from '@mui/material'
//* ======= Components and features
import ReportSlideContainer from 'features/report-designer/layout-containers/ReportSlideContainer'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, {
    ReportDesignerStoreStateType,
    useReportActiveSlide,
    useReportSlideOnboarding,
} from 'features/report-designer/store/reportDesignerStore'
//* ======= Assets and styles
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import SNALogo from 'assets/images/sna-toolbox-logo.png'
import { HelpButton } from './ReportView_Temp'
import { ReportTourType } from '../types/reportDesigner.types'

type Props = {
    onExitFullscreen: () => void
}

function ReportViewFullscreen({ onExitFullscreen }: Props) {
    const { slides, activeSlideId, setActiveSlideId } = useReportStore((store) => ({
        slides: store.slides,
        activeSlideId: store.activeSlideId,
        setActiveSlideId: store.setActiveSlideId,
    }))

    const activeSlide = useReportActiveSlide()

    const [slidesMenu, setSlidesMenu] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    const currentSlideSummary = useMemo(() => {
        if (activeSlide !== null) {
            const positionNumber = slides.findIndex((_slide) => _slide.id === activeSlide.id) + 1

            return {
                number: positionNumber,
                title: activeSlide.title,
            }
        } else {
            return {
                number: 0,
                title: '',
            }
        }
    }, [slides, activeSlide])

    // const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false)
    // const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)

    const navigateSlides = (step: 1 | -1) => {
        const activeSlideIndex = slides.findIndex((_slide) => _slide.id === activeSlideId)

        if (activeSlideIndex === -1) {
            return
        }

        let newActiveSlideId: ReportDesignerStoreStateType['activeSlideId'] = null

        if (step === 1) {
            if (activeSlideIndex !== slides.length - 1) {
                newActiveSlideId = slides[activeSlideIndex + 1].id
            }
        } else if (step === -1) {
            if (activeSlideIndex !== 0) {
                newActiveSlideId = slides[activeSlideIndex - 1].id
            }
        }

        if (newActiveSlideId === null) {
            return
        }

        setActiveSlideId({
            slideId: newActiveSlideId,
        })
    }

    // Hotkeys for slide navigation
    useHotkeys(
        'up, left',
        () => {
            navigateSlides(-1)
        },
        [slides, activeSlideId]
    )
    useHotkeys(
        'down, right',
        () => {
            navigateSlides(1)
        },
        [slides, activeSlideId]
    )

    const calculatePreviewProgress = () => {
        if (activeSlide !== null) {
            return Math.round((currentSlideSummary.number * 100) / slides.length)
        } else {
            return 0
        }
    }

    return (
        <Stack
            sx={(theme) => ({
                height: '100%',

                // @Theme conditional
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.bg_4 : theme.palette.common.bg_1,
            })}
        >
            {/* Slide
                ========================================= */}
            <Box
                gap={3}
                sx={(theme) => ({
                    flexGrow: 1,

                    width: '100%',
                    minHeight: 0,
                })}
            >
                <DndProvider backend={HTML5Backend}>
                    <ReportSlideContainer />
                </DndProvider>
            </Box>

            {/* Bottom controls
                ========================================= */}
            <Stack
                sx={(theme) => ({
                    flexShrink: 0,

                    width: '100%',

                    backgroundColor: theme.palette.common.bg_3,
                })}
            >
                {/* Progress bar
                    ========================================= */}
                <LinearProgress variant="determinate" value={calculatePreviewProgress()} />

                {/* <Slider
                    sx={{
                        width: '98vw',
                        margin: 'auto',
                    }}
                    marks
                    onChange={(e, v) => {
                        if (typeof v === 'number') {
                            const slideId = contextState.slides[v - 1]?.id
                            if (slideId) {
                                contextDispatch({
                                    type: 'REPORT_SET_ACTIVE_SLIDE',
                                    payload: slideId,
                                })
                            }
                        }
                    }}
                    value={currentSlideSummary.number}
                    min={1}
                    step={1}
                    max={contextState.slides.length}
                    aria-label="report seek bar"
                    getAriaValueText={(v) => contextState.slides[v - 1]?.title}
                    valueLabelDisplay="auto"
                /> */}

                {/* Slide controls
                    ========================================= */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1.5}
                    sx={(theme) => ({
                        padding: theme.spacing(1.5),
                        backgroundColor: theme.palette.common.bg_3,
                    })}
                >
                    {/* Slide navigation controls
                        ========================================= */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1.5}
                        sx={(theme) => ({})}
                        id="fullscreen-navigation"
                    >
                        <Box className="slide-navigation">
                            {/* Previous slide button
                            ========================================= */}
                            <IconButton
                                onClick={(evt) => navigateSlides(-1)}
                                disabled={currentSlideSummary.number === 1}
                                sx={(theme) => ({
                                    border: `1px solid ${theme.palette.common.border_2}`,

                                    '&.Mui-disabled': {
                                        borderColor: 'transparent',
                                    },
                                })}
                            >
                                <ChevronLeftIcon
                                    sx={{
                                        width: 32,
                                        height: 32,
                                    }}
                                />
                            </IconButton>

                            {/* Next slide button
                            ========================================= */}
                            <IconButton
                                onClick={(evt) => navigateSlides(1)}
                                disabled={currentSlideSummary.number === slides.length}
                                sx={(theme) => ({
                                    border: `1px solid ${theme.palette.common.border_2}`,

                                    '&.Mui-disabled': {
                                        borderColor: 'transparent',
                                    },
                                })}
                            >
                                <ChevronLeftIcon
                                    sx={{
                                        width: 32,
                                        height: 32,

                                        transform: 'rotate(180deg)',
                                    }}
                                />
                            </IconButton>
                        </Box>
                        {/* Current slide title and menu button
                            ========================================= */}
                        <BaseButton
                            id="slides-menu-button"
                            label={currentSlideSummary.number + '. ' + (currentSlideSummary.title || 'Untitled Slide')}
                            onClick={(evt) => setSlidesMenu({ isOpen: true, anchorElement: evt.currentTarget })}
                            variant="text"
                            color="secondary"
                            sx={(theme) => ({
                                padding: theme.spacing(1, 3),
                                fontSize: 16,

                                color: theme.palette.common.text_1,

                                '&:hover': {
                                    color: theme.palette.common.text_1,
                                },
                            })}
                        />

                        {/* (Out-of-flow) Slides menu
                            ========================================= */}
                        <Menu
                            anchorEl={slidesMenu.anchorElement}
                            open={slidesMenu.isOpen}
                            onClose={() => setSlidesMenu((prevState) => ({ ...prevState, isOpen: false }))}
                            disablePortal
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'slides-menu-button',
                            }}
                            slotProps={{
                                paper: {
                                    sx: (theme) => ({
                                        width: 260,
                                        maxHeight: 320,

                                        backgroundImage: 'unset',
                                        // @Theme conditional
                                        backgroundColor:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.common.bg_4
                                                : theme.palette.common.bg_1,
                                    }),
                                    className: 'u-scrollbar',
                                },
                            }}
                        >
                            {slides.map((_slide, idx) => (
                                <MenuItem
                                    key={_slide.id}
                                    onClick={(evt) => {
                                        setActiveSlideId({
                                            slideId: _slide.id,
                                        })

                                        setSlidesMenu((prevState) => ({ ...prevState, isOpen: false }))
                                    }}
                                    sx={{
                                        paddingY: 1,
                                    }}
                                >
                                    <Typography
                                        fontSize={14}
                                        fontWeight={600}
                                        color={activeSlide?.id === _slide.id ? 'primary' : 'common.text_3'}
                                        sx={{
                                            flex: '0 0 auto',

                                            minWidth: '3ch',
                                        }}
                                    >
                                        {idx + 1 + '.'}
                                    </Typography>

                                    <Typography
                                        fontSize={14}
                                        fontWeight={500}
                                        noWrap
                                        textAlign="left"
                                        color={activeSlide?.id === _slide.id ? 'primary' : undefined}
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        {_slide.title || 'Untitled Slide'}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Stack>

                    {/* Right side
                        ========================================= */}
                    <Stack direction="row" alignItems="center" gap={2.5}>
                        <HelpButton />

                        {/* Logo and dialog buttons
                            ========================================= */}
                        <Stack
                            sx={{
                                marginRight: 4,
                            }}
                        >
                            <img
                                src={SNALogo}
                                alt="SNA logo"
                                style={{
                                    height: 30,
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                }}
                            />

                            <Typography variant="caption" textAlign="center">
                                Powered by SNA Toolbox
                            </Typography>
                        </Stack>

                        {/* Slides progress text
                            ========================================= */}
                        <Typography>{currentSlideSummary.number + ' of ' + slides.length}</Typography>

                        {/* Exit fullscreen button
                            ========================================= */}
                        <Tooltip
                            title="Exit Fullscreen"
                            PopperProps={{
                                disablePortal: true,
                            }}
                            arrow
                            placement="top"
                        >
                            <IconButton className="fullscreen-toggle-button" onClick={(evt) => onExitFullscreen()}>
                                <FullscreenExitIcon
                                    sx={{
                                        width: 32,
                                        height: 32,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>

            {/* Onboarding
                            ========================================= */}
            {/* <ReportSlideOnboarding
                activeSlide={activeSlide}
                showOnboarding={showOnboarding}
                setShowOnboarding={setShowOnboarding}
            /> */}
        </Stack>
    )
}

export default ReportViewFullscreen
