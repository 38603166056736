import React, { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
    Button,
    DialogContent,
    Typography,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Divider,
    Box,
} from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import ChiSquareConfig, { DefaultChiSquareConfig } from './Configs/ChiSquareConfig'
import TTestConfig, { DefaultTTestConfig } from './Configs/TTestConfig'
import AnovaConfig, { DefaultAnovaConfig } from './Configs/AnovaConfig'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { NODE_ANALYTICS_METRICS } from 'features/network-viz/constants/NetworkViz.const'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
import {
    AnovaConfigType,
    ChiSquareConfigType,
    TTestConfigType,
} from 'features/network-viz/types/NetworkViz.types'
import { useParams } from 'react-router'

// Types for different types of statistical tests
type BaseStatisticalTestType = {
    id: string
    title: string
    network: string
    testType: 't_test' | 'chi_square' | 'anova'
}

export type StatisticalTestType =
    | (BaseStatisticalTestType & {
          testType: 't_test'
          config: TTestConfigType
          result?: {
              effect_size: number
              p_val: number
              t_stat: number
          }
      })
    | (BaseStatisticalTestType & {
          testType: 'chi_square'
          config: ChiSquareConfigType
          result?: {
              chi2: number
              p: number
              dof: number
              expected: Array<Array<number>>
              crosstab: Record<string, Record<string, number>>
          }
      })
    | (BaseStatisticalTestType & {
          testType: 'anova'
          config: AnovaConfigType
          result?: {
              f_value: number
              p_value: number
          }
      })

type TestType = StatisticalTestType['testType']

interface StatisticalTestsDialogProps {
    onClose: () => void
}

const StatisticalTestsDialog: React.FC<StatisticalTestsDialogProps> = ({ onClose }) => {
    // Using network visualization context
    const { nodes, analytics, statisticalTests: statsitclTests, nodeDataSchema } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()
    const { pid, wid } = useParams()

    // Creating options for tests
    const numericOptions = useMemo<{ label: string; value: any; section?: boolean }[]>(() => {
        return [
            { label: 'Node Analytics', value: 'Node Analytics', section: true },
            ...NODE_ANALYTICS_METRICS.map((option) => ({ ...option, value: 'analytic_' + option.value })),
            { label: 'Node Attributes', value: 'Node Attributes', section: true },
            ...nodeDataSchema.numericOptions.map((option) => ({
                label: option,
                value: 'attribute_' + option,
            })),
        ]
    }, [nodeDataSchema])

    const allOptions = useMemo<{ label: string; value: any; section?: boolean }[]>(() => {
        return [
            { label: 'Node Analytics', value: 'Node Analytics', section: true },
            ...NODE_ANALYTICS_METRICS.map((option) => ({ ...option, value: 'analytic_' + option.value })),
            { label: 'Node Attributes', value: 'Node Attributes', section: true },
            ...Object.keys(nodeDataSchema.fields).map((option) => ({
                label: option,
                value: 'attribute_' + option,
            })),
        ]
    }, [nodeDataSchema])

    // States for tests and current test
    const [tests, setTests] = useState<StatisticalTestType[]>(structuredClone(statsitclTests ?? []))
    const [currentTest, setCurrentTest] = useState<StatisticalTestType | null>(null)
    const [deleteConfirmId, setDeleteConfirmId] = useState<string | null>(null)

    // Handlers for adding, removing, modifying, and saving tests
    const handleAddTest = () =>
        setCurrentTest({ id: '', title: '', network: 'all', testType: 't_test', config: DefaultTTestConfig })
    const handleRemoveTest = (id: string) => setTests(tests.filter((test) => test.id !== id))
    const handleModifyTest = (index: number) => setCurrentTest(tests[index])

    const handleSaveTest = (updatedTest: StatisticalTestType) => {
        if (updatedTest.id === '') {
            updatedTest.id = uuidv4()
            setTests([...tests, updatedTest])
        } else {
            const updatedTests = tests.map((test) => (test.id === updatedTest.id ? updatedTest : test))
            setTests(updatedTests)
        }
        setCurrentTest(null)
    }

    const updateTTestConfig = (config: Partial<TTestConfigType>) => {
        if (currentTest === null) {
            return
        }
        setCurrentTest((pv) => {
            if (pv === null || pv.testType !== 't_test') return pv
            return { ...pv, config: { ...pv.config, ...config } }
        })
    }

    const updateChiSquareConfig = (config: Partial<ChiSquareConfigType>) => {
        if (currentTest === null) {
            return
        }
        setCurrentTest((pv) => {
            if (pv === null || pv.testType !== 'chi_square') return pv
            return { ...pv, config: { ...pv.config, ...config } }
        })
    }

    const updateAnovaConfig = (config: Partial<AnovaConfigType>) => {
        if (currentTest === null) {
            return
        }
        setCurrentTest((pv) => {
            if (pv === null || pv.testType !== 'anova') return pv
            return { ...pv, config: { ...pv.config, ...config } }
        })
    }

    const getColumnData = (column: string, network: string) => {
        const splitIndex = column.indexOf('_')
        let mode = column.substring(0, splitIndex)
        let field = column.substring(splitIndex + 1)
        // Sort node IDs to ensure consistent order
        let sortedNodeIds = Object.keys(nodes).sort()
        if (mode === 'analytic' && analytics?.[network]) {
            // Return data in consistent order according to sorted node IDs
            return sortedNodeIds.map((nodeId) => analytics[network].nodes[nodeId][field])
        } else if (mode === 'attribute') {
            // Return data in consistent order according to sorted node IDs
            return sortedNodeIds.map((nodeId) => nodes[nodeId][field])
        }
    }

    const runTest = async () => {
        const test = currentTest
        if (test == null) return
        const { testType, config } = test
        const apiUrl = `${process.env.REACT_APP_API_URL}/analytics/stat_test`
        switch (testType) {
            case 't_test':
                {
                    const tTestConfig = config as TTestConfigType
                    const { group1, group2 } = tTestConfig
                    const group1Data = getColumnData(group1, test.network)
                    const group2Data = getColumnData(group2, test.network)
                    const response = await fetch(apiUrl, {
                        body: JSON.stringify({
                            type: 't_test',
                            data: { group1: group1Data, group2: group2Data },
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        credentials: 'include',
                    })
                    if (response.ok) {
                        const data = await response.json()
                        setCurrentTest((pv) => ({ ...pv!, result: data }))
                        console.log(data)
                    } else {
                        console.log('Error')
                        console.log(response)
                    }
                }
                break
            case 'chi_square':
                {
                    const tTestConfig = config as ChiSquareConfigType
                    const { group1, group2 } = tTestConfig
                    const group1Data = getColumnData(group1.field, test.network)
                    const group2Data = getColumnData(group2.field, test.network)
                    const response = await fetch(apiUrl, {
                        body: JSON.stringify({
                            type: 'chi_square',
                            data: {
                                group1: group1Data,
                                group2: group2Data,
                                bins: [group1.bins || -1, group2.bins || -1],
                            },
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        credentials: 'include',
                    })
                    if (response.ok) {
                        const data = await response.json()
                        setCurrentTest((pv) => ({ ...pv!, result: data }))
                        console.log(data)
                    } else {
                        console.log('Error')
                        console.log(response)
                    }
                }
                break
            case 'anova':
                {
                    const tTestConfig = config as AnovaConfigType
                    const { groups } = tTestConfig
                    const groupData = groups.map((group) => getColumnData(group.field, test.network))
                    const response = await fetch(apiUrl, {
                        body: JSON.stringify({
                            type: 'anova',
                            data: groupData,
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        credentials: 'include',
                    })
                    if (response.ok) {
                        const data = await response.json()
                        setCurrentTest((pv) => ({ ...pv!, result: data }))
                        console.log(data)
                    } else {
                        console.log('Error')
                        console.log(response)
                    }
                }
                break
            default:
                return
        }
    }

    // Create a memo for test validation
    const isTestValid = useMemo(() => {
        if (!currentTest) return false
        if (currentTest.title.trim() === '') return false
        switch (currentTest.testType) {
            case 't_test':
                if (
                    currentTest.config.group1 === '' ||
                    currentTest.config.group2 === '' ||
                    currentTest.config.group1 === currentTest.config.group2
                )
                    return false
                break
            case 'chi_square':
                break
            case 'anova':
                break
            default:
                return false
        }
        return true
    }, [currentTest])

    const saveToContext = () => {
        dispatchContext({ type: 'STATISTICAL_TEST_UPDATE', payload: tests })
        onClose()
    }

    return (
        <React.Fragment>
            {/* List of tests */}
            {currentTest === null && (
                <>
                    <DialogContent>
                        <Typography variant="h6">Statistical Tests</Typography>
                        <Typography>Here you can create, modify, or remove statistical tests.</Typography>
                        {/* Display the list of tests */}
                        <List>
                            {tests.map((test, index) => (
                                <ListItem key={test.id}>
                                    <ListItemText
                                        primary={test.title}
                                        secondary={`Test Type: ${test.testType}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="modify"
                                            onClick={() => handleModifyTest(index)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        {deleteConfirmId === test.id ? (
                                            <Button
                                                color="secondary"
                                                onClick={() => handleRemoveTest(test.id)}
                                            >
                                                Confirm Delete
                                            </Button>
                                        ) : (
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => setDeleteConfirmId(test.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        {/* Add new test button */}
                        <Button onClick={handleAddTest}>Add New Test</Button>
                    </DialogContent>
                    <DialogActions>
                        <BaseButton color="secondary" onClick={onClose}>
                            Close
                        </BaseButton>
                        <BaseButton color="primary" variant="contained" onClick={saveToContext}>
                            Save
                        </BaseButton>
                    </DialogActions>
                </>
            )}

            {/* Test details */}
            {currentTest !== null && (
                <>
                    <DialogContent>
                        {/* Render the form for creating/modifying a test */}
                        <Grid2 container>
                            <Grid2 xs={12} p={1}>
                                <BaseFilledTextField
                                    fullWidth
                                    label="Test Title"
                                    value={currentTest.title}
                                    onChange={(e) => {
                                        setCurrentTest({ ...currentTest, title: e.target.value })
                                    }}
                                />
                            </Grid2>
                            <Grid2 xs={12} md={6} p={1}>
                                <BaseSelectWithLabel
                                    fullWidth
                                    label="Network"
                                    value={currentTest.network}
                                    onChange={(network) => setCurrentTest({ ...currentTest, network })}
                                    options={Object.keys(analytics || {})}
                                />
                            </Grid2>
                            <Grid2 xs={12} md={6} p={1}>
                                <BaseSelectWithLabel
                                    fullWidth
                                    label="Test Type"
                                    value={currentTest.testType}
                                    onChange={(testType: TestType) => {
                                        switch (testType) {
                                            case 't_test':
                                                setCurrentTest({
                                                    ...currentTest,
                                                    testType: 't_test',
                                                    config: DefaultTTestConfig,
                                                    result: undefined,
                                                })
                                                break
                                            case 'anova':
                                                setCurrentTest({
                                                    ...currentTest,
                                                    testType: 'anova',
                                                    config: DefaultAnovaConfig,
                                                    result: undefined,
                                                })
                                                break
                                            case 'chi_square':
                                                setCurrentTest({
                                                    ...currentTest,
                                                    testType: 'chi_square',
                                                    config: DefaultChiSquareConfig,
                                                    result: undefined,
                                                })
                                        }
                                    }}
                                    options={['t_test', 'chi_square', 'anova']}
                                />
                            </Grid2>

                            {/* Render the relevant configs based on the test type */}
                            {currentTest.testType === 't_test' && (
                                <TTestConfig
                                    config={currentTest.config}
                                    options={numericOptions}
                                    onUpdate={updateTTestConfig}
                                />
                            )}
                            {currentTest.testType === 'chi_square' && (
                                <ChiSquareConfig
                                    config={currentTest.config}
                                    nodeDataSchema={nodeDataSchema}
                                    options={allOptions}
                                    onUpdate={updateChiSquareConfig}
                                />
                            )}
                            {currentTest.testType === 'anova' && (
                                <AnovaConfig
                                    config={currentTest.config}
                                    onUpdate={updateAnovaConfig}
                                    nodeDataSchema={nodeDataSchema}
                                    options={numericOptions}
                                />
                            )}
                        </Grid2>
                        <Grid2 xs={12} md={6} p={1}>
                            {currentTest.result && currentTest.testType === 't_test' && (
                                <div>
                                    <Typography variant="h5">T-Test Results:</Typography>
                                    <Typography>
                                        H0 (Null Hypothesis): The mean of {currentTest.config.group1} is equal
                                        to the mean of {currentTest.config.group2}
                                    </Typography>
                                    <Typography>Effect size: {currentTest.result.effect_size}</Typography>
                                    <Typography>P-value: {currentTest.result.p_val}</Typography>
                                    <Typography>T-Stat: {currentTest.result.t_stat}</Typography>
                                    <Typography
                                        color={currentTest.result.p_val < 0.05 ? 'error' : 'textSecondary'}
                                    >
                                        {currentTest.result.p_val < 0.05
                                            ? 'The result is significant at p < 0.05. There is evidence to reject the null hypothesis.'
                                            : 'The result is not significant at p < 0.05. There is not enough evidence to reject the null hypothesis.'}
                                    </Typography>
                                </div>
                            )}
                            {currentTest.result && currentTest.testType === 'chi_square' && (
                                <Box>
                                    <Typography variant="h6">Crosstab:</Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {Object.keys(currentTest.result.crosstab).map((key) => (
                                                    <TableCell key={key}>{key}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(
                                                currentTest.result.crosstab[
                                                    Object.keys(currentTest.result.crosstab)[0]
                                                ]
                                            ).map((rowKey) => (
                                                <TableRow key={rowKey}>
                                                    <TableCell>{rowKey}</TableCell>
                                                    {Object.keys(currentTest.result?.crosstab || {}).map(
                                                        (key) => (
                                                            <TableCell key={key}>
                                                                {currentTest.result?.crosstab[key][rowKey]}
                                                            </TableCell>
                                                        )
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Divider />
                                    <Typography variant="h5">Chi-Square Test Results:</Typography>
                                    <Typography>Chi-square statistic: {currentTest.result.chi2}</Typography>
                                    <Typography>P-value: {currentTest.result.p}</Typography>
                                    <Typography>Degrees of freedom: {currentTest.result.dof}</Typography>
                                    <Typography
                                        color={currentTest.result.p < 0.05 ? 'error' : 'textSecondary'}
                                    >
                                        {currentTest.result.p < 0.05
                                            ? 'The result is significant at p < 0.05. The observed distribution is significantly different from the expected distribution.'
                                            : 'The result is not significant at p < 0.05. The observed distribution is not significantly different from the expected distribution.'}
                                    </Typography>
                                </Box>
                            )}
                            {currentTest.result && currentTest.testType === 'anova' && (
                                <div>
                                    <Typography variant="h5">ANOVA Test Results:</Typography>
                                    <Typography>F-value: {currentTest.result.f_value}</Typography>
                                    <Typography>P-value: {currentTest.result.p_value}</Typography>
                                    <Typography
                                        color={currentTest.result.p_value < 0.05 ? 'error' : 'textSecondary'}
                                    >
                                        {currentTest.result.p_value < 0.05
                                            ? 'The result is significant at p < 0.05. At least one group mean is significantly different from others.'
                                            : 'The result is not significant at p < 0.05. No significant difference between group means.'}
                                    </Typography>
                                </div>
                            )}
                        </Grid2>
                    </DialogContent>
                    <DialogActions>
                        <BaseButton color="warning" onClick={() => setCurrentTest(null)}>
                            Discard
                        </BaseButton>
                        <BaseButton
                            color="primary"
                            variant="outlined"
                            disabled={!isTestValid}
                            onClick={runTest}
                        >
                            Run Test
                        </BaseButton>
                        <BaseButton
                            color="primary"
                            variant="contained"
                            disabled={!isTestValid}
                            onClick={() => handleSaveTest(currentTest)}
                        >
                            Save
                        </BaseButton>
                    </DialogActions>
                </>
            )}
        </React.Fragment>
    )
}

export default StatisticalTestsDialog
