//* ======= Libraries
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
//* ======= Components and features
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import TemplateCard from 'components/template-card/TemplateCard'
//* ======= Custom logic
import { CreateSurveyRequestType, CreateSurveysService } from 'services/SurveyService'
//* ======= Assets and styles
import { useParams } from 'react-router-dom'

interface ICreateSurveyDialog {
    onConfirm?: (id: number) => void
    onClose?: () => void
}

function CreateSurveyDialog({ onConfirm, onClose }: ICreateSurveyDialog) {
    const { pid } = useParams()

    const selectOptions = [
        {
            name: 'Fixed population',
            value: 'fixed',
        },
        {
            name: 'Snow Ball',
            value: 'snowball',
        },
    ]

    //form
    const {
        control,
        register,
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateSurveyRequestType>()

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const createSurvey = () => {
        if (!pid) return
        handleSubmit(
            //Valid
            (data) => {
                setIsLoading(true)
                CreateSurveysService(Number.parseInt(pid), data)
                    .then((res) => {
                        if (res.success && onConfirm && res.data) {
                            onConfirm(res.data)
                        }
                    })
                    .catch((e) => {})
                    .finally(() => {
                        setIsLoading(false)
                    })
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    // const setImage = (data: string) => {
    //     setValue('thumbnail', data);
    // }

    return (
        <Grid container>
            {/* Sidebar
				========================================= */}
            <Grid
                item
                xs={3}
                zeroMinWidth
                container
                direction="column"
                alignItems="flex-start"
                rowGap={2}
                sx={(theme) => ({
                    padding: 3,

                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.common.ung_blue_one : theme.palette.common.bg_4,
                })}
            >
                <Typography fontSize={18} fontWeight={600} color="secondary">
                    Create new survey
                </Typography>

                {/* Template card
				    ========================================= */}
                <TemplateCard
                    data={{
                        id: -1,
                        title: watch('title'),
                        // image: watch("thumbnail")
                    }}
                    hasMenuOnBoxed={false}
                />

                {/* Add profile image button
				    ========================================= */}
                <BaseButton
                    variant="outlined"
                    onClick={() => {
                        document.getElementById('select-profile-image')?.click()
                    }}
                >
                    Upload Image
                </BaseButton>
                {/* <PickCustomImage setCustomImage={setImage} circularCrop={false} /> */}
            </Grid>

            {/* Main content
				========================================= */}
            <Grid
                item
                xs={9}
                zeroMinWidth
                container
                direction="column"
                alignItems="flex-start"
                rowGap={2}
                sx={(theme) => ({
                    padding: 3,
                })}
            >
                {/* Survey title
				    ========================================= */}
                <Typography fontSize={16} fontWeight={400} color="secondary" mb={0.5}>
                    Survey Title
                </Typography>
                <Grid item zeroMinWidth container columnGap={6} mb={1.5}>
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="title"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseFilledTextField
                                    label="Survey Title"
                                    required
                                    variant="filled"
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{
                                required: 'Survey Title is required',
                                minLength: { value: 3, message: 'The title should be at least 3 characters' },
                                maxLength: {
                                    value: 50,
                                    message: 'The should not be more than 50 characters',
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Description and language
				    ========================================= */}
                <Grid item zeroMinWidth container columnGap={6}>
                    <Grid item zeroMinWidth xs>
                        <Typography fontSize={16} fontWeight={400} color="secondary">
                            Description
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item zeroMinWidth container columnGap={6}>
                    {/* Description
				        ========================================= */}
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseFilledTextField
                                    hiddenLabel
                                    multiline
                                    rows={6}
                                    variant="filled"
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                {/* Confirm and close buttons
				    ========================================= */}
                <Stack alignSelf="flex-end" direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                    <BaseButton
                        variant="outlined"
                        size="large"
                        label="Close"
                        onClick={(e) => onClose && onClose()}
                        sx={{
                            width: 180,
                            py: 1.5,
                        }}
                    />

                    <BaseButton
                        variant="contained"
                        size="large"
                        label="Create"
                        isLoading={isLoading}
                        onClick={() => createSurvey()}
                        // endIcon={<ChevronRightIcon />}
                        sx={{
                            width: 180,
                            py: 1.5,
                        }}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default CreateSurveyDialog
