//* ======= Libraries
import React, { useState, useEffect, Dispatch, SetStateAction, useMemo, useContext } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Fuse from 'fuse.js'
//* ======= Components and features
import ConfirmDialog from 'features/confirm-dialog/ConfirmDialog'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Tooltip from '@mui/material/Tooltip'
import BaseButton from 'components/base/BaseButton'
import TemplateCard from 'components/template-card/TemplateCard'
import SearchField from 'components/search-field/SearchField'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import { RootContext } from 'contexts/RootContext'
import {
    CreateProjectTag,
    DeleteProjectService,
    RemoveProjectTag,
    SearchProjectsService,
    TagType,
    UpdateProjectTitleService,
    toggleProjectFav,
} from 'services/ProjectService'
import {
    CreateSurveyTag,
    DeleteSurveyService,
    RemoveSurveyTag,
    SearchSurveysService,
    UpdateSurveyTitleService,
} from 'services/SurveyService'
import {
    CreateWidgetTag,
    DeleteWidget,
    RemoveWidgetTag,
    SearchWidgetsService,
    UpdateWidgetTitleService,
} from 'services/WidgetApi'
import {
    CloneReportService,
    CreateReportTag,
    DeleteReport,
    RemoveReportTag,
    SearchReportsService,
    UpdateReportTitleService,
} from 'services/ReportApi'
//* ======= Assets and styles
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ViewComfyIcon from '@mui/icons-material/ViewComfy'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import FolderByDialog from 'features/folder-by-dialog/FolderByDialog'
import { ResourceType } from 'types/resources'
import { useNavigate, useParams } from 'react-router-dom'
import TagEditorDialog from 'features/tag-managment-dialog/TagEditor'
import SortAZIcon from '@mui/icons-material/North'
import SortZAIcon from '@mui/icons-material/South'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import StyledDialog from 'components/dialog/StyledDialog'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import ToastHelper from 'helpers/ToastHelper'
import { DeleteAllocationService } from 'services/AllocationApi'
import { UpdateDashboardLayoutSettings } from 'services/UserService'
import { merge, set } from 'lodash'
import { DashboardLayoutSettingsType } from 'services/AuthenticationService'
import { PartialDeep } from 'type-fest'

interface IProjectsPanel {
    resources: ResourceType[]
    setResources: Dispatch<SetStateAction<ResourceType[]>>
    tagCategories: string[]
    resourceName: 'project' | 'survey' | 'report' | 'widget' | 'allocation'
    sortOptions?: BaseSelectWithLabelOptionType
    isLoading?: boolean
}

type pathsType = { category: string; value: any }

export type FolderType = {
    title: string
    category: string
    projectNumbers: number
    path: pathsType[]
}

let timeout: NodeJS.Timeout

const SearchAPIS = {
    project: SearchProjectsService,
    report: SearchReportsService,
    widget: SearchWidgetsService,
    survey: SearchSurveysService,
    // ToDo fix this
    allocation: SearchSurveysService,
}

const SearchPath = {
    project: 'project/',
    report: '',
    widget: '',
    survey: '',
    allocation: '',
}

function ProjectsPanel({
    resources,
    isLoading = false,
    setResources,
    tagCategories,
    resourceName,
    sortOptions,
}: IProjectsPanel) {
    const { pid } = useParams()
    const navigator = useNavigate()

    const theme = useTheme()
    const isViewportSmall = useMediaQuery(theme.breakpoints.down('xl'))

    //states
    const [groupedProjects, setGroupedProjects] = useState<ResourceType[]>([])
    const [filteredProjects, setFilteredProjects] = useState<ResourceType[]>([])
    const [folders, setFolders] = useState<FolderType[]>([])
    const [filteredFolders, setFilteredFolders] = useState<FolderType[]>([])
    const [searchQuery, setSearchQuery] = useState<string>()
    const [paths, setpaths] = useState<pathsType[]>([])
    const [deleteResource, setDeleteResource] = useState<ResourceType | null>(null)
    const [localTagCategories, setLocalTagCategories] = useState<string[]>(tagCategories)
    const [layoutSettings, setLayoutSettings] = useState<DashboardLayoutSettingsType>({
        sortBy: 'Created on',
        sortDirection: -1,
        folderStructure: [],
        isListView: false,
    })
    const openDeleteResourceDialog = (resource: ResourceType) => setDeleteResource(resource)
    const closeDeleteResourceDialog = () => setDeleteResource(null)

    const [renameResource, setRenameResource] = useState<ResourceType | null>(null)
    const [newTitle, setNewTitle] = useState<string>('')
    const openRenameResourceDialog = (resource: ResourceType) => setRenameResource(resource)
    const closeRenameResourceDialog = () => {
        setRenameResource(null)
        setNewTitle('')
    }

    const { setFavs, user, updateUser } = useContext(RootContext)

    useEffect(() => {
        setLocalTagCategories(tagCategories)
    }, [tagCategories])

    useEffect(() => {
        if (user) {
            let defaultValues = null
            switch (resourceName) {
                case 'project':
                    defaultValues = user.projectDashboardLayoutSettings
                    break
                case 'survey':
                    defaultValues = user.surveyDashboardLayoutSettings
                    break
                case 'report':
                    defaultValues = user.reportDashboardLayoutSettings
                    break
                case 'widget':
                    defaultValues = user.widgetDashboardLayoutSettings
                    break
            }
            if (defaultValues) {
                setLayoutSettings(merge(layoutSettings, defaultValues))
            }
        }
    }, [user, resourceName])

    //folder settings
    const [showFolderSettings, setShowFolderSettings] = useState<boolean>(false)
    const handleOpenFolderSettings = () => {
        setShowFolderSettings(true)
    }
    const handleCloseFolderSettings = () => {
        setShowFolderSettings(false)
    }

    //Tag editor
    const [showTagEditor, setShowTagEditor] = useState<{ open: boolean; resourceId: number | null }>({
        open: false,
        resourceId: null,
    })

    const handleOpenTagEditor = (projectId: number) => {
        setShowTagEditor({ open: true, resourceId: projectId })
    }
    const handleCloseTagEditor = () => {
        setShowTagEditor({ open: false, resourceId: null })
    }

    //return list of tags of a give project using its id
    const getProjectTags = (id: number) => {
        const index = filteredProjects.findIndex((fp) => fp.id == id)
        if (index !== -1) {
            return filteredProjects[index].tags || []
        }
        return []
    }

    const insertTag = (tag: TagType, onSuccess?: () => void) => {
        setResources((bp) => {
            const v = [...bp]
            const index = v.findIndex((v) => v.id === showTagEditor.resourceId)
            if (index !== -1) {
                v[index].tags?.push(tag)
            }
            return v
        })
        setLocalTagCategories((tc) => {
            const categories = structuredClone(tc)
            if (tag.key && categories.indexOf(tag.key) === -1) {
                return [...categories, tag.key]
            }
            return categories
        })
        onSuccess && onSuccess()
    }

    const handleAddTag = (tag: TagType, onSuccess?: () => void) => {
        if (showTagEditor.resourceId) {
            switch (resourceName) {
                case 'project':
                    CreateProjectTag(showTagEditor.resourceId, tag).then((res) => {
                        if (res.success) {
                            insertTag(
                                {
                                    ...tag,
                                    primaryKey: res.data.primaryKey,
                                },
                                onSuccess
                            )
                        }
                    })
                    return
                case 'survey':
                    if (pid)
                        CreateSurveyTag(pid, showTagEditor.resourceId, tag).then((res) => {
                            if (res.success) {
                                insertTag(
                                    {
                                        ...tag,
                                        primaryKey: res.data.primaryKey,
                                    },
                                    onSuccess
                                )
                            }
                        })
                    return
                case 'widget':
                    if (pid)
                        CreateWidgetTag(pid, showTagEditor.resourceId, tag).then((res) => {
                            if (res.success) {
                                insertTag({ ...tag, primaryKey: res.data.primaryKey }, onSuccess)
                            }
                        })
                    return
                case 'report':
                    if (pid)
                        CreateReportTag(pid, showTagEditor.resourceId, tag).then((res) => {
                            if (res.success) {
                                insertTag({ ...tag, primaryKey: res.data.primaryKey }, onSuccess)
                            }
                        })
                    return
            }
        }
    }

    const removeTag = (tagId: number, onSuccess?: () => void) => {
        setResources((bp) => {
            const v = [...bp]
            const index = v.findIndex((v) => v.id == showTagEditor.resourceId)
            if (index !== -1) {
                v[index].tags = v[index].tags?.filter((t) => t.primaryKey != tagId)
            }
            return v
        })
        onSuccess && onSuccess()
    }

    const handleRemoveTag = (tagId: number, onSuccess?: () => void) => {
        if (showTagEditor.resourceId && tagId) {
            switch (resourceName) {
                case 'project':
                    RemoveProjectTag(showTagEditor.resourceId, tagId).then((res) => {
                        if (res.success) {
                            removeTag(tagId, onSuccess)
                        }
                    })
                    return
                case 'survey':
                    if (pid)
                        RemoveSurveyTag(pid, showTagEditor.resourceId, tagId).then((res) => {
                            if (res.success) {
                                removeTag(tagId, onSuccess)
                            }
                        })
                    return
                case 'report':
                    if (pid)
                        RemoveReportTag(pid, showTagEditor.resourceId, tagId).then((res) => {
                            if (res.success) {
                                removeTag(tagId, onSuccess)
                            }
                        })
                    return
                case 'widget':
                    if (pid)
                        RemoveWidgetTag(pid, showTagEditor.resourceId, tagId).then((res) => {
                            if (res.success) {
                                removeTag(tagId, onSuccess)
                            }
                        })
                    return
            }
        }
    }

    const handleDeleteResource = async () => {
        //ToDo add force
        if (deleteResource) {
            let isDeleted = false
            const rid = deleteResource.id
            switch (resourceName) {
                case 'report':
                    if (pid) {
                        isDeleted = (await DeleteReport(pid, rid, true)).success
                    }
                    break
                case 'widget':
                    if (pid) {
                        isDeleted = (await DeleteWidget(pid, rid, true)).success
                    }
                    break
                case 'survey':
                    if (pid) {
                        isDeleted = (await DeleteSurveyService(pid, rid, true)).success
                    }
                    break
                case 'project':
                    isDeleted = (await DeleteProjectService(rid, true)).success
                    break
                case 'allocation':
                    isDeleted = (await DeleteAllocationService(pid!, rid, true)).success
                    break
            }

            if (isDeleted) {
                setResources((bp) => {
                    return bp.filter((r) => r.id !== rid)
                })
            }
        }
        closeDeleteResourceDialog()
    }

    const handleRenameResource = async () => {
        if (renameResource && newTitle.trim() !== '') {
            let isUpdated = false

            const toast = new ToastHelper({
                errorMessage: `Failed to rename ${resourceName}`,
                successMessage: `${resourceName} renamed successfully`,
                loadingMessage: `Renaming ${resourceName}`,
            })

            const resourceId = renameResource.id
            switch (resourceName) {
                case 'report':
                    if (pid) {
                        isUpdated = (await UpdateReportTitleService(pid, resourceId, { title: newTitle }, false))
                            .success
                    }
                    break
                case 'widget':
                    if (pid) {
                        isUpdated = (await UpdateWidgetTitleService(pid, resourceId, { title: newTitle }, false))
                            .success
                    }
                    break
                case 'survey':
                    if (pid) {
                        isUpdated = (await UpdateSurveyTitleService(pid, resourceId, { title: newTitle }, false))
                            .success
                    }
                    break
                case 'project':
                    isUpdated = (await UpdateProjectTitleService(resourceId, { title: newTitle }, false)).success
                    break
            }

            if (isUpdated) {
                toast.success()
                setResources((bp) => {
                    const v = [...bp]
                    const index = v.findIndex((v) => v.id === resourceId)
                    if (index !== -1) {
                        v[index].title = newTitle
                    }
                    return v
                })
            } else {
                toast.fail()
            }
        }
        closeRenameResourceDialog()
    }

    const handleCloneResource = async (resourceId: number) => {
        switch (resourceName) {
            case 'report':
                if (pid) {
                    const toast = new ToastHelper({
                        errorMessage: `Failed to clone ${resourceName}`,
                        successMessage: `${resourceName} cloned successfully`,
                        loadingMessage: `Cloning ${resourceName}`,
                    })
                    try {
                        const res = await CloneReportService(pid, resourceId)
                        if (res.success) {
                            setResources((prev) => {
                                const temp = [...prev]
                                return [
                                    ...temp,
                                    {
                                        id: res.data.primaryKey!,
                                        title: res.data.title,
                                        creator: res.data.createdBy,
                                        favourite: false,
                                        canRename: true,
                                        canDelete: true,
                                        canEditTag: true,
                                        link: `/project/${pid}/reports/${res.data.primaryKey}`,
                                    },
                                ]
                            })
                            toast.success()
                            return
                        }
                    } catch {}
                    toast.fail()
                }
                break
        }
    }

    const handleLayoutSettingsUpdate = async (settings: Partial<DashboardLayoutSettingsType>) => {
        const updatedSettings = { ...structuredClone(layoutSettings), ...settings }
        // update the local state
        setLayoutSettings((ls) => {
            const temp = { ...ls, ...settings }
            return temp
        })

        // update database and root context
        switch (resourceName) {
            case 'project':
                await UpdateDashboardLayoutSettings({ layoutSettings: updatedSettings, dashboardType: 'Project' })
                updateUser({ projectDashboardLayoutSettings: updatedSettings })
                break
            case 'survey':
                await UpdateDashboardLayoutSettings({ layoutSettings: updatedSettings, dashboardType: 'Survey' })
                updateUser({ surveyDashboardLayoutSettings: updatedSettings })
                break
            case 'report':
                await UpdateDashboardLayoutSettings({ layoutSettings: updatedSettings, dashboardType: 'Report' })
                updateUser({ reportDashboardLayoutSettings: updatedSettings })
                break
            case 'widget':
                await UpdateDashboardLayoutSettings({ layoutSettings: updatedSettings, dashboardType: 'Widget' })
                updateUser({ widgetDashboardLayoutSettings: updatedSettings })
                break
        }
    }

    // * effects

    //Filter and group projects
    useEffect(() => {
        let tmpProjects: ResourceType[] = []
        let resultProjects: ResourceType[] = []
        const resultFolders: { [key: string]: FolderType } = {}

        //filter by tag
        for (let project of resources) {
            let flag = true
            for (let path of paths) {
                if (path.value === null) continue
                if (project.tags?.findIndex((t) => t.key === path.category && t.value === path.value) === -1) {
                    flag = false
                    break
                }
            }
            if (flag) {
                tmpProjects.push(project)
            }
        }

        //group by
        if (layoutSettings.folderStructure[paths.length] != null) {
            for (let _project of tmpProjects) {
                let isGrouped = false
                for (let i = paths.length; i < layoutSettings.folderStructure.length; i++) {
                    const _groupBy = layoutSettings.folderStructure[i]
                    const tag = _project.tags?.find((t) => t.key === _groupBy)
                    if (tag !== undefined) {
                        if (resultFolders[tag.value]) {
                            resultFolders[tag.value].projectNumbers++
                        } else {
                            resultFolders[tag.value] = {
                                title: tag.value,
                                category: _groupBy,
                                projectNumbers: 1,
                                path: layoutSettings.folderStructure.slice(0, i + 1).map((f, index) => {
                                    return {
                                        category: f,
                                        value: index === i ? tag.value : null,
                                    }
                                }),
                            }
                        }
                        isGrouped = true
                        break
                    }
                }
                if (!isGrouped) {
                    resultProjects.push(_project)
                }
            }
        } else {
            resultProjects.push(...tmpProjects)
        }
        setGroupedProjects(resultProjects)
        setFolders(Object.values(resultFolders))
    }, [resources, layoutSettings.folderStructure, paths])

    //search
    useEffect(() => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
            if (searchQuery) {
                const tmpProjects: ResourceType[] = []
                const tempFolders: FolderType[] = []
                let fuse = new Fuse([...groupedProjects, ...folders], {
                    findAllMatches: true,
                    threshold: 0,
                    keys: ['primaryKey', 'title', 'description', 'tags.category', 'tags.value'],
                })
                const result = fuse.search(searchQuery)
                for (let item of result) {
                    if ((item.item as FolderType).projectNumbers) {
                        tempFolders.push(item.item as FolderType)
                    } else {
                        tmpProjects.push(item.item as ResourceType)
                    }
                }
                setFilteredProjects(tmpProjects)
                setFilteredFolders(tempFolders)
            } else {
                setFilteredProjects(groupedProjects)
                setFilteredFolders(folders)
            }
        }, 150)
    }, [searchQuery, folders, groupedProjects])

    // Sort
    const sortedResources = useMemo(() => {
        const projects = [...filteredProjects]
        return projects.sort((r1, r2) => {
            if (layoutSettings.sortBy == 'title') {
                if (r2.title && (!r1.title || r2.title > r1.title)) {
                    return layoutSettings.sortDirection
                }
            } else {
                const v1 = r1.info?.find((r) => r.label == layoutSettings.sortBy)?.value
                const v2 = r2.info?.find((r) => r.label == layoutSettings.sortBy)?.value
                if (v2 && (!v1 || v2 > v1)) {
                    return layoutSettings.sortDirection
                }
            }

            if (r1.favourite) return -1
            if (r2.favourite) {
                return 1
            }

            return -1 * layoutSettings.sortDirection
        })
    }, [filteredProjects, layoutSettings.sortBy, layoutSettings.sortDirection])
    // Actions
    const toggleFavourite = (id?: number) => {
        if (id) {
            switch (resourceName) {
                case 'project':
                    toggleProjectFav({ id }).then((res) => {
                        if (res.success) {
                            setResources((bp) => {
                                const v = [...bp]
                                const index = v.findIndex((v) => v.id == id)
                                if (index !== -1) {
                                    v[index].favourite = !v[index].favourite
                                    //update favs
                                    setFavs((favs) => {
                                        if (favs == null) return null
                                        const temp = [...favs]
                                        if (v[index].favourite) {
                                            temp.push({
                                                projectId: v[index].id,
                                                title: v[index].title,
                                                type: resourceName,
                                                thumbnail: v[index].image,
                                            })
                                            return temp
                                        } else {
                                            return temp.filter((f) => f.projectId != v[index].id)
                                        }
                                    })
                                }
                                return v
                            })
                        }
                    })
                    return
                case 'survey':
                    if (pid)
                        setResources((bp) => {
                            const v = [...bp]
                            const index = v.findIndex((v) => v.id == id)
                            if (index !== -1) {
                                v[index].favourite = !v[index].favourite

                                //update favs
                                setFavs((favs) => {
                                    if (favs == null) return null
                                    const temp = [...favs]
                                    if (v[index].favourite) {
                                        temp.push({
                                            projectId: parseInt(pid),
                                            title: v[index].title,
                                            type: resourceName,
                                            thumbnail: v[index].image,
                                            surveyId: v[index].id,
                                        })
                                        return temp
                                    } else {
                                        return temp.filter((f) => f.surveyId != v[index].id)
                                    }
                                })
                            }
                            return v
                        })
                    //    }
                    //  })
                    return
            }
        }
    }

    return (
        <Stack
            spacing={1}
            sx={(theme) => ({
                flexGrow: 1,

                height: 'auto',
                padding: theme.spacing(1.5, 2, 4, 4),

                // @Theme conditional
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
            })}
        >
            {isLoading ? (
                <Stack width="100%" height="100%" alignContent="center">
                    <div style={{ margin: 'auto' }}>
                        <SnaCircularLoading />
                    </div>
                </Stack>
            ) : (
                <React.Fragment>
                    {/* Title and toolbar
				========================================= */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        {/* Breadcrumb
					========================================= */}
                        <Breadcrumbs>
                            <Link
                                underline="hover"
                                color="primary"
                                onClick={() => setpaths([])}
                                sx={{
                                    cursor: 'pointer',
                                }}
                            >
                                All {resourceName}s
                            </Link>

                            {paths.map((p, index) => {
                                if (p.value === null) return null
                                return (
                                    <Link
                                        underline="hover"
                                        color="primary"
                                        onClick={() => setpaths((p) => p.slice(0, index + 1))}
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {p.value}
                                    </Link>
                                )
                            })}
                        </Breadcrumbs>

                        {/* Search
					========================================= */}
                        <SearchField
                            onSearch={(s) => setSearchQuery(s)}
                            placeholder="Search"
                            navigationPath={SearchPath[resourceName]}
                            searchApi={SearchAPIS[resourceName]}
                            sx={{
                                marginLeft: 'auto',
                                marginRight: 3,
                            }}
                        />

                        {/* Sort
					========================================= */}
                        {sortOptions && (
                            <>
                                <BaseSelectWithLabel
                                    label="Sort By"
                                    value={layoutSettings.sortBy}
                                    onChange={(v) => {
                                        handleLayoutSettingsUpdate({ sortBy: v })
                                    }}
                                    options={sortOptions}
                                    sx={{
                                        width: '200px',
                                        height: '48px',
                                        marginRight: 1,
                                    }}
                                />

                                <Tooltip
                                    title={
                                        'Sort Direction ' +
                                        (layoutSettings.sortDirection === 1 ? 'Low to High' : 'High to Low')
                                    }
                                >
                                    <IconButton
                                        onClick={() => {
                                            handleLayoutSettingsUpdate({
                                                sortDirection: layoutSettings.sortDirection === 1 ? -1 : 1,
                                            })
                                        }}
                                        sx={{
                                            height: '50px',
                                            width: '50px',

                                            borderRadius: '4px',
                                            color: 'common.fill_2',
                                        }}
                                    >
                                        {layoutSettings.sortDirection === 1 ? <SortAZIcon /> : <SortZAIcon />}
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}

                        {/* New folder button
					========================================= */}
                        <Tooltip title="Folder By">
                            <IconButton
                                sx={{
                                    height: '50px',
                                    width: '50px',

                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                                onClick={handleOpenFolderSettings}
                            >
                                <CreateNewFolderIcon />
                            </IconButton>
                        </Tooltip>
                        {/* Filter button
                        ToDo
					========================================= */}

                        {/* <IconButton
                            sx={{
                                height: '50px',
                                width: '50px',

                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <FilterAltIcon />
                        </IconButton> */}

                        {/* List view button
					========================================= */}
                        <Tooltip title={'List View'}>
                            <IconButton
                                onClick={() => handleLayoutSettingsUpdate({ isListView: true })}
                                sx={{
                                    height: '50px',
                                    width: '50px',

                                    borderRadius: '4px',
                                    color: layoutSettings.isListView ? 'primary.main' : 'common.fill_2',
                                }}
                            >
                                <FormatListBulletedIcon />
                            </IconButton>
                        </Tooltip>

                        {/* Grid view button
					========================================= */}
                        <Tooltip title={'Grid View'}>
                            <IconButton
                                onClick={() => {
                                    handleLayoutSettingsUpdate({ isListView: false })
                                }}
                                sx={{
                                    height: '50px',
                                    width: '50px',

                                    borderRadius: '4px',
                                    color: layoutSettings.isListView ? 'common.fill_2' : 'primary.main',
                                }}
                            >
                                <ViewComfyIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Items grid and Empty message
				========================================= */}
                    {resources.length === 0 ? (
                        /*  Empty list message
                                ========================================= */
                        <Stack
                            alignItems="center"
                            sx={{
                                height: '100%',
                            }}
                        >
                            <ContactSupportIcon sx={{ fontSize: '128px', color: '#185b8e' }} />

                            <Typography fontSize={18} fontWeight={400} color="common.text_1">
                                You have no {resourceName}s in your board yet.
                            </Typography>
                            <Typography fontSize={14} fontWeight={400} color="common.text_1" mb={2}>
                                Start from Create {resourceName} or tell us to create one for you.
                            </Typography>

                            {/* <Stack direction="row" alignItems="center" gap={2}>
                                <BaseButton label={'Create ' + resourceName} variant="outlined" size="large" />

                                <BaseButton label="Build for Me" variant="contained" size="large" />
                            </Stack> */}
                        </Stack>
                    ) : (filteredProjects && filteredProjects.length > 0) ||
                      (filteredFolders && filteredFolders.length > 0) ? (
                        /*  Items grid
                                ========================================= */
                        <Box
                            sx={{
                                minHeight: 0,

                                display: 'grid',
                                gridTemplateColumns: layoutSettings.isListView
                                    ? '1fr'
                                    : isViewportSmall
                                    ? 'repeat(4, 1fr)'
                                    : 'repeat(6, 1fr)',
                                gridAutoFlow: 'row',
                                columnGap: 2,
                                rowGap: 2,
                            }}
                        >
                            {filteredFolders.map((_folder, index) => {
                                return (
                                    <TemplateCard
                                        key={_folder.title + index}
                                        data={{
                                            id: -1,
                                            title: _folder.title,
                                            info: [
                                                {
                                                    label: resourceName + 's',
                                                    value: '# ' + _folder.projectNumbers,
                                                },
                                            ],
                                        }}
                                        isWideView={layoutSettings.isListView}
                                        hasMenuOnBoxed={true}
                                        isFolder={true}
                                        clickHandler={() => setpaths((pv) => [...pv, ..._folder.path])}
                                    />
                                )
                            })}

                            {sortedResources.map((_resource) => {
                                return (
                                    <TemplateCard
                                        key={_resource.id}
                                        data={_resource}
                                        isWideView={layoutSettings.isListView}
                                        clickHandler={() => _resource.link && navigator(_resource.link)}
                                        hasMenuOnBoxed={true}
                                        contextMenu={[
                                            {
                                                title: 'Tag Editor',
                                                action: () => handleOpenTagEditor(_resource.id),
                                            },
                                            {
                                                title: _resource.favourite ? 'Remove Favourite' : 'Mark as Favourite',
                                                action: () => toggleFavourite(_resource.id),
                                            },
                                            {
                                                title: 'Clone',
                                                action: () => handleCloneResource(_resource.id),
                                            },
                                            {
                                                title: 'Rename',
                                                action: () => openRenameResourceDialog(_resource),
                                            },
                                            {
                                                title: 'Delete',
                                                action: () => openDeleteResourceDialog(_resource),
                                            },
                                        ].filter((item) => {
                                            if (item.title === 'Tag Editor') {
                                                return _resource.canEditTag
                                            } else if (item.title === 'Delete') {
                                                return _resource.canDelete
                                            }
                                            return true
                                        })}
                                        boxedFloatingButtonProps={{
                                            Icon: _resource.favourite ? (
                                                <StarIcon color="primary" />
                                            ) : (
                                                <StarBorderIcon />
                                            ),
                                            onClick: () => toggleFavourite(_resource.id),
                                        }}
                                    />
                                )
                            })}
                        </Box>
                    ) : (
                        <></>
                    )}

                    {/* Dialogs 
                ========================================= */}
                    {showFolderSettings && (
                        <FolderByDialog
                            open={showFolderSettings}
                            onClose={handleCloseFolderSettings}
                            folderStructure={layoutSettings.folderStructure}
                            tags={localTagCategories}
                            setFolderStructure={(fs) => handleLayoutSettingsUpdate({ folderStructure: fs })}
                        />
                    )}

                    {deleteResource != null && (
                        <ConfirmDialog
                            open={deleteResource != null}
                            onClose={closeDeleteResourceDialog}
                            actionLabel="Delete"
                            closeLabel="Close"
                            content={`Are you sure you want to delete the ${resourceName} with following details?`}
                            secondaryContent={`Title: ${deleteResource.title} ID: ${deleteResource.id}`}
                            confirmMessage={deleteResource.title}
                            title={`Delete ${resourceName}: ${deleteResource.title}`}
                            onConfirm={handleDeleteResource}
                        />
                    )}

                    {renameResource != null && (
                        <StyledDialog
                            open={renameResource !== null}
                            onClose={closeRenameResourceDialog}
                            fullWidth
                            maxWidth="md"
                        >
                            <DialogTitle>Rename {resourceName}</DialogTitle>
                            <DialogContent>
                                <Stack spacing={2}>
                                    <Typography variant="body1">
                                        Enter the new title for the {resourceName}: {renameResource.title}
                                    </Typography>
                                    <BaseFilledTextField
                                        label="New Title"
                                        defaultValue={renameResource.title}
                                        onChange={(e) => setNewTitle(e.target.value)}
                                        fullWidth
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <BaseButton label="Cancel" onClick={closeRenameResourceDialog} />
                                <BaseButton
                                    label="Rename"
                                    disabled={newTitle.trim() === '' || newTitle.trim() === renameResource.title}
                                    onClick={handleRenameResource}
                                />
                            </DialogActions>
                        </StyledDialog>
                    )}

                    {showTagEditor.open && (
                        <TagEditorDialog
                            open={showTagEditor.open}
                            onClose={handleCloseTagEditor}
                            tagCategories={localTagCategories}
                            onAdd={handleAddTag}
                            onDelete={handleRemoveTag}
                            tags={showTagEditor.resourceId ? getProjectTags(showTagEditor.resourceId) : []}
                        />
                    )}
                </React.Fragment>
            )}
        </Stack>
    )
}

export default ProjectsPanel
