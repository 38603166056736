//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles

interface ISettingsLinkedAccounts {}

function SettingsLinkedAccounts({}: ISettingsLinkedAccounts) {
    return (
        <Stack gap={2} width="100%" maxWidth={500}>
            <Typography alignSelf="flex-start" fontSize={16} fontWeight={400}>
                We use these to let you sign in easily into your account:
            </Typography>

            {/* Google
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={10}>
                <BaseButton
                    label="Google"
                    variant="contained"
                    size="large"
                    sx={{
                        width: 176,

                        backgroundColor: '#4285F4',

                        '&:hover': {
                            backgroundColor: '#4285F4',
                        },
                    }}
                />

                <BaseButton label="Connect" variant="outlined" size="large" />
            </Stack>

            {/* Business email
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={10}>
                <BaseButton
                    label="Business Email"
                    variant="contained"
                    size="large"
                    sx={{
                        width: 176,

                        backgroundColor: '#217BBF',

                        '&:hover': {
                            backgroundColor: '#217BBF',
                        },
                    }}
                />

                <BaseButton label="Connect" variant="outlined" size="large" />
            </Stack>

            {/* Apple ID
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={10}>
                <BaseButton
                    label="Apple ID"
                    variant="contained"
                    size="large"
                    sx={{
                        width: 176,

                        backgroundColor: '#000000',

                        '&:hover': {
                            backgroundColor: '#000000',
                        },
                    }}
                />

                <BaseButton label="Connect" variant="outlined" size="large" />
            </Stack>

            {/* Slack
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={10}>
                <BaseButton
                    label="Slack"
                    variant="contained"
                    size="large"
                    sx={{
                        width: 176,

                        backgroundColor: '#611F69',

                        '&:hover': {
                            backgroundColor: '#611F69',
                        },
                    }}
                />

                <BaseButton label="Connect" variant="outlined" size="large" />
            </Stack>

            {/* Facebook
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={10}>
                <BaseButton
                    label="Facebook"
                    variant="contained"
                    size="large"
                    sx={{
                        width: 176,

                        backgroundColor: '#3B5998',

                        '&:hover': {
                            backgroundColor: '#3B5998',
                        },
                    }}
                />

                <BaseButton label="Connect" variant="outlined" size="large" />
            </Stack>

            {/* Microsoft
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={10}>
                <BaseButton
                    label="Microsoft"
                    variant="contained"
                    size="large"
                    sx={{
                        width: 176,

                        backgroundColor: '#EA3E23',

                        '&:hover': {
                            backgroundColor: '#EA3E23',
                        },
                    }}
                />

                <BaseButton label="Connect" variant="outlined" size="large" />
            </Stack>
        </Stack>
    )
}

export default SettingsLinkedAccounts
