//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { createWithEqualityFn } from 'zustand/traditional'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
//* ======= Components and features
//* ======= Custom logic
import {
    ReportMasterSettingsType,
    ReportLayoutSettingsType,
    ReportDataSourceType,
    ReportSlideType,
    ReportWidgetType,
    ReportCopySourceType,
    ReportTourType as ReportTourType,
    ReportWidgetOnboardingType,
} from 'features/report-designer/types/reportDesigner.types'
import { ReportCustomViewType, ReportShareType } from 'features/share-report-dialog/ShareReportDialog'
import reportStoreActionsCreator, {
    ReportDesignerStoreActionsType,
} from 'features/report-designer/store/reportDesignerActions'
import { StepType, useTour } from '@reactour/tour'
import { PartialDeep } from 'type-fest'
import { Difference } from 'microdiff'

//* ======= Assets and styles

type ReportDesignerBaseStoreStateType = {
    status: 'pending' | 'faulty' | 'ready'
    errorMessage?: string
    id: string | number
    title: string
    viewMode: 'design' | 'preview'
    aspectRatio: [number, number] // [width, height]
    masterSettings: ReportMasterSettingsType
    layoutSettings: ReportLayoutSettingsType
    slides: ReportSlideType[]
    dataSources: ReportDataSourceType[]
    activeSlideId: ReportSlideType['id'] | null
    widgetsToRender: ReportWidgetType['id'][]
    activeWidgetId: ReportWidgetType['id'] | null
    secondarySelectedWidgetIds: ReportWidgetType['id'][]
    copySource: ReportCopySourceType
    customViews: ReportCustomViewType[]
    reportAccess: ReportShareType[]
    accessToken: string | null
    tour: ReportTourType
}

export type ReportDesignerStoreStateType = ReportDesignerBaseStoreStateType & {
    history: {
        past: HistoryActionType[]
        future: HistoryActionType[]
    }
}

export type HistoryActionItemType =
    | {
          type: 'widget'
          widgetId: string | number
          slideId: string | number
          action: 'update'
          data: Difference[]
      }
    | {
          type: 'widget'
          widgetId: string | number
          slideId: string | number
          action: 'add' | 'delete'
          index: number
          data: ReportWidgetType
      }
    | {
          type: 'widget'
          widgetId: string | number
          slideId: string | number
          action: 'layer'
          index: number
      }
    | {
          type: 'slide'
          slideId: string | number
          action: 'add' | 'delete'
          index: number
          data: ReportSlideType
      }
    | {
          type: 'slide'
          slideId: string | number
          action: 'update'
          data: Difference[]
      }
    | {
          type: 'slide'
          slideId: string | number
          action: 'layer'
          index: number
      }
    | {
          type: 'masterSettings'
          action: 'update'
          data: Difference[]
      }

export type HistoryActionType = {
    name: string
    timestamp: number
    actions: HistoryActionItemType[]
}

const initialState: ReportDesignerStoreStateType = {
    status: 'pending',
    // Placeholder: This property should be overwritten on initialization.
    id: -1,
    // Placeholder: This property should be overwritten on initialization.
    title: '',
    // Placeholder: This property should be overwritten on initialization.
    viewMode: 'preview',
    aspectRatio: [16, 9],
    masterSettings: {
        backgroundColor: {
            isEnabled: false,
            color: undefined,
        },
        onboarding: {
            // hasSkipButton: true,
            // onFinishBehavior: 'next-slide',
            onFinishText: 'Finish',
            // onNextSlideText: 'Next Slide',
            // skipText: 'Close',
        },
        header: {
            isEnabled: false,
            secondaryText: {
                value: '',
                styles: {
                    fontFamily: 'Poppins',
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: '#333333FF',
                },
            },
            image: {
                status: 'ready',
                source: '',
                name: '',
                fit: 'contain',
            },
            height: 10,
            backgroundColor: {
                isEnabled: false,
                color: undefined,
            },
            hasBottomShadow: false,
        },
    },
    layoutSettings: {
        leftSidebar: {
            isOverlayed: false,
            width: 190,
            transitionDuration: 250,
        },
        settingsSidebar: {
            isExpanded: true,
            width: '15%',
            transitionDuration: 250,
            view: 'report',
        },
    },
    // Placeholder: This property should be overwritten on initialization.
    slides: [],
    dataSources: [],
    // Placeholder: This property should be overwritten on initialization.
    activeSlideId: null,
    activeWidgetId: null,
    widgetsToRender: [],
    secondarySelectedWidgetIds: [],
    copySource: {
        slide: null,
        widget: null,
    },
    customViews: [],
    reportAccess: [],
    accessToken: null,
    tour: {
        step: 0,
        isOpen: false,
        mode: 'slide',
    },
    history: {
        past: [],
        future: [],
    },
}

/* *
 * Notes:
 *
 * We use "createWithEqualityFn" instead of the base "create" method because inside the components that are using the store state,
 * we are generally selecting multiple values and returning a single object (and then use the destructured values).
 * The "shallow" comparison function fixes the issue of excessive re-rendering bug that happens with selecting store values in such a way.
 * If we wanted to remove the "shallow" function and use the base "create" method, we would have to call "useReportStore" hook separately
 * for each value of the store that we want to select (and not bundle them together in an object).
 */

const useReportStore = createWithEqualityFn<ReportDesignerStoreStateType & ReportDesignerStoreActionsType>()(
    immer(
        devtools((set, get) => ({
            ...initialState,
            ...reportStoreActionsCreator(initialState, set, get),
        }))
    ),
    shallow
)

export default useReportStore

/*
 * Custom hooks to facilitate usage of store values.
 * =========================================
 */

//* Use active slide
export const useReportActiveSlide = () => {
    const { status, slides, activeSlideId } = useReportStore((store) => ({
        status: store.status,
        slides: store.slides,
        activeSlideId: store.activeSlideId,
    }))

    // Invalid value is "null"
    const slide = useMemo<ReportSlideType | null>(() => {
        // "status" is in an invalid state.
        if (['pending', 'faulty'].includes(status) === true) {
            return null
        }

        // "activeSlideId" was set to "null"
        if (activeSlideId === null) {
            return null
        }

        const targetSlide = slides.find((_slide) => _slide.id === activeSlideId)

        // "activeSlideId" has a value that doesn't exist in the current slides list.
        if (targetSlide === undefined) {
            return null
        }

        return targetSlide
    }, [status, slides, activeSlideId])

    return slide
}

type ExtendedStepType = StepType & { hideButtons?: boolean }

//* Use onboarding slide
export const useReportSlideOnboarding = () => {
    const { updateCombinedWidgetContent, updateReportTour, updateReportTourStep, tour, wtr, slides, setActiveSlideId } =
        useReportStore((store) => ({
            updateCombinedWidgetContent: store.updateCombinedWidgetContent,
            updateReportTour: store.updateReportTour,
            updateReportTourStep: store.updateReportTourStep,
            tour: store.tour,
            wtr: store.widgetsToRender,
            slides: store.slides,
            setActiveSlideId: store.setActiveSlideId,
        }))

    const activeSlide = useReportActiveSlide()

    const [slideChange, setSlideChange] = useState(false)

    const { setIsOpen, setSteps, setCurrentStep } = useTour()

    // sync the tour state with the store
    useEffect(() => {
        if (tour.isOpen) {
            setSlideChange(false)
            startTour(tour.step)
        } else {
            setIsOpen(false)
        }
    }, [tour.isOpen])

    useEffect(() => {
        setCurrentStep(tour.step)
    }, [tour.step])

    // this is for change of slide
    // when slide changes, we need to wait for the slide to render and then start the tour
    useEffect(() => {
        if (wtr.length > 0) {
            if (tour.isOpen) setSlideChange(true)
            setIsOpen(false)
        } else {
            if (tour.isOpen) {
                setSlideChange(false)
                setIsOpen(true)
            }
        }
    }, [wtr.length])

    const mapOnboardingSetp = (step: ReportWidgetOnboardingType, slideId?: string | number) => {
        return {
            content: step.content,
            selector: step.component.join(' '),
            // hideButtons: step.hideNextButton,
            stepInteraction: step.interactive,
            position: 'top',
            action: (elem) => {
                slideId !== undefined && setActiveSlideId({ slideId })

                // if (step.hideNextButton) {
                //     if (elem !== null && 'onclick' in elem) {
                //         const originalOnClick = elem.onclick as (() => void) | null
                //         elem.onclick = () => {
                //             updateReportTourStep({ data: 'next' })

                //             if (originalOnClick !== null) {
                //                 originalOnClick()
                //             }
                //         }

                //         return () => {
                //             elem.onclick = originalOnClick
                //         }
                //     }
                // } else
                if (step.component.length > 1 && step.component[1].startsWith('#widget')) {
                    // Extract the IDs for the widget and sub-widget
                    const subWidgetId = step.component[1].replace('#widget-', '')
                    const widgetId = step.component[0].replace('#widget-', '')

                    // Find the corresponding widget in the active slide
                    const widget = activeSlide!.widgets.find((widget) => widget.id === widgetId)

                    // Check if the widget is a 'combined' type and needs updating
                    if (widget?.content.kind === 'combined') {
                        // Update the content of the combined widget
                        updateCombinedWidgetContent({
                            slideId: activeSlide!.id,
                            widgetId: widgetId,
                            data: { selectedWidgetId: subWidgetId },
                        })
                    }
                }
            },
        } as ExtendedStepType
    }

    // Handler to restart the tour
    const startTour = (step: number = 0) => {
        if (activeSlide === null || setSteps == null) return

        const steps: ExtendedStepType[] = []

        if (tour.mode === 'slide') {
            steps.push(...activeSlide.onboarding.steps.map((step, index) => mapOnboardingSetp(step)))
        } else if (tour.mode === 'report') {
            for (const slide of slides) {
                steps.push(...slide.onboarding.steps.map((step, index) => mapOnboardingSetp(step, slide.id)))
            }
        }
        setSteps(steps)
        updateReportTour({ data: { isOpen: true, step: step } })
        setIsOpen(true)
    }

    return { slideChange: slideChange }
}

//* Use active widget
export const useReportActiveWidget = (widgetId?: ReportWidgetType['id']) => {
    const {
        status,
        slides,
        activeSlideId,
        activeWidgetId: reportActiveWidgetId,
    } = useReportStore((store) => ({
        status: store.status,
        slides: store.slides,
        activeSlideId: store.activeSlideId,
        activeWidgetId: store.activeWidgetId,
    }))

    const activeWidgetId = widgetId || reportActiveWidgetId

    // Invalid value is "null"
    const widget = useMemo<ReportWidgetType | null>(() => {
        // "status" is in an invalid state.
        if (['pending', 'faulty'].includes(status) === true) {
            return null
        }

        // "activeSlideId" was set to "null"
        if (activeSlideId === null) {
            return null
        }

        const targetSlide = slides.find((_slide) => _slide.id === activeSlideId)

        // "activeSlideId" has a value that doesn't exist in the current slides list.
        if (targetSlide === undefined) {
            return null
        }

        const targetWidget = targetSlide.widgets.find((_widget) => _widget.id === activeWidgetId)

        // "activeWidgetId" has a value that doesn't exist in the current slide's widgets list.
        if (targetWidget === undefined) {
            return null
        }

        return targetWidget
    }, [status, slides, activeSlideId, activeWidgetId])

    return widget
}

//* Use active widget
export const useReportSelectedWidgets = () => {
    const { status, slides, activeSlideId, activeWidgetId, secondarySelectedWidgetIds } = useReportStore((store) => ({
        status: store.status,
        slides: store.slides,
        activeSlideId: store.activeSlideId,
        activeWidgetId: store.activeWidgetId,
        secondarySelectedWidgetIds: store.secondarySelectedWidgetIds,
    }))

    // Invalid value is "null"
    const widget = useMemo<ReportWidgetType[] | null>(() => {
        // "status" is in an invalid state.
        if (['pending', 'faulty'].includes(status) === true) {
            return null
        }

        // "activeSlideId" was set to "null"
        if (activeSlideId === null) {
            return null
        }

        if (secondarySelectedWidgetIds.length === 0) {
            return null
        }

        const targetSlide = slides.find((_slide) => _slide.id === activeSlideId)

        // "activeSlideId" has a value that doesn't exist in the current slides list.
        if (targetSlide === undefined) {
            return null
        }

        const targetWidgets = targetSlide.widgets.filter(
            (_widget) => _widget.id === activeWidgetId || secondarySelectedWidgetIds.includes(_widget.id)
        )

        // if less than 2 widgets are selected, return null
        if (targetWidgets.length < 2) {
            return null
        }

        return targetWidgets
    }, [status, slides, activeSlideId, activeWidgetId, secondarySelectedWidgetIds])

    return widget
}
