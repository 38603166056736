//* ======= Libraries
import React, { useMemo, useCallback } from 'react'
import {
    DataGridPro,
    DataGridProProps,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridRowSpacingParams,
} from '@mui/x-data-grid-pro'
import { Stack, styled } from '@mui/material'
import { deepmerge } from '@mui/utils'
//* ======= Components and features
//* ======= Custom logic
//* ======= Assets and styles

const CustomizedDataGrid = styled(DataGridPro, {
    shouldForwardProp: (prop) => prop !== 'sx',
})(({ theme, sx }) =>
    deepmerge(
        {
            border: 0,
            // Main container
            '& .MuiDataGrid-main': {
                // Header container
                '& .MuiDataGrid-columnHeaders': {
                    borderBottom: 0,

                    // Header inner container
                    '& .MuiDataGrid-columnHeadersInner': {
                        // Header column cell
                        '& .MuiDataGrid-columnHeader': {
                            '&:focus': {
                                outline: 0,
                            },
                            '&:focus-within': {
                                outline: 0,
                            },
                        },
                    },
                },

                // Row
                '& .MuiDataGrid-row': {
                    borderRadius: '10px',
                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,

                    // Add line-through style for deleted rows
                    '&.line-through': {
                        position: 'relative',

                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            height: '2px',
                            backgroundColor: 'red', // change color as needed
                            top: '50%',
                        },
                    },

                    // Selected
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.common.bg_5,
                    },

                    // Row cell
                    '& .MuiDataGrid-cell': {
                        borderBottom: 0,

                        '&:focus': {
                            outline: 0,
                        },
                        '&:focus-within': {
                            outline: 0,
                        },

                        // Cell content
                        '& .MuiDataGrid-cellContent': {
                            color: theme.palette.common.text_1,
                        },
                    },
                },
            },
        },
        sx || {}
    )
)

interface IDataGrid extends DataGridProProps {
    title?: string
    hasToolbar?: boolean
    hasSearch?: boolean
    hasHeader?: boolean
    hasColumnSelector?: boolean
    hasExport?: boolean
    hasFilter?: boolean
    customToolbar?: React.ReactNode
}

function StyledDataGrid({
    density = 'standard',
    title,
    hasToolbar = true,
    hasColumnSelector = true,
    hasExport = true,
    hasFilter = true,
    hasHeader = true,
    hasSearch = true,
    customToolbar,
    columns,
    ...props
}: IDataGrid) {
    const parsedColumns = useMemo(() => {
        if (columns.length !== 0 && Boolean(columns) !== false) {
            return columns.map((_column) => ({
                ...(_column.width
                    ? {}
                    : {
                          flex: 1,
                          minWidth: 48,
                      }),
                ..._column,
            }))
        }

        return []
    }, [columns])

    const gridGetRowSpacing = useCallback(({ isLastVisible }: GridRowSpacingParams) => {
        return {
            bottom: isLastVisible ? 0 : 8,
        }
    }, [])

    return (
        <CustomizedDataGrid
            columns={parsedColumns}
            getRowSpacing={gridGetRowSpacing}
            density={density}
            columnHeaderHeight={hasHeader ? 56 : 0}
            rowHeight={48}
            slots={{
                toolbar: () => (
                    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
                        {/* Search
                        	========================================= */}
                        {hasSearch && (
                            <GridToolbarQuickFilter
                                variant="filled"
                                size="small"
                                hiddenLabel
                                sx={(theme) => ({
                                    // Label
                                    '& .MuiInputLabel-root': {
                                        '&.Mui-focused': {
                                            color: theme.palette.secondary.main,
                                        },
                                        '&.Mui-error': {
                                            color: theme.palette.common.fuschia100,
                                        },
                                    },
                                    // Input
                                    '& .MuiFilledInput-root': {
                                        borderRadius: 4,
                                        border: '1px solid transparent',
                                        backgroundColor: theme.palette.common.bg_4,

                                        '&.Mui-focused': {
                                            borderColor: theme.palette.primary.main,
                                            backgroundColor: theme.palette.common.bg_3,
                                        },
                                        '&.Mui-error': {
                                            borderColor: theme.palette.common.fuschia100,
                                        },
                                        '&::before': {
                                            display: 'none',
                                        },
                                        '&::after': {
                                            display: 'none',
                                        },
                                    },
                                    // Helper text
                                    '& .MuiFormHelperText-root': {
                                        '&.Mui-error': {
                                            color: theme.palette.common.fuschia100,
                                        },
                                    },
                                })}
                                debounceMs={500}
                            />
                        )}

                        {/* Header
                        	========================================= */}
                        {(hasToolbar || customToolbar) && (
                            <Stack direction="row" alignItems="center" marginLeft="auto">
                                {hasToolbar && (
                                    <>
                                        {hasColumnSelector && <GridToolbarColumnsButton />}

                                        {hasFilter && <GridToolbarFilterButton />}

                                        {hasExport && <GridToolbarExport />}
                                    </>
                                )}
                                {customToolbar && customToolbar}
                            </Stack>
                        )}
                    </GridToolbarContainer>
                ),
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            {...props}
            classes={{ virtualScroller: 'u-scrollbar', ...props.classes }}
        />
    )
}

export default StyledDataGrid
