//* ======= Libraries
import React, { useContext } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import { SurveyContext } from 'contexts/SurveyContex'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { UpdateSurveyHasReportService } from 'services/SurveyService'
import { useParams } from 'react-router-dom'
import SurveyReportDesigner from 'features/survey-report/SurveyReportDesigner'

export interface SurveyReportProps {}

export default function SurveyReport({}: SurveyReportProps) {
    const { pid, sid } = useParams()
    //states

    const { survey, updateSurvey } = useContext(SurveyContext)

    //methods

    const enableSurveyReport = () => {
        if (pid == null || sid == null) return
        UpdateSurveyHasReportService(pid, sid, true).then(
            (res) =>
                res.success &&
                updateSurvey({
                    hasReport: true,
                })
        )
    }

    return (
        <PageCard>
            <PageCard.Body noPaddingRight>
                {survey == null || survey.hasReport == null ? (
                    <Box sx={{ margin: 'auto', width: '75px', marginTop: '30%' }}>
                        {' '}
                        <SnaCircularLoading />
                    </Box>
                ) : !survey.hasReport ? (
                    <Card sx={{ width: '60%', minWidth: '400px', margin: 'auto', marginTop: '30%' }}>
                        <CardHeader title={`Enable Individual Report for ${survey?.title}`}></CardHeader>
                        <CardContent>
                            <Typography>Are you sure you want to enable Individual Report for this survey?</Typography>
                            <Typography>
                                This report will be displayed to each participant after finishing up the survey based on
                                their own responses?
                            </Typography>
                        </CardContent>
                        <CardActionArea>
                            <BaseButton variant="contained" fullWidth onClick={enableSurveyReport}>
                                Enable
                            </BaseButton>
                        </CardActionArea>
                    </Card>
                ) : (
                    <SurveyReportDesigner />
                )}
            </PageCard.Body>
        </PageCard>
    )
}
