import React, { useMemo } from 'react'
import { registerAddressQuestion } from 'features/survey-designer/custom-questions/AddressQestion'
import { registerNetworkQuestion } from 'features/survey-designer/custom-questions/NetworkQuestion'
import { Model, SurveyModel } from 'survey-core'
import { Survey } from 'survey-react-ui'

type Props = {
    question: any
}

function SurveyQuestionPreview({ question }: Props) {
    let survey: SurveyModel | undefined = useMemo(() => {
        if (!question) return

        //register custom question
        registerNetworkQuestion()
        registerAddressQuestion()
        //create survey instance
        let s = new Model({
            pages: [
                {
                    name: 'page1',
                    elements: [question],
                },
            ],
        })

        //config survey
        s.showTitle = false
        s.showPrevButton = false
        s.showNavigationButtons = false
        s.surveyShowDataSaving = true

        return s
    }, [question])

    return <Survey model={survey} />
}

export default SurveyQuestionPreview
