//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import { GetNodeOtherFiledsService, RespondentType } from 'services/NodeService'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { CreateSurveyParticipants } from 'services/SurveyService'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

interface IAddSurveyParticipantsDialog {
    respondents: RespondentType[]
    onConfirm?: () => void
    onClose?: () => void
}

function AddSurveyParticipantsDialog({ respondents, onConfirm, onClose }: IAddSurveyParticipantsDialog) {
    const { pid, sid } = useParams()
    const gridApi = useGridApiRef()

    const insertNodes = () => {
        if (pid && sid) {
            const rows = Array.from(gridApi.current.getSelectedRows().keys()).map((v) => ({
                id: parseInt(v.toString()),
            }))
            let toastId = toast.loading('Inserting survey participants...')
            CreateSurveyParticipants(pid, sid, rows)
                .then((res) => {
                    if (res.success) {
                        onConfirm && onConfirm()
                        toast.update(toastId, {
                            type: 'success',
                            render: 'Survey participants inserted successfully!',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    } else {
                        toast.update(toastId, {
                            type: 'error',
                            render: 'Failed to insert survey participants!',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    }
                })
                .catch((err) => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Failed to insert survey participants!',
                        isLoading: false,
                        autoClose: 1000,
                        closeButton: null,
                    })
                })
        }
    }

    const [otherFields, setOtherFileds] = useState<string[]>([])

    const getOtherFileds = () => {
        if (pid == null) setOtherFileds([])

        if (pid) {
            GetNodeOtherFiledsService(pid)
                .then((res) => {
                    if (res.success) {
                        setOtherFileds(res.data.map((r) => r.field))
                    } else {
                        setOtherFileds([])
                    }
                })
                .catch((e) => setOtherFileds([]))
        }
    }

    useEffect(() => {
        getOtherFileds()
    }, [pid])

    const columns: GridColDef<any>[] = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'id',
            },
            {
                field: 'nodeTitle',
                headerName: 'Node',
            },
            {
                field: 'accountName',
                headerName: 'Name',
            },
            {
                field: 'accountEmail',
                headerName: 'Email',
            },
            {
                field: 'accountPhoneNumber',
                headerName: 'Phone number',
            },
            ...otherFields.map((f) => ({
                field: `otherFields.${f}`,
                headerName: f,
            })),
        ],
        [otherFields]
    )

    const rows = useMemo(
        () =>
            respondents.map((r) => {
                const clonedData = structuredClone(r)
                const { others, ...rest } = clonedData
                const row: Record<string, any> = { ...rest }
                for (const key in others) {
                    row[`otherFields.${key}`] = others[key]
                }
                return row
            }),
        [respondents]
    )

    return (
        <Stack gap={1} minHeight={640} py={3} px={4}>
            {/* Title
				========================================= */}
            <Typography fontSize={25} fontWeight={600} color="primary">
                Add Survey Participants
            </Typography>

            {/* Helper description
				========================================= */}
            <Typography paragraph fontSize={16} fontWeight={400} color="common.text_1">
                Please select the nodes you want to invite to this survey and press the 'Insert' button. You can use
                filter option to filter the nodes.
            </Typography>

            {/* Search and filter button
				========================================= */}
            <Stack alignSelf="flex-end" direction="row" alignItems="center" gap={2} marginBottom={1}>
                {/* Search
					========================================= */}
                {/* <SearchField options={['Something']} placeholder="Search" /> */}

                {/* Filter button
					========================================= */}
                {/* <IconButton
                    sx={{
                        borderRadius: '4px',
                        color: 'common.fill_2',
                    }}
                >
                    <FilterListIcon />
                </IconButton> */}
            </Stack>

            {/* (Conditional) Filter chips
				========================================= */}
            {/* <Stack direction="row" flexWrap="wrap" alignItems="center" gap={1}>
                {Array.from(Array(3)).map((_, idx) => (
                    <Chip key={idx} label={'Filter ' + (idx + 1)} size="small" onDelete={(e) => undefined} />
                ))}
            </Stack> */}

            {/* Table
				========================================= */}
            <Box
                sx={{
                    height: '60vh',
                    marginBottom: 1.5,
                }}
            >
                <StyledDataGrid apiRef={gridApi} density="compact" rows={rows} checkboxSelection columns={columns} />
            </Box>

            {/* Footer controls
				========================================= */}
            <Stack alignSelf="flex-end" direction="row" alignItems="center" gap={3} marginBottom={1}>
                {/* Close button
					========================================= */}
                <BaseButton
                    label="Close"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    size="large"
                    sx={{
                        py: 1,
                    }}
                />

                {/* Insert button
					========================================= */}
                <BaseButton
                    label="Insert"
                    // disabled={gridApi.current !=null && gridApi.current.getSelectedRows().size> 0}
                    onClick={insertNodes}
                    variant="contained"
                    size="large"
                    sx={{
                        py: 1,
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default AddSurveyParticipantsDialog
