import { Box, FormControl, hexToRgb, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import ColorPicker from 'components/widgets/ColorPicker'
import { getColorPallet } from '../../constants/defaultNodeSettings'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { NodeInteractionConfigType } from 'features/network-viz/types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'

export default function NetworkInteractionSettings() {
    const { nodeInteractionConfig, edgeRenders } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()

    const [localValue, setLocalValue] = useState<NodeInteractionConfigType>(nodeInteractionConfig)

    const updateField = (field: string) => (value: any) => {
        setLocalValue((pv) => ({ ...pv, [field]: value }))
    }

    const toggleField =
        (
            field:
                | 'freeRoam'
                | 'hideNonAdjacentNodesOnSelect'
                | 'directed'
                | 'layerColor'
                | 'zoomSelectedNode'
                | 'dimOtherNodesOnSelect'
        ) =>
        () => {
            setLocalValue((pv) => ({ ...pv, [field]: !pv[field] }))
        }

    useEffect(() => {
        setLocalValue((pv) => {
            const tmp = { ...pv }
            const colors = tmp.colors ? [...tmp.colors] : null
            if (colors == null) {
                return {
                    ...tmp,
                    colors: getColorPallet('light')
                        .slice(0, tmp.layer)
                        .map((x) => ({
                            hex: x,
                            rgba: hexToRgb(x),
                        })),
                }
            } else if (colors.length > tmp.layer) {
                return {
                    ...tmp,
                    colors: colors.slice(0, tmp.layer),
                }
            } else if (colors.length < tmp.layer) {
                return {
                    ...tmp,
                    colors: [
                        ...colors,
                        ...getColorPallet('light')
                            .slice(colors.length, tmp.layer)
                            .map((x) => ({
                                hex: x,
                                rgba: hexToRgb(x),
                            })),
                    ],
                }
            }
            return { ...tmp }
        })
    }, [localValue.layer])

    const applyChanges = () => {
        if (localValue.directed !== nodeInteractionConfig.directed) {
            const worker = new WebWorker('workers/network/node-adjacency.js')
            worker
                .run({
                    edgeRenders: edgeRenders.filter((x) => x.hide !== true),
                    isDirected: localValue.directed,
                })
                .then((res: any) => {
                    contextDispatch({ type: 'NODE_ADJACENCY_EDIT', payload: { nodeAdjacentList: res } })
                })
        }
        contextDispatch({
            type: 'NODE_INTERACTION_EDIT',
            payload: { nodeInteractionConfig: { ...localValue } },
        })
    }

    const setColor = (idx: number, color: string) => {
        setLocalValue((pv) => {
            if (!pv.colors) return { ...pv }
            const tmpColor = [...pv.colors]
            tmpColor[idx] = { hex: color, rgba: hexToRgb(color) }
            return { ...pv, colors: tmpColor }
        })
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={500}>
                    Interaction Settings
                </Typography>
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={1} minWidth={0}>
                <StyledWidgetAccordion
                    title="Highlight Adjacent Nodes"
                    defaultExpanded={true}
                    onToggle={toggleField('hideNonAdjacentNodesOnSelect')}
                    isToggledOff={!localValue.hideNonAdjacentNodesOnSelect}
                    hasToggle
                    hasBottomBorder
                >
                    <BaseFilledTextField
                        label="Layers"
                        fullWidth
                        size="small"
                        type="number"
                        inputProps={{
                            min: 1,
                            max: 2,
                        }}
                        value={localValue.layer}
                        onChange={(e) => updateField('layer')(Math.max(1, Number(e.target.value)))}
                        helperText="Number of layers to be highlighted."
                    />

                    <StyledSwitch
                        checked={localValue.layerColor}
                        onChange={toggleField('layerColor')}
                        label="Layer-based coloring"
                    />

                    {localValue.layerColor &&
                        Array.isArray(localValue.colors) &&
                        localValue.colors.map((color, idx) => {
                            return (
                                <Box key={idx}>
                                    <Typography>Layer {idx + 1} Color</Typography>
                                    <ColorPicker value={color.hex} onChange={(c) => c && setColor(idx, c)} />
                                </Box>
                            )
                        })}

                    <StyledSwitch
                        checked={localValue.directed}
                        onChange={toggleField('directed')}
                        label="Directed Mode"
                    />

                    <StyledSwitch
                        checked={localValue.dimOtherNodesOnSelect}
                        onChange={toggleField('dimOtherNodesOnSelect')}
                        label="Dim not Selected Nodes"
                    />

                    <BaseButton fullWidth variant="outlined" onClick={applyChanges}>
                        Apply Changes
                    </BaseButton>
                </StyledWidgetAccordion>
            </Stack>
        </Stack>
    )
}
