//* ======= Libraries
import React from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ViewComfyIcon from '@mui/icons-material/ViewComfy'

interface INotifications {}

function Notifications({}: INotifications) {
    return (
        <PageCard>
            <PageCard.Body>
                <Stack
                    spacing={1}
                    alignItems="center"
                    width="100%"
                    height="100%"
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 2,
                    }}
                >
                    {/* Wrapper
                		========================================= */}
                    <Grid
                        container
                        sx={{
                            height: '100%',
                            width: 'clamp(620px, 50%, 90%)',
                        }}
                    >
                        {/* Timeline
							TODO:
							This component seems buggy. Maybe use something other than MUI for this.
                			========================================= */}
                        <Grid
                            item
                            xs={3}
                            sx={(theme) => ({
                                padding: theme.spacing(1.5, 2, 2),

                                backgroundColor: 'common.bg_1',
                            })}
                        >
                            <Typography fontSize={16} fontWeight={600} color="primary" marginBottom={1.5}>
                                All Notifications
                            </Typography>

                            <Timeline
                                sx={{
                                    margin: 0,
                                }}
                            >
                                <TimelineItem
                                    sx={{
                                        width: '50%',
                                    }}
                                >
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        <Typography fontSize={14} fontWeight={600} color="primary">
                                            Today
                                        </Typography>
                                        <Typography fontSize={8} fontWeight={600} color="secondary">
                                            April 14
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem
                                    sx={{
                                        width: '50%',
                                    }}
                                >
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        {/* <TimelineConnector /> */}
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        <Typography fontSize={14} fontWeight={600} color="common.text_3">
                                            Yesterday
                                        </Typography>
                                        <Typography fontSize={8} fontWeight={600} color="secondary">
                                            April 13
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>

                        {/* Controls and list
                			========================================= */}
                        <Grid
                            item
                            xs
                            height="100%"
                            sx={{
                                paddingLeft: 1,
                                paddingRight: 1,
                            }}
                        >
                            <Stack gap={1} height="100%" minHeight={0}>
                                {/* Top controls
                					========================================= */}
                                <Stack flexShrink={0} direction="row" alignItems="center">
                                    {/* Search
										========================================= */}
                                    {/* <SearchField
                                        options={['Something']}
                                        // value={searchQuery}
                                        // onChange={(evt, value) => setSearchQuery(value?.toString())}
                                        placeholder="Search"
                                        inputBgColor="common.bg_3"
                                        sx={{
                                            marginLeft: 'auto',
                                            marginRight: 2,
                                        }}
                                    /> */}

                                    {/* Filter button
										========================================= */}
                                    <IconButton
                                        sx={{
                                            height: '50px',
                                            width: '50px',

                                            borderRadius: '4px',
                                            color: 'common.fill_2',
                                        }}
                                    >
                                        <FilterAltIcon />
                                    </IconButton>

                                    {/* List view button
										========================================= */}
                                    <IconButton
                                        // onClick={() => setIsListView(true)}
                                        sx={{
                                            height: '50px',
                                            width: '50px',

                                            borderRadius: '4px',
                                            // color: isListView ? 'primary.main' : 'common.fill_2',
                                            color: 'common.fill_2',
                                        }}
                                    >
                                        <FormatListBulletedIcon />
                                    </IconButton>

                                    {/* Grid view button
										========================================= */}
                                    <IconButton
                                        // onClick={() => setIsListView(false)}
                                        sx={{
                                            height: '50px',
                                            width: '50px',

                                            borderRadius: '4px',
                                            // color: isListView ? 'common.fill_2' : 'primary.main',
                                            color: 'common.fill_2',
                                        }}
                                    >
                                        <ViewComfyIcon />
                                    </IconButton>
                                </Stack>

                                {/* List of notifications
                					========================================= */}
                                <Stack
                                    gap={1}
                                    sx={{
                                        flexGrow: 1,

                                        overflowY: 'auto',
                                    }}
                                    className="u-scrollbar"
                                >
                                    {Array.from(Array(3)).map((_, idx) => (
                                        <Stack
                                            key={idx}
                                            flexShrink={0}
                                            direction="row"
                                            alignItems="flex-start"
                                            gap={1}
                                            sx={{
                                                padding: 2,

                                                boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                                                borderBottomLeftRadius: '10px',
                                                borderBottomRightRadius: '10px',
                                                backgroundColor: 'common.bg_1',
                                            }}
                                        >
                                            {/* User avatar
                								========================================= */}
                                            <Avatar
                                                src={TempAvatar}
                                                alt="user profile"
                                                sx={{
                                                    height: 32,
                                                    width: 32,
                                                }}
                                            />

                                            {/* Details
                								========================================= */}
                                            <Stack gap={0.5}>
                                                {/* Title
                									========================================= */}
                                                <Typography fontSize={10} fontWeight={400} color="common.text_1">
                                                    SNA Toolbox
                                                </Typography>

                                                {/* Content
                									========================================= */}
                                                <Typography fontSize={10} fontWeight={400} color="secondary">
                                                    Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin
                                                    metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget
                                                    justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod
                                                    quis libero sed convallis. Vestibulum fringilla felis nec turpis
                                                    aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere.
                                                    Suspendisse nec mollis dolor, vel cursus leo. Integer vitae sem
                                                    vitae leo pretium porta. In consequat magna purus, iaculis rhoncus
                                                    velit imperdiet sit amet.
                                                </Typography>

                                                {/* Action button/link (?)
                									========================================= */}
                                                <BaseButton
                                                    label="More About all new features"
                                                    size="small"
                                                    sx={{
                                                        alignSelf: 'flex-end',

                                                        marginTop: 0.5,
                                                        fontSize: 10,
                                                        fontWeight: 400,
                                                    }}
                                                />

                                                {/* Action link (in case needed)
                									========================================= */}
                                                {/* <Link
                                                    href="#"
                                                    underline="none"
                                                    sx={{
                                                        alignSelf: 'flex-end',

                                                        marginTop: 0.5,
                                                        padding: 0.5,
                                                        fontSize: 10,
                                                        fontWeight: 400,

                                                        color: 'primary.main',
                                                    }}
                                                >
                                                    More About all new features
                                                </Link> */}
                                            </Stack>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}

export default Notifications
