//* ======= Libraries
import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import customECharts, { EChartsCustomOptionType, EchartsCustomSeriesType } from 'app/echarts/custom-echarts'
import { ChartTypeType } from 'features/chart/Chart.asset'
import usePreviousValue from 'hooks/usePreviousValue'
import useDebouncedResizeObserver from 'hooks/useDebouncedResizeObserver'
import { cloneDeep } from 'lodash'
//* ======= Assets and styles

type Props = {
    type: ChartTypeType
    options: EChartsCustomOptionType
    renderer?: 'svg' | 'canvas'
    clearOnChange?: boolean
    onReady?: () => void
    scaleFactor?: number
}

const scaleProperties = (obj: any, scaleFactor: number, properties: string[]) => {
    for (const key in obj) {
        if (!obj.hasOwnProperty(key)) continue

        const value = obj[key]
        if (typeof value === 'number' && properties.includes(key)) {
            obj[key] = value * scaleFactor
        } else if (typeof value === 'object' && value !== null) {
            scaleProperties(value, scaleFactor, properties)
        } else if (Array.isArray(value)) {
            value.forEach((item) => {
                scaleProperties(item, scaleFactor, properties)
            })
        }
    }
}

const scaleChartOptions = (options: EChartsCustomOptionType, scaleFactor: number): EChartsCustomOptionType => {
    // Clone the options to avoid mutating the original object
    const scaledOptions = cloneDeep(options)

    // Define properties to scale here, could be extended to be chart type specific
    const scalableProperties = ['width', 'length', 'distance', 'fontSize']

    scaleProperties(scaledOptions, scaleFactor, scalableProperties)

    // Add logic here to scale other parts of the options if necessary

    return scaledOptions
}

// The Chart component is a general-purpose chart component built on the ECharts library.
// It accepts a chart type, options for the chart, an optional renderer type, and a flag to clear the chart when its type or options change.
function Chart({ type, options, clearOnChange = false, renderer = 'canvas', onReady, scaleFactor = 1 }: Props) {
    // const muiTheme = useTheme()

    // chartInstance stores the instance of the currently rendered ECharts object.
    const [chartInstance, setChartInstance] = useState<customECharts.ECharts | null>(null)
    const prevChartType = usePreviousValue(type)

    // chartContainerRef is a reference to the div that will contain the chart.
    const chartContainerRef = useRef<HTMLDivElement>(null)
    const chartContainerSize = useDebouncedResizeObserver({ wait: 100, ref: chartContainerRef })

    // resizeChart resizes the ECharts instance to fill its container.
    const resizeChart = useCallback(() => {
        if (chartInstance !== null) {
            chartInstance.resize()
        }
    }, [chartInstance])

    // saveImageSVG and saveImagePNG save the current state of the chart as an SVG or PNG image, respectively.
    // These methods create a new chart with the current options, wait for it to finish rendering,
    // and then generate a data URL for the rendered chart and trigger a download of this URL.
    const saveImage = useCallback(
        (rendererType: 'svg' | 'canvas') => {
            if (chartInstance) {
                const fileType = rendererType === 'svg' ? 'svg' : 'png'
                var link = document.createElement('a')

                var div = document.createElement('div')
                div.setAttribute(
                    'style',
                    'width:1024px;height:768px;display:none;position:fixed;zIndex:999;top:0;left:0'
                )
                document.body.appendChild(div)
                let chart = customECharts.init(div, undefined, { renderer: rendererType })
                chart.setOption({ ...options, toolbox: undefined })

                const finishHandler = function () {
                    link.download = `chart-${type}-${Date.now()}.${fileType}`
                    link.href = chart.getDataURL()
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    document.body.removeChild(div)
                    chart.off('finished', finishHandler)
                    chart.dispose()
                }
                chart.on('finished', finishHandler)
            }
        },
        [chartInstance, type, options]
    )

    const saveImageSVG = useCallback(() => saveImage('svg'), [saveImage])
    const saveImagePNG = useCallback(() => saveImage('canvas'), [saveImage])

    const [isFullscreen, setIsFullscreen] = useState(false)

    // useEffect to detect if chart is displayed in fullscreen mode.
    // This is needed to adjust the tooltip behavior as the default behavior of the tooltip is to be appended to the body.
    useEffect(() => {
        // Check the initial state
        setIsFullscreen(!!document.fullscreenElement)

        // Handler to set the state based on fullscreen changes
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement)
        }

        // Add the event listener
        document.addEventListener('fullscreenchange', handleFullscreenChange)

        // Cleanup by removing the event listener on unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange)
        }
    }, []) // The empty dependency array ensures this effect runs only on mount and unmount

    // processedOptions is a memoized copy of the provided options, with the saveAsImage feature replaced with a custom feature.
    const processedOptions = useMemo(() => {
        // Make a deep copy of options to avoid mutating the original object
        const newOptions = cloneDeep(options)

        // Adjust tooltip based on isFullscreen
        if (isFullscreen && newOptions.tooltip) {
            if (Array.isArray(newOptions.tooltip)) {
                newOptions.tooltip.forEach((tooltip) => {
                    tooltip.appendToBody = false
                })
            } else {
                newOptions.tooltip.appendToBody = false
            }
        }

        if (Array.isArray(newOptions.toolbox)) {
            newOptions.toolbox.forEach((toolbox) => {
                if (toolbox.feature && toolbox.feature.saveAsImage) {
                    // Remove saveAsImage
                    delete toolbox.feature.saveAsImage
                    // Add mySaveAsImage
                    toolbox.feature.mySaveAsImage = {
                        show: true,
                        title: 'Save as Image',
                        icon: 'path://M10 20v-6h4v6h5v-8h-14v8z M3 20h4v-8h-4v8z M14 2.14c-0.59-0.84-1.27-1.59-2-2.14h-8c-1.1 0-2 0.9-2 2v16c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12l-2 2z',
                        onclick: () => {
                            saveImageSVG()
                            saveImagePNG()
                        },
                    }
                }
            })
        } else if (newOptions.toolbox && newOptions.toolbox.feature && newOptions.toolbox.feature.saveAsImage) {
            // Remove saveAsImage
            delete newOptions.toolbox.feature.saveAsImage

            // Add mySaveAsImage
            newOptions.toolbox.feature.mySaveAsImage = {
                show: true,
                title: 'Save as Image',
                icon: 'path://M10 20v-6h4v6h5v-8h-14v8z M3 20h4v-8h-4v8z M14 2.14c-0.59-0.84-1.27-1.59-2-2.14h-8c-1.1 0-2 0.9-2 2v16c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12l-2 2z',
                onclick: () => {
                    saveImageSVG()
                    saveImagePNG()
                },
            }
        }

        return newOptions
    }, [options, saveImageSVG, saveImagePNG, isFullscreen])

    // This effect runs once when the component is first mounted. It creates a new ECharts instance in the chart container.
    // When the component is unmounted, it disposes of the ECharts instance to free up resources.
    useEffect(() => {
        if (chartContainerRef?.current !== null) {
            const chartContainer = chartContainerRef.current as HTMLElement

            // TODO: Theme can later be added with more acceptable colors.
            // const chartTheme = muiTheme.palette.mode === 'dark' ? 'dark' : undefined
            const echartsInstance = customECharts.init(chartContainer, undefined, { renderer })

            echartsInstance.on('finished', () => {
                onReady && onReady()
            })

            setChartInstance(echartsInstance)

            return () => {
                echartsInstance.dispose()
            }
        }
    }, [])

    // This effect runs whenever the chart type or options change. It applies the new options to the chart and optionally clears it.
    useEffect(() => {
        if (processedOptions && chartInstance !== null) {
            // Checking for chart "type" changes and redrawing the chart if it has changed.
            if (isFullscreen || clearOnChange || type !== prevChartType) {
                chartInstance.clear()
            }

            const scaledOptions = scaleChartOptions(processedOptions, scaleFactor)

            chartInstance.setOption(scaledOptions)
        }
    }, [processedOptions, chartInstance, type, clearOnChange, prevChartType, scaleFactor])

    // This effect runs whenever the size of the chart container changes. It resizes the chart to fill its container.
    useLayoutEffect(() => {
        resizeChart()
    }, [resizeChart, chartContainerSize])

    // The chart is rendered in a div that fills its container.
    return (
        <Box
            ref={chartContainerRef}
            style={{
                width: '100%',
                height: '100%',
            }}
        />
    )
}

export default Chart
