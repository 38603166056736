import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BackIcon from '@mui/icons-material/ArrowBack'
import ColorPicker from '../../../../components/widgets/ColorPicker'
import { FormBuilderFieldType } from '../../../StyledComponents/FormBuilder'
import BaseButton from 'components/base/BaseButton'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import RangeSliderSelector from 'components/widgets/RangeSliderSelector'
import { EdgeGroupStyleType, EdgeStyleType } from 'features/network-viz/types/NetworkViz.types'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { updateUnchangedProperties } from 'features/network-viz/helpers/NetworkViz.helper'

export interface EdgeSettingsEditProps {
    groupForEdit: string
    onClose: () => void
}

type SectionType = 'normal' | 'colorScale' | 'selectedIn' | 'selectedOut' | 'sizeScale'

export function NetworkEdgeSettingsEdit({ groupForEdit, onClose }: EdgeSettingsEditProps) {
    const { edges, edgeRenders, networkVizKind, edgeStyle, edgeDataSchema } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [localValue, setLocalValue] = useState<EdgeGroupStyleType>(edgeStyle[groupForEdit])

    const updateLocalValue = (field: string, section?: SectionType) => (value: any) => {
        setLocalValue((lv) => {
            if (section) {
                return { ...lv, [section]: { ...lv[section], [field]: value } }
            } else {
                return { ...lv, [field]: value }
            }
        })
    }

    const updateRelationType = (relationType: EdgeGroupStyleType['relationType']) => {
        setLocalValue((pv) => ({
            ...pv,
            relationType,
        }))
        dispatchContext({ type: 'EDGE_RELATION_TYPE_EDIT', payload: { group: groupForEdit, relationType } })
    }

    const applyChanges = (section: SectionType) => () => {
        if (localValue == null) return
        const newGNS = { ...edgeStyle }
        newGNS[groupForEdit] = {
            ...newGNS[groupForEdit],
            [section]: localValue[section],
        }

        // if default group is getting updated, update all styles if the value is not overridden
        if (groupForEdit === 'default') {
            const originalStyleSection = edgeStyle.default[section]
            for (let g in newGNS) {
                if (g === 'default') continue // Skip the default group

                // Create a copy of the groupStyleSection to avoid modifying it directly
                const groupStyleSectionCopy = structuredClone(newGNS[g][section])

                // Update properties where they match the default value (deep)
                updateUnchangedProperties(groupStyleSectionCopy, originalStyleSection, localValue[section])

                // Assign the merged style back to the group
                // ToDo Fix any type
                newGNS[g][section] = groupStyleSectionCopy as any
            }
        }

        const worker = new WebWorker('workers/network/change-edge-style-worker.js')

        worker
            .run({
                edges,
                edgeRenders,
                dataSchema: edgeDataSchema.fields,
                edgeStyle: newGNS,
            })
            .then((res: any) => {
                dispatchContext({ type: 'EDGE_STYLE_EDIT', payload: { edgeRenders: res, edgeStyle: newGNS } })
            })
            .catch((e) => {})
            .finally(() => {})
    }

    const updateSizeScale = (value: number[]) => {
        setLocalValue((lv) => {
            return {
                ...lv,
                sizeScale: {
                    ...lv.sizeScale,
                    sizeFrom: value[0],
                    sizeTo: value[1],
                },
            }
        })
    }

    return (
        <React.Fragment>
            {localValue == null ? (
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        minWidth: 0,
                    })}
                >
                    <SnaCircularLoading />
                </Stack>
            ) : (
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        minWidth: 0,
                    })}
                >
                    {/* Back button
                ========================================= */}
                    <BaseButton
                        label="Back"
                        onClick={onClose}
                        startIcon={<BackIcon />}
                        sx={{
                            alignSelf: 'flex-start',

                            marginBottom: 1,
                        }}
                    />

                    {/* Content
                ========================================= */}

                    {/* Type
                            ========================================= */}
                    <BaseSelectWithLabel
                        size="small"
                        label="Type of relationship"
                        value={localValue.relationType}
                        onChange={updateRelationType}
                        options={['positive', 'negative', 'neutral']}
                        fullWidth
                    />

                    {/* Default Edge Section
                    ========================================= */}
                    <StyledWidgetAccordion defaultExpanded={true} title="Default Edge" hasBottomBorder>
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            {/* Size
                            ========================================= */}
                            <Typography>Width</Typography>
                            <ValueSliderPicker
                                min={0.1}
                                max={10}
                                step={0.1}
                                disabled={localValue.sizeScale.enabled}
                                value={localValue.normal?.width || 0}
                                onChange={updateLocalValue('width', 'normal')}
                            />

                            {networkVizKind === '2d+' && (
                                <>
                                    {/* Curveness
                                ========================================= */}

                                    <Typography>Curveness</Typography>
                                    <StyledSwitch
                                        label="Automatic"
                                        checked={localValue.normal.curveness === null}
                                        onChange={() => {
                                            setLocalValue((pv) => ({
                                                ...pv,
                                                normal: {
                                                    ...pv.normal,
                                                    curveness: pv.normal.curveness === null ? 0.1 : null,
                                                },
                                            }))
                                        }}
                                    />

                                    {localValue.normal.curveness !== null && (
                                        <ValueSliderPicker
                                            min={0}
                                            max={1}
                                            step={0.01}
                                            value={localValue.normal?.curveness || 0}
                                            onChange={updateLocalValue('curveness', 'normal')}
                                        />
                                    )}

                                    {/* Type
                            ========================================= */}
                                    <BaseSelectWithLabel
                                        size="small"
                                        label="Type"
                                        value={localValue.normal.type}
                                        onChange={updateLocalValue('type', 'normal')}
                                        options={['solid', 'dashed', 'dotted']}
                                        fullWidth
                                    />
                                </>
                            )}

                            {/* Color
                            ========================================= */}
                            <ColorPicker
                                disabled={localValue.colorScale.enabled}
                                value={localValue.normal.color}
                                onChange={updateLocalValue('color', 'normal')}
                            />
                        </Stack>
                        {/* Apply changes button
                        ========================================= */}
                        <BaseButton fullWidth variant="outlined" sx={{ marginTop: 2 }} onClick={applyChanges('normal')}>
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Selected In Edge Section 
                    ========================================= */}
                    <StyledWidgetAccordion title="Selected Inbound Edge" hasBottomBorder>
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            {/* Size
                            ========================================= */}
                            <Typography>Width Scale</Typography>
                            <ValueSliderPicker
                                min={1}
                                max={10}
                                step={0.1}
                                value={localValue.selectedIn?.width || 0}
                                onChange={updateLocalValue('width', 'selectedIn')}
                            />

                            {/* Type
                            ========================================= */}
                            <BaseSelectWithLabel
                                size="small"
                                label="Type"
                                value={localValue.selectedIn?.type}
                                onChange={updateLocalValue('type', 'selectedIn')}
                                options={['solid', 'dashed', 'dotted']}
                                fullWidth
                            />

                            {/* Color
                            ========================================= */}
                            <ColorPicker
                                value={localValue.selectedIn?.color}
                                onChange={updateLocalValue('color', 'selectedIn')}
                            />
                        </Stack>
                        {/* Apply changes button
                        ========================================= */}
                        <BaseButton
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                            onClick={applyChanges('selectedIn')}
                        >
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Selected Out Edge Section 
                    ========================================= */}
                    <StyledWidgetAccordion title="Selected Outbound Edge" hasBottomBorder>
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            {/* Size
                            ========================================= */}
                            <Typography>Width Scale</Typography>
                            <ValueSliderPicker
                                min={1}
                                max={10}
                                step={0.1}
                                value={localValue.selectedOut?.width || 0}
                                onChange={updateLocalValue('width', 'selectedOut')}
                            />

                            {/* Type
                            ========================================= */}
                            <BaseSelectWithLabel
                                size="small"
                                label="Type"
                                value={localValue.selectedOut?.type}
                                onChange={updateLocalValue('type', 'selectedOut')}
                                options={['solid', 'dashed', 'dotted']}
                                fullWidth
                            />

                            {/* Color
                            ========================================= */}
                            <ColorPicker
                                value={localValue.selectedOut?.color}
                                onChange={updateLocalValue('color', 'selectedOut')}
                            />
                        </Stack>
                        {/* Apply changes button
                        ========================================= */}
                        <BaseButton
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                            onClick={applyChanges('selectedOut')}
                        >
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Color Scale
                    ========================================= */}
                    <StyledWidgetAccordion
                        title="Color Scale"
                        hasBottomBorder
                        hasToggle
                        onToggle={(v) => updateLocalValue('enabled', 'colorScale')(v)}
                        isToggledOff={!localValue.colorScale.enabled}
                    >
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            <BaseSelectWithLabel
                                disabled={!localValue.colorScale.enabled}
                                size="small"
                                label="Field"
                                value={localValue.colorScale?.attribute}
                                onChange={updateLocalValue('attribute', 'colorScale')}
                                options={edgeDataSchema.numericOptions}
                                fullWidth
                            />

                            <Stack>
                                <Typography>From</Typography>
                                <ColorPicker
                                    disabled={!localValue.colorScale.enabled}
                                    value={localValue.colorScale?.colorFrom}
                                    onChange={updateLocalValue('colorFrom', 'colorScale')}
                                />
                            </Stack>

                            <Stack>
                                <Typography>From</Typography>
                                <ColorPicker
                                    disabled={!localValue.colorScale.enabled}
                                    value={localValue.colorScale?.colorTo}
                                    onChange={updateLocalValue('colorTo', 'colorScale')}
                                />
                            </Stack>
                        </Stack>
                        <BaseButton
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                            onClick={applyChanges('colorScale')}
                        >
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Size Scale
                    ========================================= */}
                    <StyledWidgetAccordion
                        title="Size Scale"
                        hasBottomBorder
                        hasToggle
                        onToggle={(v) => updateLocalValue('enabled', 'sizeScale')(v)}
                        isToggledOff={!localValue.sizeScale.enabled}
                    >
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            <BaseSelectWithLabel
                                disabled={!localValue.sizeScale.enabled}
                                size="small"
                                label="Field"
                                value={localValue.sizeScale?.attribute}
                                onChange={updateLocalValue('attribute', 'sizeScale')}
                                options={edgeDataSchema.numericOptions}
                                fullWidth
                            />
                            <Typography>Size Range</Typography>
                            <RangeSliderSelector
                                min={1}
                                max={100}
                                onChange={updateSizeScale}
                                values={[localValue?.sizeScale?.sizeFrom || 1, localValue?.sizeScale?.sizeTo || 10]}
                                disabled={!localValue.sizeScale.enabled}
                            />
                        </Stack>
                        <BaseButton
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                            onClick={applyChanges('sizeScale')}
                        >
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>
                </Stack>
            )}
        </React.Fragment>
    )
}
