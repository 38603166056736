export const formatValue = (data: any, precision = 2) => {
    if (typeof data == 'number') {
        return Math.round(data * Math.pow(10, precision)) / Math.pow(10, precision)
    }
    if (typeof data == 'object' || Array.isArray(data)) {
        return JSON.stringify(data)
    }
    return data
}

export const formatLabel = (data: string) => {
    if (data == null) return 'Unkown'

    try {
        const dataArray = data.split(/[.,\/\s-_]/)
        return dataArray
            .map((word) => {
                return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
    } catch { }
    return data
}

export const buildAbbreviation = (title: String) => {
    if (title) {
        const items = title.split(/\s+/)
        let res = ''
        for (let item of items) {
            if (item && item.length > 0) {
                res += item.substring(0, 1)
            }
        }
        return res.toUpperCase()
    }
    return null
}
