import { v4 as uuidv4 } from 'uuid'
import * as math from 'mathjs'
import { sampleCorrelation, sampleCovariance } from 'simple-statistics'
import moment from 'moment'
import { cloneDeep, uniq } from 'lodash'
import { toast } from 'react-toastify'
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    ChartWidgetType,
    FilterRecordType,
    FilterWidgetType,
    NetworkWidgetType,
    ReportDataSourceAttributeType,
    ReportDataSourceRowType,
    ReportDataSourceType,
    ReportSavedStateType,
    ReportSlideType,
    ReportWidgetContentKindType,
    ReportWidgetType,
    SelectedDataSourceType,
    TableWidgetType,
    InsightWidgetType,
    ReportDataAggregationMethodType,
    ReportWidgetContentType,
    ReportNetworkDataSourceType,
    ReportConnectedWidgetType,
    ReportFilterCompareType,
    ReportDataSourceCalcualtedValueType,
    ReportDataSourceDataType,
} from 'features/report-designer/types/reportDesigner.types'
import {
    defaultReportWidgetValues,
    defaultTextWidgetValues,
    defaultImageWidgetValues,
    defaultVideoWidgetValues,
    defaultChartWidgetValues,
    defaultNetworkWidgetValues,
    defaultFilterWidgetValues,
    defaultTableWidgetValues,
    defaultInsightWidgetValues,
    defaultInfoWidgetValues,
    defaultNavLinkWidgetValues,
    defaultHorizontalLineWidgetValues,
    defaultVerticalLineWidgetValues,
    defaultAIWidgetValues,
    defaultDynamicControlWidgetValues,
    defaultPanelWidgetValues,
} from 'features/report-designer/helpers/reportDesignerDefaultValues'
import { NodeAttributeType, NodeType } from 'features/network-viz/types/NetworkViz.types'
import { PaletteOptions } from '@mui/material'
import { NODE_ANALYTICS_METRICS } from 'features/network-viz/constants/NetworkViz.const'
import WebWorker from 'helpers/webWorkerHelper'
import { UpdateReport } from 'services/ReportApi'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import {
    BarChartDataDefinitionType,
    BoxplotChartDataDefinitionType,
    GaugeChartDataDefinitionType,
    LineChartDataDefinitionType,
    PictorialBarChartDataDefinitionType,
    PieChartDataDefinitionType,
    RadarChartDataDefinitionType,
    ScatterChartDataDefinitionType,
    TreemapChartDataDefinitionType,
    WordCloudChartDataDefinitionType,
} from 'features/chart/Chart.asset'
import {
    FlexibleSelectGroupedOptionType,
    FlexibleSelectOptionType,
    FlexibleSelectUngroupedOptionType,
} from 'components/group-field-selector/FlexibleSelect'
import { TableDefinitionModeEachRow } from '../widgets/table-widget/helpers/TableWidget.asset'
import { ExpressionEditorSuggestionType } from 'features/ExpressionEditor/ExpressionEditor'

/* =========================================
 * General
 */

export function formatValueAsLabel(value: any): string {
    // Strings
    if (typeof value === 'string') {
        try {
            const wordsArray = value
                .trim()
                .replaceAll('#', '')
                .split(/[.,/\s-_]/)

            return wordsArray
                .map((_word) => {
                    return _word.charAt(0).toUpperCase() + _word.slice(1)
                })
                .join(' ')
        } catch {
            return value.toString()
        }
    }
    // Numbers
    else if (typeof value === 'number') {
        return value.toString()
    }
    // Dates
    else if (value instanceof Date) {
        const momentString = moment(value).format('DD/MM/YYYY')

        return momentString.replaceAll('/', '.')
    }
    // Others
    else {
        return value?.toString() || '(unnameable)'
    }
}

type ApplyAggregationParams = {
    method: ReportDataAggregationMethodType
    data: ReportDataSourceRowType[]
    field: string
    // This is used for computing the Correlation and Covariance
    secondaryField?: string
    defaultValue?: number
}

export function applyAggregation({ method, data, field, secondaryField, defaultValue }: ApplyAggregationParams) {
    // Count
    if (method === 'count') return data.length

    // Invalid params return value
    if (field.trim() === '') return null

    if (method === 'expression') {
        const scope: Record<string, Record<string, any[] | Record<string, any[]>>> = {}
        for (let row of data) {
            for (let key in row) {
                const splitKey = key.split('.')
                if (splitKey[0] === 'basic' && splitKey.length === 2) {
                    if (scope['info'] === undefined) scope['info'] = {}
                    const values = scope['info'][splitKey[1]]
                    if (Array.isArray(values)) {
                        values.push(row[key])
                    } else {
                        scope['info'][splitKey[1]] = [row[key]]
                    }
                } else if (splitKey[0] === 'analytic' && splitKey.length === 3) {
                    if (scope['analytics'] === undefined) scope['analytics'] = {}
                    if (scope['analytics'][splitKey[1]] === undefined) scope['analytics'][splitKey[1]] = {}
                    const networkObject = scope['analytics'][splitKey[1]] as Record<string, any[]>
                    const values = networkObject[splitKey[2]]
                    if (Array.isArray(values)) {
                        values.push(row[key])
                    } else {
                        networkObject[splitKey[2]] = [row[key]]
                    }
                }
            }
        }
        const expression = field.startsWith('basic.') ? field.substring('basic.'.length) : field

        return math.evaluate(expression, scope)
    }

    const cleanArray = cleanVector(data, field, defaultValue)

    if (cleanArray.length === 0) return 0

    switch (method) {
        case 'max':
            return math.round(math.max(cleanArray), 8)
        case 'min':
            return math.round(math.min(cleanArray), 8)
        case 'avg':
            return math.round(math.mean(cleanArray), 8)
        case 'sum':
            return math.round(math.sum(cleanArray), 8)
        case 'median':
            return math.round(math.median(cleanArray), 8)
        case 'q1':
            return math.round(math.quantileSeq(cleanArray, 1 / 4, false) as number, 8)
        case 'q3':
            return math.round(math.quantileSeq(cleanArray, 3 / 4, false) as number, 8)
        case 'correlation': {
            // Invalid params return value
            if (secondaryField === undefined) return null

            const correlationCleanArray = cleanVector(data, secondaryField, defaultValue)

            if (correlationCleanArray.length < 2) return null

            return math.round(sampleCorrelation(cleanArray, correlationCleanArray), 8)
        }
        case 'covariance': {
            // Invalid params return value
            if (secondaryField === undefined) return null

            const covarianceCleanArray = cleanVector(data, secondaryField, defaultValue)

            if (covarianceCleanArray.length < 2) return null

            return math.round(sampleCovariance(cleanArray, covarianceCleanArray), 8)
        }

        default:
            return null
    }
}

/* =========================================
 * Data source helpers
 */

export function getChartAttributes(
    type: ChartWidgetType['type'],
    dataDefinition: ChartWidgetType['dataDefinition']
): ReportDataSourceAttributeType[] {
    switch (type) {
        case 'line': {
            const lineDataDefinition = dataDefinition as LineChartDataDefinitionType
            return [lineDataDefinition.xAxisField, ...lineDataDefinition.series.map((x) => x.attribute)]
        }
        case 'bar': {
            const barDataDefinition = dataDefinition as BarChartDataDefinitionType
            return [barDataDefinition.xAxisField, ...barDataDefinition.series.map((x) => x.attribute)]
        }
        case 'pie': {
            const pieDataDefinition = dataDefinition as PieChartDataDefinitionType
            return [pieDataDefinition.categoryField, pieDataDefinition.series.attribute]
        }
        case 'scatter': {
            const scatterDataDefinition = dataDefinition as ScatterChartDataDefinitionType
            return [scatterDataDefinition.xAxisField, scatterDataDefinition.yAxisField]
        }
        case 'radar': {
            const radarDataDefinition = dataDefinition as RadarChartDataDefinitionType
            const attributes = [...radarDataDefinition.indicators.map((x) => x.field)]
            for (let series of radarDataDefinition.series) {
                attributes.push(series.attribute)
                if (series.fieldLabel !== undefined) attributes.push(series.fieldLabel)
            }
            return attributes
        }
        case 'gauge': {
            const gaugeDataDefinition = dataDefinition as GaugeChartDataDefinitionType
            const attributes: ReportDataSourceAttributeType[] = []
            if (gaugeDataDefinition.indicator.aggregationMethod === 'expression') {
                try {
                    const parsedExpr = math.parse(gaugeDataDefinition.indicator.field.field)
                    const variables = collectExpressionVariableVariables(parsedExpr)

                    for (let variable of variables) {
                        const splitVariable = variable.split('.')
                        switch (splitVariable[0]) {
                            case 'info':
                                if (splitVariable.length === 2)
                                    attributes.push({
                                        type: 'basic',
                                        field: splitVariable[1],
                                    })
                                break
                            case 'analytics':
                                if (splitVariable.length === 3)
                                    attributes.push({
                                        type: 'analytic',
                                        relationship: splitVariable[1],
                                        field: splitVariable[2],
                                    })
                                break
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
            } else {
                attributes.push(gaugeDataDefinition.indicator.field)
                if (
                    gaugeDataDefinition.indicator.compareField &&
                    (gaugeDataDefinition.indicator.aggregationMethod === 'correlation' ||
                        gaugeDataDefinition.indicator.aggregationMethod === 'covariance')
                ) {
                    attributes.push(gaugeDataDefinition.indicator.compareField)
                }
            }

            for (let series of gaugeDataDefinition.series) {
                attributes.push(series.attribute)
                if (series.fieldLabel !== undefined) attributes.push(series.fieldLabel)
            }
            return attributes
        }
        case 'boxplot': {
            const boxplotDataDefinition = dataDefinition as BoxplotChartDataDefinitionType
            return [boxplotDataDefinition.categoryField, boxplotDataDefinition.valueField]
        }
        case 'pictorialBar': {
            const pictorialBarDataDefinition = dataDefinition as PictorialBarChartDataDefinitionType
            const attributes = [pictorialBarDataDefinition.categoryField]
            if (pictorialBarDataDefinition.valueField) {
                attributes.push(pictorialBarDataDefinition.valueField)
            }
            return attributes
        }
        case 'wordCloud': {
            const wordCloudDataDefinition = dataDefinition as WordCloudChartDataDefinitionType
            return [wordCloudDataDefinition.categoryField]
        }
        case 'treemap': {
            const treemapDataDefinition = dataDefinition as TreemapChartDataDefinitionType
            return [
                treemapDataDefinition.labelField!,
                treemapDataDefinition.valueField!,
                ...treemapDataDefinition.groupByFields,
            ]
        }
    }
    return []
}

export type ReportDataSourceDataRowTies = {
    id: number | string
    source: string
    sourceLabel: string
    target: string
    targetLabel: string
    relationship?: string
    color?: string
    tooltip?: string
}

export function getWidgetDataSource(
    dataSources: ReportDesignerStoreStateType['dataSources'],
    widget: ReportWidgetType
): ReportDataSourceType | undefined {
    if (widget.content.kind === 'network') {
        const networkWidget = widget.content.details as NetworkWidgetType
        return dataSources.find((_dataSource) => _dataSource.id === networkWidget.networkDatasourceId)
    } else if ('selectedDataSource' in widget.content.details) {
        const selectedDataSource = widget.content.details.selectedDataSource
        if (selectedDataSource === null) {
            return undefined
        }
        return dataSources.find((_dataSource) => _dataSource.id === selectedDataSource.id)
    }

    return undefined
}

export function getDataSourceData(
    dataSources: ReportDesignerStoreStateType['dataSources'],
    selectedDataSource: SelectedDataSourceType | null,
    // list of the attributes from the selected datasource that should be retrieved
    attributes: ReportDataSourceAttributeType[],
    // dynamic filters that should be applied to the data
    dynamicFilters?: FilterRecordType | null,
    compareWith?: TableDefinitionModeEachRow['compareWith'],
    widgetContent?: ReportWidgetType['content']
): ReportDataSourceRowType[] | undefined {
    if (selectedDataSource === null) {
        return undefined
    }
    // find the selected datasource
    const targetDataSource = dataSources.find((_dataSource) => _dataSource.id === selectedDataSource.id)

    // combine static and dynamic filters
    const filters: ReportFilterCompareType[] = structuredClone(selectedDataSource.filters)

    // Adding dynamic filters (applied by the Filter widget)
    if (dynamicFilters !== undefined && dynamicFilters !== null) {
        for (const _widgetId in dynamicFilters) {
            if (Object.prototype.hasOwnProperty.call(dynamicFilters, _widgetId)) {
                const _filters = dynamicFilters[_widgetId]

                if (_filters != null) {
                    filters.push(..._filters)
                }
            }
        }
    }

    if (targetDataSource === undefined) {
        return undefined
    }

    // if type of selected datasource is network
    if (targetDataSource.mode === 'network') {
        const analytics = targetDataSource.presets[selectedDataSource.preset!]?.analytics

        if (analytics == null) return undefined

        const resultData: ReportDataSourceRowType[] = []
        switch (selectedDataSource.type || 'nodes') {
            case 'nodes':
                const edges = targetDataSource.presets[selectedDataSource.preset!].edgeRenders
                targetDataSource.presets[selectedDataSource.preset!].nodeRenders.forEach((_nodeRender) => {
                    if (_nodeRender.hide) return

                    const row: NodeType = { id: _nodeRender.id }
                    const node = targetDataSource.networkContext.nodes[_nodeRender.id]

                    // Filter out rows of data which have invalid values and also rows which should be removed based on the "filters".
                    if (!checkRowMatchesFilters(node, filters, analytics, edges)) return

                    for (let _attribute of attributes) {
                        if (_attribute.field.trim() === '') continue

                        const key = convertNodeAttributeToKeyString(_attribute)

                        row[key] = getRowValue(_attribute, node, analytics)
                    }

                    resultData.push(row)
                })

                break

            case 'graph':
                {
                    const row: ReportDataSourceRowType = {}

                    if (analytics === null) {
                        return undefined
                    }

                    for (let _attribute of attributes) {
                        if (_attribute.type === 'analytic') {
                            const key = convertNodeAttributeToKeyString(_attribute)

                            row[key] =
                                _attribute.relationship in analytics
                                    ? analytics[_attribute.relationship]?.graph[_attribute.field]
                                    : analytics[_attribute.relationship.toLowerCase()]?.graph[_attribute.field]
                        }
                    }

                    resultData.push(row)
                }

                break

            case 'ties':
                {
                    const nodes = targetDataSource.networkContext.nodes
                    const edgeRenders = targetDataSource.presets[selectedDataSource.preset!].edgeRenders

                    /*
                     * Note:
                     * At the moment of this writing, TableWidget uses the result of this part of the function and only has
                     * one (1) attribute for user to select and that is the field which will be used as the nodes' "label".
                     * That's why we are doing this manual "attributes[0]" and then returning a custom "ReportDataSourceDataRowTies"
                     * instead of looping over attributes and adding them to each row like the others.
                     */
                    const titleField = attributes[0].field

                    let bidirectionalFiltering = false

                    if (
                        widgetContent !== undefined &&
                        widgetContent.kind === 'table' &&
                        widgetContent.details.dataDefinition.mode === 'ties'
                    ) {
                        bidirectionalFiltering = widgetContent.details.dataDefinition.bidirectionalFiltering || false
                    }

                    for (let _edge of edgeRenders) {
                        // Ignore hidden edges
                        if (_edge.hide) continue

                        // Filter out rows of data which have invalid values and also rows which should be removed based on the "filters".
                        if (
                            !checkRowMatchesFilters(
                                targetDataSource.networkContext.nodes[_edge.source],
                                filters,
                                analytics
                            ) ||
                            (bidirectionalFiltering &&
                                !checkRowMatchesFilters(
                                    targetDataSource.networkContext.nodes[_edge.target],
                                    filters,
                                    analytics
                                ))
                        )
                            continue

                        const row: ReportDataSourceDataRowTies = {
                            id: _edge.id,
                            source: _edge.source, // source node ID
                            sourceLabel: '',
                            target: _edge.target, // target node ID
                            targetLabel: '',
                        }

                        row.relationship = _edge.groupKey
                        row.color = _edge.lineStyle.color

                        if (nodes[_edge.source] == null) {
                            //targetDataSource.networkContext.networkShrink?.enabled) {
                            row.sourceLabel = `${_edge.source}`
                            row.targetLabel = `${_edge.target}`
                            row.tooltip = `Frequency ${_edge.innerEdges?.length || 0}`
                        } else if (titleField !== undefined && titleField.trim() !== '') {
                            row.sourceLabel = nodes[_edge.source][titleField] || '(undefined)'
                            row.targetLabel = nodes[_edge.target][titleField] || '(undefined)'
                        } else {
                            row.sourceLabel = `Node ${_edge.source}`
                            row.targetLabel = `Node ${_edge.target}`
                        }

                        resultData.push(row)
                    }
                }
                break

            case 'ergm':
                if (analytics === null) return undefined
                for (let _network in analytics) {
                    const ergm = analytics[_network].ergm
                    if (ergm === undefined) continue

                    ergm.params_stats.forEach((param, idx) => {
                        const row: ReportDataSourceRowType = {
                            name: param.name,
                            relationship: _network,
                            converged: ergm.converged.toString(),
                            log_likelihood: ergm.log_likelihood,
                            pseudo_r_squared: ergm.pseudo_r_squared,
                            coef: param.coef,
                            p_values: param.p_values,
                            z: param.z,
                            std_err: param.std_err,
                            conf_int_lb: param.conf_int[0],
                            conf_int_ub: param.conf_int[1],
                        }

                        resultData.push(row)
                    })
                }
                return resultData

            default:
                break
        }

        return [...resultData]
    }
    // Other data source modes
    else {
        const panelData = targetDataSource.data[selectedDataSource.panel || Object.keys(targetDataSource.data)[0]]
        const comparePanel: ReportDataSourceRowType[] | null =
            compareWith?.type === 'panel' ? targetDataSource.data[compareWith.field] : null

        const result: ReportDataSourceRowType[] = []

        for (const _row of panelData) {
            if (checkRowMatchesFilters(_row, filters)) {
                const row: ReportDataSourceRowType = {}

                for (let attribute of attributes) {
                    const key = convertNodeAttributeToKeyString(attribute)

                    row[key] = _row[attribute.field]
                    row[`compare_${key}`] = comparePanel?.find((x) => x.id === _row.id)?.[attribute.field]
                }

                result.push(row)
            }
        }
        return result
    }
}

// This function will generate select options for a given datasource
export function getDataSourceFields(
    dataSources: ReportDataSourceType[],
    selectedDataSource: SelectedDataSourceType | null,
    numericOnly: boolean = false,
    collectCalculatedValues: boolean = true
): FlexibleSelectOptionType[] {
    if (selectedDataSource === null) {
        return []
    }

    const targetDataSource = dataSources.find((_dataSource) => _dataSource.id === selectedDataSource.id)

    if (targetDataSource === undefined) {
        return []
    }

    // Network
    if (targetDataSource.mode === 'network') {
        switch (selectedDataSource.type || 'nodes') {
            case 'nodes': {
                const result: FlexibleSelectGroupedOptionType[] = []

                const attributesGroup: FlexibleSelectGroupedOptionType = {
                    group: 'Node Attributes',
                    items: [],
                }

                if (numericOnly) {
                    for (let _field of targetDataSource.presets[selectedDataSource.preset!]?.nodeDataSchema
                        .numericOptions) {
                        attributesGroup.items.push({
                            label: formatValueAsLabel(_field),
                            value: {
                                type: 'basic',
                                field: _field,
                            },
                        })
                    }
                } else {
                    for (let _field in targetDataSource.presets[selectedDataSource.preset!]?.nodeDataSchema.fields) {
                        attributesGroup.items.push({
                            label: formatValueAsLabel(_field),
                            value: {
                                type: 'basic',
                                field: _field,
                            },
                        })
                    }
                }

                result.push(attributesGroup)

                if (
                    collectCalculatedValues &&
                    targetDataSource.calcuatedValues &&
                    targetDataSource.calcuatedValues.length > 0
                ) {
                    const calculatedValuesGroup: FlexibleSelectGroupedOptionType = {
                        group: 'Calculated Values',
                        items: targetDataSource.calcuatedValues.map((calculatedValue) => ({
                            label: calculatedValue.name,
                            value: {
                                type: 'calculated',
                                field: calculatedValue.name,
                                expression: calculatedValue.expression,
                            },
                        })),
                    }

                    result.push(calculatedValuesGroup)
                }

                if (
                    targetDataSource.presets[selectedDataSource.preset!]?.analytics?.view &&
                    targetDataSource.presets[selectedDataSource.preset!]?.analytics?.all
                ) {
                    for (let _relation in targetDataSource.presets[selectedDataSource.preset!]?.analytics) {
                        const analyticsGroup: FlexibleSelectGroupedOptionType = {
                            group: `Node Analytics - ${_relation}`,
                            items: NODE_ANALYTICS_METRICS.map((_metric) => ({
                                label: _metric.label,
                                value: {
                                    type: 'analytic',
                                    relationship: _relation,
                                    field: _metric.value,
                                },
                            })),
                        }

                        result.push(analyticsGroup)
                    }
                }

                return result
            }

            case 'graph': {
                const result: FlexibleSelectGroupedOptionType[] = []

                if (
                    targetDataSource.presets[selectedDataSource.preset!]?.analytics?.view &&
                    targetDataSource.presets[selectedDataSource.preset!]?.analytics?.all
                ) {
                    for (let _relation in targetDataSource.presets[selectedDataSource.preset!].analytics) {
                        const analyticsGroup: FlexibleSelectGroupedOptionType = {
                            group: `Graph Analytics - ${_relation}`,
                            items: Object.keys(
                                targetDataSource.presets[selectedDataSource.preset!].analytics![_relation].graph
                            ).map((_analytic: any) => ({
                                label: _analytic,
                                value: {
                                    type: 'analytic',
                                    relationship: _relation,
                                    field: _analytic,
                                },
                            })),
                        }

                        result.push(analyticsGroup)
                    }
                }

                return result
            }

            case 'ergm': {
                const result: FlexibleSelectUngroupedOptionType[] = [
                    {
                        label: 'Name',
                        value: {
                            type: 'basic',
                            field: 'name',
                        },
                    },
                    {
                        label: 'Relationship',
                        value: {
                            type: 'basic',
                            field: 'relationship',
                        },
                    },
                    {
                        label: 'Converged',
                        value: {
                            type: 'basic',
                            field: 'converged',
                        },
                    },
                    {
                        label: 'Likelihood',
                        value: {
                            type: 'basic',
                            field: 'log_likelihood',
                        },
                    },
                    {
                        label: 'Relationship',
                        value: {
                            type: 'basic',
                            field: 'relationship',
                        },
                    },
                    {
                        label: 'Pseudo R-Squared',
                        value: {
                            type: 'basic',
                            field: 'pseudo_r_squared',
                        },
                    },
                    {
                        label: 'Coefficient',
                        value: {
                            type: 'basic',
                            field: 'coef',
                        },
                    },
                    {
                        label: 'Confidence Interval Lower Bound',
                        value: {
                            type: 'basic',
                            field: 'conf_int_lb',
                        },
                    },
                    {
                        label: 'Confidence Interval Upper Bound',
                        value: {
                            type: 'basic',
                            field: 'conf_int_ub',
                        },
                    },
                    {
                        label: 'P-Values',
                        value: {
                            type: 'basic',
                            field: 'p_values',
                        },
                    },
                    {
                        label: 'Standard Error',
                        value: {
                            type: 'basic',
                            field: 'std_err',
                        },
                    },
                    {
                        label: 'Z',
                        value: {
                            type: 'basic',
                            field: 'z',
                        },
                    },
                ]

                return result
            }

            case 'ties': {
                const result: FlexibleSelectGroupedOptionType[] = []

                const attributesGroup: FlexibleSelectGroupedOptionType = {
                    group: 'Node Attributes',
                    items: [],
                }

                for (let field in targetDataSource.presets[selectedDataSource.preset!]?.nodeDataSchema.fields) {
                    attributesGroup.items.push({
                        label: formatValueAsLabel(field),
                        value: {
                            type: 'basic',
                            field: field,
                        },
                    })
                }

                result.push(attributesGroup)

                return result
            }

            default:
                return []
        }
    }
    // Other data source modes
    else {
        const result: FlexibleSelectUngroupedOptionType[] = targetDataSource.fields.map((_field) => ({
            label: formatValueAsLabel(_field),
            value: {
                type: 'basic',
                field: _field,
            },
        }))

        return result
    }
}

export const getDataSourceFieldValues = (
    dataSource: ReportDataSourceType,
    field: ReportDataSourceAttributeType,
    networkPreset?: NetworkWidgetType['selectedPreset']
): string[] => {
    if (field.field.trim() === '') return []

    // In
    if (dataSource.mode === 'network') {
        if (field.type === 'analytic') {
            return uniq(
                Object.values(
                    dataSource.presets[networkPreset || 'default'].analytics?.[field.relationship]?.nodes || {}
                )
                    .map((_node: any) => _node[field.field])
                    .sort((v1, v2) => v1 - v2)
            )
        }

        if (dataSource.networkContext.nodeDataSchema.fields[field.field]?.type === 'string') {
            return dataSource.networkContext.nodeDataSchema.fields[field.field].range as string[]
        }

        if (dataSource.networkContext.nodeDataSchema.fields[field.field]?.type === 'number') {
            return dataSource.networkContext.nodeDataSchema.fields[field.field].range.values as string[]
        }
    } else {
        const values = new Set<string>()

        for (let _panel in dataSource.data) {
            const dataPanel = dataSource.data[_panel]

            dataPanel.forEach((_row) => values.add(_row[field.field] + ''))
        }

        return Array.from(values)
    }

    return []
}

const checkLessMoreCondition = (value: any, filterItem: ReportFilterCompareType) => {
    if (['lt', 'lte', 'gt', 'gte'].includes(filterItem.operator)) {
        const n1 = Number(value)
        const n2 = Number(filterItem.value)
        if (isNaN(n1) || isNaN(n2)) return false

        switch (filterItem.operator) {
            case 'gt':
                return n1 > n2
            case 'gte':
                return n1 >= n2
            case 'lt':
                return n1 < n2
            case 'lte':
                return n1 <= n2
        }
    }
    return false
}

const checkCompareCondition = (value: any, filterItem: ReportFilterCompareType) => {
    if (filterItem)
        switch (filterItem.operator) {
            case 'eq':
                return String(value).toLowerCase() === String(filterItem.value).toLowerCase()
            case 'neq':
                return String(value).toLowerCase() !== String(filterItem.value).toLowerCase()
            case 'containsAny':
                return filterItem.value?.some((v) => String(v).toLowerCase() === String(value).toLowerCase()) ?? false
            case 'notContainsAny':
                return !(
                    filterItem.value?.some((v) => String(v).toLowerCase() === String(value).toLowerCase()) ?? false
                )
            case 'between':
                if (filterItem.value === null) return true
                const n1 = Number(value)

                if (filterItem.value[0] !== null) {
                    const n2 = Number(filterItem.value[0])
                    if (isNaN(n2)) return false
                    if (n1 < n2) return false
                }

                if (filterItem.value[1] !== null) {
                    const n3 = Number(filterItem.value[1])
                    if (isNaN(n3)) return false
                    if (n1 > n3) return false
                }
                return true
            default:
                return checkLessMoreCondition(value, filterItem)
        }

    return false
}

// A helper function to check if a given node/row matches an array of filters
export function checkRowMatchesFilters(
    row: ReportDataSourceRowType,
    filters: ReportFilterCompareType[],
    // Only needed if type of selected data source is network
    analytics?: NetworkVizContextType['analytics'],
    edges?: NetworkVizContextType['edgeRenders']
): boolean {
    for (let filter of filters) {
        if (filter === null) continue

        if (filter.field === null) continue
        const rowValue = getRowValue(filter.field, row, analytics)
        if (!checkCompareCondition(rowValue, filter)) return false
    }
    return true
}

export function convertNodeAttributeToKeyString(attribute: ReportDataSourceAttributeType): string {
    // ToDo - It's better to return null instead of empty string
    if (attribute === null || attribute === undefined) return ''

    switch (attribute.type) {
        case 'analytic':
        case 'graph':
        case 'ergm':
            return attribute.type + '.' + attribute.relationship + '.' + attribute.field
        case 'basic':
        case 'custom':
        case 'calculated':
            return attribute.type + '.' + attribute.field
        case 'alaam':
            return attribute.type + '.' + attribute.targetField + '.' + attribute.relationship + '.' + attribute.field
    }
}

export function convertStringToNodeAttribute(attribute: string): ReportDataSourceAttributeType | null {
    const splitAttribute = attribute.split('.')

    switch (splitAttribute[0]) {
        case 'analytic':
        case 'graph':
            return {
                type: splitAttribute[0],
                relationship: splitAttribute[1],
                field: splitAttribute[2],
            }
        case 'basic':
            return {
                type: splitAttribute[0],
                field: splitAttribute[1],
            }
        case 'custom':
            return {
                type: splitAttribute[0],
                field: splitAttribute[1] as 'Each Row' | 'Total',
            }
        case 'calculated':
            return {
                type: splitAttribute[0],
                field: splitAttribute[1],
                expression: '',
            }
    }
    return null
}

export function convertNetworkNodeAttributeToKeyString(attribute: NodeAttributeType): string {
    switch (attribute.source) {
        case 'analytic':
            return attribute.source + '.' + attribute.relationship + '.' + attribute.field
        case 'info':
            return attribute.source + '.' + attribute.field
    }
}

export function areReportNodeAttributesEqual(
    attribute1: ReportDataSourceAttributeType[],
    attribute2: ReportDataSourceAttributeType[]
): boolean {
    if (attribute1.length !== attribute2.length) return false
    for (let i = 0; i < attribute1.length; i++) {
        if (convertNodeAttributeToKeyString(attribute1[i]) !== convertNodeAttributeToKeyString(attribute2[i]))
            return false
    }
    return true
}

export function convertNetworkNodeAttributeToReportDataSourceAttribute(
    attribute: NodeAttributeType
): ReportDataSourceAttributeType {
    if (attribute.source === 'analytic') {
        return {
            type: 'analytic',
            relationship: attribute.relationship,
            field: attribute.field,
        }
    } else {
        return {
            type: 'basic',
            field: attribute.field,
        }
    }
}

export function convertNodeAttributeToLabel(attribute: ReportDataSourceAttributeType | NodeAttributeType): string {
    if ('type' in attribute) {
        switch (attribute.type) {
            case 'analytic':
            case 'graph':
            case 'ergm':
                if (attribute.relationship === 'view') return `${attribute.field}`
                else return `${attribute.field} of network ${attribute.relationship}`
            case 'basic':
                return `${attribute.field}`
            case 'custom':
                return `${attribute.field}`
            case 'calculated':
                return `${attribute.field}`
            case 'alaam':
                return `${attribute.field} of network ${attribute.relationship}`
        }
    } else {
        switch (attribute.source) {
            case 'analytic':
                if (attribute.relationship === 'view') return `${attribute.field}`
                else return `${attribute.field} of network ${attribute.relationship}`
            case 'info':
                return `${attribute.field}`
        }
    }
}

function getRowValue(
    attribute: ReportDataSourceAttributeType,
    row: Record<string, any>, // Only needed if type of selected data source is network
    analytics?: NetworkVizContextType['analytics']
) {
    switch (attribute.type) {
        case 'analytic':
            const id = row['id'] + ''
            return analytics?.[attribute.relationship]?.nodes[id][attribute.field]
        case 'basic':
            return row[attribute.field]
        case 'calculated':
            return evaluateExpression(attribute.expression, row, analytics)
    }
}

export function evaluateExpression(
    expression: string,
    row: Record<string, any>,
    analytics?: NetworkVizContextType['analytics']
) {
    const nodeAnalytics: Record<string, Record<string, Record<string, any>>> = {}

    // This part not very efficient, but it's okay for now
    // ToDo - Optimize this part
    if (analytics) {
        for (let network in analytics) {
            for (let nodeId in analytics[network].nodes) {
                if (nodeAnalytics[nodeId] === undefined) nodeAnalytics[nodeId] = {}
                nodeAnalytics[nodeId][network] = analytics[network].nodes[nodeId]
            }
        }
    }

    // using row.id is not a good idea, but it's okay for now
    // ToDo - improve this part
    const scope = {
        info: row,
        analytics: nodeAnalytics[row.id],
    }
    return computeCalculatedAttribute(expression, scope)
}

function getValueFromWidget(
    attribute: ReportDataSourceAttributeType,
    rowId: string,
    widget: ReportWidgetContentType,
    datasources: ReportDataSourceType[]
) {
    switch (widget.kind) {
        case 'network':
            const networkDetails = widget.details as NetworkWidgetType
            const { selectedPreset, networkDatasourceId } = networkDetails
            const networkDataSource = datasources.find(
                (x) => x.id === networkDatasourceId
            ) as ReportNetworkDataSourceType
            const analytics = networkDataSource.presets[selectedPreset]?.analytics
            const node = networkDataSource.networkContext.nodes[rowId]
            return getRowValue(attribute, node, analytics)
        case 'table':
            const tableDetails = widget.details as TableWidgetType
            const { selectedDataSource } = tableDetails
            if (selectedDataSource !== null) {
                const dataSource = datasources.find((x) => x.id === selectedDataSource.id) as ReportDataSourceType

                switch (dataSource?.mode) {
                    case 'network':
                        const networkDataSource = dataSource as ReportNetworkDataSourceType
                        const analytics = networkDataSource.presets[selectedDataSource.preset || 'default']?.analytics
                        const node = networkDataSource.networkContext.nodes[rowId]
                        return getRowValue(attribute, node, analytics)
                    default:
                        if ('data' in dataSource) {
                            const row = dataSource.data[
                                selectedDataSource.panel || Object.keys(dataSource.data)[0]
                            ]?.find((x) => x.id === rowId)
                            if (row) {
                                return getRowValue(attribute, row)
                            }
                        }
                }
            }

            break
    }
    throw new Error("Can't get value from widget")
}

export function groupDataSourcePanelToMap(data: ReportDataSourceRowType[], field: string) {
    const result = new Map<ReportDataSourceRowType[any], ReportDataSourceRowType[]>()

    for (let _row of data) {
        const key = _row[field]

        if (result.has(key)) {
            result.get(key)!.push(_row)
        } else {
            result.set(key, [_row])
        }
    }

    return result
}

//clean the data to only include numuric values
//if not numuric, return 0 for now
//ToDo consider better data cleaning policy, like avg, median, ....
//e.g age: [18,na,26,42,null,...]
export const cleanVector = (data: ReportDataSourceRowType[], field: string, defaultValue?: number) => {
    const cleanArray = data.map((d) => {
        if ((d[field] === null || d[field] === undefined) && defaultValue !== undefined) return defaultValue
        try {
            return parseFloat(d[field]!.toString())
        } catch (e) {
            return NaN
        }
    })
    return cleanArray.filter((x) => !isNaN(x))
}

// export function filterDataSourceData(
//     dataSourceData: ReportDataSourceDataType,
//     datasourceFilters: SelectedDataSourceType['filters'],
//     panelId?: string | number,
//     dynamicFilters?: ChartWidgetType['filters']
// ) {
//     // If panel ID is not provided, we select the first panel in the data source.
//     let dataSourcePanelId = panelId
//     if (dataSourcePanelId === undefined) {
//         dataSourcePanelId = Object.keys(dataSourceData)[0]
//     }

//     const dataPanel = dataSourceData[dataSourcePanelId]

//     const filters: SelectedDataSourceType['filters'] = structuredClone(datasourceFilters)

//     // Adding dynamic filters (applied by the Filter widget)
//     if (dynamicFilters !== undefined) {
//         for (const _widgetId in dynamicFilters) {
//             if (Object.prototype.hasOwnProperty.call(dynamicFilters, _widgetId)) {
//                 const filter = dynamicFilters[_widgetId]

//                 if (filter != null) {
//                     filters.push({
//                         field: filter.field,
//                         values: filter.value,
//                     })
//                 }
//             }
//         }
//     }

//     // Filter out rows of data which have invalid values and also rows which should be removed based on the "filters".
//     if (filters.length > 0) {
//         return dataPanel.filter((_dataRow) => {
//             for (let _filter of filters) {
//                 if (_filter === null) continue

//                 if (Array.isArray(_filter.values)) {
//                     if (
//                         _filter.values.length > 0 &&
//                         _filter.values.includes(_dataRow[_filter.field]) === false &&
//                         _filter.values.includes((_dataRow[_filter.field] + '').toLowerCase()) === false
//                     ) {
//                         return false
//                     }
//                 } else {
//                     if (
//                         _filter.values !== '' &&
//                         _filter.values != null &&
//                         _filter.values !== _dataRow[_filter.field] &&
//                         _filter.values !== (_dataRow[_filter.field] + '').toLowerCase()
//                     ) {
//                         return false
//                     }
//                 }
//             }

//             return true
//         })
//     }

//     return [...dataPanel]
// }

/* =========================================
 * Slide helpers
 */

/*
 * Returns the slide dimensions, based on the aspect ratio, zoom level, and wrapping element's dimensions.
 */
export const determineSlideDimensions = (
    wrapperWidth: number,
    wrapperHeight: number,
    aspectRatio: ReportDesignerStoreStateType['aspectRatio'],
    zoomLevel: ReportSlideType['zoomLevel'],
    zoomStep: number = 0.25
) => {
    let baseDimensions = getBaseDimensions(aspectRatio)

    // If there is enough space for the aspect ratio's width, then we fill the width
    let slideWidth = wrapperWidth
    let slideHeight = (wrapperWidth * aspectRatio[1]) / aspectRatio[0]

    // If there is not enough space for the aspect ratio's width, then we fill the height
    if (slideHeight > wrapperHeight) {
        slideWidth = (wrapperHeight * aspectRatio[0]) / aspectRatio[1]
        slideHeight = wrapperHeight
    }

    const zoomFactor = 1 + zoomLevel * zoomStep

    return {
        width: Math.floor(slideWidth * zoomFactor),
        height: Math.floor(slideHeight * zoomFactor),
        currentScale: Number(((slideWidth * zoomFactor) / baseDimensions.width).toFixed(8)),
    }
}

export const getBaseDimensions = (aspectRatio: ReportDesignerStoreStateType['aspectRatio']) => {
    let baseDimensions = {
        width: 1280,
        height: 720,
    }
    switch (aspectRatio.join(':')) {
        case '16:9':
            baseDimensions = {
                width: 1280,
                height: 720,
            }

            break

        case '4:3':
            baseDimensions = {
                width: 960,
                height: 720,
            }

            break

        default:
            break
    }

    return baseDimensions
}

type DetermineSlideOffsetParams = {
    parentRect: DOMRect
    slideDimensions: {
        width: number
        height: number
    }
}
export const determineSlideOffset = ({ parentRect, slideDimensions }: DetermineSlideOffsetParams) => {
    const topOffset = parentRect.top
    // We take slide's width into consideration because in the view, we are always centering the slide within its parent.
    // Math.max() handles the case in which slide is zoomed-in and has bigger dimensions than its parent. In such a case,
    // the offset left would be the parent's offset value, since the slide's offset would be either smaller (due to scrolling)
    // or even a negative number (out of the viewport's bounds).
    const leftOffset = Math.max((parentRect.width - slideDimensions.width) / 2, 0) + parentRect.left

    return {
        top: topOffset,
        left: leftOffset,
    }
}

/*
 * Returns the position of an element in percentage, based on its relative position to its parent and
 * the parents's dimensions.
 */
export const getElementRelativePositionPercentage = (
    relativePosition: { top: number; left: number },
    parentDimensions: { width: number; height: number },
    accuracy: number = 10
) => {
    return {
        top: ((relativePosition.top * 100) / parentDimensions.height).toFixed(accuracy),
        left: ((relativePosition.left * 100) / parentDimensions.width).toFixed(accuracy),
    }
}

/*
 * Returns the position of an element in percentage, based on its relative position to its parent and
 * the parents's dimensions.
 */
export const parseWidgetPosition = (position: string | number) => {
    if (typeof position === 'string') {
        return parseFloat(position.replaceAll('%', '').replaceAll('px', ''))
    } else {
        return position
    }
}

/*
 * Returns the dimensions of a widget in percentage, based on its parent "slide" dimensions.
 */
export const getElementRelativeDimensionsPercentage = (
    elementDimensions: { width: number; height: number },
    parentDimensions: { width: number; height: number },
    accuracy: number = 10
) => {
    return {
        width: ((elementDimensions.width * 100) / parentDimensions.width).toFixed(accuracy),
        height: ((elementDimensions.height * 100) / parentDimensions.height).toFixed(accuracy),
    }
}

/*
 * Creates a new widget with relative position and dimensions.
 */
export const createReportWidget = (
    kind: ReportWidgetContentKindType,
    position: ReportWidgetType['position'],
    slideDimensions: { width: number; height: number },
    themeMode: PaletteOptions['mode']
): ReportWidgetType => {
    let widget: ReportWidgetType = {
        ...defaultReportWidgetValues,
        id: uuidv4(),
        // Position should be relative to the slide and in percentage.
        position: {
            top: position.top + '%',
            left: position.left + '%',
        },
    }

    switch (kind) {
        case 'text':
            widget = {
                ...widget,
                title: 'New Text Widget',
                dimensions: {
                    width: 200,
                    height: 60,
                },
                content: {
                    kind: kind,
                    details: defaultTextWidgetValues,
                },
            }

            break

        case 'image':
            widget = {
                ...widget,
                title: 'New Image Widget',
                dimensions: {
                    width: 180,
                    height: 180,
                },
                content: {
                    kind: kind,
                    details: defaultImageWidgetValues,
                },
            }

            break

        case 'chart':
            widget = {
                ...widget,
                title: 'New Chart Widget',
                dimensions: {
                    width: 480,
                    height: 320,
                },
                content: {
                    kind: kind,
                    details: defaultChartWidgetValues,
                },
            }

            break

        case 'network':
            widget = {
                ...widget,
                title: 'New Network Viz Widget',
                dimensions: {
                    width: 480,
                    height: 320,
                },
                content: {
                    kind: kind,
                    details: defaultNetworkWidgetValues,
                },
            }
            break

        case 'filter':
            widget = {
                ...widget,
                title: 'Data Filter',
                dimensions: {
                    width: 300,
                    height: 50,
                },
                content: {
                    kind: kind,
                    details: defaultFilterWidgetValues,
                },
            }

            break

        case 'insight':
            widget = {
                ...widget,
                title: 'Insight Widget',
                dimensions: {
                    width: 300,
                    height: 400,
                },
                content: {
                    kind: kind,
                    details: structuredClone(defaultInsightWidgetValues),
                },
            }

            break

        case 'ai':
            widget = {
                ...widget,
                title: 'AI Widget',
                dimensions: {
                    width: 300,
                    height: 400,
                },
                content: {
                    kind: kind,
                    details: structuredClone(defaultAIWidgetValues),
                },
            }
            break

        case 'table':
            widget = {
                ...widget,
                title: 'Table Widget',
                dimensions: {
                    width: 300,
                    height: 500,
                },
                content: {
                    kind: kind,
                    details: defaultTableWidgetValues,
                },
            }

            break

        case 'video':
            widget = {
                ...widget,
                title: 'New Video Widget',
                dimensions: {
                    width: 480,
                    height: 320,
                },
                content: {
                    kind: kind,
                    details: defaultVideoWidgetValues,
                },
            }

            break

        case 'info':
            widget = {
                ...widget,
                title: 'New Info Widget',
                dimensions: {
                    width: 48,
                    height: 48,
                },
                content: {
                    kind: kind,
                    details: defaultInfoWidgetValues,
                },
            }

            break

        case 'navLink':
            widget = {
                ...widget,
                title: 'New Nav Link Widget',
                dimensions: {
                    width: 140,
                    height: 46,
                },
                content: {
                    kind: kind,
                    details: defaultNavLinkWidgetValues['internal'],
                },
            }

            break

        /*
         * Shapes
         * ========================================= */

        case 'horizontalLine':
            widget = {
                ...widget,
                title: 'Horizontal Line',
                dimensions: {
                    width: 200,
                    height: 25,
                },
                content: {
                    kind: kind,
                    details: defaultHorizontalLineWidgetValues,
                },
            }

            break

        case 'verticalLine':
            widget = {
                ...widget,
                title: 'Vertical Line',
                dimensions: {
                    width: 25,
                    height: 200,
                },
                content: {
                    kind: kind,
                    details: defaultVerticalLineWidgetValues,
                },
            }

            break

        case 'dynamicControl':
            widget = {
                ...widget,
                title: 'Dynamic Control',
                dimensions: {
                    width: 300,
                    height: 50,
                },
                content: {
                    kind: kind,
                    details: defaultDynamicControlWidgetValues,
                },
            }

            break

        case 'panel':
            widget = {
                ...widget,
                title: 'Panel',
                dimensions: {
                    width: 300,
                    height: 500,
                },
                content: {
                    kind: kind,
                    details: defaultPanelWidgetValues,
                },
            }

            break

        default:
            break
    }

    return structuredClone(widget)
}

/* =========================================
 * Widgets helpers
 */

// check if widget represents a group of data rows(nodes) or a single data row (node)
const getWidgetConnectivityConfig = (
    widget: ReportWidgetType['content']
):
    | {
          groupBy: false | ReportDataSourceAttributeType[]
          idField: ReportDataSourceAttributeType
      }
    | false => {
    switch (widget.kind) {
        case 'network':
            const networkConfig = widget.details as NetworkWidgetType
            if (networkConfig.shrinkMode) {
                return {
                    groupBy:
                        networkConfig.nodeGroupBy?.map((x) =>
                            convertNetworkNodeAttributeToReportDataSourceAttribute(x)
                        ) || [],
                    idField: { type: 'basic', field: 'id' },
                }
            } else {
                return {
                    groupBy: false,
                    idField: { type: 'basic', field: 'id' },
                }
            }
        case 'table':
            const tableConfig = widget.details as TableWidgetType
            switch (tableConfig.dataDefinition.mode) {
                case 'aggregation':
                    if (tableConfig.dataDefinition.groupField == null) return false
                    return {
                        groupBy: tableConfig.dataDefinition.groupField ? [tableConfig.dataDefinition.groupField] : [],
                        idField: tableConfig.dataDefinition.groupField,
                    }
                case 'each-row':
                case 'top-k':
                    if (tableConfig.dataDefinition.idField === null) return false
                    return {
                        groupBy: false,
                        idField: tableConfig.dataDefinition.idField,
                    }
                case 'ties':
                    return {
                        groupBy: false,
                        idField: { type: 'basic', field: 'id' },
                    }
                case 'graph':
                    return false
            }
            return false
        case 'insight':
            const insightConfig = widget.details as InsightWidgetType
            if (insightConfig.mode === 'group') {
                if (insightConfig.groupBy == null) return false
                return {
                    idField: insightConfig.groupBy,
                    groupBy: [insightConfig.groupBy],
                }
            } else {
                if (insightConfig.idField === null) return false
                return {
                    idField: insightConfig.idField,
                    groupBy: false,
                }
            }
    }
    return false
}

export type ControllableWidgetType = {
    id: ReportWidgetType['id']
    title: string
    defaultBehavior:
        | {
              mode: 'filter'
              filterAttribute: ReportDataSourceAttributeType
          }
        | {
              mode: 'select'
              selectedAttribute: ReportDataSourceAttributeType | null
          }
    configurable:
        | false
        | {
              modes: ('filter' | 'select')[]
          }
}

const getWidgetConnectedMode = (
    connectedWidget: ReportConnectedWidgetType,
    sourceWidget: ReportWidgetType,
    targetWidget: ReportWidgetType
): 'filter' | 'select' | false => {
    const widgetInteractionMode = getWidgetWidgetInteractionMode(sourceWidget, targetWidget)
    if (widgetInteractionMode === false) return false

    // Default behavior based on interaction mode
    const behavior = widgetInteractionMode.defaultBehavior

    // Determine the mode of operation based on widget configuration and interaction compatibility
    const mode =
        widgetInteractionMode.configurable === false
            ? behavior.mode // Use default mode if not configurable
            : connectedWidget.config && widgetInteractionMode.configurable.modes.includes(connectedWidget.config.mode)
            ? connectedWidget.config.mode // Use connected widget's configured mode if valid and allowed
            : behavior.mode // Fallback to default mode

    return mode
}

const getWidgetWidgetInteractionMode = (
    firstWidget: ReportWidgetType,
    secondWidget: ReportWidgetType
): ControllableWidgetType | false => {
    const { kind: firstWidgetType } = firstWidget.content
    const { kind: secondWidgetType } = secondWidget.content

    switch (firstWidgetType) {
        case 'network':
        case 'table':
            const firstWidgetConnectivityMode = getWidgetConnectivityConfig(firstWidget.content)
            const secondWidgetConnectivityMode = getWidgetConnectivityConfig(secondWidget.content)

            if (firstWidgetConnectivityMode === false) return false

            switch (secondWidgetType) {
                case 'chart':
                    return {
                        configurable:
                            firstWidgetConnectivityMode.groupBy === false
                                ? {
                                      modes: ['filter'],
                                  }
                                : false,
                        id: secondWidget.id,
                        defaultBehavior: {
                            mode: 'filter',
                            filterAttribute: firstWidgetConnectivityMode.idField,
                        },
                        title: secondWidget.title,
                    }
                case 'network':
                case 'table':
                    if (secondWidgetConnectivityMode === false) return false
                    if (firstWidgetConnectivityMode.groupBy !== false) {
                        if (secondWidgetConnectivityMode.groupBy !== false) {
                            if (
                                areReportNodeAttributesEqual(
                                    firstWidgetConnectivityMode.groupBy,
                                    secondWidgetConnectivityMode.groupBy
                                )
                            ) {
                                return {
                                    configurable: false,
                                    id: secondWidget.id,
                                    defaultBehavior: {
                                        mode: 'select',
                                        selectedAttribute: null,
                                    },
                                    title: secondWidget.title,
                                }
                            } else {
                                return false
                            }
                        } else {
                            return {
                                configurable: false,
                                id: secondWidget.id,
                                defaultBehavior: {
                                    mode: 'filter',
                                    filterAttribute: firstWidgetConnectivityMode.idField,
                                },
                                title: secondWidget.title,
                            }
                        }
                    } else {
                        if (secondWidgetConnectivityMode.groupBy !== false) {
                            return {
                                configurable: false,
                                id: secondWidget.id,
                                defaultBehavior: {
                                    mode: 'select',
                                    selectedAttribute: secondWidgetConnectivityMode.idField,
                                },
                                title: secondWidget.title,
                            }
                        } else {
                            return {
                                configurable: {
                                    modes: ['filter', 'select'],
                                },
                                id: secondWidget.id,
                                defaultBehavior: {
                                    mode: 'select',
                                    selectedAttribute: null,
                                },
                                title: secondWidget.title,
                            }
                        }
                    }
                case 'insight':
                    if (secondWidgetConnectivityMode === false) return false
                    if (firstWidgetConnectivityMode.groupBy !== false) {
                        if (
                            secondWidgetConnectivityMode.groupBy !== false &&
                            areReportNodeAttributesEqual(
                                firstWidgetConnectivityMode.groupBy,
                                secondWidgetConnectivityMode.groupBy
                            )
                        ) {
                            return {
                                configurable: false,
                                id: secondWidget.id,
                                defaultBehavior: {
                                    mode: 'select',
                                    selectedAttribute: null,
                                },
                                title: secondWidget.title,
                            }
                        } else {
                            return false
                        }
                    } else {
                        return {
                            configurable: false,
                            id: secondWidget.id,
                            defaultBehavior: {
                                mode: 'select',
                                selectedAttribute: secondWidgetConnectivityMode.idField,
                            },
                            title: secondWidget.title,
                        }
                    }
                default:
                    return false
            }
        case 'filter':
            if (['chart', 'network', 'table'].includes(secondWidgetType)) {
                if (secondWidget.content.kind === 'network' && secondWidget.content.details.shrinkMode) {
                    return false
                }
                return {
                    configurable: false,
                    id: secondWidget.id,
                    defaultBehavior: {
                        mode: 'filter',
                        filterAttribute: firstWidget.content.details.field,
                    },
                    title: secondWidget.title,
                }
            } else {
                return false
            }
        default:
            return false
    }
}

export const determineWidgetInteractionCompatibility = (
    firstWidget: ReportWidgetType,
    secondWidget: ReportWidgetType
) => {
    if (firstWidget.id === secondWidget.id) return false
    return getWidgetWidgetInteractionMode(firstWidget, secondWidget)
}

export const getConnectedWidgets = (
    widget: ReportConnectedWidgetType,
    slideWidgets: ReportSlideType['widgets'],
    visitedWidgets: ReportConnectedWidgetType[] = []
): ReportConnectedWidgetType[] => {
    const targetWidget = slideWidgets.find((_widget) => _widget.id === widget.id)

    if (targetWidget !== undefined) {
        let connectedWidgets: ReportConnectedWidgetType[] = [widget]

        if (
            targetWidget.content.details !== null &&
            ['network', 'table', 'filter'].includes(targetWidget.content.kind)
        ) {
            if ('connectedWidgets' in targetWidget.content.details) {
                for (let _connectedWidget of targetWidget.content.details.connectedWidgets) {
                    if (visitedWidgets.some((x) => x.id === _connectedWidget.id)) continue

                    connectedWidgets.push(
                        ...getConnectedWidgets(_connectedWidget, slideWidgets, [...connectedWidgets, ...visitedWidgets])
                    )
                }
            }
        }

        return connectedWidgets
    }

    return []
}

// Function to apply selected item changes to widgets based on interaction mode between widgets
export const applySelectedItemChangeToWidgets = (
    connectedWidget: ReportConnectedWidgetType,
    sourceWidget: ReportWidgetType,
    targetWidget: ReportWidgetType,
    selectedItem: string | null,
    sourceWidgetId: string | number,
    connectedWidgetsIds: ReportConnectedWidgetType[],
    datasources: ReportDataSourceType[]
): void => {
    // Determine the interaction mode between source and target widgets
    // it's mandatory to check the interaction mode even we have the connected widget, because the target or source widget might have been changed
    // after the connected widget has been created, and the interaction config in the connected widget might be outdated
    // therefore, here we check again to make sure first if the widgets are compatible, second, to find out the correct interaction mode
    const widgetInteractionMode = determineWidgetInteractionCompatibility(sourceWidget, targetWidget)
    if (widgetInteractionMode === false) return

    // Default behavior based on interaction mode
    const behavior = widgetInteractionMode.defaultBehavior

    // Determine the mode of operation based on widget configuration and interaction compatibility
    const mode =
        widgetInteractionMode.configurable === false
            ? behavior.mode // Use default mode if not configurable
            : connectedWidget.config && widgetInteractionMode.configurable.modes.includes(connectedWidget.config.mode)
            ? connectedWidget.config.mode // Use connected widget's configured mode if valid and allowed
            : behavior.mode // Fallback to default mode

    // Process the selected item based on the determined mode
    switch (mode) {
        case 'select':
            if (behavior.mode !== 'select') return

            let selectedNode = selectedItem

            // if the value of selected attribute is set by the determine widget interaction compatibility function,
            // we need to get the value from selected item of the source widget
            if (selectedItem !== null && behavior.selectedAttribute !== null) {
                try {
                    selectedNode = getValueFromWidget(
                        behavior.selectedAttribute,
                        selectedItem,
                        sourceWidget.content,
                        datasources
                    )
                } catch (e) {
                    return
                }
            }

            // To make the selected node consistent across the widgets, we convert it to lowercase.
            selectedNode = selectedNode === null ? null : String(selectedNode).toLowerCase()

            // set the selected item in the target widget
            switch (targetWidget.content.kind) {
                case 'network':
                    ;(targetWidget.content.details as NetworkWidgetType).selectedNode = selectedNode
                    break

                case 'table':
                    ;(targetWidget.content.details as TableWidgetType).selectedRowId = selectedNode
                    break

                case 'insight':
                    ;(targetWidget.content.details as InsightWidgetType).selectedItem = selectedNode
            }
            break
        // Handle filter mode operations
        case 'filter':
            const details = targetWidget.content.details
            if ('filters' in details) {
                const filters: FilterRecordType = cloneDeep(details.filters || {})

                // clear the filters of the connected widgets
                for (let connectedWidget of connectedWidgetsIds) {
                    const connectedWidgetId = connectedWidget.id
                    if (filters[connectedWidgetId] !== undefined) {
                        filters[connectedWidgetId] = null
                    }
                }

                // clear the selected node of the target widget
                if (targetWidget.content.kind === 'network') {
                    ;(targetWidget.content.details as NetworkWidgetType).selectedNode = null
                } else if (targetWidget.content.kind === 'table') {
                    ;(targetWidget.content.details as TableWidgetType).selectedRowId = null
                }

                // if the selected item is not null, add the new filter to the target widget
                if (selectedItem !== null) {
                    // check if the connected widget not uses the default configuration
                    if (
                        widgetInteractionMode.configurable !== false &&
                        connectedWidget.config &&
                        connectedWidget.config.mode === 'filter'
                    ) {
                        const connectedWidgetFilters = connectedWidget.config.filters

                        const _filters: ReportFilterCompareType[] = []

                        for (let connectedWidgetFilter of connectedWidgetFilters) {
                            if (connectedWidgetFilter.type === 'compare') {
                                if (connectedWidgetFilter.field === null) continue

                                const selectedValue = getValueFromWidget(
                                    connectedWidgetFilter.field,
                                    selectedItem,
                                    sourceWidget.content,
                                    datasources
                                )

                                _filters.push({
                                    operator: connectedWidgetFilter.operator,
                                    field: connectedWidgetFilter.field,
                                    value: selectedValue,
                                })
                            } else {
                                const connectedFilter = connectedWidgetFilter
                                const targetDataSource = getWidgetDataSource(datasources, targetWidget)

                                if (targetDataSource?.mode === 'network') {
                                    const edges = Object.values(targetDataSource.networkContext.edges)
                                    const relationshipField = targetDataSource.networkContext.analyticsSettings.groupBy

                                    const nodeIds = edges.reduce((acc, edge) => {
                                        if (
                                            connectedFilter.relationship?.toLowerCase() ===
                                            String(edge[relationshipField]).toLowerCase()
                                        ) {
                                            switch (connectedFilter.connectionMode) {
                                                case 'any':
                                                    if (edge.source === selectedItem) {
                                                        acc.add(edge.target)
                                                    } else if (edge.target === selectedItem) {
                                                        acc.add(edge.source)
                                                    }
                                                    break
                                                case 'in':
                                                    if (edge.target === selectedItem) {
                                                        acc.add(edge.source)
                                                    }
                                                    break
                                                case 'out':
                                                    if (edge.source === selectedItem) {
                                                        acc.add(edge.target)
                                                    }
                                                    break
                                                case 'reciprocated':
                                                    if (edge.source === selectedItem) {
                                                        if (
                                                            edges.some(
                                                                (e) =>
                                                                    e.source === edge.target &&
                                                                    e.target === selectedItem
                                                            )
                                                        ) {
                                                            acc.add(edge.target)
                                                        }
                                                    } else if (edge.target === selectedItem) {
                                                        if (
                                                            edges.some(
                                                                (e) =>
                                                                    e.source === selectedItem &&
                                                                    e.target === edge.source
                                                            )
                                                        ) {
                                                            acc.add(edge.source)
                                                        }
                                                    }
                                                    break
                                            }
                                            return acc
                                        }
                                        return acc
                                    }, new Set<string>())

                                    _filters.push({
                                        operator: 'containsAny',
                                        field: {
                                            type: 'basic',
                                            field: 'id',
                                        },
                                        value: [selectedItem, ...Array.from(nodeIds)],
                                    })
                                }
                            }
                        }

                        let selectedId: string | null = selectedItem

                        // remove the selected node by adding a filter to the connected widget
                        if (connectedWidget.config.filterOutSelectedNode) {
                            _filters.push({
                                operator: 'neq',
                                field: {
                                    type: 'basic',
                                    field: 'id',
                                },
                                value: selectedItem,
                            })
                            selectedId = null
                        }

                        if (targetWidget.content.kind === 'network') {
                            ;(targetWidget.content.details as NetworkWidgetType).selectedNode = selectedId
                        } else if (targetWidget.content.kind === 'table') {
                            ;(targetWidget.content.details as TableWidgetType).selectedRowId = selectedId
                        }

                        filters[sourceWidgetId] = _filters
                    } else {
                        if (behavior.mode !== 'filter') return

                        filters[sourceWidgetId] = [
                            {
                                operator: 'eq',
                                field: behavior.filterAttribute,
                                value: selectedItem,
                            },
                        ]
                    }
                }

                details.filters = filters
            }
            break
    }
}

/* =========================================
 * Filters helpers
 */

// check if the filter object is empty
// export const isFilterRecordEmpty = (filterRecord: FilterRecordType) => {
//     for (let key in filterRecord) {
//         if (filterRecord[key] !== null) {
//             const value = filterRecord[key]?.value
//             if (value != null && value !== '' && (!Array.isArray(value) || value.length > 0)) return false
//         }
//     }
//     return true
// }

/* =========================================
 * SAVE REPORT
 */

export const SaveSlide = (_slide: ReportSlideType) => {
    const widgetsList = _slide.widgets.map((_widget) => {
        switch (_widget.content.kind) {
            case 'chart':
                let chartOptions = (_widget.content.details as ChartWidgetType).options

                // We either send an empty object or "null" as the options.
                if (chartOptions !== null) {
                    chartOptions = {}
                }

                return {
                    ..._widget,
                    content: {
                        ..._widget.content,
                        details: {
                            ..._widget.content.details,
                            options: chartOptions,
                            filters: null,
                        },
                    },
                }

            case 'network':
                let networkWidget = _widget.content.details as NetworkWidgetType
                return {
                    ..._widget,
                    content: {
                        ..._widget.content,
                        details: {
                            ...networkWidget,
                            filters: {},
                            selectedNode: null,
                            analytics: {},
                        },
                    },
                }

            case 'table':
                let tableWidget = _widget.content.details as TableWidgetType
                return {
                    ..._widget,
                    content: {
                        ..._widget.content,
                        details: {
                            ...tableWidget,
                            filters: null,
                            parsedData: null,
                            selectedRowId: null,
                        },
                    },
                }

            case 'filter':
                let filterWidget = _widget.content.details as FilterWidgetType
                return {
                    ..._widget,
                    content: {
                        ..._widget.content,
                        details: {
                            ...filterWidget,
                            value: filterWidget.isMultiple ? [] : '',
                        },
                    },
                }
            case 'insight':
                let insightWidget = _widget.content.details as InsightWidgetType
                return {
                    ..._widget,
                    content: {
                        ..._widget.content,
                        details: {
                            ...insightWidget,
                            selectedItem: null,
                            cachedData: null,
                        },
                    },
                }

            default:
                return _widget
        }
    })

    return {
        ..._slide,
        widgets: structuredClone(widgetsList),
    }
}

export const saveReport = async (
    reportState: ReportSavedStateType,
    projectId: number | string,
    reportId: number | string,
    thumbnail?: string
) => {
    const toastId = toast.loading('Save in progress')

    try {
        const worker = new WebWorker<string>('workers/network/zip-worker.js')

        const slidesClone: ReportSlideType[] = structuredClone(reportState.slides)

        const response = await UpdateReport(projectId, {
            primaryKey: parseInt(reportId + ''),
            thumbnail: thumbnail,
            state: await worker.run({
                mode: 'zip',
                data: {
                    reportVersion: reportState.reportVersion,
                    // Remove options from "Chart" widgets so that we can regenerate them on report load.
                    slides: slidesClone.map((_slide) => SaveSlide(_slide)),
                    dataSources: reportState.dataSources,
                    aspectRatio: reportState.aspectRatio,
                    masterSettings: reportState.masterSettings,
                } as ReportSavedStateType,
            }),
        })

        if (!response.success) throw new Error('Cannot save the data')

        toast.update(toastId, {
            render: 'The Report saved successfully.',
            type: 'success',
            isLoading: false,
            autoClose: 1000,
            closeButton: null,
        })
    } catch (e) {
        toast.update(toastId, {
            render: 'Failed to save Report.',
            type: 'error',
            isLoading: false,
            autoClose: 1000,
            closeButton: null,
        })
    }
}

// Calculated attributes

export const computeCalculatedAttributes = ({
    datasource,
    preset = 'default',
    calculatedAttributes,
}: {
    datasource: ReportDataSourceType
    preset?: string
    calculatedAttributes: ReportDataSourceCalcualtedValueType[]
}) => {
    const result: ReportDataSourceRowType[] = []

    switch (datasource.mode) {
        case 'network':
            const networkDatasource = datasource as ReportNetworkDataSourceType
            const networkContext = networkDatasource.networkContext
            const analytics = networkDatasource.presets[preset]?.analytics

            // Convert the analytics to a map of node id to analytics
            const nodeAnalytics: Record<string, Record<string, Record<string, any>>> = {}
            if (analytics) {
                for (let network in analytics) {
                    for (let nodeId in analytics[network].nodes) {
                        if (nodeAnalytics[nodeId] === undefined) nodeAnalytics[nodeId] = {}
                        nodeAnalytics[nodeId][network] = analytics[network].nodes[nodeId]
                    }
                }
            }

            for (let nodeId in networkContext.nodes) {
                const node = networkContext.nodes[nodeId]

                const scope = {
                    info: node,
                    analytics: nodeAnalytics[nodeId] || {},
                }

                const row: ReportDataSourceRowType = {
                    id: nodeId,
                }

                for (let attribute of calculatedAttributes) {
                    row[attribute.name] = computeCalculatedAttribute(attribute.expression, scope)
                }

                result.push(row)
            }

            break
    }

    return result
}

export const computeCalculatedAttribute = (expression: string, scope?: Record<string, any>) => {
    try {
        return math.evaluate(expression, scope)
    } catch (e) {
        return undefined
    }
}

export const getExpressionVariableOptions = (datasource: ReportDataSourceType, preset: string = 'default') => {
    const infoItems: ExpressionEditorSuggestionType = {
        name: 'info',
        subItems: [],
    }
    const analyticsItems: ExpressionEditorSuggestionType = {
        name: 'analytics',
        subItems: [],
    }
    switch (datasource.mode) {
        case 'network':
            const presetData = datasource.presets['default']

            if (presetData) {
                const dataSchema = presetData.nodeDataSchema
                for (let key in dataSchema.fields) {
                    infoItems.subItems!.push({
                        name: key,
                    })
                }

                const analytics = presetData.analytics
                if (analytics) {
                    for (let networkName in analytics) {
                        const netwokItems: ExpressionEditorSuggestionType = {
                            name: networkName,
                            subItems: [],
                        }
                        const data = analytics[networkName]
                        for (let key in data.schema) {
                            netwokItems.subItems!.push({
                                name: key,
                            })
                        }

                        // add reciprocated_count
                        netwokItems.subItems!.push({
                            name: 'reciprocated_count',
                        })

                        analyticsItems.subItems!.push(netwokItems)
                    }
                }
            }
            break
        default:
            return []
    }

    return [infoItems, analyticsItems].filter((x) => x.subItems && x.subItems.length > 0)
}

export const collectExpressionVariableVariables = (node: math.MathNode, vars = new Set<string>()) => {
    if (node.type === 'SymbolNode') {
        const symbolNode = node as math.SymbolNode
        vars.add(symbolNode.name)
    } else if (node.type === 'AccessorNode') {
        // Handle AccessorNode by only collecting variables from the object part
        vars.add(String(node))
        // Note: You might also want to collect variables from the index if it's not a constant
    } else if (node.type === 'FunctionNode' || node.type === 'OperatorNode') {
        const argsNode = node as math.FunctionNode | math.OperatorNode
        argsNode.args.forEach((arg) => collectExpressionVariableVariables(arg, vars))
    } else if (node.type === 'ParenthesisNode') {
        const argsNode = node as math.ParenthesisNode
        collectExpressionVariableVariables(argsNode.content, vars)
    } else if (node.type === 'ConditionalNode') {
        const conditionalNode = node as math.ConditionalNode
        collectExpressionVariableVariables(conditionalNode.condition, vars)
        collectExpressionVariableVariables(conditionalNode.trueExpr, vars)
        collectExpressionVariableVariables(conditionalNode.falseExpr, vars)
    }
    return vars
}

// this function is used to determine if the keydown event should be captured by the application or not
export function shouldCaptureKeydown(): boolean {
    const active = document.activeElement

    // check if the active element is an input or textarea or contenteditable element
    if (
        active !== null &&
        (active.tagName === 'INPUT' ||
            active.tagName === 'TEXTAREA' ||
            ('isContentEditable' in active && active.isContentEditable))
    ) {
        return true
    }

    // determine if mui dialog is open by checking if the active element or any of its ancestors has the class "MuiDialog-root"
    const dialog = active?.closest('.MuiDialog-root')
    if (dialog !== null) {
        return true
    }

    // determine if mui popover is open by checking if the active element or any of its ancestors has the class "MuiPopover-root"
    const popover = active?.closest('.MuiPopover-root')
    if (popover !== null) {
        return true
    }

    return false
}
