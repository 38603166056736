//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
//* ======= Custom logic
//* ======= Assets and styles
import SurveyLoadImage from 'assets/images/survey-load-image.png'
import SnaLogo from 'assets/images/sna-toolbox-logo.png'
import DoneAllIcon from '@mui/icons-material/DoneAll'

interface ISurveyCompleted {}

function SurveyCompleted({}: ISurveyCompleted) {
    return (
        <Stack
            sx={(theme) => ({
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',

                backgroundColor: theme.palette.common.bg_1,
            })}
            className="u-scrollbar-pink"
        >
            {/* Body
				========================================= */}
            <Stack
                sx={{
                    flexGrow: 1,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,

                    width: '100%',
                    maxWidth: 1280,
                    py: 2,
                    px: 3,
                }}
            >
                {/* Survey pic
					========================================= */}
                <img
                    src={SurveyLoadImage}
                    alt="survey logo"
                    style={{
                        height: '300px',
                    }}
                />

                {/* Title
					========================================= */}
                <Typography fontSize={30} fontWeight={400} color="common.text_1" marginBottom={2}>
                    Our records show that you have already completed this survey.{' '}
                    <DoneAllIcon fontSize="large" color="success" />
                </Typography>

                {/* Copyright text
					========================================= */}
                <Typography fontSize={14} fontWeight={400} color="common.text_3">
                    Copyright 2022 SNA Toolbox Pty Ltd. All Rights Reserved.
                </Typography>
            </Stack>

            {/* Footer
				========================================= */}
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{
                    flexShrink: 0,
                    alignSelf: 'center',

                    width: '100%',
                    maxWidth: 1280,
                    py: 2,
                    px: 3,

                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    backgroundColor: 'common.bg_1',
                }}
            >
                <img
                    src={SnaLogo}
                    alt="SNA logo"
                    style={{
                        height: 30,
                        width: 140,
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />

                <Typography fontSize={12} fontWeight={400} color="common.text_3" marginTop={0.75}>
                    This survey was built using SNA Toolbox.
                </Typography>

                <Typography fontSize={10} fontWeight={400} color="common.text_3" marginLeft="auto">
                    SNA Toolbox Survey Software v3.3.2
                </Typography>

                <Link
                    href="#"
                    underline="none"
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,

                        color: 'primary.main',
                    }}
                >
                    More Information
                </Link>
            </Stack>
        </Stack>
    )
}

export default SurveyCompleted
