import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import type { Identifier } from 'dnd-core'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Box } from '@mui/system'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

interface ChipGroupByMultiSelectPrposType {
    placeholder: string
    suggestions: string[]
    defaultValue?: string[]
    allowArbitary?: boolean
    onChange: (newVal: any) => void
    readOnly?: boolean
    regex?: RegExp
    size?: 'small' | 'medium'
    chipLocation?: 'after' | 'before'
}

const filter = createFilterOptions<string>()

export default function ChipGroupByMultiSelect({
    size = 'medium',
    chipLocation = 'before',
    readOnly = false,
    placeholder,
    suggestions,
    defaultValue = [],
    allowArbitary = false,
    onChange,
    regex,
}: ChipGroupByMultiSelectPrposType) {
    const [values, setValues] = useState<string[]>([])

    const ref2 = useRef<HTMLInputElement>(null)

    //set default value if any
    useEffect(() => {
        setValues(defaultValue)
    }, [])

    const options = useMemo(() => {
        if (!Array.isArray(suggestions)) return []
        if (!values) return suggestions
        return suggestions.filter((s) => values.findIndex((v) => v == s) == -1)
    }, [values, suggestions])

    const validateValue = (v: string) => {
        if (!regex) return true
        return v.match(regex)
    }

    return (
        <Stack
            direction={chipLocation == 'before' ? 'column' : 'column-reverse'}
            width="100%"
            sx={{ position: 'relative' }}
        >
            <DndProvider backend={HTML5Backend}>
                <DraggableChipViewr onChange={onChange} setValues={setValues} values={values} />
            </DndProvider>
            <Autocomplete
                fullWidth
                size={size}
                options={options}
                disableClearable={true}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    const { inputValue } = params
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option)
                    if (inputValue !== '' && !isExisting) {
                        filtered.push(inputValue)
                    }
                    return filtered
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                onChange={(event, value) => {
                    if (typeof value == 'string') {
                        if (validateValue(value)) {
                            let suggestion = suggestions?.find(
                                (s) => s.toLowerCase() == value.toLocaleLowerCase()
                            )
                            if (suggestion != null) {
                                //@ts-ignore
                                setValues((v) => {
                                    const updatedValue = [...v, suggestion]
                                    onChange(updatedValue)
                                    return updatedValue
                                })
                            } else {
                                setValues((v) => {
                                    const updatedValue = [...v, value]
                                    onChange(updatedValue)
                                    return updatedValue
                                })
                            }
                        }
                    } else {
                        if (value != null) {
                            let isValid = validateValue(value)

                            if (isValid)
                                setValues((v) => {
                                    const updatedValue = [...v, value]
                                    onChange(updatedValue)
                                    return updatedValue
                                })
                        }
                    }

                    setTimeout(() => {
                        if (ref2.current) {
                            ref2.current.blur()
                            const event = new KeyboardEvent('keydown', {
                                key: 'Escape',
                            })
                            ref2.current.dispatchEvent(event)
                        }
                    }, 10)
                }}
                value={''}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo={allowArbitary}
                readOnly={readOnly}
                renderInput={(params) => (
                    <BaseFilledTextField hiddenLabel {...params} placeholder={placeholder} inputRef={ref2} />
                )}
            />
        </Stack>
    )
}

const DraggableChipViewr = ({
    values,
    setValues,
    onChange,
}: {
    onChange: (newVal: any) => void
    values: string[]
    setValues: Dispatch<SetStateAction<string[]>>
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const [targetHoverIndex, setTargetHoverIndex] = useState(-1)

    const [{ handlerId }, drop] = useDrop<string, void, { handlerId: Identifier | null }>(
        {
            accept: 'chip',
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                }
            },
            hover(item: string, monitor) {
                if (!ref.current) {
                    return
                }
                const dragId = item
                // Determine mouse position
                const sourceOffset = monitor.getDifferenceFromInitialOffset()
                if (sourceOffset) {
                    const targetIndex = Math.floor(sourceOffset.x / 60) + 1
                    const old_index = values.findIndex((s) => s === dragId)

                    // if (targetIndex !== 0) {
                    setTargetHoverIndex(targetIndex + old_index)
                    // } else {
                    //   setTargetHoverIndex(-1);
                    // }
                }
            },
            drop(item: string, monitor) {
                if (!ref.current) {
                    return
                }
                setTargetHoverIndex(-1)
                const dragId = item
                // Determine mouse position
                const sourceOffset = monitor.getDifferenceFromInitialOffset()
                if (sourceOffset) {
                    const targetIndex = Math.min(values.length, Math.floor(sourceOffset.x / 60) + 1)

                    swapChips(dragId, targetIndex > 0 ? targetIndex - 1 : targetIndex)
                }
            },
        },
        [values]
    )

    const handleDelete = (index: number) => () => {
        setValues((v) => {
            const updatedValue = [...v.slice(0, index), ...v.slice(index + 1)]
            onChange(updatedValue)
            return updatedValue
        })
    }

    drop(ref)

    const swapChips = (item1: string, item2: number) => {}

    return (
        <Grid
            ref={ref}
            container
            spacing={2}
            sx={{ position: 'absolute', bottom: '-50px', marginY: 1, zIndex: 99 }}
        >
            {values.map((v, index) => {
                // if (index == targetHoverIndex) {
                //     return (
                //         <React.Fragment>
                //             <Grid item><DraggableChip title={v} onDelete={handleDelete(index)} /></Grid>
                //             <Grid item><Chip label={"test"} onDelete={() => { }} /></Grid>
                //         </React.Fragment>
                //     )
                // }
                return (
                    <React.Fragment>
                        <Grid item>
                            <Chip label={v} onDelete={handleDelete(index)} />{' '}
                        </Grid>
                        {index + 1 != values.length && (
                            <Grid item>
                                <ArrowForwardIcon color="secondary" />
                            </Grid>
                        )}
                    </React.Fragment>
                )
            })}
        </Grid>
    )
}

const DraggableChip = ({ title, onDelete }: { title: string; onDelete: () => void }) => {
    const [collected, drag, dragPreview] = useDrag(() => ({
        type: 'chip',
        item: { id: title },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    }))

    return collected.isDragging ? (
        <Box ref={dragPreview}></Box>
    ) : (
        <Chip ref={drag} label={title} onDelete={onDelete} />
    )
}
