//* ======= Libraries
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDialog from 'components/dialog/StyledDialog'
import SignInLayout from 'pages/sign-in/SignInLayout'
//* ======= Custom logic
//* ======= Assets and styles
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { isEmailValid } from 'helpers/validators'
import { ForgotPasswordService } from 'services/AuthenticationService'

interface IForgotPassword {}

function ForgotPassword({}: IForgotPassword) {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const [email, setEmail] = useState<string>('')

    const navigator = useNavigate()

    const requestResetPassword = () => {
        ForgotPasswordService({ username: email }).then((res) => res.success && setIsDialogOpen(true))
    }

    return (
        <SignInLayout>
            {/* Main content
				========================================= */}
            <Stack gridRow={2} width="40%">
                {/* Title
					========================================= */}
                <Stack direction="row">
                    <IconButton onClick={() => navigator('/login')}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography fontSize={36} fontWeight={400} color="common.text_1" mb={1}>
                        Forgot Password
                    </Typography>
                </Stack>

                <Typography paragraph fontSize={18} fontWeight={400} color="common.text_1" mb={7}>
                    Enter the email address associated with your account and we’ll send you a link to reset
                    your password.
                </Typography>

                {/* Email
					========================================= */}
                <BaseFilledTextField
                    type="email"
                    label="Work Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        marginBottom: 3,
                    }}
                />

                {/* Continue button
					========================================= */}
                <BaseButton
                    label="Continue"
                    disabled={!isEmailValid(email)}
                    onClick={requestResetPassword}
                    variant="contained"
                    sx={{
                        py: 2,
                    }}
                />
            </Stack>

            {/* Dialog
				========================================= */}
            <StyledDialog
                open={isDialogOpen}
                onClose={(event, reason) => setIsDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <Stack alignItems="center" gap={3} py={2.5} px={3}>
                    <Typography fontSize={36} fontWeight={400} color="common.text_1">
                        Forgot Password
                    </Typography>

                    <Typography fontSize={16} fontWeight={400} color="common.text_1" marginBottom={3}>
                        Check your email address. We’ll send you a link to reset your password.
                    </Typography>

                    <BaseButton
                        label="Close"
                        onClick={() => navigator('/login')}
                        variant="contained"
                        size="large"
                        width="300px"
                    />
                </Stack>
            </StyledDialog>
        </SignInLayout>
    )
}

export default ForgotPassword
