export const ROUTES: { [key: string]: string } = {
    dashboard: '/dashboard',
}

export const NO_HEADER_ROUTES: { [key: string]: string } = {
    surveyPreview: '/survey-preview',
    verifyEmail: '/verify-email',
    completeProfile: '/complete-profile',
}

export const APP_VERSIONS = {
    APP: '2.0.02',
    REPORT: '1.2.5',
    NETWORK: '1.0.8',
    SURVEY: '1.0.0',
}

export const PERMISSIONS = {
    project: {
        AccessProject: 'AccessProject',
        UpdateProjectSettings: 'UpdateProjectSettings',
        DeleteProject: 'DeleteProject',
        ManageProjectTag: 'ManageProjectTag',
        DeleteProjectTag: 'DeleteProjectTag',
        ManageProjectUsers: 'ManageProjectUsers',
        AccessProjectData: 'AccessProjectData',
    },
    survey: {
        CreateSurvey: 'CreateSurvey',
        ReadSurveysList: 'ReadSurveysList',
        DeleteSurvey: 'DeleteSurvey',
        ReadSpecificSurvey: 'ReadSpecificSurvey',
        ManipulateSurveyTitle: 'ManipulateSurveyTitle',
        ReadSurveyQuestions: 'ReadSurveyQuestions',
        ManipulateSurveyQuestions: 'ManipulateSurveyQuestions',
        ManageSurveyStatus: 'ManageSurveyStatus',
        ReadSurveyParticipants: 'ReadSurveyParticipants',
        ManipulateSurveyParticipants: 'ManipulateSurveyParticipants',
        ManipulateSurveyAnalytics: 'ManipulateSurveyAnalytics',
        ReadSurveyAnalytics: 'ReadSurveyAnalytics',
        ReadSurveyProfile: 'ReadSurveyProfile',
        ManipulateSurveyProfile: 'ManipulateSurveyProfile',
        ReadSurveyAuditParticipants: 'ReadSurveyAuditParticipants',
        ReadSurveyAuditAdmin: 'ReadSurveyAuditAdmin',
        AccessSurveyData: 'AccessSurveyData',
        ChangeSurveyInstanceStatus: 'ChangeSurveyInstanceStatus',
        ManageSurveyNotifications: 'ManageSurveyNotifications',
        ReadSurveyRules: 'ReadSurveyRules',
        ManipulateSurveyRules: 'ManipulateSurveyRules',
        ReadSurveyReport: 'ReadSurveyReport',
        ManipulateSurveyReport: 'ManipulateSurveyReport',
        ReadSurveySettings: 'ReadSurveySettings',
        ManipulateSurveySettings: 'ManipulateSurveySettings',
        ManageSurveyTag: 'ManageSurveyTag',
    },
    widget: {
        CreateWidget: 'CreateWidget',
        UpdateWidget: 'UpdateWidget',
        ReadWidgetList: 'ReadWidgetList',
        DeleteWidget: 'DeleteWidget',
        ReadSpecificWidget: 'ReadSpecificWidget',
        ManageWidgetTag: 'ManageWidgetTag',
        UpdateWidgetAnalytics: 'UpdateWidgetAnalytics',
        AccessWidgetData: 'AccessWidgetData',
    },
    node: {
        ReadNodeList: 'ReadNodeList',
        CreateNode: 'CreateNode',
        UpdateNode: 'UpdateNode',
        DeleteNode: 'DeleteNode',
        ReadSpecificNode: 'ReadSpecificNode',
        ReadNodeRelations: 'ReadNodeRelations',
        AddNodeNote: 'AddNodeNote',
        DeleteNodeNotes: 'DeleteNodeNotes',
    },
    respondent: {
        ManageRespondent: 'ManageRespondent',
    },
    report: {
        ReadReportList: 'ReadReportList',
        CreateReport: 'CreateReport',
        UpdateReport: 'UpdateReport',
        ReadSpecificReport: 'ReadSpecificReport',
        DeleteReport: 'DeleteReport',
        ManageReportAccess: 'ManageReportAccess',
        ManageReportTag: 'ManageReportTag',
    },
    allocation: {
        ManageGroupAllocation: 'ManageGroupAllocation',
    },
    nodeAction: {
        ManageNodeAction: 'ManageNodeAction',
    },
}
