import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import { ERGMAnalyticsType, NetworkAnalyticsType, NodeAnalyticType } from 'features/network-viz/types/NetworkViz.types'
import {
    applyAggregation,
    computeCalculatedAttribute,
    evaluateExpression,
} from 'features/report-designer/helpers/reportDesigner.helper'
import {
    InsightCachedType,
    InsightMessageType,
    InsightWidgetType,
    ReportDataSourceAttributeType,
    ReportDataSourceRowType,
    ReportDataSourceType,
    ReportNetworkDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import WebWorker from 'helpers/webWorkerHelper'

function isNumber(value: any) {
    return !isNaN(value) && !isNaN(parseFloat(value))
}

function compareValues(ruleValue: any, value: any) {
    const ruleValueIsNumber = isNumber(ruleValue)
    const valueIsNumber = isNumber(value)

    if (ruleValueIsNumber && valueIsNumber) {
        // Both are numbers, compare as numbers
        return parseFloat(ruleValue) - parseFloat(value)
    } else if (ruleValueIsNumber || valueIsNumber) {
        // One is a number and one is not, cannot compare
        return false
    } else {
        // Both are not numbers, compare as strings
        return ruleValue.localeCompare(value)
    }
}

export function evaluateRule(rule: InsightWidgetType['insights'][number]['rules'][number], value: any) {
    switch (rule.operator) {
        case 'between':
            // ToDo
            break
        case 'eq':
            return compareValues(rule.value, value) === 0
        case 'neq':
            return compareValues(rule.value, value) !== 0
        case 'gt':
            return compareValues(rule.value, value) < 0
        case 'gte':
            return compareValues(rule.value, value) <= 0
        case 'lt':
            return compareValues(rule.value, value) > 0
        case 'lte':
            return compareValues(rule.value, value) >= 0
    }
    return false
}

function getConditionKey(condition: InsightWidgetType['insights'][number]['rules'][number]) {
    if (condition.varaible === null) return null
    return `${condition.aggregationMethod || ''}.${condition.varaible.type}.${
        'relationship' in condition.varaible ? condition.varaible.relationship : ''
    }.${condition.varaible?.field || ''}`
}

async function getGroupData(
    selectedDatasource: ReportDataSourceType,
    insights: InsightWidgetType['insights'],
    groupBy: InsightWidgetType['groupBy'],
    panelId: number | string | undefined = 0
) {
    const result: Record<string, Record<string, any>> = {}

    if (selectedDatasource.mode === 'network') {
        let groupAnalytics: Record<string, Record<string, NetworkAnalyticsType> | null> = {
            all: selectedDatasource.networkContext.analytics,
        }
        let groups: Record<string, any[]> | null = null
        if (groupBy != null) {
            for (const nodeId in selectedDatasource.networkContext.nodes) {
                const node = selectedDatasource.networkContext.nodes[nodeId]
                const value = node[groupBy.field]
                if (groups === null) {
                    groups = {}
                }
                if (groups[value] === undefined) {
                    groups[value] = []
                }
                groups[value].push(nodeId)
            }

            groupAnalytics = await computeAnalyticsForGroups(groups!, selectedDatasource)
        }

        for (let insight of insights) {
            for (let rule of insight.rules) {
                const key = getConditionKey(rule)
                if (key === null || rule.varaible === null) continue

                const groupsToProcess =
                    groupBy != null ? groups : { all: Object.keys(selectedDatasource.networkContext.nodes) }

                for (const group in groupsToProcess) {
                    switch (rule.varaible.type) {
                        case 'graph':
                            const value =
                                groupAnalytics[group]?.[rule.varaible.relationship.toLowerCase()]?.graph[
                                    rule.varaible.field
                                ]
                            if (value === undefined) continue
                            if (result[group] === undefined) {
                                result[group] = {}
                            }
                            result[group][key] = value
                            break
                        case 'analytic':
                        case 'basic':
                            const aggregationResult = aggregateDataForNodes(
                                groupsToProcess[group],
                                selectedDatasource,
                                groupAnalytics[group],
                                rule
                            )
                            if (aggregationResult !== undefined) {
                                if (result[group] === undefined) {
                                    result[group] = {}
                                }
                                result[group][key] = aggregationResult
                            }
                    }
                }
            }
        }
        return result
    } else {
        const data = selectedDatasource.data[panelId]

        let groups: Record<string, ReportDataSourceRowType[]> | null = null
        if (groupBy != null) {
            for (const row of data) {
                const value = row[groupBy.field]?.toString() ?? 'unknown'
                if (groups === null) {
                    groups = {}
                }
                if (groups[value] === undefined) {
                    groups[value] = []
                }
                groups[value].push(row)
            }
        }

        for (let insight of insights) {
            for (let rule of insight.rules) {
                const key = getConditionKey(rule)
                if (key === null || rule.varaible === null) continue

                const groupsToProcess = groupBy != null ? groups : { all: data }

                for (const group in groupsToProcess) {
                    switch (rule.varaible.type) {
                        case 'basic':
                            const aggregationResult = aggregateDataFoRows(groupsToProcess[group], rule)

                            if (aggregationResult !== undefined) {
                                if (result[group] === undefined) {
                                    result[group] = {}
                                }
                                result[group][key] = aggregationResult
                            }
                    }
                }
            }
        }
        return result
    }
}

async function computeAnalyticsForGroups(
    groups: Record<string, string[]>,
    selectedDatasource: ReportNetworkDataSourceType
): Promise<Record<string, Record<string, NetworkAnalyticsType> | null>> {
    const result: Record<string, Record<string, NetworkAnalyticsType> | null> = {}
    for (const group in groups) {
        const allNodes = Object.values(selectedDatasource.networkContext.nodes).filter((x) =>
            groups[group].includes(x.id)
        )
        const allEdges = Object.values(selectedDatasource.networkContext.edges).filter(
            (x) => groups[group].includes(x.source) && groups[group].includes(x.target)
        )
        const nodes = selectedDatasource.networkContext.nodeRenders.filter((x) => groups[group].includes(x.id))
        const edges = selectedDatasource.networkContext.edgeRenders.filter(
            (x) => groups[group].includes(x.source) && groups[group].includes(x.target)
        )
        const webWorker = new WebWorker('workers/network/apply-advance-analytics.js')

        const options = {
            nodes,
            edges,
            allEdges,
            allNodes,
            nodeInfo: selectedDatasource.networkContext.nodes,
            analyticsSettings: selectedDatasource.networkContext.analyticsSettings,
            mode: 'all',
        }

        const mode = 'basic'

        const response = (await webWorker.run({
            mode,
            options,
            analyticsUrl: process.env.REACT_APP_API_URL,
        })) as any
        result[group] = response
    }
    return result
}

function aggregateDataForNodes(
    nodeIds: string[],
    selectedDatasource: ReportNetworkDataSourceType,
    groupAnalytics: Record<string, NetworkAnalyticsType> | null,
    rule: InsightWidgetType['insights'][number]['rules'][number]
): any {
    // This function is dedicated to aggregating data for a set of nodes.

    if (rule.varaible === null) return undefined

    // Ensure that the rule is for an aggregation type.
    if (rule.varaible.type !== 'analytic' && rule.varaible.type !== 'basic') {
        return undefined
    }

    // Collect and aggregate data for the specified nodes.
    const dataVector = nodeIds
        .map((nodeId) =>
            rule.varaible!.type === 'analytic'
                ? groupAnalytics?.[rule.varaible!.relationship]?.nodes[nodeId]?.[rule.varaible!.field]
                : selectedDatasource.networkContext.nodes[nodeId]?.[rule.varaible!.field]
        )
        .filter((item) => item != null)

    // Apply the aggregation method.
    return applyAggregation({
        method: rule.aggregationMethod!,
        data: dataVector.map((x) => ({ [rule.varaible!.field]: x })),
        field: rule.varaible.field,
    })
}

function aggregateDataFoRows(
    data: ReportDataSourceRowType[],
    rule: InsightWidgetType['insights'][number]['rules'][number]
): any {
    // This function is dedicated to aggregating data for a set of nodes.

    if (rule.varaible === null) return undefined

    // Ensure that the rule is for an aggregation type.
    if (rule.varaible.type !== 'basic') {
        return undefined
    }

    // Collect and aggregate data for the specified nodes.
    const dataVector = data.map((row) => row[rule.varaible!.field]).filter((item) => item != null)

    // Apply the aggregation method.
    return applyAggregation({
        method: rule.aggregationMethod!,
        data: dataVector.map((x) => ({ [rule.varaible!.field]: x })),
        field: rule.varaible.field,
    })
}

const computeUrgecy = (
    expression: string | undefined,
    data: {
        datasource: ReportDataSourceType
        idField: ReportDataSourceAttributeType
        selectedItem: InsightWidgetType['selectedItem']
        panelId?: number | string
    }
) => {
    if (expression === undefined || data.selectedItem === null) return undefined
    let row: Record<string, any> = {}

    switch (data.datasource.mode) {
        case 'network':
            row = data.datasource.networkContext.nodes[data.selectedItem]
            break
        default:
            row =
                data.datasource.data[data.panelId ?? 0]?.find(
                    (x: ReportDataSourceRowType) => x[data.idField.field] === data.selectedItem
                ) ?? {}
    }

    const urgencyValue = evaluateExpression(
        expression,
        row,
        data.datasource.mode === 'network' ? data.datasource.networkContext.analytics : undefined
    )
    return urgencyValue
}

const processGroupMode = (
    data: Record<string, Record<string, any>>,
    insight: InsightWidgetType['insights'][number]
): InsightCachedType | null => {
    let satisfied = true
    for (let rule of insight.rules) {
        let value = null
        const variable = rule.varaible
        if (variable === null) return null

        const key = getConditionKey(rule)
        if (key === null) return null
        value = data[key]

        satisfied = evaluateRule(rule, value)

        if (!satisfied) break
    }
    if (satisfied) {
        return {
            ...insight.message.satisfied,
            condition: 'satisfied',
            insightId: insight.id,
            severity: 0,
        }
    } else {
        return insight.message.unsatisfied.enabled
            ? {
                  ...insight.message.unsatisfied,
                  condition: 'unsatisfied',
                  insightId: insight.id,
                  severity: 0,
              }
            : null
    }
}

const processOtherModes = (
    selectedDatasource: ReportDataSourceType,
    insight: InsightWidgetType['insights'][number],
    idField: ReportDataSourceAttributeType,
    selectedItem: InsightWidgetType['selectedItem'],
    panelId: number | string | undefined = 0
): InsightCachedType | null => {
    let satisfied = true
    for (let rule of insight.rules) {
        let value = null
        const variable = rule.varaible
        if (variable === null) return null

        if (selectedItem == null) return null

        if (variable.type === 'analytic') {
            if (selectedDatasource.mode === 'network') {
                value =
                    selectedDatasource.networkContext.analytics?.[variable.relationship]?.nodes?.[selectedItem]?.[
                        variable.field
                    ]
            } else {
                return null
            }
        } else if (variable.type === 'basic') {
            if (selectedDatasource.mode === 'network') {
                value = selectedDatasource.networkContext.nodes?.[selectedItem]?.[variable.field]
            } else {
                value = selectedDatasource.data[panelId]?.find(
                    (x: ReportDataSourceRowType) => x[idField.field] === selectedItem
                )?.[variable.field]
            }
        } else {
            return null
        }

        satisfied = evaluateRule(rule, value)

        if (!satisfied) break
    }
    if (satisfied) {
        return {
            ...insight.message.satisfied,
            condition: 'satisfied',
            insightId: insight.id,
            severity: computeUrgecy(insight.message.satisfied.severity, {
                datasource: selectedDatasource,
                idField: idField,
                selectedItem: selectedItem,
                panelId: panelId,
            }),
        }
    } else {
        return insight.message.unsatisfied.enabled
            ? {
                  ...insight.message.unsatisfied,
                  condition: 'unsatisfied',
                  insightId: insight.id,
                  severity: computeUrgecy(insight.message.unsatisfied.severity, {
                      datasource: selectedDatasource,
                      idField: idField,
                      selectedItem: selectedItem,
                      panelId: panelId,
                  }),
              }
            : null
    }
}

const getErgmAlaamValue = (
    analytics: ERGMAnalyticsType,
    variable: Extract<ReportDataSourceAttributeType, { type: 'ergm' | 'alaam' }>
) => {
    if (variable.metric === 'pseudo_r_squared') {
        return analytics.pseudo_r_squared
    }
    if (variable.metric === 'converged') {
        return analytics.converged.toString()
    }
    if (variable.metric === 'log_likelihood') {
        return analytics.log_likelihood
    }
    let param = analytics.params_stats.find((x) => x.name === variable.field)
    if (param === undefined) return null
    if (variable.metric === 'conf_int_lb') {
        return param.conf_int?.[0] ?? null
    }
    if (variable.metric === 'conf_int_ub') {
        return param.conf_int?.[1] ?? null
    }

    if (variable.metric === 'coef') {
        return param.coef
    }
    if (variable.metric === 'std_err') {
        return param.std_err
    }
    if (variable.metric === 'z') {
        return param.z
    }
    if (variable.metric === 'p_values') {
        return param.p_values
    }

    return null
}

const processErgmAlaamMode = (
    analytics: NetworkVizContextType['analytics'],
    insight: InsightWidgetType['insights'][number]
): InsightCachedType | null => {
    let satisfied = true

    if (analytics === null) return null

    for (let rule of insight.rules) {
        let value = null
        const variable = rule.varaible
        if (variable === null) return null

        if (variable.type === 'ergm') {
            const ergm = analytics[variable.relationship]?.ergm
            if (ergm === undefined) return null
            value = getErgmAlaamValue(ergm, variable)
        } else if (variable.type === 'alaam') {
            const alaam = analytics[variable.relationship]?.alaam?.[variable.targetField]
            if (alaam === undefined) return null
            value = getErgmAlaamValue(alaam, variable)
        } else {
            return null
        }

        satisfied = evaluateRule(rule, value)

        if (!satisfied) break
    }

    if (satisfied) {
        return {
            ...insight.message.satisfied,
            condition: 'satisfied',
            insightId: insight.id,
            severity: 0,
        }
    } else {
        return insight.message.unsatisfied.enabled
            ? {
                  ...insight.message.unsatisfied,
                  condition: 'unsatisfied',
                  insightId: insight.id,
                  severity: 0,
              }
            : null
    }
}
export const genereateInsightWidgetData = async (
    selectedDatasource: ReportDataSourceType,
    mode: InsightWidgetType['mode'],
    groupBy: InsightWidgetType['groupBy'],
    insights: InsightWidgetType['insights'],
    labelField: ReportDataSourceAttributeType | null,
    idField: ReportDataSourceAttributeType | null,
    panelId: number | string | undefined = 0,
    presetId: string = 'default'
): Promise<InsightWidgetType['cachedData']> => {
    if (mode === 'group') {
        const result: InsightWidgetType['cachedData'] = {}

        const groupData: Record<string, Record<string, any>> | undefined = await getGroupData(
            selectedDatasource,
            insights,
            groupBy,
            panelId
        )
        if (groupData === undefined) {
            return {
                all: {
                    data: [],
                    label: 'All',
                },
            }
        }

        for (let group in groupData) {
            const messages: InsightCachedType[] = []
            for (let insight of insights) {
                const message = processGroupMode(groupData[group], insight)
                if (message !== null) {
                    messages.push(message)
                }
            }
            messages.sort((a, b) => {
                const orderA = insights.find((x) => x.id === a.insightId)?.message[a.condition].order || 0
                const orderB = insights.find((x) => x.id === b.insightId)?.message[b.condition].order || 0
                return orderA - orderB
            })
            result[group.toLowerCase()] = {
                data: messages,
                label: group,
            }
        }
        return result
    } else if (mode === 'ergm/alaam') {
        if (selectedDatasource.mode !== 'network') return {}

        const messages: InsightCachedType[] = []
        for (let insight of insights) {
            const analytics = selectedDatasource.presets[presetId]?.analytics
            const message = processErgmAlaamMode(analytics, insight)
            if (message !== null) {
                messages.push(message)
            }
        }

        messages.sort((a, b) => {
            const orderA = insights.find((x) => x.id === a.insightId)?.message[a.condition].order || 0
            const orderB = insights.find((x) => x.id === b.insightId)?.message[b.condition].order || 0
            return orderA - orderB
        })
        return {
            all: {
                data: messages,
                label: 'All',
            },
        }
    } else {
        if (idField === null) throw new Error('Id field is required')
        const result: InsightWidgetType['cachedData'] = {}
        const rowIdsWithLabel: Record<string, string> = {}
        if (selectedDatasource.mode === 'network') {
            const nodes = selectedDatasource.networkContext.nodes
            const analytics = selectedDatasource.networkContext.analytics

            const getNodeValue = (nodeId: string, attribute: ReportDataSourceAttributeType | null) => {
                if (attribute === null || attribute === undefined) return null
                if (attribute.type === 'basic') {
                    return nodes[nodeId][attribute.field]
                } else if (attribute.type === 'analytic') {
                    if (analytics === null) return null
                    return analytics[attribute.relationship].nodes[nodeId][attribute.field]
                }
            }

            for (let nodeId in nodes) {
                const label = getNodeValue(nodeId, labelField) ?? `Node ${nodeId}`
                const id = getNodeValue(nodeId, idField) ?? nodeId
                rowIdsWithLabel[id] = label
            }
        } else {
            const data = selectedDatasource.data[panelId]

            for (let row of data) {
                const id = row[idField.field]
                if (id === undefined) continue
                let label = `Row ${id}`
                if (labelField !== null) {
                    label = row[labelField.field]?.toString() ?? label
                }

                rowIdsWithLabel[id.toString()] = label
            }
        }
        for (let rowId in rowIdsWithLabel) {
            const messages: InsightCachedType[] = []
            for (let insight of insights) {
                const message = processOtherModes(selectedDatasource, insight, idField, rowId, panelId)
                if (message !== null) {
                    messages.push(message)
                }
            }

            messages.sort((a, b) => {
                const orderA = insights.find((x) => x.id === a.insightId)?.message[a.condition].order || 0
                const orderB = insights.find((x) => x.id === b.insightId)?.message[b.condition].order || 0
                return orderA - orderB
            })

            const label = rowIdsWithLabel[rowId]

            result[rowId.toLowerCase()] = {
                data: messages,
                label: label,
            }
        }
        return result
    }
}
