//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Box, Tabs, Tab } from '@mui/material'
//* ======= Components and features
import LineChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/LineChartWidgetSettings'
import BarChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/BarChartWidgetSettings'
import PieChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/PieChartWidgetSettings'
import ScatterChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/ScatterChartWidgetSettings'
import RadarChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/RadarChartWidgetSettings'
import BoxplotChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/BoxplotChartWidgetSettings'
import GaugeChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/GaugeChartWidgetSettings'
import PictorialBarChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/PictorialBarChartWidgetSettings'
import WordCloudChartWidgetSettings from 'features/report-designer/widgets/chart-widget/settings/WordCloudChartWidgetSettings'
import ColorPicker from 'components/widgets/ColorPicker'
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    ChartWidgetType,
    ReportWidgetSettingsBaseType,
} from 'features/report-designer/types/reportDesigner.types'
import { ChartConfigType, ChartDataDefinitionType, ChartTypeType } from 'features/chart/Chart.asset'
import {
    generateChartName,
    generateChartOptions,
    GenerateChartOptionsParamsModeType,
    getChartTypeInitialValues,
} from 'features/chart/Chart.helper'
import { getChartAttributes, getDataSourceData } from 'features/report-designer/helpers/reportDesigner.helper'
import TreemapChartWidgetSettings from './settings/TreemapChartWidgetSettings'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
//* ======= Assets and styles

const chartTypeOptions: Array<{
    label: string
    value: ChartTypeType
}> = [
    {
        label: 'Line',
        value: 'line',
    },
    {
        label: 'Bar',
        value: 'bar',
    },
    {
        label: 'Pie',
        value: 'pie',
    },
    {
        label: 'Scatter',
        value: 'scatter',
    },
    {
        label: 'Radar',
        value: 'radar',
    },
    {
        label: 'Boxplot',
        value: 'boxplot',
    },
    {
        label: 'Gauge',
        value: 'gauge',
    },
    {
        label: 'Pictorial Bar',
        value: 'pictorialBar',
    },
    {
        label: 'Word Cloud',
        value: 'wordCloud',
    },
    {
        label: 'Treemap',
        value: 'treemap',
    },
]

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'chart'>
    settings: Omit<ReportWidgetType, 'content'>
    details: ChartWidgetType
}

function ChartWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { dataSources, activeSlideId, updateWidget, updateWidgetStyles, updateChartWidgetContent } = useReportStore(
        (store) => ({
            dataSources: store.dataSources,
            activeSlideId: store.activeSlideId,
            updateWidget: store.updateWidget,
            updateWidgetStyles: store.updateWidgetStyles,
            updateChartWidgetContent: store.updateChartWidgetContent,
        })
    )

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'chart') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: ChartWidgetType) => {
        if (activeSlideId !== null && widget !== null) {
            updateChartWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    const onChartTypeChange = (type: ChartTypeType) => {
        if (activeSlideId === null || widget === null) return

        const initialValues = getChartTypeInitialValues(type)

        if (initialValues !== null) {
            const { options, ...initialBaseValues } = initialValues

            updateDetails({
                ...initialBaseValues,
                options: null,
                //* We intentionally preserve "selectedDataSource" and "filters" values on chart type change.
                selectedDataSource: widget.details.selectedDataSource,
                filters: widget.details.filters,
            })

            // TODO: This may cause problems. The user may choose a name that starts with "New".
            //       Since we are already doing this on chart type change, maybe we can remove this one.
            if (widget.settings.title.startsWith('New')) {
                const newChartTitle = generateChartName({
                    type: type,
                    dataDefinition: initialBaseValues.dataDefinition,
                })

                updateWidget({
                    slideId: activeSlideId,
                    widgetId: widget.settings.id,
                    data: {
                        title: newChartTitle,
                    },
                })
            }
        }
    }

    /*
     * This should be the only place that we call "generateChartOptions" in order to update store.
     * All sub-components will use callbacks that call this function in the end.
     * Sub-components are free to call "generateChartOptions" for their internal needs, but updating
     * store with values from generateChartOptions is only done in here.
     */
    const onChartUpdate = (
        mode: GenerateChartOptionsParamsModeType,
        updatedValues: Partial<Pick<ChartWidgetType, 'selectedDataSource' | 'dataDefinition' | 'config'>>
    ) => {
        if (activeSlideId === null || widget === null) return

        // Getting values from "selectedDataSource" if it was provided.
        const chartSelectedDataSource =
            updatedValues.selectedDataSource !== undefined
                ? updatedValues.selectedDataSource
                : widget.details.selectedDataSource

        // Create complete data definitions and configs from state or partial params.
        const chartDataDefinitions = (
            updatedValues.dataDefinition !== undefined ? updatedValues.dataDefinition : widget.details.dataDefinition
        ) as ChartDataDefinitionType

        const chartConfig = (
            updatedValues.config !== undefined ? updatedValues.config : widget.details.config
        ) as ChartConfigType

        const targetDataSourceData = getDataSourceData(
            dataSources,
            chartSelectedDataSource,
            getChartAttributes(widget.details.type, chartDataDefinitions)
        )

        const updatedChartOptions = generateChartOptions({
            type: widget.details.type,
            mode: mode,
            prevOptions: widget.details.options,
            filteredData: targetDataSourceData,
            dataDefinition: chartDataDefinitions,
            config: chartConfig,
        })

        updateDetails({
            ...widget.details,
            selectedDataSource: chartSelectedDataSource,
            dataDefinition: chartDataDefinitions,
            config: chartConfig,
            options: updatedChartOptions,
        } as ChartWidgetType)

        // If chart has its default/initial name, update the name based on the data config.
        // TODO: This may cause problems. The user may choose a name that starts with "New".
        //       Since we are already doing this on chart type change, maybe we can remove this one.
        if (widget.settings.title.startsWith('New')) {
            const newChartTitle = generateChartName({
                type: widget.details.type,
                dataDefinition: { ...widget.details.dataDefinition, ...chartDataDefinitions },
            })

            updateWidget({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: {
                    title: newChartTitle,
                },
            })
        }
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={{
                        height: '100%',
                    }}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Chart" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(conditionalVisibility) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility,
                                        },
                                    })
                                }}
                            />

                            {/* Tooltip
                                ========================================= */}
                            <ReportWidgetTooltipSettings
                                tooltip={widget.settings.tooltip}
                                onChange={(tooltip) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            tooltip,
                                        },
                                    })
                                }}
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                overflowY: 'auto',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Chart type select
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Chart type"
                                options={chartTypeOptions}
                                value={widget.details.type}
                                onChange={(value) => onChartTypeChange(value)}
                                sx={(theme) => ({
                                    marginX: theme.spacing(2),
                                })}
                            />

                            {/* Chart type settings
                                ========================================= */}
                            {widget.details.type === 'line' ? (
                                <LineChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'bar' ? (
                                <BarChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'pie' ? (
                                <PieChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'scatter' ? (
                                <ScatterChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'radar' ? (
                                <RadarChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'boxplot' ? (
                                <BoxplotChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'gauge' ? (
                                <GaugeChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'pictorialBar' ? (
                                <PictorialBarChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'wordCloud' ? (
                                <WordCloudChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : widget.details.type === 'treemap' ? (
                                <TreemapChartWidgetSettings
                                    data={widget.details}
                                    dataSources={dataSources}
                                    onUpdate={onChartUpdate}
                                />
                            ) : (
                                false
                            )}
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default ChartWidgetSettings
