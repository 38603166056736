//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import {
    Stack,
    Box,
    Typography,
    Avatar,
    Card,
    CardContent,
    Tooltip,
    TextField,
    List,
    ListItemButton,
    Divider,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItem,
    Chip,
    Menu,
    MenuItem,
    FormControlLabel,
    Checkbox,
    SvgIcon,
    Badge,
    Link,
} from '@mui/material'
import { get, isEqual } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
import ButtonSelectMenu from 'features/button-select-menu/ButtonSelectMenu'
import { AssignedUsers } from 'features/survey-rules/SurveyRulesItem'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDialog from 'components/dialog/StyledDialog'
import EditorClassic from 'components/Editor/EditorClassic'
//* ======= Custom logic
import { NodeType } from 'services/NodeService'
import {
    AddNodeActionAssigneeService,
    AddNodeActionNoteService,
    CreateNodeActionRequestType,
    CreateNodeActionService,
    DeleteNodeActionAssigneeService,
    DeleteNodeActionService,
    NodeActionType,
    UpdateNodeActionService,
    UpdateNodeActionStatusService,
} from 'services/NodeActionService'
import { buildAbbreviation } from 'helpers/TextFormatterHelpers'
import { ProjectAccountType } from 'services/ProjectService'
//* ======= Assets and styles
import CheckIcon from '@mui/icons-material/Check'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import AdjustIcon from '@mui/icons-material/Adjust'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import SendIcon from '@mui/icons-material/Send'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import FlagIcon from '@mui/icons-material/Flag'
import EventIcon from '@mui/icons-material/Event'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import FilterIcon from '@mui/icons-material/FilterList'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import BackIcon from '@mui/icons-material/ArrowBack'

export const STATUS_OPTIONS = [
    {
        label: 'Pending',
        value: 'Pending',
        color: '#FFC107',
        textColor: '#FFFFFF',
        icon: <HourglassEmptyIcon />,
    },
    {
        label: 'In Progress',
        value: 'InProgress',
        color: '#6CACE4',
        textColor: '#FFFFFF',
        icon: <AutorenewIcon />,
    },
    {
        label: 'Resolved',
        value: 'Resolved',
        color: '#4CAF50',
        textColor: '#FFFFFF',
        icon: <CheckCircleIcon />,
    },
    {
        label: 'Ignored',
        value: 'DECLINED',
        color: '#B0B0B0',
        textColor: '#FFFFFF',
        icon: <CancelIcon />,
    },
]

export const PRIORITY_OPTIONS = [
    {
        label: 'Low',
        value: 'Low',
        color: '#B0B0B0',
        textColor: '#FFFFFF',
        icon: <LowPriorityIcon />,
    },
    {
        label: 'Medium',
        value: 'Medium',
        color: '#2196F3',
        textColor: '#FFFFFF',
        icon: <AdjustIcon />,
    },
    {
        label: 'High',
        value: 'High',
        color: '#FF9800',
        textColor: '#FFFFFF',
        icon: <WarningIcon />,
    },
    {
        label: 'Critical',
        value: 'Critical',
        color: '#E53935',
        textColor: '#FFFFFF',
        icon: <ErrorIcon />,
    },
]

interface INodeActions {
    node: NodeType
    accounts: ProjectAccountType[]
    updateNode: (node: NodeType) => void
    selectedAction: NodeActionType | null
    setSelectedAction: (action: NodeActionType | null) => void
    navigationHandlers: {
        toOverview: () => void
        toSurveys: () => void
        toRelations: () => void
    }
}
export type ActionFilterType = { status: string[]; priority: string[]; assignedToMe: boolean }

export const ACTION_FILTER_DEFAULT: ActionFilterType = {
    status: ['Pending', 'InProgress', 'Resolved', 'DECLINED'],
    priority: ['Low', 'Medium', 'High', 'Critical'],
    assignedToMe: false,
}

function NodeActions({
    selectedAction,
    setSelectedAction,
    node,
    accounts,
    updateNode,
    navigationHandlers,
}: INodeActions) {
    const { pid, nid } = useParams()
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showAddActionDialog, setShowAddActionDialog] = useState(false)

    const [actionFilter, setActionFilter] = useState<ActionFilterType>(structuredClone(ACTION_FILTER_DEFAULT))

    const filteredActions = useMemo(() => {
        if (node.actions === undefined) return []
        const result = node.actions.filter((action) => {
            const isStatusMatch = actionFilter.status.includes(action.status)
            const isPriorityMatch = actionFilter.priority.includes(action.priority)
            const isAssignedToMe = actionFilter.assignedToMe
                ? action.assignedTo?.find((x) => x.projectAccountID === 1) !== undefined
                : true
            return isStatusMatch && isPriorityMatch && isAssignedToMe
        })
        return result
    }, [node.actions, actionFilter])

    const deleteAction = async () => {
        if (selectedAction === null || pid == null || nid == null) return
        try {
            const response = await DeleteNodeActionService(pid, nid, selectedAction.primaryKey)
            if (response.success) {
                const clonedNode = structuredClone(node)
                const index = clonedNode.actions?.findIndex((x) => x.primaryKey === selectedAction.primaryKey) ?? -1

                if (index !== -1) {
                    clonedNode.actions?.splice(index, 1)
                    updateNode(clonedNode)
                    setSelectedAction(null)
                    setShowDeleteDialog(false)
                }
            }
        } catch (err) {}
    }

    const addAction = (action: NodeActionType) => {
        setSelectedAction(action)
        const clonedNode = structuredClone(node)
        clonedNode.actions = clonedNode.actions ?? []
        clonedNode.actions?.push(action)
        updateNode(clonedNode)

        setShowAddActionDialog(false)
    }

    const updateSelectedAction = (action: NodeActionType) => {
        if (selectedAction === null) return
        const clonedNode = structuredClone(node)

        const index = clonedNode.actions?.findIndex((x) => x.primaryKey === selectedAction.primaryKey) ?? -1
        setSelectedAction(action)

        if (index !== -1) {
            clonedNode.actions![index] = action
            updateNode(clonedNode)
        }
    }

    const addProjetAccountToAction = async (projectAccountID: number) => {
        if (selectedAction === null || pid == null || nid == null) return

        try {
            const response = await AddNodeActionAssigneeService(pid, nid, selectedAction.primaryKey, {
                projectAccountID,
            })
            if (response.success) {
                updateSelectedAction(response.data)
            }
        } catch (err) {}
    }

    const removeProjetAccountFromAction = async (projectAccountID: number) => {
        if (selectedAction === null || pid == null || nid == null) return
        try {
            const idToRemove = selectedAction.assignedTo?.find(
                (x) => x.projectAccountID === projectAccountID
            )?.primaryKey
            if (idToRemove === undefined) return
            const response = await DeleteNodeActionAssigneeService(pid, nid, selectedAction.primaryKey, idToRemove)
            if (response.success) {
                updateSelectedAction(response.data)
            }
        } catch (err) {}
    }

    const updateStatus = async (status: NodeActionType['status']) => {
        if (selectedAction === null || pid == null || nid == null) return
        try {
            const response = await UpdateNodeActionStatusService(pid, nid, selectedAction.primaryKey, { status })
            if (response.success) {
                updateSelectedAction(response.data)
            }
        } catch (err) {}
    }

    const updateNodeAction = async (
        updates: Partial<Pick<NodeActionType, 'title' | 'priority' | 'dueDate' | 'description'>>
    ) => {
        if (selectedAction === null || pid == null || nid == null) return
        try {
            const { title, priority, dueDate, description } = selectedAction
            const response = await UpdateNodeActionService(pid, nid, selectedAction.primaryKey, {
                title,
                priority,
                dueDate,
                description,
                ...updates,
            })
            if (response.success) {
                updateSelectedAction(response.data)
            }
        } catch (err) {}
    }

    const getStatusOption = (status: NodeActionType['status']) =>
        STATUS_OPTIONS.find((option) => option.value === status)
    const getPriorityOption = (priority: NodeActionType['priority']) =>
        PRIORITY_OPTIONS.find((option) => option.value === priority)

    const getDueDateChip = (dueDate: NodeActionType['dueDate']) => {
        if (!dueDate) return null

        const diff = moment(dueDate).diff(moment(), 'days')
        if (diff > 3) return null

        let label = ''
        let tooltip = ''
        let IconComponent = EventIcon
        let backgroundColor: string = '#B0B0B0' // Default color for no due date
        let textColor: string = '#FFF'

        if (diff < 0) {
            tooltip = `Overdue by ${Math.abs(diff)} day${Math.abs(diff) > 1 ? 's' : ''}`
            label = 'Overdue'
            IconComponent = EventBusyIcon
            backgroundColor = '#F44336' // Red color for overdue
        } else if (diff === 0) {
            tooltip = `Due today`
            label = 'Due'
            IconComponent = WarningIcon
            backgroundColor = '#FFC107' // Yellow color for due today
        } else if (diff < 3) {
            tooltip = `Due in ${diff} day${diff > 1 ? 's' : ''}`
            label = 'Due Soon'
            IconComponent = WarningIcon
            backgroundColor = '#FF9800' // Orange color for due soon
        }

        return (
            <Tooltip title={tooltip}>
                <Chip
                    icon={<IconComponent sx={{ color: textColor }} />}
                    label={label}
                    size="small"
                    sx={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                        '& .MuiChip-icon': {
                            color: textColor,
                        },
                    }}
                />
            </Tooltip>
        )
    }

    return (
        <Stack
            sx={(theme) => ({
                gap: theme.spacing(1),

                height: '100%',
                minHeight: 0,
            })}
        >
            {/* Top row
                ========================================= */}
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    py: theme.spacing(1),
                    px: theme.spacing(2),

                    backgroundColor: theme.palette.common.bg_1,
                })}
            >
                {/* Panel selection buttons
                            ========================================= */}
                <Stack direction="row" alignItems="center" gap={3}>
                    {/* Overview button
                                ========================================= */}
                    <BaseButton
                        label="Overview"
                        onClick={() => navigationHandlers.toOverview()}
                        sx={{
                            width: 120,
                            fontSize: 20,

                            color: 'common.fill_2',
                        }}
                    />

                    {/* Surveys button
                                ========================================= */}
                    <BaseButton
                        label="Surveys"
                        onClick={() => navigationHandlers.toSurveys()}
                        sx={{
                            width: 120,
                            fontSize: 20,

                            color: 'common.fill_2',
                        }}
                    />

                    {/* Relations button
                                ========================================= */}
                    <BaseButton
                        label="Relations"
                        onClick={() => navigationHandlers.toRelations()}
                        sx={{
                            width: 120,
                            fontSize: 20,
                            color: 'common.fill_2',
                        }}
                    />

                    {/* Actions button
                                ========================================= */}
                    <BaseButton
                        label="Actions"
                        sx={{
                            width: 120,
                            fontSize: 20,
                        }}
                    />
                </Stack>
            </Stack>

            {/* Content
                ========================================= */}
            <Stack
                direction="row"
                alignItems="flex-start"
                gap={1}
                sx={(theme) => ({
                    flex: '1 0 0',

                    minHeight: 0,
                    paddingBottom: theme.spacing(1),
                })}
            >
                {/* Actions List */}
                <Box
                    sx={(theme) => ({
                        flex: '0 0 20%',

                        height: '100%',
                        paddingBottom: theme.spacing(1),

                        overflowY: 'auto',

                        backgroundColor: theme.palette.common.bg_1,
                    })}
                    className="u-scrollbar"
                >
                    <Link href={`/project/${pid}/actions`} underline="none">
                        <BaseButton startIcon={<BackIcon />}>See all Actions</BaseButton>
                    </Link>
                    {/* Header */}
                    <Stack
                        sx={(theme) => ({
                            padding: theme.spacing(1, 2, 1, 2),
                        })}
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        {/* Title */}
                        <Typography
                            fontSize={14}
                            fontWeight={500}
                            sx={(theme) => ({
                                color: theme.palette.info.main,
                            })}
                        >
                            Actions
                        </Typography>

                        {/* Filter button */}
                        <Box ml="auto">
                            <ActionFilterMenu
                                initialFilter={actionFilter}
                                onApply={(filters) => setActionFilter(filters)}
                            />
                        </Box>

                        {/* Add action button */}
                        <Tooltip title="Add Action">
                            <IconButton onClick={() => setShowAddActionDialog(true)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    {/* Actions list
                    ========================================= */}

                    <List disablePadding>
                        {/* Action item */}
                        {filteredActions.map((_action, idx, array) => {
                            const isSelected = selectedAction?.primaryKey === _action.primaryKey

                            // Retrieve the corresponding status and priority details
                            const statusDetails = getStatusOption(_action.status)!
                            const priorityDetails = getPriorityOption(_action.priority)!

                            return (
                                <React.Fragment key={_action.primaryKey}>
                                    <ListItemButton
                                        onClick={() => setSelectedAction(_action)}
                                        selected={isSelected}
                                        sx={{ padding: '12px 16px', '&:hover': { backgroundColor: '#f5f5f5' } }}
                                    >
                                        <Stack gap={0.5}>
                                            <Typography variant="button" fontWeight="bold">
                                                {_action.title}
                                            </Typography>

                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Box display="flex" gap={1}>
                                                    <Chip
                                                        icon={statusDetails.icon}
                                                        label={statusDetails.label}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: statusDetails.color,
                                                            color: statusDetails.textColor,
                                                            '& .MuiChip-icon': {
                                                                color: statusDetails.textColor,
                                                            },
                                                        }}
                                                    />

                                                    <Chip
                                                        icon={<FlagIcon />}
                                                        label={priorityDetails.label}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: priorityDetails.color,
                                                            color: priorityDetails.textColor,
                                                            '& .MuiChip-icon': {
                                                                color: priorityDetails.textColor,
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Box>

                                            {_action.status === 'Resolved' ? (
                                                <Box display="flex" gap={1} alignItems="center">
                                                    <EventAvailableIcon color="success" />
                                                    <Typography variant="body2">
                                                        Resolved on: {moment(_action.endDate).format('MMM DD')}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box display="flex" gap={1} alignItems="center">
                                                    <Tooltip title="Due Date">
                                                        <>
                                                            <EventIcon />
                                                            <Typography variant="body2">
                                                                {moment(_action.dueDate).format('MMM DD')}
                                                            </Typography>
                                                        </>
                                                    </Tooltip>
                                                    {getDueDateChip(_action.dueDate)}
                                                </Box>
                                            )}
                                        </Stack>
                                    </ListItemButton>

                                    {idx !== array.length - 1 && <Divider />}
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Box>

                {/* Action details
                    ========================================= */}
                <Stack
                    sx={(theme) => ({
                        flex: '1 0 0',
                        gap: theme.spacing(2),

                        height: '100%',
                        minHeight: 0,
                        padding: theme.spacing(3),
                        overflowY: 'auto',

                        borderRadius: '4px',
                        backgroundColor: theme.palette.common.bg_1,
                    })}
                    className="u-scrollbar"
                >
                    {selectedAction ? (
                        <>
                            {/* Header */}
                            <Stack direction="row" alignItems="center" gap={5} height="70px">
                                <TextField
                                    defaultValue={selectedAction.title}
                                    key={selectedAction.primaryKey}
                                    label="Title"
                                    onBlur={(e) => updateNodeAction({ title: e.target.value })}
                                    variant="outlined"
                                />

                                {/* Status  */}
                                <Stack direction={'row'} alignItems="center" gap={1}>
                                    <ButtonSelectMenu
                                        value={selectedAction.status}
                                        options={STATUS_OPTIONS}
                                        onChange={(status) => updateStatus(status)}
                                        tooltip="Status"
                                    />

                                    <Tooltip title="Mark as Resolved">
                                        <BaseButton
                                            variant="contained"
                                            color="success"
                                            onClick={() => updateStatus('Resolved')}
                                            sx={{ minWidth: 'auto', px: 1 }}
                                        >
                                            <CheckIcon />
                                        </BaseButton>
                                    </Tooltip>
                                </Stack>

                                <Tooltip title="Delete">
                                    <BaseButton
                                        sx={{ marginLeft: 'auto' }}
                                        endIcon={<DeleteIcon />}
                                        color="error"
                                        onClick={() => setShowDeleteDialog(true)}
                                    >
                                        Delete
                                    </BaseButton>
                                </Tooltip>
                            </Stack>

                            <Divider />

                            {/* Action Content
                                ========================================= */}
                            <Stack direction="row" gap={1} sx={{ maxHeight: '100%', flexGrow: 1, overflowY: 'auto' }}>
                                {/* Configs */}
                                <Stack gap={2}>
                                    {/* User Assignment and priority */}{' '}
                                    <Stack direction="row" gap={1} pl={1}>
                                        <AssignedUsers
                                            size={40}
                                            accounts={accounts}
                                            onAddAccount={addProjetAccountToAction}
                                            onRemoveAccount={removeProjetAccountFromAction}
                                            selectedAccounts={
                                                selectedAction.assignedTo?.map((x) => x.projectAccountID) || []
                                            }
                                        />
                                        <Box sx={{ marginLeft: 'auto' }}>
                                            <ButtonSelectMenu
                                                value={selectedAction.priority}
                                                onChange={(priority) => updateNodeAction({ priority })}
                                                options={PRIORITY_OPTIONS}
                                                tooltip="Priority"
                                            />
                                        </Box>
                                    </Stack>
                                    {/* Due Date */}
                                    <DatePicker
                                        label="Due Date"
                                        value={selectedAction.dueDate ? moment(selectedAction.dueDate) : null}
                                        slotProps={{
                                            field: {
                                                clearable: true,
                                                onClear: () => {
                                                    updateNodeAction({ dueDate: null })
                                                },
                                            },
                                        }}
                                        onChange={(date) => {
                                            if (date) {
                                                const formattedDate = moment(date)
                                                    .hour(23)
                                                    .minute(59)
                                                    .second(0)
                                                    .utc()
                                                    .toISOString()
                                                updateNodeAction({ dueDate: formattedDate })
                                            } else {
                                                updateNodeAction({ dueDate: null })
                                            }
                                        }}
                                    />
                                    {/* Description */}
                                    <Box sx={{ width: '30vw', flexGrow: 1 }}>
                                        <EditorClassic
                                            data={selectedAction.description ?? ''}
                                            onBlur={(event, editor) =>
                                                updateNodeAction({ description: editor.getData() })
                                            }
                                        />
                                    </Box>
                                </Stack>
                                {/* Notes */}
                                <NotesTimeline
                                    updateAction={updateSelectedAction}
                                    nodeActionId={selectedAction.primaryKey}
                                    notes={selectedAction.notes}
                                />
                            </Stack>
                        </>
                    ) : (
                        <Typography variant="subtitle1" color="textSecondary">
                            Click on an action to view its details.
                        </Typography>
                    )}
                </Stack>
            </Stack>

            {/* Delete dialog
                ========================================= */}
            <StyledDialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                <DialogTitle>
                    <Typography variant="h6">Delete Action</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this action? This action cannot be undone.</Typography>
                </DialogContent>
                <DialogActions>
                    <BaseButton onClick={() => setShowDeleteDialog(false)}>Cancel</BaseButton>
                    <BaseButton onClick={deleteAction} color="error">
                        Delete
                    </BaseButton>
                </DialogActions>
            </StyledDialog>

            {/* Add action dialog
                ========================================= */}
            <AddActionDialog
                open={showAddActionDialog}
                onClose={() => setShowAddActionDialog(false)}
                onAdd={addAction}
            />
        </Stack>
    )
}

type NotesTimelineProps = {
    updateAction: (action: NodeActionType) => void
    nodeActionId: number
    notes: NodeActionType['notes'] | undefined
}

function NotesTimeline({ nodeActionId, notes, updateAction }: NotesTimelineProps) {
    const { pid, nid } = useParams()
    const [note, setNote] = useState<string>('')
    const [isFocused, setIsFocused] = useState(false)

    const sortedNotes = useMemo(() => {
        if (notes === undefined) return []
        return notes.sort((a, b) => moment(a.createdDate).diff(moment(b.createdDate)))
    }, [notes])

    const createNote = async () => {
        try {
            if (pid === undefined || nid === undefined) return
            if (!note.trim()) return
            const res = await AddNodeActionNoteService(pid, nid, nodeActionId, { note })
            if (res.success) {
                updateAction(res.data)
                setNote('')
            }
        } catch (err) {}
    }

    return (
        <Stack
            sx={{
                height: '100%',
                width: '100%',
                flexDirection: 'column',
            }}
        >
            <Typography
                variant="h6"
                sx={(theme) => ({
                    paddingX: theme.spacing(1),
                    color: theme.palette.info.main,
                })}
            >
                Activty History
            </Typography>
            {/* Timeline */}

            <List
                sx={{
                    width: '100%',
                    overflowY: 'auto',
                    flexGrow: 1, // It will grow to fill available space
                    flexShrink: 1, // It can also shrink if needed
                }}
                className="u-scrollbar"
            >
                {sortedNotes.map((note) => (
                    <ListItem key={note.primaryKey}>
                        {note.type === 'Comment' ? (
                            <Stack direction="row" alignItems="flex-start" gap={2} sx={{ width: '100%' }}>
                                <Avatar src={note.creator.avatar} alt={note.creator.name}>
                                    {buildAbbreviation(note.creator.name)}
                                </Avatar>
                                <Card variant="outlined" sx={{ flexGrow: 1 }}>
                                    <CardContent>
                                        <Stack
                                            sx={{ width: '100%' }}
                                            direction={'row'}
                                            alignItems="center"
                                            gap={1}
                                            justifyContent="space-between"
                                        >
                                            <Typography variant="subtitle2">{note.creator.name} commented:</Typography>
                                            <Tooltip title={moment(note.createdDate).format('MMM DD YYYY at HH:mm')}>
                                                <Typography
                                                    sx={{ cursor: 'pointer' }}
                                                    variant="caption"
                                                    color="textSecondary"
                                                >
                                                    {moment(note.createdDate).format('MMM DD [at] HH:mm')}
                                                </Typography>
                                            </Tooltip>
                                        </Stack>
                                        <Typography variant="body1" mt={1}>
                                            {note.note}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Stack>
                        ) : (
                            <Stack
                                sx={{ width: '100%' }}
                                direction={'row'}
                                alignItems="center"
                                gap={1}
                                justifyContent="space-between"
                            >
                                <Typography>{note.note}</Typography>
                                <Tooltip title={moment(note.createdDate).format('MMM DD YYYY at HH:mm')}>
                                    <Typography variant="caption" sx={{ cursor: 'pointer' }} color="textSecondary">
                                        {moment(note.createdDate).format('MMM DD [at] HH:mm')}
                                    </Typography>
                                </Tooltip>
                            </Stack>
                        )}
                    </ListItem>
                ))}
            </List>

            <Stack
                direction="row"
                sx={{
                    flexGrow: 0, // It won't grow
                    flexShrink: 0, // It won't shrink
                }}
                onFocus={() => setIsFocused(true)} // When the input gains focus
                onBlur={() => setIsFocused(false)} // When the input loses focus
            >
                {/* Base Filled Text Field */}
                <BaseFilledTextField
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    multiline
                    onKeyDown={(e) => {
                        if (!e.shiftKey && e.key === 'Enter') {
                            createNote()
                            e.preventDefault() // Prevents the default action of adding a newline
                        }
                    }}
                    rows={isFocused ? 3 : 1} // If focused, use 3 rows, otherwise 1
                    fullWidth
                    placeholder="Add a note..."
                    hiddenLabel
                />
                <BaseButton sx={{ width: '60px' }} variant="contained" fullWidth onClick={createNote}>
                    <SendIcon />
                </BaseButton>
            </Stack>
        </Stack>
    )
}

type AddActionDialogProps = {
    open: boolean
    onClose: () => void
    onAdd: (action: NodeActionType) => void
}

function AddActionDialog({ onAdd, open, onClose }: AddActionDialogProps) {
    const { pid, nid } = useParams()

    const [action, setAction] = useState<CreateNodeActionRequestType>({
        title: '',
        dueDate: null,
        priority: 'Low',
    })

    useEffect(() => {
        setAction({
            title: '',
            dueDate: null,
            priority: 'Medium',
        })
    }, [open])

    const addAction = async () => {
        if (pid === undefined || nid === undefined) return
        if (!action.title.trim()) return
        try {
            const res = await CreateNodeActionService(pid, nid, action)
            if (res.success) {
                onAdd(res.data)
            }
        } catch (err) {}
    }

    const updateAction = (updates: Partial<CreateNodeActionRequestType>) => {
        setAction((a) => ({ ...a, ...updates }))
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant="h6">Add Action</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2} py={1}>
                    <TextField
                        value={action.title}
                        onChange={(e) => updateAction({ title: e.target.value })}
                        label="Title"
                        variant="outlined"
                    />

                    <Stack direction="row" alignItems="center" gap={2} alignContent="space-between">
                        <DatePicker
                            label="Due Date"
                            value={action.dueDate}
                            sx={{ flex: 1 }}
                            onChange={(dueDate) => {
                                if (dueDate) {
                                    updateAction({ dueDate })
                                } else {
                                    updateAction({ dueDate: null })
                                }
                            }}
                        />

                        <Stack direction="row" alignItems="center" gap={1} sx={{ flex: 1 }} justifyContent="flex-end">
                            <Typography>Priority:</Typography>
                            <Box>
                                <ButtonSelectMenu
                                    value={action.priority}
                                    onChange={(priority) => updateAction({ priority })}
                                    options={PRIORITY_OPTIONS}
                                    tooltip="Priority"
                                />
                            </Box>
                        </Stack>
                    </Stack>

                    <Box sx={{ height: '250px', width: '100%' }}>
                        <EditorClassic
                            data={action.description ?? ''}
                            onBlur={(event, editor) => {
                                updateAction({ description: editor.getData() })
                            }}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose}>Cancel</BaseButton>
                <BaseButton onClick={addAction}>Add</BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

type ActionFilterMenuProps = {
    initialFilter: ActionFilterType
    onApply: (filter: ActionFilterType) => void
}

export const ActionFilterMenu: React.FC<ActionFilterMenuProps> = ({ initialFilter, onApply }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [localFilter, setLocalFilter] = useState<ActionFilterType>(structuredClone(initialFilter))

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLocalFilter(structuredClone(initialFilter))
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setLocalFilter(initialFilter)
    }

    const handleApply = () => {
        setAnchorEl(null)
        onApply(localFilter)
    }

    const resetFilters = () => {
        setAnchorEl(null)
        onApply(structuredClone(ACTION_FILTER_DEFAULT))
    }

    const handleStatusChange = (status: string) => {
        setLocalFilter((prevState) => ({
            ...prevState,
            status: prevState.status.includes(status)
                ? prevState.status.filter((s) => s !== status)
                : [...prevState.status, status],
        }))
    }

    const handlePriorityChange = (priority: string) => {
        setLocalFilter((prevState) => ({
            ...prevState,
            priority: prevState.priority.includes(priority)
                ? prevState.priority.filter((p) => p !== priority)
                : [...prevState.priority, priority],
        }))
    }

    const handleAssignedChange = () => {
        setLocalFilter((prevState) => ({
            ...prevState,
            assignedToMe: !prevState.assignedToMe,
        }))
    }

    const hasFilter = useMemo(() => {
        return !isEqual(initialFilter, ACTION_FILTER_DEFAULT)
    }, [initialFilter])

    return (
        <>
            <Tooltip title="Filter">
                <IconButton onClick={handleOpen}>
                    <Badge color="error" variant="dot" invisible={!hasFilter}>
                        <FilterIcon />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <Typography variant="h6" sx={{ padding: '8px 16px' }}>
                    Filter Actions
                </Typography>
                <Divider />

                <Stack direction="row" alignItems="start" gap={2} sx={{ padding: '8px 16px' }}>
                    {/* By Status */}
                    <Stack spacing={2}>
                        <Typography variant="subtitle1">By Status</Typography>

                        {STATUS_OPTIONS.map((status) => (
                            <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
                                <FormControlLabel
                                    onChange={() => handleStatusChange(status.value)}
                                    checked={localFilter.status.includes(status.value)}
                                    control={<Checkbox name={status.label} sx={{ color: status.color }} />}
                                    label={status.label}
                                />
                                <SvgIcon style={{ color: status.color }}>{status.icon}</SvgIcon>
                            </Stack>
                        ))}
                    </Stack>

                    {/* Vertical Divider */}
                    <Box sx={{ width: '2px', backgroundColor: 'divider', alignSelf: 'stretch', my: 1 }}></Box>

                    {/* By Priority */}
                    <Stack spacing={2}>
                        <Typography variant="subtitle1">By Priority</Typography>

                        {PRIORITY_OPTIONS.map((priority) => (
                            <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
                                <FormControlLabel
                                    onChange={() => handlePriorityChange(priority.value)}
                                    checked={localFilter.priority.includes(priority.value)}
                                    control={<Checkbox name={priority.label} sx={{ color: priority.color }} />}
                                    label={priority.label}
                                />
                                <SvgIcon style={{ color: priority.color }}>{priority.icon}</SvgIcon>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>

                {/* Assigned to Me */}
                <MenuItem>
                    <FormControlLabel
                        onChange={handleAssignedChange}
                        checked={localFilter.assignedToMe}
                        control={<Checkbox name="assignedToMe" />}
                        label="Assigned to Me"
                    />
                </MenuItem>
                <Divider />

                {/* Apply button */}

                <MenuItem>
                    <BaseButton
                        variant="outlined"
                        color="secondary"
                        sx={{ mx: 'auto', display: 'block' }}
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </BaseButton>
                    <BaseButton
                        variant="contained"
                        color="primary"
                        sx={{ mx: 'auto', display: 'block' }}
                        onClick={handleApply}
                    >
                        Apply Filters
                    </BaseButton>
                </MenuItem>
            </Menu>
        </>
    )
}

export default NodeActions
