import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { InsightItemType, NodeDictonaryType } from '../../InisghtWidgetInterventionAdvancedView'
import IssueSummaryCard from '../widgets/IssueSummaryCard'
import { useEffect, useMemo, useState } from 'react'
import { Box, Chip, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { string } from 'mathjs'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'

type InsightCategorySummaryPanelType = {
    categoryId: string
    items: InsightItemType[string]
    nodeDictionary: NodeDictonaryType
    totalNodes: number
}

const InsightCategorySummaryPanel = ({
    items,
    nodeDictionary,
    totalNodes,
    categoryId,
}: InsightCategorySummaryPanelType) => {
    const [issuesSummary, setIssuesSummary] = useState<{
        totalIssues: number
        highUrgencyIssues: number
    }>({
        totalIssues: 0,
        highUrgencyIssues: 0,
    })

    useEffect(() => {
        const totalIssues = Object.values(items.issues).reduce((acc, insight) => {
            return acc + insight.nodes.length
        }, 0)

        const highUrgencyIssues = Object.values(items.issues).reduce((acc, insight) => {
            return acc + insight.nodes.filter((node) => node.severity === 'high').length
        }, 0)

        setIssuesSummary({
            totalIssues,
            highUrgencyIssues,
        })
    }, [items])

    const volnurableNodes = useMemo(() => {
        const nodes: {
            id: string
            title: string
            issues: {
                name: string
                icon: string | undefined
            }[]
        }[] = []

        Object.entries(nodeDictionary).forEach(([nodeId, node]) => {
            const issues = node.issues.filter((issue) => issue.category === categoryId)
            if (issues.length === 0) return
            nodes.push({
                id: nodeId,
                title: node.title,
                issues: issues.map((issue) => ({
                    name: issue.text,
                    icon: issue.icon,
                    color: issue,
                })),
            })
        })

        nodes.sort((a, b) => b.issues.length - a.issues.length)

        return nodes
    }, [nodeDictionary, items.title])

    return (
        <Grid2 container sx={(theme) => ({})}>
            <Grid2 xs={12} md={4} p={2}>
                <Stack height="100%" component={Paper} direction="column" gap={1} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Summary
                    </Typography>
                    {/* Total number of issues */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                            {issuesSummary.totalIssues}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            issues
                        </Typography>
                    </Stack>
                    <Divider flexItem />
                    {/* High urgency issues */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" component="span" color="error" fontWeight="medium">
                            {issuesSummary.highUrgencyIssues}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            high urgency issues
                        </Typography>
                    </Stack>

                    <Divider flexItem />
                    {/* Number of impacted students */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" component="span" fontWeight="medium">
                            {Object.keys(nodeDictionary).length}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            studnets impacted out of {totalNodes}
                        </Typography>
                    </Stack>

                    <Divider flexItem />

                    {/* Average issues per student */}
                    <Stack direction="row" alignItems="center" gap={1} fontWeight="medium">
                        <Typography variant="h5" component="span">
                            {totalNodes === 0 ? 0 : (issuesSummary.totalIssues / totalNodes).toFixed(2)}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            avg issues per student
                        </Typography>
                    </Stack>
                </Stack>
            </Grid2>
            <Grid2 xs={12} md={8} p={2}>
                <IssueSummaryCard title={items.title} icon={items.icon} items={items} onClick={() => {}} />
            </Grid2>
            <Grid2 xs={12} lg={12} p={2} height={430}>
                <Stack component={Paper} height="100%" width={'100%'} direction="column" gap={1} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Vulnerable students
                    </Typography>
                    <Box width="100%" flexGrow={1}>
                        <StyledDataGrid
                            rows={volnurableNodes}
                            columns={[
                                { field: 'title', width: 150, headerName: 'Name' },
                                {
                                    field: 'issues',
                                    headerName: 'Issues',
                                    renderCell: (params) => {
                                        return (
                                            <Stack
                                                direction="row"
                                                gap={1}
                                                sx={{ width: '100%', overflow: 'auto' }}
                                                className="u-scrollbar"
                                            >
                                                {params.value.map((issue: any) => (
                                                    <Chip
                                                        key={issue.name}
                                                        label={issue.name}
                                                        icon={
                                                            issue.icon ? <DynamicIcon icon={issue.icon} /> : undefined
                                                        }
                                                        sx={{
                                                            backgroundColor: issue.bgColor,
                                                            color: issue.color,
                                                            '& .MuiChip-icon': {
                                                                color: issue.color,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Stack>
                                        )
                                    },
                                },
                            ]}
                            hasHeader={false}
                            hasToolbar={false}
                            hasSearch={false}
                            pagination={true}
                            autoPageSize={true}
                            rowSelection={false}
                            rowHeight={60}
                        />
                    </Box>
                </Stack>
            </Grid2>
        </Grid2>
    )
}

export default InsightCategorySummaryPanel
