import { parseUrl } from './Apis'
import { request } from './request'
import { Location as Apis } from './Apis'

export type GetAdressServiceResponseType = {
    data: string[]
    success: boolean
}

export const GetAdressService: (
    query: string,
    showMessage?: boolean
) => Promise<GetAdressServiceResponseType> = async (query, showMessage = false) => {
    return request(
        parseUrl(Apis.AddressSearch, { query: encodeURIComponent(query) }),
        'GET',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        false
    )
}

export type GetReverseGeocodeResponseType = {
    data: string
    success: boolean
}

export const GetReverseGeocode: (
    lat: number,
    lon: number,
    showMessage?: boolean
) => Promise<GetReverseGeocodeResponseType> = async (lat, lon, showMessage = false) => {
    return request(
        parseUrl(Apis.ReverseGeocode, { lat, lon }),
        'GET',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        false
    )
}

export type GetGeocodeServiceResponseType = {
    data: [number, number]
    success: boolean
}

export const GetGeocodeService: (
    query: string,
    showMessage?: boolean
) => Promise<GetGeocodeServiceResponseType> = async (query, showMessage = false) => {
    return request(
        parseUrl(Apis.Geocode, { query: encodeURIComponent(query) }),
        'GET',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        false
    )
}
