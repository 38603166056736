//* ======= Libraries
import { useEffect, useMemo, useState } from 'react'
import { PartialDeep } from 'type-fest'
import { Divider, Stack, Typography, Tabs, Tab, Box } from '@mui/material'
//* ======= Components and features
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BorderPicker from 'components/widgets/BorderPicker'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import { getDataSourceFields } from 'features/report-designer/helpers/reportDesigner.helper'
import {
    FilterWidgetType,
    ReportWidgetContentKindType,
    ReportWidgetSettingsBaseType,
    ReportWidgetType,
    SelectedDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import { deepMergeCustom } from 'helpers/helpers'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import ConnectedWidgetsSettings from '../components/ConnectedWidgetsSettings'
//* ======= Assets and styles

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'filter'>
    settings: Omit<ReportWidgetType, 'content'>
    details: FilterWidgetType
}

function FilterWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { dataSources, updateWidget, updateWidgetStyles, updateFilterWidgetContent } = useReportStore((store) => ({
        dataSources: store.dataSources,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updateFilterWidgetContent: store.updateFilterWidgetContent,
    }))

    const activeSlide = useReportActiveSlide()
    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'filter') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])

    const [controllableWidgets, setControllableWidgets] = useState<ReportWidgetType[]>([])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    // Update selected data source fields
    useEffect(() => {
        if (activeWidget !== null && activeWidget.content.kind === 'filter') {
            const {
                content: { details },
            } = activeWidget

            if (details.selectedDataSource === undefined) {
                setDataSourceFields([])
            } else {
                const dataSourceFields = getDataSourceFields(dataSources, details.selectedDataSource)

                setDataSourceFields(dataSourceFields)
            }
        }
    }, [dataSources, activeWidget])

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlide !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<FilterWidgetType>) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            deepMergeCustom(updatedDetails, details)

            updateFilterWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }

    const toggleMultiple = (isEnabled: boolean) => {
        updateDetails({
            isMultiple: isEnabled,
            value: isEnabled ? [] : '',
        })
    }

    const updateSelectedDataSource = (data: SelectedDataSourceType) => {
        updateDetails({
            selectedDataSource: structuredClone(data),
        })
    }

    return (
        <>
            {activeSlide !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Filter" />
                            </Tabs>
                        </Box>
                    )}
                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Data Selector settings
                                ========================================= */}
                            <ReportDataSourceSelector
                                layoutDirection="column"
                                selectedDataSource={widget.details.selectedDataSource}
                                dataSources={dataSources}
                                hideFilterSelection={true}
                                enableGraphInfo={false}
                                onChange={updateSelectedDataSource}
                            />

                            <Divider />

                            {/* Filter field select
                                ========================================= */}
                            <FlexibleSelect
                                label="Filter field"
                                options={dataSourceFields}
                                value={widget.details.field}
                                onChange={(value) =>
                                    updateDetails({
                                        field: value,
                                    })
                                }
                            />

                            {/* Filter Label input
                                ========================================= */}
                            <BaseFilledTextField
                                label="Field label"
                                value={widget.details.label}
                                onChange={(event) =>
                                    updateDetails({
                                        label: event.target.value,
                                    })
                                }
                            />

                            {/* Font size input
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Font Size"
                                options={[
                                    { label: 'Extra Small', value: 10 },
                                    { label: 'Small', value: 12 },
                                    { label: 'Medium', value: 14 },
                                    { label: 'Large', value: 16 },
                                    { label: 'Extra Large', value: 18 },
                                ]}
                                value={widget.details.fontSize}
                                onChange={(value) =>
                                    updateDetails({
                                        fontSize: value,
                                    })
                                }
                                fullWidth
                                size="small"
                            />

                            {/* Multiple switch
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    textAlign="left"
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Multiselect:
                                </Typography>

                                <BaseSwitch
                                    checked={widget.details.isMultiple}
                                    onChange={(evt, checked) => toggleMultiple(checked)}
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>

                            <Divider />

                            {/* Controllable widgets
                                ========================================= */}
                            <ConnectedWidgetsSettings
                                connectedWidgets={widget.details.connectedWidgets}
                                onConnectedWidgetsUpdate={(newConnectedWidgets) =>
                                    updateDetails({ connectedWidgets: newConnectedWidgets })
                                }
                                slideWidgets={activeSlide.widgets}
                                selectedWidgetId={widgetId}
                            />
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default FilterWidgetSettings
