//* ======= Libraries
import React, { useState, useEffect } from 'react'
import { GridLoader } from 'react-spinners'
import { Stack, Box, useTheme, alpha } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import { ImageWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

type Props = {
    data: ImageWidgetType
    onReady: () => void
}

function ImageWidget({ data, onReady }: Props) {
    const muiTheme = useTheme()

    const [isBuffering, setIsBuffering] = useState(true)

    useEffect(() => {
        onReady()
    }, [])

    useEffect(() => {
        if (data.status === 'uploading') {
            setIsBuffering(true)
        }
    }, [data.status])

    return data.source === '' ? (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
                padding: 1,
            }}
        >
            <ImageOutlinedIcon
                sx={(theme) => ({
                    width: '90%',
                    height: '90%',

                    color: theme.palette.common.fill_2,
                })}
            />
        </Stack>
    ) : (
        <Box
            sx={(theme) => ({
                position: 'relative',

                width: '100%',
                height: '100%',
            })}
        >
            {/* Buffering spinner
                ========================================= */}
            {isBuffering && (
                <Box
                    sx={(theme) => ({
                        position: 'absolute',
                        top: 0,
                        left: 0,

                        display: 'grid',
                        placeContent: 'center',

                        width: '100%',
                        height: '100%',

                        backgroundColor: alpha(theme.palette.common.bg_2, 0.75),
                    })}
                >
                    <GridLoader color={muiTheme.palette.primary.main} />
                </Box>
            )}

            {/* Image
                ========================================= */}
            <img
                src={data.source}
                alt="widget content"
                onLoad={(evt) => {
                    setIsBuffering(false)
                }}
                style={{
                    width: '100%',
                    height: '100%',
                    opacity: (data.transparency ?? 100) / 100,
                    objectFit: data.fit,
                    objectPosition: 'center',
                }}
            />
        </Box>
    )
}

export default ImageWidget
