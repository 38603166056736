//* ======= Libraries
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles
import InfoIcon from '@mui/icons-material/Info'
import ChipMultiSelect from 'components/chip-multi-select/ChipMultiSelect'
import { EMAIL_REGEX } from 'helpers/validators'

interface ISendSurveyInvitation {
    onConfirm?: () => void
    onClose?: () => void
}

function SendSurveyInvitation({ onConfirm, onClose }: ISendSurveyInvitation) {
    const [users, setUsers] = useState<string[]>([
        'Grumpy@factory.com',
        'Doodly@workshop.cr',
        'Bored@home.meh',
    ])

    return (
        <Stack gap={2} minWidth={0} py={5} px={6} width={752}>
            {/* Title
				========================================= */}
            <Typography alignSelf="flex-start" fontSize={36} fontWeight={400} color="common.text_1">
                Send Test Invitation
            </Typography>

            {/* Input
				========================================= */}
            <ChipMultiSelect
                suggestions={[]}
                placeholder="Type an email address and click on the add button"
                onChange={(v) => {}}
                regex={EMAIL_REGEX}
            />

            {/* Message
				========================================= */}
            <Typography alignSelf="flex-start" fontSize={16} fontWeight={400} color="secondary">
                Message
            </Typography>
            <BaseFilledTextField hiddenLabel fullWidth multiline rows={9} />

            {/* Info
				========================================= */}
            <Stack direction="row" alignItems="center" gap={1} marginBottom={1} paddingLeft={1}>
                <InfoIcon
                    sx={{
                        color: 'primary.main',
                    }}
                />

                <Typography alignSelf="flex-start" fontSize={16} fontWeight={400} color="secondary">
                    The survey link will be added automatically
                </Typography>
            </Stack>

            {/* Controls
				========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                {/* Cancel button
					========================================= */}
                <BaseButton
                    label="Cancel"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    size="large"
                    width="186px"
                />

                {/* Publish button
					========================================= */}
                <BaseButton
                    label="Send"
                    onClick={(e) => onConfirm && onConfirm()}
                    variant="contained"
                    size="large"
                    width="186px"
                />
            </Stack>
        </Stack>
    )
}

export default SendSurveyInvitation

const StyledEmailChip = styled(Chip)(({ theme }) => ({
    py: theme.spacing(0.25),
    px: theme.spacing(0.5),

    borderRadius: '5px',
    backgroundColor: theme.palette.common.bg_5,
    color: theme.palette.common.text_1,

    '& .MuiChip-deleteIcon': {
        color: theme.palette.primary.main,

        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
}))
