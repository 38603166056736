//* ======= Libraries
import React, { useEffect } from 'react'
import { Stack, Box } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import { HorizontalLineWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles

type Props = {
    data: HorizontalLineWidgetType
    scaleFactor: number
    onReady: () => void
}

function HorizontalLineWidget({ data, scaleFactor, onReady }: Props) {
    useEffect(() => {
        onReady()
    }, [])
    return (
        <Stack
            justifyContent="center"
            sx={(theme) => ({
                width: '100%',
                height: '100%',
            })}
        >
            <Box
                sx={{
                    width: '100%',
                    height: data.height * scaleFactor + 'px',

                    borderRadius: '2px',
                    backgroundColor: data.color,
                }}
            />
        </Stack>
    )
}

export default HorizontalLineWidget
