export function omitDeep(input) {
    if (Array.isArray(input)) {
        const result = []
        input.forEach((i) => {
            const v = omitDeep(i)
            if (!isNil(v)) result.push(v)
        })
        return result
    }

    if (isObject(input)) {
        const result = {}
        for (let key in input) {
            const v = omitDeep(input[key])
            if (!isNil(v)) result[key] = v
        }
        return result
    }
    return input
}

function isNil(value) {
    return value === null || value === undefined
}

function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
}
