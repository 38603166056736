//* ======= Libraries
import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { EChartsCoreOption } from 'echarts'
import moment from 'moment'
//* ======= Components and features
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Accordion from '@mui/material/Accordion'
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Select from '@mui/material/Select'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseButton from 'components/base/BaseButton'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
import { SurveyInstanceStatusDataMap } from 'features/survey-overview/SurveyOverview'
//* ======= Custom logic
import { SurveyInstanceBasicType } from 'services/SurveyService'
//* ======= Assets and styles
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EmailIcon from '@mui/icons-material/Email'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { NodeType } from 'services/NodeService'
import { useNavigate } from 'react-router-dom'
import Chart from 'features/chart/Chart'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
    // flexDirection: 'row-reverse',
    gap: theme.spacing(2.5),

    padding: theme.spacing(2, 2),

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        // transform: 'rotate(-180deg)',
        color: theme.palette.primary.main,
    },

    '& .MuiAccordionSummary-content': {
        margin: 0,

        color: theme.palette.common.text_1,

        // '&.Mui-expanded': {
        //     color: theme.palette.primary.main,
        // },
    },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),

    minWidth: 0,
    padding: theme.spacing(1, 2, 2, 3),
}))

interface INodeOverview {
    node: NodeType
    navigationHandlers: {
        toSurveys: () => void
        toRelations: () => void
        toActions: () => void
    }
}

function NodeOverview({ node, navigationHandlers }: INodeOverview) {
    const navigator = useNavigate()
    const selectOptions = [
        {
            value: '',
            label: 'None',
        },
        {
            value: '1',
            label: 'One',
        },
        {
            value: '2',
            label: 'Two',
        },
    ]

    const surveyOverview: {
        active: number
        notStarted: number
        completed: number
        chart?: EChartsCustomOptionType
        nextSurvey?: SurveyInstanceBasicType
    } = useMemo(() => {
        if (node?.surveyInstances != null && node.surveyInstances.length > 0) {
            const seriesData: { [key: string]: number } = {}
            let nextSurvey = node.surveyInstances.find((x) => !['completed', 'expired'].includes(x.status))

            for (let statusType in SurveyInstanceStatusDataMap) {
                const v = node.surveyInstances.filter((si) => si.status === statusType).length
                if (v > 0) seriesData[statusType] = v
            }
            const chart: EChartsCustomOptionType = {
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['40%', '70%'],
                        itemStyle: {
                            borderRadius: 8,
                        },
                        color: Object.keys(seriesData).map((key) => SurveyInstanceStatusDataMap[key].color),
                        data: Object.keys(seriesData).map((key) => ({
                            value: seriesData[key],
                            name: SurveyInstanceStatusDataMap[key].label,
                        })),
                    },
                ],
            }

            return {
                active: seriesData.invited || 0 + seriesData.in_progress || 0,
                notStarted: seriesData.not_started || 0,
                completed: seriesData.completed || 0,
                nextSurvey,
                chart,
            }
        }
        return {
            active: 0,
            notStarted: 0,
            completed: 0,
        }
    }, [node?.surveyInstances])

    const network: EChartsCustomOptionType | null = useMemo(() => {
        if (node.network) {
            const links: any[] = []
            const nodes: { [key: number]: any } = {
                [node.primaryKey]: {
                    id: node.primaryKey + '',
                    name: node.title,
                    symbolSize: 20,
                    label: { show: true },
                    itemStyle: {
                        color: '#8ac588',
                    },
                },
            }
            node.network.forEach((rel) => {
                links.push({
                    source: rel.source.id + '',
                    target: rel.target.id + '',
                })
                if (!nodes[rel.source.id]) {
                    nodes[rel.source.id] = {
                        id: rel.source.id + '',
                        name: rel.source.title,
                        symbolSize: 15,
                    }
                }
                if (!nodes[rel.target.id]) {
                    nodes[rel.target.id] = {
                        id: rel.target.id + '',
                        name: rel.target.title,
                        symbolSize: 15,
                    }
                }
            })
            return {
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: { show: true },
                    },
                },
                animationDuration: 1500,
                animationEasingUpdate: 'quinticInOut',
                series: [
                    {
                        type: 'graph',
                        layout: 'force',
                        data: Object.values(nodes),
                        links: links,
                        roam: 'scale',
                        label: {
                            position: 'right',
                        },
                        force: {
                            repulsion: 100,
                        },
                        lineStyle: {
                            curveness: 0.3,
                        },
                        edgeSymbol: ['circle', 'arrow'],
                        edgeSymbolSize: [4, 10],
                        emphasis: {
                            focus: 'adjacency',
                            lineStyle: {
                                width: 3,
                            },
                        },
                    },
                ],
            }
        }
        return null
    }, [node?.network])

    return (
        <Grid container height="100%" paddingRight={1}>
            {node == null ? (
                <>Loading</>
            ) : (
                <>
                    {/* Main content
                ========================================= */}
                    <Grid item xs={(node?.respondents?.length || 0) > 1 ? 10 : 12} height="100%">
                        <Stack gap={1} height="100%">
                            {/* Top row
                        ========================================= */}
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={3}
                                sx={{
                                    // Same as the height of other 'layouts' top bar.
                                    // Safe to remove and modify if needed.
                                    minHeight: 64,
                                    py: 1,
                                    paddingLeft: 2,
                                    paddingRight: 4,

                                    backgroundColor: 'common.bg_1',
                                }}
                            >
                                {/* Overview button
                            ========================================= */}
                                <BaseButton
                                    label="Overview"
                                    sx={{
                                        width: 120,
                                        fontSize: 20,
                                    }}
                                />

                                {/* Surveys button
                            ========================================= */}
                                <BaseButton
                                    label="Surveys"
                                    onClick={() => navigationHandlers.toSurveys()}
                                    sx={{
                                        width: 120,
                                        fontSize: 20,

                                        color: 'common.fill_2',
                                    }}
                                />

                                {/* Relations button
                            ========================================= */}
                                <BaseButton
                                    label="Relations"
                                    onClick={() => navigationHandlers.toRelations()}
                                    sx={{
                                        width: 120,
                                        fontSize: 20,

                                        color: 'common.fill_2',
                                    }}
                                />
                                {/* Actions button
                                ========================================= */}
                                <BaseButton
                                    label="Actions"
                                    onClick={() => navigationHandlers.toActions()}
                                    sx={{
                                        width: 120,
                                        fontSize: 20,

                                        color: 'common.fill_2',
                                    }}
                                />
                            </Stack>

                            {/* Content
                        ========================================= */}
                            <Stack
                                flexGrow={1}
                                gap={1}
                                sx={{
                                    paddingBottom: 2,
                                    overflowY: 'auto',
                                }}
                                className="u-scrollbar"
                            >
                                {/* Overview boxes
                            ========================================= */}
                                <Grid container columnSpacing={1} alignItems="stretch">
                                    {/* Surveys overview
                                ========================================= */}
                                    <Grid item xs={6}>
                                        <Stack
                                            gap={1.5}
                                            sx={(theme) => ({
                                                minHeight: 400,
                                                height: '100%',
                                                width: '100%',
                                                paddingTop: theme.spacing(2),

                                                backgroundColor: 'common.bg_1',
                                            })}
                                        >
                                            {/* Title and show all button
                                        ========================================= */}
                                            <Stack
                                                flexShrink={0}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                gap={1}
                                                sx={{
                                                    paddingLeft: 3.5,
                                                    paddingRight: 2.5,
                                                }}
                                            >
                                                <Typography fontSize={20} fontWeight={500} color="common.text_1">
                                                    Surveys
                                                </Typography>

                                                <BaseButton
                                                    label="Show all"
                                                    onClick={navigationHandlers.toSurveys}
                                                    sx={{
                                                        fontSize: 20,
                                                        fontWeight: 500,
                                                    }}
                                                />
                                            </Stack>

                                            {/* Stats
                                        ========================================= */}
                                            <Stack
                                                flexGrow={1}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="flex-end"
                                                gap={1}
                                                sx={{
                                                    paddingLeft: 3.5,
                                                    paddingRight: 2.5,
                                                }}
                                            >
                                                {/* Completion circular progress (TODO)
                                            ========================================= */}
                                                <Box
                                                    flexGrow={1}
                                                    sx={{
                                                        alignSelf: 'stretch',
                                                        minHeight: 244,
                                                        height: '100%',
                                                    }}
                                                >
                                                    {surveyOverview.chart ? (
                                                        <Chart type="graph" options={surveyOverview.chart} />
                                                    ) : (
                                                        <Typography>This node has no surveys</Typography>
                                                    )}
                                                </Box>

                                                {/* Completion stats
                                            ========================================= */}
                                                <Stack flexShrink={0} gap={1}>
                                                    {/* Active stat
                                                ========================================= */}
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        gap={2}
                                                    >
                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight={600}
                                                            color="common.text_3"
                                                            width={100}
                                                            noWrap
                                                        >
                                                            Active:
                                                        </Typography>

                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight={600}
                                                            textAlign="right"
                                                            color="secondary"
                                                            width="3ch"
                                                        >
                                                            {surveyOverview.active}
                                                        </Typography>
                                                    </Stack>

                                                    {/* Not started stat
                                                ========================================= */}
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        gap={2}
                                                    >
                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight={600}
                                                            color="common.text_3"
                                                            width={100}
                                                            noWrap
                                                        >
                                                            Not Started:
                                                        </Typography>

                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight={600}
                                                            textAlign="right"
                                                            color="secondary"
                                                            width="3ch"
                                                        >
                                                            {surveyOverview.notStarted}
                                                        </Typography>
                                                    </Stack>

                                                    {/* Completed stat
                                                ========================================= */}
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        gap={2}
                                                    >
                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight={600}
                                                            color="common.text_3"
                                                            width={100}
                                                            noWrap
                                                        >
                                                            Completed:
                                                        </Typography>

                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight={600}
                                                            textAlign="right"
                                                            color="secondary"
                                                            width="3ch"
                                                        >
                                                            {surveyOverview.completed}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            {/* Next survey details and actions
                                        ========================================= */}
                                            {surveyOverview.nextSurvey && (
                                                <Stack
                                                    flexShrink={0}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="stretch"
                                                >
                                                    {/* Next survey details
                                            ========================================= */}
                                                    <Stack
                                                        flexGrow={1}
                                                        minWidth={0}
                                                        sx={{
                                                            py: 1.5,
                                                            paddingLeft: 3.5,
                                                            paddingRight: 1.5,

                                                            backgroundColor: 'common.bg_4',
                                                        }}
                                                    >
                                                        {/* Text and menu
                                                ========================================= */}
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            gap={1}
                                                        >
                                                            <Typography fontSize={14} fontWeight={600} color="primary">
                                                                Next Survey
                                                            </Typography>

                                                            {/* (Conditional) Menu button
                                                    ========================================= */}
                                                            <IconButton
                                                                sx={{
                                                                    padding: 0.5,

                                                                    color: 'primary.main',
                                                                }}
                                                            >
                                                                <MoreHorizIcon />
                                                            </IconButton>
                                                        </Stack>

                                                        {/* Next survey title
                                                ========================================= */}
                                                        <Typography
                                                            fontSize={18}
                                                            fontWeight={500}
                                                            noWrap
                                                            color="common.text_1"
                                                        >
                                                            {surveyOverview.nextSurvey.surveyName}
                                                        </Typography>
                                                        {/* Due date box
                                                ========================================= */}
                                                        <Typography fontSize={14} fontWeight={500} color="secondary">
                                                            {(['in_progress', 'invited'].includes(
                                                                surveyOverview.nextSurvey.status
                                                            )
                                                                ? 'Due '
                                                                : 'Start ') + 'Date: '}
                                                            {moment(
                                                                ['in_progress', 'invited'].includes(
                                                                    surveyOverview.nextSurvey.status
                                                                )
                                                                    ? surveyOverview.nextSurvey.endDate
                                                                    : surveyOverview.nextSurvey.startDate
                                                            ).format('DD.MM.YYYY')}
                                                        </Typography>
                                                    </Stack>

                                                    {/* Next survey due date/action
                                            ========================================= */}
                                                    <Box flexShrink={0}>
                                                        {/* (Conditional) Complete button
                                                ========================================= */}
                                                        {['in_progress', 'invited'].includes(
                                                            surveyOverview.nextSurvey.status
                                                        ) && (
                                                            <BaseButton
                                                                label="Complete"
                                                                variant="contained"
                                                                onClick={() =>
                                                                    navigator(
                                                                        `/survey/si/${surveyOverview.nextSurvey?.accessToken}`
                                                                    )
                                                                }
                                                                disableElevation
                                                                sx={{
                                                                    height: '100%',
                                                                    width: 165,

                                                                    borderRadius: 0,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Grid>

                                    {/* Relations overview
                                ========================================= */}
                                    <Grid item xs={6}>
                                        <Stack
                                            gap={1.5}
                                            sx={(theme) => ({
                                                minHeight: '100%',
                                                width: '100%',
                                                padding: theme.spacing(2, 2.5, 2, 3.5),

                                                backgroundColor: 'common.bg_1',
                                            })}
                                        >
                                            {/* Title and show all button
                                        ========================================= */}
                                            <Stack
                                                flexShrink={0}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <Typography fontSize={20} fontWeight={500} color="common.text_1">
                                                    Relations
                                                </Typography>

                                                <BaseButton
                                                    label="Show all"
                                                    onClick={navigationHandlers.toRelations}
                                                    sx={{
                                                        fontSize: 20,
                                                        fontWeight: 500,
                                                    }}
                                                />
                                            </Stack>

                                            {/* Relations graph
                                        ========================================= */}
                                            <Box
                                                flexGrow={1}
                                                sx={{
                                                    minHeight: 244,
                                                    height: 244,
                                                }}
                                            >
                                                {network && <Chart type="graph" options={network} />}
                                            </Box>

                                            {/* <Typography fontSize={10} fontWeight={400} color="common.text_3">
                                                Nominated By?
                                            </Typography> */}

                                            {/* Nominators list (?)
                                            todo
                                        ========================================= */}
                                            {/* <Stack
                                                flexShrink={0}
                                                direction="row"
                                                alignItems="center"
                                                flexWrap="wrap"
                                                gap={1}
                                            >
                                                {Array.from(Array(3)).map((_, idx) => (
                                                    <Stack
                                                        key={idx}
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1}
                                                        sx={{
                                                            maxWidth: 187,
                                                            py: 1,
                                                            px: 1.75,

                                                            borderRadius: '10px',
                                                            backgroundColor: 'common.bg_3',
                                                        }}
                                                    >
                                                        <Avatar
                                                            src={TempAvatar}
                                                            sx={{
                                                                height: 24,
                                                                width: 24,
                                                            }}
                                                        />

                                                        <Typography
                                                            fontSize={10}
                                                            fontWeight={400}
                                                            noWrap
                                                            color="common.text_1"
                                                        >
                                                            {'Item ' + (idx + 1)}
                                                        </Typography>
                                                    </Stack>
                                                ))}
                                            </Stack> */}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                {false && (
                                    <>
                                        {/* Preferences
                            ========================================= */}
                                        <Stack
                                            direction="row"
                                            sx={{
                                                backgroundColor: 'common.bg_1',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    paddingTop: 2.5,
                                                    paddingBottom: 2,
                                                    px: 4,
                                                }}
                                            >
                                                <Typography fontSize={20} fontWeight={500} color="common.text_1">
                                                    Preferences
                                                </Typography>
                                            </Box>

                                            {/* Preference options
                                ========================================= */}
                                            <Stack
                                                flexGrow={1}
                                                gap={1.5}
                                                sx={(theme) => ({
                                                    padding: theme.spacing(2, 4),

                                                    backgroundColor: 'common.bg_3',
                                                })}
                                            >
                                                {/* Contact methods
                                    ========================================= */}
                                                <Stack direction="row" alignItems="center" gap={7}>
                                                    <Typography
                                                        fontSize={20}
                                                        fontWeight={500}
                                                        color="primary"
                                                        sx={{
                                                            flexBasis: '15ch',
                                                        }}
                                                    >
                                                        Contact method
                                                    </Typography>

                                                    {/* Method radios
                                        ========================================= */}
                                                    <RadioGroup row defaultValue={'both'} sx={{ gap: 5 }}>
                                                        <FormControlLabel
                                                            value="both"
                                                            label="Both"
                                                            control={<Radio />}
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                },
                                                            }}
                                                        />

                                                        <FormControlLabel
                                                            value="sms"
                                                            label="SMS"
                                                            control={<Radio />}
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                },
                                                            }}
                                                        />

                                                        <FormControlLabel
                                                            value="email"
                                                            label="Email"
                                                            control={<Radio />}
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                },
                                                            }}
                                                        />
                                                    </RadioGroup>
                                                </Stack>

                                                {/* Language
                                    ========================================= */}
                                                <Stack direction="row" alignItems="center" gap={7}>
                                                    <Typography
                                                        fontSize={20}
                                                        fontWeight={500}
                                                        color="primary"
                                                        sx={{
                                                            flexBasis: '15ch',
                                                        }}
                                                    >
                                                        Language
                                                    </Typography>

                                                    <Select
                                                        defaultValue={''}
                                                        displayEmpty
                                                        input={<BasicSelectInput />}
                                                        IconComponent={ExpandMoreIcon}
                                                        sx={{
                                                            width: 380,

                                                            '& .MuiInput-input': {
                                                                py: 2,
                                                                fontSize: 18,
                                                            },

                                                            '& .MuiSelect-icon': {
                                                                color: 'primary.main',
                                                            },
                                                        }}
                                                    >
                                                        {selectOptions.map((_option, idx) => (
                                                            <MenuItem
                                                                key={_option.label + idx}
                                                                value={_option.value || ''}
                                                            >
                                                                {_option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Stack>
                                            </Stack>
                                        </Stack>

                                        {/* Footer buttons
                            ========================================= */}
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            gap={3}
                                            sx={{
                                                marginTop: 'auto',
                                            }}
                                        >
                                            {/* Reminder button
                                ========================================= */}
                                            <BaseButton
                                                label="Reminder"
                                                variant="contained"
                                                size="large"
                                                sx={{
                                                    width: 180,
                                                }}
                                            />

                                            {/* Contact button
                                ========================================= */}
                                            <BaseButton
                                                label="Contact"
                                                variant="contained"
                                                size="large"
                                                sx={{
                                                    width: 180,
                                                }}
                                            />

                                            {/* Withdraw button
                                ========================================= */}
                                            <BaseButton
                                                label="Withdraw"
                                                variant="contained"
                                                size="large"
                                                color="warning"
                                                sx={{
                                                    width: 180,
                                                }}
                                            />
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Grid>

                    {/* Sidebar
                ========================================= */}
                    {(node?.respondents?.length || 0) > 1 && (
                        <Grid item xs={2} height="100%" paddingLeft={1}>
                            <Stack
                                sx={{
                                    height: '100%',
                                    py: 2,
                                    overflowY: 'auto',

                                    backgroundColor: 'common.bg_1',
                                }}
                                className="u-scrollbar"
                            >
                                <Typography
                                    fontSize={14}
                                    fontWeight={400}
                                    color="common.text_1"
                                    sx={{
                                        mx: 2,
                                    }}
                                >
                                    Respondents:
                                </Typography>

                                {/* Respondents list
                        ========================================= */}
                                {node?.respondents?.map((_item, idx) => (
                                    <Accordion
                                        key={_item.primarKey}
                                        TransitionProps={{ unmountOnExit: true }}
                                        disableGutters
                                        square
                                        sx={{
                                            marginBottom: 0.5,

                                            boxShadow: 0,
                                            borderRadius: 0,
                                            backgroundColor: 'common.bg_3',

                                            '&:before': {
                                                height: 0,

                                                backgroundColor: 'unset',
                                            },
                                        }}
                                    >
                                        {/* Summary
                            ========================================= */}
                                        <StyledAccordionSummary>
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <Avatar
                                                    src={_item.avatar}
                                                    alt="profile"
                                                    sx={{
                                                        height: 32,
                                                        width: 32,
                                                    }}
                                                >
                                                    {_item.name?.substring(0, 1)?.toUpperCase()}
                                                </Avatar>

                                                <Typography fontSize={14} fontWeight={400} color="common.text_1">
                                                    {_item.name}
                                                </Typography>
                                            </Stack>
                                        </StyledAccordionSummary>

                                        {/* Summary
                                ========================================= */}
                                        <StyledAccordionDetails>
                                            {/* Email
						            ========================================= */}
                                            <Stack direction="row" alignItems="flex-start" gap={1.5}>
                                                <EmailIcon
                                                    sx={{
                                                        width: 22,

                                                        color: 'primary.main',
                                                    }}
                                                />

                                                <Typography fontSize={14} fontWeight={400} color="common.text_1">
                                                    {_item.email}
                                                </Typography>
                                            </Stack>

                                            {/* Phone
						            ========================================= */}
                                            <Stack direction="row" alignItems="flex-start" gap={1.5}>
                                                <ContactPhoneIcon
                                                    sx={{
                                                        width: 22,

                                                        color: 'primary.main',
                                                    }}
                                                />

                                                <Typography fontSize={14} fontWeight={400} color="common.text_1">
                                                    {_item.phoneNumber || 'NA'}
                                                </Typography>
                                            </Stack>
                                        </StyledAccordionDetails>
                                    </Accordion>
                                ))}
                            </Stack>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}

export default NodeOverview
