//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import BaseSelect from 'components/base/BaseSelect'
import TemplateCard from 'components/template-card/TemplateCard'
//* ======= Custom logic
//* ======= Assets and styles
import { ReactComponent as ICON_PREMIUM } from 'assets/icons/premium.svg'
import { ResourceType } from 'types/resources'
import { Typography } from '@mui/material'

interface IAllTemplatesDialog {
    onConfirm?: (id: number) => void
    templates: ResourceType[]
}

function AllTemplatesDialog({ templates, onConfirm }: IAllTemplatesDialog) {
    // TODO:
    // List selection values can be merged into one state.
    const [selectedPriceIndex, setSelectedPriceIndex] = useState(0)
    const handlePriceItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        setSelectedPriceIndex(index)
    }

    const [checkedTypes, setCheckedTypes] = useState([0])

    const handleTypeItemToggle = (value: number) => () => {
        const currentIndex = checkedTypes.indexOf(value)
        const newChecked = [...checkedTypes]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setCheckedTypes(newChecked)
    }

    const [selectMultipleValue, setSelectMultipleValue] = useState<any>([])
    const selectOptions = [
        {
            name: 'None',
            value: '',
        },
        {
            name: 'One',
            value: 1,
        },
        {
            name: 'Two',
            value: 2,
        },
        {
            name: 'Long Three',
            value: 3,
        },
    ]
    const selectOnChangeHandler = (value: any) => {
        setSelectMultipleValue(value)
    }

    const searchFieldOptions = ['Grumpy user', 'Happy user', 'Lazy user']

    if (true) {
        return (
            <Stack
                direction="row"
                sx={(theme) => ({
                    height: 700,

                    backgroundColor: 'common.bg_2',
                })}
            >
                <Box sx={{ padding: 2 }}>
                    <h1>Under Construction</h1>
                    <p>
                        We're excited to announce that in the next version of our software, we'll be
                        introducing tempalte feature that we think you're going to love! While it's not fully
                        implemented yet, we're working hard to make sure that it's perfect before it's
                        released.
                    </p>
                    <p>
                        We understand that waiting for new features can be frustrating, but we want to assure
                        you that we're doing everything we can to get it up and running as soon as possible.
                        We're taking our time to make sure that it's a seamless and user-friendly experience
                        for you.
                    </p>
                    <p>
                        We appreciate your patience and understanding, and we can't wait for you to try out
                        the new feature when it's ready. Stay tuned for updates on our progress, and thank you
                        for being a valued member of our community!
                    </p>
                </Box>
            </Stack>
        )
    } else
        return (
            <Stack
                direction="row"
                sx={(theme) => ({
                    height: 700,

                    backgroundColor: 'common.bg_2',
                })}
            >
                {/* Sidebar
            ========================================= */}
                <Stack
                    flexShrink={0}
                    gap={2}
                    py={2}
                    px={3}
                    sx={(theme) => ({
                        width: '300px',

                        borderRight: `1px solid ${theme.palette.common.border_2}`,
                    })}
                >
                    {/* Price list
                ========================================= */}
                    <List disablePadding>
                        <ListItem disableGutters disablePadding>
                            <ListItemButton
                                selected={selectedPriceIndex === 0}
                                onClick={(event) => handlePriceItemClick(event, 0)}
                            >
                                <ListItemText
                                    primary="All"
                                    primaryTypographyProps={{
                                        fontSize: 18,
                                        fontWeight: 600,

                                        color: selectedPriceIndex === 0 ? 'primary.main' : 'secondary.main',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disableGutters disablePadding>
                            <ListItemButton
                                selected={selectedPriceIndex === 1}
                                onClick={(event) => handlePriceItemClick(event, 1)}
                            >
                                <ListItemText
                                    primary="Free"
                                    primaryTypographyProps={{
                                        fontSize: 18,
                                        fontWeight: 600,

                                        color: selectedPriceIndex === 1 ? 'primary.main' : 'secondary.main',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disableGutters disablePadding>
                            <ListItemButton
                                selected={selectedPriceIndex === 2}
                                onClick={(event) => handlePriceItemClick(event, 2)}
                            >
                                <ListItemText
                                    primary="Premium"
                                    primaryTypographyProps={{
                                        fontSize: 18,
                                        fontWeight: 600,

                                        color: selectedPriceIndex === 2 ? 'primary.main' : 'secondary.main',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>

                    {/* Type list
                ========================================= */}
                    <List
                        subheader={
                            <ListSubheader
                                disableSticky
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                }}
                            >
                                Type
                            </ListSubheader>
                        }
                    >
                        {[0, 1, 2].map((value) => {
                            const labelId = `types-list-label-${value}`

                            return (
                                <ListItem key={value} disablePadding>
                                    <ListItemButton onClick={handleTypeItemToggle(value)} dense>
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                            }}
                                        >
                                            <Checkbox
                                                edge="start"
                                                size="small"
                                                checked={checkedTypes.indexOf(value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>

                                        <ListItemText id={labelId} primary={`Item ${value + 1}`} />
                                        <ListItemText
                                            id={labelId}
                                            primary={`(${value * 2 + 7})`}
                                            sx={{
                                                flexGrow: 0,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Stack>

                {/* Main content
            ========================================= */}
                <Stack flexGrow={1} gap={2} py={2} px={3}>
                    {/* Search and filters
                ========================================= */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        {/* Search
                    ========================================= */}
                        {/* <SearchField
                    freeSolo
                    options={searchFieldOptions}
                    onChange={(evt, value) => value && console.log(value)}
                    placeholder="Search"
                    width="220px"
                    sx={{
                        width: '390px',
                    }}
                /> */}

                        <Stack direction="row" alignItems="center" gap={1} marginLeft={'auto'}>
                            {/* Categories
                        ========================================= */}
                            <BaseSelect
                                name="mySelect"
                                options={selectOptions}
                                value={selectMultipleValue}
                                onChangeHandler={selectOnChangeHandler}
                                multiple
                                width={158}
                                placeholder="Categories"
                            />

                            {/* Tags
                        ========================================= */}
                            <BaseSelect
                                name="mySelect"
                                options={selectOptions}
                                value={selectMultipleValue}
                                onChangeHandler={selectOnChangeHandler}
                                multiple
                                width={158}
                                placeholder="Tags"
                            />
                        </Stack>
                    </Stack>

                    {/* Templates grid
                ========================================= */}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            gridAutoFlow: 'row',
                            columnGap: 2.25,
                            rowGap: 2.25,

                            marginRight: -1,
                            paddingRight: 1,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {templates &&
                            templates.map((resource) => {
                                return (
                                    <TemplateCard
                                        key={resource.id}
                                        data={resource}
                                        hasMenuOnBoxed={false}
                                        iconBadge={<ICON_PREMIUM />}
                                        clickHandler={() =>
                                            resource.id != null && onConfirm && onConfirm(resource.id)
                                        }
                                    />
                                )
                            })}
                    </Box>
                </Stack>
            </Stack>
        )
}

export default AllTemplatesDialog
