import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useDrop, DropTargetMonitor } from 'react-dnd'
import DraggableListItem from './GenericDraggableListItem'
import { Divider, Stack } from '@mui/material'

// Define a generic type for items
type ItemType = {
    id: string | number
    [key: string]: any // Additional properties of the item
}

interface GenericDragDropListProps<T extends ItemType> {
    items: T[]
    renderItem: (item: T) => JSX.Element
    getKeyValue: (item: T, idx: number) => string
    onItemMove?: (dragId: string | number, newIndex: number) => void
    targetType: string
}

// A utility function to handle the item move logic
export function moveItemInArray<T extends ItemType>(items: T[], dragId: ItemType['id'], hoverId: ItemType['id']) {
    const newItems = [...items]
    const dragIndex = newItems.findIndex((item) => item.id === dragId)
    const hoverIndex = newItems.findIndex((item) => item.id === hoverId)

    if (dragIndex < 0 || hoverIndex < 0 || dragIndex === hoverIndex) {
        return items
    }

    const [draggedItem] = newItems.splice(dragIndex, 1)
    newItems.splice(hoverIndex, 0, draggedItem)

    return newItems
}

function GenericDragDropList<T extends ItemType>({
    items,
    renderItem,
    onItemMove,
    getKeyValue,
    targetType,
}: GenericDragDropListProps<T>) {
    const [internalItems, setInternalItems] = useState<T[]>([])

    useEffect(() => {
        setInternalItems(items)
    }, [items])

    const moveItem = useCallback((dragId: string | number, hoverId: string | number) => {
        setInternalItems((prevItems) => moveItemInArray(prevItems, dragId, hoverId))
    }, [])

    const [, drop] = useDrop({
        accept: targetType,
        collect: (monitor: DropTargetMonitor) => ({
            isOver: !!monitor.isOver(),
        }),
    })

    const onMoveEnd = useCallback(
        (dragId: string | number) => {
            if (onItemMove !== undefined) {
                onItemMove(
                    dragId,
                    internalItems.findIndex((i) => i.id === dragId)
                )
            }
        },
        [onItemMove, internalItems]
    )

    return (
        <Stack spacing={1} gap={1.5} ref={drop}>
            {internalItems.map((item, idx) => (
                <React.Fragment key={getKeyValue(item, idx)}>
                    <DraggableListItem
                        item={item}
                        moveItem={moveItem}
                        onMoveEnd={onMoveEnd}
                        renderItemContent={renderItem}
                        targetType={targetType}
                    />
                    {/* Divider
                          ========================================= */}
                    {idx !== internalItems.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </Stack>
    )
}

export default GenericDragDropList
