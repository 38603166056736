//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { IconButton, Tooltip, Typography } from '@mui/material'
//* ======= Custom logic
//* ======= Assets and styles

interface IPageCardProps {
    children?: React.ReactNode
}

function PageCard({ children }: IPageCardProps) {
    return (
        <Stack direction="row" spacing={1} height="100%">
            {children}
        </Stack>
    )
}

interface IPageCardLayerProps {
    noPaddingRight?: boolean
    children?: React.ReactNode
}

function PageCard_Side({ children }: IPageCardLayerProps) {
    return (
        <Box
            component="aside"
            sx={(theme) => ({
                flexShrink: 0,

                minWidth: 0,
                // Same as the left padding of 'header'.
                // paddingLeft: theme.spacing(3),

                // @Theme conditional
                backgroundColor:
                    theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
            })}
        >
            {children}
        </Box>
    )
}

function PageCard_Body({ noPaddingRight = false, children }: IPageCardLayerProps) {
    return (
        <Box
            sx={(theme) => ({
                flexGrow: 1,

                minWidth: 0,
                // Same as the right padding of 'header'.
                paddingRight: noPaddingRight ? 0 : theme.spacing(1.5),
                overflowY: 'auto',
                overflowX: 'hidden',

                // backgroundColor: 'common.bg_1',
            })}
            className="u-scrollbar"
        >
            {children}
        </Box>
    )
}

function PageCard_Help({ children }: IPageCardLayerProps) {
    const [open, setOpen] = useState(false)
    return (
        <>
            {open ? (
                <Box
                    sx={(theme) => ({
                        position: 'fixed',
                        right: 0,
                        bottom: 0,
                        zIndex: 1000,
                    })}
                    className="u-scrollbar"
                >
                    <Tooltip title="Close">
                        <IconButton onClick={() => setOpen(false)}>
                            <Typography variant="h6">X</Typography>
                        </IconButton>
                    </Tooltip>
                    <iframe
                        title="Google Workflow"
                        src="https://scribehow.com/embed/Google_Workflow__k1qYk3vOSKu7YWWbrAAhLw?as=scrollable&skipIntro=true"
                        height="640"
                        width="640"
                    ></iframe>
                </Box>
            ) : (
                <Box
                    sx={(theme) => ({
                        position: 'fixed',
                        right: 0,
                        bottom: 0,
                        zIndex: 1000,
                    })}
                    className="u-scrollbar"
                >
                    <Tooltip title="Help">
                        <IconButton onClick={() => setOpen(true)}>
                            <Typography variant="h6">?</Typography>
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </>
    )
}

PageCard.Side = PageCard_Side
PageCard.Body = PageCard_Body
PageCard.Help = PageCard_Help

export default PageCard
