//* ======= Libraries
import React, { useContext, useEffect, useMemo, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import NewProjectStrip from 'features/general-dashboard/NewProjectStrip'
import ProjectsPanel from 'features/general-dashboard/ProjectsPanel'
import Stack from '@mui/material/Stack'
import { ResourceType } from 'types/resources'
import { RootContext } from 'contexts/RootContext'
import { useNavigate, useParams } from 'react-router-dom'
import ProjectDrawerNavigation from './Navigation'
import { ProjectContext } from 'contexts/ProjectContext'
import { GetReports, ReportType } from 'services/ReportApi'
import Badge from '@mui/material/Badge'
import Chip from '@mui/material/Chip'
import moment from 'moment'
import NavigableDialog from 'components/dialog/NavigableDialog'
import { ICreateResourceDialogStateType } from './ProjectDashboard.route'
import CreateReportDialog from 'features/report-create-dialog/CreateReportDialog'
import DashboardSkeleton from './DashboardSkeleton'
import { PERMISSIONS } from 'helpers/constants'
//* ======= Custom logic
//* ======= Assets and styles

interface IProjectDashboard {}

function ReportDashboard({}: IProjectDashboard) {
    const navigator = useNavigate()
    const { pid } = useParams()
    const { project, drawerContent, hasPermission } = useContext(ProjectContext)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [reports, setReports] = useState<ResourceType[]>([])
    const [reportTags, setReportTags] = useState<string[]>([])
    const [templates, setTemplates] = useState<ResourceType[]>([])
    const [templatesTags, setTemplateTags] = useState<string[]>([])

    const parseReports = (allReports: Array<ReportType>, isTemplate = false) => {
        const canDelete = hasPermission(PERMISSIONS.report.DeleteReport)
        const canEditTag = hasPermission(PERMISSIONS.report.ManageReportTag)
        const canRename = hasPermission(PERMISSIONS.report.UpdateReport)
        const tempProjects: ResourceType[] = allReports
            .filter((tp) => (!isTemplate && !tp.isTemplate) || (isTemplate && tp.isTemplate))
            .map((s) => ({
                id: s.primaryKey || -1,
                title: s.title,
                image: s.thumbnail,
                // favourite: s.favourite,
                info: [
                    {
                        label: 'Created on',
                        value: `${`${moment(s.createdOn).format('DD-MM-YYYY HH:mm')}`}`,
                    },
                ],
                canDelete,
                canEditTag,
                canRename,
                link: `/project/${pid}/reports/${s.primaryKey}`,
                rating: 5,
                tags: s.tags,
            }))

        let tempTags = new Set<string>()
        for (let p of tempProjects) {
            if (p.tags)
                for (let t of p.tags) {
                    t.key && tempTags.add(t.key)
                }
        }

        return {
            reports: tempProjects,
            tags: Array.from(tempTags),
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [reports])

    //fetch data
    useEffect(() => {
        //if not pid, do nothing
        if (pid == null) return
        setIsLoading(true)
        GetReports(pid)
            .then((response) => {
                if (response.success) {
                    // get all reports
                    const allProjects = parseReports(response.data)
                    setReports(allProjects.reports)
                    setReportTags(allProjects.tags)
                    // get all templates
                    const allTemplates = parseReports(response.data, true)
                    setTemplates(allTemplates.reports)
                    setTemplateTags(allTemplates.tags)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }, [])

    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
    const [createDialogStep, setCreateDialogStep] = useState<number>(0)

    const handleCloseCreateDialog = () => setCreateDialogOpen(false)
    const handleOpenCreateDialog = () => setCreateDialogOpen(true)

    const CreateReportDialogs: ICreateResourceDialogStateType[] = [
        {
            title: 'Create Report',
            disableBackButton: true,
            maxWidth: 'lg',
            component: (
                <CreateReportDialog
                    onClose={handleCloseCreateDialog}
                    onConfirm={(id?: number) => {
                        if (id) navigator('' + id)
                        handleCloseCreateDialog()
                    }}
                />
            ),
        },
    ]

    const dialogState = useMemo<ICreateResourceDialogStateType>(() => {
        return CreateReportDialogs[createDialogStep]
    }, [createDialogStep])

    if (project == null) return <DashboardSkeleton />

    const canCreateReport = hasPermission(PERMISSIONS.report.CreateReport)

    return (
        <PageCard>
            <PageCard.Side>{project?.primaryKey && <MainDrawer content={drawerContent} />}</PageCard.Side>

            <PageCard.Body>
                <Stack spacing={1} width="100%" height="100%">
                    {canCreateReport && (
                        <NewProjectStrip
                            templates={templates}
                            tagCategories={templatesTags}
                            resourceName="Report"
                            onCreate={handleOpenCreateDialog}
                        />
                    )}
                    <ProjectsPanel
                        resources={reports}
                        setResources={setReports}
                        tagCategories={reportTags}
                        resourceName="report"
                        isLoading={isLoading}
                    />
                </Stack>
            </PageCard.Body>
            <NavigableDialog
                title={dialogState.title}
                open={createDialogOpen}
                onClose={handleCloseCreateDialog}
                backHandler={dialogState.onBack}
                disableBackButton={dialogState.disableBackButton}
                fullWidth={true}
                maxWidth={dialogState.maxWidth}
                scroll="body"
            >
                {dialogState.component}
            </NavigableDialog>
        </PageCard>
    )
}

export default ReportDashboard
