//* ======= Libraries
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Merge, PartialDeep } from 'type-fest'
import { Divider, Stack, Typography } from '@mui/material'
//* ======= Components and features
import GaugeChartDataConfigDialog from 'features/report-designer/widgets/chart-widget/settings/GaugeChartDataConfigDialog'
import FontPicker, { FontPickerValueType } from 'components/widgets/FontPicker'
import ColorPicker from 'components/widgets/ColorPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseSwitch from 'components/base/BaseSwitch'
import BorderPicker from 'components/widgets/BorderPicker'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { ChartWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { GaugeChartType, GaugeChartConfigType, ChartMultiViewGridSizeOptions } from 'features/chart/Chart.asset'
import { GenerateChartOptionsParamsModeType } from 'features/chart/Chart.helper'
import { DECIMAL_PRECISION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { deepMergeCustom } from 'helpers/helpers'
//* ======= Assets and styles

const verticalAlignOptions = ['top', 'center', 'bottom']
const horizontalAlignOptions = ['left', 'center', 'right']
const gaugeViewModeOptions = ['gauge', 'ring', 'speedometer']

export type ReportGaugeChartWidgetSettingsStateType = Merge<
    Pick<ChartWidgetType, 'selectedDataSource' | 'type' | 'dataDefinition' | 'config' | 'options'>,
    GaugeChartType
>

type Props = {
    data: ReportGaugeChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onUpdate: (
        mode: GenerateChartOptionsParamsModeType,
        updatedValues: Merge<
            Partial<Pick<ChartWidgetType, 'selectedDataSource'>>,
            Partial<Pick<GaugeChartType, 'dataDefinition' | 'config'>>
        >
    ) => void
}

function GaugeChartWidgetSettings({ data, dataSources, onUpdate }: Props) {
    // Create an internal chart data on props change.
    const chartData = useMemo<ReportGaugeChartWidgetSettingsStateType>(() => {
        return {
            selectedDataSource: data.selectedDataSource,
            type: 'gauge',
            dataDefinition: data.dataDefinition,
            config: data.config,
            options: data.options,
        }
    }, [data])

    const [isDataConfigDialogOpen, setIsDataConfigDialogOpen] = useState(false)

    const onChartConfigUpdate = (updatedConfig: PartialDeep<ReportGaugeChartWidgetSettingsStateType['config']>) => {
        const config = deepMergeCustom(structuredClone(chartData.config), updatedConfig)

        onUpdate('config', {
            config: config,
        })
    }

    const onFontPickerChange = useCallback(
        (configField: keyof ReportGaugeChartWidgetSettingsStateType['config'], values: FontPickerValueType) => {
            let updatedConfig: PartialDeep<ReportGaugeChartWidgetSettingsStateType['config']> = {}

            // Title and Legend
            if (configField === 'title' || configField === 'legend') {
                updatedConfig = {
                    ...updatedConfig,
                    [configField]: {
                        styles: {
                            fontFamily: values.fontFamily,
                            fontSize: values.fontSize,
                            fontWeight: values.isBold ? 'bold' : 'normal',
                            color: values.color,
                        },
                    },
                }
            }

            onChartConfigUpdate(updatedConfig)
        },
        [chartData]
    )

    const onGaugeViewModeChange = (viewMode: GaugeChartConfigType['viewMode']) => {
        // We set the update mode to "all" to trigger a data generation as well because
        // some of the visual properties are calculated based on the data.
        switch (viewMode) {
            case 'gauge':
                onUpdate('all', {
                    selectedDataSource: chartData.selectedDataSource,
                    dataDefinition: chartData.dataDefinition,
                    config: {
                        ...chartData.config,
                        viewMode: viewMode,
                        valueLabel: {
                            ...chartData.config.valueLabel,
                            verticalOffset: -10,
                        },
                    },
                })

                break

            case 'ring':
                onUpdate('all', {
                    selectedDataSource: chartData.selectedDataSource,
                    dataDefinition: chartData.dataDefinition,
                    config: {
                        ...chartData.config,
                        viewMode: viewMode,
                        valueLabel: {
                            ...chartData.config.valueLabel,
                            verticalOffset: 0,
                        },
                    },
                })

                break

            case 'speedometer':
                onUpdate('all', {
                    selectedDataSource: chartData.selectedDataSource,
                    dataDefinition: chartData.dataDefinition,
                    config: {
                        ...chartData.config,
                        viewMode: viewMode,
                        valueLabel: {
                            ...chartData.config.valueLabel,
                            verticalOffset: 25,
                        },
                    },
                })

                break

            default:
                break
        }
    }

    const onChartDataUpdate = (
        updatedData: Pick<ReportGaugeChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>
    ) => {
        // We generate the options again and update store state.
        // We set the update mode to "all" to trigger a data generation as well because
        // some of the visual properties are calculated based on the data.
        onUpdate('all', {
            selectedDataSource: updatedData.selectedDataSource,
            dataDefinition: updatedData.dataDefinition,
            config: chartData.config,
        })

        setIsDataConfigDialogOpen(false)
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                paddingX: theme.spacing(2),
            })}
        >
            {/* Configure data button
				========================================= */}
            <BaseButton
                label="Configure Data"
                onClick={(evt) => setIsDataConfigDialogOpen(true)}
                variant="outlined"
                sx={(theme) => ({
                    alignSelf: 'center',

                    width: '80%',
                    paddingY: theme.spacing(1.5),
                })}
            />

            <Divider />

            {/* Config settings
                ========================================= */}
            {chartData.options !== null ? (
                <>
                    {/* Decimal precision
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={(theme) => ({
                                flex: '1 0 0',

                                paddingY: theme.spacing(1),
                            })}
                        >
                            Decimal Precision:
                        </Typography>

                        <BaseSelectWithLabel
                            options={DECIMAL_PRECISION_OPTIONS}
                            value={chartData.config.decimalPrecision}
                            onChange={(value) =>
                                onChartConfigUpdate({
                                    decimalPrecision: value,
                                })
                            }
                            hiddenLabel
                            size="small"
                            sx={{
                                flex: '0 0 30%',
                            }}
                        />
                    </Stack>

                    {/* Multi view settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Multi-View"
                        hasToggle
                        isToggledOff={chartData.config.multiView.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                multiView: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Grid Size:
                                </Typography>

                                <BaseSelectWithLabel
                                    options={ChartMultiViewGridSizeOptions}
                                    value={chartData.config.multiView.gridSize}
                                    onChange={(value) =>
                                        onChartConfigUpdate({
                                            multiView: {
                                                gridSize: value,
                                            },
                                        })
                                    }
                                    hiddenLabel
                                    size="small"
                                    sx={{
                                        width: '50%',
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Title settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Title"
                        hasToggle
                        isToggledOff={chartData.config.title.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                title: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Title"
                                defaultValue={chartData.config.title.value}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            value: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Secondary title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Secondary Title"
                                defaultValue={chartData.config.title.secondaryValue}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            secondaryValue: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Vertical align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Vertical Align"
                                options={verticalAlignOptions}
                                value={chartData.config.title.verticalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            verticalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Horizontal align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Horizontal Align"
                                options={horizontalAlignOptions}
                                value={chartData.config.title.horizontalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            horizontalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Title font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('title', values)}
                                disabled={chartData.config.title.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.title.styles?.fontFamily,
                                    fontSize: chartData.config.title.styles?.fontSize,
                                    color: chartData.config.title.styles?.color,
                                    isBold: chartData.config.title.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Legend settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Legend"
                        hasToggle
                        isToggledOff={chartData.config.legend.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                legend: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Legend font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('legend', values)}
                                disabled={chartData.config.legend.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.legend.styles?.fontFamily,
                                    fontSize: chartData.config.legend.styles?.fontSize,
                                    color: chartData.config.legend.styles?.color,
                                    isBold: chartData.config.legend.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* View mode select
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            View Mode:
                        </Typography>

                        <BaseSelectWithLabel
                            options={gaugeViewModeOptions}
                            value={chartData.config.viewMode}
                            onChange={(value) => onGaugeViewModeChange(value)}
                            hiddenLabel
                            size="small"
                            sx={{
                                width: '50%',
                            }}
                        />
                    </Stack>

                    <Divider />

                    {/* Progress bar rounded switch
				        ========================================= */}
                    {['gauge', 'ring'].includes(chartData.config.viewMode) === true && (
                        <>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Rounded Bars
                                </Typography>

                                <BaseSwitch
                                    checked={chartData.config.progressBar.roundCap}
                                    onChange={(evt, checked) =>
                                        onChartConfigUpdate({
                                            progressBar: {
                                                roundCap: checked,
                                            },
                                        })
                                    }
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>

                            <Divider />
                        </>
                    )}

                    {/* Progress bar border
				        ========================================= */}
                    {['gauge', 'ring'].includes(chartData.config.viewMode) === true && (
                        <StyledWidgetAccordion
                            title="Bars Border"
                            hasToggle
                            isToggledOff={chartData.config.progressBar.borderStyle.isEnabled === false}
                            onToggle={(isEnabled) =>
                                onChartConfigUpdate({
                                    progressBar: {
                                        borderStyle: {
                                            isEnabled: isEnabled,
                                        },
                                    },
                                })
                            }
                            hasBottomBorder
                        >
                            <BorderPicker
                                value={{
                                    width: `${chartData.config.progressBar.borderStyle.width}px`,
                                    style: chartData.config.progressBar.borderStyle.type,
                                    color: chartData.config.progressBar.borderStyle.color,
                                    radius: 1,
                                }}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        progressBar: {
                                            borderStyle: {
                                                // Removing the "px" suffix
                                                width: Number(value.width.slice(0, -2)),
                                                type: value.style,
                                                color: value.color,
                                            },
                                        },
                                    })
                                }
                                disabled={chartData.config.progressBar.borderStyle.isEnabled === false}
                                hasRadius={false}
                            />
                        </StyledWidgetAccordion>
                    )}

                    {/* Axis line
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Axis Line"
                        hasToggle
                        isToggledOff={chartData.config.axisLine.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                axisLine: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Axis line roundCap
				                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Rounded Axis Line
                                </Typography>

                                <BaseSwitch
                                    checked={chartData.config.axisLine.roundCap}
                                    onChange={(evt, checked) =>
                                        onChartConfigUpdate({
                                            axisLine: {
                                                roundCap: checked,
                                            },
                                        })
                                    }
                                    disabled={chartData.config.axisLine.isEnabled === false}
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>

                            {/* Axis line color
				                ========================================= */}
                            <ColorPicker
                                value={chartData.config.axisLine.color}
                                onChange={(newColor) =>
                                    // We specifically use "all" mode instead of "config" because the
                                    // "speedometer" view mode's color stops depend on axis line color
                                    // on certain situations.
                                    onUpdate('all', {
                                        selectedDataSource: chartData.selectedDataSource,
                                        dataDefinition: chartData.dataDefinition,
                                        config: {
                                            ...chartData.config,
                                            axisLine: {
                                                ...chartData.config.axisLine,
                                                color: newColor,
                                            },
                                        },
                                    })
                                }
                                disabled={chartData.config.axisLine.isEnabled === false}
                                isPopover={true}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Split number
				        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Split Number:
                        </Typography>

                        <BaseFilledTextField
                            type="number"
                            hiddenLabel
                            size="small"
                            value={chartData.config.splitNumber}
                            onChange={(evt) =>
                                onChartConfigUpdate({
                                    splitNumber: Number(evt.target.value),
                                })
                            }
                            inputProps={{
                                min: 4,
                                max: 24,
                                step: 1,
                            }}
                            sx={{
                                width: '50%',
                            }}
                        />
                    </Stack>

                    <Divider />

                    {/* Split line
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Split Line"
                        hasToggle
                        isToggledOff={chartData.config.splitLine.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                splitLine: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <ColorPicker
                            value={chartData.config.splitLine.color}
                            onChange={(newColor) =>
                                onChartConfigUpdate({
                                    splitLine: {
                                        color: newColor,
                                    },
                                })
                            }
                            disabled={chartData.config.splitLine.isEnabled === false}
                            isPopover={true}
                        />
                    </StyledWidgetAccordion>

                    {/* Labels
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Labels"
                        hasToggle
                        isToggledOff={chartData.config.labels.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                labels: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <FontPicker
                            onChange={(values) =>
                                onChartConfigUpdate({
                                    labels: {
                                        styles: {
                                            fontFamily: values.fontFamily,
                                            fontSize: values.fontSize,
                                            fontWeight: values.isBold ? 'bold' : 'normal',
                                            color: values.color,
                                        },
                                    },
                                })
                            }
                            disabled={chartData.config.labels.isEnabled === false}
                            defaultValue={{
                                fontFamily: chartData.config.labels.styles.fontFamily,
                                fontSize: chartData.config.labels.styles.fontSize,
                                color: chartData.config.labels.styles.color,
                                isBold: chartData.config.labels.styles.fontWeight === 'bold',
                            }}
                            styleControls={false}
                        />
                    </StyledWidgetAccordion>

                    {/* Value label
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Value Label"
                        hasToggle
                        isToggledOff={chartData.config.valueLabel.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                valueLabel: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Vertical offset
				                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Vertical Offset%:
                                </Typography>

                                <BaseFilledTextField
                                    type="number"
                                    hiddenLabel
                                    size="small"
                                    value={chartData.config.valueLabel.verticalOffset}
                                    onChange={(evt) =>
                                        onChartConfigUpdate({
                                            valueLabel: {
                                                verticalOffset:
                                                    Number(evt.target.value) > 100
                                                        ? 100
                                                        : Number(evt.target.value) < -100
                                                        ? -100
                                                        : Number(evt.target.value),
                                            },
                                        })
                                    }
                                    disabled={chartData.config.valueLabel.isEnabled === false}
                                    inputProps={{
                                        min: -100,
                                        max: 100,
                                        step: 1,
                                    }}
                                    sx={{
                                        width: '40%',
                                    }}
                                />
                            </Stack>

                            {/* Font styles
				                ========================================= */}
                            <FontPicker
                                onChange={(values) =>
                                    // We specifically use "all" mode instead of "config" because the
                                    // "speedometer" view mode's color stops depend on axis line color
                                    // on certain situations.
                                    onUpdate('all', {
                                        selectedDataSource: chartData.selectedDataSource,
                                        dataDefinition: chartData.dataDefinition,
                                        config: {
                                            ...chartData.config,
                                            valueLabel: {
                                                ...chartData.config.valueLabel,
                                                styles: {
                                                    ...chartData.config.valueLabel.styles,
                                                    fontFamily: values.fontFamily,
                                                    fontSize: values.fontSize,
                                                    fontWeight: values.isBold ? 'bold' : 'normal',
                                                    color: values.color,
                                                },
                                            },
                                        },
                                    })
                                }
                                disabled={chartData.config.valueLabel.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.valueLabel.styles.fontFamily,
                                    fontSize: chartData.config.valueLabel.styles.fontSize,
                                    color: chartData.config.valueLabel.styles.color,
                                    isBold: chartData.config.valueLabel.styles.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>
                </>
            ) : (
                false
            )}

            {/* (Out-of-flow) Data config dialog
				========================================= */}
            {isDataConfigDialogOpen && (
                <GaugeChartDataConfigDialog
                    isOpen={isDataConfigDialogOpen}
                    data={chartData}
                    dataSources={dataSources}
                    onConfirm={onChartDataUpdate}
                    onClose={() => setIsDataConfigDialogOpen(false)}
                />
            )}
        </Stack>
    )
}

export default GaugeChartWidgetSettings
