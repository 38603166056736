import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { AIWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { PartialDeep } from 'type-fest'

type AIWidgetProps = {
    Id: ReportWidgetType['id']
    data: AIWidgetType
    title: string
    onReady: () => void
}

function AIWidget({ Id, data, title, onReady }: AIWidgetProps) {
    const { activeSlideId, updateAIWidgetContent, onWidgetReady } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateAIWidgetContent: store.updateAIWidgetContent,
        onWidgetReady: store.onWidgetReady,
    }))

    const updateWidget = (data: PartialDeep<AIWidgetType>) => {
        if (activeSlideId === null) return

        updateAIWidgetContent({
            slideId: activeSlideId,
            widgetId: Id,
            data: data,
        })
    }

    useEffect(() => {
        onReady()
    }, [])

    return (
        <Stack
            sx={{
                height: '100%',
                position: 'relative',
                overflowY: 'auto',
            }}
            className="u-scrollbar"
        >
            {data.showTitle && (
                <Typography width="100%" paddingTop={1} textAlign="center" fontSize={18} fontWeight={600}>
                    {title}
                </Typography>
            )}

            {/* Content */}
            <Box flexGrow={1}>
                <Stack
                    sx={{
                        height: '100%',
                        //To make it allign with corresponding insight
                    }}
                >
                    {data.outcomes === null ? (
                        <Typography>{data.emptyDataMessage}</Typography>
                    ) : (
                        data.outcomes
                            .split('\n')
                            .map((str, index, array) =>
                                array.length - 1 === index ? str : <Typography>{str}</Typography>
                            )
                    )}
                </Stack>
            </Box>
        </Stack>
    )
}

export default AIWidget
