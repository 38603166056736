//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'

interface ITemplateDetailsDialog {
    onConfirm?: () => void
}

function TemplateDetailsDialog({ onConfirm }: ITemplateDetailsDialog) {
    return (
        <Stack
            sx={{
                minHeight: 0,
                minWidth: 0,
            }}
        >
            {/* Main content
				========================================= */}
            <Grid
                container
                alignItems="flex-start"
                sx={(theme) => ({
                    padding: theme.spacing(3, 4),

                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light'
                            ? theme.palette.common.ung_blue_one
                            : theme.palette.common.bg_3,
                })}
            >
                {/* Carousel
				    ========================================= */}
                <Grid
                    item
                    xs={7}
                    zeroMinWidth
                    sx={{
                        position: 'relative',

                        height: '480px',
                        paddingRight: 2.25,

                        borderRadius: '10px',
                        backgroundColor: 'common.bg_4',
                    }}
                >
                    (Carousel)
                </Grid>

                {/* Details
				    ========================================= */}
                <Grid
                    alignSelf={'stretch'}
                    item
                    container
                    direction={'column'}
                    xs={5}
                    alignItems={'flex-start'}
                    gap={2.5}
                    zeroMinWidth
                    sx={{
                        paddingTop: 2,
                        paddingLeft: 2.25,
                    }}
                >
                    {/* Creator
					    ========================================= */}
                    <Stack direction="row" alignItems="center" spacing={1} mb={0.5}>
                        <Avatar
                            src={TempAvatar}
                            alt="creator"
                            sx={{
                                height: '44px',
                                width: '44px',
                            }}
                        />

                        <Stack alignItems={'flex-start'}>
                            <Typography fontSize={10} fontWeight={400} color="primary">
                                Creator
                            </Typography>
                            <Typography fontSize={10} fontWeight={400} color="secondary">
                                CreatorUsername
                            </Typography>
                        </Stack>
                    </Stack>

                    {/* Name
					    ========================================= */}
                    <Typography fontSize={30} fontWeight={600} color="primary">
                        Template Name
                    </Typography>

                    {/* Description
					    ========================================= */}
                    <Typography
                        fontSize={18}
                        fontWeight={400}
                        color="secondary"
                        paragraph
                        sx={{
                            width: '100%',
                        }}
                    >
                        Track your home renovation project with ease. With so many pieces in a typical
                        project, it can become overwhelming to keep track of progress, dependencies and
                        to-do's. This template makes it easy to stay up to date on tasks, next steps and
                        overall project completion.
                    </Typography>

                    {/* Confirm/Use button
					    ========================================= */}
                    <BaseButton
                        variant="contained"
                        size="large"
                        label="Use Template"
                        onClick={(e) => onConfirm && onConfirm()}
                        sx={{
                            alignSelf: 'flex-end',

                            marginTop: 'auto',
                        }}
                    />
                </Grid>
            </Grid>

            {/* Footer
				========================================= */}
            <Stack
                flexShrink={0}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                gap={12}
                padding={4}
            >
                {/* Creation date
				    ========================================= */}
                <Stack alignItems="flex-start" gap={1}>
                    <Typography fontSize={18} fontWeight={400} color="primary">
                        Created On
                    </Typography>

                    <Typography fontSize={18} fontWeight={400} color="common.text_1">
                        Oct 12, 2022
                    </Typography>
                </Stack>

                {/* Number of users
				    ========================================= */}
                <Stack alignItems="flex-start" gap={1}>
                    <Typography fontSize={18} fontWeight={400} color="primary">
                        Used By
                    </Typography>

                    <Typography fontSize={18} fontWeight={400} color="common.text_1">
                        # Users
                    </Typography>
                </Stack>

                {/* Rating
				    ========================================= */}
                <Stack alignItems="flex-start" gap={1}>
                    <Typography fontSize={18} fontWeight={400} color="primary">
                        Rating
                    </Typography>

                    <Typography fontSize={18} fontWeight={400} color="common.text_1">
                        4.7
                    </Typography>
                </Stack>

                {/* Price
				    ========================================= */}
                <Stack alignItems="flex-start" gap={1}>
                    <Typography fontSize={18} fontWeight={400} color="primary">
                        Price
                    </Typography>

                    <Typography fontSize={18} fontWeight={400} color="common.text_1">
                        {'$' + 14}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default TemplateDetailsDialog
