//* ======= Libraries
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDialog from 'components/dialog/StyledDialog'
import SignInLayout from 'pages/sign-in/SignInLayout'
import ProfileImageUploadDialog from 'features/sign-in/ProfileImageUploadDialog'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import { MOBILE_REGEX } from 'helpers/validators'
import { UpdateAccountService, UpdaterAccountRequestType } from 'services/AuthenticationService'
import { Controller, useForm } from 'react-hook-form'
import CountryCodesIndexed from 'assets/json/CountryCode'
import { RootContext } from 'contexts/RootContext'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'

interface ICompleteProfile {}

function CompleteProfile({}: ICompleteProfile) {
    const { user } = useContext(RootContext)
    const navigator = useNavigate()

    useEffect(() => {
        user?.isProfileCompeleted && navigator('/dashboard')
    }, [user])

    return (
        <SignInLayout>
            {/* Main content
				========================================= */}
            <Stack gridRow={2} width="40%">
                <CreateAccountProfileForm />
            </Stack>
        </SignInLayout>
    )
}

/*	Profile form
    ========================================= */

interface ICreateAccountProfileForm {}

const COUNTRIES = Object.keys(CountryCodesIndexed.byName)
const POSITIONS = [
    'Accounting',
    'Administration',
    'Advertising',
    'Art',
    'Business',
    'Consulting',
    'Customer Service',
    'Educator',
    'Engineering',
    'Healthcare Provider',
    'Human Resources',
    'IT',
    'Legal',
    'Management',
    'Owner',
    'Product Management',
    'Research',
    'Sales',
    'Student',
]
const SECTOR = [
    'Education',
    'Sport',
    'Energy',
    'Finance',
    'Goverment',
    'Healthcare',
    'Manufacturing',
    'NGO',
    'Technology',
    'Transport',
]

function CreateAccountProfileForm({}: ICreateAccountProfileForm) {
    const { getUser } = useContext(RootContext)
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
    const [uploadedImage, setUploadedImage] = useState(TempAvatar)
    const navigator = useNavigate()

    const {
        control,
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdaterAccountRequestType>({
        mode: 'onBlur',
    })

    const submitForm = () => {
        handleSubmit(
            //Valid
            (data) => {
                UpdateAccountService(data)
                    .then((res) => {
                        if (res.success) {
                            getUser()
                            navigator('/dashboard')
                        }
                    })
                    .catch((e) => {})
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    const onProfileImageSelected = (filePath: string) => {
        setValue('avatar', filePath)
        setUploadedImage(filePath)
        setIsUploadDialogOpen(false)
    }

    return (
        <>
            {/* Title
				========================================= */}
            <Typography fontSize={16} fontWeight={400} textAlign="justify" color="common.text_1" mb={3}>
                To access SNA Toolbox Dashboard, please first complete your SNA Account profile.
            </Typography>

            {/* Profile image upload
				========================================= */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                marginBottom={3}
            >
                <Avatar
                    src={uploadedImage}
                    alt="avatar"
                    sx={{
                        height: 60,
                        width: 60,
                    }}
                />

                <BaseButton label="Add Profile Image" onClick={(evt) => setIsUploadDialogOpen(true)} />
            </Stack>

            {/* Fullname
				========================================= */}
            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <BaseFilledTextField
                        label="Fullname"
                        value={value}
                        fullWidth
                        required
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        sx={{
                            marginBottom: 2.5,
                        }}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{
                    required: 'Fullname is required',
                    minLength: {
                        value: 2,
                        message: 'Fullname must be at least 2 characters',
                    },
                    pattern: {
                        message: 'Not a valid fullname. Fullname name must contain only letters',
                        value: /^(?=.*[a-z][a-z])[\s a-z ,.'-]+$/i,
                    },
                }}
            />

            {/* Company name
				========================================= */}
            <Controller
                name="company"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <BaseFilledTextField
                        label="Company Name"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        sx={{
                            marginBottom: 2.5,
                        }}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{
                    minLength: {
                        value: 2,
                        message: 'Company name must be at least 2 characters',
                    },
                    pattern: {
                        message: 'Not a valid Company name. Fullname name must contain only letters',
                        value: /^(?=.*[a-z][a-z])[\s a-z ,.'-]+$/i,
                    },
                }}
            />

            {/* Role
				========================================= */}
            <Controller
                name="position"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <BaseFilledTextField
                        label="What's your role?"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        sx={{
                            marginBottom: 2.5,
                        }}
                        helperText={error ? error.message : null}
                    />
                )}
            />

            {/* Country/Region
				========================================= */}
            <Controller
                name="country"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <BaseSelectWithLabel
                        label="Country"
                        options={COUNTRIES}
                        value={value}
                        fullWidth
                        sx={{
                            marginBottom: 2.5,
                        }}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : undefined}
                    />
                )}
            />

            {/* <Stack gap={1} marginBottom={2.5}>
                <Typography alignSelf="flex-start" fontSize={16} fontWeight={400}>
                    What is your role?
                </Typography>
                <FreeSoloCreateOption
                    label='What is your role?'
                    options={POSITIONS.map(p => ({ title: p }))} value={{ title: profile.position || '' }}
                    setValue={(v) => setProfileField('position')(v)} />
            </Stack> */}

            {/* Country and number
				========================================= */}
            <Stack gap={1} marginBottom={6}>
                <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <BaseFilledTextField
                            label="Phone number"
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            error={!!error}
                            sx={{
                                marginBottom: 2.5,
                            }}
                            helperText={error ? error.message : '+xx xxx xxx-xxx'}
                        />
                    )}
                    rules={{
                        pattern: {
                            message: 'Not a valid mobile number',
                            value: MOBILE_REGEX,
                        },
                    }}
                />
            </Stack>

            {/* Complete button
				========================================= */}
            <BaseButton
                label="Complete"
                onClick={submitForm}
                // disabled
                variant="contained"
                sx={{
                    py: 2,
                }}
            />

            {/* Profile image selection dialog
				========================================= */}
            {isUploadDialogOpen && (
                <StyledDialog
                    open={isUploadDialogOpen}
                    onClose={() => setIsUploadDialogOpen(false)}
                    fullWidth
                    maxWidth="sm"
                >
                    <ProfileImageUploadDialog onImageSelect={onProfileImageSelected} />
                </StyledDialog>
            )}
        </>
    )
}

export default CompleteProfile
