import { ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import { AllocationAPIs as Apis, parseUrl } from './Apis'
import { request } from './request'

// ToDo fix types and remove anys
export type AllocationResult = Record<
    string,
    Record<
        string,
        {
            members: string[]
            stats: Record<string, number>
        }
    >
>

export type AllocationConfigType = {
    selectedNetworkViz: number | null
    optimalClassSize: number
    groupBy: ReportDataSourceAttributeType[]
    networks: {
        selected: boolean
        name: string
        mode: 'positive' | 'negative'
        weight: number
    }[]
    diversityFields: {
        attribute: ReportDataSourceAttributeType
        type: 'numeric' | 'categorical'
        weight: number
    }[]
}

export type AllocationType = {
    primaryKey: number
    title: string
    status: 'NotStarted' | 'Pending' | 'Completed' | 'Failed'
    config: AllocationConfigType
    result: AllocationResult
}

export type BasicAllocationType = {
    primaryKey: number
    title: string
    status: string
}

export type CreateAllocationType = {
    title: string
    config: any
    data: any
}

type CreateAllocationResponseType = {
    success: boolean
    data: BasicAllocationType
}

export const CreateAllocationService: (
    pid: string | number,
    body: CreateAllocationType,
    showMessage?: boolean
) => Promise<CreateAllocationResponseType> = async (pid, body, showMessage = false) => {
    return request(
        parseUrl(Apis.CreateAllocation, { pid }),
        'POST',
        undefined,
        body,
        showMessage,
        undefined,
        false,
        true
    )
}

type GetAllocationsResponseType = {
    success: boolean
    data: BasicAllocationType[]
}

export const GetAllocationsService: (
    pid: string | number,
    showMessage?: boolean
) => Promise<GetAllocationsResponseType> = async (pid, showMessage = false) => {
    return request(
        parseUrl(Apis.GetAllocations, { pid }),
        'GET',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        true
    )
}

type GetAllocationResponseType = {
    success: boolean
    data: AllocationType
}

export const GetAllocationService: (
    pid: string | number,
    aid: string | number,
    showMessage?: boolean
) => Promise<GetAllocationResponseType> = async (pid, aid, showMessage = false) => {
    return request(
        parseUrl(Apis.GetAllocation, { pid, aid }),
        'GET',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        true
    )
}

export const DeleteAllocationService: (
    pid: string | number,
    aid: string | number,
    showMessage?: boolean
) => Promise<GetAllocationResponseType> = async (pid, aid, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteAllocation, { pid, aid }),
        'DELETE',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        true
    )
}
