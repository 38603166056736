import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { List, ListItem, ListItemText } from '@mui/material'
import { formatLabel, formatValue } from 'features/network-viz/helpers/DataFormatter'
import React, { useState } from 'react'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import { NetworkAnalyticsType } from 'features/network-viz/types/NetworkViz.types'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'

interface NetworkAnalyticsInfoProps {
    analytics: NetworkVizContextType['analytics']
    title: string
    onClose?: () => void
}

export default function NetworkAnalyticsInfo({ title, onClose, analytics }: NetworkAnalyticsInfoProps) {
    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                maxHeight: 500,
                width: 350,
                padding: theme.spacing(1.5, 2),
            })}
        >
            {/* Title and close button
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={600} color="primary">
                    {title}
                </Typography>

                <IconButton onClick={onClose} size="small">
                    <CloseRoundedIcon fontSize="small" />
                </IconButton>
            </Stack>

            <NetworkAnalyticsInfoTable analytics={analytics} />
        </Stack>
    )
}

interface NetworkAnalyticsInfoTableProps {
    analytics: NetworkVizContextType['analytics']
}

export function NetworkAnalyticsInfoTable({ analytics }: NetworkAnalyticsInfoTableProps) {
    const [relationship, setRelationship] = useState('view')

    return (
        <React.Fragment>
            <BaseSelectWithLabel
                label="Relationship"
                value={relationship}
                size="small"
                onChange={(v) => setRelationship(v)}
                options={Object.keys(analytics || {})}
            />
            <List
                dense
                sx={{
                    padding: 0,
                    margin: 0,
                    overflow: 'auto',
                }}
                className="u-scrollbar"
            >
                {analytics?.[relationship]?.graph &&
                    Object.keys(analytics[relationship].graph).map((k: string, index) => (
                        <ListItem
                            sx={{
                                padding: 0,
                                margin: 0,
                            }}
                            key={index}
                        >
                            <ListItemText
                                sx={{ marginY: '1px' }}
                                primary={formatLabel(k) + ':'}
                                secondary={formatValue(analytics[relationship].graph[k])}
                            />
                        </ListItem>
                    ))}
            </List>
        </React.Fragment>
    )
}
