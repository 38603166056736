//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import BaseButton from 'components/base/BaseButton'
import {
    DataGridPro,
    GridToolbar,
    GridColDef,
    GridRenderCellParams,
    useGridApiRef,
    GridToolbarExport,
} from '@mui/x-data-grid-pro'
//* ======= Custom logic
//* ======= Assets and styles
import DeleteIcon from '@mui/icons-material/Delete'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import GoodIcon from '@mui/icons-material/GppGood'
import {
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableRow,
    Tooltip,
} from '@mui/material'
import './upload-node-dialog.css'
import { CreateRequestNodeType, ImportNodesService } from 'services/NodeService'
import CircleIcon from '@mui/icons-material/Circle'
import WebWorker from 'helpers/webWorkerHelper'

const css_prefix = 'upload-node-dialog-'

const SampleCsv = require('assets/sample/nodes.csv')

interface IUploadNodeDialog {
    projectId: number
    onConfirm?: () => void
}

function UploadNodeDialog({ onConfirm, projectId }: IUploadNodeDialog) {
    const [rows, setRows] = useState<Array<{ [key: string]: any }>>([])
    // const [columns, setColumns] = useState<GridColDef[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [loading, setLoading] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [hasWarning, setHasWarning] = useState(false)
    const [hasData, setHasData] = useState(false)
    const [showGuide, setShowGuide] = useState(false)

    const gridApi = useGridApiRef()

    //find row index by id
    const findRowIndex = (id: number) => {
        return rows.findIndex((item) => item.id === id)
    }

    //remove row
    const deleteRow = (id: number) => {
        const newNodes = [...rows].filter((ri) => ri.id !== id)
        setRows(newNodes)
        verifyNodes(newNodes)
    }

    //remove row
    const deleteAllErrors = () => {
        const newNodes = [...rows].filter((ri) => ri.status !== 'error')
        setRows(newNodes)
        verifyNodes(newNodes)
    }

    //filter errors only
    const filterErrorsOnly = () => {
        if (gridApi.current == null) return

        gridApi.current.setFilterModel({
            items: [{ field: 'status', operator: 'equals', value: 'error' }],
        })
    }

    // select file
    function uploadAndParseNodes(event: React.ChangeEvent<HTMLInputElement>) {
        setLoading(true)
        if (event.target.files && event.target.files.length > 0) {
            const loadNodesWorker = new window.Worker(
                process.env.REACT_APP_BASE_URL + 'workers/node/parse-nodes-worker.js'
            )
            loadNodesWorker.postMessage({ fileName: event.target.files[0] })
            loadNodesWorker.onmessage = function (e) {
                const { nodes, headers, hasError, hasWarning } = e.data

                setColumns([
                    ...headers,
                    {
                        field: 'status',
                        renderCell: (params: GridRenderCellParams) => {
                            if (params.value === 'error') {
                                return (
                                    <Tooltip title={params.row.errors.join('\n')}>
                                        <ErrorIcon color="error" />
                                    </Tooltip>
                                )
                            } else if (params.value === 'warning') {
                                return (
                                    <Tooltip title={params.row.warnings.join('\n')}>
                                        <WarningIcon color="warning" />
                                    </Tooltip>
                                )
                            } else {
                                return <GoodIcon color="success" />
                            }
                        },
                    },
                    {
                        field: 'id',
                        disableColumnMenu: true,
                        filterable: false,
                        editable: false,
                        headerName: '',
                        sortable: false,
                        renderCell: (params: GridRenderCellParams) => {
                            return (
                                <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
                                    <IconButton
                                        onClick={(e) => deleteRow(params.id as number)}
                                        sx={{
                                            color: 'common.fuschia100',
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            )
                        },
                    },
                ])
                setHasData(true)
                setRows(nodes)
                setHasError(hasError)
                setHasWarning(hasWarning)
                setLoading(false)
            }
        }
    }

    //verify if there is any error or warning
    const verifyNodes = (newRows: Array<{ [key: string]: any }>) => {
        setLoading(true)
        const loadGraphWorker = new window.Worker('workers/node/verify-nodes-worker.js')
        loadGraphWorker.postMessage(newRows)
        loadGraphWorker.onmessage = function (e) {
            const { nodes, hasError, hasWarning } = e.data

            setRows(nodes)
            setHasError(hasError)
            setHasWarning(hasWarning)
            setLoading(false)
        }
    }

    //update cell value
    const updateNode = (row: { [key: string]: any }, event: { [key: string]: any }) => {
        if (row.reason === 'enterKeyDown') {
            const newRows = { ...rows }
            const index = findRowIndex(row.id)
            if (index === -1) return
            newRows[index][row.field] = event.target.value
            verifyNodes(newRows)
        }
    }

    //import nodes
    const importNodes = () => {
        const combineNodesWorker = new WebWorker<CreateRequestNodeType[]>(
            'workers/node/combine-nodes-worker.js'
        )
        combineNodesWorker.run(rows).then((nodes) => {
            setLoading(true)
            ImportNodesService(nodes, projectId)
                .then((res) => {
                    if (res.success) {
                        onConfirm && onConfirm()
                    }
                })
                .finally(() => setLoading(false))
        })
    }

    return (
        <Stack minHeight={360}>
            {/* (Conditional) File error message
				========================================= */}
            {hasError ||
                (hasWarning && (
                    <Box
                        sx={(theme) => ({
                            padding: theme.spacing(2, 5.25),

                            backgroundColor: theme.palette.common.fuschia60,
                        })}
                    >
                        <Typography fontSize={14} fontWeight={400} color="common.fuschia100">
                            The selected file has some missing/bad values. Please review the table and fix the
                            issues.
                        </Typography>
                    </Box>
                ))}

            {/* Main content
				========================================= */}
            {!showGuide ? (
                <Stack
                    flexGrow={1}
                    alignItems="flex-start"
                    gap={1}
                    py={2}
                    px={3}
                    sx={(theme) => ({
                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? theme.palette.common.bg_2
                                : theme.palette.common.bg_3,
                    })}
                >
                    <Typography paragraph fontSize={14} fontWeight={400} color="common.text_1">
                        Please select the CSV file with your list of nodes for import. For structuring
                        instructions, click here:
                        <BaseButton onClick={() => setShowGuide(true)}>Node Information CSV Guide</BaseButton>
                    </Typography>

                    {/* Upload and download buttons
				    ========================================= */}
                    <Stack direction="row" alignItems="center" gap={2} marginBottom={1}>
                        <BaseButton
                            variant="contained"
                            size="large"
                            label="Select File"
                            onClick={() => {
                                document.getElementById('select-node-csv')?.click()
                            }}
                        />
                        <input
                            id="select-node-csv"
                            type="file"
                            accept=".csv, .txt"
                            onChange={uploadAndParseNodes}
                            style={{ display: 'none' }}
                        />
                    </Stack>

                    {/* Progress bar
				    ========================================= */}
                    {loading && (
                        <Stack
                            alignSelf="stretch"
                            direction="row"
                            alignItems="center"
                            gap={2}
                            marginBottom={3}
                        >
                            <LinearProgress sx={{ width: '100%' }} />
                        </Stack>
                    )}

                    {hasData && (
                        <React.Fragment>
                            {/* (Conditional) Table controls
                            ========================================= */}
                            <Stack alignSelf="stretch" direction="row" alignItems="center" gap={2}>
                                <BaseButton label="Show errors only" onClick={filterErrorsOnly} />
                                <BaseButton label="Remove all rows with error" onClick={deleteAllErrors} />

                                {/* Search
					            ========================================= */}
                                {/* <SearchField
                                freeSolo
                                options={searchFieldOptions}
                                onChange={(evt, value) => value && onSearch(value)}
                                placeholder="Search"
                                width="220px"
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            /> */}
                            </Stack>

                            {/* (Conditional) Table
				            ========================================= */}
                            <Box
                                sx={{
                                    alignSelf: 'stretch',

                                    height: 300,
                                    marginBottom: 3,
                                    paddingBottom: 1,
                                }}
                            >
                                {/* TODO @Pourya fix table format */}
                                <DataGridPro
                                    apiRef={gridApi}
                                    columns={columns}
                                    rows={rows}
                                    loading={loading}
                                    density="compact"
                                    hideFooter
                                    components={{ Toolbar: GridToolbar }}
                                    initialState={{
                                        pinnedColumns: { left: ['status'], right: ['id'] },
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: 'title',
                                                    sort: 'asc',
                                                },
                                            ],
                                        },
                                    }}
                                    editMode="cell"
                                    onCellEditStop={updateNode}
                                    getRowClassName={(data) => {
                                        let id = data.id as number
                                        const index = findRowIndex(id)
                                        if (index === -1) return css_prefix + 'normal'
                                        if (rows[index].errors.length > 0) {
                                            // return css_prefix + 'error';
                                        } else if (rows[index].warnings.length > 0) {
                                            // return css_prefix + 'warning';
                                        }
                                        return css_prefix + 'normal'
                                    }}
                                />
                            </Box>
                        </React.Fragment>
                    )}

                    {/* Footer buttons
				    ========================================= */}
                    <Stack
                        alignSelf="stretch"
                        marginTop="auto"
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={3}
                    >
                        <BaseButton
                            variant="outlined"
                            size="large"
                            label="Skip"
                            onClick={(e) => onConfirm && onConfirm()}
                            sx={{
                                width: 186,
                                py: 1.5,
                            }}
                        />

                        <BaseButton
                            variant="contained"
                            size="large"
                            label="Import Nodes"
                            disabled={!hasData || hasError}
                            onClick={importNodes}
                            sx={{
                                width: 186,
                                py: 1.5,
                            }}
                        />
                    </Stack>
                </Stack>
            ) : (
                <Stack
                    flexGrow={1}
                    alignItems="flex-start"
                    gap={1}
                    py={2}
                    px={3}
                    sx={(theme) => ({
                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? theme.palette.common.bg_2
                                : theme.palette.common.bg_3,
                    })}
                >
                    <NodeInfoGuide />
                    {/* Footer buttons
				    ========================================= */}
                    <Stack alignSelf="stretch" marginTop="auto" direction="row" alignItems="center" gap={3}>
                        <BaseButton
                            variant="outlined"
                            size="large"
                            label="Back"
                            onClick={(e) => setShowGuide(false)}
                            sx={{
                                width: 186,
                                py: 1.5,
                            }}
                        />
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}

const NodeInfoGuide = () => {
    const rows = [
        {
            id: 0,
            title: 'Node 1',
            description: 'This is Node 1',
            respondent_title: 'John Doe',
            respondent_email: 'johndoe@example.com',
            respondent_mobile: '1234567890',
            age: '25',
        },
        {
            id: 1,
            title: 'Node 2',
            description: 'This is Node 2',
            respondent_title: 'Jane Doe',
            respondent_email: 'janedoe@example.com',
            respondent_mobile: '0987654321',
            age: '30',
        },
    ]

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            description: 'The title of the node. This is a required field.',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Description',
            description: 'A brief description of the node. This is a required field.',
            flex: 1,
        },
        {
            field: 'respondent_title',
            headerName: 'Respondent Title',
            description:
                'The name of the individual who is responding for this node. This is a required field.',
            flex: 1,
        },
        {
            field: 'respondent_email',
            headerName: 'Respondent Email',
            description: 'The email address of the respondent. This is a required field.',
            flex: 1,
        },
        {
            field: 'respondent_mobile',
            headerName: 'Respondent Mobile',
            description: 'The mobile number of the respondent. This is a required field.',
            flex: 1,
        },
        {
            field: 'age',
            headerName: 'Age',
            description: 'The age of the node. This is an additional optional field.',
            flex: 1,
        },
    ]

    const columnsInfos = [
        {
            column: 'title**',
            description:
                'This is the identifier of the node. It can be a name, an ID, or any unique identifier that distinguishes this node from others in your network.',
        },
        {
            column: 'description',
            description:
                'A short, concise text that provides additional context or information about the node. This might be a brief summary, a classification type, or any descriptive information that is helpful to characterize the node.',
        },
        {
            column: 'respondent_title*',
            description:
                'The name of the individual who will be answering surveys on behalf of this node. In many cases, the respondent is also the node (e.g., when the node is a person), but this may not always be the case.',
        },
        {
            column: 'respondent_email*',
            description:
                'The email address of the respondent. This is the primary contact method used for sending survey invitations, reminders, or notifications.',
        },
        {
            column: 'respondent_mobile*',
            description:
                'The mobile number of the respondent. This could be used for text message surveys, notifications, or as a secondary contact method.',
        },
        {
            column: 'additional columns',
            description:
                'These columns hold additional attributes or properties specific to the node. The header of the column determines the attribute name. You can add as many of these columns as you want to capture more specific details about each node. In the example below, age is used, but you could also include attributes like gender, location, department, etc., based on your needs.',
        },
    ]

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h2">Node Information CSV Guide</Typography>
            <hr />
            <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }} className="u-scrollbar">
                <Typography gutterBottom textAlign="justify">
                    This CSV file is used to import node information into the system. A 'node' represents an
                    entity in your network which could be a person, place, activity, or any abstract concept
                    that is relevant to your surveys. Each row in the CSV represents one node, and the columns
                    hold specific information about that node.
                </Typography>

                <Typography gutterBottom textAlign="justify">
                    Here's a brief description of each column in the CSV:
                </Typography>

                <TableContainer sx={{ marginBottom: 2 }}>
                    <Table>
                        <TableBody>
                            {columnsInfos.map((columnsInfo) => (
                                <TableRow key={columnsInfo.column}>
                                    <TableCell component="th" scope="row">
                                        {columnsInfo.column}
                                    </TableCell>
                                    <TableCell>{columnsInfo.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography gutterBottom>** Mandatory | * Recommended</Typography>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography textAlign="justify">
                                    If you are importing various types of nodes (e.g., students, school
                                    activities), it is highly recommended to add an additional column to
                                    indicate the type of nodes. This can be later used in network questions
                                    for filtering.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography textAlign="justify">
                                    If you want to have more than one respondent per node, create a row per
                                    respondent and only fill the Title with the node name or identifier, and
                                    respondent details (email, title, mobile), and leave the rest blank.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography textAlign="justify">
                                    Ensure all your data is correctly formatted and fits the purpose of each
                                    column to ensure a successful import. If you have any other questions or
                                    concerns, please contact our support team.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography textAlign="justify">
                                    Remember, your data privacy is important to us. Ensure you have obtained
                                    necessary permissions before importing contact details into the system.
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>

                <Typography>Below is an example of how your data can look like:</Typography>
                <Box sx={{ height: (rows.length + 1) * 54, width: '100%', marginY: 2 }}>
                    <DataGridPro columns={columns} rows={rows} hideFooter />
                </Box>
                <Link href={SampleCsv} target="_blank">
                    Download template CSV file
                </Link>
            </Box>
        </Box>
    )
}

export default UploadNodeDialog
