//* ======= Libraries
import React, { useContext, useMemo } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { SurveyContext } from 'contexts/SurveyContex'
import { Link, useParams } from 'react-router-dom'
import { GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { GetSurveyAdminLogsService, GetSurveyLogsService } from 'services/SurveyService'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import Tooltip from '@mui/material/Tooltip'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import FilterListIcon from '@mui/icons-material/FilterList'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import DownloadIcon from '@mui/icons-material/Download'
import PrintIcon from '@mui/icons-material/Print'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EmailIcon from '@mui/icons-material/Email'
import SettingsIcon from '@mui/icons-material/Settings'
import PreviewIcon from '@mui/icons-material/Preview'
import AddBoxIcon from '@mui/icons-material/AddBox'
import SendIcon from '@mui/icons-material/Send'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import SmsIcon from '@mui/icons-material/Sms'
import StyledTooltip from 'features/styled-tooltip/StyledTooltip'
import BaseButton from 'components/base/BaseButton'
import PopupDialog from 'features/popup-dialog/PopupDialog'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
import moment from 'moment'
import { ProjectContext } from 'contexts/ProjectContext'
import { PERMISSIONS } from 'helpers/constants'

const TempRowCandidateView = ({ username, avatar }: { username: string; avatar: string | undefined }) => {
    return (
        <Stack direction="row" alignItems="center" gap={2}>
            <Avatar src={avatar || TempAvatar} alt={username} />

            <Typography fontSize={10} fontWeight={400} color="common.text_1">
                {username}
            </Typography>
        </Stack>
    )
}

interface ISurveyLogs {}

/**
 * TODO:
 * 	1.	'aria-controls' is missing for each tab's container.
 */

function SurveyLogs({}: ISurveyLogs) {
    const { drawerContent } = useContext(SurveyContext)
    const { hasPermission } = useContext(ProjectContext)

    const canViewAdminLog = useMemo(() => {
        return hasPermission(PERMISSIONS.survey.ReadSurveyAuditAdmin)
    }, [hasPermission])

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue)
    }

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack
                    height="100%"
                    sx={(theme) => ({
                        paddingBottom: 2,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? theme.palette.common.bg_1
                                : theme.palette.common.bg_3,
                    })}
                >
                    {/* Tabs and head controls
						========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            // py: 1.25,
                            paddingLeft: 0,
                            paddingRight: 3,

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Tabs
							========================================= */}
                        <Tabs
                            value={selectedTabIndex}
                            onChange={handleTabChange}
                            aria-label="survey logs tabs"
                            sx={{
                                '& .MuiTab-root': {
                                    py: 2,
                                    px: 3,
                                    fontSize: 20,
                                    fontWeight: 500,

                                    color: 'secondary.main',
                                },
                            }}
                        >
                            {/* Participants tab
								========================================= */}
                            <Tab label="Participants Log" id="survey-logs-tab-1" />

                            {/* Admin tab
								========================================= */}
                            {canViewAdminLog && <Tab label="Admin Log" id="survey-logs-tab-2" />}
                        </Tabs>

                        {/* Controls
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            {/* Search
								========================================= */}
                            {/* <SearchField
                                options={['Something']}
                                placeholder="Search"
                                sx={{
                                    marginRight: 1,
                                }}
                            /> */}

                            {/* Filter button
								========================================= */}
                            <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <FilterListIcon />
                            </IconButton>

                            {/* Column (?) button
								========================================= */}
                            <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <ViewColumnIcon />
                            </IconButton>

                            {/* Download button
								========================================= */}
                            <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>

                            {/* Print button
								========================================= */}
                            <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <PrintIcon />
                            </IconButton>
                        </Stack>
                    </Stack>

                    {/* Participants table / Tabs panel
						========================================= */}
                    <Box
                        flexGrow={1}
                        sx={{
                            paddingLeft: 3,
                            paddingRight: 2,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {selectedTabIndex === 0 ? (
                            <SurveyLogsParticipantsPanel />
                        ) : selectedTabIndex === 1 ? (
                            <SurveyLogsAdminPanel />
                        ) : (
                            false
                        )}
                    </Box>
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}

export default SurveyLogs

/*	Participants logs panel
    ======================================== */

const ACTION_ICON_MAP: { [key: string]: any } = {
    DeleteWidget: (
        <Tooltip title="Delete Widget">
            <DeleteForeverIcon />
        </Tooltip>
    ),
    SurveySendManualReminderEmail: (
        <Tooltip title="Reminder Email">
            <EmailIcon />
        </Tooltip>
    ),
    UpdateSurveySettings: (
        <Tooltip title="Update Survey Settings">
            <SettingsIcon />
        </Tooltip>
    ),
    GetSurveyNotification: (
        <Tooltip title="Get Survey Notification">
            <PreviewIcon />
        </Tooltip>
    ),
    GetSurveyLogs: (
        <Tooltip title="Get Survey Logs">
            <PreviewIcon />
        </Tooltip>
    ),
    GetProject: (
        <Tooltip title="Get Project">
            <PreviewIcon />
        </Tooltip>
    ),
    CreateSurvey: (
        <Tooltip title="Create Survey">
            <AddBoxIcon />
        </Tooltip>
    ),
    SendManualReminder: (
        <Tooltip title="Send Manual Reminder">
            <SendIcon />
        </Tooltip>
    ),
    CreateReport: (
        <Tooltip title="Create Report">
            <AddBoxIcon />
        </Tooltip>
    ),
    UpdateWidget: (
        <Tooltip title="Update Widget">
            <EditIcon />
        </Tooltip>
    ),
    GetSurvey: (
        <Tooltip title="Get Survey">
            <PreviewIcon />
        </Tooltip>
    ),
    UpdateReport: (
        <Tooltip title="Update Report">
            <EditIcon />
        </Tooltip>
    ),
    DeleteSurvey: (
        <Tooltip title="Delete Survey">
            <DeleteForeverIcon />
        </Tooltip>
    ),
    ExceptionAction: (
        <Tooltip title="Exception Action">
            <ErrorIcon />
        </Tooltip>
    ),
    GetReportById: (
        <Tooltip title="Get Report">
            <PreviewIcon />
        </Tooltip>
    ),
    DeleteReport: (
        <Tooltip title="Delete Report">
            <DeleteForeverIcon />
        </Tooltip>
    ),
    CreateRule: (
        <Tooltip title="Create Rule">
            <AddBoxIcon />
        </Tooltip>
    ),
    UpdateSurveyNotification: (
        <Tooltip title="Update Survey Notification">
            <EditIcon />
        </Tooltip>
    ),
    SurveySendManualReminderSms: (
        <Tooltip title="Reminder Sms">
            <SmsIcon />
        </Tooltip>
    ),
    CreateWidget: (
        <Tooltip title="Create Widget">
            <AddBoxIcon />
        </Tooltip>
    ),
}

interface ISurveyLogsParticipantsPanel {}

function SurveyLogsParticipantsPanel({}: ISurveyLogsParticipantsPanel) {
    const { sid, pid } = useParams()
    const [page, setPage] = React.useState(0)
    const [rowCount, setRowCount] = React.useState(0)
    const [rows, setRows] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false)
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>()

    React.useEffect(() => {
        if (!sid || !pid) return
        let active = true

        ;(async () => {
            setLoading(true)
            const newRows = await GetSurveyLogsService(pid, sid, { pageNumber: page, pageSize: 20 })

            if (!active || !newRows.success) {
                return
            }

            setRows(newRows.data.data)
            setRowCount(newRows.data.rowCount)
            setLoading(false)
        })()

        return () => {
            active = false
        }
    }, [page, filterModel])

    return (
        <StyledDataGrid
            rows={rows}
            rowCount={rowCount}
            columns={[
                {
                    field: 'avatar',
                    headerName: '',
                    renderCell: ({ row }) => (
                        <Avatar src={row.node?.avatar}>{buildAbbreviation(row.participant?.name)}</Avatar>
                    ),
                },
                {
                    field: 'node',
                    headerName: 'Node',
                    renderCell: ({ value }) => (
                        <Link to={`/project/${pid}/nodes/${value?.id}`}>{value?.title}</Link>
                    ),
                },
                {
                    field: 'participant',
                    headerName: 'Participant',
                    valueGetter: ({ value }) => value?.name,
                },
                {
                    field: 'email',
                    headerName: 'Email',
                    valueGetter: ({ row }) => row.participant?.email,
                },
                {
                    field: 'mobile',
                    headerName: 'Phone Number',
                    valueGetter: ({ row }) => row.participant?.phoneNumber,
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    renderCell: ({ value }) => ACTION_ICON_MAP[value] || value,
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    renderCell: ({ value }) =>
                        value ? <ThumbUpIcon color="success" /> : <ThumbDownIcon color="error" />,
                },
                {
                    field: 'timeStamp',
                    headerName: 'Timestamp',
                    valueFormatter: ({ value }) => moment(value).format('DD.MM.YY HH:mm'),
                },
                {
                    field: 'details',
                    headerName: 'Details',
                    renderCell: ({ value, row, id }) => (
                        <PopupDialog
                            dialogTitle={row?.participant?.name || `Log: ${id}`}
                            dialogContet={
                                <p
                                    style={{
                                        color: 'black',
                                        backgroundColor: 'white',
                                        overflowWrap: 'break-word',
                                        overflow: 'hidden',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: value }}
                                />
                            }
                        />
                    ),
                },
            ]}
            filterMode="server"
            onFilterModelChange={(model: GridFilterModel) => setFilterModel(model)}
            paginationMode="server"
            pagination
            paginationModel={{
                page: page,
                pageSize: 20,
            }}
            onPaginationModelChange={(model) => setPage(model.page)}
            loading={loading}
        />
    )
}

/*	Admin logs panel
    ======================================== */

interface ISurveyLogsAdminPanel {}

function SurveyLogsAdminPanel({}: ISurveyLogsParticipantsPanel) {
    const { sid, pid } = useParams()
    const [page, setPage] = React.useState(0)
    const [rowCount, setRowCount] = React.useState(0)
    const [rows, setRows] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false)
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([])

    React.useEffect(() => {
        if (!sid || !pid) return
        let active = true

        ;(async () => {
            setLoading(true)
            const newRows = await GetSurveyAdminLogsService(pid, sid, { pageNumber: page, pageSize: 20 })

            if (!active || !newRows.success) {
                return
            }

            setRows(newRows.data.data)
            setRowCount(newRows.data.rowCount)
            setLoading(false)
        })()

        return () => {
            active = false
        }
    }, [page])

    return (
        <StyledDataGrid
            rows={rows}
            rowCount={rowCount}
            columns={[{ field: 'id' }, { field: 'action' }, { field: 'status' }]}
            paginationMode="server"
            pagination
            paginationModel={{
                page: page,
                pageSize: 20,
            }}
            onPaginationModelChange={(model) => setPage(model.page)}
            loading={loading}
        />
    )
}
