import React, { useState, useRef, useMemo } from 'react'
import { IconButton, Tooltip, Box, Stack, Typography, alpha } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import BaseButton from 'components/base/BaseButton'
import useDebouncedResizeObserver from 'hooks/useDebouncedResizeObserver'
import { uniq } from 'lodash'
import CircleIcon from '@mui/icons-material/Circle'

type NodeLegendItemType = {
    title: string
    color: string
    active: boolean
}

const NetworNodeLegend: React.FC = () => {
    const { nodeStyle, nodeRenders } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const groups: NodeLegendItemType[] = useMemo(() => {
        const result: NodeLegendItemType[] = []
        const visibleNodes = uniq(nodeRenders.filter((x) => x.hide !== true).map((render) => render.groupKey))

        visibleNodes.forEach((name) => {
            if (name === undefined || name === 'default') return
            result.push({
                title: name,
                color: nodeStyle[name].normal.color,
                active: nodeStyle[name].visible,
            })
        })
        return result.sort((a, b) => (a.title > b.title ? 1 : -1))
    }, [nodeStyle, nodeRenders])

    // const toggleRelation = (index: number) => {
    //     dispatchContext({
    //         type: 'EDGE_STYLE_VISSIBLE_TOGGLE',
    //         payload: {
    //             name: groups[index].title,
    //         },
    //     })
    // }

    return (
        <Stack
            sx={(theme) => ({
                display: 'flex',
                overflowX: 'hidden',
                flex: 1,
                alignItems: 'center', // Add this to vertically center the items within the Stack,
                height: '100%',
            })}
            direction="column"
            gap={1}
            className="u-scrollbar"
        >
            {groups.map((relation, index) => (
                <Stack
                    key={index}
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{
                        cursor: 'pointer',
                    }}
                    // onClick={() => toggleRelation(index)}
                >
                    <IconButton
                        sx={{
                            minWidth: 0,
                            minHeight: 0,
                            width: 16,
                            height: 16,
                            color: relation.active ? relation.color : '#eeeeee',
                        }}
                    >
                        <CircleIcon fontSize="small" />
                    </IconButton>
                    <Typography variant="caption">{relation.title}</Typography>
                </Stack>
            ))}
        </Stack>
    )
}

export default NetworNodeLegend
