import { toast } from 'react-toastify'

type ToastHelperConfigType = {
    loadingMessage?: string
    successMessage?: string
    errorMessage?: string
    closeDelay?: number
}

// this function is used to show toast message when user create, update, delete, etc...
// ToDo replace all individual useses of toast with this helper

export default class ToastHelper {
    config: ToastHelperConfigType
    toastId: string | number

    /**
     * @param config Configuration options for the toast notifications
     */
    constructor(config: ToastHelperConfigType = {}) {
        this.config = {
            loadingMessage: 'Loading...',
            successMessage: 'Success!',
            errorMessage: 'Something went wrong.',
            closeDelay: 2000,
            ...config,
        }
        this.toastId = toast.loading(this.config.loadingMessage)
    }

    /**
     * Updates the toast notification to indicate a successful operation
     */
    success(message?: string) {
        try {
            toast.update(this.toastId, {
                render: message || this.config.successMessage,
                type: 'success',
                isLoading: false,
                autoClose: this.config.closeDelay,
                closeButton: null,
            })
        } catch (error) {
            console.error('Failed to update toast to success:', error)
        }
    }

    /**
     * Updates the toast notification to indicate a failed operation
     */
    fail(message?: string) {
        try {
            toast.update(this.toastId, {
                render: message || this.config.errorMessage,
                type: 'error',
                isLoading: false,
                autoClose: this.config.closeDelay,
                closeButton: null,
            })
        } catch (error) {
            console.error('Failed to update toast to failure:', error)
        }
    }

    /**
     * Closes the toast notification programmatically
     */
    close() {
        try {
            toast.dismiss(this.toastId)
        } catch (error) {
            console.error('Failed to close toast:', error)
        }
    }
}
