import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import SearchIcon from '@mui/icons-material/Search'

import './styled-text-field.css'
import { useState } from 'react'

const cssBaseClass = 'styles-text-field'

export function StyledTextField(props: TextFieldProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    return (
        <TextField
            size="small"
            className={`${cssBaseClass}`}
            fullWidth
            variant="filled"
            {...props}
            InputLabelProps={{
                className: `${cssBaseClass}-label`,
            }}
            InputProps={{
                endAdornment:
                    props.type === 'password' ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : props.type === 'search' ? (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ) : null,
            }}
            type={showPassword ? 'text' : props.type}
            onKeyPress={(evt) => {
                const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                if (props.type === 'number' && !numbers.includes(Number(evt.key))) {
                    evt.preventDefault()
                }
            }}
        />
    )
}
