//* ======= Libraries
import React, { ReactNode } from 'react'
import {
    Stack,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionSummaryProps,
    AccordionDetails,
    styled,
} from '@mui/material'
//* ======= Components and features
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
//* ======= Assets and styles
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
    gap: theme.spacing(1),

    minWidth: 0,
    padding: theme.spacing(0, 0),

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
        color: theme.palette.primary.main,
    },

    '& .MuiAccordionSummary-content': {
        minWidth: 0,
        margin: 0,

        // color: theme.palette.common.text_1,

        // '&.Mui-expanded': {
        //     color: theme.palette.primary.main,
        // },
    },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    minWidth: 0,
    padding: 0,
}))

type Props = {
    title?: string
    // isExpanded?: boolean
    // onExpand?: () => void
    defaultExpanded?: boolean
    hasToggle?: boolean
    isToggledOff?: boolean
    onToggle?: (isEnabled: boolean) => void
    isDisabled?: boolean
    hasBottomBorder?: boolean
    children?: ReactNode
}

function StyledWidgetAccordion({
    title,
    // isExpanded = false,
    // onExpand,
    defaultExpanded = false,
    hasToggle = false,
    isToggledOff = false,
    onToggle,
    isDisabled = false,
    hasBottomBorder = false,
    children,
}: Props) {
    return (
        <Accordion
            disabled={isDisabled}
            // expanded={isExpanded}
            // onChange={(event) => { }}
            defaultExpanded={defaultExpanded}
            TransitionProps={{ unmountOnExit: false }}
            disableGutters
            square
            sx={[
                (theme) => ({
                    paddingBottom: hasBottomBorder ? theme.spacing(1) : undefined,

                    boxShadow: 0,
                    borderBottom: hasBottomBorder ? `1px solid ${theme.palette.common.border_2}` : undefined,
                    backgroundColor: 'transparent',
                    backgroundImage: 'none',

                    '&:before': {
                        height: 0,

                        backgroundColor: 'unset',
                    },

                    '&.Mui-disabled': {
                        backgroundColor: 'transparent',
                    },
                }),
            ]}
        >
            {/* Accordion header
				========================================= */}
            <StyledAccordionSummary>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={0.5}
                    sx={{
                        minWidth: 0,
                        width: '100%',
                    }}
                >
                    {/* Title
						========================================= */}
                    <Typography fontSize={14} fontWeight={400} noWrap color="common.text_1">
                        {title}
                    </Typography>

                    {/* Enable switch
						========================================= */}
                    {hasToggle && (
                        <BaseSwitch
                            checked={isToggledOff === false}
                            onChange={(evt, checked) => onToggle && onToggle(checked)}
                            onClick={(evt) => evt.stopPropagation()}
                            size="small"
                            color="primary"
                            sx={{
                                flexShrink: 0,
                            }}
                        />
                    )}
                </Stack>
            </StyledAccordionSummary>

            {/* Accordion body
				========================================= */}
            <StyledAccordionDetails>{children}</StyledAccordionDetails>
        </Accordion>
    )
}

export default StyledWidgetAccordion
