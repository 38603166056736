import { Stack, Typography, Card, CardContent, CardActions, CardHeader, Box } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import { useMemo } from 'react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import Chart from 'features/chart/Chart'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import { InsightItemType } from '../../InisghtWidgetInterventionAdvancedView'
import { i } from 'mathjs'

type IssueSummaryCardProps = {
    title: string
    items: InsightItemType[string]
    icon?: string
    onClick: () => void
}

const IssueSummaryCard = ({ title, items, icon, onClick }: IssueSummaryCardProps) => {
    const chartOption: EChartsCustomOptionType = useMemo(() => {
        const colors = {
            high: 'red',
            medium: 'orange',
            low: 'green',
        }

        const _data: Record<
            string,
            Array<{
                value: number
                itemStyle: {
                    color: string
                }
                label?: {
                    show: boolean
                    position: [number, number]
                    formatter: string
                }
            }>
        > = {}

        const visitedIndex: Record<string, boolean> = {}

        Object.values(items.issues).forEach((issue, index) => {
            issue.nodes.forEach((node) => {
                if (_data[node.severity] === undefined) {
                    _data[node.severity] = []
                }
                if (_data[node.severity][index] === undefined) {
                    _data[node.severity][index] = {
                        value: 0,
                        itemStyle: {
                            color: colors[node.severity],
                        },
                    }
                }
                _data[node.severity][index].value += 1
                if (!visitedIndex[index]) {
                    visitedIndex[index] = true
                    _data[node.severity][index].label = {
                        show: true,
                        position: [0, -15],
                        formatter: issue.text,
                    }
                }
            })
        })

        let options: EChartsCustomOptionType = {
            yAxis: {
                show: false,
                type: 'category',
                data: Object.values(items.issues).map((issue) => issue.text),
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01],
            },
            legend: {
                data: [
                    {
                        name: 'high',
                        itemStyle: {
                            color: colors.high,
                        },
                    },
                    {
                        name: 'medium',
                        itemStyle: {
                            color: colors.medium,
                        },
                    },
                    {
                        name: 'low',
                        itemStyle: {
                            color: colors.low,
                        },
                    },
                ],
            },
            series: Object.entries(_data).map(([name, data], index) => {
                return {
                    name: name,
                    type: 'bar',
                    data: data,
                    stack: 'total',
                    // barWidth: 10,
                    barCategoryGap: 20,
                    barMaxWidth: 20,
                }
            }),
        }
        console.log(options)

        return options
    }, [items])

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <CardHeader
                title={
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {icon && <DynamicIcon icon={icon} />}
                        <Typography variant="h6">{title}</Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Box height={400} width="100%">
                    <Chart type="bar" options={chartOption} />
                </Box>
            </CardContent>
        </Card>
    )
}

export default IssueSummaryCard
