//* ======= Libraries
import React, { ReactNode } from 'react'
//* ======= Components and features
import TemplateCardWide from './TemplateCardWide'
import TemplateCardBoxed, { ITemplateCardBoxedFloatingButton } from './TemplateCardBoxed'
//* ======= Custom logic
import { ResourceType } from 'types/resources'
import { ContextMenuItemType } from 'types/ContextMenuType'
//* ======= Assets and styles

type TemplateCardProps = {
    data: ResourceType
    isWideView?: boolean | undefined
    isFolder?: boolean | undefined
    hasMenuOnBoxed?: boolean
    iconBadge?: ReactNode
    boxedFloatingButtonProps?: ITemplateCardBoxedFloatingButton
    // TODO: Other props like project details, button handlers, etc.
    contextMenu?: ContextMenuItemType[]
    clickHandler?: () => void
}

function TemplateCard({
    data,
    isFolder = false,
    isWideView = false,
    hasMenuOnBoxed = false,
    iconBadge,
    boxedFloatingButtonProps,
    clickHandler,
    contextMenu,
}: TemplateCardProps) {
    return isWideView ? (
        <TemplateCardWide
            data={data}
            isFolder={isFolder}
            clickHandler={clickHandler}
            contextMenu={contextMenu}
        />
    ) : (
        <TemplateCardBoxed
            data={data}
            isFolder={isFolder}
            showMenu={hasMenuOnBoxed}
            topLeftIcon={iconBadge}
            topRightButtonProps={boxedFloatingButtonProps}
            clickHandler={clickHandler}
            contextMenu={contextMenu}
        />
    )
}

export default TemplateCard
