import {
    Autocomplete,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import MenuIcon from '@mui/icons-material/MoreHoriz'
import SaveIcon from '@mui/icons-material/Save'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

import { useEffect, useState } from 'react'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { toast } from 'react-toastify'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { applyFilter } from '../filter/NetworkFilter.helpers'
import { useParams } from 'react-router'

export default function NetworkPresetSettings() {
    const { presets, ...networkVizContext } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)
    const handleOpenSaveDialog = () => setSaveDialogOpen(true)
    const handleCloseSaveDialog = () => setSaveDialogOpen(false)

    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
    const handleOpenCreateDialog = () => setCreateDialogOpen(true)
    const handleCloseCreateDialog = () => setCreateDialogOpen(false)

    const [presetName, setPresetName] = useState<string>('')

    const deletePreset = (p: string) => dispatchContext({ type: 'PRESET_DELETE', payload: p })

    const savePreset = async () => {
        const toastId = toast.loading('Save in progress')
        const worker = new WebWorker('workers/network/zip-worker.js')
        try {
            const {
                keyFrames,
                nodeRenders,
                nodeAdjacentList,
                nodeDataSchema,
                nodeGroupBy,
                nodeInteractionConfig,
                nodeStyle,
                edgeArrowType,
                edgeDataSchema,
                edgeGroupBy,
                edgeRenders,
                edgeStyle,
                filters,
                networkShrink,
                layoutKind,
                layoutSettings,
                currentTimeFrame,
                timePanel,
                legend,
                analytics,
                nodeGroupInfo,
                dimOneWayEdges,
                highlightReciprocatedEdges,
                onlyShowReciprocatedEdges,
            } = networkVizContext
            const zip = (await worker.run({
                mode: 'zip',
                data: {
                    selectedNode: [],
                    searchResult: [],
                    searchResultIndex: -1,
                    nodeRenders,
                    nodeAdjacentList,
                    nodeDataSchema,
                    nodeGroupBy,
                    nodeInteractionConfig,
                    nodeStyle,
                    edgeArrowType,
                    edgeDataSchema,
                    edgeGroupBy,
                    edgeRenders,
                    edgeStyle,
                    filters,
                    layoutKind,
                    layoutSettings,
                    currentTimeFrame,
                    timePanel,
                    legend,
                    analytics,
                    networkShrink,
                    nodeGroupInfo,
                    keyFrames,
                    dimOneWayEdges,
                    highlightReciprocatedEdges,
                    onlyShowReciprocatedEdges,
                },
            })) as string
            dispatchContext({ type: 'PRESET_CREATE_OR_EDIT', payload: { key: presetName, state: zip } })
            handleCloseSaveDialog()
            toast.update(toastId, {
                render: 'The preset saved successfully.',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        } catch (e) {
            toast.update(toastId, {
                render: 'Failed to save preset.',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        }
    }

    const loadState = async (presetKey: string) => {
        const worker = new WebWorker('workers/network/zip-worker.js')
        const presetState = (await worker.run({
            mode: 'un-zip',
            data: presets[presetKey],
        })) as Partial<NetworkVizContextType>
        dispatchContext({
            type: 'PRESET_LOAD',
            payload: {
                ...presetState,
                keyFrames: presetState.keyFrames ?? [],
            },
        })
    }

    const saveAsTemplate = async (presetKey: string) => {
        const worker = new WebWorker('workers/network/zip-worker.js')
        const presetState = (await worker.run({
            mode: 'un-zip',
            data: presets[presetKey],
        })) as Partial<NetworkVizContextType>

        const template = {
            nodeGroupBy: presetState.nodeGroupBy,
            nodeStyle: presetState.nodeStyle,
            edgeGroupBy: presetState.edgeGroupBy,
            edgeArrowType: presetState.edgeArrowType,
            edgeStyle: presetState.edgeStyle,
            layoutKind: presetState.layoutKind,
            layoutSettings: presetState.layoutSettings,
            filters: presetState.filters,
            keyFrames: presetState.keyFrames,
        }

        let templates = JSON.parse(localStorage.getItem('presetTemplates') || '{}')

        templates[presetKey] = template

        localStorage.setItem('presetTemplates', JSON.stringify(templates))
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={500}>
                    Presets
                </Typography>
                <Stack direction="row">
                    <Tooltip title="From Template">
                        <IconButton onClick={handleOpenCreateDialog}>
                            <AutoAwesomeIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save">
                        <IconButton onClick={handleOpenSaveDialog}>
                            <SaveIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={1} minWidth={0}>
                {/* Preset items
                    ========================================= */}
                {Object.keys(presets).map((_preset) => (
                    <PresetItem
                        key={_preset}
                        preset={_preset}
                        saveAsTemplate={saveAsTemplate}
                        deletePreset={deletePreset}
                        loadState={loadState}
                    />
                ))}
            </Stack>

            {/* Dialog
                ========================================= */}
            {saveDialogOpen && (
                <StyledDialog open={saveDialogOpen} onClose={handleCloseSaveDialog} maxWidth="sm">
                    <Stack
                        gap={2}
                        sx={{
                            width: 440,
                            py: 2,
                            px: 3,
                        }}
                    >
                        {/* Title
				            ========================================= */}
                        <Typography fontSize={20} fontWeight={600} textAlign="center">
                            Save Preset
                        </Typography>

                        {/* Body
				            ========================================= */}
                        <Stack
                            gap={1}
                            sx={{
                                maxHeight: 400,
                                overflowY: 'auto',
                            }}
                            className="u-scrollbar"
                        >
                            <Typography>Enter a title and press the save button.</Typography>

                            {/* Title autocomplete
				                ========================================= */}
                            <Autocomplete
                                options={Object.keys(presets)}
                                value={presetName}
                                onInputChange={(evt, value) => {
                                    setPresetName(value || '')
                                }}
                                renderInput={(params) => <BaseFilledTextField label="Title" {...params} />}
                                freeSolo
                                sx={{
                                    marginBottom: 2,
                                }}
                            />

                            {/* Save button
				                ========================================= */}
                            <BaseButton
                                label="Save"
                                disabled={presetName.trim() == ''}
                                onClick={savePreset}
                                variant="contained"
                                sx={{
                                    py: 1,
                                }}
                            />
                        </Stack>
                    </Stack>
                </StyledDialog>
            )}

            {createDialogOpen && <PresetTemplateDialog open={createDialogOpen} onClose={handleCloseCreateDialog} />}
        </Stack>
    )
}

type PresetItemProps = {
    preset: string
    loadState: (preset: string) => void
    saveAsTemplate: (preset: string) => void
    deletePreset: (preset: string) => void
}
const PresetItem = ({ loadState, deletePreset, saveAsTemplate, preset }: PresetItemProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="stretch" gap={0.5} minWidth={0}>
            {/* Preset activation button
                            ========================================= */}
            <BaseButton
                onClick={(evt) => loadState(preset)}
                color="secondary"
                sx={{
                    flexBasis: 0,
                    flexGrow: 1,

                    borderRadius: 0,
                }}
            >
                <Typography
                    fontSize={14}
                    noWrap
                    sx={{
                        width: '100%',
                        textAlign: 'left',

                        color: 'common.text_1',
                    }}
                >
                    {preset}
                </Typography>
            </BaseButton>

            {/* Preset delete button
                            ========================================= */}
            <IconButton
                onClick={handleClick}
                sx={{
                    flexShrink: 0,
                }}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id={'preset-menu-' + preset}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => deletePreset(preset)}>Delete</MenuItem>
                <MenuItem
                    onClick={() => {
                        saveAsTemplate(preset)
                        handleClose()
                    }}
                >
                    Save as template
                </MenuItem>
            </Menu>
        </Stack>
    )
}

type PresetTemplateDialogProps = {
    open: boolean
    onClose: () => void
}

const PresetTemplateDialog = ({ open, onClose }: PresetTemplateDialogProps) => {
    const [templates, setTemplates] = useState<any>({})
    const [template, setTemplate] = useState('')
    const [title, setTitle] = useState('')

    const [selectedTemplate, setSelectedTemplate] = useState<Partial<NetworkVizContextType>>({})

    const networkContext = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const create = async () => {
        let newState = structuredClone(networkContext) as NetworkVizContextType

        // * Layout

        newState.layoutKind = selectedTemplate.layoutKind!
        newState.layoutSettings = selectedTemplate.layoutSettings!

        // * Filter

        const filterOutcome = await applyFilter(networkContext, selectedTemplate.filters || [], true)

        newState.analytics = filterOutcome.analytics
        newState.edgeRenders = filterOutcome.edgeRenders
        newState.nodeRenders = filterOutcome.nodeRenders
        newState.nodeAdjacentList = filterOutcome.nodeAdjacentList
        newState.filters = selectedTemplate.filters || []

        // * Analytics

        const options = {
            nodes: newState.nodeRenders,
            edges: newState.edgeRenders,
            allEdges: Object.values(newState.edges),
            allNodes: Object.values(newState.nodes),
            nodeInfo: newState.nodes,
            analyticsSettings: newState.analyticsSettings,
            mode: 'all',
        }

        const analyticsWorker = new WebWorker('workers/network/apply-advance-analytics.js')
        newState.analytics = (await analyticsWorker.run({
            mode: 'basic',
            options,
            analyticsUrl: process.env.REACT_APP_API_URL,
        })) as any

        // * Node Group By

        // set the default edge style
        newState.nodeStyle.default = selectedTemplate.nodeStyle!.default

        if (newState.nodeStyle.default.label.field) {
            if (newState.nodeDataSchema.fields[newState.nodeStyle.default.label.field] === undefined) {
                newState.nodeStyle.default.label.field = undefined
            }
        }

        if (newState.nodeStyle.default.colorScale.attribute) {
            if (newState.nodeStyle.default.colorScale.attribute.source === 'info')
                if (
                    newState.nodeDataSchema.fields[newState.nodeStyle.default.colorScale.attribute.field] === undefined
                ) {
                    newState.nodeStyle.default.colorScale.attribute.field = ''
                }
        }

        if (newState.nodeStyle.default.sizeScale.attribute) {
            if (newState.nodeStyle.default.sizeScale.attribute.source === 'info')
                if (
                    newState.nodeDataSchema.fields[newState.nodeStyle.default.sizeScale.attribute.field] === undefined
                ) {
                    newState.nodeStyle.default.sizeScale.attribute.field = ''
                }
        }

        const worker = new WebWorker<any>('workers/network/group-by-nodes.js')
        const nodeGroupByResponse = await worker.run({
            nodeRenders: newState.nodeRenders,
            edgeRenders: newState.edgeRenders,
            nodes: newState.nodes,
            groupBys: selectedTemplate.nodeGroupBy || [],
            nodeStyle: newState.nodeStyle,
            analytics: newState.analytics,
            dataSchema: newState.nodeDataSchema.fields,
        })

        newState.legend.nodes = nodeGroupByResponse.nodeLegend
        newState.nodeGroupBy = selectedTemplate.nodeGroupBy || []
        newState.nodeRenders = nodeGroupByResponse.nodeRenders
        newState.nodeStyle = nodeGroupByResponse.nodeStyle

        // * Edge Group by
        newState.edgeStyle.default = selectedTemplate.edgeStyle!.default
        newState.edgeGroupBy = selectedTemplate.edgeGroupBy!

        const edgeGroupByworker = new WebWorker<any>('workers/network/group-by-edges.js')
        const edgeGroupByResponse = await edgeGroupByworker.run({
            edgeRenders: newState.edgeRenders,
            edges: newState.edges,
            groupBy: selectedTemplate.edgeGroupBy,
            edgeStyle: newState.edgeStyle,
            analytics: newState.analytics,
            dataSchema: newState.edgeDataSchema.fields,
        })

        newState.legend.edges = edgeGroupByResponse.edgeLegend
        newState.edgeGroupBy = selectedTemplate.edgeGroupBy || null
        newState.edgeRenders = edgeGroupByResponse.edgeRenders
        newState.edgeStyle = edgeGroupByResponse.edgeStyle

        if (selectedTemplate.edgeStyle) {
            for (let key in selectedTemplate.edgeStyle) {
                if (newState.edgeStyle[key]) {
                    newState.edgeStyle[key] = selectedTemplate.edgeStyle[key]
                }
            }
        }

        const edgeStyleworker = new WebWorker('workers/network/change-edge-style-worker.js')

        newState.edgeRenders = (await edgeStyleworker.run({
            edges: newState.edges,
            edgeRenders: newState.edgeRenders,
            dataSchema: newState.edgeDataSchema.fields,
            edgeStyle: newState.edgeStyle,
        })) as any

        const zipWorker = new WebWorker('workers/network/zip-worker.js')

        newState.presets[template] = (await zipWorker.run({
            mode: 'zip',
            data: {
                selectedNode: [],
                searchResult: [],
                searchResultIndex: -1,
                nodeRenders: newState.nodeRenders,
                nodeAdjacentList: newState.nodeAdjacentList,
                nodeDataSchema: newState.nodeDataSchema,
                nodeGroupBy: newState.nodeGroupBy,
                nodeInteractionConfig: newState.nodeInteractionConfig,
                nodeStyle: newState.nodeStyle,
                edgeArrowType: newState.edgeArrowType,
                edgeDataSchema: newState.edgeDataSchema,
                edgeGroupBy: newState.edgeGroupBy,
                edgeRenders: newState.edgeRenders,
                edgeStyle: newState.edgeStyle,
                filters: newState.filters,
                layoutKind: newState.layoutKind,
                layoutSettings: newState.layoutSettings,
                currentTimeFrame: newState.currentTimeFrame,
                timePanel: newState.timePanel,
                legend: newState.legend,
                analytics: newState.analytics,
            },
        })) as string

        dispatchContext({
            type: 'PRESET_LOAD',
            payload: newState,
        })

        onClose()
    }

    void useEffect(() => {
        if (open) setTemplates(JSON.parse(localStorage.getItem('presetTemplates') || '{}'))
    }, [open])

    return (
        <StyledDialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
            <DialogTitle>Create Preset From Template</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <Stack direction="row" gap={1}>
                        <BaseSelectWithLabel
                            value={template}
                            onChange={(v) => {
                                setTemplate(v)
                                setSelectedTemplate(templates[v])
                            }}
                            label="Template"
                            options={Object.keys(templates)}
                            fullWidth
                        />
                        {/* <BaseFilledTextField
                            fullWidth
                            label="Preset Title"
                            defaultValue={title}
                            onBlur={(evt) => {
                                setTitle(evt.target.value)
                            }}
                        /> */}
                    </Stack>

                    <Stack direction="row" gap={1}>
                        <BaseSelectWithLabel
                            fullWidth
                            label="Node Group By"
                            options={networkContext.nodeDataSchema.groupByOptions}
                            value={selectedTemplate.nodeGroupBy || []}
                            selectProps={{
                                multiple: true,
                            }}
                            helperText={
                                selectedTemplate.nodeGroupBy?.length === 0
                                    ? 'The preset does not set the node group by value'
                                    : undefined
                            }
                            onChange={(v) => {
                                setSelectedTemplate((pv) => ({
                                    ...pv,
                                    nodeGroupBy: v,
                                }))
                            }}
                        />

                        <BaseSelectWithLabel
                            fullWidth
                            label="Edge Group By"
                            options={networkContext.edgeDataSchema.groupByOptions}
                            value={selectedTemplate.edgeGroupBy}
                            helperText={
                                selectedTemplate.edgeGroupBy === ''
                                    ? 'The preset does not set the edge group by value'
                                    : networkContext.edgeDataSchema.groupByOptions.includes(
                                          selectedTemplate.edgeGroupBy || ''
                                      )
                                    ? undefined
                                    : `The selected edge group by option (${selectedTemplate.edgeGroupBy}) not avialble in this network`
                            }
                            onChange={(v) => {
                                setSelectedTemplate((pv) => ({
                                    ...pv,
                                    edgeGroupBy: v,
                                }))
                            }}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                {/* <BaseButton color="secondary" onClick={() => localStorage.setItem('presetTemplates', '{}')}>
                    Clear
                </BaseButton> */}
                <BaseButton onClick={onClose}>Close</BaseButton>
                <BaseButton disabled={template === ''} color="primary" onClick={create}>
                    Create
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
