//* ======= Libraries
import React, { useContext, useEffect } from 'react'
//* ======= Components and features
import Container from '@mui/material/Container'
import PanelRoutes from 'routes/panelRoutes'
//* ======= Custom logic
import { RootContext } from '../contexts/RootContext'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import ComponentShowcase from './ComponentShowcase'
import GeneralDashboard from './general-dashboard/GeneralDashboard.route'
import ProjectRoutes from 'routes/projectRoutes'
import SurveyRoutes from 'routes/surveyRoutes'
import Notifications from './notifications/Notifications.route'
import SurveyDashboard from './survey/SurveyDashboard.route'
import SurveyDesignerPage from './survey/SurveyDesigner.rout'
import SurveyNotifications from './survey/SurveyNotifications.route'
import SurveyParticipants from './survey/SurveyParticipants.route'
import SurveyLogs from './survey/SurveyLogs.route'
import SurveyResults from './survey/SurveyResults.route'
import SurveyAnalytics from './survey/SurveyAnalytics.route'
import SurveyRules from './survey/SurveyRules.route'
import ProjectDashboard from './project-dashboard/ProjectDashboard.route'
import WidgetDashboard from './project-dashboard/WidgetDashboard.route'
import NodeDashboard from './nodes/NodeDashboard.route'
import NodeDetails from './nodes/NodeDetails.route'
import ReportDashboard from './project-dashboard/ReportDashboard.route'
import CreateAccount from './sign-in/CreateAccount.route'
import ForgotPassword from './sign-in/ForgotPassword.route'
import ResetPassword from './sign-in/ResetPassword.route'
import SignIn from './sign-in/SignIn.route'
import Settings from './settings/Settings.route'
import SurveyInstanceRoutes from 'routes/SurveyInstanceRoutes'
import GraphContainer from 'features/GraphStudio/GraphContainer/GraphContainer'
import Report from 'pages/reports/Report.route'
import SurveyLogin from './survey/SurveyLogin.route'
import SurveyPage from './survey/SurveyPage.route'
import SurveySettings from './survey/SurveySettings.route'
import ProjectSettings from './project-dashboard/ProjectSettings.route'
import ProjectUsers from './project-dashboard/ProjectUsers.route'
import CompleteProfile from './sign-in/CompleteProfile.route'
import PageNotFound from './page-not-found/PageNotFound.route'
import VerifyEmail from './sign-in/VerifyEmail.route'
import SurveyReport from './survey/SurveyReport.route'
import NetworkVizDesignerPage from './network-viz/NetworkVizDesigner.route'
import SurveyReportRoutes from 'routes/SurveyReportRoutes'
import ReportViewPage from './reports/ReportView.route'
import PermissionEditor from './admin/PermissionEditor.route'
import { ProjectContext } from 'contexts/ProjectContext'
import { PERMISSIONS } from 'helpers/constants'
import DashboardSkeleton from './project-dashboard/DashboardSkeleton'
import PageUnauthorized from './page-unauthorized/PageUnauthorized.route'
import IdentityProviders from './admin/IdentityProviders.route'
import UserManagment from './admin/UserManagment.route'
import AllocationDesigner from './allocation/Allocation.route'
import AllocationDashboard from './allocation/AllocationDashboard.route'
import NodeActionDashboard from './actions/NodeActionDashboard.route'
import InterventionProviders from './interventions/Interventions.route'
import ReportViewDemo from 'features/report-designer/view/ReportViewSample'
//* ======= Assets and styles

//TODO:
// 1. add React.lazy with Suspense to load components on demand

//ToDo: Replace this with a better solution
function AdminWrapper() {
    const { user } = useContext(RootContext)

    if (user == null) return null
    if (user?.roles?.includes('superadmin')) {
        return <PanelRoutes />
    }

    // Redirect to not-found page or a forbidden page as needed
    return <Navigate to="/not-found" />
}

type ProjectPermissionGuardType = {
    requiredPermission: string | string[]
    children: React.ReactNode
}

function ProjectPermissionGuard({ requiredPermission, children }: ProjectPermissionGuardType) {
    const { hasPermission, project, role } = useContext(ProjectContext)

    if (role && project) {
        if (hasPermission(requiredPermission)) return <>{children}</>
        else return <Navigate to={`/project/${project.primaryKey}/unauthorized`} />
    } else return <DashboardSkeleton />
}

function PermissionBasedNavigator() {
    const { role } = useContext(ProjectContext)
    const navigator = useNavigate()

    useEffect(() => {
        if (role) {
            if (role.projectRolePermissions.includes(PERMISSIONS.survey.ReadSurveysList)) {
                return navigator('surveys')
            } else if (role.projectRolePermissions.includes(PERMISSIONS.node.ReadNodeList)) {
                return navigator('nodes')
            } else if (role.projectRolePermissions.includes(PERMISSIONS.widget.ReadWidgetList)) {
                return navigator('widgets')
            } else if (role.projectRolePermissions.includes(PERMISSIONS.report.ReadReportList)) {
                return navigator('reports')
            } else {
                return navigator('/')
            }
        }
    }, [role, navigator])

    return <DashboardSkeleton />
}

type RouterConfigType = {
    path: string
    component: React.ReactNode
    permission: string | string[]
}

export const projectRouterConfig: RouterConfigType[] = [
    {
        path: 'surveys',
        component: <ProjectDashboard />,
        permission: PERMISSIONS.survey.ReadSurveysList,
    },
    {
        path: 'networks',
        component: <WidgetDashboard />,
        permission: PERMISSIONS.widget.ReadWidgetList,
    },
    {
        path: 'networks/:wid',
        component: <NetworkVizDesignerPage />,
        permission: PERMISSIONS.widget.ReadSpecificWidget,
    },
    {
        path: 'nodes',
        component: <NodeDashboard />,
        permission: PERMISSIONS.node.ReadNodeList,
    },
    {
        path: 'nodes/:nid',
        component: <NodeDetails />,
        permission: PERMISSIONS.node.ReadSpecificNode,
    },
    {
        path: 'reports',
        component: <ReportDashboard />,
        permission: PERMISSIONS.report.ReadReportList,
    },
    {
        path: 'reports/:rid',
        component: <Report />,
        permission: PERMISSIONS.report.ReadSpecificReport,
    },
    {
        path: 'settings',
        component: <ProjectSettings />,
        permission: PERMISSIONS.project.UpdateProjectSettings,
    },
    {
        path: 'users',
        component: <ProjectUsers />,
        permission: PERMISSIONS.project.ManageProjectUsers,
    },
    {
        path: 'allocations',
        component: <AllocationDashboard />,
        permission: PERMISSIONS.allocation.ManageGroupAllocation,
    },
    {
        path: 'allocations/:aid',
        component: <AllocationDesigner />,
        permission: PERMISSIONS.allocation.ManageGroupAllocation,
    },
    {
        path: 'actions',
        component: <NodeActionDashboard />,
        permission: PERMISSIONS.nodeAction.ManageNodeAction,
    },
]

export const surveyRouterConfig: RouterConfigType[] = [
    {
        path: '',
        component: <SurveyDashboard />,
        permission: PERMISSIONS.survey.ReadSpecificSurvey,
    },
    {
        path: 'designer',
        component: <SurveyDesignerPage />,
        permission: PERMISSIONS.survey.ReadSurveyQuestions,
    },
    {
        path: 'notifications',
        component: <SurveyNotifications />,
        permission: PERMISSIONS.survey.ManageSurveyNotifications,
    },
    {
        path: 'participants',
        component: <SurveyParticipants />,
        permission: PERMISSIONS.survey.ReadSurveyParticipants,
    },
    {
        path: 'logs',
        component: <SurveyLogs />,
        permission: PERMISSIONS.survey.ReadSurveyAuditParticipants,
    },
    {
        path: 'results',
        component: <SurveyResults />,
        permission: PERMISSIONS.survey.ReadSurveyParticipants,
    },
    {
        path: 'analytics',
        component: <SurveyAnalytics />,
        permission: PERMISSIONS.survey.ReadSurveyAnalytics,
    },
    {
        path: 'rules',
        component: <SurveyRules />,
        permission: PERMISSIONS.survey.ReadSurveyRules,
    },
    {
        path: 'report',
        component: <SurveyReport />,
        permission: PERMISSIONS.survey.ReadSurveyReport,
    },
    {
        path: 'settings',
        component: <SurveySettings />,
        permission: PERMISSIONS.survey.ReadSurveySettings,
    },
    {
        path: 'open',
        component: <SurveyPage isOpenParticipation={true} />,
        permission: PERMISSIONS.survey.ChangeSurveyInstanceStatus,
    },
]

export const PUBLIC_PATHS: RegExp[] = [
    /^\/login$/,
    /^\/create-account$/,
    /^\/forgot-password$/,
    /^\/reset-password$/,
    /^\/unauthorized$/,
    /^\/survey\/.*$/,
    /^\/report\/.*$/,
]

export const ProjectBasePath = '/project/:pid'
export const SurveyBasePath = 'surveys/:sid'

export default function MasterLayout() {
    return (
        <Container
            maxWidth="xxl"
            disableGutters
            sx={{
                minHeight: '100vh',
                height: '100%',

                boxShadow: 3,
            }}
        >
            <Routes>
                {process.env.NODE_ENV === 'development' && <Route path="/showcase" element={<ComponentShowcase />} />}

                <Route path="/admin" element={<AdminWrapper />}>
                    <Route path="permissions" element={<PermissionEditor />} />
                    <Route path="idps" element={<IdentityProviders />} />
                    <Route path="users" element={<UserManagment />} />
                    <Route path="interventions" element={<InterventionProviders />} />
                </Route>

                <Route path="/survey" element={<SurveyInstanceRoutes />}>
                    <Route path=":sid" element={<SurveyLogin />} />
                    <Route path="si/:token" element={<SurveyPage />} />
                    <Route path="public/:token" element={<SurveyPage isPublic={true} isOpenParticipation={true} />} />
                </Route>

                <Route path="/report" element={<SurveyReportRoutes />}>
                    <Route path=":token" element={<ReportViewPage />} />
                    <Route path="demo/:token" element={<ReportViewDemo />} />
                </Route>

                <Route path="/" element={<PanelRoutes />}>
                    <Route path="/project/:pid" element={<ProjectRoutes />}>
                        <Route path="" element={<PermissionBasedNavigator />} />

                        {projectRouterConfig.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <ProjectPermissionGuard requiredPermission={route.permission}>
                                        {route.component}
                                    </ProjectPermissionGuard>
                                }
                            />
                        ))}

                        <Route path={SurveyBasePath} element={<SurveyRoutes />}>
                            {surveyRouterConfig.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <ProjectPermissionGuard requiredPermission={route.permission}>
                                            {route.component}
                                        </ProjectPermissionGuard>
                                    }
                                />
                            ))}
                            <Route path="unauthorized" element={<PageUnauthorized />} />
                        </Route>

                        <Route path="unauthorized" element={<PageUnauthorized />} />
                    </Route>

                    <Route path="/complete-profile" element={<CompleteProfile />} />
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/account" element={<Settings />} />

                    <Route path="/notifications" element={<Notifications />} />

                    <Route path="dashboard" element={<Navigate to="/" replace />} />
                    <Route path="/" element={<GeneralDashboard />} />
                </Route>

                <Route path="/create-account" element={<CreateAccount />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/login" element={<SignIn />} />

                <Route path="/unauthorized" element={<PageUnauthorized />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Container>
    )
}
