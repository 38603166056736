import { useEffect, useMemo, useState } from 'react'
import { throttle } from 'lodash'
import { Question } from 'survey-core'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import * as Survey from 'survey-core'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import { GetAdressService, GetReverseGeocode } from 'services/AddressApi'

interface CustomGeoProps {
    question: Question & { placeholder: string }
    onChange: (newVal: any) => void
}

export default function AddressQuestion({ question, onChange }: CustomGeoProps) {
    const [value, setValue] = useState<string | null>('')
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState<readonly string[]>([])

    useEffect(() => {
        setValue(question.value || '')
    }, [])

    //set the question value whenever value is changed
    const updateValue = (value: any) => {
        setValue(value)
        onChange(value)
    }

    const fetch = useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: readonly string[]) => void) => {
                GetAdressService(request.input).then((res) => res.success && callback(res.data))
            }, 200),
        []
    )

    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log('Latitude is :')
            console.log('Longitude is :')
            GetReverseGeocode(position.coords.latitude, position.coords.longitude).then(
                (res) => res.success && updateValue(res.data)
            )
        })
    }

    useEffect(() => {
        let active = true

        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }

        fetch({ input: inputValue }, (results?: readonly string[]) => {
            if (active) {
                let newOptions: readonly string[] = []

                if (value) {
                    newOptions = [value]
                }

                if (results) {
                    newOptions = [...newOptions, ...results]
                }

                setOptions(newOptions)
            }
        })

        return () => {
            active = false
        }
    }, [value, inputValue, fetch])

    return (
        <Stack direction="row" width="100%">
            <Autocomplete
                id="address_question"
                getOptionLabel={(option) => option}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                fullWidth
                includeInputInList
                filterSelectedOptions
                value={value}
                onChange={(event: any, newValue: string | null) => {
                    setOptions(newValue ? [newValue, ...options] : options)
                    updateValue(newValue)
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField {...params} label={question?.placeholder || 'Search Address'} fullWidth />
                )}
                renderOption={(props, option) => {
                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Box component={LocationOnIcon} sx={{ color: 'secondary.main', mr: 2 }} />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" color="secondary.main">
                                        {option}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    )
                }}
            />
            <Tooltip title="Current Location">
                <IconButton onClick={getCurrentLocation}>
                    <MyLocationIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    )
}

export function registerAddressQuestion() {
    Survey.SvgRegistry.registerIconFromSvg(
        'location',
        '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 1v3h3v2h-3v3h-2V6h-3V4h3V1h2zm-8 12c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm2-9.75V7h3v3h2.92c.05.39.08.79.08 1.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 6.22 7.8 3 12 3c.68 0 1.35.08 2 .25z"/></svg>'
    )

    var addressQuestionWidget = {
        name: 'address_question',
        title: 'Address Search',
        iconName: 'icon-location',
        widgetIsLoaded: function () {
            return true //We do not have external scripts
        },
        isFit: function (question: any) {
            return question.getType() == 'address_question'
        },
        init() {
            Survey.Serializer.addClass('address_question', [], undefined, 'empty')
            Survey.Serializer.addProperty('address_question', {
                name: 'placeholder:string',
                default: 'Search Address',
                category: 'general',
            })
        },
        render: function (questionBase: any) {
            function onChange(newVal: any) {
                questionBase.value = newVal || null
            }
            return <AddressQuestion onChange={onChange} question={questionBase} />
        },
    }
    Survey.CustomWidgetCollection.Instance.add(addressQuestionWidget, 'customtype')
}
