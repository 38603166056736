import React, { ReactElement, ReactNode, useEffect } from 'react'
import BaseButton from 'components/base/BaseButton'
import Box from '@mui/material/Box'
import { Fade, Popper } from '@mui/material'

export interface VerticalActionItemProps {
    icon: ReactNode
    id: string
    openWidget: string | null
    content: ReactNode
    toggleOpen: (key: any) => void
    disable?: boolean
    title: string
}

function ReportNetworkInfoWrapperComponent({
    icon,
    content,
    id: key,
    toggleOpen,
    openWidget,
    title,
}: VerticalActionItemProps) {
    const [open, setOpen] = React.useState(openWidget === key)

    useEffect(() => {
        setOpen(openWidget === key)
    }, [openWidget, key])

    const anchorEl = React.useRef<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        toggleOpen(key)
    }

    const canBeOpen = open && Boolean(anchorEl.current)
    const id = canBeOpen ? title + '-transition-popper' : undefined

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <BaseButton
                onClick={handleClick}
                ref={anchorEl}
                disableElevation
                sx={(theme) => ({
                    height: 48,
                    minWidth: 0,
                    width: 48,
                    borderRadius: 0,
                    // @Theme conditional
                    backgroundColor: open
                        ? theme.palette.primary.main
                        : theme.palette.mode === 'light'
                        ? theme.palette.common.bg_3
                        : theme.palette.common.bg_4,
                    color: open ? theme.palette.common.fill_4 : theme.palette.primary.main,

                    '&:hover': {
                        // @Theme conditional
                        backgroundColor: open
                            ? theme.palette.primary.main
                            : theme.palette.mode === 'light'
                            ? theme.palette.common.bg_3
                            : theme.palette.common.bg_4,
                    },
                })}
            >
                {icon}
            </BaseButton>

            <Popper
                id={id}
                open={open}
                placement="left-start"
                anchorEl={anchorEl.current}
                transition
                // disablePortal
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box
                            sx={(theme) => ({
                                overflow: 'hidden',

                                borderRadius: '4px',
                                border: `1px solid ${theme.palette.common.border_2}`,
                                filter: ' alpha(opacity = 95)',
                                mozOpacity: 0.95,
                                /* Mozilla */
                                opacity: 0.95,
                                // @Theme conditional
                                backgroundColor:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_1
                                        : theme.palette.common.bg_3,
                            })}
                        >
                            {content &&
                                React.cloneElement(content as ReactElement, {
                                    onClose: () => setOpen(false),
                                })}
                        </Box>
                    </Fade>
                )}
            </Popper>
        </Box>
    )
}

export default ReportNetworkInfoWrapperComponent
