//* ======= Libraries
import React, { useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Stack, Box, Typography, IconButton, Tooltip, Menu, MenuItem, ClickAwayListener } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import { ReportSlideType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import CheckIcon from '@mui/icons-material/Check'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ReportSlideThumbnail from '../slide/ReportSlideThumbnail'

type CustomDragItemType = {
    dragId: ReportSlideType['id']
}

export const ReportSlidesListItemDragTypeValue = 'SLIDE'

type Props = {
    id: ReportSlideType['id']
    title: ReportSlideType['title']
    thumbnail: ReportSlideType['thumbnail']
    isActive: boolean
    onSelect: () => void
    hasRemoveButton?: boolean
    onRemove?: () => void
    getPositionIndex: (id: ReportSlideType['id']) => number
    onMove: (id: ReportSlideType['id'], newIndex: number) => void
    onMoveEnd?: (id: ReportSlideType['id'], newIndex: number) => void
    onDuplicate: () => void
    onExport: () => void
    slide: ReportSlideType
}

function ReportSlidesListItem({
    id,
    title,
    thumbnail,
    isActive,
    onSelect,
    hasRemoveButton = false,
    onRemove = () => undefined,
    getPositionIndex,
    onMove,
    onMoveEnd,
    onDuplicate,
    onExport,
    slide,
}: Props) {
    const [isRemoveView, setIsRemoveView] = useState(false)

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number
        mouseY: number
    } | null>(null)

    const [{ isDragging }, dragSource] = useDrag<CustomDragItemType, unknown, { isDragging: boolean }>(
        {
            type: ReportSlidesListItemDragTypeValue,
            item: () => ({
                dragId: id,
            }),
            canDrag: isRemoveView === false,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        },
        [id, onMove, isRemoveView]
    )

    const [, dropTarget] = useDrop<CustomDragItemType>(
        {
            accept: ReportSlidesListItemDragTypeValue,
            hover: (item, monitor) => {
                if (item.dragId !== id) {
                    const newIndex = getPositionIndex(id)

                    onMove(item.dragId, newIndex)
                }
            },
            drop: (item, monitor) => {
                if (onMoveEnd !== undefined) {
                    const newIndex = getPositionIndex(id)

                    onMoveEnd(item.dragId, newIndex)
                }
            },
        },
        [getPositionIndex, onMove, onMoveEnd, isRemoveView]
    )

    // * Context Menue
    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        )
    }

    const handleClose = () => {
        setContextMenu(null)
    }

    const handleDuplicate = () => {
        onDuplicate()
        handleClose()
    }

    const handleExport = () => {
        onExport()
        handleClose()
    }

    return (
        <Box
            ref={(node: HTMLDivElement) => dragSource(dropTarget(node))}
            role="button"
            onClick={(evt) => (evt.type !== 'click' || isRemoveView ? undefined : onSelect())}
            onContextMenu={(evt) => (isRemoveView ? undefined : handleContextMenu(evt))}
            sx={(theme) => ({
                position: 'relative',

                display: 'flex',
                flexDirection: 'column',

                width: '100%',
                minHeight: 0,
                height: 100,
                overflow: 'hidden',

                cursor: isRemoveView ? 'auto' : 'pointer',
                opacity: isDragging ? 0.15 : 1,
                boxShadow: isActive ? '0px 4px 4px rgba(33, 123, 191, 0.25)' : undefined,
                borderRadius: '10px',
                border: '1px solid transparent',
                borderColor: isActive ? theme.palette.primary.main : 'transparent',
                // @Theme conditional
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,

                '&:hover .MuiIconButton-root': {
                    opacity: 1,
                },
            })}
            className="slide-navigation"
        >
            {isRemoveView ? (
                /*  Remove view
                    ========================================= */
                <ClickAwayListener onClickAway={(evt) => setIsRemoveView(false)}>
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            height: '100%',
                            overflow: 'hidden',
                        })}
                    >
                        <Typography fontSize={12} fontWeight={500} color="warning">
                            Remove?
                        </Typography>

                        <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
                            <IconButton
                                onClick={(evt) => {
                                    // Needed so as to not trigger "other ReportSlidesListItem"s ClickAwayListener callback.
                                    evt.stopPropagation()

                                    setIsRemoveView(false)
                                }}
                                size="small"
                                color="warning"
                            >
                                <CloseRoundedIcon
                                    sx={{
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                            </IconButton>

                            <IconButton
                                onClick={(evt) => {
                                    // Needed so as to not trigger "other ReportSlidesListItem"s ClickAwayListener callback.
                                    evt.stopPropagation()

                                    onRemove()
                                }}
                                size="small"
                                color="primary"
                            >
                                <CheckIcon
                                    sx={{
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                            </IconButton>
                        </Stack>
                    </Stack>
                </ClickAwayListener>
            ) : (
                /*  Main view
                    ========================================= */
                <>
                    {/* Remove button
                        ========================================= */}
                    {hasRemoveButton && (
                        <IconButton
                            onClick={(evt) => {
                                evt.stopPropagation()

                                setIsRemoveView(true)
                            }}
                            sx={(theme) => ({
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                zIndex: 1,

                                opacity: 0,
                            })}
                        >
                            <CloseRoundedIcon
                                sx={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        </IconButton>
                    )}

                    {/* Image
                        ========================================= */}

                    {thumbnail && (
                        <Box
                            sx={{
                                flexGrow: 1,

                                minHeight: 0,
                            }}
                        >
                            <img
                                src={thumbnail}
                                alt={`Thumbnail of ${title}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    )}

                    {/* Slide number
                        ========================================= */}
                    <Tooltip
                        title={title.length > 14 ? title : ''}
                        enterDelay={750}
                        arrow
                        placement="bottom"
                        followCursor
                        PopperProps={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 16],
                                    },
                                },
                            ],
                        }}
                    >
                        <Typography
                            fontSize={14}
                            textAlign="center"
                            noWrap
                            sx={(theme) => ({
                                flexShrink: 0,
                                marginTop: 'auto',

                                width: '100%',
                                paddingX: theme.spacing(1),
                                userSelect: 'none',
                            })}
                        >
                            {title || getPositionIndex(id) + 1}
                        </Typography>
                    </Tooltip>
                </>
            )}

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                }
            >
                <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
                <MenuItem onClick={handleExport}>Export</MenuItem>
            </Menu>
        </Box>
    )
}

export default ReportSlidesListItem
