import { GridColType } from '@mui/x-data-grid-pro'
import { FontPickerValueType } from 'components/widgets/FontPicker'
import {
    ReportDataSourceAttributeType,
    ReportDataAggregationMethodType,
    ReportDataSortOrderType,
    ReportDataSourceRowType,
} from 'features/report-designer/types/reportDesigner.types'

/*
 * General
 * =========================================
 */

export type TableParsedDataColumn = {
    fieldType: 'primary' | 'secondary' | 'avatar' | 'list' | 'dynamic'
    field: string
    type?: GridColType
    title?: string
    description?: string
}

export type TableBadgeType = {
    title: string
    color: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'
    icon?:
        | 'up'
        | 'down'
        | 'double-up'
        | 'double-down'
        | 'balance'
        | 'top'
        | 'bottom'
        | 'warning'
        | 'center'
        | 'info'
        | 'error'
        | 'success'
        | 'check'
        | 'double-check'
}

export type TableParsedDataRow = {
    // This ID is the main value of "selected row ID" that gets passed around and
    // is calculated differently based on the data definitions.
    _internal_id: string
    // if the compare with field is defined, this property will store the value of group that the row belongs to
    _compare_group?: ReportDataSourceRowType[any]
    cells: Record<
        string,
        {
            value: string | number | any[]
            hideLabel?: boolean
            mappedValue?: string
            color?: string
            badges?: TableBadgeType[]
        }
    >
    // Required field
    id: string | number
}

/*
 * Configs
 * =========================================
 */

export type TableConfigViewMode = 'grid' | 'card' | 'chip'

type TableConfigBase = {
    hasTitle: boolean
    emptyDataMessage: string
    fontStyles: {
        fontFamily: FontPickerValueType['fontFamily']
        fontSize: number
        fontWeight: 'normal' | 'bold'
    }
}

export type TableConfig = TableConfigGridView | TableConfigCardView | TableConfigChipView

/*
 * Config - Grid view
 * =========================================
 */

export type TableConfigGridView = TableConfigBase & {
    viewMode: Extract<TableConfigViewMode, 'grid'>
    hasSearch: boolean
    hasHeader: boolean
    columnsWidth: Record<string, number>
}

export const TableConfigGridViewDefaultValues: TableConfigGridView = {
    viewMode: 'grid',
    hasSearch: false,
    hasHeader: true,
    hasTitle: false,
    emptyDataMessage: 'No Data',
    columnsWidth: {},
    fontStyles: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 'normal',
    },
}

/*
 * Config - Card view
 * =========================================
 */

export type TableConfigCardView = TableConfigBase & {
    viewMode: Extract<TableConfigViewMode, 'card'>
    hasHeader: boolean
    canChangeRows: boolean
    hasSearch: boolean
    isPrimaryLabelVisible: boolean
    headerStyle: {
        fontFamily: FontPickerValueType['fontFamily']
        fontSize: number
        fontWeight: 'normal' | 'bold'
        overflow?: 'none' | 'truncate' | 'wrap'
        color: string | undefined
    }
}

export const TableConfigCardViewDefaultValues: TableConfigCardView = {
    viewMode: 'card',
    canChangeRows: true,
    hasHeader: true,
    hasSearch: true,
    isPrimaryLabelVisible: false,
    headerStyle: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 'bold',
        overflow: 'truncate',
        color: undefined,
    },
    hasTitle: false,
    emptyDataMessage: 'No Data',
    fontStyles: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 'normal',
    },
}

/*
 * Config - Chip view
 * =========================================
 */

export type TableConfigChipView = TableConfigBase & {
    viewMode: Extract<TableConfigViewMode, 'chip'>
    hasSearch: boolean
    defaultChipColor: string
    selectedChipColor: string
    gap: number
}

export const TableConfigChipViewDefaultValues: TableConfigChipView = {
    viewMode: 'chip',
    emptyDataMessage: 'No Data',
    hasTitle: false,
    defaultChipColor: '#F4F3F3',
    selectedChipColor: '#217BBF',
    hasSearch: false,
    fontStyles: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 'normal',
    },
    gap: 0.5,
}

/*
 * Data definitions
 * =========================================
 */

export type TableDefinitionMode = 'each-row' | 'top-k' | 'aggregation' | 'ties' | 'graph'

export type TableDefinitionAdvancedNumericType = {
    dataType: 'number'
    // Indicates the range of values that the widget can display. The range is defined by a minimum and maximum value.
    range: {
        min: number
        max: number
    }
    //  Indicates the polarity or direction of the value, whether positive or negative.
    valuePolarity: 'positive' | 'negative'
    // let the user to map values into multiple bins and assign a color to each bin
    valueMapping: {
        isEnabled: boolean
        bins: {
            id: string
            upperBound: number | null
            value: string | null
            color: string
        }[]
    }
    rankingSettings: {
        isEnabled: boolean
        mode: 'top' | 'bottom' | 'both'
        limit: number
    }
}

export type TableDefinitionAdvancedStringType = {
    dataType: 'string'
    valueMapping: {
        isEnabled: boolean
        categories: {
            id: string
            key: string
            value: string
            color: string
        }[]
    }
}

export type TableDefinitionAdvancedType = TableDefinitionAdvancedNumericType | TableDefinitionAdvancedStringType

export type TableDefinitionColumn = {
    id: string
    field: ReportDataSourceAttributeType | null
    title?: string
    description?: string
    aggregation?: ReportDataAggregationMethodType
    decimalPrecision?: number
    advanced: TableDefinitionAdvancedType
}

type TableDefinitionBase = {
    decimalPrecision: number
}

export type TableDefinition =
    | TableDefinitionModeEachRow
    | TableDefinitionModeTopK
    | TableDefinitionModeAggregation
    | TableDefinitionModeTies
    | TableDefinitionModeGraph

/*
 * Definition - Each row
 * =========================================
 */

export type TableDefinitionModeEachRow = TableDefinitionBase & {
    mode: Extract<TableDefinitionMode, 'each-row'>
    idField: ReportDataSourceAttributeType | null
    sortField: ReportDataSourceAttributeType | null
    compareWith?:
        | {
              type: 'basic'
              field: string
          }
        | {
              type: 'analytic'
              relationship: string
              field: string
          }
        | {
              type: 'panel'
              field: string
          }
        | null
    sortOrder: ReportDataSortOrderType
    header: {
        field: ReportDataSourceAttributeType | null
        secondaryField: ReportDataSourceAttributeType | null
        avatar: ReportDataSourceAttributeType | null
    }
    columns: TableDefinitionColumn[]
}

export const TableDefinitionEachRowDefaultValues: TableDefinitionModeEachRow = {
    mode: 'each-row',
    idField: null,
    sortField: null,
    sortOrder: 'asc',
    header: {
        field: null,
        secondaryField: null,
        avatar: null,
    },
    columns: [],
    // Base
    decimalPrecision: 2,
}

/*
 * Definition - Top K
 * =========================================
 */

export type TableDefinitionModeTopK = TableDefinitionBase & {
    mode: Extract<TableDefinitionMode, 'top-k'>
    idField: ReportDataSourceAttributeType | null
    value: {
        field: ReportDataSourceAttributeType | null
        title?: string
        isVisible: boolean
    }
    label: {
        field: ReportDataSourceAttributeType | null
        title?: string
    }
    rank: {
        title?: string
        isVisible: boolean
    }
    limit: number
    sortOrder: ReportDataSortOrderType
}

export const TableDefinitionTopKDefaultValues: TableDefinitionModeTopK = {
    mode: 'top-k',
    idField: null,
    value: {
        field: null,
        isVisible: true,
    },
    label: {
        field: null,
    },
    rank: {
        title: 'Rank',
        isVisible: true,
    },
    limit: 10,
    sortOrder: 'desc',
    // Base
    decimalPrecision: 2,
}

/*
 * Definition - Aggregation
 * =========================================
 */

export type TableDefinitionModeAggregation = TableDefinitionBase & {
    mode: Extract<TableDefinitionMode, 'aggregation'>
    groupField: ReportDataSourceAttributeType | null
    columns: TableDefinitionColumn[]
}

export const TableDefinitionAggregationDefaultValues: TableDefinitionModeAggregation = {
    mode: 'aggregation',
    groupField: null,
    columns: [],
    // Base
    decimalPrecision: 2,
}

/*
 * Definition - Ties
 * =========================================
 */

export type TableDefinitionModeTiesRelation = {
    name: string
    nodesBackgroundColor: string
    label: string
    isEnabled: boolean
}

export type TableDefinitionModeTies = TableDefinitionBase & {
    mode: Extract<TableDefinitionMode, 'ties'>
    edgeInclusivity: 'source' | 'target' | 'reciprocated' | 'all'
    nodesLabelField: ReportDataSourceAttributeType | null
    relations: TableDefinitionModeTiesRelation[]
    excludeEmptyNodes?: boolean
    bidirectionalFiltering?: boolean
    hideHeaders?: boolean
}

export const TableDefinitionTiesDefaultValues: TableDefinitionModeTies = {
    mode: 'ties',
    edgeInclusivity: 'all',
    nodesLabelField: null,
    relations: [],
    // Base
    decimalPrecision: 2,
}

/*
 * Definition - Graph
 * =========================================
 */

export type TableDefinitionModeGraph = TableDefinitionBase & {
    mode: Extract<TableDefinitionMode, 'graph'>
    columns: TableDefinitionColumn[]
}

export const TableDefinitionModeGraphDefaultValues: TableDefinitionModeGraph = {
    mode: 'graph',
    decimalPrecision: 2,
    columns: [],
}
