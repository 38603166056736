import { Box, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material'
import PageCard from 'components/layouts/PageCard'
import React from 'react'

type Props = {}

function DashboardSkeleton(props: Props) {
    const theme = useTheme()
    const isViewportSmall = useMediaQuery(theme.breakpoints.down('xl'))

    return (
        <PageCard>
            <PageCard.Side>
                <Skeleton
                    variant="rectangular"
                    height="100%"
                    width={isViewportSmall ? '13rem' : '18.5rem'}
                    animation="wave"
                    sx={{ backgroundColor: theme.palette.common.bg_1 }}
                />
            </PageCard.Side>

            <PageCard.Body>
                <Skeleton
                    variant="rectangular"
                    height="100%"
                    animation="wave"
                    sx={{ backgroundColor: theme.palette.common.bg_1 }}
                />
            </PageCard.Body>
        </PageCard>
    )
}

export default DashboardSkeleton
