//* ======= Libraries
import React, { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import NodeConflictResolutionDialog from 'features/nodes/NodeConflictResolutionDialog'
import AddNewNodeDialog from 'features/nodes/AddNewNodeDialog'
import DeleteNodeDialog from 'features/nodes/DeleteNodeDialog'
//* ======= Custom logic
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import PublishIcon from '@mui/icons-material/Publish'
import UpdateIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { ProjectContext } from 'contexts/ProjectContext'
import ProjectDrawerNavigation from 'pages/project-dashboard/Navigation'
import { useNavigate, useParams } from 'react-router-dom'
import { DeleteNodesService, GetNodeOtherFiledsService, GetNodesService } from 'services/NodeService'
import StyledDataGrid, { GenreicAPIDataType } from 'components/data-grid/StyledDataGridWithApi'
import { GridColDef, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import UploadNodeDialog from 'features/upload-node-dialog/UploadNodeDialog'
import UpdateNodeDialog from 'features/update-node-dialog copy/UpdateNodeDialog'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'
import { PERMISSIONS } from 'helpers/constants'

interface INodeDashboardDialog {
    type: 'conflict' | 'add-node' | 'upload-node'
    isOpen: boolean
}

interface INodeDashboard {}

function NodeDashboard({}: INodeDashboard) {
    const { pid } = useParams()
    const navigator = useNavigate()
    const { project, drawerContent, hasPermission } = useContext(ProjectContext)

    const gridApi = useGridApiRef()
    const refreshData = React.useRef<() => void>(null)
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([])

    const [dialogState, setDialogState] = useState<INodeDashboardDialog>({
        type: 'conflict',
        isOpen: false,
    })

    const [isDeleteNodeDialogOpen, setIsDeleteNodeDialogOpen] = useState<{ open: boolean; ids?: number[] }>({
        open: false,
    })
    const [columns, setColumns] = useState<GridColDef[]>([])

    const deleteNodes = async (force?: boolean) => {
        if (!pid || !isDeleteNodeDialogOpen.ids) return
        //ToDo force delete
        let response = await DeleteNodesService(isDeleteNodeDialogOpen.ids, pid)
        if (response.success && refreshData.current) {
            refreshData.current()
            closeDeleteNodeDialog()
        }
    }

    const openDeleteNodeDialog = (nids: number[]) => {
        setIsDeleteNodeDialogOpen({ open: true, ids: nids })
    }

    const closeDeleteNodeDialog = () => {
        setIsDeleteNodeDialogOpen({ open: false })
    }

    const [importNodesDialogOpen, setImportNodesDialogOpen] = useState<boolean>(false)
    const openImportNodesDialog = () => setImportNodesDialogOpen(true)
    const closeImportNodesDialog = () => setImportNodesDialogOpen(false)

    const [updateNodesDialogOpen, setUpdateNodesDialogOpen] = useState<boolean>(false)
    const openUpdateNodesDialog = () => setUpdateNodesDialogOpen(true)
    const closeUpdateNodesDialog = () => setUpdateNodesDialogOpen(false)

    const [otherFields, setOtherFileds] = useState<string[]>([])

    useEffect(() => {
        const baseColumns: GridColDef[] = [{ field: 'primaryKey', headerName: 'Id' }, { field: 'title' }]
        for (let f of otherFields) {
            baseColumns.push({ field: 'others.' + f, headerName: f })
        }
        if (hasPermission(PERMISSIONS.node.ReadSpecificNode))
            baseColumns.push({
                field: 'actions',
                type: 'actions',
                width: 100,
                renderCell: (params) => {
                    return <BaseButton onClick={() => navigator(`/project/${pid}/nodes/${params.id}`)}>Open</BaseButton>
                },
            })
        setColumns(baseColumns)
    }, [otherFields, hasPermission, navigator, pid])

    const getOtherFileds = () => {
        if (pid == null) setOtherFileds([])

        if (pid) {
            GetNodeOtherFiledsService(pid)
                .then((res) => {
                    if (res.success) {
                        setOtherFileds(res.data.map((r) => r.field))
                    } else {
                        setOtherFileds([])
                    }
                })
                .catch((e) => setOtherFileds([]))
        }
    }

    const getData = async (pagination: { pageNumber: number; pageSize: number }) => {
        if (!pid) return
        getOtherFileds()
        let response = await GetNodesService(pid, pagination)
        if (response.success) {
            const result: GenreicAPIDataType = {
                success: true,
                data: { data: [], rowCount: response.data.rowCount },
            }

            result.data.data = response.data.data.map((d) => {
                const { others, ...rest } = d
                const res: { [key: string]: any } = { ...rest }
                for (let key in others) {
                    res['others.' + key] = others[key]
                }
                return res
            })
            return result
        }
        return
    }

    if (project == null) return <DashboardSkeleton />

    const canCreateNode = hasPermission(PERMISSIONS.node.CreateNode)
    const canUpdateNode = hasPermission(PERMISSIONS.node.UpdateNode)

    return (
        <PageCard>
            <PageCard.Side>{project?.primaryKey && <MainDrawer content={drawerContent} />}</PageCard.Side>

            <PageCard.Body>
                <Stack
                    height="100%"
                    sx={(theme) => ({
                        paddingBottom: 2,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* Title and head controls
						========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            py: 1.25,
                            px: 3,

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Title
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography fontSize={20} fontWeight={500} color="secondary">
                                Nodes List
                            </Typography>
                        </Stack>

                        {/* Controls
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            {selectionModel.length == 0 ? (
                                <>
                                    {/* Conflict resolution button
                                    ToDo
								========================================= */}
                                    {/* <BaseButton
                                        label="Conflict Resolution"
                                        onClick={(e) =>
                                            setDialogState((prevState) => ({
                                                type: 'conflict',
                                                isOpen: true,
                                            }))
                                        }
                                        variant="outlined"
                                        size="large"
                                        endIcon={<MergeIcon />}
                                        sx={{
                                            marginRight: 1,
                                        }}
                                    /> */}

                                    {/* Add node button
								========================================= */}
                                    {canCreateNode && (
                                        <>
                                            <BaseButton
                                                label="Add Node"
                                                onClick={(e) =>
                                                    setDialogState((prevState) => ({
                                                        type: 'add-node',
                                                        isOpen: true,
                                                    }))
                                                }
                                                variant="outlined"
                                                size="large"
                                                endIcon={<AddIcon />}
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            />
                                            {/* Import node button
								            ========================================= */}
                                            <BaseButton
                                                onClick={openImportNodesDialog}
                                                label="Import Nodes"
                                                // onClick={(e) => setDialogState({ type: 'add-participants', isOpen: true })}
                                                variant="outlined"
                                                size="large"
                                                endIcon={<PublishIcon />}
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            />
                                        </>
                                    )}

                                    {/* Update node button
								        ========================================= */}
                                    {canUpdateNode && (
                                        <BaseButton
                                            onClick={openUpdateNodesDialog}
                                            label="Update Nodes"
                                            // onClick={(e) => setDialogState({ type: 'add-participants', isOpen: true })}
                                            variant="outlined"
                                            size="large"
                                            endIcon={<UpdateIcon />}
                                            sx={{
                                                marginRight: 1,
                                            }}
                                        />
                                    )}
                                </>
                            ) : (
                                /* Delete selected nodes
                               ========================================= */
                                <BaseButton
                                    label="Delete Selected Nodes"
                                    onClick={(e) => openDeleteNodeDialog(selectionModel.map((m) => parseInt(m + '')))}
                                    color="warning"
                                    variant="outlined"
                                    size="large"
                                    endIcon={<DeleteIcon />}
                                    sx={{
                                        marginRight: 1,
                                    }}
                                />
                            )}
                            {/* Search
								========================================= */}
                            {/* <SearchField options={['Something']} placeholder="Search" /> */}

                            {/* History button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <HistoryIcon />
                            </IconButton> */}

                            {/* ??? button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <ViewColumnIcon />
                            </IconButton> */}

                            {/* Filter button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <FilterListIcon />
                            </IconButton> */}

                            {/* Publish button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <DownloadIcon />
                            </IconButton> */}

                            {/* Reload (?) button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <RefreshIcon
                                    sx={{
                                        transform: 'rotate(180deg)',
                                    }}
                                />
                            </IconButton> */}
                        </Stack>
                    </Stack>

                    {/* Nodes list
						========================================= */}
                    <Box
                        flexGrow={1}
                        sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingRight: 2,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <StyledDataGrid
                            initialState={{ pinnedColumns: { right: ['actions'] } }}
                            apiRef={gridApi}
                            checkboxSelection={true}
                            refreshData={refreshData}
                            getData={getData}
                            columns={columns}
                            onRowSelectionModelChange={(model) => {
                                setSelectionModel(model)
                            }}
                        />
                    </Box>
                </Stack>

                {/* Conflict resolution dialog
					========================================= */}
                {dialogState.isOpen && (
                    <StyledDialog
                        open={dialogState.isOpen}
                        // Prevent backdrop clicks and ESC key from closing the dialog by accident.
                        onClose={() => undefined}
                        fullWidth={true}
                        maxWidth={
                            dialogState.type === 'conflict' ? 'xl' : dialogState.type === 'upload-node' ? 'lg' : 'sm'
                        }
                        scroll="body"
                    >
                        {dialogState.type === 'conflict' ? (
                            <NodeConflictResolutionDialog
                                onConfirm={() =>
                                    setDialogState((prevState) => ({
                                        ...prevState,
                                        isOpen: false,
                                    }))
                                }
                                onClose={() =>
                                    setDialogState((prevState) => ({
                                        ...prevState,
                                        isOpen: false,
                                    }))
                                }
                            />
                        ) : dialogState.type === 'add-node' ? (
                            <AddNewNodeDialog
                                otherFields={otherFields}
                                onConfirm={() => {
                                    refreshData.current && refreshData.current()
                                    setDialogState((prevState) => ({
                                        ...prevState,
                                        isOpen: false,
                                    }))
                                }}
                                onClose={() =>
                                    setDialogState((prevState) => ({
                                        ...prevState,
                                        isOpen: false,
                                    }))
                                }
                            />
                        ) : dialogState.type === 'upload-node' ? (
                            false
                        ) : (
                            false
                        )}
                    </StyledDialog>
                )}

                {/* Delete node dialog
					========================================= */}
                {isDeleteNodeDialogOpen.open && (
                    <StyledDialog
                        open={isDeleteNodeDialogOpen.open}
                        onClose={closeDeleteNodeDialog}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DeleteNodeDialog onConfirm={deleteNodes} onClose={closeDeleteNodeDialog} />
                    </StyledDialog>
                )}

                {/* import node dialog
					========================================= */}
                {importNodesDialogOpen && pid && (
                    <StyledDialog open={importNodesDialogOpen} onClose={closeImportNodesDialog} fullWidth maxWidth="lg">
                        <UploadNodeDialog
                            projectId={parseInt(pid)}
                            onConfirm={() => {
                                refreshData.current && refreshData.current()
                                closeImportNodesDialog()
                            }}
                        />
                    </StyledDialog>
                )}

                {/* import node dialog
					========================================= */}
                {updateNodesDialogOpen && pid && (
                    <StyledDialog open={updateNodesDialogOpen} onClose={closeUpdateNodesDialog} fullWidth maxWidth="lg">
                        <UpdateNodeDialog
                            projectId={parseInt(pid)}
                            onConfirm={() => {
                                refreshData.current && refreshData.current()
                                closeUpdateNodesDialog()
                            }}
                        />
                    </StyledDialog>
                )}
            </PageCard.Body>
        </PageCard>
    )
}

export default NodeDashboard
