import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Tooltip from '@mui/material/Tooltip'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TransitionProps } from '@mui/material/transitions'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { NodeInfoConfigType } from 'features/network-viz/types/NetworkViz.types'
import { KeyValue } from 'features/StyledComponents/FormBuilder'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import BaseFilledTextField from 'components/base/BaseFilledTextField'


interface EditNodeSettingsModalProps {
    open: boolean
    handleClose: () => void
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export function EditNodeSettingsModal({ open, handleClose }: EditNodeSettingsModalProps) {
    const { nodeInfoConfig } = useNetworkVizContext();
    const dispatchContext = useNetworkVizDispatch();

    const [localData, setLocalData] = useState<NodeInfoConfigType>({ fileds: {} })

    const [options, setOptions] = useState<Array<string | KeyValue>>([])

    const [expanded, setExpanded] = React.useState<string | false>('panel1')

    const handleAcardionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    useEffect(() => {
        if (nodeInfoConfig) {
            setLocalData({ ...nodeInfoConfig })
            setOptions([{ value: '', label: 'None' }, ...Object.keys(nodeInfoConfig.fileds)])
        }
    }, [open, nodeInfoConfig])

    const updateLocalDataField = (key: string, item: 'label' | 'visible') => (value: any) => {
        setLocalData((ld) => ({
            ...ld,
            fileds: {
                ...ld?.fileds,
                [key]: {
                    ...ld?.fileds[key],
                    [item]: value,
                },
            },
        }))
    }

    const updateLocalData =
        (key: 'image' | 'title' | 'subTitle' | 'hideNA') => (value: any) => {
            setLocalData((ld: NodeInfoConfigType) => {
                ld[key] = value
                return { ...ld }
            })
        }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            aria-describedby="node-info-settings-dialog-slide-description"
        >
            <DialogTitle>{'Selected Node Settings'}</DialogTitle>
            <DialogContent sx={{ height: '70vh' }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleAcardionChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>General settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <BaseSelectWithLabel
                                    label="Title Field"
                                    fullWidth
                                    options={options}
                                    value={localData.title || ''}
                                    onChange={updateLocalData('title')}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <BaseSelectWithLabel
                                    label="SubTitle Field"
                                    fullWidth
                                    options={options}
                                    value={localData.subTitle || ''}
                                    onChange={updateLocalData('subTitle')}
                                />
                            </Grid>


                            <Grid item xs={6}>
                                <BaseSelectWithLabel
                                    label="Image Field"
                                    fullWidth
                                    options={options}
                                    value={localData.image || ''}
                                    onChange={updateLocalData('image')}
                                />
                            </Grid>


                            <Grid item xs={6}>
                                <StyledSwitch
                                    label="Hide NAs"
                                    checked={localData.hideNA || false}
                                    onChange={(e) => updateLocalData('hideNA')(e.target.checked)}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleAcardionChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography>Fields</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {localData?.fileds && (
                            <Grid container spacing={2} mt={1}>
                                {Object.keys(localData.fileds).map((key, index) => {
                                    const field = localData.fileds[key]
                                    const keyIndex = key
                                    return (
                                        <Grid item xs={6} key={keyIndex}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={10}>
                                                    <BaseFilledTextField
                                                        label={key}
                                                        value={field.label}
                                                        onChange={(e) =>
                                                            updateLocalDataField(key, 'label')(e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Tooltip title={'Toggle Visibility'}>
                                                        <StyledSwitch
                                                            checked={field.visible}
                                                            onChange={(e) =>
                                                                updateLocalDataField(
                                                                    key,
                                                                    'visible'
                                                                )(e.target.checked)
                                                            }
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button
                    onClick={() => {
                        localData && dispatchContext({ type: 'NODE_INFO_CONFIG_EDIT', payload: localData })
                        handleClose()
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
