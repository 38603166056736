//* ======= Libraries
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { isEqual, round } from 'lodash'
import { Stack, Box, Tooltip, IconButton, Pagination, Badge } from '@mui/material'
//* ======= Components and features
import Chart from 'features/chart/Chart'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { ChartWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { generateChartOptions, splitChartOptions } from 'features/chart/Chart.helper'
import { getChartAttributes, getDataSourceData } from 'features/report-designer/helpers/reportDesigner.helper'
//* ======= Assets and styles
import MultilineChartIcon from '@mui/icons-material/MultilineChart'
import FilterBadge from '../components/FilterBadge'

type Props = {
    Id: ReportWidgetType['id']
    data: ChartWidgetType
    onReady: () => void
    scaleFactor: number
}

function ChartWidget({ Id, data, onReady, scaleFactor }: Props) {
    const { dataSources, activeSlideId, updateChartWidgetContent } = useReportStore((store) => ({
        dataSources: store.dataSources,
        activeSlideId: store.activeSlideId,
        updateChartWidgetContent: store.updateChartWidgetContent,
    }))

    // Process options and apply some config or alterations.
    // Adding this step here, allows for less data generation and faster config application.
    const processedChartOptions = useMemo(() => {
        const chartData = structuredClone(data)

        if (chartData.options !== null) {
            switch (chartData.type) {
                case 'line':
                    // Decimal precision
                    if (chartData.options !== null && chartData.options.series !== undefined) {
                        for (const _series of chartData.options.series) {
                            _series.data = _series.data.map((_data: any) =>
                                round(_data, chartData.config.decimalPrecision)
                            )
                        }
                    }

                    break

                case 'bar':
                    // Decimal precision
                    if (chartData.options !== null && chartData.options.series !== undefined) {
                        for (const _series of chartData.options.series) {
                            _series.data = _series.data.map((_data: any) =>
                                round(_data, chartData.config.decimalPrecision)
                            )
                        }
                    }

                    break

                case 'pie':
                    // Decimal precision
                    if (chartData.options !== null && chartData.options.series !== undefined) {
                        if (Array.isArray(chartData.options.series)) {
                            for (const _series of chartData.options.series) {
                                _series.data = _series.data.map((_data: any) => ({
                                    ..._data,
                                    value: round(_data.value, chartData.config.decimalPrecision),
                                }))
                            }
                        } else {
                            chartData.options.series.data = chartData.options.series.data!.map((_data: any) => ({
                                ..._data,
                                value: round(_data.value, chartData.config.decimalPrecision),
                            }))
                        }
                    }

                    break

                case 'scatter':
                    // Decimal precision
                    if (chartData.options !== null && chartData.options.series !== undefined) {
                        for (const _series of chartData.options.series) {
                            _series.data = _series.data.map((_data: any) => {
                                return _data.map((_value: any) => round(_value, chartData.config.decimalPrecision))
                            })
                        }
                    }

                    break

                case 'radar':
                    // Decimal precision
                    if (
                        chartData.options !== null &&
                        chartData.options.series !== undefined &&
                        chartData.options.series.data !== undefined
                    ) {
                        chartData.options.series.data = chartData.options.series.data!.map((_data: any) => ({
                            ..._data,
                            value: _data.value.map((_value: any) => round(_value, chartData.config.decimalPrecision)),
                        }))
                    }

                    break

                case 'boxplot':
                    // Decimal precision
                    if (chartData.options !== null && chartData.options.series !== undefined) {
                        chartData.options.series = [
                            {
                                ...chartData.options.series[0],
                                data: chartData.options.series[0].data.map((_data: any) => {
                                    return {
                                        ..._data,
                                        value: _data.value.map((_value: any) =>
                                            round(_value, chartData.config.decimalPrecision)
                                        ),
                                    }
                                }),
                            },
                        ]
                    }

                    break

                case 'gauge':
                    // Decimal precision
                    if (
                        chartData.options !== null &&
                        chartData.options.series !== undefined &&
                        chartData.options.series.data !== undefined
                    ) {
                        chartData.options.series = {
                            ...chartData.options.series,
                            data: chartData.options.series.data!.map((_data: any) => ({
                                ..._data,
                                value:
                                    _data.value !== undefined
                                        ? round(_data.value, chartData.config.decimalPrecision)
                                        : _data.value,
                            })),
                        }
                    }

                    break

                case 'pictorialBar':
                    // Decimal precision
                    if (chartData.options !== null && chartData.options.series !== undefined) {
                        chartData.options.series = {
                            ...chartData.options.series,
                            data: chartData.options.series.data!.map((_data: any) => ({
                                ..._data,
                                value: round(_data.value, chartData.config.decimalPrecision),
                            })),
                        }
                    }

                    break

                case 'wordCloud':
                    // Decimal precision
                    // Not needed.

                    break

                case 'graph':
                    // Decimal precision
                    // Not needed.

                    break

                default:
                    break
            }
        } else {
            onReady()
        }

        return structuredClone(chartData.options)
    }, [data.type, data.options, data.config.decimalPrecision])

    const currentFiltersRef = useRef<ChartWidgetType['filters']>(data.filters)

    const splitChartOptionsList = useMemo(() => {
        if (data.config.multiView.isEnabled) {
            return splitChartOptions(data) ?? []
        } else {
            return []
        }
    }, [data])

    const updateWidget = (data: ChartWidgetType, disableTracking: boolean) => {
        if (activeSlideId === null) return

        updateChartWidgetContent({
            slideId: activeSlideId,
            widgetId: Id,
            data: data,
            disableTracking,
        })
    }

    const updateChartOptions = () => {
        updateWidget(
            {
                ...data,
                options: generateChartOptions({
                    type: data.type,
                    mode: 'all',
                    prevOptions: data.options,
                    filteredData: getDataSourceData(
                        dataSources,
                        data.selectedDataSource,
                        getChartAttributes(data.type, data.dataDefinition),
                        data.filters
                    ),
                    unfilteredData: getDataSourceData(
                        dataSources,
                        data.selectedDataSource,
                        getChartAttributes(data.type, data.dataDefinition),
                        undefined
                    ),
                    dataDefinition: data.dataDefinition,
                    config: data.config,
                }),
            } as ChartWidgetType,
            true
        )
    }

    // Generate and update chart options if dynamic filters change
    useEffect(() => {
        if (isEqual(currentFiltersRef.current, data.filters) === false) {
            currentFiltersRef.current = data.filters

            updateChartOptions()
        }
    }, [data.filters])

    const clearAllFilters = () => {
        updateWidget({ ...data, filters: null }, true)
    }

    const onChartReady = () => {
        onReady()
    }

    return processedChartOptions === null ? (
        /*  Initial view
            ========================================= */
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
                padding: 1,
            }}
        >
            <MultilineChartIcon
                sx={(theme) => ({
                    width: '90%',
                    height: '90%',

                    color: theme.palette.common.fill_2,
                })}
            />
        </Stack>
    ) : (
        /*  Main view
            ========================================= */
        <Box
            sx={{
                position: 'relative',

                height: '100%',
            }}
        >
            {data.config.multiView.isEnabled ? (
                /*  Multi-chart view
                    ========================================= */
                <MultipleChartsView
                    type={data.type}
                    optionsList={splitChartOptionsList}
                    columns={data.config.multiView.gridSize.columns}
                    rows={data.config.multiView.gridSize.rows}
                    onReady={onChartReady}
                />
            ) : (
                /*  Single chart view
                    ========================================= */
                // TODO: Word cloud is still making problems for types
                <Chart
                    type={data.type}
                    // @ts-ignore
                    options={processedChartOptions}
                    onReady={onChartReady}
                    scaleFactor={scaleFactor}
                />
            )}
        </Box>
    )
}

export default ChartWidget

type MultipleChartsViewProps = {
    type: ChartWidgetType['type']
    optionsList: ChartWidgetType['options'][]
    columns?: number
    rows?: number
    onReady?: () => void
}

function MultipleChartsView({ type, onReady, optionsList, columns = 2, rows = 2 }: MultipleChartsViewProps) {
    const [page, setPage] = useState(1)

    const pageCount = Math.ceil(optionsList.length / (rows * columns))

    const currentPageItems = optionsList.filter((_options, idx) => {
        const itemsPerPage = columns * rows

        return idx >= itemsPerPage * page - itemsPerPage && idx < itemsPerPage * page
    })

    useEffect(() => {
        onReady && onReady()
    }, [])

    useEffect(() => {
        if (page > pageCount) {
            setPage(pageCount)
        }
    }, [columns, rows])

    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
            }}
            gap={2}
        >
            {/* Charts grid
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 0 0',

                    display: 'grid',
                    gridTemplateColumns: '1fr '.repeat(columns),
                    gridTemplateRows: '1fr '.repeat(rows),
                    placeItems: 'center',
                    gap: theme.spacing(1),
                })}
            >
                {currentPageItems.map((_option, idx) => {
                    return (
                        /*  Chart item
                            ========================================= */
                        <Box
                            key={idx}
                            sx={{
                                position: 'relative',

                                width: '100%',
                                height: '100%',
                            }}
                        >
                            {/* 
                                This extra Box with absolute positioning is mandatory since
                                echarts cannot "see" the grid item's dimensions on resize.
                             */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,

                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Chart type={type} options={_option || {}} />
                            </Box>
                        </Box>
                    )
                })}
            </Box>

            {/* Bottom pagination
                ========================================= */}
            <Pagination
                count={pageCount}
                page={page}
                onChange={(_, page) => setPage(page)}
                variant="outlined"
                color="primary"
                sx={{
                    flexShrink: 0,

                    display: 'flex',
                    justifyContent: 'center',
                }}
            />
        </Stack>
    )
}
