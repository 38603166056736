//* ======= Libraries
import { useState, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Box, Typography, Tabs, Tab, FormControlLabel, Switch, Divider } from '@mui/material'
//* ======= Components and features
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    ReportWidgetSettingsBaseType,
    DynamicControlWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import FontPicker from 'components/widgets/FontPicker'
import ColorPicker from 'components/widgets/ColorPicker'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
import DynamicIconSelector from 'features/dynamic-icon-slector/DynamicIconSelector'
//* ======= Assets and styles

const variantOptions: BaseSelectWithLabelOptionType = [
    {
        label: 'Text',
        value: 'text',
    },
    {
        label: 'Outlined',
        value: 'outlined',
    },
    {
        label: 'Contained',
        value: 'contained',
    },
    {
        label: 'Icon',
        value: 'icon',
    },
]

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'dynamicControl'>
    settings: Omit<ReportWidgetType, 'content'>
    details: DynamicControlWidgetType
}

function DynamicControlWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { activeSlideId, updateWidget, updateWidgetStyles, updateDynamicControlWidgetContent } = useReportStore(
        (store) => ({
            activeSlideId: store.activeSlideId,
            updateWidget: store.updateWidget,
            updateWidgetStyles: store.updateWidgetStyles,
            updateDynamicControlWidgetContent: store.updateDynamicControlWidgetContent,
        })
    )

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'dynamicControl') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: DynamicControlWidgetType) => {
        if (activeSlideId !== null && widget !== null) {
            updateDynamicControlWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Button" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}

                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                key="widgetTitle" // Unique key without this, the input value becomes same as Variable name field
                                defaultValue={widget.settings.title}
                                onBlur={(evt) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }}
                                size="small"
                                fullWidth
                            />

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(value) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility: value,
                                        },
                                    })
                                }
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Variable name
                                ========================================= */}
                            <BaseFilledTextField
                                label="Variable Name"
                                defaultValue={widget.details.variableName}
                                onBlur={(evt) => {
                                    updateDetails({
                                        ...widget.details,
                                        variableName: evt.target.value.trim(),
                                    })
                                }}
                                size="small"
                                fullWidth
                            />

                            {/* Variable value
                                ========================================= */}

                            <BaseFilledTextField
                                label="Variable Value"
                                defaultValue={widget.details.variableValue}
                                onBlur={(evt) =>
                                    updateDetails({
                                        ...widget.details,
                                        variableValue: evt.target.value.trim(),
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Toggle on click
                                ========================================= */}
                            <FormControlLabel
                                checked={widget.details.toggle}
                                onChange={(evt, checked) =>
                                    updateDetails({
                                        ...widget.details,
                                        toggle: checked,
                                    })
                                }
                                control={<Switch />}
                                label="Toggle on click"
                            />

                            <Divider />

                            {/* Variant select
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Variant"
                                options={variantOptions}
                                value={widget.details.variant}
                                size="small"
                                onChange={(value) =>
                                    updateDetails({
                                        ...widget.details,
                                        variant: value,
                                    })
                                }
                            />

                            <Divider />

                            <Stack gap={0.5} sx={{ flexShrink: 0 }}>
                                <Typography fontSize={14} noWrap textAlign="left">
                                    Font Styles:
                                </Typography>

                                {/* Label styles
                                ========================================= */}

                                <FontPicker
                                    onChange={(values) =>
                                        updateDetails({
                                            ...widget.details,
                                            fontStyle: {
                                                fontFamily: values.fontFamily,
                                                fontSize: values.fontSize,
                                                fontWeight: values.isBold ? 'bold' : 'normal',
                                            },
                                        })
                                    }
                                    defaultValue={{
                                        fontFamily: widget.details.fontStyle.fontFamily,
                                        fontSize: widget.details.fontStyle.fontSize,
                                        isBold: widget.details.fontStyle.fontWeight === 'bold',
                                    }}
                                    styleControls={false}
                                />
                            </Stack>

                            <Divider />

                            <StyledWidgetAccordion title="Non-active Config">
                                <Stack gap={1}>
                                    <BaseFilledTextField
                                        label="Label"
                                        defaultValue={widget.details.nonActiveConfig.label}
                                        onBlur={(evt) =>
                                            updateDetails({
                                                ...widget.details,
                                                nonActiveConfig: {
                                                    ...widget.details.nonActiveConfig,
                                                    label: evt.target.value.trim(),
                                                },
                                            })
                                        }
                                        size="small"
                                        fullWidth
                                    />

                                    <ColorPicker
                                        value={widget.details.nonActiveConfig.color}
                                        onChange={(value) =>
                                            updateDetails({
                                                ...widget.details,
                                                nonActiveConfig: {
                                                    ...widget.details.nonActiveConfig,
                                                    color: value,
                                                },
                                            })
                                        }
                                    />
                                    <Box>
                                        <DynamicIconSelector
                                            defaultIcon={widget.details.nonActiveConfig.icon}
                                            onSelectIcon={(value) =>
                                                updateDetails({
                                                    ...widget.details,
                                                    nonActiveConfig: { ...widget.details.nonActiveConfig, icon: value },
                                                })
                                            }
                                            label="Default Icon"
                                            clearable={true}
                                        />
                                    </Box>
                                </Stack>
                            </StyledWidgetAccordion>

                            <Divider />

                            <StyledWidgetAccordion
                                title="Active Config"
                                hasToggle
                                isToggledOff={!widget.details.activeConfig.enabled}
                                onToggle={(isEnabled) => {
                                    updateDetails({
                                        ...widget.details,
                                        activeConfig: {
                                            ...widget.details.activeConfig,
                                            enabled: isEnabled,
                                        },
                                    })
                                }}
                            >
                                <Stack gap={1}>
                                    <BaseFilledTextField
                                        label="Label"
                                        defaultValue={widget.details.activeConfig.label || ''}
                                        disabled={!widget.details.activeConfig.enabled}
                                        onBlur={(evt) =>
                                            updateDetails({
                                                ...widget.details,
                                                activeConfig: {
                                                    ...widget.details.activeConfig,
                                                    label: evt.target.value.trim(),
                                                },
                                            })
                                        }
                                        size="small"
                                        fullWidth
                                    />

                                    <Typography fontSize={14} noWrap textAlign="left">
                                        Color:
                                    </Typography>

                                    <ColorPicker
                                        value={widget.details.activeConfig.color}
                                        disabled={!widget.details.activeConfig.enabled}
                                        onChange={(value) =>
                                            updateDetails({
                                                ...widget.details,
                                                activeConfig: {
                                                    ...widget.details.activeConfig,
                                                    color: value,
                                                },
                                            })
                                        }
                                    />

                                    <Box>
                                        <DynamicIconSelector
                                            defaultIcon={widget.details.activeConfig.icon}
                                            onSelectIcon={(value) =>
                                                updateDetails({
                                                    ...widget.details,
                                                    activeConfig: { ...widget.details.activeConfig, icon: value },
                                                })
                                            }
                                            label="Icon"
                                            clearable={true}
                                            disabled={!widget.details.activeConfig.enabled}
                                        />
                                    </Box>
                                </Stack>
                            </StyledWidgetAccordion>
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default DynamicControlWidgetSettings
