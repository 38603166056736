import {
    InsightWidgetType,
    ReportDataSourceAttributeType,
    ReportDataSourceRowType,
} from 'features/report-designer/types/reportDesigner.types'
import { NodeDictonaryType } from '../../../InisghtWidgetInterventionAdvancedView'
import { useEffect, useMemo, useState } from 'react'
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    convertNodeAttributeToKeyString,
    convertStringToNodeAttribute,
    formatValueAsLabel,
    getDataSourceData,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import { Box, IconButton, Paper, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import Chart from 'features/chart/Chart'
import SettingIcon from '@mui/icons-material/Settings'
import AdvancedInsightCorrelationConfigDialog from './AdvancedInsightCorrelationConfigDialog'
import { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { max, min, quantileSeq } from 'mathjs'
import { GetIssueCorrelationService, IssueCorrelationType } from 'services/WidgetApi'

type AdvancedInsightCorrelationProps = {
    selectedDataSource: InsightWidgetType['selectedDataSource']
    nodeDictionary: NodeDictonaryType
    viewMode: ReportDesignerStoreStateType['viewMode']
    dataSourceFields: FlexibleSelectOptionType[]
    correlationConfig: InsightWidgetType['interventionConfig']['correlationConfig']
    updateCorrelationConfig: (config: InsightWidgetType['interventionConfig']['correlationConfig']) => void
}

const AdvancedInsightCorrelation = ({
    viewMode,
    selectedDataSource,
    nodeDictionary,
    dataSourceFields,
    correlationConfig,
    updateCorrelationConfig,
}: AdvancedInsightCorrelationProps) => {
    const { dataSources } = useReportStore((store) => ({
        dataSources: store.dataSources,
    }))

    const [showFilterConfigDialog, setShowFilterConfigDialog] = useState(false)

    const [scatterChartAttribute, setScatterChartAttribute] = useState<ReportDataSourceAttributeType | null>(null)

    const [gridRowSelectionModel, setGridRowSelectionModel] = useState<GridRowSelectionModel>([])
    const [correlatedAttributes, setCorrelatedAttributes] = useState<IssueCorrelationType[]>([])

    const [chartType, setChartType] = useState<'heatmap' | 'boxplot' | 'scatter'>('heatmap')

    useEffect(() => {
        if (selectedDataSource === null) return

        // read the data from the data source
        const rawData = getDataSourceData(
            dataSources,
            selectedDataSource,
            correlationConfig.map((x) => x.attribute).filter((x) => x !== null) as ReportDataSourceAttributeType[]
        )

        if (rawData === undefined) return

        // add number of issues to each node
        const data = rawData.map((node) => {
            let id = node.id!
            if (typeof id !== 'number' || typeof id !== 'string') id = id.toString()
            const issuesCount = nodeDictionary[id]?.issues?.length ?? 0
            return { ...node, issues: issuesCount }
        }) as ReportDataSourceRowType[]

        GetIssueCorrelationService({
            data,
            attributes: correlationConfig
                .filter((x) => x.attribute !== null)
                .map((x) => ({
                    mode: x.mode,
                    attribute: convertNodeAttributeToKeyString(x.attribute!),
                })),
        }).then((response) => {
            if (response.success) {
                setCorrelatedAttributes(response.data.correlations)
                if (response.data.correlations.length > 0) {
                    setScatterChartAttribute(convertStringToNodeAttribute(response.data.correlations[0].attribute))
                    setGridRowSelectionModel([response.data.correlations[0].id])
                }
            }
        })
    }, [correlationConfig, dataSources, selectedDataSource, nodeDictionary])

    const scatterChartOptions = useMemo<EChartsCustomOptionType>(() => {
        if (scatterChartAttribute === null || selectedDataSource === null) return {}

        const enableJitter = true

        const jitterStrength = 0.5 // Adjust the strength of the jitter
        const getJitter = (val: number) => {
            const newValue = val + (Math.random() - 0.5) * jitterStrength
            if (val >= 0 && newValue < 0) return val + (Math.random() / 2) * jitterStrength
            if (val < 0 && newValue >= 0) return val - (Math.random() / 2) * jitterStrength
            return newValue
        }

        const data = getDataSourceData(dataSources, selectedDataSource, [scatterChartAttribute])
        if (data === undefined) return {}

        const attributeKey = convertNodeAttributeToKeyString(scatterChartAttribute)

        const result = data.map((row) => {
            const id = row.id!.toString()
            return {
                value: enableJitter
                    ? [getJitter(nodeDictionary[id]?.issues.length ?? 0), row[attributeKey]]
                    : [nodeDictionary[id]?.issues.length ?? 0, row[attributeKey]],
            }
        })

        const mode = correlationConfig.find(
            (x) =>
                x.attribute !== null &&
                convertNodeAttributeToKeyString(x.attribute) === convertNodeAttributeToKeyString(scatterChartAttribute)
        )?.mode

        if (mode === undefined) return {}

        return {
            tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
                formatter: (params: any) => {
                    return `${params.value[0]}: ${params.value[1]}`
                },
            },
            yAxis: {
                type: mode === 'categorical' ? 'category' : 'value',
                name: scatterChartAttribute.field,
            },
            xAxis: {
                type: 'value',
                name: 'Issues',
            },
            series: [
                {
                    type: 'scatter',
                    data: result,
                },
            ],
        } as EChartsCustomOptionType
    }, [scatterChartAttribute, dataSources, selectedDataSource, nodeDictionary, correlationConfig])

    const heatmapChartOptions = useMemo<EChartsCustomOptionType>(() => {
        if (scatterChartAttribute === null || selectedDataSource === null) return {}

        const data = getDataSourceData(dataSources, selectedDataSource, [scatterChartAttribute])
        if (data === undefined) return {}

        const attributeKey = convertNodeAttributeToKeyString(scatterChartAttribute)

        // Create unique sets for x and y categories
        const issuesSet = new Set<number>()
        const attributeSet = new Set<number | string>()

        // Prepare the data for the heatmap
        const heatmapData: { [key: string]: number } = {}

        data.forEach((row) => {
            const issuesCount = nodeDictionary[row.id!.toString()]?.issues.length ?? 0
            const attributeValue = row[attributeKey] as any

            issuesSet.add(issuesCount)
            attributeSet.add(attributeValue)

            const key = `${issuesCount},${attributeValue}`
            if (heatmapData[key] === undefined) {
                heatmapData[key] = 0
            }
            heatmapData[key]++
        })

        // Convert sets to sorted arrays
        const issuesArray = Array.from(issuesSet).sort((a, b) => a - b)
        const attributeArray = Array.from(attributeSet).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))

        // Map data to a 2D array for the heatmap
        const result = []
        for (const issuesCount of issuesArray) {
            for (const attributeValue of attributeArray) {
                const key = `${issuesCount},${attributeValue}`
                result.push([
                    attributeArray.indexOf(attributeValue),
                    issuesArray.indexOf(issuesCount),

                    heatmapData[key] ?? 0,
                ])
            }
        }

        return {
            tooltip: {
                position: 'top',
                formatter: (params: any) => {
                    return `Issues: ${issuesArray[params.value[0]]}<br>${scatterChartAttribute.field}: ${
                        attributeArray[params.value[1]]
                    }<br>Count: ${params.value[2]}`
                },
            },
            yAxis: {
                type: 'category',
                data: issuesArray,
                name: 'Issues',
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: true,
                },
            },
            xAxis: {
                type: 'category',
                data: attributeArray,
                name: scatterChartAttribute.field,
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: true,
                },
                nameLocation: 'middle',
                nameGap: 25,
            },
            visualMap: {
                min: 0,
                max: Math.max(...result.map((item) => item[2])),
                calculable: true,
                orient: 'vertical',
                bottom: 'center',
                right: '5px',
            },
            grid: {
                top: '30px',
                right: '70px',
                bottom: '20px',
                left: '15px',
                containLabel: true,
            },
            series: [
                {
                    type: 'heatmap',
                    data: result,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        } as EChartsCustomOptionType
    }, [scatterChartAttribute, dataSources, selectedDataSource, nodeDictionary])

    const boxPlotChartOptions = useMemo<EChartsCustomOptionType>(() => {
        if (scatterChartAttribute === null || selectedDataSource === null) return {}

        // Get the data from the data source
        const data = getDataSourceData(dataSources, selectedDataSource, [scatterChartAttribute])
        if (data === undefined) return {}

        const attributeKey = convertNodeAttributeToKeyString(scatterChartAttribute)

        // Group the data by the number of issues
        const dataGroups: { [key: number | string]: number[] } = {}

        const mode = correlationConfig.find(
            (x) =>
                x.attribute !== null &&
                convertNodeAttributeToKeyString(x.attribute) === convertNodeAttributeToKeyString(scatterChartAttribute)
        )?.mode

        if (mode === undefined) return {}

        // for continuous attributes, group the data by the number of issues
        if (mode === 'continuous') {
            for (const node of data) {
                let id = node.id!
                if (typeof id !== 'number' || typeof id !== 'string') id = id.toString()
                const issuesCount = nodeDictionary[id]?.issues?.length ?? 0
                if (dataGroups[issuesCount] === undefined) dataGroups[issuesCount] = []
                dataGroups[issuesCount].push(Number(node[attributeKey]))
            }
        }
        // if mode is categorical, group the data by attribute value
        else if (mode === 'categorical') {
            for (const node of data) {
                let id = node.id!
                if (typeof id !== 'number' || typeof id !== 'string') id = id.toString()
                const issuesCount = nodeDictionary[id]?.issues?.length ?? 0
                const attributeValue = node[attributeKey] as any
                if (dataGroups[attributeValue] === undefined) dataGroups[attributeValue] = []
                dataGroups[attributeValue].push(issuesCount)
            }
        }

        // compute the box plot data
        const sortedCategoryNames = Object.keys(dataGroups).sort((a, b) => a.localeCompare(b))

        const seriesData: { value: any[] }[] = []

        for (let groupKey of sortedCategoryNames) {
            const values = dataGroups[groupKey]

            if (values.length === 0) continue

            const boxplotValues = [
                min(values),
                ...(quantileSeq(values, [1 / 4, 1 / 2, 3 / 4]) as Array<number>),
                max(values),
            ]

            seriesData.push({
                value: boxplotValues,
            })
        }

        return {
            tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
                formatter: (params: any) => {
                    return `${params.value[0]}: ${params.value[1]}`
                },
            },
            yAxis: {
                type: 'value',
                name: mode === 'continuous' ? scatterChartAttribute.field : 'Issues',
            },
            xAxis: {
                type: 'category',
                data: sortedCategoryNames,
                name: mode === 'continuous' ? 'Issues' : scatterChartAttribute.field,
                nameLocation: 'middle',
                nameGap: 25,
            },
            series: [
                {
                    type: 'boxplot',
                    data: seriesData,
                },
            ],
        } as EChartsCustomOptionType
    }, [scatterChartAttribute, dataSources, selectedDataSource, nodeDictionary, correlationConfig])

    return (
        <Stack component={Paper} height="100%" width={'100%'} direction="column" gap={1} p={1} elevation={3}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                    Correlated Attributes
                </Typography>
                {viewMode === 'design' && (
                    <Tooltip title="Configure">
                        <IconButton onClick={() => setShowFilterConfigDialog(true)}>
                            <SettingIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
            {correlatedAttributes.length === 0 ? (
                <Stack>No data available</Stack>
            ) : (
                <Stack direction="row" gap={1} height={'80%'}>
                    <Box height="100%" width={350}>
                        <StyledDataGrid
                            rows={correlatedAttributes}
                            columns={[
                                {
                                    field: 'attribute',
                                    headerName: 'Attribute',
                                    renderCell: (params) => {
                                        const label = correlationConfig.find((x) => {
                                            if (x.attribute === null) return false
                                            return convertNodeAttributeToKeyString(x.attribute) === params.row.attribute
                                        })?.label
                                        if (label === undefined) return params.value
                                        return label
                                    },
                                },
                                {
                                    field: 'effect_class',
                                    headerName: 'Impact',
                                    width: 100,
                                },
                            ]}
                            density="compact"
                            hasHeader={true}
                            hasToolbar={false}
                            onRowClick={(params) => {
                                setScatterChartAttribute(convertStringToNodeAttribute(params.row.attribute))
                            }}
                            onRowSelectionModelChange={setGridRowSelectionModel}
                            rowSelectionModel={gridRowSelectionModel}
                            hasSearch={false}
                            pagination={true}
                            autoPageSize={true}
                            disableMultipleRowSelection
                            rowHeight={75}
                        />
                    </Box>
                    <Box height="100%" sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Tabs value={chartType} onChange={(_, newValue) => setChartType(newValue)} variant="scrollable">
                            <Tab label="Heatmap" value="heatmap" />
                            <Tab label="Box Plot" value="boxplot" />
                            <Tab label="Scatter" value="scatter" />
                        </Tabs>
                        <Box height="calc(100% - 60px)" sx={{ overflow: 'hidden', pt: 1 }}>
                            {scatterChartAttribute !== null && chartType === 'scatter' && (
                                <Chart type="scatter" options={scatterChartOptions} />
                            )}
                            {scatterChartAttribute !== null && chartType === 'heatmap' && (
                                <Chart type="heatmap" options={heatmapChartOptions} />
                            )}
                            {scatterChartAttribute !== null && chartType === 'boxplot' && (
                                <Chart type="boxplot" options={boxPlotChartOptions} />
                            )}
                        </Box>
                    </Box>
                </Stack>
            )}

            {/* Out of flow - Insight configuration dialog */}
            <AdvancedInsightCorrelationConfigDialog
                isOpen={showFilterConfigDialog}
                onClose={() => setShowFilterConfigDialog(false)}
                dataSourceFields={dataSourceFields}
                correlationConfig={correlationConfig}
                onSave={(_correlationConfig) => {
                    updateCorrelationConfig(_correlationConfig)
                    setShowFilterConfigDialog(false)
                }}
            />
        </Stack>
    )
}

export default AdvancedInsightCorrelation
