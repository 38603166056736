//* ======= Libraries
import React, { useState } from 'react'
import { Stack, Box, Typography, FormGroup, FormControlLabel, Checkbox, DialogProps } from '@mui/material'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDialog from 'components/dialog/StyledDialog'
//* ======= Custom logic
//* ======= Assets and styles

type ConfirmDialogProps = {
    open: boolean
    title?: string
    content: string
    secondaryContent?: string
    maxWidth?: DialogProps['maxWidth']
    confirmMessage?: string
    hasForceOption?: boolean
    closeLabel?: string
    actionLabel?: string
    onConfirm?: (isForced?: boolean) => void
    onClose: () => void
}

function ConfirmDialog({
    open,
    title,
    content,
    secondaryContent,
    maxWidth = 'md',
    confirmMessage,
    closeLabel = 'Cancel',
    actionLabel = 'Confirm',
    hasForceOption = false,
    onConfirm,
    onClose,
}: ConfirmDialogProps) {
    const [text, setText] = useState<string>('')

    const [isForced, setIsForced] = useState<boolean>(false)

    return (
        <StyledDialog maxWidth={maxWidth} fullWidth open={open} onClose={onClose}>
            <Stack
                gap={2}
                sx={{
                    height: '100%',
                    py: 3,
                    px: 4,
                }}
            >
                {/* Title
				    ========================================= */}
                {title && (
                    <Typography
                        alignSelf="flex-start"
                        fontSize={36}
                        fontWeight={600}
                        color="common.fuschia80"
                    >
                        {title}
                    </Typography>
                )}

                {/* Content
				    ========================================= */}
                <Typography
                    fontSize={16}
                    fontWeight={400}
                    color="common.text_1"
                    marginBottom={secondaryContent ? 1 : 2}
                >
                    {content}
                </Typography>

                {/* Secondary content
				    ========================================= */}
                {secondaryContent && (
                    <Typography fontSize={16} fontWeight={400} color="common.text_1" marginBottom={2}>
                        {secondaryContent}
                    </Typography>
                )}

                {/* Confirm message
				    ========================================= */}
                {confirmMessage && (
                    <Stack gap={2} marginBottom={1}>
                        <Typography
                            paragraph
                            fontSize={16}
                            fontWeight={400}
                            color="common.text_1"
                            marginBottom={1}
                        >
                            {'Write '}
                            <Box component="span" fontWeight={600}>
                                {confirmMessage}
                            </Box>
                            {
                                ' in the textbox below to proceed with the action in the textbox below to proceed with the action.'
                            }
                        </Typography>

                        {/* Input
				            ========================================= */}
                        <BaseFilledTextField
                            hiddenLabel
                            fullWidth
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            sx={{
                                alignSelf: 'stretch',
                            }}
                        />
                    </Stack>
                )}

                {/* Force delete checkbox
				    ========================================= */}
                {hasForceOption && (
                    <FormGroup
                        sx={{
                            marginBottom: 1,
                        }}
                    >
                        <FormControlLabel
                            label="Force Delete"
                            control={<Checkbox checked={isForced} onChange={() => setIsForced((f) => !f)} />}
                            sx={{
                                alignSelf: 'flex-start',

                                marginRight: 0,
                            }}
                        />
                    </FormGroup>
                )}

                {/* Controls
				    ========================================= */}
                <Stack direction="row" justifyContent="center" alignItems="center" gap={2} marginTop={2}>
                    {/* Cancel button
					    ========================================= */}
                    <BaseButton
                        label={closeLabel}
                        onClick={(e) => onClose && onClose()}
                        variant="outlined"
                        color="warning"
                        size="large"
                    />

                    {/* Publish button
					    ========================================= */}
                    <BaseButton
                        label={actionLabel}
                        disabled={
                            confirmMessage != null &&
                            text.toLocaleLowerCase() !== confirmMessage.toLocaleLowerCase()
                        }
                        onClick={(evt) => onConfirm && onConfirm(isForced)}
                        variant="contained"
                        color="warning"
                        size="large"
                    />
                </Stack>
            </Stack>
        </StyledDialog>
    )
}

export default ConfirmDialog
