import { NodeApis as Apis, parseUrl } from './Apis'
import { NodeActionType } from './NodeActionService'
import { SearchResponseType } from './ProjectService'
import { BaseResponseType, request } from './request'
import { SurveyInstanceBasicType } from './SurveyService'

export type BasicRespondentType = {
    primarKey?: number
    name?: string
    email?: string
    phoneNumber?: string
    avatar?: string
}

export type BasicNodeType = { id: number; title: string; avatar?: string }

export type NodeType = {
    primaryKey: number
    title: string
    avatar?: string
    category?: string
    others?: { [ket: string]: any }
    respondents?: Array<BasicRespondentType>
    surveyInstances?: SurveyInstanceBasicType[]
    actions?: NodeActionType[]
    network?: {
        primaryKey: number
        source: BasicNodeType
        target: BasicNodeType
        relation: string
    }[]
}

export type CreateRequestNodeType = {
    title: string
    others?: { [ket: string]: any }
    respondents?: Array<BasicRespondentType>
}

export type UpdatenodeRequestType = {
    primaryKey: number
    others?: { [ket: string]: any }
}

export type GetNodessResponse = {
    success: boolean
    data: {
        data: Array<NodeType>
        rowCount: number
    }
}

export type GetPaginationRequestType = {
    pageNumber: number
    pageSize: number
}

export const GetNodesService: (
    pid: number | string,
    pagination?: GetPaginationRequestType,
    showMessage?: boolean
) => Promise<GetNodessResponse> = async (pid, pagination = { pageNumber: 0, pageSize: 20 }, showMessage = false) => {
    return request(parseUrl(Apis.GetAll, { pid }), 'GET', undefined, pagination, showMessage)
}

export type GetNodeOtherFiledsServiceResponseType = {
    success: boolean
    data: { field: string }[]
}

export const GetNodeOtherFiledsService: (
    pid: number | string,
    showMessage?: boolean
) => Promise<GetNodeOtherFiledsServiceResponseType> = async (pid, showMessage = false) => {
    return request(parseUrl(Apis.GetOtherFields, { pid }), 'GET', undefined, undefined, showMessage)
}

export type NodeOtherFiledsWithUniqueValuesType = {
    key: string
    uniqueValues: string[]
}

export type GetNodeOtherFiledsWithUniqueValuesServiceResponseType = {
    success: boolean
    data: NodeOtherFiledsWithUniqueValuesType[]
}

export const GetNodeOtherFiledsWithUniqueValuesService: (
    pid: number | string,
    showMessage?: boolean
) => Promise<GetNodeOtherFiledsWithUniqueValuesServiceResponseType> = async (pid, showMessage = false) => {
    return request(parseUrl(Apis.GetOtherFieldsWithUniqueValues, { pid }), 'GET', undefined, undefined, showMessage)
}

export type RespondentType = {
    primaryKey: number
    id: number
    nodeId: number
    nodeTitle: string
    accountId: number
    accountName: string
    accountEmail: string
    accountPhoneNumber: string
    accountAvatar: string
    others: { [key: string]: any }
}

export type GetRespondentsResponseType = {
    success: boolean
    data: Array<RespondentType>
}

export const GetRespondentsService: (
    pid: number | string,
    showMessage?: boolean
) => Promise<GetRespondentsResponseType> = async (pid, showMessage = false) => {
    return request(parseUrl(Apis.GetRespondents, { pid }), 'GET', undefined, undefined, showMessage)
}

export const ImportNodesService: (
    nodes: CreateRequestNodeType[],
    projectId: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (nodes, projectId, showMessage = false) => {
    return request(
        parseUrl(Apis.Import, { pid: projectId }),
        'POST',
        { 'Content-Type': 'application/json' },
        nodes,
        showMessage
    )
}

export const UpdateNodesService: (
    nodes: UpdatenodeRequestType[],
    projectId: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (nodes, projectId, showMessage = false) => {
    return request(
        parseUrl(Apis.BatchUpdate, { pid: projectId }),
        'PUT',
        { 'Content-Type': 'application/json' },
        nodes,
        showMessage
    )
}

export const DeleteNodesService: (
    nodes: number[],
    projectId: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (nodes, projectId, showMessage = false) => {
    return request(
        parseUrl(Apis.Delete, { pid: projectId }),
        'DELETE',
        { 'Content-Type': 'application/json' },
        nodes,
        showMessage
    )
}

/// Get Node with id
export type GetNodeResponseType = {
    success: boolean
    data: NodeType
}

export const GetNodeService: (
    pid: number | string,
    nid: number | string,
    showMessage?: boolean
) => Promise<GetNodeResponseType> = async (pid, nid, showMessage = false) => {
    return request(parseUrl(Apis.GetNode, { pid, nid }), 'GET', undefined, undefined, showMessage)
}

export const SearchNodesService: (
    pid: string | number,
    term: string,
    showMessage?: boolean
) => Promise<SearchResponseType> = async (pid, term, showMessage = false) => {
    return request(parseUrl(Apis.Search, { pid, term }), 'GET', undefined, undefined, showMessage)
}

///update node
export const updateNodeService: (
    pid: string | number,
    nid: string | number,
    node: NodeType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, nid, node, showMessage = false) => {
    return request(parseUrl(Apis.update, { pid, nid }), 'PUT', undefined, node, showMessage)
}
