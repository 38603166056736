//* ======= Libraries
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Merge, PartialDeep } from 'type-fest'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
//* ======= Components and features
import RadarChartDataConfigDialog from 'features/report-designer/widgets/chart-widget/settings/RadarChartDataConfigDialog'
import ColorPicker from 'components/widgets/ColorPicker'
import FontPicker, { FontPickerValueType } from 'components/widgets/FontPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { ChartWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { RadarChartType, RadarChartConfigType, ChartMultiViewGridSizeOptions } from 'features/chart/Chart.asset'
import { GenerateChartOptionsParamsModeType } from 'features/chart/Chart.helper'
import { DECIMAL_PRECISION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { deepMergeCustom } from 'helpers/helpers'

const verticalAlignOptions = ['top', 'center', 'bottom']
const horizontalAlignOptions = ['left', 'center', 'right']
const radarShapeOptions = ['polygon', 'circle']
const radarSymbolsShapeOptions = ['circle', 'roundRect', 'diamond']

export type ReportRadarChartWidgetSettingsStateType = Merge<
    Pick<ChartWidgetType, 'selectedDataSource' | 'type' | 'dataDefinition' | 'config' | 'options'>,
    RadarChartType
>

type Props = {
    data: ReportRadarChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onUpdate: (
        mode: GenerateChartOptionsParamsModeType,
        updatedValues: Merge<
            Partial<Pick<ChartWidgetType, 'selectedDataSource'>>,
            Partial<Pick<RadarChartType, 'dataDefinition' | 'config'>>
        >
    ) => void
}

function RadarChartWidgetSettings({ data, dataSources, onUpdate }: Props) {
    // Create an internal chart data on props change.
    const chartData = useMemo<ReportRadarChartWidgetSettingsStateType>(() => {
        return {
            selectedDataSource: data.selectedDataSource,
            type: 'radar',
            dataDefinition: data.dataDefinition,
            config: data.config,
            options: data.options,
        }
    }, [data])

    const [isDataConfigDialogOpen, setIsDataConfigDialogOpen] = useState(false)

    const onChartConfigUpdate = (updatedConfig: PartialDeep<ReportRadarChartWidgetSettingsStateType['config']>) => {
        const config = deepMergeCustom(structuredClone(chartData.config), updatedConfig)

        onUpdate('config', {
            config: config,
        })
    }

    const onFontPickerChange = useCallback(
        (configField: keyof ReportRadarChartWidgetSettingsStateType['config'], values: FontPickerValueType) => {
            let updatedConfig: PartialDeep<ReportRadarChartWidgetSettingsStateType['config']> = {}

            // Title and Legend
            if (configField === 'title' || configField === 'legend') {
                updatedConfig = {
                    ...updatedConfig,
                    [configField]: {
                        styles: {
                            fontFamily: values.fontFamily,
                            fontSize: values.fontSize,
                            fontWeight: values.isBold ? 'bold' : 'normal',
                            color: values.color,
                        },
                    },
                }
            }

            onChartConfigUpdate(updatedConfig)
        },
        [chartData]
    )

    const onChartDataUpdate = (
        updatedData: Pick<ReportRadarChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>
    ) => {
        // We generate the options again and update store state.
        onUpdate('data', {
            selectedDataSource: updatedData.selectedDataSource,
            dataDefinition: updatedData.dataDefinition,
        })

        setIsDataConfigDialogOpen(false)
    }

    const addSplitAreaColor = () => {
        const colorsList: RadarChartConfigType['splitArea']['colors'] =
            chartData.config.splitArea.colors === undefined ? [] : [...chartData.config.splitArea.colors]

        onChartConfigUpdate({
            splitArea: {
                colors: [...colorsList, '#99999933'],
            },
        })
    }

    const removeSplitAreaColor = (itemIdx: number) => {
        if (chartData.config.splitArea.colors !== undefined) {
            const colorsList: RadarChartConfigType['splitArea']['colors'] = [...chartData.config.splitArea.colors]

            onChartConfigUpdate({
                splitArea: {
                    colors:
                        // If there is only 1 item left in the list, return undefined instead of an empty array (echarts expects it).
                        colorsList.length === 1 ? undefined : colorsList.filter((_color, idx) => idx !== itemIdx),
                },
            })
        }
    }

    const updateSplitAreaColor = (itemIdx: number, newColor: string | undefined) => {
        if (chartData.config.splitArea.colors !== undefined) {
            onChartConfigUpdate({
                splitArea: {
                    colors: chartData.config.splitArea.colors.map((_color, idx) =>
                        idx === itemIdx ? newColor || '#99999933' : _color
                    ),
                },
            })
        }
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                paddingX: theme.spacing(2),
            })}
        >
            {/* Configure data button
				========================================= */}
            <BaseButton
                label="Configure Data"
                onClick={(evt) => setIsDataConfigDialogOpen(true)}
                variant="outlined"
                sx={(theme) => ({
                    alignSelf: 'center',

                    width: '80%',
                    paddingY: theme.spacing(1.5),
                })}
            />

            <Divider />

            {/* Config settings
                ========================================= */}
            {chartData.options !== null ? (
                <>
                    {/* Decimal precision
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={(theme) => ({
                                flex: '1 0 0',

                                paddingY: theme.spacing(1),
                            })}
                        >
                            Decimal Precision:
                        </Typography>

                        <BaseSelectWithLabel
                            options={DECIMAL_PRECISION_OPTIONS}
                            value={chartData.config.decimalPrecision}
                            onChange={(value) =>
                                onChartConfigUpdate({
                                    decimalPrecision: value,
                                })
                            }
                            hiddenLabel
                            size="small"
                            sx={{
                                flex: '0 0 30%',
                            }}
                        />
                    </Stack>

                    {/* Multi view settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Multi-View"
                        hasToggle
                        isToggledOff={chartData.config.multiView.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                multiView: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Grid Size:
                                </Typography>

                                <BaseSelectWithLabel
                                    options={ChartMultiViewGridSizeOptions}
                                    value={chartData.config.multiView.gridSize}
                                    onChange={(value) =>
                                        onChartConfigUpdate({
                                            multiView: {
                                                gridSize: value,
                                            },
                                        })
                                    }
                                    hiddenLabel
                                    size="small"
                                    sx={{
                                        width: '50%',
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Title settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Title"
                        hasToggle
                        isToggledOff={chartData.config.title.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                title: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Title"
                                defaultValue={chartData.config.title.value}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            value: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Secondary title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Secondary Title"
                                defaultValue={chartData.config.title.secondaryValue}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            secondaryValue: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Vertical align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Vertical Align"
                                options={verticalAlignOptions}
                                value={chartData.config.title.verticalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            verticalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Horizontal align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Horizontal Align"
                                options={horizontalAlignOptions}
                                value={chartData.config.title.horizontalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            horizontalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Title font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('title', values)}
                                disabled={chartData.config.title.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.title.styles?.fontFamily,
                                    fontSize: chartData.config.title.styles?.fontSize,
                                    color: chartData.config.title.styles?.color,
                                    isBold: chartData.config.title.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Legend settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Legend"
                        hasToggle
                        isToggledOff={chartData.config.legend.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                legend: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Legend font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('legend', values)}
                                disabled={chartData.config.legend.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.legend.styles?.fontFamily,
                                    fontSize: chartData.config.legend.styles?.fontSize,
                                    color: chartData.config.legend.styles?.color,
                                    isBold: chartData.config.legend.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Shape select
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Shape:
                        </Typography>

                        <BaseSelectWithLabel
                            label="Shape"
                            options={radarShapeOptions}
                            value={chartData.config.shape}
                            onChange={(value) =>
                                onChartConfigUpdate({
                                    shape: value,
                                })
                            }
                            size="small"
                            sx={{
                                width: '50%',
                            }}
                        />
                    </Stack>

                    <Divider />

                    {/* Split area settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Split Area"
                        hasToggle
                        isToggledOff={chartData.config.splitArea.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                splitArea: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Colors header
						        ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Colors:
                                </Typography>

                                {/* Add color button
                                    ========================================= */}
                                <BaseButton
                                    label="Add Color"
                                    onClick={(evt) => addSplitAreaColor()}
                                    startIcon={<AddIcon />}
                                />
                            </Stack>

                            {/* Colors list
						        ========================================= */}
                            {chartData.config.splitArea.colors !== undefined &&
                                chartData.config.splitArea.colors.map((_color, idx) => (
                                    <Stack
                                        key={_color + idx}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        <Box
                                            sx={{
                                                flexGrow: 1,

                                                minWidth: 0,
                                            }}
                                        >
                                            <ColorPicker
                                                value={chartData.config.splitArea.colors![idx]}
                                                onChange={(newColor) => updateSplitAreaColor(idx, newColor)}
                                                isPopover={true}
                                                gap={1}
                                            />
                                        </Box>

                                        <IconButton
                                            onClick={(evt) => removeSplitAreaColor(idx)}
                                            sx={(theme) => ({
                                                flexShrink: 0,

                                                color: theme.palette.common.ung_pink,
                                            })}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                ))}
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Split lines
				        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Split Lines
                        </Typography>

                        <BaseSwitch
                            checked={chartData.config.hasSplitLines}
                            onChange={(evt, checked) =>
                                onChartConfigUpdate({
                                    hasSplitLines: checked,
                                })
                            }
                            size="small"
                            color="primary"
                            sx={{
                                flexShrink: 0,
                            }}
                        />
                    </Stack>

                    <Divider />

                    {/* Axis lines
				        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Axis Lines
                        </Typography>

                        <BaseSwitch
                            checked={chartData.config.hasAxisLines}
                            onChange={(evt, checked) =>
                                onChartConfigUpdate({
                                    hasAxisLines: checked,
                                })
                            }
                            size="small"
                            color="primary"
                            sx={{
                                flexShrink: 0,
                            }}
                        />
                    </Stack>

                    <Divider />

                    {/* Symbols
				        ========================================= */}
                    <StyledWidgetAccordion title="Symbols" hasBottomBorder>
                        <Stack gap={1}>
                            {/* Symbols shape
				                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Shape:
                                </Typography>

                                <BaseSelectWithLabel
                                    label="Symbol Shape"
                                    options={radarSymbolsShapeOptions}
                                    value={chartData.config.symbols.shape}
                                    onChange={(value) =>
                                        onChartConfigUpdate({
                                            symbols: {
                                                shape: value,
                                            },
                                        })
                                    }
                                    size="small"
                                    sx={{
                                        width: '50%',
                                    }}
                                />
                            </Stack>

                            {/* Symbols size
				                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Size:
                                </Typography>

                                <BaseFilledTextField
                                    type="number"
                                    hiddenLabel
                                    size="small"
                                    defaultValue={chartData.config.symbols.size}
                                    onBlur={(evt) => {
                                        const newValue =
                                            Number(evt.target.value) > 100
                                                ? 100
                                                : Number(evt.target.value) < 1
                                                ? 1
                                                : Number(evt.target.value)

                                        onChartConfigUpdate({
                                            symbols: {
                                                size: Number(evt.target.value),
                                            },
                                        })

                                        evt.target.value = newValue.toString()
                                    }}
                                    inputProps={{
                                        min: 1,
                                        max: 100,
                                        step: 1,
                                    }}
                                    sx={{
                                        width: '50%',
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </StyledWidgetAccordion>
                </>
            ) : (
                false
            )}

            {/* (Out-of-flow) Data config dialog
				========================================= */}
            {isDataConfigDialogOpen && (
                <RadarChartDataConfigDialog
                    isOpen={isDataConfigDialogOpen}
                    data={chartData}
                    dataSources={dataSources}
                    onConfirm={onChartDataUpdate}
                    onClose={() => setIsDataConfigDialogOpen(false)}
                />
            )}
        </Stack>
    )
}

export default RadarChartWidgetSettings
