//* ======= Libraries
import React, { useState, useEffect } from 'react'
import { InlineEditorType } from 'ckeditor5-custom-build'
import { Stack, Box, Typography } from '@mui/material'
//* ======= Components and features
import EditorInline from 'components/Editor/EditorInline'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { ReportMasterSettingsType, ReportSlideHeaderType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles

type Props = {
    data: Pick<ReportSlideHeaderType, 'primaryText'> &
        Pick<ReportMasterSettingsType['header'], 'secondaryText' | 'image'>
    fontSizeScaleFactor: number
}

function ReportSlideHeader({ data, fontSizeScaleFactor }: Props) {
    const { viewMode, activeSlideId, updateSlide } = useReportStore((store) => ({
        viewMode: store.viewMode,
        activeSlideId: store.activeSlideId,
        updateSlide: store.updateSlide,
    }))

    const onEditorBlur = (editor: InlineEditorType) => {
        if (activeSlideId !== null) {
            const editorData = editor.getData()

            updateSlide({
                slideId: activeSlideId,
                data: {
                    header: {
                        primaryText: editorData,
                    },
                },
            })
        }
    }

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
                height: '100%',
                minHeight: 0,
            })}
        >
            {/* Primary text
				========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 0 0',

                    minHeight: 0,
                    maxHeight: '100%',
                    overflowY: 'hidden',
                    overflowX: 'hidden',

                    // Editor custom styles
                    '& .ck-content.ck-editor__editable': {
                        // Remove padding from the editor container.
                        paddingX: 0,

                        // Remove vertical margins from paragraphs inside the editor.
                        '& > p': {
                            marginY: 0,
                        },
                    },
                })}
            >
                <EditorInline
                    data={data.primaryText}
                    onBlur={(event, editor) => onEditorBlur(editor)}
                    config={{
                        toolbar: ['fontColor', 'fontSize', 'bold', 'italic', 'underline'],
                        placeholder: 'Header title...',
                    }}
                    isReadOnly={viewMode === 'preview'}
                    fontSize={16 * fontSizeScaleFactor}
                />
            </Box>

            {/* Secondary text
				========================================= */}
            {data.secondaryText.value !== '' && (
                <Typography
                    noWrap
                    sx={(theme) => ({
                        flex: '0 0 auto',

                        fontFamily: data.secondaryText.styles.fontFamily,
                        fontSize: data.secondaryText.styles.fontSize * fontSizeScaleFactor,
                        fontWeight: data.secondaryText.styles.fontWeight,

                        color: data.secondaryText.styles.color,
                    })}
                >
                    {data.secondaryText.value}
                </Typography>
            )}

            {/* Image
				========================================= */}
            {data.image.source !== '' && (
                <img
                    src={data.image.source}
                    alt={data.image.name}
                    style={{
                        flex: '0 0 auto',

                        aspectRatio: '1 / 1',
                        height: '100%',
                        objectFit: data.image.fit,
                        objectPosition: 'center',
                    }}
                />
            )}
        </Stack>
    )
}

export default ReportSlideHeader
