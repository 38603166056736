import React, { useCallback, useMemo, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import { MenuItem, IconButton, Typography, Stack, Popover, Box } from '@mui/material'
import MUI_ICON_LIST from 'assets/json/MuiIcons'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { debounce } from 'lodash'
import BaseButton from 'components/base/BaseButton'

type DynamicIconSelectorProps = {
    onSelectIcon: (icon: string) => void
    label: string
    disabled?: boolean
    defaultIcon?: string
    clearable?: boolean
}

const DynamicIconSelector = ({
    onSelectIcon,
    label,
    disabled = false,
    clearable = true,
    defaultIcon = '',
}: DynamicIconSelectorProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setSearchQuery('')
    }

    const open = Boolean(anchorEl)

    const [icons] = useState(Object.keys(MUI_ICON_LIST))
    const [selectedIcon, setSelectedIcon] = useState(defaultIcon)

    const [searchQuery, setSearchQuery] = React.useState('')

    const inputRef = React.useRef<HTMLInputElement>(null)

    const onSearch = useCallback(
        debounce((query: string) => {
            if (query.trim() !== searchQuery) {
                setSearchQuery(query.trim())
            }
        }, 350),
        [searchQuery]
    )

    const filteredIcons = useMemo(() => {
        if (searchQuery === '') return icons

        const searchQueryLower = searchQuery.toLowerCase()

        return Object.entries(MUI_ICON_LIST)
            .map(([key, value]) => {
                let rank = 0
                const keyLower = key.toLowerCase()
                const valueLower = value.toLowerCase()

                // Higher rank if search query matches the start of the key or value
                if (keyLower.startsWith(searchQueryLower)) rank += 4
                if (valueLower.startsWith(searchQueryLower)) rank += 2

                // Additional rank if search query is included anywhere in the key or value
                if (keyLower.includes(searchQueryLower)) rank += 2
                if (valueLower.includes(searchQueryLower)) rank += 1

                return {
                    title: key,
                    rank,
                }
            })
            .filter(({ rank }) => rank > 0)
            .sort((a, b) => b.rank - a.rank)
            .map(({ title }) => title)
    }, [icons, searchQuery])

    const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const iconName = filteredIcons[index]
        const isSelected = selectedIcon === iconName
        return (
            <MenuItem
                key={iconName}
                value={iconName}
                style={style}
                selected={isSelected}
                onClick={() => {
                    setSelectedIcon(iconName)
                    onSelectIcon(iconName)
                    handleClose()
                }}
            >
                <DynamicIcon icon={iconName} /> {iconName}
            </MenuItem>
        )
    }

    return (
        <>
            <Stack
                direction="row"
                gap={1}
                sx={(theme) => ({
                    width: '100%',
                    height: '100%',

                    bgcolor: open ? theme.palette.common.bg_1 : theme.palette.common.bg_4,
                    border: open ? `1px solid ${theme.palette.primary.main}` : undefined,
                    borderRadius: 2,
                })}
            >
                <BaseButton
                    disabled={disabled}
                    onClick={handleClick}
                    color="secondary"
                    fullWidth
                    sx={{ height: '100%' }}
                >
                    {selectedIcon !== '' ? (
                        <Stack sx={{ width: '100%', alignItems: 'start' }}>
                            <Typography variant="caption" color={disabled ? 'text.disabled' : 'text.secondary'}>
                                {label}
                            </Typography>
                            <Stack direction="row" alignItems="center" justifyContent="start" sx={{ width: '100%' }}>
                                <DynamicIcon icon={selectedIcon} />
                                <Typography variant="button">{selectedIcon}</Typography>
                            </Stack>
                        </Stack>
                    ) : (
                        <Typography
                            color={disabled ? 'text.disabled' : 'text.secondary'}
                            sx={{ width: '100%', justifySelf: 'start', textAlign: 'left' }}
                        >
                            {label}
                        </Typography>
                    )}
                </BaseButton>
                {clearable && selectedIcon !== '' && (
                    <IconButton
                        onClick={(e) => {
                            setSelectedIcon('')
                            onSelectIcon('')
                        }}
                        disabled={disabled}
                        size="small"
                        sx={{ alignSelf: 'center' }}
                    >
                        <ClearIcon />
                    </IconButton>
                )}
            </Stack>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: '350px' }}>
                    <BaseFilledTextField
                        ref={inputRef}
                        sx={{
                            '& .MuiFilledInput-root': {
                                borderRadius: 4,
                            },
                            margin: '8px',
                            width: 'calc(100% - 16px)',
                        }}
                        hiddenLabel
                        size="small"
                        placeholder="Search..."
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" sx={{ marginRight: 1 }} />,
                            endAdornment: searchQuery.length > 0 && (
                                <IconButton
                                    onClick={(e) => {
                                        if (inputRef.current) inputRef.current.value = ''
                                        setSearchQuery('')
                                    }}
                                    size="small"
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                        onChange={(e) => onSearch(e.target.value.trim())}
                    />
                    <List
                        height={300} // Adjust based on your needs
                        itemCount={filteredIcons.length}
                        itemSize={50} // Adjust based on your needs
                        width="100%" // Adjust based on your needs
                        className="u-scrollbar"
                        initialScrollOffset={filteredIcons.findIndex((icon) => icon === selectedIcon) * 50}
                        style={{ overflowX: 'hidden' }}
                    >
                        {renderRow}
                    </List>
                </Box>
            </Popover>
        </>
    )
}

export default DynamicIconSelector
