import { AccountType } from 'services/AuthenticationService'
import { NavigationDrawerType } from 'types/NavigationTypes'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import GroupIcon from '@mui/icons-material/Group'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import HandshakeIcon from '@mui/icons-material/Handshake'

const GeneralDrawerNavigation = (roles: AccountType['roles']): NavigationDrawerType => {
    var content: NavigationDrawerType = {
        title: 'Dashboard',
        sections: [
            [
                {
                    title: 'Projects',
                    link: '/',
                    icon: <DashboardCustomizeIcon color="inherit" />,
                },
                {
                    title: 'Intervention Providers',
                    link: '/admin/interventions',
                    icon: <HandshakeIcon color="inherit" />,
                    permissions: ['superadmin'],
                },
                {
                    title: 'Permission Management',
                    link: '/admin/permissions',
                    icon: <FingerprintIcon color="inherit" />,
                    permissions: ['superadmin'],
                },
                {
                    title: 'Identity Providers',
                    link: '/admin/idps',
                    icon: <VerifiedUserIcon color="inherit" />,
                    permissions: ['superadmin'],
                },
                {
                    title: 'User Management',
                    link: '/admin/users',
                    icon: <GroupIcon color="inherit" />,
                    permissions: ['superadmin'],
                },
            ],
        ],
        quickAccess: true,
        settings: {
            title: 'Account Settings',
            link: '/account',
        },
    }
    // filter sections based on role
    for (let idx in content.sections) {
        content.sections[idx] = content.sections[idx].filter(
            (x) => x.permissions === undefined || x.permissions.some((item) => roles.includes(item))
        )
    }

    content.sections = content.sections.filter((x) => x.length > 0)

    return content
}

export default GeneralDrawerNavigation
