import React, { useMemo, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box } from '@mui/material'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import Chart from 'features/chart/Chart'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import { BarSeriesOption } from 'echarts/charts'

interface NetworkAnalyticsInfoProps {
    analytics: NetworkVizContextType['analytics']
    alaamSettings: NetworkVizContextType['analyticsSettings']['alaam']
    title: string
    onClose?: () => void
}

export default function NetworkALAAMInfo({
    title,
    onClose,
    analytics,
    alaamSettings,
}: NetworkAnalyticsInfoProps) {
    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                maxHeight: 500,
                width: 350,
                padding: theme.spacing(1.5, 2),
            })}
        >
            {/* Title and close button
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={600} color="primary">
                    {title}
                </Typography>

                <IconButton onClick={onClose} size="small">
                    <CloseRoundedIcon fontSize="small" />
                </IconButton>
            </Stack>

            <NetworkALAAMInfoTable analytics={analytics} alaamSettings={alaamSettings} />
        </Stack>
    )
}

interface NetworkALAAMInfoTableProps {
    analytics: NetworkVizContextType['analytics']
    alaamSettings: NetworkVizContextType['analyticsSettings']['alaam']
}

export function NetworkALAAMInfoTable({ analytics, alaamSettings }: NetworkALAAMInfoTableProps) {
    const [selectedNetwork, setSelectedNetwork] = useState('')
    const [selectedAttribute, setSelectedAttribute] = useState('')

    const networkOptions = useMemo<string[]>(() => {
        const result: string[] = []
        if (analytics !== null) {
            for (let network in analytics) {
                if (analytics[network].alaam !== undefined) result.push(network)
            }
        }
        if (result.length > 0) setSelectedNetwork(result[0])
        return result
    }, [analytics])

    const echartsOptions = useMemo(() => {
        if (analytics?.[selectedNetwork]?.alaam?.[selectedAttribute] === undefined) return {}
        const data = analytics[selectedNetwork].alaam![selectedAttribute]!
        const paramsData = data.params_stats.map((param, idx) => {
            let color = '#7f7f7f' // Default color (gray)

            // If p_value < 0.05, change color to blue
            if (param.p_values < 0.05) {
                color = '#1f77b4'
            }

            return {
                name: param.name,
                value: param.coef,
                p_value: param.p_values,
                z: param.z,
                std_err: param.std_err,
                conf_int: param.conf_int,
                label: {
                    position: param.coef < 0 ? 'right' : 'left',
                },
                itemStyle: {
                    color: color, // Assign the color to each data item
                },
            }
        })

        const echartOptions: EChartsCustomOptionType = {
            tooltip: {
                trigger: 'axis',
                appendToBody: true,
                enterable: true,
                formatter: (params: any) => {
                    const data = params[0].data
                    return `
                        <div>Name: ${data.name}</div>
                        <div>Coef: ${data.value}</div>
                        <div>P value: ${data.p_value}</div>
                        <div>Std Err: ${data.std_err}</div>
                        <div>Z: ${data.z}</div>
                        <div>Conf Int: ${data.conf_int.join(' to ')}</div>
                    `
                },
                textStyle: {
                    width: 'auto',
                },
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                top: 30,
                bottom: 40,
            },
            yAxis: {
                type: 'category',
                data: paramsData.map((param) => param.name),
                axisLine: { show: false },
                axisLabel: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
            },
            xAxis: {
                type: 'value',
                position: 'top',
                min: -10, // Set according to your specific data range
                max: 10, // Set according to your specific data range
                axisLine: {
                    lineStyle: {
                        color: '#ccc',
                    },
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                    },
                },
            },
            graphic: {
                type: 'text',
                left: 'center',
                top: '90%',
                style: {
                    text: 'P-Value',
                    textAlign: 'center',
                    fontSize: 12,
                },
            },
            visualMap: {
                show: true,
                type: 'piecewise',
                orient: 'horizontal',
                left: 'center',
                bottom: 0,
                pieces: [
                    { lte: 0.05, color: '#1f77b4', label: '<= 0.05' },
                    { gt: 0.05, color: '#7f7f7f', label: '> 0.05' },
                ],
            },
            series: [
                {
                    name: 'Coef',
                    data: paramsData,
                    type: 'bar',
                    label: {
                        show: true,
                        overflow: 'truncate',
                        width: '130',
                        formatter: '{b}',
                    },
                },
            ] as BarSeriesOption[],
        }

        return echartOptions
    }, [analytics, selectedAttribute, selectedNetwork])

    return (
        <React.Fragment>
            <BaseSelectWithLabel
                // label="Relationship"
                hiddenLabel
                value={selectedNetwork}
                size="small"
                onChange={(v) => setSelectedNetwork(v)}
                options={networkOptions}
            />
            <BaseSelectWithLabel
                // label="Attribute"
                hiddenLabel
                value={selectedAttribute}
                size="small"
                onChange={(v) => setSelectedAttribute(v)}
                options={alaamSettings.targetAttributes}
            />
            <Box height="500px">
                <Chart type="bar" options={echartsOptions} clearOnChange={true} />
            </Box>
        </React.Fragment>
    )
}
