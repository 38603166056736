//* ======= Libraries
import React, { useContext, useEffect, useMemo, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import AddSurveyParticipantsDialog from 'features/survey-participants/AddSurveyParticipantsDialog'
//* ======= Custom logic
//* ======= Assets and styles
import InfoIcon from '@mui/icons-material/Info'
import AddIcon from '@mui/icons-material/Add'
import FilterListIcon from '@mui/icons-material/FilterList'
import DownloadIcon from '@mui/icons-material/Download'
import { SurveyContext } from 'contexts/SurveyContex'
import { Link, useParams } from 'react-router-dom'
import { validateSurveyPath } from 'helpers/PathValidator'
import {
    DeleteSurveyParticipants,
    GetSurveyParticipants,
    SurveyParticipantType,
} from 'services/SurveyService'
import { GetRespondentsService, RespondentType } from 'services/NodeService'
import Delete from '@mui/icons-material/Delete'
import { GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import ConfirmDialog from 'features/confirm-dialog/ConfirmDialog'
import { toast } from 'react-toastify'
import { ProjectContext } from 'contexts/ProjectContext'
import { PERMISSIONS } from 'helpers/constants'

interface ISurveyParticipants {}

function SurveyParticipants({}: ISurveyParticipants) {
    const { drawerContent } = useContext(SurveyContext)
    const { hasPermission } = useContext(ProjectContext)

    const canAccessNode = useMemo(() => hasPermission(PERMISSIONS.node.ReadSpecificNode), [hasPermission])

    const { pid, sid } = useParams()
    const gridApi = useGridApiRef()

    const [dialogState, setDialogState] = useState<{
        type: 'add-participants' | 'SOMETHING_ELSE'
        isOpen: boolean
    }>({
        type: 'add-participants',
        isOpen: false,
    })

    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([])
    const [participants, setParticipants] = useState<SurveyParticipantType[]>([])
    const [respondents, setRespondents] = useState<RespondentType[]>([])
    const [loading, setLoading] = useState(false)

    const [isDeleteNodeDialogOpen, setIsDeleteNodeDialogOpen] = useState<{ open: boolean; ids?: number[] }>({
        open: false,
    })

    const openDeleteNodeDialog = (nids: number[]) => {
        setIsDeleteNodeDialogOpen({ open: true, ids: nids })
    }

    const closeDeleteNodeDialog = () => {
        setIsDeleteNodeDialogOpen({ open: false })
    }

    useEffect(() => {
        if (pid && sid && validateSurveyPath(pid, sid)) {
            setLoading(true)
            GetSurveyParticipants(parseInt(pid), parseInt(sid))
                .then((res) => {
                    res.success && setParticipants(res.data)
                })
                .finally(() => setLoading(false))
            GetRespondentsService(pid).then((res) => res.success && setRespondents(res.data))
        }
    }, [pid, sid])

    const excludedRespondents = useMemo(() => {
        const ids = participants.map((p) => p.nodeAccountId)
        return respondents.filter((r) => !ids.includes(r.primaryKey))
    }, [respondents, participants])

    const deleteParticipants = () => {
        if (pid && sid && isDeleteNodeDialogOpen.ids) {
            const toastId = toast.loading('Deleting participants...')
            const ids = isDeleteNodeDialogOpen.ids
            DeleteSurveyParticipants(
                pid,
                sid,
                ids.map((v) => ({ id: v }))
            )
                .then((res) => {
                    if (res.success) {
                        closeDeleteNodeDialog()
                        setParticipants((pp) => pp.filter((p) => !ids.includes(p.primaryKey)))
                        toast.update(toastId, {
                            render: 'Participants deleted successfully!',
                            type: 'success',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    } else {
                        toast.update(toastId, {
                            render: 'Something went wrong!',
                            type: 'error',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    }
                })
                .catch((err) => {
                    toast.update(toastId, {
                        render: 'Something went wrong!',
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000,
                        closeButton: null,
                    })
                })
        }
    }

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack
                    height="100%"
                    sx={(theme) => ({
                        paddingBottom: 2,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? theme.palette.common.bg_1
                                : theme.palette.common.bg_3,
                    })}
                >
                    {/* Title and head controls
						========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            py: 1.25,
                            px: 3,

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Title
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography fontSize={20} fontWeight={500} color="primary">
                                Participants List
                            </Typography>

                            <Tooltip title="Some info!">
                                <InfoIcon
                                    sx={{
                                        height: '16px',
                                        width: '16px',

                                        color: 'primary.main',
                                    }}
                                />
                            </Tooltip>
                        </Stack>

                        {/* Controls
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            {/* Delete selected participants button
								========================================= */}
                            {selectionModel?.length > 0 && (
                                <BaseButton
                                    label="Delete selected Participant"
                                    onClick={() =>
                                        openDeleteNodeDialog(selectionModel.map((m) => parseInt(m + '')))
                                    }
                                    variant="outlined"
                                    size="large"
                                    color="warning"
                                    endIcon={<Delete />}
                                    sx={{
                                        marginRight: 1,
                                    }}
                                />
                            )}

                            {/* Add participants button
								========================================= */}
                            <BaseButton
                                label="Add Participant"
                                onClick={(e) => setDialogState({ type: 'add-participants', isOpen: true })}
                                variant="outlined"
                                size="large"
                                endIcon={<AddIcon />}
                                sx={{
                                    marginRight: 1,
                                }}
                            />

                            {/* Search
								========================================= */}
                            {/* <SearchField
                                options={['Something']}
                                placeholder="Search"
                                sx={{
                                    marginRight: 1,
                                }}
                            /> */}

                            {/* Filter button
								========================================= */}
                            <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <FilterListIcon />
                            </IconButton>

                            {/* Download button
								========================================= */}
                            <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Stack>
                    </Stack>

                    {/* Participants table
						========================================= */}
                    <Box
                        flexGrow={1}
                        sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingRight: 2,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <StyledDataGrid
                            loading={loading}
                            apiRef={gridApi}
                            rows={participants}
                            checkboxSelection
                            columns={[
                                {
                                    field: 'accountName',
                                    headerName: 'Name',
                                },
                                {
                                    field: 'nodeTitle',
                                    headerName: 'Node',
                                    renderCell: canAccessNode
                                        ? ({ value, row }) => (
                                              <Link to={`/project/${pid}/nodes/${row.nodeId}`}>{value}</Link>
                                          )
                                        : undefined,
                                },
                                {
                                    field: 'accountEmail',
                                    headerName: 'Email',
                                },
                                {
                                    field: 'accountPhoneNumber',
                                    headerName: 'Phone number',
                                },
                            ]}
                            onRowSelectionModelChange={(model) => {
                                setSelectionModel(model)
                            }}
                        />
                    </Box>

                    {/* Rows and page selection controls
						========================================= */}
                    {/* <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={{
                            padding: 2,
                        }}
                    >
                        (Rows per page - Page selection)
                    </Stack> */}
                </Stack>
            </PageCard.Body>

            {/* Dialogs
				========================================= */}
            <StyledDialog
                open={dialogState.isOpen}
                onClose={(event, reason) => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                maxWidth="xl"
                fullWidth
            >
                {dialogState.type === 'add-participants' ? (
                    <AddSurveyParticipantsDialog
                        respondents={excludedRespondents}
                        onConfirm={() => {
                            if (pid && sid)
                                GetSurveyParticipants(parseInt(pid), parseInt(sid)).then(
                                    (res) => res.success && setParticipants(res.data)
                                )
                            setDialogState((prevState) => ({ ...prevState, isOpen: false }))
                        }}
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : (
                    false
                )}
            </StyledDialog>

            {isDeleteNodeDialogOpen.open && (
                <ConfirmDialog
                    hasForceOption
                    confirmMessage="Delete All"
                    actionLabel="Remove All"
                    closeLabel="Close"
                    content="Are you sure you want to remove the selected participants?"
                    title={`Delete ${isDeleteNodeDialogOpen.ids?.length} participant(s)`}
                    open={isDeleteNodeDialogOpen.open}
                    onConfirm={deleteParticipants}
                    onClose={closeDeleteNodeDialog}
                />
            )}
        </PageCard>
    )
}

export default SurveyParticipants
