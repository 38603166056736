//* ======= Libraries
import React, { ReactElement, useContext, useEffect, useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseButton from 'components/base/BaseButton'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
//* ======= Assets and styles
import { useParams } from 'react-router-dom'
import { GetNodeOtherFiledsService } from 'services/NodeService'
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { SurveyInstanceBasicType } from 'services/SurveyService'
import ChipGroupByMultiSelect from 'components/chip-multi-select/ChipGroupByMultiSelect'
import QRCode from 'qrcode'

import { SurveyContext } from 'contexts/SurveyContex'
import moment from 'moment'

interface IGeneratePdfInvitesDialog {
    onConfirm?: () => void
    onClose?: () => void
    instances: SurveyInstanceBasicType[]
}

const styles = StyleSheet.create({
    page: {
        margin: 10,
    },
    title: {
        fontSize: 18,
        textAlign: 'center',
        fontFamily: 'Times-Roman',
    },
    text: {
        marginVertical: 6,
        fontSize: 14,
        textAlign: 'left',
        fontFamily: 'Times-Roman',
        textOverflow: 'ellipsis',
    },
    header: {
        fontSize: 12,
        marginBottom: 8,
        textAlign: 'center',
        color: 'grey',
    },
})

function GeneratePdfInvitesDialog({ onConfirm, onClose, instances }: IGeneratePdfInvitesDialog) {
    const { pid } = useParams()
    const { survey } = useContext(SurveyContext)
    const [otherFileds, setOtherFileds] = useState<string[]>([])
    const [columnNumber, setColumnNumber] = React.useState(2)
    const [hasQR, setHasQR] = useState(true)
    const [groupBy, setGroupBy] = React.useState([])
    const [document, setDocument] = useState<ReactElement>()

    useEffect(() => {
        if (pid) {
            GetNodeOtherFiledsService(pid).then((res) => {
                if (res.success) {
                    setOtherFileds(res.data.map((d) => d.field))
                }
            })
        }
    }, [])

    useEffect(() => {
        GenerateDocument()
    }, [instances, survey])

    const GenerateDocument = async () => {
        if (survey == null) return
        const data: {
            [key: string]: {
                fullname: string
                passcode: string
                qrCode: string
                round?: number
            }[]
        } = {}
        if (!groupBy) {
            data['all'] = []
        }
        if (groupBy) {
            for (let item of instances) {
                const dataItem: {
                    fullname: string
                    passcode: string
                    qrCode: string
                    round?: number
                } = {
                    fullname: item.respondent.name || '',
                    passcode: item.passCode,
                    qrCode: await QRCode.toDataURL(`${process.env.REACT_APP_BASE_URL}survey/si/${item.accessToken}`),
                    round: item.round,
                }
                if (groupBy) {
                    let addiationalInfo = item.others || {}

                    let key = ''
                    for (let g of groupBy) {
                        key +=
                            (typeof addiationalInfo[g] == 'string'
                                ? addiationalInfo[g]
                                : JSON.stringify(addiationalInfo[g])) + ','
                    }
                    key = key.substring(0, key.length - 1)

                    if (data[key]) {
                        data[key].push(dataItem)
                    } else {
                        data[key] = [dataItem]
                    }
                } else {
                    data['all'].push(dataItem)
                }
            }
            const groups = Object.keys(data)
            groups.sort()

            setDocument(<Document>{groups.map((group) => GeneratePage(data[group], group))}</Document>)
        } else {
            setDocument(<Document>{GeneratePage(data['all'])}</Document>)
        }
    }

    const hyphenationCallback = (words: string) => {
        return [words]
    }

    const GeneratePage = (
        data: {
            fullname: string
            passcode: string
            qrCode: string
            round?: number
        }[],
        group?: string
    ) => {
        if (!data || survey === undefined) return
        const date = 'Date: ' + moment(new Date()).format('DD/MM/YYYY')
        if (hasQR) {
            return (
                <Page key={group + 'qr'} size="A4" style={styles.page}>
                    <Text style={{ ...styles.header, marginBottom: 4 }} fixed>
                        Survey: {survey.title}
                    </Text>
                    {group && (
                        <Text style={styles.header} fixed>
                            {groupBy.join(', ')}: {group}
                        </Text>
                    )}
                    <Text style={styles.header} fixed>
                        {date}
                    </Text>
                    {data.map((item) => (
                        <View
                            key={item.passcode}
                            wrap={false}
                            style={{
                                flexDirection: 'row',
                                borderBottom: '1px dotted gray',
                                width: '90vw',
                                marginHorizontal: '5vw',
                                padding: 5,
                            }}
                        >
                            <View
                                style={{
                                    alignContent: 'center',
                                    padding: 5,
                                    backgroundColor: '#ededed',
                                    width: '65vw',
                                }}
                            >
                                <Text style={styles.text}>User: {item.fullname}</Text>
                                <Text hyphenationCallback={hyphenationCallback} style={styles.text}>
                                    URL: {process.env.REACT_APP_BASE_URL + 'survey/' + survey.id}
                                </Text>
                                <Text hyphenationCallback={hyphenationCallback} style={styles.text}>
                                    Passcode: {item.passcode}
                                </Text>
                            </View>
                            <View style={{ alignContent: 'center', width: '25vw' }}>
                                <Text style={styles.header}>Or scan this QR code</Text>
                                <Image src={item.qrCode} style={{ marginLeft: 25, width: 100, height: 100 }} />
                            </View>
                        </View>
                    ))}
                </Page>
            )
        }

        const temp = []
        for (let i = 0; i < data.length; i += columnNumber) {
            const row = []
            for (let j = i; j < Math.min(data.length, i + columnNumber); j++) {
                row.push(data[j])
            }
            temp.push(row)
        }

        let groupByText = null

        if (group && groupBy && groupBy.length > 0) {
            groupByText = ''
            const values = group.split(',')
            for (let i = 0; i < groupBy.length; i++) {
                groupByText += ` | ${groupBy[i]}: ${values[i]}`
            }
            groupByText = groupByText.substring(2)
        }

        return (
            <Page key={group} size="A4" style={styles.page}>
                <Text style={{ ...styles.header, marginBottom: 4 }} fixed>
                    Survey: {survey.title}
                </Text>
                {groupByText && (
                    <Text style={styles.header} fixed>
                        {groupByText}
                    </Text>
                )}
                <Text style={styles.header} fixed>
                    {date}
                </Text>
                {temp.map((row, index) => (
                    <View
                        key={index}
                        wrap={false}
                        style={{
                            flexDirection: 'row',
                            borderBottom: '1px dotted gray',
                            width: '90vw',
                            marginHorizontal: '5vw',
                            padding: 5,
                        }}
                    >
                        {row.map((item) => (
                            <View
                                style={{
                                    alignContent: 'center',
                                    borderRight: '1px dotted gray',
                                    marginHorizontal: 5,
                                    padding: 5,
                                    backgroundColor: '#ededed',
                                    width: 90 / columnNumber + 'vw',
                                }}
                            >
                                <Text style={styles.text}>User: {item.fullname}</Text>
                                <Text style={styles.text}>
                                    URL: {process.env.REACT_APP_BASE_URL + 'survey/' + survey.id}
                                </Text>
                                <Text style={styles.text}>Passcode: {item.passcode}</Text>
                                {item.round != null && <Text style={styles.text}>Round: {item.round}</Text>}
                            </View>
                        ))}
                    </View>
                ))}
            </Page>
        )
    }

    return (
        <Stack gap={2} height="80vh" paddingTop={0.5} paddingBottom={1}>
            {/* Header
				========================================= */}
            <Stack
                direction="row"
                alignItems="center"
                gap={2}
                py={2}
                px={3}
                sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.common.border_2}`,
                    paddingBottom: '50px',
                })}
            >
                {/* Group by select
					========================================= */}
                {otherFileds.length > 0 && (
                    <Box sx={{ flexGrow: 1 }}>
                        <ChipGroupByMultiSelect
                            defaultValue={groupBy}
                            onChange={(v) => {
                                setGroupBy(v)
                            }}
                            suggestions={otherFileds}
                            allowArbitary={false}
                            placeholder="Group By"
                        />
                    </Box>
                )}

                {/* Number of columns select
					========================================= */}
                {!hasQR && (
                    <BaseSelectWithLabel
                        name="colNumberSelect"
                        label="Columns per row"
                        size="small"
                        value={columnNumber}
                        sx={{ width: '150px' }}
                        onChange={(v) => setColumnNumber(v)}
                        options={[1, 2, 3, 4]}
                        placeholder="No. of Columns"
                    />
                )}

                {/* QR code switch
					========================================= */}
                <FormGroup aria-label="qr-code" row>
                    <FormControlLabel
                        checked={hasQR}
                        onChange={() => setHasQR((v) => !v)}
                        control={<BaseSwitch color="primary" />}
                        label="Add QR code"
                        labelPlacement="start"
                        sx={{
                            // gap: 2,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                                fontWeight: 400,

                                color: 'common.text_1',
                            },
                        }}
                    />
                </FormGroup>

                {/* Apply button
					========================================= */}
                <BaseButton
                    label="Apply"
                    variant="contained"
                    onClick={GenerateDocument}
                    size="large"
                    sx={{
                        width: 180,
                        marginLeft: 'auto',
                    }}
                />
            </Stack>

            {/* PDF Viewer
				========================================= */}
            <Box
                sx={{
                    flexGrow: 1,

                    backgroundColor: 'common.fuschia60',
                }}
            >
                {document && (
                    <PDFViewer showToolbar={true} style={{ width: '100%', height: '100%' }}>
                        {document}
                    </PDFViewer>
                )}
            </Box>

            {/* Footer controls
				========================================= */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3} py={1} px={3}>
                {/* Cancel button
					========================================= */}
                <BaseButton
                    label="Close"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    size="large"
                    sx={{
                        width: 180,
                    }}
                />

                {/* Print button
					========================================= */}
                {/* <BaseButton
                    label="Print"
                    onClick={(e) => onConfirm && onConfirm()}
                    variant="contained"
                    size="large"
                    startIcon={<PrintIcon />}
                    sx={{
                        width: 180,
                    }}
                /> */}
            </Stack>
        </Stack>
    )
}

export default GeneratePdfInvitesDialog
