import { useState } from 'react'
import AddDataSourceDialog from './AddDatasourceDialog'
import BaseButton from 'components/base/BaseButton'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { TimePanelType } from 'features/network-viz/types/NetworkViz.types'

export default function NetworkTimepanelSetting() {
    const { timePanel, nodeDataSchema, edgeDataSchema } =
        useNetworkVizContext()

    const dispatchContext = useNetworkVizDispatch()

    const [localState, setLocalState] = useState<TimePanelType>(timePanel)

    const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)

    const handleOpenSaveDialog = () => setSaveDialogOpen(true)
    const handleCloseSaveDialog = () => setSaveDialogOpen(false)

    const updateTimePanelState = () => {
        dispatchContext({ type: 'TIME_PANEL_EDIT', payload: { ...localState } });
    }


    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={500}>
                    Data Source
                </Typography>

                <BaseButton label="Add" onClick={handleOpenSaveDialog} startIcon={<AddIcon />} />
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={1} minWidth={0}>
                <FormGroup aria-label="enable-time" row>
                    <FormControlLabel
                        label="Enable"
                        control={<BaseSwitch color="primary" />}
                        labelPlacement="start"
                        checked={localState.show}
                        onChange={() => setLocalState((lv) => ({ ...lv, show: !lv.show }))}
                        sx={{
                            gap: 1,
                            marginRight: 0,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 16,
                                fontWeight: 400,

                                color: 'common.text_1',
                            },
                        }}
                    />
                </FormGroup>
                <Collapse in={localState.show} orientation="vertical" timeout="auto" unmountOnExit>
                    <Stack gap={1} minWidth={0}>
                        <BaseSelectWithLabel
                            label="Node Time Attribute"
                            value={localState.nodeAttribute}
                            onChange={(v) => setLocalState((lv) => ({ ...lv, nodeAttribute: v }))}
                            options={Object.keys(nodeDataSchema.fields)}
                        />
                        <BaseSelectWithLabel
                            label="Edge Time Attribute"
                            value={localState.edgeAttribute}
                            onChange={(v) => setLocalState((lv) => ({ ...lv, edgeAttribute: v }))}
                            options={Object.keys(edgeDataSchema)}
                        />
                        <BaseSelectWithLabel
                            label="Interval"
                            value={localState.interval}
                            onChange={(v) => setLocalState((lv) => ({ ...lv, interval: v }))}
                            options={[
                                { label: 'Every minute', value: 'minutes' },
                                { label: 'Every hour', value: 'hours' },
                                { label: 'Every day', value: 'days' },
                                { label: 'Every week', value: 'weeks' },
                                { label: 'Every month', value: 'months' },
                                { label: 'Every year', value: 'years' },
                            ]}
                        />
                    </Stack>
                </Collapse>
                {/* Apply changes button
                        ========================================= */}
                <BaseButton
                    fullWidth
                    variant="outlined"
                    sx={{ marginTop: 2 }}
                    onClick={updateTimePanelState}
                // onClick={applyChanges('normal')}
                >
                    Apply Changes
                </BaseButton>
            </Stack>

            {/* Dialog
                ========================================= */}
            {saveDialogOpen && <AddDataSourceDialog onClose={handleCloseSaveDialog} opne={saveDialogOpen} />}
        </Stack>
    )
}
