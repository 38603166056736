import { BaseResponseType, request } from './request'
import { SuperAdminApis as Apis, parseUrl } from './Apis'
import { AccountType } from './AuthenticationService'

export const FactoryResetPermissionsService: (showMessage?: boolean) => Promise<BaseResponseType> = async (
    showMessage = false
) => {
    return request(
        Apis.FactoryResetPermissions,
        'POST',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        true
    )
}

export type ProjectRolePermissionType = {
    projectRoleID: number
    projectPermissionID: number
}

export type ProjectPermissionType = {
    primaryKey: number
    permissionName: string
}

export type ProjectRoleType = {
    primaryKey: number
    roleName: string
    roleDescription: string
}

export type GetAllPermissionsResponseType = {
    data: {
        projectPermissions: Array<ProjectPermissionType>
        projectRoles: Array<ProjectRoleType>
        projectRoleProjectPermissions: Array<ProjectRolePermissionType>
    }
    success: boolean
}

export const GetAllPermissionsService: (
    showMessage?: boolean
) => Promise<GetAllPermissionsResponseType> = async (showMessage = false) => {
    return request(Apis.GetAllPermissions, 'GET', undefined, undefined, showMessage, undefined, false, true)
}

export type UpdatePermissionsRequestType = {
    projectRoleProjectPermissions: Array<ProjectRolePermissionType>
}

export const UpdatePermissionsService: (
    body: UpdatePermissionsRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (body, showMessage = false) => {
    return request(Apis.UpdatePermissions, 'PUT', undefined, body, showMessage, undefined, false, true)
}

/// Get All IDPs

export type IDPType = {
    primaryKey: number
    organizationDomain: string
    entityId: string
    metadataUrl: string
    loadMetadata: boolean
}

export type GetAllIDPsResponseType = {
    data: Array<IDPType>
    success: boolean
}

export const GetAllIDPsService: (showMessage?: boolean) => Promise<GetAllIDPsResponseType> = async (
    showMessage = false
) => {
    return request(Apis.GetAllIDPs, 'GET', undefined, undefined, showMessage, undefined, false, true)
}

/// Create IDP

export type CreateIDPRequestType = {
    organizationDomain: string
    entityId: string
    metadataFile: string
    loadMetadata: boolean
}

export const CreateIDPService: (
    body: CreateIDPRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (body, showMessage = false) => {
    return request(Apis.CreateIDP, 'POST', undefined, body, showMessage, undefined, false, true)
}

/// Delete IDP
export const DeleteIDPService: (
    idpid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (idpid, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteIDP, { idpid }),
        'DELETE',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        true
    )
}

/// Update IDP

export const UpdateIDPService: (
    idpid: number | string,
    body: IDPType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (idpid, body, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateIDP, { idpid }),
        'PUT',
        undefined,
        body,
        showMessage,
        undefined,
        false,
        true
    )
}

/// Get All Users

export type UserManagmentAccountType = AccountType & { primaryKey: number; isActive: boolean }

export type GetAllUsersResponseType = {
    data: Array<UserManagmentAccountType>
    success: boolean
}

export const AdminGetAllUsersService: (showMessage?: boolean) => Promise<GetAllUsersResponseType> = async (
    showMessage = false
) => {
    return request(Apis.GetAllUsers, 'GET', undefined, undefined, showMessage, undefined, false, true)
}

/// Create IDP

export type CreateUserRequestType = {
    email: string
    roles: number[]
}

export type CreateUserResponseType = {
    success: boolean
    data: UserManagmentAccountType
}

export const AdminCreateUserService: (
    body: CreateUserRequestType,
    showMessage?: boolean
) => Promise<CreateUserResponseType> = async (body, showMessage = false) => {
    return request(Apis.CreateUser, 'POST', undefined, body, showMessage, undefined, false, true)
}

/// Delete IDP
export const AdminDeleteUserService: (
    uid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (uid, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteUser, { uid }),
        'DELETE',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        true
    )
}

/// Update IDP

export const AdminUpdateUserRoleService: (
    uid: number | string,
    body: { roles: number[] },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (uid, body, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateUserRole, { uid }),
        'PUT',
        undefined,
        body,
        showMessage,
        undefined,
        false,
        true
    )
}

/// Update IDP

export const AdminResendUserInvitationService: (
    uid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (uid, showMessage = false) => {
    return request(
        parseUrl(Apis.ResendInvitation, { uid }),
        'POST',
        undefined,
        undefined,
        showMessage,
        undefined,
        false,
        true
    )
}
