import { PartialDeep } from 'type-fest'
import {
    LineChartConfigOptionsType,
    BarChartConfigOptionsType,
    PieChartConfigOptionsType,
    ScatterChartConfigOptionsType,
    RadarChartConfigOptionsType,
    BoxplotChartConfigOptionsType,
    GaugeChartConfigOptionsType,
    PictorialBarChartConfigOptionsType,
    WordCloudChartConfigOptionsType,
} from 'features/chart/Chart.helper'
import {
    LineChartDataOptionsType,
    BarChartDataOptionsType,
    PieChartDataOptionsType,
    ScatterChartDataOptionsType,
    RadarChartDataOptionsType,
    BoxplotChartDataOptionsType,
    GaugeChartDataOptionsType,
    PictorialBarChartDataOptionsType,
    WordCloudChartDataOptionsType,
    TreemapChartDataOptionsType,
} from 'features/chart/Chart.data.helper'
import {
    ReportDataAggregationMethodType,
    ReportDataSortOrderType,
    ReportDataSourceAttributeType,
} from 'features/report-designer/types/reportDesigner.types'
import { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
import { FontPickerValueType } from 'components/widgets/FontPicker'

/*
 * Base
 * =========================================
 */

type DataDefinitionSeriesType = {
    title?: string
    attribute: ReportDataSourceAttributeType
    aggregationMethod: ReportDataAggregationMethodType
    color: {
        isEnabled: boolean
        value: string | undefined
    }
}

type ChartBaseConfigTextStylesType = {
    fontFamily: FontPickerValueType['fontFamily']
    fontSize: number
    fontWeight: 'normal' | 'bold'
    overflow?: 'none' | 'truncate' | 'wrap'
    color: string | undefined
}

export type ChartBaseConfigType = {
    decimalPrecision: number
    multiView: {
        isEnabled: boolean
        gridSize: {
            columns: number
            rows: number
        }
    }
    title: {
        isEnabled: boolean
        value: string
        secondaryValue?: string
        verticalAlign: 'top' | 'center' | 'bottom'
        horizontalAlign: 'left' | 'center' | 'right'
        styles?: ChartBaseConfigTextStylesType
    }
    legend: {
        isEnabled: boolean
        orientation: 'horizontal' | 'vertical'
        verticalAlign: 'top' | 'center' | 'bottom'
        horizontalAlign: 'left' | 'center' | 'right' | string
        styles?: ChartBaseConfigTextStylesType
    }
}

export type ChartBaseAxesConfigType = {
    xAxisOptions: {
        isEnabled: boolean
        bounds: {
            min: number | null
            max: number | null
        }
        name: string
        labels: {
            isEnabled: boolean
            styles?: ChartBaseConfigTextStylesType
            forceAllVisible: boolean
            rotation?: number
        }
    }
    yAxisOptions: {
        isEnabled: boolean
        bounds: {
            min: number | null
            max: number | null
        }
        name: string
        labels: {
            isEnabled: boolean
            styles?: ChartBaseConfigTextStylesType
            forceAllVisible: boolean
            rotation?: number
        }
    }
}

/*
 * Base - Echarts options
 * =========================================
 */

export type ChartBaseConfigOptionsType = PartialDeep<{
    title: {
        show: boolean
        top: string | number
        left: string | number
        // text
        text: string
        textStyle: {
            fontFamily: string
            fontSize: string | number
            fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
            overflow: 'break' | 'breakAll' | 'truncate' | 'none'
            color: string | undefined
        }
        // subtext
        subtext: string
        subtextStyle: {
            fontFamily: string
            fontSize: string | number
            fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
            overflow: 'break' | 'breakAll' | 'truncate' | 'none'
            color: string | undefined
        }
    }
    legend: {
        show: boolean
        type: string
        orient: 'vertical' | 'horizontal'
        top: 'top' | 'center' | 'bottom'
        left: string
        textStyle: {
            fontFamily: string
            fontSize: string | number
            fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
            overflow: 'break' | 'breakAll' | 'truncate' | 'none'
            color: string | undefined
        }
    }
}>

const ChartDefaultBaseConfigOptions: ChartBaseConfigOptionsType = {
    title: {
        show: false,
        top: 'top',
        left: 'center',
        text: '',
        textStyle: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
        subtext: '',
        subtextStyle: {
            fontFamily: 'Poppins',
            fontSize: 10,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        show: false,
        type: 'scroll',
        orient: 'horizontal',
        top: 'bottom',
        left: '8%',
        textStyle: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            overflow: 'none',
            color: '#333333FF',
        },
    },
}

export type ChartBaseAxesConfigOptionsType = PartialDeep<{
    xAxis: {
        show: boolean
        // bounds
        min: number | undefined
        max: number | undefined
        // name
        name: string
        nameTextStyle: {
            fontFamily: string
            fontSize: string | number
            fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
            color: string | undefined
        }
        // axis label
        axisLabel: {
            show: boolean
            fontFamily: string
            fontSize: string | number
            fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
            overflow: 'break' | 'breakAll' | 'truncate' | 'none'
            color: string | undefined
            interval: string | number
            rotate: number
        }
    }
    yAxis: {
        show: boolean
        // bounds
        min: number | undefined
        max: number | undefined
        // name
        name: string
        nameTextStyle: {
            fontFamily: string
            fontSize: string | number
            fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
            color: string | undefined
        }
        // axis label
        axisLabel: {
            show: boolean
            fontFamily: string
            fontSize: string | number
            fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
            overflow: 'break' | 'breakAll' | 'truncate' | 'none'
            color: string | undefined
            interval: string | number
            rotate: number
        }
    }
}>

const ChartDefaultBaseAxesConfigOptions: ChartBaseAxesConfigOptionsType = {
    xAxis: {
        show: true,
        min: undefined,
        max: undefined,
        name: '',
        nameTextStyle: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            // TODO: This is a hard-coded color from the default echarts theme.
            //       Add/configure echarts theme and then update this value.
            color: '#6E7079FF',
        },
        axisLabel: {
            show: true,
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            overflow: 'none',
            // TODO: This is a hard-coded color from the default echarts theme.
            //       Add/configure echarts theme and then update this value.
            color: '#6E7079FF',
            interval: 'auto',
            rotate: 0,
        },
    },
    yAxis: {
        show: true,
        min: undefined,
        max: undefined,
        name: '',
        nameTextStyle: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            // TODO: This is a hard-coded color from the default echarts theme.
            //       Add/configure echarts theme and then update this value.
            color: '#6E7079FF',
        },
        axisLabel: {
            show: true,
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            overflow: 'none',
            // TODO: This is a hard-coded color from the default echarts theme.
            //       Add/configure echarts theme and then update this value.
            color: '#6E7079FF',
            interval: 'auto',
            rotate: 0,
        },
    },
}

/*
 * Main types
 * =========================================
 */

export type ChartTypeType =
    | 'line'
    | 'bar'
    | 'pie'
    | 'scatter'
    | 'radar'
    | 'boxplot'
    | 'gauge'
    | 'pictorialBar'
    | 'wordCloud'
    | 'graph'
    | 'treemap'
    | 'sunburst'
    | 'heatmap'

export type ChartDataDefinitionType =
    | LineChartDataDefinitionType
    | BarChartDataDefinitionType
    | PieChartDataDefinitionType
    | ScatterChartDataDefinitionType
    | RadarChartDataDefinitionType
    | BoxplotChartDataDefinitionType
    | GaugeChartDataDefinitionType
    | PictorialBarChartDataDefinitionType
    | WordCloudChartDataDefinitionType
    | GraphChartDataDefinitionType
    | TreemapChartDataDefinitionType

export type ChartConfigType =
    | LineChartConfigType
    | BarChartConfigType
    | PieChartConfigType
    | ScatterChartConfigType
    | RadarChartConfigType
    | BoxplotChartConfigType
    | GaugeChartConfigType
    | PictorialBarChartConfigType
    | WordCloudChartConfigType
    | GraphChartConfigType
    | TreemapChartConfigType

export type ChartOptionsType =
    | LineChartOptionsType
    | BarChartOptionsType
    | PieChartOptionsType
    | ScatterChartOptionsType
    | RadarChartOptionsType
    | BoxplotChartOptionsType
    | GaugeChartOptionsType
    | PictorialBarChartOptionsType
    | WordCloudChartOptionsType
    | GraphChartOptionsType
    | TreemapChartOptionsType

export type ChartType =
    | LineChartType
    | BarChartType
    | PieChartType
    | ScatterChartType
    | RadarChartType
    | BoxplotChartType
    | GaugeChartType
    | PictorialBarChartType
    | WordCloudChartType
    | GraphChartType
    | TreemapChartType

/*
 * Line chart
 * =========================================
 */

export type LineChartDataDefinitionType = {
    xAxisField: ReportDataSourceAttributeType
    series: DataDefinitionSeriesType[]
}

export type LineChartConfigType = ChartBaseConfigType &
    ChartBaseAxesConfigType & {
        // TBD
    }

//* Echarts options
export type LineChartOptionsType = LineChartDataOptionsType & LineChartConfigOptionsType

export type LineChartType = {
    type: Extract<ChartTypeType, 'line'>
    dataDefinition: LineChartDataDefinitionType
    config: LineChartConfigType
    options: LineChartOptionsType | null
}

export const LineChartDefaultDataDefinition: LineChartDataDefinitionType = {
    xAxisField: {
        type: 'basic',
        field: '',
    },
    series: [],
}

export const LineChartDefaultConfig: LineChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Line Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
    xAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
    yAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
}

export const LineChartDefaultOptions: LineChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,
    ...ChartDefaultBaseAxesConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        trigger: 'axis',
        appendToBody: true,
    },
}

/*
 * Bar chart
 * =========================================
 */

export type BarChartDataDefinitionType = {
    xAxisField: ReportDataSourceAttributeType
    series: DataDefinitionSeriesType[]
}

export type BarChartConfigType = ChartBaseConfigType &
    ChartBaseAxesConfigType & {
        // TBD
    }

//* Echarts options
export type BarChartOptionsType = BarChartDataOptionsType & BarChartConfigOptionsType

export type BarChartType = {
    type: Extract<ChartTypeType, 'bar'>
    dataDefinition: BarChartDataDefinitionType
    config: BarChartConfigType
    options: BarChartOptionsType | null
}

export const BarChartDefaultDataDefinition: BarChartDataDefinitionType = {
    xAxisField: {
        type: 'basic',
        field: '',
    },
    series: [],
}

export const BarChartDefaultConfig: BarChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Bar Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
    xAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
    yAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
}

export const BarChartDefaultOptions: BarChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,
    ...ChartDefaultBaseAxesConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        trigger: 'item',
        appendToBody: true,
    },
}

/*
 * Pie chart
 * =========================================
 */

export type PieChartDataDefinitionType = {
    categoryField: ReportDataSourceAttributeType
    series: Omit<DataDefinitionSeriesType, 'color'>
}

export type PieChartConfigType = ChartBaseConfigType & {
    // TBD
}

//* Echarts options
export type PieChartOptionsType = PieChartDataOptionsType & PieChartConfigOptionsType

export type PieChartType = {
    type: Extract<ChartTypeType, 'pie'>
    dataDefinition: PieChartDataDefinitionType
    config: PieChartConfigType
    options: PieChartOptionsType | null
}

export const PieChartDefaultDataDefinition: PieChartDataDefinitionType = {
    categoryField: {
        type: 'basic',
        field: '',
    },
    series: {
        aggregationMethod: 'avg',
        attribute: {
            field: '',
            type: 'basic',
        },
    },
}

export const PieChartDefaultConfig: PieChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Pie Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
}

export const PieChartDefaultOptions: PieChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        trigger: 'item',
        appendToBody: true,
    },
}

/*
 * Scatter chart
 * =========================================
 */

export type ScatterChartDataDefinitionType = {
    xAxisField: ReportDataSourceAttributeType
    yAxisField: ReportDataSourceAttributeType
    color?: string | undefined
}

export type ScatterChartConfigType = ChartBaseConfigType &
    ChartBaseAxesConfigType & {
        // TBD
    }

//* Echarts options
export type ScatterChartOptionsType = ScatterChartDataOptionsType & ScatterChartConfigOptionsType

export type ScatterChartType = {
    type: Extract<ChartTypeType, 'scatter'>
    dataDefinition: ScatterChartDataDefinitionType
    config: ScatterChartConfigType
    options: ScatterChartOptionsType | null
}

export const ScatterChartDefaultDataDefinition: ScatterChartDataDefinitionType = {
    xAxisField: {
        type: 'basic',
        field: '',
    },
    yAxisField: {
        type: 'basic',
        field: '',
    },
    color: '#2D77BC',
}

export const ScatterChartDefaultConfig: ScatterChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Scatter Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
    xAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
    yAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
}

export const ScatterChartDefaultOptions: ScatterChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,
    ...ChartDefaultBaseAxesConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        trigger: 'item',
        appendToBody: true,
    },
}

/*
 * Radar chart
 * =========================================
 */

export type RadarChartDataDefinitionType = {
    indicators: Array<{
        field: ReportDataSourceAttributeType
        max: number
        title?: string
    }>
    series: Array<
        DataDefinitionSeriesType & {
            fieldLabel?: ReportDataSourceAttributeType
        }
    >
}

export type RadarChartConfigType = ChartBaseConfigType & {
    shape: 'polygon' | 'circle'
    splitArea: {
        isEnabled: boolean
        colors: string[] | undefined
    }
    hasSplitLines: boolean
    hasAxisLines: boolean
    symbols: {
        shape: 'circle' | 'roundRect' | 'diamond'
        size: number
    }
}

//* Echarts options
export type RadarChartOptionsType = RadarChartDataOptionsType & RadarChartConfigOptionsType

export type RadarChartType = {
    type: Extract<ChartTypeType, 'radar'>
    dataDefinition: RadarChartDataDefinitionType
    config: RadarChartConfigType
    options: RadarChartOptionsType | null
}

export const RadarChartDefaultDataDefinition: RadarChartDataDefinitionType = {
    // TODO: What should the initial "max" value be?
    indicators: [
        {
            field: {
                type: 'basic',
                field: '',
            },
            max: 10,
        },
        {
            field: {
                type: 'basic',
                field: '',
            },
            max: 10,
        },
        {
            field: {
                type: 'basic',
                field: '',
            },
            max: 10,
        },
    ],
    series: [],
}

export const RadarChartDefaultConfig: RadarChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Radar Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'left',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 18,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
    // Chart-specific config
    shape: 'polygon',
    splitArea: {
        isEnabled: true,
        colors: ['#99999933', '#9999991A'],
    },
    hasSplitLines: true,
    hasAxisLines: true,
    symbols: {
        shape: 'circle',
        size: 8,
    },
}

export const RadarChartDefaultOptions: RadarChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        trigger: 'item',
        appendToBody: true,
    },
    //? Static option. Only assigned inside initial/default options object.
    grid: {
        containLabel: true,
    },
    radar: {
        //? Static option. Only assigned inside initial/default options object.
        radius: '65%',
        shape: 'polygon',
        splitArea: {
            show: true,
            areaStyle: {
                color: ['#99999933', '#9999991A'],
                //? Static option. Only assigned inside initial/default options object.
                opacity: 1,
            },
        },
        splitLine: {
            show: true,
        },
        axisLine: {
            show: true,
        },
    },
    series: {
        symbol: 'circle',
        symbolSize: 8,
    },
}

/*
 * Boxplot chart
 * =========================================
 */

export type BoxplotChartDataDefinitionType = {
    categoryField: ReportDataSourceAttributeType
    categoryOrder: ReportDataSortOrderType
    valueField: ReportDataSourceAttributeType
    categories: Array<{
        key: string | number
        color: string | undefined
    }>
}

export type BoxplotChartConfigType = ChartBaseConfigType &
    ChartBaseAxesConfigType & {
        orientation: 'horizontal' | 'vertical'
    }

//* Echarts options
export type BoxplotChartOptionsType = BoxplotChartDataOptionsType & BoxplotChartConfigOptionsType

export type BoxplotChartType = {
    type: Extract<ChartTypeType, 'boxplot'>
    dataDefinition: BoxplotChartDataDefinitionType
    config: BoxplotChartConfigType
    options: BoxplotChartOptionsType | null
}

export const BoxplotChartDefaultDataDefinition: BoxplotChartDataDefinitionType = {
    categoryField: {
        type: 'basic',
        field: '',
    },
    categoryOrder: 'asc',
    valueField: {
        type: 'basic',
        field: '',
    },
    categories: [],
}

export const BoxplotChartDefaultConfig: BoxplotChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Boxplot Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
    xAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
    yAxisOptions: {
        isEnabled: true,
        name: '',
        bounds: {
            min: null,
            max: null,
        },
        labels: {
            isEnabled: true,
            styles: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 'normal',
                // TODO: This is a hard-coded color from the default echarts theme.
                //       Add/configure echarts theme and then update this value.
                color: '#6E7079FF',
            },
            forceAllVisible: false,
            rotation: 0,
        },
    },
    orientation: 'horizontal',
}

export const BoxplotChartDefaultOptions: BoxplotChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,
    ...ChartDefaultBaseAxesConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        trigger: 'axis',
        confine: true,
        appendToBody: true,
    },
    xAxis: {
        nameLocation: 'middle',
        nameGap: 30,
        scale: true,
    },
    yAxis: {
        type: 'category',
    },
    //? Static option. Only assigned inside initial/default options object.
    legend: {
        selected: {
            detail: false,
        },
    },
}

/*
 * Gauge chart
 * =========================================
 */

export type GaugeChartDataDefinitionType = {
    indicator: {
        field: ReportDataSourceAttributeType

        compareField?: ReportDataSourceAttributeType
        aggregationMethod: ReportDataAggregationMethodType
        title?: string
        valueBounds: {
            min: number
            max: number
        }
    }
    isVisualizedByPercentage: boolean
    isColoredByValue: boolean
    colorStops: Array<{
        upperBoundValue: number
        color: string | undefined
    }>
    series: Array<
        DataDefinitionSeriesType & {
            fieldLabel?: ReportDataSourceAttributeType
        }
    >
}

export type GaugeChartConfigType = ChartBaseConfigType & {
    /*
     * viewMode should change the following properties inside "options":
     *      gauge:
     *              radius: '75%'
     *              startAngle: 200
     *              endAngle: -20
     *              center: ['50%', '60%']
     *              detail.offsetCenter: [0, '-10%']
     *              pointer: {
     *                  show: false,
     *              }
     *              progress: {
     *                  show: true,
     *              }
     *              axisLine: {
     *                  lineStyle: {
     *                      width: X or (20), => it's dynamic and has to be re-calculated on viewMode change.
     *                  }
     *              }
     *              splitLine: {
     *                  length: 10, (static)
     *                  distance: -40, -(axisLine width + splitLine length + 10) => it's dynamic and has to be re-calculated on viewMode change.
     *              }
     *              axisLabel: {
     *                  distance: -20,
     *              }
     *
     *      ring:
     *              radius: '70%'
     *              startAngle: 180
     *              endAngle: -180
     *              center: ['50%', '50%']
     *              detail.offsetCenter: [0, 0]
     *              pointer: {
     *                  show: false,
     *              }
     *              progress: {
     *                  show: true,
     *              }
     *              axisLine: {
     *                  lineStyle: {
     *                      width: X or (20), => it's dynamic and has to be re-calculated on viewMode change.
     *                  }
     *              }
     *              splitLine: {
     *                  length: 10, (static)
     *                  distance: -40, -(axisLine width + splitLine length + 10) => it's dynamic and has to be re-calculated on viewMode change.
     *              }
     *              axisLabel: {
     *                  distance: -20,
     *              }
     *
     *      speedometer:
     *              radius: '80%'
     *              startAngle: 200
     *              endAngle: -20
     *              center: ['50%', '60%']
     *              detail.offsetCenter: [0, '-10%']
     *              pointer: {
     *                  show: true,
     *              }
     *              progress: {
     *                  show: false,
     *              }
     *              axisLine: {
     *                  lineStyle: {
     *                      width: 30, (static)
     *                  }
     *              }
     *              splitLine: {
     *                  length: 30, (static, same as axisLine width)
     *                  distance: -30, (static, same as length)
     *              }
     *              axisLabel: {
     *                  distance: 40, (static, is equal to splitLine length + 10 gap)
     *              }
     * Naturally, config properties which are tied to those options should also change.
     */
    viewMode: 'gauge' | 'ring' | 'speedometer'
    progressBar: {
        roundCap: boolean
        borderStyle: {
            isEnabled: boolean
            width: number
            type: 'solid' | 'dashed' | 'dotted'
            color: string | undefined
        }
    }
    axisLine: {
        isEnabled: boolean
        roundCap: boolean
        color: string | undefined
    }
    // Split the circumference into sections
    splitNumber: number
    splitLine: {
        isEnabled: boolean
        color: string | undefined
    }
    labels: {
        isEnabled: boolean
        styles: ChartBaseConfigTextStylesType
    }
    valueLabel: {
        isEnabled: boolean
        // Should be constrained between 100 and -100 because it will
        // be converted to a relative percentage value.
        verticalOffset: number
        styles: ChartBaseConfigTextStylesType
    }
}

//* Echarts options
export type GaugeChartOptionsType = GaugeChartDataOptionsType & GaugeChartConfigOptionsType

export type GaugeChartType = {
    type: Extract<ChartTypeType, 'gauge'>
    dataDefinition: GaugeChartDataDefinitionType
    config: GaugeChartConfigType
    options: GaugeChartOptionsType | null
}

export const GaugeChartDefaultDataDefinition: GaugeChartDataDefinitionType = {
    indicator: {
        field: {
            type: 'basic',
            field: '',
        },
        aggregationMethod: 'avg',
        title: '',
        valueBounds: {
            min: 0,
            max: 100,
        },
    },
    isVisualizedByPercentage: false,
    isColoredByValue: false,
    colorStops: [],
    series: [],
}

export const GaugeChartDefaultConfig: GaugeChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Gauge Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
    // Chart-specific config
    viewMode: 'gauge',
    progressBar: {
        roundCap: false,
        borderStyle: {
            isEnabled: false,
            width: 1,
            type: 'solid',
            color: undefined,
        },
    },
    axisLine: {
        isEnabled: true,
        roundCap: false,
        color: '#E6EBF8FF',
    },
    splitNumber: 8,
    splitLine: {
        isEnabled: true,
        color: '#999999FF',
    },
    labels: {
        isEnabled: true,
        styles: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            color: '#999999FF',
        },
    },
    valueLabel: {
        isEnabled: true,
        verticalOffset: -10,
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#999999FF',
        },
    },
}

export const GaugeChartDefaultOptions: GaugeChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        formatter: '{b} : <strong>{c}</strong>',
        appendToBody: true,
    },
    series: {
        type: 'gauge',
        startAngle: 200,
        endAngle: -20,
        center: ['50%', '60%'],
        min: 0,
        max: 100,
        pointer: {
            show: false,
        },
        progress: {
            show: true,
            //? Static option. Only assigned inside initial/default options object.
            overlap: false,
            //? Static option. Only assigned inside initial/default options object.
            clip: false,
            roundCap: false,
            // We only add border styles here for now.
            itemStyle: undefined,
        },
        axisLine: {
            show: true,
            roundCap: false,
            lineStyle: {
                width: 20,
                color: [[1, '#E6EBF8']],
            },
        },
        splitNumber: 8,
        splitLine: {
            show: true,
            length: 10,
            // We put the splitLine on "top" of the value bars.
            // Distance should always be equal to the sum of axisLine width and splitLine length plus 10 (gap).
            distance: -40, // -(axisLine width + splitLine length + 10)
            lineStyle: {
                //? Static option. Only assigned inside initial/default options object.
                width: 3,
                color: '#999999FF',
            },
        },
        //? Static option. Only assigned inside initial/default options object.
        axisTick: {
            show: false,
            length: 6,
        },
        axisLabel: {
            show: true,
            distance: -20,
            //? Static option. Only assigned inside initial/default options object.
            width: 70,
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#999999FF',
        },
        title: {
            show: false,
        },
        detail: {
            show: true,
            //? Static option. Only assigned inside initial/default options object.
            valueAnimation: true,
            offsetCenter: [0, '-10%'],
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#999999FF',
        },
    },
}

type GaugeChartConfigBaseValuesType = Record<
    GaugeChartConfigType['viewMode'],
    {
        radius: string
        startAngle: number
        endAngle: number
        center: string[]
        axisLineWidth: number
        splitLineLength: number
        splitLineDistance: number
        // speedometer
        axisLabelDistance?: number
        // gauge and ring
        axisLabelDistanceAlignValue?: number
    }
>

// Constant config values categorized by "viewMode", needed for options generation.
export const GAUGE_CHART_CONFIG_BASE_VALUES: GaugeChartConfigBaseValuesType = {
    gauge: {
        radius: '75%',
        startAngle: 200,
        endAngle: -20,
        center: ['50%', '60%'],
        axisLineWidth: 20,
        splitLineLength: 10,
        splitLineDistance: -40,
        axisLabelDistanceAlignValue: -70,
    },
    ring: {
        radius: '70%',
        startAngle: 180,
        endAngle: -180,
        center: ['50%', '50%'],
        axisLineWidth: 20,
        splitLineLength: 10,
        splitLineDistance: -40,
        axisLabelDistanceAlignValue: -60,
    },
    speedometer: {
        radius: '85%',
        startAngle: 200,
        endAngle: -20,
        center: ['50%', '60%'],
        axisLineWidth: 30,
        splitLineLength: 30,
        splitLineDistance: -30,
        axisLabelDistance: 40,
    },
}

/*
 * Pictorial bar chart
 * =========================================
 */

export type PictorialBarChartDataDefinitionType = {
    aggregationMethod: ReportDataAggregationMethodType
    categoryField: ReportDataSourceAttributeType
    categoryOrder: ReportDataSortOrderType
    valueField?: ReportDataSourceAttributeType
    categories: Array<{
        key: string | number
        symbol: string
        color?: string
    }>
}

export type PictorialBarChartConfigType = ChartBaseConfigType & {
    // TBD
}

//* Echarts options
export type PictorialBarChartOptionsType = PictorialBarChartDataOptionsType & PictorialBarChartConfigOptionsType

export type PictorialBarChartType = {
    type: Extract<ChartTypeType, 'pictorialBar'>
    dataDefinition: PictorialBarChartDataDefinitionType
    config: PictorialBarChartConfigType
    options: PictorialBarChartOptionsType | null
}

export const PictorialBarChartDefaultDataDefinition: PictorialBarChartDataDefinitionType = {
    aggregationMethod: 'avg',
    categoryField: {
        type: 'basic',
        field: '',
    },
    categoryOrder: 'asc',
    valueField: {
        type: 'basic',
        field: '',
    },
    categories: [],
}

export const PictorialBarChartDefaultConfig: PictorialBarChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New PictorialBar Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
}

export const PictorialBarChartDefaultOptions: PictorialBarChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,

    //? Static option. Only assigned inside initial/default options object.
    tooltip: {
        trigger: 'item',
        appendToBody: true,
    },
    //? Static option. Only assigned inside initial/default options object.
    xAxis: {
        type: 'value',
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
    },
    // animationDurationUpdate: 250,
}

/*
 * Word cloud chart
 * =========================================
 */

export type WordCloudChartDataDefinitionType = {
    categoryField: ReportDataSourceAttributeType
}

export type WordCloudChartConfigType = ChartBaseConfigType & {
    // TBD
}

//* Echarts options
export type WordCloudChartOptionsType = WordCloudChartDataOptionsType & WordCloudChartConfigOptionsType

export type WordCloudChartType = {
    type: Extract<ChartTypeType, 'wordCloud'>
    dataDefinition: WordCloudChartDataDefinitionType
    config: WordCloudChartConfigType
    options: WordCloudChartOptionsType | null
}

export const WordCloudChartDefaultDataDefinition: WordCloudChartDataDefinitionType = {
    categoryField: {
        type: 'basic',
        field: '',
    },
}

export const WordCloudChartDefaultConfig: WordCloudChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Word cloud Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
}

export const WordCloudChartDefaultOptions: WordCloudChartOptionsType = {
    // Base default options
    ...ChartDefaultBaseConfigOptions,
}

/*
 * Graph chart
 * =========================================
 */

export type GraphChartDataDefinitionType = {
    // TBD
}

export type GraphChartConfigType = ChartBaseConfigType & {
    // TBD
}

//* Echarts options
export type GraphChartOptionsType = ChartBaseConfigOptionsType

export type GraphChartType = {
    type: Extract<ChartTypeType, 'graph'>
    dataDefinition: GraphChartDataDefinitionType
    config: GraphChartConfigType
    options: GraphChartOptionsType | null
}

export const GraphChartDefaultDataDefinition: GraphChartDataDefinitionType = {}

export const GraphChartDefaultConfig: GraphChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Graph Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
}

export const GraphChartDefaultOptions: GraphChartOptionsType = {}

/*
 * Treemap chart
 * =========================================
 */

export type TreemapChartDataDefinitionType = {
    groupByFields: ReportDataSourceAttributeType[]
    valueField: ReportDataSourceAttributeType | null
    labelField: ReportDataSourceAttributeType | null
}

export type TreemapChartConfigType = ChartBaseConfigType & {
    // TBD
}

//* Echarts options
export type TreemapChartOptionsType = TreemapChartDataOptionsType & ChartBaseConfigOptionsType

export type TreemapChartType = {
    type: Extract<ChartTypeType, 'treemap'>
    dataDefinition: TreemapChartDataDefinitionType
    config: TreemapChartConfigType
    options: TreemapChartOptionsType | null
}

export const TreemapChartDefaultDataDefinition: TreemapChartDataDefinitionType = {
    groupByFields: [],
    valueField: null,
    labelField: null,
}

export const TreemapChartDefaultConfig: TreemapChartConfigType = {
    decimalPrecision: 2,
    multiView: {
        isEnabled: false,
        gridSize: {
            columns: 2,
            rows: 2,
        },
    },
    title: {
        isEnabled: false,
        value: 'New Graph Chart',
        secondaryValue: '',
        verticalAlign: 'top',
        horizontalAlign: 'center',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'truncate',
            color: '#333333FF',
        },
    },
    legend: {
        isEnabled: false,
        orientation: 'horizontal',
        verticalAlign: 'bottom',
        horizontalAlign: '8%',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333FF',
        },
    },
}

export const TreemapChartDefaultOptions: TreemapChartOptionsType = {}

/*
 * Other
 * =========================================
 */

export const ChartMultiViewGridSizeOptions: BaseSelectWithLabelOptionType = [
    {
        label: '2 x 1',
        value: {
            columns: 2,
            rows: 1,
        },
    },
    {
        label: '2 x 2',
        value: {
            columns: 2,
            rows: 2,
        },
    },
    {
        label: '3 x 3',
        value: {
            columns: 3,
            rows: 3,
        },
    },
]
