//* ======= Libraries
import React from 'react'
import { Typography, Tooltip } from '@mui/material'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ReportSlideType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles

export const ReportSlidesListItemDragTypeValue = 'SLIDE'

type Props = {
    id: ReportSlideType['id']
    title: ReportSlideType['title']
    thumbnail: ReportSlideType['thumbnail']
    isActive: boolean
    onSelect: () => void
    getPositionIndex: (id: ReportSlideType['id']) => number
}

function ReportSlidesListItemBasic({ id, title, thumbnail, isActive, onSelect, getPositionIndex }: Props) {
    return (
        <Tooltip
            title={title.length > 14 ? title : ''}
            enterDelay={750}
            arrow
            placement="bottom"
            followCursor
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 16],
                        },
                    },
                ],
            }}
        >
            <BaseButton
                onClick={(evt) => onSelect()}
                variant="text"
                color="secondary"
                sx={(theme) => ({
                    justifyContent: 'flex-start',
                    gap: 1,

                    paddingY: theme.spacing(1.5),

                    boxShadow: isActive ? '0px 4px 4px rgba(33, 123, 191, 0.25)' : undefined,
                    borderBottom: '1px solid transparent',
                    borderColor: isActive ? theme.palette.primary.main : 'transparent',
                    color: theme.palette.common.text_1,

                    '&:hover': {
                        color: theme.palette.common.text_1,
                    },
                })}
            >
                <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={isActive ? 'primary' : 'common.text_3'}
                    sx={{
                        flex: '0 0 auto',
                    }}
                >
                    {getPositionIndex(id) + 1 + '.'}
                </Typography>

                <Typography
                    fontSize={14}
                    fontWeight={500}
                    noWrap
                    textAlign="left"
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    {title || 'Untitled Slide'}
                </Typography>
            </BaseButton>
        </Tooltip>
    )
}

export default ReportSlidesListItemBasic
