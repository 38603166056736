import React from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import BaseFilledTextField from './BaseFilledTextField'

const filter = createFilterOptions<OptionType>()

interface FreeSoloCreateOptionPropsType {
    value: OptionType | null
    setValue: (v: string | null) => void
    options: OptionType[]
    label?: string
    width?: string
    required?: boolean
}

export default function FreeSoloCreateOption({
    value,
    setValue,
    options,
    label,
    width,
    required,
}: FreeSoloCreateOptionPropsType) {
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                //
                if (typeof newValue === 'string') {
                    setValue(newValue)
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue(newValue.inputValue)
                } else {
                    setValue(newValue?.title || null)
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const { inputValue } = params
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title)
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    })
                }

                return filtered
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue
                }
                // Regular option
                return option.title
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            sx={{ width: width }}
            freeSolo
            renderInput={(params) => (
                <BaseFilledTextField required={required == true} {...params} label={label} />
            )}
        />
    )
}

interface OptionType {
    inputValue?: string
    title: string
}
