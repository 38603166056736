export const AuthenticationApis = {
    AuthMethod: '/auth/auth-method',
    Login: '/auth/login',
    Logout: '/auth/logout',
    GetUser: '/auth',
    ValidateInviteToken: 'auth/{token}',
    CreateAccount: '/auth/register/{token}',
    UpdateAccount: '/auth/account',
    ForgotPassword: '/auth/forgot-password',
    ResetPassword: '/auth/reset-password',
    UpdatePassword: '/auth/update-password',
    VerifyEmail: '/auth/verify-email/{code}',
    ResendEmailVerification: '/auth/resend-email-verification',
}

export const ProjectApis = {
    GetAll: '/project',
    GetAllPermissions: '/project/permissions',
    Create: '/project',
    Update: '/project/{pid}',
    UpdateThumbnail: '/project/{pid}/thumbnail',
    Get: '/project/{pid}',
    Search: '/project/search/{term}',
    GetProjectAccounts: '/project/{pid}/access',
    GetProjectRoles: '/project/{pid}/roles',
    Delete: '/project/{pid}?{force}',
    AddTag: '/project/{pid}/tag',
    RemoveTag: '/project/{pid}/tag/{tid}',
    AddUserAccess: '/project/{pid}/access',
    RemoveUserAccess: '/project/{pid}/access/{aid}',
    UpdateUserAccess: '/project/{pid}/access/{aid}',
    UpdateUserDataAccessView: '/project/{pid}/access/{aid}/data-access-view',
    ResendUserAccessInvitation: '/project/{pid}/access/{aid}',
    ToggleFavourite: '/project/{pid}/favourite',
    ToggleQuickAccess: '/project/{pid}/quick-access',
    ToggleArchived: '/project/{pid}/quick-access',
    UpdateTitle: '/project/{pid}/title',
    UpdateBaseColor: '/project/{pid}/color',
    GetProjectDataAccess: '/project/{pid}/data-access-view',
    AddProjectDataAccess: '/project/{pid}/data-access-view',
    AddProjectDataAccessBatch: '/project/{pid}/data-access-view/batch',
    DeleteProjectDataAccess: '/project/{pid}/data-access-view/{aid}',
    UpdateProjectDataAccess: '/project/{pid}/data-access-view/{aid}',
}

export const Location = {
    AddressSearch: '/location/{query}',
    Geocode: '/location/geocode/{query}',
    ReverseGeocode: '/location/{lat}/{lon}',
}

export const NodeApis = {
    GetAll: '/project/{pid}/node/getNodes',
    GetNode: '/project/{pid}/node/node/{nid}',
    update: '/project/{pid}/node/{nid}',
    Import: '/project/{pid}/node/bulk',
    BatchUpdate: '/project/{pid}/node/bulk',
    Delete: '/project/{pid}/node/bulk',
    GetRespondents: '/project/{pid}/node/respondents',
    GetOtherFields: '/project/{pid}/node/others',
    GetOtherFieldsWithUniqueValues: '/project/{pid}/node/uniqueotherskeysvalues',
    Search: '/project/{pid}/node/search/{term}',
}

export const SurveyApis = {
    GetAll: '/project/{pid}/survey',
    Create: '/project/{pid}/survey',
    Get: '/project/{pid}/survey/{sid}',
    GetSurveyInfo: '/project/{pid}/survey/{sid}/info',
    SendTestEmail: '/project/{pid}/survey/{sid}/testEmail',
    Delete: '/project/{pid}/survey/{sid}?{force}',
    GetSurveyProfile: '/project/{pid}/survey/{sid}/profile?{language}',
    UpdateSurveyProfile: '/project/{pid}/survey/{sid}/profile',
    UpdateSurveyReminderSchedule: '/project/{pid}/survey/{sid}/reminder/schedule',
    CreateSurveyProfile: '/project/{pid}/survey/{sid}/profile',
    UpdateTitle: '/project/{pid}/survey/{sid}/UpdateSurveyTitle',
    SendReminder: '/project/{pid}/survey/{sid}/reminder',
    GetSettings: '/project/{pid}/survey/{sid}/settings',
    UpdateSettingsSchedule: '/project/{pid}/survey/{sid}/settings/schedule',
    UpdateSettingsGeneral: '/project/{pid}/survey/{sid}/settings/general',
    GetLogs: '/project/{pid}/survey/{sid}/logs',
    GetAdminLogs: '/project/{pid}/survey/{sid}/logs/admin',
    GetParticipants: '/project/{pid}/survey/{sid}/userNodeSurvey',
    CreateParticipants: '/project/{pid}/survey/{sid}/userNodeSurvey',
    DeleteParticipants: '/project/{pid}/survey/{sid}/userNodeSurvey?{force}',
    UpdateSurveyJson: '/project/{pid}/survey/{sid}/question',
    GetSurveyJson: '/project/{pid}/survey/{sid}/questionjson',
    GetAllQuestions: '/project/{pid}/survey/{sid}/questions',
    GetSurveySuggestions: '/project/{pid}/survey/{sid}/getSurveySuggestions',
    GetRules: '/project/{pid}/survey/{sid}/rules',
    CreateRule: '/project/{pid}/survey/{sid}/rules',
    DeleteRule: '/project/{pid}/survey/{sid}/rules/{rid}?force=true',
    UpdateRule: '/project/{pid}/survey/{sid}/rules/{rid}',
    AddTag: '/project/{pid}/survey/{sid}/tag',
    RemoveTag: '/project/{pid}/survey/{sid}/tag/{tid}',
    Result: '/project/{pid}/surveyInstance/{sid}?{round}',
    Publish: '/project/{pid}/survey/{sid}/publish',
    Resume: '/project/{pid}/survey/{sid}/resume',
    Pause: '/project/{pid}/survey/{sid}/pause',
    Search: '/project/{pid}/survey/search/{term}',
    ExportDataDictionary: '/project/{pid}/survey/{sid}/export/dictionary',
    ExportResponses: '/project/{pid}/survey/{sid}/export/result',
    ExportReport: '/project/{pid}/survey/{sid}/export/result/report?{random}',
    UpdateSurveyHasReport: '/project/{pid}/survey/{sid}/report/hasReport',
    UpdateSurveyReport: '/project/{pid}/survey/{sid}/report',
    GetSurveyReport: '/project/{pid}/survey/{sid}/report',
    GetSurveyAnalytics: '/project/{pid}/survey/{sid}/analytics',
    UpdateSurveyAnalytics: '/project/{pid}/survey/{sid}/analytics',
    ExportNetwork: '/project/{pid}/survey/{sid}/export/network',
    GetOpenParticipationSurvey: '/project/{pid}/survey/{sid}/open-paticipation',
}

export const SurveyInstanceApis = {
    GetToken: 'surveyInstance/login',
    GetInstance: 'surveyInstance/{token}/get',
    GetPublicSurvey: 'surveyInstance/{token}/public',
    SaveInstance: 'surveyInstance/{token}/save',
    SubmitInstance: 'surveyInstance/{token}/submit',
    SubmitPublicInstance: 'surveyInstance/public/{token}/submit',
    SubmitOpenSurveyInstance: 'surveyInstance/open/project/{pid}/survey/{sid}/submit',
    ChangeStatus: 'project/{pid}/surveyInstance/{sid}/changeSurveyInstanceStatus',
}

export const UserApis = {
    GetFavs: 'user/favourite',
    UpdateDashboardLayoutSettings: 'user/layout-settings',
}

export const ReportApis = {
    GetReports: 'project/{pid}/report',
    CreatReport: 'project/{pid}/report',
    CloneReport: 'project/{pid}/report/{rid}/clone',
    UpdateReport: 'project/{pid}/report',
    UpdateTitle: 'project/{pid}/report/{rid}/title',
    DeleteReport: 'project/{pid}/report/{rid}?force={force}',
    ShareReport: 'project/{pid}/report/{rid}/share',
    DeleteReportAccess: 'project/{pid}/report/{rid}/share/{raid}',
    UpdateReportAccess: 'project/{pid}/report/{rid}/share/{raid}',
    GetReportByID: 'project/{pid}/report/{rid}',
    GetSampleReport: '/demo/report/{token}',
    GetReportByToken: 'project/12/report/byToken/{token}',
    RequestReportPasscode: 'project/12/report/byToken/{token}/passcode',
    AddTag: '/project/{pid}/report/{rid}/tag',
    RemoveTag: '/project/{pid}/report/{rid}/tag/{tid}',
    Search: '/project/{pid}/report/search/{term}',
    UploadImage: '/project/{pid}/report/{rid}/upload',
    CreatReportCustomAccess: 'project/{pid}/report/{rid}/customView',
    UpdateReportCustomAccess: 'project/{pid}/report/{rid}/customView/{cvi}',
    DeleteReportCustomAccess: 'project/{pid}/report/{rid}/customView/{cvi}',
    ResendReportAccessInvite: 'project/{pid}/report/{rid}/resend-invite/{raid}',
    GetReportViewersAccounts: 'project/{pid}/report/{rid}/report-viewers',
    UpdateReportViewersAccounts: 'project/{pid}/report/{rid}/report-viewers',
}

export const WidgetApis = {
    GetWidgets: 'project/{pid}/widget',
    CreatWidget: 'project/{pid}/widget',
    UpdateWidget: 'project/{pid}/widget',
    UpdateTitle: 'project/{pid}/widget/{wid}/title',
    DeleteWidget: 'project/{pid}/widget/{wid}?force={force}',
    DuplicateWidget: 'project/{pid}/widget/{wid}/duplicate',
    GetWidgetByID: 'project/{pid}/widget/{wid}',
    AddTag: '/project/{pid}/widget/{wid}/tag',
    RemoveTag: '/project/{pid}/widget/{wid}/tag/{tid}',
    UpdateWidgetAnalytics: 'project/{pid}/widget/{wid}/analytics',
    Search: '/project/{pid}/widget/search/{term}',
    GetIssueCorrelation: '/analytics/issue_corr',
}

export const QuestionBankApi = {
    GetQuestions: 'questionBank?{category}',
    AddQuestion: 'questionBank',
    DeleteQuestion: 'questionBank/{qid}',
}

export const SuperAdminApis = {
    GetAllPermissions: '/admin/permission',
    FactoryResetPermissions: '/admin/permission',
    UpdatePermissions: '/admin/permission',
    GetAllIDPs: '/admin/idp',
    CreateIDP: '/admin/idp',
    DeleteIDP: '/admin/idp/{idpid}',
    UpdateIDP: '/admin/idp/{idpid}',
    GetAllUsers: '/admin/user',
    CreateUser: '/admin/user',
    DeleteUser: '/admin/user/{uid}',
    UpdateUserRole: '/admin/user/{uid}',
    ResendInvitation: '/admin/user/resendInvitation/{uid}',
}

export const AllocationAPIs = {
    GetAllocations: '/project/{pid}/allocation',
    CreateAllocation: '/project/{pid}/allocation',
    GetAllocation: '/project/{pid}/allocation/{aid}',
    DeleteAllocation: '/project/{pid}/allocation/{aid}',
}

export const NodeActionAPIs = {
    GetAll: '/project/{pid}/node-action',
    Get: '/project/{pid}/node/{nid}/action/{aid}',
    UpdateNodeActionStatus: '/project/{pid}/node/{nid}/action/{aid}/status',
    UpdateNodeAction: '/project/{pid}/node/{nid}/action/{aid}',
    DeleteNodeAction: '/project/{pid}/node/{nid}/action/{aid}',
    CreateNodeAction: '/project/{pid}/node/{nid}/action',
    AddNodeActionNote: '/project/{pid}/node/{nid}/action/{aid}/note',
    UpdateNodeActionNote: '/project/{pid}/node/{nid}/action/{aid}/note/{noteid}',
    DeleteNodeActionNote: '/project/{pid}/node/{nid}/action/{aid}/note/{noteid}',
    AddNodeActionAssignee: '/project/{pid}/node/{nid}/action/{aid}/assign',
    DeleteNodeActionAssignee: '/project/{pid}/node/{nid}/action/{aid}/assign/{assigneeid}',
}

export const InterventionAPIs = {
    GetAll: '/intervention',
    Get: '/intervention/{iid}',
    Create: '/intervention',
    Update: '/intervention/{iid}',
    Delete: '/intervention/{iid}',
}

export const AIApis = {
    InvokeOpenAI: '/ai',
    tts: '/ai/tts',
}

export const parseUrl = (url: string, pathParams: { [key: string]: any }) => {
    for (let param in pathParams) {
        url = url.replaceAll(`{${param}}`, encodeURI(pathParams[param]))
    }
    return url
}
