import CheckIcon from '@mui/icons-material/Check'
import {
    Box,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    List,
    ListItemButton,
    Collapse,
    Divider,
    InputAdornment,
    Typography,
} from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NODE_ANALYTICS_METRICS } from '../constants/NetworkViz.const'
import { NetworkVizContextType } from '../context/NetworkVizContext'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { FilterAttribiuteType } from '../types/NetworkViz.types'
import { formatLabel } from './DataFormatter'

type NodeAttributeSelectorProps = {
    nodeAttributeOptions: string[]
    edgeAttributeOptions?: string[]
    analytics: NetworkVizContextType['analytics']
    attribute: FilterAttribiuteType | null
    noneOption?: boolean
    noneMessage?: string
    label?: string
    emptyMessage?: string
    helperText?: string
    onChange: (value: FilterAttribiuteType | null) => void
    disabled?: boolean
}

//ToDo make non attribute items sticky. I've tried it but sitcky items where transparent and could find to solution to fix it.
//ToDo for now, I used base filled text field component to show the selected item, which has a number of issue. Modify it or create a custom component.

function NodeAttributeSelector({
    label = 'Attribute',
    edgeAttributeOptions,
    helperText,
    attribute,
    onChange,
    analytics,
    nodeAttributeOptions,
    emptyMessage = 'Select an attribute',
    noneOption = false,
    noneMessage = 'None',
    disabled = false,
}: NodeAttributeSelectorProps) {
    const selectOptions = useMemo(() => {
        const options: BaseSelectWithLabelOptionType = []
        if (noneOption) {
            options.push({ label: noneMessage, value: null })
        }
        if (edgeAttributeOptions && edgeAttributeOptions.length > 0) {
            options.push({ label: 'Edge Attribute', value: 'Edge Attribute', section: true })
            edgeAttributeOptions.forEach((x) =>
                options.push({
                    label: formatLabel(x),
                    value: {
                        field: x,
                        source: 'edge',
                    },
                })
            )
        }
        if (nodeAttributeOptions && nodeAttributeOptions.length > 0) {
            options.push({ label: 'Node Attribute', value: 'Node Attribute', section: true })
            nodeAttributeOptions.forEach((x) =>
                options.push({
                    label: formatLabel(x),
                    value: {
                        field: x,
                        source: 'info',
                    },
                })
            )
        }
        if (analytics) {
            for (let group in analytics) {
                if (group === 'view') continue
                options.push({
                    label: `Node Analytics: ${group}`,
                    value: `Node Analytics: ${group}`,
                    section: true,
                })
                NODE_ANALYTICS_METRICS.forEach((x) =>
                    options.push({
                        label: formatLabel(x.label),
                        value: {
                            field: x.value,
                            relationship: group,
                            source: 'analytic',
                        },
                    })
                )
            }
        }
        return options
    }, [analytics, nodeAttributeOptions, edgeAttributeOptions])

    return (
        <BaseSelectWithLabel
            fullWidth
            disabled={disabled}
            helperText={helperText}
            placeholder={emptyMessage}
            label={label}
            value={attribute}
            onChange={onChange}
            options={selectOptions}
        />
    )
}

export default NodeAttributeSelector
