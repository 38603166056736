import { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { Question } from 'survey-core'
import * as Survey from 'survey-core'
import { PropertyGridEditorCollection } from 'survey-creator-core'
import TextEditor from 'features/text-editor/TextEditor'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { alpha } from '@mui/material/styles'

interface HtmlEditorProps {
    question: Question
    onChange: (newVal: any) => void
}

export function HtmlEditor({ question, onChange }: HtmlEditorProps) {
    const [fullscreen, setFullscreen] = useState(false)

    return (
        <Box
            sx={(theme) => ({
                position: fullscreen ? 'fixed' : 'relative',
                width: '100%',
                height: fullscreen ? '100vh' : '350px',
                left: fullscreen ? 0 : 'unset',
                top: fullscreen ? 0 : 'unset',
                zIndex: fullscreen ? '9999' : 'unset',
                backgroundColor: alpha(theme.palette.common.bg_1, 0.9),
                padding: fullscreen ? '10px' : '0px',
                overflow: 'auto',
            })}
        >
            {fullscreen && (
                <Tooltip title={'Exit fullscreen mode'}>
                    <IconButton onClick={() => setFullscreen(false)}>
                        <CloseRoundedIcon />
                    </IconButton>
                </Tooltip>
            )}

            <TextEditor
                defaultValue={question?.value || ''}
                height={fullscreen ? 'calc(100vh - 100px)' : '350px'}
                onBlur={(value: any) => onChange(value)}
            />

            <Tooltip title={fullscreen ? 'Exit fullscreen mode' : 'Fullscreen mode'}>
                <IconButton
                    sx={{ position: 'absolute', left: '10px', bottom: '10px' }}
                    onClick={() => setFullscreen((pv) => !pv)}
                >
                    {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default function registerHtmlEditor() {
    var HtmlEditorWidget = {
        name: 'editor',
        widgetIsLoaded: function () {
            return true //We do not have external scripts
        },
        isFit: function (question: any) {
            return question.getType() == 'editor'
        },
        init() {
            Survey.Serializer.addClass('editor', [], undefined, 'empty')
        },
        render: function (questionBase: any) {
            function onChange(newVal: any) {
                questionBase.value = newVal || null
            }
            return <HtmlEditor question={questionBase} onChange={onChange} />
        },
    }
    Survey.CustomWidgetCollection.Instance.add(HtmlEditorWidget, 'customtype')
    var widget = Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('editor')
    //Hide from toolbox
    widget.showInToolbox = false

    PropertyGridEditorCollection.register({
        fit: function (prop: any) {
            return prop.type == 'html'
        },
        getJSON: function (obj: any, prop: any, options: any) {
            return {
                type: 'editor',
            }
        },
    })
}
