//* ======= Libraries
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Box, Stack, IconButton, Paper, Grow, ClickAwayListener, Popper } from '@mui/material'
//* ======= Components and features
import ReportWidgetsToolbarDraggableItem from 'features/report-designer/widgets-toolbar/ReportWidgetsToolbarDraggableItem'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { ReportWidgetContentKindType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

type AvailableWidgetsType = {
    visible: Array<{ kind: ReportWidgetContentKindType; title: string }>
    collapsed: Array<{ kind: ReportWidgetContentKindType; title: string }>
}

const availableWidgets: AvailableWidgetsType = {
    visible: [
        {
            kind: 'text',
            title: 'Text',
        },
        {
            kind: 'image',
            title: 'Image',
        },
        {
            kind: 'chart',
            title: 'Chart',
        },
        {
            kind: 'network',
            title: 'Network',
        },
        {
            kind: 'filter',
            title: 'Data Filter',
        },
        {
            kind: 'table',
            title: 'Table',
        },
        {
            kind: 'insight',
            title: 'Insight',
        },
        {
            kind: 'panel',
            title: 'Panel',
        },
    ],
    collapsed: [
        {
            kind: 'video',
            title: 'Video',
        },
        {
            kind: 'info',
            title: 'Info',
        },
        {
            kind: 'dynamicControl',
            title: 'Dynamic Control',
        },
        {
            kind: 'navLink',
            title: 'Nav Link',
        },
        {
            kind: 'horizontalLine',
            title: 'Horizontal Line',
        },
        {
            kind: 'verticalLine',
            title: 'Vertical Line',
        },
        {
            kind: 'ai',
            title: 'AI',
        },
    ],
}

function ReportWidgetsToolbar() {
    const status = useReportStore((store) => store.status)

    const [moreWidgetsMenu, setMoreWidgetsMenu] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    return (
        <Stack alignItems="center" gap={0.5}>
            {status === 'pending' ? (
                /*  "pending" view
                    ========================================= */
                <Box
                    sx={{
                        width: '100%',
                        // Height of 7 widget slots by default. Change if necessary.
                        height: 7 * 30,

                        '& > span': {
                            height: '100%',
                        },
                    }}
                >
                    <Skeleton height="100%" />
                </Box>
            ) : status === 'faulty' ? (
                /*  "faulty" view
                    ========================================= */
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    sx={{
                        // Height of 7 widget slots by default. Change if necessary.
                        height: 7 * 30,
                    }}
                >
                    <ErrorOutlineIcon
                        sx={(theme) => ({
                            height: 32,
                            width: 32,

                            color: theme.palette.common.fuschia80,
                        })}
                    />
                </Stack>
            ) : (
                /*  Main view
                    ========================================= */
                <>
                    {/* Visible widgets list
                        ========================================= */}
                    {availableWidgets.visible.map((_widget, idx) => (
                        <ReportWidgetsToolbarDraggableItem
                            key={idx + _widget.title}
                            tooltip={_widget.title}
                            widgetKind={_widget.kind}
                        />
                    ))}

                    {/* More widgets menu button
                        ========================================= */}
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '100%',
                        }}
                    >
                        <IconButton
                            id="more-menu-button"
                            onClick={(evt) =>
                                setMoreWidgetsMenu((prevState) => ({
                                    ...prevState,
                                    isOpen: !prevState.isOpen,
                                    anchorElement: evt.currentTarget,
                                }))
                            }
                            size="small"
                        >
                            <KeyboardDoubleArrowRightIcon
                                sx={{
                                    height: '100%',
                                    width: 26,
                                }}
                            />
                        </IconButton>
                    </Stack>

                    {/* (Out-of-flow) More widgets menu
                        ========================================= */}
                    <Popper
                        id="more-widgets-menu"
                        open={moreWidgetsMenu.isOpen}
                        anchorEl={moreWidgetsMenu.anchorElement}
                        popperOptions={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 8],
                                    },
                                },
                            ],
                        }}
                        placement="right"
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: 'left center',
                                }}
                            >
                                <Paper
                                    elevation={8}
                                    sx={(theme) => ({
                                        // width: 260,
                                        // maxHeight: 320,

                                        backgroundImage: 'unset',
                                        // @Theme conditional
                                        backgroundColor:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.common.bg_2
                                                : theme.palette.common.bg_4,
                                    })}
                                    className="u-scrollbar"
                                >
                                    <ClickAwayListener
                                        onClickAway={() =>
                                            setMoreWidgetsMenu((prevState) => ({
                                                ...prevState,
                                                isOpen: false,
                                            }))
                                        }
                                    >
                                        <Stack
                                            alignItems="center"
                                            gap={0.5}
                                            sx={(theme) => ({
                                                paddingX: theme.spacing(1.25),
                                            })}
                                        >
                                            {availableWidgets.collapsed.map((_widget, idx) => (
                                                <ReportWidgetsToolbarDraggableItem
                                                    key={idx + _widget.title}
                                                    tooltip={_widget.title}
                                                    widgetKind={_widget.kind}
                                                />
                                            ))}
                                        </Stack>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            )}
        </Stack>
    )
}

export default ReportWidgetsToolbar
