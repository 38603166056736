import { Stack, Box, Typography } from '@mui/material'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ColorPicker from 'components/widgets/ColorPicker'
import { TableDefinitionModeTiesRelation } from '../helpers/TableWidget.asset'
import { useDrag, useDrop } from 'react-dnd'
import DragHandleIcon from '@mui/icons-material/DragHandle'

export const TableDefinitionTiesRelationshipItemDragTypeValue = 'TABLE_DEFINITION_TIES_RELATIONSHIP_ITEM'

type CustomDragItemType = {
    dragId: string
}

type RelationshipProps = {
    name: string
    relationship: {
        isEnabled: boolean
        label: string
        nodesBackgroundColor: string
    }
    updateRelationshipField: (
        relationship: string,
        field: keyof TableDefinitionModeTiesRelation,
        value: TableDefinitionModeTiesRelation[keyof TableDefinitionModeTiesRelation]
    ) => void

    getPositionIndex: (id: string) => number
    onMove: (id: string, newIndex: number) => void
    onMoveEnd: () => void
}

function TableTiesRelationshipAccordionItem({
    name,
    relationship,
    updateRelationshipField,
    getPositionIndex,
    onMove,
    onMoveEnd,
}: RelationshipProps) {
    const [{ isDragging }, dragSource, dragPreview] = useDrag<CustomDragItemType, unknown, { isDragging: boolean }>(
        {
            type: TableDefinitionTiesRelationshipItemDragTypeValue,
            item: () => ({
                dragId: name,
            }),
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: relationship.isEnabled,
        },
        [name, onMove]
    )

    const [, dropTarget] = useDrop<CustomDragItemType>(
        {
            accept: TableDefinitionTiesRelationshipItemDragTypeValue,
            hover: (item) => {
                if (item.dragId !== name) {
                    const newIndex = getPositionIndex(name)

                    onMove(item.dragId, newIndex)
                }
            },
            drop: () => {
                onMoveEnd()
            },
        },
        [getPositionIndex, onMove, onMoveEnd]
    )

    return (
        <Stack
            ref={(node: HTMLDivElement) => dragPreview(dropTarget(node))}
            direction="row"
            alignItems="center"
            gap={2}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            {/* Drag handle
                ========================================= */}
            <Box
                ref={dragSource}
                sx={(theme) => ({
                    flex: '0 0 auto',
                    alignSelf: 'stretch',

                    width: 42,
                    height: '100%',
                    paddingX: theme.spacing(1),
                    overflow: 'hidden',
                    cursor: 'grab',
                })}
            >
                <DragHandleIcon
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Box>

            <StyledWidgetAccordion
                title={name}
                hasToggle={true}
                isToggledOff={!relationship.isEnabled}
                onToggle={() => updateRelationshipField(name, 'isEnabled', !relationship.isEnabled)}
            >
                <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
                    <BaseFilledTextField
                        label="Label"
                        defaultValue={relationship.label}
                        onBlur={(e) => updateRelationshipField(name, 'label', e.target.value)}
                        disabled={relationship.isEnabled === false}
                    />

                    {/* Nodes color
                    ========================================= */}
                    <Stack direction="row" alignItems="center" gap={2}>
                        <Typography
                            sx={(theme) => ({
                                flexShrink: 0,
                                marginRight: theme.spacing(2),
                            })}
                        >
                            Color:
                        </Typography>

                        {/* Color picker
                        ========================================= */}
                        <Box
                            sx={(theme) => ({
                                flexShrink: 0,
                            })}
                        >
                            <ColorPicker
                                disabled={relationship.isEnabled === false}
                                value={relationship.nodesBackgroundColor}
                                onChange={(newColor) =>
                                    newColor && updateRelationshipField(name, 'nodesBackgroundColor', newColor)
                                }
                                isPopover={true}
                            />
                        </Box>
                    </Stack>
                </Stack>
            </StyledWidgetAccordion>
        </Stack>
    )
}

export default TableTiesRelationshipAccordionItem
