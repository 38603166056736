//* ======= Libraries
import { useState } from 'react'
import { Stack, Box, Typography, IconButton, Tooltip, Divider } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
//* ======= Components and features
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import {
    AIWidgetType,
    ChartWidgetType,
    NetworkWidgetType,
    ReportDataSourceAttributeType,
    ReportNetworkDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { InvokeAIService } from 'services/AIService'
import ToastHelper from 'helpers/ToastHelper'
import { convertNodeAttributeToLabel } from 'features/report-designer/helpers/reportDesigner.helper'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { uniq, uniqBy } from 'lodash'

type Props = {
    isOpen: boolean
    data: AIWidgetType
    selectedWidget: NetworkWidgetType | ChartWidgetType | null
    onConfirm: (data: AIWidgetType) => void
    onClose: () => void
}

function AIWidgetDataConfigDialog({
    isOpen = false,
    data,
    selectedWidget,
    onConfirm,
    onClose = () => undefined,
}: Props) {
    const { dataSources } = useReportStore((store) => ({
        dataSources: store.dataSources,
    }))

    const [aiData, setAIData] = useState<AIWidgetType>(data)

    // * insight manipulation

    const removePrompt = (id: AIWidgetType['prompts'][number]['id']) => () => {
        setAIData((pv) => ({
            ...pv,
            prompts: pv.prompts.filter((x) => x.id !== id),
        }))
    }

    const addPrompt = () => {
        setAIData((pv) => ({
            ...pv,
            prompts: [...pv.prompts, { id: uuidv4(), content: '', role: 'assistant' }],
        }))
    }

    type UpdatePromptFields = Exclude<keyof AIWidgetType['prompts'][number], 'id'>

    const updatePropmpt =
        (_id: AIWidgetType['prompts'][number]['id'], field: UpdatePromptFields) =>
        (value: AIWidgetType['prompts'][number][UpdatePromptFields]) => {
            setAIData((pv) => ({
                ...pv,
                prompts: pv.prompts.map((x) => (x.id === _id ? { ...x, [field]: value } : x)),
            }))
        }

    const generateSystemPrompt = () => {
        const systemPrompt: {
            role: 'system' | 'user' | 'assistant'
            content: string
        }[] = []
        if (selectedWidget) {
            // if network widget
            if ('networkDatasourceId' in selectedWidget) {
                const selectedNetworkDatasource = dataSources.find(
                    (x) => x.id === selectedWidget.networkDatasourceId && x.mode === 'network'
                ) as ReportNetworkDataSourceType

                if (selectedNetworkDatasource) {
                    const presetKey = selectedWidget.selectedPreset || 'default'

                    const networkContext = {
                        ...selectedNetworkDatasource.networkContext,
                        ...selectedNetworkDatasource.presets[presetKey],
                        selectedItem:
                            selectedWidget.selectedNode === null
                                ? null
                                : { mode: 'node', id: selectedWidget.selectedNode },
                    }

                    systemPrompt.push({
                        role: 'system',
                        content: `Explain this social network`,
                    })

                    if (selectedWidget.shrinkMode) {
                        if (networkContext.nodeGroupInfo) {
                            for (let group in networkContext.nodeGroupInfo) {
                                systemPrompt.push({
                                    role: 'system',
                                    content: `The group ${group} has ${JSON.stringify({
                                        nodes: networkContext.nodeGroupInfo[group].nodes.length,
                                        innerNetwork: networkContext.nodeGroupInfo[group].edges,
                                    })}`,
                                })
                            }
                            systemPrompt.push({
                                role: 'system',
                                content: `The network nodes are grouped by ${networkContext.nodeGroupBy
                                    .map((x) => convertNodeAttributeToLabel(x))
                                    .join(
                                        ', '
                                    )}. Interpret the metrics for each group based on the given data. `,
                            })
                        }
                        const edges = networkContext.edgeRenders
                            .filter((x) => x.hide !== true)
                            .map((edge) => {
                                const { groupKey: relationship, tooltip, source, target } = edge
                                return { relationship, tooltip, source, target }
                            })
                        systemPrompt.push({
                            role: 'system',
                            content: `The groups are also connected and this the information of external connectsion. Explain the interaction of this groups based on this date. ${JSON.stringify(
                                edges
                            )}`,
                        })
                    } else {
                        const relationships = uniq(
                            networkContext.edgeRenders
                                .filter((x) => x.hide !== true)
                                .map((edge) => networkContext.edges[edge.id])
                                .map((x) => networkContext.edges[x.id].relationship)
                        )

                        for (let relationship of relationships) {
                            const analytics = networkContext.analytics?.[relationship.toLocaleLowerCase()]
                            if (analytics) {
                                systemPrompt.push({
                                    role: 'system',
                                    content: `Interpret the metrics for the network ${relationship} Network and discuss its density, reciprocity, average degree, and other metrics. 
                                    The network metrics for the network ${relationship} is ${JSON.stringify(
                                        analytics.graph
                                    )} `,
                                })
                            }
                        }
                    }
                }
            }
            // if chart widget
            else {
                systemPrompt.push({
                    role: 'system',
                    content: `Explain this ${selectedWidget.type} chart.`,
                })

                switch (selectedWidget.type) {
                    case 'line':
                    case 'bar':
                        systemPrompt.push({
                            role: 'system',
                            content: `The x-axis of this chart represents ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.xAxisField
                            )}.`,
                        })
                        systemPrompt.push({
                            role: 'system',
                            content: `The series in this chart represent the following: ${selectedWidget.dataDefinition.series
                                .map(
                                    (x) =>
                                        `aggregationMethod = ${
                                            x.aggregationMethod
                                        }, field: ${convertNodeAttributeToLabel(x.attribute)}${
                                            x.title ? `, title: ${x.title}` : ''
                                        }`
                                )
                                .join(', ')}.`,
                        })
                        systemPrompt.push({
                            role: 'system',
                            content: `The x-axis data comprises the following: ${selectedWidget.options?.xAxis?.data}.`,
                        })

                        for (const series of selectedWidget.options?.series || []) {
                            systemPrompt.push({
                                role: 'system',
                                content: `The ${series.name} data is as follows: ${series.data}.`,
                            })
                        }

                        if (selectedWidget.dataDefinition.series.length > 1) {
                            systemPrompt.push({
                                role: 'assistant',
                                content:
                                    'Also Provide insights that explain any correlation between the multiple y-axes within this chart.',
                            })
                        }
                        break
                    case 'pie':
                        systemPrompt.push({
                            role: 'system',
                            content: `The slices of this pie chart represent the : ${
                                selectedWidget.dataDefinition.series.aggregationMethod
                            } 
                            of ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.series.attribute
                            )} ${
                                selectedWidget.dataDefinition.series.title
                                    ? `(Tilte :${selectedWidget.dataDefinition.series.title}`
                                    : ''
                            } 
                            for each ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.categoryField
                            )}.
                            `,
                        })

                        let data = Array.isArray(selectedWidget.options?.series)
                            ? selectedWidget.options?.series?.[0]?.data
                            : selectedWidget.options?.series?.data

                        if (data) {
                            systemPrompt.push({
                                role: 'system',
                                content: `The data values for each slice are as follows: ${data
                                    .map((x) => `Name: ${x.name}, Value: ${x.value}`)
                                    .join(', ')}`,
                            })
                        }

                        break
                    case 'scatter':
                        systemPrompt.push({
                            role: 'system',
                            content: `The x-axis of this chart represents ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.xAxisField
                            )}.`,
                        })

                        systemPrompt.push({
                            role: 'system',
                            content: `The y-axis of this chart represents ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.yAxisField
                            )}.`,
                        })

                        let scatterData = Array.isArray(selectedWidget.options?.series)
                            ? selectedWidget.options?.series?.[0]?.data
                            : undefined
                        if (scatterData) {
                            systemPrompt.push({
                                role: 'system',
                                content: `The series in this chart represent the following: ${scatterData
                                    .map((x) => `(${x[0]},${x[1]})`)
                                    .join(', ')}.`,
                            })
                        }
                        break
                    case 'radar':
                        // Metrics or Indicators
                        systemPrompt.push({
                            role: 'system',
                            content: `The axes of this radar chart represent the following indicators: ${selectedWidget.dataDefinition.indicators
                                .map(
                                    (indicator) =>
                                        `${convertNodeAttributeToLabel(indicator.field)} (Max: ${
                                            indicator.max
                                        })`
                                )
                                .join(', ')}.`,
                        })

                        // Series
                        systemPrompt.push({
                            role: 'system',
                            content: `The series in this chart represent the following: ${selectedWidget.dataDefinition.series
                                .map(
                                    (s) =>
                                        `Aggregation Method = ${
                                            s.aggregationMethod
                                        }, Group By = ${convertNodeAttributeToLabel(s.attribute)} ${
                                            s.title ? `, Title = ${s.title}` : ''
                                        }`
                                )
                                .join(', ')}.`,
                        })

                        const radarData = selectedWidget.options?.series?.data
                        if (radarData) {
                            // Series Data
                            for (const series of radarData) {
                                systemPrompt.push({
                                    role: 'system',
                                    content: `The data for group ${
                                        series.name
                                    } is as follows: ${series.value.join(', ')}.`,
                                })
                            }

                            // If more than one series, provide insight
                            if (radarData.length > 1) {
                                systemPrompt.push({
                                    role: 'assistant',
                                    content:
                                        'Provide insights that explain any patterns or differences between the multiple series within this radar chart.',
                                })
                            }
                        }
                        break
                    case 'boxplot':
                        systemPrompt.push({
                            role: 'system',
                            content: `The data of this chart is grouped by ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.categoryField
                            )}.`,
                        })

                        systemPrompt.push({
                            role: 'system',
                            content: `The field of intreset is ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.valueField
                            )}.`,
                        })

                        systemPrompt.push({
                            role: 'system',
                            content: `for each category, the data represents ['min', 'Q1', 'median', 'Q3', 'max']`,
                        })

                        let categories
                        if (selectedWidget.config.orientation === 'vertical') {
                            categories = selectedWidget.options?.xAxis?.data
                        } else {
                            categories = selectedWidget.options?.yAxis?.data
                        }

                        systemPrompt.push({
                            role: 'system',
                            content: `The categories of this chart are as follows: ${categories?.join(
                                ', '
                            )}.`,
                        })

                        const boxSeries = selectedWidget.options?.series
                        if (boxSeries) {
                            // Series Data
                            for (const series of boxSeries) {
                                systemPrompt.push({
                                    role: 'system',
                                    content: `The data for categories are as follows: ${series.data
                                        .map((x) => `[${x.value.join(', ')}]`)
                                        .join(', ')}.`,
                                })
                            }

                            // If more than one series, provide insight
                            if (boxSeries.length > 1) {
                                systemPrompt.push({
                                    role: 'assistant',
                                    content:
                                        'Provide insights that explain any patterns or differences between the multiple series within this boxplot chart.',
                                })
                            }
                        }
                        break
                    case 'gauge':
                        systemPrompt.push({
                            role: 'system',
                            content: `This chart represnets ${
                                selectedWidget.dataDefinition.indicator.aggregationMethod
                            } of
                             ${convertNodeAttributeToLabel(selectedWidget.dataDefinition.indicator.field)}.`,
                        })

                        systemPrompt.push({
                            role: 'system',
                            content: `The data is grouped By ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.series[0].attribute
                            )}.`,
                        })
                        const gaugeData = selectedWidget.options?.series?.data
                        if (gaugeData) {
                            systemPrompt.push({
                                role: 'system',
                                content: `The data for this chart is as follows: ${gaugeData
                                    .map((x) => `Name: ${x.name}, Value: ${x.value}`)
                                    .join(', ')}.`,
                            })
                        }
                        break
                    case 'pictorialBar':
                        systemPrompt.push({
                            role: 'system',
                            content: `The y-axis of this chart represents ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.categoryField
                            )}.`,
                        })

                        systemPrompt.push({
                            role: 'system',
                            content: `The x-axis of this chart represents 
                            ${
                                selectedWidget.dataDefinition.aggregationMethod === 'count'
                                    ? 'number of items within each category'
                                    : `${
                                          selectedWidget.dataDefinition.aggregationMethod
                                      } of ${convertNodeAttributeToLabel(
                                          selectedWidget.dataDefinition.valueField!
                                      )}.`
                            }`,
                        })

                        const pictorialBarCategories = selectedWidget.options?.yAxis?.data
                        if (pictorialBarCategories)
                            systemPrompt.push({
                                role: 'system',
                                content: `The y-axis values are ${pictorialBarCategories.join(', ')}.`,
                            })

                        const pictorialBarData = selectedWidget.options?.series?.data

                        if (pictorialBarData) {
                            systemPrompt.push({
                                role: 'system',
                                content: `The data for this chart is as follows: ${pictorialBarData
                                    .map((x) => `Symbol: ${x.symbol}, Value: ${x.value}`)
                                    .join(', ')}.`,
                            })
                        }
                        break
                    case 'wordCloud':
                        systemPrompt.push({
                            role: 'system',
                            content: `The data of this chart is word frequency of ${convertNodeAttributeToLabel(
                                selectedWidget.dataDefinition.categoryField
                            )}.`,
                        })
                        const wordCloudData = selectedWidget.options?.series?.[0]?.data
                        if (wordCloudData) {
                            systemPrompt.push({
                                role: 'system',
                                content: `The data for this chart is as follows: ${wordCloudData
                                    .map((x) => `Name: ${x.name}, Value: ${x.value}`)
                                    .join(', ')}.`,
                            })
                        }
                }
            }
        }

        systemPrompt.push({
            role: 'assistant',
            content:
                'Generate concise, actionable insights based on this data that are not immediately obvious from the chart.',
        })

        systemPrompt.push({
            role: 'assistant',
            content: 'Use passive voice, omitting any introductory sentence.',
        })
        return systemPrompt
    }

    const updateContent = (value: string) => {
        setAIData((pv) => ({
            ...pv,
            outcomes: value,
        }))
    }

    const testPrompt = async () => {
        const toast = new ToastHelper({
            errorMessage: 'Failed to run prompt',
            successMessage: 'Prompt executed successfully',
            loadingMessage: 'Running prompt...',
        })
        try {
            var response = await InvokeAIService({
                prompts: [...generateSystemPrompt(), ...aiData.prompts],
            })

            if (response.success) {
                updateContent(response.data.choices?.[0]?.message?.content || '')
                toast.success()
                return
            }
        } catch {}
        toast.fail()
    }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {selectedWidget === null ? (
                <>
                    <Box
                        sx={(theme) => ({
                            flex: '0 0 auto',
                            display: 'grid',
                            // 34px is the width of the close button. Change accordingly.
                            gridTemplateColumns: '34px 1fr auto',
                            placeContent: 'center',
                            columnGap: theme.spacing(1),
                            padding: theme.spacing(2, 3),
                        })}
                    >
                        <Typography
                            fontSize={20}
                            fontWeight={500}
                            textAlign="center"
                            sx={{
                                gridColumn: 2,
                            }}
                        >
                            Please Select a Data Widget (Network or Chart)
                        </Typography>

                        <IconButton
                            onClick={(evt) => onClose()}
                            size="small"
                            sx={{
                                gridColumn: 3,
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            flex: '0 0 auto',

                            padding: theme.spacing(2, 3),
                        })}
                    >
                        <BaseButton
                            label="Close"
                            onClick={(evt) => onClose()}
                            variant="outlined"
                            color="secondary"
                            sx={(theme) => ({
                                minWidth: 180,
                                paddingY: 1,

                                color: theme.palette.common.fill_1,
                            })}
                        />
                    </Stack>
                </>
            ) : (
                // * Main view
                <>
                    {/* Header
                    ========================================= */}
                    <Box
                        sx={(theme) => ({
                            flex: '0 0 auto',
                            display: 'grid',
                            // 34px is the width of the close button. Change accordingly.
                            gridTemplateColumns: '34px 1fr auto',
                            placeContent: 'center',
                            columnGap: theme.spacing(1),
                            padding: theme.spacing(2, 3),
                            borderBottom: `1px solid ${theme.palette.common.border_3}`,
                        })}
                    >
                        <Typography
                            fontSize={20}
                            fontWeight={500}
                            textAlign="center"
                            sx={{
                                gridColumn: 2,
                            }}
                        >
                            AI Widget Prompt Configuration
                        </Typography>

                        <IconButton
                            onClick={(evt) => onClose()}
                            size="small"
                            sx={{
                                gridColumn: 3,
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>

                    {/* Content
                ========================================= */}
                    <Box
                        sx={(theme) => ({
                            flex: '1 1 auto',

                            display: 'grid',
                            gridTemplateColumns: '10fr 9fr',
                            placeContent: 'stretch',
                            columnGap: 2,

                            height: '70vh',
                            padding: theme.spacing(3),
                            overflowY: 'auto',
                        })}
                        className="u-scrollbar"
                    >
                        {/* Content wrapper
                    ========================================= */}
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                height: '100%',
                                paddingRight: theme.spacing(2),
                                overflowY: 'auto',
                                borderRight: `1px solid ${theme.palette.common.border_3}`,
                            })}
                            className="u-scrollbar"
                        >
                            {/* Header
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" gap={1}>
                                <Typography>Insights</Typography>
                                <Tooltip title="Add new Prompt">
                                    <IconButton onClick={() => addPrompt()}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            {/* Insights wrapper
                                ========================================= */}
                            <Stack gap={1} sx={{ width: '100%', flexGrow: 1 }}>
                                {aiData.prompts.map((prompt, idx) => (
                                    <Stack key={prompt.id} direction="row" spacing={1}>
                                        <BaseSelectWithLabel
                                            label="Role"
                                            value={prompt.role}
                                            options={[
                                                { label: 'Assistant', value: 'assistant' },
                                                { label: 'User', value: 'user' },
                                            ]}
                                            onChange={(value) => {
                                                updatePropmpt(prompt.id, 'role')(value)
                                            }}
                                            sx={{
                                                width: '30ch',
                                            }}
                                        />
                                        <BaseFilledTextField
                                            label="Prompt"
                                            value={prompt.content}
                                            fullWidth
                                            onChange={(evt) => {
                                                updatePropmpt(prompt.id, 'content')(evt.target.value)
                                            }}
                                        />
                                        <Tooltip title="Delete Prompt">
                                            <IconButton onClick={removePrompt(prompt.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>

                        {aiData.prompts.length === 0 ? (
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    height: '100%',
                                }}
                            >
                                No Data.
                            </Stack>
                        ) : (
                            <Stack
                                sx={{
                                    height: '100%',
                                    //To make it allign with corresponding insight
                                    marginTop: '40px',
                                    paddingTop: 2,
                                }}
                            >
                                <BaseButton label="Test" onClick={testPrompt} />
                                <Divider />
                                <Typography variant="h6">{aiData.outcomes}</Typography>
                            </Stack>
                        )}
                    </Box>

                    {/* Footer
                ========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            flex: '0 0 auto',

                            padding: theme.spacing(2, 3),
                        })}
                    >
                        <BaseButton
                            label="Discard"
                            onClick={(evt) => onClose()}
                            variant="outlined"
                            color="secondary"
                            sx={(theme) => ({
                                minWidth: 180,
                                paddingY: 1,

                                color: theme.palette.common.fill_1,
                            })}
                        />

                        <BaseButton
                            label="Apply"
                            onClick={(evt) => onConfirm({ ...aiData })}
                            variant="contained"
                            color="primary"
                            sx={{
                                minWidth: 180,
                                paddingY: 1,
                            }}
                        />
                    </Stack>
                </>
            )}
        </StyledDialog>
    )
}

export default AIWidgetDataConfigDialog
