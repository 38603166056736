/* eslint-disable no-fallthrough */
import { APP_VERSIONS } from 'helpers/constants'
import WebWorker from 'helpers/webWorkerHelper'
import { NetworkVizContextType, NetworkVizInitialState } from '../context/NetworkVizContext'
import { NodeAdjacentListType } from '../types/NetworkViz.types'
// This function will provide backward compatibility by migrating data from older version to the current version

// For adding a new migration, add a new case to the end of the switch statment [p.s. Do not put break at the end of the case statment]
const migrateNetworkVizState = async (
    oldState: any,
    networkVersion: string | undefined
): Promise<NetworkVizContextType> => {
    // if version is up to date, rturn state
    if (APP_VERSIONS.NETWORK === networkVersion) return oldState

    //otherwise migrate to the latest version

    const newState = structuredClone(oldState)

    switch (networkVersion) {
        //Convert version 0 to version 1.0.0
        /* falls through */
        // @ts-ignore
        case undefined:
            // update the default network
            const nodeAdjacencyWorker = new WebWorker<NodeAdjacentListType>('workers/network/node-adjacency.js')
            const nodeAdjacentList = await nodeAdjacencyWorker.run({
                edgeRenders: newState.edgeRenders.filter((x: any) => x.hide !== true),
                isDirected: newState.nodeInteractionConfig?.directed || true,
            })
            newState.nodeAdjacentList = nodeAdjacentList
            for (let key in newState.edgeStyle) {
                const selectedStyle = structuredClone(newState.edgeStyle[key].selected)
                newState.edgeStyle[key].selectedIn = {
                    ...selectedStyle,
                }
                newState.edgeStyle[key].selectedOut = {
                    ...selectedStyle,
                    color: '#ff7f0e77',
                }
            }

            // update presets
            for (let presetKey in newState.presets) {
                const worker = new WebWorker<any>('workers/network/zip-worker.js')
                const presetState = await worker.run({
                    mode: 'un-zip',
                    data: newState.presets[presetKey],
                })

                const nodeAdjacencyWorker = new WebWorker<NodeAdjacentListType>('workers/network/node-adjacency.js')
                const nodeAdjacentList = await nodeAdjacencyWorker.run({
                    edgeRenders: presetState.edgeRenders.filter((x: any) => x.hide !== true),
                    isDirected: presetState.nodeInteractionConfig?.directed || true,
                })
                presetState.nodeAdjacentList = nodeAdjacentList
                for (let key in presetState.edgeStyle) {
                    const selectedStyle = structuredClone(presetState.edgeStyle[key].selected)
                    presetState.edgeStyle[key].selectedIn = {
                        ...selectedStyle,
                    }
                    presetState.edgeStyle[key].selectedOut = {
                        ...selectedStyle,
                        color: '#ff7f0e77',
                    }
                }
                const zipWorker = new WebWorker('workers/network/zip-worker.js')

                newState.presets[presetKey] = await zipWorker.run({
                    mode: 'zip',
                    data: presetState,
                })
            }
        /* falls through */
        // @ts-ignore
        case '1.0.0':
            newState.analyticsSettings = { groupBy: '', networks: {} }
        /* falls through */
        // @ts-ignore
        case '1.0.1':
            newState.analyticsSettings = { groupBy: '', networks: {} }
        /* falls through */
        // @ts-ignore
        case '1.0.2':
            newState.analyticsSettings = {
                ...newState.analyticsSettings,
                ergm: {
                    explanatoryAttributes: [],
                    behavior: [],
                    characteristics: [],
                    networks: [],
                },
            }
        /* falls through */
        // @ts-ignore
        case '1.0.4':
            newState.statsitclTests = []
            newState.analyticsSettings = {
                ...newState.analyticsSettings,
                ergm: {
                    explanatoryAttributes: [],
                    behavior: [],
                    characteristics: [],
                    networks: [],
                },
                alaam: {
                    behavior: [],
                    characteristics: [],
                    networks: [],
                    quantile: 0.75,
                    targetAttributes: [],
                    explanatoryAttributes: [],
                },
            }
        /* falls through */
        // @ts-ignore
        case '1.0.5':
            newState.networkShrink = structuredClone(NetworkVizInitialState.networkShrink)
        /* falls through */
        // @ts-ignore
        case '1.0.6':
            newState.analyticsSettings.assortativity = []
        /* falls through */
        // @ts-ignore
        case '1.0.7':
            newState.keyFrames = []
    }
    return newState
}

export default migrateNetworkVizState
