import { AuthenticationApis as Apis, parseUrl } from './Apis'
import { BaseResponseType, request } from './request'

export type AuthMethodReuqest = {
    username: string
}

export type AuthMethodType = {
    method: string
    avatar?: string
    url?: string
}

export type AuthMethodResponseType = {
    data: AuthMethodType
    success: boolean
}

export const AuthMethod: (body: AuthMethodReuqest, showMessage?: boolean) => Promise<AuthMethodResponseType> = async (
    body,
    showMessage = false
) => {
    return request(Apis.AuthMethod, 'POST', { 'Content-Type': 'application/json' }, body, showMessage)
}

export type LoginRequestType = {
    userEmail: string
    password: string
}

export const Login: (body: LoginRequestType, showMessage?: boolean) => Promise<BaseResponseType> = async (
    body,
    showMessage = false
) => {
    return request(Apis.Login, 'POST', { 'Content-Type': 'application/json' }, body, showMessage)
}

export const Logout: (showMessage?: boolean) => Promise<BaseResponseType> = async (showMessage = false) => {
    return request(Apis.Logout, 'POST', undefined, undefined, showMessage)
}

export type DashboardLayoutSettingsType = {
    sortDirection: number
    sortBy: string
    folderStructure: string[]
    isListView: boolean
}

export type AccountType = {
    username?: string
    isProfileCompeleted?: boolean
    isEmailVerified?: boolean
    isMobileVerified?: boolean
    email?: string
    name?: string
    company?: string
    position?: string
    country?: string
    phoneNumber?: string
    primaryKey: number
    avatar?: string
    roles: string[]
    projectDashboardLayoutSettings?: DashboardLayoutSettingsType
    surveyDashboardLayoutSettings?: DashboardLayoutSettingsType
    widgetDashboardLayoutSettings?: DashboardLayoutSettingsType
    reportDashboardLayoutSettings?: DashboardLayoutSettingsType
}

export type GetUserResponseType = {
    success: boolean
    data: AccountType
}

export const GetUser: (showMessage?: boolean) => Promise<GetUserResponseType> = async (showMessage = false) => {
    return request(Apis.GetUser, 'GET', undefined, undefined, showMessage)
}

export type CreateAccountRequestType = {
    username: string
    email: string
    password: string
}
export const CreateAccountService: (
    token: string,
    body: CreateAccountRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (token, body, showMessage = false) => {
    return request(
        parseUrl(Apis.CreateAccount, { token }),
        'POST',
        { 'Content-Type': 'application/json' },
        body,
        showMessage
    )
}

export type UpdaterAccountRequestType = {
    name: string
    avatar?: string
    company?: string
    position?: string
    country?: string
    phoneNumber?: string
}
export const UpdateAccountService: (
    body: UpdaterAccountRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (body, showMessage = false) => {
    return request(Apis.UpdateAccount, 'PUT', { 'Content-Type': 'application/json' }, body, showMessage)
}

export const ForgotPasswordService: (
    body: AuthMethodReuqest,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (body, showMessage = false) => {
    return request(Apis.ForgotPassword, 'POST', { 'Content-Type': 'application/json' }, body, showMessage)
}

export type ResetPasswordRequestType = {
    password: string
    token: string
}

export const ResetPasswordService: (
    body: ResetPasswordRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (body, showMessage = false) => {
    return request(Apis.ResetPassword, 'POST', { 'Content-Type': 'application/json' }, body, showMessage)
}

export const UpdatePasswordService: (
    body: ResetPasswordRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (body, showMessage = false) => {
    return request(Apis.UpdatePassword, 'POST', { 'Content-Type': 'application/json' }, body, showMessage)
}

export type ValidateInviteType = {
    email: string
    invitedBy: string
    projectTitle: string
}

export type ValidateInviteResponseType = {
    data: ValidateInviteType
    success: boolean
}

export const ValidateInviteService: (
    token: string,
    showMessage?: boolean
) => Promise<ValidateInviteResponseType> = async (token, showMessage = false) => {
    return request(parseUrl(Apis.ValidateInviteToken, { token }), 'GET', undefined, undefined, showMessage)
}

export const VerifyEmailService: (code: string, showMessage?: boolean) => Promise<BaseResponseType> = async (
    code,
    showMessage = false
) => {
    return request(parseUrl(Apis.VerifyEmail, { code }), 'PUT', undefined, undefined, showMessage)
}

export const ResendEmailVerificationService: (showMessage?: boolean) => Promise<BaseResponseType> = async (
    showMessage = false
) => {
    return request(Apis.ResendEmailVerification, 'POST', undefined, undefined, showMessage)
}
