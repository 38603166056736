import React, { useEffect } from 'react'

import Chart from 'features/chart/Chart'
import { Box, Stack } from '@mui/material'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import { ClassStatType } from 'pages/allocation/Allocation.route'
import { quantileSeq } from 'mathjs'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'

interface GroupAllocationBoxplotChartProps {
    data: Record<string, ClassStatType> | null
}

const GroupAllocationBoxplotChart: React.FC<GroupAllocationBoxplotChartProps> = ({ data }) => {
    const [chartOption, setChartOption] = React.useState<EChartsCustomOptionType | null>(null)
    const [field, setField] = React.useState<string | null>(null)

    useEffect(() => {
        if (data === null || field === null) return

        const groupNames = Object.keys(data ?? {})

        // Helper function: Compute the boxplot values for an array
        const computeBoxplotStats = (values: number[]) => {
            const cleanedValues = values.filter((value) => !isNaN(value)) // Assuming this cleans the values
            return [
                Math.min(...cleanedValues),
                ...(quantileSeq(cleanedValues, [1 / 4, 1 / 2, 3 / 4]) as Array<number>),
                Math.max(...cleanedValues),
            ]
        }

        const seriesData = groupNames
            .map((group) => {
                return computeBoxplotStats(data![group].diversity[field!])
            })
            .filter((value) => value !== undefined) as Array<Array<number>>

        const boxplotChartOption: EChartsCustomOptionType = {
            title: {
                text: `Boxplot for ${field}`,
                left: 'center',
            },
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                left: '10%',
                right: '10%',
                bottom: '15%',
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                nameGap: 30,
                data: groupNames,
                axisLabel: {
                    interval: 0,
                    rotate: -30,
                },
            },
            yAxis: {
                type: 'value',
                scale: true,
            },
            series: [
                {
                    name: 'boxplot',
                    type: 'boxplot',
                    data: seriesData,
                },
            ],
        }

        setChartOption(boxplotChartOption)
    }, [data, field])

    useEffect(() => {
        if (data === null) return
        if (field === null) setField(Object.keys(data[Object.keys(data)[0]].diversity)[0])
    }, [data])

    return (
        <Stack height="100%" p={1}>
            <BaseSelectWithLabel
                label="Diversity Attribute"
                value={field}
                size="small"
                options={data ? Object.keys(data[Object.keys(data)[0]].diversity) : []}
                onChange={(value) => setField(value)}
                sx={{ width: '100%' }}
            />
            <Box sx={{ width: '100%', flexGrow: 1 }}>
                {chartOption && <Chart options={chartOption} type="boxplot" />}
            </Box>
        </Stack>
    )
}

export default GroupAllocationBoxplotChart
