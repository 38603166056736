import { BrowserRouter as Router } from 'react-router-dom'
// Poppins font (https://fontsource.org/fonts/poppins)
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
// Poppins font (https://fontsource.org/fonts/roboto)
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import RootContextProvider, { RootContext } from './contexts/RootContext'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LoadingSkeletonTheme from 'features/loading-skeleton-theme/LoadingSkeletonTheme'
import MasterLayout from './pages/MasterLayout'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import getTheme from 'theme/mui-theme'
import { Profiler, useContext, useEffect } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import './App.css'

function Content() {
    const { theme } = useContext(RootContext)

    return (
        <ThemeProvider theme={getTheme(theme)}>
            <CssBaseline />
            {/* MUI X DateTime requirement */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <GoogleOAuthProvider clientId="286029522464-rd7i96phab6qunc38k8j3kt3bk4fvpm2.apps.googleusercontent.com">
                    <LoadingSkeletonTheme>
                        <MasterLayout />
                    </LoadingSkeletonTheme>
                </GoogleOAuthProvider>
            </LocalizationProvider>

            <ToastContainer />
        </ThemeProvider>
    )
}

function App() {
    const callback = (
        id: string,
        phase: 'mount' | 'update',
        actualDuration: number,
        baseDuration: number,
        startTime: number,
        commitTime: number
    ) => {
        console.log({
            id,
            phase,
            actualDuration,
            baseDuration,
        })
    }

    return (
        <Router>
            {/* <Profiler id="Navigation" onRender={callback}> */}

            <RootContextProvider>
                <Content />
            </RootContextProvider>
            {/* </Profiler> */}
        </Router>
    )
}

export default App
