import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useEffect, useMemo, useState } from 'react'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import ShowIcon from '@mui/icons-material/Visibility'
import {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import {
    DataSchemaType,
    FilterItemType,
    NetworkAnalyticsType,
    NetworkItemIdType,
    NetworkSelectedItemType,
    NodeAttributeType,
    NodeIdType,
    NodeRenderType,
    NodeType,
} from 'features/network-viz/types/NetworkViz.types'
import { NODE_ANALYTICS_METRICS } from 'features/network-viz/constants/NetworkViz.const'
import { formatLabel, formatValue } from 'features/network-viz/helpers/DataFormatter'
import NodeAttributeSelector from 'features/network-viz/helpers/NodeAttributeSelector'
import { isSelectedItemEquals } from 'features/network-viz/helpers/NetworkViz.helper'

interface AnalyticsProps {
    analytics: NetworkVizContextType['analytics']
    filters: FilterItemType[]
    nodes: Record<string, NodeType>
    selectedItem: NetworkSelectedItemType | null
    nodeDataSchema: DataSchemaType
    nodeRenders: NodeRenderType[]
    onClose?: () => void
    onSelectedNodeChange: (id: NetworkItemIdType) => void
}

export default function NetworkAnalyticsInfoTop10({
    onClose,
    analytics,
    filters,
    nodes,
    selectedItem,
    nodeDataSchema,
    nodeRenders,
    onSelectedNodeChange,
}: AnalyticsProps) {
    const [mode, setMode] = useState<1 | -1>(1)
    const [show, setShow] = useState(false)
    const [attribute, setAttribute] = useState<NodeAttributeType>({
        source: 'info',
        field: '',
    })

    const applyFilters = (_filters: FilterItemType[]) => {
        //ToDo
        // dispatchContext({ type: 'FILTER_CHANGE', payload: _filters })
    }

    const removeHighlight = () => {
        if (show) {
            //ToDo
            // dispatchContext({ type: 'FILTER_REMOVE', payload: "sna_top_10_auto_filter" })
            setShow(false)
        }
    }

    const onDialogClose = () => {
        removeHighlight()
        onClose && onClose()
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                maxHeight: 450,
                width: 350,
                padding: theme.spacing(1.5, 2),
            })}
        >
            {/* Title and close button
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={600} color="primary">
                    {mode === 1 ? 'Top 10' : 'Bottom 10'}
                </Typography>
                <IconButton onClick={onDialogClose} size="small">
                    <CloseRoundedIcon fontSize="small" />
                </IconButton>
            </Stack>

            <Stack direction="row" gap={1}>
                <BaseSelectWithLabel
                    label="Mode"
                    size="small"
                    sx={{ width: '130px' }}
                    value={mode}
                    onChange={(e) => setMode(e)}
                    options={[
                        { label: 'Top 10', value: 1 },
                        { label: 'Bottom 10', value: -1 },
                    ]}
                />

                <NodeAttributeSelector
                    attribute={attribute}
                    onChange={(v) => v && setAttribute(v as NodeAttributeType)}
                    analytics={analytics}
                    nodeAttributeOptions={nodeDataSchema.numericOptions}
                />
            </Stack>

            {/* Table
                ========================================= */}
            <NetworkAnalyticsInfoTop10Table
                analytics={analytics}
                attribute={attribute}
                mode={mode}
                nodeRenders={nodeRenders}
                nodes={nodes}
                selectedItem={selectedItem}
                onSelectedNodeChange={onSelectedNodeChange}
            />

            {/* Bottom controls
                    ========================================= */}
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={1}
                sx={{
                    flexShrink: 0,
                }}
            >
                {/* <IconButton
                    disabled={attribute.field === ''}
                    onClick={() => {
                        if (show) {
                            removeHighlight()
                        } else {
                            //TOdo fix this
                            setShow(true)
                            applyFilters([
                                ...filters,
                                {
                                    filterMode: 'dim',
                                    type: 'number',
                                    numericMode: 'topK',
                                    attribute: attribute,
                                    topK: {
                                        k: 10,
                                        type: mode === 1 ? 'high' : 'low',
                                    },
                                    id: 'sna_top_10_auto_filter',
                                },
                            ])
                        }
                    }}
                >
                    <ShowIcon color={show ? 'primary' : 'secondary'} />
                </IconButton> */}
            </Stack>
        </Stack>
    )
}

type NetworkAnalyticsInfoTop10TableProps = {
    attribute: NodeAttributeType
    analytics: NetworkVizContextType['analytics']
    nodeRenders: NetworkVizContextType['nodeRenders']
    nodes: NetworkVizContextType['nodes']
    mode: 1 | -1
    onSelectedNodeChange?: (id: NetworkItemIdType) => void
    selectedItem: NetworkSelectedItemType | null
}
export function NetworkAnalyticsInfoTop10Table({
    onSelectedNodeChange,
    selectedItem,
    attribute,
    analytics,
    nodeRenders,
    nodes,
    mode,
}: NetworkAnalyticsInfoTop10TableProps) {
    const data = useMemo<{ id: string; value: any }[] | null>(() => {
        if (attribute.field === '') return null
        if (attribute.source === 'analytic') {
            const key = attribute.field
            if (analytics === null || analytics[attribute.relationship]?.nodes == null) return []

            const nodesList: string[] = Object.keys(analytics[attribute.relationship].nodes)

            nodesList.sort((a, b) => {
                //@ts-ignore
                const n1 = Number(analytics[attribute.relationship].nodes[a][key])
                //@ts-ignore
                const n2 = Number(analytics[attribute.relationship].nodes[b][key])
                if (isNaN(n1)) {
                    if (isNaN(n2)) {
                        return 0
                    } else {
                        return 1 * mode
                    }
                } else if (isNaN(n2)) {
                    return -1 * mode
                } else {
                    return (n2 - n1) * mode
                }
            })

            //@ts-ignore
            return nodesList
                .slice(0, 10)
                .map((x) => ({ id: x, value: analytics[attribute.relationship].nodes[x][key] }))
        } else {
            const _nodes = [...nodeRenders]
            _nodes.sort((a: NodeRenderType, b: NodeRenderType) => {
                const n1 = Number(nodes[a.id][attribute.field])
                const n2 = Number(nodes[b.id][attribute.field])
                if (isNaN(n1)) {
                    if (isNaN(n2)) {
                        return 0
                    } else {
                        return 1 * mode
                    }
                } else if (isNaN(n2)) {
                    return -1 * mode
                } else {
                    return (n2 - n1) * mode
                }
            })

            return _nodes.slice(0, 10).map((x) => ({ id: x.id, value: nodes[x.id][attribute.field] }))
        }
    }, [mode, attribute])

    // ToDo
    // useEffect(() => {
    //     removeHighlight();
    // }, [data])

    return (
        <>
            {data && (
                <TableContainer
                    sx={{
                        overflow: 'auto',
                    }}
                    className="u-scrollbar"
                >
                    <Table
                        size="medium"
                        stickyHeader
                        padding={'none'}
                        sx={{
                            borderCollapse: 'separate',
                            borderSpacing: '0 8px',
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& .MuiTableCell-head': {
                                        padding: 1,
                                        fontSize: 14,
                                        fontWeight: 500,

                                        backgroundColor: 'common.bg_3',
                                    },
                                }}
                            >
                                <TableCell align="center">
                                    <Typography noWrap>Node</Typography>
                                </TableCell>

                                <TableCell align="center">
                                    <Typography noWrap>{formatLabel(attribute.field)}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.map((k: { id: string; value: any }, index) => (
                                <TableRow
                                    key={k.id}
                                    onClick={() => {
                                        onSelectedNodeChange && onSelectedNodeChange(k.id + '')
                                    }}
                                    sx={(theme) => ({
                                        cursor: 'pointer',
                                        backgroundColor: isSelectedItemEquals(selectedItem, {
                                            mode: 'node',
                                            id: k.id + '',
                                        })
                                            ? theme.palette.common.secondaryActive
                                            : theme.palette.common.bg_3,

                                        '&:hover': {
                                            backgroundColor: theme.palette.common.bg_4,
                                        },

                                        '& .MuiTableCell-body': {
                                            maxWidth: 200,
                                            padding: theme.spacing(1),

                                            borderBottom: 0,

                                            '&:first-of-type': {
                                                borderTopLeftRadius: '10px',
                                                borderBottomLeftRadius: '10px',
                                            },

                                            '&:last-of-type': {
                                                borderTopRightRadius: '10px',
                                                borderBottomRightRadius: '10px',
                                            },
                                        },
                                    })}
                                >
                                    <TableCell align="center">
                                        <Typography noWrap>Node {k.id}</Typography>
                                    </TableCell>

                                    <TableCell align="center">
                                        <Typography noWrap>{formatValue(k.value)}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    )
}
