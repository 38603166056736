import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledDialog from 'components/dialog/StyledDialog'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PanelWidgetTransfer from './PanelWidgetTransfer'
import { ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'

type AddPanelLayerDialogProps = {
    widgetId: ReportWidgetType['id']
    open: boolean
    onClose: () => void
    parents: string[]
    onAdd: (name: string, parent: string, widgets: ReportWidgetType['id'][]) => void
}

const AddPanelLayerDialog = ({ open, onClose, onAdd, parents, widgetId }: AddPanelLayerDialogProps) => {
    const [selectedWidgets, setSelectedWidgets] = useState<ReportWidgetType['id'][]>([])
    //form
    const {
        control,
        handleSubmit,
        reset,
        formState: { isValid, isSubmitting },
    } = useForm<{
        name: string
        parent: string
    }>({
        mode: 'onBlur', // Validate the form on blur
        reValidateMode: 'onBlur', // Re-validate the form on every blur event
        criteriaMode: 'all',
    })

    const handleAddLayer = () => {
        handleSubmit(
            //Valid
            (data) => {
                onAdd(data.name, data.parent, selectedWidgets)
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    useEffect(() => {
        if (open) {
            // Reset the form
            reset()
        }
    }, [open, reset])

    return (
        <StyledDialog open={open} maxWidth={'sm'} fullWidth disableEscapeKeyDown>
            <DialogTitle>Add Panel Layer</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                            <BaseFilledTextField
                                label="Layer Name"
                                required
                                value={value}
                                fullWidth
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{
                            required: 'Layer name is required',
                            minLength: { value: 2, message: 'The name should be at least 2 characters' },
                            maxLength: {
                                value: 50,
                                message: 'The name not be more than 50 characters',
                            },
                            // root layer is not allowed
                            validate: (value) => {
                                for (let item of parents) {
                                    if (item === value) {
                                        return 'Layer name already exists'
                                    }
                                }
                                return true
                            },
                        }}
                    />

                    <Controller
                        name="parent"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                            <BaseSelectWithLabel
                                label="Layer Name"
                                required
                                value={value}
                                fullWidth
                                options={parents}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{
                            required: 'Parent is required',
                        }}
                    />

                    <PanelWidgetTransfer
                        widgetId={widgetId}
                        widgets={selectedWidgets}
                        onAddWidgets={(widgetId) => setSelectedWidgets((prev) => [...prev, ...widgetId])}
                        onRemoveWidgets={(widgetId) =>
                            setSelectedWidgets((prev) => prev.filter((_widgetId) => !widgetId.includes(_widgetId)))
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton disabled={isSubmitting} color="secondary" onClick={onClose}>
                    Cancel
                </BaseButton>
                <BaseButton
                    disabled={!isValid || isSubmitting}
                    color="primary"
                    variant="contained"
                    onClick={handleAddLayer}
                >
                    Add
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default AddPanelLayerDialog
