import React, { useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel';
import { TTestConfigType } from 'features/network-viz/types/NetworkViz.types';


interface TTestConfigProps {
    config: TTestConfigType; // Replace 'any' with the specific type for the T-test config
    onUpdate: (updatedConfig: Partial<TTestConfigType>) => void; // Replace 'any' with the specific type for the T-test config
    options: { label: string; value: any; section?: boolean }[]
}

export const DefaultTTestConfig: TTestConfigType = {
    group1: '',
    group2: '',
}

const TTestConfig: React.FC<TTestConfigProps> = ({ config, onUpdate, options }) => {

    const [group1Options, setGroup1Options] = useState(options);
    const [group2Options, setGroup2Options] = useState(options);

    return (
        <>
            <Grid2 xs={12} md={6} p={1}>
                <BaseSelectWithLabel
                    fullWidth
                    options={group1Options}
                    label="Group 1" value={config.group1}
                    onChange={(value) => {
                        onUpdate({ group1: value });
                        setGroup2Options(options.filter(option => option.value !== value));
                    }}
                />
            </Grid2>
            <Grid2 xs={12} md={6} p={1}>
                <BaseSelectWithLabel
                    fullWidth
                    options={group2Options}
                    label="Group 2" value={config.group2}
                    onChange={(value) => {
                        onUpdate({ group2: value });
                        setGroup1Options(options.filter(option => option.value !== value));
                    }}
                />
            </Grid2>

        </>
    );
};

export default TTestConfig;