//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import BaseButton from 'components/base/BaseButton'
import {
    DataGridPro,
    GridToolbar,
    GridColDef,
    GridRenderCellParams,
    useGridApiRef,
} from '@mui/x-data-grid-pro'
//* ======= Custom logic
//* ======= Assets and styles
import DeleteIcon from '@mui/icons-material/Delete'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import GoodIcon from '@mui/icons-material/GppGood'
import { LinearProgress, Tooltip } from '@mui/material'
import './update-node-dialog.css'

import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { UpdateNodesService } from 'services/NodeService'

interface IUpdateNodeDialog {
    projectId: number
    onConfirm?: () => void
}

function UpdateNodeDialog({ onConfirm, projectId }: IUpdateNodeDialog) {
    const [rows, setRows] = useState<Array<{ [key: string]: any }>>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [loading, setLoading] = useState(false)
    const [hasData, setHasData] = useState(false)

    // select file
    function updateAndParseNodes(event: React.ChangeEvent<HTMLInputElement>) {
        setLoading(true)
        if (event.target.files && event.target.files.length > 0) {
            const loadGraphWorker = new window.Worker(
                process.env.REACT_APP_BASE_URL + 'workers/node/update-nodes-worker.js'
            )
            loadGraphWorker.postMessage({ fileName: event.target.files[0] })
            loadGraphWorker.onmessage = function (e) {
                const { nodes, headers } = e.data

                setColumns(headers)
                setHasData(true)
                setRows(nodes)
                setLoading(false)
            }
        }
    }

    //import nodes
    const importNodes = () => {
        setLoading(true)
        UpdateNodesService(
            rows.map((x) => {
                const { id, ...rest } = x
                return {
                    primaryKey: id,
                    others: {
                        ...rest,
                    },
                }
            }),
            projectId
        )
            .then((res) => {
                if (res.success) {
                    onConfirm && onConfirm()
                }
            })
            .finally(() => setLoading(false))
    }

    return (
        <Stack minHeight={360} maxHeight="80vh" overflow="auto">
            {/* Main content
				========================================= */}
            <Stack
                alignItems="flex-start"
                gap={1}
                py={2}
                px={3}
                sx={(theme) => ({
                    // @Theme conditional
                    flex: '1 1 auto',
                    backgroundColor:
                        theme.palette.mode === 'light'
                            ? theme.palette.common.bg_2
                            : theme.palette.common.bg_3,
                })}
            >
                <Typography paragraph fontSize={14} fontWeight={400} color="common.text_1">
                    Please select the CSV file that contains list of nodes. Please make sure the file must be
                    comma delimited and must contain a column with header `id`.
                </Typography>

                {/* Update and download buttons
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2} marginBottom={1}>
                    <BaseButton
                        variant="contained"
                        size="large"
                        label="Select File"
                        onClick={() => {
                            document.getElementById('update-node-csv')?.click()
                        }}
                    />
                    <input
                        id="update-node-csv"
                        type="file"
                        accept=".csv, .txt"
                        onChange={updateAndParseNodes}
                        style={{ display: 'none' }}
                    />
                </Stack>

                {/* Progress bar
				    ========================================= */}
                {loading && (
                    <Stack alignSelf="stretch" direction="row" alignItems="center" gap={2} marginBottom={3}>
                        <LinearProgress sx={{ width: '100%' }} />
                    </Stack>
                )}

                {hasData && (
                    /* (Conditional) Table
				            ========================================= */
                    <Box
                        sx={{
                            alignSelf: 'stretch',
                            maxHeight: '50vh',
                            overflow: 'auto',
                            marginBottom: 3,
                            paddingBottom: 1,
                        }}
                    >
                        <StyledDataGrid rows={rows} columns={columns} loading={loading} density="compact" />
                    </Box>
                )}

                {/* Footer buttons
				    ========================================= */}
                <Stack
                    alignSelf="stretch"
                    marginTop="auto"
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={3}
                >
                    <BaseButton
                        variant="outlined"
                        size="large"
                        label="Close"
                        onClick={(e) => onConfirm && onConfirm()}
                        sx={{
                            width: 186,
                            py: 1.5,
                        }}
                    />

                    <BaseButton
                        variant="contained"
                        size="large"
                        label="Update Nodes"
                        disabled={!hasData}
                        onClick={importNodes}
                        sx={{
                            width: 186,
                            py: 1.5,
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default UpdateNodeDialog
