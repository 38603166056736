//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import SurveyPreviewLogin from 'features/survey-preview/SurveyPreviewLogin'
//* ======= Custom logic
//* ======= Assets and styles

interface ISurveyLogin {}

function SurveyLogin({}: ISurveyLogin) {
    return (
        <Box
            sx={(theme) => ({
                flexGrow: 1,
                minHeight: 0,
                backgroundColor: 'common.bg_4',
            })}
        >
            <SurveyPreviewLogin />
        </Box>
    )
}

export default SurveyLogin
