//* ======= Libraries
import React, { useState } from 'react'
import { styled, TextFieldProps, Typography } from '@mui/material'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
//* ======= Custom logic
//* ======= Assets and styles
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

type PasswordFieldProps = TextFieldProps & { hasComplexityBar?: boolean }

interface IPasswordComplexity {
    complexity: 'none' | 'weak' | 'average' | 'good' | 'strong'
    value: 0 | 25 | 50 | 75 | 100
}

/* *
 * Notes:
 *  1.  The 'sx' prop is applied to the wrapping "Stack" not the field itself.
 *      All other props are redirected to the field. If you need to target the field,
 *      use nested classes inside sx.
 */

function PasswordField({ hasComplexityBar = false, helperText, sx, ...fieldProps }: PasswordFieldProps) {
    const [isTextVisible, setIsTextVisible] = useState(false)

    const [complexity, setComplexity] = useState<IPasswordComplexity>({
        complexity: 'none',
        value: 0,
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        if (value.length === 0) {
            setComplexity({
                complexity: 'weak',
                value: 0,
            })
        } else if (value.match(/^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{10,}$/)) {
            setComplexity({
                complexity: 'strong',
                value: 100,
            })
        } else if (value.match(/^(?=.*(?=.*[A-Z])[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/)) {
            setComplexity({
                complexity: 'good',
                value: 75,
            })
        } else if (value.match(/^(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/)) {
            setComplexity({
                complexity: 'average',
                value: 50,
            })
        } else {
            setComplexity({
                complexity: 'weak',
                value: 25,
            })
        }
    }

    const toggleVisibility = () => {
        setIsTextVisible((prevState) => !prevState)
    }

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    return (
        <Stack gap={0} sx={sx}>
            <BaseFilledTextField
                type={isTextVisible ? 'text' : 'password'}
                onInput={handleChange}
                size={hasComplexityBar ? 'small' : 'medium'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleVisibility}
                                onMouseDown={handleMouseDown}
                                edge="end"
                            >
                                {isTextVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...fieldProps}
                sx={
                    hasComplexityBar
                        ? {
                              '& .MuiFilledInput-root': {
                                  borderBottomLeftRadius: 0,
                                  borderBottomRightRadius: 0,
                              },
                          }
                        : undefined
                }
            />

            {hasComplexityBar && <StyledComplexityProgress variant="determinate" value={complexity.value} />}

            {helperText && (
                <Typography
                    variant="caption"
                    sx={{
                        color: '#EF5DA8',
                        marginX: '14px',
                        marginTop: '3px',
                        marginBottom: 0,
                        fontWeight: 400,
                    }}
                >
                    {helperText}
                </Typography>
            )}
        </Stack>
    )
}

const StyledComplexityProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 10,

    borderRadius: '0 0 8px 8px',
    backgroundColor: theme.palette.common.bg_2,

    '& .MuiLinearProgress-bar': {
        backgroundColor:
            value === 0
                ? theme.palette.common.bg_2
                : value === 25
                ? theme.palette.common.red
                : value === 50
                ? theme.palette.common.orange
                : value === 75
                ? theme.palette.common.yellow
                : theme.palette.common.green,
    },
}))

export default PasswordField
