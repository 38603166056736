import React, { useEffect, useRef } from 'react'
import Editor, { useMonaco, OnMount } from '@monaco-editor/react'
import { Box, Stack, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
export type ExpressionEditorSuggestionType = {
    name: string
    subItems?: ExpressionEditorSuggestionType[]
}

interface ExpressionEditorProps {
    variables: ExpressionEditorSuggestionType[]
    defaultExpression?: string
    onChange: (expression: string) => void
    id: string
    functions?: string[]
    label?: string
    error?: string
}

const mathFunctions = ['sqrt', 'log', 'sin', 'cos', 'tan']

const ExpressionEditor: React.FC<ExpressionEditorProps> = ({
    id,
    variables,
    defaultExpression,
    onChange,
    functions = mathFunctions,
    label,
    error,
}) => {
    const completionProvierRed = useRef<any>(null)

    const handleEditorDidMount: OnMount = (editor, monaco) => {
        const langKey = `simpleMath-${id}`

        // Check if the language already exists
        const existingLanguage = monaco.languages.getLanguages().find((language) => language.id === langKey)
        if (!existingLanguage) {
            // Define the simple math language only if it does not already exist
            monaco.languages.register({ id: langKey })
        }

        // Provide the tokens for the custom language
        monaco.languages.setMonarchTokensProvider(langKey, {
            tokenizer: {
                root: [
                    // Add tokenization rules here (optional)
                    [/\d+/, 'number'],
                    [/[+\-*/]/, 'operator'],
                    [/[a-zA-Z_]\w*\(/, 'function'],
                    [/[a-zA-Z_]\w*/, 'variable'],
                ],
            },
        })

        // Define a theme that gives the custom tokens colors (optional)
        monaco.editor.defineTheme('mathTheme', {
            base: 'vs',
            inherit: true,
            rules: [
                { token: 'number', foreground: '88b04b' },
                { token: 'operator', foreground: 'ff0000' },
                { token: 'variable', foreground: '0000ff' },
                { token: 'function', foreground: '0000ff', fontStyle: 'bold' },
            ],
            colors: {},
        })

        // Set the theme
        monaco.editor.setTheme('mathTheme')

        completionProvierRed.current = monaco.languages.registerCompletionItemProvider(langKey, {
            provideCompletionItems: function (model, position) {
                const textUntilPosition = model.getValueInRange({
                    startLineNumber: position.lineNumber,
                    startColumn: 1,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column,
                })

                const match = textUntilPosition.match(/([\w.]+)$/)
                const currentInputParts = match ? match[0].split('.') : []
                let suggestions: any[] = []

                // Function to recursively find suggestions based on the input parts
                const findSuggestions = (
                    inputParts: string[],
                    items: ExpressionEditorSuggestionType[]
                ): ExpressionEditorSuggestionType[] => {
                    if (inputParts.length === 0) return items

                    for (let i = 0; i < inputParts.length - 1; i++) {
                        const currentPart = inputParts[i]

                        const matchingItem = items.find((item) => item.name === currentPart)
                        if (matchingItem && matchingItem.subItems) {
                            items = matchingItem.subItems
                        } else {
                            return []
                        }
                    }

                    const lastPart = inputParts[inputParts.length - 1]

                    return items.filter((item) => item.name.startsWith(lastPart))
                }

                // Check if there is no input (Ctrl+Space on empty input) and adjust accordingly
                const isInputEmpty =
                    currentInputParts.length === 0 || (currentInputParts.length === 1 && currentInputParts[0] === '')
                const applicableSuggestions = isInputEmpty ? variables : findSuggestions(currentInputParts, variables)

                suggestions = applicableSuggestions.map((item) => ({
                    label: item.name,
                    kind: monaco.languages.CompletionItemKind.Variable,
                    insertText: item.name,
                    range: {
                        startLineNumber: position.lineNumber,
                        startColumn: isInputEmpty
                            ? position.column
                            : position.column - currentInputParts[currentInputParts.length - 1].length,
                        endLineNumber: position.lineNumber,
                        endColumn: position.column,
                    },
                }))

                // Check if the input is suitable for function suggestions
                const isFunctionInput = currentInputParts.length === 1 && currentInputParts[0] !== ''

                const functionSuggestions = isFunctionInput
                    ? functions
                          .filter((func) => func.startsWith(currentInputParts[0]))
                          .map((func) => ({
                              label: func,
                              kind: monaco.languages.CompletionItemKind.Function,
                              insertText: `${func}()`, // Insert the function with parentheses
                              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet, // Allows placing the cursor inside the parentheses
                              range: {
                                  startLineNumber: position.lineNumber,
                                  startColumn: position.column - currentInputParts[currentInputParts.length - 1].length,
                                  endLineNumber: position.lineNumber,
                                  endColumn: position.column,
                              },
                          }))
                    : []

                suggestions = [...suggestions, ...functionSuggestions]

                return { suggestions }
            },
        })

        editor.onDidBlurEditorWidget(() => {
            onChange(editor.getValue())
        })

        // disable press `Enter` in case of producing line breaks
        editor.addCommand(monaco.KeyCode.Enter, () => {
            // Get the suggest controller to check if the suggest widget is active
            const suggestController = editor.getContribution('editor.contrib.suggestController')

            // Check if the suggest widget is visible
            if (suggestController !== null && 'widget' in suggestController) {
                const widget = suggestController.widget as any
                if (widget.value._state === 3) {
                    editor.trigger('editor', 'acceptSelectedSuggestion', {})
                }
            } else {
                // If the suggest widget is not visible, you might want to handle the Enter key for other purposes
                // For example, trigger a validation or submission of the current value
                // Do nothing if you simply want to prevent line breaks
            }

            // Always prevent the default action (inserting a newline)
            return false
        })

        editor.onDidPaste((e: any) => {
            if (e.range.endLineNumber <= 1) {
                return
            }
            let newContent = ''
            const textModel = editor.getModel()
            if (!textModel) {
                return
            }
            const lineCount = textModel.getLineCount()
            // remove all line breaks
            for (let i = 0; i < lineCount; i += 1) {
                newContent += textModel.getLineContent(i + 1)
            }
            textModel.setValue(newContent)
            editor.setPosition({ column: newContent.length + 1, lineNumber: 1 })
        })

        // disable `F1` command palette
        editor.addCommand(monaco.KeyCode.F1, () => {})

        // eslint-disable-next-line no-bitwise
        editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyF, () => {})
    }

    useEffect(() => {
        return () => {
            completionProvierRed.current?.dispose()
        }
    }, [])

    return (
        <Box
            sx={{
                padding: 1,
                border: '1px solid #e0e0e0',
                borderRadius: 4,
                width: '100%',
            }}
        >
            {label && label.trim() !== '' && (
                <Typography variant="caption" sx={{ padding: '8px', color: 'text.secondary' }}>
                    {label}
                </Typography>
            )}
            <Editor
                height="44px"
                width="100%"
                defaultLanguage={`simpleMath-${id}`}
                defaultValue={defaultExpression}
                onMount={handleEditorDidMount}
                options={{
                    fontSize: 14,
                    fontWeight: 'normal',
                    wordWrap: 'off',
                    lineNumbers: 'off',
                    lineNumbersMinChars: 0,
                    overviewRulerLanes: 0,
                    overviewRulerBorder: false,
                    hideCursorInOverviewRuler: true,
                    lineDecorationsWidth: 10,
                    glyphMargin: false,
                    folding: false,
                    scrollBeyondLastColumn: 0,
                    scrollbar: {
                        horizontal: 'hidden',
                        vertical: 'hidden',
                        // avoid can not scroll page when hover monaco
                        alwaysConsumeMouseWheel: false,
                    },
                    // disable `Find`
                    find: {
                        addExtraSpaceOnTop: false,
                        autoFindInSelection: 'never',
                        seedSearchStringFromSelection: 'never',
                    },
                    minimap: { enabled: false },
                    // see: https://github.com/microsoft/monaco-editor/issues/1746
                    wordBasedSuggestions: 'off',
                    // avoid links underline
                    links: false,
                    // avoid highlight hover word
                    occurrencesHighlight: 'off',
                    cursorStyle: 'line-thin',
                    // hide current row highlight grey border
                    // see: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ieditoroptions.html#renderlinehighlight
                    renderLineHighlight: 'none',
                    contextmenu: false,
                    // default selection is rounded
                    roundedSelection: false,
                    hover: {
                        // unit: ms
                        // default: 300
                        delay: 100,
                    },
                    acceptSuggestionOnEnter: 'on',
                    // auto adjust width and height to parent
                    // see: https://github.com/Microsoft/monaco-editor/issues/543#issuecomment-321767059
                    automaticLayout: true,
                    // if monaco is inside a table, hover tips or completion may casue table body scroll
                    fixedOverflowWidgets: true,
                    lineHeight: 40,
                }}
            />
            {error && (
                <Stack gap={1} direction={'row'} alignItems={'center'}>
                    <ErrorIcon color="error" />
                    <Typography variant="caption" sx={{ padding: '8px', color: 'error.main' }}>
                        {error}
                    </Typography>
                </Stack>
            )}
        </Box>
    )
}

export default ExpressionEditor
