import { Box, IconButton, Stack, Tooltip } from '@mui/material'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { useReportActiveSlide } from 'features/report-designer/store/reportDesignerStore'
import { ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { useEffect, useState } from 'react'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import { set } from 'lodash'

type PanelWidgetTransferProps = {
    widgetId: ReportWidgetType['id']
    widgets: ReportWidgetType['id'][]
    onAddWidgets: (widgetId: ReportWidgetType['id'][]) => void
    onRemoveWidgets: (widgetId: ReportWidgetType['id'][]) => void
}

const PanelWidgetTransfer = ({ widgets, widgetId, onAddWidgets, onRemoveWidgets }: PanelWidgetTransferProps) => {
    const activeSlide = useReportActiveSlide()
    const columns: GridColDef<ReportWidgetType>[] = [{ field: 'title', headerName: 'Name', width: 200 }]

    const [availableWidgets, setAvailableWidgets] = useState<ReportWidgetType[]>([])
    const [selectedWidgets, setSelectedWidgets] = useState<ReportWidgetType[]>([])

    const [availableWidgetRowSelectionModel, setAvailableWidgetRowSelectionModel] = useState<GridRowSelectionModel>([])
    const [selectedWidgetRowSelectionModel, setSelectedWidgetRowSelectionModel] = useState<GridRowSelectionModel>([])

    useEffect(() => {
        if (activeSlide) {
            setAvailableWidgets(
                activeSlide.widgets.filter((_widget) => _widget.hide !== true && _widget.id !== widgetId)
            )
            setSelectedWidgets(activeSlide.widgets.filter((_widget) => widgets.includes(_widget.id)))
        }
    }, [activeSlide])

    const handleAddWidgets = () => {
        const widgetsToAdd = availableWidgetRowSelectionModel
            .map((row) => availableWidgets.find((_widget) => _widget.id === row))
            .filter((widget) => widget !== undefined) as ReportWidgetType[]
        setSelectedWidgets((prev) => [...prev, ...widgetsToAdd])
        setAvailableWidgets((prev) => prev.filter((_widget) => !availableWidgetRowSelectionModel.includes(_widget.id)))
        onAddWidgets(availableWidgetRowSelectionModel)
        setAvailableWidgetRowSelectionModel([])
    }

    const handleRemoveWidgets = () => {
        const widgetsToRemove = selectedWidgetRowSelectionModel
            .map((row) => selectedWidgets.find((_widget) => _widget.id === row))
            .filter((widget) => widget !== undefined) as ReportWidgetType[]
        setAvailableWidgets((prev) => [...prev, ...widgetsToRemove])
        setSelectedWidgets((prev) => prev.filter((_widget) => !selectedWidgetRowSelectionModel.includes(_widget.id)))
        onRemoveWidgets(selectedWidgetRowSelectionModel)
        setSelectedWidgetRowSelectionModel([])
    }

    return (
        <Stack direction={'row'} spacing={2}>
            <Box sx={{ minHeight: '300px', flex: 1 }}>
                <StyledDataGrid
                    columns={columns}
                    rows={availableWidgets}
                    rowSelectionModel={availableWidgetRowSelectionModel}
                    onRowSelectionModelChange={(model) => setAvailableWidgetRowSelectionModel(model)}
                    hasToolbar={false}
                    checkboxSelection={true}
                    hideFooter={true}
                    hasSearch={false}
                />
            </Box>
            <Stack direction={'column'} gap={1} justifyContent="center">
                <Tooltip title="Add selected widgets" enterDelay={500} enterNextDelay={500}>
                    <span>
                        <IconButton onClick={handleAddWidgets} disabled={availableWidgetRowSelectionModel.length === 0}>
                            <ArrowRightIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Remove selected widgets" enterDelay={500} enterNextDelay={500}>
                    <span>
                        <IconButton
                            disabled={selectedWidgetRowSelectionModel.length === 0}
                            onClick={handleRemoveWidgets}
                        >
                            <ArrowLeftIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>

            <Box sx={{ minHeight: '300px', flex: 1 }}>
                <StyledDataGrid
                    columns={columns}
                    rows={selectedWidgets}
                    hasToolbar={false}
                    checkboxSelection={true}
                    hideFooter={true}
                    rowSelectionModel={selectedWidgetRowSelectionModel}
                    onRowSelectionModelChange={(model) => setSelectedWidgetRowSelectionModel(model)}
                    hasSearch={false}
                />
            </Box>
        </Stack>
    )
}

export default PanelWidgetTransfer
