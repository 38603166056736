import React, { useEffect, useMemo } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CountryCodesIndexed from 'assets/json/CountryCode'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
import ReactCountryFlag from 'react-country-flag'

type CountryCodeSelectorProps = {
    value: string
    onChange: (value: string) => void
}

const DialCode = CountryCodesIndexed.byDialCode

const CountryCodeSelector = ({ value, onChange, ...props }: CountryCodeSelectorProps) => {
    const selected = useMemo(() => {
        // Check if the value starts with '+', if so, remove it
        const cleanValue = value.startsWith('+') ? value.substring(1) : value

        for (let i = 4; i >= 1; i--) {
            const code = '+' + cleanValue.substring(0, i)
            if (DialCode[code]) return code
        }
        return null
    }, [value])

    const list = React.useMemo(() => Object.values(CountryCodesIndexed.byName), [])

    const cuntryCode = useMemo(() => {
        if (!selected) return
        const codeData = DialCode[selected]
        return codeData?.code
    }, [selected])

    return (
        <Select
            id="code-select"
            value={selected}
            onChange={(e) => {
                const newCode = e.target?.value || '+' // new country dial code, e.g. '+93'
                // Remove the previous dial code from the beginning of the value, if it exists
                const numberWithoutCode = selected ? value.substring(selected.length - 1) : value
                const newFullNumber = newCode.substring(1) + numberWithoutCode
                onChange(newFullNumber)
            }}
            renderValue={(selected: string | null) =>
                cuntryCode && <ReactCountryFlag countryCode={cuntryCode} svg />
            }
            input={<BasicSelectInput />}
        >
            {list &&
                Array.isArray(list) &&
                list.map((country) => (
                    <MenuItem key={country.code} value={country.dial_code}>
                        <Stack direction="row" gap={1}>
                            <ReactCountryFlag countryCode={country.code} svg />
                            <Typography>{country.name}</Typography>
                            <Typography>{country.dial_code}</Typography>
                        </Stack>
                    </MenuItem>
                ))}
        </Select>
    )
}

type PhoneNumberInputProps = {
    label: string
    onValidate?: (valid: boolean) => void
    required: boolean
    style?: React.CSSProperties
    value?: string
    onChange: (value: string) => void
    onBlur: (e: any) => void
    error?: boolean
    helperText?: string
    fullWidth?: boolean
}

export default function PhoneNumberInput({
    fullWidth,
    onBlur,
    error = false,
    helperText,
    label,
    onValidate,
    required,
    style,
    value = '',
    onChange,
    ...props
}: PhoneNumberInputProps) {
    // preprocess the initial value to remove any '+' prefix
    const cleanedValue = useMemo(() => {
        return value.startsWith('+') ? value.substring(1) : value
    }, [value])

    const handleChange = (value: string) => {
        try {
            let number = value.startsWith('+') ? value.substring(1) : value
            number = number.replace(/\s/g, '')
            onChange(number)
        } catch (e) {}
    }

    return (
        <FormControl fullWidth={true} error={error} required={required} margin="normal" style={style}>
            <InputLabel htmlFor="standard-adornment-mobile">{label ? label : 'Contact Number'}</InputLabel>
            <Input
                fullWidth={fullWidth}
                id="standard-adornment-mobile"
                value={cleanedValue}
                dir="ltr"
                startAdornment={
                    <InputAdornment position="start">
                        <Stack direction="row" gap={1} justifyItems="center" alignItems="center">
                            <CountryCodeSelector value={value} onChange={handleChange} />
                            <Typography>+</Typography>
                        </Stack>
                    </InputAdornment>
                }
                aria-describedby="standard-phone-helper-text"
                inputProps={{
                    'aria-label': 'phone',
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                }}
                sx={{
                    height: '50px',
                }}
                onBlur={onBlur}
                onChange={(e) => handleChange(e.target.value)}
            />

            {helperText && <FormHelperText id="standard-phone-helper-text">{helperText}</FormHelperText>}
        </FormControl>
    )
}
