//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { round } from 'lodash'
import { Stack, Box, Typography, IconButton, Divider, Tooltip, Collapse } from '@mui/material'
//* ======= Components and features
import Chart from 'features/chart/Chart'
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import StyledDialog from 'components/dialog/StyledDialog'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ColorPicker from 'components/widgets/ColorPicker'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportDataSourceAttributeType,
    SelectedDataSourceType,
    ReportDataAggregationMethodType,
} from 'features/report-designer/types/reportDesigner.types'
import {
    collectExpressionVariableVariables,
    convertNodeAttributeToKeyString,
    getChartAttributes,
    getDataSourceData,
    getDataSourceFields,
    getExpressionVariableOptions,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { generateChartOptions, getChartTypeInitialValues } from 'features/chart/Chart.helper'
import { AGGREGATION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { ReportGaugeChartWidgetSettingsStateType } from 'features/report-designer/widgets/chart-widget/settings/GaugeChartWidgetSettings'
import { GaugeChartDataDefinitionType, GaugeChartOptionsType } from 'features/chart/Chart.asset'
import { applyAggregation } from 'features/report-designer/helpers/reportDesigner.helper'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import TuneIcon from '@mui/icons-material/Tune'
import ExpressionEditor from 'features/ExpressionEditor/ExpressionEditor'
import * as math from 'mathjs'

const aggregationFunctions = ['sum', 'mean', 'min', 'max', 'size', 'median', 'corr', 'std', 'variance']

type Props = {
    isOpen: boolean
    data: ReportGaugeChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onConfirm: (data: Pick<ReportGaugeChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>) => void
    onClose: () => void
}

function GaugeChartDataConfigDialog({
    isOpen = false,
    data,
    dataSources,
    onConfirm,
    onClose = () => undefined,
}: Props) {
    const [chartData, setChartData] = useState<ReportGaugeChartWidgetSettingsStateType>(data)

    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])
    const [chartOptions, setChartOptions] = useState<GaugeChartOptionsType | null>(chartData.options)

    const variableOptions = useMemo(() => {
        const datasource = dataSources.find((ds) => ds.id === chartData.selectedDataSource?.id)
        if (datasource) return getExpressionVariableOptions(datasource)
        return []
    }, [chartData.selectedDataSource, dataSources])

    const isExpressionValid = (
        expression: string
    ): {
        isValid: boolean
        error?: string
    } => {
        try {
            const parsedExpression = math.parse(expression)

            // Function to recursively check nodes
            const checkNodes = (
                node: math.MathNode
            ): {
                isValid: boolean
                error?: string
            } => {
                // Base case: If it's a FunctionNode, it's valid at this level
                if (node.type === 'FunctionNode') {
                    const funcNode = node as math.FunctionNode
                    let functionName = funcNode.fn.name
                    if (aggregationFunctions.includes(functionName)) {
                        return {
                            isValid: true,
                        }
                    } else {
                        return {
                            isValid: true,
                            error: `The function "${functionName}" is not supported. Supported functions are: ${aggregationFunctions.join(
                                ', '
                            )}`,
                        }
                    }
                }
                if (node.type === 'ConstantNode') {
                    return {
                        isValid: true,
                    }
                }

                // If it's an OperatorNode, check if all of its arguments are FunctionNode
                if (node.type === 'OperatorNode') {
                    const argsNode = node as math.FunctionNode
                    for (var _node of argsNode.args) {
                        const checkResult = checkNodes(_node)
                        if (checkResult.isValid === false) {
                            return checkResult
                        }
                    }
                    return {
                        isValid: true,
                    }
                }

                // If it's a ParenthesisNode, check the content inside
                if (node.type === 'ParenthesisNode') {
                    const argsNode = node as math.ParenthesisNode
                    return checkNodes(argsNode.content)
                }

                // Handling ConditionalNode: check condition, trueExpr, and falseExpr
                if (node.type === 'ConditionalNode') {
                    const conditionalNode = node as math.ConditionalNode
                    return (
                        checkNodes(conditionalNode.condition) &&
                        checkNodes(conditionalNode.trueExpr) &&
                        checkNodes(conditionalNode.falseExpr)
                    )
                }

                return {
                    isValid: false,
                    error: `Error at "${String(
                        node
                    )}". The expression should only contain valid functions and constants`,
                }
            }

            const checkFunctions = checkNodes(parsedExpression)

            if (checkFunctions.isValid === false) {
                return checkFunctions
            }

            const checkVariableExists = (varName: string) => {
                const splitVarName = varName.split('.')
                let currentItems = structuredClone(variableOptions)
                if (splitVarName[0] === 'info' && splitVarName.length !== 2) return false
                if (splitVarName[0] === 'analytics' && splitVarName.length !== 3) return false
                for (let i = 0; i < splitVarName.length; i++) {
                    const newItems = currentItems.find((item) => item.name === splitVarName[i])
                    if (newItems) {
                        currentItems = newItems.subItems || []
                    } else {
                        return false
                    }
                }
                return true
            }

            const allVariables = collectExpressionVariableVariables(parsedExpression)

            const isValidExpression = [...allVariables].every((varName) => checkVariableExists(varName))

            if (isValidExpression === false) {
                return {
                    isValid: false,
                    error: 'The expression contains invalid variables',
                }
            }
        } catch (error) {
            return {
                isValid: false,
                error: String(error),
            }
        }
        return {
            isValid: true,
            error: undefined,
        }
    }

    // Prevent user from applying any changes if the selected data configurations are not valid
    const isChartConfigValid = useMemo(() => {
        if (
            chartData.selectedDataSource === null ||
            chartData.selectedDataSource.id === -1 ||
            chartData.dataDefinition.indicator.field.field === '' ||
            ((chartData.dataDefinition.indicator.aggregationMethod === 'correlation' ||
                chartData.dataDefinition.indicator.aggregationMethod === 'covariance') &&
                (chartData.dataDefinition.indicator.compareField === undefined ||
                    chartData.dataDefinition.indicator.compareField.field === '')) ||
            (chartData.dataDefinition.indicator.aggregationMethod === 'expression' &&
                isExpressionValid(chartData.dataDefinition.indicator.field.field).isValid === false) ||
            chartData.dataDefinition.series.length === 0
        ) {
            return false
        }

        for (let _series of chartData.dataDefinition.series) {
            if (_series.aggregationMethod !== 'count' && _series.attribute.field.trim() === '') return false
        }

        return true
    }, [chartData])

    const getIndicatorFieldValueBounds = (
        aggregationMethod?: ReportDataAggregationMethodType,
        field?: ReportDataSourceAttributeType
    ): ReportGaugeChartWidgetSettingsStateType['dataDefinition']['indicator']['valueBounds'] => {
        let fieldMin: number = 0
        let fieldMax: number = 100

        let aggregationMethodValue =
            aggregationMethod !== undefined ? aggregationMethod : chartData.dataDefinition.indicator.aggregationMethod

        let fieldValue: ReportDataSourceAttributeType =
            field !== undefined ? field : chartData.dataDefinition.indicator.field

        const filteredData = getDataSourceData(dataSources, chartData.selectedDataSource, [fieldValue])

        if (filteredData !== undefined) {
            if (aggregationMethodValue === 'correlation') {
                fieldMin = -1
                fieldMax = 1
            } else if (aggregationMethodValue === 'count') {
                fieldMin = 0
                fieldMax = filteredData.length
            } else {
                const aggregatedMin = applyAggregation({
                    method: 'min',
                    data: filteredData,
                    field: convertNodeAttributeToKeyString(fieldValue),
                })
                const aggregatedMax = applyAggregation({
                    method: 'max',
                    data: filteredData,
                    field: convertNodeAttributeToKeyString(fieldValue),
                })

                fieldMin = aggregatedMin !== null ? round(aggregatedMin, chartData.config.decimalPrecision) : 0
                fieldMax = aggregatedMax !== null ? round(aggregatedMax, chartData.config.decimalPrecision) : 100

                if (aggregationMethodValue === 'sum') {
                    if (fieldMin !== null) fieldMin *= filteredData.length

                    if (fieldMax !== null) fieldMax *= filteredData.length
                }
            }
        }

        return {
            min: fieldMin,
            max: fieldMax,
        }
    }

    const indicatorFieldValueBounds: ReportGaugeChartWidgetSettingsStateType['dataDefinition']['indicator']['valueBounds'] =
        useMemo(() => {
            return getIndicatorFieldValueBounds(
                chartData.dataDefinition.indicator.aggregationMethod,
                chartData.dataDefinition.indicator.field
            )
        }, [
            chartData.dataDefinition.indicator.aggregationMethod,
            chartData.dataDefinition.indicator.field,
            dataSources,
        ])

    // After selected data source changes, update and show its fields.
    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, chartData.selectedDataSource))
    }, [chartData.selectedDataSource])

    // Update dialog's chart options after chartData state changes.
    useEffect(() => {
        if (isChartConfigValid) {
            setChartOptions((prevState) => {
                // We set the update mode to "all" to trigger a config generation as well because
                // some of the visual properties are calculated based on the data.
                const newOptions = generateChartOptions({
                    type: 'gauge',
                    mode: 'all',
                    prevOptions: prevState,
                    filteredData: getDataSourceData(
                        dataSources,
                        chartData.selectedDataSource,
                        getChartAttributes(chartData.type, chartData.dataDefinition)
                    ),
                    dataDefinition: chartData.dataDefinition,
                    config: chartData.config,
                })

                return { ...newOptions }
            })
        } else {
            setChartOptions(null)
        }
    }, [chartData, isChartConfigValid])

    const updateSelectedDataSource = (selectedDataSource: SelectedDataSourceType) => {
        const initialValues = getChartTypeInitialValues(chartData.type)

        if (initialValues !== null) {
            setChartData((prevState) => {
                // If the selected data source was changed (based on some of its properties that are considered important),
                // we need to reset some chart state properties to their initial values:
                let hasDataSourceChanged = false
                if (
                    prevState.selectedDataSource === null ||
                    prevState.selectedDataSource.id !== selectedDataSource.id ||
                    prevState.selectedDataSource.preset !== selectedDataSource.preset ||
                    prevState.selectedDataSource.type !== selectedDataSource.type
                ) {
                    hasDataSourceChanged = true
                }

                return {
                    ...prevState,
                    selectedDataSource: {
                        ...prevState.selectedDataSource,
                        ...selectedDataSource,
                    },
                    dataDefinition: hasDataSourceChanged
                        ? (initialValues.dataDefinition as GaugeChartDataDefinitionType)
                        : { ...prevState.dataDefinition },
                    options: hasDataSourceChanged ? null : { ...prevState.options },
                }
            })
        }
    }

    const onIndicatorChange = (
        field: keyof ReportGaugeChartWidgetSettingsStateType['dataDefinition']['indicator'],
        value: any
    ) => {
        let fieldMin: number | null = null
        let fieldMax: number | null = null
        let shouldResetDependantProperties = false

        let aggregationMethodValue = chartData.dataDefinition.indicator.aggregationMethod
        if (field === 'aggregationMethod') {
            aggregationMethodValue = value as ReportDataAggregationMethodType
        }

        let fieldValue: ReportDataSourceAttributeType = chartData.dataDefinition.indicator.field
        if (field === 'field') {
            fieldValue = value as ReportDataSourceAttributeType
        }

        // On changing the "field" property, update the value bounds of the selected field.
        if (field === 'aggregationMethod' || field === 'field') {
            const indicatorBounds = getIndicatorFieldValueBounds(aggregationMethodValue, fieldValue)
            fieldMin = indicatorBounds.min
            fieldMax = indicatorBounds.max

            // This flag will help with reseting dataDefinition properties which need to be reset on important indicator fields change.
            shouldResetDependantProperties = true
        }

        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                indicator: {
                    ...prevState.dataDefinition.indicator,
                    valueBounds: {
                        ...prevState.dataDefinition.indicator.valueBounds,
                        min: fieldMin ?? prevState.dataDefinition.indicator.valueBounds.min,
                        max: fieldMax ?? prevState.dataDefinition.indicator.valueBounds.max,
                    },
                    [field]: value,
                },
                isColoredByValue: shouldResetDependantProperties ? false : prevState.dataDefinition.isColoredByValue,
                colorStops: shouldResetDependantProperties ? [] : [...prevState.dataDefinition.colorStops],
            },
        }))
    }

    /* =========================================
     * Color stops
     */

    const addColorStop = () => {
        const newColorStop: ReportGaugeChartWidgetSettingsStateType['dataDefinition']['colorStops'][number] = {
            upperBoundValue: chartData.dataDefinition.indicator.valueBounds.max,
            color: '#999999FF',
        }

        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                colorStops: [...prevState.dataDefinition.colorStops, newColorStop],
            },
        }))
    }

    const removeColorStop = (itemListIndex: number) => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                colorStops: prevState.dataDefinition.colorStops.filter((_, idx) => idx !== itemListIndex),
            },
        }))
    }

    const updateColorStop = (
        itemListIndex: number,
        field: keyof ReportGaugeChartWidgetSettingsStateType['dataDefinition']['colorStops'][number],
        value: any
    ) => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                colorStops: prevState.dataDefinition.colorStops.map((_colorStop, idx) => {
                    if (idx === itemListIndex) {
                        return {
                            ..._colorStop,
                            [field]: value,
                        }
                    } else {
                        return _colorStop
                    }
                }),
            },
        }))
    }

    /* =========================================
     * Series
     */

    const addSeries = () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                series: [
                    ...prevState.dataDefinition.series,
                    {
                        aggregationMethod: 'avg',
                        attribute: {
                            type: 'custom',
                            field: 'Total',
                        },
                        color: { isEnabled: false, value: undefined },
                    },
                ],
            },
        }))
    }

    const deleteSeries = (seriesListIndex: number) => () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                series: prevState.dataDefinition.series.filter((_series, _idx) => seriesListIndex !== _idx),
            },
        }))
    }

    const updateSeries =
        (seriesListIndex: number) =>
        (field: keyof ReportGaugeChartWidgetSettingsStateType['dataDefinition']['series'][number]) =>
        (value: any) => {
            setChartData((prevState) => {
                const updatedSeriesList = structuredClone(prevState.dataDefinition.series)

                updatedSeriesList[seriesListIndex][field] = value

                return {
                    ...prevState,
                    dataDefinition: {
                        ...prevState.dataDefinition,
                        series: [...updatedSeriesList],
                    },
                }
            })
        }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',

                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),

                    padding: theme.spacing(2, 3),

                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Gauge Chart Data Configuration
                </Typography>

                <IconButton
                    onClick={(evt) => onClose()}
                    size="small"
                    sx={{
                        gridColumn: 3,
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 1 auto',

                    display: 'grid',
                    gridTemplateColumns: '10fr 9fr',
                    placeContent: 'stretch',
                    columnGap: 2,

                    height: '70vh',
                    padding: theme.spacing(3),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {/* Content wrapper
                    ========================================= */}
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        paddingRight: theme.spacing(2),
                        overflowY: 'auto',

                        borderRight: `1px solid ${theme.palette.common.border_3}`,
                    })}
                    className="u-scrollbar"
                >
                    {/* Data source selector
                        ========================================= */}
                    <ReportDataSourceSelector
                        dataSources={dataSources}
                        selectedDataSource={chartData.selectedDataSource}
                        onChange={updateSelectedDataSource}
                    />

                    <Divider />

                    {/* Indicator
                        ========================================= */}
                    <Stack
                        gap={1}
                        sx={(theme) => ({
                            marginBottom: theme.spacing(1),
                        })}
                    >
                        <Typography textAlign="left" noWrap>
                            Indicator:
                        </Typography>

                        {/* Indicator aggregation method and field
                            ========================================= */}
                        <Box
                            sx={(theme) => ({
                                display: 'grid',
                                gridTemplateColumns:
                                    chartData.dataDefinition.indicator.aggregationMethod === 'expression'
                                        ? '1fr'
                                        : '2fr',
                                alignItems: 'center',
                                columnGap: theme.spacing(2),
                                rowGap: theme.spacing(1),
                            })}
                        >
                            {/* Aggregation method
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Aggregation Method"
                                options={[
                                    {
                                        label: 'Expression',
                                        value: 'expression',
                                    },
                                    ...AGGREGATION_OPTIONS,
                                    {
                                        label: 'Correlation',
                                        value: 'correlation',
                                    },
                                    {
                                        label: 'Covariance',
                                        value: 'covariance',
                                    },
                                ]}
                                value={
                                    dataSourceFields.length !== 0
                                        ? chartData.dataDefinition.indicator.aggregationMethod
                                        : ''
                                }
                                onChange={(value) => onIndicatorChange('aggregationMethod', value)}
                                disabled={dataSourceFields.length === 0}
                                required
                            />

                            {chartData.dataDefinition.indicator.aggregationMethod === 'expression' ? (
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <ExpressionEditor
                                        error={isExpressionValid(chartData.dataDefinition.indicator.field.field).error}
                                        id="gauge-chart-indicator-expression"
                                        onChange={(value) =>
                                            onIndicatorChange('field', {
                                                type: 'basic',
                                                field: value,
                                            })
                                        }
                                        variables={variableOptions}
                                        functions={aggregationFunctions}
                                        defaultExpression={chartData.dataDefinition.indicator.field.field}
                                    />
                                </Box>
                            ) : (
                                <>
                                    {/* Indicator field
                                ========================================= */}
                                    <FlexibleSelect
                                        label="Field"
                                        options={dataSourceFields}
                                        value={
                                            dataSourceFields.length !== 0
                                                ? chartData.dataDefinition.indicator.field
                                                : undefined
                                        }
                                        onChange={(value) => onIndicatorChange('field', value)}
                                        disabled={dataSourceFields.length === 0}
                                        required
                                    />

                                    {/* (Conditional) Compare field
                                ========================================= */}
                                    {['correlation', 'covariance'].includes(
                                        chartData.dataDefinition.indicator.aggregationMethod
                                    ) && (
                                        <FlexibleSelect
                                            label="Compare Field"
                                            options={dataSourceFields}
                                            value={
                                                dataSourceFields.length !== 0
                                                    ? chartData.dataDefinition.indicator.compareField
                                                    : undefined
                                            }
                                            onChange={(value) => onIndicatorChange('compareField', value)}
                                            disabled={dataSourceFields.length === 0}
                                            required
                                        />
                                    )}
                                </>
                            )}
                        </Box>

                        {/* Indicator min and max fields
                            ========================================= */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                            {/* Indicator min
                                ========================================= */}
                            <BaseFilledTextField
                                type="number"
                                label="Min"
                                value={
                                    isNaN(chartData.dataDefinition.indicator.valueBounds.min)
                                        ? 0
                                        : chartData.dataDefinition.indicator.valueBounds.min
                                }
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    onIndicatorChange('valueBounds', {
                                        ...chartData.dataDefinition.indicator.valueBounds,
                                        min: Number(evt.target.valueAsNumber.toFixed(3)),
                                    })
                                }}
                                onBlur={(evt) => {
                                    if (Number(evt.target.value) > indicatorFieldValueBounds.min) {
                                        onIndicatorChange('valueBounds', {
                                            ...chartData.dataDefinition.indicator.valueBounds,
                                            min: indicatorFieldValueBounds.min,
                                        })
                                    }
                                }}
                                disabled={
                                    chartData.dataDefinition.indicator.field.field.trim() === '' ||
                                    isNaN(chartData.dataDefinition.indicator.valueBounds.min)
                                }
                                inputProps={{
                                    max: indicatorFieldValueBounds.min,
                                    step: 1,
                                }}
                                required
                                sx={{
                                    flex: '1 0 0',
                                }}
                            />

                            {/* Indicator max
                                ========================================= */}
                            <BaseFilledTextField
                                type="number"
                                label="Max"
                                value={
                                    isNaN(chartData.dataDefinition.indicator.valueBounds.max)
                                        ? 0
                                        : chartData.dataDefinition.indicator.valueBounds.max
                                }
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    onIndicatorChange('valueBounds', {
                                        ...chartData.dataDefinition.indicator.valueBounds,
                                        max: Number(evt.target.valueAsNumber.toFixed(3)),
                                    })
                                }
                                onBlur={(evt) => {
                                    if (Number(evt.target.value) < indicatorFieldValueBounds.max) {
                                        onIndicatorChange('valueBounds', {
                                            ...chartData.dataDefinition.indicator.valueBounds,
                                            max: indicatorFieldValueBounds.max,
                                        })
                                    }
                                }}
                                disabled={
                                    chartData.dataDefinition.indicator.field.field.trim() === '' ||
                                    isNaN(chartData.dataDefinition.indicator.valueBounds.max)
                                }
                                inputProps={{
                                    min: indicatorFieldValueBounds.max,
                                    step: 1,
                                }}
                                required
                                sx={{
                                    flex: '1 0 0',
                                }}
                            />
                        </Stack>
                    </Stack>

                    {/* Visualize by percentage switch
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography
                            sx={(theme) => ({
                                flex: '1 0 0',

                                fontSize: 14,
                                textAlign: 'left',
                            })}
                        >
                            Visualize by percentage:
                        </Typography>

                        <BaseSwitch
                            checked={chartData.dataDefinition.isVisualizedByPercentage}
                            onChange={(evt, checked) =>
                                setChartData((prevState) => ({
                                    ...prevState,
                                    dataDefinition: {
                                        ...prevState.dataDefinition,
                                        isVisualizedByPercentage: checked,
                                    },
                                }))
                            }
                            color="primary"
                            sx={{
                                flexShrink: 0,
                            }}
                        />
                    </Stack>

                    {/* Color by value section
                        ========================================= */}
                    <StyledWidgetAccordion
                        title="Color By Value"
                        hasToggle
                        isToggledOff={chartData.dataDefinition.isColoredByValue === false}
                        onToggle={(isEnabled) =>
                            setChartData((prevState) => ({
                                ...prevState,
                                dataDefinition: { ...prevState.dataDefinition, isColoredByValue: isEnabled },
                            }))
                        }
                    >
                        <Stack gap={1}>
                            {chartData.dataDefinition.colorStops.map((_colorStop, idx) => (
                                <GaugeChartIndicatorColorStopItem
                                    key={_colorStop.upperBoundValue.toString() + idx}
                                    data={_colorStop}
                                    indicatorValueBounds={chartData.dataDefinition.indicator.valueBounds}
                                    onUpdate={(field, value) => updateColorStop(idx, field, value)}
                                    onRemove={() => removeColorStop(idx)}
                                    isDisabled={chartData.dataDefinition.isColoredByValue === false}
                                />
                            ))}

                            {/* Add color stop button
                                ========================================= */}
                            <BaseButton
                                label="Add Color Stop"
                                onClick={(evt) => addColorStop()}
                                disabled={chartData.dataDefinition.isColoredByValue === false}
                                startIcon={<AddIcon />}
                                sx={{
                                    alignSelf: 'center',

                                    marginTop: 1,
                                }}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    <Divider />

                    {/* Series header
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Series:
                        </Typography>

                        {/* Add series button
                            ========================================= */}
                        <BaseButton
                            label="Add Series"
                            onClick={(evt) => addSeries()}
                            disabled={dataSourceFields.length === 0}
                            startIcon={<AddIcon />}
                        />
                    </Stack>

                    {/* Series list
                        ========================================= */}
                    <Stack gap={2}>
                        {/* Series items
                            ========================================= */}
                        {chartData.dataDefinition.series.map((_series, idx, array) => (
                            <React.Fragment key={idx}>
                                <GaugeChartSeriesItem
                                    series={_series}
                                    fieldOptions={dataSourceFields}
                                    onUpdate={updateSeries(idx)}
                                    onDelete={deleteSeries(idx)}
                                />

                                {idx !== array.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Stack>

                {/* Chart wrapper
                    ========================================= */}
                {chartOptions === null ? (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: '100%',
                        }}
                    >
                        Invalid/Empty data.
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Chart type={chartData.type} options={chartOptions} clearOnChange={true} />
                    </Box>
                )}
            </Box>

            {/* Footer
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    padding: theme.spacing(2, 3),
                })}
            >
                <BaseButton
                    label="Discard"
                    onClick={(evt) => onClose()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Apply"
                    onClick={(evt) => onConfirm(chartData)}
                    disabled={isChartConfigValid === false}
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </StyledDialog>
    )
}

/* =========================================
 * Indicator color stop item
 */

type GaugeChartIndicatorColorStopItemType = {
    data: ReportGaugeChartWidgetSettingsStateType['dataDefinition']['colorStops'][number]
    indicatorValueBounds: ReportGaugeChartWidgetSettingsStateType['dataDefinition']['indicator']['valueBounds']
    onUpdate: (
        field: keyof ReportGaugeChartWidgetSettingsStateType['dataDefinition']['colorStops'][number],
        value: any
    ) => void
    onRemove: () => void
    isDisabled: boolean
}

const GaugeChartIndicatorColorStopItem = ({
    data,
    indicatorValueBounds,
    onUpdate,
    onRemove,
    isDisabled,
}: GaugeChartIndicatorColorStopItemType) => {
    const [upperBoundValue, setUpperBoundValue] = useState(data.upperBoundValue)

    return (
        <Stack direction="row" alignItems="center" gap={3}>
            {/* Upper bound value
                ========================================= */}
            <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                    flex: '1 0 0',
                }}
            >
                <Typography
                    fontSize={14}
                    textAlign="left"
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    Upper Bound:
                </Typography>

                <BaseFilledTextField
                    type="number"
                    value={upperBoundValue}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        setUpperBoundValue(Number(evt.target.valueAsNumber.toFixed(3)))
                    }
                    onBlur={(evt) => {
                        const numericValue = Number(evt.target.value)

                        if (Number(numericValue.toFixed(3)) < indicatorValueBounds.min) {
                            onUpdate('upperBoundValue', indicatorValueBounds.min)
                        } else if (Number(numericValue.toFixed(3)) > indicatorValueBounds.max) {
                            onUpdate('upperBoundValue', indicatorValueBounds.max)
                        } else {
                            onUpdate('upperBoundValue', Number(numericValue.toFixed(3)))
                        }
                    }}
                    disabled={isDisabled}
                    inputProps={{
                        min: indicatorValueBounds.min,
                        max: indicatorValueBounds.max,
                        step: 1,
                    }}
                    required
                    hiddenLabel
                    size="small"
                    sx={{
                        flex: '1 0 0',
                    }}
                />
            </Stack>

            {/* Color
                ========================================= */}
            <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                    flex: '1 0 0',
                }}
            >
                <Typography
                    fontSize={14}
                    textAlign="left"
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    Color:
                </Typography>

                <Box
                    sx={{
                        flexShrink: 0,

                        width: 200,
                    }}
                >
                    <ColorPicker
                        value={data.color}
                        onChange={(newColor) => onUpdate('color', newColor)}
                        disabled={isDisabled}
                        isPopover={true}
                        gap={1}
                    />
                </Box>
            </Stack>

            {/* Remove button
                ========================================= */}
            <IconButton
                onClick={(evt) => onRemove()}
                disabled={isDisabled}
                sx={(theme) => ({
                    flexShrink: 0,

                    color: theme.palette.common.ung_pink,
                })}
            >
                <DeleteIcon />
            </IconButton>
        </Stack>
    )
}

/* =========================================
 * Series item
 */

type GaugeChartSeriesItemProps = {
    series: ReportGaugeChartWidgetSettingsStateType['dataDefinition']['series'][number]
    fieldOptions: FlexibleSelectOptionType[]
    onUpdate: (
        field: keyof ReportGaugeChartWidgetSettingsStateType['dataDefinition']['series'][number]
    ) => (value: any) => void
    onDelete: () => void
}

const GaugeChartSeriesItem = ({ series, fieldOptions, onUpdate, onDelete }: GaugeChartSeriesItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const [title, setTitle] = useState(series.title || '')

    const seriesFieldOptions = [
        {
            label: 'Total',
            value: {
                type: 'custom',
                field: 'Total',
            },
        },
        {
            label: 'Each Row',
            value: {
                type: 'custom',
                field: 'Each Row',
            },
        },
        ...fieldOptions,
    ]

    return (
        <Stack>
            {/* Primary/Required fields
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                {/* Field
                    ========================================= */}
                <FlexibleSelect
                    label="Field"
                    options={seriesFieldOptions}
                    value={seriesFieldOptions.length !== 0 ? series.attribute : undefined}
                    onChange={onUpdate('attribute')}
                    disabled={series.aggregationMethod === 'count'}
                    required
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Aggregation method and field label fields
                    ========================================= */}
                {series.attribute.type === 'custom' && series.attribute.field === 'Each Row' && (
                    /*  Field label
                        ========================================= */
                    <FlexibleSelect
                        label="Label"
                        options={fieldOptions}
                        value={fieldOptions.length !== 0 ? series.fieldLabel : undefined}
                        onChange={onUpdate('fieldLabel')}
                        sx={{
                            flex: '1 0 0',
                        }}
                    />
                )}

                {/* Extra options expand button
                    ========================================= */}
                <Tooltip title="Extra Options">
                    <IconButton
                        onClick={(evt) => setIsExpanded((prevState) => !prevState)}
                        color={isExpanded ? 'primary' : undefined}
                        sx={{
                            flexShrink: 0,
                        }}
                    >
                        <TuneIcon />
                    </IconButton>
                </Tooltip>

                <Divider orientation="vertical" />

                {/* Delete button
                    ========================================= */}
                <Tooltip title="Delete">
                    <IconButton
                        onClick={(evt) => onDelete()}
                        sx={(theme) => ({
                            flexShrink: 0,

                            color: theme.palette.common.ung_pink,
                        })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            {/* Extra options collapsible area
                ========================================= */}
            <Collapse in={isExpanded}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={4} marginTop={2}>
                    {/* Title
                        ========================================= */}
                    <BaseFilledTextField
                        label="Series Title"
                        helperText="Optional. If not set, an auto generated title will be used based on the selected aggregation method and field."
                        value={title}
                        onChange={(evt) => setTitle(evt.target.value)}
                        onBlur={(evt) => onUpdate('title')(evt.target.value.trim())}
                        sx={{
                            flex: '1 0 0',
                        }}
                    />

                    {/* Color
                        ========================================= */}
                    <Box
                        sx={{
                            flex: '1 0 0',
                        }}
                    >
                        <StyledWidgetAccordion
                            title="Color"
                            hasToggle
                            isToggledOff={series.color.isEnabled === false}
                            onToggle={(isEnabled) =>
                                onUpdate('color')({
                                    ...series.color,
                                    isEnabled: isEnabled,
                                })
                            }
                        >
                            <ColorPicker
                                value={series.color.value}
                                onChange={(newColor) =>
                                    onUpdate('color')({
                                        ...series.color,
                                        value: newColor,
                                    })
                                }
                                disabled={series.color.isEnabled === false}
                                isPopover={true}
                            />
                        </StyledWidgetAccordion>
                    </Box>
                </Stack>
            </Collapse>
        </Stack>
    )
}

export default GaugeChartDataConfigDialog
