import { isEqual } from 'lodash'
import { HistoryActionItemType, HistoryActionType, ReportDesignerStoreStateType } from './reportDesignerStore'
import { ReportMasterSettingsType, ReportSlideType, ReportWidgetType } from '../types/reportDesigner.types'
import diff, { Difference } from 'microdiff'

type IndexAdjustmentsType = {
    [key: string]: {
        [key: number]: number
    }
}

// adjust the index of the array based on the index adjustments
const adjustIndex = (indexAdjustments: IndexAdjustmentsType[string], currentIndex: number): number => {
    for (let index in indexAdjustments) {
        const numericIndex = parseInt(index)
        if (numericIndex <= currentIndex) {
            currentIndex += indexAdjustments[numericIndex]
        }
    }
    return currentIndex
}

// generate a key for the change based on the history item and the change
const generateKey = (historyItem: Extract<HistoryActionItemType, { action: 'update' }>, change: Difference): string => {
    const slideId = 'slideId' in historyItem ? historyItem.slideId + '_' : ''
    const widgetId = 'widgetId' in historyItem ? historyItem.widgetId + '_' : ''

    // exclude the last key in the path, as it is the key that is being changed
    return `${slideId}${widgetId}${change.path.slice(0, -1).join('_')}`
}

const applyChanges = (
    item: Extract<HistoryActionItemType, { action: 'update' }>,
    indexAdjustments: IndexAdjustmentsType,
    targetElement: ReportWidgetType | ReportSlideType | ReportMasterSettingsType,
    mode: 'undo' | 'redo'
) => {
    for (let change of item.data) {
        const key = generateKey(item, change)
        if (indexAdjustments[key] === undefined) indexAdjustments[key] = {}

        let parentElement: any = targetElement
        for (let i = 0; i < change.path.length - 1; i++) {
            parentElement = parentElement[change.path[i]]
        }
        let lastKey = change.path[change.path.length - 1]

        // if the last key is a number and the parent element is an array, adjust the index
        // this is needed to handle the case where this array items are added or removed by other actions/changes in the history
        if (typeof lastKey === 'number' && Array.isArray(parentElement)) {
            lastKey = adjustIndex(indexAdjustments[key], lastKey)
        }

        switch (change.type) {
            case 'CHANGE':
                if (mode === 'undo') {
                    parentElement[lastKey] = change.oldValue
                } else {
                    parentElement[lastKey] = change.value
                }
                break
            case 'CREATE':
                if (mode === 'undo') {
                    if (typeof lastKey === 'number' && Array.isArray(parentElement)) {
                        parentElement.splice(lastKey, 1)
                        indexAdjustments[key][lastKey] = (indexAdjustments[key][lastKey] ?? 0) + -1
                    } else {
                        delete parentElement[lastKey]
                    }
                } else {
                    if (typeof lastKey === 'number' && Array.isArray(parentElement)) {
                        parentElement.splice(lastKey, 0, change.value)
                        indexAdjustments[key][lastKey] = (indexAdjustments[key][lastKey] ?? 0) + 1
                    } else {
                        parentElement[lastKey] = change.value
                    }
                }
                break
            case 'REMOVE':
                if (mode === 'undo') {
                    if (typeof lastKey === 'number' && Array.isArray(parentElement)) {
                        parentElement.splice(lastKey, 0, change.oldValue)
                        indexAdjustments[key][lastKey] = (indexAdjustments[key][lastKey] ?? 0) + 1
                    } else {
                        parentElement[lastKey] = change.oldValue
                    }
                } else {
                    if (typeof lastKey === 'number' && Array.isArray(parentElement)) {
                        parentElement.splice(lastKey, 1)
                        indexAdjustments[key][lastKey] = (indexAdjustments[key][lastKey] ?? 0) + -1
                    } else {
                        delete parentElement[lastKey]
                    }
                }
                break
        }
    }
}

// perform the history action based on the mode (undo or redo)
export const perfromHistoryAction = (
    state: ReportDesignerStoreStateType,
    historyItem: HistoryActionType,
    mode: 'undo' | 'redo'
): HistoryActionType | null => {
    const futureAction: HistoryActionItemType[] = []

    // Object to track our index adjustments
    let indexAdjustments: IndexAdjustmentsType = {}

    for (let item of historyItem.actions) {
        switch (item.type) {
            case 'widget': {
                const slideId = item.slideId
                const targetSlide = state.slides.find((_slide) => _slide.id === slideId)
                if (targetSlide === undefined) return null
                switch (item.action) {
                    case 'add':
                        const widgetId = item.widgetId
                        targetSlide.widgets = targetSlide.widgets.filter((widget) => widget.id !== widgetId)
                        state.activeSlideId = item.slideId
                        state.activeWidgetId = null
                        futureAction.push({
                            type: 'widget',
                            action: 'delete',
                            slideId: item.slideId,
                            widgetId: item.widgetId,
                            index: item.index,
                            data: item.data,
                        })
                        break
                    case 'delete':
                        targetSlide.widgets.splice(item.index, 0, item.data)
                        state.activeSlideId = item.slideId
                        state.activeWidgetId = null
                        futureAction.push({
                            type: 'widget',
                            action: 'add',
                            slideId: item.slideId,
                            widgetId: item.widgetId,
                            index: item.index,
                            data: item.data,
                        })
                        break
                    case 'update': {
                        const widgetId = item.widgetId
                        const targetWidget = targetSlide.widgets.find((_widget) => _widget.id === widgetId)
                        if (targetWidget === undefined) return null
                        futureAction.push(item)
                        applyChanges(item, indexAdjustments, targetWidget, mode)

                        state.activeSlideId = item.slideId
                        state.activeWidgetId = null

                        break
                    }
                    case 'layer': {
                        const widgetId = item.widgetId
                        const currentIndex = targetSlide.widgets.findIndex((_widget) => _widget.id === widgetId)
                        if (currentIndex === -1) return null
                        const nextIndex = item.index
                        futureAction.push({
                            type: 'widget',
                            action: 'layer',
                            slideId: item.slideId,
                            widgetId: item.widgetId,
                            index: currentIndex,
                        })
                        const targetWidget = targetSlide.widgets[currentIndex]
                        targetSlide.widgets.splice(currentIndex, 1)
                        targetSlide.widgets.splice(nextIndex, 0, targetWidget)
                        state.activeSlideId = item.slideId
                        break
                    }
                }
                break
            }
            case 'slide': {
                const slideId = item.slideId
                switch (item.action) {
                    case 'add': {
                        const targetSlide = state.slides.find((_slide) => _slide.id === slideId)
                        if (targetSlide === undefined) return null
                        state.slides = state.slides.filter((_slide) => _slide.id !== slideId)
                        state.activeSlideId = state.slides[0]?.id ?? null
                        state.activeWidgetId = null
                        futureAction.push({
                            type: 'slide',
                            action: 'delete',
                            slideId: item.slideId,
                            data: item.data,
                            index: item.index,
                        })
                        break
                    }
                    case 'delete': {
                        state.slides.splice(item.index, 0, item.data)
                        state.activeSlideId = item.slideId
                        state.activeWidgetId = null
                        futureAction.push({
                            type: 'slide',
                            action: 'add',
                            slideId: item.slideId,
                            data: item.data,
                            index: item.index,
                        })
                        break
                    }
                    case 'update': {
                        const targetSlide = state.slides.find((_slide) => _slide.id === slideId)
                        if (targetSlide === undefined) return null
                        futureAction.push(item)
                        applyChanges(item, indexAdjustments, targetSlide, mode)
                        state.activeSlideId = item.slideId
                        state.activeWidgetId = null

                        break
                    }
                    case 'layer': {
                        const currentIndex = state.slides.findIndex((_slide) => _slide.id === slideId)
                        if (currentIndex === -1) return null
                        const nextIndex = item.index
                        futureAction.push({
                            type: 'slide',
                            action: 'layer',
                            slideId: item.slideId,
                            index: currentIndex,
                        })
                        const targetSlide = state.slides[currentIndex]
                        state.slides.splice(currentIndex, 1)
                        state.slides.splice(nextIndex, 0, targetSlide)
                        state.activeSlideId = item.slideId

                        break
                    }
                }
                break
            }
        }
    }
    return {
        name: historyItem.name,
        timestamp: historyItem.timestamp,
        actions: futureAction,
    }
}

export const addToHistory = (
    history: ReportDesignerStoreStateType['history'],
    action: HistoryActionItemType | HistoryActionItemType[],
    name: string
) => {
    const timestamp = new Date().getTime()
    const actionItems = Array.isArray(action) ? action : [action]

    // if history not empty, check if the last history item can be merged with the new action
    // The merge happen if:
    // - the last history item was added less than 1 second ago
    // - all the actions in the last history item and the new action are of the same type
    // - the slide ids and widget ids (if applicable) are the same
    if (history.past.length > 0) {
        const lastHistory = history.past[history.past.length - 1]

        // helper function to get the target of the action
        // if all the actions are of the same type and have the same target, return the target, otherwise return null
        const getActionTarget = (
            actions: HistoryActionItemType[]
        ): {
            type: string
            slideId?: string | number
            widgetId?: string | number
            action: string
        } | null => {
            if (actions.length === 0) return null

            const firstAction = actions[0]
            const result = {
                type: firstAction.type,
                slideId: 'slideId' in firstAction ? firstAction.slideId : undefined,
                widgetId: 'widgetId' in firstAction ? firstAction.widgetId : undefined,
                action: firstAction.action,
            }

            for (let i = 1; i < actions.length; i++) {
                const action = actions[i]
                const slideId = 'slideId' in action ? action.slideId : undefined
                const widgetId = 'widgetId' in action ? action.widgetId : undefined
                if (
                    action.type !== result.type ||
                    slideId !== result.slideId ||
                    widgetId !== result.widgetId ||
                    actions[i].action !== result.action
                ) {
                    return null
                }
            }

            return result
        }

        // get the target of the current action and the last history item
        const currentActionTarget = getActionTarget(actionItems)
        const lastActionTarget = getActionTarget(lastHistory.actions)

        // check if merge conditions are met, if so, merge the actions and return
        // otherwise, run the normal flow
        if (
            timestamp - lastHistory.timestamp < 1000 &&
            currentActionTarget !== null &&
            lastActionTarget !== null &&
            isEqual(currentActionTarget, lastActionTarget)
        ) {
            lastHistory.actions.push(...actionItems)
            return
        }
    }

    // Limit the history size
    const historySize = 100

    // if the history is full, remove the first item
    if (history.past.length > historySize) {
        history.past.shift()
    }

    const historyItem: HistoryActionType = {
        actions: actionItems,
        name: name,
        timestamp,
    }

    // add the action to the history
    history.past.push(historyItem)

    // clear the future history
    history.future = []
}

export const addUpdateWidgetToHistory = ({
    widgets,
    history,
    slideId,
    name,
    updateHistory = true,
}: {
    widgets: {
        currentWidget: ReportWidgetType
        updatedWidget: ReportWidgetType
    }[]
    history: ReportDesignerStoreStateType['history']
    slideId: ReportSlideType['id']
    name?: string
    updateHistory?: boolean
}) => {
    const widgetChanges = widgets
        .map(({ currentWidget, updatedWidget }) => {
            let diffWidget = diff(currentWidget, updatedWidget)

            // exclude the fields that should not be tracked
            switch (updatedWidget.content.kind) {
                case 'panel':
                    diffWidget = diffWidget.filter((item) => {
                        if (item.path.length === 0) return true
                        const lastKey = item.path[item.path.length - 1]
                        if (lastKey === 'selectedWidgetId') return false
                        return true
                    })
                    break
                case 'combined':
                    diffWidget = diffWidget.filter((item) => {
                        if (item.path.length === 0) return true
                        const lastKey = item.path[item.path.length - 1]
                        if (lastKey === 'selectedWidgetId') return false
                        return true
                    })
                    break
            }

            if (diffWidget.length === 0) return null
            return {
                type: 'widget',
                action: 'update',
                slideId: slideId,
                widgetId: updatedWidget.id,
                data: diffWidget,
            }
        })
        .filter((item) => item !== null) as HistoryActionItemType[]

    if (widgetChanges.length === 0) return

    let historyName = name

    if (historyName === undefined) {
        historyName = widgetChanges.length > 1 ? 'Update widgets' : `Update ${widgets[0].updatedWidget.title} content`
    }
    if (updateHistory) {
        addToHistory(history, widgetChanges, historyName)
    } else {
        return widgetChanges
    }
}

export const addUpdateSlideToHistory = ({
    currentSlide,
    updatedSlide,
    history,
    name,
}: {
    currentSlide: ReportSlideType
    updatedSlide: ReportSlideType
    history: ReportDesignerStoreStateType['history']
    name?: string
}) => {
    // exclude the widgets from the comparison as they are handled separately
    const { widgets: currentWidgets, dimensions: currentD, ...currentSlideData } = currentSlide
    const { widgets: updatedWidgets, dimensions: updatedD, ...updatedSlideData } = updatedSlide

    const diffSlide = diff(currentSlideData, updatedSlideData)
    if (diffSlide.length === 0) return
    addToHistory(
        history,
        {
            type: 'slide',
            action: 'update',
            slideId: updatedSlide.id,
            data: diffSlide,
        },
        name ?? `Update ${updatedSlide.title} content`
    )
}
