//* ======= Libraries
import { useMemo, useState } from 'react'
import { PartialDeep } from 'type-fest'
import { Box, Divider, FormControlLabel, Stack, Switch, Tab, Tabs, Typography } from '@mui/material'
//* ======= Components and features
import InsightDataConfigDialog from 'features/report-designer/widgets/insight-widget/InsightDataConfigDialog'
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import {
    InsightWidgetType,
    ReportWidgetContentKindType,
    ReportWidgetSettingsBaseType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import { genereateInsightWidgetData } from './InisghtWidget.helper'
import FontPicker from 'components/widgets/FontPicker'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
import ColorPicker from 'components/widgets/ColorPicker'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
//* ======= Assets and styles

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'insight'>
    settings: Omit<ReportWidgetType, 'content'>
    details: InsightWidgetType
}

function InsightWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { updateWidget, updateWidgetStyles, updateInsightWidgetContent, dataSources } = useReportStore((store) => ({
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updateInsightWidgetContent: store.updateInsightWidgetContent,
        dataSources: store.dataSources,
    }))

    const activeSlide = useReportActiveSlide()
    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'insight') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const [isDataConfigDialogOpen, setIsDataConfigDialogOpen] = useState<boolean>(false)

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlide !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<InsightWidgetType>) => {
        if (activeSlide !== null && widget !== null) {
            updateInsightWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: details,
            })

            if (isDataConfigDialogOpen) {
                setIsDataConfigDialogOpen(false)
            }
        }
    }

    const insightDataConfigDialogConfirm = async (insightData: InsightWidgetType) => {
        if (activeSlide !== null && widget !== null) {
            // Generate new cached data asynchronously
            const dataSource = dataSources.find((x) => x.id === insightData.selectedDataSource?.id)
            const newCachedData = await genereateInsightWidgetData(
                dataSource!,
                insightData.mode,
                insightData.groupBy,
                insightData.insights,
                insightData.labelField,
                insightData.idField,
                insightData.selectedDataSource?.panel
            )
            updateInsightWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: {
                    ...insightData,
                    cachedData: newCachedData,
                },
            })

            if (isDataConfigDialogOpen) {
                setIsDataConfigDialogOpen(false)
            }
        }
    }

    const updateModeField = (mode: InsightWidgetType['mode']) => {
        if (widget === null) return
        updateDetails({
            mode: mode,
        })
    }

    return (
        <>
            {activeSlide !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Insight" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(conditionalVisibility) => {
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility,
                                        },
                                    })
                                }}
                            />

                            {/* Tooltip
                                ========================================= */}
                            <ReportWidgetTooltipSettings
                                tooltip={widget.settings.tooltip}
                                onChange={(tooltip) => {
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            tooltip,
                                        },
                                    })
                                }}
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Mode
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Mode"
                                value={widget.details.mode}
                                onChange={(value) => updateModeField(value)}
                                options={[
                                    {
                                        label: 'Group',
                                        value: 'group',
                                    },
                                    {
                                        label: 'Individual',
                                        value: 'individual',
                                    },
                                    {
                                        label: 'Intervention',
                                        value: 'intervention',
                                    },
                                    {
                                        label: 'ERGM / ALAAM',
                                        value: 'ergm/alaam',
                                    },
                                ]}
                            />

                            {/* Configure data button
				                ========================================= */}
                            <BaseButton
                                label="Configure Data"
                                onClick={(evt) => setIsDataConfigDialogOpen(true)}
                                variant="outlined"
                                sx={(theme) => ({
                                    alignSelf: 'center',

                                    width: '80%',
                                    paddingY: theme.spacing(1.5),
                                })}
                            />

                            {/* Empty data message
                                ========================================= */}
                            <BaseFilledTextField
                                label="Empty Data Message"
                                defaultValue={widget.details.emptyDataMessage}
                                onBlur={(evt) =>
                                    updateDetails({
                                        emptyDataMessage: evt.target.value.trim(),
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Title switch
				                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    textAlign="left"
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Show Title:
                                </Typography>

                                <BaseSwitch
                                    checked={widget.details.showTitle}
                                    onChange={(evt, checked) =>
                                        updateDetails({
                                            showTitle: checked,
                                        })
                                    }
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>

                            {/* Group insights switch
				                ========================================= */}
                            {widget.details.mode !== 'intervention' && (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={widget.settings.dimensions.autoHeight}
                                                onChange={(evt, checked) =>
                                                    updateWidget({
                                                        slideId: activeSlide.id,
                                                        widgetId: widget.settings.id,
                                                        data: {
                                                            dimensions: {
                                                                autoHeight: checked,
                                                            },
                                                        },
                                                    })
                                                }
                                            />
                                        }
                                        label="Auto Height"
                                    />

                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Group insights:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.groupInsights}
                                            onChange={(evt, checked) =>
                                                updateDetails({
                                                    groupInsights: checked,
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    <StyledWidgetAccordion
                                        title="Header"
                                        hasToggle={true}
                                        hasBottomBorder={true}
                                        isToggledOff={!widget.details.basicConfiguration.hasHeader}
                                        onToggle={(isEnabled) =>
                                            updateDetails({
                                                basicConfiguration: {
                                                    hasHeader: isEnabled,
                                                },
                                            })
                                        }
                                    >
                                        <Stack gap={1}>
                                            {/* Row change switch
				                                ========================================= */}
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <Typography
                                                    fontSize={14}
                                                    noWrap
                                                    textAlign="left"
                                                    sx={{
                                                        flex: '1 0 0',
                                                    }}
                                                >
                                                    Enable Row change:
                                                </Typography>

                                                <BaseSwitch
                                                    checked={widget.details.basicConfiguration.canChangeRows}
                                                    onChange={(evt, checked) =>
                                                        updateDetails({
                                                            basicConfiguration: {
                                                                canChangeRows: checked,
                                                            },
                                                        })
                                                    }
                                                    disabled={!widget.details.basicConfiguration.hasHeader}
                                                    size="small"
                                                    color="primary"
                                                    sx={{
                                                        flexShrink: 0,
                                                    }}
                                                />
                                            </Stack>

                                            {/* Search switch
				                        ========================================= */}
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <Typography
                                                    fontSize={14}
                                                    noWrap
                                                    textAlign="left"
                                                    sx={{
                                                        flex: '1 0 0',
                                                    }}
                                                >
                                                    Enable Search:
                                                </Typography>

                                                <BaseSwitch
                                                    checked={widget.details.basicConfiguration.hasSearch}
                                                    disabled={!widget.details.basicConfiguration.hasHeader}
                                                    onChange={(evt, checked) =>
                                                        updateDetails({
                                                            basicConfiguration: {
                                                                hasSearch: checked,
                                                            },
                                                        })
                                                    }
                                                    size="small"
                                                    color="primary"
                                                    sx={{
                                                        flexShrink: 0,
                                                    }}
                                                />
                                            </Stack>

                                            {/* Header style settings
				                        ========================================= */}
                                            <StyledWidgetAccordion
                                                isDisabled={!widget.details.basicConfiguration.hasHeader}
                                                title="Header Style"
                                            >
                                                <Stack gap={1}>
                                                    {/* Title font and color settings
						                        ========================================= */}
                                                    <FontPicker
                                                        onChange={(value) =>
                                                            updateDetails({
                                                                basicConfiguration: {
                                                                    headerStyle: {
                                                                        fontFamily: value.fontFamily,
                                                                        fontSize: value.fontSize,
                                                                        fontWeight: value.isBold ? 'bold' : 'normal',
                                                                        color: value.color,
                                                                    },
                                                                },
                                                            })
                                                        }
                                                        defaultValue={{
                                                            fontFamily:
                                                                widget.details.basicConfiguration.headerStyle
                                                                    .fontFamily,
                                                            fontSize:
                                                                widget.details.basicConfiguration.headerStyle.fontSize,
                                                            color: widget.details.basicConfiguration.headerStyle.color,
                                                            isBold:
                                                                widget.details.basicConfiguration.headerStyle
                                                                    .fontWeight === 'bold',
                                                        }}
                                                        disabled={!widget.details.basicConfiguration.hasHeader}
                                                        styleControls={false}
                                                    />
                                                </Stack>
                                            </StyledWidgetAccordion>
                                        </Stack>
                                    </StyledWidgetAccordion>
                                </>
                            )}

                            {widget.details.mode === 'intervention' && (
                                <>
                                    {/* layout
                                        ========================================= */}
                                    <BaseSelectWithLabel
                                        label="Layout"
                                        value={widget.details.interventionConfig.layout}
                                        size="small"
                                        onChange={(value) =>
                                            updateDetails({
                                                interventionConfig: {
                                                    layout: value,
                                                },
                                            })
                                        }
                                        options={[
                                            {
                                                label: 'Single Column',
                                                value: 'one-column',
                                            },
                                            {
                                                label: 'Two Columns',
                                                value: 'two-column',
                                            },
                                            {
                                                label: 'Advanced',
                                                value: 'advanced',
                                            },
                                        ]}
                                    />
                                    {/* Display export switch
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Show Export:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.interventionConfig.showExport}
                                            onChange={(evt, checked) =>
                                                updateDetails({
                                                    interventionConfig: {
                                                        showExport: checked,
                                                    },
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Display search switch
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Show Search:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.interventionConfig.showSearch}
                                            onChange={(evt, checked) =>
                                                updateDetails({
                                                    interventionConfig: {
                                                        showSearch: checked,
                                                    },
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Display Description switch
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Show Description:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.interventionConfig.showDescription}
                                            onChange={(evt, checked) =>
                                                updateDetails({
                                                    interventionConfig: {
                                                        showDescription: checked,
                                                    },
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Display Actions switch
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Show Actions:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.interventionConfig.showActions}
                                            onChange={(evt, checked) =>
                                                updateDetails({
                                                    interventionConfig: {
                                                        showActions: checked,
                                                    },
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    <Divider />
                                    {widget.details.interventionConfig.layout !== 'advanced' && (
                                        <>
                                            <StyledSwitch
                                                checked={widget.details.interventionConfig.iconOnly}
                                                size="small"
                                                label="Icon Only"
                                                onChange={(evt, checked) => {
                                                    updateDetails({
                                                        interventionConfig: {
                                                            iconOnly: checked,
                                                        },
                                                    })
                                                }}
                                            />

                                            <BaseSelectWithLabel
                                                label="Icon Position"
                                                value={widget.details.interventionConfig.iconPosition}
                                                onChange={(value) =>
                                                    updateDetails({
                                                        interventionConfig: {
                                                            iconPosition: value,
                                                        },
                                                    })
                                                }
                                                options={[
                                                    {
                                                        label: 'Left',
                                                        value: 'start',
                                                    },
                                                    {
                                                        label: 'Right',
                                                        value: 'end',
                                                    },
                                                    {
                                                        label: 'Top',
                                                        value: 'top',
                                                    },
                                                    {
                                                        label: 'Bottom',
                                                        value: 'bottom',
                                                    },
                                                ]}
                                                size="small"
                                            />

                                            {/* Tabs font style
                                        ========================================= */}
                                            <StyledWidgetAccordion title="Tabs Font Style" hasBottomBorder={true}>
                                                <FontPicker
                                                    onChange={(values) =>
                                                        updateDetails({
                                                            interventionConfig: {
                                                                tabTextStyle: {
                                                                    fontFamily: values.fontFamily,
                                                                    fontSize: values.fontSize,
                                                                    fontWeight: values.isBold ? 'bold' : 'normal',
                                                                    color: values.color,
                                                                },
                                                            },
                                                        })
                                                    }
                                                    defaultValue={{
                                                        fontFamily:
                                                            widget.details.interventionConfig.tabTextStyle.fontFamily,
                                                        fontSize:
                                                            widget.details.interventionConfig.tabTextStyle.fontSize,
                                                        isBold:
                                                            widget.details.interventionConfig.tabTextStyle
                                                                .fontWeight === 'bold',
                                                        color: widget.details.interventionConfig.tabTextStyle.color,
                                                    }}
                                                    styleControls={{
                                                        bold: true,
                                                    }}
                                                />
                                            </StyledWidgetAccordion>
                                        </>
                                    )}

                                    {/* Table title default value and font style
                                        ========================================= */}
                                    <StyledWidgetAccordion
                                        title="Table Title"
                                        hasBottomBorder
                                        hasToggle={true}
                                        isToggledOff={widget.details.interventionConfig.tableTitle.enabled === false}
                                        onToggle={(isEnabled) =>
                                            updateDetails({
                                                interventionConfig: {
                                                    tableTitle: {
                                                        enabled: isEnabled,
                                                    },
                                                },
                                            })
                                        }
                                    >
                                        <Stack gap={1}>
                                            <BaseFilledTextField
                                                label="Default Value"
                                                defaultValue={widget.details.interventionConfig.tableTitle.text}
                                                onBlur={(evt) =>
                                                    updateDetails({
                                                        interventionConfig: {
                                                            tableTitle: {
                                                                text: evt.target.value.trim(),
                                                            },
                                                        },
                                                    })
                                                }
                                                size="small"
                                                fullWidth
                                            />

                                            <FontPicker
                                                onChange={(values) =>
                                                    updateDetails({
                                                        interventionConfig: {
                                                            tableTitle: {
                                                                fontFamily: values.fontFamily,
                                                                fontSize: values.fontSize,
                                                                fontWeight: values.isBold ? 'bold' : 'normal',
                                                                color: values.color,
                                                            },
                                                        },
                                                    })
                                                }
                                                defaultValue={{
                                                    fontFamily: widget.details.interventionConfig.tableTitle.fontFamily,
                                                    fontSize: widget.details.interventionConfig.tableTitle.fontSize,
                                                    isBold:
                                                        widget.details.interventionConfig.tableTitle.fontWeight ===
                                                        'bold',
                                                    color: widget.details.interventionConfig.tableTitle.color,
                                                }}
                                                styleControls={{
                                                    bold: true,
                                                }}
                                            />
                                        </Stack>
                                    </StyledWidgetAccordion>
                                </>
                            )}

                            {/* Font style settings
                                ========================================= */}
                            <StyledWidgetAccordion title="Default Text Font Style">
                                <FontPicker
                                    onChange={(value) =>
                                        updateDetails({
                                            fontStyles: {
                                                fontFamily: value.fontFamily,
                                                fontSize: value.fontSize,
                                            },
                                        })
                                    }
                                    defaultValue={{
                                        fontFamily: widget.details.fontStyles.fontFamily,
                                        fontSize: widget.details.fontStyles.fontSize,
                                    }}
                                    styleControls={false}
                                />
                            </StyledWidgetAccordion>
                        </Stack>
                    ) : (
                        false
                    )}

                    {/* (Out-of-flow) Data config dialog
				        ========================================= */}
                    {isDataConfigDialogOpen && (
                        <InsightDataConfigDialog
                            isOpen={isDataConfigDialogOpen}
                            data={widget.details}
                            onConfirm={insightDataConfigDialogConfirm}
                            onClose={() => setIsDataConfigDialogOpen(false)}
                        />
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default InsightWidgetSettings
