//* ======= Libraries
import React from 'react'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import Input, { InputProps } from '@mui/material/Input'

const StyledInput = styled((props: InputProps) => <Input disableUnderline {...props} />)(({ theme }) => ({
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        fontSize: 16,
        fontWeight: 400,

        borderRadius: '8px',
        border: '1px solid transparent',
        backgroundColor: theme.palette.common.bg_4,
        color: theme.palette.common.text_1,

        '&:focus': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.bg_3,
        },
    },
}))

function StyledSlimInput({ ...props }: InputProps) {
    return <StyledInput {...props} />
}

export default StyledSlimInput
