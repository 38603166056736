import React, { ChangeEvent } from 'react'
import { Range, getTrackBackground } from 'react-range'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'

interface IRangeSliderSelector {
    disabled?: boolean
    min?: number
    max?: number
    step?: number
    values: number[]
    onChange: (values: number[]) => void
}

function RangeSliderSelector({
    disabled = false,
    min = 0,
    max = 100,
    step = 1,
    values = [0, 10],
    onChange,
}: IRangeSliderSelector) {
    const muiTheme = useTheme()

    const onInputValueChange =
        (fieldName: 'min' | 'max') => (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (fieldName === 'min') {
                const minNewValue = Number(evt.target.value)
                const currentMaxValue = values[1]

                if (minNewValue > currentMaxValue) {
                    return
                } else {
                    onChange([minNewValue, currentMaxValue])
                }
            } else if (fieldName === 'max') {
                const maxNewValue = Number(evt.target.value)
                const currentMinValue = values[0]

                if (maxNewValue < currentMinValue) {
                    return
                } else {
                    onChange([currentMinValue, maxNewValue])
                }
            }
        }

    return (
        <Stack gap={1} minWidth={0}>
            {/* Range
                ========================================= */}
            <Box
                sx={{
                    px: 0.5,
                }}
            >
                <Range
                    disabled={disabled}
                    step={step}
                    min={min}
                    max={max}
                    values={values}
                    onChange={onChange}
                    draggableTrack={true}
                    renderTrack={({ props, children }) => (
                        <Box
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            sx={{
                                ...props.style,

                                width: '100%',
                                py: 1,
                            }}
                        >
                            <Box
                                ref={props.ref}
                                sx={{
                                    height: '6px',
                                    width: '100%',

                                    background: getTrackBackground({
                                        values: [values[0], values[1]],
                                        colors: disabled
                                            ? [
                                                  muiTheme.palette.common.bg_4,
                                                  muiTheme.palette.common.bg_5,
                                                  muiTheme.palette.common.bg_4,
                                              ]
                                            : [
                                                  muiTheme.palette.common.bg_4,
                                                  muiTheme.palette.primary.main,
                                                  muiTheme.palette.common.bg_4,
                                              ],
                                        min: min,
                                        max: max,
                                        rtl: false,
                                    }),
                                }}
                            >
                                {children}
                            </Box>
                        </Box>
                    )}
                    renderThumb={({ props }) => (
                        <Box
                            {...props}
                            sx={{
                                ...props.style,
                                height: 12,
                                width: 12,
                                borderRadius: '50%',
                                backgroundColor: disabled
                                    ? muiTheme.palette.common.bg_5
                                    : muiTheme.palette.primary.main,
                            }}
                        />
                    )}
                />
            </Box>

            {/* Min/Max fields
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                {/* Min
                    ========================================= */}
                <BaseFilledTextField
                    type="number"
                    disabled={disabled}
                    placeholder="Min"
                    value={values[0]}
                    onChange={onInputValueChange('min')}
                    inputProps={{
                        min: min,
                        max: values[1],
                        step: step,
                    }}
                    hiddenLabel
                    size="small"
                    sx={{
                        flexBasis: 0,
                        flexGrow: 1,

                        fontSize: 14,
                        fontWeight: 400,

                        '& .MuiFilledInput-root': {
                            '& .MuiFilledInput-input': {
                                paddingX: 1,
                            },
                        },
                    }}
                />

                {/* Max
                    ========================================= */}
                <BaseFilledTextField
                    type="number"
                    disabled={disabled}
                    placeholder="Max"
                    value={values[1]}
                    onChange={onInputValueChange('max')}
                    inputProps={{
                        min: values[0],
                        max: max,
                        step: step,
                    }}
                    hiddenLabel
                    size="small"
                    sx={{
                        flexBasis: 0,
                        flexGrow: 1,

                        fontSize: 14,
                        fontWeight: 400,

                        '& .MuiFilledInput-root': {
                            '& .MuiFilledInput-input': {
                                paddingX: 1,
                            },
                        },
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default RangeSliderSelector
