import React, { useEffect, useState, useRef } from 'react'
import Color from 'color'
import { RgbaColor, RgbaColorPicker, HexColorInput } from 'react-colorful'
import { useTheme, Box, Collapse, Stack, Typography, Popover } from '@mui/material'
import { css } from '@emotion/css'
import { debounce } from 'helpers/helpers'

export const rgbaToHexaColor = (rgba: RgbaColor) => {
    return Color([rgba.r, rgba.g, rgba.b, rgba.a]).hexa()
}

export const hexaToRgbaColor = (hexa: string) => {
    const { r, g, b, alpha } = Color(hexa).object()

    return {
        r,
        g,
        b,
        a: typeof alpha === 'number' ? alpha : 1,
    }
}

export const COLORPICKER_DEFAULT_HEX_VALUE = '#FFFFFFFF'
export const COLORPICKER_DEFAULT_RGBA_VALUE = { r: 255, g: 255, b: 255, a: 1 }

type Props = {
    // value should be in the format of "HEX" or "HEXA"
    // TODO: Remove the optional indicator. Value should always be provided.
    value?: string | undefined
    onChange?: (value?: string) => void
    disabled?: boolean
    isPopover?: boolean
    gap?: number
    varient?: 'minimal' | 'default'
}

function ColorPicker({ value, onChange, disabled, isPopover = false, gap = 2, varient = 'default' }: Props) {
    const muiTheme = useTheme()

    const [color, setColor] = useState<RgbaColor>(
        value !== undefined ? hexaToRgbaColor(value.toUpperCase()) : COLORPICKER_DEFAULT_RGBA_VALUE
    )
    const [hexInputColor, setHexInputColor] = useState(
        value !== undefined ? value.toUpperCase() : COLORPICKER_DEFAULT_HEX_VALUE
    )

    const [isExpanded, setIsExpanded] = useState(false)

    const [isPickerPopoverOpen, setIsPickerPopoverOpen] = useState(false)

    const [mainContainerRefState, setMainContainerRefState] = useState<HTMLDivElement | null>(null)

    const onValueChange = (newColor: RgbaColor | string, caller: 'picker' | 'field') => {
        if (caller === 'picker') {
            if (onChange) {
                onChange(rgbaToHexaColor(newColor as RgbaColor).toUpperCase())
            }
        } else {
            if (onChange) {
                onChange((newColor as string).toUpperCase())
            }
        }
    }

    // Update color picker and hex input states on value change.
    useEffect(() => {
        if (value && value !== '') {
            if (hexaToRgbaColor(value) !== color) {
                setColor(hexaToRgbaColor(value))
            }

            if (value.toUpperCase() !== hexInputColor) {
                setHexInputColor(value.toUpperCase())
            }
        } else {
            setHexInputColor(COLORPICKER_DEFAULT_HEX_VALUE)
            setColor(COLORPICKER_DEFAULT_RGBA_VALUE)
        }
    }, [value])

    // Call onValueChange after hex input color changes.
    useEffect(() => {
        if (value !== undefined && value.toUpperCase() !== hexInputColor) {
            onValueChange(hexInputColor, 'field')
        }
    }, [hexInputColor])

    // Color picker collapsible area expansion and popover state reset on component disable.
    useEffect(() => {
        if (disabled) {
            if (isExpanded) {
                setIsExpanded(false)
            }

            if (isPickerPopoverOpen) {
                setIsPickerPopoverOpen(false)
            }
        }
    }, [disabled])

    const onMainAreaClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled === undefined || disabled === false) {
            if (isPopover) {
                setIsPickerPopoverOpen((prevState) => !prevState)
            } else {
                setIsExpanded((prevState) => !prevState)
            }
        }
    }

    return (
        <Stack gap={0.5}>
            {/* Selected color and collapse trigger
                ========================================= */}
            <Stack
                ref={(node) => setMainContainerRefState(node)}
                role="button"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={gap}
                sx={{
                    paddingY: 1,
                    cursor: disabled ? 'default' : 'pointer',

                    opacity: disabled ? '0.5' : 1,
                }}
                onClick={onMainAreaClick}
            >
                {/* Selected value color box
                    ========================================= */}
                <Box
                    component="span"
                    sx={(theme) => ({
                        flexShrink: 0,

                        height: 24,
                        width: 32,

                        borderRadius: '4px',
                        border: `1px solid ${theme.palette.common.border_2}`,
                        backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                    })}
                />

                {/* Selected value field
                    ========================================= */}
                {varient === 'default' && (
                    <HexColorInput
                        color={hexInputColor}
                        onChange={setHexInputColor}
                        onClick={(evt) => {
                            evt.stopPropagation()
                        }}
                        disabled={disabled}
                        prefixed
                        style={{
                            flexGrow: 1,

                            minWidth: 0,
                            padding: muiTheme.spacing(1, 1.5),
                            fontFamily: 'Poppins',
                            fontSize: 14,
                            textAlign: 'center',
                            letterSpacing: 2,

                            borderRadius: '8px',
                            border: 0,
                            backgroundColor: muiTheme.palette.common.bg_4,
                            color: muiTheme.palette.common.text_1,
                        }}
                        className={css`
                            &:focus-visible {
                                outline: 0;
                            }
                        `}
                    />
                )}

                {/* Selected value opacity
                    ========================================= */}
                {varient === 'default' && (
                    <Typography
                        fontSize={14}
                        textAlign="right"
                        sx={{
                            flexShrink: 0,

                            minWidth: 36,
                        }}
                    >
                        {Math.floor(color.a * 100)}%
                    </Typography>
                )}
            </Stack>

            {/* Collapsible color picker
                ========================================= */}
            {(isPopover === undefined || isPopover === false) && (
                <Collapse in={isExpanded} timeout={150}>
                    <RgbaColorPicker
                        color={color}
                        onChange={debounce((newColor: RgbaColor) => onValueChange(newColor, 'picker'), 300)}
                        style={{ width: '100%', padding: '16px 16px 6px' }}
                    />
                </Collapse>
            )}

            {/* (Out-of-flow) Popover color picker
                ========================================= */}
            {mainContainerRefState !== null && (
                <Popover
                    id="picker-popover"
                    open={isPickerPopoverOpen}
                    anchorEl={mainContainerRefState}
                    onClose={(evt, reason) => setIsPickerPopoverOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: 256,
                            padding: '16px 16px 6px',
                        }}
                    >
                        {varient === 'minimal' && (
                            <HexColorInput
                                color={hexInputColor}
                                onChange={setHexInputColor}
                                onClick={(evt) => {
                                    evt.stopPropagation()
                                }}
                                disabled={disabled}
                                prefixed
                                style={{
                                    flexGrow: 1,
                                    width: '100%',
                                    marginBottom: 2,
                                    minWidth: 0,
                                    padding: muiTheme.spacing(1, 1.5),
                                    fontFamily: 'Poppins',
                                    fontSize: 14,
                                    textAlign: 'center',
                                    letterSpacing: 2,

                                    borderRadius: '8px',
                                    border: 0,
                                    backgroundColor: muiTheme.palette.common.bg_4,
                                    color: muiTheme.palette.common.text_1,
                                }}
                                className={css`
                                    &:focus-visible {
                                        outline: 0;
                                    }
                                `}
                            />
                        )}
                        <RgbaColorPicker
                            color={color}
                            onChange={debounce((newColor: RgbaColor) => onValueChange(newColor, 'picker'), 300)}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Popover>
            )}
        </Stack>
    )
}

export default ColorPicker
