import { FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import EditableTextField from 'components/base/EditableTextField'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import { ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { PartialDeep } from 'type-fest'

type ReportConditionalVisibilitySettingsProps = {
    conditionalVisibility: ReportWidgetType['conditionalVisibility']
    onChange: (tooltip: PartialDeep<ReportWidgetType['conditionalVisibility']>) => void
}

const ReportConditionalVisibilitySettings = ({
    conditionalVisibility,
    onChange,
}: ReportConditionalVisibilitySettingsProps) => {
    return (
        <StyledWidgetAccordion
            title="Conditional Visibility"
            hasBottomBorder
            hasToggle={true}
            isToggledOff={!conditionalVisibility.isEnabled}
            onToggle={(isEnabled) => {
                onChange({
                    isEnabled: isEnabled,
                })
            }}
        >
            <Stack gap={1}>
                <Typography variant="body2" color="textSecondary">
                    Show or hide the widget based on the value of a variable.
                </Typography>

                <EditableTextField
                    label="Variable Name"
                    value={conditionalVisibility.variableName}
                    onBlur={(v) =>
                        onChange({
                            variableName: v,
                        })
                    }
                    size="small"
                    fullWidth
                    disabled={!conditionalVisibility.isEnabled}
                />
                <EditableTextField
                    label="Value"
                    value={conditionalVisibility.variableValue}
                    onBlur={(v) =>
                        onChange({
                            variableValue: v,
                        })
                    }
                    size="small"
                    fullWidth
                    disabled={!conditionalVisibility.isEnabled}
                />

                <FormControlLabel
                    control={<Switch />}
                    label="Show Close Button"
                    checked={conditionalVisibility.hasCloseButton}
                    onChange={(evt, checked) =>
                        onChange({
                            hasCloseButton: checked,
                        })
                    }
                    disabled={!conditionalVisibility.isEnabled}
                />

                {/* Placement */}
                {conditionalVisibility.hasCloseButton && (
                    <BaseSelectWithLabel
                        label="Placement"
                        value={conditionalVisibility.placement}
                        options={[
                            { value: 'top-left-inside', label: 'Top Left' },
                            // { value: 'top-left-outside', label: 'Top Left Outside' },
                            { value: 'top-right-inside', label: 'Top Right' },
                            // { value: 'top-right-outside', label: 'Top Right Outside' },
                        ]}
                        onChange={(value) =>
                            onChange({
                                placement: value as ReportWidgetType['conditionalVisibility']['placement'],
                            })
                        }
                        size="small"
                        fullWidth
                        disabled={!conditionalVisibility.isEnabled}
                    />
                )}

                <FormControlLabel
                    control={<Switch />}
                    label="Close on Outside Click"
                    checked={conditionalVisibility.closeOnOutsideClick}
                    onChange={(evt, checked) =>
                        onChange({
                            closeOnOutsideClick: checked,
                        })
                    }
                    disabled={!conditionalVisibility.isEnabled}
                />
            </Stack>
        </StyledWidgetAccordion>
    )
}

export default ReportConditionalVisibilitySettings
