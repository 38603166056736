import { groupBy, values } from 'lodash'
import { max, min, quantileSeq } from 'mathjs'
import { PartialDeep } from 'type-fest'
import Color from 'color'
import {
    ChartDataDefinitionType,
    ChartConfigType,
    ChartOptionsType,
    // Line
    LineChartDataDefinitionType,
    LineChartConfigType,
    LineChartOptionsType,
    // Bar
    BarChartDataDefinitionType,
    BarChartConfigType,
    BarChartOptionsType,
    // Pie
    PieChartDataDefinitionType,
    PieChartConfigType,
    PieChartOptionsType,
    // Scatter
    ScatterChartDataDefinitionType,
    ScatterChartConfigType,
    ScatterChartOptionsType,
    // Radar
    RadarChartDataDefinitionType,
    RadarChartConfigType,
    RadarChartOptionsType,
    // Boxplot
    BoxplotChartDataDefinitionType,
    BoxplotChartConfigType,
    BoxplotChartOptionsType,
    // Gauge
    GaugeChartDataDefinitionType,
    GaugeChartConfigType,
    GaugeChartOptionsType,
    GAUGE_CHART_CONFIG_BASE_VALUES,
    // Pictorial bar
    PictorialBarChartDataDefinitionType,
    PictorialBarChartConfigType,
    PictorialBarChartOptionsType,
    // Word cloud
    WordCloudChartDataDefinitionType,
    WordCloudChartConfigType,
    WordCloudChartOptionsType,
    TreemapChartConfigType,
    TreemapChartOptionsType,
    TreemapChartDataDefinitionType,
} from 'features/chart/Chart.asset'
import { ReportDataSortOrderType, ReportDataSourceRowType } from 'features/report-designer/types/reportDesigner.types'
import {
    applyAggregation,
    cleanVector,
    convertNodeAttributeToKeyString,
    convertNodeAttributeToLabel,
    formatValueAsLabel,
    groupDataSourcePanelToMap,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { determineColorLuminosity } from 'helpers/helpers'
import { AGGREGATION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
const keyword_extractor = require('keyword-extractor')

/**
 * Sort the data groups Map into an array.
 * @param map The data groups map created from a data source panel.
 * @param order Order of sorting. Can be "asc" or "desc".
 * @returns A sorted array of the data groups, first by order of their types and then by their keys. The order of types is fixed.
 */
export function sortDataGroupMap(
    map: Map<ReportDataSourceRowType[any], ReportDataSourceRowType[]>,
    order: ReportDataSortOrderType = 'asc'
) {
    const numberGroup: [number, ReportDataSourceRowType[]][] = []
    const dateGroup: [Date, ReportDataSourceRowType[]][] = []
    const stringGroup: [string, ReportDataSourceRowType[]][] = []
    const naGroup: ['n/a', ReportDataSourceRowType[]][] = []
    const undefinedGroup: ['(undefined)', ReportDataSourceRowType[]][] = []

    const na_variants = ['n/a', 'na', 'n.a.', 'n.a']

    // Step 1: Populate type groups based on key type
    for (const [key, value] of map) {
        // Number
        if (typeof key === 'number') {
            numberGroup.push([key, value])
        }
        // Date
        else if (key instanceof Date) {
            dateGroup.push([key, value])
        }
        // String
        else if (typeof key === 'string') {
            // N/A
            if (na_variants.includes(key.toLowerCase().trim())) {
                naGroup.push(['n/a', value])
            } else {
                stringGroup.push([key.trim(), value])
            }
        }
        // Others
        else {
            undefinedGroup.push(['(undefined)', value])
        }
    }

    const isAscending = order === 'asc'

    // Step 2: Sort each type group
    const sortTypeGroup = (group: [number | string | Date, ReportDataSourceRowType[]][]) => {
        return group.sort(([keyA], [keyB]) => {
            if (typeof keyA === 'number') {
                return ((keyA as number) - (keyB as number)) * (isAscending ? 1 : -1)
            }

            if (keyA instanceof Date && keyB instanceof Date) {
                return ((keyA as Date).getTime() - (keyB as Date).getTime()) * (isAscending ? 1 : -1)
            }

            if (typeof keyA === 'string') {
                return (keyA as string).localeCompare(keyB as string) * (isAscending ? 1 : -1)
            }

            return 0
        })
    }

    const sortedNumberGroup = sortTypeGroup(numberGroup)
    const sortedDateGroup = sortTypeGroup(dateGroup)
    const sortedStringGroup = sortTypeGroup(stringGroup)

    // Step 3: Join the sorted type groups based on the order of types
    const sortedMapArray = [
        ...sortedNumberGroup,
        ...sortedDateGroup,
        ...sortedStringGroup,
        ...naGroup,
        ...undefinedGroup,
    ]

    return structuredClone(sortedMapArray)
}

function parseNumber(value: any, precision: number = 8, defaultValue: number = 0) {
    if (value == null) return defaultValue

    let numberValue = Number(value)

    if (Number.isNaN(numberValue)) {
        return defaultValue
    }

    let poweredPrecision = Math.pow(10, precision)

    return Math.round((numberValue + Number.EPSILON) * poweredPrecision) / poweredPrecision
}

type ChartDataParserParams<T extends ChartDataDefinitionType, K extends ChartOptionsType, U extends ChartConfigType> = {
    dataDefinition: T
    filteredData: ReportDataSourceRowType[]
    unfilteredData?: ReportDataSourceRowType[]
    prevOptions: K
    config: U
}

/*
 * Line chart
 * =========================================
 */

export type LineChartDataOptionsType = PartialDeep<{
    xAxis: {
        type: 'category'
        data: (string | number)[]
    }
    yAxis: {
        type: 'value'
    }
    series: {
        type: 'line'
        name: string | number
        data: any[]
        color: string | undefined
    }[]
}>

export function ParseLineChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    LineChartDataDefinitionType,
    LineChartOptionsType,
    LineChartConfigType
>): LineChartDataOptionsType {
    const dataOptions: LineChartOptionsType = {}

    const dataGroups = groupDataSourcePanelToMap(
        filteredData,
        convertNodeAttributeToKeyString(dataDefinition.xAxisField)
    )

    const sortedDataCategories = sortDataGroupMap(dataGroups)

    const sortedCategoryNames: (string | number)[] = []

    for (let [_category, _value] of sortedDataCategories) {
        const refinedCategoryName = formatValueAsLabel(_category)

        sortedCategoryNames.push(refinedCategoryName)
    }

    dataOptions.xAxis = {
        type: 'category',
        data: sortedCategoryNames,
    }

    const series: LineChartOptionsType['series'] = []

    for (let _line of dataDefinition.series) {
        // If aggregation method is anything other than "count", then "field" is required.
        if (_line.aggregationMethod === 'count' || _line.attribute.field.trim() !== '') {
            const lineData: any[] = []

            for (let [_category, _value] of sortedDataCategories) {
                lineData.push(
                    applyAggregation({
                        method: _line.aggregationMethod,
                        data: _value,
                        field: convertNodeAttributeToKeyString(_line.attribute),
                    })
                )
            }

            series.push({
                type: 'line',
                name: _line.title || formatValueAsLabel(_line.aggregationMethod + ' ' + _line.attribute.field),
                data: lineData,
                color: _line.color.isEnabled ? _line.color.value : undefined,
            })
        }
    }

    dataOptions.yAxis = {
        type: 'value',
    }

    dataOptions.series = series

    return structuredClone(dataOptions)
}

/*
 * Bar chart
 * =========================================
 */

export type BarChartDataOptionsType = PartialDeep<{
    xAxis: {
        type: 'category'
        data: (string | number)[]
    }
    yAxis: {
        type: 'value'
    }
    series: {
        type: 'bar'
        name: string | number
        data: any[]
        color: string | undefined
    }[]
}>

export function ParseBarChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    BarChartDataDefinitionType,
    BarChartOptionsType,
    BarChartConfigType
>): BarChartDataOptionsType {
    const dataOptions: BarChartOptionsType = {}

    const dataGroups = groupDataSourcePanelToMap(
        filteredData,
        convertNodeAttributeToKeyString(dataDefinition.xAxisField)
    )

    const sortedDataCategories = sortDataGroupMap(dataGroups)

    const sortedCategoryNames: (string | number)[] = []

    for (let [_category, _value] of sortedDataCategories) {
        const refinedCategoryName = formatValueAsLabel(_category)

        sortedCategoryNames.push(refinedCategoryName)
    }

    dataOptions.xAxis = {
        type: 'category',
        data: sortedCategoryNames,
    }

    const series: BarChartOptionsType['series'] = []

    for (let _bar of dataDefinition.series) {
        // If aggregation method is anything other than "count", then "field" is required.
        if (_bar.aggregationMethod === 'count' || _bar.attribute.field.trim() !== '') {
            const barData: any[] = []

            for (let [_category, _value] of sortedDataCategories) {
                barData.push(
                    applyAggregation({
                        method: _bar.aggregationMethod,
                        data: _value,
                        field: convertNodeAttributeToKeyString(_bar.attribute),
                    })
                )
            }

            series.push({
                type: 'bar',
                name: _bar.title || formatValueAsLabel(_bar.aggregationMethod + ' ' + _bar.attribute.field),
                data: barData,
                color: _bar.color.isEnabled ? _bar.color.value : undefined,
            })
        }
    }

    dataOptions.yAxis = {
        type: 'value',
    }

    dataOptions.series = [...series]

    return structuredClone(dataOptions)
}

/*
 * Pie chart
 * =========================================
 */

type PieChartSeriesDataType = {
    value: any
    name: string | number | undefined
}

export type PieChartDataOptionsType = PartialDeep<{
    xAxis: {
        type: 'category'
        data: (string | number)[]
    }
    yAxis: {
        type: 'value'
    }
    series:
        | {
              type: 'pie'
              name: string | number
              data: PieChartSeriesDataType[]
          }
        | {
              type: 'pie'
              name: string | number
              data: PieChartSeriesDataType[]
          }[]
}>

export function ParsePieChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    PieChartDataDefinitionType,
    PieChartOptionsType,
    PieChartConfigType
>): PieChartDataOptionsType {
    const dataOptions: PieChartOptionsType = {}

    // Total
    if (dataDefinition.categoryField.field === 'total') {
        const seriesData = []

        seriesData.push({
            name: dataDefinition.series.title || formatValueAsLabel('Total ' + dataDefinition.series.attribute.field),
            value: applyAggregation({
                method: dataDefinition.series.aggregationMethod,
                data: filteredData,
                field: convertNodeAttributeToKeyString(dataDefinition.series.attribute),
            }),
        })

        dataOptions.series = {
            type: 'pie',
            name: 'Total',
            data: seriesData,
        }
    }
    // Others
    else {
        const dataGroups = groupDataSourcePanelToMap(
            filteredData,
            convertNodeAttributeToKeyString(dataDefinition.categoryField)
        )

        const sortedDataCategories = sortDataGroupMap(dataGroups)

        const series: PieChartOptionsType['series'] = []

        // If aggregation method is anything other than "count", then "field" is required.
        if (
            dataDefinition.series.aggregationMethod === 'count' ||
            dataDefinition.series.attribute.field.trim() !== ''
        ) {
            const pieData: PieChartSeriesDataType[] = []

            for (let [_category, _value] of sortedDataCategories) {
                pieData.push({
                    name: formatValueAsLabel(_category),
                    value: applyAggregation({
                        method: dataDefinition.series.aggregationMethod,
                        data: _value,
                        field: convertNodeAttributeToKeyString(dataDefinition.series.attribute),
                    }),
                })
            }

            series.push({
                type: 'pie',
                name:
                    dataDefinition.series.title ||
                    formatValueAsLabel(
                        dataDefinition.series.aggregationMethod + ' ' + dataDefinition.series.attribute.field
                    ),
                data: pieData,
                // color: dataDefinition.series.color.isEnabled ? dataDefinition.series.color.value : undefined,
            })
        }

        dataOptions.series = series
    }

    return structuredClone(dataOptions)
}

/*
 * Scatter chart
 * =========================================
 */

export type ScatterChartDataOptionsType = PartialDeep<{
    series: {
        type: 'scatter'
        name: string | number
        data: number[][]
        symbolSize: number
        itemStyle?: {
            color: string | undefined
        }
    }[]
}>

export function ParseScatterChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    ScatterChartDataDefinitionType,
    ScatterChartOptionsType,
    ScatterChartConfigType
>): ScatterChartDataOptionsType {
    const dataOptions: ScatterChartOptionsType = {}

    const seriesData: Array<number[]> = []

    for (let _row of filteredData) {
        const xAxisFieldKey = convertNodeAttributeToKeyString(dataDefinition.xAxisField)
        const yAxisFieldKey = convertNodeAttributeToKeyString(dataDefinition.yAxisField)

        if (isNaN(Number(_row[xAxisFieldKey])) === false)
            seriesData.push([Number(_row[xAxisFieldKey]), Number(_row[yAxisFieldKey])])
    }

    dataOptions.series = [
        {
            type: 'scatter',
            name: formatValueAsLabel(dataDefinition.yAxisField.field),
            data: seriesData,
            symbolSize: 12,
            itemStyle: {
                color: dataDefinition.color,
            },
        },
    ]

    return structuredClone(dataOptions)
}

/*
 * Radar chart
 * =========================================
 */

type RadarChartSeriesDataType = {
    value: any[]
    name: string | number | undefined
    itemStyle?: {
        color: string | undefined
    }
    detail?: {
        show: boolean
    }
}

export type RadarChartDataOptionsType = PartialDeep<{
    radar: {
        indicator: {
            name: string
            max: number
        }[]
    }
    series: {
        type: 'radar'
        name: string
        data: RadarChartSeriesDataType[]
    }
}>

export function ParseRadarChartData({
    dataDefinition,
    filteredData,
    unfilteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    RadarChartDataDefinitionType,
    RadarChartOptionsType,
    RadarChartConfigType
>): RadarChartDataOptionsType {
    const dataOptions: RadarChartOptionsType = structuredClone(prevOptions)

    const indicators = []
    for (let _indicator of dataDefinition.indicators) {
        indicators.push({
            name: _indicator.title || formatValueAsLabel(_indicator.field.field),
            max: _indicator.max,
            // ToDo Add option to modify text style of radar chart indicators in the future
            // nameTextStyle: {
            //     fontSize: 16,
            //     color: 'black',
            //     fontWeight: 'bold',
            // },
        })
    }

    dataOptions.radar = {
        ...dataOptions?.radar,
        indicator: indicators,
    }

    const seriesData: RadarChartSeriesDataType[] = []

    for (let _series of dataDefinition.series) {
        // Each row
        if (_series.attribute.type === 'custom' && _series.attribute.field === 'Each Row') {
            for (let _row of filteredData) {
                const dataValue = []

                for (let _indicator of dataDefinition.indicators) {
                    dataValue.push(Number(_row[convertNodeAttributeToKeyString(_indicator.field)]))
                }

                seriesData.push({
                    value: dataValue,
                    name: _series.fieldLabel
                        ? formatValueAsLabel(_row[convertNodeAttributeToKeyString(_series.fieldLabel)])
                        : // Explicitly setting undefined allows Echarts to apply default colors and use the series' name instead.
                          undefined,
                })
            }
        }
        // Total
        else if (_series.attribute.type === 'custom' && _series.attribute.field === 'Total') {
            const dataValue = []

            for (let _indicator of dataDefinition.indicators) {
                dataValue.push(
                    applyAggregation({
                        method: _series.aggregationMethod,
                        data: unfilteredData ?? filteredData,
                        field: convertNodeAttributeToKeyString(_indicator.field),
                    })
                )
            }

            const totalGroupLabel =
                'Overal ' + AGGREGATION_OPTIONS.find((_option) => _option.value === _series.aggregationMethod)?.label ||
                _series.aggregationMethod

            seriesData.push({
                value: dataValue,
                name: formatValueAsLabel(totalGroupLabel),
                itemStyle: _series.color.isEnabled
                    ? {
                          color: _series.color.value,
                      }
                    : undefined,
            })
        }
        // Others
        else {
            const dataGroups = groupDataSourcePanelToMap(
                filteredData,
                convertNodeAttributeToKeyString(_series.attribute)
            )

            const sortedDataCategories = sortDataGroupMap(dataGroups)

            for (let [_category, _value] of sortedDataCategories) {
                const dataValue = []

                for (let _indicator of dataDefinition.indicators) {
                    dataValue.push(
                        applyAggregation({
                            method: _series.aggregationMethod,
                            data: _value,
                            field: convertNodeAttributeToKeyString(_indicator.field),
                        })
                    )
                }

                const categoryLabel = convertNodeAttributeToLabel(_series.attribute)

                seriesData.push({
                    value: dataValue,
                    name: formatValueAsLabel(`${categoryLabel}: ${_category}`),
                })
            }
        }
    }

    dataOptions.series = {
        ...dataOptions.series,
        type: 'radar',
        // TODO: Any better name?
        name: 'Radar',
        data: seriesData,
    }

    return structuredClone(dataOptions)
}

/*
 * Boxplot chart
 * =========================================
 */

type BoxplotChartSeriesDataType = {
    value: any[]
    itemStyle: {
        color: string | undefined
        borderColor: string | undefined
    }
}

export type BoxplotChartDataOptionsType = PartialDeep<{
    xAxis: {
        type: 'value' | 'category'
        nameLocation: 'start' | 'middle' | 'end'
        nameGap: number
        scale: boolean
        data: (string | number)[]
    }
    yAxis: {
        type: 'value' | 'category'
        nameLocation: 'start' | 'middle' | 'end'
        nameGap: number
        scale: boolean
        data: (string | number)[]
    }
    series: [
        {
            type: 'boxplot'
            name: string | number
            data: BoxplotChartSeriesDataType[]
            encode: {
                tooltip: string[]
            }
        }
    ]
}>

export function ParseBoxplotChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    BoxplotChartDataDefinitionType,
    BoxplotChartOptionsType,
    BoxplotChartConfigType
>): BoxplotChartDataOptionsType {
    let dataOptions: BoxplotChartOptionsType = structuredClone(prevOptions)

    const boxplotConfig: BoxplotChartConfigType = structuredClone(config)

    const dataGroups = groupDataSourcePanelToMap(
        filteredData,
        convertNodeAttributeToKeyString(dataDefinition.categoryField)
    )

    const sortedDataCategories = sortDataGroupMap(dataGroups, dataDefinition.categoryOrder)

    const sortedCategoryNames: (string | number)[] = []

    const seriesData: BoxplotChartSeriesDataType[] = []

    for (let [_category, _value] of sortedDataCategories) {
        const cleanedCategoryValues = cleanVector(_value, convertNodeAttributeToKeyString(dataDefinition.valueField))

        if (cleanedCategoryValues.length === 0) continue

        const boxplotValues = [
            min(cleanedCategoryValues),
            ...(quantileSeq(cleanedCategoryValues, [1 / 4, 1 / 2, 3 / 4]) as Array<number>),
            max(cleanedCategoryValues),
        ]

        const refinedCategoryName = formatValueAsLabel(_category)

        sortedCategoryNames.push(refinedCategoryName)

        const targetDataDefinitionCategory = dataDefinition.categories.find(
            (_item) => _item.key === refinedCategoryName
        )

        const categoryColor = targetDataDefinitionCategory?.color || '#2D77BC'

        seriesData.push({
            value: boxplotValues,
            itemStyle: {
                color: categoryColor,
                borderColor:
                    determineColorLuminosity(categoryColor) === 'light'
                        ? Color(categoryColor).darken(0.2).string()
                        : determineColorLuminosity(categoryColor) === 'dark'
                        ? Color(categoryColor).lighten(1).string()
                        : Color(categoryColor).lightness(35).string(),
            },
        })
    }

    // Setting axes options based on chart orientation
    if (boxplotConfig.orientation === 'horizontal') {
        dataOptions.xAxis = {
            ...dataOptions?.xAxis,
            type: 'value',
            nameLocation: 'middle',
            nameGap: 30,
            scale: true,
        }

        dataOptions.yAxis = {
            ...dataOptions?.yAxis,
            type: 'category',
            data: sortedCategoryNames,
        }
    } else if (boxplotConfig.orientation === 'vertical') {
        dataOptions.xAxis = {
            ...dataOptions?.xAxis,
            type: 'category',
            data: sortedCategoryNames,
        }

        dataOptions.yAxis = {
            ...dataOptions?.yAxis,
            type: 'value',
            nameLocation: 'middle',
            nameGap: 30,
            scale: true,
        }
    }

    dataOptions = {
        ...dataOptions,
        series: [
            {
                type: 'boxplot',
                name: formatValueAsLabel(dataDefinition.valueField.field),
                data: seriesData,
                encode: {
                    tooltip: ['min', 'Q1', 'median', 'Q3', 'max'],
                },
            },
        ],
    }

    return structuredClone(dataOptions)
}

/*
 * Gauge chart
 * =========================================
 */

type GaugeChartSeriesDataType = {
    value: number | undefined
    name: string | undefined
    itemStyle?: {
        color: string | undefined
    }
}

export type GaugeChartDataOptionsType = PartialDeep<{
    xAxis: {
        type: 'category'
        data: (string | number)[]
    }
    yAxis: {
        type: 'value'
    }
    series: {
        type: 'gauge'
        name: string | number
        axisLine: {
            lineStyle: {
                width: number
                color: [number, string][]
            }
        }
        splitLine: {
            distance: number
        }
        axisLabel: {
            distance: number
        }
        min: number
        max: number
        data: GaugeChartSeriesDataType[]
        title: {
            show: boolean
        }
        detail: {
            color: string | undefined
            formatter: string | undefined
        }
    }
}>

export function ParseGaugeChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    GaugeChartDataDefinitionType,
    GaugeChartOptionsType,
    GaugeChartConfigType
>): GaugeChartDataOptionsType {
    const dataOptions: GaugeChartOptionsType = structuredClone(prevOptions)

    const gaugeConfig: GaugeChartConfigType = structuredClone(config)

    let seriesData: GaugeChartSeriesDataType[] = []

    // Checking for "color by value" option and if enabled, sort the color stops based on their values.
    let sortedColorStops: GaugeChartDataDefinitionType['colorStops'] | null = null
    if (dataDefinition.isColoredByValue === true && dataDefinition.colorStops.length !== 0) {
        sortedColorStops = [...dataDefinition.colorStops].sort((a, b) => a.upperBoundValue - b.upperBoundValue)
    }

    for (let _series of dataDefinition.series) {
        // Each row
        if (_series.attribute.type === 'custom' && _series.attribute.field === 'Each Row') {
            for (let _row of filteredData) {
                const dataValue = parseNumber(_row[convertNodeAttributeToKeyString(dataDefinition.indicator.field)])

                /*
                 * Color generation based on ColorStops.
                 * Segment 1
                 * This part handles all view modes which should apply their color stops directly
                 * into each series data "itemStyle".
                 */
                let seriesValueColor: GaugeChartDataDefinitionType['colorStops'][number]['color'] | null = null

                // "null" value is the only check needed to determine if there are valid color stops defined by user.
                if (sortedColorStops !== null) {
                    // Filter color stops with upperBounds equal or greater than the current series value.
                    const matchingColorStops = sortedColorStops.filter(
                        (_colorStop) => _colorStop.upperBoundValue >= dataValue
                    )

                    // Select the first matching color stop and use its color.
                    if (matchingColorStops.length > 0) {
                        seriesValueColor = matchingColorStops[0].color
                    }
                }
                // Series may have defined a color for itself as well (apart from ColorStops)
                else if (_series.color.isEnabled) {
                    seriesValueColor = _series.color.value
                }

                // Calculating data value in percentage
                let dataValueInPercentage: number | null = null
                if (dataDefinition.isVisualizedByPercentage) {
                    dataValueInPercentage = Number(
                        ((dataValue * 100) / dataDefinition.indicator.valueBounds.max).toFixed(3)
                    )
                }

                seriesData.push({
                    value: dataValueInPercentage ?? dataValue,
                    name:
                        _series.fieldLabel &&
                        formatValueAsLabel(_row[convertNodeAttributeToKeyString(_series.fieldLabel)]),
                    itemStyle:
                        seriesValueColor !== null
                            ? {
                                  color: seriesValueColor,
                              }
                            : undefined,
                })
            }
        }
        // Others
        else {
            const dataGroups = groupDataSourcePanelToMap(
                filteredData,
                convertNodeAttributeToKeyString(_series.attribute)
            )

            const sortedDataCategories = sortDataGroupMap(dataGroups)

            for (let [_category, _value] of sortedDataCategories) {
                const dataValue = parseNumber(
                    applyAggregation({
                        method: dataDefinition.indicator.aggregationMethod,
                        data: _value,
                        field: convertNodeAttributeToKeyString(dataDefinition.indicator.field),
                        secondaryField: dataDefinition.indicator.compareField
                            ? convertNodeAttributeToKeyString(dataDefinition.indicator.compareField)
                            : undefined,
                    })
                )

                /*
                 * Color generation based on ColorStops.
                 * Segment 1
                 * This part handles all view modes which should apply their color stops directly
                 * into each series data "itemStyle".
                 */
                let seriesValueColor: GaugeChartDataDefinitionType['colorStops'][number]['color'] | null = null

                // "null" value is the only check needed to determine if there are valid color stops defined by user.
                if (sortedColorStops !== null) {
                    // Filter color stops with upperBounds equal or greater than the current series value.
                    const matchingColorStops = sortedColorStops.filter(
                        (_colorStop) => _colorStop.upperBoundValue >= dataValue
                    )

                    // Select the first matching color stop and use its color.
                    if (matchingColorStops.length > 0) {
                        seriesValueColor = matchingColorStops[0].color
                    }
                }
                // Series may have defined a color for itself as well (apart from ColorStops)
                else if (_series.color.isEnabled) {
                    seriesValueColor = _series.color.value
                }

                // Calculating data value in percentage
                let dataValueInPercentage: number | null = null
                if (dataDefinition.isVisualizedByPercentage) {
                    dataValueInPercentage = Number(
                        ((dataValue * 100) / dataDefinition.indicator.valueBounds.max).toFixed(3)
                    )
                }

                seriesData.push({
                    value: dataValueInPercentage ?? dataValue,
                    name:
                        _series.attribute.type === 'custom' && _series.attribute.field === 'Total'
                            ? 'Total'
                            : formatValueAsLabel(_category),
                    itemStyle:
                        seriesValueColor !== null
                            ? {
                                  color: seriesValueColor,
                              }
                            : undefined,
                })
            }
        }
    }

    /*
     * Color generation based on ColorStops.
     * Segment 2
     * This part handles "speedometer" view mode which should apply its
     * color stops "segments" on axis line "itemStyle".
     * The type of "[number, string][]" is what echarts expects for its axisLine.itemStyle.color property type.
     */
    let axisLineColorSegments: [number, string][] | null = null

    // "null" value is the only check needed to determine if there are valid color stops defined by user.
    if (gaugeConfig.viewMode === 'speedometer' && sortedColorStops !== null) {
        axisLineColorSegments = []

        for (const _colorStop of sortedColorStops) {
            let valuePercentage = 0

            if (dataDefinition.isVisualizedByPercentage) {
                valuePercentage = Number(
                    (_colorStop.upperBoundValue / dataDefinition.indicator.valueBounds.max).toFixed(3)
                )
            } else {
                valuePercentage = Number(
                    (
                        (_colorStop.upperBoundValue - dataDefinition.indicator.valueBounds.min) /
                        (dataDefinition.indicator.valueBounds.max - dataDefinition.indicator.valueBounds.min)
                    ).toFixed(3)
                )
            }

            axisLineColorSegments.push([valuePercentage, _colorStop.color || '#E6EBF8'])
        }

        const lastColorSegmentValue = axisLineColorSegments[axisLineColorSegments.length - 1][0]

        // If the defined color stops do not cover the entire bounds range (min to max),
        // then we have to add a last color segment with the value of 1 to complete the array and
        // prevent visual bugs on the part of echarts.
        if (lastColorSegmentValue !== dataDefinition.indicator.valueBounds.max) {
            axisLineColorSegments.push([1, gaugeConfig.axisLine.color || '#E6EBF8'])
        }
    }

    // Hide series data value label if there are more than one to prevent overlapping text.
    if (seriesData.length > 1) {
        seriesData = seriesData.map((_data) => ({
            ..._data,
            detail: {
                show: false,
            },
        }))
    }

    const prevSeriesOptions = dataOptions?.series as GaugeChartOptionsType['series']

    const viewModeDependantSeriesOptions = {
        // These initial values are based on "gauge" view mode.
        axisLineWidth: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.axisLineWidth,
        // The cast type of "[number, string][]" is what echarts expects for its axisLine.itemStyle.color property type.
        axisLineColor: [[1, gaugeConfig.axisLine.color || '#E6EBF8']] as [number, string][],
        splitLineDistance: -40,
        axisLabelDistance: -20,
        detailColor: gaugeConfig.valueLabel.styles.color,
    }
    switch (gaugeConfig.viewMode) {
        case 'gauge':
            viewModeDependantSeriesOptions.axisLineWidth =
                seriesData.length < 8
                    ? GAUGE_CHART_CONFIG_BASE_VALUES.gauge.axisLineWidth * seriesData.length
                    : GAUGE_CHART_CONFIG_BASE_VALUES.gauge.axisLineWidth * 7

            viewModeDependantSeriesOptions.axisLineColor = [[1, gaugeConfig.axisLine.color || '#E6EBF8']]

            // We put the splitLine on "top" of the value bars.
            // Distance should always be equal to the sum of axisLine width and splitLine length plus 10 (gap).
            viewModeDependantSeriesOptions.splitLineDistance =
                (viewModeDependantSeriesOptions.axisLineWidth +
                    GAUGE_CHART_CONFIG_BASE_VALUES.gauge.splitLineLength +
                    10) *
                -1

            // We need to "add" the splitLine distance first to counter the buggy gap created by echarts
            // and then add our own custom distance (visually adjusted).
            viewModeDependantSeriesOptions.axisLabelDistance =
                viewModeDependantSeriesOptions.splitLineDistance * -1 +
                (GAUGE_CHART_CONFIG_BASE_VALUES.gauge?.axisLabelDistanceAlignValue || 0)

            break

        case 'ring':
            viewModeDependantSeriesOptions.axisLineWidth =
                seriesData.length < 8
                    ? GAUGE_CHART_CONFIG_BASE_VALUES.ring.axisLineWidth * seriesData.length
                    : GAUGE_CHART_CONFIG_BASE_VALUES.ring.axisLineWidth * 7

            viewModeDependantSeriesOptions.axisLineColor = [[1, gaugeConfig.axisLine.color || '#E6EBF8']]

            // We put the splitLine on "top" of the value bars.
            // Distance should always be equal to the sum of axisLine width and splitLine length plus 10 (gap).
            viewModeDependantSeriesOptions.splitLineDistance =
                (viewModeDependantSeriesOptions.axisLineWidth +
                    GAUGE_CHART_CONFIG_BASE_VALUES.ring.splitLineLength +
                    10) *
                -1

            // We need to "add" the splitLine distance first to counter the buggy gap created by echarts
            // and then add our own custom distance (visually adjusted).
            viewModeDependantSeriesOptions.axisLabelDistance =
                viewModeDependantSeriesOptions.splitLineDistance * -1 +
                (GAUGE_CHART_CONFIG_BASE_VALUES.ring?.axisLabelDistanceAlignValue || 0)

            break

        case 'speedometer':
            viewModeDependantSeriesOptions.axisLineWidth = GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.axisLineWidth

            viewModeDependantSeriesOptions.axisLineColor = axisLineColorSegments ?? [
                [1, gaugeConfig.axisLine.color || '#E6EBF8'],
            ]

            viewModeDependantSeriesOptions.splitLineDistance =
                GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.splitLineDistance || 0

            viewModeDependantSeriesOptions.axisLabelDistance =
                GAUGE_CHART_CONFIG_BASE_VALUES.speedometer?.axisLabelDistance || 0

            break

        default:
            break
    }

    dataOptions.series = {
        ...prevSeriesOptions,
        type: 'gauge',
        // TODO: Any better name?
        name: 'Gauge',
        axisLine: {
            ...prevSeriesOptions?.axisLine,
            lineStyle: {
                ...prevSeriesOptions?.axisLine?.lineStyle,
                width: viewModeDependantSeriesOptions.axisLineWidth,
                color: viewModeDependantSeriesOptions.axisLineColor,
            },
        },
        splitLine: {
            ...prevSeriesOptions?.splitLine,
            distance: viewModeDependantSeriesOptions.splitLineDistance,
        },
        axisLabel: {
            ...prevSeriesOptions?.splitLine,
            distance: viewModeDependantSeriesOptions.axisLabelDistance,
        },
        min: dataDefinition.isVisualizedByPercentage ? 0 : dataDefinition.indicator.valueBounds.min,
        max: dataDefinition.isVisualizedByPercentage ? 100 : dataDefinition.indicator.valueBounds.max,
        data: seriesData,
        title: {
            show: false,
        },
        detail: {
            // This line ensures that the detail label color always matches the corresponding color stop.
            color: sortedColorStops !== null ? 'inherit' : gaugeConfig.valueLabel.styles.color,
            // add % sign if visualized by percentage
            formatter: dataDefinition.isVisualizedByPercentage ? '{value}%' : '{value}',
        },
    }

    return structuredClone(dataOptions)
}

/*
 * Pictorial bar chart
 * =========================================
 */

type PictorialBarChartSeriesDataType = {
    value: any
    symbol: string
    itemStyle: {
        color: string | undefined
    }
}

export type PictorialBarChartDataOptionsType = PartialDeep<{
    //? Static option. Only assigned inside initial/default options object.
    xAxis: {
        type: 'value'
    }
    yAxis: {
        type: 'category'
        data: (string | number)[]
    }
    series: {
        type: 'pictorialBar'
        data: PictorialBarChartSeriesDataType[]
        name: string | number
        symbolRepeat: boolean | number | 'fixed'
        symbolSize: string | number | (string | number)[]
        barCategoryGap: string | number
    }
}>

export function ParsePictorialBarChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    PictorialBarChartDataDefinitionType,
    PictorialBarChartOptionsType,
    PictorialBarChartConfigType
>): PictorialBarChartDataOptionsType {
    let dataOptions: PictorialBarChartOptionsType = {}

    const dataGroups = groupDataSourcePanelToMap(
        filteredData,
        convertNodeAttributeToKeyString(dataDefinition.categoryField)
    )

    const sortedDataCategories = sortDataGroupMap(dataGroups, dataDefinition.categoryOrder)

    const sortedCategoryNames: (string | number)[] = []

    const seriesData: PictorialBarChartSeriesDataType[] = []

    for (let [_category, _value] of sortedDataCategories) {
        // If aggregation method is anything other than "count", then "field" is required.
        if (dataDefinition.aggregationMethod === 'count' || dataDefinition.valueField !== undefined) {
            const refinedCategoryName = formatValueAsLabel(_category)

            sortedCategoryNames.push(refinedCategoryName)

            const targetDataDefinitionCategory = dataDefinition.categories.find(
                (_item) => _item.key === refinedCategoryName
            )

            seriesData.push({
                value: applyAggregation({
                    method: dataDefinition.aggregationMethod,
                    data: _value,
                    field:
                        dataDefinition.valueField !== undefined
                            ? convertNodeAttributeToKeyString(dataDefinition.valueField)
                            : '',
                }),
                symbol: targetDataDefinitionCategory?.symbol || 'circle',
                itemStyle: {
                    color: targetDataDefinitionCategory?.color,
                },
            })
        }
    }

    dataOptions = {
        yAxis: {
            type: 'category',
            data: sortedCategoryNames,
        },
        series: {
            type: 'pictorialBar',
            name:
                dataDefinition.aggregationMethod === 'count'
                    ? formatValueAsLabel(dataDefinition.categoryField.field + ' Tally')
                    : formatValueAsLabel(dataDefinition.aggregationMethod + ' ' + dataDefinition.valueField?.field),
            data: seriesData,
            symbolRepeat: true,
            symbolSize: ['40%', '30%'],
            barCategoryGap: '20%',
        },
    }

    return structuredClone(dataOptions)
}

/*
 * Treemap chart
 * =========================================
 */

type TreeNodeType = {
    name: string
    value: number
    children?: TreeNodeType[]
}

export type TreemapChartDataOptionsType = PartialDeep<{
    series: {
        type: 'treemap'
        data: TreeNodeType[]
        name: string | number
        leafDepth: number
        width?: string
        height?: string
        levels: {
            colorSaturation?: [number, number]
            itemStyle?: {
                borderColorSaturation?: number
                gapWidth?: number
                borderWidth?: number
            }
        }[]
    }
}>

export function ParseTreemapChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    TreemapChartDataDefinitionType,
    TreemapChartOptionsType,
    TreemapChartConfigType
>): TreemapChartDataOptionsType {
    const labelKey = convertNodeAttributeToKeyString(dataDefinition.labelField!)
    const valueKey = convertNodeAttributeToKeyString(dataDefinition.valueField!)
    // Helper function to recursively group and format data
    function formatData(
        groupByFields: TreemapChartDataDefinitionType['groupByFields'],
        data: ReportDataSourceRowType[],
        level = 0
    ): TreeNodeType[] {
        if (level >= groupByFields.length)
            return data.map((_row) => ({
                name: _row[labelKey] + '',
                value: isNaN(Number(_row[valueKey])) ? 0 : Number(_row[valueKey]),
                children: [],
            }))

        const groupFieldName = convertNodeAttributeToKeyString(groupByFields[level])
        const groupedData = groupDataSourcePanelToMap(data, groupFieldName)

        return Array.from(groupedData.entries()).map(([key, groupData]) => {
            const children = formatData(groupByFields, groupData, level + 1)

            const seriesObject: TreeNodeType = {
                name: formatValueAsLabel(key),
                value:
                    applyAggregation({
                        method: 'sum',
                        data: groupData,
                        field: convertNodeAttributeToKeyString(dataDefinition.valueField!),
                    }) ?? 0,
                children: children.length > 0 ? children : undefined,
            }

            return seriesObject
        })
    }

    let dataOptions: TreemapChartOptionsType = {}

    // Start formatting data from the top level
    const seriesData = formatData(dataDefinition.groupByFields, filteredData)

    dataOptions = {
        series: {
            type: 'treemap',
            name: 'All',
            data: seriesData,
            leafDepth: dataDefinition.groupByFields.length,
            width: '100%',
            height: '100%',
        },
    }

    return structuredClone(dataOptions)
}

/*
 * Word cloud chart
 * =========================================
 */

export type WordCloudChartDataOptionsType = PartialDeep<{
    series: {
        type: 'wordCloud'
        name: string | number
        // The shape of the "cloud" to draw. Can be any polar equation represented as a
        // callback function, or a keyword present. Available presents are circle (default),
        // cardioid (apple or heart shape curve, the most known polar equation), diamond (
        // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.
        shape: 'circle' | 'cardioid' | 'diamond' | 'triangle-forward' | 'triangle' | 'pentagon' | 'star'
        // Keep aspect ratio of maskImage or 1:1 for shapes
        // This option is supported from echarts-wordcloud@2.1.0
        keepAspect: boolean

        // A silhouette image which the white area will be excluded from drawing texts.
        // The shape option will continue to apply as the shape of the cloud to grow.
        // maskImage: maskImage,

        // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
        // Default to be put in the center and has 75% x 80% size.
        left: string
        top: string
        // TODO: Why isn't "PartialDeep" working correctly? If we remove the optional "?" operator, there will be errors!
        right?: string
        bottom?: string
        width: string | number
        height: string | number
        // Text size range which the value in data will be mapped to.
        // Default to have minimum 12px and maximum 60px size.
        sizeRange: number[]
        // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45
        rotationRange: number[]
        rotationStep: number
        // size of the grid in pixels for marking the availability of the canvas
        // the larger the grid size, the bigger the gap between words.
        gridSize: number
        // set to true to allow word being draw partly outside of the canvas.
        // Allow word bigger than the size of the canvas to be drawn
        drawOutOfBound: boolean
        // If perform layout animation.
        // NOTE disable it will lead to UI blocking when there is lots of words.
        layoutAnimation: boolean
        // Global text style
        textStyle: {
            fontFamily: string
            fontWeight: 'normal' | 'bold' | number
        }
        emphasis: {
            focus: string

            textStyle: {
                textShadowBlur: number
                textShadowColor: string | undefined
            }
        }
        data: {
            name: string | number
            value: number
            textStyle: {
                color: string | undefined
            }
        }[]
    }[]
}>

export function ParseWordCloudChartData({
    dataDefinition,
    filteredData,
    prevOptions,
    config,
}: ChartDataParserParams<
    WordCloudChartDataDefinitionType,
    WordCloudChartOptionsType,
    WordCloudChartConfigType
>): WordCloudChartDataOptionsType {
    let dataOptions: WordCloudChartOptionsType = {}

    const dataGroups = groupDataSourcePanelToMap(
        filteredData,
        convertNodeAttributeToKeyString(dataDefinition.categoryField)
    )

    const sortedDataCategories = sortDataGroupMap(dataGroups)

    const processedCategories: string[] = []

    for (const [_category, _value] of sortedDataCategories) {
        try {
            processedCategories.push(
                ...keyword_extractor.extract(formatValueAsLabel(_category), {
                    language: 'english',
                    remove_digits: false,
                    return_changed_case: true,
                    remove_duplicates: false,
                })
            )
        } catch (error) {
            console.error(error)
        }
    }

    const groupedCategories = groupBy(processedCategories)

    const seriesData = values(groupedCategories).map((_category) => ({
        name: _category[0],
        value: _category.length,
        textStyle: {
            color:
                'rgb(' +
                [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                ].join(',') +
                ')',
        },
    }))

    dataOptions = {
        series: [
            {
                type: 'wordCloud',
                name: formatValueAsLabel(dataDefinition.categoryField.field),

                // The shape of the "cloud" to draw. Can be any polar equation represented as a
                // callback function, or a keyword present. Available presents are circle (default),
                // cardioid (apple or heart shape curve, the most known polar equation), diamond (
                // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

                shape: 'circle',

                // Keep aspect ratio of maskImage or 1:1 for shapes
                // This option is supported from echarts-wordcloud@2.1.0
                keepAspect: false,

                // A silhouette image which the white area will be excluded from drawing texts.
                // The shape option will continue to apply as the shape of the cloud to grow.

                // maskImage: maskImage,

                // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
                // Default to be put in the center and has 75% x 80% size.

                left: 'center',
                top: 'center',
                width: '85%',
                height: '85%',
                // right: null,
                // bottom: null,

                // Text size range which the value in data will be mapped to.
                // Default to have minimum 12px and maximum 60px size.

                sizeRange: [12, 60],

                // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

                rotationRange: [-90, 90],
                rotationStep: 45,

                // size of the grid in pixels for marking the availability of the canvas
                // the larger the grid size, the bigger the gap between words.

                gridSize: 8,

                // set to true to allow word being draw partly outside of the canvas.
                // Allow word bigger than the size of the canvas to be drawn
                drawOutOfBound: false,

                // If perform layout animation.
                // NOTE disable it will lead to UI blocking when there is lots of words.
                layoutAnimation: true,

                // Global text style
                textStyle: {
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                },
                emphasis: {
                    focus: 'self',

                    textStyle: {
                        textShadowBlur: 10,
                        textShadowColor: '#333',
                    },
                },
                data: seriesData,
            },
        ],
    }

    return structuredClone(dataOptions)
}
