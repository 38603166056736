//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { Stack, Box, Typography, alpha, getContrastRatio, darken } from '@mui/material'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { NavLinkWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

type Props = {
    data: NavLinkWidgetType
    isActive: boolean
    fontSizeScaleFactor: number
    onReady: () => void
}

function NavLinkWidget({ data, isActive, fontSizeScaleFactor, onReady }: Props) {
    const { viewMode, slides, activeSlideId, setActiveSlideId } = useReportStore((store) => ({
        viewMode: store.viewMode,
        slides: store.slides,
        activeSlideId: store.activeSlideId,
        setActiveSlideId: store.setActiveSlideId,
    }))

    useEffect(() => {
        onReady()
    }, [])

    const targetSlide = useMemo(() => {
        if (data.mode === 'internal') {
            return slides.find((_slide) => _slide.id === data.targetSlideId) || null
        }
        // If it's 'external', we don't need to find a target slide.
        return null
    }, [slides, data])

    const isLinkValid = useMemo(() => {
        if (data.mode === 'external') {
            try {
                const urlObj = new URL(data.href) // Throws an error for invalid URLs

                if (urlObj.protocol === 'http:' || urlObj.protocol === 'https:') {
                    return true
                }
            } catch (error) {
                return false
            }
        } else if (data.mode === 'internal') {
            if (targetSlide !== null) {
                return true
            }
        }

        return false
    }, [data, targetSlide])

    const onClickHandler = () => {
        if (viewMode === 'design' && isActive === false) {
            return
        }

        if (data.mode === 'internal') {
            if (targetSlide && activeSlideId !== targetSlide.id) {
                setActiveSlideId({ slideId: targetSlide.id })
            }
        } else if (data.mode === 'external') {
            window.open(data.href, '_blank')
        }
    }

    return isLinkValid === false ? (
        /*  Invalid target view
			========================================= */
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={(theme) => ({
                width: '100%',
                height: '100%',
                padding: theme.spacing(0.5, 1),
            })}
        >
            <CloseRoundedIcon
                sx={(theme) => ({
                    width: 22 * fontSizeScaleFactor,
                    height: 22 * fontSizeScaleFactor,

                    color: theme.palette.common.fill_1,
                })}
            />

            <Typography
                component="span"
                sx={(theme) => ({
                    fontSize: 14 * fontSizeScaleFactor,

                    color: theme.palette.common.text_1,
                })}
            >
                (No Target)
            </Typography>
        </Stack>
    ) : (
        /*  Main view
			========================================= */
        <BaseButton
            label={data.label}
            onClick={(evt) => onClickHandler()}
            variant={data.variant}
            sx={(theme) => ({
                width: '100%',
                height: '100%',
                padding: theme.spacing(0.5, 1),
                fontFamily: data.styles.fontFamily,
                fontSize: data.styles.fontSize * fontSizeScaleFactor,
                fontWeight: data.styles.fontWeight,
                display: 'flex',
                // Allow the first click on the widget to select the widget and
                // set activeWidgetId and if that's set, allow interaction.
                pointerEvents: viewMode === 'design' ? (isActive ? 'auto' : 'none') : undefined,

                borderColor: data.variant === 'outlined' ? data.styles.color : undefined,
                backgroundColor: data.variant === 'contained' ? data.styles.color : undefined,
                color:
                    data.variant === 'contained'
                        ? // This contrast ratio number is taken from MUI doc's examples.
                          getContrastRatio(data.styles.color, '#FFF') > 4.5
                            ? '#FFF'
                            : '#111'
                        : data.styles.color,

                '&:hover': {
                    borderColor: data.variant === 'outlined' ? alpha(data.styles.color, 0.9) : undefined,
                    backgroundColor:
                        data.variant === 'contained' ? darken(data.styles.color, 0.1) : alpha(data.styles.color, 0.1),
                },
            })}
        />
    )
}

export default NavLinkWidget
