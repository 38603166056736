import { Box, Chip, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Chart from 'features/chart/Chart'
import { InsightItemType, NodeDictonaryType } from '../../InisghtWidgetInterventionAdvancedView'
import React, { useEffect, useMemo, useState } from 'react'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { InsightWidgetType, ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import Color from 'color'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import AdvancedInsightCompareConfigDialog from './AdvancedInsightCompareConfigDialog'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { quantileSeq, round } from 'mathjs'
import { uniq, uniqBy } from 'lodash'

type InsightComparePanelProps = {
    categories: InsightWidgetType['interventionConfig']['categories']
    compareConfig: InsightWidgetType['interventionConfig']['compareConfig']
    items: InsightItemType
    nodeDictonary: NodeDictonaryType
    setActiveView: (value: { category: string | null; insightId: string | null }) => void
    totalNodes: number
    dataSourceFields: FlexibleSelectOptionType[]
    viewMode: ReportDesignerStoreStateType['viewMode']
    saveCompareConfig: (config: InsightWidgetType['interventionConfig']['compareConfig']) => void
    selectedDataSource: InsightWidgetType['selectedDataSource']
    idField: InsightWidgetType['idField']
}

const InsightComparePanel: React.FC<InsightComparePanelProps> = ({
    items,
    setActiveView,
    selectedDataSource,
    nodeDictonary: nodeDictionary,
    categories,
    totalNodes,
    compareConfig,
    dataSourceFields,
    viewMode,
    saveCompareConfig,
    idField,
}) => {
    const { dataSources } = useReportStore((store) => ({
        dataSources: store.dataSources,
    }))

    const [compareBy, setCompareBy] = useState<ReportDataSourceAttributeType | null>(null)
    const [groupedItems, setGroupedItems] = useState<Record<string, InsightItemType>>({})

    const [groupSize, setGroupSize] = useState<Record<string, number>>({})

    const [isNormalized, setIsNormalized] = useState(false)

    useEffect(() => {
        if (compareConfig.length > 0) setCompareBy(compareConfig[0].attribute)
    }, [])

    useEffect(() => {
        if (compareBy === null || selectedDataSource === null) return
        const compareByConfig = compareConfig.find((config) => config.attribute === compareBy)
        if (compareByConfig === undefined) return

        setIsNormalized(compareByConfig.normalize ?? false)

        const datasource = dataSources.find((ds) => ds.id === selectedDataSource?.id)
        if (datasource === undefined) return

        const nodeGroups: Record<string | number, any> = {}

        if (datasource.mode === 'network') {
            const preset = datasource.presets[selectedDataSource.preset ?? 'default']

            if (compareBy.type === 'basic') {
                for (const nodeId in datasource.networkContext.nodes) {
                    nodeGroups[nodeId] = datasource.networkContext.nodes[nodeId][compareBy.field]
                }
            } else if (compareBy.type === 'analytic') {
                if (!preset.analytics || !preset.analytics[compareBy.relationship]) return
                for (const nodeId in preset.analytics[compareBy.relationship].nodes) {
                    nodeGroups[nodeId] = preset.analytics[compareBy.relationship].nodes[nodeId][compareBy.field]
                }
            }
        } else {
            if (compareBy.type === 'basic') {
                if (idField === null) return
                const panel = datasource.data[selectedDataSource.panel ?? 0]
                if (panel === undefined) return
                for (const row in panel) {
                    let nodeId = panel[row][idField.field]
                    if (nodeId === undefined) continue

                    // Convert nodeId to string if it is not a number or string (date)
                    if (typeof nodeId !== 'number' && typeof nodeId !== 'string') nodeId = nodeId.toString()

                    nodeGroups[nodeId] = panel[row][compareBy.field]
                }
            }
        }

        // if compareBy is a number, bin the values
        if (compareByConfig.mode === 'number') {
            if (compareByConfig.biningMethod === 'quantile') {
                const values = Object.values(nodeGroups)
                // Calculate the quartiles (four quantiles)
                const quantiles = quantileSeq(values, [0.25, 0.5, 0.75]) as number[]
                for (const nodeId in nodeGroups) {
                    const value = nodeGroups[nodeId]
                    let quantileIndex = -1

                    // Determine the quantile index
                    if (value <= quantiles[0]) {
                        quantileIndex = 1
                    } else if (value <= quantiles[1]) {
                        quantileIndex = 2
                    } else if (value <= quantiles[2]) {
                        quantileIndex = 3
                    } else {
                        quantileIndex = 4
                    }

                    // Label the node with the correct quantile
                    nodeGroups[nodeId] = `Q${quantileIndex}`
                }
            } else {
                throw new Error('Not implemented')
            }
        }

        const newItems: Record<string, InsightItemType> = {}

        const groups = uniq(Object.values(nodeGroups))

        // compute the group size
        const groupSize: Record<string, number> = {}

        for (const group of groups) {
            groupSize[group] = 0
        }

        for (const nodeId in nodeGroups) {
            groupSize[nodeGroups[nodeId]] += 1
        }

        setGroupSize(groupSize)

        // initialize newItems
        for (const group of groups) {
            newItems[group] = {}
        }

        // iterate over items and populate newItems
        Object.entries(items).forEach(([categoryKey, category]) => {
            for (const group of groups) {
                newItems[group][categoryKey] = {
                    ...category,
                    issues: {},
                }
            }

            Object.entries(category.issues).forEach(([insightId, insight]) => {
                for (const group of groups) {
                    newItems[group][categoryKey].issues[insightId] = {
                        ...insight,
                        nodes: [],
                    }
                }

                insight.nodes.forEach((node) => {
                    const group = nodeGroups[node.id]
                    newItems[group][categoryKey].issues[insightId].nodes.push(node)
                })
            })
        })

        // calculate total issues for each group
        for (const group of groups) {
            for (const categoryKey in newItems[group]) {
                const category = newItems[group][categoryKey]
                const totalIssues: InsightItemType[string]['numberOfIssues'] = {
                    negative: {
                        high: 0,
                        medium: 0,
                        low: 0,
                    },
                    positive: {
                        high: 0,
                        medium: 0,
                        low: 0,
                    },
                    neutral: {
                        high: 0,
                        medium: 0,
                        low: 0,
                    },
                }

                Object.values(category.issues).forEach((issue) => {
                    issue.nodes.forEach((node) => {
                        totalIssues[issue.mode][node.severity] += 1
                    })
                })
            }
        }

        setGroupedItems(newItems)
    }, [compareBy, items, selectedDataSource, dataSources, idField, compareConfig])

    const [showViewConfig, setShowViewConfig] = useState(false)

    const sunburstChartOptions = useMemo<EChartsCustomOptionType>(() => {
        const data = Object.entries(groupedItems).map(([groupKey, group]) => ({
            name: groupKey,
            children: Object.entries(group).map(([categoryKey, category]) => {
                const totalIssues = Object.values(category.issues).reduce(
                    (acc, insight) => acc + insight.nodes.length,
                    0
                )
                return {
                    name: category.title,
                    itemStyle: {
                        color: category.color,
                    },
                    children: Object.entries(category.issues).map(([insightId, insight]) => ({
                        name: insight.text,
                        value: isNormalized
                            ? round(insight.nodes.length / groupSize[groupKey], 3)
                            : insight.nodes.length,

                        itemStyle: {
                            color: category.color
                                ? Color(category.color)
                                      .lighten((1 - insight.nodes.length / totalIssues) * 0.6)
                                      .toString()
                                : 'grey',
                        },
                    })),
                }
            }),
        }))
        const options = {
            tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
            },

            series: [
                {
                    type: 'sunburst',
                    data,
                    radius: ['10%', '95%'],
                    emphasis: {
                        focus: 'ancestor',
                        label: {
                            show: false,
                        },
                    },
                    levels: [
                        {}, // Root level
                        {
                            // Level 1: Group
                            itemStyle: {
                                borderWidth: 4,
                            },
                            label: {
                                show: false,
                            },
                        },
                        {
                            // Level 2: Category
                            itemStyle: {
                                borderWidth: 2,
                            },
                            label: {
                                show: false,
                            },
                        },
                        {
                            // Level 3: Issue
                            itemStyle: {
                                borderWidth: 1,
                            },
                            label: {
                                show: false,
                            },
                        },
                    ],
                },
            ],
        } as EChartsCustomOptionType
        return options
    }, [groupedItems])

    const stackedBarChartOptions = useMemo<EChartsCustomOptionType>(() => {
        const groups = Object.keys(groupedItems).sort((a, b) => a.localeCompare(b))

        const allIssues = Object.values(groupedItems).reduce((acc, group) => {
            Object.entries(group).forEach(([categoryId, category]) => {
                Object.values(category.issues).forEach((insight) => {
                    acc.push({
                        categoryId: categoryId,
                        issueId: insight.insightId + '-' + insight.condition,
                        title: insight.text,
                    })
                })
            })
            return acc
        }, [] as { categoryId: string; issueId: string; title: string }[])

        const uniqueIssues = uniqBy(allIssues, (issue) => issue.issueId)

        // Create series for each type and severity
        const series = uniqueIssues.map((issue) => {
            return {
                name: issue.title,
                type: 'bar',
                label: {
                    show: false,
                },
                stack: 'total',

                data: groups.map((group) => {
                    const issues = groupedItems[group]?.[issue.categoryId]?.issues[issue.issueId]

                    if (issues === undefined) return 0

                    if (isNormalized) {
                        if (groupSize[group] === 0) return 0
                        return round(issues.nodes.length / groupSize[group], 3)
                    } else {
                        return issues.nodes.length
                    }
                }),
            }
        })

        return {
            xAxis: {
                show: true,
                type: 'category',
                data: groups,
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, 0.01],
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                data: uniqueIssues.map((category) => ({
                    name: category.title,
                })),
                type: 'scroll',
            },
            series,
        } as EChartsCustomOptionType
    }, [groupedItems])

    const heatmapOptions = useMemo<EChartsCustomOptionType>(() => {
        if (compareBy === null) return {}

        const groups = Object.keys(groupedItems).sort((a, b) => a.localeCompare(b))

        const allIssues = Object.values(groupedItems).reduce((acc, group) => {
            Object.entries(group).forEach(([categoryId, category]) => {
                Object.values(category.issues).forEach((insight) => {
                    acc.push({
                        categoryId: categoryId,
                        issueId: insight.insightId + '-' + insight.condition,
                        title: insight.text,
                    })
                })
            })
            return acc
        }, [] as { categoryId: string; issueId: string; title: string }[])

        const uniqueIssues = uniqBy(allIssues, (issue) => issue.issueId)
        const issueTitles = uniqueIssues.map((issue) => issue.title)

        // Create data for the heatmap
        const heatmapData = uniqueIssues.flatMap((issue, yIndex) =>
            groups.map((group, xIndex) => {
                const issues = groupedItems[group]?.[issue.categoryId]?.issues[issue.issueId]

                let value = issues ? issues.nodes.length : 0

                if (isNormalized) {
                    if (groupSize[group] === 0) value = 0
                    else value = round(value / groupSize[group], 3)
                }

                return [xIndex, yIndex, value]
            })
        )

        const options = {
            grid: {
                containLabel: true,
                right: '2%', // Adjust right margin
                left: '2%', // Adjust right margin
                top: '2%', // Adjust right margin
                bottom: '15%', // Adjust right margin
            },
            xAxis: {
                type: 'category',
                data: groups,
                splitArea: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
            },
            yAxis: {
                type: 'category',
                data: issueTitles.map((title) => (title.length > 15 ? `${title.substring(0, 15)}...` : title)), // Truncate long titles
                splitArea: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
            },
            visualMap: {
                min: 0,
                max: Math.max(...heatmapData.map((item) => item[2])),
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '5%',
                inRange: {
                    color: ['#ADF67E', '#FF6347'],
                },
            },
            series: [
                {
                    name: 'Issues Count',
                    type: 'heatmap',
                    data: heatmapData,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                        },
                    },
                    itemStyle: {
                        shadowBlur: 1,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            ],
            tooltip: {
                position: 'top',
                formatter: ({ data }: any) =>
                    `Group: ${groups[data[0]]}<br/>Issue: ${issueTitles[data[1]]}<br/>
                ${isNormalized ? 'Avg issue per student ' : 'Issues'}
                : ${data[2]}`,
            },
        } as EChartsCustomOptionType

        return options
    }, [groupedItems])

    return (
        <Grid2 container sx={(theme) => ({})}>
            {/* category select */}
            <Grid2 xs={12} p={2}>
                <Stack direction="row" gap={1}>
                    <BaseSelectWithLabel
                        label="Compare By"
                        value={compareBy}
                        onChange={setCompareBy}
                        options={compareConfig.map((config) => ({
                            label: config.label,
                            value: config.attribute,
                        }))}
                        sx={{ flexGrow: 1 }}
                    />
                    {viewMode === 'design' && (
                        <Tooltip title="Compare config">
                            <IconButton onClick={() => setShowViewConfig(true)}>
                                <DynamicIcon icon="settings" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </Grid2>

            <Grid2 xs={12} sx={{ height: '320px' }} p={2}>
                <Stack height="100%" component={Paper} direction="column" gap={2} p={1} elevation={3}>
                    <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                        {/* Legend */}
                        <Stack direction="column" gap={2} width="100px" height="100%">
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: 'bold', width: '200px', flexGrow: 1 }}
                                gutterBottom
                            >
                                Issues by Category
                            </Typography>
                            {categories.map((category) => (
                                <Stack direction="row" gap={1} alignItems="center">
                                    <Box
                                        sx={{
                                            bgcolor: category.color,
                                            width: 30,
                                            height: 20,
                                            borderRadius: 1,
                                        }}
                                    />
                                    <Typography variant="caption">{category.title}</Typography>
                                </Stack>
                            ))}
                        </Stack>
                        <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                            <Chart type="sunburst" options={sunburstChartOptions} />
                        </Box>
                    </Stack>
                </Stack>
            </Grid2>

            <Grid2 xs={12} sx={{ height: '420px' }} p={2}>
                <Stack height="100%" component={Paper} direction="column" gap={2} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Issues by Severity
                    </Typography>
                    <Chart type="bar" options={stackedBarChartOptions} />
                </Stack>
            </Grid2>

            <Grid2 xs={12} sx={{ height: '620px' }} p={2}>
                <Stack height="100%" component={Paper} direction="column" gap={2} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Issues by Severity
                    </Typography>
                    <Chart type="heatmap" options={heatmapOptions} />
                </Stack>
            </Grid2>

            {viewMode === 'design' && (
                <AdvancedInsightCompareConfigDialog
                    open={showViewConfig}
                    onClose={() => setShowViewConfig(false)}
                    dataSourceFields={dataSourceFields}
                    config={compareConfig}
                    onSave={(config) => {
                        saveCompareConfig(config)
                        setShowViewConfig(false)
                    }}
                />
            )}
        </Grid2>
    )
}

export default InsightComparePanel
