import { CreatorType } from 'types/resources'
import { parseUrl, QuestionBankApi as Apis } from './Apis'
import { BaseResponseType, request } from './request'

export type QuestionBankType = {
    primaryKey?: number
    question: any
    category: string
    name: string
    description?: string
    createdOn?: Date
    createdBy?: CreatorType
}

export type GetQuestionsResponseType = {
    success: boolean
    data: Array<QuestionBankType>
}

export const GetQuestionsService: (
    category?: string,
    showMessage?: boolean
) => Promise<GetQuestionsResponseType> = async (category, showMessage = false) => {
    return request(
        parseUrl(Apis.GetQuestions, { category: category == null ? '' : `category=${category}` }),
        'GET',
        undefined,
        undefined,
        showMessage
    )
}

export const AddQuestionService: (
    question: QuestionBankType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (question, showMessage = false) => {
    return request(Apis.AddQuestion, 'POST', undefined, question, showMessage)
}

export const DeleteQuestionService: (
    qid: string | number,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (qid, showMessage = false) => {
    return request(parseUrl(Apis.DeleteQuestion, { qid }), 'Delete', undefined, undefined, showMessage)
}
