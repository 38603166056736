//* ======= Libraries
import { Outlet } from 'react-router-dom'
import SurveyContextProvider from 'contexts/SurveyContex'

export default function SurveyRoutes() {
    return (
        <SurveyContextProvider>
            <Outlet />
        </SurveyContextProvider>
    )
}
