import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { GetSurveyService, SurveyBasicType } from 'services/SurveyService'
import { RootContext } from './RootContext'
import { NavigationDrawerType, NavigationPathType } from 'types/NavigationTypes'
import SurveyDrawerNavigation from 'pages/survey/Navigation'
import { GetNodeOtherFiledsService } from 'services/NodeService'
import { ProjectContext } from './ProjectContext'
import { DeepPartial } from 'react-hook-form'
import { merge } from 'lodash'

interface SurveyContextTypes {
    nodeColumns?: string[]
    survey?: SurveyBasicType
    drawerContent: NavigationDrawerType
    fetchSurvey: (id: number) => void
    updateSurvey: (updatedSurvey: DeepPartial<SurveyBasicType>) => void
}

const SurveyContext = createContext<SurveyContextTypes>({
    survey: undefined,
    drawerContent: { quickAccess: false, sections: [] },
    fetchSurvey: (id: number) => {},
    updateSurvey: (updatedSurvey: DeepPartial<SurveyBasicType>) => {},
})

export { SurveyContext }

interface SurveyContextProviderTypes {
    children: React.ReactNode
}

export default function SurveyContextProvider({ children }: SurveyContextProviderTypes) {
    const { sid, pid } = useParams()
    const location = useLocation()

    const { project, role } = useContext(ProjectContext)

    const [survey, setSurvey] = useState<SurveyBasicType>()
    const [nodeColumns, setNodeColumns] = useState<string[]>([])
    const [drawerContent, setDrawerContent] = useState<NavigationDrawerType>({
        quickAccess: false,
        sections: [],
    })

    const { setPath } = useContext(RootContext)

    const fetchSurvey = () => {
        //ToDo show error messages
        if (sid && pid) {
            GetSurveyService(Number.parseInt(pid), Number.parseInt(sid))
                .then((result) => result.success && setSurvey(result.data))
                .finally(() => {})

            GetNodeOtherFiledsService(pid).then((res) => res.success && setNodeColumns(res.data.map((v) => v.field)))
        } else setSurvey({ title: '' })
    }

    useEffect(fetchSurvey, [sid, pid])

    useEffect(() => {
        if (survey && project && role) {
            const path: NavigationPathType[] = [
                { link: '/', title: 'All Projects' },
                {
                    title: project.title,
                    link: `/project/${pid}`,
                },
                {
                    title: 'All Surveys',
                    link: `/project/${pid}/surveys`,
                },
                {
                    title: survey.title,
                    link: `/project/${pid}/surveys/${sid}`,
                },
            ]
            if (location.pathname.endsWith('designer')) {
                path.push({ title: 'Survey Designer' })
            } else if (location.pathname.endsWith('notifications')) {
                path.push({ title: 'Notifications' })
            } else if (location.pathname.endsWith('participants')) {
                path.push({ title: 'Participants' })
            } else if (location.pathname.endsWith('logs')) {
                path.push({ title: 'Logs' })
            } else if (location.pathname.endsWith('Logs')) {
                path.push({ title: 'All Surveys' })
            } else if (location.pathname.endsWith('results')) {
                path.push({ title: 'All Surveys' })
            } else if (location.pathname.endsWith('surveys')) {
                path.push({ title: 'All Surveys' })
            }
            setPath(path)
            setDrawerContent(SurveyDrawerNavigation(role, survey, pid, sid))
        }
    }, [survey, pid, setPath, sid, project, role, location.pathname])

    const updateSurvey = (updatedSurvey: DeepPartial<SurveyBasicType>) => {
        setSurvey((s) => (s === undefined ? undefined : (merge({}, s, updatedSurvey) as SurveyBasicType)))
    }
    return (
        <SurveyContext.Provider value={{ updateSurvey, survey, fetchSurvey, drawerContent, nodeColumns }}>
            {children}
        </SurveyContext.Provider>
    )
}
