import { Stack, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import { useState } from 'react'
import { LayoutSettingsType } from '../../../types/NetworkViz.types'

type NetworkNoOverlapSettingsProps = {
    settings: LayoutSettingsType['noverlap']
    saveSettings: (settings: LayoutSettingsType['noverlap']) => void
}

function NetworkNoOverlapSettings({ settings, saveSettings }: NetworkNoOverlapSettingsProps) {
    const [_settings, setSettings] = useState(settings)

    const updateSettings = (filed: keyof LayoutSettingsType['noverlap']) => (value: number) => {
        setSettings(pv => ({ ...pv, [filed]: value }))
    }

    return (
        <Stack gap={2}>
            <Stack>
                <Typography>Grid Size</Typography>
                <ValueSliderPicker
                    min={1}
                    max={100}
                    step={1}
                    value={_settings.gridSize}
                    onChange={updateSettings('gridSize')}
                />
            </Stack>
            <Stack>
                <Typography>Gravity</Typography>
                <ValueSliderPicker
                    min={1}
                    max={100}
                    step={1}
                    value={_settings.margin}
                    onChange={updateSettings('margin')}
                />
            </Stack>
            <Stack>
                <Typography>Inertia</Typography>
                <ValueSliderPicker
                    min={1}
                    max={10}
                    step={0.1}
                    value={_settings.expansion}
                    onChange={updateSettings('expansion')}
                />
            </Stack>


            <BaseButton
                label="Apply"
                onClick={(evt) => saveSettings(_settings)}
                variant="outlined"
            />
        </Stack>
    )
}

export default NetworkNoOverlapSettings