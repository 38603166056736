//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { Stack, Box, Typography, alpha, getContrastRatio, darken, IconButton } from '@mui/material'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, { useReportActiveSlide } from 'features/report-designer/store/reportDesignerStore'
import { DynamicControlWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'

type Props = {
    data: DynamicControlWidgetType
    isActive: boolean
    fontSizeScaleFactor: number
    onReady: () => void
}

function DynamicControlWidget({ data, isActive, fontSizeScaleFactor, onReady }: Props) {
    const { viewMode, activeSlideId, updateSlideVariable } = useReportStore((store) => ({
        viewMode: store.viewMode,
        activeSlideId: store.activeSlideId,
        updateSlideVariable: store.updateSlideVariable,
    }))

    const activeSlide = useReportActiveSlide()

    useEffect(() => {
        onReady()
    }, [])

    const isValid = useMemo(() => {
        return data.variableName !== null && data.variableName !== '' && data.variableValue !== null
    }, [data.variableName, data.variableValue])

    const isEnabled = useMemo(() => {
        if (!activeSlide?.variables || data.variableName === null) return false
        return data.variableValue === activeSlide.variables[data.variableName]
    }, [data.variableName, data.variableValue, activeSlide?.variables])

    const onClickHandler = () => {
        if (viewMode === 'design' && isActive === false) {
            return
        }
        if (activeSlideId === null || !isValid) return

        updateSlideVariable({
            slideId: activeSlideId,
            data: {
                variableName: data.variableName!,
                variableValue: data.variableValue!,
                toggle: data.toggle,
            },
        })
    }

    const currentState = useMemo(() => {
        if (isEnabled && data.activeConfig.enabled) {
            return {
                icon: data.activeConfig.icon,
                label: data.activeConfig.label,
                color: data.activeConfig.color ?? '#FFF',
            }
        } else {
            return {
                icon: data.nonActiveConfig.icon,
                label: data.nonActiveConfig.label,
                color: data.nonActiveConfig.color ?? '#FFF',
            }
        }
    }, [isEnabled, data.activeConfig, data.nonActiveConfig])

    return !isValid ? (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={(theme) => ({
                width: '100%',
                height: '100%',
                padding: theme.spacing(0.5, 1),
            })}
        >
            <CloseRoundedIcon
                sx={(theme) => ({
                    width: 22 * fontSizeScaleFactor,
                    height: 22 * fontSizeScaleFactor,

                    color: theme.palette.common.fill_1,
                })}
            />

            <Typography
                component="span"
                sx={(theme) => ({
                    fontSize: 14 * fontSizeScaleFactor,

                    color: theme.palette.common.text_1,
                })}
            >
                (No Variable name or value)
            </Typography>
        </Stack>
    ) : data.variant === 'icon' ? (
        <IconButton
            onClick={(evt) => onClickHandler()}
            sx={(theme) => ({
                width: '100%',
                height: '100%',
                padding: theme.spacing(0.5, 1),
                fontFamily: data.fontStyle.fontFamily,
                fontSize: data.fontStyle.fontSize * fontSizeScaleFactor,
                fontWeight: data.fontStyle.fontWeight,
                // Allow the first click on the widget to select the widget and
                // set activeWidgetId and if that's set, allow interaction.
                pointerEvents: viewMode === 'design' ? (isActive ? 'auto' : 'none') : undefined,

                borderColor: data.variant === 'outlined' ? currentState.color : undefined,
                backgroundColor: data.variant === 'contained' ? currentState.color : undefined,
                color:
                    data.variant === 'contained'
                        ? // This contrast ratio number is taken from MUI doc's examples.
                          getContrastRatio(currentState.color, '#FFF') > 4.5
                            ? '#FFF'
                            : '#111'
                        : currentState.color,

                '&:hover': {
                    borderColor: data.variant === 'outlined' ? alpha(currentState.color, 0.9) : undefined,
                    backgroundColor:
                        data.variant === 'contained' ? darken(currentState.color, 0.1) : alpha(currentState.color, 0.1),
                },
            })}
        >
            {currentState.icon && <DynamicIcon icon={currentState.icon} />}
        </IconButton>
    ) : (
        <BaseButton
            label={currentState.label}
            endIcon={currentState.icon && <DynamicIcon icon={currentState.icon} />}
            onClick={(evt) => onClickHandler()}
            variant={data.variant}
            sx={(theme) => ({
                width: '100%',
                height: '100%',
                minHeight: 0,
                minWidth: 0,
                padding: theme.spacing(0.5, 1),
                fontFamily: data.fontStyle.fontFamily,
                fontSize: data.fontStyle.fontSize * fontSizeScaleFactor,
                fontWeight: data.fontStyle.fontWeight,
                // Allow the first click on the widget to select the widget and
                // set activeWidgetId and if that's set, allow interaction.
                pointerEvents: viewMode === 'design' ? (isActive ? 'auto' : 'none') : undefined,

                borderColor: data.variant === 'outlined' ? currentState.color : undefined,
                backgroundColor: data.variant === 'contained' ? currentState.color : undefined,
                color:
                    data.variant === 'contained'
                        ? // This contrast ratio number is taken from MUI doc's examples.
                          getContrastRatio(currentState.color, '#FFF') > 4.5
                            ? '#FFF'
                            : '#111'
                        : currentState.color,

                '&:hover': {
                    borderColor: data.variant === 'outlined' ? alpha(currentState.color, 0.9) : undefined,
                    backgroundColor:
                        data.variant === 'contained' ? darken(currentState.color, 0.1) : alpha(currentState.color, 0.1),
                },
                display: 'flex',
            })}
        />
    )
}

export default DynamicControlWidget
