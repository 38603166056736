import React, { useEffect, useMemo } from 'react'
import { MenuItem, Menu, Stack, Tooltip, Typography, IconButton } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import Groups3Icon from '@mui/icons-material/Groups3'
import { isEqual } from 'lodash'
import { NetworkWidgetType, ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import { convertNodeAttributeToKeyString } from 'features/report-designer/helpers/reportDesigner.helper'
import { NetworkVizContextType, useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { NodeAttributeType, NodeGroupStyleType } from '../types/NetworkViz.types'
import CloseIcon from '@mui/icons-material/Close'

interface GroupbyMenuProps {
    options: NetworkWidgetType['groupBy']
    onGroupbyChange?: (groupBy: ReportDataSourceAttributeType | null) => void
}

const convertNodeAttributeToReoportDataSourceAttributeType = (
    attribute: NodeAttributeType
): ReportDataSourceAttributeType | null => {
    if (attribute === null || attribute.field === '') {
        return null
    } else if (attribute.source === 'analytic') {
        return {
            type: 'analytic',
            field: attribute.field,
            relationship: attribute.relationship,
        }
    } else if (attribute.source === 'info') {
        return {
            type: 'basic',
            field: attribute.field,
        }
    } else {
        return null
    }
}

const GroupbyMenu: React.FC<GroupbyMenuProps> = ({ options, onGroupbyChange }) => {
    const { nodes, nodeRenders, nodeStyle, nodeDataSchema, analytics, nodeGroupBy, edgeRenders } =
        useNetworkVizContext()
    const dispatchNetworkContext = useNetworkVizDispatch()

    const [groupbyMenuAnchorEl, setGroupbyMenuAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleGroupbyScaleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setGroupbyMenuAnchorEl(event.currentTarget)
    }

    const handleGroupbyMenuClose = () => {
        setGroupbyMenuAnchorEl(null)
    }

    const applyGroupBy = (newValue: ReportDataSourceAttributeType | null) => {
        let updatedGroupby: NetworkVizContextType['nodeGroupBy'] = []

        onGroupbyChange && onGroupbyChange(newValue)

        if (newValue === null) {
            updatedGroupby = []
        } else if (newValue.type === 'analytic') {
            updatedGroupby = [
                {
                    field: newValue.field,
                    relationship: newValue.relationship,
                    source: 'analytic',
                },
            ]
        } else if (newValue.type === 'basic') {
            updatedGroupby = [
                {
                    field: newValue.field,
                    source: 'info',
                },
            ]
        } else {
            return
        }

        const worker = new WebWorker('workers/network/group-by-nodes.js')
        worker
            .run({
                nodeRenders,
                edgeRenders,
                nodes,
                groupBys: updatedGroupby,
                nodeStyle,
                analytics,
                dataSchema: nodeDataSchema.fields,
            })
            .then((res: any) => {
                const { nodeRenders, nodeStyle, nodeLegend, nodeGroupInfo } = res
                dispatchNetworkContext({
                    type: 'NODE_GROUP_BY_EDIT',
                    payload: {
                        nodeGroupBy: updatedGroupby,
                        nodeRenders,
                        nodeStyle,
                        nodeLegend,
                        nodeGroupInfo,
                    },
                })
            })
            .catch((e) => {})
            .finally(() => {})

        handleGroupbyMenuClose()
    }

    const currentGroupbyAttribute = useMemo<ReportDataSourceAttributeType | null>(() => {
        if (nodeGroupBy.length === 0) return null
        return convertNodeAttributeToReoportDataSourceAttributeType(nodeGroupBy[0])
    }, [nodeGroupBy])

    return (
        <>
            {options.enabled && options.fields.length > 0 && (
                <Stack direction={'row'} className="node-group-by">
                    <Tooltip title="Group Nodes By">
                        <BaseButton
                            color="secondary"
                            startIcon={<Groups3Icon color="secondary" />}
                            sx={{
                                maxWidth: 150,
                            }}
                            onClick={handleGroupbyScaleMenuButtonClick}
                        >
                            <Typography
                                sx={{
                                    maxWidth: 150,
                                    overflow: 'hidden',
                                    textAlign: 'start',
                                }}
                                noWrap
                                textOverflow="ellipsis"
                            >
                                {currentGroupbyAttribute === null
                                    ? 'Group nodes'
                                    : options.fields.find((x) => isEqual(x.field, currentGroupbyAttribute))?.label}
                            </Typography>
                        </BaseButton>
                    </Tooltip>
                    {currentGroupbyAttribute !== null && (
                        <Tooltip title="Reset Node Group by">
                            <IconButton
                                color="secondary"
                                onClick={() => applyGroupBy(null)}
                                sx={{
                                    minWidth: 0,
                                    width: 36,
                                    height: 36,
                                    borderRadius: '50%',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Menu
                        PopoverClasses={{
                            paper: 'node-group-by',
                        }}
                        anchorEl={groupbyMenuAnchorEl}
                        keepMounted
                        open={Boolean(groupbyMenuAnchorEl)}
                        onClose={() => handleGroupbyMenuClose()}
                    >
                        <MenuItem
                            sx={(theme) => ({
                                color: currentGroupbyAttribute === null ? 'primary.main' : 'text.primary',
                                bgcolor: currentGroupbyAttribute === null ? theme.palette.common.bg_4 : 'transparent',
                            })}
                            onClick={() => applyGroupBy(null)}
                        >
                            No Group
                        </MenuItem>
                        {options.fields.map((_item) => {
                            const isSelected = isEqual(currentGroupbyAttribute, _item.field)
                            const key = convertNodeAttributeToKeyString(_item.field)
                            return (
                                <MenuItem
                                    sx={(theme) => ({
                                        color: isSelected ? 'primary.main' : 'text.primary',
                                        bgcolor: isSelected ? theme.palette.common.bg_4 : 'transparent',
                                    })}
                                    key={key}
                                    onClick={() => applyGroupBy(_item.field)}
                                >
                                    {_item.label}
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Stack>
            )}
        </>
    )
}

export default GroupbyMenu
