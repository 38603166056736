import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SurveyContext } from 'contexts/SurveyContex'
import { EChartsOption, SeriesOption } from 'echarts'
import * as echarts from 'echarts'
import { useNavigate } from 'react-router-dom'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import { Box } from '@mui/material'
import Chart from 'features/chart/Chart'
import { ProjectContext } from 'contexts/ProjectContext'
import moment from 'moment'
import { PERMISSIONS } from 'helpers/constants'

type Props = {}

export const SurveyInstanceStatusDataMap: { [key: string]: { label: string; color: string } } = {
    awaitin_approval: {
        label: 'Awaiting Consent',
        color: '#cfcfcf',
    },
    not_started: {
        label: 'Not Started',
        color: '#cfcfcf',
    },
    invited: {
        label: 'Invited',
        color: '#BFA1DD',
    },
    in_progress: {
        label: 'Ongoing',
        color: '#59B8FF',
    },
    completed: {
        label: 'Completed',
        color: '#9FE39D',
    },
    expired: {
        label: 'expired',
        color: '#F38686',
    },
}

export function SurveyInstanceProgressBar({
    data,
}: {
    data?: {
        status: string
        count: number
    }[]
}) {
    const chartOption: EChartsCustomOptionType = useMemo(() => {
        if (data == null) return {}

        let options: EChartsCustomOptionType = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
                },
            },
            grid: {
                show: false,
                left: 10,
                top: 0,
                right: 10,
                bottom: 0,
            },
            xAxis: {
                show: false,
                type: 'value',
            },
            yAxis: {
                show: false,
                type: 'category',
                data: [''],
            },
            series: data.map((item) => ({
                name: item.status,
                label: {
                    show: true,
                    overflow: 'truncate',
                    formatter: SurveyInstanceStatusDataMap[item.status]?.label,
                },
                itemStyle: {
                    color: SurveyInstanceStatusDataMap[item.status]?.color,
                },
                type: 'bar',
                stack: 'total',
                barWidth: '24px',
                emphasis: {
                    focus: 'series',
                },
                data: [item.count],
            })),
        }

        return options
    }, [data])

    return <Chart options={chartOption} type="bar" />
}

function SurveyOverview() {
    const { survey } = useContext(SurveyContext)
    const { hasPermission } = useContext(ProjectContext)

    const canReadSurveyParticipants = useMemo(
        () => hasPermission(PERMISSIONS.survey.ReadSurveyParticipants),
        [hasPermission]
    )
    const canReadSurveySettings = useMemo(
        () => hasPermission(PERMISSIONS.survey.ReadSurveySettings),
        [hasPermission]
    )

    const canReadSurveyRules = useMemo(
        () => hasPermission(PERMISSIONS.survey.ReadSurveyRules),
        [hasPermission]
    )

    const canReadSurveyNotifications = useMemo(
        () => hasPermission(PERMISSIONS.survey.ManageSurveyNotifications),
        [hasPermission]
    )

    useEffect(() => {
        if (survey) {
        }
    }, [survey])
    const nav = useNavigate()

    if (survey === undefined) return null
    return (
        <Grid
            container
            spacing={2}
            sx={{
                padding: 2,
                borderRadius: '10px',
                backgroundColor: 'common.bg_3',
            }}
        >
            {/* Survey Instance Progress for published surveys */}
            {survey.status !== 'draft' && (
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Stack direction="row" height={36.5}>
                            <Typography fontWeight={600}>Progress</Typography>
                            <span style={{ flexGrow: 1 }} />
                            {canReadSurveyParticipants && (
                                <Button onClick={() => nav('results')}>See all</Button>
                            )}
                        </Stack>
                        <SurveyInstanceProgressBar data={survey.surveyInstaceStatus} />
                    </Paper>
                </Grid>
            )}

            {/* Schedule overview */}
            <Grid item xs={12} md={12}>
                <Paper sx={{ padding: 2 }}>
                    <Stack direction="row" height={36.5}>
                        <Typography fontWeight={600}>Schedule</Typography>
                        <span style={{ flexGrow: 1 }} />
                        {canReadSurveySettings && <Button onClick={() => nav('settings')}>Edit</Button>}
                    </Stack>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>
                                Start at:{' '}
                                {moment(survey.startDate).isValid()
                                    ? moment(survey.startDate).format('D.M.yyyy  h:mm A')
                                    : 'Not Set'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {/* Notification overview */}
            <Grid item xs={12} md={6}>
                <Paper sx={{ padding: 2 }}>
                    <Stack direction="row" height={36.5}>
                        <Typography fontWeight={600}>Notifications</Typography>
                        <span style={{ flexGrow: 1 }} />
                        {canReadSurveyNotifications && (
                            <Button onClick={() => nav('notifications')}>Edit</Button>
                        )}
                    </Stack>
                    {survey.notificationSummary && (
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row">
                                    <Typography fontWeight={500}>Auto Invitation</Typography>
                                    <Typography>
                                        : {survey.notificationSummary.invitationSummaryText}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row">
                                    <Typography fontWeight={500}>Auto Reminder</Typography>
                                    <Typography>
                                        : {survey.notificationSummary.reminderSummaryText}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Grid>

            {/* Rules overview */}
            <Grid item xs={12} md={6}>
                <Paper sx={{ padding: 2 }}>
                    <Stack direction="row" height={36.5}>
                        <Typography fontWeight={600}>Rules</Typography>
                        <span style={{ flexGrow: 1 }} />
                        {canReadSurveyRules && <Button onClick={() => nav('rules')}>Edit</Button>}
                    </Stack>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>Number of Rules: {survey.numberOfRules}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default SurveyOverview
