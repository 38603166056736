//* ======= Libraries
import React, { useEffect, useState } from 'react'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import BaseSelect from 'components/base/BaseSelect'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
//* ======= Custom logic
//* ======= Assets and styles
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'

export type FontPickerValueType = {
    fontFamily: 'Poppins' | 'Roboto' | 'Arial' | 'Times'
    fontSize: number
    color: string | undefined
    textAlign?: 'left' | 'center' | 'right'
    isBold?: boolean
    isItalic?: boolean
    isUnderlined?: boolean
}

export const defaultFontPickerValues: FontPickerValueType = {
    fontFamily: 'Poppins',
    fontSize: 16,
    color: '#000000ff',
    textAlign: 'left',
    isBold: false,
    isItalic: false,
    isUnderlined: false,
}

export const fontFamilyOptions = ['Poppins', 'Roboto', 'Arial', 'Times']
const fontSizeOptions = Array.from(Array(15))
    .map((_, idx) => ({
        name: 8 + idx * 2 + ' px',
        value: 8 + idx * 2,
    }))
    .concat([
        { name: '42 px', value: 42 },
        { name: '46 px', value: 46 },
        { name: '52 px', value: 52 },
        { name: '58 px', value: 58 },
        { name: '64 px', value: 64 },
        { name: '78 px', value: 78 },
        { name: '92 px', value: 92 },
    ])

type Props = {
    onChange: (value: FontPickerValueType) => void
    disabled?: boolean
    defaultValue?: Partial<FontPickerValueType>
    styleControls?:
        | false
        | {
              bold?: boolean
              italic?: boolean
              underlined?: boolean
              textAlign?: boolean
              color?: boolean
          }
}

function FontPicker({
    onChange,
    disabled = false,
    defaultValue = defaultFontPickerValues,
    styleControls = {
        bold: true,
        italic: true,
        underlined: true,
        textAlign: true,
        color: true,
    },
}: Props) {
    const [pickerValue, setPickerValue] = useState<FontPickerValueType>({
        ...defaultFontPickerValues,
        ...defaultValue,
    })

    const onFieldUpdate = (field: keyof FontPickerValueType, value: FontPickerValueType[keyof FontPickerValueType]) => {
        setPickerValue((prevState) => {
            return {
                ...prevState,
                [field]: value,
            }
        })

        onChange({ ...pickerValue, [field]: value })
    }

    const onFieldToggle = (field: keyof FontPickerValueType) => {
        setPickerValue((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }))

        onChange({ ...pickerValue, [field]: !pickerValue[field] })
    }

    return (
        <Stack gap={1}>
            {/* Font family
                ========================================= */}
            <BaseSelect
                options={fontFamilyOptions}
                value={pickerValue.fontFamily}
                onChangeHandler={(value: FontPickerValueType['fontFamily']) => onFieldUpdate('fontFamily', value)}
                disabled={disabled}
                placeholder="Font Family"
                isSlim
            />
            {/* Font size
                ========================================= */}
            <BaseSelect
                options={fontSizeOptions}
                value={pickerValue.fontSize}
                onChangeHandler={(value: FontPickerValueType['fontSize']) => onFieldUpdate('fontSize', value)}
                disabled={disabled}
                placeholder="Font Size"
                isSlim
                maxHeight={400}
            />
            {/* Color picker
                ========================================= */}
            {styleControls !== false && styleControls.color && (
                <ColorPicker
                    value={pickerValue.color}
                    onChange={(value) => onFieldUpdate('color', value)}
                    disabled={disabled}
                    isPopover={true}
                />
            )}
            {/* Font style and alignment
                ========================================= */}
            {styleControls !== false && Object.values(styleControls).includes(true) && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                    gap={1}
                    sx={(theme) => ({
                        minWidth: 0,
                    })}
                >
                    {/* Font style controls
                        ========================================= */}
                    {(styleControls.bold || styleControls.italic || styleControls.underlined) && (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={0.25}
                            flexGrow={1}
                        >
                            {/* Bold button
                            ========================================= */}
                            {styleControls.bold && (
                                <IconButton
                                    onClick={(evt) => onFieldToggle('isBold')}
                                    disabled={disabled}
                                    sx={(theme) => ({
                                        padding: 0.5,

                                        backgroundColor: pickerValue.isBold ? theme.palette.common.bg_5 : '',

                                        '&:hover': {
                                            backgroundColor: theme.palette.common.bg_5,
                                        },
                                    })}
                                >
                                    <FormatBoldIcon
                                        sx={{
                                            height: 22,
                                            width: 22,
                                        }}
                                    />
                                </IconButton>
                            )}

                            {/* Italic button
                            ========================================= */}
                            {styleControls.italic && (
                                <IconButton
                                    onClick={(evt) => onFieldToggle('isItalic')}
                                    disabled={disabled}
                                    sx={(theme) => ({
                                        padding: 0.5,

                                        backgroundColor: pickerValue.isItalic ? theme.palette.common.bg_5 : '',

                                        '&:hover': {
                                            backgroundColor: theme.palette.common.bg_5,
                                        },
                                    })}
                                >
                                    <FormatItalicIcon
                                        sx={{
                                            height: 22,
                                            width: 22,
                                        }}
                                    />
                                </IconButton>
                            )}

                            {/* Underline button
                            ========================================= */}
                            {styleControls.underlined && (
                                <IconButton
                                    onClick={(evt) => onFieldToggle('isUnderlined')}
                                    disabled={disabled}
                                    sx={(theme) => ({
                                        padding: 0.5,

                                        backgroundColor: pickerValue.isUnderlined ? theme.palette.common.bg_5 : '',

                                        '&:hover': {
                                            backgroundColor: theme.palette.common.bg_5,
                                        },
                                    })}
                                >
                                    <FormatUnderlinedIcon
                                        sx={{
                                            height: 22,
                                            width: 22,
                                        }}
                                    />
                                </IconButton>
                            )}
                        </Stack>
                    )}

                    {/* Font alignment controls
                        ========================================= */}
                    {styleControls.textAlign && (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={0.25}
                            flexGrow={1}
                        >
                            {/* Align left button
                            ========================================= */}
                            <IconButton
                                onClick={(evt) => onFieldUpdate('textAlign', 'left')}
                                disabled={disabled}
                                sx={(theme) => ({
                                    padding: 0.5,

                                    backgroundColor:
                                        pickerValue.textAlign === 'left' ? theme.palette.common.bg_5 : undefined,

                                    '&:hover': {
                                        backgroundColor: theme.palette.common.bg_5,
                                    },
                                })}
                            >
                                <FormatAlignLeftIcon
                                    sx={{
                                        height: 22,
                                        width: 22,
                                    }}
                                />
                            </IconButton>

                            {/* Align center button
                            ========================================= */}
                            <IconButton
                                onClick={(evt) => onFieldUpdate('textAlign', 'center')}
                                disabled={disabled}
                                sx={(theme) => ({
                                    padding: 0.5,

                                    backgroundColor:
                                        pickerValue.textAlign === 'center' ? theme.palette.common.bg_5 : undefined,

                                    '&:hover': {
                                        backgroundColor: theme.palette.common.bg_5,
                                    },
                                })}
                            >
                                <FormatAlignCenterIcon
                                    sx={{
                                        height: 22,
                                        width: 22,
                                    }}
                                />
                            </IconButton>

                            {/* Align right button
                            ========================================= */}
                            <IconButton
                                onClick={(evt) => onFieldUpdate('textAlign', 'right')}
                                disabled={disabled}
                                sx={(theme) => ({
                                    padding: 0.5,

                                    backgroundColor:
                                        pickerValue.textAlign === 'right' ? theme.palette.common.bg_5 : undefined,

                                    '&:hover': {
                                        backgroundColor: theme.palette.common.bg_5,
                                    },
                                })}
                            >
                                <FormatAlignRightIcon
                                    sx={{
                                        height: 22,
                                        width: 22,
                                    }}
                                />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default FontPicker
