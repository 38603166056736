//* ======= Libraries
import React, { ReactNode } from 'react'
//* ======= Components and features
import Button, { ButtonProps } from '@mui/material/Button'
import { LinearProgress } from '@mui/material'

interface BaseButtonProps extends ButtonProps {
    label?: string
    width?: string
    children?: ReactNode
    isLoading?: boolean
}

/* *
 * Notes:
 *  1.  Using 'children' props prevents 'label' from rendering.
 *      Also, consider removing 'startIcon/endIcon' props when passing direct children.
 *  2.  When using both 'width' and 'sx' props, the 'sx' will override the width, so
 *      you should move your width definition inside the sx.
 */

const BaseButton = React.forwardRef(
    (
        { label = '', color = 'primary', width, children, isLoading, ...buttonProps }: BaseButtonProps,
        ref: React.ForwardedRef<HTMLButtonElement>
    ) => {
        const renderLoadingIndicator = () => {
            if (isLoading) {
                return <LinearProgress sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} />
            }
            return null
        }

        return (
            <Button
                ref={ref}
                color={color}
                sx={{ width: width }}
                {...buttonProps}
                disabled={isLoading || buttonProps.disabled}
            >
                {children || label}
                {renderLoadingIndicator()}
            </Button>
        )
    }
)

export default BaseButton
