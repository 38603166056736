import { NavigationDrawerType } from 'types/NavigationTypes'
import ListAltIcon from '@mui/icons-material/ListAlt'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'
import HubIcon from '@mui/icons-material/Hub'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import Diversity2Icon from '@mui/icons-material/Diversity2'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { ProjectRoleWithPermissionType } from 'services/ProjectService'
import { PERMISSIONS } from 'helpers/constants'

const ProjectDrawerNavigation = (
    pid: number,
    title: string,
    role: ProjectRoleWithPermissionType
): NavigationDrawerType => {
    const content: NavigationDrawerType = {
        avatar: 'P',
        role: role.roleName,
        title,
        sections: [
            [
                {
                    title: 'Surveys',
                    link: `/project/${pid}/surveys`,
                    icon: <ListAltIcon color="inherit" />,
                    permissions: [PERMISSIONS.survey.ReadSurveysList],
                },
                {
                    title: 'Nodes',
                    link: `/project/${pid}/nodes`,
                    icon: <BubbleChartIcon color="inherit" />,
                    permissions: [PERMISSIONS.node.ReadNodeList],
                },
                {
                    title: 'Actions',
                    link: `/project/${pid}/actions`,
                    icon: <NotificationsActiveIcon color="inherit" />,
                    permissions: [PERMISSIONS.nodeAction.ManageNodeAction],
                },
                {
                    title: 'Networks',
                    link: `/project/${pid}/networks`,
                    icon: <HubIcon color="inherit" />,
                    permissions: [PERMISSIONS.widget.ReadWidgetList],
                },
                {
                    title: 'Reports',
                    link: `/project/${pid}/reports`,
                    icon: <AutoGraphIcon color="inherit" />,
                    permissions: [PERMISSIONS.report.ReadReportList],
                },
                {
                    title: 'Allocations',
                    link: `/project/${pid}/allocations`,
                    icon: <Diversity2Icon color="inherit" />,
                    permissions: [PERMISSIONS.allocation.ManageGroupAllocation],
                },
            ],
            [
                {
                    title: 'User management',
                    link: `/project/${pid}/users`,
                    icon: <GroupAddIcon color="inherit" />,
                    permissions: [PERMISSIONS.project.ManageProjectUsers],
                },
            ],
        ],
        quickAccess: true,
        settings: {
            title: 'Settings',
            link: `/project/${pid}/settings`,
            permissions: [PERMISSIONS.project.UpdateProjectSettings],
        },
    }

    // filter sections based on role
    for (let idx in content.sections) {
        content.sections[idx] = content.sections[idx].filter(
            (x) =>
                x.permissions === undefined || x.permissions.some((item) => role.projectRolePermissions.includes(item))
        )
    }

    content.sections = content.sections.filter((x) => x.length > 0)

    // remove settings if role not match
    if (
        content.settings?.permissions &&
        !content.settings.permissions.some((item) => role.projectRolePermissions.includes(item))
    )
        delete content.settings
    return content
}

export default ProjectDrawerNavigation
