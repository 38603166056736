//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Checkbox from '@mui/material/Checkbox'
import BaseButton from 'components/base/BaseButton'
import LiteTemplateCard from 'components/lite-template-card/LiteTemplateCard'
//* ======= Assets and styles

interface ISurveryImportDialog {
    onConfirm?: () => void
    onClose?: () => void
}

function SurveryImportDialog({ onConfirm, onClose }: ISurveryImportDialog) {
    const tempTemplateList = [
        {
            name: 'One',
            badge: 2,
            image: null,
            data: null,
        },
        {
            name: 'Two',
            badge: 4,
            image: null,
            data: null,
        },
        {
            name: 'Three',
            badge: 3,
            image: null,
            data: null,
        },
        {
            name: 'Four',
            badge: 5,
            image: null,
            data: null,
        },
        {
            name: 'Five',
            badge: 1,
            image: null,
            data: null,
        },
        {
            name: 'Six',
            badge: 2,
            image: null,
            data: null,
        },
    ]

    return (
        <Stack
            gap={2}
            py={2}
            px={3}
            minHeight={260}
            sx={(theme) => ({
                // @Theme conditional
                backgroundColor:
                    theme.palette.mode === 'light' ? theme.palette.common.bg_2 : theme.palette.common.bg_3,
            })}
        >
            {/* Title and tooltip
				========================================= */}
            <Stack direction="row" alignItems="center" gap={1}>
                <Typography fontSize={20} fontWeight={600} color="common.text_1">
                    Import Slides
                </Typography>

                {/* TODO: Tooltip goes here. */}
            </Stack>

            {/* Import radios
				========================================= */}
            <FormControl>
                {/* <FormLabel id="survey-import-radio-label">Import type:</FormLabel> */}
                <RadioGroup
                    row
                    aria-labelledby="survey-import-radio-label"
                    defaultValue="all"
                    name="survey-import-radio"
                >
                    <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="Import all slides"
                        sx={{
                            marginRight: 7,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                                fontWeight: 400,

                                color: 'common.text_1',
                            },
                        }}
                    />
                    <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="Customize import slides"
                        sx={{
                            marginRight: 0,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                                fontWeight: 400,

                                color: 'common.text_1',
                            },
                        }}
                    />
                </RadioGroup>
            </FormControl>

            {/* Slides list
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                sx={{
                    marginBottom: 3,
                    paddingBottom: 1,
                    overflowX: 'auto',
                }}
                className="u-scrollbar"
            >
                {tempTemplateList.map((_template, idx) => (
                    <LiteTemplateCard
                        key={idx}
                        name={_template.name}
                        topRightComponent={<Checkbox disableRipple />}
                        image={_template.image}
                        // TODO: Add the same onClick event here as Checkbox onChecked.
                    />
                ))}
            </Stack>

            {/* Footer buttons
				========================================= */}
            <Stack marginTop="auto" direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                <BaseButton
                    variant="outlined"
                    size="large"
                    label="Close"
                    onClick={(e) => onClose && onClose()}
                    sx={{
                        width: 186,
                        py: 1.5,
                    }}
                />

                <BaseButton
                    variant="contained"
                    size="large"
                    label="Next"
                    onClick={(e) => onConfirm && onConfirm()}
                    sx={{
                        width: 186,
                        py: 1.5,
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default SurveryImportDialog
