//* ======= Libraries
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import PasswordField from 'components/PasswordField/PasswordField'
import StyledDialog from 'components/dialog/StyledDialog'
import SignInLayout from 'pages/sign-in/SignInLayout'
import LoginOptions from 'features/sign-in/LoginOptions'
import PrivacyPolicyDialog from 'features/sign-in/PrivacyPolicyDialog'
import TermsOfServiceDialog from 'features/sign-in/TermsOfServiceDialog'
//* ======= Custom logic
//* ======= Assets and styles
import { EMAIL_REGEX, PASSWORD_REGEX } from 'helpers/validators'
import {
    CreateAccountRequestType,
    CreateAccountService,
    ValidateInviteService,
    ValidateInviteType,
} from 'services/AuthenticationService'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { RootContext } from 'contexts/RootContext'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { CircularProgress, Link } from '@mui/material'

interface ICreateAccount {}

function CreateAccount({}: ICreateAccount) {
    return (
        <SignInLayout>
            {/* Main content
				========================================= */}
            <Stack gridRow={2} width="40%">
                <CreateAccountCredentialsForm />
            </Stack>
        </SignInLayout>
    )
}

/*	Credentials form
    ========================================= */

interface ICreateAccountCredentialsForm {}

function CreateAccountCredentialsForm({}: ICreateAccountCredentialsForm) {
    const [searchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    const { getUser } = useContext(RootContext)

    const {
        control,
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateAccountRequestType>({
        mode: 'onBlur',
    })

    const onValid = (data: CreateAccountRequestType) => {
        setIsLoading(true)
        if (token)
            CreateAccountService(token, data)
                .then((res) => {
                    res.success &&
                        getUser((user) => {
                            if (!user.isEmailVerified) {
                                navigate('/verify-email')
                            } else if (!user.isProfileCompeleted) {
                                navigate('/complete-profile')
                            } else {
                                navigate('/')
                            }
                        })
                    setIsLoading(false) // Set loading to false once done
                })
                .catch((e) => {
                    setIsLoading(false) // Set loading to false in case of error
                })
    }

    const onInvalid = (e: FieldErrors<CreateAccountRequestType>) => {
        console.log(e)
    }

    const [terms, setTerms] = useState(false)
    const [marketing, setMarketing] = useState(false)

    const [inviteInfo, setInviteInfo] = useState<ValidateInviteType | null>()

    const token = useMemo(() => searchParams.get('invite_token'), [searchParams])

    const fetchInviteInfo = async () => {
        try {
            if (token) {
                var res = await ValidateInviteService(token)
                if (res.success) {
                    setInviteInfo(res.data)
                    return
                }
            }
        } catch {}
        setInviteInfo(null)
    }

    useEffect(() => {
        fetchInviteInfo()
    }, [])

    useEffect(() => {
        if (inviteInfo) {
            setValue('email', inviteInfo.email)
        }
    }, [inviteInfo])

    const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false)
    const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)

    return inviteInfo === undefined ? (
        <SnaCircularLoading size={24} horizontal />
    ) : inviteInfo === null ? (
        <>
            {token == null ? (
                <>
                    <Typography fontSize={16} fontWeight={400} color="text.primary" sx={{ marginBottom: 3 }}>
                        Currently, joining the SNA platform is by invitation only. If you're interested in creating an
                        account, please{' '}
                        <Link
                            href="https://www.snatoolbox.com/contact-us"
                            sx={{
                                textDecoration: 'underline',
                                ':hover': {
                                    textDecoration: 'none',
                                    color: 'primary.main',
                                },
                            }}
                        >
                            Contact Us
                        </Link>
                        .
                    </Typography>
                </>
            ) : (
                <>
                    <Typography
                        fontSize={16}
                        fontWeight={400}
                        color="error.main" // or you could use a custom color
                        sx={{ marginBottom: 3 }}
                    >
                        Your invite token is either invalid or has expired. If you believe this is an error, please{' '}
                        <Link
                            href="https://www.snatoolbox.com/contact-us"
                            sx={{
                                textDecoration: 'underline',
                                ':hover': {
                                    textDecoration: 'none',
                                    color: 'primary.main',
                                },
                            }}
                        >
                            Contact Us
                        </Link>{' '}
                        for further assistance.
                    </Typography>
                </>
            )}
        </>
    ) : (
        <form onSubmit={handleSubmit(onValid, onInvalid)}>
            {/* Title
				========================================= */}
            <Typography fontSize={30} fontWeight={400} color="common.text_1" mb={3}>
                Create Your SNA Account
            </Typography>

            {inviteInfo.invitedBy ? (
                <Typography fontSize={14} fontWeight={200} color="common.text_1" mb={3}>
                    To join <b>{inviteInfo.invitedBy}</b> in <b>{inviteInfo.projectTitle}</b>, you need to create an
                    account
                </Typography>
            ) : (
                <Typography fontSize={14} fontWeight={200} color="common.text_1" mb={3}>
                    To join SNA Toolbox, you need to create an account
                </Typography>
            )}

            {/* Email
				========================================= */}
            <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <BaseFilledTextField
                        label="Email"
                        type="email"
                        autoComplete="email" // Add this line
                        required
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={token != null}
                        error={!!error}
                        sx={{
                            marginBottom: 3,
                        }}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{
                    required: 'Email is required',
                    pattern: {
                        message: 'Not a valid email address',
                        value: EMAIL_REGEX,
                    },
                }}
            />

            {/* Username
				========================================= */}
            <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <BaseFilledTextField
                        label="Username"
                        type="text"
                        required
                        autoComplete="username"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                        error={!!error}
                        sx={{
                            marginBottom: 3,
                        }}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{
                    required: 'Username is required',
                    minLength: {
                        message: 'Username must be at least 6 charecters',
                        value: 6,
                    },
                    pattern: {
                        message: 'Not a valid username',
                        value: /^(?=[a-zA-Z][A-Za-z0-9._]{5,19}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
                    },
                }}
            />

            {/* Password
				========================================= */}
            <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <PasswordField
                        label="Password"
                        hasComplexityBar
                        autoComplete="new-password"
                        required
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        sx={{
                            marginBottom: 1,
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{
                    required: 'Password is required',
                    minLength: {
                        message: 'Password must be at least 8 charecters',
                        value: 8,
                    },
                    pattern: {
                        message:
                            'Password must be at least 8 characters  (no spaces), and include at least one uppercase letter, one lowercase letter, one number, and one special character (e.g., @$!%*#?&-+_=[]{}()^~.,).',
                        value: PASSWORD_REGEX,
                    },
                }}
            />

            {/* Terms and policy agreement
				========================================= */}
            <FormGroup>
                <FormControlLabel
                    label={
                        <Stack direction="row" alignItems="center" gap={0.5}>
                            <Typography fontSize={13} fontWeight={400}>
                                I agree to SNA Toolbox
                            </Typography>

                            {/* Terms of use dialog button
								========================================= */}
                            <BaseButton
                                label="Terms of Use"
                                onClick={(evt) => setIsTermsOfServiceDialogOpen(true)}
                                sx={{
                                    padding: 0.25,
                                }}
                            />

                            <Typography fontSize={13} fontWeight={400}>
                                and
                            </Typography>

                            {/* Privacy policy dialog button
								========================================= */}
                            <BaseButton
                                label="Privacy Policy"
                                onClick={(evt) => setIsPrivacyPolicyDialogOpen(true)}
                                sx={{
                                    padding: 0.25,
                                }}
                            />
                        </Stack>
                    }
                    control={<Checkbox />}
                    checked={terms}
                    onChange={() => setTerms((t) => !t)}
                    sx={{
                        marginRight: 0,
                    }}
                />
            </FormGroup>

            {/* News and updates checkbox
				========================================= */}
            <FormGroup
                sx={{
                    marginBottom: 3,
                }}
            >
                <FormControlLabel
                    label="I agree to receive SNA Toolbox news and updates."
                    control={<Checkbox />}
                    checked={marketing}
                    onChange={() => setMarketing((t) => !t)}
                    sx={{
                        marginRight: 0,

                        '& .MuiFormControlLabel-label': {
                            fontSize: 13,
                            fontWeight: 400,

                            color: 'common.text_1',
                        },
                    }}
                />
            </FormGroup>

            {/* Next button
				========================================= */}
            <BaseButton
                label="Create Account"
                type="submit"
                disabled={
                    !terms ||
                    errors?.email != null ||
                    errors?.password != null ||
                    errors?.username != null ||
                    watch('email') === '' ||
                    watch('password') === '' ||
                    watch('username') === ''
                }
                isLoading={isLoading}
                variant="contained"
                sx={{
                    marginBottom: 3,
                    py: 2,
                }}
            />

            {false && token == null && (
                <React.Fragment>
                    <Typography fontSize={16} fontWeight={400} color="common.text_1" mb={2}>
                        Or sign up with:
                    </Typography>

                    {/* Other sign up options
				========================================= */}
                    <Box
                        sx={{
                            marginBottom: 3,
                        }}
                    >
                        <LoginOptions />
                    </Box>

                    <Divider
                        sx={{
                            marginBottom: 1,

                            borderColor: 'common.border_1',
                        }}
                    />

                    {/*	Sign in link
				========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                        <Typography fontSize={16} fontWeight={400} color="common.text_1">
                            I already have an account
                        </Typography>

                        <BaseButton
                            label="Sign In"
                            onClick={(evt) => navigate('/login')}
                            sx={{
                                fontSize: 24,
                                fontWeight: 700,
                            }}
                        />
                    </Stack>
                </React.Fragment>
            )}

            {/* Dialogs
				========================================= */}
            {(isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen) && (
                <StyledDialog
                    open={isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen}
                    onClose={() =>
                        isPrivacyPolicyDialogOpen
                            ? setIsPrivacyPolicyDialogOpen(false)
                            : setIsTermsOfServiceDialogOpen(false)
                    }
                    fullWidth
                    maxWidth="lg"
                >
                    {isPrivacyPolicyDialogOpen ? (
                        <PrivacyPolicyDialog onClose={() => setIsPrivacyPolicyDialogOpen(false)} />
                    ) : isTermsOfServiceDialogOpen ? (
                        <TermsOfServiceDialog onClose={() => setIsTermsOfServiceDialogOpen(false)} />
                    ) : (
                        false
                    )}
                </StyledDialog>
            )}
        </form>
    )
}

/*	Profile form
    ========================================= */

export default CreateAccount
