import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AccountType, GetUser, Login, Logout, LoginRequestType } from '../services/AuthenticationService'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { NavigationPathType } from 'types/NavigationTypes'
import { applySurveyTheme } from 'theme/mui-theme'
import { FavItemType, GetFavouriteItems } from 'services/UserService'
import { GetProjectPermissionsService, ProjectRoleWithPermissionType } from 'services/ProjectService'
import * as Sentry from '@sentry/react'
import { PUBLIC_PATHS } from 'pages/MasterLayout'
import { PartialDeep } from 'type-fest'
import { merge } from 'lodash'

interface RootContextTypes {
    user: AccountType | null
    path: NavigationPathType[]
    setPath: Dispatch<SetStateAction<NavigationPathType[]>>
    onLogin: (request: LoginRequestType) => void
    onLogout: () => void
    getUser: (callback?: (user: AccountType) => void) => void
    permissions: ProjectRoleWithPermissionType[]
    theme: 'dark' | 'light'
    changeTheme: (t: 'dark' | 'light') => void
    favs: FavItemType[] | null
    setFavs: React.Dispatch<React.SetStateAction<FavItemType[] | null>>
    updateUser: (user: PartialDeep<AccountType>) => void
}

const RootContext = createContext<RootContextTypes>({
    user: null,
    path: [],
    permissions: [],
    setPath: () => {},
    favs: null,
    setFavs: () => {},
    onLogin: (request: LoginRequestType) => {},
    onLogout: () => {},
    getUser: () => {},
    updateUser: (user: PartialDeep<AccountType>) => {},
    theme: 'light',
    changeTheme: (t: 'dark' | 'light') => {},
})
export { RootContext }

interface RootContextProviderTypes {
    children: React.ReactNode
}

export default function RootContextProvider({ children }: RootContextProviderTypes) {
    let navigate = useNavigate()
    let location = useLocation()
    const [searchParams] = useSearchParams()

    // * auth and user management
    const [user, _setUser] = useState<AccountType | null>(null)

    const [authenticated, setAuthenticated] = useState<boolean | null>(null)
    const [permissions, setPermissions] = useState<ProjectRoleWithPermissionType[]>([])

    const setUser = (user: AccountType | null) => {
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: user?.primaryKey.toString(),
                email: user?.email,
            })
        })
        _setUser(user)
        if (user == null) {
            setAuthenticated(false)
            navigate(`/login?redirect=${location.pathname}`)
        } else {
            fetchPermissions()
            setAuthenticated(true)
            if (!user.isEmailVerified) {
                navigate('/verify-email')
            } else if (!user.isProfileCompeleted) {
                navigate('/complete-profile')
            }
        }
    }

    const updateUser = (user: PartialDeep<AccountType>) => {
        if (user) {
            _setUser((oldUser) => {
                if (oldUser) {
                    return merge(oldUser, user)
                }
                return null
            })
        }
    }

    const fetchPermissions = () => {
        GetProjectPermissionsService()
            .then((result) => {
                if (result.success) {
                    setPermissions(result.data)
                }
            })
            .catch((e) => {})
    }

    const getUser = (callback?: (user: AccountType) => void) => {
        GetUser(false)
            .then((result) => {
                if (result.success) {
                    setUser(result.data)
                    if (callback) callback(result.data)
                } else {
                    setUser(null)
                }
            })
            .catch((e) => setUser(null))
    }

    const onLogin = (request: LoginRequestType) => {
        Login(request).then((result) => {
            if (result.success) getUser(() => navigate(searchParams.get('redirect') || 'dashboard'))
        })
    }

    const onLogout = () => {
        Logout().then((result) => {
            if (result.success) {
                setUser(null)
            }
        })
    }

    const [path, setPath] = useState<NavigationPathType[]>([{ title: 'All Projects', link: '/' }])

    const [theme, setTheme] = useState<'dark' | 'light'>(localStorage.getItem('theme') === 'dark' ? 'dark' : 'light')

    const changeTheme = (theme: 'dark' | 'light') => {
        localStorage.setItem('theme', theme)
        applySurveyTheme(theme)
        setTheme(theme)
    }

    useEffect(() => {
        const style = document.createElement('style')
        style.id = 'survey-desigener-style'
        document.head.appendChild(style)
        const theme = localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
        applySurveyTheme(theme)
    }, [])

    useEffect(() => {
        const isPublicPath = PUBLIC_PATHS.some((pattern) => pattern.test(location.pathname))
        if (!isPublicPath) {
            getUser()
        }
    }, [])

    //Fav Items
    const [favs, setFavs] = useState<FavItemType[] | null>(null)

    useEffect(() => {
        authenticated && GetFavouriteItems().then((res) => res.success && setFavs(res.data))
    }, [authenticated])

    return (
        <RootContext.Provider
            value={{
                permissions,
                favs,
                setFavs,
                user,
                onLogin,
                onLogout,
                getUser,
                path,
                setPath,
                theme,
                changeTheme,
                updateUser,
            }}
        >
            {children}
        </RootContext.Provider>
    )
}
