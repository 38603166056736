import { parseUrl, UserApis as Apis } from './Apis'
import { DashboardLayoutSettingsType } from './AuthenticationService'
import { BaseResponseType, request } from './request'

export type FavItemType = {
    title: string
    projectId: number
    surveyId?: number
    reportId?: number
    widgetId?: number
    thumbnail?: string
    type: 'project' | 'survey' | 'report' | 'widget'
}

export type GetFavouriteItemsResponseType = {
    success: boolean
    data: FavItemType[]
}

export const GetFavouriteItems: (showMessage?: boolean) => Promise<GetFavouriteItemsResponseType> = async (
    showMessage = false
) => {
    return request(Apis.GetFavs, 'GET', undefined, undefined, showMessage)
}

export type UpdateDashboardLayoutSettingsRequestType = {
    layoutSettings: DashboardLayoutSettingsType
    dashboardType: 'Project' | 'Survey' | 'Report' | 'Widget'
}

export const UpdateDashboardLayoutSettings: (
    settings: UpdateDashboardLayoutSettingsRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (settings, showMessage = false) => {
    return request(Apis.UpdateDashboardLayoutSettings, 'PUT', undefined, settings, showMessage)
}
