import { NetworkAnalyticsType } from 'features/GraphStudio/types'
import { CreatorType } from 'types/resources'
import { parseUrl, WidgetApis as Apis } from './Apis'
import { SearchResponseType, TagType } from './ProjectService'
import { BaseResponseType, request } from './request'
import { ReportDataSourceRowType } from 'features/report-designer/types/reportDesigner.types'

export type WidgetType = {
    widgetId?: number
    primaryKey?: number
    projectID?: number
    state: any
    title: string
    thumbnail?: string
    type: 'network' | 'chart'
    tags?: TagType[]
    isTemplate?: boolean
    createdOn: Date
    createdBy: CreatorType
}

export type GetWidgetsResponse = {
    success: boolean
    data: Array<WidgetType>
}

export const GetWidgets: (pid: string | number, showMessage?: boolean) => Promise<GetWidgetsResponse> = async (
    pid,
    showMessage = false
) => {
    return request(parseUrl(Apis.GetWidgets, { pid }), 'GET', undefined, undefined, showMessage)
}

export type GetWidgetResponse = {
    success: boolean
    data: WidgetType
}

export const GetWidget: (
    pid: string | number,
    wid: string | number,
    showMessage?: boolean
) => Promise<GetWidgetResponse> = async (pid, wid, showMessage = false) => {
    return request(parseUrl(Apis.GetWidgetByID, { pid, wid }), 'GET', undefined, undefined, showMessage)
}

export type UpdateWidgetType = {
    primaryKey: number
    state: any
    thumbnail: string
}

export const CreateWidget: (
    pid: string | number,
    widget: WidgetType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, widget, showMessage = false) => {
    return request(parseUrl(Apis.CreatWidget, { pid }), 'POST', undefined, widget, showMessage)
}

export const UpdateWidget: (
    pid: string | number,
    widget: UpdateWidgetType,
    showMessage?: boolean
) => Promise<GetWidgetResponse> = async (pid, widget, showMessage = false) => {
    return request(parseUrl(Apis.UpdateWidget, { pid }), 'PUT', undefined, widget, showMessage)
}

export const DeleteWidget: (
    pid: string | number,
    wid: string | number,
    force: boolean,
    showMessage?: boolean
) => Promise<GetWidgetResponse> = async (pid, wid, force, showMessage = false) => {
    return request(parseUrl(Apis.DeleteWidget, { pid, wid, force }), 'DELETE', undefined, undefined, showMessage)
}

export type CreateWidgetTagResponseType = {
    success: boolean
    data: {
        primaryKey: number
    }
}

export const CreateWidgetTag: (
    pid: number | string,
    wid: number | string,
    tag: TagType,
    showMessage?: boolean
) => Promise<CreateWidgetTagResponseType> = async (pid, wid, tag, showMessage = false) => {
    return request(parseUrl(Apis.AddTag, { pid, wid }), 'POST', undefined, tag, showMessage)
}

export const RemoveWidgetTag: (
    pid: number | string,
    wid: number | string,
    tid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, wid, tid, showMessage = false) => {
    return request(parseUrl(Apis.RemoveTag, { pid, tid, wid }), 'DELETE', undefined, undefined, showMessage)
}

export type UpdateWidgetAnalyticsResponseType = {
    success: boolean
    data: string
}

export const UpdateWidgetAnalytics: (
    pid: string | number,
    wid: string | number,
    widget: WidgetType,
    showMessage?: boolean
) => Promise<UpdateWidgetAnalyticsResponseType> = async (pid, wid, widget, showMessage = false) => {
    return request(parseUrl(Apis.UpdateWidgetAnalytics, { pid, wid }), 'PUT', undefined, widget, showMessage)
}

export const SearchWidgetsService: (
    pid: number | string,
    term: string,
    showMessage?: boolean
) => Promise<SearchResponseType> = async (pid, term, showMessage = false) => {
    return request(parseUrl(Apis.Search, { term, pid }), 'GET', undefined, undefined, showMessage)
}

export const UpdateWidgetTitleService: (
    pid: string | number,
    wid: string | number,
    title: { title: string },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, wid, title, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateTitle, { pid, wid }),
        'PUT',
        { 'Content-Type': 'application/json' },
        title,
        showMessage
    )
}

type GetIssueCorrelationRequestType = {
    data: ReportDataSourceRowType[]
    attributes: {
        mode: 'continuous' | 'categorical'
        attribute: string
    }[]
}

export type IssueCorrelationType = {
    id: number
    attribute: string
    correlation: number
    effect_size: number
    effect_class: 'low' | 'medium' | 'high'
    effect_size_type: string
    p_value: number
}

type GetIssueCorrelationResponseType = {
    success: boolean
    data: {
        correlations: IssueCorrelationType[]
    }
}

export const GetIssueCorrelationService: (
    data: GetIssueCorrelationRequestType,
    showMessage?: boolean
) => Promise<GetIssueCorrelationResponseType> = async (data, showMessage = false) => {
    return request(Apis.GetIssueCorrelation, 'POST', undefined, data, showMessage)
}
