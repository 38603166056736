import { Box, Stack, DialogTitle, Chip, useTheme, Paper, IconButton, Divider, Typography, Tooltip } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import { NetworkAnalyticsType } from 'features/network-viz/types/NetworkViz.types'
import {
    checkRowMatchesFilters,
    convertNodeAttributeToKeyString,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import {
    InisghtInterventionTableColumnType,
    InsightCachedType,
    InsightWidgetType,
    ReportFilterCompareType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { alpha } from '@mui/material'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CustomTreeItem from 'components/CustomTreeItem/CustomTreeItem'
import InsightCategorySummaryPanel from './components/InsightCategorySummaryPanel/InsightCategorySummaryPanel'
import InsightDetailsPanel from './components/InsightDetailsPanel/InsightDetailsPanel'
import InsightSummaryPanel from './components/InsightSummaryPanel/InsightSummaryPanel'
import AddIcon from '@mui/icons-material/Add'
import BackIcon from '@mui/icons-material/ArrowBack'
import Slide from '@mui/material/Slide'
import AdvancedInsightFilterPanel from './components/widgets/filter/AdvancedInsightFilterPanel'
import { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import DifferenceIcon from '@mui/icons-material/Difference'
import AdvancedInsightFilterConfigDialog from './components/widgets/filter/AdvancedInsightFilterConfigDialog'
import InsightComparePanel from './components/InisightComparePanel/InsightComparePanel'
type InsightWidgetInterventionViewProps = {
    widgetId: ReportWidgetType['id']
    insightWidget: InsightWidgetType
    isActive: boolean
    fontSize: number
    fontScaleFactor: number
}

type InsightWidgetInterventionViewData = InsightCachedType & {
    nodes: {
        id: string
        severity: 'low' | 'medium' | 'high'
        [key: string]: any
    }[]
}

function EndIcon() {
    return <div style={{ width: 24 }} />
}

export type InsightItemType = Record<
    string,
    {
        title: string
        icon?: string
        color?: string
        numberOfIssues: {
            negative: {
                high: number
                medium: number
                low: number
            }
            positive: {
                high: number
                medium: number
                low: number
            }
            neutral: {
                high: number
                medium: number
                low: number
            }
        }
        issues: Record<string, InsightWidgetInterventionViewData>
    }
>

export type NodeDictonaryType = Record<
    string,
    {
        id: string
        title: string
        issues: Array<{
            text: string
            icon?: string
            category: string
        }>
    }
>

const getUrgency = (urgency: number | undefined) => {
    if (urgency === undefined) return 'medium'
    return urgency < 33 ? 'low' : urgency < 66 ? 'medium' : 'high'
}

const InsightWidgetInterventionAdvancedView = ({
    widgetId,
    insightWidget,
    isActive,
    fontSize,
    fontScaleFactor,
}: InsightWidgetInterventionViewProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const mainBoxRef = useRef<HTMLDivElement>(null)

    const [showFilterConfigDialog, setShowFilterConfigDialog] = useState<boolean>(false)
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
    const [filter, setFilter] = useState<ReportFilterCompareType[]>([])

    const filterSummary = useMemo(() => {
        const operatorDic: Record<ReportFilterCompareType['operator'], string> = {
            eq: '=',
            neq: '!=',
            gt: '>',
            gte: '>=',
            lt: '<',
            lte: '<=',
            containsAny: 'in',
            notContainsAny: 'not in',
            between: 'between',
        }
        return filter
            .map((filter) => {
                const title = insightWidget.interventionConfig.filterConfig.find(
                    (x) => x.attribute === filter.field
                )?.label
                return `${title} ${operatorDic[filter.operator]} ${
                    Array.isArray(filter.value) ? `[${filter.value.join(', ')}]` : filter.value
                }`
            })
            .join(' AND ')
    }, [filter])

    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])

    const theme = useTheme()

    const { dataSources, updateInsightWidgetContent, activeSlideId, viewMode } = useReportStore((store) => ({
        dataSources: store.dataSources,
        updateInsightWidgetContent: store.updateInsightWidgetContent,
        activeSlideId: store.activeSlideId,
        viewMode: store.viewMode,
    }))

    const [activeView, setActiveView] = useState<
        | 'overview'
        | 'compare'
        | {
              category: string | null
              insightId: string | null
          }
    >('overview')

    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, insightWidget.selectedDataSource))
    }, [insightWidget.selectedDataSource])

    const [items, setItems] = useState<InsightItemType>({})
    const [nodeDictonary, setNodeDictonary] = useState<NodeDictonaryType>({})
    const [totalNodes, setTotalNodes] = useState<number>(0)

    useEffect(() => {
        if (insightWidget.cachedData) {
            const newNodeDictonary: NodeDictonaryType = {}
            const newInterventions: InsightItemType = {
                General: {
                    title: 'General',
                    numberOfIssues: {
                        negative: {
                            high: 0,
                            medium: 0,
                            low: 0,
                        },
                        positive: {
                            high: 0,
                            medium: 0,
                            low: 0,
                        },
                        neutral: {
                            high: 0,
                            medium: 0,
                            low: 0,
                        },
                    },
                    issues: {},
                },
            }

            for (let category of insightWidget.interventionConfig.categories) {
                newInterventions[category.id] = {
                    issues: {},
                    title: category.title,
                    icon: category.icon,
                    color: category.color,
                    numberOfIssues: {
                        negative: {
                            high: 0,
                            medium: 0,
                            low: 0,
                        },
                        positive: {
                            high: 0,
                            medium: 0,
                            low: 0,
                        },
                        neutral: {
                            high: 0,
                            medium: 0,
                            low: 0,
                        },
                    },
                }
            }

            let allRows: Record<string, Record<string, any>> = {}
            let analyticRows: Record<string, NetworkAnalyticsType> | null = null

            const dataSource = dataSources.find((x) => x.id === insightWidget.selectedDataSource?.id)

            if (dataSource === undefined) return
            if (dataSource.mode === 'network') {
                allRows = dataSource.networkContext.nodes
                analyticRows = dataSource.networkContext.analytics
            } else {
                if (insightWidget.idField === null) return
                const data = dataSource.data[insightWidget.selectedDataSource?.panel ?? 0]
                if (data === undefined) return
                for (let row of data) {
                    const id = row[insightWidget.idField.field]
                    if (id === undefined || id === null) continue
                    allRows[id.toString()] = row
                }
            }

            let rows: Record<string, Record<string, any>> = {}

            // filter rows
            if (filter.length > 0) {
                for (let rowId in allRows) {
                    if (checkRowMatchesFilters(allRows[rowId], filter, analyticRows)) {
                        rows[rowId] = allRows[rowId]
                    }
                }
            } else {
                rows = allRows
            }

            setTotalNodes(Object.keys(rows).length)

            const columnsDict: Record<string, InisghtInterventionTableColumnType[]> = {}

            const condition_list: (keyof InsightWidgetType['insights'][number]['message'])[] = [
                'satisfied',
                'unsatisfied',
            ]

            for (let insight of insightWidget.insights) {
                for (let condition of condition_list) {
                    columnsDict[insight.id + '-' + condition] = insight.message[condition].columns ?? []
                }
            }

            for (let nodeId in insightWidget.cachedData) {
                // skip if node is not in the rows
                if (!rows[nodeId]) continue
                const node: InsightWidgetInterventionViewData['nodes'][number] = {
                    id: nodeId,
                    severity: 'medium',
                }

                const data = insightWidget.cachedData[nodeId].data as InsightCachedType[]

                for (let item of data) {
                    const itemKey = item.insightId + '-' + item.condition
                    const nodeColumns = columnsDict[itemKey] ?? []
                    const severity = getUrgency(item.severity)
                    const tmpNode: InsightWidgetInterventionViewData['nodes'][number] = { ...node, severity }

                    if (newNodeDictonary[nodeId] === undefined) {
                        newNodeDictonary[nodeId] = {
                            id: nodeId,
                            title: insightWidget.labelField?.field
                                ? rows[nodeId][insightWidget.labelField.field]
                                : nodeId,
                            issues: [],
                        }
                    }

                    for (let column of nodeColumns) {
                        if (column.field === null) continue
                        const fieldKey = convertNodeAttributeToKeyString(column.field)
                        if (column.field.type === 'analytic') {
                            if (analyticRows === null) continue
                            tmpNode[fieldKey] =
                                analyticRows[column.field.relationship].nodes[nodeId][column.field.field]
                        } else {
                            tmpNode[fieldKey] = rows[nodeId]?.[column.field.field]
                        }
                    }
                    const category = insightWidget.insights.find((x) => x.id === item.insightId)?.category ?? 'General'

                    newNodeDictonary[nodeId].issues.push({
                        text: item.text,
                        icon: item.icon,
                        category: category,
                    })

                    if (newInterventions[category].issues[itemKey]) {
                        newInterventions[category].issues[itemKey].nodes.push(tmpNode)
                    } else {
                        newInterventions[category].issues[itemKey] = {
                            ...item,
                            nodes: [tmpNode],
                        }
                    }

                    newInterventions[category].numberOfIssues[item.mode][severity]++
                }
            }
            setNodeDictonary(newNodeDictonary)

            setItems(newInterventions)
        }
    }, [
        insightWidget.cachedData,
        insightWidget.insights,
        insightWidget.interventionConfig.categories,
        dataSources,
        filter,
    ])

    const targetDataSource = useMemo(() => {
        return dataSources.find((x) => x.id === insightWidget.selectedDataSource?.id)
    }, [dataSources, insightWidget.selectedDataSource])

    const saveCompareConfig = (config: InsightWidgetType['interventionConfig']['compareConfig']) => {
        if (activeSlideId === null) return
        updateInsightWidgetContent({
            slideId: activeSlideId,
            widgetId,
            data: {
                ...insightWidget,
                interventionConfig: {
                    ...insightWidget.interventionConfig,
                    compareConfig: config,
                },
            },
        })
    }

    return (
        <Stack
            ref={containerRef}
            sx={{ height: '100%', pb: 1, width: '100%', overflow: 'hidden' }}
            direction={'row'}
            gap={1}
        >
            {/* Main Navigation */}
            <Stack
                component={Paper}
                elevation={3}
                sx={{
                    width: '250px',
                    m: 1,
                    overflowY: 'auto',
                    flexShrink: 0,
                    overflowX: 'hidden',
                    height: '98%',
                    position: 'relative',
                }}
                className="u-scrollbar"
            >
                <Slide direction="left" in={showFilterPanel} mountOnEnter unmountOnExit>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                        {targetDataSource && (
                            <AdvancedInsightFilterPanel
                                filterConfig={insightWidget.interventionConfig.filterConfig}
                                defaultFilters={filter}
                                onFilterChange={(_filter) => {
                                    setFilter(_filter)
                                    setShowFilterPanel(false)
                                }}
                                onBack={() => setShowFilterPanel(false)}
                                selectedDataSource={insightWidget.selectedDataSource}
                                targetDataSource={targetDataSource}
                            />
                        )}
                    </Box>
                </Slide>
                <Slide direction="right" in={!showFilterPanel} mountOnEnter unmountOnExit>
                    <Stack
                        direction="column"
                        gap={1}
                        sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
                    >
                        {/* Filter */}
                        <Stack
                            p={1}
                            justifyContent="space-between"
                            sx={{ width: '100%', overflow: 'hidden', height: '50px', flexShrink: 0 }}
                            alignItems="center"
                            direction="row"
                            gap={1}
                        >
                            <Box sx={{ flexGrow: 1, maxWidth: '200px' }}>
                                {filter.length === 0 ? (
                                    <BaseButton
                                        label="Add Filter"
                                        endIcon={<AddIcon />}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setShowFilterPanel(true)}
                                        sx={{
                                            border: '1px dashed',
                                            borderRadius: 5,
                                            maxWidth: '100%',
                                        }}
                                    />
                                ) : (
                                    <Tooltip title={filterSummary}>
                                        <Chip
                                            label={filterSummary}
                                            onClick={() => setShowFilterPanel(true)}
                                            onDelete={() => setFilter([])}
                                            color="primary"
                                            size="small"
                                            sx={{
                                                maxWidth: '100%',
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Box>

                            {viewMode === 'design' && (
                                <Tooltip title="Filter Settings">
                                    <IconButton
                                        size="small"
                                        sx={{ flexShrink: 0, width: '34px' }}
                                        onClick={() => setShowFilterConfigDialog(true)}
                                    >
                                        <DynamicIcon icon="settings" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                        <Divider flexItem />
                        <SimpleTreeView
                            onSelectedItemsChange={(event, itemIds) => {
                                if (itemIds === null) return
                                if (itemIds === 'overview' || itemIds === 'compare') {
                                    setActiveView(itemIds)
                                } else if (!itemIds.startsWith('overview')) {
                                    setActiveView(JSON.parse(decodeURIComponent(itemIds)))
                                }
                                mainBoxRef.current?.scrollTo(0, 0)
                            }}
                            slots={{
                                expandIcon: ArrowRightIcon,
                                collapseIcon: ArrowDropDownIcon,
                                endIcon: EndIcon,
                            }}
                            sx={{
                                width: '240px',
                                flexShrink: 0,
                                flexGrow: 1,
                            }}
                            defaultSelectedItems={'overview'}
                        >
                            <CustomTreeItem
                                itemId={'overview'}
                                label={'Overview'}
                                labelIcon={<DynamicIcon icon={'Assessment'} />}
                            />
                            <CustomTreeItem itemId="compare" label={'Compare'} labelIcon={<DifferenceIcon />} />
                            {Object.entries(items).map(([categoryKey, category]) => {
                                if (Object.keys(category.issues).length === 0) return null
                                return (
                                    <CustomTreeItem
                                        itemId={`overview-${categoryKey}`}
                                        key={categoryKey}
                                        label={category.title}
                                        labelIcon={category.icon ? <DynamicIcon icon={category.icon} /> : undefined}
                                        labelInfo={(
                                            category.numberOfIssues.negative.high +
                                            category.numberOfIssues.negative.medium +
                                            category.numberOfIssues.negative.low
                                        ).toString()}
                                    >
                                        <CustomTreeItem
                                            itemId={encodeURIComponent(
                                                JSON.stringify({ category: categoryKey, insightId: null })
                                            )}
                                            label={'Overview'}
                                            labelIcon={<DynamicIcon icon={'Assessment'} />}
                                        />

                                        {Object.entries(category.issues).map(([insightId, insight]) => {
                                            return (
                                                <CustomTreeItem
                                                    itemId={encodeURIComponent(
                                                        JSON.stringify({ category: categoryKey, insightId })
                                                    )}
                                                    key={insightId}
                                                    label={insight.text}
                                                    labelIcon={
                                                        insight.icon ? <DynamicIcon icon={insight.icon} /> : undefined
                                                    }
                                                    color={
                                                        insight.mode === 'negative'
                                                            ? theme.palette.error.main
                                                            : insight.mode === 'positive'
                                                            ? theme.palette.success.main
                                                            : undefined
                                                    }
                                                    bgColor={
                                                        insight.mode === 'negative'
                                                            ? alpha(theme.palette.error.main, 0.1)
                                                            : insight.mode === 'positive'
                                                            ? alpha(theme.palette.success.main, 0.1)
                                                            : undefined
                                                    }
                                                    labelInfo={insight.nodes.length.toString()}
                                                ></CustomTreeItem>
                                            )
                                        })}
                                    </CustomTreeItem>
                                )
                            })}
                        </SimpleTreeView>
                    </Stack>
                </Slide>
            </Stack>
            {/* Main Content */}
            <Box sx={{ flexGrow: 1, height: '100%', overflowY: 'auto' }} className="u-scrollbar" ref={mainBoxRef}>
                {activeView === 'overview' ? (
                    <Box margin="auto">
                        <InsightSummaryPanel
                            items={items}
                            categories={insightWidget.interventionConfig.categories}
                            nodeDictionary={nodeDictonary}
                            totalNodes={totalNodes}
                            setActiveView={(value) => {
                                setActiveView(value)
                            }}
                            selectedDataSource={insightWidget.selectedDataSource}
                            viewMode={viewMode}
                            correlationConfig={insightWidget.interventionConfig.correlationConfig ?? []}
                            dataSourceFields={dataSourceFields}
                            updateCorrelationConfig={(config) => {
                                if (activeSlideId === null) return
                                updateInsightWidgetContent({
                                    slideId: activeSlideId,
                                    widgetId,
                                    data: {
                                        ...insightWidget,
                                        interventionConfig: {
                                            ...insightWidget.interventionConfig,
                                            correlationConfig: config,
                                        },
                                    },
                                })
                            }}
                        />
                    </Box>
                ) : activeView === 'compare' ? (
                    <Box margin="auto">
                        <InsightComparePanel
                            items={items}
                            categories={insightWidget.interventionConfig.categories}
                            nodeDictonary={nodeDictonary}
                            totalNodes={totalNodes}
                            setActiveView={(value) => {
                                setActiveView(value)
                            }}
                            dataSourceFields={dataSourceFields}
                            viewMode={viewMode}
                            compareConfig={insightWidget.interventionConfig.compareConfig ?? []}
                            saveCompareConfig={saveCompareConfig}
                            selectedDataSource={insightWidget.selectedDataSource}
                            idField={insightWidget.idField}
                        />
                    </Box>
                ) : activeView.category !== null && activeView.insightId === null ? (
                    <Box margin="auto">
                        <InsightCategorySummaryPanel
                            items={items[activeView.category]}
                            nodeDictionary={nodeDictonary}
                            totalNodes={totalNodes}
                            categoryId={activeView.category}
                        />
                    </Box>
                ) : activeView.category !== null && activeView.insightId !== null ? (
                    <Box margin="auto" sx={{ height: '100%' }}>
                        <InsightDetailsPanel
                            items={items[activeView.category].issues[activeView.insightId]}
                            fontSize={fontSize}
                            isActive={isActive}
                            fontScaleFactor={fontScaleFactor}
                            id={activeView.insightId}
                            widgetId={widgetId}
                            insightWidget={insightWidget}
                            back={() => setActiveView('overview')}
                        />
                    </Box>
                ) : null}
            </Box>

            {/* Out of flow */}
            {viewMode === 'design' && (
                <AdvancedInsightFilterConfigDialog
                    isOpen={showFilterConfigDialog}
                    onClose={() => setShowFilterConfigDialog(false)}
                    dataSourceFields={dataSourceFields}
                    filterConfig={insightWidget.interventionConfig.filterConfig}
                    onSave={(filterConfig) => {
                        if (activeSlideId === null) return
                        updateInsightWidgetContent({
                            slideId: activeSlideId,
                            widgetId,
                            data: {
                                ...insightWidget,
                                interventionConfig: {
                                    ...insightWidget.interventionConfig,
                                    filterConfig,
                                },
                            },
                        })
                        setShowFilterConfigDialog(false)
                    }}
                />
            )}
        </Stack>
    )
}

export default InsightWidgetInterventionAdvancedView
