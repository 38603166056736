//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Typography, Tabs, Tab, Divider } from '@mui/material'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    HorizontalLineWidgetType,
    ReportWidgetSettingsBaseType,
} from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles

const heightOptions = Array.from(Array(10)).map((_, idx) => ({
    label: idx + 1 + ' px',
    value: idx + 1,
}))

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'horizontalLine'>
    settings: Omit<ReportWidgetType, 'content'>
    details: HorizontalLineWidgetType
}

function HorizontalLineWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { activeSlideId, updateWidget, updateHorizontalLineWidgetContent } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateWidget: store.updateWidget,
        updateHorizontalLineWidgetContent: store.updateHorizontalLineWidgetContent,
    }))

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'horizontalLine') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const updateDetails = (details: PartialDeep<HorizontalLineWidgetType>) => {
        if (activeSlideId !== null && widget !== null) {
            updateHorizontalLineWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        height: '100%',
                        paddingTop: theme.spacing(0.5),
                        paddingX: theme.spacing(2),
                        overflowY: 'auto',
                        // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                        overflowX: 'hidden',
                    })}
                    className="u-scrollbar"
                >
                    {/* Heading
                        ========================================= */}
                    <Typography fontSize={16} noWrap sx={{ flexShrink: 0 }}>
                        Horizontal Line Settings
                    </Typography>

                    <Divider
                        sx={{
                            marginBottom: 1.5,
                        }}
                    />

                    {/* Container settings
                        ========================================= */}
                    <Stack gap={2}>
                        {/* Title
                            ========================================= */}
                        <BaseFilledTextField
                            label="Widget Title"
                            defaultValue={widget.settings.title}
                            onBlur={(evt) =>
                                updateWidget({
                                    slideId: activeSlideId,
                                    widgetId: widget.settings.id,
                                    data: {
                                        title: evt.target.value.trim(),
                                    },
                                })
                            }
                            size="small"
                            fullWidth
                        />
                    </Stack>

                    <Divider
                        sx={{
                            marginY: 1.5,
                        }}
                    />

                    {/* Content settings
                        ========================================= */}
                    <Stack gap={2}>
                        {/* Height
                            ========================================= */}
                        <BaseSelectWithLabel
                            label="Height"
                            options={heightOptions}
                            value={widget.details.height}
                            onChange={(value) =>
                                updateDetails({
                                    height: value,
                                })
                            }
                            maxHeight={274}
                        />

                        {/* Color
                            ========================================= */}
                        <ColorPicker
                            value={widget.details.color}
                            onChange={(newColor) =>
                                updateDetails({
                                    color: newColor,
                                })
                            }
                        />
                    </Stack>
                </Stack>
            ) : (
                false
            )}
        </>
    )
}

export default HorizontalLineWidgetSettings
