import {
    Tab,
    Tabs,
    Box,
    Stack,
    Typography,
    Card,
    CardContent,
    CardActions,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    Chip,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import StyledDialog from 'components/dialog/StyledDialog'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import { NetworkAnalyticsType } from 'features/network-viz/types/NetworkViz.types'
import {
    convertNodeAttributeToKeyString,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import {
    InisghtInterventionTableColumnType,
    InsightCachedType,
    InsightMessageType,
    InsightWidgetType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import { debounce, groupBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GetInterventionsService, InterventionType } from 'services/InterventionService'
import { alpha } from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import InfoIcon from '@mui/icons-material/Info'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Pagination } from 'swiper/modules'
import 'swiper/css/pagination'
import WarningIcon from '@mui/icons-material/Warning'
import CheckIcon from '@mui/icons-material/DoneAll'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import { GridCallbackDetails } from '@mui/x-data-grid-pro'

type InsightWidgetInterventionViewProps = {
    widgetId: ReportWidgetType['id']
    insightWidget: InsightWidgetType
    isActive: boolean
    fontSize: number
}

type InsightWidgetInterventionViewData = InsightCachedType & {
    nodes: {
        id: string
        [key: string]: any
    }[]
}

const InsightWidgetInterventionView = ({
    widgetId,
    insightWidget,
    isActive,
    fontSize,
}: InsightWidgetInterventionViewProps) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [columnWidth, setColumnWidth] = useState<number>(insightWidget.interventionConfig.columnWidth)
    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])

    const { dataSources, updateInsightWidgetContent, activeSlideId, viewMode } = useReportStore((store) => ({
        dataSources: store.dataSources,
        updateInsightWidgetContent: store.updateInsightWidgetContent,
        activeSlideId: store.activeSlideId,
        viewMode: store.viewMode,
    }))

    const [selectedTab, setSelectedTab] = useState<string | number | null>(null)
    const [items, setItems] = useState<Record<string, InsightWidgetInterventionViewData>>({})
    const [isColumnConfigOpen, setIsColumnConfigOpen] = useState(false)

    const [selectedActionTab, setSelectedActionTab] = useState<string | null>(null)

    useEffect(() => {
        if (insightWidget.cachedData) {
            const newInterventions: Record<string, InsightWidgetInterventionViewData> = {}
            let rows: Record<string, Record<string, any>> = {}
            let analyticRows: Record<string, NetworkAnalyticsType> | null = null

            const dataSource = dataSources.find((x) => x.id === insightWidget.selectedDataSource?.id)

            if (dataSource === undefined) return
            if (dataSource.mode === 'network') {
                rows = dataSource.networkContext.nodes
                analyticRows = dataSource.networkContext.analytics
            } else {
                if (insightWidget.idField === null) return
                const data = dataSource.data[insightWidget.selectedDataSource?.panel ?? 0]
                if (data === undefined) return
                for (let row of data) {
                    const id = row[insightWidget.idField.field]
                    if (id === undefined || id === null) continue
                    rows[id.toString()] = row
                }
            }

            const columnsDict: Record<string, InisghtInterventionTableColumnType[]> = {}

            const condition_list: (keyof InsightWidgetType['insights'][number]['message'])[] = [
                'satisfied',
                'unsatisfied',
            ]

            for (let insight of insightWidget.insights) {
                for (let condition of condition_list) {
                    columnsDict[insight.id + '-' + condition] = insight.message[condition].columns ?? []
                }
            }

            for (let nodeId in insightWidget.cachedData) {
                const node: InsightWidgetInterventionViewData['nodes'][number] = {
                    id: nodeId,
                }

                const data = insightWidget.cachedData[nodeId].data as InsightCachedType[]

                for (let item of data) {
                    const itemKey = item.insightId + '-' + item.condition
                    const nodeColumns = columnsDict[itemKey] ?? []
                    const tmpNode = { ...node }
                    for (let column of nodeColumns) {
                        if (column.field === null) continue
                        const fieldKey = convertNodeAttributeToKeyString(column.field)
                        if (column.field.type === 'analytic') {
                            if (analyticRows === null) continue
                            tmpNode[fieldKey] =
                                analyticRows[column.field.relationship].nodes[nodeId][column.field.field]
                        } else {
                            tmpNode[fieldKey] = rows[nodeId]?.[column.field.field]
                        }
                    }
                    if (newInterventions[itemKey]) {
                        newInterventions[itemKey].nodes.push(tmpNode)
                    } else {
                        newInterventions[itemKey] = {
                            ...item,
                            nodes: [tmpNode],
                        }
                    }
                }
            }

            setItems(newInterventions)
        }
    }, [insightWidget.cachedData, insightWidget.insights])

    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, insightWidget.selectedDataSource))
    }, [dataSources, insightWidget.selectedDataSource])

    // Function to handle the vertical divider movement
    const handleMouseDownVertical = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        document.addEventListener('mousemove', handleMouseMoveVertical)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMoveVertical = useCallback((event: any) => {
        // Calculate the new position of the divider based on the cursor's position
        if (activeSlideId && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect()
            const newDividerXPosition = ((event.clientX - containerRect.left) / containerRect.width) * 100
            const width = Math.min(Math.max(newDividerXPosition, 0), 100)
            setColumnWidth(width)
            updateColumnWidth(width)
        }
    }, [])

    const updateColumnWidth = useCallback(
        debounce((width: number) => {
            if (activeSlideId === null || widgetId === undefined) return
            updateInsightWidgetContent({
                slideId: activeSlideId,
                widgetId,
                data: {
                    interventionConfig: {
                        columnWidth: width,
                    },
                },
            })
        }, 300), // Adjust the debounce delay as needed
        [activeSlideId, widgetId]
    )

    const actionsList = useMemo(() => {
        if (selectedTab === null || items[selectedTab] === undefined) return []
        return items[selectedTab].actions
    }, [selectedTab, items])

    const groupedActionList = useMemo(() => {
        if (selectedTab === null || items[selectedTab] === undefined) return null
        const _actionList = items[selectedTab].actions
        const groupedActions = groupBy(_actionList, 'category')
        setSelectedActionTab(Object.keys(groupedActions)[0] ?? null)
        return groupedActions
    }, [selectedTab, items])

    // Cleanup function to remove event listeners
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMoveVertical)
        document.removeEventListener('mouseup', handleMouseUp)
    }

    // update the column width in the store after 300ms of inactivity
    const debouncedUpdate = useCallback(
        debounce((widgetId, activeSlideId, columnWidth) => {
            updateInsightWidgetContent({
                widgetId,
                slideId: activeSlideId,
                data: {
                    interventionConfig: {
                        columnWidth,
                    },
                },
            })
        }, 300),
        []
    )

    // Update the column width in the store when the column width changes
    useEffect(() => {
        if (activeSlideId) {
            debouncedUpdate(widgetId, activeSlideId, columnWidth)
        }
        // Return a cleanup function to cancel the debounced call if the component unmounts
        return () => {
            debouncedUpdate.cancel()
        }
    }, [columnWidth, activeSlideId, widgetId, debouncedUpdate])

    const activeInsight = useMemo(() => {
        if (selectedTab === null || items[selectedTab] === undefined) return null
        return insightWidget.insights.find((x) => x.id === items[selectedTab!].insightId) ?? null
    }, [selectedTab, insightWidget.insights, items])

    const [dataGridColumns, setDataGridColumns] = useState<GridColDef[]>([])
    const [sortModel, setSortModel] = useState<GridSortModel>()

    useEffect(() => {
        if (selectedTab === null) {
            setDataGridColumns([])
            setSortModel([])
            return
        }
        const selectedInsightMessage = activeInsight?.message[items[selectedTab].condition]

        const columns = selectedInsightMessage?.columns
        if (columns === undefined) {
            setDataGridColumns([])
            setSortModel([])
            return
        }

        setDataGridColumns(
            columns
                .filter((x) => x.field !== null)
                .map((x) => ({
                    field: convertNodeAttributeToKeyString(x.field!),
                    headerName: x.label,
                    width: x.width ?? undefined,
                }))
        )
    }, [selectedTab, activeInsight, items])

    useEffect(() => {
        if (dataGridColumns.length > 0 && selectedTab !== null) {
            const selectedInsightMessage = activeInsight?.message[items[selectedTab].condition]
            const columns = selectedInsightMessage?.columns
            if (columns === undefined) return
            const sortColumn = columns.find((x) => x.sort !== false)
            if (sortColumn && sortColumn.sort) {
                setSortModel([{ field: convertNodeAttributeToKeyString(sortColumn.field!), sort: sortColumn.sort }])
            }
        }
    }, [dataGridColumns])

    // extract the tabs from the items and sort them based on the order
    const tabs = useMemo(() => {
        const orderedTabs = Object.keys(items)
            .sort((a, b) => {
                const insightA = insightWidget.insights.find((x) => items[a].insightId === x.id)
                const insightB = insightWidget.insights.find((x) => items[b].insightId === x.id)
                const orderA = insightA?.message[items[a].condition].order ?? 0
                const orderB = insightB?.message[items[b].condition].order ?? 0
                return orderA - orderB
            })
            .map((key) => ({
                value: key,
                label: items[key].text,
                icon: items[key].icon,
            }))

        if (selectedTab === null || items[selectedTab] === undefined) setSelectedTab(orderedTabs[0]?.value ?? null)

        return orderedTabs
    }, [items, insightWidget.insights])

    const showActionDescription = useMemo(
        () => insightWidget.interventionConfig.showActions || insightWidget.interventionConfig.showDescription,
        [insightWidget.interventionConfig.showActions, insightWidget.interventionConfig.showDescription]
    )

    const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
        // on the initial load, the sort model change will call with an empty model that can override the state sort model.
        // therefore, we added a check to only update the sort model when the model is not empty.
        if (model.length !== 0) {
            setSortModel(model)
            // update the sort column in the store when the view mode is design
            if (viewMode === 'design') {
                if (widgetId !== undefined && activeSlideId != null) {
                    const sortColumn = model[0]
                    updateInsightWidgetContent({
                        widgetId,
                        slideId: activeSlideId,
                        data: {
                            insights: insightWidget.insights.map((x) =>
                                x.id === activeInsight?.id
                                    ? {
                                          ...x,
                                          message: {
                                              ...x.message,
                                              [items[selectedTab!].condition]: {
                                                  ...x.message[items[selectedTab!].condition],
                                                  columns: x.message[items[selectedTab!].condition].columns.map((y) =>
                                                      y.field &&
                                                      convertNodeAttributeToKeyString(y.field) === sortColumn.field
                                                          ? { ...y, sort: sortColumn.sort }
                                                          : { ...y, sort: false }
                                                  ),
                                              },
                                          },
                                      }
                                    : x
                            ),
                        },
                    })
                }
            }
        }
    }

    return (
        <Box
            ref={containerRef}
            sx={{ height: '100%', width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
        >
            {/* tabs */}
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={selectedTab}
                onChange={(evt, value) => setSelectedTab(value)}
                className="tabs"
                sx={{
                    flexShrink: 0,
                    width: '100%',
                }}
            >
                {tabs.map((tab) => (
                    <Tab
                        value={tab.value}
                        label={insightWidget.interventionConfig.iconOnly && tab.icon ? undefined : tab.label}
                        icon={
                            tab.icon && (
                                <DynamicIcon
                                    icon={tab.icon}
                                    sx={{ fontSize: insightWidget.interventionConfig.tabTextStyle.fontSize * 1.5 }}
                                />
                            )
                        }
                        iconPosition={insightWidget.interventionConfig.iconPosition ?? 'end'}
                        sx={{
                            fontWeight: insightWidget.interventionConfig.tabTextStyle.fontWeight,
                            fontSize: insightWidget.interventionConfig.tabTextStyle.fontSize,
                            fontFamily: insightWidget.interventionConfig.tabTextStyle.fontFamily,
                            color: insightWidget.interventionConfig.tabTextStyle.color,
                            minHeight: 0,
                        }}
                    />
                ))}
            </Tabs>
            {/* Main content */}
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                {selectedTab && items[selectedTab] !== undefined && (
                    <Box sx={{ height: '100%', p: 1 }}>
                        {insightWidget.interventionConfig.layout === 'one-column' ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    overflowY: 'auto',
                                }}
                                className="impacted-table u-scrollbar"
                            >
                                {insightWidget.interventionConfig.showActions && actionsList.length > 0 && (
                                    <Stack direction="column" gap={1} my={1}>
                                        <Typography
                                            sx={{
                                                fontWeight: insightWidget.interventionConfig.tableTitle.fontWeight,
                                                fontSize: insightWidget.interventionConfig.tableTitle.fontSize,
                                                fontFamily: insightWidget.interventionConfig.tableTitle.fontFamily,
                                            }}
                                        >
                                            Actions:
                                        </Typography>
                                        <Box sx={{ width: '100%', overflow: 'hidden' }}>
                                            <Swiper
                                                modules={[Pagination]}
                                                pagination={{
                                                    dynamicBullets: true,
                                                    clickable: true,
                                                }}
                                                slidesPerView={'auto'}
                                                spaceBetween={30}
                                                style={{
                                                    paddingBottom: '30px',
                                                }}
                                            >
                                                {actionsList.map((action) => (
                                                    <SwiperSlide key={action.title} style={{ width: 'auto' }}>
                                                        <Box>
                                                            <Tooltip
                                                                title={action.description}
                                                                arrow
                                                                enterDelay={500}
                                                                enterNextDelay={500}
                                                            >
                                                                <Chip
                                                                    label={action.title}
                                                                    variant="outlined"
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        fontSize: fontSize,
                                                                        fontFamily: insightWidget.fontStyles.fontFamily,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </Box>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </Box>
                                    </Stack>
                                )}

                                {insightWidget.interventionConfig.tableTitle.enabled && (
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: insightWidget.interventionConfig.tableTitle.fontWeight,
                                                fontSize: insightWidget.interventionConfig.tableTitle.fontSize,
                                                fontFamily: insightWidget.interventionConfig.tableTitle.fontFamily,
                                                color: insightWidget.interventionConfig.tableTitle.color,
                                            }}
                                        >
                                            {insightWidget.interventionConfig.tableTitle.text} (
                                            {items[selectedTab].nodes.length})
                                        </Typography>
                                        {insightWidget.interventionConfig.showDescription &&
                                            items[selectedTab].description != null &&
                                            items[selectedTab].description!.length > 0 && (
                                                <Tooltip
                                                    title={items[selectedTab].description!}
                                                    arrow
                                                    placement="left-start"
                                                >
                                                    <InfoIcon color="info" />
                                                </Tooltip>
                                            )}
                                    </Stack>
                                )}

                                <StyledDataGrid
                                    rows={items[selectedTab].nodes}
                                    columns={dataGridColumns}
                                    hideFooter
                                    autoHeight
                                    density="compact"
                                    hasSearch={insightWidget.interventionConfig.showSearch}
                                    hasColumnSelector={false}
                                    hasFilter={false}
                                    hasExport={insightWidget.interventionConfig.showExport}
                                    sortModel={sortModel}
                                    onSortModelChange={handleSortModelChange}
                                    sortingOrder={['asc', 'desc']}
                                    key={selectedTab}
                                    onColumnWidthChange={(params) => {
                                        if (widgetId !== undefined && activeSlideId != null && viewMode === 'design') {
                                            const newColumnWidth = params.width
                                            updateInsightWidgetContent({
                                                widgetId,
                                                slideId: activeSlideId,
                                                data: {
                                                    insights: insightWidget.insights.map((x) =>
                                                        x.id === activeInsight?.id
                                                            ? {
                                                                  ...x,
                                                                  message: {
                                                                      ...x.message,
                                                                      [items[selectedTab!].condition]: {
                                                                          ...x.message[items[selectedTab!].condition],
                                                                          columns: x.message[
                                                                              items[selectedTab!].condition
                                                                          ].columns.map((y) =>
                                                                              y.field &&
                                                                              convertNodeAttributeToKeyString(
                                                                                  y.field
                                                                              ) === params.colDef.field
                                                                                  ? { ...y, width: newColumnWidth }
                                                                                  : y
                                                                          ),
                                                                      },
                                                                  },
                                                              }
                                                            : x
                                                    ),
                                                },
                                            })
                                        }
                                    }}
                                    customToolbar={
                                        viewMode === 'design' ? (
                                            <>
                                                <BaseButton size="small" onClick={() => setIsColumnConfigOpen(true)}>
                                                    Columns
                                                </BaseButton>
                                            </>
                                        ) : null
                                    }
                                    sx={{
                                        fontSize: fontSize,
                                        fontFamily: insightWidget.fontStyles.fontFamily,
                                    }}
                                />
                            </Box>
                        ) : insightWidget.interventionConfig.layout === 'two-column' ? (
                            <Stack direction="row" sx={{ height: '100%' }}>
                                {/* Nodes Table */}
                                <Box
                                    sx={{
                                        width: showActionDescription ? `${columnWidth}%` : '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                    className="impacted-table"
                                >
                                    {insightWidget.interventionConfig.tableTitle.enabled && (
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: insightWidget.interventionConfig.tableTitle.fontWeight,
                                                fontSize: insightWidget.interventionConfig.tableTitle.fontSize,
                                                fontFamily: insightWidget.interventionConfig.tableTitle.fontFamily,
                                                color: insightWidget.interventionConfig.tableTitle.color,
                                            }}
                                        >
                                            {insightWidget.interventionConfig.tableTitle.text} (
                                            {items[selectedTab].nodes.length})
                                        </Typography>
                                    )}

                                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                        <StyledDataGrid
                                            rows={items[selectedTab].nodes}
                                            columns={dataGridColumns}
                                            density="compact"
                                            hideFooter
                                            hasSearch={insightWidget.interventionConfig.showSearch}
                                            hasColumnSelector={false}
                                            hasFilter={false}
                                            hasExport={insightWidget.interventionConfig.showExport}
                                            sortModel={sortModel}
                                            onSortModelChange={handleSortModelChange}
                                            sortingOrder={['asc', 'desc']}
                                            onColumnWidthChange={(params) => {
                                                if (
                                                    widgetId !== undefined &&
                                                    activeSlideId != null &&
                                                    viewMode === 'design'
                                                ) {
                                                    const newColumnWidth = params.width
                                                    updateInsightWidgetContent({
                                                        widgetId,
                                                        slideId: activeSlideId,
                                                        data: {
                                                            insights: insightWidget.insights.map((x) =>
                                                                x.id === activeInsight?.id
                                                                    ? {
                                                                          ...x,
                                                                          message: {
                                                                              ...x.message,
                                                                              [items[selectedTab!].condition]: {
                                                                                  ...x.message[
                                                                                      items[selectedTab!].condition
                                                                                  ],
                                                                                  columns: x.message[
                                                                                      items[selectedTab!].condition
                                                                                  ].columns.map((y) =>
                                                                                      y.field &&
                                                                                      convertNodeAttributeToKeyString(
                                                                                          y.field
                                                                                      ) === params.colDef.field
                                                                                          ? {
                                                                                                ...y,
                                                                                                width: newColumnWidth,
                                                                                            }
                                                                                          : y
                                                                                  ),
                                                                              },
                                                                          },
                                                                      }
                                                                    : x
                                                            ),
                                                        },
                                                    })
                                                }
                                            }}
                                            customToolbar={
                                                viewMode === 'design' ? (
                                                    <>
                                                        <BaseButton
                                                            size="small"
                                                            onClick={() => setIsColumnConfigOpen(true)}
                                                        >
                                                            Columns
                                                        </BaseButton>
                                                    </>
                                                ) : null
                                            }
                                            sx={{ fontSize: fontSize, fontFamily: insightWidget.fontStyles.fontFamily }}
                                            key={selectedTab}
                                        />
                                    </Box>
                                </Box>
                                {showActionDescription && (
                                    <>
                                        {/* Vertical divider */}
                                        {isActive && (
                                            <Box
                                                sx={(theme) => ({
                                                    width: '4px',
                                                    bgcolor: alpha(theme.palette.primary.dark, 0.5),
                                                    cursor: 'col-resize !important',
                                                    '&:hover': { bgcolor: theme.palette.primary.dark },
                                                })}
                                                onMouseDown={handleMouseDownVertical}
                                            />
                                        )}

                                        {/* Description and actions*/}

                                        <Stack sx={{ width: `${100 - columnWidth}%`, height: '100%' }} p={1} gap={5}>
                                            {/* Description */}
                                            {insightWidget.interventionConfig.showDescription &&
                                                items[selectedTab].description != null &&
                                                items[selectedTab].description!.length > 0 && (
                                                    <Box sx={{ flexShrink: 0 }} className="u-scrollbar description">
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    insightWidget.interventionConfig.tableTitle
                                                                        .fontWeight,
                                                                fontSize:
                                                                    insightWidget.interventionConfig.tableTitle
                                                                        .fontSize,
                                                                fontFamily:
                                                                    insightWidget.interventionConfig.tableTitle
                                                                        .fontFamily,
                                                                color: insightWidget.interventionConfig.tableTitle
                                                                    .color,
                                                            }}
                                                        >
                                                            Issue
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: fontSize,
                                                                fontFamily: insightWidget.fontStyles.fontFamily,
                                                            }}
                                                        >
                                                            {items[selectedTab].description}
                                                        </Typography>
                                                    </Box>
                                                )}

                                            {/* Actions */}
                                            {insightWidget.interventionConfig.showActions &&
                                                groupedActionList !== null &&
                                                Object.keys(groupedActionList).length > 0 && (
                                                    <Stack
                                                        gap={0}
                                                        sx={{ overflowY: 'auto', flexGrow: 1 }}
                                                        className="u-scrollbar actions"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    insightWidget.interventionConfig.tableTitle
                                                                        .fontWeight,
                                                                fontSize:
                                                                    insightWidget.interventionConfig.tableTitle
                                                                        .fontSize,
                                                                fontFamily:
                                                                    insightWidget.interventionConfig.tableTitle
                                                                        .fontFamily,
                                                                color: insightWidget.interventionConfig.tableTitle
                                                                    .color,
                                                            }}
                                                        >
                                                            Actions
                                                        </Typography>

                                                        <Tabs
                                                            value={selectedActionTab}
                                                            onChange={(evt, value) => setSelectedActionTab(value)}
                                                            variant="scrollable"
                                                            scrollButtons="auto"
                                                            sx={{ width: '100%' }}
                                                        >
                                                            {Object.keys(groupedActionList).map((key) => (
                                                                <Tab
                                                                    value={key}
                                                                    label={key}
                                                                    sx={{
                                                                        fontWeight:
                                                                            insightWidget.interventionConfig
                                                                                .tabTextStyle.fontWeight,
                                                                        fontSize:
                                                                            insightWidget.interventionConfig
                                                                                .tabTextStyle.fontSize,
                                                                        fontFamily:
                                                                            insightWidget.interventionConfig
                                                                                .tabTextStyle.fontFamily,
                                                                        color: insightWidget.interventionConfig
                                                                            .tabTextStyle.color,
                                                                        minHeight: '36px',
                                                                        height: '36px',
                                                                        padding: 0,
                                                                    }}
                                                                />
                                                            ))}
                                                        </Tabs>

                                                        <Grid2 container>
                                                            {selectedActionTab !== null &&
                                                                groupedActionList[selectedActionTab]?.map(
                                                                    (intervention) => (
                                                                        <Grid2 xs={6}>
                                                                            <Card
                                                                                variant="outlined"
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    m: 2,
                                                                                }}
                                                                            >
                                                                                <CardContent>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        justifyContent="space-between"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: fontSize,
                                                                                                fontFamily:
                                                                                                    insightWidget
                                                                                                        .fontStyles
                                                                                                        .fontFamily,
                                                                                                fontWeight: 'bold',
                                                                                            }}
                                                                                        >
                                                                                            {intervention.title}
                                                                                        </Typography>

                                                                                        <DynamicIcon
                                                                                            color="primary"
                                                                                            icon={intervention.icon}
                                                                                        />
                                                                                    </Stack>

                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: fontSize,
                                                                                            fontFamily:
                                                                                                insightWidget.fontStyles
                                                                                                    .fontFamily,
                                                                                        }}
                                                                                    >
                                                                                        {intervention.description}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Grid2>
                                                                    )
                                                                )}
                                                        </Grid2>
                                                    </Stack>
                                                )}
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                        ) : null}
                    </Box>
                )}
            </Box>

            <InsightInterventionTableColumnSelector
                columns={activeInsight?.message[items[selectedTab!].condition].columns ?? []}
                updateColumns={(columns) => {
                    if (activeSlideId) {
                        updateInsightWidgetContent({
                            widgetId,
                            slideId: activeSlideId,
                            data: {
                                insights: insightWidget.insights.map((x) =>
                                    x.id === activeInsight?.id
                                        ? {
                                              ...x,
                                              message: {
                                                  ...x.message,
                                                  [items[selectedTab!].condition]: {
                                                      ...x.message[items[selectedTab!].condition],
                                                      columns,
                                                  },
                                              },
                                          }
                                        : x
                                ),
                            },
                        })
                    }
                }}
                dataSourceFields={dataSourceFields}
                isOpen={isColumnConfigOpen}
                onClose={() => {
                    setIsColumnConfigOpen(false)
                }}
            />
        </Box>
    )
}

export default InsightWidgetInterventionView

type InsightInterventionTableColumnSelectorProps = {
    isOpen: boolean
    dataSourceFields: FlexibleSelectOptionType[]
    onClose: () => void
    columns: InisghtInterventionTableColumnType[]
    updateColumns: (columns: InisghtInterventionTableColumnType[]) => void
}
export const InsightInterventionTableColumnSelector = ({
    isOpen,
    onClose,
    columns,
    updateColumns,
    dataSourceFields,
}: InsightInterventionTableColumnSelectorProps) => {
    const [localColumns, setLocalColumns] = useState<InisghtInterventionTableColumnType[]>([])

    const addColumn = () => {
        setLocalColumns((pv) => [...pv, { field: null, label: '', width: null, sort: false }])
    }

    const removeColumn = (index: number) => {
        if (index === 0) return
        setLocalColumns((pv) => pv.filter((x, i) => i !== index))
    }

    const updateColumn = (index: number, column: Partial<InisghtInterventionTableColumnType>) => {
        setLocalColumns((pv) => pv.map((x, i) => (i === index ? { ...x, ...column } : x)))
    }

    const handleSave = () => {
        if (!isDataValid) return
        updateColumns(localColumns)
        handleClose()
    }

    const handleClose = () => {
        setLocalColumns([])
        onClose()
    }

    useEffect(() => {
        isOpen && setLocalColumns(columns)
    }, [isOpen])

    const isDataValid = useMemo(() => {
        return localColumns.every((x) => x.field !== null && x.label.trim() !== '')
    }, [localColumns])

    const updateColumnSort = (index: number) => (value: InisghtInterventionTableColumnType['sort']) => {
        // update the sort value of the column. if sort is not false, then set the other columns to false
        setLocalColumns((pv) =>
            pv.map((x, i) => (i === index ? { ...x, sort: value } : { ...x, sort: value !== false ? false : x.sort }))
        )
    }
    return (
        <StyledDialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Select Columns</Typography>
                    <BaseButton onClick={addColumn}>Add Column</BaseButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ height: '40vh', overflow: 'auto' }} className="scrollbar">
                <Stack direction="column" gap={1}>
                    {localColumns.map((column, index) => (
                        <Stack direction="row" alignItems="center" gap={1}>
                            <BaseButton disabled={index === 0} onClick={() => removeColumn(index)}>
                                Remove
                            </BaseButton>
                            <FlexibleSelect
                                options={dataSourceFields}
                                value={column.field}
                                required={true}
                                fullWidth
                                hiddenLabel={true}
                                onChange={(value) => {
                                    updateColumn(index, { field: value, label: value.field ?? '' })
                                }}
                            />
                            <BaseFilledTextField
                                label="Label"
                                required={true}
                                fullWidth
                                focused
                                sx={{ height: '100%' }}
                                value={column.label}
                                onChange={(evt) => updateColumn(index, { label: evt.target.value })}
                            />
                            {/* Sort */}
                            <BaseSelectWithLabel
                                label="Sort"
                                fullWidth
                                value={column.sort}
                                options={[
                                    { value: 'asc', label: 'Ascending' },
                                    { value: 'desc', label: 'Descending' },
                                    { value: false, label: 'None' },
                                ]}
                                onChange={updateColumnSort(index)}
                            />
                        </Stack>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={handleClose}>Cancel</BaseButton>
                <BaseButton onClick={handleSave} disabled={!isDataValid}>
                    Save
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
