//* ======= Libraries
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Merge, PartialDeep } from 'type-fest'
import { Divider, Stack, Typography } from '@mui/material'
//* ======= Components and features
import ScatterChartDataConfigDialog from 'features/report-designer/widgets/chart-widget/settings/ScatterChartDataConfigDialog'
import FontPicker, { FontPickerValueType } from 'components/widgets/FontPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { ChartWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { ScatterChartType } from 'features/chart/Chart.asset'
import { GenerateChartOptionsParamsModeType } from 'features/chart/Chart.helper'
import { DECIMAL_PRECISION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { deepMergeCustom } from 'helpers/helpers'
//* ======= Assets and styles

const verticalAlignOptions = ['top', 'center', 'bottom']
const horizontalAlignOptions = ['left', 'center', 'right']

export type ReportScatterChartWidgetSettingsStateType = Merge<
    Pick<ChartWidgetType, 'selectedDataSource' | 'type' | 'dataDefinition' | 'config' | 'options'>,
    ScatterChartType
>

type Props = {
    data: ReportScatterChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onUpdate: (
        mode: GenerateChartOptionsParamsModeType,
        updatedValues: Merge<
            Partial<Pick<ChartWidgetType, 'selectedDataSource'>>,
            Partial<Pick<ScatterChartType, 'dataDefinition' | 'config'>>
        >
    ) => void
}

function ScatterChartWidgetSettings({ data, dataSources, onUpdate }: Props) {
    // Create an internal chart data on props change.
    const chartData = useMemo<ReportScatterChartWidgetSettingsStateType>(() => {
        return {
            selectedDataSource: data.selectedDataSource,
            type: 'scatter',
            dataDefinition: data.dataDefinition,
            config: data.config,
            options: data.options,
        }
    }, [data])

    const [isDataConfigDialogOpen, setIsDataConfigDialogOpen] = useState(false)

    const onChartConfigUpdate = (updatedConfig: PartialDeep<ReportScatterChartWidgetSettingsStateType['config']>) => {
        const config = deepMergeCustom(structuredClone(chartData.config), updatedConfig)

        onUpdate('config', {
            config: config,
        })
    }

    const onFontPickerChange = useCallback(
        (configField: keyof ReportScatterChartWidgetSettingsStateType['config'], values: FontPickerValueType) => {
            let updatedConfig: PartialDeep<ReportScatterChartWidgetSettingsStateType['config']> = {}

            // Title and Legend
            if (configField === 'title' || configField === 'legend') {
                updatedConfig = {
                    ...updatedConfig,
                    [configField]: {
                        styles: {
                            fontFamily: values.fontFamily,
                            fontSize: values.fontSize,
                            fontWeight: values.isBold ? 'bold' : 'normal',
                            color: values.color,
                        },
                    },
                }
            }
            // X and Y Axes
            else if (configField === 'xAxisOptions' || configField === 'yAxisOptions') {
                updatedConfig = {
                    ...updatedConfig,
                    [configField]: {
                        labels: {
                            styles: {
                                fontFamily: values.fontFamily,
                                fontSize: values.fontSize,
                                fontWeight: values.isBold ? 'bold' : 'normal',
                                color: values.color,
                            },
                        },
                    },
                }
            }

            onChartConfigUpdate(updatedConfig)
        },
        [chartData]
    )

    const onChartDataUpdate = (
        updatedData: Pick<ReportScatterChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>
    ) => {
        // We generate the options again and update store state.
        onUpdate('data', {
            selectedDataSource: updatedData.selectedDataSource,
            dataDefinition: updatedData.dataDefinition,
        })

        setIsDataConfigDialogOpen(false)
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                paddingX: theme.spacing(2),
            })}
        >
            {/* Configure data button
				========================================= */}
            <BaseButton
                label="Configure Data"
                onClick={(evt) => setIsDataConfigDialogOpen(true)}
                variant="outlined"
                sx={(theme) => ({
                    alignSelf: 'center',

                    width: '80%',
                    paddingY: theme.spacing(1.5),
                })}
            />

            <Divider />

            {/* Config settings
                ========================================= */}
            {chartData.options !== null ? (
                <>
                    {/* Decimal precision
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={(theme) => ({
                                flex: '1 0 0',

                                paddingY: theme.spacing(1),
                            })}
                        >
                            Decimal Precision:
                        </Typography>

                        <BaseSelectWithLabel
                            options={DECIMAL_PRECISION_OPTIONS}
                            value={chartData.config.decimalPrecision}
                            onChange={(value) =>
                                onChartConfigUpdate({
                                    decimalPrecision: value,
                                })
                            }
                            hiddenLabel
                            size="small"
                            sx={{
                                flex: '0 0 30%',
                            }}
                        />
                    </Stack>

                    {/* Title settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Title"
                        hasToggle
                        isToggledOff={chartData.config.title.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                title: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Title"
                                defaultValue={chartData.config.title.value}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            value: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Secondary title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Secondary Title"
                                defaultValue={chartData.config.title.secondaryValue}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            secondaryValue: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Vertical align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Vertical Align"
                                options={verticalAlignOptions}
                                value={chartData.config.title.verticalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            verticalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Horizontal align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Horizontal Align"
                                options={horizontalAlignOptions}
                                value={chartData.config.title.horizontalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            horizontalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Title font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('title', values)}
                                disabled={chartData.config.title.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.title.styles?.fontFamily,
                                    fontSize: chartData.config.title.styles?.fontSize,
                                    color: chartData.config.title.styles?.color,
                                    isBold: chartData.config.title.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Legend settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Legend"
                        hasToggle
                        isToggledOff={chartData.config.legend.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                legend: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Legend font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('legend', values)}
                                disabled={chartData.config.legend.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.legend.styles?.fontFamily,
                                    fontSize: chartData.config.legend.styles?.fontSize,
                                    color: chartData.config.legend.styles?.color,
                                    isBold: chartData.config.legend.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* X-Axis settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="X-Axis"
                        hasToggle
                        isToggledOff={chartData.config.xAxisOptions.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                xAxisOptions: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Axis Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Axis Name"
                                defaultValue={chartData.config.xAxisOptions.name}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        xAxisOptions: {
                                            name: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.xAxisOptions.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Font picker
                                ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('xAxisOptions', values)}
                                disabled={chartData.config.xAxisOptions.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.xAxisOptions.labels.styles?.fontFamily,
                                    fontSize: chartData.config.xAxisOptions.labels.styles?.fontSize,
                                    color: chartData.config.xAxisOptions.labels.styles?.color,
                                    isBold: chartData.config.xAxisOptions.labels.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />

                            {/* Display all labels switch
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Display All Labels:
                                </Typography>

                                <BaseSwitch
                                    checked={chartData.config.xAxisOptions.labels.forceAllVisible}
                                    onChange={(evt, checked) =>
                                        onChartConfigUpdate({
                                            xAxisOptions: {
                                                labels: {
                                                    forceAllVisible: checked,
                                                },
                                            },
                                        })
                                    }
                                    disabled={chartData.config.xAxisOptions.isEnabled === false}
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flex: '0 0 auto',
                                    }}
                                />
                            </Stack>

                            {/* Label rotation
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography fontSize={14} noWrap>
                                    Rotation:
                                </Typography>

                                <BaseFilledTextField
                                    type="number"
                                    defaultValue={chartData.config.xAxisOptions.labels.rotation}
                                    onBlur={(evt) => {
                                        const newValue =
                                            Number(evt.target.value) > 90
                                                ? 90
                                                : Number(evt.target.value) < -90
                                                ? -90
                                                : Number(evt.target.value)

                                        onChartConfigUpdate({
                                            xAxisOptions: {
                                                labels: {
                                                    rotation: newValue,
                                                },
                                            },
                                        })

                                        evt.target.value = newValue.toString()
                                    }}
                                    disabled={chartData.config.xAxisOptions.isEnabled === false}
                                    inputProps={{
                                        min: -90,
                                        max: 90,
                                        step: 1,
                                    }}
                                    hiddenLabel
                                    size="small"
                                    placeholder="Rotation"
                                />
                            </Stack>

                            {/* Bounds
                                ========================================= */}
                            <Stack gap={1}>
                                <Typography fontSize={14} noWrap>
                                    Bounds:
                                </Typography>

                                {/* Min
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                    sx={(theme) => ({
                                        paddingLeft: theme.spacing(1.5),
                                    })}
                                >
                                    <Typography
                                        fontSize={14}
                                        noWrap
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        Min:
                                    </Typography>

                                    <BaseFilledTextField
                                        type="number"
                                        defaultValue={chartData.config.xAxisOptions.bounds.min ?? ''}
                                        onBlur={(evt) => {
                                            // If user is clearing the field value:
                                            if (evt.target.value.trim() === '') {
                                                onChartConfigUpdate({
                                                    xAxisOptions: {
                                                        bounds: {
                                                            min: null,
                                                        },
                                                    },
                                                })

                                                evt.target.value = ''

                                                return
                                            }

                                            let newValue = Number(evt.target.value)

                                            const decimalPercisionUnit =
                                                1 / Math.pow(10, chartData.config.decimalPrecision)

                                            // If bounds max is available
                                            if (chartData.config.xAxisOptions.bounds.max !== null) {
                                                newValue =
                                                    Number(evt.target.value) >= chartData.config.xAxisOptions.bounds.max
                                                        ? chartData.config.xAxisOptions.bounds.max -
                                                          decimalPercisionUnit
                                                        : Number(evt.target.value)
                                            }

                                            onChartConfigUpdate({
                                                xAxisOptions: {
                                                    bounds: {
                                                        min: newValue,
                                                    },
                                                },
                                            })

                                            evt.target.value = newValue.toString()
                                        }}
                                        disabled={chartData.config.xAxisOptions.isEnabled === false}
                                        inputProps={{
                                            max: chartData.config.xAxisOptions.bounds.max ?? undefined,
                                            step: 1 / Math.pow(10, chartData.config.decimalPrecision),
                                        }}
                                        hiddenLabel
                                        placeholder="(auto)"
                                        size="small"
                                        sx={(theme) => ({
                                            flex: '0 0 50%',
                                        })}
                                    />
                                </Stack>

                                {/* Max
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                    sx={(theme) => ({
                                        paddingLeft: theme.spacing(1.5),
                                    })}
                                >
                                    <Typography
                                        fontSize={14}
                                        noWrap
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        Max:
                                    </Typography>

                                    <BaseFilledTextField
                                        type="number"
                                        defaultValue={chartData.config.xAxisOptions.bounds.max ?? ''}
                                        onBlur={(evt) => {
                                            // If user is clearing the field value:
                                            if (evt.target.value.trim() === '') {
                                                onChartConfigUpdate({
                                                    xAxisOptions: {
                                                        bounds: {
                                                            max: null,
                                                        },
                                                    },
                                                })

                                                evt.target.value = ''

                                                return
                                            }

                                            let newValue = Number(evt.target.value)

                                            const decimalPercisionUnit =
                                                1 / Math.pow(10, chartData.config.decimalPrecision)

                                            // If bounds min is available
                                            if (chartData.config.xAxisOptions.bounds.min !== null) {
                                                newValue =
                                                    Number(evt.target.value) <= chartData.config.xAxisOptions.bounds.min
                                                        ? chartData.config.xAxisOptions.bounds.min +
                                                          decimalPercisionUnit
                                                        : Number(evt.target.value)
                                            }

                                            onChartConfigUpdate({
                                                xAxisOptions: {
                                                    bounds: {
                                                        max: newValue,
                                                    },
                                                },
                                            })

                                            evt.target.value = newValue.toString()
                                        }}
                                        disabled={chartData.config.xAxisOptions.isEnabled === false}
                                        inputProps={{
                                            min: chartData.config.xAxisOptions.bounds.min ?? undefined,
                                            step: 1 / Math.pow(10, chartData.config.decimalPrecision),
                                        }}
                                        hiddenLabel
                                        placeholder="(auto)"
                                        size="small"
                                        sx={(theme) => ({
                                            flex: '0 0 50%',
                                        })}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Y-Axis settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Y-Axis"
                        hasToggle
                        isToggledOff={chartData.config.yAxisOptions.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                yAxisOptions: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Axis Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Axis Name"
                                defaultValue={chartData.config.yAxisOptions.name}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        yAxisOptions: {
                                            name: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.yAxisOptions.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Font picker
                                ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('yAxisOptions', values)}
                                disabled={chartData.config.yAxisOptions.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.yAxisOptions.labels.styles?.fontFamily,
                                    fontSize: chartData.config.yAxisOptions.labels.styles?.fontSize,
                                    color: chartData.config.yAxisOptions.labels.styles?.color,
                                    isBold: chartData.config.yAxisOptions.labels.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />

                            {/* Display all labels switch
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Display All Labels:
                                </Typography>

                                <BaseSwitch
                                    checked={chartData.config.yAxisOptions.labels.forceAllVisible}
                                    onChange={(evt, checked) =>
                                        onChartConfigUpdate({
                                            yAxisOptions: {
                                                labels: {
                                                    forceAllVisible: checked,
                                                },
                                            },
                                        })
                                    }
                                    disabled={chartData.config.yAxisOptions.isEnabled === false}
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flex: '0 0 auto',
                                    }}
                                />
                            </Stack>

                            {/* Label rotation
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography fontSize={14} noWrap>
                                    Rotation:
                                </Typography>

                                <BaseFilledTextField
                                    type="number"
                                    defaultValue={chartData.config.yAxisOptions.labels.rotation}
                                    onBlur={(evt) => {
                                        const newValue =
                                            Number(evt.target.value) > 90
                                                ? 90
                                                : Number(evt.target.value) < -90
                                                ? -90
                                                : Number(evt.target.value)

                                        onChartConfigUpdate({
                                            yAxisOptions: {
                                                labels: {
                                                    rotation: newValue,
                                                },
                                            },
                                        })

                                        evt.target.value = newValue.toString()
                                    }}
                                    disabled={chartData.config.yAxisOptions.isEnabled === false}
                                    inputProps={{
                                        min: -90,
                                        max: 90,
                                        step: 1,
                                    }}
                                    hiddenLabel
                                    size="small"
                                    placeholder="Rotation"
                                />
                            </Stack>

                            {/* Bounds
                                ========================================= */}
                            <Stack gap={1}>
                                <Typography fontSize={14} noWrap>
                                    Bounds:
                                </Typography>

                                {/* Min
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                    sx={(theme) => ({
                                        paddingLeft: theme.spacing(1.5),
                                    })}
                                >
                                    <Typography
                                        fontSize={14}
                                        noWrap
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        Min:
                                    </Typography>

                                    <BaseFilledTextField
                                        type="number"
                                        defaultValue={chartData.config.yAxisOptions.bounds.min ?? ''}
                                        onBlur={(evt) => {
                                            // If user is clearing the field value:
                                            if (evt.target.value.trim() === '') {
                                                onChartConfigUpdate({
                                                    yAxisOptions: {
                                                        bounds: {
                                                            min: null,
                                                        },
                                                    },
                                                })

                                                evt.target.value = ''

                                                return
                                            }

                                            let newValue = Number(evt.target.value)

                                            const decimalPercisionUnit =
                                                1 / Math.pow(10, chartData.config.decimalPrecision)

                                            // If bounds max is available
                                            if (chartData.config.yAxisOptions.bounds.max !== null) {
                                                newValue =
                                                    Number(evt.target.value) >= chartData.config.yAxisOptions.bounds.max
                                                        ? chartData.config.yAxisOptions.bounds.max -
                                                          decimalPercisionUnit
                                                        : Number(evt.target.value)
                                            }

                                            onChartConfigUpdate({
                                                yAxisOptions: {
                                                    bounds: {
                                                        min: newValue,
                                                    },
                                                },
                                            })

                                            evt.target.value = newValue.toString()
                                        }}
                                        disabled={chartData.config.yAxisOptions.isEnabled === false}
                                        inputProps={{
                                            max: chartData.config.yAxisOptions.bounds.max ?? undefined,
                                            step: 1 / Math.pow(10, chartData.config.decimalPrecision),
                                        }}
                                        hiddenLabel
                                        placeholder="(auto)"
                                        size="small"
                                        sx={(theme) => ({
                                            flex: '0 0 50%',
                                        })}
                                    />
                                </Stack>

                                {/* Max
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                    sx={(theme) => ({
                                        paddingLeft: theme.spacing(1.5),
                                    })}
                                >
                                    <Typography
                                        fontSize={14}
                                        noWrap
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        Max:
                                    </Typography>

                                    <BaseFilledTextField
                                        type="number"
                                        defaultValue={chartData.config.yAxisOptions.bounds.max ?? ''}
                                        onBlur={(evt) => {
                                            // If user is clearing the field value:
                                            if (evt.target.value.trim() === '') {
                                                onChartConfigUpdate({
                                                    yAxisOptions: {
                                                        bounds: {
                                                            max: null,
                                                        },
                                                    },
                                                })

                                                evt.target.value = ''

                                                return
                                            }

                                            let newValue = Number(evt.target.value)

                                            const decimalPercisionUnit =
                                                1 / Math.pow(10, chartData.config.decimalPrecision)

                                            // If bounds min is available
                                            if (chartData.config.yAxisOptions.bounds.min !== null) {
                                                newValue =
                                                    Number(evt.target.value) <= chartData.config.yAxisOptions.bounds.min
                                                        ? chartData.config.yAxisOptions.bounds.min +
                                                          decimalPercisionUnit
                                                        : Number(evt.target.value)
                                            }

                                            onChartConfigUpdate({
                                                yAxisOptions: {
                                                    bounds: {
                                                        max: newValue,
                                                    },
                                                },
                                            })

                                            evt.target.value = newValue.toString()
                                        }}
                                        disabled={chartData.config.yAxisOptions.isEnabled === false}
                                        inputProps={{
                                            min: chartData.config.yAxisOptions.bounds.min ?? undefined,
                                            step: 1 / Math.pow(10, chartData.config.decimalPrecision),
                                        }}
                                        hiddenLabel
                                        placeholder="(auto)"
                                        size="small"
                                        sx={(theme) => ({
                                            flex: '0 0 50%',
                                        })}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </StyledWidgetAccordion>
                </>
            ) : (
                false
            )}

            {/* (Out-of-flow) Data config dialog
				========================================= */}
            {isDataConfigDialogOpen && (
                <ScatterChartDataConfigDialog
                    isOpen={isDataConfigDialogOpen}
                    data={chartData}
                    dataSources={dataSources}
                    onConfirm={onChartDataUpdate}
                    onClose={() => setIsDataConfigDialogOpen(false)}
                />
            )}
        </Stack>
    )
}

export default ScatterChartWidgetSettings
