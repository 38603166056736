//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Box, Typography, Tabs, Tab } from '@mui/material'
//* ======= Components and features
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    NavLinkWidgetType,
    NavLinkWidgetInternalModeType,
    ReportWidgetSettingsBaseType,
} from 'features/report-designer/types/reportDesigner.types'
import FontPicker from 'components/widgets/FontPicker'
import AbsoluteUrlInput from 'components/absolute-url-input/AbsoluteUrlInput'
import { deepMergeCustom } from 'helpers/helpers'
import { defaultNavLinkWidgetValues } from 'features/report-designer/helpers/reportDesignerDefaultValues'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
//* ======= Assets and styles

const modeOptions: BaseSelectWithLabelOptionType = [
    {
        label: 'Slide Link',
        value: 'internal',
    },
    {
        label: 'External Link',
        value: 'external',
    },
]

const variantOptions: BaseSelectWithLabelOptionType = [
    {
        label: 'Text',
        value: 'text',
    },
    {
        label: 'Outlined',
        value: 'outlined',
    },
    {
        label: 'Contained',
        value: 'contained',
    },
]

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'navLink'>
    settings: Omit<ReportWidgetType, 'content'>
    details: NavLinkWidgetType
}

function NavLinkWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { slides, activeSlideId, updateWidget, updateWidgetStyles, updateNavLinkWidgetContent } = useReportStore(
        (store) => ({
            slides: store.slides,
            activeSlideId: store.activeSlideId,
            updateWidget: store.updateWidget,
            updateWidgetStyles: store.updateWidgetStyles,
            updateNavLinkWidgetContent: store.updateNavLinkWidgetContent,
        })
    )

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'navLink') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const slidesSelectData: {
        selectedValue: NavLinkWidgetInternalModeType['targetSlideId']
        options: Array<{
            label: string
            value: NavLinkWidgetInternalModeType['targetSlideId']
        }>
    } = useMemo(() => {
        const options: Array<{
            label: string
            value: NavLinkWidgetInternalModeType['targetSlideId']
        }> = [
            {
                label: '(No Selection)',
                value: null,
            },
        ]

        if (widget === null || widget.details.mode === 'external') {
            return {
                selectedValue: null,
                options: options,
            }
        }

        for (let index = 0; index < slides.length; index++) {
            const _slide = slides[index]

            // Don't add the current slide to the selectable list of slides.
            if (_slide.id === activeSlideId) continue

            options.push({
                label: _slide.title !== '' ? `${index + 1}. ${_slide.title}` : `${index + 1}. Untitled Slide`,
                value: _slide.id,
            })
        }

        const targetSlideId = widget.details.targetSlideId

        return {
            selectedValue: slides.find((_slide) => _slide.id === targetSlideId) !== undefined ? targetSlideId : null,
            options: options,
        }
    }, [slides, activeSlideId, widget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: NavLinkWidgetType) => {
        if (activeSlideId !== null && widget !== null) {
            updateNavLinkWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    const onModeChange = (mode: NavLinkWidgetType['mode']) => {
        if (widget === null || mode === widget.details.mode) return

        const updatedInitialValues = structuredClone(defaultNavLinkWidgetValues[mode])

        // Extracting unique values from the union types and merging common properties to keep the user's settings intact.
        if (mode === 'internal' && widget.details.mode === 'external') {
            const { mode: stateMode, href, ...commonProperties } = widget.details

            deepMergeCustom(updatedInitialValues, commonProperties)
        } else if (mode === 'external' && widget.details.mode === 'internal') {
            const { mode: stateMode, targetSlideId, ...commonProperties } = widget.details

            deepMergeCustom(updatedInitialValues, commonProperties)
        }

        updateDetails(updatedInitialValues)
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Nav Link" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}

                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(value) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility: value,
                                        },
                                    })
                                }
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* mode select
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Link Mode"
                                options={modeOptions}
                                value={widget.details.mode}
                                onChange={(value) => onModeChange(value)}
                            />

                            {widget.details.mode === 'external' ? (
                                /*  href input
                                    ========================================= */
                                <AbsoluteUrlInput
                                    label="Link"
                                    defaultValue={widget.details.href}
                                    onChange={(url) =>
                                        updateDetails({
                                            ...widget.details,
                                            href: url,
                                        } as NavLinkWidgetType)
                                    }
                                    size="small"
                                    fullWidth
                                />
                            ) : (
                                /*  Target slide select
                                    ========================================= */
                                <BaseSelectWithLabel
                                    label="Target Slide"
                                    options={slidesSelectData.options}
                                    value={slidesSelectData.selectedValue}
                                    onChange={(value) =>
                                        updateDetails({
                                            ...widget.details,
                                            targetSlideId: value,
                                        } as NavLinkWidgetType)
                                    }
                                />
                            )}

                            {/* Label
                                ========================================= */}
                            <BaseFilledTextField
                                label="Label"
                                defaultValue={widget.details.label}
                                onBlur={(evt) =>
                                    updateDetails({
                                        ...widget.details,
                                        label: evt.target.value.trim(),
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Variant select
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Variant"
                                options={variantOptions}
                                value={widget.details.variant}
                                onChange={(value) =>
                                    updateDetails({
                                        ...widget.details,
                                        variant: value,
                                    })
                                }
                            />

                            <Typography fontSize={14} noWrap textAlign="left">
                                Styles:
                            </Typography>

                            {/* Label styles
                                ========================================= */}
                            <FontPicker
                                onChange={(values) =>
                                    updateDetails({
                                        ...widget.details,
                                        styles: {
                                            fontFamily: values.fontFamily,
                                            fontSize: values.fontSize,
                                            fontWeight: values.isBold ? 'bold' : 'normal',
                                            color: values.color || widget.details.styles.color,
                                        },
                                    })
                                }
                                defaultValue={{
                                    fontFamily: widget.details.styles.fontFamily,
                                    fontSize: widget.details.styles.fontSize,
                                    isBold: widget.details.styles.fontWeight === 'bold',
                                    color: widget.details.styles.color,
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default NavLinkWidgetSettings
