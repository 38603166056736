//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatarOne from 'assets/images/profile-avatar-1.svg'
import TempAvatarTwo from 'assets/images/profile-avatar-2.svg'
import TempAvatarThree from 'assets/images/profile-avatar-3.svg'
import TempAvatarFour from 'assets/images/profile-avatar-4.svg'
import TempAvatarFive from 'assets/images/profile-avatar-5.svg'
import TempAvatarSix from 'assets/images/profile-avatar-6.svg'
import ImageCropper from 'components/ImageCropper/ImageCropper'

interface IProfileImageUploadDialog {
    onImageSelect: (filePath: string) => void
}

function ProfileImageUploadDialog({ onImageSelect }: IProfileImageUploadDialog) {
    const [isUploadImageView, setIsUploadImageView] = useState(false)
    const [uploadedImagePath, setUploadedImagePath] = useState<string>('')

    const onImageSelected = (filePath: string) => {
        // Other processing requirements?

        onImageSelect(filePath)
    }

    const onUpload = (imageReaderResult: string) => {
        setIsUploadImageView(true)
        setUploadedImagePath(imageReaderResult)
    }

    return (
        <Box padding={3}>
            {isUploadImageView ? (
                <ProfileImageCropView uploadedImagePath={uploadedImagePath} onConfirm={onImageSelected} />
            ) : (
                <ProfileImageTemplatesSelection onConfirm={onImageSelected} onImageUpload={onUpload} />
            )}
        </Box>
    )
}

/*	Template selection
	========================================= */

interface IProfileImageTemplatesSelection {
    onConfirm: (filePath: string) => void
    onImageUpload: (imageReaderResult: string) => void
}

function ProfileImageTemplatesSelection({ onConfirm, onImageUpload }: IProfileImageTemplatesSelection) {
    const defaultAvatars = [
        {
            id: 1,
            path: TempAvatarOne,
        },
        {
            id: 2,
            path: TempAvatarTwo,
        },
        {
            id: 3,
            path: TempAvatarThree,
        },
        {
            id: 4,
            path: TempAvatarFour,
        },
        {
            id: 5,
            path: TempAvatarFive,
        },
        {
            id: 6,
            path: TempAvatarSix,
        },
    ]
    const [selectedAvatar, setSelectedAvatar] = useState<{ id: number; path: string }>(defaultAvatars[0])

    const onImageUploaded = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.files && evt.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                onImageUpload(reader?.result?.toString() || '')
            })
            reader.readAsDataURL(evt.target.files[0])
        }
    }

    return (
        <Stack gap={7}>
            {/* Default avatars grid
				========================================= */}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridAutoFlow: 'row',
                    columnGap: 2,
                    rowGap: 3,
                    placeItems: 'center',
                }}
            >
                {defaultAvatars.map((_item, idx) => (
                    <Box
                        key={_item.id}
                        component="span"
                        sx={(theme) => ({
                            position: 'relative',

                            padding: 0.5,

                            borderRadius: '6px',
                            border: `1px solid ${theme.palette.common.border_1}`,
                            borderColor: selectedAvatar.id === _item.id ? 'common.fuschia100' : 'transparent',
                        })}
                    >
                        <input
                            type="radio"
                            name="default-avatars-radio"
                            checked={selectedAvatar.id === _item.id}
                            onChange={(evt) => setSelectedAvatar(_item)}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,

                                height: '100%',
                                width: '100%',
                                margin: 0,
                                padding: 0,

                                cursor: 'pointer',
                                opacity: 0,
                            }}
                        />

                        <Avatar
                            src={_item.path}
                            sx={{
                                height: 128,
                                width: 128,
                            }}
                        />
                    </Box>
                ))}
            </Box>

            {/* Upload image and accept buttons
				========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                {/* Upload image button
					========================================= */}
                <label htmlFor="profile-upload">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={onImageUploaded}
                        id="profile-upload"
                        style={{
                            display: 'none',
                        }}
                    />
                    <Button
                        component="span"
                        variant="outlined"
                        sx={{
                            width: 186,
                            py: 1,
                        }}
                    >
                        Add Profile Image
                    </Button>
                </label>

                {/* Accept button
					========================================= */}
                <BaseButton
                    label="Accept"
                    onClick={() => onConfirm(selectedAvatar.path)}
                    disabled={selectedAvatar.id === 0}
                    variant="contained"
                    sx={{
                        width: 186,
                        py: 1,
                    }}
                />
            </Stack>
        </Stack>
    )
}

/*	Image crop
	========================================= */

interface IProfileImageCropView {
    uploadedImagePath: string
    onConfirm: (filePath: string) => void
}

function ProfileImageCropView({ uploadedImagePath = '', onConfirm }: IProfileImageCropView) {
    return <ImageCropper imageSource={uploadedImagePath} onCropComplete={onConfirm} isCircularCrop />
}

export default ProfileImageUploadDialog
