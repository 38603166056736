import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import StyledDialog from 'components/dialog/StyledDialog'
import ImageCropper from '../ImageCropper/ImageCropper'

type ImageDataState = {
    source: string
    name: string
} | null

type Props = {
    inputId?: string
    onCropComplete: (imageUrl: string) => void
    circularCrop?: boolean
}

export default function ImageUploadCropper({
    inputId = 'select-profile-image',
    onCropComplete,
    circularCrop = true,
}: Props) {
    const [imageData, setImageData] = useState<ImageDataState>(null)

    const onFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.files && evt.target.files.length > 0) {
            const reader = new FileReader()

            reader.addEventListener('load', () => {
                setImageData({
                    source: reader?.result?.toString() || '',
                    name: evt.target.files![0].name ?? 'uploaded-image',
                })
            })

            reader.readAsDataURL(evt.target.files[0])
        }
    }

    const onConfirm = (imageUrl: string) => {
        onCropComplete(imageUrl)

        setImageData(null)
    }

    return (
        <>
            {/* Hidden file input
				========================================= */}
            <input
                id={inputId}
                type="file"
                accept="image/*"
                onChange={onFileSelect}
                style={{
                    display: 'none',
                }}
            />

            {/* Crop dialog
				========================================= */}
            {imageData !== null && (
                <StyledDialog
                    open={imageData.source !== ''}
                    onClose={(evt) => setImageData(null)}
                    maxWidth="md"
                >
                    <Stack
                        sx={(theme) => ({
                            width: 500,
                            padding: 2,
                            paddingBottom: 3,

                            borderRadius: '10px',
                            backgroundColor: 'common.bg_1',
                        })}
                    >
                        <ImageCropper
                            imageSource={imageData.source}
                            onCropComplete={onConfirm}
                            isCircularCrop={circularCrop}
                        />
                    </Stack>
                </StyledDialog>
            )}
        </>
    )
}
