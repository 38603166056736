import {
    isArray,
    isDate,
    isEqual,
    isFunction,
    isMap,
    isObject,
    isRegExp,
    isSet,
    isSymbol,
    transform,
} from 'lodash'
import Color from 'color'

/* =========================================
 * Debounce
 */

export function debounce<T extends Function>(func: T, wait: number = 250) {
    let timeout: NodeJS.Timeout

    // This is the function that is returned and will be executed many times
    // We spread (...args) to capture any number of parameters we want to pass
    return function executedFunction(...args: any) {
        // This will reset the waiting every function execution.
        // This is the step that prevents the function from
        // being executed because it will never reach the
        // inside of the previous setTimeout
        clearTimeout(timeout)

        // The callback function to be executed after
        // the debounce time has elapsed
        const later = () => {
            // clear the timeout to indicate the debounce ended
            // and make sure it is all cleaned up
            clearTimeout(timeout)

            // Execute the callback
            func(...args)
        }

        // Restart the debounce waiting period.
        // setTimeout returns a truthy value
        timeout = setTimeout(later, wait)
    }
}

/* =========================================
 * Throttle
 */

export function throttle<T extends Function>(callback: T, limit: number = 250) {
    // Create a closure around these variables.
    // They will be shared among all events handled by the throttle.
    let throttleTimeout: any = null
    let storedEvent: any = null

    // This is the function that will handle events and throttle callbacks when the throttle is active.
    const throttledEventHandler = (event: any) => {
        // Update the stored event every iteration
        storedEvent = event

        // We execute the callback with our event if our throttle is not active
        const shouldHandleEvent = !throttleTimeout

        // If there isn't a throttle active, we execute the callback and create a new throttle.
        if (shouldHandleEvent) {
            // Handle our event
            callback(storedEvent)

            // Since we have used our stored event, we null it out.
            storedEvent = null

            // Create a new throttle by setting a timeout to prevent handling events during the delay.
            // Once the timeout finishes, we execute our throttle if we have a stored event.
            throttleTimeout = setTimeout(() => {
                // We immediately null out the throttleTimeout since the throttle time has expired.
                throttleTimeout = null

                // If we have a stored event, recursively call this function.
                // The recursion is what allows us to run continusously while events are present.
                // If events stop coming in, our throttle will end. It will then execute immediately if a new event ever comes.
                if (storedEvent) {
                    // Since our timeout finishes:
                    // 1. This recursive call will execute `callback` immediately since throttleTimeout is now null
                    // 2. It will restart the throttle timer, allowing us to repeat the throttle process
                    throttledEventHandler(storedEvent)
                }
            }, limit)
        }
    }

    // Return our throttled event handler as a closure
    return throttledEventHandler
}

/* =========================================
 * Truncate text
 */

export const truncateText = (text: string, maxLength: number) => {
    return text.substring(0, maxLength - 3) + '...'
}

/* =========================================
 * Color luminosity
 */

type ColorLuminosity = 'light' | 'dark' | 'super-dark'

export const determineColorLuminosity = (hexColor: string): ColorLuminosity => {
    const luminance = Color(hexColor).luminosity()

    // These "magic numbers" are taken from https://www.w3.org/TR/WCAG20/#relativeluminancedef
    // and are custom opinions. Edit if necessary.
    if (luminance > 0.03928) {
        return 'light'
    } else if (luminance < 0.03928 && luminance > 0.01964) {
        return 'dark'
    }
    // Less than 0.01964 luminance
    else {
        return 'super-dark'
    }
}

/* =========================================
 * Object difference
 */

export function objectDifference(object: any, base: any) {
    function changes(object: any, base: any) {
        return transform(object, function (result: any, value, key) {
            if (isEqual(value, base[key])) {
                result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
            }
        })
    }
    return changes(object, base)
}

/* =========================================
 * Complex type check
 */
function isComplexType(value: any) {
    return (
        isArray(value) ||
        isDate(value) ||
        isFunction(value) ||
        isRegExp(value) ||
        isSymbol(value) ||
        isMap(value) ||
        isSet(value)
    )
}

/* =========================================
 * Deep merge custom
 */

/**
 * Deep merge own properties of a "target" (base) object with any number of "source" objects.
 * Overwrites Array, Date, Function, RegExp, Symbol, Map, and Set values completely without merging.
 * Undefined property values are NOT ignored and will be assigned to the target.
 * This function MUTATES the target object.
 *
 * @param target The target (base) object.
 * @param sources The object(s) containing the changes to be deeply merged.
 * @return Mutated target object with deep merged values.
 */
export function deepMergeCustom<T extends Record<string, any>, U extends Record<string, any>[]>(
    target: T | undefined | null,
    ...sources: U
): T & U[number] {
    if (target === undefined || target === null) {
        target = {} as Extract<T, Record<string, any>>
    } else if (typeof target === 'object') {
        target = target as Extract<T, Record<string, any>>
    } else if (typeof target !== 'object') {
        throw new Error('Target must have a non-primitive value.')
    }

    for (const source of sources) {
        if (typeof source !== 'object' || source === null) {
            throw new Error('All sources must be objects.')
        }

        for (const _key in source) {
            // Skip prototype properties
            if (source.hasOwnProperty(_key) === false) continue

            // Avoid prototype pollution
            if (['__proto__', 'constructor', 'prototype'].includes(_key)) continue

            const sourceValue = source[_key]

            // If target's value is of a primitive type, assign source's value to it without further checks.
            if (typeof target[_key] !== 'object') {
                ;(target as Record<string, any>)[_key] = sourceValue
            }
            // Over-write "complex" types without merging.
            else if (isComplexType(sourceValue)) {
                ;(target as Record<string, any>)[_key] = sourceValue
            }
            // If a property has a primitive value, "undefined", or "null", assign these to the target.
            else if (typeof sourceValue !== 'object' || sourceValue === undefined || sourceValue === null) {
                ;(target as Record<string, any>)[_key] = sourceValue
            }
            // Recursively merge other, simple property values.
            else {
                ;(target as Record<string, any>)[_key] = deepMergeCustom(target[_key], sourceValue)
            }
        }
    }

    return target
}
