// Libraries
import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
// Components and features
import PageCard from 'components/layouts/PageCard'
import WithErrorBoundary from 'components/wrappers/WithErrorBoundary'
import ReportView from 'features/report-designer/view/ReportView'
// Custom logic
// Assets and styles

export default function ReportViewPage() {
    const location = useLocation()
    const currentPathRef = useRef(location.pathname)

    return (
        <Box
            sx={(theme) => ({
                flexGrow: 1,

                minHeight: 0,

                backgroundColor: 'common.bg_4',
            })}
        >
            <WithErrorBoundary
                errorRecovery={{
                    type: 'navigate',
                    path: currentPathRef.current,
                }}
            >
                <ReportView />
            </WithErrorBoundary>
        </Box>
    )
}
