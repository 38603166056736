//* ======= Libraries
import React, { useContext, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import WithErrorBoundary from 'components/wrappers/WithErrorBoundary'
import ReportDesigner from 'features/report-designer/ReportDesigner'
import ReportView_Temp from 'features/report-designer/view/ReportView_Temp'
//* ======= Custom logic
import { ProjectContext } from 'contexts/ProjectContext'
import { PERMISSIONS } from 'helpers/constants'
//* ======= Assets and styles

export default function Report() {
    const location = useLocation()
    const currentPathRef = useRef(location.pathname)
    const { hasPermission } = useContext(ProjectContext)

    const canUpdateReport = useMemo(() => hasPermission(PERMISSIONS.report.UpdateReport), [hasPermission])

    return (
        <PageCard>
            <PageCard.Body noPaddingRight>
                <WithErrorBoundary
                    errorRecovery={{
                        type: 'navigate',
                        path: currentPathRef.current,
                    }}
                >
                    {canUpdateReport ? <ReportDesigner /> : <ReportView_Temp />}
                </WithErrorBoundary>
            </PageCard.Body>
        </PageCard>
    )
}
