//* ======= Libraries
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import SearchIcon from '@mui/icons-material/Search'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { isEmailValid } from 'helpers/validators'
import { ProjectAccountCreateType, ProjectRoleWithPermissionType } from 'services/ProjectService'

interface IAddUsersDialog {
    closeButtonLabel?: string
    roles: ProjectRoleWithPermissionType[]
    onClose?: () => void
    onConfirm?: (accounts: ProjectAccountCreateType[]) => void
}

const StyledPermissionInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        width: '14ch',
        padding: theme.spacing(1, 2),
        fontSize: 16,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.text_4,

        '&:focus': {},
    },
}))

function AddUsersDialog({ onConfirm, onClose, roles, closeButtonLabel = 'Skip' }: IAddUsersDialog) {
    const [email, setEmail] = useState('')

    const [users, setUsers] = useState<ProjectAccountCreateType[]>([])

    const addUser = () => {
        const temp = email
        isEmailValid(email) && setUsers((u) => [{ email: temp, roleID: -1 }, ...u])
        setEmail('')
    }

    const removeUser = (index: number) => {
        setUsers((u) => {
            const temp = [...u]
            temp.splice(index, 1)
            return temp
        })
    }

    const usersValid = () => users.every((u) => u.roleID !== -1)

    const updateUserRole = (index: number) => (role: any) => {
        setUsers((u) => {
            const temp = [...u]
            temp[index].roleID = role
            return temp
        })
    }

    return (
        <Stack
            gap={2}
            py={2}
            px={4}
            minHeight={260}
            sx={(theme) => ({
                // @Theme conditional
                backgroundColor:
                    theme.palette.mode === 'light' ? theme.palette.common.bg_2 : theme.palette.common.bg_3,
            })}
        >
            {/* Search and add field
				========================================= */}
            <Stack direction="row" alignItems="stretch" marginBottom={1}>
                {/*
                    // TODO:
                    // 1.   Define custom behaviour like 'clearOnBlur' etc. later.
                    // 2.   Style for the options (if needed).
                */}
                {/* <Autocomplete
                    disablePortal
                    id="users-list"
                    options={autocompleteOptions}
                    onChange={(evt, value) => value && console.log(value)}
                    renderInput={(params) => (
                        <Box
                            ref={params.InputProps.ref}
                            sx={{
                                height: '100%',
                                width: '100%',
                                py: 0.5,
                                paddingRight: 1,
                                paddingLeft: 2,

                                borderTopLeftRadius: '10px',
                                borderBottomLeftRadius: '10px',
                                backgroundColor: 'common.bg_4',
                            }}
                        >
                            <InputBase
                                autoFocus
                                name="users-list"
                                placeholder="Invite by name or email"
                                fullWidth
                                startAdornment={
                                    <SearchIcon
                                        sx={{
                                            marginRight: 1,

                                            color: 'secondary.main',
                                        }}
                                    />
                                }
                                inputProps={{ ...params.inputProps }}
                                sx={{
                                    height: '100%',
                                }}
                            />
                        </Box>
                    )}
                    sx={{
                        flexGrow: 1,
                    }}
                /> */}
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '100%',
                        py: 0.5,
                        paddingRight: 1,
                        paddingLeft: 2,
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        backgroundColor: 'common.bg_4',
                    }}
                >
                    <InputBase
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="users-list"
                        placeholder="Invite by email"
                        fullWidth
                        startAdornment={
                            <SearchIcon
                                sx={{
                                    marginRight: 1,

                                    color: 'secondary.main',
                                }}
                            />
                        }
                        sx={{
                            height: '100%',
                        }}
                    />
                </Box>

                <BaseButton
                    variant="contained"
                    size="large"
                    label="Add"
                    disabled={!isEmailValid(email)}
                    disableElevation
                    onClick={addUser}
                    sx={{
                        width: 120,
                        py: 1.5,

                        borderRadius: '10px',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                />
            </Stack>

            {/* Users list
				========================================= */}
            <Stack gap={1} marginBottom={4}>
                {/* List item
				    ========================================= */}
                {users.map((u, idx) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Avatar
                            alt="username"
                            sx={{
                                height: 40,
                                width: 40,
                            }}
                        />

                        {/* User label
				        ========================================= */}
                        <Typography fontSize={14} fontWeight={400} color="common.text_1">
                            {u.email}
                        </Typography>

                        {/* Controls
				        ========================================= */}
                        <Stack direction="row" alignItems="center" gap={2} marginLeft="auto">
                            {/* Remove button
				            ========================================= */}
                            <BaseButton label="Remove" onClick={() => removeUser(idx)} />

                            {/* Permissions select
				            ========================================= */}
                            <Select
                                id="user-permission-select"
                                value={u.roleID}
                                displayEmpty
                                onChange={(e) => updateUserRole(idx)(e.target.value)}
                                input={<StyledPermissionInput />}
                                IconComponent={ExpandMoreIcon}
                                sx={{
                                    '& .MuiSelect-icon': {
                                        color: 'common.fill_4',
                                    },
                                }}
                            >
                                <MenuItem disabled={true} value={-1}>
                                    <em>Select a Role</em>
                                </MenuItem>
                                {roles.map((_option, idx) => (
                                    <MenuItem key={_option.primaryKey} value={_option.primaryKey}>
                                        {_option.roleName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Stack>
                ))}
            </Stack>

            {/* Footer buttons
				========================================= */}
            <Stack marginTop="auto" direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                <BaseButton
                    variant="outlined"
                    size="small"
                    label={closeButtonLabel}
                    onClick={(e) => onClose && onClose()}
                    sx={{
                        width: 186,
                        py: 1.5,
                    }}
                />

                <BaseButton
                    variant="contained"
                    size="small"
                    disabled={!usersValid()}
                    label="Invite"
                    onClick={(e) => onConfirm && onConfirm(users)}
                    sx={{
                        width: 186,
                        py: 1.5,
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default AddUsersDialog
