//* ======= Libraries
import React from 'react'
import { Stack, Box, Divider } from '@mui/material'
//* ======= Components and features
import ReportSlidesList from 'features/report-designer/slides-list/ReportSlidesList'
import ReportDataSources from 'features/report-designer/data-sources/ReportDataSources'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
//* ======= Assets and styles

function ReportLeftSidebar() {
    const viewMode = useReportStore((store) => store.viewMode)

    return (
        <Stack
            gap={1}
            sx={(theme) => ({
                height: '100%',
            })}
        >
            {/* Slide cards
                ========================================= */}
            <Box
                sx={{
                    flexGrow: 1,

                    minHeight: 0,
                }}
            >
                <ReportSlidesList />
            </Box>

            {/* Data sources
                ========================================= */}
            {viewMode === 'design' && (
                <>
                    <Divider
                        sx={(theme) => ({
                            borderColor: theme.palette.common.border_2,
                        })}
                    />

                    <Box
                        sx={{
                            flexShrink: 0,
                            minHeight: 200,
                            height: '20%',
                        }}
                    >
                        <ReportDataSources />
                    </Box>
                </>
            )}
        </Stack>
    )
}

export default ReportLeftSidebar
