import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import UpIcon from '@mui/icons-material/ArrowCircleUp'
import DownIcon from '@mui/icons-material/ArrowCircleDown'
import DeleteIcon from '@mui/icons-material/Delete'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseSelect from 'components/base/BaseSelect'
import BaseButton from 'components/base/BaseButton'

export interface FolderByDialogInterface {
    open: boolean
    onClose: () => void
    tags: string[]
    folderStructure: string[]
    setFolderStructure: (value: string[]) => void
}

// @Pourya
//ToDO fix style based on sort dialog
// ToDo imp sort based on this component

export default function FolderByDialog({
    open,
    onClose,
    tags,
    folderStructure,
    setFolderStructure,
}: FolderByDialogInterface) {
    //store the local value
    const [local, setLocal] = useState<string[]>([])

    // on open, set the default value
    useEffect(() => {
        if (open) {
            setLocal([...folderStructure])
        }
    }, [open])

    // data manipulation

    const addNewRow = () => {
        setLocal((pv) => [...pv, ''])
    }

    const updateRow = (i: number) => (v: string) => {
        let tmp = [...local]
        tmp[i] = v
        setLocal(tmp)
    }

    const removeRow = (i: number) => () => {
        let tmp = [...local]
        tmp.splice(i, 1)
        setLocal(tmp)
    }

    const moveUp = (i: number) => () => {
        if (i - 1 < 0) return
        let tmp = [...local]
        let x = tmp[i]
        tmp[i] = tmp[i - 1]
        tmp[i - 1] = x
        setLocal(tmp)
    }

    const moveDown = (i: number) => () => {
        if (i + 1 >= local.length) return
        let tmp = [...local]
        let x = tmp[i]
        tmp[i] = tmp[i + 1]
        tmp[i + 1] = x
        setLocal(tmp)
    }

    // save data
    const saveAndClose = () => {
        setFolderStructure(local)
        onClose()
    }

    return (
        <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <Stack
                gap={1}
                sx={{
                    py: 3,
                    px: 4,
                }}
            >
                {/* Title
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary">
                    Folder By:
                </Typography>

                {/* Body
				    ========================================= */}
                <Stack
                    gap={1}
                    sx={{
                        maxHeight: 400,
                        overflowY: 'auto',
                    }}
                    className="u-scrollbar"
                >
                    {/* Items list
				        ========================================= */}
                    <Stack gap={1}>
                        {Array.isArray(local) &&
                            local.map((item, index) => (
                                <Stack key={item + index} direction="row" alignItems="center" gap={4}>
                                    {/* Field select
				                        ========================================= */}
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                        }}
                                    >
                                        <BaseSelect
                                            placeholder="Field"
                                            options={tags.filter((t) => item === t || !local.includes(t))}
                                            onChangeHandler={updateRow(index)}
                                            value={item}
                                            fullWidth
                                        />
                                    </Box>

                                    {/* Item controls
				                        ========================================= */}
                                    <Stack flexShrink={0} direction="row" alignItems="center" gap={1}>
                                        {/* Move-up button
				                            ========================================= */}
                                        <IconButton disabled={index === 0} onClick={moveUp(index)}>
                                            <UpIcon />
                                        </IconButton>

                                        {/* Move-down button
				                            ========================================= */}
                                        <IconButton disabled={index === local.length - 1} onClick={moveDown(index)}>
                                            <DownIcon />
                                        </IconButton>

                                        {/* Remove button
				                            ========================================= */}
                                        <IconButton onClick={removeRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            ))}
                    </Stack>

                    {/* Add new folder button
				        ========================================= */}
                    <BaseButton
                        label="Add New Folder"
                        onClick={addNewRow}
                        disabled={local.length === tags.length}
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{
                            alignSelf: 'flex-start',
                        }}
                    />
                </Stack>

                {/* Footer
				    ========================================= */}
                <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2} paddingTop={1}>
                    {/* Close button
				        ========================================= */}
                    <BaseButton label="Close" onClick={onClose} variant="outlined" />

                    {/* Save button
				        ========================================= */}
                    <BaseButton label="Save" onClick={saveAndClose} variant="contained" />
                </Stack>
            </Stack>
        </StyledDialog>
    )
}
