import React, { useState, useRef, useMemo } from 'react'
import { IconButton, Tooltip, Box, Stack, Typography, alpha } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import BaseButton from 'components/base/BaseButton'
import useDebouncedResizeObserver from 'hooks/useDebouncedResizeObserver'
import { uniq } from 'lodash'
import ArrowIcon from '@mui/icons-material/ArrowRightAlt'

type RelationType = {
    title: string
    color: string
    active: boolean
}

const NetworkEdgeLegend: React.FC = () => {
    const { edgeStyle, edgeRenders } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const legendRef = useRef<HTMLDivElement>(null)

    const legendSize = useDebouncedResizeObserver({
        ref: legendRef,
        wait: 250,
        roundingFunction: (number) => Math.floor(number),
    })

    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(false)

    const relations: RelationType[] = useMemo(() => {
        const result: RelationType[] = []
        const visibleEdges = uniq(edgeRenders.filter((x) => x.hide !== true).map((render) => render.groupKey))

        visibleEdges.forEach((name) => {
            if (name === undefined || name === 'default') return
            result.push({
                title: name,
                color: edgeStyle[name].normal.color,
                active: edgeStyle[name].visible,
            })
        })
        return result
    }, [edgeStyle, edgeRenders])

    const checkOverflow = () => {
        const element = legendRef.current
        if (element) {
            setShowLeftArrow(element.scrollLeft > 0)
            setShowRightArrow(element.scrollWidth > element.clientWidth + element.scrollLeft)
        }
    }

    React.useEffect(() => {
        checkOverflow()
    }, [legendSize])

    const toggleRelation = (index: number) => {
        dispatchContext({
            type: 'EDGE_STYLE_VISSIBLE_TOGGLE',
            payload: {
                name: relations[index].title,
            },
        })
    }

    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                height: '100%',
            })}
        >
            {showLeftArrow && (
                <Tooltip title="Scroll Left">
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (legendRef.current) {
                                legendRef.current.scrollLeft -= 100
                                checkOverflow()
                            }
                        }}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                </Tooltip>
            )}

            <Stack
                ref={legendRef}
                sx={(theme) => ({
                    display: 'flex',
                    overflowX: 'hidden',
                    flex: 1,
                    mr: showRightArrow ? 0 : 2, // to ensure spacing if no right arrow
                    ml: showLeftArrow ? 0 : 2,
                    alignItems: 'center', // Add this to vertically center the items within the Stack,
                    height: '100%',
                })}
                direction="row"
                gap={1}
                onScroll={checkOverflow}
            >
                {relations.map((relation, index) => (
                    <Stack
                        key={index}
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => toggleRelation(index)}
                    >
                        <IconButton
                            sx={{
                                minWidth: 0,
                                minHeight: 0,
                                width: 16,
                                height: 16,
                                color: relation.active ? relation.color : '#eeeeee',
                            }}
                        >
                            <ArrowIcon />
                        </IconButton>
                        <Typography variant="caption">{relation.title}</Typography>
                    </Stack>
                ))}
            </Stack>

            {showRightArrow && (
                <Tooltip title="Scroll Right">
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (legendRef.current) {
                                legendRef.current.scrollLeft += 100
                                checkOverflow()
                            }
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    )
}

export default NetworkEdgeLegend
