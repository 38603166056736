import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/system'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import CustomCKEditor from 'ckeditor5-custom-build'
import { Box, CircularProgress } from '@mui/material'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'

function customItemRenderer(item) {
    alert('tada')
    const itemElement = document.createElement('span')

    itemElement.classList.add('custom-item')
    itemElement.id = `mention-list-item-id-${item.userId}`
    itemElement.textContent = `${'test'} `

    const usernameElement = document.createElement('span')

    usernameElement.classList.add('custom-item-username')
    usernameElement.textContent = item.id

    itemElement.appendChild(usernameElement)

    return itemElement
}

export default function TextEditor({ height = '350px', defaultValue = '', onBlur, keywords = [] }) {
    const theme = useTheme()
    const editorRef = useRef(null)

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 500)
    }, [])

    useEffect(() => {
        if (editorRef.current)
            editorRef.current.editing.view.change((writer) => {
                writer.setStyle('height', height, editorRef.current.editing.view.document.getRoot())
            })
    }, [height])

    return (
        <Box width="100%" sx={{ backgroundClip: 'common.bg_1' }}>
            {isLoading ? (
                <SnaCircularLoading />
            ) : (
                <CKEditor
                    editor={CustomCKEditor.ClassicEditor}
                    data={defaultValue}
                    config={{
                        mention: {
                            dropdownLimit: 100,
                            feeds: [
                                {
                                    marker: '[',
                                    feed: keywords,
                                    minimumCharacters: 0,
                                },
                            ],
                        },
                    }}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        editorRef.current = editor
                        editor.editing.view.change((writer) => {
                            writer.setStyle('height', height, editor.editing.view.document.getRoot())
                        })
                    }}
                    onChange={(event, editor) => {}}
                    onBlur={(event, editor) => {
                        const data = editor.getData()
                        onBlur && onBlur(data)
                    }}
                    onFocus={(event, editor) => {}}
                />
            )}
        </Box>
    )
}
