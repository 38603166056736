/**
 * This class copies sigma/rendering/webgl/programs/node.fast, but with a tiny
 * difference: The fragment shader ("./node.border.frag.glsl") draws a white
 * disc with a colored border.
 */
import { floatColor } from 'sigma/utils'
import { NodeDisplayData } from 'sigma/types'
import { AbstractNodeProgram } from 'sigma/rendering/webgl/programs/common/node'
import { RenderParams } from 'sigma/rendering/webgl/programs/common/program'
import { vert, frag } from './node.border.gls'
import { BorderStyleType, ShadowStyleType } from 'features/GraphStudio/types'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'

const POINTS = 1,
    ATTRIBUTES = 7

interface ExtendedNodeDisplayData extends NodeDisplayData {
    border: NetworkVizContextType['nodeRenders'][number]['attributes']['border']
    shadow: boolean
    hide: boolean
}

export default class NodeProgramBorder extends AbstractNodeProgram {
    borderColorLocation: GLint
    nodeShadow: GLint

    constructor(gl: WebGLRenderingContext) {
        super(gl, vert, frag, POINTS, ATTRIBUTES)
        this.borderColorLocation = 3
        this.nodeShadow = 4

        this.bind()
    }

    bind(): void {
        super.bind()
        const gl = this.gl
        gl.enableVertexAttribArray(this.borderColorLocation)
        gl.enableVertexAttribArray(this.nodeShadow)

        gl.vertexAttribPointer(
            this.borderColorLocation,
            4,
            gl.UNSIGNED_BYTE,
            true,
            this.attributes * Float32Array.BYTES_PER_ELEMENT,
            16
        )

        gl.vertexAttribPointer(
            this.nodeShadow,
            2,
            gl.FLOAT,
            false,
            this.attributes * Float32Array.BYTES_PER_ELEMENT,
            20
        )
    }

    process(data: ExtendedNodeDisplayData, hidden: boolean, offset: number): void {
        const array = this.array
        let i = offset * POINTS * ATTRIBUTES

        if (hidden) {
            array[i++] = 0
            array[i++] = 0
            array[i++] = 0
            array[i++] = 0
            array[i++] = 0
            array[i] = 0
            return
        }

        const color = floatColor(data.color)

        array[i++] = data.x
        array[i++] = data.y
        array[i++] = data.size
        array[i++] = color
        array[i++] =
            data.hidden !== true && data.border?.show === true ? floatColor(data.border!.color) : color

        if (data.shadow) {
            array[i] = 1
        } else {
            array[i] = 0
        }
    }

    render(params: RenderParams): void {
        const gl = this.gl

        const program = this.program
        gl.useProgram(program)

        gl.uniform1f(this.ratioLocation, 1 / Math.sqrt(params.ratio))
        gl.uniform1f(this.scaleLocation, params.scalingRatio)
        gl.uniformMatrix3fv(this.matrixLocation, false, params.matrix)

        gl.drawArrays(gl.POINTS, 0, this.array.length / ATTRIBUTES)
    }
}
