//* ======= Libraries
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
import FreeSoloCreateOption from 'components/base/FreeSoloCreateOption'
//* ======= Custom logic
import { BasicRespondentType, CreateRequestNodeType, ImportNodesService } from 'services/NodeService'
import { EMAIL_REGEX, MOBILE_REGEX } from 'helpers/validators'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface IAddNewNodeDialog {
    onClose?: () => void
    onConfirm: () => void
    otherFields?: string[]
}

function AddNewNodeDialog({ onConfirm, onClose, otherFields = [] }: IAddNewNodeDialog) {
    const { pid } = useParams()
    const [isRespondentView, setIsRespondentView] = useState(false)
    const [title, setTitle] = useState<string>('')
    const [others, setOthers] = useState<{ title: string; value: string }[]>([])
    const [respondents, setRespondents] = useState<{ title: string; value: string }[]>([])

    useEffect(() => {
        setOthers(otherFields.map((o) => ({ title: o, value: '' })))
    }, [])

    const createNode = (respondents?: BasicRespondentType[]) => {
        const parsedOthers: { [ket: string]: any } = {}
        for (let other of others) {
            parsedOthers[other.title] = other.value
        }
        if (pid == null) return
        let node: CreateRequestNodeType = {
            title,
            others: parsedOthers,
            respondents,
        }
        ImportNodesService([node], pid).then((res) => {
            if (res.success) {
                toast.success('Node Added!')
                onConfirm()
            }
        })
    }
    return (
        <Stack
            height="100%"
            sx={{
                py: 3,
            }}
        >
            {/* Title
				========================================= */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                px={4}
                marginBottom={3}
            >
                {isRespondentView && (
                    <IconButton onClick={() => setIsRespondentView(false)}>
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Typography fontSize={25} fontWeight={600} color="primary">
                    {`Add ${isRespondentView ? 'Respondent' : 'New Node'}`}
                </Typography>

                <Typography fontSize={25} fontWeight={600} color="primary">{`${
                    isRespondentView ? '2' : '1'
                }/2`}</Typography>
            </Stack>

            {/* Content
				========================================= */}
            {isRespondentView ? (
                <AddRespondentView defaultName={title} onConfirm={createNode} onClose={onClose} />
            ) : (
                <NewNodeView
                    defaultTitle={title}
                    defaultOthers={others}
                    onConfirm={(name: string, addtionalInfo: any) => {
                        setIsRespondentView(true)
                        setOthers(addtionalInfo)
                        setTitle(name)
                    }}
                    onClose={onClose}
                />
            )}
        </Stack>
    )
}

interface INewNodeView {
    defaultTitle?: string
    defaultOthers?: { title: string; value: string }[]
    onConfirm?: (title: string, additionalInfos: { title: string; value: string }[]) => void
    onClose?: () => void
}

function NewNodeView({ defaultTitle, defaultOthers, onConfirm, onClose }: INewNodeView) {
    const [additionalInfos, setAdditionalInfos] = useState<{ title: string; value: string }[]>([])
    const [name, setName] = useState<string>(defaultTitle || '')

    useEffect(() => {
        setAdditionalInfos(defaultOthers || [])
    }, [defaultOthers])

    const onAddInfo = () => {
        setAdditionalInfos((pv) => (pv ? [...pv, { title: '', value: '' }] : [{ title: '', value: '' }]))
    }

    const onDeleteInfo = (index: number) => {
        setAdditionalInfos((pv) => pv.filter((v, i) => i != index))
    }

    const editInfo = (field: 'title' | 'value', index: number) => (value: string) => {
        setAdditionalInfos((pv) => {
            const temp = [...pv]
            temp[index] = { ...temp[index], [field]: value }
            return temp
        })
    }

    const handleNextButton = () => {
        if (name.trim().length > 1) {
            onConfirm &&
                onConfirm(
                    name,
                    additionalInfos.filter((ai) => ai.title.trim().length > 0 && ai.value.trim().length > 0)
                )
        }
    }

    return (
        <>
            {/* Node title
				========================================= */}
            <Stack gap={3} px={4}>
                {/* Name
					========================================= */}
                <BaseFilledTextField
                    label="Node Title"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                />
            </Stack>

            {/* Additional information button
                ========================================= */}
            <BaseButton
                label="Other fields"
                onClick={onAddInfo}
                startIcon={<AddIcon />}
                sx={{
                    alignSelf: 'center',

                    marginTop: 3,
                }}
            />
            {/* Additional information list
				========================================= */}
            {additionalInfos.length > 0 && (
                <Stack
                    gap={1}
                    marginTop={3}
                    px={4}
                    sx={{ maxHeight: '60vh', overflow: 'auto' }}
                    className="u-scrollbar"
                >
                    {additionalInfos.map((ai, idx) => (
                        <Stack
                            key={idx}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={3}
                        >
                            {/* Details
						        ========================================= */}
                            <Stack direction="row" alignItems="center" gap={1.5}>
                                <BaseFilledTextField
                                    size="small"
                                    label="Title"
                                    defaultValue={ai.title}
                                    onBlur={(e) => editInfo('title', idx)(e.target.value)}
                                />

                                <BaseFilledTextField
                                    size="small"
                                    label="Value"
                                    defaultValue={ai.value}
                                    onBlur={(e) => editInfo('value', idx)(e.target.value)}
                                />
                            </Stack>

                            {/* Delete
						        ========================================= */}
                            <IconButton
                                onClick={() => onDeleteInfo(idx)}
                                sx={{
                                    color: 'common.fuschia80',
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    ))}
                </Stack>
            )}

            {/* Next and close button
				========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={3} marginTop={3}>
                {/* Close button
					========================================= */}
                <BaseButton
                    label="Close"
                    onClick={() => onClose && onClose()}
                    variant="outlined"
                    sx={{
                        width: 186,
                        py: 1,
                    }}
                />

                {/* Next button
					========================================= */}
                <BaseButton
                    label="Next"
                    disabled={name.trim().length <= 1}
                    onClick={handleNextButton}
                    variant="contained"
                    sx={{
                        width: 186,
                        py: 1,
                    }}
                />
            </Stack>
        </>
    )
}

interface IAddRespondentView {
    defaultName?: string
    onConfirm: (respondents?: BasicRespondentType[]) => void
    onClose?: () => void
}

function AddRespondentView({ defaultName = '', onConfirm, onClose }: IAddRespondentView) {
    const [respondentMode, setRespondentMode] = useState('0')

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<BasicRespondentType>({ mode: 'onBlur' })

    const createNode = () => {
        if (respondentMode == '0') {
            handleSubmit(
                //Valid
                (data) => {
                    onConfirm([data])
                },
                //Invalid
                (e) => {
                    console.log(e)
                }
            )()
        } else if (respondentMode == '2') {
            onConfirm([])
        }
    }

    const fieldOptions = [
        {
            title: 'Cat 1',
        },
        {
            title: 'Cat 2',
        },
        {
            title: 'Cat 3',
        },
    ]
    const [categoryValue, setCategoryValue] = React.useState<{ inputValue?: string; title: string } | null>(
        null
    )

    const selectOptions = [
        {
            value: '',
            label: 'None',
        },
        {
            value: '1',
            label: 'One',
        },
        {
            value: '2',
            label: 'Two',
        },
    ]

    return (
        <>
            {/* Respondent mode
				========================================= */}
            <Stack gap={1} px={4} mb={2}>
                <FormControl>
                    <FormLabel id="respondent-mode-label">Respondent Mode</FormLabel>
                    <RadioGroup
                        aria-labelledby="respondent-mode"
                        value={respondentMode}
                        row
                        onChange={(e, v) => setRespondentMode(v)}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="0" control={<Radio />} label="Single" />
                        <FormControlLabel value="1" control={<Radio />} label="Multiple" disabled={true} />
                        <FormControlLabel value="2" control={<Radio />} label="None" />
                    </RadioGroup>
                </FormControl>
            </Stack>
            {/* Single respondent
				========================================= */}

            {respondentMode == '0' && (
                <Stack gap={1} px={4}>
                    {/* Title
					========================================= */}
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={defaultName}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <BaseFilledTextField
                                label="Fullname"
                                required
                                variant="filled"
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{
                            required: 'Fullname is required',
                            minLength: { value: 2, message: 'Fullname should be at least 2 characters' },
                            maxLength: {
                                value: 50,
                                message: 'Fullname should not be more than 50 characters',
                            },
                        }}
                    />

                    {/* Email
                    ========================================= */}
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <BaseFilledTextField
                                label="Email Address"
                                variant="filled"
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{
                            pattern: {
                                message: 'Not a valid email address',
                                value: EMAIL_REGEX,
                            },
                        }}
                    />

                    {/* Phone
                    ========================================= */}
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <BaseFilledTextField
                                label="Phone number"
                                value={value}
                                fullWidth
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? error.message : '+xx xxx xxx-xxx'}
                            />
                        )}
                        rules={{
                            pattern: {
                                message: 'Not a valid mobile number',
                                value: MOBILE_REGEX,
                            },
                        }}
                    />
                </Stack>
            )}

            {/* Multi respondent
				========================================= */}
            {respondentMode == '1' && (
                <React.Fragment>
                    <Stack gap={1} px={4}>
                        {/* Category
					========================================= */}
                        <Box marginBottom={1}>
                            <FreeSoloCreateOption
                                options={fieldOptions}
                                value={categoryValue?.title ? categoryValue : null}
                                setValue={(value) => setCategoryValue(value ? { title: value } : null)}
                                label="Add Respondent"
                            />
                        </Box>

                        {/* (Conditional) List
					========================================= */}
                        {Array.from(Array(3)).map((_, idx) => (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                                paddingLeft={1}
                            >
                                {/* Details
							========================================= */}
                                <Stack direction="row" alignItems="center" gap={1.5}>
                                    <Typography fontSize={16} fontWeight={400} color="common.text_1">
                                        01
                                    </Typography>

                                    <Typography fontSize={16} fontWeight={400} color="common.text_1">
                                        SNA
                                    </Typography>
                                </Stack>

                                {/* Edit and delete
							========================================= */}
                                <Stack direction="row" alignItems="center" gap={0.5}>
                                    {/* Edit button
								========================================= */}
                                    <IconButton
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                    >
                                        <EditIcon
                                            sx={{
                                                height: 18,
                                                width: 18,
                                            }}
                                        />
                                    </IconButton>

                                    {/* Delete button
								========================================= */}
                                    <IconButton
                                        sx={{
                                            color: 'common.fuschia80',
                                        }}
                                    >
                                        <DeleteIcon
                                            sx={{
                                                height: 18,
                                                width: 18,
                                            }}
                                        />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>

                    {/* (Conditional) New respondent details
				========================================= */}
                    <Stack
                        gap={3}
                        sx={(theme) => ({
                            marginTop: theme.spacing(3),
                            py: theme.spacing(3),
                            px: theme.spacing(4),

                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light'
                                    ? theme.palette.common.ung_blue_one
                                    : theme.palette.common.bg_3,
                        })}
                    >
                        <Typography fontSize={16} fontWeight={400} color="common.text_1">
                            Additional New Respondent
                        </Typography>

                        {/* Title
					========================================= */}
                        <BaseFilledTextField label="Fullname" />

                        {/* Value
					========================================= */}
                        <BaseFilledTextField label="Email" />

                        {/* Phone
					========================================= */}
                        <BaseFilledTextField label="Phone number e.g. +x-xxx xxx xxxx" />

                        {/* Add and cancel buttons
					========================================= */}
                        <Stack direction="row" justifyContent="center" alignItems="center" gap={3}>
                            {/* Cancel button
						========================================= */}
                            <BaseButton
                                label="Cancel"
                                variant="outlined"
                                sx={{
                                    alignSelf: 'center',

                                    width: 186,
                                    py: 1,
                                }}
                            />

                            {/* Add button
						========================================= */}
                            <BaseButton
                                label="Add"
                                variant="contained"
                                sx={{
                                    alignSelf: 'center',

                                    width: 186,
                                    py: 1,
                                }}
                            />
                        </Stack>
                    </Stack>
                </React.Fragment>
            )}

            {/* Quick add to survey, close and create
				========================================= */}
            <Stack gap={1} marginTop={3} px={4}>
                {false && respondentMode != '2' && (
                    <React.Fragment>
                        <Typography fontSize={16} fontWeight={400} color="common.text_1">
                            Quick add to survey
                        </Typography>

                        {/* Quick add to survey select
					========================================= */}
                        <Select
                            defaultValue={''}
                            displayEmpty
                            input={<BasicSelectInput />}
                            IconComponent={ExpandMoreIcon}
                            sx={{
                                marginBottom: 2,

                                '& .MuiInput-input': {
                                    py: 2,
                                    fontSize: 18,
                                },

                                '& .MuiSelect-icon': {
                                    color: 'primary.main',
                                },
                            }}
                        >
                            {selectOptions.map((_option, idx) => (
                                <MenuItem key={_option.label + idx} value={_option.value || ''}>
                                    {_option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </React.Fragment>
                )}
                {/* Create and close button
					========================================= */}
                <Stack direction="row" justifyContent="center" alignItems="center" gap={3}>
                    {/* Close button
						========================================= */}
                    <BaseButton
                        label="Close"
                        onClick={() => onClose && onClose()}
                        variant="outlined"
                        sx={{
                            width: 186,
                            py: 1,
                        }}
                    />

                    {/* Create button
						========================================= */}
                    <BaseButton
                        label="Create"
                        onClick={createNode}
                        variant="contained"
                        sx={{
                            width: 186,
                            py: 1,
                        }}
                    />
                </Stack>
            </Stack>
        </>
    )
}

export default AddNewNodeDialog
