import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Typography,
    Stack,
    DialogContent,
    DialogActions,
    Box,
    Tooltip,
    IconButton,
    FormControl,
    FormLabel,
} from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { useNetworkVizContext, useNetworkVizDispatch } from '../../context/NetworkVizContext'
import { EdgeType, NodeAttributeType } from '../../types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import BaseButton from 'components/base/BaseButton'
import { toast } from 'react-toastify'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { Dictionary, groupBy } from 'lodash'
import FlexibleSelect, {
    FlexibleSelectGroupedOptionType,
    FlexibleSelectOptionItemType,
} from 'components/group-field-selector/FlexibleSelect'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { DeepPartial } from 'react-hook-form'
import { deepMergeCustom } from 'helpers/helpers'
import WarningIcon from '@mui/icons-material/Warning'
import { applyAggregation } from 'features/report-designer/helpers/reportDesigner.helper'
import { ReportDataSourceRowType } from 'features/report-designer/types/reportDesigner.types'
import { applySenderReceiverFiltersToEdges } from './NetworkManipulationHelper'

// Define the properties that this component expects
interface FilterSenderReceiverDialogProps {
    onClose: () => void
}

const FilterSenderReceiverDialog: React.FC<FilterSenderReceiverDialogProps> = ({ onClose }) => {
    // Use the network visualization context
    const { edges, nodes, analyticsSettings, nodeDataSchema, analytics, ...networkVizContext } = useNetworkVizContext()

    // Use the network visualization dispatch
    const dispatchContext = useNetworkVizDispatch()

    // Define the state for this component
    const [relationName, setRelationName] = useState<string>('')
    const [baseNetwork, setBaseNetwork] = useState<string>('')
    const [isRunning, setIsRunning] = useState<boolean>(false)
    const [networks, setNetworks] = useState<Dictionary<EdgeType[]>>({})

    const [networkOptions, setNetworkOptions] = useState<string[]>([])

    const [senderFilters, setSenderFilters] = useState<SenderreceiverFilterType[]>([])
    const [receiverFilters, setreceiverFilters] = useState<SenderreceiverFilterType[]>([])

    const selectOptions = useMemo(() => {
        const options: FlexibleSelectGroupedOptionType[] = []

        if (nodeDataSchema) {
            const items: FlexibleSelectOptionItemType[] = []
            for (const key in nodeDataSchema.fields) {
                items.push({
                    label: key,
                    value: {
                        source: 'info',
                        field: key,
                    } as NodeAttributeType,
                })
            }
            options.push({
                group: 'Node Attribute',
                items,
            })
        }

        if (analytics) {
            for (const network in analytics) {
                const items: FlexibleSelectOptionItemType[] = []
                for (const key in analytics[network].schema) {
                    items.push({
                        label: key,
                        value: {
                            source: 'analytic',
                            relationship: network,
                            field: key,
                        } as NodeAttributeType,
                    })
                }
                options.push({
                    group: `Analytics ${network}`,
                    items,
                })
            }
        }

        return options
    }, [analytics, nodeDataSchema])

    useEffect(() => {
        const _edges = Object.values(edges)
        const _networks = groupBy(_edges, analyticsSettings.groupBy)
        setNetworks(_networks)
        setNetworkOptions(Object.keys(_networks))
    }, [analyticsSettings.groupBy, edges])

    // Determine whether the "Run" button should be disabled
    const isRunDisabled = useMemo(() => {
        if (baseNetwork.trim() === '' || relationName.trim() === '') return true
        if (senderFilters.length === 0 && receiverFilters.length === 0) return true
        for (const filter of senderFilters) {
            if (!isFilterValid(filter)) return true
        }
        for (const filter of receiverFilters) {
            if (!isFilterValid(filter)) return true
        }
    }, [senderFilters, baseNetwork, relationName, receiverFilters])

    // Handler for when the "Apply Advance Analytics" operation is performed
    const handleApplyAdvanceAnalytics = async () => {
        if (isRunDisabled) return
        setIsRunning(true)
        const network = networks[baseNetwork]
        // Create a map to count occurrences of each edge
        const newEdges: EdgeType[] = applySenderReceiverFiltersToEdges(
            network,
            senderFilters,
            receiverFilters,
            nodes,
            analytics
        )

        if (newEdges.length === 0) {
            toast.error('No edges found with the given filters.')
            setIsRunning(false)
            return
        }

        // Create a worker to add the edges
        const worker = new WebWorker('workers/network/add-edges.js')

        const ToastCloseButton = ({ closeToast }: { closeToast?: () => void }) => (
            <BaseButton
                onClick={() => {
                    worker.terminate()
                    closeToast && closeToast()
                }}
                label="Cancel"
            />
        )

        const toastId = toast.loading('Calculating Network Analytics', {
            // add close button to toast
            closeButton: <ToastCloseButton />,
        })

        try {
            const { nodeRenders, edgeStyle, edgeGroupBy, layoutKind, filters, nodeInteractionConfig } =
                networkVizContext
            const response = (await worker.run({
                nodeRenders,
                nodes,
                edges,
                edgeStyle,
                edgeGroupBy,
                newEdges: newEdges,
                relationship: relationName,
                sourceField: 'source',
                targetField: 'target',
                layoutKind,
                filters,
                isDirected: nodeInteractionConfig.directed,
                analyticsSettings,
            })) as any

            dispatchContext({
                type: 'EDGE_ADD',
                payload: {
                    edges: response.edges,
                    edgeRenders: response.edgeRenders,
                    edgeLegend: response.edgeLegend,
                    edgeStyle: response.edgeStyle,
                    edgeDataSchema: response.edgeSchema,
                    nodeRenders: response.nodeRenders,
                    nodeAdjacentList: response.nodeAdjacentList,
                    filters: response.filters,
                    analyticsSettings: response.analyticsSettings,
                },
            })

            toast.update(toastId, {
                render: 'Successfully calculated the intersection network.',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        } catch {
            toast.update(toastId, {
                render: 'Failed to calculate the intersection network.',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        }
        setIsRunning(false)
        handleClose()
    }

    // Handler for when the "Close Dialog" operation is performed
    const handleClose = () => {
        setBaseNetwork('')
        setRelationName('')
        setSenderFilters([])
        setreceiverFilters([])
        onClose()
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Typography variant="h6">Filter Ties</Typography>
                <Typography>
                    Returns a new graph that contains the edges that meets the requirment in sender and receiver
                    filters.
                </Typography>
                <Stack direction="row" gap={3} spacing={2} mt={3}>
                    <BaseFilledTextField
                        label="Relationship Name"
                        value={relationName}
                        onChange={(e) => setRelationName(e.target.value)}
                        fullWidth
                        helperText="The name of the new network."
                    />
                    <BaseSelectWithLabel
                        value={baseNetwork}
                        onChange={(e) => setBaseNetwork(e)}
                        fullWidth
                        label="Networks"
                        options={networkOptions}
                        helperText="Select the network to filter."
                    />
                </Stack>
                <Stack gap={3} spacing={2} mt={3}>
                    <FilterBuilder
                        mode="sender"
                        filters={senderFilters}
                        setFilters={setSenderFilters}
                        selectOptions={selectOptions}
                    />
                    <FilterBuilder
                        mode="receiver"
                        filters={receiverFilters}
                        setFilters={setreceiverFilters}
                        selectOptions={selectOptions}
                    />
                </Stack>
            </DialogContent>

            <DialogActions>
                <BaseButton color="secondary" onClick={handleClose}>
                    Close
                </BaseButton>
                <BaseButton
                    variant="contained"
                    disabled={isRunDisabled || isRunning}
                    color="primary"
                    onClick={handleApplyAdvanceAnalytics}
                >
                    Run
                </BaseButton>
            </DialogActions>
        </React.Fragment>
    )
}

export type SenderreceiverFilterType = {
    attribute: {
        mode: 'attribute' | 'difference'
        value: NodeAttributeType | null
    }
    operator: 'eq' | 'ne' | 'gt' | 'lt' | 'gte' | 'lte'
    value:
        | {
              mode: 'constant'
              value: string | number | null
          }
        | {
              mode: 'attribute'
              node: 'sender' | 'receiver'
              value: NodeAttributeType | null
          }
        | {
              mode: 'aggregate'
              method: 'avg' | 'min' | 'max' | 'q1' | 'median' | 'q3'
              value: NodeAttributeType | null
          }
}

const DefaultValues: Record<SenderreceiverFilterType['value']['mode'], SenderreceiverFilterType['value']> = {
    constant: { mode: 'constant', value: null },
    attribute: { mode: 'attribute', node: 'sender', value: null },
    aggregate: { mode: 'aggregate', method: 'avg', value: null },
}

const getModeFromString = (mode: string): SenderreceiverFilterType['value']['mode'] => {
    if (mode === 'constant') return 'constant'
    if (mode === 'attribute') return 'attribute'
    if (mode === 'aggregate') return 'aggregate'
    return 'constant'
}

const isFilterValid = (filter: SenderreceiverFilterType) => {
    if (filter.attribute.value === null) return false
    if (filter.value.value === null) return false

    return true
}

type FilterBuilderProps = {
    filters: SenderreceiverFilterType[]
    setFilters: React.Dispatch<React.SetStateAction<SenderreceiverFilterType[]>>
    selectOptions: FlexibleSelectGroupedOptionType[]
    mode: 'sender' | 'receiver'
}

const FilterBuilder: React.FC<FilterBuilderProps> = ({ selectOptions, mode, filters, setFilters }) => {
    const handleAddFilter = () => {
        setFilters([
            ...filters,
            { attribute: { mode: 'attribute', value: null }, operator: 'eq', value: { mode: 'constant', value: null } },
        ])
    }

    const updateFilterItem = (index: number, data: DeepPartial<SenderreceiverFilterType>) => {
        setFilters((pv) => {
            const tmpFilters = structuredClone(pv)
            tmpFilters[index] = deepMergeCustom(tmpFilters[index], data)
            return tmpFilters
        })
    }

    return (
        <Stack gap={1} sx={{ width: '100%' }}>
            {/* Header */}
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Filter {mode}</Typography>
                <Tooltip title="Add Filter" arrow>
                    <IconButton onClick={handleAddFilter}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            {/* Filters */}
            {filters.map((filter, index) => {
                const valid = isFilterValid(filter)
                return (
                    <Stack
                        direction="row"
                        gap={1}
                        key={index}
                        sx={(theme) => {
                            return {
                                border: '1px solid',
                                borderColor: theme.palette.grey[300],
                                padding: '10px',
                                borderRadius: '5px',
                                position: 'relative',
                            }
                        }}
                    >
                        {!valid && (
                            <Box sx={{ position: 'absolute', top: 2, right: 2 }}>
                                <Tooltip title="Invalid Filter" enterDelay={500} enterNextDelay={500}>
                                    <WarningIcon color="error" />
                                </Tooltip>
                            </Box>
                        )}
                        {/* Source Attribute  */}
                        <FormControl component="fieldset" variant="outlined" fullWidth>
                            <Stack gap={1}>
                                <FormLabel component="legend">Filter Attribute</FormLabel>
                                <BaseSelectWithLabel
                                    value={filter.attribute.mode}
                                    onChange={(value) => updateFilterItem(index, { attribute: { mode: value } })}
                                    fullWidth
                                    label="Filter Mode"
                                    options={[
                                        {
                                            label: 'Value',
                                            value: 'attribute',
                                        },
                                        {
                                            label: 'Difference',
                                            value: 'difference',
                                        },
                                    ]}
                                />
                                {/* Filter Attribute */}
                                <FlexibleSelect
                                    options={selectOptions}
                                    value={filter.attribute.value}
                                    onChange={(value) => updateFilterItem(index, { attribute: { value: value } })}
                                    fullWidth
                                    label="Attribute"
                                />
                            </Stack>
                        </FormControl>
                        {/* Filter Operator */}
                        <FormControl component="fieldset" variant="outlined" fullWidth>
                            <Stack gap={1}>
                                <FormLabel component="legend">Operator</FormLabel>
                                <BaseSelectWithLabel
                                    value={filter.operator}
                                    onChange={(value) => updateFilterItem(index, { operator: value })}
                                    fullWidth
                                    hiddenLabel
                                    options={[
                                        { label: 'Equal', value: 'eq' },
                                        { label: 'Not Equal', value: 'ne' },
                                        { label: 'Greater Than', value: 'gt' },
                                        { label: 'Greater Than or Equal', value: 'gte' },
                                        { label: 'Less Than', value: 'lt' },
                                        { label: 'Less Than or Equal', value: 'lte' },
                                    ]}
                                />
                            </Stack>
                        </FormControl>

                        {/* Filter Value */}
                        <FormControl component="fieldset" variant="outlined" fullWidth>
                            <Stack gap={1}>
                                <FormLabel component="legend">Comparison Value</FormLabel>

                                <Stack direction="row" gap={1}>
                                    <BaseSelectWithLabel
                                        value={filter.value.mode}
                                        onChange={(value: string) =>
                                            updateFilterItem(index, { value: DefaultValues[getModeFromString(value)] })
                                        }
                                        options={[
                                            { label: 'Constant', value: 'constant' },
                                            { label: 'Attribute', value: 'attribute' },
                                            { label: 'Aggregate', value: 'aggregate' },
                                        ]}
                                        fullWidth
                                        label="Mode"
                                    />

                                    {filter.value.mode === 'attribute' && (
                                        <BaseSelectWithLabel
                                            value={mode}
                                            label="Node"
                                            options={[
                                                { label: 'Sender', value: 'sender' },
                                                { label: 'receiver', value: 'receiver' },
                                            ]}
                                            fullWidth
                                            onChange={(value) => {
                                                updateFilterItem(index, { value: { node: value } })
                                            }}
                                        />
                                    )}

                                    {filter.value.mode === 'aggregate' && (
                                        <BaseSelectWithLabel
                                            value={filter.value.method}
                                            onChange={(value) => updateFilterItem(index, { value: { method: value } })}
                                            fullWidth
                                            label="Method"
                                            options={[
                                                { label: 'Average', value: 'avg' },
                                                { label: 'Minimum', value: 'min' },
                                                { label: 'Maximum', value: 'max' },
                                                { label: 'Q1', value: 'q1' },
                                                { label: 'Median', value: 'median' },
                                                { label: 'Q3', value: 'q3' },
                                            ]}
                                        />
                                    )}
                                </Stack>

                                {filter.value.mode === 'constant' && (
                                    <BaseFilledTextField
                                        value={filter.value.value}
                                        onChange={(e) =>
                                            updateFilterItem(index, {
                                                value: { mode: 'constant', value: e.target.value },
                                            })
                                        }
                                        fullWidth
                                        label="Value"
                                    />
                                )}

                                {(filter.value.mode === 'aggregate' || filter.value.mode === 'attribute') && (
                                    <FlexibleSelect
                                        options={selectOptions}
                                        value={filter.value.value}
                                        onChange={(value) => updateFilterItem(index, { value: { value: value } })}
                                        fullWidth
                                        label="Attribute"
                                    />
                                )}
                            </Stack>
                        </FormControl>

                        {/* Delete Filter */}

                        <Tooltip title="Delete Filter" arrow>
                            <IconButton
                                sx={{ height: '40px', alignSelf: 'center' }}
                                onClick={() => setFilters((pv) => pv.filter((_, i) => i !== index))}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            })}
        </Stack>
    )
}

export default FilterSenderReceiverDialog
