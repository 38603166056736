//* ======= Libraries
import React, { useContext, useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { useParams } from 'react-router-dom'
import { SurveyContext } from 'contexts/SurveyContex'
import { PauseSurveyService } from 'services/SurveyService'
import { toast } from 'react-toastify'
//* ======= Custom logic
//* ======= Assets and styles

interface IPauseSurveyDialog {
    onClose: () => void
}

function PauseSurveyDialog({ onClose }: IPauseSurveyDialog) {
    const { pid, sid } = useParams()
    const [verificationText, setVerificationText] = useState('')
    const { updateSurvey } = useContext(SurveyContext)

    const pauseSurvey = () => {
        const toastId = toast.loading('Resuming survey...')

        if (pid != null && sid != null && verificationText.toLocaleLowerCase() === 'pause') {
            PauseSurveyService(pid, sid)
                .then((res) => {
                    if (res.success) {
                        updateSurvey({
                            status: 'paused',
                        })
                        onClose && onClose()
                        toast.update(toastId, {
                            type: 'success',
                            render: 'Survey paused successfully!',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    } else {
                        toast.update(toastId, {
                            render: 'Failed to pause survey!',
                            type: 'error',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    }
                })
                .catch((err) => {
                    toast.update(toastId, {
                        render: 'Failed to pause survey!',
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000,
                        closeButton: null,
                    })
                })
        }
    }
    return (
        <Stack gap={2} minWidth={0} py={5} px={6} width={752}>
            {/* Title
        ========================================= */}
            <Typography alignSelf="flex-start" fontSize={36} fontWeight={600} color="common.fuschia80">
                Pause Data Collection
            </Typography>

            <Typography fontSize={16} fontWeight={400} color="common.text_1">
                Are you sure you want to pause data collection for this survey?
            </Typography>

            <Typography paragraph fontSize={16} fontWeight={400} color="common.text_1" marginBottom={1}>
                By pressing the "Pause Data Collection" button, this survey will be temporarily paused, and no
                participants will be able to submit responses during this time. You can resume it later. Are you sure
                you want to perform this action? Write{' '}
                <Box component="span" fontWeight={600}>
                    PAUSE
                </Box>{' '}
                in the textbox below to proceed with the action.
            </Typography>

            {/* Input
        ========================================= */}
            <BaseFilledTextField
                hiddenLabel
                fullWidth
                value={verificationText}
                onChange={(e) => setVerificationText(e.target.value)}
                sx={{
                    alignSelf: 'stretch',
                }}
            />

            {/* Notify checkbox
        ========================================= */}
            <FormGroup
                sx={{
                    marginBottom: 1,
                }}
            >
                <FormControlLabel
                    label="Notify Participants"
                    control={<Checkbox />}
                    sx={{
                        alignSelf: 'flex-start',
                        marginRight: 0,
                    }}
                />
            </FormGroup>

            {/* Controls
        ========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                {/* Cancel button
            ========================================= */}
                <BaseButton
                    label="Cancel"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    color="warning"
                    size="large"
                    width="186px"
                />

                {/* Pause Data Collection button
            ========================================= */}
                <BaseButton
                    label="Pause Data Collection"
                    disabled={verificationText.toLocaleLowerCase() !== 'pause'}
                    onClick={(e) => pauseSurvey()}
                    variant="contained"
                    color="warning"
                    size="large"
                />
            </Stack>
        </Stack>
    )
}

export default PauseSurveyDialog
