//* ======= Libraries
import React, { useState, useContext, useEffect, useCallback, useRef, useLayoutEffect, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useParams } from 'react-router-dom'
import { Stack, Box, IconButton, Tooltip, useTheme, List, Typography } from '@mui/material'
//* ======= Components and features
import ReportLeftSidebar from 'features/report-designer/layout-containers/ReportLeftSidebar'
import ReportWidgetsToolbar from 'features/report-designer/layout-containers/ReportWidgetsToolbar'
import ReportControlsToolbar from 'features/report-designer/layout-containers/ReportControlsToolbar'
import ReportSlideContainer from 'features/report-designer/layout-containers/ReportSlideContainer'
import ReportSettingsSidebar from 'features/report-designer/layout-containers/ReportSettingsSidebar'
import ReportViewFullscreen from 'features/report-designer/view/ReportViewFullscreen'

//* ======= Custom logic
import { RootContext } from 'contexts/RootContext'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { throttle } from 'helpers/helpers'
//* ======= Assets and styles
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { GetReport } from 'services/ReportApi'
import { ProviderProps, TourProvider } from '@reactour/tour'
import { getReportOnboardingProps } from './helpers/reportDesigner.constants'
import moment from 'moment'

function ReportDesigner() {
    const { setPath } = useContext(RootContext)
    const { pid, rid } = useParams()

    const {
        status,
        title,
        viewMode,
        leftSidebar,
        settingsSidebar,
        resetReport,
        updateStatus,
        updateViewMode,
        updateLayoutLeftSidebar,
        updateLayoutSettingsSidebar,
        updateLayoutSettingsSidebarView,
        loadReport,
        masterSettings,
        updateReportTour,
        updateReportTourStep,
        history,
    } = useReportStore((store) => ({
        status: store.status,
        title: store.title,
        viewMode: store.viewMode,
        leftSidebar: store.layoutSettings.leftSidebar,
        settingsSidebar: store.layoutSettings.settingsSidebar,
        resetReport: store.resetReport,
        updateStatus: store.updateStatus,
        updateViewMode: store.updateViewMode,
        updateLayoutLeftSidebar: store.updateLayoutLeftSidebar,
        updateLayoutSettingsSidebar: store.updateLayoutSettingsSidebar,
        updateLayoutSettingsSidebarView: store.updateLayoutSettingsSidebarView,
        loadReport: store.loadReport,
        masterSettings: store.masterSettings,
        updateReportTour: store.updateReportTour,
        updateReportTourStep: store.updateReportTourStep,
        history: store.history,
    }))

    // Left sidebar collapse/expand state
    const [isLeftSidebarCollapsed, setIsLeftSidebarCollapsed] = useState(true)

    const muiTheme = useTheme()

    // Handle left sidebar overlay and collapse/expand on window resize
    const windowWidthRef = useRef(0)
    const onWindowResize = useCallback(
        throttle(() => {
            if (window.innerWidth < muiTheme.breakpoints.values.xl) {
                updateLayoutLeftSidebar({
                    settings: {
                        isOverlayed: true,
                    },
                })

                if (windowWidthRef.current > muiTheme.breakpoints.values.xl && isLeftSidebarCollapsed === false) {
                    setIsLeftSidebarCollapsed(true)
                }
            } else {
                updateLayoutLeftSidebar({
                    settings: {
                        isOverlayed: false,
                    },
                })
            }

            windowWidthRef.current = window.innerWidth
        }, 200),
        []
    )

    const fetchReport = async () => {
        if (pid === undefined || rid === undefined) return

        resetReport()

        try {
            const response = await GetReport(pid, rid)

            if (response.success === false) {
                updateStatus({
                    status: 'faulty',
                    errorMessage: 'Unable to fetch report data!',
                })

                return
            }

            // Load report data and update store
            loadReport({
                responseData: response.data,
                viewMode: 'design',
                reportId: rid,
            })
        } catch (error) {
            updateStatus({
                status: 'faulty',
                errorMessage: 'Unable to fetch report data!',
            })
        }
    }

    // Report store reset and left toolbar collapse/expand logic
    useEffect(() => {
        if (window.innerWidth < muiTheme.breakpoints.values.xl) {
            updateLayoutLeftSidebar({
                settings: {
                    isOverlayed: true,
                },
            })
        }

        window.addEventListener('resize', onWindowResize)

        return () => {
            // Reset the report store when leaving this route/component.
            resetReport()

            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    // Fetch report data and update store
    useEffect(() => {
        /*
         * This check ensures that inside the report designer page, we can "reset" everything
         * and re-fetch data from server by simply dispatching an action to the store and setting
         * the "status" property to "pending".
         * This can be useful if we want to get out of a faulty state or any other situation which requires
         * a reset of the report page.
         */
        if (status === 'pending') {
            fetchReport()
        }
    }, [status])

    // TODO: Clean up root context later and remove these updates.
    useEffect(() => {
        if (title !== '') {
            setPath((p) => {
                const newPath = [...p]
                // if last path is not the same as the title, add it to the path
                if (newPath[newPath.length - 1].title !== title) {
                    newPath.push({ title })
                }
                return newPath
            })
        }
    }, [title, setPath])

    // On click inside empty areas, show report settings view and de-select active widget.
    const onEmptyAreaClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (evt.target === evt.currentTarget && ['pending', 'faulty'].includes(status) === false) {
            if (settingsSidebar.view !== 'report') {
                updateLayoutSettingsSidebarView({
                    view: 'report',
                })
            }
        } else {
            // Ignore click
        }
    }

    // Expand/collapse settings sidebar
    const onSettingsSidebarButtonClick = () => {
        updateLayoutSettingsSidebar({
            settings: {
                isExpanded: !settingsSidebar.isExpanded,
            },
        })
    }

    const fullscreenContainerRef = useRef<HTMLDivElement>(null)

    // Fullscreen view effect
    useLayoutEffect(() => {
        if (viewMode === 'preview') {
            if (fullscreenContainerRef.current !== null) {
                fullscreenContainerRef.current.requestFullscreen()

                function onFullscreenChange() {
                    if (Boolean(document.fullscreenElement) === false) {
                        updateViewMode({
                            mode: 'design',
                        })
                    }
                }

                document.addEventListener('fullscreenchange', onFullscreenChange)

                return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
            }
        } else {
            if (Boolean(document.fullscreenElement) === true) {
                document.exitFullscreen()
            }
        }
    }, [viewMode])

    const tourProviderProps: Partial<ProviderProps> = useMemo(() => {
        return getReportOnboardingProps(masterSettings.onboarding, updateReportTour, updateReportTourStep)
    }, [masterSettings.onboarding])

    return (
        <>
            {status === 'ready' && viewMode === 'preview' ? (
                /*  Fullscreen view
                    ========================================= */
                <Box
                    ref={fullscreenContainerRef}
                    sx={{
                        height: '100%',
                    }}
                >
                    <TourProvider steps={[]} {...tourProviderProps}>
                        <ReportViewFullscreen onExitFullscreen={() => updateViewMode({ mode: 'design' })} />
                    </TourProvider>
                </Box>
            ) : (
                /*  Main view
                    ========================================= */
                <DndProvider backend={HTML5Backend}>
                    {/* Page wrapper
                        ========================================= */}
                    <TourProvider steps={[]} {...tourProviderProps}>
                        <Stack
                            onClick={onEmptyAreaClick}
                            direction="row"
                            gap={1.5}
                            component="div"
                            sx={{
                                position: 'relative',
                                // userSelect: 'none',
                                height: '100%',
                                paddingLeft: leftSidebar.isOverlayed ? 1 : 0,
                            }}
                        >
                            {/* Left sidebar
                            ========================================= */}
                            <Box
                                sx={(theme) => ({
                                    position: leftSidebar.isOverlayed ? 'absolute' : undefined,
                                    top: leftSidebar.isOverlayed ? 0 : undefined,
                                    left: leftSidebar.isOverlayed ? 0 : undefined,
                                    zIndex: leftSidebar.isOverlayed ? 5 : undefined,
                                    isolation: 'isolate',

                                    flexShrink: 0,

                                    height: '100%',
                                    paddingY: theme.spacing(2),

                                    boxShadow: 1,
                                    // @Theme conditional
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? theme.palette.common.bg_1
                                            : theme.palette.common.bg_3,
                                })}
                            >
                                {/* Sidebar content
                                ========================================= */}
                                <Box
                                    sx={(theme) => ({
                                        height: '100%',
                                        width:
                                            leftSidebar.isOverlayed && isLeftSidebarCollapsed ? 0 : leftSidebar.width,
                                        pointerEvents:
                                            leftSidebar.isOverlayed && isLeftSidebarCollapsed ? 'none' : 'auto',

                                        transition:
                                            theme.transitions.create('width', {
                                                duration: leftSidebar.transitionDuration,
                                                easing: theme.transitions.easing.sharp,
                                            }) +
                                            ', ' +
                                            theme.transitions.create('opacity', {
                                                duration: theme.transitions.duration.shortest,
                                                easing: theme.transitions.easing.sharp,
                                            }),
                                        opacity: leftSidebar.isOverlayed && isLeftSidebarCollapsed ? 0 : 1,
                                    })}
                                >
                                    <ReportLeftSidebar />
                                </Box>

                                {/* Expand/Collapse button
                                ========================================= */}
                                {leftSidebar.isOverlayed && (
                                    <Tooltip
                                        title={isLeftSidebarCollapsed ? 'Expand Slides List' : 'Collpase Slides List'}
                                        enterDelay={200}
                                        arrow
                                        placement="right"
                                    >
                                        <IconButton
                                            onClick={(evt) => setIsLeftSidebarCollapsed((prevState) => !prevState)}
                                            sx={(theme) => ({
                                                position: 'absolute',
                                                top: '50%',
                                                right: 0,
                                                zIndex: 5,

                                                padding: theme.spacing(0.5),
                                                paddingLeft: isLeftSidebarCollapsed
                                                    ? theme.spacing(1.5)
                                                    : theme.spacing(0.5),
                                                overflow: 'hidden',

                                                transition: theme.transitions.create('transform', {
                                                    duration: leftSidebar.transitionDuration + 100,
                                                    easing: theme.transitions.easing.sharp,
                                                }),
                                                transform: isLeftSidebarCollapsed
                                                    ? 'translateY(-50%) translateX(65%)'
                                                    : 'translateY(-50%) translateX(50%)',
                                                boxShadow: 2,
                                                // @Theme conditional
                                                backgroundColor:
                                                    theme.palette.mode === 'light'
                                                        ? theme.palette.common.bg_2
                                                        : theme.palette.common.bg_4,

                                                '&:hover': {
                                                    // @Theme conditional
                                                    backgroundColor:
                                                        theme.palette.mode === 'light'
                                                            ? theme.palette.common.bg_1
                                                            : theme.palette.common.bg_3,
                                                },
                                            })}
                                        >
                                            <ChevronLeftIcon
                                                sx={(theme) => ({
                                                    height: 32,
                                                    width: 32,

                                                    transition: theme.transitions.create('transform', {
                                                        duration: leftSidebar.transitionDuration + 100,
                                                        easing: theme.transitions.easing.sharp,
                                                    }),
                                                    transform: isLeftSidebarCollapsed ? 'rotate(-180deg)' : undefined,
                                                })}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>

                            {/* Widgets and Controls toolbar
                            ========================================= */}
                            {viewMode === 'design' && (
                                <Stack
                                    onClick={onEmptyAreaClick}
                                    justifyContent="space-between"
                                    gap={2}
                                    sx={(theme) => ({
                                        flexShrink: 0,
                                        alignSelf: 'stretch',

                                        width: 64,
                                    })}
                                >
                                    {/* Widgets toolbar
                                    ========================================= */}
                                    <Box
                                        sx={(theme) => ({
                                            flexShrink: 0,

                                            paddingY: theme.spacing(1.5),
                                            paddingX: theme.spacing(1),

                                            // @Theme conditional
                                            backgroundColor:
                                                theme.palette.mode === 'light'
                                                    ? theme.palette.common.bg_1
                                                    : theme.palette.common.bg_3,
                                        })}
                                    >
                                        <ReportWidgetsToolbar />
                                    </Box>

                                    {/* Controls toolbar
                                    ========================================= */}
                                    {['pending', 'faulty'].includes(status) === false && (
                                        <Box
                                            sx={(theme) => ({
                                                flexShrink: 0,

                                                paddingBottom: theme.spacing(1.5),
                                                paddingX: theme.spacing(1),
                                            })}
                                        >
                                            <ReportControlsToolbar />
                                        </Box>
                                    )}
                                </Stack>
                            )}

                            {/* Main area
                            ========================================= */}
                            <Stack
                                gap={1}
                                sx={(theme) => ({
                                    flexGrow: 1,

                                    minWidth: 0,
                                    marginRight: theme.spacing(1),
                                })}
                            >
                                <ReportSlideContainer />

                                {false && (
                                    <Stack
                                        direction="row"
                                        sx={{ height: '200px', maxHeight: '400px', flexShrink: 0, overflow: 'hidden' }}
                                    >
                                        <Box sx={{ height: '100%', overflowY: 'scroll', flex: 0.5 }}>
                                            <Typography variant="h6">Past</Typography>
                                            <Stack gap={1} direction="column-reverse">
                                                {history.past.map((item, index) => {
                                                    return (
                                                        <Tooltip title={JSON.stringify(item)} key={index}>
                                                            <Box
                                                                sx={{
                                                                    padding: 2,
                                                                    bgcolor: index % 2 === 0 ? 'white' : '#efefef',
                                                                }}
                                                            >
                                                                {item.name +
                                                                    ' ' +
                                                                    moment(item.timestamp).format('HH:mm:ss')}
                                                            </Box>
                                                        </Tooltip>
                                                    )
                                                })}
                                            </Stack>
                                        </Box>
                                        <Box sx={{ height: '100%', overflowY: 'scroll', flex: 0.5 }}>
                                            <Typography variant="h6">Future</Typography>
                                            <Stack gap={1} direction="column-reverse">
                                                {history.future.map((item, index) => {
                                                    return (
                                                        <Tooltip title={JSON.stringify(item)} key={index}>
                                                            <Box
                                                                sx={{
                                                                    padding: 2,
                                                                    bgcolor: index % 2 === 0 ? '#white' : '#efefef',
                                                                }}
                                                            >
                                                                {item.name +
                                                                    ' ' +
                                                                    moment(item.timestamp).format('HH:mm:ss')}
                                                            </Box>
                                                        </Tooltip>
                                                    )
                                                })}
                                            </Stack>
                                        </Box>
                                    </Stack>
                                )}
                            </Stack>

                            {/* Right sidebar
                            ========================================= */}
                            <Box
                                sx={(theme) => ({
                                    flexShrink: 0,

                                    position: 'relative',
                                    isolation: 'isolate',

                                    width: settingsSidebar.isExpanded ? settingsSidebar.width : 15,
                                    minWidth: settingsSidebar.isExpanded ? 250 : 0,
                                    height: '100%',
                                    paddingTop: theme.spacing(1),
                                    paddingBottom: theme.spacing(2),

                                    transition: theme.transitions.create(['width', 'min-width'], {
                                        duration: settingsSidebar.transitionDuration,
                                        easing: theme.transitions.easing.sharp,
                                    }),
                                    // @Theme conditional
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? theme.palette.common.bg_1
                                            : theme.palette.common.bg_3,
                                })}
                            >
                                {/* Sidebar content
                                ========================================= */}
                                <Box
                                    sx={(theme) => ({
                                        width: '100%',
                                        height: '100%',
                                        pointerEvents: settingsSidebar.isExpanded ? 'auto' : 'none',

                                        transition: theme.transitions.create('opacity', {
                                            duration: theme.transitions.duration.shortest,
                                            easing: theme.transitions.easing.sharp,
                                        }),
                                        opacity: settingsSidebar.isExpanded ? 1 : 0,
                                    })}
                                >
                                    <ReportSettingsSidebar />
                                </Box>

                                {/* Expand/Collapse button
                                ========================================= */}
                                <Tooltip
                                    title={settingsSidebar.isExpanded ? 'Collapse Settings' : 'Expand Settings'}
                                    enterDelay={200}
                                    arrow
                                    placement="left"
                                >
                                    <IconButton
                                        onClick={(evt) => onSettingsSidebarButtonClick()}
                                        sx={(theme) => ({
                                            position: 'absolute',
                                            top: '50%',
                                            left: 0,
                                            zIndex: 5,

                                            padding: theme.spacing(0.5),
                                            paddingRight: settingsSidebar.isExpanded
                                                ? theme.spacing(0.5)
                                                : theme.spacing(1.5),
                                            overflow: 'hidden',

                                            transition: theme.transitions.create('transform', {
                                                duration: settingsSidebar.transitionDuration + 100,
                                                easing: theme.transitions.easing.sharp,
                                            }),
                                            transform: settingsSidebar.isExpanded
                                                ? 'translateY(-50%) translateX(-50%)'
                                                : 'translateY(-50%) translateX(-35%)',
                                            boxShadow: 2,
                                            // @Theme conditional
                                            backgroundColor:
                                                theme.palette.mode === 'light'
                                                    ? theme.palette.common.bg_2
                                                    : theme.palette.common.bg_4,

                                            '&:hover': {
                                                // @Theme conditional
                                                backgroundColor:
                                                    theme.palette.mode === 'light'
                                                        ? theme.palette.common.bg_1
                                                        : theme.palette.common.bg_3,
                                            },
                                        })}
                                    >
                                        <ChevronLeftIcon
                                            sx={(theme) => ({
                                                height: 32,
                                                width: 32,

                                                transition: theme.transitions.create('transform', {
                                                    duration: settingsSidebar.transitionDuration + 100,
                                                    easing: theme.transitions.easing.sharp,
                                                }),
                                                transform: settingsSidebar.isExpanded ? 'rotate(-180deg)' : undefined,
                                            })}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>
                    </TourProvider>
                </DndProvider>
            )}
        </>
    )
}

export default ReportDesigner
