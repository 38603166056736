import React from 'react'
import { DataGridProProps, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import StyledDataGrid from './StyledDataGrid'

export type GenreicAPIDataType = {
    success: boolean
    data: {
        data: Array<any>
        rowCount: number
    }
}

export interface StyledDataGridWithApiPropsType
    extends Omit<DataGridProProps, 'rows' | 'rowCount' | 'onPageChange' | 'paginationMode' | 'loading'> {
    getData: (data: { pageNumber: number; pageSize: number }) => Promise<GenreicAPIDataType | undefined>
    refreshData: React.MutableRefObject<(() => void) | null>
}

export default function StyledDataGridWithApi({
    getData,
    refreshData,
    columns,
    ...props
}: StyledDataGridWithApiPropsType) {
    const [page, setPage] = React.useState(0)
    const [rowCount, setRowCount] = React.useState(0)
    const [rows, setRows] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false)
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([])

    React.useEffect(() => {
        fetchData()
    }, [page])

    React.useEffect(() => {
        if (refreshData) refreshData.current = fetchData
    }, [])

    const fetchData = () => {
        let active = true

        ;(async () => {
            setLoading(true)
            const newRows = await getData({ pageNumber: page, pageSize: 5 })
            if (!newRows) return

            if (!active || !newRows.success) {
                return
            }

            setRows(newRows.data.data)
            setRowCount(newRows.data.rowCount)
            setLoading(false)
        })()

        return () => {
            active = false
        }
    }

    return (
        <StyledDataGrid
            getRowId={(row) => row.primaryKey}
            rows={rows}
            rowCount={rowCount}
            onPaginationModelChange={(model, details) => setPage(model.page)}
            columns={columns}
            paginationMode="server"
            loading={loading}
            {...props}
        />
    )
}
