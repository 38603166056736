import { Stack, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import { NODE_ANALYTICS_METRICS } from 'features/network-viz/constants/NetworkViz.const'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import NodeAttributeSelector from 'features/network-viz/helpers/NodeAttributeSelector'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { LayoutSettingsType } from '../../../types/NetworkViz.types'

type NetworkSFDPProps = {
    settings: LayoutSettingsType['sfdp']
    saveSettings: (settings: LayoutSettingsType['sfdp']) => void
    nodeDataSchema: NetworkVizContextType['nodeDataSchema']
    edgeDataSchema: NetworkVizContextType['edgeDataSchema']
    analytics: NetworkVizContextType['analytics']
}

function NetworkSFDPSettings({
    analytics,
    nodeDataSchema,
    edgeDataSchema,
    settings,
    saveSettings,
}: NetworkSFDPProps) {
    const [_settings, setSettings] = useState({ ...settings })

    useEffect(() => {
        if (!isEqual(_settings, settings)) {
            setSettings({ ...settings })
        }
    }, [settings])

    const updateSettings = (filed: keyof LayoutSettingsType['sfdp']) => (value: any) => {
        setSettings((pv) => ({ ...pv, [filed]: value }))
    }

    return (
        <Stack gap={2}>
            <NodeAttributeSelector
                label="Vertext Weight"
                emptyMessage="None"
                noneOption={true}
                onChange={updateSettings('vweight')}
                attribute={_settings.vweight}
                helperText="A vertex property map with the respective weights"
                nodeAttributeOptions={nodeDataSchema.numericOptions}
                analytics={analytics}
            />

            <NodeAttributeSelector
                label="Groups"
                emptyMessage="None"
                noneOption={true}
                onChange={updateSettings('groups')}
                attribute={_settings.groups}
                helperText="A vertex property map with group assignments. Vertices belonging to the same group will be put close together."
                nodeAttributeOptions={nodeDataSchema.numericOptions}
                analytics={analytics}
            />

            <BaseSelectWithLabel
                label="Edge Weight"
                options={[{ label: 'None', value: 'None' }, ...edgeDataSchema.numericOptions]}
                helperText="An edge property map with the respective weights."
                value={_settings.eweight}
                onChange={updateSettings('eweight')}
            />

            <Stack>
                <Typography>Relative strength</Typography>
                <ValueSliderPicker
                    min={0}
                    max={10}
                    step={0.05}
                    value={_settings.C}
                    onChange={updateSettings('C')}
                />
                <Typography variant="caption">Relative strength of repulsive forces.</Typography>
            </Stack>

            <Stack>
                <Typography>Edge length</Typography>
                <ValueSliderPicker
                    min={0}
                    max={1000}
                    step={1}
                    value={_settings.K}
                    onChange={updateSettings('K')}
                />
                <Typography variant="caption">
                    Optimal edge length. If 0, it will be taken to be the average edge distance in the initial
                    layout.
                </Typography>
            </Stack>

            <Stack>
                <Typography>Repulsive force exponent.</Typography>
                <ValueSliderPicker
                    min={0}
                    max={100}
                    step={1}
                    value={_settings.p}
                    onChange={updateSettings('p')}
                />
            </Stack>

            <Stack>
                <Typography>Theta</Typography>
                <ValueSliderPicker
                    min={0}
                    max={10}
                    step={0.01}
                    value={_settings.theta}
                    onChange={updateSettings('theta')}
                />
                <Typography variant="caption">
                    Quadtree opening parameter, a.k.a. Barnes-Hut opening criterion.
                </Typography>
            </Stack>

            <Stack>
                <Typography>Max Level</Typography>
                <ValueSliderPicker
                    min={1}
                    max={100}
                    step={1}
                    value={_settings.max_level}
                    onChange={updateSettings('max_level')}
                />
                <Typography variant="caption">Maximum quadtree level.</Typography>
            </Stack>

            <Stack>
                <Typography>Attractive force</Typography>
                <ValueSliderPicker
                    min={1}
                    max={100}
                    step={1}
                    value={_settings.r}
                    onChange={updateSettings('r')}
                />
                <Typography variant="caption">
                    Strength of attractive force between connected components.
                </Typography>
            </Stack>

            <Stack>
                <Typography>Repulsive force</Typography>
                <ValueSliderPicker
                    min={0.01}
                    max={10}
                    step={0.01}
                    value={_settings.gamma}
                    onChange={updateSettings('gamma')}
                />
                <Typography variant="caption">
                    Strength of the repulsive force between different groups.
                </Typography>
            </Stack>

            <Stack>
                <Typography>Repulsive force length</Typography>
                <ValueSliderPicker
                    min={1}
                    max={100}
                    step={1}
                    value={_settings.mu}
                    onChange={updateSettings('mu')}
                />
                <Typography variant="caption">
                    Typical length of the repulsive force between different groups.
                </Typography>
            </Stack>

            <Stack>
                <Typography>Attracttive force factor</Typography>
                <ValueSliderPicker
                    min={1}
                    max={100}
                    step={1}
                    value={_settings.kappa}
                    onChange={updateSettings('kappa')}
                />
                <Typography variant="caption">
                    Multiplicative factor on the attracttive force between nodes of the same group.
                </Typography>
            </Stack>

            <Stack>
                <Typography>Cooling step</Typography>
                <ValueSliderPicker
                    min={0}
                    max={10}
                    step={0.1}
                    value={_settings.cooling_step}
                    onChange={updateSettings('cooling_step')}
                />
                <Typography variant="caption">Cooling update step.</Typography>
            </Stack>

            <StyledSwitch
                label="Adaptive cooling"
                checked={_settings.adaptive_cooling}
                onChange={(e, c) => updateSettings('adaptive_cooling')(c)}
            />

            <Stack>
                <Typography>Relative convergence criterion</Typography>
                <ValueSliderPicker
                    min={0.01}
                    max={10}
                    step={0.01}
                    value={_settings.epsilon}
                    onChange={updateSettings('epsilon')}
                />
            </Stack>

            <Stack>
                <Typography>Max Iteration</Typography>
                <ValueSliderPicker
                    min={0}
                    max={100}
                    step={10}
                    value={_settings.max_iter}
                    onChange={updateSettings('max_iter')}
                />
                <Typography variant="caption">
                    Maximum number of iterations. If this value is 0, it runs until convergence.
                </Typography>
            </Stack>

            <BaseButton label="Apply" onClick={(evt) => saveSettings(_settings)} variant="outlined" />
        </Stack>
    )
}

export default NetworkSFDPSettings
