//* ======= Libraries
import React, { ReactElement, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { RootContext } from 'contexts/RootContext'
import { NavigationDrawerType } from 'types/NavigationTypes'
import { FavItemType } from 'services/UserService'
//* ======= Assets and styles
import SettingsIcon from '@mui/icons-material/Settings'
import StarsIcon from '@mui/icons-material/Stars'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ProjectIcon from '@mui/icons-material/CorporateFare'
import QuizIcon from '@mui/icons-material/Quiz'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'

interface IMainDrawer {
    content: NavigationDrawerType
}

export const parseUrl = (url: string, fav: FavItemType) => {
    url = url.replaceAll(`{projectId}`, fav.projectId + '')
    if (fav.type != 'project') url = url.replaceAll(`{surveyId}`, fav.surveyId + '')
    return url
}

const Fav_Constants: { [key: string]: { icon: ReactElement; baseUrl: string } } = {
    project: {
        icon: (
            <ProjectIcon
                sx={{
                    color: 'common.fill_2',
                }}
            />
        ),
        baseUrl: '/project/{projectId}',
    },
    survey: {
        icon: (
            <QuizIcon
                sx={{
                    color: 'common.fill_2',
                }}
            />
        ),
        baseUrl: '/project/{projectId}/surveys/{surveyId}',
    },
}

function MainDrawer({ content }: IMainDrawer) {
    const navigator = useNavigate()
    const location = useLocation()
    const { favs } = useContext(RootContext)

    const theme = useTheme()
    const isViewportSmall = useMediaQuery(theme.breakpoints.down('xl'))

    const [isCollapsed, setIsCollapsed] = useState(false)

    return (
        <Box
            sx={(theme) => ({
                height: '100%',
                // '18.5rem' will be '296px'; the width of design.
                // '13rem' will be '208px'.
                // Spacing value of '3' is '48px' which is the width of collapse button plus
                // horizontal padding of this container in collapsed state.
                width: isCollapsed ? 48 : isViewportSmall ? '13rem' : '18.5rem',
                paddingTop: 2,
                paddingBottom: 3,
                // Same as the left padding of 'header'.
                paddingLeft: isCollapsed ? 0.5 : 3,
                paddingRight: isCollapsed ? 0.5 : 2,

                // TODO:    transition the width of drawer. Needs proper styling to all inner
                //          elements to support overflowing content and not jump around during
                //          transition.
                // transition: 'width 0.15s ease-in-out',
                // backgroundColor: 'common.bg_1',
            })}
        >
            <Stack
                spacing={1}
                height="100%"
                sx={{
                    overflowY: 'auto',
                }}
                className="u-scrollbar"
            >
                {/* Main content
                    ========================================= */}
                <Stack flexGrow={1} alignItems="flex-start">
                    {isCollapsed ? (
                        /*  Collapsed view
                            ========================================= */
                        false
                    ) : (
                        /*  Uncollapsed view
                            ========================================= */
                        <>
                            {/* Title
                                ========================================= */}
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {/* (Conditional) Title Mark?
                                    ========================================= */}
                                {content.avatar && (
                                    <Box
                                        sx={(theme) => ({
                                            height: 24,
                                            width: 24,
                                            marginRight: 1,
                                            fontSize: 16,
                                            fontWeight: 700,
                                            lineHeight: '20px',
                                            textAlign: 'center',

                                            borderRadius: '5px',
                                            border: `2px solid ${theme.palette.primary.main}`,
                                            color: 'primary.main',
                                        })}
                                    >
                                        {content.avatar.toUpperCase()}
                                    </Box>
                                )}

                                <Typography fontSize={20} fontWeight={700} color="primary">
                                    {content.title}
                                </Typography>
                            </Stack>

                            {/* Survey options
                                ========================================= */}
                            <List
                                sx={(theme) => ({
                                    minWidth: 0,
                                    width: '100%',

                                    '& .MuiListItem-root': {
                                        '&:not(:last-of-type)': {
                                            marginBottom: 0.5,
                                        },

                                        '& .MuiListItemIcon-root': {
                                            minWidth: 0,
                                            marginRight: theme.spacing(2),
                                        },
                                    },
                                })}
                            >
                                {content.sections.map((section, idx) => (
                                    <React.Fragment key={idx}>
                                        {section.map((item) => {
                                            const isItemSelected = location.pathname === item.link
                                            return (
                                                <ListItem
                                                    disableGutters
                                                    disablePadding
                                                    key={item.title}
                                                    onClick={() => navigator(item.link)}
                                                >
                                                    <ListItemButton dense disableGutters>
                                                        {item.icon && (
                                                            <ListItemIcon>
                                                                {typeof item.icon == 'string' ? (
                                                                    <img
                                                                        src={item.icon}
                                                                        alt={item.title}
                                                                        style={{
                                                                            height: 36,
                                                                            filter: `saturate(${
                                                                                isItemSelected ? 100 : 0
                                                                            }%)`,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Typography
                                                                        sx={{
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            fontSize: 0,
                                                                        }}
                                                                        color={isItemSelected ? 'primary' : 'secondary'}
                                                                    >
                                                                        {item.icon}
                                                                    </Typography>
                                                                )}
                                                            </ListItemIcon>
                                                        )}
                                                        <ListItemText
                                                            primary={item.title}
                                                            primaryTypographyProps={{
                                                                fontSize: 14,
                                                                fontWeight: 400,
                                                                lineHeight: '24px',
                                                                color: isItemSelected
                                                                    ? 'primary.main'
                                                                    : 'common.text_1',
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                        <Divider
                                            sx={{
                                                alignSelf: 'stretch',
                                                my: 1,
                                                borderColor: 'common.border_2',
                                            }}
                                        />
                                    </React.Fragment>
                                ))}
                            </List>

                            {/* Favourite resources
                                ========================================= */}
                            {content.quickAccess && (
                                <List
                                    sx={(theme) => ({
                                        minWidth: 0,
                                        width: '100%',

                                        '& .MuiListItem-root': {
                                            '&:not(:last-of-type)': {
                                                marginBottom: 0.5,
                                            },

                                            '& .MuiListItemIcon-root': {
                                                minWidth: 0,
                                                marginRight: theme.spacing(2),
                                            },
                                        },
                                    })}
                                >
                                    {/* Section header
                                        ========================================= */}
                                    <ListItem disableGutters disablePadding>
                                        <ListItemIcon>
                                            <StarsIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Favourite items"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                color: 'primary.main',
                                            }}
                                        />
                                    </ListItem>
                                    {favs == null ? (
                                        <ListItemButton dense disableGutters>
                                            <ListItemIcon>
                                                <SnaCircularLoading size={24} horizontal />
                                            </ListItemIcon>
                                        </ListItemButton>
                                    ) : (
                                        favs.map((fav) => (
                                            <ListItem
                                                key={fav.title}
                                                disableGutters
                                                disablePadding
                                                secondaryAction={
                                                    <Tooltip
                                                        title="Unpin"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                        }}
                                                    >
                                                        <IconButton>
                                                            <BookmarkRemoveIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                onClick={() =>
                                                    navigator(parseUrl(Fav_Constants[fav.type].baseUrl, fav))
                                                }
                                            >
                                                <ListItemButton dense disableGutters>
                                                    <ListItemIcon>
                                                        {fav.thumbnail != null && fav.thumbnail.length > 0 ? (
                                                            <Avatar sx={{ width: 24, height: 24 }} src={fav.thumbnail}>
                                                                {fav.title && fav.title.substring(0, 1).toUpperCase()}
                                                            </Avatar>
                                                        ) : (
                                                            Fav_Constants[fav.type].icon
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={fav.title}
                                                        primaryTypographyProps={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                    )}
                                </List>
                            )}
                        </>
                    )}
                </Stack>

                {/* Bottom controls
                    ========================================= */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {isCollapsed ? (
                        /*  Collapsed view
                            ========================================= */
                        false
                    ) : (
                        /*  Uncollapsed view
                            ========================================= */
                        <>
                            {content.settings && (
                                <Stack direction="row" alignItems="center" gap={1} sx={{ flexGrow: 1 }}>
                                    <BaseButton
                                        label={content.settings.title}
                                        onClick={() => content.settings?.link && navigator(content.settings.link)}
                                        variant="outlined"
                                        fullWidth
                                        startIcon={<SettingsIcon />}
                                    />
                                </Stack>
                            )}
                        </>
                    )}

                    {/* Collapse button
                        (Note: Don't add elements after this button. If you did, handle 'gridColumn'.)
                        ========================================= */}
                    <IconButton onClick={() => setIsCollapsed((prevState) => !prevState)} color="primary">
                        <ChevronLeftIcon
                            sx={{
                                transition: 'transform 0.4s ease-in-out',
                                transform: isCollapsed ? 'rotate(-180deg)' : undefined,
                            }}
                        />
                    </IconButton>
                </Box>
            </Stack>
        </Box>
    )
}

export default MainDrawer
