import React from 'react'
import { useDrag, useDrop, DropTargetMonitor, DragSourceMonitor } from 'react-dnd'
import DragHandleIcon from '@mui/icons-material/DragHandle' // Import appropriate icons
import { Stack, Box } from '@mui/material'

type ItemType = {
    id: string | number
    [key: string]: any
}

interface DraggableListItemProps<T extends ItemType> {
    item: T
    targetType: string
    moveItem: (dragId: string | number, hoverId: string | number) => void
    onMoveEnd?: (dragId: string | number) => void
    renderItemContent: (item: T) => JSX.Element
}

function DraggableListItem<T extends ItemType>({
    item,
    moveItem,
    onMoveEnd,
    renderItemContent,
    targetType,
}: DraggableListItemProps<T>) {
    const [{ isDragging }, dragSource, dragPreview] = useDrag(
        {
            type: targetType,
            item: { id: item.id },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
        },
        [item.id]
    )

    const [, dropTarget] = useDrop(
        {
            accept: targetType,
            hover: (draggedItem: { id: string | number; label: string }, monitor) => {
                if (draggedItem.id !== item.id) {
                    moveItem(draggedItem.id, item.id)
                }
            },
            drop: (draggedItem) => {
                if (onMoveEnd !== undefined) {
                    onMoveEnd(draggedItem.id)
                }
            },
        },
        [item.id]
    )

    return (
        <Stack
            ref={(node: HTMLDivElement) => dragPreview(dropTarget(node))}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <Box
                ref={dragSource}
                sx={{ flexShrink: 0, width: 40, height: '100%', paddingX: 1, overflow: 'hidden', cursor: 'grab' }}
            >
                <DragHandleIcon sx={{ width: '100%', height: '100%' }} />
            </Box>

            <Box flexGrow={1}>{renderItemContent(item)}</Box>
        </Stack>
    )
}

export default DraggableListItem
