import showdown from 'showdown'
import { Model } from 'survey-core'

export function registerMarkdown(survey: Model) {
    // Instantiate Showdown
    const converter = new showdown.Converter()
    survey.onTextMarkdown.add((survey: Model, options: any) => {
        // Convert Markdown to HTML
        let str = converter.makeHtml(options.text)
        // Remove root paragraphs <p></p>
        str = str.substring(3)
        str = str.substring(0, str.length - 4)
        // Set HTML markup to render
        options.html = str
    })
}
