import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconButton, Stack, Tooltip } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import {
    ReportDataSourceType,
    ReportFilterCompareType,
    SelectedDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { isEqual } from 'lodash'
import { getDataSourceFields, getDataSourceFieldValues } from 'features/report-designer/helpers/reportDesigner.helper'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import usePreviousValue from 'hooks/usePreviousValue'

// List of operators that require a set of values
const setOperators: ReportFilterCompareType['operator'][] = ['notContainsAny', 'containsAny']

type Props = {
    dataSources: ReportDataSourceType[]
    selectedDataSource: SelectedDataSourceType | null
    onChange: (selectedDataSource: SelectedDataSourceType) => void
    hideFilterSelection?: boolean
    enableGraphInfo?: boolean
    layoutDirection?: 'row' | 'column'
    shouldIncludeTies?: boolean
    shouldIncludeDataType?: boolean
}

function ReportDataSourceSelector({
    dataSources,
    selectedDataSource,
    onChange,
    hideFilterSelection = false,
    enableGraphInfo = true,
    layoutDirection = 'row',
    shouldIncludeTies = false,
    shouldIncludeDataType = true,
}: Props) {
    const [dataSourceSelectOptions, setDataSourceSelectOptions] = useState<BaseSelectWithLabelOptionType>([])
    const [targetDataSource, setTargetDataSource] = useState<ReportDataSourceType | null>(null)
    const [presetOptions, setPresetOptions] = useState<BaseSelectWithLabelOptionType>([])
    const [fields, setFields] = useState<FlexibleSelectOptionType[]>([])
    const [filters, setFilters] = useState<Array<ReportFilterCompareType>>([
        ...(selectedDataSource !== null ? selectedDataSource.filters : []),
    ])

    const previousSelectedDataSource = usePreviousValue(selectedDataSource)

    const setPresets = (dataSourceObject: ReportDataSourceType | undefined) => {
        let _presetOptions = []
        if (dataSourceObject?.mode === 'network') {
            for (let key in dataSourceObject.presets) {
                _presetOptions.push({
                    label: key,
                    value: key,
                })
            }
        }

        setPresetOptions(_presetOptions)

        return _presetOptions
    }

    // Data source change
    const onSelectedDataSourceChange = (id: SelectedDataSourceType['id']) => {
        const dataSourceObject = dataSources.find((_dataSource) => _dataSource.id === id)

        const _presetOptions = setPresets(dataSourceObject)

        onChange({
            id,
            datasourceType: dataSourceObject?.mode || 'static',
            filters: [],
            // TODO: After we created a union type for Network-type data sources, do something about these "reset" values.
            preset: _presetOptions.length !== 0 ? _presetOptions[0].value : '',
            type: 'nodes',
        })
    }

    // Update selectedDataSource fields
    const updateSelectedDataSource = useCallback(
        (field: keyof Omit<SelectedDataSourceType, 'id'>) =>
            (value: SelectedDataSourceType[keyof Omit<SelectedDataSourceType, 'id'>]) => {
                if (selectedDataSource === null) {
                    return
                } else {
                    onChange({
                        ...selectedDataSource,
                        [field]: value,
                    })
                }
            },
        [selectedDataSource, onChange]
    )

    // Populate data source select options
    useEffect(() => {
        setDataSourceSelectOptions(
            dataSources.map((_dataSource) => ({
                label: _dataSource.title,
                value: _dataSource.id,
            }))
        )
    }, [dataSources])

    // Update internal states on props change
    useEffect(() => {
        // Update targetDataSource and its fields on selected data source change
        if (
            targetDataSource === null ||
            (selectedDataSource !== null && selectedDataSource.id !== targetDataSource.id)
        ) {
            // Initial/blank view
            if (selectedDataSource === null) {
                setTargetDataSource(null)
                setPresets(undefined)
                setFields([])
            }
            // Update internal targetDataSource state and its fields list.
            else {
                const dataSourceObject = dataSources.find((_dataSource) => _dataSource.id === selectedDataSource.id)

                setPresets(dataSourceObject)

                setTargetDataSource(dataSourceObject || null)

                const dataSourceFields = getDataSourceFields(dataSources, selectedDataSource)

                setFields(dataSourceFields)
            }
        }

        // Update filters
        if (selectedDataSource !== null) {
            if (isEqual(selectedDataSource.filters, filters) === false) {
                setFilters(selectedDataSource.filters)
            }
        } else {
            setFilters([])
        }
    }, [dataSources, selectedDataSource])

    useEffect(() => {
        if (selectedDataSource === null) {
            setFields([])
        }

        if (
            previousSelectedDataSource?.id !== selectedDataSource?.id ||
            previousSelectedDataSource?.type !== selectedDataSource?.type
        ) {
            setFields(getDataSourceFields(dataSources, selectedDataSource))
            setFilters([])
        }
    }, [selectedDataSource])

    const showFilters = useMemo(() => {
        if (selectedDataSource?.type !== 'nodes') return false
        return hideFilterSelection === false
    }, [hideFilterSelection, selectedDataSource?.type])

    /* =========================================
     * Filters
     */

    const addFilter = () => {
        if (selectedDataSource === null) return

        const currentFilters = [...selectedDataSource.filters]

        updateSelectedDataSource('filters')([...currentFilters, { field: null, operator: 'eq', value: null }])
    }

    const updateFilterField = (filterListIndex: number) => (field: any) => {
        if (selectedDataSource === null) return

        const currentFilters = [...selectedDataSource.filters]

        const selectedFilter = currentFilters[filterListIndex]

        if (selectedFilter) {
            currentFilters[filterListIndex] = {
                ...selectedFilter,
                field,
                value: null,
            }
        }

        updateSelectedDataSource('filters')([...currentFilters])
    }

    const updateFilterValue = (filterListIndex: number) => (value: any[] | any) => {
        if (selectedDataSource === null) return

        const currentFilters = [...selectedDataSource.filters]

        const selectedFilter = currentFilters[filterListIndex]

        if (selectedFilter) {
            currentFilters[filterListIndex] = {
                ...selectedFilter,
                value,
            }
        }

        updateSelectedDataSource('filters')([...currentFilters])
    }

    const removeFilter = (filterListIndex: number) => {
        if (selectedDataSource === null) return

        const currentFilters = selectedDataSource.filters.filter((_filter, idx) => idx !== filterListIndex)

        updateSelectedDataSource('filters')([...currentFilters])
    }

    const updateFilterOperator = (filterListIndex: number) => (operator: ReportFilterCompareType['operator']) => {
        if (selectedDataSource === null) return

        const currentFilters = [...selectedDataSource.filters]

        const selectedFilter = currentFilters[filterListIndex]

        // if type of new operator is different from the type of the selected filter, reset the value
        if (selectedFilter) {
            if (setOperators.includes(operator) !== setOperators.includes(selectedFilter.operator)) {
                currentFilters[filterListIndex] = {
                    ...selectedFilter,
                    operator,
                    value: null,
                }
            } else if (operator === 'between') {
                currentFilters[filterListIndex] = {
                    ...selectedFilter,
                    operator,
                    value: [null, null],
                }
            } else {
                currentFilters[filterListIndex] = {
                    ...selectedFilter,
                    operator,
                }
            }
        }

        updateSelectedDataSource('filters')([...currentFilters])
    }

    return (
        <Stack gap={2} sx={{ width: '100%' }}>
            {/* Data sources
                ========================================= */}
            <Stack
                direction={layoutDirection}
                justifyContent={'space-between'}
                alignItems={layoutDirection === 'row' ? 'center' : 'stretch'}
                gap={2}
            >
                {/* Data source select
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Data source"
                    required
                    options={dataSourceSelectOptions}
                    value={
                        dataSourceSelectOptions.length !== 0 && selectedDataSource !== null ? selectedDataSource.id : ''
                    }
                    onChange={onSelectedDataSourceChange}
                    disabled={dataSourceSelectOptions.length === 0}
                    fullWidth
                />

                {/* Network options
                    ========================================= */}
                {targetDataSource?.mode === 'network' && (
                    <>
                        {/* Network presets select
                            ========================================= */}
                        <BaseSelectWithLabel
                            label="Preset"
                            required
                            options={presetOptions}
                            value={
                                Object.keys(targetDataSource.presets).length !== 0 && selectedDataSource !== null
                                    ? selectedDataSource.preset
                                    : ''
                            }
                            onChange={updateSelectedDataSource('preset')}
                            fullWidth
                        />

                        {/* Network data type select
                            ========================================= */}
                        {shouldIncludeDataType && enableGraphInfo && (
                            <BaseSelectWithLabel
                                label="Data type"
                                required
                                options={shouldIncludeTies ? ['graph', 'nodes', 'ties'] : ['graph', 'nodes']}
                                value={selectedDataSource !== null ? selectedDataSource.type : ''}
                                onChange={updateSelectedDataSource('type')}
                                fullWidth
                            />
                        )}
                    </>
                )}
                {targetDataSource?.mode === 'static' && (
                    <BaseSelectWithLabel
                        label="Panel"
                        required
                        options={Object.keys(targetDataSource.data)}
                        value={selectedDataSource !== null ? selectedDataSource.panel : ''}
                        onChange={updateSelectedDataSource('panel')}
                        fullWidth
                    />
                )}
            </Stack>

            {/* Filters
                ========================================= */}
            {showFilters && (
                <StyledWidgetAccordion title="Filters">
                    <Stack
                        sx={{
                            paddingBottom: 1,
                            paddingLeft: 2,
                        }}
                    >
                        {/* Add filter button
                            ========================================= */}
                        <BaseButton
                            label="Add Filter"
                            onClick={(evt) => addFilter()}
                            disabled={targetDataSource === null}
                            startIcon={<AddIcon />}
                            sx={{
                                alignSelf: 'start',
                            }}
                        />

                        {/* Filters list
                            ========================================= */}
                        {targetDataSource !== null && (
                            <Stack gap={1} marginTop={filters.length > 0 ? 1.5 : 0}>
                                {filters.map((_filter, idx) => {
                                    const isSetOperator = setOperators.includes(_filter.operator)
                                    return (
                                        /*  Filter item
                                        ========================================= */
                                        <Stack key={idx} direction="row" gap={1.5} alignItems="center">
                                            {/* Field select
                                                    ========================================= */}
                                            <FlexibleSelect
                                                label="Field"
                                                options={fields}
                                                value={fields.length !== 0 ? _filter.field : undefined}
                                                onChange={updateFilterField(idx)}
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            />

                                            {/* Operator select
                                                    ========================================= */}
                                            <BaseSelectWithLabel
                                                label="Operator"
                                                options={[
                                                    { label: 'Equals', value: 'eq' },
                                                    { label: 'Not equals', value: 'neq' },
                                                    { label: 'Greater than', value: 'gt' },
                                                    { label: 'Greater than or equals', value: 'gte' },
                                                    { label: 'Less than', value: 'lt' },
                                                    { label: 'Less than or equals', value: 'lte' },
                                                    { label: 'Contains any', value: 'containsAny' },
                                                    { label: 'Does not contain', value: 'notContainsAny' },
                                                ]}
                                                value={_filter.operator}
                                                onChange={updateFilterOperator(idx)}
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            />

                                            {isSetOperator ? (
                                                <BaseSelectWithLabel
                                                    label="Value"
                                                    options={
                                                        selectedDataSource !== null && _filter.field !== null
                                                            ? getDataSourceFieldValues(
                                                                  targetDataSource,
                                                                  _filter.field,
                                                                  selectedDataSource.preset
                                                              )
                                                            : []
                                                    }
                                                    value={_filter.value ?? []}
                                                    onChange={updateFilterValue(idx)}
                                                    disabled={_filter.field === null}
                                                    selectProps={{
                                                        multiple: true,
                                                    }}
                                                    sx={{
                                                        flex: '1 0 0',
                                                    }}
                                                />
                                            ) : (
                                                <BaseFilledTextField
                                                    label="Value"
                                                    value={_filter.value ?? ''}
                                                    onChange={(evt) => updateFilterValue(idx)(evt.target.value)}
                                                    sx={{
                                                        flex: '1 0 0',
                                                    }}
                                                />
                                            )}

                                            {/* Remove field button
                                                ========================================= */}
                                            <Tooltip title="Remove filter">
                                                <IconButton
                                                    onClick={(evt) => removeFilter(idx)}
                                                    sx={(theme) => ({
                                                        flexShrink: 0,

                                                        color: theme.palette.common.ung_pink,
                                                    })}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        )}
                    </Stack>
                </StyledWidgetAccordion>
            )}
        </Stack>
    )
}

export default ReportDataSourceSelector
