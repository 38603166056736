//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Box, Stack, Tab, Tabs, Typography, Divider, IconButton } from '@mui/material'
//* ======= Components and features
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    VideoWidgetType,
    ReportWidgetSettingsBaseType,
} from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'video'>
    settings: Omit<ReportWidgetType, 'content'>
    details: VideoWidgetType
}

function VideoWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { activeSlideId, updateWidget, updateWidgetStyles, updateVideoWidgetContent } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updateVideoWidgetContent: store.updateVideoWidgetContent,
    }))

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'video') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const [sourceInputValue, setSourceInputValue] = useState('')

    // Update any dependant states on activeWidget change.
    useEffect(() => {
        if (activeWidget !== null && activeWidget.content.kind === 'video') {
            const {
                content: { details },
            } = activeWidget

            setSourceInputValue(details.source)
        }
    }, [activeWidget])

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<VideoWidgetType>) => {
        if (activeSlideId !== null && widget !== null) {
            updateVideoWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Video" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(conditionalVisibility) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility,
                                        },
                                    })
                                }}
                            />

                            {/* Tooltip
                                ========================================= */}
                            <ReportWidgetTooltipSettings
                                tooltip={widget.settings.tooltip}
                                onChange={(tooltip) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            tooltip,
                                        },
                                    })
                                }}
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            <Typography fontSize={14} noWrap textAlign="left">
                                Source URL:
                            </Typography>

                            {/* Source
                                ========================================= */}
                            <BaseFilledTextField
                                value={sourceInputValue}
                                onChange={(evt) => setSourceInputValue(evt.target.value)}
                                onKeyUp={(evt) => {
                                    if (evt.key === 'Enter') {
                                        updateDetails({ source: sourceInputValue.trim() })
                                    }
                                }}
                                InputProps={{
                                    endAdornment:
                                        widget.details.source !== '' ? (
                                            <IconButton onClick={(evt) => updateDetails({ source: '' })}>
                                                <ClearRoundedIcon
                                                    sx={{
                                                        width: 22,
                                                        height: 22,
                                                    }}
                                                />
                                            </IconButton>
                                        ) : undefined,
                                }}
                                size="small"
                                hiddenLabel
                                fullWidth
                                sx={(theme) => ({
                                    '& .MuiInputBase-adornedEnd': {
                                        paddingRight: theme.spacing(0.5),
                                    },
                                })}
                            />

                            {/* Set source button
                                ========================================= */}
                            <BaseButton
                                label="Set Source"
                                onClick={(evt) => updateDetails({ source: sourceInputValue.trim() })}
                                disabled={sourceInputValue.trim() === ''}
                                variant="outlined"
                                sx={{
                                    alignSelf: 'center',

                                    paddingY: 1,
                                    paddingX: 4,
                                }}
                            />
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default VideoWidgetSettings
