//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles

interface IRemoveSurveyDialog {
    onConfirm?: () => void
    onClose?: () => void
}

function RemoveSurveyDialog({ onConfirm, onClose }: IRemoveSurveyDialog) {
    return (
        <Stack gap={2} minWidth={0} py={5} px={6} width={752}>
            {/* Title
				========================================= */}
            <Typography alignSelf="flex-start" fontSize={36} fontWeight={600} color="common.fuschia80">
                Remove Survey
            </Typography>

            <Typography fontSize={16} fontWeight={400} color="common.text_1">
                Are you sure you want to remove this survey?
            </Typography>

            <Typography paragraph fontSize={16} fontWeight={400} color="common.text_1" marginBottom={1}>
                By pressing the remove button, this survey will be removed. Are you sure you want to perform
                this action? Write{' '}
                <Box component="span" fontWeight={600}>
                    Organization Name
                </Box>{' '}
                in the textbox below to proceed with the action.
            </Typography>

            {/* Input
				========================================= */}
            <BaseFilledTextField
                hiddenLabel
                fullWidth
                sx={{
                    alignSelf: 'stretch',
                }}
            />

            {/* Notify checkbox
				========================================= */}
            <FormGroup
                sx={{
                    marginBottom: 1,
                }}
            >
                <FormControlLabel
                    label="Notify Participants"
                    control={<Checkbox />}
                    sx={{
                        alignSelf: 'flex-start',

                        marginRight: 0,
                    }}
                />
            </FormGroup>

            {/* Controls
				========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                {/* Cancel button
					========================================= */}
                <BaseButton
                    label="Cancel"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    color="warning"
                    size="large"
                    width="186px"
                />

                {/* Publish button
					========================================= */}
                <BaseButton
                    label="Remove"
                    onClick={(e) => onConfirm && onConfirm()}
                    variant="contained"
                    color="warning"
                    size="large"
                    width="186px"
                />
            </Stack>
        </Stack>
    )
}

export default RemoveSurveyDialog
