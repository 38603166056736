//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import SettingsAccount from 'features/settings/SettingsAccount'
import SettingsLinkedAccounts from 'features/settings/SettingsLinkedAccounts'
import SettingsPassword from 'features/settings/SettingsPassword'
//* ======= Custom logic
//* ======= Assets and styles

interface ISettings {}

function Settings({}: ISettings) {
    const [selectedPanel, setSelectedPanel] = useState(0)

    return (
        <PageCard>
            <PageCard.Body noPaddingRight>
                <Stack
                    spacing={1}
                    alignItems="center"
                    width="100%"
                    height="100%"
                    sx={{
                        py: 2,

                        backgroundColor: 'common.bg_1',
                    }}
                >
                    {/* Wrapper
                		========================================= */}
                    <Grid
                        container
                        sx={{
                            height: '100%',
                            width: 'clamp(620px, 50%, 90%)',
                        }}
                    >
                        {/* Settings list
                			========================================= */}
                        <Grid
                            item
                            xs={3}
                            sx={(theme) => ({
                                paddingLeft: 1,
                                paddingRight: 1,

                                borderRight: `1px solid ${theme.palette.common.border_2}`,
                            })}
                        >
                            <List
                                sx={(theme) => ({
                                    minWidth: 0,
                                    width: '100%',
                                    py: 0,

                                    '& .MuiListItem-root': {
                                        '&:not(:last-of-type)': {
                                            marginBottom: 0.5,
                                        },
                                    },
                                })}
                            >
                                {/* Account
                            		========================================= */}
                                <ListItem
                                    disableGutters
                                    disablePadding
                                    sx={{
                                        color: selectedPanel === 0 ? 'primary.main' : 'common.text_3',
                                    }}
                                >
                                    <ListItemButton dense onClick={(evt) => setSelectedPanel(0)}>
                                        <ListItemText
                                            primary="Account"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>

                                {/* Linked accounts
                            		========================================= */}
                                {/* <ListItem
                                    disableGutters
                                    disablePadding
                                    sx={{
                                        color: selectedPanel === 1 ? 'primary.main' : 'common.text_3',
                                    }}
                                >
                                    <ListItemButton dense onClick={(evt) => setSelectedPanel(1)}>
                                        <ListItemText
                                            primary="Linked Accounts"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem> */}

                                {/* Password
                            		========================================= */}
                                <ListItem
                                    disableGutters
                                    disablePadding
                                    sx={{
                                        color: selectedPanel === 2 ? 'primary.main' : 'common.text_3',
                                    }}
                                >
                                    <ListItemButton dense onClick={(evt) => setSelectedPanel(2)}>
                                        <ListItemText
                                            primary="Password"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>

                                {/* Security
                            		========================================= */}
                                {/* <ListItem
                                    disableGutters
                                    disablePadding
                                    sx={{
                                        color: selectedPanel === 3 ? 'primary.main' : 'common.text_3',
                                    }}
                                >
                                    <ListItemButton dense onClick={(evt) => setSelectedPanel(3)}>
                                        <ListItemText
                                            primary="Security"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem> */}

                                {/* Go premium button
                            		========================================= */}
                                {/* <ListItem
                                    disableGutters
                                    disablePadding
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                >
                                    <ListItemButton dense>
                                        <Typography fontSize={14} fontWeight={600} noWrap my={0.5}>
                                            Go <strong>Premium</strong>
                                        </Typography> */}

                                {/* <ListItemText
                                            primary="Go Premium"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        /> */}
                                {/* </ListItemButton>
                                </ListItem> */}
                            </List>
                        </Grid>

                        {/* Settings details
                			========================================= */}
                        <Grid
                            item
                            xs
                            height="100%"
                            sx={{
                                paddingLeft: 7,
                                paddingRight: 3,
                                overflowY: 'auto',
                            }}
                            className="u-scrollbar"
                        >
                            {selectedPanel === 0 ? (
                                <SettingsAccount />
                            ) : selectedPanel === 1 ? (
                                <SettingsLinkedAccounts />
                            ) : selectedPanel === 2 ? (
                                <SettingsPassword />
                            ) : selectedPanel === 3 ? (
                                false
                            ) : (
                                false
                            )}
                        </Grid>
                    </Grid>
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}

export default Settings
