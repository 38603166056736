//* ======= Libraries
import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import PasswordField from 'components/PasswordField/PasswordField'
import StyledDialog from 'components/dialog/StyledDialog'
import SignInLayout from 'pages/sign-in/SignInLayout'
//* ======= Custom logic
//* ======= Assets and styles
import { isPasswordValid } from 'helpers/validators'
import { ResetPasswordService } from 'services/AuthenticationService'

interface IResetPassword {}

function ResetPassword({}: IResetPassword) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [password, setPassword] = useState('')

    const resetPassword = () => {
        const token = searchParams.get('token')
        if (token && isPasswordValid(password)) {
            ResetPasswordService({ token, password }).then((res) => res.success && setIsDialogOpen(true))
        }
    }
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    return (
        <SignInLayout>
            {/* Main content
				========================================= */}
            <Stack gridRow={2} width="40%">
                {/* Title
					========================================= */}
                <Typography fontSize={36} fontWeight={400} color="common.text_1" mb={1}>
                    Reset Password
                </Typography>

                <Typography paragraph fontSize={18} fontWeight={400} color="common.text_1" mb={7}>
                    Enter the email address associated with your account and we’ll send you a link to reset
                    your password.
                </Typography>

                {/* New password
					========================================= */}
                <PasswordField
                    label="New Password"
                    hasComplexityBar
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{
                        marginBottom: 3,
                    }}
                />

                {/* Reset button
					========================================= */}
                <BaseButton
                    label="Reset Password"
                    disabled={!isPasswordValid(password)}
                    onClick={resetPassword}
                    variant="contained"
                    sx={{
                        py: 2,
                    }}
                />
            </Stack>

            {/* Dialog
				========================================= */}
            <StyledDialog
                open={isDialogOpen}
                onClose={(event, reason) => setIsDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <Stack alignItems="center" gap={3} py={2.5} px={3}>
                    <Typography fontSize={36} fontWeight={400} color="common.text_1">
                        Reset Password
                    </Typography>

                    <Typography fontSize={16} fontWeight={400} color="common.text_1" marginBottom={3}>
                        Your password has been reset. You can use your new password to login.
                    </Typography>

                    <BaseButton
                        label="Login"
                        onClick={(evt) => navigate('/')}
                        variant="contained"
                        size="large"
                        width="300px"
                    />
                </Stack>
            </StyledDialog>
        </SignInLayout>
    )
}

export default ResetPassword
