import { DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import StyledDialog from 'components/dialog/StyledDialog'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import { InsightWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { useEffect, useMemo, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import ErrorIcon from '@mui/icons-material/Error'

type AdvancedInsightCorrelationConfigDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSave: (config: InsightWidgetType['interventionConfig']['correlationConfig']) => void
    dataSourceFields: FlexibleSelectOptionType[]
    correlationConfig: InsightWidgetType['interventionConfig']['correlationConfig']
}

const AdvancedInsightCorrelationConfigDialog: React.FC<AdvancedInsightCorrelationConfigDialogProps> = ({
    isOpen,
    onClose,
    onSave,
    dataSourceFields,
    correlationConfig,
}) => {
    const [internalCorrelationConfig, setInternalCorrelationConfig] =
        useState<InsightWidgetType['interventionConfig']['correlationConfig']>(correlationConfig)

    useEffect(() => {
        if (isOpen) {
            setInternalCorrelationConfig(correlationConfig)
        }
    }, [isOpen])

    const handleAddItem = () => {
        setInternalCorrelationConfig((prev) => {
            return [
                ...prev,
                {
                    attribute: null,
                    label: '',
                    mode: 'continuous',
                },
            ]
        })
    }

    const handleRemoveItem = (index: number) => {
        setInternalCorrelationConfig((prev) => {
            if (prev.length === 1)
                return [
                    {
                        attribute: null,
                        label: '',
                        mode: 'continuous',
                    },
                ]

            return prev.filter((_, i) => i !== index)
        })
    }

    const handleUpdateItem = (
        index: number,
        updatedItem: Partial<InsightWidgetType['interventionConfig']['correlationConfig'][number]>
    ) => {
        setInternalCorrelationConfig((prev) => {
            const updatedConfig = structuredClone(prev)
            updatedConfig[index] = {
                ...updatedConfig[index],
                ...updatedItem,
            }
            return updatedConfig
        })
    }

    const isItemValid = (item: InsightWidgetType['interventionConfig']['correlationConfig'][number]) => {
        return item.attribute !== null && item.label !== ''
    }

    const isAllValid = useMemo(() => {
        return internalCorrelationConfig.every(isItemValid)
    }, [internalCorrelationConfig])

    return (
        <StyledDialog open={isOpen} disableEscapeKeyDown maxWidth="md" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Correlation Configuration</Typography>
                    <Tooltip title="Add Correlation">
                        <IconButton onClick={handleAddItem}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" gap={1}>
                    {internalCorrelationConfig.map((item, index) => {
                        const hasError = !isItemValid(item)
                        return (
                            <Stack key={index} direction="column" gap={1}>
                                {hasError && (
                                    <Tooltip title="Invalid Configuration">
                                        <ErrorIcon color="error" />
                                    </Tooltip>
                                )}
                                <Stack direction="row" gap={1} alignItems="center">
                                    <FlexibleSelect
                                        hiddenLabel={true}
                                        options={dataSourceFields}
                                        value={item.attribute}
                                        onChange={(value) =>
                                            handleUpdateItem(index, { attribute: value, label: value.field })
                                        }
                                        fullWidth
                                        sx={{
                                            height: '67px',
                                        }}
                                    />
                                    <BaseFilledTextField
                                        label="Label"
                                        value={item.label}
                                        fullWidth
                                        inputProps={{
                                            style: {
                                                height: '32.5px',
                                            },
                                        }}
                                        onChange={(e) => handleUpdateItem(index, { label: e.target.value })}
                                    />
                                    <BaseSelectWithLabel
                                        label="Mode"
                                        options={[
                                            { value: 'continuous', label: 'Continuous' },
                                            { value: 'categorical', label: 'Categorical' },
                                        ]}
                                        value={item.mode}
                                        onChange={(value) =>
                                            handleUpdateItem(index, {
                                                mode: value as 'continuous' | 'categorical',
                                            })
                                        }
                                        sx={{
                                            width: '150px',
                                            flexShrink: 0,
                                        }}
                                        selectProps={{
                                            style: {
                                                height: '67px',
                                            },
                                        }}
                                    />
                                    <Tooltip title="Remove">
                                        <IconButton onClick={() => handleRemoveItem(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        )
                    })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose}>Cancel</BaseButton>
                <BaseButton
                    onClick={() => {
                        onSave(internalCorrelationConfig)
                        onClose()
                    }}
                    disabled={!isAllValid}
                >
                    Save
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default AdvancedInsightCorrelationConfigDialog
