import { MultiGraph } from 'graphology'
import forceAtlas2 from 'graphology-layout-forceatlas2'

export function hexToRgbA(hex) {
    var c
    if (/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 4) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2], c[3], c[3]]
        } else if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2], 'f', 'f']
        } else if (c.length == 6) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2], 'f', 'f']
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 24) & 255, (c >> 16) & 255, (c >> 8) & 255, (c & 255) / 255].join(',') + ')'
    }
    throw new Error(hex + ' Bad Hex')
}

function clearSelected(items) {
    for (let idx in items) {
        items[idx].highlighted = undefined
        items[idx].tmpHide = false
        items[idx].tmpX = undefined
        items[idx].tmpY = undefined
    }
}

const highlightNodes = function ({
    nodeAdjacentList,
    nodes,
    edges,
    nodeStyle,
    edgeStyle,
    selectedNodes,
    nodeInteractionConfig,
}) {
    //a dictionary of nodes to be highlighetd and their layer number
    let nodeIds = {}
    //set layer 0 for all the nodes in selected nodes
    selectedNodes.forEach((x) => (nodeIds[x] = 0))

    //Reset all nodes and edges to their original state
    clearSelected(edges)
    clearSelected(nodes)

    if (selectedNodes.length === 0) {
        return { nodes, edges }
    }

    //if hightlight node adjacent mode enable, add the Adjacent Nodes to the nodeIds dic
    if (nodeInteractionConfig.hideNonAdjacentNodesOnSelect) {
        for (let id in nodeIds) {
            for (let i = 0; i < nodeInteractionConfig.layer; i++) {
                if (nodeAdjacentList[id]) {
                    nodeIds = { ...nodeAdjacentList[id][i], ...nodeIds }
                }
            }
        }
    }

    //calculate layout of each node
    nodes.forEach((node) => {
        const layer = nodeIds[node.id]
        //if node not in nodeIds dic, pass
        if (layer != null) {
            if (!nodeInteractionConfig.layerColor) {
                if (nodeInteractionConfig.dimOtherNodesOnSelect) {
                    if (layer === 0) node.highlighted = 0
                } else {
                    node.highlighted = 0
                }
            } else {
                node.highlighted = layer
            }
        } else if (nodeInteractionConfig.hideNonAdjacentNodesOnSelect && nodeInteractionConfig.dimOtherNodesOnSelect) {
            node.tmpHide = true
        }
    })

    edges.forEach((edge) => {
        if (nodeIds[edge.source] != null) {
            edge.highlighted = 'out'
        } else if (nodeIds[edge.target] != null) {
            edge.highlighted = 'in'
        } else if (nodeInteractionConfig.hideNonAdjacentNodesOnSelect && nodeInteractionConfig.dimOtherNodesOnSelect) {
            edge.tmpHide = true
        }
    })

    return { nodes, edges }
}

export default highlightNodes
