//* ======= Libraries
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useDrop, XYCoord } from 'react-dnd'
import { Merge } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'
import { useHotkeys } from 'react-hotkeys-hook'
import * as htmlToImage from 'html-to-image'
import { Stack, Box, Paper, useTheme, Typography, Skeleton } from '@mui/material'
//* ======= Components and features
import ReportSlideHeader from 'features/report-designer/slide/ReportSlideHeader'
import WidgetContainer, { WidgetContainerSlideDataType } from 'features/report-designer/widgets/WidgetContainer'
import { ReportWidgetsToolbarDraggableItemDragTypeValue } from 'features/report-designer/widgets-toolbar/ReportWidgetsToolbarDraggableItem'
//* ======= Custom logic
import useReportStore, { useReportActiveSlide } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetsToolbarDragItemType,
    ReportWidgetContentKindType,
    ReportWidgetType,
    NetworkWidgetType,
    ReportSlideHeaderType,
    ReportMasterSettingsType,
    ReportSlideType,
    ReportCopySourceType,
    ReportWidgetPositionType,
} from 'features/report-designer/types/reportDesigner.types'
import {
    createReportWidget,
    determineSlideDimensions,
    determineSlideOffset,
    getElementRelativePositionPercentage,
    shouldCaptureKeydown,
} from 'features/report-designer/helpers/reportDesigner.helper'
import ReportAnalyticsPanel from '../report-analytics-panel/ReportAnalyticsPanel'
import ReportSlideContextMenu, {
    ReportSlideContextMenuProps,
} from 'features/report-designer/slide/ReportSlideContextMenu'
import { defaultReportSlideValues } from 'features/report-designer/helpers/reportDesignerDefaultValues'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { zIndexOrdering } from 'sigma/utils'
//* ======= Assets and styles

type Props = {
    wrapperDimensions: { width: number; height: number } | null
    isResizing: boolean
}

function ReportSlide({ isResizing, wrapperDimensions }: Props) {
    const {
        status,
        viewMode,
        aspectRatio,
        masterSettings,
        layoutSettings,
        dataSources,
        activeSlideId,
        activeWidgetId,
        copySource,
        updateLayoutSettingsSidebarView,
        updateCopySource,
        updateSlide,
        addWidget,
        removeWidget,
        updateWidgetLayer,
        updateWidget,
        toggleWidgetDesignerHide,
        updateSlideWidgetsDesignerHide,
        setActiveWidgetId,
        undo,
        redo,
    } = useReportStore((store) => ({
        status: store.status,
        viewMode: store.viewMode,
        aspectRatio: store.aspectRatio,
        masterSettings: store.masterSettings,
        layoutSettings: store.layoutSettings,
        dataSources: store.dataSources,
        activeSlideId: store.activeSlideId,
        activeWidgetId: store.activeWidgetId,
        copySource: store.copySource,
        updateLayoutSettingsSidebarView: store.updateLayoutSettingsSidebarView,
        updateCopySource: store.updateCopySource,
        updateSlide: store.updateSlide,
        addWidget: store.addWidget,
        removeWidget: store.removeWidget,
        updateWidgetLayer: store.updateWidgetLayer,
        updateWidget: store.updateWidget,
        toggleWidgetDesignerHide: store.toggleWidgetDesignerHide,
        updateSlideWidgetsDesignerHide: store.updateSlideWidgetsDesignerHide,
        setActiveWidgetId: store.setActiveWidgetId,
        undo: store.undo,
        redo: store.redo,
    }))

    const activeSlide = useReportActiveSlide()

    const [focuseWidget, setFocuseWidget] = useState<ReportWidgetType['id'] | null>(null)

    // Use a ref to hold the current values of your states
    const currentStateRef = useRef({ activeWidgetId, activeSlide, focuseWidget, copySource })

    // Update the ref whenever the relevant state changes
    useEffect(() => {
        currentStateRef.current = { activeWidgetId, activeSlide, focuseWidget, copySource }
    }, [activeWidgetId, activeSlide, focuseWidget, copySource])

    const muiTheme = useTheme()

    /* =========================================
     * Header
     */

    const headerData: Merge<ReportMasterSettingsType['header'], ReportSlideHeaderType> = useMemo(() => {
        if (activeSlide === null) {
            return {
                ...structuredClone(masterSettings.header),
                isOverridden: false,
                primaryText: '',
            }
        }

        return structuredClone({
            isEnabled: activeSlide.header.isEnabled,
            isOverridden: activeSlide.header.isOverridden,
            primaryText: activeSlide.header.primaryText,
            secondaryText: masterSettings.header.secondaryText,
            image: masterSettings.header.image,
            height: activeSlide.header.isOverridden ? activeSlide.header.height : masterSettings.header.height,
            backgroundColor: masterSettings.header.backgroundColor,
            hasBottomShadow: masterSettings.header.hasBottomShadow,
        })
    }, [masterSettings.header, activeSlide?.header])

    const headerContainerRef = useRef<HTMLElement>(null)

    // Add header's container element to the list of "widgets" so the Moveable library can provide
    // guidelines when dragging/dropping widgets.
    useEffect(() => {
        if (headerData.isEnabled === true && headerContainerRef.current !== null) {
            setWidgetElementsDict((prevState) => ({
                ...prevState,
                header_container: headerContainerRef.current!,
            }))

            return () => {
                setWidgetElementsDict((prevState) => {
                    const { header_container, ...rest } = prevState

                    return {
                        ...rest,
                    }
                })
            }
        }
    }, [headerData.isEnabled])

    /* =========================================
     * Widget container data
     */

    // All rendered widgets "HTMLElement" node ref. Needed for Moveable "elementGuidelines" inside WidgetContainer.
    const [widgetElementsDict, setWidgetElementsDict] = useState<WidgetContainerSlideDataType['widgetElementsDict']>({})

    const widgetContainerSlideData: WidgetContainerSlideDataType = useMemo(
        () => ({
            dimensions: activeSlide?.dimensions || defaultReportSlideValues.dimensions,
            widgetElementsDict: widgetElementsDict,
        }),
        [activeSlide?.dimensions, widgetElementsDict]
    )

    const onWidgetElementExposed = useCallback((id: ReportWidgetType['id'], element: HTMLElement) => {
        if (widgetElementsDict[id] === undefined) {
            setWidgetElementsDict((prevState) => ({
                ...prevState,
                [id]: element,
            }))
        }
    }, [])

    /* =========================================
     * Network
     */

    const [isAnalyticsPanelExpanded, setIsAnalyticsPanelExpanded] = useState(false)

    // ToDo  Lets have a chat about this
    const networkWidget = useMemo(() => {
        if (activeSlide) {
            //if active widge is network, then show this network analytics in the panel
            if (activeWidgetId) {
                const activeWidget = activeSlide.widgets.find((x) => x.id === activeWidgetId)
                if (
                    activeWidget &&
                    activeWidget.content.kind === 'network' &&
                    (activeWidget.content.details as NetworkWidgetType).networkAnalyticsPanel.show
                )
                    return activeWidget
            }
            //otherwise, display the first network (if any)
            const networkWidget = activeSlide.widgets.find(
                (x) =>
                    x.content.kind === 'network' && (x.content.details as NetworkWidgetType).networkAnalyticsPanel.show
            )
            if (networkWidget) return networkWidget
        }
        //if no network in the slide, then hide the panel
        return false
    }, [activeSlide, activeWidgetId])

    /* =========================================
     * Effects
     */

    // Determine slide dimensions and update store
    useEffect(() => {
        if (activeSlideId !== null && activeSlide?.zoomLevel !== undefined && wrapperDimensions !== null) {
            //! Temporary solution. Taking "ReportAnalyticsPanel" into consideration for slide width.
            let wrapperWidthWithPanel = wrapperDimensions.width
            if (networkWidget) {
                // The original gap is only 8 pixels, but for some reason we need 24 pixels to prevent a scrollbar!
                wrapperWidthWithPanel = isAnalyticsPanelExpanded
                    ? wrapperDimensions.width - (300 + 24)
                    : wrapperDimensions.width - (50 + 24)
            }

            const calculatedDimensions = determineSlideDimensions(
                // We substract a few pixels to allow slide's "box shadow" to be visible.
                wrapperWidthWithPanel - 6,
                // We substract a few pixels to allow slide's "box shadow" to be visible.
                wrapperDimensions.height - 6,
                aspectRatio,
                activeSlide.zoomLevel
            )

            const slideContainerBoundingRect = document
                .getElementById('report_slide_container')!
                .getBoundingClientRect()

            const slideOffsets = determineSlideOffset({
                parentRect: slideContainerBoundingRect,
                slideDimensions: calculatedDimensions,
            })

            const finalDimensions = {
                width: calculatedDimensions.width,
                height: calculatedDimensions.height,
                currentScale: calculatedDimensions.currentScale,
                offsetTop: slideOffsets.top,
                offsetLeft: slideOffsets.left,
            }

            // Store current slide's dimensions in the store to be used in child components.
            updateSlide({
                slideId: activeSlideId,
                data: {
                    dimensions: structuredClone(finalDimensions),
                },
            })
        }
    }, [
        activeSlideId,
        activeSlide?.zoomLevel,
        wrapperDimensions,
        aspectRatio,
        networkWidget,
        isAnalyticsPanelExpanded,
        updateSlide,
    ])

    /* =========================================
     * Widget drop
     */

    const onWidgetDrop = (widgetKind: ReportWidgetContentKindType, clientOffset: XYCoord) => {
        if (activeSlide === null) {
            return
        }

        const slideContainerElement = document.getElementById('report_slide_container')

        const dropPositionRelativeToSlide = {
            top: clientOffset.y - activeSlide.dimensions.offsetTop + slideContainerElement!.scrollTop,
            left: clientOffset.x - activeSlide.dimensions.offsetLeft + slideContainerElement!.scrollLeft,
        }

        const slideSize = {
            width: activeSlide.dimensions.width,
            height: activeSlide.dimensions.height,
        }

        const dropPositionInPercentage = getElementRelativePositionPercentage(dropPositionRelativeToSlide, slideSize)

        const widget = createReportWidget(widgetKind, dropPositionInPercentage, slideSize, muiTheme.palette.mode)

        addWidget({
            slideId: activeSlide.id,
            data: structuredClone(widget),
        })
    }

    // React-dnd "useDrop"
    const [, dropTarget] = useDrop<ReportWidgetsToolbarDragItemType, void, unknown>(
        () => ({
            accept: ReportWidgetsToolbarDraggableItemDragTypeValue,
            // canDrop: (item, monitor) => true,
            drop: (item, monitor) => {
                onWidgetDrop(item.widgetKind, monitor.getClientOffset() ?? { x: 0, y: 0 })
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [activeSlide, activeSlide?.dimensions]
    )

    /* =========================================
     * Handlers
     */

    // On click inside empty areas, change settings sidebar view and de-select active widget.
    const onEmptyAreaClick = (evt: React.MouseEvent<HTMLDivElement | HTMLParagraphElement, MouseEvent>) => {
        if (evt.target === evt.currentTarget && ['pending', 'faulty'].includes(status) === false) {
            if (layoutSettings.settingsSidebar.view !== 'report') {
                updateLayoutSettingsSidebarView({
                    view: 'report',
                })
            }
        } else {
            // Ignore click
        }
    }

    const onSlideClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (viewMode === 'design' && evt.target === evt.currentTarget) {
            if (layoutSettings.settingsSidebar.view !== 'slide') {
                updateLayoutSettingsSidebarView({
                    view: 'slide',
                })
            }
        } else {
            // Ignore click
        }
    }

    /* =========================================
     * Context menu
     */

    // Context menu state
    const initialContextMenuState: Pick<
        ReportSlideContextMenuProps,
        'isOpen' | 'mouseX' | 'mouseY' | 'view' | 'widgetData' | 'widgetPositionIndex'
    > = {
        isOpen: false,
        mouseX: 0,
        mouseY: 0,
        view: 'slide',
    }
    const [contextMenuState, setContextMenuState] = useState(initialContextMenuState)

    const onContextMenuHandler = useCallback(
        (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
            isWidgetMenu: boolean,
            widget?: ReportWidgetType,
            positionIndex?: number
        ) => {
            if (viewMode === 'design') {
                event.preventDefault()
                event.stopPropagation()

                setContextMenuState((prevState) => {
                    // Close the previous menu if it's open.
                    if (prevState.isOpen === true) {
                        return initialContextMenuState
                    } else {
                        // Show widget context menu.
                        if (isWidgetMenu && widget !== undefined && positionIndex !== undefined) {
                            return {
                                ...prevState,
                                isOpen: true,
                                mouseX: event.clientX,
                                mouseY: event.clientY,
                                view: 'widget',
                                widgetData: widget,
                                widgetPositionIndex: positionIndex,
                            }
                        }
                        // Show slide context menu.
                        else {
                            return {
                                ...prevState,
                                isOpen: true,
                                mouseX: event.clientX,
                                mouseY: event.clientY,
                                view: 'slide',
                            }
                        }
                    }
                })
            } else {
                // Preview mode doesn't have a context menu at the moment.
            }
        },
        [viewMode]
    )

    const onWidgetCopy = (widget: ReportWidgetType) => {
        updateCopySource({
            data: {
                widget: structuredClone(widget),
            },
        })

        setContextMenuState(initialContextMenuState)
    }

    const onWidgetPaste = (
        _activeSlide: ReportSlideType | null,
        _copySource: ReportCopySourceType,
        mouseX: number,
        mouseY: number,
        position?: ReportWidgetPositionType
    ) => {
        if (_activeSlide === null || _copySource.widget === null) {
            return
        }

        const dropPositionRelativeToSlide = {
            top: mouseY - _activeSlide.dimensions.offsetTop,
            left: mouseX - _activeSlide.dimensions.offsetLeft,
        }

        const slideSize = {
            width: _activeSlide.dimensions.width,
            height: _activeSlide.dimensions.height,
        }

        const dropPositionInPercentage = getElementRelativePositionPercentage(dropPositionRelativeToSlide, slideSize)

        const newWidget: ReportWidgetType = {
            ..._copySource.widget,
            id: uuidv4(),
            title: _copySource.widget.title + ' copy',
            position: position
                ? position
                : {
                      top: dropPositionInPercentage.top + '%',
                      left: dropPositionInPercentage.left + '%',
                  },
        }

        addWidget({
            slideId: _activeSlide.id,
            data: structuredClone(newWidget),
        })

        setContextMenuState(initialContextMenuState)
    }

    const onWidgetDelete = (widgetId: ReportWidgetType['id']) => {
        if (activeSlide !== null) {
            //ToDo
            //check if the deleted widget is filter, remove the filter from controlled widgets
            const widgetToDelete = activeSlide.widgets.find((x) => x.id === widgetId)
            // if(widgetToDelete?.content.kind === 'filter'){
            //     for(let wid of (widgetToDelete.content.details as FilterWidgetType).widgets){
            //         contextDispatch({
            //             type: 'WIDGET_REMOVE_FILTER',
            //             payload: {
            //                 slideId: activeSlide.id,
            //                 widgetId: wid,
            //                 filterWidgetId: widgetId,
            //             },
            //         })
            //     }
            // }

            removeWidget({
                slideId: activeSlide.id,
                widgetId: widgetId,
            })

            setContextMenuState(initialContextMenuState)
        }
    }

    const onWidgetLayerChange = (widgetPositionIndex: number, step: 1 | 'top' | -1 | 'back') => {
        if (activeSlide === null) {
            return
        }

        let newIndex: number = -1

        switch (step) {
            case 1:
                if (widgetPositionIndex === activeSlide.widgets.length - 1) {
                    return
                } else {
                    newIndex = widgetPositionIndex + 1
                }

                break

            case 'top':
                if (widgetPositionIndex === activeSlide.widgets.length - 1) {
                    return
                } else {
                    newIndex = activeSlide.widgets.length - 1
                }

                break

            case -1:
                if (widgetPositionIndex === 0) {
                    return
                } else {
                    newIndex = widgetPositionIndex - 1
                }

                break

            case 'back':
                if (widgetPositionIndex === 0) {
                    return
                } else {
                    newIndex = 0
                }

                break

            default:
                break
        }

        updateWidgetLayer({
            slideId: activeSlide.id,
            previousIndex: widgetPositionIndex,
            newIndex: newIndex,
        })

        setContextMenuState(initialContextMenuState)
    }

    const onWidgetDesignerHide = (widgetId: ReportWidgetType['id']) => {
        toggleWidgetDesignerHide({
            slideId: activeSlide!.id,
            widgetId: widgetId,
        })

        setContextMenuState(initialContextMenuState)
    }

    const onWidgetDesignerHideOthers = (widgetId: ReportWidgetType['id']) => {
        updateSlideWidgetsDesignerHide({
            slideId: activeSlide!.id,
            hide: true,
            excludeWidgetIds: [widgetId],
        })

        setContextMenuState(initialContextMenuState)
    }

    const onShowAllWidgets = () => {
        updateSlideWidgetsDesignerHide({
            slideId: activeSlide!.id,
            hide: false,
        })

        setContextMenuState(initialContextMenuState)
    }

    const onUpdateWidgetLock = (widgetId: ReportWidgetType['id'], locked: boolean) => {
        if (activeSlide === null) return
        updateWidget({
            slideId: activeSlide.id,
            widgetId: widgetId,
            data: {
                locked,
            },
        })

        setContextMenuState(initialContextMenuState)
    }

    /* =========================================
     * Save slide thumbnail
     */

    // const saveSlideImage = debounce(async () => {
    //     if (activeSlide === null || activeSlide.id === null) return
    //     const element = document.getElementById('slide_' + activeSlide.id)
    //     if (element === null) return
    //     const data = await htmlToImage.toPng(element, {
    //         width: element.clientWidth,
    //         height: element.clientHeight,
    //         canvasWidth: 300,
    //         canvasHeight: (element.clientHeight * 300) / element.clientWidth,
    //         cacheBust: true,
    //         quality: 0.01,
    //     })

    //     contextDispatch({
    //         type: 'SLIDE_EDIT',
    //         payload: {
    //             slideId: activeSlide.id,
    //             data: { thumbnail: data },
    //         },
    //     })
    // }, 1000)

    // useEffect(() => {
    //     // saveSlideImage()
    // }, [activeSlide?.widgets])

    useEffect(() => {
        setFocuseWidget(null)
    }, [activeWidgetId])

    const onHotkey = (evt: KeyboardEvent) => {
        if (shouldCaptureKeydown() || currentStateRef.current.activeSlide === null) return

        // If there is an active widget
        if (currentStateRef.current.activeWidgetId !== null) {
            // ctrl + key
            if (evt.ctrlKey) {
                switch (evt.key) {
                    // Arrange elements forward or to front if alt is pressed
                    case ']':
                        onWidgetLayerChange(
                            currentStateRef.current.activeSlide.widgets.findIndex(
                                (x) => x.id === currentStateRef.current.activeWidgetId
                            ),
                            evt.altKey ? 'top' : 1
                        )

                        break
                    // Arrange elements backward or to back if alt is pressed
                    case '[':
                        onWidgetLayerChange(
                            currentStateRef.current.activeSlide.widgets.findIndex(
                                (x) => x.id === currentStateRef.current.activeWidgetId
                            ),
                            evt.altKey ? 'back' : -1
                        )

                        break
                    // copy the active widget
                    case 'c':
                        const _activeWidget = currentStateRef.current.activeSlide.widgets.find(
                            (x) => x.id === currentStateRef.current.activeWidgetId
                        )
                        if (_activeWidget) {
                            onWidgetCopy(_activeWidget)
                        }

                        break
                    // focus active widget
                    case 'Enter':
                        evt.preventDefault()
                        evt.stopPropagation()
                        setFocuseWidget(currentStateRef.current.activeWidgetId)
                        break
                    // hide the active widget or hide others if alt is pressed
                    case 'h':
                    case 'H':
                        evt.preventDefault()
                        if (evt.altKey) {
                            onWidgetDesignerHideOthers(currentStateRef.current.activeWidgetId)
                        } else {
                            onWidgetDesignerHide(currentStateRef.current.activeWidgetId)
                        }
                        break
                    // lock/unlock the active widget
                    case 'l':
                    case 'L':
                        evt.preventDefault()
                        const _activeWidgetLock = currentStateRef.current.activeSlide.widgets.find(
                            (x) => x.id === currentStateRef.current.activeWidgetId
                        )
                        if (_activeWidgetLock) {
                            onUpdateWidgetLock(_activeWidgetLock.id, !_activeWidgetLock.locked)
                        }
                        break

                    default:
                        break
                }
            } else {
                switch (evt.key) {
                    // delete the active widget
                    case 'Delete':
                        removeWidget({
                            slideId: currentStateRef.current.activeSlide.id,
                            widgetId: currentStateRef.current.activeWidgetId,
                        })

                        break

                    // select next widget or previous widget if shift is pressed
                    case 'Tab':
                        evt.preventDefault()
                        evt.stopPropagation()
                        const filteredWidgets = currentStateRef.current.activeSlide.widgets.filter(
                            (x) => x.hide !== true
                        )
                        const currentIndex = filteredWidgets.findIndex(
                            (x) => x.id === currentStateRef.current.activeWidgetId
                        )

                        let newIndex: number | null = null
                        if (evt.shiftKey) {
                            if (currentIndex === 0) {
                                newIndex = filteredWidgets.length - 1
                            } else {
                                newIndex = currentIndex - 1
                            }
                        } else {
                            if (currentIndex === filteredWidgets.length - 1) {
                                newIndex = 0
                            } else {
                                newIndex = currentIndex + 1
                            }
                        }

                        if (filteredWidgets[newIndex]) {
                            setActiveWidgetId({
                                widgetId: filteredWidgets[newIndex].id,
                            })
                        }

                        break
                    // deselect the active widget
                    case 'Escape':
                        setActiveWidgetId({ widgetId: null })
                        break
                    default:
                        break
                }
            }
        }
        // If there is no active widget
        else {
        }

        // shortcut for actions that don't depend on the active widget

        switch (evt.key) {
            // paste the copied widget
            case 'v':
            case 'V':
                if (evt.ctrlKey && currentStateRef.current.copySource.widget !== null) {
                    const newPosition: ReportWidgetPositionType = {
                        top: '0%',
                        left: '0%',
                    }

                    if (typeof currentStateRef.current.copySource.widget.position.top === 'string') {
                        newPosition.top =
                            Number(
                                currentStateRef.current.copySource.widget.position.top.substring(
                                    0,
                                    currentStateRef.current.copySource.widget.position.top.length - 1
                                )
                            ) +
                            2 +
                            '%'
                    } else {
                        newPosition.top = currentStateRef.current.copySource.widget.position.top + 10
                    }

                    if (typeof currentStateRef.current.copySource.widget.position.left === 'string') {
                        newPosition.left =
                            Number(
                                currentStateRef.current.copySource.widget.position.left.substring(
                                    0,
                                    currentStateRef.current.copySource.widget.position.left.length - 1
                                )
                            ) +
                            2 +
                            '%'
                    } else {
                        newPosition.left = currentStateRef.current.copySource.widget.position.left + 10
                    }

                    onWidgetPaste(
                        currentStateRef.current.activeSlide,
                        currentStateRef.current.copySource,
                        0,
                        0,
                        newPosition
                    )
                }

                break
            // show all widgets
            case 'H':
            case 'h':
                if (evt.ctrlKey && evt.shiftKey) {
                    evt.preventDefault()
                    onShowAllWidgets()
                }
                break
            case 'z':
            case 'Z':
                if (evt.ctrlKey) {
                    evt.preventDefault()
                    if (evt.shiftKey) {
                        redo()
                    } else {
                        undo()
                    }
                }
                break

            default:
                break
        }
    }

    useEffect(() => {
        if (viewMode === 'design') {
            window.addEventListener('keydown', onHotkey, true)
        }
        return () => {
            window.removeEventListener('keydown', onHotkey, true)
        }
    }, [viewMode])

    return (
        <>
            {activeSlide !== null ? (
                <Stack
                    onClick={onEmptyAreaClick}
                    direction="row"
                    gap={1}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    <Box
                        id={`slide-${activeSlide.id}`}
                        component={Paper}
                        elevation={2}
                        ref={dropTarget}
                        onClick={onSlideClick}
                        onContextMenu={(evt) => onContextMenuHandler(evt, false)}
                        sx={(theme) => ({
                            position: 'relative',
                            isolation: 'isolate',

                            width: activeSlide.dimensions.width,
                            height: activeSlide.dimensions.height,
                            minHeight: activeSlide.dimensions.height,
                            overflow: 'hidden',
                            backgroundColor: activeSlide.styles.backgroundColor.isEnabled
                                ? activeSlide.styles.backgroundColor.color
                                : masterSettings.backgroundColor.isEnabled
                                ? masterSettings.backgroundColor.color
                                : // @Theme conditional
                                theme.palette.mode === 'light'
                                ? theme.palette.common.bg_1
                                : theme.palette.common.bg_3,
                        })}
                    >
                        <Box sx={(theme) => ({ opacity: isResizing ? 0 : 1, transition: 'opacity .5s' })}>
                            {/* Header
                            ========================================= */}
                            {headerData.isEnabled === true && (
                                <Box
                                    ref={headerContainerRef}
                                    className="slide-header"
                                    // TODO: Add the ability to switch to a "tab" of settings sidebars then extract this function.
                                    onClick={(evt) => {
                                        if (viewMode === 'design' && layoutSettings.settingsSidebar.view !== 'slide') {
                                            updateLayoutSettingsSidebarView({
                                                view: 'slide',
                                            })
                                        }
                                    }}
                                    sx={(theme) => ({
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: `${headerData.height}%`,
                                        padding: theme.spacing(1.5, 2),
                                        paddingLeft: theme.spacing(3),
                                        boxShadow: headerData.hasBottomShadow ? 2 : undefined,
                                        backgroundColor: headerData.backgroundColor.isEnabled
                                            ? headerData.backgroundColor.color
                                            : undefined,
                                    })}
                                >
                                    <ReportSlideHeader
                                        data={headerData}
                                        fontSizeScaleFactor={activeSlide.dimensions.currentScale}
                                    />
                                </Box>
                            )}

                            {/* Widgets
                            ========================================= */}
                            {activeSlide.widgets.map((_widget, idx) => {
                                var opacity = 1
                                var conditionalVisible = false
                                if (_widget.hide === true || (viewMode === 'design' && _widget.designerHide === true))
                                    return null
                                if (
                                    _widget.conditionalVisibility.isEnabled &&
                                    _widget.conditionalVisibility.variableName !== null
                                ) {
                                    if (
                                        _widget.conditionalVisibility.variableValue !==
                                        activeSlide.variables[_widget.conditionalVisibility.variableName!]
                                    ) {
                                        if (viewMode === 'design') opacity = 0.2
                                        else opacity = 0
                                    } else {
                                        conditionalVisible = true
                                    }
                                }

                                if (viewMode === 'design' && focuseWidget !== null) {
                                    opacity = _widget.id !== focuseWidget ? 0.2 : 1
                                }

                                return (
                                    <WidgetContainer
                                        key={_widget.id}
                                        widget={_widget}
                                        slideData={widgetContainerSlideData}
                                        exposeElement={onWidgetElementExposed}
                                        onContextMenu={(event, widget) => {
                                            onContextMenuHandler(event, true, widget, idx)
                                        }}
                                        conditionalVisible={conditionalVisible}
                                        positionIndex={idx}
                                        opacity={opacity}
                                        updateFocusMode={(_widgetId) => setFocuseWidget(_widgetId)}
                                    />
                                )
                            })}
                        </Box>

                        {/* (Out-of-flow) Context menu
                            ========================================= */}
                        <ReportSlideContextMenu
                            isOpen={contextMenuState.isOpen}
                            mouseX={contextMenuState.mouseX}
                            mouseY={contextMenuState.mouseY}
                            view={contextMenuState.view}
                            widgetData={contextMenuState.widgetData}
                            widgetPositionIndex={contextMenuState.widgetPositionIndex}
                            onClose={() => setContextMenuState((prevState) => ({ ...prevState, isOpen: false }))}
                            onCopy={onWidgetCopy}
                            canPaste={copySource.widget !== null}
                            onPaste={(x, y) => onWidgetPaste(activeSlide, copySource, x, y)}
                            onDelete={onWidgetDelete}
                            canChangeLayerToTop={
                                contextMenuState.widgetPositionIndex !== activeSlide.widgets.length - 1
                            }
                            canChangeLayerToBottom={contextMenuState.widgetPositionIndex !== 0}
                            onLayerChange={onWidgetLayerChange}
                            onHide={onWidgetDesignerHide}
                            onHideOthers={onWidgetDesignerHideOthers}
                            onUpdateWidgetLock={onUpdateWidgetLock}
                            onShowAllWidgets={onShowAllWidgets}
                        />
                    </Box>

                    {/* Network widget details panel
                        ========================================= */}
                    {networkWidget && (
                        <ReportAnalyticsPanel
                            isExpanded={isAnalyticsPanelExpanded}
                            toggleExpanded={() => {
                                setIsAnalyticsPanelExpanded((pv) => !pv)
                            }}
                            dataSources={dataSources}
                            widget={networkWidget.content.details as NetworkWidgetType}
                            title={networkWidget.title}
                        />
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default ReportSlide
