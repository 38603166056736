//* ======= Libraries
import React, { useState, useContext, useEffect } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelect from 'components/base/BaseSelect'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
//* ======= Assets and styles
import { TimePicker } from '@mui/x-date-pickers'
import { useParams } from 'react-router-dom'
import { ReminderScheduleType, UpdateSurveyReminderScheduleService } from 'services/SurveyService'
import { SurveyContext } from 'contexts/SurveyContex'
import moment, { Moment } from 'moment'

interface IScheduleNotificationDialog {
    onConfirm?: () => void
    onClose: () => void
}
const defaultTime = moment().hour(9).minute(0).second(0).millisecond(0)

function ScheduleNotificationDialog({ onConfirm, onClose }: IScheduleNotificationDialog) {
    const { pid, sid } = useParams()
    const { survey, updateSurvey } = useContext(SurveyContext)
    const selectOptions = Array.from(Array(10).keys()).map((i) => ({
        name: (i + 1).toString(),
        value: i + 1,
    }))

    const [time, setTime] = useState<Moment | null>(defaultTime)

    const [schedule, setSchedule] = useState<ReminderScheduleType>({
        afterInvite: 7,
        beforeDeadline: 3,
        hasMax: true,
        maxSend: 5,
        time: defaultTime.toDate(),
        minInterval: 2,
        weekdaysOnly: true,
    })

    useEffect(() => {
        if (survey?.reminderSchedule != null) {
            setSchedule(survey.reminderSchedule)
            setTime(moment(survey.reminderSchedule.time))
        }
    }, [survey, survey?.reminderSchedule])

    const updateSchedule = (field: string) => (value: any) => {
        setSchedule((s) => ({ ...s, [field]: value }))
    }

    const updateScheduleToggle = (field: 'hasMax' | 'weekdaysOnly') => () => {
        setSchedule((s) => ({ ...s, [field]: !s[field] }))
    }

    const update = () => {
        if (sid != null && pid != null) {
            schedule.time = time?.toDate() || defaultTime.toDate()
            UpdateSurveyReminderScheduleService(pid, sid, { schedule }).then((res) => {
                if (res.success) {
                    updateSurvey({ reminderSchedule: schedule })
                    onClose()
                }
            })
        }
    }

    return (
        <Stack
            gap={1}
            sx={{
                py: 3,
                px: 4,
            }}
        >
            {/* Title
				========================================= */}
            <Typography fontSize={25} fontWeight={600} color="primary" marginBottom={1}>
                Schedule Recurring Reminder
            </Typography>

            {/* Send after invite
				========================================= */}
            <Typography fontSize={16} fontWeight={400}>
                Send After Invite
            </Typography>
            <BaseSelect
                options={selectOptions}
                value={schedule.afterInvite}
                onChangeHandler={updateSchedule('afterInvite')}
                placeholder="Select"
                sx={{
                    marginBottom: 1,
                }}
            />

            {/* Send before deadline
				========================================= */}
            <Typography fontSize={16} fontWeight={400}>
                Send Before Deadline
            </Typography>
            <BaseSelect
                options={selectOptions}
                value={schedule.beforeDeadline}
                onChangeHandler={updateSchedule('beforeDeadline')}
                placeholder="Select"
                sx={{
                    marginBottom: 1,
                }}
            />

            {/* Datetime selection
				========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={16} fontWeight={400}>
                    Send Time
                </Typography>

                <FormGroup aria-label="enabled" row>
                    <FormControlLabel
                        checked={schedule.weekdaysOnly}
                        onChange={updateScheduleToggle('weekdaysOnly')}
                        control={<BaseSwitch color="primary" />}
                        label="Weekdays Only"
                        labelPlacement="start"
                        sx={{
                            marginLeft: 0,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                                fontWeight: 400,
                            },
                        }}
                    />
                </FormGroup>
            </Stack>
            <Box
                sx={{
                    marginBottom: 1,
                }}
            >
                <TimePicker
                    value={time}
                    onChange={(v) => setTime(v)}
                    slotProps={{
                        textField: {
                            hiddenLabel: true,
                            fullWidth: true,
                        },
                    }}
                />
            </Box>

            {/* Send limit (?)
				========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={16} fontWeight={400}>
                    Max Send
                </Typography>

                <FormGroup aria-label="enabled" row>
                    <FormControlLabel
                        control={<BaseSwitch color="primary" />}
                        label="Enable"
                        checked={schedule.hasMax}
                        onChange={updateScheduleToggle('hasMax')}
                        labelPlacement="start"
                        sx={{
                            marginLeft: 0,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                                fontWeight: 400,
                            },
                        }}
                    />
                </FormGroup>
            </Stack>
            <BaseSelect
                options={selectOptions}
                value={schedule.maxSend}
                onChangeHandler={updateSchedule('maxSend')}
                placeholder="Select"
                sx={{
                    marginBottom: 1,
                }}
            />

            {/* Min interval
				========================================= */}
            <BaseSelect
                options={selectOptions}
                value={schedule.minInterval}
                onChangeHandler={updateSchedule('minInterval')}
                placeholder="Select Min Interval"
                sx={{
                    marginBottom: 4,
                }}
            />

            {/* Controls
				========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={3}>
                {/* Cancel button
					========================================= */}
                <BaseButton
                    label="Close"
                    onClick={() => onClose()}
                    variant="outlined"
                    sx={{
                        width: 180,
                        py: 1.5,
                    }}
                />

                {/* Submit button
					========================================= */}
                <BaseButton
                    label="Schedule"
                    onClick={update}
                    variant="contained"
                    sx={{
                        width: 180,
                        py: 1.5,
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default ScheduleNotificationDialog
