//* ======= Libraries
import React, { useCallback } from 'react'
import { isEqual } from 'lodash'
import {
    Stack,
    Typography,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Tooltip,
    Divider,
    Switch,
    FormGroup,
    FormHelperText,
} from '@mui/material'
//* ======= Components and features
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'

//* ======= Custom logic
import {
    TableDefinitionModeTies,
    TableDefinitionModeTiesRelation,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
//* ======= Assets and styles
import QuestionIcon from '@mui/icons-material/HelpOutline'
import { useDrop } from 'react-dnd'
import TableTiesRelationshipAccordionItem, {
    TableDefinitionTiesRelationshipItemDragTypeValue,
} from './TableTiesRelationshipAccordionItem'

type Props = {
    definition: TableDefinitionModeTies
    fieldOptions: FlexibleSelectOptionType[]
    onUpdate: (data: TableDefinitionModeTies) => void
    disabled: boolean
}

function TableDefinitionTiesSettings({ definition, fieldOptions, onUpdate, disabled }: Props) {
    // Helps with visual drop cursor style when dragging list items. Doesn't do anything else.
    const [, dropTarget] = useDrop(() => ({ accept: TableDefinitionTiesRelationshipItemDragTypeValue }))

    const [localRelations, setLocalRelations] = React.useState<TableDefinitionModeTiesRelation[]>(
        structuredClone(definition.relations)
    )

    const updateDefinitionField = (
        field: keyof TableDefinitionModeTies,
        value: TableDefinitionModeTies[keyof TableDefinitionModeTies]
    ) => {
        const needsUpdate = isEqual(definition[field], value) === false

        if (needsUpdate) {
            onUpdate({
                ...definition,
                [field]: value,
            })
        }
    }

    const updateRelationshipField = (
        relationship: string,
        field: keyof TableDefinitionModeTiesRelation,
        value: TableDefinitionModeTiesRelation[keyof TableDefinitionModeTiesRelation]
    ) => {
        const currentRelationindex = localRelations.findIndex((x) => x.name === relationship)
        if (currentRelationindex === -1) return
        const currentRelation = localRelations[currentRelationindex]

        if (!isEqual(currentRelation[field], value)) {
            // Clone the relation and update the field
            const updatedRelation = { ...currentRelation, [field]: value }

            // Clone the relations  and set the updated relation
            const updatedRelations = structuredClone(definition.relations)
            updatedRelations[currentRelationindex] = updatedRelation

            // Provide the updated definition to the onUpdate callback
            onUpdate({
                ...definition,
                relations: updatedRelations,
            })
            setLocalRelations(updatedRelations)
        }
    }

    // dragging methods
    const findColumnCloneIndexById = useCallback(
        (id: string) => {
            return localRelations.findIndex((x) => x.name === id)
        },
        [localRelations]
    )

    // Update columns list clone with the new positions.
    const onColumnMove = useCallback(
        (columnId: string, newIndex: number) => {
            const targetIndex = findColumnCloneIndexById(columnId)

            const targetColumn = localRelations[targetIndex]

            if (targetColumn !== undefined) {
                const updatedColumns = [...localRelations]
                updatedColumns.splice(targetIndex, 1)
                updatedColumns.splice(newIndex, 0, targetColumn)

                setLocalRelations(updatedColumns)
            }
        },
        [findColumnCloneIndexById, localRelations]
    )
    const onMoveEnd = () => {
        onUpdate({
            ...definition,
            relations: localRelations,
        })
    }

    return (
        <Stack gap={2}>
            {/* Ties inclusion mode
                ========================================= */}
            <FormControl>
                <FormLabel>
                    Nodes to Include
                    <Tooltip
                        title={
                            <>
                                <Typography variant="body1" component="span">
                                    <strong>Choose which nodes to display:</strong>
                                </Typography>
                                <br />
                                <Typography variant="body2" component="span">
                                    - <strong>Outgoing Nodes:</strong> Displays nodes that are reached from the selected
                                    node.
                                </Typography>
                                <br />
                                <Typography variant="body2" component="span">
                                    - <strong>Incoming Nodes:</strong> Displays nodes that connect to the selected node.
                                </Typography>
                                <br />
                                <Typography variant="body2" component="span">
                                    - <strong>Reciprocated :</strong> Displays nodes with reciprocated relationships to
                                    the selected node.
                                </Typography>
                                <br />
                                <Typography variant="body2" component="span">
                                    - <strong>All:</strong> Displays all nodes connected to the selected node,
                                    regardless of direction.
                                </Typography>
                            </>
                        }
                    >
                        <QuestionIcon
                            color="info"
                            fontSize="small"
                            style={{ marginLeft: '8px', verticalAlign: 'middle', cursor: 'pointer' }}
                        />
                    </Tooltip>
                </FormLabel>
                <RadioGroup
                    row
                    value={definition.edgeInclusivity}
                    onChange={(e, v) =>
                        updateDefinitionField('edgeInclusivity', v as TableDefinitionModeTies['edgeInclusivity'])
                    }
                >
                    <FormControlLabel value="source" control={<Radio />} label="Outgoing Nodes" />

                    <FormControlLabel value="target" control={<Radio />} label="Incoming Nodes" />

                    <FormControlLabel value="reciprocated" control={<Radio />} label="Reciprocated" />

                    <FormControlLabel value="all" control={<Radio />} label="All Nodes" />
                </RadioGroup>
            </FormControl>
            <Stack direction="row" gap={2} alignItems="center">
                {/* exclude empty nodes
                ========================================= */}
                <FormGroup>
                    <FormControlLabel
                        label="Exclude Empty Nodes"
                        control={
                            <Switch
                                checked={definition.excludeEmptyNodes}
                                onChange={(e, v) => updateDefinitionField('excludeEmptyNodes', v)}
                            />
                        }
                        disabled={disabled}
                    />
                    <FormHelperText>Hide nodes that do not have any relationships to other nodes.</FormHelperText>
                </FormGroup>

                {/* Bidirectional Filtering
                ========================================= */}
                <FormGroup>
                    <FormControlLabel
                        label="Bidirectional Filtering"
                        control={
                            <Switch
                                checked={definition.bidirectionalFiltering}
                                onChange={(e, v) => updateDefinitionField('bidirectionalFiltering', v)}
                            />
                        }
                        disabled={disabled}
                    />
                    <FormHelperText>
                        Toggle on to apply filters to both source and target nodes in relationships.
                    </FormHelperText>
                </FormGroup>
            </Stack>

            {/* exclude empty nodes
                ========================================= */}
            <FormGroup>
                <FormControlLabel
                    label="Hide Network Titles"
                    control={
                        <Switch
                            checked={definition.hideHeaders}
                            onChange={(e, v) => updateDefinitionField('hideHeaders', v)}
                        />
                    }
                    disabled={disabled}
                />
                <FormHelperText>Enable this option to hide network titles in the card view.</FormHelperText>
            </FormGroup>

            {/* Node label field
                ========================================= */}
            <FlexibleSelect
                label="Label Field"
                value={definition.nodesLabelField}
                options={fieldOptions}
                onChange={(value) => updateDefinitionField('nodesLabelField', value)}
                disabled={disabled}
                hasClearButton
                clearedReturnValue={null}
            />

            {/* 
            // ToDo @Pourya - When I drag row at index i, in the preview, row at index i and i+1 are displaying 
            */}
            <Stack ref={dropTarget} gap={1.5}>
                {localRelations.map((relationship) => (
                    <React.Fragment key={relationship.name}>
                        <TableTiesRelationshipAccordionItem
                            name={relationship.name}
                            onMove={onColumnMove}
                            onMoveEnd={onMoveEnd}
                            getPositionIndex={findColumnCloneIndexById}
                            relationship={relationship}
                            updateRelationshipField={updateRelationshipField}
                        />

                        {/* Divider
                                ========================================= */}
                        <Divider />
                    </React.Fragment>
                ))}
            </Stack>
        </Stack>
    )
}

export default TableDefinitionTiesSettings
