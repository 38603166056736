import React from 'react'
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

interface Shortcut {
    action: string
    keys: string
}

const basicShortcuts: Shortcut[] = [
    { action: 'Paste Copied Widget', keys: 'Ctrl + V' },
    { action: 'Show All Widgets', keys: 'Ctrl + Shift + H' },
]

const widgetActions: Shortcut[] = [
    { action: 'Move Widget Forward', keys: 'Ctrl + ]' },
    { action: 'Move Widget Backward', keys: 'Ctrl + [' },
    { action: 'Move Widget to Front', keys: 'Alt + Ctrl + ]' },
    { action: 'Move Widget to Back', keys: 'Alt + Ctrl + [' },
    { action: 'Copy Active Widget', keys: 'Ctrl + C' },
    { action: 'Focus on Active Widget', keys: 'Ctrl + Enter' },
    { action: 'Hide Active Widget', keys: 'Ctrl + H' },
    { action: 'Hide Other Widgets', keys: 'Ctrl + Alt + H' },
    { action: 'Delete Active Widget', keys: 'Delete' },
    { action: 'Lock/Unlock Active Widget', keys: 'Ctrl + L' },
    { action: 'Select Next Widget', keys: 'Tab' },
    { action: 'Select Previous Widget', keys: 'Shift + Tab' },
    { action: 'Deselect Active Widget', keys: 'Escape' },
    { action: 'Increase Widget Height', keys: 'Ctrl + ArrowUp' },
    { action: 'Decrease Widget Height', keys: 'Ctrl + ArrowDown' },
    { action: 'Decrease Widget Width', keys: 'Ctrl + ArrowLeft' },
    { action: 'Increase Widget Width', keys: 'Ctrl + ArrowRight' },
    { action: 'Move Widget Up', keys: 'ArrowUp' },
    { action: 'Move Widget Down', keys: 'ArrowDown' },
    { action: 'Move Widget Left', keys: 'ArrowLeft' },
    { action: 'Move Widget Right', keys: 'ArrowRight' },
]

const KeyboardShortcutsGuide: React.FC = () => {
    const renderShortcutTable = (shortcuts: Shortcut[]) => (
        <TableContainer component={Paper}>
            <Table aria-label="shortcut table">
                <TableHead>
                    <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell align="right">Keys</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shortcuts.map((shortcut, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {shortcut.action}
                            </TableCell>
                            <TableCell align="right">{shortcut.keys}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
            <Typography variant="h6" gutterBottom>
                Basic Shortcuts
            </Typography>
            {renderShortcutTable(basicShortcuts)}
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Widget Actions
            </Typography>
            {renderShortcutTable(widgetActions)}
        </Box>
    )
}

export default KeyboardShortcutsGuide
