import { InterventionAPIs as Apis, parseUrl } from './Apis'
import { BaseResponseType, request } from './request'

export type InterventionType = {
    primaryKey: number
    title: string
    description: string
    provider?: string
    deliveryMode?: string
    type?: string
    targetAudience?: string
    link?: string
    image?: string
    cost?: string
    tier?: string
    intendedOutcomes?: string[]
}

export type GetInterventionsResponseType = {
    success: boolean
    data: Array<InterventionType>
}

export const GetInterventionsService: (showMessage?: boolean) => Promise<GetInterventionsResponseType> = async (
    showMessage = false
) => {
    return request(Apis.GetAll, 'GET', undefined, undefined, showMessage)
}

export type GetInterventionResponseType = {
    success: boolean
    data: InterventionType
}

export const GetInterventionService: (
    iid: string | number,
    showMessage?: boolean
) => Promise<GetInterventionResponseType> = async (iid, showMessage = false) => {
    return request(parseUrl(Apis.Get, { iid }), 'GET', undefined, undefined, showMessage)
}

export type CreateInterventionResponseType = {
    success: boolean
    data: InterventionType
}

export const CreateInterventionService: (
    intervention: InterventionType,
    showMessage?: boolean
) => Promise<CreateInterventionResponseType> = async (intervention, showMessage = false) => {
    return request(Apis.Create, 'POST', { 'Content-Type': 'application/json' }, intervention, showMessage)
}

export const UpdateInterventionService: (
    iid: string | number,
    intervention: InterventionType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (iid, intervention, showMessage = false) => {
    return request(
        parseUrl(Apis.Update, { iid }),
        'PUT',
        { 'Content-Type': 'application/json' },
        intervention,
        showMessage
    )
}

export const DeleteInterventionService: (
    iid: string | number,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (iid, showMessage = false) => {
    return request(parseUrl(Apis.Delete, { iid }), 'DELETE', undefined, undefined, showMessage)
}
