import { AIApis as Apis } from './Apis'
import { request } from './request'

export type InvokeAIServiceRequestType = {
    prompts: {
        content: string
        role: 'user' | 'assistant' | 'system'
    }[]
}

type InvokeAIServiceResponseType = {
    success: boolean
    data: any
}

export const InvokeAIService: (
    prompts: InvokeAIServiceRequestType,
    showMessage?: boolean
) => Promise<InvokeAIServiceResponseType> = async (prompts, showMessage = false) => {
    return request(Apis.InvokeOpenAI, 'POST', undefined, prompts, showMessage)
}

type TextToSpeechResponseType = {
    success: boolean
    data: string
}

export const TextToSpeechService: (text: string, showMessage?: boolean) => Promise<TextToSpeechResponseType> = async (
    text,
    showMessage = false
) => {
    return request(Apis.tts, 'POST', undefined, { text }, showMessage)
}
