import { useEffect, useMemo, useState } from 'react'
import { NetworkVizContextType, useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import StyledDialog from 'components/dialog/StyledDialog'
import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseButton from 'components/base/BaseButton'
import { NodeAttributeType } from '../types/NetworkViz.types'

type AssortativitySettingsProps = {
    open: boolean
    onClose: () => void
}

const AssortativityDialog = ({ open, onClose }: AssortativitySettingsProps) => {
    const { analyticsSettings, nodeDataSchema } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [localData, setLocalData] = useState<NetworkVizContextType['analyticsSettings']>(analyticsSettings)

    useEffect(() => {
        if (open) {
            setLocalData({ ...analyticsSettings })
        }
    }, [open])

    const saveSettings = () => {
        dispatchContext({
            type: 'ANALYTICS_SETTINGS_EDIT',
            payload: localData,
        })
        onClose()
    }

    const fieldOptions = useMemo(() => {
        const options = Object.keys(nodeDataSchema.fields).map((key) => ({
            label: key,
            value: {
                field: key,
                source: 'info',
            } as NodeAttributeType,
        }))

        return options
    }, [nodeDataSchema])

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Compute assortativity for node attributes.</DialogTitle>
            <DialogContent sx={{ minHeight: '10vh' }}>
                <Typography>
                    Assortativity measures the similarity of connections in the graph with respect to the given
                    attribute.
                </Typography>
                <Typography>Select the attributes you want to </Typography>

                <Stack direction="row" gap={3} spacing={2} mt={3}>
                    <BaseSelectWithLabel
                        value={localData.assortativity}
                        onChange={(value) => setLocalData({ ...localData, assortativity: value })}
                        fullWidth
                        selectProps={{
                            multiple: true,
                        }}
                        label="Assortativity Attributes"
                        options={fieldOptions}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton color="secondary" onClick={onClose}>
                    Close
                </BaseButton>
                <BaseButton variant="contained" color="primary" onClick={saveSettings}>
                    Save
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default AssortativityDialog
