//* ======= Libraries
import { styled } from '@mui/material/styles'
//* ======= Components and features
import Dialog, { DialogProps } from '@mui/material/Dialog'
//* ======= Custom logic
//* ======= Assets and styles

interface IStyledDialog extends DialogProps {}

const MuiStyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        overflow: 'hidden',

        borderRadius: '10px',
        backgroundColor: theme.palette.common.bg_1,
        backgroundImage: 'unset',
    },
}))

/**
 * TODO:
 *  1.  Overflowing content are clipped due to the styles we defined.
 *      Maybe we can allow overflowing in here or the using components. Fix later.
 */

function StyledDialog({ open, children, ...dialogProps }: IStyledDialog) {
    return (
        <MuiStyledDialog open={open} maxWidth="lg" {...dialogProps}>
            {children}
        </MuiStyledDialog>
    )
}

export default StyledDialog
