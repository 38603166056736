import Stack from '@mui/material/Stack'
import BaseButton from 'components/base/BaseButton'
import { ReactComponent as ICON_VIEW_2D } from 'assets/icons/view-2d.svg'
import { ReactComponent as ICON_VIEW_2D_PLUS } from 'assets/icons/view-2d-plus.svg'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { Tooltip } from '@mui/material'
import { useState } from 'react'
import { useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import { NetworkVizKindType } from '../types/NetworkViz.types'

export interface NetworkKindSelectorProps { }

export default function NetworkKindSelector({ }: NetworkKindSelectorProps) {
    const { networkVizKind } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()

    const updateNetworkKind = (kind: NetworkVizKindType) => {
        contextDispatch({ type: 'NETWORK_VIZ_KIND', payload: kind })
    }

    return (
        <Stack direction="row" alignItems="center" gap={1}>
            {/* Flat (2d)
                ========================================= */}
            <Tooltip title="2D: optimised for large networks">
                <BaseButton
                    onClick={(evt) => {
                        updateNetworkKind('2d')
                    }}
                    disableElevation
                    sx={(theme) => ({
                        height: 48,
                        minWidth: 0,
                        width: 48,

                        borderRadius: 0,
                        // @Theme conditional
                        backgroundColor:
                            networkVizKind === '2d'
                                ? theme.palette.primary.main
                                : theme.palette.mode === 'light'
                                    ? theme.palette.common.bg_3
                                    : theme.palette.common.bg_4,
                        color: networkVizKind === '2d' ? theme.palette.common.fill_4 : theme.palette.primary.main,

                        '&:hover': {
                            // @Theme conditional
                            backgroundColor:
                                networkVizKind === '2d'
                                    ? theme.palette.primary.main
                                    : theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_3
                                        : theme.palette.common.bg_4,
                        },
                    })}
                >
                    <ICON_VIEW_2D
                        style={{
                            height: '32px',
                            width: '32px',
                        }}
                    />
                </BaseButton>
            </Tooltip>
            {/* 2D (echarts)
                ========================================= */}
            <Tooltip title="2D+: optimised for best appearance">
                <BaseButton
                    onClick={(evt) => {
                        updateNetworkKind('2d+')
                    }}
                    disableElevation
                    sx={(theme) => ({
                        height: 48,
                        minWidth: 0,
                        width: 48,

                        borderRadius: 0,
                        // @Theme conditional
                        backgroundColor:
                            networkVizKind === '2d+'
                                ? theme.palette.primary.main
                                : theme.palette.mode === 'light'
                                    ? theme.palette.common.bg_3
                                    : theme.palette.common.bg_4,
                        color: networkVizKind === '2d+' ? theme.palette.common.fill_4 : theme.palette.primary.main,

                        '&:hover': {
                            // @Theme conditional
                            backgroundColor:
                                networkVizKind === '2d+'
                                    ? theme.palette.primary.main
                                    : theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_3
                                        : theme.palette.common.bg_4,
                        },
                    })}
                >
                    <ICON_VIEW_2D_PLUS
                        style={{
                            height: '32px',
                            width: '32px',
                        }}
                    />
                </BaseButton>
            </Tooltip>

            {/* 3D
                ========================================= */}
            <Tooltip title="3D">
                <BaseButton
                    onClick={(evt) => {
                        updateNetworkKind('3d')
                    }}
                    disableElevation
                    sx={(theme) => ({
                        height: 48,
                        minWidth: 0,
                        width: 48,

                        borderRadius: 0,
                        // @Theme conditional
                        backgroundColor:
                            networkVizKind === '3d'
                                ? theme.palette.primary.main
                                : theme.palette.mode === 'light'
                                    ? theme.palette.common.bg_3
                                    : theme.palette.common.bg_4,
                        color: networkVizKind === '3d' ? theme.palette.common.fill_4 : theme.palette.primary.main,

                        '&:hover': {
                            // @Theme conditional
                            backgroundColor:
                                networkVizKind === '3d'
                                    ? theme.palette.primary.main
                                    : theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_3
                                        : theme.palette.common.bg_4,
                        },
                    })}
                >
                    <ViewInArIcon
                        sx={{
                            height: 32,
                            width: 32,
                        }}
                    />
                </BaseButton>
            </Tooltip>
        </Stack>
    )
}
