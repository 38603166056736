//* ======= Libraries
import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import BaseButton from 'components/base/BaseButton'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
//* ======= Custom logic
import { NodeType } from 'services/NodeService'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
//* ======= Assets and styles
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
    // flexDirection: 'row-reverse',
    gap: theme.spacing(2.5),

    padding: theme.spacing(2, 2),

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        // transform: 'rotate(-180deg)',
        color: theme.palette.primary.main,
    },

    '& .MuiAccordionSummary-content': {
        margin: 0,

        color: theme.palette.common.text_1,

        // '&.Mui-expanded': {
        //     color: theme.palette.primary.main,
        // },
    },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),

    minWidth: 0,
    padding: theme.spacing(1, 2, 2, 3),
}))

interface INodeSurveys {
    node: NodeType

    navigationHandlers: {
        toOverview: () => void
        toRelations: () => void
        toActions: () => void
    }
}

function NodeSurveys({ node, navigationHandlers }: INodeSurveys) {
    const navigator = useNavigate()

    return (
        <Grid container height="100%" paddingRight={1}>
            {/* Main content
                ========================================= */}
            <Grid item xs={12} height="100%">
                <Stack gap={1} height="100%">
                    {/* Top row
                        ========================================= */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{
                            py: 1,
                            px: 2,

                            backgroundColor: 'common.bg_1',
                        }}
                    >
                        {/* Panel selection buttons
                            ========================================= */}
                        <Stack direction="row" alignItems="center" gap={3}>
                            {/* Overview button
                                ========================================= */}
                            <BaseButton
                                label="Overview"
                                onClick={() => navigationHandlers.toOverview()}
                                sx={{
                                    width: 120,
                                    fontSize: 20,

                                    color: 'common.fill_2',
                                }}
                            />

                            {/* Surveys button
                                ========================================= */}
                            <BaseButton
                                label="Surveys"
                                sx={{
                                    width: 120,
                                    fontSize: 20,
                                }}
                            />

                            {/* Relations button
                                ========================================= */}
                            <BaseButton
                                label="Relations"
                                onClick={() => navigationHandlers.toRelations()}
                                sx={{
                                    width: 120,
                                    fontSize: 20,

                                    color: 'common.fill_2',
                                }}
                            />
                            {/* Actions button
                                ========================================= */}
                            <BaseButton
                                label="Actions"
                                onClick={() => navigationHandlers.toActions()}
                                sx={{
                                    width: 120,
                                    fontSize: 20,

                                    color: 'common.fill_2',
                                }}
                            />
                        </Stack>

                        {/* Search
					        ========================================= */}
                        {/* <SearchField
                            options={['Something']}
                            placeholder="Search"
                            sx={{
                                marginLeft: 'auto',
                                marginRight: 3,
                            }}
                        /> */}

                        {/* Filter button
                            ========================================= */}
                        {/* <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <FilterListIcon />
                        </IconButton> */}

                        {/* ??? button
                            ========================================= */}
                        {/* <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <ViewColumnIcon />
                        </IconButton> */}

                        {/* Download button
                            ========================================= */}
                        {/* <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <DownloadIcon />
                        </IconButton> */}
                    </Stack>

                    {/* Content
                        ========================================= */}
                    <Stack
                        flexGrow={1}
                        gap={2}
                        sx={{
                            paddingBottom: 2,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {/* Stats and data grid
                            ========================================= */}
                        <Stack
                            flexGrow={1}
                            gap={3}
                            sx={{
                                padding: 3,
                                paddingBottom: 4,

                                backgroundColor: 'common.bg_1',
                            }}
                        >
                            {/* Stats
                                ========================================= */}
                            {/* <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                gap={1}
                            >

                                <Box
                                    flexGrow={1}
                                    sx={{
                                        alignSelf: 'stretch',

                                        minHeight: 244,
                                        height: '100%',
                                    }}
                                >
                                    (CIRCULAR PROGRESS)
                                </Box>

                                <Stack flexShrink={0} gap={1}>
                                    
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                            color="common.text_3"
                                            width={100}
                                            noWrap
                                        >
                                            Active:
                                        </Typography>

                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                            textAlign="right"
                                            color="secondary"
                                            width="3ch"
                                        >
                                            555
                                        </Typography>
                                    </Stack>

                                   
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                            color="common.text_3"
                                            width={100}
                                            noWrap
                                        >
                                            Not Started:
                                        </Typography>

                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                            textAlign="right"
                                            color="secondary"
                                            width="3ch"
                                        >
                                            555
                                        </Typography>
                                    </Stack>

                                 
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                            color="common.text_3"
                                            width={100}
                                            noWrap
                                        >
                                            Completed:
                                        </Typography>

                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                            textAlign="right"
                                            color="secondary"
                                            width="3ch"
                                        >
                                            4
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack> */}

                            {/* Data grid
                                ========================================= */}
                            <Box sx={{ flexGrow: 1 }}>
                                <StyledDataGrid
                                    rows={node?.surveyInstances || []}
                                    columns={[
                                        {
                                            field: 'respondent',
                                            headerName: 'Respondent',
                                            renderCell: (params) => {
                                                return (
                                                    <Tooltip
                                                        placement="bottom-start"
                                                        title={
                                                            <Paper sx={{ padding: 2 }}>
                                                                <Typography>{`ID: ${params?.value?.primaryKey}`}</Typography>
                                                                <Typography>{`Name: ${
                                                                    params?.value?.name || 'Anonymous User'
                                                                }`}</Typography>
                                                                {params?.value?.email && (
                                                                    <Typography>{`Email: ${params?.value?.email}`}</Typography>
                                                                )}
                                                                {params?.value?.phoneNumber && (
                                                                    <Typography>{`Phone Number: ${params?.value?.phoneNumber}`}</Typography>
                                                                )}
                                                            </Paper>
                                                        }
                                                    >
                                                        <Avatar src={params?.value?.avatar}>
                                                            {buildAbbreviation(params?.value?.name)}
                                                        </Avatar>
                                                    </Tooltip>
                                                )
                                            },
                                        },
                                        {
                                            field: 'surveyInstanceId',
                                            headerName: 'Instance ID',
                                        },
                                        {
                                            field: 'surveyName',
                                            headerName: 'Survey Title',
                                        },
                                        {
                                            field: 'round',
                                            headerName: 'Round',
                                        },
                                        {
                                            field: 'status',
                                            headerName: 'Status',
                                        },
                                        {
                                            field: 'timeSpent',
                                            headerName: 'Time Spent',
                                            valueFormatter: (v) => {
                                                return v.value / 60 > 0 ? `${v.value / 60} mins` : `${v.value} secs`
                                            },
                                        },
                                        {
                                            field: 'startDate',
                                            headerName: 'Start Date',
                                            valueFormatter: (v) => moment(v.value).format('DD.MM.YYYY'),
                                        },
                                        {
                                            field: 'endDate',
                                            headerName: 'End Date',
                                            valueFormatter: (v) => moment(v.value).format('DD.MM.YYYY'),
                                        },
                                        {
                                            field: 'passCode',
                                            headerName: 'Passcode',
                                        },
                                        {
                                            field: 'accessToken',
                                            headerName: 'Actions',
                                            sortable: false,
                                            renderCell: (params) => (
                                                <Stack gap={1} direction="row">
                                                    <BaseButton
                                                        onClick={() => navigator(`/survey/si/${params.value}?view`)}
                                                    >
                                                        View
                                                    </BaseButton>
                                                    {params.row.status == 'not_started' ? (
                                                        <BaseButton
                                                            onClick={() => navigator(`/survey/si/${params.value}`)}
                                                        >
                                                            Activate
                                                        </BaseButton>
                                                    ) : (
                                                        <BaseButton
                                                            onClick={() => navigator(`/survey/si/${params.value}`)}
                                                        >
                                                            Open
                                                        </BaseButton>
                                                    )}
                                                </Stack>
                                            ),
                                        },
                                    ]}
                                    getRowId={(row) => row.surveyInstanceId}
                                />
                            </Box>
                        </Stack>

                        {/* Footer buttons
                            ========================================= */}
                        {false && (
                            <Stack
                                flexShrink={0}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                gap={3}
                                sx={{
                                    marginTop: 'auto',
                                }}
                            >
                                {/* Reminder button
                                ========================================= */}
                                <BaseButton
                                    label="Reminder"
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        width: 180,
                                    }}
                                />

                                {/* Contact button
                                ========================================= */}
                                <BaseButton
                                    label="Contact"
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        width: 180,
                                    }}
                                />

                                {/* Withdraw button
                                ========================================= */}
                                <BaseButton
                                    label="Withdraw"
                                    variant="contained"
                                    size="large"
                                    color="warning"
                                    sx={{
                                        width: 180,
                                    }}
                                />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Grid>

            {/* Sidebar
                ========================================= */}
            {/* <Grid item xs={2} height="100%" paddingLeft={1}>
                <Stack
                    sx={{
                        height: '100%',
                        py: 2,
                        overflowY: 'auto',

                        backgroundColor: 'common.bg_1',
                    }}
                    className="u-scrollbar"
                >
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        color="common.text_1"
                        sx={{
                            mx: 2,
                        }}
                    >
                        Respondents
                    </Typography>


                </Stack>
            </Grid> */}
        </Grid>
    )
}

export default NodeSurveys
