import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { GraphLegendType } from 'features/network-viz/types/NetworkViz.types'

export interface LegendsContentProps {
    legendContent: GraphLegendType
    onClose?: () => void
}

export default function NetworkLegendsContent({ legendContent, onClose }: LegendsContentProps) {
    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                width: 300,
                padding: theme.spacing(1.5, 2),
            })}
        >
            {/* Title and close button
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={600} color="primary">
                    Legend
                </Typography>

                <IconButton onClick={onClose} size="small">
                    <CloseRoundedIcon fontSize="small" />
                </IconButton>
            </Stack>

            <Stack
                gap={1}
                className="u-scrollbar"
                sx={{
                    maxHeight: 400,
                    overflowY: 'auto',
                }}
            >
                {/* Items
                ========================================= */}
                {legendContent.nodes.map((node, idx) => (
                    <Stack
                        key={node.title}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography fontSize={14} fontWeight={500}>
                            {node.title}: ({node.count})
                        </Typography>

                        <Box
                            component="span"
                            sx={(theme) => ({
                                height: 16,
                                width: 16,

                                borderRadius: 1,
                                backgroundColor: node.color || theme.palette.common.bg_1,
                            })}
                        />
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}
