import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Backdrop from '@mui/material/Backdrop'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import Network2dPlusRenderer from './components/Network2dPlusRenderer'
import Network2dRenderer from './components/netwrok-2d/Network2dRenderer'
import Network3dRenderer from './components/Network3dRenderer'
import {
    EdgeGroupStyleType,
    EdgeRenderType,
    NetworkSelectedItemType,
    NodeAdjacentListType,
    NodeGroupStyleType,
    NodeIdType,
    NodeRenderType,
} from '../types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import highlightNodes, { hexToRgbA } from './components/highlightSelectedNodes'
import usePreviousValue from 'hooks/usePreviousValue'
import { mean } from 'mathjs'
import { isSelectedItemEquals } from '../helpers/NetworkViz.helper'
import { Typography } from '@mui/material'

let longLoadingTimeout = null

export default function NetworkRenderer({ preview, redraw }: { preview?: boolean; redraw?: number }) {
    const containerRef = useRef<HTMLDivElement>(null)
    const echartsRef = useRef<HTMLDivElement>(null)

    const {
        status,
        networkVizKind,
        selectedItem,
        action,
        nodeRenders,
        edgeRenders,
        edgeArrowType,
        nodeAdjacentList,
        nodeStyle,
        edgeStyle,
        viewMode,
        nodeInteractionConfig,
        nodeGroupInfo,
        level,
        onlyShowReciprocatedEdges,
        highlightReciprocatedEdges,
        dimOneWayEdges,
    } = useNetworkVizContext()

    const lastSelectedItem = usePreviousValue(selectedItem)
    const lastStatus = usePreviousValue(status)

    const dispatchContext = useNetworkVizDispatch()

    const [nodes, setNodes] = useState<NodeRenderType[]>([])
    const [edges, setEdges] = useState<EdgeRenderType[]>([])
    const [hoverNode, setHoverNode] = useState<NodeIdType | null>(null)

    useEffect(() => {
        const tmpNodes: NodeRenderType[] = []
        const clonedNodes = structuredClone(nodeRenders) as NodeRenderType[]

        for (let node of clonedNodes) {
            if (node.hide || node.tmpHide) continue
            node = {
                ...node,
                x: node.tmpX ?? node.x,
                y: node.tmpY ?? node.y,
                attributes: {
                    ...node.attributes,
                    x: node.attributes.x,
                    y: node.attributes.y,
                },
            }
            if (node.highlighted !== undefined) {
                //get the node style
                let style = (node.groupKey ? nodeStyle[node.groupKey] : nodeStyle.default) || nodeStyle.default
                const selected = style.selected
                tmpNodes.push(
                    setNodeHighlightedStyle(
                        { ...node },
                        selected,
                        !nodeInteractionConfig.layerColor || node.highlighted === 0
                            ? null
                            : nodeInteractionConfig.colors?.[node.highlighted - 1]?.hex ?? null,
                        node.highlighted === 0
                    )
                )
            } else if (node.dim) {
                tmpNodes.push({
                    ...node,
                    itemStyle: {
                        ...node.itemStyle,
                        color: '#96969624',
                        borderWidth: 0,
                    },
                    label: { show: false },
                    attributes: {
                        ...node.attributes,
                        type: 'circle',
                        label: undefined,
                        color: 'rgba(150, 150, 150, .7)',
                    },
                })
            } else if (node.filterHighlight === true) {
                let style = node.groupKey ? nodeStyle[node.groupKey] : nodeStyle.default
                const selected = style.selected
                tmpNodes.push(setNodeHighlightedStyle({ ...node }, selected, null))
            } else {
                tmpNodes.push({ ...node })
            }
        }
        setNodes(tmpNodes)
    }, [nodeRenders])

    const getEdgeKey = (edge: EdgeRenderType) => {
        const { source, target, groupKey } = edge
        if (source < target) {
            return `${source}-${target}-${groupKey}`
        }
        return `${target}-${source}-${groupKey}`
    }

    useEffect(() => {
        const combinedEdges: EdgeRenderType[] = []
        const edgeMap: { [key: string]: EdgeRenderType[] } = {}

        for (let edge of edgeRenders) {
            if (edge.hide || edge.tmpHide) continue

            const key = getEdgeKey(edge)

            if (!edgeMap[key]) {
                edgeMap[key] = []
            }

            edgeMap[key].push(edge)
        }

        for (let groupKey in edgeMap) {
            const edges = edgeMap[groupKey]

            // Logic to combine two-way edges for each groupKey
            if (edges.length === 2) {
                const combinedEdge = structuredClone(edges[0]) as EdgeRenderType
                combinedEdge.dim = edges[0].dim && edges[1].dim
                combinedEdge.hide = edges[0].hide && edges[1].hide
                combinedEdge.highlighted = edges[0].highlighted === 'in' || edges[1].highlighted === 'in' ? 'in' : false
                combinedEdge.symbol = ['arrow', 'arrow']
                combinedEdge.symbolSize = edgeArrowType.size

                if (highlightReciprocatedEdges) {
                    combinedEdge.lineStyle.width *= 1.5
                }

                combinedEdges.push(combinedEdge)
            } else {
                if (onlyShowReciprocatedEdges !== true) {
                    combinedEdges.push({
                        ...edges[0],
                        symbol: ['none', 'arrow'],
                        symbolSize: edgeArrowType.size,
                        dim: edges[0].dim || dimOneWayEdges,
                    }) // Push the original edges if they aren't combined
                }
            }
        }
        const tmpEdges: EdgeRenderType[] = []

        for (let edge of combinedEdges) {
            if (edge.hide) continue
            if (edgeStyle[edge.groupKey!].visible === false) continue
            if (edge.highlighted === 'in' || (hoverNode && nodeAdjacentList[hoverNode]?.edges?.in?.includes(edge.id))) {
                let style = edge.groupKey ? edgeStyle[edge.groupKey] : edgeStyle.default
                const selected = style.selectedIn
                tmpEdges.push(setEdgeHighlightedStyle({ ...edge }, selected))
            } else if (
                edge.highlighted === 'out' ||
                (hoverNode && nodeAdjacentList[hoverNode]?.edges?.out?.includes(edge.id))
            ) {
                let style = edge.groupKey ? edgeStyle[edge.groupKey] : edgeStyle.default
                const selected = style.selectedOut
                tmpEdges.push(setEdgeHighlightedStyle({ ...edge }, selected))
            } else if (edge.dim) {
                tmpEdges.push({
                    ...edge,
                    lineStyle: {
                        ...edge.lineStyle,
                        color: '#96969624',
                    },
                    attributes: {
                        ...edge.attributes,
                        color: 'rgba(150, 150, 150, .7)',
                    },
                })
            } else {
                tmpEdges.push({ ...edge })
            }
        }
        setEdges(tmpEdges)
    }, [edgeRenders, hoverNode, level, onlyShowReciprocatedEdges, highlightReciprocatedEdges, dimOneWayEdges])

    const onSelectedNodeChange = (selectedItem: NetworkSelectedItemType) => {
        dispatchContext({ type: 'SELECTED_ITEM_CHANGE', payload: selectedItem })
    }

    const onNodeHover = (id: NodeIdType | null) => {
        setHoverNode(id)
    }

    useEffect(() => {
        if (status !== 'ready') return
        // to prevent loop in the effect, we check if the selected item is the same as the last selected item
        // also, there is an edge case when report viz update the filters and selectedItem at the same time.
        // in this case, the nodeRenders are updated by this effect, and also an effect in the NetworkVizReportView component where the later overrides the former.
        // the filter will update the status, therefore here is a check to allow re-run the effect when the status is changed.
        if (isSelectedItemEquals(lastSelectedItem || null, selectedItem) && lastStatus === status) return
        if (nodeRenders.length < 500) {
            //if number of nodes less than 500, do it in ui thread, otherwise do it in a worker thread
            //ToDo 500 is a made up number for now, find a better way to set the threshold
            const { nodes, edges } = highlightNodes({
                nodeAdjacentList,
                nodes: structuredClone(nodeRenders) as NodeRenderType[],
                nodeStyle,
                edgeStyle,
                selectedNodes: selectedItem?.mode === 'node' || selectedItem?.mode === 'group' ? [selectedItem.id] : [],
                edges: structuredClone(edgeRenders) as EdgeRenderType[],
                nodeInteractionConfig,
            })
            dispatchContext({
                type: 'NODE_SELECT_HIGHLIGHT_CHANGE',
                payload: { nodeRenders: nodes, edgeRenders: edges },
            })
            if (nodeInteractionConfig.dimOtherNodesOnSelect && nodeInteractionConfig.hideNonAdjacentNodesOnSelect)
                dispatchContext({
                    type: 'ACTION',
                    payload: {
                        type: 'fit',
                    },
                })
        } else {
            const worker = new WebWorker('workers/network/apply-highlight-nodes.js')

            worker
                .run({
                    nodeAdjacentList,
                    nodes: nodeRenders,
                    nodeStyle,
                    edgeStyle,
                    selectedNodes:
                        selectedItem?.mode === 'node' || selectedItem?.mode === 'group' ? [selectedItem.id] : [],
                    edges: edgeRenders,
                    nodeInteractionConfig,
                })
                .then((res: any) => {
                    const { nodes, edges } = res

                    dispatchContext({
                        type: 'NODE_SELECT_HIGHLIGHT_CHANGE',
                        payload: { nodeRenders: nodes, edgeRenders: edges },
                    })
                })
        }
    }, [selectedItem, nodeAdjacentList, nodeInteractionConfig, status])

    const updateNodeLocation = (id: string, x: number, y: number) => {
        dispatchContext({
            type: 'NODE_POSITION_UPDATE',
            payload: {
                id,
                x,
                y,
            },
        })
    }

    return (
        <Stack
            ref={containerRef}
            alignItems="center"
            // This ID is important and is used inside 'Graph3D' component to draw the canvas.
            id="graph-show-wrapper"
            sx={(theme) => ({
                height: '100%',
                minWidth: 0,
                width: '100%',
            })}
        >
            {/* 
                // TODO:
                Why are these two boxes separated?
                They have the same style and can contain both type of charts instead of 'display:none'-ing !
                Also, at the moment the 'Loading' texts will create an overflow. if we move all the graph conditions
                inside one container, this issue will also be fixed.
            */}

            {/* Chart containers
                ========================================= */}
            <Box
                ref={echartsRef}
                sx={(theme) => ({
                    display: ['2d+'].includes(networkVizKind) ? 'block' : 'none',

                    alignSelf: 'stretch',

                    height: '100%',
                    // width: '100%',
                })}
            />

            {/* Charts
                ========================================= */}
            {(viewMode === 'design' && status === 'pending') || status === 'loading' ? (
                <Backdrop open={true} sx={{ position: 'absolute', zIndex: 999 }}>
                    <SnaCircularLoading />
                </Backdrop>
            ) : nodes.length === 0 ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'text.secondary',
                    }}
                >
                    <Typography variant="h6">No data</Typography>
                </Box>
            ) : networkVizKind === '2d+' ? (
                <Network2dPlusRenderer
                    onNodeHover={onNodeHover}
                    action={action}
                    nodeGroupInfo={nodeGroupInfo}
                    selectedNode={selectedItem?.mode === 'node' ? selectedItem.id : null}
                    redraw={redraw}
                    nodes={nodes}
                    edges={edges}
                    chartRef={echartsRef}
                    edgeSymbol={edgeArrowType}
                    setSelectedItem={onSelectedNodeChange}
                />
            ) : networkVizKind === '2d' ? (
                <Network2dRenderer
                    onNodeHover={onNodeHover}
                    action={action}
                    updateNodeLocation={updateNodeLocation}
                    selectedNode={selectedItem?.mode === 'node' ? selectedItem.id : null}
                    nodes={nodes}
                    edges={edges}
                    setSelectedItem={onSelectedNodeChange}
                />
            ) : // <div>Sigma</div>
            networkVizKind === '3d' ? (
                <Network3dRenderer
                    onNodeHover={onNodeHover}
                    action={action}
                    selectedNode={selectedItem?.mode === 'node' ? selectedItem.id : null}
                    nodes={nodes}
                    edges={edges}
                    setSelectedItem={onSelectedNodeChange}
                />
            ) : (
                <></>
            )}
        </Stack>
    )
}

function setNodeHighlightedStyle(
    node: NodeRenderType,
    style: NodeGroupStyleType['selected'],
    color: string | null,
    scale?: boolean
) {
    //if color not provided, use the style color
    if (color === null) {
        color = style.color
    }
    let nodeSizeFactor = style.sizeScale || 1.5

    if (scale) node.symbolSize *= nodeSizeFactor

    //set the echarts item style
    node.itemStyle = {
        ...node.itemStyle,
        color: color,
        borderColor: style.border.settings?.color,
        borderType: style.border.settings?.style === 'dash' ? 'dashed' : style.border.settings?.style,
        borderWidth: style.border.enabled ? style.border.settings?.width : 0,
        shadowBlur: style.shadow.enabled ? style.shadow.settings?.blur : 0,
        shadowColor: style.shadow.settings?.color,
        shadowOffsetX: style.shadow.enabled ? style.shadow.settings?.x : 0,
        shadowOffsetY: style.shadow.enabled ? style.shadow.settings?.y : 0,
    }

    //set sigma attributes
    node.attributes = {
        ...node.attributes,
        color: hexToRgbA(color),
        border: {
            color: style.border.settings.color,
            show: style.border.enabled || false,
        },
        shadow: style.shadow.enabled ? true : false,
        type: style.border.enabled ? 'border' : 'circle',
        size: node.attributes.size * nodeSizeFactor,
    }

    return node
}

function setEdgeHighlightedStyle(edge: EdgeRenderType, style: EdgeGroupStyleType['selectedIn']) {
    let edgeWithFactor = style.width || 1.5

    //set the echarts item style
    edge.lineStyle = {
        color: style.color,
        type: style.type || 'solid',
        width: edge.lineStyle.width * edgeWithFactor,
    }

    //set sigma attributes
    edge.attributes = {
        color: hexToRgbA(style.color),
        size: edge.lineStyle.width,
    }

    return edge
}
