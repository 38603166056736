import React, { useMemo } from 'react'
import Chip from '@mui/material/Chip'
import Color from 'color'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as ArrowInIcon } from 'assets/icons/arrow-in.svg'
import { ReactComponent as ArrowOutIcon } from 'assets/icons/arrow-out.svg'
import { ReactComponent as ArrowBothIcon } from 'assets/icons/arrow-both.svg'
import { SvgIcon } from '@mui/material'

interface TableNodeChipProps {
    color: string
    name: string
    mode: 'in' | 'out' | 'both'
    selectedNode: string
    tooltip?: string
    fontSize: number
    fontFamily: string
}

const TableNodeChip: React.FC<TableNodeChipProps> = ({
    tooltip,
    color,
    selectedNode,
    name,
    mode,
    fontSize,
    fontFamily,
}) => {
    const fontColor = useMemo(() => {
        return Color(color).isDark() ? '#ffffff' : '#000000'
    }, [color])

    const icon = useMemo(() => {
        switch (mode) {
            case 'in':
                return (
                    <Tooltip enterDelay={200} title={`Relationship from ${name} to ${selectedNode}`}>
                        <SvgIcon fontSize="small" component={ArrowInIcon} style={{ color: fontColor }} />
                    </Tooltip>
                )
            case 'out':
                return (
                    <Tooltip enterDelay={200} title={`Relationship from ${selectedNode} to ${name}`}>
                        <SvgIcon fontSize="small" component={ArrowOutIcon} style={{ color: fontColor }} />
                    </Tooltip>
                )
            case 'both':
                return (
                    <Tooltip enterDelay={200} title={`Bidirectional relationship between ${name} and ${selectedNode}`}>
                        <SvgIcon fontSize="small" component={ArrowBothIcon} style={{ color: fontColor }} />
                    </Tooltip>
                )
            default:
                return undefined
        }
    }, [mode, selectedNode, name, fontColor])

    return (
        <Tooltip enterDelay={200} title={tooltip}>
            <Chip
                label={name}
                icon={icon}
                sx={{
                    backgroundColor: color,
                    fontSize: fontSize,
                    fontFamily: fontFamily,
                    color: fontColor,
                    '& .MuiChip-icon': {
                        color: fontColor,
                    },
                }}
            />
        </Tooltip>
    )
}

export default TableNodeChip
