//* ======= Libraries
import React, { useContext, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
//* ======= Components and features
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { RootContext } from 'contexts/RootContext'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
//* ======= Assets and styles
import SNALogo from 'assets/images/sna-toolbox-logo.png'
import TempAvatar from 'assets/images/profile-photo.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Check from '@mui/icons-material/Check'
import BackIcon from '@mui/icons-material/ArrowBack'
import { Divider } from '@mui/material'

interface IMainHeaderProps {}

type BackType = { title?: string; link: string }

/**
 * TODO:
 *  1.  After adding 'react-router' Link components, use their active/selected state
 *      to colorize the appropriate breadcrumb item.
 */

function MainHeader({}: IMainHeaderProps) {
    const { user, path, theme, onLogout, changeTheme } = useContext(RootContext)
    const location = useLocation()
    const navigator = useNavigate()

    const closeMenu = () => {
        setProfileMenu({ isOpen: false, anchorElement: null })
        setThemeMenu({ isOpen: false, anchorElement: null })
    }

    const [profileMenu, setProfileMenu] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    const [themeMenu, setThemeMenu] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    const [notificationsMenu, setNotificationsMenu] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    const back: BackType | null = useMemo(() => {
        let path = location.pathname.split('/')
        if (path.length - 1 >= 0 && path[path.length - 1] === 'designer')
            return {
                link: path.slice(0, path.length - 1).join('/'),
            }
        if (path.length - 2 >= 0 && ['reports', 'widgets', 'nodes', 'allocations'].includes(path[path.length - 2])) {
            return {
                link: path.slice(0, path.length - 1).join('/'),
            }
        }

        return null
    }, [location.pathname])

    return (
        <AppBar position="static" elevation={0} color="inherit">
            <Toolbar
                disableGutters
                sx={(theme) => ({
                    alignItems: 'center',

                    padding: theme.spacing(1, 3),
                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                })}
            >
                {/* (Conditional) Back button
                    ========================================= */}
                {back && (
                    <NavLink
                        to={back?.link}
                        style={{
                            marginRight: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <IconButton>
                            <BackIcon />
                        </IconButton>
                    </NavLink>
                )}

                {/* Logo
                    ========================================= */}
                <Box
                    sx={{
                        // '296px'
                        width: '18.5rem',
                    }}
                >
                    <NavLink to="/" style={{ cursor: 'pointer' }}>
                        <img src={SNALogo} alt="SNA Toolbox" />
                    </NavLink>
                </Box>

                {/* Main content
                    ========================================= */}
                <Box paddingLeft={1}>
                    {/* Breadcrumb
                        ========================================= */}
                    <Breadcrumbs separator=">" maxItems={4} aria-label="breadcrumb">
                        {path.map((p, index) => {
                            return p.link ? (
                                <NavLink key={p.title + index} to={p.link}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: index + 1 == path.length ? 700 : 500,
                                            color: index + 1 == path.length ? 'primary.main' : 'secondary.main',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {p.title}
                                    </Typography>
                                </NavLink>
                            ) : (
                                <Typography
                                    key={p.title + index}
                                    variant="body1"
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: index + 1 === path.length ? 700 : 500,
                                        color: index + 1 === path.length ? 'primary.main' : 'secondary.main',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {p.title}
                                </Typography>
                            )
                        })}
                    </Breadcrumbs>
                </Box>

                {/* Right controls
                    ========================================= */}
                <Stack direction="row" spacing={2} alignItems="center" marginLeft="auto">
                    {/* Premium CTA
                    ToDo
                        ========================================= */}
                    {/* <BaseButton variant="text" size="medium">
                        <Typography variant="body2">
                            Go <strong>Premium</strong>
                        </Typography>
                    </BaseButton> */}

                    {/* Profile menu button
                        ========================================= */}
                    <BaseButton
                        variant="text"
                        size="medium"
                        endIcon={<ExpandMoreIcon />}
                        onClick={(evt) => setProfileMenu({ isOpen: true, anchorElement: evt.currentTarget })}
                        id="profile-menu-button"
                        aria-controls={profileMenu.isOpen ? 'profile-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={profileMenu.isOpen ? 'true' : undefined}
                    >
                        <Avatar alt="temp profile" src={user?.avatar}>
                            {buildAbbreviation(user?.name || '')}
                        </Avatar>
                    </BaseButton>

                    {/* (Out-of-flow) Profile menu
                        ========================================= */}
                    <Menu
                        id="profile-menu"
                        anchorEl={profileMenu.anchorElement}
                        open={profileMenu.isOpen}
                        onClose={closeMenu}
                        MenuListProps={{
                            'aria-labelledby': 'profile-menu-button',
                        }}
                        sx={{
                            '& .MuiMenu-paper': {
                                width: 280,

                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px',

                                '& .MuiMenu-list': {
                                    paddingTop: 1,
                                    paddingBottom: 1,

                                    '& .MuiMenuItem-root': {
                                        py: 1,
                                        paddingLeft: 4,
                                    },
                                },
                            },
                        }}
                    >
                        <MenuItem>
                            <Stack>
                                <Stack direction="row" alignItems="center" spacing={1} sx={{ overflow: 'hidden' }}>
                                    <Avatar
                                        alt="temp profile"
                                        src={user?.avatar}
                                        sx={{ width: 32, height: 32 }} // Decrease width and height for smaller avatar
                                    >
                                        {buildAbbreviation(user?.name || '')}
                                    </Avatar>
                                    <Typography variant="h5" noWrap>
                                        {user?.name}
                                    </Typography>
                                </Stack>
                                <Typography
                                    noWrap
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                    width="100%"
                                    display="block"
                                >
                                    {user?.email}
                                </Typography>
                            </Stack>
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            onClick={(evt) => {
                                closeMenu()
                                navigator('/account')
                            }}
                        >
                            Account Settings
                        </MenuItem>
                        <MenuItem onClick={(evt) => setThemeMenu({ isOpen: true, anchorElement: evt.currentTarget })}>
                            Theme
                        </MenuItem>
                        {/* ToDo */}
                        {/* <MenuItem
                            sx={(theme) => ({
                                borderBottom: `1px solid ${theme.palette.common.border_2}`,
                            })}
                        >
                            <Typography fontSize={14} fontWeight={600} noWrap my={0.5} color="primary">
                                Go <strong>Premium</strong>
                            </Typography>
                        </MenuItem> */}
                        <MenuItem onClick={onLogout}>Logout</MenuItem>
                    </Menu>

                    {/* (Out-of-flow) Theme menu
                        ========================================= */}
                    <Menu
                        id="theme-menu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        sx={{
                            marginLeft: '-3px',
                        }}
                        anchorEl={themeMenu.anchorElement}
                        open={themeMenu.isOpen}
                        onClose={() => setThemeMenu((prevState) => ({ ...prevState, isOpen: false }))}
                        onMouseLeave={() => setThemeMenu((prevState) => ({ ...prevState, isOpen: false }))}
                        MenuListProps={{
                            'aria-labelledby': 'theme-menu-button',
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                changeTheme('light')
                                closeMenu()
                            }}
                        >
                            <ListItemText
                                sx={{
                                    // @Theme conditional
                                    color: theme === 'light' ? 'primary.main' : '',
                                }}
                            >
                                Light
                            </ListItemText>

                            {theme === 'light' && (
                                <ListItemIcon
                                    sx={{
                                        marginLeft: 3,

                                        color: 'primary.main',
                                    }}
                                >
                                    <Check />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                changeTheme('dark')
                                closeMenu()
                            }}
                        >
                            <ListItemText
                                sx={{
                                    color: theme === 'dark' ? 'primary.main' : '',
                                }}
                            >
                                Dark
                            </ListItemText>

                            {theme === 'dark' && (
                                <ListItemIcon
                                    sx={{
                                        marginLeft: 3,

                                        color: 'primary.main',
                                    }}
                                >
                                    <Check />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    </Menu>

                    {/* Notifications menu button
                        ToDo
                        ========================================= */}
                    {/* <IconButton
                        onClick={(evt) =>
                            setNotificationsMenu({ isOpen: true, anchorElement: evt.currentTarget })
                        }
                        id="notifications-menu-button"
                        aria-controls={notificationsMenu.isOpen ? 'notifications-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={notificationsMenu.isOpen ? 'true' : undefined}
                    >
                        <NotificationsIcon color="primary" />
                    </IconButton> */}

                    {/* (Out-of-flow) Notifications menu
                        ========================================= */}
                    <Menu
                        id="notifications-menu"
                        anchorEl={notificationsMenu.anchorElement}
                        open={notificationsMenu.isOpen}
                        onClose={() => setNotificationsMenu((prevState) => ({ ...prevState, isOpen: false }))}
                        MenuListProps={{
                            'aria-labelledby': 'notifications-menu-button',
                        }}
                        sx={{
                            '& .MuiMenu-paper': {
                                width: 340,

                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px',

                                '& .MuiMenu-list': {
                                    paddingTop: 2,
                                    paddingBottom: 1,
                                    px: 2,

                                    '& .MuiMenuItem-root': {
                                        py: 1,
                                        paddingLeft: 4,
                                    },
                                },
                            },
                        }}
                    >
                        {/* Notification items
                            
                            ========================================= */}
                        {Array.from(Array(2)).map((_, idx) => (
                            <Stack
                                key={idx}
                                direction="row"
                                alignItems="flex-start"
                                gap={1}
                                sx={(theme) => ({
                                    marginBottom: 1,
                                    py: 0.5,
                                    px: 0.5,

                                    borderBottom: `1px solid ${theme.palette.common.border_2}`,
                                })}
                            >
                                {/* User avatar
                                    ========================================= */}
                                <Avatar
                                    src={TempAvatar}
                                    alt="user profile"
                                    sx={{
                                        height: 32,
                                        width: 32,
                                    }}
                                />

                                {/* Details
                                    ========================================= */}
                                <Stack gap={0.5}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        {/* Title
                                            ========================================= */}
                                        <Typography fontSize={10} fontWeight={400} color="common.text_1">
                                            SNA Toolbox
                                        </Typography>

                                        {/* Title
                                            ========================================= */}
                                        <Typography fontSize={8} fontWeight={400} color="secondary">
                                            15min ago
                                        </Typography>
                                    </Stack>

                                    {/* Content
                                        ========================================= */}
                                    <Typography fontSize={10} fontWeight={400} color="secondary">
                                        {'Ut sodales, ex sit amet consectetur accumsan, '}

                                        {/* (Conditional) More link
                                            ========================================= */}
                                        <Link
                                            href="#"
                                            underline="none"
                                            sx={{
                                                color: 'primary.main',
                                            }}
                                        >
                                            More...
                                        </Link>
                                    </Typography>
                                </Stack>
                            </Stack>
                        ))}

                        {/* Show more link
                            ========================================= */}
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
                            <Link
                                href="#"
                                underline="none"
                                sx={{
                                    padding: 0.5,
                                    fontSize: 10,
                                    fontWeight: 400,

                                    color: 'primary.main',
                                }}
                            >
                                Show More
                            </Link>
                        </Stack>
                    </Menu>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default MainHeader
