//* ======= Libraries
import React, { useContext, useEffect, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import SurveyRulesItem from 'features/survey-rules/SurveyRulesItem'
//* ======= Custom logic
//* ======= Assets and styles
import ImgThinkingFace from 'assets/images/thinking-face.svg'
import FilterListIcon from '@mui/icons-material/FilterList'
import DownloadIcon from '@mui/icons-material/Download'
import PrintIcon from '@mui/icons-material/Print'
import { SurveyContext } from 'contexts/SurveyContex'
import {
    CreateSurveyRulesService,
    DeleteSurveyRulesService,
    GetSurveyQuestionService,
    GetSurveyRulesService,
    SurveyRuleType,
    UpdateSurveyRulesService,
} from 'services/SurveyService'
import { useParams } from 'react-router-dom'
import { Question } from 'survey-core'
import { GetProjectAccounts, ProjectAccountType } from 'services/ProjectService'

interface ISurveyRules {}

function SurveyRules({}: ISurveyRules) {
    const { sid, pid } = useParams()
    const { drawerContent } = useContext(SurveyContext)
    const [rules, setRules] = useState<SurveyRuleType[]>([])
    const [questions, setQuestions] = useState<{ Questions: Question[] }>()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [ruleType, setRuleType] = useState<'individual' | 'aggregation'>('individual')
    const [accounts, setAccount] = useState<ProjectAccountType[]>([])

    //fetch data
    useEffect(() => {
        if (sid && pid) {
            GetSurveyRulesService(pid, sid).then((res) => res.success && setRules(res.data))
            GetSurveyQuestionService(pid, sid).then((res) => res.success && setQuestions(res.data))
            GetProjectAccounts(pid).then((res) => res.success && setAccount(res.data))
        }
    }, [])

    //add a new rule
    const addRule = () => {
        setRules((r) => [
            ...r,
            {
                actionDTOs: [],
                condition: [],
                type: ruleType,
                primaryKey: null,
                surveyID: null,
            },
        ])
        setIsDialogOpen(false)
    }

    const updateRule = (index: number) => (value: SurveyRuleType) => {
        if (!sid || !pid) return
        if (value.primaryKey) {
            UpdateSurveyRulesService(pid, sid, value.primaryKey, value).then(
                (res) => res.success && setRules((r) => [...r.slice(0, index), value, ...r.slice(index + 1)])
            )
        } else {
            CreateSurveyRulesService(pid, sid, value).then(
                (res) => res.success && setRules((r) => [...r.slice(0, index), value, ...r.slice(index + 1)])
            )
        }
    }

    const deleteRule = (index: number) => () => {
        const rid = rules[index].primaryKey
        // if the rule is not saved yet, just remove it from the list
        if (rid === null) {
            setRules((r) => [...r.slice(0, index), ...r.slice(index + 1)])
            return
        }
        if (!sid || !pid || !rid) return
        DeleteSurveyRulesService(pid, sid, rid, false).then(
            (res) => res.success && setRules((r) => [...r.slice(0, index), ...r.slice(index + 1)])
        )
    }

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack
                    sx={(theme) => ({
                        height: '100%',
                        paddingBottom: 1,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* Header title and controls
						========================================= */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            py: 1,
                            paddingLeft: 3,
                            paddingRight: 2,

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Title
							========================================= */}
                        <Typography fontSize={20} fontWeight={500} color="primary">
                            Rules
                        </Typography>

                        <BaseButton label="Add" onClick={() => setIsDialogOpen(true)} />

                        {/* Search
							========================================= */}
                        {/* <SearchField
                            placeholder="Search"
                            sx={{
                                marginLeft: 'auto',
                                marginRight: 1,
                            }}
                        /> */}

                        {/* Filter button
							========================================= */}
                        <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <FilterListIcon />
                        </IconButton>

                        {/* Download button
							========================================= */}
                        <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <DownloadIcon />
                        </IconButton>

                        {/* Print button
							========================================= */}
                        <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <PrintIcon />
                        </IconButton>
                    </Stack>

                    {/* Main content
						========================================= */}
                    <Stack
                        flexGrow={1}
                        gap={2}
                        sx={{
                            padding: 3,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {rules.length == 0 ? (
                            /*	Empty message
                                ========================================= */
                            <Stack flexGrow={1} alignItems="center" justifyContent="center">
                                <img src={ImgThinkingFace} alt="no data" />

                                <Typography fontSize={18} fontWeight={400} color="common.text_1">
                                    You have no rules yet.
                                </Typography>
                                <Typography fontSize={14} fontWeight={400} color="common.text_1" mb={2}>
                                    Start from Add New Rule button below.
                                </Typography>

                                <BaseButton
                                    label="Add New Rule"
                                    onClick={(e) => setIsDialogOpen(true)}
                                    variant="outlined"
                                    size="large"
                                    width="360px"
                                />
                            </Stack>
                        ) : (
                            rules.map((rule, index) => (
                                <SurveyRulesItem
                                    accounts={accounts}
                                    defaultValue={rule}
                                    questions={questions?.Questions || []}
                                    saveChanges={updateRule(index)}
                                    deleteRule={deleteRule(index)}
                                />
                            ))
                        )}
                    </Stack>
                </Stack>

                {/* New rule dialog
                    ========================================= */}
                <StyledDialog
                    open={isDialogOpen}
                    onClose={(event, reason) => setIsDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <Stack alignItems="flex-start" gap={1} py={2.5} px={3}>
                        <Typography fontSize={36} fontWeight={600} color="common.text_1" marginBottom={2}>
                            Create New Rule
                        </Typography>

                        <Typography fontSize={16} fontWeight={400}>
                            Type:
                        </Typography>

                        {/* Rule type radios
						    ========================================= */}
                        <RadioGroup
                            value={ruleType}
                            onChange={(e, v) => setRuleType(v === 'individual' ? 'individual' : 'aggregation')}
                            sx={{
                                marginBottom: 8,
                            }}
                        >
                            <FormControlLabel
                                value="individual"
                                label="Individual"
                                control={<Radio />}
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: 20,
                                        fontWeight: 600,
                                    },
                                }}
                            />

                            <FormControlLabel
                                value="aggregation"
                                label="Aggregation"
                                disabled={true}
                                control={<Radio />}
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: 20,
                                        fontWeight: 600,
                                    },
                                }}
                            />
                        </RadioGroup>

                        {/* Control buttons
						    ========================================= */}
                        <Stack alignSelf="stretch" direction="row" justifyContent="space-between" gap={3}>
                            <BaseButton
                                label="Cancel"
                                variant="outlined"
                                size="large"
                                onClick={() => setIsDialogOpen(false)}
                                fullWidth
                            />

                            <BaseButton label="Add" variant="contained" size="large" fullWidth onClick={addRule} />
                        </Stack>
                    </Stack>
                </StyledDialog>
            </PageCard.Body>
        </PageCard>
    )
}

export default SurveyRules
