import { NetworkVizContextType, NetworkVizInitialState } from 'features/network-viz/context/NetworkVizContext'
import { NetworkVizContextActionType } from 'features/network-viz/context/NetworkVizContextReducer'
import {
    EdgeRenderType,
    EdgeType,
    FilterItemType,
    NodeAdjacentListType,
    NodeRenderType,
    NodeType,
} from 'features/network-viz/types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import { toast } from 'react-toastify'

//check if the filter cover a given value
export function isValueSelected(filterItem: FilterItemType, value: string | number) {
    if (filterItem.attribute === null) return false

    if (filterItem.type === 'number') {
        //ToDo: Think if we need it in future. Not supported at least for now.
        if (filterItem.numericMode === 'topK') return false

        let tmpNumber = Number(value)
        if (Number.isNaN(tmpNumber)) {
            return false
        }
        return tmpNumber >= filterItem.range.max && tmpNumber <= filterItem.range.min
    }

    if (filterItem.type === 'string') {
        return filterItem.values.includes(value + '')
    }

    return false
}

// * This function will apply filters to the given networkviz contxt
export async function applyFilter(
    context: Pick<
        NetworkVizContextType,
        | 'networkVizKind'
        | 'layoutKind'
        | 'layoutSettings'
        | 'nodeRenders'
        | 'nodes'
        | 'edges'
        | 'edgeRenders'
        | 'analytics'
        | 'nodeAdjacentList'
        | 'analyticsSettings'
        | 'nodeInteractionConfig'
    >,
    filterItems: FilterItemType[],
    runLayoutAfterFilter: boolean = false
): Promise<Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders' | 'analytics' | 'nodeAdjacentList'>> {
    // Destructure context to retrieve relevant fields.
    const {
        networkVizKind,
        layoutKind,
        layoutSettings,
        nodeRenders: initialNodeRenders,
        nodes,
        edgeRenders: initialEdgeRenders,
        edges,
        analytics: initialAnalytics,
        nodeAdjacentList: initialNodeAdjacentList,
        nodeInteractionConfig,
        analyticsSettings,
    } = context

    // Cache initial analytics data.
    let _analytics = initialAnalytics
    let nodeAdjacentList = initialNodeAdjacentList!
    let hasDataChanged = false

    const filterWorker = new WebWorker<{
        nodeRenders: NodeRenderType[]
        edgeRenders: EdgeRenderType[]
    }>('workers/network/apply-filter.js')

    let data = await filterWorker.run({
        nodeRenders: initialNodeRenders,
        nodes,
        edgeRenders: initialEdgeRenders,
        edges,
        filterItems: filterItems,
        analytics: initialAnalytics,
    })

    // Check if data has changed after the filter application.
    hasDataChanged =
        JSON.stringify(data.nodeRenders) !== JSON.stringify(initialNodeRenders) ||
        JSON.stringify(data.edgeRenders) !== JSON.stringify(initialEdgeRenders)

    // Step 2: Compute analytics (only if data has changed)

    if (hasDataChanged) {
        // Prepare data for analytics computation.
        // const options = {
        //     nodes: data.nodeRenders,
        //     edges: data.edgeRenders,
        //     allEdges: Object.values(edges || {}),
        //     allNodes: Object.values(nodes || {}),
        //     analyticsSettings: analyticsSettings || {},
        // }

        // _analytics = await computeAnalytics(options, initialAnalytics || null)

        // Re-calculate node adjacency list.
        const nodeAdjacencyWorker = new WebWorker<NodeAdjacentListType>('workers/network/node-adjacency.js')
        nodeAdjacentList = await nodeAdjacencyWorker.run({
            edgeRenders: data.edgeRenders.filter((x) => x.hide !== true),
            isDirected: nodeInteractionConfig.directed || true,
        })

        // Step 5: Update layout if required.
        if (runLayoutAfterFilter) {
            const worker = new WebWorker<NodeRenderType[]>('workers/network/apply-alogorithm.js')

            const res = await worker.run({
                nodeRenders: data.nodeRenders,
                edgeRenders: data.edgeRenders,
                layout: layoutKind || 'sfdp',
                layoutSetting:
                    layoutSettings?.[layoutKind || 'sfdp'] ||
                    NetworkVizInitialState.layoutSettings[layoutKind || 'sfdp'],
                mode: networkVizKind === '3d' ? '3d' : '2d',
                analytics: _analytics,
                nodes,
                edges,
                analyticsUrl: process.env.REACT_APP_API_URL,
            })

            // Update node renders after layout update.
            data.nodeRenders = res
        }
    }

    // Return updated network visualization context.
    return {
        edgeRenders: data.edgeRenders,
        nodeRenders: data.nodeRenders,
        analytics: _analytics || null,
        nodeAdjacentList,
    }
}

export function removeFilter(
    filterId: string,
    context: Partial<NetworkVizContextType>,
    dispatchContext: React.Dispatch<NetworkVizContextActionType>
) {
    // const worker = new WebWorker('workers/network/apply-filter.js');
    // const { nodeRenders, nodes, edgeRenders, edges, analytics } = context
    // worker.run({
    //     nodeRenders, nodes, edgeRenders, edges, filterItems, analytics
    // }).then((data: any) => {
    //     const { nodeRenders, edgeRenders } = data;
    //     dispatchContext({
    //         type: 'FILTER_CHANGE', payload: {
    //             edgeRenders, nodeRenders, filters: filterItems
    //         }
    //     })
    // }).catch(() => {
    // }).finally(() => {
    // });
}

// Helper function to compute analytics
async function computeAnalytics(
    options: {
        nodes: NodeRenderType[]
        edges: EdgeRenderType[]
        allEdges: EdgeType[]
        allNodes: NodeType[]
        nodeInfo: Record<string, NodeType>
        analyticsSettings: {}
    },
    initialAnalytics: NetworkVizContextType['analytics']
): Promise<NetworkVizContextType['analytics']> {
    const analyticsWebWorker = new WebWorker<NetworkVizContextType['analytics']>(
        'workers/network/apply-advance-analytics.js'
    )
    try {
        const newAnalytics = await analyticsWebWorker.run({
            mode: 'basic',
            options,
            analyticsUrl: process.env.REACT_APP_API_URL,
        })
        return { ...(initialAnalytics || {}), ...newAnalytics }
    } catch (e) {
        toast.error('Failed to calculate analytics.')
        return initialAnalytics
    }
}
