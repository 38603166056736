//* ======= Libraries
import React from 'react'
import { Menu, MenuItem, List, Divider } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import { ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles

export type ReportSlideContextMenuProps = {
    isOpen: boolean
    mouseX: number
    mouseY: number
    view: 'slide' | 'widget'
    widgetData?: ReportWidgetType
    widgetPositionIndex?: number
    onClose: () => void
    onCopy: (widget: ReportWidgetType) => void
    canPaste: boolean
    onPaste: (mouseX: number, mouseY: number) => void
    onDelete: (widgetId: ReportWidgetType['id']) => void
    canChangeLayerToTop: boolean
    canChangeLayerToBottom: boolean
    onLayerChange: (widgetPositionIndex: number, step: 1 | 'top' | -1 | 'back') => void
    onHide: (widgetId: ReportWidgetType['id']) => void
    onHideOthers: (widgetId: ReportWidgetType['id']) => void
    onShowAllWidgets: () => void
    onUpdateWidgetLock: (widgetId: ReportWidgetType['id'], isLocked: boolean) => void
}

function ReportSlideContextMenu({
    isOpen,
    mouseX,
    mouseY,
    view,
    widgetData,
    widgetPositionIndex,
    onClose,
    onCopy,
    canPaste,
    onPaste,
    onDelete,
    canChangeLayerToTop,
    canChangeLayerToBottom,
    onLayerChange,
    onHide,
    onHideOthers,
    onShowAllWidgets,
    onUpdateWidgetLock,
}: ReportSlideContextMenuProps) {
    return isOpen ? (
        <Menu
            open={isOpen}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={isOpen ? { top: mouseY, left: mouseX } : undefined}
            PaperProps={{
                className: 'u-scrollbar',
            }}
            sx={{
                '& .MuiPaper-root': { minWidth: 120, maxWidth: 180, maxHeight: 380 },
            }}
        >
            {/* Copy option
				========================================= */}
            {view === 'widget' && <MenuItem onClick={(evt) => widgetData && onCopy(widgetData)}>Copy</MenuItem>}

            {/* Paste option
				========================================= */}
            {view === 'slide' && (
                <>
                    <MenuItem onClick={(evt) => onPaste(mouseX, mouseY)} disabled={canPaste === false}>
                        Paste
                    </MenuItem>
                    <MenuItem onClick={(evt) => onShowAllWidgets()}>Show All Widgets</MenuItem>
                </>
            )}

            {/* Delete option
				========================================= */}
            {view === 'widget' && <MenuItem onClick={(evt) => widgetData && onDelete(widgetData.id)}>Delete</MenuItem>}

            {/* Widget layer options
				========================================= */}
            {view === 'widget' && (
                // We are FORCED to create a List here because if we use fragments,
                // MUI will scream that the Menu can't render fragments!
                <List disablePadding>
                    <Divider
                        sx={{
                            marginY: 1,
                        }}
                    />

                    {/* Bring forward option
						========================================= */}
                    <MenuItem
                        onClick={(evt) => widgetPositionIndex !== undefined && onLayerChange(widgetPositionIndex, 1)}
                        disabled={canChangeLayerToTop === false}
                    >
                        Bring Forward
                    </MenuItem>

                    {/* Bring to top option
						========================================= */}
                    <MenuItem
                        onClick={(evt) =>
                            widgetPositionIndex !== undefined && onLayerChange(widgetPositionIndex, 'top')
                        }
                        disabled={canChangeLayerToTop === false}
                    >
                        Bring to Top
                    </MenuItem>

                    <Divider />

                    {/* Send backward option
						========================================= */}
                    <MenuItem
                        onClick={(evt) => widgetPositionIndex !== undefined && onLayerChange(widgetPositionIndex, -1)}
                        disabled={canChangeLayerToBottom === false}
                    >
                        Send Backwards
                    </MenuItem>

                    {/* Send to back option
						========================================= */}
                    <MenuItem
                        onClick={(evt) =>
                            widgetPositionIndex !== undefined && onLayerChange(widgetPositionIndex, 'back')
                        }
                        disabled={canChangeLayerToBottom === false}
                    >
                        Send to Back
                    </MenuItem>
                </List>
            )}

            {/* Widget Designer hide options */}
            {view === 'widget' && widgetData && (
                <List disablePadding>
                    <Divider />
                    <MenuItem onClick={(evt) => onUpdateWidgetLock(widgetData.id, !widgetData.locked)}>
                        {widgetData.locked ? 'Unlock' : 'Lock'}
                    </MenuItem>
                    <MenuItem onClick={(evt) => onHide(widgetData.id)}>Hide</MenuItem>
                    <MenuItem onClick={(evt) => onHideOthers(widgetData.id)}>Hide Others</MenuItem>
                </List>
            )}
        </Menu>
    ) : null
}

export default ReportSlideContextMenu
