//* ======= Libraries
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PartialDeep } from 'type-fest'
import { ClassicEditorType } from 'ckeditor5-custom-build'
import { toast } from 'react-toastify'
import { Stack, Typography, Divider, LinearProgress, Box, Tooltip, IconButton, FormControlLabel } from '@mui/material'
//* ======= Components and features
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import EditorClassic from 'components/Editor/EditorClassic'
import ColorPicker from 'components/widgets/ColorPicker'
import FontPicker from 'components/widgets/FontPicker'
import ImageUpload from 'components/ImageUpload/ImageUpload'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { UploadImageService } from 'services/ReportApi'
import { ASPECT_RATIO_OPTIONS, IMAGE_FIT_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
//* ======= Assets and styles
import DeleteIcon from '@mui/icons-material/Delete'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { saveReport } from './helpers/reportDesigner.helper'
import { APP_VERSIONS } from 'helpers/constants'

function ReportSettings() {
    const { pid, rid } = useParams()

    const {
        dataSources,
        slides,
        aspectRatio,
        masterSettings,
        updateAspectRatio,
        updateBackgroundColor,
        updateHeader: store_updateHeader,
        updateMasterSettings,
    } = useReportStore((store) => ({
        dataSources: store.dataSources,
        slides: store.slides,
        aspectRatio: store.aspectRatio,
        masterSettings: store.masterSettings,
        updateAspectRatio: store.updateAspectRatio,
        updateMasterSettings: store.updateMasterSettings,
        updateBackgroundColor: store.updateBackgroundColor,
        updateHeader: store.updateHeader,
    }))

    // const [editorInstance, setEditorInstance] = useState<InlineEditorType | null>(null)

    const [aspectRatioSelectValue, setAspectRatioSelectValue] = useState(aspectRatio.join(':'))

    useEffect(() => {
        if (aspectRatio.join(':') !== aspectRatioSelectValue) {
            const ratioValues = aspectRatioSelectValue.split(':')

            updateAspectRatio({ ratio: [Number(ratioValues[0]), Number(ratioValues[1])] })
        }
    }, [aspectRatioSelectValue])

    const updateHeader = (data: PartialDeep<ReportDesignerStoreStateType['masterSettings']['header']>) => {
        store_updateHeader({
            settings: data,
        })
    }

    const onHeaderImageUpload = async (url: string, name: string) => {
        if (pid === undefined || rid === undefined) return

        updateHeader({
            image: {
                status: 'uploading',
            },
        })

        try {
            const result = await UploadImageService(pid, rid, url)

            if (result.success) {
                updateHeader({
                    image: {
                        status: 'ready',
                        source: result.data,
                        name: name,
                    },
                })
            } else {
                toast.error('Cannot upload the selected image!')

                updateHeader({
                    image: {
                        status: 'ready',
                    },
                })
            }
        } catch (error) {
            toast.error('Cannot upload the selected image!')

            updateHeader({
                image: {
                    status: 'ready',
                },
            })
        }
    }

    const onThumbnailImageUpload = async (url: string, name: string) => {
        if (pid === undefined || rid === undefined) return

        try {
            saveReport(
                {
                    reportVersion: APP_VERSIONS.REPORT,
                    aspectRatio,
                    masterSettings: masterSettings,
                    dataSources,
                    slides,
                },
                pid!,
                rid!,
                url
            )
        } catch {}
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                height: '100%',
                paddingTop: theme.spacing(0.5),
                paddingX: theme.spacing(2),
                overflowY: 'auto',
                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                overflowX: 'hidden',
            })}
            className="u-scrollbar"
        >
            {/* Heading
                ========================================= */}
            <Typography fontSize={16} noWrap sx={{ flexShrink: 0 }}>
                Report Settings
            </Typography>

            <Divider
                sx={{
                    marginBottom: 1.5,
                }}
            />

            {/* Aspect ratio
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                <Typography fontSize={14} noWrap sx={{ flexShrink: 0 }}>
                    Aspect Ratio:
                </Typography>

                <BaseSelectWithLabel
                    options={ASPECT_RATIO_OPTIONS}
                    value={aspectRatioSelectValue}
                    onChange={(value: string) => setAspectRatioSelectValue(value)}
                    size="small"
                    hiddenLabel
                    sx={{
                        width: 110,
                    }}
                />
            </Stack>

            {/* Background color
                ========================================= */}
            <StyledWidgetAccordion
                title="Background Color"
                hasToggle
                isToggledOff={masterSettings.backgroundColor.isEnabled === false}
                onToggle={(isEnabled) =>
                    updateBackgroundColor({
                        styles: {
                            isEnabled: isEnabled,
                        },
                    })
                }
                hasBottomBorder
            >
                <ColorPicker
                    disabled={masterSettings.backgroundColor.isEnabled === false}
                    value={masterSettings.backgroundColor.color}
                    onChange={(newColor) =>
                        updateBackgroundColor({
                            styles: {
                                color: newColor,
                            },
                        })
                    }
                    isPopover={true}
                />
            </StyledWidgetAccordion>

            {/* Header
                ========================================= */}
            <StyledWidgetAccordion
                title="Header"
                hasToggle
                isToggledOff={masterSettings.header.isEnabled === false}
                onToggle={(isEnabled) =>
                    updateHeader({
                        isEnabled: isEnabled,
                    })
                }
                hasBottomBorder
            >
                <Stack gap={1}>
                    {/* Secondary text
                        ========================================= */}
                    <BaseFilledTextField
                        label="Secondary Text"
                        defaultValue={masterSettings.header.secondaryText.value}
                        onBlur={(evt) =>
                            updateHeader({
                                secondaryText: {
                                    value: evt.target.value.trim(),
                                },
                            })
                        }
                        disabled={masterSettings.header.isEnabled === false}
                        size="small"
                        fullWidth
                    />

                    {/* Secondary text styles
                        ========================================= */}
                    <FontPicker
                        onChange={(values) =>
                            updateHeader({
                                secondaryText: {
                                    styles: {
                                        fontFamily: values.fontFamily,
                                        fontSize: values.fontSize,
                                        fontWeight: values.isBold ? 'bold' : 'normal',
                                        color: values.color,
                                    },
                                },
                            })
                        }
                        disabled={masterSettings.header.isEnabled === false}
                        defaultValue={{
                            fontFamily: masterSettings.header.secondaryText.styles.fontFamily,
                            fontSize: masterSettings.header.secondaryText.styles.fontSize,
                            color: masterSettings.header.secondaryText.styles.color,
                            isBold: masterSettings.header.secondaryText.styles.fontWeight === 'bold',
                        }}
                        styleControls={false}
                    />

                    {/* Image header and remove button
                        ========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            marginTop: theme.spacing(1),
                        })}
                    >
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={(theme) => ({
                                flex: '1 0 0',

                                paddingY: theme.spacing(1),
                            })}
                        >
                            Image:
                        </Typography>

                        {/* Remove image button
                            ========================================= */}
                        {masterSettings.header.image.source !== '' && (
                            <Tooltip
                                title="Remove Image"
                                PopperProps={{
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -8],
                                            },
                                        },
                                    ],
                                }}
                            >
                                {/* Intermediary div to handle MUI Tooltip "disabled content" error. */}
                                <Box
                                    sx={{
                                        flex: '0 0 auto',
                                    }}
                                >
                                    <IconButton
                                        onClick={(evt) =>
                                            updateHeader({
                                                image: {
                                                    source: '',
                                                    name: '',
                                                },
                                            })
                                        }
                                        disabled={masterSettings.header.isEnabled === false}
                                        sx={(theme) => ({
                                            color: theme.palette.common.ung_pink,
                                        })}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        )}
                    </Stack>

                    {/* Image name
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={{
                                flex: '0 0 auto',
                            }}
                        >
                            File name:
                        </Typography>

                        <Typography
                            fontSize={14}
                            noWrap
                            textAlign="right"
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            {masterSettings.header.image.name || '(N/A)'}
                        </Typography>
                    </Stack>

                    {/* Upload button
                        ========================================= */}
                    <BaseButton
                        label="Select File"
                        onClick={(evt) => document.getElementById('report-header-image-upload-input')?.click()}
                        disabled={
                            masterSettings.header.isEnabled === false ||
                            masterSettings.header.image.status === 'uploading'
                        }
                        variant="outlined"
                        startIcon={
                            <FileUploadIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                }}
                            />
                        }
                        sx={{
                            paddingY: 1.25,
                        }}
                    />

                    {/* Uploading status indicator
                        ========================================= */}
                    {masterSettings.header.image.status === 'uploading' && <LinearProgress />}

                    {/* Image fit select
                        ========================================= */}
                    <BaseSelectWithLabel
                        label="Image fit"
                        options={IMAGE_FIT_OPTIONS}
                        value={masterSettings.header.image.fit}
                        onChange={(value) =>
                            updateHeader({
                                image: {
                                    fit: value,
                                },
                            })
                        }
                        disabled={
                            masterSettings.header.isEnabled === false || masterSettings.header.image.source === ''
                        }
                        size="small"
                        sx={(theme) => ({
                            marginBottom: theme.spacing(1),
                        })}
                    />

                    {/* Height
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={(theme) => ({
                                flex: '1 0 0',

                                paddingY: theme.spacing(1),
                            })}
                        >
                            Height (%):
                        </Typography>

                        <BaseFilledTextField
                            type="number"
                            defaultValue={masterSettings.header.height}
                            onBlur={(evt) => {
                                updateHeader({
                                    height:
                                        Number(evt.target.value) > 50
                                            ? 50
                                            : Number(evt.target.value) < 8
                                            ? 8
                                            : Number(evt.target.value),
                                })

                                if (Number(evt.target.value) > 50) {
                                    evt.target.value = '50'
                                } else if (Number(evt.target.value) < 8) {
                                    evt.target.value = '8'
                                }
                            }}
                            disabled={masterSettings.header.isEnabled === false}
                            inputProps={{
                                min: 8,
                                max: 50,
                                step: 1,
                            }}
                            hiddenLabel
                            size="small"
                            sx={(theme) => ({
                                flex: '0 0 40%',
                            })}
                        />
                    </Stack>

                    {/* Background color
                        ========================================= */}
                    <StyledWidgetAccordion
                        title="Background Color"
                        hasToggle
                        isToggledOff={masterSettings.header.backgroundColor.isEnabled === false}
                        onToggle={(isEnabled) =>
                            updateHeader({
                                backgroundColor: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        isDisabled={masterSettings.header.isEnabled === false}
                        hasBottomBorder
                    >
                        <ColorPicker
                            value={masterSettings.header.backgroundColor.color}
                            onChange={(newColor) =>
                                updateHeader({
                                    backgroundColor: {
                                        color: newColor,
                                    },
                                })
                            }
                            disabled={
                                masterSettings.header.isEnabled === false ||
                                masterSettings.header.backgroundColor.isEnabled === false
                            }
                            isPopover={true}
                        />
                    </StyledWidgetAccordion>

                    {/* Bottom shadow switch
                        ========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            marginTop: theme.spacing(1),
                        })}
                    >
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Bottom Shadow:
                        </Typography>

                        <BaseSwitch
                            checked={masterSettings.header.hasBottomShadow}
                            onChange={(evt, checked) =>
                                updateHeader({
                                    hasBottomShadow: checked,
                                })
                            }
                            disabled={masterSettings.header.isEnabled === false}
                            size="small"
                            color="primary"
                            sx={{
                                flex: '0 0 auto',
                            }}
                        />
                    </Stack>
                </Stack>
            </StyledWidgetAccordion>

            {/* Onboarding
                ========================================= */}
            <StyledWidgetAccordion title="Onboarding" hasBottomBorder>
                <Stack gap={1}>
                    {/* <BaseSelectWithLabel
                        label="On Finish"
                        value={masterSettings.onboarding.onFinishBehavior}
                        fullWidth
                        onChange={(value) =>
                            updateMasterSettings({
                                settings: {
                                    onboarding: {
                                        onFinishBehavior: value,
                                    },
                                },
                            })
                        }
                        options={[
                            { label: 'Close', value: 'close' },
                            { label: 'Next Slide', value: 'next-slide' },
                        ]}
                        size="small"
                    /> */}

                    <BaseFilledTextField
                        label="Finish Button Label"
                        defaultValue={masterSettings.onboarding.onFinishText}
                        onBlur={(evt) =>
                            updateMasterSettings({
                                settings: {
                                    onboarding: {
                                        onFinishText: evt.target.value,
                                    },
                                },
                            })
                        }
                    />
                    {/* {masterSettings.onboarding.onFinishBehavior === 'next-slide' && (
                        <BaseFilledTextField
                            label="Next Slide Button Label"
                            defaultValue={masterSettings.onboarding.onNextSlideText}
                            onBlur={(evt) =>
                                updateMasterSettings({
                                    settings: {
                                        onboarding: {
                                            onNextSlideText: evt.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    )} */}

                    {/* <FormControlLabel
                        control={<BaseSwitch size="small" />}
                        checked={masterSettings.onboarding.hasSkipButton}
                        label="Can Skip"
                        sx={{ ml: 1 }}
                        onChange={(evt, checked) =>
                            updateMasterSettings({
                                settings: {
                                    onboarding: {
                                        hasSkipButton: checked,
                                    },
                                },
                            })
                        }
                    /> */}

                    {/* {masterSettings.onboarding.hasSkipButton && (
                        <BaseFilledTextField
                            label="Skip Button Label"
                            defaultValue={masterSettings.onboarding.skipText}
                            onBlur={(evt) =>
                                updateMasterSettings({
                                    settings: {
                                        onboarding: {
                                            skipText: evt.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    )} */}
                </Stack>
            </StyledWidgetAccordion>

            {/* Report Thumbnail
                ========================================= */}
            <Stack>
                <Typography fontSize={16} noWrap sx={{ flexShrink: 0 }}>
                    Report Thumbsnail
                </Typography>

                {/* Upload button
                        ========================================= */}
                <BaseButton
                    label="Select File"
                    onClick={(evt) => document.getElementById('report-thumbnail-image-upload-input')?.click()}
                    variant="outlined"
                    startIcon={
                        <FileUploadIcon
                            sx={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    }
                    sx={{
                        paddingY: 1.25,
                    }}
                />
            </Stack>

            {/* (Hidden) Image upload input
                ========================================= */}
            <ImageUpload
                // This id is tied to logic. Change accordingly.
                inputId="report-header-image-upload-input"
                onComplete={onHeaderImageUpload}
            />

            <ImageUpload
                // This id is tied to logic. Change accordingly.
                inputId="report-thumbnail-image-upload-input"
                onComplete={onThumbnailImageUpload}
            />
        </Stack>
    )
}

export default ReportSettings
