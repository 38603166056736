//* ======= Libraries
import React, { useState, useContext, useEffect } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import Box from '@mui/material/Box'
import NodesSidebar from 'features/nodes/NodesSidebar'
import NodeOverview from 'features/nodes/NodeOverview'
import NodeSurveys from 'features/nodes/NodeSurveys'
import NodeRelations from 'features/nodes/NodeRelations'
//* ======= Custom logic
//* ======= Assets and styles
import { GetNodeService, NodeType } from 'services/NodeService'
import { useLocation, useParams } from 'react-router-dom'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { RootContext } from 'contexts/RootContext'
import { ProjectContext } from 'contexts/ProjectContext'
import { Dashboard } from '@mui/icons-material'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'
import { PERMISSIONS } from 'helpers/constants'
import BaseButton from 'components/base/BaseButton'
import { Card, CardActions, CardContent, Paper, Stack, Typography } from '@mui/material'
import NodeActions from 'features/nodes/NodeActions'
import { GetProjectAccounts, ProjectAccountType } from 'services/ProjectService'
import { NodeActionType } from 'services/NodeActionService'
import { set } from 'lodash'

interface INodeDetails {}

function NodeDetails({}: INodeDetails) {
    const { setPath } = useContext(RootContext)
    const { project, hasPermission } = useContext(ProjectContext)
    const [selectedLayout, setSelectedLayout] = useState<'overview' | 'surveys' | 'relations' | 'actions'>('overview')
    const [selectedAction, setSelectedAction] = useState<NodeActionType | null>(null)

    const { nid, pid } = useParams()
    const location = useLocation()

    const [node, setNode] = useState<NodeType | null | undefined>()
    const [accounts, setAccounts] = useState<ProjectAccountType[]>([])

    useEffect(() => {
        if (pid != null && nid != null) {
            GetProjectAccounts(pid).then((res) => {
                res.success && setAccounts(res.data)
            })
            GetNodeService(pid, nid)
                .then((res) => {
                    if (res.success) {
                        setNode(res.data)
                        const queryParams = new URLSearchParams(location.search)
                        const action = queryParams.get('action')
                        if (action != null) {
                            setSelectedLayout('actions')
                            const nodeAction = res.data.actions?.find((a) => a.primaryKey + '' === action) ?? null
                            setSelectedAction(nodeAction)
                        }
                        setPath((p) => [...p, { title: res.data.title }])
                    } else {
                        setNode(null)
                    }
                })
                .catch((e) => setNode(null))

            // Update node every 30 seconds
            const intervalId = setInterval(() => {
                if (document.visibilityState === 'visible') {
                    GetNodeService(pid, nid).then((res) => {
                        if (res.success) {
                            setNode(res.data)
                        }
                    })
                }
            }, 30000)

            return () => {
                clearInterval(intervalId)
            }
        }
    }, [])

    const updateNode = (node: NodeType) => {
        setNode(node)
    }

    if (project == null || node === undefined) {
        return <DashboardSkeleton />
    }

    if (hasPermission(PERMISSIONS.node.ReadSpecificNode) === false) {
        return (
            <PageCard>
                <PageCard.Body>
                    <Stack height="100%" justifyContent="center" alignItems="center">
                        <Card>
                            <CardContent>
                                <Typography>You don't have permission to access this page.</Typography>
                            </CardContent>
                            <CardActions>
                                <BaseButton fullWidth variant="contained">
                                    Go Back to Project Dashboard
                                </BaseButton>
                            </CardActions>
                        </Card>
                    </Stack>
                </PageCard.Body>
            </PageCard>
        )
    }

    if (node === null) {
        return (
            <PageCard>
                <PageCard.Body>
                    <Box height="100%">
                        <Typography>Node not found</Typography>
                        <BaseButton variant="contained">Go Back to Project Dashboard</BaseButton>
                    </Box>
                </PageCard.Body>
            </PageCard>
        )
    }

    return (
        <PageCard>
            <PageCard.Side>
                <NodesSidebar updateNode={(n) => setNode(n)} node={node} />
            </PageCard.Side>

            <PageCard.Body noPaddingRight>
                <Box height="100%">
                    {selectedLayout === 'overview' ? (
                        <NodeOverview
                            node={node}
                            navigationHandlers={{
                                toSurveys: () => setSelectedLayout('surveys'),
                                toRelations: () => setSelectedLayout('relations'),
                                toActions: () => setSelectedLayout('actions'),
                            }}
                        />
                    ) : selectedLayout === 'surveys' ? (
                        <NodeSurveys
                            node={node}
                            navigationHandlers={{
                                toOverview: () => setSelectedLayout('overview'),
                                toRelations: () => setSelectedLayout('relations'),
                                toActions: () => setSelectedLayout('actions'),
                            }}
                        />
                    ) : selectedLayout === 'relations' ? (
                        <NodeRelations
                            node={node}
                            navigationHandlers={{
                                toOverview: () => setSelectedLayout('overview'),
                                toSurveys: () => setSelectedLayout('surveys'),
                                toActions: () => setSelectedLayout('actions'),
                            }}
                        />
                    ) : selectedLayout === 'actions' ? (
                        <NodeActions
                            node={node}
                            updateNode={updateNode}
                            accounts={accounts}
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                            navigationHandlers={{
                                toOverview: () => setSelectedLayout('overview'),
                                toSurveys: () => setSelectedLayout('surveys'),
                                toRelations: () => setSelectedLayout('relations'),
                            }}
                        />
                    ) : (
                        false
                    )}
                </Box>
            </PageCard.Body>
        </PageCard>
    )
}

export default NodeDetails
