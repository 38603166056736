//* ======= Libraries
import React, { ReactNode, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import PrivacyPolicyDialog from 'features/sign-in/PrivacyPolicyDialog'
import TermsOfServiceDialog from 'features/sign-in/TermsOfServiceDialog'
//* ======= Custom logic
//* ======= Assets and styles
import Logo from 'assets/images/logo.svg'
import LoginVector from 'assets/images/login-vector.svg'

interface ISignInLayout {
    children?: ReactNode
}

function SignInLayout({ children }: ISignInLayout) {
    const location = useLocation()

    const theme = useTheme()
    const isViewportSmall = useMediaQuery(theme.breakpoints.down('xl'))

    const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false)
    const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)

    // TODO: Quite a dirty implementation.
    const sidebarText =
        location.pathname === '/'
            ? 'Welcome Back'
            : location.pathname === '/create-account'
            ? 'Join SNA Toolbox'
            : ''

    return (
        <Box
            sx={{
                flexGrow: 1,

                display: 'grid',
                gridTemplateColumns: isViewportSmall ? '1fr' : '5fr 7fr',
                gridTemplateRows: isViewportSmall ? 'min-content 1fr' : '1fr',

                height: '100%',

                backgroundColor: 'common.bg_1',
            }}
        >
            {/* Left side
				========================================= */}
            <Stack
                direction={isViewportSmall ? 'row' : 'column'}
                justifyContent={isViewportSmall ? 'flex-start' : 'space-between'}
                gap={2}
                sx={(theme) => ({
                    position: 'relative',

                    minWidth: 0,
                    padding: isViewportSmall ? theme.spacing(4, 10, 3.5) : theme.spacing(10, 10, 6),
                    overflow: 'hidden',

                    background: 'linear-gradient(180deg, #217BBF 0%, #0A5792 100%)',
                })}
            >
                {/* Background vector
					========================================= */}
                <img
                    src={LoginVector}
                    alt="login vector"
                    style={{
                        position: 'absolute',
                        top: '-5%',
                        right: isViewportSmall ? 0 : '-33%',

                        height: isViewportSmall ? '174px' : '70vh',
                        pointerEvents: 'none',
                        userSelect: 'none',
                    }}
                />

                {/* Top row buttons
					========================================= */}
                {isViewportSmall === false && (
                    <Stack direction="row" alignItems="center">
                        {/* Back button
						========================================= */}
                        {/* <BaseButton
                            label="Back"
                            color="secondary"
                            startIcon={<ArrowBackIcon />}
                            sx={{
                                px: 2,

                                color: 'common.text_4',
                            }}
                        /> */}
                    </Stack>
                )}

                {/* Greeting and logo
					========================================= */}
                <Stack alignItems="center">
                    {/* Greeting
						========================================= */}
                    {isViewportSmall === false && (
                        <Typography fontSize={45} fontWeight={700} color="common.text_4" mb={3}>
                            {sidebarText}
                        </Typography>
                    )}

                    {/* Logo
						========================================= */}
                    <img
                        src={Logo}
                        alt="logo"
                        style={{
                            height: isViewportSmall ? '40px' : undefined,
                            marginBottom: isViewportSmall ? 0 : '24px',
                        }}
                    />

                    {isViewportSmall === false && (
                        <>
                            <Typography fontSize={16} fontWeight={400} color="common.text_4" mb={0.5}>
                                SNA Toolbox Survey Software
                            </Typography>

                            <Typography fontSize={14} fontWeight={400} color="common.text_4">
                                Version 2.1.9
                            </Typography>
                        </>
                    )}
                </Stack>

                {/* Bottom row
					========================================= */}
                {isViewportSmall === false && (
                    <Stack direction="row" justifyContent="center" gap={9} alignItems="center">
                        {/* Privacy policy button
						    ========================================= */}
                        <BaseButton
                            label="Privacy Policy"
                            onClick={(evt) => setIsPrivacyPolicyDialogOpen(true)}
                            color="secondary"
                            sx={{
                                px: 2,

                                color: 'common.text_4',
                            }}
                        />

                        {/* Terms of use button
						    ========================================= */}
                        <BaseButton
                            label="Terms of Use"
                            onClick={(evt) => setIsTermsOfServiceDialogOpen(true)}
                            color="secondary"
                            sx={{
                                px: 2,

                                color: 'common.text_4',
                            }}
                        />
                    </Stack>
                )}
            </Stack>

            {/* Right side
				========================================= */}
            <Box
                gap={2}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    // '80px' is the top padding of 'Left side' container in side-by-side view.
                    gridTemplateRows: isViewportSmall ? '10px 1fr auto' : '80px 1fr auto',
                    justifyItems: 'center',
                    alignItems: 'center',

                    minWidth: 0,
                    padding: 1,
                }}
            >
                {/* Page content
					========================================= */}
                <Stack
                    sx={{
                        gridRow: 2,

                        alignItems: 'center',

                        width: '100%',
                    }}
                >
                    {children}
                </Stack>

                {/* Privacy/Terms of use buttons and copyright statement
					========================================= */}
                <Stack
                    gridRow={3}
                    alignItems="center"
                    gap={1}
                    sx={{
                        marginTop: isViewportSmall ? 2 : 0,
                        marginBottom: isViewportSmall ? 3 : 6,
                    }}
                >
                    {isViewportSmall && (
                        <Stack direction="row" alignItems="center" gap={3}>
                            {/* Privacy policy button
						    ========================================= */}
                            <BaseButton
                                label="Privacy Policy"
                                onClick={(evt) => setIsPrivacyPolicyDialogOpen(true)}
                                size="small"
                                color="secondary"
                                sx={{
                                    px: 1,
                                    textDecoration: 'underline',

                                    color: 'common.text_1',
                                }}
                            />

                            {/* Terms of use button
						    ========================================= */}
                            <BaseButton
                                label="Terms of Use"
                                onClick={(evt) => setIsTermsOfServiceDialogOpen(true)}
                                size="small"
                                color="secondary"
                                sx={{
                                    px: 1,
                                    textDecoration: 'underline',

                                    color: 'common.text_1',
                                }}
                            />
                        </Stack>
                    )}

                    {/* Copyright statement
					    ========================================= */}
                    <Typography fontSize={12} fontWeight={400} color="common.text_1">
                        Copyright © 2022 SNA Toolbox Pty Ltd. All Rights Reserved.
                    </Typography>
                </Stack>
            </Box>

            {/* Dialogs
				========================================= */}
            {(isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen) && (
                <StyledDialog
                    open={isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen}
                    onClose={() =>
                        isPrivacyPolicyDialogOpen
                            ? setIsPrivacyPolicyDialogOpen(false)
                            : setIsTermsOfServiceDialogOpen(false)
                    }
                    fullWidth
                    maxWidth="lg"
                >
                    {isPrivacyPolicyDialogOpen ? (
                        <PrivacyPolicyDialog onClose={() => setIsPrivacyPolicyDialogOpen(false)} />
                    ) : isTermsOfServiceDialogOpen ? (
                        <TermsOfServiceDialog onClose={() => setIsTermsOfServiceDialogOpen(false)} />
                    ) : (
                        false
                    )}
                </StyledDialog>
            )}
        </Box>
    )
}

export default SignInLayout
