import { Box, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro'
import { DatePicker } from '@mui/x-date-pickers'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import StyledDialog from 'components/dialog/StyledDialog'
import ButtonSelectMenu from 'features/button-select-menu/ButtonSelectMenu'
import { PRIORITY_OPTIONS } from 'features/nodes/NodeActions'
import { InsightMessageType } from 'features/report-designer/types/reportDesigner.types'
import { AssignedUsers } from 'features/survey-rules/SurveyRulesItem'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { NodeActionAccountType } from 'services/NodeActionService'

type CreateTaskDialogType = {
    isOpen: boolean
    onClose: () => void
    inisght: InsightMessageType
    actionIndex: number
    taskId: string
    nodes: {
        id: string
        [key: string]: any
    }[]
    columns: GridColDef[]
    sortModel: GridSortModel | undefined
}

const CreateTaskDialog = ({
    isOpen,
    onClose,
    inisght,
    actionIndex,
    taskId,
    nodes,
    columns,
    sortModel,
}: CreateTaskDialogType) => {
    const [task, setTask] = useState<{
        title: string
        description: string
        nodes: string[]
        priority: 'Low' | 'Medium' | 'High' | 'Critical'
        assignedTo: number[]
        dueDate: string | null
    }>({
        title: '',
        description: '',
        nodes: [],
        priority: 'Low',
        assignedTo: [],
        dueDate: null,
    })

    useEffect(() => {
        if (isOpen && inisght.actions[actionIndex]) {
            setTask({
                title: inisght.actions[actionIndex].title,
                description: `Issue: ${inisght.text}
${inisght.description && inisght.description.length > 0 ? `Issue Description: ${inisght.description}` : ''}
Task Description: ${inisght.actions[actionIndex].description}`,
                nodes: [],
                priority: 'Low',
                assignedTo: [],
                dueDate: null,
            })
        } else {
            setTask({
                title: '',
                description: '',
                nodes: [],
                priority: 'Low',
                assignedTo: [],
                dueDate: null,
            })
        }
    }, [isOpen])

    const updateTask = (newTask: Partial<typeof task>) => {
        setTask((prevTask) => {
            return {
                ...prevTask,
                ...newTask,
            }
        })
    }

    return (
        <StyledDialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>{inisght.actions[actionIndex]?.title}</DialogTitle>
            <DialogContent sx={{ height: '80vh', overflow: 'hidden' }}>
                {task && (
                    <Stack direction="row" gap={2} sx={{ height: '100%', overflow: 'hidden' }}>
                        {/* Table */}
                        <Box
                            sx={{
                                height: '100%',
                                flex: 0.61,
                                overflow: 'auto',
                            }}
                        >
                            <StyledDataGrid
                                density="compact"
                                hasExport={false}
                                hasColumnSelector={false}
                                checkboxSelection={true}
                                rows={nodes}
                                columns={columns}
                                sortModel={sortModel}
                            />
                        </Box>
                        {/* Details */}
                        <Box sx={{ flex: 0.39, overflow: 'auto' }} className="u-scrollbar">
                            <Stack width="100%" gap={2}>
                                <Typography variant="h6">Details</Typography>
                                <BaseFilledTextField label="Title" value={task.title} />
                                <BaseFilledTextField label="Description" value={task.description} multiline rows={4} />
                                <Stack direction="row" gap={2} alignItems="center">
                                    <Typography>Assigned To</Typography>
                                    <AssignedUsers
                                        size={40}
                                        accounts={[
                                            {
                                                primaryKey: 1,
                                                role: {
                                                    primaryKey: 1,
                                                    roleName: 'admin',
                                                },
                                                account: {
                                                    primaryKey: 1,
                                                    name: 'admin',
                                                    email: 'test',
                                                    avatar: '',
                                                    isActive: true,
                                                },
                                            },
                                        ]}
                                        onAddAccount={(accountId) => {
                                            updateTask({ assignedTo: [...task.assignedTo, accountId] })
                                        }}
                                        onRemoveAccount={(accountId) => {
                                            updateTask({ assignedTo: task.assignedTo.filter((id) => id !== accountId) })
                                        }}
                                        selectedAccounts={task.assignedTo}
                                    />
                                </Stack>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <Typography>Priority</Typography>
                                    <ButtonSelectMenu
                                        value={task.priority}
                                        onChange={(priority) => updateTask({ priority })}
                                        options={PRIORITY_OPTIONS}
                                        tooltip="Priority"
                                    />
                                </Stack>
                                {/* Due Date */}
                                <DatePicker
                                    label="Due Date"
                                    value={task.dueDate ? moment(task.dueDate) : null}
                                    slotProps={{
                                        field: {
                                            clearable: true,
                                            onClear: () => {
                                                updateTask({ dueDate: null })
                                            },
                                        },
                                    }}
                                    onChange={(date) => {
                                        if (date) {
                                            const formattedDate = moment(date)
                                                .hour(23)
                                                .minute(59)
                                                .second(0)
                                                .utc()
                                                .toISOString()
                                            updateTask({ dueDate: formattedDate })
                                        } else {
                                            updateTask({ dueDate: null })
                                        }
                                    }}
                                />
                            </Stack>
                        </Box>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose}>Cancel</BaseButton>
                <BaseButton
                    onClick={() => {
                        onClose()
                    }}
                    color="primary"
                >
                    Create
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default CreateTaskDialog
