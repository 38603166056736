//* ======= Libraries
import React, { useState, useEffect, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { v4 as uuidv4 } from 'uuid'
import { Stack, Box, Typography, IconButton, Tooltip, Divider } from '@mui/material'
//* ======= Components and features
import TableDefinitionColumnItem, {
    TableDefinitionColumnItemDragTypeValue,
} from 'features/report-designer/widgets/table-widget/views/TableDefinitionColumnItem'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
//* ======= Custom logic
import {
    TableDefinitionColumn,
    TableDefinitionModeGraph,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'

type TableDefinitionGraphSettingsProps = {
    definition: TableDefinitionModeGraph
    fieldOptions: FlexibleSelectOptionType[]
    onUpdate: (data: TableDefinitionModeGraph) => void
    disabled: boolean
}

function TableDefinitionGraphSettings({
    definition,
    fieldOptions,
    onUpdate,
    disabled,
}: TableDefinitionGraphSettingsProps) {
    // We need a clone of columns list to handle drag&drop actions internally (on item move)
    // and then update the actual store state only once (on item move end).
    const [columnsListClone, setColumnsListClone] = useState<TableDefinitionColumn[]>([])

    // Sync columns list clone with the definition's list on change.
    useEffect(() => {
        setColumnsListClone(definition.columns)
    }, [definition.columns])

    /* =========================================
     * Columns list methods
     */

    const addColumn = () => {
        onUpdate({
            ...definition,
            columns: [
                ...definition.columns,
                {
                    id: uuidv4(),
                    field: null,
                    advanced: {
                        dataType: 'string',
                        valueMapping: {
                            isEnabled: false,
                            categories: [],
                        },
                    },
                    // We purposefully don't add an "aggregation" property because this data definition "mode" doesn't need one
                    // and also because the presence of that property will show a select component in "DefinitionColumnItem".
                },
            ],
        })
    }

    const updateColumn = (data: TableDefinitionColumn) => {
        onUpdate({
            ...definition,
            columns: definition.columns.map((_column) => {
                if (_column.id === data.id) {
                    return { ...data }
                } else {
                    return _column
                }
            }),
        })
    }

    const removeColumn = (columnId: TableDefinitionColumn['id']) => {
        onUpdate({
            ...definition,
            columns: definition.columns.filter((_column) => _column.id !== columnId),
        })
    }

    /* =========================================
     * Columns list items drag and drop
     */

    // Helps with visual drop cursor style when dragging list items. Doesn't do anything else.
    const [, dropTarget] = useDrop(() => ({ accept: TableDefinitionColumnItemDragTypeValue }))

    const findColumnCloneIndexById = useCallback(
        (id: TableDefinitionColumn['id']) => {
            return definition.columns.findIndex((_column) => _column.id === id)
        },
        [definition.columns]
    )

    // Update columns list clone with the new positions.
    const onColumnMove = useCallback(
        (columnId: TableDefinitionColumn['id'], newIndex: number) => {
            const targetIndex = findColumnCloneIndexById(columnId)

            const targetColumn = columnsListClone[targetIndex]

            if (targetColumn !== undefined) {
                const updatedColumns = [...columnsListClone]
                updatedColumns.splice(targetIndex, 1)
                updatedColumns.splice(newIndex, 0, targetColumn)

                setColumnsListClone(updatedColumns)
            }
        },
        [findColumnCloneIndexById, columnsListClone]
    )

    // Update store state with the updated column positions.
    const onColumnMoveEnd = () => {
        onUpdate({
            ...definition,
            columns: [...columnsListClone],
        })
    }

    return (
        <Stack gap={2}>
            {/* Columns header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                <Typography
                    textAlign="left"
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    Columns:
                </Typography>

                {/* Add column button
                    ========================================= */}
                <Tooltip title="Add Column">
                    {/* Intermediary element to capture Tooltip on button's disabled state. */}
                    <Box>
                        <IconButton onClick={(evt) => addColumn()} disabled={disabled}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Stack>

            {/* Columns list
                ========================================= */}
            <Stack ref={dropTarget} gap={1.5}>
                {columnsListClone.length > 0 ? (
                    columnsListClone.map((_column, idx, array) => (
                        <React.Fragment key={_column.id}>
                            <TableDefinitionColumnItem
                                data={_column}
                                fieldOptions={fieldOptions}
                                onUpdate={updateColumn}
                                onRemove={removeColumn}
                                getPositionIndex={findColumnCloneIndexById}
                                onMove={onColumnMove}
                                onMoveEnd={onColumnMoveEnd}
                                disabled={disabled}
                            />

                            {/* Divider
                                ========================================= */}
                            {idx !== array.length - 1 && <Divider />}
                        </React.Fragment>
                    ))
                ) : (
                    /*  Empty list message
                        ========================================= */
                    <Typography fontSize={14} textAlign="center">
                        (No columns defined.)
                    </Typography>
                )}
            </Stack>
        </Stack>
    )
}

export default TableDefinitionGraphSettings
