import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useTheme } from '@mui/material'

type LoadingSkeletonThemeProps = {
    children: React.ReactNode
}

function LoadingSkeletonTheme({ children }: LoadingSkeletonThemeProps) {
    const muiTheme = useTheme()

    return (
        <SkeletonTheme
            baseColor={
                muiTheme.palette.mode === 'light'
                    ? muiTheme.palette.common.bg_3
                    : muiTheme.palette.common.bg_2
            }
            highlightColor={
                muiTheme.palette.mode === 'light'
                    ? muiTheme.palette.common.bg_4
                    : muiTheme.palette.common.bg_3
            }
            inline
        >
            {children}
        </SkeletonTheme>
    )
}

export default LoadingSkeletonTheme
