//* ======= Libraries
import { useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles
import { useParams } from 'react-router-dom'
import { SendSurveyReminderService } from 'services/SurveyService'
import { toast } from 'react-toastify'

interface ISendReminderSurveyDialog {
    onClose?: () => void
}

function SendReminderSurveyDialog({ onClose }: ISendReminderSurveyDialog) {
    const { pid, sid } = useParams()
    const [verificationText, setVerificationText] = useState('')

    const publishSurvey = () => {
        if (pid != null && sid != null && verificationText.toLocaleLowerCase() == 'send') {
            SendSurveyReminderService(pid, sid).then((res) => {
                if (res.success) {
                    toast.success('Reminder Emails Sent!')
                    onClose && onClose()
                }
            })
        }
    }
    return (
        <Stack gap={2} minWidth={0} py={5} px={6} width={752}>
            {/* Title
				========================================= */}
            <Typography alignSelf="flex-start" fontSize={36} fontWeight={400} color="common.text_1">
                Send Survey Reminders
            </Typography>

            <Typography fontSize={16} fontWeight={400} color="common.text_1">
                Are you sure you want to send reminder email for this survey?
            </Typography>

            <Typography paragraph fontSize={16} fontWeight={600} color="common.text_1" marginBottom={1}>
                By pressing the send button, a reminder email or sms, based on the configured settings, will
                be sent to all the participants who did not complete their survey yet. Are you sure you want
                to perform this action? Write{' '}
                <Box component="span" color="primary.main">
                    SEND
                </Box>{' '}
                in the textbox below to proceed with the action.
            </Typography>

            {/* Input
				========================================= */}
            <BaseFilledTextField
                hiddenLabel
                fullWidth
                value={verificationText}
                onChange={(e) => setVerificationText(e.target.value)}
                sx={{
                    alignSelf: 'stretch',
                    marginBottom: 2,
                }}
            />

            {/* Controls
				========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                {/* Cancel button
					========================================= */}
                <BaseButton
                    label="Cancel"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    size="large"
                    width="186px"
                />

                {/* Publish button
					========================================= */}
                <BaseButton
                    label="Send"
                    disabled={verificationText.toLocaleLowerCase() !== 'send'}
                    onClick={publishSurvey}
                    variant="contained"
                    size="large"
                    width="186px"
                />
            </Stack>
        </Stack>
    )
}

export default SendReminderSurveyDialog
