//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { Stack, Box, Typography, IconButton, RadioGroup, FormControlLabel, Radio, Divider } from '@mui/material'
//* ======= Components and features
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import TableDefinitionEachRowSettings from 'features/report-designer/widgets/table-widget/views/TableDefinitionEachRowSettings'
import TableDefinitionTopKSettings from 'features/report-designer/widgets/table-widget/views/TableDefinitionTopKSettings'
import TableDefinitionAggregationSettings from 'features/report-designer/widgets/table-widget/views/TableDefinitionAggregationSettings'
import TableDefinitionTiesSettings from 'features/report-designer/widgets/table-widget/views/TableDefinitionTiesSettings'
import TableWidgetGridView from 'features/report-designer/widgets/table-widget/views/TableWidgetGridView'
import TableWidgetCardView from 'features/report-designer/widgets/table-widget/views/TableWidgetCardView'
import StyledDialog from 'components/dialog/StyledDialog'
import { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    TableWidgetType,
    SelectedDataSourceType,
    ReportDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import { getDataSourceData, getDataSourceFields } from 'features/report-designer/helpers/reportDesigner.helper'
import {
    getTableAttributes,
    getTableConfigViewModeInitialValues,
    getTableDefinitionModeInitialValues,
    parseTableData,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.helper'
import {
    TableConfigViewMode,
    TableConfigGridView,
    TableConfigCardView,
    TableDefinition,
    TableDefinitionMode,
    TableConfigChipView,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import TableDefinitionGraphSettings from './views/TableDefinitionGraphSettings'
import { formatLabel } from 'features/network-viz/helpers/DataFormatter'
import TableWidgetChipView from './views/TableWidgetChipView'

const availableDataDefinitionModeOptions: BaseSelectWithLabelOptionType = [
    {
        label: 'Each Row',
        value: 'each-row',
    },
    {
        label: 'Top K',
        value: 'top-k',
    },
    {
        label: 'Aggregation',
        value: 'aggregation',
    },
]

type Props = {
    isOpen: boolean
    data: TableWidgetType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onConfirm: (data: Pick<TableWidgetType, 'selectedDataSource' | 'dataDefinition' | 'config'>) => void
    onClose: () => void
}

function TableDataConfigDialog({ isOpen = false, data, dataSources, onConfirm, onClose = () => undefined }: Props) {
    const [tableData, setTableData] = useState<TableWidgetType>(data)
    // This is a duplicate of "tableData" just to handle dialog's internal table.
    // Maybe it can be changed to another, more specific type.
    const [tableDemoState, setTableDemoState] = useState<TableWidgetType>(tableData)
    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])

    // Prevent user from applying any changes if the selected data configurations are not valid
    const isTableConfigValid = useMemo(() => {
        if (tableData.selectedDataSource === null || tableData.selectedDataSource.id === -1) {
            return false
        }

        switch (tableData.dataDefinition.mode) {
            case 'graph':
                if (tableData.dataDefinition.columns.length === 0) {
                    return false
                }
                for (let _column of tableData.dataDefinition.columns) {
                    if (_column.field === null || _column.field.field.trim() === '') {
                        return false
                    }
                }

                break
            case 'each-row':
                if (
                    tableData.dataDefinition.idField === null ||
                    tableData.dataDefinition.idField.field.trim() === '' ||
                    tableData.dataDefinition.header.field === null ||
                    tableData.dataDefinition.header.field.field.trim() === '' ||
                    tableData.dataDefinition.columns.length === 0
                ) {
                    return false
                }

                for (let _column of tableData.dataDefinition.columns) {
                    if (_column.field === null || _column.field.field.trim() === '') {
                        return false
                    }
                }

                break

            case 'top-k':
                if (
                    tableData.dataDefinition.idField === null ||
                    tableData.dataDefinition.idField.field.trim() === '' ||
                    tableData.dataDefinition.value.field === null ||
                    tableData.dataDefinition.value.field.field.trim() === ''
                ) {
                    return false
                }

                break

            case 'aggregation':
                if (
                    tableData.dataDefinition.groupField === null ||
                    tableData.dataDefinition.groupField.field.trim() === '' ||
                    tableData.dataDefinition.columns.length === 0
                ) {
                    return false
                }

                for (let _column of tableData.dataDefinition.columns) {
                    if (
                        _column.field === null ||
                        _column.field.field.trim() === '' ||
                        _column.aggregation === undefined
                    ) {
                        return false
                    }
                }

                break

            case 'ties':
                if (
                    tableData.dataDefinition.nodesLabelField === null ||
                    tableData.dataDefinition.nodesLabelField.field.trim() === ''
                ) {
                    return false
                }

                break

            default:
                break
        }

        return true
    }, [tableData])

    // After selected data source changes, update and show its fields.
    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, tableData.selectedDataSource))
    }, [tableData.selectedDataSource])

    // Update dialog table's parsed data after tableData state changes.
    useEffect(() => {
        if (isTableConfigValid) {
            setTableDemoState((prevState) => ({
                ...prevState,
                ...tableData,
                parsedData: parseTableData({
                    filteredData:
                        getDataSourceData(
                            dataSources,
                            tableData.selectedDataSource,
                            getTableAttributes(tableData.dataDefinition),
                            undefined,
                            'compareWith' in tableData.dataDefinition ? tableData.dataDefinition.compareWith : null,
                            {
                                kind: 'table',
                                details: tableData,
                            }
                        ) || [],
                    definition: tableData.dataDefinition,
                }),
            }))
        } else {
            setTableDemoState((prevState) => ({
                ...prevState,
                parsedData: null,
            }))
        }
    }, [tableData, isTableConfigValid])

    const updateSelectedDataSource = (selectedDataSource: SelectedDataSourceType) => {
        let mode: TableDefinitionMode = tableData.dataDefinition.mode

        // Determine the mode based on the selected data source type and the current table definition mode.
        // if the selected data source is either "graph" or "ties", we need to change the table definition mode to "graph" and "ties" respectively.
        // Otherwise, if the current table definition mode is either "ties" or "graph", we need to change the table definition mode to "each-row".
        if (selectedDataSource.type === 'graph') {
            mode = 'graph'
        } else if (selectedDataSource.type === 'ties') {
            mode = 'ties'
        } else if (tableData.dataDefinition.mode === 'ties' || tableData.dataDefinition.mode === 'graph') {
            mode = 'each-row'
        }

        // Get default values based on the determined mode
        const initialDefinitionValues = getTableDefinitionModeInitialValues(mode)

        if (initialDefinitionValues?.mode === 'ties') {
            const dataSource = dataSources.find((ds) => ds.id === selectedDataSource.id)
            if (dataSource && dataSource.mode === 'network') {
                const edgeStyles = dataSource.presets[selectedDataSource.preset ?? 'view'].edgeStyle
                const relationships = Object.keys(edgeStyles).filter((x) => x !== 'default')
                for (let relationship of relationships) {
                    initialDefinitionValues.relations.push({
                        name: relationship,
                        isEnabled: true,
                        nodesBackgroundColor: edgeStyles[relationship].normal.color,
                        label: formatLabel(relationship),
                    })
                }
            }
        }

        if (initialDefinitionValues !== null) {
            setTableData((prevState) => {
                // If the selected data source was changed (and not its filters or other properties),
                // we need to reset some properties to their initial values:
                let hasDataSourceChanged = false
                if (
                    prevState.selectedDataSource === null ||
                    prevState.selectedDataSource.id !== selectedDataSource.id ||
                    prevState.selectedDataSource.type !== selectedDataSource.type
                ) {
                    hasDataSourceChanged = true
                }

                // We need to update data definition to its initial values if data source is changed
                if (hasDataSourceChanged) {
                    return {
                        ...prevState,
                        selectedDataSource: {
                            ...prevState.selectedDataSource,
                            ...selectedDataSource,
                        },
                        dataDefinition: {
                            ...prevState.dataDefinition,
                            ...initialDefinitionValues,
                        },
                        // Any time the data structure changes in a way that needs new data parsing, we have to clear selected row as well.
                        selectedRowId: null,
                    }
                }
                // Otherwise, we simply update the properties of the selected data source
                else {
                    return {
                        ...prevState,
                        selectedDataSource: {
                            ...prevState.selectedDataSource,
                            ...selectedDataSource,
                        },
                        // Any time the data structure changes in a way that needs new data parsing, we have to clear selected row as well.
                        selectedRowId: null,
                    }
                }
            })
        }
    }

    const onViewModeChange = (viewMode: TableWidgetType['config']['viewMode']) => {
        const initialConfigValues = getTableConfigViewModeInitialValues(viewMode)

        if (initialConfigValues !== null) {
            // TODO:
            // Changing view mode inside this dialog is "merging" the previous config with the initial values of the new view mode.
            // This is somewhat desired? because the user configures each view mode's options outside this dialog, so on the inside,
            // even if we are changing the config, it probably should keep the user's settings from outside and not over-write with the
            // default/initial values.
            setTableData((prevState) => {
                let updatedTableConfig: TableWidgetType['config'] = {
                    ...prevState.config,
                }

                if (viewMode === 'grid') {
                    updatedTableConfig = {
                        ...(initialConfigValues as TableConfigGridView),
                        ...prevState.config,
                        viewMode: viewMode,
                    }
                } else if (viewMode === 'card') {
                    updatedTableConfig = {
                        ...(initialConfigValues as TableConfigCardView),
                        ...prevState.config,
                        viewMode: viewMode,
                    }
                } else if (viewMode === 'chip') {
                    updatedTableConfig = {
                        ...(initialConfigValues as TableConfigChipView),
                        ...prevState.config,
                        viewMode: viewMode,
                    }
                }

                return {
                    ...prevState,
                    config: structuredClone(updatedTableConfig),
                }
            })
        }
    }

    const onDefinitionModeChange = (mode: TableWidgetType['dataDefinition']['mode']) => {
        const initialDefinitionValues = getTableDefinitionModeInitialValues(mode)

        if (initialDefinitionValues !== null) {
            setTableData((prevState) => ({
                ...prevState,
                dataDefinition: {
                    ...initialDefinitionValues,
                },
                // Any time the data structure changes in a way that needs new data parsing, we have to clear selected row as well.
                selectedRowId: null,
            }))
        }
    }

    const updateDefinition = (data: TableDefinition) => {
        setTableData((prevState) => ({
            ...prevState,
            dataDefinition: structuredClone(data),
        }))
    }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',

                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),

                    padding: theme.spacing(2, 3),

                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Table Widget Data Configuration
                </Typography>

                <IconButton
                    onClick={(evt) => onClose()}
                    size="small"
                    sx={{
                        gridColumn: 3,
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 1 auto',

                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    placeContent: 'stretch',
                    columnGap: 2,

                    height: '70vh',
                    padding: theme.spacing(3),
                })}
            >
                {/* Content wrapper
                    ========================================= */}
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        paddingRight: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                        overflowY: 'auto',

                        borderRight: `1px solid ${theme.palette.common.border_3}`,
                    })}
                    className="u-scrollbar"
                >
                    {/* Data source select
                        ========================================= */}
                    <ReportDataSourceSelector
                        dataSources={dataSources}
                        onChange={updateSelectedDataSource}
                        selectedDataSource={tableData.selectedDataSource}
                        shouldIncludeTies={true}
                    />

                    <Divider />

                    {/* Definition mode select
                        ========================================= */}
                    {tableData.selectedDataSource?.type === 'nodes' && (
                        <>
                            <BaseSelectWithLabel
                                label="Table mode"
                                options={availableDataDefinitionModeOptions}
                                value={tableData.dataDefinition.mode}
                                onChange={(value) => onDefinitionModeChange(value)}
                                disabled={dataSourceFields.length === 0}
                                required
                                fullWidth
                                size="small"
                            />

                            <Divider />
                        </>
                    )}

                    {/* Definition settings
                        ========================================= */}
                    {tableData.dataDefinition.mode === 'each-row' ? (
                        <TableDefinitionEachRowSettings
                            dataSources={dataSources}
                            selectedDataSource={tableData.selectedDataSource}
                            definition={tableData.dataDefinition}
                            fieldOptions={dataSourceFields}
                            onUpdate={updateDefinition}
                            disabled={dataSourceFields.length === 0}
                        />
                    ) : tableData.dataDefinition.mode === 'top-k' ? (
                        <TableDefinitionTopKSettings
                            definition={tableData.dataDefinition}
                            fieldOptions={dataSourceFields}
                            onUpdate={updateDefinition}
                            disabled={dataSourceFields.length === 0}
                        />
                    ) : tableData.dataDefinition.mode === 'aggregation' ? (
                        <TableDefinitionAggregationSettings
                            definition={tableData.dataDefinition}
                            fieldOptions={dataSourceFields}
                            onUpdate={updateDefinition}
                            disabled={dataSourceFields.length === 0}
                        />
                    ) : tableData.dataDefinition.mode === 'ties' ? (
                        <TableDefinitionTiesSettings
                            definition={tableData.dataDefinition}
                            fieldOptions={dataSourceFields}
                            onUpdate={updateDefinition}
                            disabled={dataSourceFields.length === 0}
                        />
                    ) : tableData.dataDefinition.mode === 'graph' ? (
                        <TableDefinitionGraphSettings
                            definition={tableData.dataDefinition}
                            fieldOptions={dataSourceFields}
                            onUpdate={updateDefinition}
                            disabled={dataSourceFields.length === 0}
                        />
                    ) : (
                        false
                    )}
                </Stack>

                {/* Table wrapper
                    ========================================= */}
                {tableDemoState.parsedData === null || tableDemoState.parsedData.rows.length === 0 ? (
                    /*  Initial/Empty data view
                        ========================================= */
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: '100%',
                        }}
                    >
                        {tableDemoState.config.emptyDataMessage}
                    </Stack>
                ) : (
                    /*  Main view
                        ========================================= */
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: 0,
                            height: '100%',
                            minHeight: 0,
                        }}
                    >
                        {/* View mode radios
                            ========================================= */}
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={6}
                            sx={{
                                flex: '0 0 auto',
                            }}
                        >
                            <Typography
                                component="label"
                                id="table-view-mode-radio-group"
                                sx={{
                                    flex: '0 0 auto',
                                }}
                            >
                                View Mode:
                            </Typography>

                            <RadioGroup
                                row
                                aria-labelledby="table-view-mode-radio-group"
                                value={tableData.config.viewMode}
                                onChange={(evt) => onViewModeChange(evt.target.value as TableConfigViewMode)}
                                sx={{
                                    flex: '1 0 0',
                                }}
                            >
                                <FormControlLabel value="grid" control={<Radio />} label="Grid View" />
                                <FormControlLabel value="card" control={<Radio />} label="Card View" />
                                <FormControlLabel value="chip" control={<Radio />} label="Chip View" />
                            </RadioGroup>
                        </Stack>

                        {/* Table views
                            ========================================= */}
                        <Box
                            sx={{
                                flex: '1 0 0',

                                minWidth: 0,
                                minHeight: 0,
                            }}
                        >
                            {tableDemoState.config.viewMode === 'grid' ? (
                                <TableWidgetGridView
                                    parsedData={tableDemoState.parsedData}
                                    config={tableDemoState.config}
                                    selectedRowId={tableDemoState.selectedRowId}
                                    isActive={true}
                                    fontSizeScaleFactor={1}
                                    viewMode="design"
                                />
                            ) : tableDemoState.config.viewMode === 'card' ? (
                                <TableWidgetCardView
                                    parsedData={tableDemoState.parsedData}
                                    config={tableDemoState.config}
                                    selectedRowId={tableDemoState.selectedRowId}
                                    isActive={true}
                                    fontSizeScaleFactor={1}
                                />
                            ) : tableDemoState.config.viewMode === 'chip' ? (
                                <TableWidgetChipView
                                    viewMode="design"
                                    parsedData={tableDemoState.parsedData}
                                    config={tableDemoState.config}
                                    selectedRowId={tableDemoState.selectedRowId}
                                    isActive={true}
                                    fontSizeScaleFactor={1}
                                />
                            ) : (
                                false
                            )}
                        </Box>
                    </Stack>
                )}
            </Box>

            {/* Footer
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    padding: theme.spacing(2, 3),
                })}
            >
                <BaseButton
                    label="Discard"
                    onClick={(evt) => onClose()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Apply"
                    onClick={(evt) => onConfirm(tableData)}
                    disabled={isTableConfigValid === false}
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </StyledDialog>
    )
}

export default TableDataConfigDialog
