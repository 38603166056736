import { useState, useEffect, useMemo } from 'react'
import { AddEdgeModal } from './NetworkAddEdgeModal'
import { NetworkEdgeSettingsEdit } from './NetworkEdgeSettingsEdit'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import EdgeArrowShapeSelector from 'components/widgets/EdgeArrowShapeSelector'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { v4 as uuidv4 } from 'uuid'
import {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import { EdgeArrowType, FilterItemStringType } from 'features/network-viz/types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import { applyFilter } from '../filter/NetworkFilter.helpers'
import { Box, DialogTitle, Tooltip } from '@mui/material'
import StyledDialog from 'components/dialog/StyledDialog'
import DifferenceDialog from 'features/network-viz/network-viz-settings/edge/DifferenceDialog'
import IntersectionDialog from 'features/network-viz/network-viz-settings/edge/IntersectionDialog'
import GridOnIcon from '@mui/icons-material/GridOn'
import { EditEdgesModal } from './NetworkEditEdgesModal'
import FilterSenderReceiverDialog from './FilterSenderReceiverDialog'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'

export interface EdgesSettingsProps {}

export default function NetworkEdgesSettings() {
    const networkContext = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [groupForEdit, setGroupForEdit] = useState<string>('')
    const [showAddEdgeModal, setShowAddEdgeModal] = useState<boolean>(false)
    const [showEdgeModal, setShowEdgeModal] = useState<boolean>(false)
    const [_edgeGroupBy, setEdgeGroupBy] = useState<string | null>(networkContext.edgeGroupBy)
    const [manipulationDialog, setManipulationDialog] = useState<false | 'intersection' | 'difference' | 'filter'>(
        false
    )

    const handleCloseManipulationDialog = () => setManipulationDialog(false)

    const handleOpenManipulationDialog = (type: 'intersection' | 'difference' | 'filter') => {
        setManipulationDialog(type)
    }

    const [localSymbolSetting, setLocalSymbolSetting] = useState<EdgeArrowType>(networkContext.edgeArrowType)

    const nonVisibleEdges = useMemo(() => {
        const { edgeStyle, filters, edgeGroupBy } = networkContext
        const filterItems = filters.filter(
            (f) =>
                'type' in f &&
                f.attribute.source === 'edge' &&
                f.attribute.field === edgeGroupBy &&
                f.filterMode === 'hide'
        )
        if (filterItems.length === 0) return []
        const groups = Object.keys(edgeStyle).filter((k) => k !== 'default')

        const result = []

        for (let group of groups) {
            let isVisible = false
            for (let filterItem of filterItems) {
                if (
                    'type' in filterItem &&
                    filterItem.type === 'string' &&
                    filterItem.values.includes(group.toLowerCase())
                ) {
                    isVisible = true
                    break
                }
            }
            if (!isVisible) {
                result.push(group)
            }
        }

        return result
    }, [networkContext.filters])

    const toggleVisibility = (category: string) => async () => {
        const { edgeStyle, filters, edgeGroupBy } = networkContext
        //!
        //ToDo fix this
        //if it's not visible, then make it visible
        let filterOutcome: Pick<
            NetworkVizContextType,
            'nodeRenders' | 'edgeRenders' | 'analytics' | 'nodeAdjacentList'
        > | null = null

        const _filters = [...filters]

        if (nonVisibleEdges.includes(category)) {
            //find first filter
            const index = filters.findIndex(
                (f) =>
                    'type' in f &&
                    f.attribute.source === 'edge' &&
                    f.attribute.field === edgeGroupBy &&
                    f.filterMode === 'hide' &&
                    f.type === 'string'
            )
            const _filter = _filters[index] as FilterItemStringType
            _filters[index] = {
                ..._filter,
                values: [..._filter.values, category.toLowerCase()],
            }

            filterOutcome = await applyFilter(networkContext, _filters)
        } else {
            const filterItems = filters.filter(
                (f) =>
                    'type' in f &&
                    f.attribute.source === 'edge' &&
                    f.attribute.field === edgeGroupBy &&
                    f.type === 'string'
            )
            //if any filter item, then make sure the cateogry is not selected
            if (filterItems.length > 0) {
                for (let filterItem of filterItems) {
                    // filterItem.selectedFields = filterItem.selectedFields?.filter((v) => v != category)
                    ;(filterItem as FilterItemStringType).values = (filterItem as FilterItemStringType).values.filter(
                        (x) => x !== category.toLowerCase()
                    )
                }
                filterOutcome = await applyFilter(networkContext, _filters)
            }
            //otherwise add a filter option where everything is selected expect the category
            else {
                const allGroups = Object.keys(edgeStyle)
                    .filter((k) => k !== 'default')
                    .map((x) => x.toLowerCase())
                const groups = Object.keys(edgeStyle)
                    .filter((k) => k !== 'default' && k !== category)
                    .map((x) => x.toLowerCase())
                _filters.push({
                    id: uuidv4(),
                    attribute: {
                        source: 'edge',
                        field: _edgeGroupBy || '',
                    },
                    type: 'string',
                    values: groups,
                    range: allGroups,
                    filterMode: 'hide',
                })
                filterOutcome = await applyFilter(networkContext, _filters, false)
            }
        }

        if (filterOutcome !== null) {
            dispatchContext({
                type: 'FILTER_CHANGE',
                payload: {
                    edgeRenders: filterOutcome.edgeRenders,
                    nodeRenders: filterOutcome.nodeRenders,
                    filters: _filters,
                    nodeAdjacentList: filterOutcome.nodeAdjacentList,
                    analytics: filterOutcome.analytics,
                },
            })
        }
    }

    const applyEdgeGroupBy = (g: string | null) => {
        const { edgeStyle, edgeRenders, edges, edgeDataSchema } = networkContext
        const groupBy = g === '' ? null : g
        const oldGroupBy = _edgeGroupBy
        setEdgeGroupBy(groupBy)
        const worker = new WebWorker('workers/network/group-by-edges.js')
        worker
            .run({ edgeRenders, edges, groupBy, edgeStyle, dataSchema: edgeDataSchema.fields })
            .then((res: any) => {
                const { edgeRenders, edgeStyle, edgeLegend } = res
                dispatchContext({
                    type: 'EDGE_GROUP_BY_EDIT',
                    payload: {
                        edgeGroupBy: groupBy,
                        edgeLegend,
                        edgeStyle,
                        edgeRenders,
                    },
                })
            })
            .catch((e) => {
                //revert the changes
                setEdgeGroupBy(oldGroupBy)
                //ToDo
            })
            .finally(() => {})
    }

    return groupForEdit === '' ? (
        /*  Main view
            ========================================= */
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <BaseButton onClick={(evt) => setShowEdgeModal(true)} startIcon={<GridOnIcon />}>
                    Edges
                </BaseButton>

                <BaseButton
                    label="Add Network"
                    onClick={(evt) => {
                        setShowAddEdgeModal(true)
                    }}
                    startIcon={<AddIcon />}
                />
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={1} minWidth={0}>
                {/* Arrow size and shape
                    ========================================= */}
                {networkContext.networkVizKind === '2d+' && (
                    <>
                        <Stack gap={1}>
                            <Typography fontSize={14}>Arrow size</Typography>

                            {/* Arrow size
                        ========================================= */}
                            <ValueSliderPicker
                                max={30}
                                min={1}
                                step={0.1}
                                value={localSymbolSetting.size}
                                onChange={(value) =>
                                    setLocalSymbolSetting((prevState) => ({ ...prevState, size: value }))
                                }
                            />

                            {/* Apply button
                        ========================================= */}
                            <BaseButton
                                label="Apply"
                                onClick={(evt) =>
                                    dispatchContext({
                                        type: 'EDGE_ARROW_SETTINGS_EDIT',
                                        payload: localSymbolSetting,
                                    })
                                }
                                variant="outlined"
                                disableElevation
                            />
                        </Stack>

                        <Divider
                            sx={{
                                marginY: 1,
                            }}
                        />
                    </>
                )}

                <StyledSwitch
                    label="Only reciprocated edges"
                    checked={networkContext.onlyShowReciprocatedEdges}
                    onChange={(evt, checked) =>
                        dispatchContext({
                            type: 'EDGE_ONLY_SHOW_RECIPOCATED_UPDATE',
                            payload: checked,
                        })
                    }
                />

                <StyledSwitch
                    label="Highlight reciprocated edges"
                    checked={networkContext.highlightReciprocatedEdges}
                    onChange={(evt, checked) =>
                        dispatchContext({
                            type: 'EDGE_HIGHLIGHT_RECIPOCATED_UPDATE',
                            payload: checked,
                        })
                    }
                />

                <StyledSwitch
                    label="Dim one-way edges"
                    checked={networkContext.dimOneWayEdges}
                    onChange={(evt, checked) =>
                        dispatchContext({
                            type: 'EDGE_DIM_ONE_WAY_UPDATE',
                            payload: checked,
                        })
                    }
                />

                {/* Set Manipulation
                    ========================================= */}
                <Typography fontSize={14} fontWeight={500}>
                    Set Operations
                </Typography>
                <Stack direction="row" alignItems="center">
                    <Tooltip title="Difference">
                        <BaseButton variant="outlined" onClick={(evt) => handleOpenManipulationDialog('difference')}>
                            A - B
                        </BaseButton>
                    </Tooltip>
                    <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
                        <BaseButton variant="outlined" onClick={(evt) => handleOpenManipulationDialog('filter')}>
                            Filter
                        </BaseButton>
                    </Box>
                    <Tooltip title="Intersection">
                        <BaseButton variant="outlined" onClick={(evt) => handleOpenManipulationDialog('intersection')}>
                            A ∩ B
                        </BaseButton>
                    </Tooltip>
                </Stack>

                {/* Group by select
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Group By"
                    options={[{ label: 'No Grouping', value: null }, ...networkContext.edgeDataSchema.groupByOptions]}
                    value={_edgeGroupBy}
                    onChange={applyEdgeGroupBy}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                        marginBottom: 1,
                    }}
                />

                {/* All edges item
                    ========================================= */}
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontSize={14} noWrap>
                        All Edges
                    </Typography>

                    <IconButton
                        onClick={(evt) => setGroupForEdit('default')}
                        size="small"
                        sx={(theme) => ({
                            marginLeft: 'auto',
                        })}
                    >
                        <EditIcon />
                    </IconButton>
                </Stack>

                {/* Items
                    ========================================= */}
                {Object.keys(networkContext.edgeStyle)
                    .filter((k) => k !== 'default')
                    .map((key) => (
                        <Stack
                            key={key}
                            direction="row"
                            alignItems="center"
                            gap={1}
                            sx={{
                                minWidth: 0,
                            }}
                        >
                            <IconButton onClick={toggleVisibility(key)}>
                                {!nonVisibleEdges.includes(key) ? (
                                    <VisibilityIcon
                                        sx={{
                                            flexShrink: 0,

                                            color: networkContext.edgeStyle[key].normal.color,
                                        }}
                                    />
                                ) : (
                                    <VisibilityOffIcon
                                        sx={{
                                            flexShrink: 0,

                                            color: networkContext.edgeStyle[key].normal.color,
                                        }}
                                    />
                                )}
                            </IconButton>

                            {/* TODO:
                                Text can become very long (e.g. URLs). Clip the string to desired
                                length before passing it to here.
                            */}
                            <Typography
                                fontSize={14}
                                sx={{
                                    flexGrow: 1,

                                    wordBreak: 'break-word',
                                }}
                            >
                                {key}
                            </Typography>

                            <IconButton
                                onClick={(evt) => setGroupForEdit(key)}
                                size="small"
                                sx={(theme) => ({
                                    flexShrink: 0,
                                })}
                            >
                                <EditIcon />
                            </IconButton>
                        </Stack>
                    ))}
            </Stack>

            <AddEdgeModal open={showAddEdgeModal} setOpen={setShowAddEdgeModal} />
            <EditEdgesModal open={showEdgeModal} setOpen={setShowEdgeModal} />

            {/* Network manipulation dialog  */}
            <StyledDialog
                open={manipulationDialog !== false}
                onClose={handleCloseManipulationDialog}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>{manipulationDialog}</DialogTitle>
                {manipulationDialog === 'intersection' ? (
                    <IntersectionDialog onClose={handleCloseManipulationDialog} />
                ) : manipulationDialog === 'difference' ? (
                    <DifferenceDialog onClose={handleCloseManipulationDialog} />
                ) : manipulationDialog === 'filter' ? (
                    <FilterSenderReceiverDialog onClose={handleCloseManipulationDialog} />
                ) : (
                    <></>
                )}
            </StyledDialog>
        </Stack>
    ) : (
        /*  Edit view
            ========================================= */
        <NetworkEdgeSettingsEdit groupForEdit={groupForEdit} onClose={() => setGroupForEdit('')} />
    )
}
