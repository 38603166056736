//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'
import { Merge } from 'type-fest'
import { Stack, Box, Typography, SxProps, Theme } from '@mui/material'
//* ======= Components and features
import FlexibleSelectMenu from 'components/group-field-selector/FlexibleSelectMenu'
//* ======= Custom logic
//* ======= Assets and styles
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

/*
 * Main exported types
 * =========================================
 */

export type FlexibleSelectOptionItemType = {
    label: string
    value: any
    disabled?: boolean
}

export type FlexibleSelectGroupedOptionType = {
    group: string
    items: FlexibleSelectOptionItemType[]
    disabled?: boolean
    description?: string
}

export type FlexibleSelectUngroupedOptionType = FlexibleSelectOptionItemType

export type FlexibleSelectOptionType = FlexibleSelectGroupedOptionType | FlexibleSelectUngroupedOptionType

type SelectedItemType = Merge<
    {
        group: FlexibleSelectGroupedOptionType['group'] | null
        groupListIndex: number | null
        itemsListIndex: number | null
    },
    FlexibleSelectOptionItemType
>

type ValuePropType = FlexibleSelectOptionItemType['value']

type MenuProps = {
    width?: number
    maxContentHeight?: number
}

type Props = {
    containerElement?: React.RefObject<HTMLElement> | HTMLElement
    options: FlexibleSelectOptionType[]
    value: ValuePropType | null | undefined
    onChange: (value: ValuePropType | undefined) => void
    disabled?: boolean
    hasClearButton?: boolean
    clearedReturnValue?: any
    label?: string
    hiddenLabel?: boolean
    required?: boolean
    placeholder?: string
    hiddenGroupName?: boolean
    size?: 'small' | 'medium'
    fullWidth?: boolean
    menuProps?: MenuProps
    sx?: SxProps<Theme>
}

/* *
 * Notes:
 *  1.  We have to make sure the "value" used for each item is unique, otherwise we would only find
 *      the first occurrence of the value in the groups/items list and might show the wrong selected item.
 */

function FlexibleSelect({
    containerElement,
    options,
    value,
    onChange,
    disabled = false,
    hasClearButton,
    clearedReturnValue = undefined,
    label,
    hiddenLabel,
    required,
    placeholder = 'Choose an option...',
    hiddenGroupName,
    size = 'medium',
    fullWidth,
    menuProps = {
        maxContentHeight: 242,
    },
    sx = [],
}: Props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const isGroupedOption = (option: FlexibleSelectOptionType) => {
        if (Object.prototype.hasOwnProperty.call(option, 'group')) {
            return true
        } else {
            return false
        }
    }

    const selectedItem: SelectedItemType = useMemo(() => {
        for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
            const _option = options[optionIndex]

            // Grouped option
            if (isGroupedOption(_option)) {
                const groupedOption = _option as FlexibleSelectGroupedOptionType

                // Find the option and item based on "value".
                //* Notes (1)
                for (let itemIdx = 0; itemIdx < groupedOption.items.length; itemIdx++) {
                    const _item = groupedOption.items[itemIdx]

                    if (isEqual(_item.value, value)) {
                        return {
                            group: groupedOption.group,
                            groupListIndex: optionIndex,
                            itemsListIndex: itemIdx,
                            ..._item,
                        }
                    }
                }
            }
            // Ungrouped option
            else {
                const ungroupedOption = _option as FlexibleSelectUngroupedOptionType

                if (isEqual(ungroupedOption.value, value)) {
                    return {
                        group: null,
                        groupListIndex: null,
                        itemsListIndex: optionIndex,
                        ...ungroupedOption,
                    }
                }
            }
        }

        // After searching for an item by the "value" and not finding anything, we add the check for
        // "null" and "undefined" values. This order ensures that we can pass an item with "null" or "undefined" value without problems.
        if (value === null || value === undefined) {
            return {
                group: null,
                groupListIndex: null,
                itemsListIndex: null,
                label: placeholder,
                value: null,
            }
        }

        // If no option was found based on "value" in both modes:
        return {
            group: null,
            groupListIndex: null,
            itemsListIndex: null,
            label: '(Invalid value)',
            value: null,
        }
    }, [options, value, placeholder])

    const isLabelHidden = label === undefined || label.trim() === '' || hiddenLabel === true
    const shouldLabelShrink = isMenuOpen === true || selectedItem.itemsListIndex !== null

    /* =========================================
     * Refs
     */

    // The outermost parent element stateful ref. Needed for Popper's anchor and box width.
    const [containerRefState, setContainerRefState] = useState<HTMLDivElement | null>(null)

    const closeMenu = () => {
        // Close the menu and trigger closing animation.
        setIsMenuOpen(false)
    }

    // Close menu if the component gets disabled
    useEffect(() => {
        if (disabled === true) {
            if (isMenuOpen) {
                closeMenu()
            }
        }
    }, [disabled])

    const onClearValue = () => {
        // Ignore clicks on disabled state
        if (disabled) return

        onChange(clearedReturnValue)

        closeMenu()
    }

    const onSelectItem = (selectedValue: ValuePropType) => {
        // Ignore clicks on disabled state
        if (disabled) return

        onChange(selectedValue)

        closeMenu()
    }

    return (
        <>
            {/* Main button
                ========================================= */}
            <Box
                ref={(node: HTMLDivElement) => setContainerRefState(node)}
                role="button"
                onClick={(evt) => {
                    // Ignore clicks on disabled state
                    if (disabled) return

                    setIsMenuOpen((prevState) => !prevState)
                }}
                sx={[
                    (theme) => ({
                        position: 'relative',

                        width: fullWidth ? '100%' : undefined,
                        minWidth: 0,
                        paddingTop:
                            // Small size values
                            size === 'small'
                                ? isLabelHidden
                                    ? theme.spacing(1.5)
                                    : theme.spacing(2.5)
                                : // Medium size values
                                size === 'medium'
                                ? isLabelHidden
                                    ? theme.spacing(2)
                                    : theme.spacing(3)
                                : undefined,
                        paddingBottom:
                            // Small size values
                            size === 'small'
                                ? isLabelHidden
                                    ? theme.spacing(1.5)
                                    : theme.spacing(0.5)
                                : // Medium size values
                                size === 'medium'
                                ? isLabelHidden
                                    ? theme.spacing(2)
                                    : theme.spacing(1)
                                : undefined,
                        paddingLeft: theme.spacing(1.5),
                        // This is the sum of normal padding (1), svg icon width (3), and the gap between content and svg (1.5).
                        //* If you change these values, you should also update the floating label's "width".
                        paddingRight: theme.spacing(1 + 3 + 1.5),
                        cursor: disabled ? 'default' : 'pointer',

                        transition: theme.transitions.create(['border-color', 'background-color'], {
                            duration: theme.transitions.duration.shortest,
                            easing: theme.transitions.easing.sharp,
                        }),
                        opacity: disabled ? theme.palette.action.disabledOpacity : undefined,
                        borderRadius: theme.spacing(1),
                        border: '1px solid transparent',
                        borderColor: isMenuOpen ? theme.palette.primary.dark : 'transparent',
                        backgroundColor: disabled
                            ? theme.palette.action.disabled
                            : isMenuOpen
                            ? theme.palette.common.bg_1
                            : theme.palette.common.bg_4,

                        '&:hover': {
                            backgroundColor: disabled || isMenuOpen ? undefined : theme.palette.action.hover,
                        },

                        '&:active': {
                            borderColor: disabled ? undefined : theme.palette.primary.dark,
                            backgroundColor: disabled ? undefined : theme.palette.common.bg_1,
                        },
                    }),
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                aria-describedby="flexible-select-popper"
            >
                {/* Label
                    ========================================= */}
                {isLabelHidden === false && (
                    <Typography
                        component="label"
                        noWrap
                        sx={(theme) => ({
                            position: 'absolute',
                            top: 0,
                            left: 0,

                            // The width for this floating label is the sum of:
                            // left padding (1.5) and right padding of the parent (1 + 3 + 1.5).
                            // This value is then multiplied by 1.25 to counter the "scale(0.75)" transform of shrunken state.
                            width: `calc((100% - ${theme.spacing(1.5 + 1 + 3 + 1.5)}) * ${
                                shouldLabelShrink ? 1.25 : 1
                            })`,
                            marginY: 'auto',
                            pointerEvents: 'none',
                            userSelect: 'none',

                            transition: theme.transitions.create(['transform', 'width', 'color'], {
                                duration: theme.transitions.duration.shortest,
                                easing: theme.transitions.easing.sharp,
                            }),
                            transform:
                                // Small size values
                                size === 'small'
                                    ? shouldLabelShrink
                                        ? `translate(${theme.spacing(1.5)}, ${theme.spacing(0.75)}) scale(0.75)`
                                        : `translate(${theme.spacing(1.5)}, ${theme.spacing(1.5)})`
                                    : // Medium size values
                                    size === 'medium'
                                    ? shouldLabelShrink
                                        ? `translate(${theme.spacing(1.5)}, ${theme.spacing(1)}) scale(0.75)`
                                        : `translate(${theme.spacing(1.5)}, ${theme.spacing(2)})`
                                    : undefined,
                            transformOrigin: 'top left',
                            color: theme.palette.text.secondary,
                        })}
                    >
                        {required === true ? label + ' *' : label}
                    </Typography>
                )}

                {/* Selected value labels
                    ========================================= */}
                <Stack
                    justifyContent="center"
                    sx={(theme) => ({
                        minWidth: 0,
                        minHeight: theme.spacing(2.75),
                    })}
                >
                    {/* Selected group
                        ========================================= */}
                    {hiddenGroupName !== true &&
                        (isLabelHidden === true || shouldLabelShrink === true) &&
                        selectedItem.group !== null && (
                            <Typography
                                noWrap
                                sx={(theme) => ({
                                    paddingTop: isLabelHidden !== true ? theme.spacing(0.75) : undefined,
                                    fontSize: 13,
                                    fontStyle: 'italic',
                                    userSelect: 'none',

                                    transform: 'translateY(-2px)',
                                    color: theme.palette.text.secondary,
                                })}
                            >
                                {selectedItem.group}
                            </Typography>
                        )}

                    {/* Selected item
                        ========================================= */}
                    <Typography
                        noWrap
                        sx={(theme) => ({
                            fontSize: 14,
                            userSelect: 'none',

                            transition: theme.transitions.create(['opacity'], {
                                duration: theme.transitions.duration.shortest,
                                easing: theme.transitions.easing.sharp,
                            }),
                            opacity: isLabelHidden === true || shouldLabelShrink === true ? 1 : 0,
                            color:
                                selectedItem.itemsListIndex === null
                                    ? theme.palette.text.secondary
                                    : theme.palette.common.text_1,
                        })}
                    >
                        {selectedItem.label}
                    </Typography>
                </Stack>

                {/* Expand/Collapse button
                    ========================================= */}
                <Box
                    sx={(theme) => ({
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: theme.spacing(1),

                        aspectRatio: '1 / 1',
                        height: 24,
                        marginY: 'auto',
                    })}
                >
                    <ExpandMoreRoundedIcon
                        sx={(theme) => ({
                            width: '100%',
                            height: '100%',

                            transition: theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest,
                                easing: theme.transitions.easing.sharp,
                            }),
                            transform: isMenuOpen ? 'rotate(180deg)' : undefined,
                            color: disabled ? theme.palette.common.fill_1 : theme.palette.primary.main,
                        })}
                    />
                </Box>
            </Box>

            {/* (Out-of-flow) Select popper
                ========================================= */}
            {containerRefState !== null && (
                <FlexibleSelectMenu
                    containerElement={containerElement}
                    anchorElement={containerRefState}
                    isOpen={isMenuOpen}
                    options={options as FlexibleSelectGroupedOptionType[]}
                    selectedItem={selectedItem}
                    onClose={closeMenu}
                    onSelectItem={onSelectItem}
                    disabled={disabled}
                    hasClearButton={hasClearButton}
                    onClearValue={onClearValue}
                    menuProps={menuProps}
                />
            )}
        </>
    )
}

export default FlexibleSelect
