import { NavigationDrawerType } from 'types/NavigationTypes'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import BookIcon from '@mui/icons-material/Book'
import BallotIcon from '@mui/icons-material/Ballot'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import RuleFolderIcon from '@mui/icons-material/RuleFolder'
import { surveyRouterConfig } from 'pages/MasterLayout'
import { ProjectRoleWithPermissionType } from 'services/ProjectService'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { SurveyBasicType } from 'services/SurveyService'

const SurveyDrawerNavigation = (
    role: ProjectRoleWithPermissionType,
    survey: SurveyBasicType,
    pid?: number | string,
    sid?: number | string
): NavigationDrawerType => {
    let content = {
        avatar: 'S',
        title: survey.title,
        role: role.roleName,
        sections: [
            [
                {
                    title: 'Dashboard',
                    link: `/project/${pid}/surveys/${sid}`,
                    icon: <DashboardIcon />,
                },

                {
                    title: 'Designer',
                    link: `/project/${pid}/surveys/${sid}/designer`,
                    icon: <AppRegistrationIcon />,
                },
                {
                    title: 'Notifications',
                    link: `/project/${pid}/surveys/${sid}/notifications`,
                    icon: <ContactMailIcon />,
                },
                {
                    title: 'Participants',
                    link: `/project/${pid}/surveys/${sid}/participants`,
                    icon: <PeopleAltIcon />,
                },
                {
                    title: 'Logs',
                    link: `/project/${pid}/surveys/${sid}/logs`,
                    icon: <BookIcon />,
                },
                {
                    title: 'Results',
                    link: `/project/${pid}/surveys/${sid}/results`,
                    icon: <BallotIcon />,
                },
                {
                    title: 'Analytics',
                    link: `/project/${pid}/surveys/${sid}/analytics`,
                    icon: <AnalyticsIcon />,
                },
                // {
                //     title: 'Individual Report',
                //     link: `/project/${pid}/surveys/${sid}/report`,
                //     icon: <InsightsIcon />,
                // },
                {
                    title: 'Rules',
                    link: `/project/${pid}/surveys/${sid}/rules`,
                    icon: <RuleFolderIcon />,
                },
            ],
        ],
        quickAccess: true,
        settings: {
            title: 'Survey Settings',
            link: `/project/${pid}/surveys/${sid}/settings`,
        },
    }

    if (survey.status === 'published' && survey.openParticipation) {
        content.sections[0].splice(1, 0, {
            title: 'Open Participation',
            link: `/project/${pid}/surveys/${sid}/open`,
            icon: <FactCheckIcon />,
        })
    }

    // check if user has permission to access the page
    for (let idx in content.sections) {
        content.sections[idx] = content.sections[idx].filter((section) => {
            // retrieve permission from router config
            var permission = surveyRouterConfig.find((x) => {
                return section.link === `/project/${pid}/surveys/${sid}${x.path === '' ? '' : `/${x.path}`}`
            })?.permission
            // if permission is undefined (the route is not protected), allow access
            if (permission === undefined) return true
            // if permission is string, check if user has the permission. Otherwise, check if user has any of the permissions
            return typeof permission === 'string'
                ? role.projectRolePermissions.includes(permission)
                : permission.some((item) => role.projectRolePermissions.includes(item))
        })
    }

    return content
}

export default SurveyDrawerNavigation
