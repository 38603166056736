function isNumeric(value: string) {
    return /^-?\d+$/.test(value)
}

export const validatProjectPath = (pid?: string) => {
    return pid != null && isNumeric(pid)
}

export const validateSurveyPath = (pid?: string, sid?: string) => {
    return pid != null && isNumeric(pid) && sid != null && isNumeric(sid)
}
