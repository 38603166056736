import { useEffect, useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import { GridActionsCellItem, GridColDef, GridRowId, GridRowParams } from '@mui/x-data-grid-pro'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'

import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { EdgeType } from 'features/network-viz/types/NetworkViz.types'
import ToastHelper from 'helpers/ToastHelper'
import WebWorker from 'helpers/webWorkerHelper'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RestoreIcon from '@mui/icons-material/Restore'
export interface EditEdgesModalProps {
    open: boolean
    setOpen: (val: boolean) => void
}

export function EditEdgesModal({ open, setOpen }: EditEdgesModalProps) {
    const {
        edges,
        nodes,
        nodeRenders,
        edgeStyle,
        edgeGroupBy,
        layoutKind,
        filters,
        nodeInteractionConfig,
        analyticsSettings,
    } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()

    //states
    const [internalEdges, setInternalEdges] = useState<EdgeType[]>([])
    const [rowsToDelete, setRowsToDelete] = useState<Set<GridRowId>>(new Set())
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [duplicates, setDuplicates] = useState<GridRowId[]>([])

    useEffect(() => {
        if (!open) return
        setColumns([
            {
                field: 'id',
                headerName: 'ID',
            },
            {
                field: 'source',
                headerName: 'Source',
                valueGetter: ({ value }) => {
                    return nodes[value].label ?? value
                },
            },
            {
                field: 'target',
                headerName: 'Target',
                valueGetter: ({ value }) => {
                    return nodes[value].label ?? value
                },
            },
            {
                field: 'relationship',
                headerName: 'relationship',
            },

            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isMarkedForDeletion = rowsToDelete.has(id)

                    return [
                        <GridActionsCellItem
                            icon={isMarkedForDeletion ? <RestoreIcon /> : <DeleteIcon />}
                            label={isMarkedForDeletion ? 'Restore' : 'Delete'}
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ]
                },
            },
        ])
    }, [open, rowsToDelete])

    const removeDuplicates = () => {
        setRowsToDelete((prev) => {
            const newSet = new Set(prev)
            for (let id of duplicates) {
                newSet.add(id)
            }
            return newSet
        })
    }

    const findDuplicates = (edges: EdgeType[]) => {
        const uniqueEdges = new Set<GridRowId>()
        const tmpDuplicates = new Set<GridRowId>()
        for (let edge of edges) {
            const key = `${edge.source}-${edge.target}-${edge.relationship}`
            if (uniqueEdges.has(key)) {
                tmpDuplicates.add(edge.id)
            }
            uniqueEdges.add(key)
        }
        return Array.from(tmpDuplicates)
    }

    // set the internal edges when the edges change or the modal opens
    useEffect(() => {
        if (!open) return
        const tempEdges = structuredClone(Object.values(edges))
        setInternalEdges(tempEdges)
        setDuplicates(findDuplicates(tempEdges))
    }, [edges, open])

    const onClose = () => {
        setRowsToDelete(new Set())
        setOpen(false)
    }

    const saveChanges = async () => {
        const toast = new ToastHelper({
            errorMessage: 'Error saving changes',
            successMessage: 'Changes saved',
            loadingMessage: 'Saving changes',
        })
        try {
            const worker = new WebWorker('workers/network/update-edges-worker.js')
            const response = (await worker.run({
                nodeRenders,
                nodes,
                edges,
                edgeStyle,
                edgeGroupBy,
                edgesToRemove: Array.from(rowsToDelete).map((x) => x + ''),
                layoutKind,
                filters,
                isDirected: nodeInteractionConfig.directed,
                analyticsSettings,
            })) as any

            contextDispatch({
                type: 'EDGE_REMOVE',
                payload: {
                    edges: response.edges,
                    edgeRenders: response.edgeRenders,
                    edgeLegend: response.edgeLegend,
                    edgeStyle: response.edgeStyle,
                    edgeDataSchema: response.edgeSchema,
                    nodeRenders: response.nodeRenders,
                    nodeAdjacentList: response.nodeAdjacentList,
                    filters: response.filters,
                    analyticsSettings: response.analyticsSettings,
                },
            })
            toast.success()
            onClose()
        } catch (err) {
            toast.fail()
        }
    }

    const getRowClassName = (params: GridRowParams) => {
        return rowsToDelete.has(params.id) ? 'line-through' : ''
    }

    const handleDeleteClick = (id: GridRowId) => () => {
        setRowsToDelete((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(id)) {
                newSet.delete(id)
            } else {
                newSet.add(id)
            }
            return newSet
        })
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>Edit Edges</DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <BaseButton disabled={duplicates.length === 0} onClick={() => removeDuplicates()}>
                    Remove duplicates {duplicates.length > 0 ? `(${duplicates.length} edges)` : ''}
                </BaseButton>

                <Stack direction="column" height="90%">
                    <StyledDataGrid
                        disableRowSelectionOnClick
                        columns={columns}
                        rows={internalEdges}
                        density="standard"
                        getRowId={(r) => r.id}
                        getRowClassName={getRowClassName}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} color="warning" variant="outlined">
                    Close
                </BaseButton>
                <BaseButton color="primary" variant="contained" onClick={saveChanges}>
                    Save Changes
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
