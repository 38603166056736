export const SAMPLE_SURVEY_RESPONSE = {
    response: {
        GrowthMindset: 3,
        Manbox5_overall: 5.6,
        k6_overall: 23,
        Masculinity_contrained: 5.5,
        School_support_engage: 4,
        School_support_engage6: 3.5,
        language: '0',
        comfortable: 2,
        isolated: 5,
        criticises: 3,
        opinion: 6,
        bullying: 6,
        future: 5,
        Friends: [
            {
                primaryKey: 1,
                title: 'A',
                avatar: '',
                category: '',
                others: {
                    house: 'A',
                    category: 'student',
                    studentid: 3,
                    attendance: 91,
                    tutorgroup: 'D',
                    year_level: 7,
                    perc_effort: 18,
                    completeyears: 0,
                    perc_academic: 37,
                    brightonbubbleflag: 0,
                },
            },
            {
                primaryKey: 2,
                title: 'B',
                avatar: '',
                category: '',
                others: {
                    house: 'A',
                    category: 'student',
                    studentid: 2,
                    attendance: 92,
                    tutorgroup: 'D',
                    year_level: 7,
                    perc_effort: 52,
                    completeyears: 2,
                    perc_academic: 73,
                    brightonbubbleflag: 0,
                },
            },
        ],
        Advice: [
            {
                primaryKey: 1,
                title: 'A',
                avatar: '',
                category: '',
                others: {
                    house: 'A',
                    category: 'student',
                    studentid: 3,
                    attendance: 91,
                    tutorgroup: 'D',
                    year_level: 7,
                    perc_effort: 18,
                    completeyears: 0,
                    perc_academic: 37,
                    brightonbubbleflag: 0,
                },
            },
        ],
        Disrespect: [
            {
                primaryKey: 3,
                title: 'A',
                avatar: '',
                category: '',
                others: {
                    house: 'A',
                    category: 'student',
                    studentid: 3,
                    attendance: 91,
                    tutorgroup: 'D',
                    year_level: 7,
                    perc_effort: 18,
                    completeyears: 0,
                    perc_academic: 37,
                    brightonbubbleflag: 0,
                },
            },
        ],
        Influential: [
            {
                primaryKey: 4,
                title: 'P',
                avatar: '',
                category: '',
                others: {
                    house: 'A',
                    category: 'student',
                    studentid: 2,
                    attendance: 92,
                    tutorgroup: 'D',
                    year_level: 7,
                    perc_effort: 52,
                    completeyears: 2,
                    perc_academic: 73,
                    brightonbubbleflag: 0,
                },
            },
        ],
        MoreTime: [
            {
                primaryKey: 5,
                title: 'P',
                avatar: '',
                category: '',
                others: {
                    house: 'A',
                    category: 'student',
                    studentid: 2,
                    attendance: 92,
                    tutorgroup: 'D',
                    year_level: 7,
                    perc_effort: 52,
                    completeyears: 2,
                    perc_academic: 73,
                    brightonbubbleflag: 0,
                },
            },
        ],
        Feedback: [
            {
                primaryKey: 6,
                title: 'P',
                avatar: '',
                category: '',
                others: {
                    house: 'A',
                    category: 'student',
                    studentid: 2,
                    attendance: 92,
                    tutorgroup: 'D',
                    year_level: 7,
                    perc_effort: 52,
                    completeyears: 2,
                    perc_academic: 73,
                    brightonbubbleflag: 0,
                },
            },
        ],
        pwi_wellbeing: '3',
        k6: { '1': '4', '2': '3', '3': '4', '4': '4', '5': '4', '6': '4' },
        Intelligence1: 6,
        COVID: 5,
        Intelligence2: 6,
        Manbox5: { '1': '6', '2': '5', '3': '6', '4': '6', '5': '5' },
        Soft: 5,
        WomenDifferent: 6,
        Nerds: 5,
        MenBetterSTEM: 6,
        SchoolActivityNet: [
            {
                primaryKey: 7,
                title: 'Community service',
                avatar: '',
                category: '',
                others: { category: 'activity' },
            },
            { primaryKey: 8, title: 'Art Club', avatar: '', category: '', others: { category: 'activity' } },
        ],
        'Your comments': 'test',
    },
    timeSpent: 52,
}
