import { Title } from '@mui/icons-material'
import { Box, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledDialog from 'components/dialog/StyledDialog'
import DynamicIconSelector from 'features/dynamic-icon-slector/DynamicIconSelector'
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import { CombinedWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { set } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

type CombinedWidgetLinkInternalState = {
    title: string
    icon: string | undefined
    linkedWidgets: CombinedWidgetType['widgets'][number]['linkedWidgets']
}

interface CombinedWidgetLinkDialogProps {
    widgetId: ReportWidgetType['id']
    innerWidgetId: ReportWidgetType['id'] | null
    isOpen: boolean
    onClose: () => void
    onSave: (state: CombinedWidgetLinkInternalState) => void
}

const CombinedWidgetLinkDialog: React.FC<CombinedWidgetLinkDialogProps> = ({
    isOpen,
    onClose,
    widgetId,
    innerWidgetId,
    onSave,
}) => {
    const activeSlide = useReportActiveSlide()

    const [internalState, setInternalState] = useState<CombinedWidgetLinkInternalState>()

    useEffect(() => {
        const widget = activeSlide?.widgets.find((widget) => widget.id === widgetId)
        const innerWidget = activeSlide?.widgets.find((widget) => widget.id === innerWidgetId)

        if (widget?.content.kind === 'combined' && innerWidget) {
            setInternalState({
                title: innerWidget.title,
                icon: widget.content.details.widgets.find((widget) => widget.id === innerWidgetId)?.icon,
                linkedWidgets:
                    widget.content.details.widgets.find((widget) => widget.id === innerWidgetId)?.linkedWidgets || [],
            })
        }
    }, [innerWidgetId, widgetId])

    const addLinkedWidget = () => {
        setInternalState((state) => {
            if (!state) return state
            return {
                ...state,
                linkedWidgets: [
                    ...state.linkedWidgets,
                    {
                        id: '',
                        tab: '',
                    },
                ],
            }
        })
    }

    const removeLinkedWidget = (index: number) => {
        setInternalState((state) => {
            if (!state) return state
            return {
                ...state,
                linkedWidgets: state.linkedWidgets.filter((_, i) => i !== index),
            }
        })
    }

    const updateLinkedWidget = (index: number, key: string, value: string) => {
        setInternalState((state) => {
            if (!state) return state
            const updatedLinkedWidgets = [...state.linkedWidgets]
            updatedLinkedWidgets[index] = {
                ...updatedLinkedWidgets[index],
                [key]: value,
            }
            return {
                ...state,
                linkedWidgets: updatedLinkedWidgets,
            }
        })
    }

    const otherCombinedWidgets = useMemo(() => {
        const items =
            activeSlide?.widgets
                .filter((widget) => {
                    return widget.id !== widgetId && widget.content.kind === 'combined'
                })
                .map((widget) => ({
                    label: widget.title,
                    value: widget.id,
                })) ?? []

        return items
    }, [activeSlide, widgetId])

    const otherCombinedWidgetsTabsDict = useMemo(() => {
        const dict: Record<string, { label: string; value: string | number }[]> = {}
        activeSlide?.widgets
            .filter((widget) => {
                return widget.id !== widgetId && widget.content.kind === 'combined'
            })
            .forEach((widget) => {
                if (widget.content.kind === 'combined') {
                    const innerWidgets = widget.content.details.widgets
                    dict[widget.id] = activeSlide?.widgets
                        .filter((x) => innerWidgets.some(({ id }) => x.id === id))
                        .map((x) => ({ label: x.title, value: x.id }))
                }
            })
        return dict
    }, [activeSlide, widgetId])

    const isValid = useMemo(() => {
        return (
            internalState &&
            internalState.title.trim() !== '' &&
            internalState.linkedWidgets.every((widget) => widget.id !== '' && widget.tab !== '')
        )
    }, [internalState])

    return (
        <StyledDialog open={isOpen && innerWidgetId !== null} onClose={onClose} fullWidth maxWidth="lg">
            {internalState && (
                <>
                    <DialogTitle>Edit {internalState.title}</DialogTitle>
                    <DialogContent sx={{ height: '70vh' }} className="u-scrollbar">
                        <Stack direction="row" gap={1}>
                            <BaseFilledTextField
                                label="Title"
                                key={innerWidgetId + '-title'}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                defaultValue={internalState.title}
                                onBlur={(e) => {
                                    setInternalState((state) => ({
                                        ...state!,
                                        title: e.target.value,
                                    }))
                                }}
                            />
                            <Box sx={{ width: '350px', flexShrink: 0 }}>
                                <DynamicIconSelector
                                    label="Icon"
                                    key={innerWidgetId + '-title'}
                                    defaultIcon={internalState.icon}
                                    clearable={true}
                                    onSelectIcon={(icon) => {
                                        setInternalState((state) => ({
                                            ...state!,
                                            icon: icon,
                                        }))
                                    }}
                                />
                            </Box>
                        </Stack>
                        <Stack spacing={2} mt={2}>
                            {/* Header */}
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant="h6">Linked Widgets</Typography>
                                <BaseButton onClick={addLinkedWidget} color="primary">
                                    Add Widget
                                </BaseButton>
                            </Stack>
                            {internalState.linkedWidgets.map((_widget, index) => {
                                return (
                                    <Stack key={index} direction="row" justifyContent="space-between" spacing={1}>
                                        <BaseSelectWithLabel
                                            label="Widget ID"
                                            value={_widget.id}
                                            options={otherCombinedWidgets}
                                            fullWidth
                                            onChange={(value) => {
                                                updateLinkedWidget(index, 'id', value)
                                            }}
                                        />
                                        <BaseSelectWithLabel
                                            label="Tab"
                                            fullWidth
                                            value={_widget.tab}
                                            options={otherCombinedWidgetsTabsDict[_widget.id] ?? []}
                                            onChange={(value) => {
                                                updateLinkedWidget(index, 'tab', value)
                                            }}
                                        />
                                        <BaseButton
                                            onClick={() => {
                                                removeLinkedWidget(index)
                                            }}
                                            color="error"
                                        >
                                            Remove
                                        </BaseButton>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <BaseButton
                            onClick={() => {
                                onClose()
                            }}
                            color="secondary"
                        >
                            Close
                        </BaseButton>
                        <BaseButton
                            onClick={() => {
                                if (isValid) {
                                    onSave(internalState)
                                }
                            }}
                            color="primary"
                            disabled={!isValid}
                            variant="contained"
                        >
                            Save
                        </BaseButton>
                    </DialogActions>
                </>
            )}
        </StyledDialog>
    )
}

export default CombinedWidgetLinkDialog
