import { useMemo, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import Draggable from 'react-draggable'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { NetworkSelectedNodeInfoTable } from './NetworkSelectedNodeInfoTable'
import { NetworkSelectedGroupInfoTable } from './NetworkSelectedGroupInfoTable'
import { NetworkItemIdType } from 'features/network-viz/types/NetworkViz.types'
import { NetworkSelectedEdgeInfoTable } from './NetworkSelectedEdgeInfoTable'

type NetworkSelectedNodeDetailsPropsType = {}

export function NetworkSelectedNodeDetails({}: NetworkSelectedNodeDetailsPropsType) {
    const { selectedItem, analytics, nodeInfoConfig, nodeGroupInfo, nodes, edges } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    //ToDo add feature to display data for recurring surveys
    const round = 0
    const [position, setPosition] = useState<{ top: number; left: number } | null>({ top: 0, left: 0 })

    const clearSelectedtNodes = () => {
        dispatchContext({ type: 'SELECTED_ITEM_CHANGE', payload: null })
    }

    const drillInto = (group: NetworkItemIdType) => {
        dispatchContext({ type: 'LEVEL_UPDATE', payload: group })
    }

    return selectedItem === null ? (
        <></>
    ) : (
        <Draggable
            position={
                position
                    ? {
                          x: position.left,
                          y: position.top,
                      }
                    : undefined
            }
            bounds="body"
            onStop={(e, data) => {
                setPosition({
                    top: data.y,
                    left: Math.max(0, data.x),
                })
            }}
        >
            <Stack
                gap={2}
                sx={(theme) => ({
                    height: 450,
                    width: 300,
                    padding: theme.spacing(1.5, 2),
                    filter: ' alpha(opacity = 95)',
                    mozOpacity: 0.95,
                    /* Mozilla */
                    opacity: 0.95,
                    border: `1px solid ${theme.palette.common.border_2}`,
                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                })}
            >
                {/* Title and close button
                ========================================= */}
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                    <Typography fontSize={14} fontWeight={600} color="primary">
                        {selectedItem.mode === 'node'
                            ? 'Node'
                            : selectedItem.mode === 'edge'
                            ? 'Edge'
                            : `Group ${selectedItem.id}`}
                    </Typography>

                    <Stack direction="row">
                        <Tooltip title="Close">
                            <IconButton onClick={clearSelectedtNodes} size="small">
                                <CloseRoundedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>

                {selectedItem.mode === 'node' ? (
                    <NetworkSelectedNodeInfoTable
                        analytics={analytics}
                        nodeInfoConfig={nodeInfoConfig}
                        nodes={nodes}
                        selectedNode={selectedItem.id}
                        mode="network-viz"
                    />
                ) : selectedItem.mode === 'group' ? (
                    <NetworkSelectedGroupInfoTable
                        groupKey={selectedItem.id}
                        analytics={analytics}
                        nodeInfoConfig={nodeInfoConfig}
                        nodes={nodes}
                        drillInto={drillInto}
                        selectedGroup={nodeGroupInfo?.[selectedItem.id]}
                        mode="network-viz"
                    />
                ) : selectedItem.mode === 'edge' ? (
                    <NetworkSelectedEdgeInfoTable edges={edges} selectedEdge={selectedItem.id} />
                ) : (
                    <></>
                )}
            </Stack>
        </Draggable>
    )
}
