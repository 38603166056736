//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import BaseButton from 'components/base/BaseButton'
import LiteTemplateCard from 'components/lite-template-card/LiteTemplateCard'
import NavigableDialog from 'components/dialog/NavigableDialog'
import AllTemplatesDialog from 'features/all-templates-dialog/AllTemplatesDialog'
import TemplateDetailsDialog from 'features/template-details-dialog/TemplateDetailsDialog'
import CreateProjectDialog from 'features/create-project-dialog/CreateProjectDialog'
import UploadNodeDialog from 'features/upload-node-dialog/UploadNodeDialog'
import SurveryImportDialog from 'features/survey-import-dialog/SurveryImportDialog'
import ReportImportDialog from 'features/report-import-dialog/ReportImportDialog'
import AddUsersDialog from 'features/add-users-dialog/AddUsersDialog'
//* ======= Custom logic
import { ResourceType } from 'types/resources'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'

interface INewProjectStrip {
    templates: ResourceType[]
    tagCategories: string[]
    resourceName: string
    onCreate?: Function
}

interface INewProjectStripDialog {
    type:
        | 'all-templates'
        | 'template-details'
        | 'create-project'
        | 'node-list'
        | 'survery-import'
        | 'report-import'
        | 'add-users'
    isOpen: boolean
    projectId?: number
    title: string
    onNext?: (id?: number) => void
    onBack?: () => void
    disableBackButton?: boolean
}

function NewProjectStrip({ templates, onCreate, tagCategories, resourceName }: INewProjectStrip) {
    const [newProjectId, setNewProjectId] = useState<number>()
    const [templateId, setTemplateId] = useState<number>()

    const [dialogState, setDialogState] = useState<INewProjectStripDialog>({
        type: 'create-project',
        isOpen: false,
        title: 'Project Information',
        disableBackButton: false,
    })

    const closeDialog = () => {
        setDialogState((prevState) => ({ ...prevState, isOpen: false }))
    }

    const showAllTemplatesDialog = (title = 'All Templates') => {
        setDialogState({
            type: 'all-templates',
            isOpen: true,
            title,
            onNext: (templateId?: number) => showTemplateDetailsDialog(templateId),
        })
    }

    const showTemplateDetailsDialog = (templateId?: number, hasBackButton = false, title = 'Template Details') => {
        setDialogState({
            type: 'template-details',
            isOpen: true,
            title,
            onNext: () => {
                setTemplateId(templateId)
                showCreateProjectDialog(true)
            },
            disableBackButton: !hasBackButton,
            onBack: showAllTemplatesDialog,
        })
    }

    const showCreateProjectDialog = (hasBackButton = false, title = 'Project Information') => {
        setDialogState({
            type: 'create-project',
            isOpen: true,
            title,
            onNext: (id?: number) => {
                setNewProjectId(id)
                showNodeListDialog(false)
            },
            disableBackButton: !hasBackButton,
            onBack: () => showTemplateDetailsDialog(templateId, true),
        })
    }

    const showNodeListDialog = (hasBackButton = false, title = 'Upload Node List') => {
        setDialogState({
            type: 'node-list',
            isOpen: true,
            title,
            onNext: () => {
                if (templateId) showSurveyImportDialog(true)
                else showAddUsersDialog(false)
            },
            disableBackButton: !hasBackButton,
            onBack: () => showCreateProjectDialog(true),
        })
    }

    const showSurveyImportDialog = (hasBackButton = false, title = 'Select Your Surveys') => {
        setDialogState({
            type: 'survery-import',
            isOpen: true,
            title,
            projectId: newProjectId,
            onNext: () => showReportImportDialog(true),
            disableBackButton: !hasBackButton,
            onBack: () => showNodeListDialog(true),
        })
    }

    const showReportImportDialog = (hasBackButton = false, title = 'Select Your Reports') => {
        setDialogState({
            type: 'report-import',
            isOpen: true,
            title,
            projectId: newProjectId,
            onNext: () => showAddUsersDialog(true),
            disableBackButton: !hasBackButton,
            onBack: () => showSurveyImportDialog(true),
        })
    }

    const showAddUsersDialog = (hasBackButton = false, title = 'Add Users') => {
        setDialogState({
            type: 'add-users',
            isOpen: true,
            title,
            projectId: newProjectId,
            onNext: () => alert('This is the end!'),
            disableBackButton: !hasBackButton,
            onBack: () => showReportImportDialog(true),
        })
    }

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                sx={(theme) => ({
                    padding: 2,

                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                })}
            >
                {/* Create project
					========================================= */}
                <Stack spacing={1}>
                    {/* Title
						========================================= */}
                    <Typography alignSelf="flex-start" fontSize={14}>
                        Create {resourceName}
                    </Typography>

                    <BaseButton
                        variant="outlined"
                        onClick={(e) => onCreate && onCreate()}
                        sx={{
                            height: '136px',
                            width: '200px',

                            borderRadius: '10px',
                            backgroundColor: 'common.bg_4',
                        }}
                    >
                        <AddIcon
                            color="primary"
                            sx={{
                                height: '64px',
                                width: '64px',
                            }}
                        />
                    </BaseButton>
                </Stack>

                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                        borderStyle: 'dashed',
                        borderColor: 'common.border_2',
                    }}
                />

                {/* Templates
					========================================= */}
                <Stack spacing={1} flexGrow={1} minWidth={0}>
                    <Stack direction="row" justifyContent="space-between" spacing={1}>
                        {/* Title
							========================================= */}
                        <Typography fontSize={14} alignSelf="flex-start">
                            Start From Template
                        </Typography>

                        {/* All templates button
							========================================= */}
                        <BaseButton
                            onClick={(e) => showAllTemplatesDialog()}
                            sx={{
                                // Same as the title text.
                                height: '21px',
                                paddingTop: 0.25,
                                paddingBottom: 0.25,
                            }}
                        >
                            <Typography fontSize={14}>All Templates</Typography>
                        </BaseButton>
                    </Stack>

                    {/* Templates list
						========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{
                            overflowX: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {templates.map((_template, idx) => (
                            <LiteTemplateCard
                                key={idx}
                                name={_template.title}
                                //TODO Ali fix the count badge
                                countBadge={_template.rating || 5}
                                image={_template.image}
                                onClick={() => showTemplateDetailsDialog(_template.id, false, _template.title)}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Stack>

            {/* Create project Dialog
				========================================= */}
            <NavigableDialog
                title={dialogState.title}
                open={dialogState.isOpen}
                onClose={closeDialog}
                backHandler={dialogState.onBack}
                disableBackButton={dialogState.disableBackButton}
                fullWidth={true}
                maxWidth={dialogState.type === 'all-templates' ? 'xl' : 'lg'}
                scroll="body"
            >
                {dialogState.type === 'all-templates' ? (
                    <AllTemplatesDialog templates={templates} onConfirm={dialogState.onNext} />
                ) : dialogState.type === 'template-details' ? (
                    <TemplateDetailsDialog onConfirm={dialogState.onNext} />
                ) : dialogState.type === 'create-project' ? (
                    <CreateProjectDialog onConfirm={dialogState.onNext} onClose={closeDialog} />
                ) : dialogState.type === 'node-list' ? (
                    <UploadNodeDialog
                        onConfirm={() => showSurveyImportDialog(true)}
                        projectId={newProjectId ? newProjectId : -1}
                    />
                ) : dialogState.type === 'survery-import' ? (
                    <SurveryImportDialog onConfirm={() => showReportImportDialog(true)} onClose={closeDialog} />
                ) : dialogState.type === 'report-import' ? (
                    <ReportImportDialog onConfirm={() => showAddUsersDialog(true)} onClose={closeDialog} />
                ) : dialogState.type === 'add-users' ? (
                    <AddUsersDialog onConfirm={() => showAddUsersDialog(true)} roles={[]} />
                ) : (
                    false
                )}
            </NavigableDialog>
        </>
    )
}

export default NewProjectStrip
