import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BackIcon from '@mui/icons-material/ArrowBack'
import React, { useState } from 'react'
import ColorPicker from '../../../../components/widgets/ColorPicker'
import BaseButton from 'components/base/BaseButton'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BorderWidgetSettings from 'components/widgets/BorderWidgetSettings'
import ShadowPicker from 'components/widgets/ShadowPicker'
import FontPicker from 'components/widgets/FontPicker'
import RangeSliderSelector from 'components/widgets/RangeSliderSelector'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { NodeGroupStyleType } from 'features/network-viz/types/NetworkViz.types'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import NodeAttributeSelector from 'features/network-viz/helpers/NodeAttributeSelector'
import { updateUnchangedProperties } from 'features/network-viz/helpers/NetworkViz.helper'

export interface NodeSettingsEditProps {
    groupForEdit: string
    onClose: () => void
}

type SectionType = 'normal' | 'colorScale' | 'label' | 'selected' | 'sizeScale'

export default function NetworkNodeSettingsEdit({ groupForEdit, onClose }: NodeSettingsEditProps) {
    const { networkVizKind, nodeDataSchema, nodeStyle, nodes, nodeRenders, analytics } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [localValue, setLocalValue] = useState<NodeGroupStyleType>(nodeStyle[groupForEdit])

    const updateLocalValue = (field: string, section?: SectionType) => (value: any) => {
        setLocalValue((lv) => {
            if (lv == null) return lv
            if (section) {
                return { ...lv, [section]: { ...lv[section], [field]: value } }
            } else {
                return { ...lv, [field]: value }
            }
        })
    }

    const updateSizeScale = (value: number[]) => {
        setLocalValue((lv) => {
            if (lv == null) return lv
            return {
                ...lv,
                sizeScale: {
                    ...lv.sizeScale,
                    sizeFrom: value[0],
                    sizeTo: value[1],
                },
            }
        })
    }

    const toggleField = (field: 'border' | 'shadow', section: 'normal' | 'selected') => (value: any) => {
        setLocalValue((lv) => {
            if (lv == null) return lv
            if (section) {
                return {
                    ...lv,
                    [section]: {
                        ...lv[section],
                        [field]: {
                            ...lv[section][field],
                            enabled: !lv[section][field].enabled,
                        },
                    },
                }
            }
            return { ...lv }
        })
    }

    const updateSettingField = (field: 'border' | 'shadow', section: 'normal' | 'selected') => (value: any) => {
        setLocalValue((lv) => {
            if (lv == null) return lv
            if (section) {
                return {
                    ...lv,
                    [section]: {
                        ...lv[section],
                        [field]: {
                            ...lv[section][field],
                            settings: value,
                        },
                    },
                }
            }

            return { ...lv }
        })
    }

    const toggleLabel = () => {
        setLocalValue((lv) => {
            if (lv == null) return lv
            return {
                ...lv,
                label: {
                    ...lv.label,
                    show: !lv.label?.show,
                },
            }
        })
    }

    const applyChanges = (section: SectionType) => () => {
        if (localValue == null) return
        const newGNS = { ...nodeStyle }
        newGNS[groupForEdit] = {
            ...newGNS[groupForEdit],
            [section]: localValue[section],
        }

        // if default group is getting updated, update all styles if the value is not overridden
        if (groupForEdit === 'default') {
            const originalStyleSection = nodeStyle.default[section]
            for (let g in newGNS) {
                if (g === 'default') continue // Skip the default group

                // Create a copy of the groupStyleSection to avoid modifying it directly
                const groupStyleSectionCopy = structuredClone(newGNS[g][section])

                // Update properties where they match the default value (deep)
                updateUnchangedProperties(groupStyleSectionCopy, originalStyleSection, localValue[section])

                // Assign the merged style back to the group
                // ToDo Fix any type
                newGNS[g][section] = groupStyleSectionCopy as any
            }
        }

        const worker = new WebWorker('workers/network/change-node-style-worker.js')

        worker
            .run({
                nodes,
                nodeRenders,
                dataSchema: nodeDataSchema.fields,
                analytics,
                nodeStyle: newGNS,
            })
            .then((res: any) => {
                dispatchContext({ type: 'NODE_STYLE_EDIT', payload: { nodeRenders: res, nodeStyle: newGNS } })
            })
            .catch((e) => {})
            .finally(() => {})
    }

    return (
        <React.Fragment>
            {localValue == null ? (
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        minWidth: 0,
                    })}
                >
                    <SnaCircularLoading />
                </Stack>
            ) : (
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        minWidth: 0,
                    })}
                >
                    {/* Back button
                    ========================================= */}
                    <BaseButton
                        label="Back"
                        onClick={onClose}
                        startIcon={<BackIcon />}
                        sx={{
                            alignSelf: 'flex-start',
                            marginBottom: 1,
                        }}
                    />

                    {/* Content
                    ========================================= */}

                    {/* Default Node Section
                    ========================================= */}
                    <StyledWidgetAccordion defaultExpanded={true} title="Default Node" hasBottomBorder>
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            {/* Size
                            ========================================= */}
                            <Stack>
                                <Typography fontSize={15}>Node Size</Typography>
                                <ValueSliderPicker
                                    disabled={localValue.sizeScale.enabled}
                                    value={localValue.normal?.size || 0}
                                    min={0}
                                    max={100}
                                    step={0.5}
                                    onChange={updateLocalValue('size', 'normal')}
                                />
                            </Stack>

                            {/* Shape
                            ========================================= */}
                            <BaseSelectWithLabel
                                size="small"
                                label="Shape"
                                value={localValue.normal.shape}
                                onChange={updateLocalValue('shape', 'normal')}
                                options={
                                    networkVizKind === '2d+'
                                        ? [
                                              'circle',
                                              'rect',
                                              'roundRect',
                                              'triangle',
                                              'diamond',
                                              'pin',
                                              'arrow',
                                              'image',
                                              'none',
                                          ]
                                        : ['circle', 'image']
                                }
                                fullWidth
                            />

                            {/* Image
                            ========================================= */}
                            {localValue.normal.shape === 'image' && (
                                <BaseSelectWithLabel
                                    size="small"
                                    label="Shape"
                                    value={localValue.normal.image}
                                    onChange={updateLocalValue('image', 'normal')}
                                    options={Object.keys(nodeDataSchema.fields)}
                                    fullWidth
                                />
                            )}

                            {/* Color
                            ========================================= */}
                            <ColorPicker
                                disabled={localValue.colorScale.enabled}
                                value={localValue.normal.color}
                                onChange={updateLocalValue('color', 'normal')}
                            />
                            {/* Border
                            ========================================= */}
                            {['2d', '2d+'].includes(networkVizKind) && (
                                <StyledWidgetAccordion
                                    title="Border"
                                    hasToggle
                                    isToggledOff={!localValue.normal.border.enabled}
                                    onToggle={toggleField('border', 'normal')}
                                >
                                    <BorderWidgetSettings
                                        simple={networkVizKind !== '2d+'}
                                        hasRadius={false}
                                        disabled={!localValue.normal.border.enabled}
                                        defaultValue={localValue.normal.border.settings}
                                        onChange={updateSettingField('border', 'normal')}
                                    />
                                </StyledWidgetAccordion>
                            )}

                            {/* Shadow
                            ========================================= */}
                            {networkVizKind === '2d+' && (
                                <StyledWidgetAccordion
                                    title="Shadow"
                                    hasToggle
                                    isToggledOff={!localValue.normal.shadow.enabled}
                                    onToggle={toggleField('shadow', 'normal')}
                                >
                                    <ShadowPicker
                                        disabled={!localValue.normal.shadow.enabled}
                                        defaultValue={localValue.normal.shadow.settings}
                                        onChange={updateSettingField('shadow', 'normal')}
                                    />
                                </StyledWidgetAccordion>
                            )}

                            {networkVizKind === '2d' && (
                                <StyledSwitch
                                    label="Shadow"
                                    size="small"
                                    onChange={toggleField('shadow', 'normal')}
                                    checked={localValue.normal.shadow.enabled}
                                />
                            )}
                        </Stack>
                        {/* Apply changes button
                        ========================================= */}
                        <BaseButton fullWidth variant="outlined" sx={{ marginTop: 2 }} onClick={applyChanges('normal')}>
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Selected Node Section
                    ========================================= */}
                    <StyledWidgetAccordion title="Selected Node" hasBottomBorder>
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            {/* Size Scale
                            ========================================= */}
                            <Stack>
                                <Typography fontSize={15}>Size Scale</Typography>
                                <ValueSliderPicker
                                    value={localValue.selected?.sizeScale || 1.5}
                                    min={0.1}
                                    max={10}
                                    step={0.1}
                                    onChange={updateLocalValue('sizeScale', 'selected')}
                                />
                            </Stack>
                            {/* Color
                            ========================================= */}
                            <ColorPicker
                                value={localValue.selected.color}
                                onChange={updateLocalValue('color', 'selected')}
                            />
                            {/* Border
                            ========================================= */}
                            {['2d+', '2d'].includes(networkVizKind) && (
                                <StyledWidgetAccordion
                                    title="Border"
                                    hasToggle
                                    isToggledOff={!localValue.selected.border.enabled}
                                    onToggle={toggleField('border', 'selected')}
                                >
                                    <BorderWidgetSettings
                                        hasRadius={false}
                                        simple={networkVizKind !== '2d+'}
                                        disabled={!localValue.selected.border.enabled}
                                        defaultValue={localValue.selected.border.settings}
                                        onChange={updateSettingField('border', 'selected')}
                                    />
                                </StyledWidgetAccordion>
                            )}

                            {/* Shadow
                            ========================================= */}
                            {networkVizKind === '2d+' && (
                                <StyledWidgetAccordion
                                    title="Shadow"
                                    hasToggle
                                    isToggledOff={!localValue.selected.shadow.enabled}
                                    onToggle={toggleField('shadow', 'selected')}
                                >
                                    <ShadowPicker
                                        disabled={!localValue.selected.shadow.enabled}
                                        defaultValue={localValue.selected.shadow.settings}
                                        onChange={updateSettingField('shadow', 'selected')}
                                    />
                                </StyledWidgetAccordion>
                            )}
                            {networkVizKind === '2d' && (
                                <StyledSwitch
                                    label="Shadow"
                                    size="small"
                                    onChange={toggleField('shadow', 'selected')}
                                    checked={localValue.selected.shadow.enabled}
                                />
                            )}
                        </Stack>
                        {/* Apply changes button
                        ========================================= */}
                        <BaseButton
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                            onClick={applyChanges('selected')}
                        >
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Label Style section
                    //ToDo advanced option should be only enabled in 2d+ mode
                    //ToDo in other modes, only field and show
                    //ToDo remove underline option
                    ========================================= */}

                    <StyledWidgetAccordion
                        isToggledOff={!localValue.label.show}
                        onToggle={toggleLabel}
                        hasToggle
                        title="Label Style"
                        hasBottomBorder
                    >
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            <BaseSelectWithLabel
                                disabled={!localValue.label.show}
                                size="small"
                                label="Field"
                                value={localValue.label?.field}
                                onChange={updateLocalValue('field', 'label')}
                                options={Object.keys(nodeDataSchema.fields)}
                                fullWidth
                            />
                            <FontPicker
                                disabled={!localValue.label.show}
                                defaultValue={localValue.label?.font}
                                onChange={updateLocalValue('font', 'label')}
                            />
                        </Stack>
                        <BaseButton fullWidth variant="outlined" sx={{ marginTop: 2 }} onClick={applyChanges('label')}>
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Color Scale
                    ========================================= */}
                    <StyledWidgetAccordion
                        title="Color Scale"
                        hasBottomBorder
                        hasToggle
                        onToggle={(v) => updateLocalValue('enabled', 'colorScale')(v)}
                        isToggledOff={!localValue.colorScale.enabled}
                    >
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            <NodeAttributeSelector
                                attribute={localValue.colorScale.attribute}
                                analytics={analytics}
                                nodeAttributeOptions={nodeDataSchema.numericOptions}
                                onChange={updateLocalValue('attribute', 'colorScale')}
                            />

                            <Stack>
                                <Typography>From</Typography>
                                <ColorPicker
                                    disabled={!localValue.colorScale.enabled}
                                    value={localValue.colorScale.colorFrom}
                                    onChange={updateLocalValue('colorFrom', 'colorScale')}
                                />
                            </Stack>

                            <Stack>
                                <Typography>To</Typography>
                                <ColorPicker
                                    disabled={!localValue.colorScale.enabled}
                                    value={localValue.colorScale.colorTo}
                                    onChange={updateLocalValue('colorTo', 'colorScale')}
                                />
                            </Stack>
                        </Stack>
                        <BaseButton
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                            onClick={applyChanges('colorScale')}
                        >
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>

                    {/* Size Scale
                    ========================================= */}
                    <StyledWidgetAccordion
                        title="Size Scale"
                        hasBottomBorder
                        hasToggle
                        onToggle={(v) => updateLocalValue('enabled', 'sizeScale')(v)}
                        isToggledOff={!localValue.sizeScale.enabled}
                    >
                        {/* Options
                        ========================================= */}
                        <Stack gap={2}>
                            <NodeAttributeSelector
                                attribute={localValue.sizeScale.attribute}
                                analytics={analytics}
                                nodeAttributeOptions={nodeDataSchema.numericOptions}
                                onChange={updateLocalValue('attribute', 'sizeScale')}
                            />

                            <Typography>Size Range</Typography>
                            <RangeSliderSelector
                                min={1}
                                max={100}
                                onChange={updateSizeScale}
                                values={[localValue?.sizeScale?.sizeFrom || 1, localValue?.sizeScale?.sizeTo || 100]}
                                disabled={!localValue.sizeScale.enabled}
                            />
                        </Stack>
                        <BaseButton
                            fullWidth
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                            onClick={applyChanges('sizeScale')}
                        >
                            Apply Changes
                        </BaseButton>
                    </StyledWidgetAccordion>
                </Stack>
            )}
        </React.Fragment>
    )
}
