//* ======= Libraries
import { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import {
    Box,
    Checkbox,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material'
//* ======= Components and features
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    NetworkWidgetType,
    ReportNetworkDataSourceType,
    ReportWidgetSettingsBaseType,
    ReportDataSourceAttributeType,
} from 'features/report-designer/types/reportDesigner.types'
import {
    convertNodeAttributeToLabel,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { KeysOfUnion } from 'features/network-viz/types/NetworkViz.types'
import { deepMergeCustom } from 'helpers/helpers'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import { isEqual } from 'lodash'
//* ======= Assets and styles
import DeleteIcon from '@mui/icons-material/Delete'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
import ConnectedWidgetsSettings from '../components/ConnectedWidgetsSettings'
import ColorPicker from 'components/widgets/ColorPicker'

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'network'>
    settings: Omit<ReportWidgetType, 'content'>
    details: NetworkWidgetType
}

function NetworkWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { dataSources, updateWidget, updateWidgetStyles, updateNetworkWidgetContent } = useReportStore((store) => ({
        dataSources: store.dataSources,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updateNetworkWidgetContent: store.updateNetworkWidgetContent,
    }))

    const activeSlide = useReportActiveSlide()
    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'network') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const [networkVizOptions, setNetworkVizOptions] = useState<BaseSelectWithLabelOptionType>([
        { label: 'Not selected', value: -1 },
    ])
    const [presetOptions, setPresetOptions] = useState<BaseSelectWithLabelOptionType>([
        { label: 'Default', value: 'default' },
    ])
    const [numericOptions, setNumericOptions] = useState<FlexibleSelectOptionType[]>([])
    const [groupByOptions, setGroupByOptions] = useState<FlexibleSelectOptionType[]>([])

    // Filter "network" data sources and save them for select options.
    useEffect(() => {
        const options: BaseSelectWithLabelOptionType = [{ label: 'Not selected', value: -1 }]

        dataSources.forEach((_dataSource) => {
            if (_dataSource.mode === 'network') {
                options.push({
                    label: _dataSource.title,
                    value: _dataSource.id,
                })
            }
        })

        setNetworkVizOptions(options)
    }, [dataSources])

    // After selected network data source changes, update "preset" and "numeric" select options.
    useEffect(() => {
        if (widget?.details.networkDatasourceId === undefined) return

        const presetOptions: BaseSelectWithLabelOptionType = []

        if (widget.details.networkDatasourceId !== -1) {
            const selectedNetwork = dataSources.find(
                (x) => x.id === widget.details.networkDatasourceId
            ) as ReportNetworkDataSourceType
            presetOptions.push(...Object.keys(selectedNetwork.presets))
            setNumericOptions(
                getDataSourceFields(
                    dataSources,
                    {
                        datasourceType: 'network',
                        filters: [],
                        id: widget.details.networkDatasourceId,
                        preset: widget.details.selectedPreset,
                    },
                    true,
                    false
                )
            )
            setGroupByOptions(
                getDataSourceFields(
                    dataSources,
                    {
                        datasourceType: 'network',
                        filters: [],
                        id: widget.details.networkDatasourceId,
                        preset: widget.details.selectedPreset,
                    },
                    false,
                    false
                )
            )
        }

        setPresetOptions(presetOptions)
    }, [dataSources, networkVizOptions, widget?.details.selectedPreset, widget?.details.networkDatasourceId])

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlide !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<NetworkWidgetType>) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            deepMergeCustom(updatedDetails, details)

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }

    const updateLegendDetails = (details: PartialDeep<NetworkWidgetType['legend']>) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            updatedDetails.legend = deepMergeCustom(updatedDetails.legend, details)

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }

    const updateSizeScaleDetails = (details: PartialDeep<NetworkWidgetType['sizeScale']>) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            updatedDetails.sizeScale = deepMergeCustom(updatedDetails.sizeScale, details)

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }

    const updateGroupbyDetails = (details: PartialDeep<NetworkWidgetType['groupBy']>) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            updatedDetails.groupBy = deepMergeCustom(updatedDetails.groupBy, details)

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }

    const updatePreset = (preset: string) => {
        const selectedDataSource = dataSources.find(
            (x) => x.id === widget?.details.networkDatasourceId
        ) as ReportNetworkDataSourceType
        const isShrinkMode = selectedDataSource?.presets[preset]?.networkShrink?.enabled || false
        const groupBy = selectedDataSource?.presets[preset]?.nodeGroupBy
        updateDetails({
            selectedPreset: preset,
            shrinkMode: isShrinkMode,
            nodeGroupBy: groupBy,
        })
    }

    const toggleNetworkComponentShow = (isEnabled: boolean) => {
        updateDetails({
            networkAnalyticsPanel: {
                show: isEnabled,
            },
        })
    }

    const toggleNetworkComponentItemShow = (
        key: KeysOfUnion<NetworkWidgetType['networkAnalyticsPanel']['components']>,
        isEnabled: boolean
    ) => {
        updateDetails({
            networkAnalyticsPanel: {
                components: {
                    [key]: {
                        show: isEnabled,
                    },
                },
            },
        })
    }

    const updateTop10Fields = (
        key: keyof NetworkWidgetType['networkAnalyticsPanel']['components']['top10'],
        value: any
    ) => {
        updateDetails({
            networkAnalyticsPanel: {
                components: {
                    top10: {
                        [key]: value,
                    },
                },
            },
        })
    }

    // Logic to add, remove, and update size scale options.

    const handleSizeScaleAdd = (item: ReportDataSourceAttributeType) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            updatedDetails.sizeScale.fields.push({
                field: item,
                label: item.field,
            })

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }
    const handleSizeScaleRemove = (item: ReportDataSourceAttributeType) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            updatedDetails.sizeScale.fields = updatedDetails.sizeScale.fields.filter(
                (_field) => !isEqual(_field.field, item)
            )

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }

    const handleSizeScaleUpdate = (item: ReportDataSourceAttributeType, label: string) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            const targetFieldIndex = updatedDetails.sizeScale.fields.findIndex((_field) => isEqual(_field.field, item))

            if (targetFieldIndex !== -1) {
                updatedDetails.sizeScale.fields[targetFieldIndex].label = label

                updateNetworkWidgetContent({
                    slideId: activeSlide.id,
                    widgetId: widget.settings.id,
                    data: updatedDetails,
                })
            }
        }
    }

    // Logic to add, remove, and update group by options.

    const handleGroupbyAdd = (item: ReportDataSourceAttributeType) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            updatedDetails.groupBy.fields.push({
                field: item,
                label: item.field,
            })

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }
    const handleGroupbyRemove = (item: ReportDataSourceAttributeType) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            updatedDetails.sizeScale.fields = updatedDetails.groupBy.fields.filter(
                (_field) => !isEqual(_field.field, item)
            )

            updateNetworkWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: updatedDetails,
            })
        }
    }

    const handleGroupbyUpdate = (item: ReportDataSourceAttributeType, label: string) => {
        if (activeSlide !== null && widget !== null) {
            const updatedDetails = structuredClone(widget.details)

            const targetFieldIndex = updatedDetails.groupBy.fields.findIndex((_field) => isEqual(_field.field, item))

            if (targetFieldIndex !== -1) {
                updatedDetails.groupBy.fields[targetFieldIndex].label = label

                updateNetworkWidgetContent({
                    slideId: activeSlide.id,
                    widgetId: widget.settings.id,
                    data: updatedDetails,
                })
            }
        }
    }

    return (
        <>
            {activeSlide !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={{
                        height: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Network Viz" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,
                                overflowY: 'auto',
                                paddingX: theme.spacing(2),
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Container settings
                                ========================================= */}
                            <Stack gap={2}>
                                {/* Title
                                    ========================================= */}
                                <BaseFilledTextField
                                    label="Widget Title"
                                    defaultValue={widget.settings.title}
                                    onBlur={(evt) =>
                                        updateWidget({
                                            slideId: activeSlide.id,
                                            widgetId: widget.settings.id,
                                            data: {
                                                title: evt.target.value.trim(),
                                            },
                                        })
                                    }
                                    size="small"
                                    fullWidth
                                />

                                {/* Background color */}
                                <StyledWidgetAccordion
                                    title="Background Color"
                                    hasToggle
                                    isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                    onToggle={(isEnabled) =>
                                        updateStyles({
                                            backgroundColor: {
                                                isEnabled: isEnabled,
                                                color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                            },
                                        })
                                    }
                                    hasBottomBorder
                                >
                                    <ColorPicker
                                        value={widget.settings.styles.backgroundColor.color}
                                        onChange={(newColor) =>
                                            updateStyles({
                                                backgroundColor: {
                                                    color: newColor,
                                                },
                                            })
                                        }
                                        disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                    />
                                </StyledWidgetAccordion>

                                {/* Border
                                    ========================================= */}
                                <StyledWidgetAccordion
                                    title="Border"
                                    hasToggle
                                    isToggledOff={widget.settings.styles.border.isEnabled === false}
                                    onToggle={(isEnabled) =>
                                        updateStyles({
                                            border: {
                                                isEnabled: isEnabled,
                                            },
                                        })
                                    }
                                    hasBottomBorder
                                >
                                    <BorderPicker
                                        value={widget.settings.styles.border}
                                        onChange={(value) =>
                                            updateStyles({
                                                border: {
                                                    width: value.width,
                                                    style: value.style,
                                                    color: value.color,
                                                    radius: value.radius,
                                                },
                                            })
                                        }
                                        disabled={widget.settings.styles.border.isEnabled === false}
                                    />
                                </StyledWidgetAccordion>

                                {/* Conditional visibility
                                ========================================= */}
                                <ReportConditionalVisibilitySettings
                                    conditionalVisibility={widget.settings.conditionalVisibility}
                                    onChange={(conditionalVisibility) => {
                                        updateWidget({
                                            slideId: activeSlide.id,
                                            widgetId: widget.settings.id,
                                            data: {
                                                conditionalVisibility,
                                            },
                                        })
                                    }}
                                />

                                {/* Tooltip
                                    ========================================= */}
                                <ReportWidgetTooltipSettings
                                    tooltip={widget.settings.tooltip}
                                    onChange={(tooltip) => {
                                        updateWidget({
                                            slideId: activeSlide.id,
                                            widgetId: widget.settings.id,
                                            data: {
                                                tooltip,
                                            },
                                        })
                                    }}
                                />
                            </Stack>
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,
                                overflowY: 'auto',
                                paddingX: theme.spacing(2),
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Network Viz type
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Network Viz"
                                options={networkVizOptions}
                                value={widget.details.networkDatasourceId}
                                onChange={(value) => {
                                    updateDetails({
                                        networkDatasourceId: value,
                                    })
                                }}
                            />

                            {/* Network preset
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Preset"
                                disabled={widget.details.networkDatasourceId === -1}
                                options={presetOptions}
                                value={widget.details.selectedPreset}
                                onChange={(value) => {
                                    updatePreset(value)
                                }}
                            />

                            {/* Network Display Mode
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Display Mode"
                                disabled={widget.details.networkDatasourceId === -1}
                                options={['2d', '2d+', '3d']}
                                value={widget.details.displayMode}
                                onChange={(value) => {
                                    updateDetails({
                                        displayMode: value,
                                    })
                                }}
                            />

                            <Divider />

                            {/* Search bar switch
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    textAlign="left"
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Search Bar:
                                </Typography>

                                <BaseSwitch
                                    checked={widget.details.displaySearchBar}
                                    onChange={(evt, checked) => updateDetails({ displaySearchBar: checked })}
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>

                            {/* Focus Mode
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    textAlign="left"
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Focus Mode:
                                </Typography>

                                <BaseSwitch
                                    checked={widget.details.focusMode}
                                    onChange={(evt, checked) =>
                                        updateDetails({
                                            focusMode: checked,
                                        })
                                    }
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>

                            {/* run layout after filter
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    textAlign="left"
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Run Layout After Filter:
                                </Typography>

                                <BaseSwitch
                                    checked={widget.details.runLayoutAfterFilter}
                                    onChange={(evt, checked) =>
                                        updateDetails({
                                            runLayoutAfterFilter: checked,
                                        })
                                    }
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>
                            {/* Legend bar
                                ========================================= */}
                            <StyledWidgetAccordion title="Legend" defaultExpanded={true} hasBottomBorder={true}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                    <Typography
                                        fontSize={14}
                                        noWrap
                                        textAlign="left"
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        Nodes
                                    </Typography>

                                    <BaseSwitch
                                        checked={widget.details.legend.node}
                                        onChange={(evt, checked) => updateLegendDetails({ node: checked })}
                                        size="small"
                                        color="primary"
                                        sx={{
                                            flexShrink: 0,
                                        }}
                                    />
                                </Stack>

                                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                    <Typography
                                        fontSize={14}
                                        noWrap
                                        textAlign="left"
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        Edges
                                    </Typography>

                                    <BaseSwitch
                                        checked={widget.details.legend.edge}
                                        onChange={(evt, checked) => updateLegendDetails({ edge: checked })}
                                        size="small"
                                        color="primary"
                                        sx={{
                                            flexShrink: 0,
                                        }}
                                    />
                                </Stack>
                            </StyledWidgetAccordion>

                            {/* Node Size Scale
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Node Size Scale"
                                hasToggle={true}
                                isToggledOff={!widget.details.sizeScale.enabled}
                                onToggle={(isEnabled) => updateSizeScaleDetails({ enabled: isEnabled })}
                                hasBottomBorder={true}
                            >
                                <Stack direction="column" gap={1}>
                                    <BaseSelectWithLabel
                                        label="Placement"
                                        options={['left', 'right']}
                                        value={widget.details.sizeScale.placement}
                                        onChange={(value) => updateSizeScaleDetails({ placement: value })}
                                    />
                                    {/* Button to open the menu to add new size scale option */}
                                    <FlexibleSelect
                                        label="Add"
                                        size="small"
                                        value={null}
                                        onChange={handleSizeScaleAdd}
                                        options={numericOptions}
                                    />

                                    {/* List of selected option */}
                                    {widget.details.sizeScale.fields.map((_item, idx) => {
                                        const itemKey = convertNodeAttributeToLabel(_item.field)
                                        return (
                                            <Stack gap={0.5}>
                                                <Stack
                                                    key={itemKey}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <Tooltip title={itemKey}>
                                                        <Typography variant="caption" textOverflow="ellipsis" noWrap>
                                                            {itemKey}
                                                        </Typography>
                                                    </Tooltip>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleSizeScaleRemove(_item.field)}
                                                    >
                                                        <DeleteIcon color="warning" />
                                                    </IconButton>
                                                </Stack>

                                                <BaseFilledTextField
                                                    defaultValue={_item.label}
                                                    label="Label"
                                                    onBlur={(evt) =>
                                                        handleSizeScaleUpdate(_item.field, evt.target.value.trim())
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Stack>
                                        )
                                    })}
                                </Stack>
                            </StyledWidgetAccordion>

                            {/* Node Group By
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Node Group By"
                                hasToggle={true}
                                isToggledOff={!widget.details.groupBy.enabled}
                                onToggle={(isEnabled) => updateGroupbyDetails({ enabled: isEnabled })}
                                hasBottomBorder={true}
                            >
                                <Stack direction="column" gap={1}>
                                    <BaseSelectWithLabel
                                        label="Placement"
                                        options={['left', 'right']}
                                        value={widget.details.groupBy.placement}
                                        onChange={(value) => updateGroupbyDetails({ placement: value })}
                                        disabled={!widget.details.groupBy.enabled}
                                    />
                                    {/* Button to open the menu to add new size scale option */}
                                    <FlexibleSelect
                                        label="Add"
                                        size="small"
                                        value={null}
                                        onChange={handleGroupbyAdd}
                                        options={groupByOptions}
                                        disabled={!widget.details.groupBy.enabled}
                                    />

                                    {/* List of selected option */}
                                    {widget.details.groupBy.fields.map((_item, idx) => {
                                        const itemKey = convertNodeAttributeToLabel(_item.field)
                                        return (
                                            <Stack gap={0.5}>
                                                <Stack
                                                    key={itemKey}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <Tooltip title={itemKey}>
                                                        <Typography variant="caption" textOverflow="ellipsis" noWrap>
                                                            {itemKey}
                                                        </Typography>
                                                    </Tooltip>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleGroupbyRemove(_item.field)}
                                                    >
                                                        <DeleteIcon color="warning" />
                                                    </IconButton>
                                                </Stack>

                                                <BaseFilledTextField
                                                    defaultValue={_item.label}
                                                    label="Label"
                                                    onBlur={(evt) =>
                                                        handleGroupbyUpdate(_item.field, evt.target.value.trim())
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Stack>
                                        )
                                    })}
                                </Stack>
                            </StyledWidgetAccordion>

                            {/* // ! (Disabled for now, most likely we dont need this feature anymore)
                                Network analytics 
                                ========================================= */}
                            {/* {false && (
                                <StyledWidgetAccordion
                                    title="Network Analytics Components"
                                    hasToggle
                                    isToggledOff={widget.details.networkAnalyticsPanel.show === false}
                                    onToggle={(isEnabled) => toggleNetworkComponentShow(isEnabled)}
                                    isDisabled={widget.details.networkDatasourceId === -1}
                                >
                                    <Stack sx={{ width: '100%', maxWidth: 360 }} gap={1}>
                                        {Object.entries(widget.details.networkAnalyticsPanel.components).map(
                                            ([key, value], idx) => {
                                                return (
                                                    <Stack
                                                        key={key + idx}
                                                        sx={{
                                                            backgroundColor:
                                                                key === 'top10' && value.show
                                                                    ? 'common.bg_2'
                                                                    : 'common.bg_1',
                                                        }}
                                                    >
                                                        <Stack key={key} direction="row" justifyContent="space-between">
                                                            <Typography>{key}</Typography>
                                                            <Checkbox
                                                                disabled={!widget.details.networkAnalyticsPanel.show}
                                                                edge="end"
                                                                checked={value.show}
                                                                onChange={(evt, checked) =>
                                                                    toggleNetworkComponentItemShow(key as any, checked)
                                                                }
                                                            />
                                                        </Stack>

                                                        <Collapse in={key === 'top10' && value.show}>
                                                            <Stack gap={1} sx={{ backgroundColor: 'common.bg_2' }}>
                                                                <BaseSelectWithLabel
                                                                    label="Top10 Field"
                                                                    options={numericOptions}
                                                                    value={
                                                                        (
                                                                            value as NetworkWidgetType['networkAnalyticsPanel']['components']['top10']
                                                                        ).field
                                                                    }
                                                                    onChange={(value) =>
                                                                        updateTop10Fields('field', value)
                                                                    }
                                                                    fullWidth
                                                                />
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="top10-sort-type-radio-buttons-group"
                                                                    name="top10-sort-type-radio-buttons-group"
                                                                    value={
                                                                        (
                                                                            value as NetworkWidgetType['networkAnalyticsPanel']['components']['top10']
                                                                        ).sort
                                                                    }
                                                                    onChange={(evt) =>
                                                                        updateTop10Fields('sort', evt.target.value)
                                                                    }
                                                                >
                                                                    <FormControlLabel
                                                                        value={1}
                                                                        control={<Radio />}
                                                                        label="Top 10"
                                                                    />
                                                                    <FormControlLabel
                                                                        value={-1}
                                                                        control={<Radio />}
                                                                        label="Bottom 10"
                                                                    />
                                                                </RadioGroup>
                                                            </Stack>
                                                        </Collapse>
                                                    </Stack>
                                                )
                                            }
                                        )}
                                    </Stack>
                                </StyledWidgetAccordion>
                            )} */}

                            <Divider />

                            {/* Controllable widgets
                                ========================================= */}
                            <ConnectedWidgetsSettings
                                connectedWidgets={widget.details.connectedWidgets}
                                onConnectedWidgetsUpdate={(newConnectedWidgets) =>
                                    updateDetails({ connectedWidgets: newConnectedWidgets })
                                }
                                slideWidgets={activeSlide.widgets}
                                selectedWidgetId={widgetId}
                            />
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default NetworkWidgetSettings
