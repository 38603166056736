import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GetSurveySuggestionsService, getOpenParticipationSurveyService } from 'services/SurveyService'
import { NodeType } from 'services/NodeService'
import {
    GetSurveyInstance,
    GetSurveyInstancePublicService,
    GetSurveyInstanceResponseType,
    SurveyInstanceType,
} from 'services/SurveyInstanceService'
import { e } from 'mathjs'

interface SurveyInstanceContextTypes {
    suggestions: { [key: string]: NodeType[] }
    surveyInstance?: SurveyInstanceType
    fetchSurveyInstance: () => void
    fetchSuggestions: () => void
}

const SurveyInstanceContext = createContext<SurveyInstanceContextTypes>({
    suggestions: {},
    surveyInstance: undefined,
    fetchSurveyInstance: () => {},
    fetchSuggestions: () => {},
})

export { SurveyInstanceContext }

interface SurveyInstanceContextProviderTypes {
    children: React.ReactNode
    isOpenParticipation?: boolean
    isPublic?: boolean
}

export default function SurveyInstanceContextProvider({
    children,
    isOpenParticipation = false,
    isPublic = false,
}: SurveyInstanceContextProviderTypes) {
    const { sid, pid, token } = useParams()

    const [suggestions, setSuggestions] = useState<{ [key: string]: NodeType[] }>({})
    const [surveyInstance, setSurveyInstance] = useState<SurveyInstanceType>()

    const fetchSuggestions = () => {
        //ToDo show error messages
        if (sid && pid) {
            GetSurveySuggestionsService(pid, sid)
                .then((result) => result.success && setSuggestions(result.data))
                .finally(() => {})
        } else setSuggestions({})
    }

    const fetchSurveyInstance = async () => {
        //ToDo show error messages

        let response: GetSurveyInstanceResponseType | null = null

        // based on the participation type, fetch the survey instance
        if (isOpenParticipation) {
            if (isPublic) {
                if (token) {
                    response = await GetSurveyInstancePublicService(token, false)
                }
            } else {
                if (pid && sid) {
                    response = await getOpenParticipationSurveyService(pid, sid, false)
                }
            }
        } else {
            if (token) {
                response = await GetSurveyInstance(token)
            }
        }

        if (response && response.success) {
            const { suggestions, ...instance } = response.data
            setSuggestions(response.data.suggestions)
            setTimeout(() => setSurveyInstance(instance), 2000)
        } else setSuggestions({})
    }

    return (
        <SurveyInstanceContext.Provider value={{ suggestions, fetchSurveyInstance, fetchSuggestions, surveyInstance }}>
            {children}
        </SurveyInstanceContext.Provider>
    )
}
