//* ======= Libraries
import React, { useEffect, useState } from 'react'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import BaseSelect from 'components/base/BaseSelect'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
//* ======= Custom logic
//* ======= Assets and styles

const widthOptions = Array.from(Array(10)).map((_, idx) => ({
    name: idx + 1 + ' px',
    value: idx + 1 + 'px',
}))

const borderStyleOptions: Array<BorderPickerValueType['style']> = ['solid', 'dashed', 'dotted']

const radiusOptions: Array<{ name: string; value: string | number }> = Array.from(Array(17)).map(
    (_, idx) => ({
        name: idx + ' px',
        value: idx,
    })
)

export type BorderPickerValueType = {
    width: string
    style: 'solid' | 'dashed' | 'dotted'
    color: string | undefined
    radius: number | string
}

type Props = {
    value: BorderPickerValueType
    onChange: (value: BorderPickerValueType) => void
    hasRadius?: boolean
    disabled?: boolean
}

function BorderPicker({ value, onChange, disabled = false, hasRadius = true }: Props) {
    const [pickerValue, setPickerValue] = useState<BorderPickerValueType>(value)

    const onFieldUpdate = (
        field: keyof BorderPickerValueType,
        value: BorderPickerValueType[keyof BorderPickerValueType]
    ) => {
        setPickerValue((prevState) => ({
            ...prevState,
            [field]: value,
        }))
    }

    useEffect(() => {
        if (value !== undefined && pickerValue !== value) {
            onChange(pickerValue)
        }
    }, [pickerValue])

    return (
        <Stack gap={1}>
            {/* Width and style
				========================================= */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                sx={{
                    marginBottom: 1,
                }}
            >
                {/* Width
					========================================= */}
                <BaseSelect
                    placeholder="Width"
                    options={widthOptions}
                    value={pickerValue.width}
                    onChangeHandler={(value) => onFieldUpdate('width', value)}
                    disabled={disabled}
                    isSlim
                    maxHeight={274}
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Style
					========================================= */}
                <BaseSelect
                    placeholder="Style"
                    options={borderStyleOptions}
                    value={pickerValue.style}
                    onChangeHandler={(value) => onFieldUpdate('style', value)}
                    disabled={disabled}
                    isSlim
                    maxHeight={274}
                    sx={{
                        flex: '1 0 0',
                    }}
                />
            </Stack>

            <Typography fontSize={14} fontWeight={400} textAlign="left" noWrap>
                Border Color
            </Typography>

            {/* Border color
				========================================= */}
            <ColorPicker
                value={pickerValue.color}
                onChange={(value) => onFieldUpdate('color', value)}
                disabled={disabled}
                isPopover={true}
            />

            {/* Radius
				========================================= */}
            {hasRadius && (
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        noWrap
                        sx={{
                            flex: '1 0 0',
                        }}
                    >
                        Border Radius
                    </Typography>

                    <BaseSelect
                        placeholder="Radius"
                        options={radiusOptions.concat([
                            {
                                name: 'Circle',
                                value: '50%',
                            },
                            {
                                name: 'Pill',
                                value: '100rem',
                            },
                        ])}
                        value={pickerValue.radius}
                        onChangeHandler={(value) => onFieldUpdate('radius', value)}
                        disabled={disabled}
                        isSlim
                        maxHeight={274}
                        sx={{
                            flex: '1 0 0',
                        }}
                    />
                </Stack>
            )}
        </Stack>
    )
}

export default BorderPicker
