//* ======= Libraries
import React, { useMemo } from 'react'
import { isEqual } from 'lodash'
import {
    Checkbox,
    Divider,
    ListItemText,
    ListSubheader,
    TextFieldProps,
    SelectProps,
    MenuItem,
    Tooltip,
    IconButton,
} from '@mui/material'
//* ======= Components and features
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

/**
 * TODO:
 *  1.  Add list virtualization (windowing) to the rendered list items so that big lists of items don't cause
 *      performance issues.
 */

type ToBeDefinedTempKeyValueType = { value: string | number | any; label: string; section?: boolean }

export type BaseSelectWithLabelOptionType = Array<string | number | ToBeDefinedTempKeyValueType | undefined>

interface IBaseSelectWithLabel extends Omit<TextFieldProps, 'variant'> {
    options?: BaseSelectWithLabelOptionType
    value?: any
    onChange?: (value: any) => void
    hasClearButton?: boolean
    label?: string
    placeholder?: string
    maxHeight?: number | string
    selectProps?: SelectProps
}

function BaseSelectWithLabel({
    options = [],
    value,
    onChange,
    hasClearButton = false,
    label,
    placeholder,
    maxHeight = 450,
    selectProps,
    ...props
}: IBaseSelectWithLabel) {
    const parsedOptions = useMemo(() => {
        const res: Array<{ label: string; value: any; section?: boolean }> = []

        if (options.length > 0) {
            options.forEach((_option) => {
                if (typeof _option === 'string' || typeof _option === 'number') {
                    res.push({ label: _option.toString(), value: _option, section: false })
                } else {
                    _option !== undefined &&
                        res.push({
                            ..._option,
                            section: (_option as ToBeDefinedTempKeyValueType)?.section || false,
                        })
                }
            })
        }
        return res
    }, [options])

    const renderOption = (
        _option: {
            label: string
            value: any
            section?: boolean | undefined
        },
        idx: number
    ) => {
        if (_option.section) {
            return (
                <ListSubheader key={_option.label + idx}>
                    {selectProps?.multiple ? (
                        _option.label
                    ) : (
                        <>
                            <Divider sx={{ marginX: 1 }} />
                            {_option.label}
                        </>
                    )}
                </ListSubheader>
            )
        }

        const isSelected = Array.isArray(value)
            ? value.findIndex((x: any) => isEqual(x, _option.value)) > -1
            : isEqual(value, _option.value)

        return (
            <MenuItem
                key={_option.label + idx}
                selected={isSelected}
                // autoFocus={isSelected}
                value={_option.value}
            >
                {selectProps?.multiple ? (
                    <>
                        <Checkbox checked={isSelected} />
                        <ListItemText primary={_option.label} />
                    </>
                ) : (
                    _option.label
                )}
            </MenuItem>
        )
    }

    return (
        <BaseFilledTextField
            select
            SelectProps={{
                value: value === null || value === undefined ? '' : value,
                defaultValue: '',
                onChange: (evt) => {
                    // when value is an object, un-select not working properly. The logic below resolve that probelm
                    if (Array.isArray(evt.target.value) && typeof options?.[0] === 'object') {
                        const result: any = []
                        for (let item of evt.target.value) {
                            const index = result.findIndex((x: any) => isEqual(x, item))
                            if (index === -1) {
                                result.push(item)
                            } else {
                                result.splice(index, 1)
                            }
                        }
                        onChange && onChange(result)
                    } else {
                        onChange && onChange(evt.target.value)
                    }
                },
                renderValue: (selectedValue: any) => {
                    if (selectProps && selectProps.multiple === true) {
                        if (selectedValue.length === 0) {
                            return placeholder || ''
                        } else {
                            return selectedValue
                                .map((_value: any) => {
                                    return parsedOptions.find((_option) => isEqual(_option.value, _value))?.label
                                })
                                .join(', ')
                        }
                    } else {
                        if (selectedValue === '') {
                            return placeholder || ''
                        } else {
                            return parsedOptions.find((_option) => isEqual(_option.value, selectedValue))?.label
                        }
                    }
                },
                displayEmpty: true,
                IconComponent: ExpandMoreIcon,
                MenuProps: {
                    ...selectProps?.MenuProps,
                    PaperProps: {
                        className: 'u-scrollbar',
                        ...selectProps?.MenuProps?.PaperProps,
                    },
                    sx: {
                        maxHeight: maxHeight,
                        ...selectProps?.MenuProps?.sx,
                    },
                },
                sx: (theme) => ({
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',

                    // Select
                    '& .MuiSelect-select': {
                        '&[aria-expanded=true]': {
                            backgroundColor: theme.palette.common.bg_1,
                        },
                    },

                    '& .MuiSelect-icon': {
                        color: props.disabled ? 'secondary.disabled' : 'primary.main',
                    },
                }),

                ...selectProps,
            }}
            label={label}
            InputProps={{
                endAdornment:
                    hasClearButton && value != null && (Array.isArray(value) ? value.length > 0 : value !== '') ? (
                        <Tooltip title="Clear">
                            <IconButton
                                sx={{ marginRight: 2 }}
                                onClick={() => onChange && onChange(selectProps?.multiple ? [] : '')}
                            >
                                <CloseRoundedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null,
            }}
            {...props}
        >
            {parsedOptions.map(renderOption)}
        </BaseFilledTextField>
    )
}

export default BaseSelectWithLabel
