import {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import { NetworkKeyFrameType } from 'features/network-viz/types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { applyFilter } from '../filter/NetworkFilter.helpers'
import { ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import { evaluateRule } from 'features/report-designer/widgets/insight-widget/InisghtWidget.helper'
import { TextToSpeechService } from 'services/AIService'
import { isEqual } from 'lodash'
import usePreviousValue from 'hooks/usePreviousValue'
import { round } from 'mathjs'

const getRuleVariableValue = (field: ReportDataSourceAttributeType, analytics: NetworkVizContextType['analytics']) => {
    try {
        if (analytics === null) return null
        switch (field.type) {
            case 'alaam':
                const alaam = analytics[field.relationship].alaam?.[field.targetField]

                if (alaam === undefined) return null

                if (field.metric === 'converged') return alaam.converged
                if (field.metric === 'log_likelihood') return alaam.log_likelihood
                if (field.metric === 'pseudo_r_squared') return alaam.pseudo_r_squared

                const alaamField = alaam.params_stats.find((param) => param.name === field.field)

                if (alaamField === undefined) return null

                if (field.metric in alaamField) {
                    const metric = field.metric as keyof typeof alaamField
                    return alaamField[metric]
                }
                return null

            case 'ergm':
                const ergm = analytics[field.relationship].ergm

                if (ergm === undefined) return null

                if (field.metric === 'converged') return ergm.converged
                if (field.metric === 'log_likelihood') return ergm.log_likelihood
                if (field.metric === 'pseudo_r_squared') return ergm.pseudo_r_squared

                const ergmField = ergm.params_stats.find((param) => param.name === field.field)

                if (ergmField === undefined) return null

                if (field.metric in ergmField) {
                    const metric = field.metric as keyof typeof ergmField
                    return ergmField[metric]
                }
                return null

            case 'graph':
                const graph = analytics[field.relationship].graph
                if (graph === undefined) return null
                return graph[field.field] ?? null
        }
    } catch (e) {
        console.error(e)
    }
    return null
}

export const isKeyframeApplicable = (keyFrame: NetworkKeyFrameType, analytics: NetworkVizContextType['analytics']) => {
    for (let rule of keyFrame.rule) {
        if (rule.varaible === null) continue
        const value = getRuleVariableValue(rule.varaible, analytics)

        const isValid = evaluateRule(rule, value)

        if (!isValid) {
            return false
        }
    }
    return true
}

// Hook to evaluate keyframes based on network metrics
const evaluateKeyframes = (keyFrames: NetworkKeyFrameType[], analytics: NetworkVizContextType['analytics']) => {
    // Implementation goes here
    const result: NetworkKeyFrameType[] = []
    for (let keyFrame of keyFrames) {
        if (isKeyframeApplicable(keyFrame, analytics)) {
            result.push(keyFrame)
        }
    }
    return result
}

const fields = (anlytics: NetworkVizContextType['analytics']) => {
    if (anlytics === null) return {}
    const result: Record<string, ReportDataSourceAttributeType> = {}
    for (const networkName in anlytics) {
        if (networkName === 'view' || networkName === 'all') continue
        for (let metric in anlytics[networkName].graph) {
            result[`[${networkName}.graph.${metric}]`] = {
                type: 'graph',
                relationship: networkName,
                field: metric,
            }
        }
    }
    return result
}

const parseKeyframeDescription = (description: string, analytics: NetworkVizContextType['analytics']) => {
    const fieldsMap = fields(analytics)
    const parser = new DOMParser()
    const doc = parser.parseFromString(description, 'text/html')
    let text = doc.body.textContent || ''
    const matches = text.match(/\[(.*?)\]/g) || []
    for (let match of matches) {
        if (match in fieldsMap) {
            const _variable = fieldsMap[match]
            if (_variable === undefined) continue
            let value = getRuleVariableValue(_variable, analytics)
            if (typeof value === 'number') {
                value = round(value, 2)
            }
            text = text.replace(match, value)
        }
    }
    return text
}

const splitTextIntoChunks = (text: string, chunkSize: number = 200) => {
    const result = []
    const sentences = text.split(/(?<=[.])|(?=\n)/) // Split text by period or newline while including the period

    let currentChunk = ''

    for (let i = 0; i < sentences.length; i++) {
        const sentence = sentences[i]

        if ((currentChunk + sentence).length <= chunkSize) {
            currentChunk += sentence
        } else {
            if (currentChunk.length > 0) {
                result.push(currentChunk.trim())
                currentChunk = sentence
            } else {
                result.push(sentence.trim())
            }
        }
    }

    if (currentChunk.length > 0) {
        result.push(currentChunk.trim())
    }

    return result
}

type VoiceOversType = Record<
    string,
    Array<{
        audio: HTMLAudioElement
        text: string
        isLast: boolean
    }>
>
// Custom hook to run the keyframe animation
const useKeyframeAnimation = () => {
    const networkVizContext = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [subTitle, setSubTitle] = useState<string>('')
    const [isPlaying, setIsPlaying] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [currentKeyframe, setCurrentKeyframe] = useState<String | null>(null)
    const [hasKeyframes, setHasKeyframes] = useState(false)

    const applicableKeyframes = useRef<NetworkKeyFrameType[]>([])
    const currentKeyframeIndex = useRef(0)
    const voiceOvers = useRef<VoiceOversType>({})
    const networkVizContextRef = useRef<NetworkVizContextType | null>(null)

    const prevKeyframes = usePreviousValue(networkVizContext.keyFrames)

    useEffect(() => {
        if (isReady && isEqual(prevKeyframes, networkVizContext.keyFrames)) return
        setIsReady(false)
        networkVizContextRef.current = networkVizContext
        const evaluatedKeyframes = evaluateKeyframes(networkVizContext.keyFrames, networkVizContext.analytics)
        setHasKeyframes(evaluatedKeyframes.length > 0)
        applicableKeyframes.current = evaluatedKeyframes
        loadVoiceOvers(evaluatedKeyframes)
    }, [networkVizContext.keyFrames, networkVizContext.analytics])

    const loadAndSpeakKeyframe = useCallback(
        async (index: number) => {
            const keyframe = applicableKeyframes.current[index]
            setCurrentKeyframe(keyframe.id)
            const newState = await loadNetworkKeyFrame(keyframe, networkVizContextRef.current!)
            dispatchContext({ type: 'PRESET_LOAD', payload: newState })
            if ((voiceOvers.current[keyframe.id]?.length ?? 0) > 0) {
                voiceOvers.current[keyframe.id][0].audio.play()
                setSubTitle(voiceOvers.current[keyframe.id][0].text)
            }
        },
        [applicableKeyframes, voiceOvers]
    )

    const loadKeyFrame = useCallback(
        async (index: number) => {
            const keyframe = applicableKeyframes.current[index]
            const newState = await loadNetworkKeyFrame(keyframe, networkVizContextRef.current!)
            dispatchContext({ type: 'PRESET_LOAD', payload: newState })
        },
        [applicableKeyframes]
    )

    const loadVoiceOvers = useCallback(async (keyframes: NetworkKeyFrameType[]) => {
        const result: VoiceOversType = {}

        const loadAudioPromises: Promise<void>[] = []

        for (let keyframe of keyframes) {
            const text = parseKeyframeDescription(keyframe.description, networkVizContextRef.current!.analytics)

            const chunks = splitTextIntoChunks(text)

            for (let i = 0; i < chunks.length; i++) {
                const audioUrl = await TextToSpeechService(chunks[i])

                // fetch audio
                const audio = new Audio(audioUrl.data)

                const loadAudioPromise = new Promise<void>((resolve, reject) => {
                    audio.oncanplaythrough = () => {
                        resolve()
                    }
                    audio.onerror = reject
                })

                loadAudioPromises.push(loadAudioPromise)

                audio.onended = () => {
                    if (i === chunks.length - 1) {
                        const nextIndex = currentKeyframeIndex.current + 1
                        if (nextIndex < keyframes.length) {
                            currentKeyframeIndex.current = nextIndex
                            loadAndSpeakKeyframe(nextIndex)
                        } else {
                            loadKeyFrame(0)
                            setIsPlaying(false)
                        }
                    } else {
                        result[keyframe.id][i + 1].audio.play()
                        setSubTitle(result[keyframe.id][i + 1].text)
                    }
                }

                if (result[keyframe.id] === undefined) {
                    result[keyframe.id] = []
                }
                result[keyframe.id].push({
                    audio,
                    text: chunks[i],
                    isLast: i === chunks.length - 1,
                })
            }
        }
        try {
            await Promise.all(loadAudioPromises)
            voiceOvers.current = result
            setIsReady(true)
        } catch (error) {
            console.error('Error loading audio files:', error)
        }
    }, [])

    const startAnimation = useCallback(() => {
        setIsPlaying(true)
        currentKeyframeIndex.current = 0
        loadAndSpeakKeyframe(0)
    }, [loadAndSpeakKeyframe])

    const muteAnimation = useCallback(() => {}, [])

    const pauseAnimation = useCallback(() => {
        for (let key in voiceOvers.current) {
            voiceOvers.current[key].forEach((voiceOver) => {
                voiceOver.audio.pause()
            })
        }
        setIsPlaying(false)
    }, [])

    const stopAnimation = useCallback(() => {
        for (let key in voiceOvers.current) {
            voiceOvers.current[key].forEach((voiceOver) => {
                voiceOver.audio.pause()
                voiceOver.audio.currentTime = 0
            })
        }
        currentKeyframeIndex.current = 0
        setIsPlaying(false)
        setSubTitle('')
    }, [])

    const resumeAnimation = useCallback(() => {
        voiceOvers.current[currentKeyframeIndex.current]?.[0].audio.play()
        setIsPlaying(true)
    }, [])

    const playKeyframe = useCallback(
        async (id: string | number) => {
            stopAnimation()
            const keyFrame = networkVizContext.keyFrames.find((keyframe) => keyframe.id === id)
            if (keyFrame === undefined) return

            const newState = await loadNetworkKeyFrame(keyFrame, networkVizContext)
            dispatchContext({ type: 'PRESET_LOAD', payload: newState })
            const frameVoiceOver = voiceOvers.current[keyFrame.id]

            if (frameVoiceOver === undefined) return

            const audio = frameVoiceOver[0].audio
            audio.play()
            setSubTitle(frameVoiceOver[0].text)
        },
        [networkVizContext.keyFrames, networkVizContext.analytics]
    )

    return {
        startAnimation,
        muteAnimation,
        pauseAnimation,
        stopAnimation,
        resumeAnimation,
        isPlaying,
        playKeyframe,
        subTitle,
        currentKeyframe,
        isReady,
        hasKeyframes,
    }
}

export const loadNetworkKeyFrame = async (keyframe: NetworkKeyFrameType, networkVizContext: NetworkVizContextType) => {
    const newState = structuredClone(networkVizContext)
    newState.edgeStyle = keyframe.networkConfig.edgeStyle
    newState.nodeStyle = keyframe.networkConfig.nodeStyle
    newState.filters = keyframe.networkConfig.filter
    newState.selectedItem = keyframe.networkConfig.selectedNode ?? null
    newState.nodeGroupBy = keyframe.networkConfig.nodeGroupBy
    newState.edgeGroupBy = keyframe.networkConfig.edgeGroupBy
    newState.dimOneWayEdges = keyframe.networkConfig.dimOneWayEdges
    newState.highlightReciprocatedEdges = keyframe.networkConfig.highlightReciprocatedEdges
    newState.onlyShowReciprocatedEdges = keyframe.networkConfig.onlyShowReciprocatedEdges

    // apply node group by

    const nodeGroupByWorker = new WebWorker('workers/network/group-by-nodes.js')
    const groupByResult = (await nodeGroupByWorker.run({
        nodeRenders: newState.nodeRenders,
        edgeRenders: newState.edgeRenders,
        nodes: newState.nodes,
        groupBys: newState.nodeGroupBy,
        nodeStyle: newState.nodeStyle,
        analytics: newState.analytics,
        dataSchema: newState.nodeDataSchema.fields,
    })) as any

    newState.nodeRenders = groupByResult.nodeRenders
    newState.nodeStyle = groupByResult.nodeStyle
    newState.legend.nodes = groupByResult.nodeLegend
    newState.nodeGroupInfo = groupByResult.nodeGroupInfo

    for (let key in keyframe.networkConfig.nodeStyle) {
        if (key in newState.nodeStyle) {
            newState.nodeStyle[key] = keyframe.networkConfig.nodeStyle[key]
        }
    }

    // apply Node Style
    const nodeStyleworker = new WebWorker('workers/network/change-node-style-worker.js')

    newState.nodeRenders = (await nodeStyleworker.run({
        nodes: newState.nodes,
        nodeRenders: newState.nodeRenders,
        dataSchema: newState.nodeDataSchema.fields,
        analytics: newState.analytics,
        nodeStyle: newState.nodeStyle,
    })) as any

    // apply edge group by
    const edgeGroupbyWorker = new WebWorker('workers/network/group-by-edges.js')
    const edgeGroupByResult = (await edgeGroupbyWorker.run({
        edgeRenders: newState.edgeRenders,
        edges: newState.edges,
        groupBy: newState.edgeGroupBy,
        edgeStyle: newState.edgeStyle,
        dataSchema: newState.edgeDataSchema.fields,
    })) as any

    newState.edgeRenders = edgeGroupByResult.edgeRenders
    newState.edgeStyle = edgeGroupByResult.edgeStyle
    newState.legend.edges = edgeGroupByResult.edgeLegend

    for (let key in keyframe.networkConfig.edgeStyle) {
        if (key in newState.edgeStyle) {
            newState.edgeStyle[key] = keyframe.networkConfig.edgeStyle[key]
        }
    }

    // apply edge style
    const edgeStyleWorker = new WebWorker('workers/network/change-edge-style-worker.js')

    newState.edgeRenders = (await edgeStyleWorker.run({
        edges: newState.edges,
        edgeRenders: newState.edgeRenders,
        dataSchema: newState.edgeDataSchema.fields,
        edgeStyle: newState.edgeStyle,
    })) as any

    // apply filters
    const filterOutcome = await applyFilter(newState, newState.filters, false)

    newState.edgeRenders = filterOutcome.edgeRenders
    newState.nodeRenders = filterOutcome.nodeRenders
    newState.nodeAdjacentList = filterOutcome.nodeAdjacentList
    newState.analytics = filterOutcome.analytics

    return newState
}

export default useKeyframeAnimation
