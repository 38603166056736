//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { Stack, Box, alpha, lighten, useTheme } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import { PanelWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import WidgetContent from '../WidgetContent'
import BaseButton from 'components/base/BaseButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
//* ======= Assets and styles

type Props = {
    data: PanelWidgetType
    scaleFactor: number
    widgetId: ReportWidgetType['id']
    onReady: () => void
    isActive: boolean
    viewMode: 'design' | 'preview'
}

function PanelWidget({ data, scaleFactor, widgetId, onReady, isActive, viewMode }: Props) {
    const activeSlide = useReportActiveSlide()
    const panelWidget = useReportActiveWidget(widgetId)

    const { updateSlideVariable, updatePanelWidgetContent } = useReportStore((store) => ({
        updateSlideVariable: store.updateSlideVariable,
        updatePanelWidgetContent: store.updatePanelWidgetContent,
    }))

    const muiTheme = useTheme()

    useEffect(() => {
        onReady()
    }, [])

    const scaledWidth = useMemo(() => {
        if (!panelWidget) return 0
        return panelWidget?.dimensions.width * scaleFactor
    }, [panelWidget, scaleFactor])

    const scaledHeight = useMemo(() => {
        if (!panelWidget) return 0
        return panelWidget?.dimensions.height * scaleFactor
    }, [panelWidget, scaleFactor])

    const getOutlineValue = (widgetId: ReportWidgetType['id']) => {
        if (isActive && viewMode === 'design') {
            // Active (selected)
            if (widgetId === data.selectedWidgetId) {
                return `2px solid ${lighten(muiTheme.palette.primary.main, 0.5)}`
            }
            // Idle (un-selected)
            else {
                return `1px dashed ${alpha(muiTheme.palette.common.border_1, 0.25)}`
            }
        } else {
            return undefined
        }
    }

    const [layers, setLayers] = useState<Record<number, string[]>>({})
    const [activeViewPerLayer, setActiveViewPerLayer] = useState<Record<number, string | null>>({})

    useEffect(() => {
        const _layers: Record<number, string[]> = {}

        const getParents = (viewId: string, parents: string[]): string[] => {
            const layer = data.views[viewId]
            if (!layer) return parents
            const newParents = [...parents, viewId]
            if (layer.parent) return getParents(layer.parent, newParents)
            return newParents
        }

        Object.keys(data.views).forEach((viewId) => {
            const parents = getParents(viewId, [])
            const layer = parents.length - 1
            if (!_layers[layer]) {
                _layers[layer] = []
                setActiveViewPerLayer((prev) => ({ ...prev, [layer]: viewId }))
            }
            _layers[layer].push(viewId)
        })

        setLayers(_layers)
    }, [data.views])

    const activeViewId = useMemo(() => {
        if (data.variable) return activeSlide?.variables?.[data.variable] ?? 'root'
        return 'root'
    }, [activeSlide, data.variable])

    const getWidgets = (viewId: string) => {
        if (viewId) {
            const activeView = data.views[viewId]
            if (activeView === undefined) return null
            const _widgets = activeSlide?.widgets?.filter((_widget) => activeView.widgets.includes(_widget.id)) ?? []
            _widgets.sort((a, b) => activeView.widgets.indexOf(a.id) - activeView.widgets.indexOf(b.id))
            return _widgets
        }
        return []
    }

    const activeLayer = useMemo(() => {
        let _activeLayer = 0
        if (Object.keys(layers).length === 0) return 0
        for (const layer in layers) {
            if (layers[layer].includes(activeViewId)) _activeLayer = parseInt(layer)
        }
        setActiveViewPerLayer((prev) => ({ ...prev, [_activeLayer]: activeViewId }))
        return _activeLayer
    }, [activeViewId, layers])

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
            {Object.entries(layers).map(([layer], index) => {
                const activeView = activeViewPerLayer[parseInt(layer)] ?? null
                if (activeView === null) return null
                const widgets = getWidgets(activeView)
                const intLayer = parseInt(layer)
                return (
                    <Stack
                        alignItems="center"
                        key={layer}
                        sx={(theme) => ({
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            transform: `translateX(${-activeLayer + intLayer}00%)`,
                            transition: 'transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1), opacity 0.3s ease-in-out',
                            opacity: activeLayer === intLayer ? 1 : 0,
                            overflow: 'auto',
                        })}
                        className="u-scrollbar"
                        gap={1}
                    >
                        {data.views[activeView].parent !== null && (
                            <BaseButton
                                sx={{ alignSelf: 'start', fontSize: scaleFactor * 12 }}
                                onClick={() => {
                                    if (!activeSlide) return
                                    if (data.variable === null || data.variable.length === 0) return
                                    updateSlideVariable({
                                        slideId: activeSlide.id,
                                        data: {
                                            variableName: data.variable,
                                            variableValue: data.views[activeView].parent,
                                        },
                                    })
                                }}
                                startIcon={<ArrowBackIcon sx={{ fontSize: scaleFactor * 12 }} />}
                            >
                                Back
                            </BaseButton>
                        )}
                        {widgets &&
                            widgets.map((_widget) => {
                                let widgetScaleFactor = scaledWidth / _widget.dimensions.width
                                const itemHeight = scaledHeight / widgets.length - 8
                                if (data.autoHeight) {
                                    const heightScaleFactor = itemHeight / _widget.dimensions.height
                                    if (widgetScaleFactor > heightScaleFactor) {
                                        widgetScaleFactor = heightScaleFactor
                                    }
                                }
                                widgetScaleFactor *= 0.9
                                const height = data.autoHeight
                                    ? {
                                          height: _widget.dimensions.autoHeight ? 'auto' : '100%',
                                      }
                                    : {
                                          height: _widget.dimensions.height * widgetScaleFactor,
                                          flexShrink: 0,
                                      }
                                return (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            outline: getOutlineValue(_widget.id),
                                            ...height,
                                        }}
                                        key={_widget.id}
                                        onClick={(e) => {
                                            if (isActive && activeSlide) {
                                                updatePanelWidgetContent({
                                                    slideId: activeSlide.id,
                                                    widgetId: widgetId,
                                                    data: {
                                                        selectedWidgetId: _widget.id,
                                                    },
                                                })
                                            }
                                        }}
                                    >
                                        <WidgetContent
                                            widgetId={_widget.id}
                                            content={_widget.content}
                                            isActive={isActive && _widget.id === data.selectedWidgetId}
                                            slideWidthScaleFactor={widgetScaleFactor}
                                            viewMode={viewMode}
                                            title={_widget.title}
                                            tooltip={_widget.tooltip}
                                            onReady={() => {}}
                                        />
                                    </Box>
                                )
                            })}
                    </Stack>
                )
            })}
        </Box>
    )
}

export default PanelWidget
