//* ======= Libraries
import { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Box, Tabs, Tab, Typography, IconButton } from '@mui/material'
//* ======= Components and features
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ColorPicker from 'components/widgets/ColorPicker'
import BorderPicker from 'components/widgets/BorderPicker'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    InfoWidgetType,
    ReportWidgetSettingsBaseType,
    PanelWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import EditableTextField from 'components/base/EditableTextField'
import AddPanelLayerDialog from './AddPanelLayerDialog'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import BaseButton from 'components/base/BaseButton'
import Edit from '@mui/icons-material/Edit'
import EditPanelLayerDialog from './EditPanelLayerDialog'
import { ReportWidgetSettingsSwitcher } from '../ReportWidgetSettings'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'panel'>
    settings: Omit<ReportWidgetType, 'content'>
    details: PanelWidgetType
}

function PanelWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const {
        activeSlideId,
        updateWidget,
        updateWidgetStyles,
        updatePanelWidgetContent,
        removeLayerFromPanelWidget,
        addLayerToPanelWidget,
        updatePanelLayer,
        updateSlideVariable,
    } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updatePanelWidgetContent: store.updatePanelWidgetContent,
        addLayerToPanelWidget: store.addLayerToPanelWidget,
        removeLayerFromPanelWidget: store.removeLayerFromPanelWidget,
        updatePanelLayer: store.updatePanelLayer,
        updateSlideVariable: store.updateSlideVariable,
    }))

    const activeWidget = useReportActiveWidget(widgetId)

    const activeSlide = useReportActiveSlide()

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'panel') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)
    const [showAddLayerDialog, setShowAddLayerDialog] = useState(false)
    const [showEditLayerDialog, setShowEditLayerDialog] = useState<
        | false
        | {
              layerName: string
              layer: PanelWidgetType['views'][string]
          }
    >(false)

    const handleOpenEditLayerDialog = (layerName: string, layer: PanelWidgetType['views'][string]) => {
        setShowEditLayerDialog({
            layerName: layerName,
            layer: layer,
        })
    }

    const handleCloseEditLayerDialog = () => {
        setShowEditLayerDialog(false)
    }

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<PanelWidgetType>) => {
        if (activeSlideId !== null && widget !== null) {
            updatePanelWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    const addPanelLayer = (name: string, parent: string, widgets: ReportWidgetType['id'][]) => {
        if (activeSlideId !== null && widget !== null) {
            addLayerToPanelWidget({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: {
                    name: name,
                    parent: parent,
                    widgets,
                },
            })
            setShowAddLayerDialog(false)
        }
    }

    const removePanelLayer = (name: string) => {
        if (activeSlideId !== null && widget !== null && name !== 'root') {
            removeLayerFromPanelWidget({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: {
                    name: name,
                },
            })
        }
    }

    const activeInternalWidget = useMemo<ReportWidgetType | null>(() => {
        if (widget === null) return null

        return activeSlide?.widgets.find((_widget) => _widget.id === widget.details.selectedWidgetId) ?? null
    }, [widget, activeSlide?.widgets])

    return (
        <>
            {activeSlideId !== null && widgetId !== undefined && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab
                                    label={activeInternalWidget?.title}
                                    wrapped={(activeInternalWidget?.title?.length ?? 0) > 12 ? true : false}
                                />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                    isPopover={true}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Variable */}
                            <EditableTextField
                                label="Variable"
                                value={widget.details.variable}
                                onBlur={(v) => updateDetails({ variable: v })}
                                size="small"
                                fullWidth
                            />

                            <StyledSwitch
                                label="Auto Height"
                                size="small"
                                checked={widget.details.autoHeight}
                                onChange={(evt, checked) => {
                                    updateDetails({
                                        autoHeight: checked,
                                    })
                                }}
                            />

                            {/* layers */}
                            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                                <Typography variant="body2">Layers</Typography>
                                <IconButton onClick={() => setShowAddLayerDialog(true)}>
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            </Stack>
                            {Object.entries(widget.details.views).map(([key, value]) => (
                                <Stack
                                    key={key}
                                    direction="row"
                                    gap={1}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <BaseButton
                                        fullWidth
                                        onClick={() => {
                                            if (widget.details.variable !== null && widget.details.variable.length > 0)
                                                updateSlideVariable({
                                                    slideId: activeSlideId,
                                                    data: {
                                                        variableName: widget.details.variable,
                                                        variableValue: key,
                                                    },
                                                })
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            noWrap
                                            sx={{ width: '100%', textAlign: 'left', textOverflow: 'ellipsis' }}
                                        >
                                            {key}
                                        </Typography>
                                    </BaseButton>

                                    <Stack direction="row" gap={1}>
                                        <IconButton
                                            onClick={() => {
                                                handleOpenEditLayerDialog(key, value)
                                            }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        {key !== 'root' && (
                                            <IconButton onClick={() => removePanelLayer(key)}>
                                                <RemoveIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        <ReportWidgetSettingsSwitcher widget={activeInternalWidget} isInternal={true} />
                    ) : (
                        false
                    )}

                    <AddPanelLayerDialog
                        open={showAddLayerDialog}
                        onClose={() => setShowAddLayerDialog(false)}
                        parents={Object.keys(widget.details.views)}
                        widgetId={widgetId}
                        onAdd={addPanelLayer}
                    />

                    {showEditLayerDialog !== false && (
                        <EditPanelLayerDialog
                            open={true}
                            widgetId={widgetId}
                            onClose={handleCloseEditLayerDialog}
                            layerName={showEditLayerDialog.layerName}
                            layer={showEditLayerDialog.layer}
                            onSave={(layerKey, updatedLayer) => {
                                updatePanelLayer({
                                    slideId: activeSlideId,
                                    widgetId: widgetId,
                                    layerName: layerKey,
                                    data: {
                                        name: updatedLayer.name,
                                        parent: updatedLayer.parent,
                                        widgets: updatedLayer.widgets,
                                    },
                                })
                                handleCloseEditLayerDialog()
                            }}
                            parents={Object.keys(widget.details.views)}
                        />
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default PanelWidgetSettings
