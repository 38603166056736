//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import SurveyPreviewContent from 'features/survey-preview/SurveyPreviewContent'
import SurveyInstanceContextProvider from 'contexts/SurveyInstanceContext'
import { useParams } from 'react-router-dom'
import {
    SaveSurveyInstanceRequestType,
    SaveSurveyInstanceService,
    SubmitOpenSurveyInstanceService,
    SubmitPublicSurveyInstanceService,
    SubmitSurveyInstanceRequestType,
    SubmitSurveyInstanceService,
} from 'services/SurveyInstanceService'
//* ======= Custom logic
//* ======= Assets and styles

interface ISurveyPage {
    isOpenParticipation?: boolean
    isPublic?: boolean
}

function SurveyPage({ isOpenParticipation, isPublic }: ISurveyPage) {
    const { token, sid, pid } = useParams()

    const handleSaveSurvey = async (saveRequest: SaveSurveyInstanceRequestType) => {
        if (!isOpenParticipation) {
            if (!token) {
                throw new Error('Token is required to save survey instance')
            }
            const response = await SaveSurveyInstanceService(token, saveRequest)
            return response
        }

        throw new Error('Save survey instance is not allowed for open participation survey')
    }

    const handleSubmitSurvey = async (submitRequest: SubmitSurveyInstanceRequestType) => {
        if (isOpenParticipation) {
            if (isPublic) {
                if (token === undefined) {
                    throw new Error('Token is required to save survey instance')
                }
                return await SubmitPublicSurveyInstanceService(token, submitRequest, false)
            } else {
                if (pid === undefined || sid === undefined) {
                    throw new Error('Project ID and Survey ID are required to save survey instance')
                }
                return await SubmitOpenSurveyInstanceService(pid, sid, submitRequest, false)
            }
        } else {
            if (token === undefined) {
                throw new Error('Token is required to save survey instance')
            }
            const response = await SubmitSurveyInstanceService(token, submitRequest, false)
            return response
        }
    }

    return (
        <SurveyInstanceContextProvider isOpenParticipation={isOpenParticipation} isPublic={isPublic}>
            <Box
                sx={(theme) => ({
                    flexGrow: 1,

                    minHeight: 0,

                    backgroundColor: 'common.bg_4',
                })}
            >
                <SurveyPreviewContent
                    onSave={isOpenParticipation === true ? undefined : handleSaveSurvey}
                    onSubmit={handleSubmitSurvey}
                    redoable={isOpenParticipation === true && isPublic !== true}
                />
            </Box>
        </SurveyInstanceContextProvider>
    )
}

export default SurveyPage
