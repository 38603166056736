import { formatLabel } from 'features/network-viz/helpers/DataFormatter'
import { ReportSavedStateType } from 'features/report-designer/types/reportDesigner.types'
import { TableDefinitionModeTiesRelation } from '../widgets/table-widget/helpers/TableWidget.asset'
import { defaultInsightWidgetValues } from './reportDesignerDefaultValues'

// ToDo type of state cannot be ReportDesignerStoreStateType, as it's an older version and can have different structure. Better alternative than any?
/**
 * This function will provide backward compatibility by migrating state from older versions to the current one.
 * To add a new migration, add a new case at the end of the switch statment but do not put break at the end of the case statment
 * as it needs to fall through.
 * @param {*} oldState State of the report in the previous version.
 * @param {string} reportVersion
 * @return {*} Updated state that matches the current state structure.
 */
const migrateReportState = (oldState: any, reportVersion: string): ReportSavedStateType => {
    const newState = structuredClone(oldState)
    switch (reportVersion) {
        /*
         * Version change:      "1.0.1" to "1.0.2"
         * Changes:
         *  -   Charts:
         *      • Changed 'isMultiView' config property from boolean to an object.
         */
        // @ts-ignore
        case '1.0.1':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    if (_widget.content.kind === 'chart') {
                        _widget.content.details.config.multiView = {
                            enabled: _widget.content.details.config.isMultiView,
                            gridSize: {
                                columns: 2,
                                rows: 2,
                            },
                        }
                        delete _widget.content.details.config.isMultiView
                    }

                    return _widget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        /*
         * Version change:      "1.0.2" to "1.0.3"
         * Changes:
         *  -   Slide:
         *      • Added a new "dimensions" property to slide object.
         *      • Updated the code structure in a way that slides and widgets will "scale" relative to
         *        a fixed base dimension (based on aspect ratio).
         */
        /* @ts-ignore falls through */
        case '1.0.2':
            newState.slides = newState.slides.map((_slide: any) => {
                _slide.dimensions = {
                    width: 1280,
                    height: 720,
                    currentScale: 1,
                    offsetTop: 104,
                    offsetLeft: 278,
                }

                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    // Converting the widget dimensions from percentage string to number.
                    _widget.dimensions = {
                        // Removing the "%" character from the end and then calculating the actual size based on
                        // 16:9 ratio's base sizes (1280 * 720).
                        width: (Number(_widget.dimensions.width.slice(0, -1)) / 100) * 1280,
                        height: (Number(_widget.dimensions.height.slice(0, -1)) / 100) * 720,
                    }

                    return _widget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        /*
         * Version change:      "1.0.3" to "1.0.4"
         * Changes:
         *  -   Report:
         *      • Renamed "masterStyles" to "masterSettings".
         *      • Added a new "header" property to the newly-renamed "masterSettings" field.
         *  -   Slide:
         *      • Added a new "header" property.
         *  -   Table:
         *      • Refactored the entire typings and code flow and defined separate data definitions
         *        and configs.
         *  -   Charts:
         *      • Boxplot:
         *          - Added "orientation" property to config.
         *          - Added "categoryOrder" and "categories" to data definition.
         *      • PictorialBar:
         *          - Added "categoryOrder" to data definition.
         *          - Changed the "categories" property of data definition from
         *              Record<any, { symbol: string; color?: string }>
         *            to
         *              Array<{ key: string | number; symbol: string; color?: string }>
         *      • Gauge:
         *          - Added "isVisualizedByPercentage" to data definition.
         */
        /* @ts-ignore falls through */
        case '1.0.3':
            newState.masterSettings = structuredClone(newState.masterStyles)

            delete newState.masterStyles

            // Add default values for the new "header" property.
            newState.masterSettings.header = {
                isEnabled: false,
                secondaryText: {
                    value: '',
                    styles: {
                        fontFamily: 'Poppins',
                        fontSize: 16,
                        fontWeight: 'normal',
                        color: '#333333FF',
                    },
                },
                image: {
                    status: 'ready',
                    source: '',
                    name: '',
                    fit: 'contain',
                },
                height: 10,
                backgroundColor: {
                    isEnabled: false,
                    color: undefined,
                },
                hasBottomShadow: false,
            }

            newState.slides = newState.slides.map((_slide: any) => {
                // Add default values for the new "header" property.
                _slide.header = {
                    isEnabled: null,
                    isOverridden: false,
                    primaryText: '',
                    height: 10,
                }

                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Migrating "table" widget
                    if (_widget.content.kind === 'table') {
                        // Creating a new table details object from scratch, instead of spreading
                        // the old values and then deleting properties.
                        const newTableDetails: any = {}

                        // SAME - "selectedDataSource" with its previous or default value.
                        newTableDetails.selectedDataSource =
                            _widget.content.details.selectedDataSource?.id === -1
                                ? null
                                : { ..._widget.content.details.selectedDataSource }

                        // SAME - "filters" with its previous or default value.
                        newTableDetails.filters =
                            _widget.content.details.filters === null
                                ? null
                                : Object.entries(_widget.content.details.filters).length === 0
                                ? null
                                : { ..._widget.content.details.filters }

                        // NEW - "dataDefinition" with merged values.
                        newTableDetails.dataDefinition = {}
                        // Extracting data definitions from old types:

                        // Old "eachRow" mode
                        if (_widget.content.details.mode === 'eachRow') {
                            // MOVED - "mode" with new value.
                            newTableDetails.dataDefinition.mode = 'each-row'

                            // MOVED - "idField" with its previous or default value.
                            newTableDetails.dataDefinition.idField =
                                _widget.content.details.idField.field === ''
                                    ? null
                                    : { ..._widget.content.details.idField }

                            // NEW - "sortField" with its previous or default value.
                            newTableDetails.dataDefinition.sortField =
                                _widget.content.details.sortBy === undefined ||
                                _widget.content.details.sortBy.field === ''
                                    ? null
                                    : { ..._widget.content.details.sortBy }

                            // NEW - "sortOrder" with its previous value.
                            newTableDetails.dataDefinition.sortOrder =
                                _widget.content.details.sortMode === 1 ? 'asc' : 'desc'

                            // MOVED - "header" with its previous or default values.
                            newTableDetails.dataDefinition.header = {
                                field:
                                    _widget.content.details.header.title.field === ''
                                        ? null
                                        : { ..._widget.content.details.header.title },
                                secondaryField:
                                    _widget.content.details.header.subTitle.field === ''
                                        ? null
                                        : { ..._widget.content.details.header.subTitle },
                                avatar:
                                    _widget.content.details.header.avatar.field === ''
                                        ? null
                                        : { ..._widget.content.details.header.avatar },
                            }

                            // MOVED - "columns" with its previous or default values.
                            // Extracting column definitions from old types:
                            newTableDetails.dataDefinition.columns = _widget.content.details.columns.map(
                                (_oldColumn: any) => ({
                                    id: _oldColumn.id,
                                    field: _oldColumn.field.field === '' ? null : { ..._oldColumn.field },
                                    title: _oldColumn?.title,
                                    description: _oldColumn?.helperText,
                                })
                            )
                        }
                        // Old "top10" mode
                        else if (_widget.content.details.mode === 'top10') {
                            // MOVED - "mode" with new value.
                            newTableDetails.dataDefinition.mode = 'top-k'

                            // MOVED - "idField" with its previous or default value.
                            newTableDetails.dataDefinition.idField =
                                _widget.content.details.idField.field === ''
                                    ? null
                                    : { ..._widget.content.details.idField }

                            // NEW - "value" with merged values.
                            newTableDetails.dataDefinition.value = {
                                field:
                                    _widget.content.details.valueField.field === ''
                                        ? null
                                        : { ..._widget.content.details.valueField },
                                title: _widget.content.details?.valueTitle,
                                isVisible: _widget.content.details.showValueFiled,
                            }

                            // NEW - "label" with merged values.
                            newTableDetails.dataDefinition.label = {
                                field:
                                    _widget.content.details.labelField.field === ''
                                        ? null
                                        : { ..._widget.content.details.labelField },
                                title: _widget.content.details?.labelTitle,
                            }

                            // NEW - "rank" with merged values.
                            newTableDetails.dataDefinition.rank = {
                                title: _widget.content.details.rankTitle,
                                isVisible: _widget.content.details.showRank,
                            }

                            // NEW - "limit" with default value.
                            newTableDetails.dataDefinition.limit = 10

                            // NEW - "sortOrder" with its previous value.
                            newTableDetails.dataDefinition.sortOrder = _widget.content.details.sort
                        }
                        // Old "aggregation" mode
                        else if (_widget.content.details.mode === 'aggregation') {
                            // MOVED - "mode" with new value.
                            newTableDetails.dataDefinition.mode = 'aggregation'

                            // NEW - "groupField" with its previous or default value.
                            newTableDetails.dataDefinition.groupField =
                                _widget.content.details.groupFiled.field === ''
                                    ? null
                                    : { ..._widget.content.details.groupFiled }

                            // MOVED - "columns" with its previous or default values.
                            // Extracting column definitions from old types:
                            newTableDetails.dataDefinition.columns = _widget.content.details.columns.map(
                                (_oldColumn: any) => ({
                                    id: _oldColumn.id,
                                    field: _oldColumn.field.field === '' ? null : { ..._oldColumn.field },
                                    title: _oldColumn?.title,
                                    description: _oldColumn?.helperText,
                                    aggregation: _oldColumn.aggregation,
                                })
                            )
                        }
                        // Old "ties" mode
                        else if (_widget.content.details.mode === 'ties') {
                            // MOVED - "mode" with new value.
                            newTableDetails.dataDefinition.mode = 'ties'

                            // NEW - "groupingMode" with default value.
                            newTableDetails.dataDefinition.groupingMode = 'source'

                            // NEW - "nodesLabelField" with new value.
                            newTableDetails.dataDefinition.nodesLabelField = {
                                type: 'basic',
                                field: 'label',
                            }

                            // NEW - "nodesBackgroundColor" with its default value.
                            newTableDetails.dataDefinition.nodesBackgroundColor = {
                                isEnabled: false,
                                value: '#58585AFF',
                            }

                            // NEW - "titles" with its default value.
                            newTableDetails.dataDefinition.titles = {}
                        }

                        // NEW - "parsedData" with default value.
                        newTableDetails.parsedData = null

                        // NEW - "config" with merged values.
                        newTableDetails.config = {}
                        // Extracting config values from old types:

                        // Old "table" view mode
                        if (_widget.content.details.viewKind === 'table') {
                            // "base" config values

                            // NEW - "viewMode" with its previous value.
                            newTableDetails.config.hasTitle = _widget.content.details.showTitle
                            // MOVED - "emptyDataMessage" with its previous value
                            newTableDetails.config.emptyDataMessage = _widget.content.details.emptyDataMessage

                            // "grid" mode config values

                            // MOVED - "viewMode" with new value.
                            newTableDetails.config.viewMode = 'grid'
                            // NEW - "hasHeader" with its default value.
                            newTableDetails.config.hasSearch = true
                            // NEW - "hasHeader" with its previous value.
                            newTableDetails.config.hasHeader = _widget.content.details.showHeader
                        }
                        // Old "card" view mode
                        else if (_widget.content.details.viewKind === 'card') {
                            // "base" config values

                            // NEW - "viewMode" with its previous value.
                            newTableDetails.config.hasTitle = _widget.content.details.showTitle
                            // MOVED - "emptyDataMessage" with its previous value
                            newTableDetails.config.emptyDataMessage = _widget.content.details.emptyDataMessage

                            // "card" mode config values

                            // MOVED - "viewMode" with new value.
                            newTableDetails.config.viewMode = 'card'
                            // NEW - "canChangeRows" with its previous value.
                            newTableDetails.config.canChangeRows = _widget.content.details.showRowController
                            // NEW - "isPrimaryLabelVisible" with default value.
                            newTableDetails.config.isPrimaryLabelVisible = false
                            // MOVED - "headerStyle" with its previous value
                            newTableDetails.config.headerStyle = { ..._widget.content.details.titleFontStyle }
                        }

                        // RENAMED - "selectedRowId" with its previous value.
                        newTableDetails.selectedRowId = _widget.content.details.selectedRow?.toString() || null
                        // SAME - "connectedWidgets" with its previous value.
                        newTableDetails.connectedWidgets = [..._widget.content.details.connectedWidgets]

                        migratedWidget.content.details = structuredClone(newTableDetails)
                    }

                    // Updating certain chart widgets
                    if (_widget.content.kind === 'chart') {
                        // Boxplot chart
                        if (_widget.content.details.type === 'boxplot') {
                            // Add "orientation" property to the config with default value.
                            migratedWidget.content.details.config.orientation = 'horizontal'

                            migratedWidget.content.details.dataDefinition = {
                                ...migratedWidget.content.details.dataDefinition,
                                // Add "categoryOrder" property with default value.
                                categoryOrder: 'asc',
                                // Data definition has a new "categories" property which holds the list of the categories
                                // and their color. Since we don't have the previous data, we set it to an empty list.
                                categories: [],
                            }
                        }

                        // Pictorial bar chart
                        if (_widget.content.details.type === 'pictorialBar') {
                            // Previous "categories" was a Record<any, { symbol: string; color?: string }>
                            const prevCategories = {
                                ...migratedWidget.content.details.dataDefinition.categories,
                            }

                            // New "categories" should be Array<{ key: string | number; symbol: string; color?: string }>
                            const newCategories = []

                            // Converting the old type to new
                            for (const key in prevCategories) {
                                if (Object.prototype.hasOwnProperty.call(prevCategories, key)) {
                                    const category = prevCategories[key]

                                    newCategories.push({
                                        key: key?.toString() || '(undefined)',
                                        symbol: category.symbol,
                                        color: category?.color,
                                    })
                                }
                            }

                            migratedWidget.content.details.dataDefinition = {
                                ...migratedWidget.content.details.dataDefinition,
                                // Add "categoryOrder" property with default value.
                                categoryOrder: 'asc',
                                // Data definition has a new "categories" property which holds the list of the categories
                                // and their color.
                                categories: [...newCategories],
                            }
                        }

                        // Gauge chart
                        if (_widget.content.details.type === 'gauge') {
                            migratedWidget.content.details.dataDefinition = {
                                ...migratedWidget.content.details.dataDefinition,
                                // Add "isVisualizedByPercentage" property with default value.
                                isVisualizedByPercentage: false,
                            }
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        //? Version "1.0.4" was skipped because there were no valid reports with this version and it was
        //? just a testing version used for development purposes.
        /* @ts-ignore falls through */
        case '1.0.4':

        /*
         * Version change:      "1.0.5" to "1.0.6"
         * Changes:
         * -   Link widget: Add an external link mode to the link widget. Add a mode property to select between internal and external links.
         *
         */
        /* @ts-ignore falls through */
        case '1.0.5':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating link widget and set the mode to internal
                    if (_widget.content.kind === 'navLink') {
                        migratedWidget.content.details.mode = 'internal'
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        /*
         * Version change:      "1.0.6" to "1.0.7"
         * Changes:
         */
        /* @ts-ignore falls through */
        case '1.0.6':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating certain chart widgets
                    if (_widget.content.kind === 'chart') {
                        // Add "decimalPrecision" property to the config with default value.
                        // This property is part of the base config options and present on all chart types.
                        migratedWidget.content.details.config.decimalPrecision = 2

                        // Line chart
                        if (_widget.content.details.type === 'line') {
                            // Add "bounds" property to the config for both X and Y axes with default values.
                            // This property is part of the base axes config options.
                            migratedWidget.content.details.config.xAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }
                            migratedWidget.content.details.config.yAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }

                            // Add "forceAllVisible" property to the config with default value.
                            migratedWidget.content.details.config.xAxisOptions.labels.forceAllVisible = false
                            migratedWidget.content.details.config.yAxisOptions.labels.forceAllVisible = false
                        }

                        // Bar chart
                        if (_widget.content.details.type === 'bar') {
                            // Add "bounds" property to the config for both X and Y axes with default values.
                            // This property is part of the base axes config options.
                            migratedWidget.content.details.config.xAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }
                            migratedWidget.content.details.config.yAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }

                            // Add "forceAllVisible" property to the config with default value.
                            migratedWidget.content.details.config.xAxisOptions.labels.forceAllVisible = false
                            migratedWidget.content.details.config.yAxisOptions.labels.forceAllVisible = false
                        }

                        // Scatter chart
                        if (_widget.content.details.type === 'scatter') {
                            // Add "bounds" property to the config for both X and Y axes with default values.
                            // This property is part of the base axes config options.
                            migratedWidget.content.details.config.xAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }
                            migratedWidget.content.details.config.yAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }

                            // Add "forceAllVisible" property to the config with default value.
                            migratedWidget.content.details.config.xAxisOptions.labels.forceAllVisible = false
                            migratedWidget.content.details.config.yAxisOptions.labels.forceAllVisible = false
                        }

                        // Boxplot chart
                        if (_widget.content.details.type === 'boxplot') {
                            // Add "bounds" property to the config for both X and Y axes with default values.
                            // This property is part of the base axes config options.
                            migratedWidget.content.details.config.xAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }
                            migratedWidget.content.details.config.yAxisOptions.bounds = {
                                min: null,
                                max: null,
                            }

                            // Add "forceAllVisible" property to the config with default value.
                            migratedWidget.content.details.config.xAxisOptions.labels.forceAllVisible = false
                            migratedWidget.content.details.config.yAxisOptions.labels.forceAllVisible = false
                        }
                    }

                    // Migrating "table" widget
                    if (_widget.content.kind === 'table') {
                        // Add "decimalPrecision" property to the definition with default value.
                        // This property is part of the base definition and present on all table modes.
                        migratedWidget.content.details.dataDefinition.decimalPrecision = 2
                    }

                    // Migrating "info" widget
                    if (_widget.content.kind === 'info') {
                        // Add "video" property with default value.
                        migratedWidget.content.details.video = {
                            source: '',
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.0.7" to "1.0.8"
         * Changes:
         *  -   Table widget:
         *                     - Add a new property to the table widget in card mode to enable/disable search.
         *                     - Add compare and benchmark feature to the table widget.
         */
        /* @ts-ignore falls through */
        case '1.0.7':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating table widget card config and set hasSearch to false
                    if (_widget.content.kind === 'table' && _widget.content.details.config.viewMode === 'card') {
                        migratedWidget.content.details.config.hasSearch = false
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        /*
         * Version change:      "1.0.8" to "1.0.9"
         * Changes:
         *  -   Table widget:
         *                   - reworked table widget ties mode
         *                   - Add a new property to the table widget in card mode to enable/disable header.
         */
        /* @ts-ignore falls through */
        case '1.0.8':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating table widget card config and set hasHeader to false
                    if (_widget.content.kind === 'table' && _widget.content.details.config.viewMode === 'card') {
                        migratedWidget.content.details.config.hasHeader = true
                    }

                    // update ties data defintion
                    if (_widget.content.kind === 'table' && _widget.content.details.dataDefinition.mode === 'ties') {
                        const { nodesLabelField } = _widget.content.details.dataDefinition

                        const relations: TableDefinitionModeTiesRelation[] = []
                        const selectedDatasource = _widget.content.details.selectedDataSource
                        const edgeStyles = newState.dataSources.find((x: any) => x.id === selectedDatasource.id)
                            .presets[selectedDatasource.preset ?? 'default'].edgeStyle

                        const relationships = Object.keys(edgeStyles).filter((x) => x !== 'default')

                        for (const relationship of relationships) {
                            relations.push({
                                name: relationship,
                                isEnabled: true,
                                nodesBackgroundColor: edgeStyles[relationship].normal.color,
                                label: formatLabel(relationship),
                            })
                        }
                        migratedWidget.content.details.dataDefinition = {
                            mode: 'ties',
                            edgeInclusivity: 'both',
                            nodesLabelField,
                            relations,
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        /*
         * Version change:      "1.0.9" to "1.1.0"
         * Changes:
         *  -   Table widget:
         *      - add advanced setting to column definition
         */
        /* @ts-ignore falls through */
        case '1.0.9':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating table widget and add default advance options to columns
                    if (
                        _widget.content.kind === 'table' &&
                        migratedWidget.content.details.dataDefinition.columns !== undefined
                    ) {
                        migratedWidget.content.details.dataDefinition.columns.forEach((column: any) => {
                            column.advanced = {
                                dataType: 'string',
                                valueMapping: {
                                    isEnabled: false,
                                    categories: [],
                                },
                            }
                        })
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        /*
         * Version change:      "1.1.0" to "1.1.1"
         * Changes:
         *  -   Network Widget:
         *      - Legend
         *      - Size Scale
         *      - Focus Mode
         */
        /* @ts-ignore falls through */
        case '1.1.0':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating table widget and add default advance options to columns
                    if (_widget.content.kind === 'network') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            legend: {
                                node: false,
                                edge: false,
                            },
                            sizeScale: {
                                enabled: false,
                                fields: [],
                                selectedField: null,
                            },
                            focusMode: false,
                        }
                    } else if (_widget.content.kind === 'insight') {
                        const connectedNetwork = _slide.widgets.find(
                            (x: any) => x.id === migratedWidget.content.details.networkWidgetId
                        )

                        migratedWidget.content.details = {
                            selectedDataSource: {
                                id: connectedNetwork.content.details.networkDatasourceId,
                                datasourceType: 'network',
                                filters: [],
                                preset: connectedNetwork.content.details.selectedPreset,
                            },
                            selectedItem: null,
                            groupBy: undefined,
                            mode: migratedWidget.content.details.mode === 'graph' ? 'group' : 'individual',
                            groupInsights: migratedWidget.content.details.groupInsights,
                            emptyDataMessage: migratedWidget.content.details.emptyDataMessage,
                            showTitle: migratedWidget.content.details.showTitle,
                            version: migratedWidget.content.details.version,
                            cachedData: {},
                            insights: migratedWidget.content.details.insights.map((insight: any) => ({
                                id: insight.id,
                                message: insight.message,
                                rules: insight.rules.map((rule: any) => ({
                                    operator: rule.operator,
                                    value: rule.value,
                                    aggregationMethod: rule.varaible.aggregationMethod ?? null,
                                    varaible:
                                        rule.varaible.source === 'graph'
                                            ? {
                                                  type: 'graph',
                                                  relationship: rule.varaible.relationship,
                                                  field: rule.varaible.field,
                                              }
                                            : rule.varaible.field,
                                })),
                            })),
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

        /*
         * Version change:      "1.1.1" to "1.1.2"
         * Changes:
         *  -   Table widget:
         *      - Added columnWidth to table gridview mode config
         */
        /* @ts-ignore falls through */
        case '1.1.1':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating table widget and add table width to grid view mode
                    if (_widget.content.kind === 'table' && _widget.content.details.config.viewMode === 'grid') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            config: {
                                ...migratedWidget.content.details.config,
                                columnsWidth: {},
                            },
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.1.2" to "1.1.3"
         * Changes:
         *  -   Inisght widget:
         *      - Added column array to insights
         *      - Add InterventionColumnWidth to insight
         *   -  Combined widget:
         *     - update inner widgets form being array of string to array of object (id, linkedWidgets[])
         */
        /* @ts-ignore falls through */
        case '1.1.2':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    // Updating table widget and add table width to grid view mode
                    if (_widget.content.kind === 'insight') {
                        let order = 0
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            interventionColumnWidth: 50,
                            insights: migratedWidget.content.details.insights.map((insight: any) => ({
                                ...insight,
                                message: {
                                    satisfied: {
                                        ...insight.message.satisfied,
                                        order: order++,
                                        columns: [
                                            {
                                                field: null,
                                                label: 'Title',
                                                width: null,
                                            },
                                        ],
                                    },
                                    unsatisfied: {
                                        ...insight.message.unsatisfied,
                                        order: order++,
                                        columns: [
                                            {
                                                field: null,
                                                label: 'Title',
                                                width: null,
                                            },
                                        ],
                                    },
                                },
                            })),
                            showExport: false,
                            showSearch: false,
                        }
                    } else if (_widget.content.kind === 'combined') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            widgets: migratedWidget.content.details.widgets.map((item: any) => {
                                if (typeof item === 'string') {
                                    return {
                                        id: item,
                                        linkedWidgets: [],
                                    }
                                } else return item
                            }),
                        }
                    } else if (
                        _widget.content.kind === 'table' &&
                        _widget.content.details.dataDefinition.edgeInclusivity === 'both'
                    ) {
                        migratedWidget.content.details.dataDefinition.edgeInclusivity = 'all'
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.1.3" to "1.1.4"
         * Changes:
         *  -   slide:
         *      - onboarding
         */
        /* @ts-ignore falls through */
        case '1.1.3':
            newState.slides = newState.slides.map((_slide: any) => {
                const onboarding = structuredClone(_slide.onboarding) ?? []

                _slide.onboarding = onboarding.map((item: any) => {
                    if (typeof item.component === 'string') {
                        const component = [item.component]
                        const subComponent = item.subComponent ?? ''
                        if (subComponent.trim() !== '') {
                            component.push(subComponent)
                        }
                        return {
                            id: item.id,
                            content: item.content,
                            component: component,
                        }
                    } else {
                        return item
                    }
                })

                return _slide
            })
        /*
         * Version change:      "1.1.4" to "1.1.5"
         * Changes:
         *  -   insight widget:
         *      - added interventionTabTextStyle and interventionTableTitle
         *      - added tableTitle to insight messages (no changes needed as value accept undefined)
         *  -   Combined widget:
         *          - added fontStyle
         *  -    Report widget:
         *          - added tooltip to widgets
         */
        /* @ts-ignore falls through */
        case '1.1.4':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    migratedWidget.tooltip = {
                        isEnabled: false,
                        icon: {
                            placement: 'top-end',
                            type: 'exclamation',
                            size: 'medium',
                            color: '#2d77bcFF',
                            paddingY: 1,
                            paddingX: 1,
                        },
                        text: '',
                    }

                    if (_widget.content.kind === 'insight') {
                        const { interventionColumnWidth, showExport, showSearch, ...others } =
                            migratedWidget.content.details
                        migratedWidget.content.details = {
                            ...others,
                            interventionConfig: {
                                columnWidth: interventionColumnWidth,
                                showExport,
                                showSearch,
                                showDescription: true,
                                showActions: true,
                                tableTitle: {
                                    enabled: true,
                                    text: 'Impacted Nodes',
                                    fontFamily: 'Poppins',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#000000FF',
                                },
                                tabTextStyle: {
                                    fontFamily: 'Poppins',
                                    fontSize: 14,
                                    fontWeight: 'normal',
                                    color: '#000000FF',
                                },
                            },
                        }
                    } else if (_widget.content.kind === 'combined') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            fontStyle: {
                                fontFamily: 'Poppins',
                                fontSize: 14,
                                fontWeight: 'normal',
                                color: '#000000FF',
                            },
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.1.5" to "1.1.6"
         * Changes:
         *  -    Network widget:
         *          - Added placement property to network widget sizeScale to allow designer make it left or right aligned
         *  -    Filter widget:
         *          - Added fontSize property to filter widget
         *  -    Insight widget:
         *         - Added layout property to insight widget (in interventionConfig)
         *  -    Slide:
         *        - Added variables property to slide
         *  -    Report Widget:
         *       - Added conditional visibility to report widget
         */
        /* @ts-ignore falls through */
        case '1.1.5':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'network') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            sizeScale: {
                                ...migratedWidget.content.details.sizeScale,
                                placement: 'left',
                            },
                        }
                    } else if (_widget.content.kind === 'filter') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            fontSize: 14,
                        }
                    } else if (_widget.content.kind === 'insight') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            interventionConfig: {
                                ...migratedWidget.content.details.interventionConfig,
                                layout: 'two-column',
                            },
                        }
                    }

                    migratedWidget.conditionalVisibility = {
                        isEnabled: false,
                        variableName: null,
                        variableValue: null,
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)
                _slide.variables = {}

                return _slide
            })
        /*
         * Version change:      "1.1.6" to "1.1.7"
         * Changes:
         *  -    Insight widget:
         *         - Added basicConfiguration property to insight widget
         */
        /* @ts-ignore falls through */
        case '1.1.6':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'insight') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            basicConfiguration: defaultInsightWidgetValues.basicConfiguration,
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.1.7" to "1.1.8"
         * Changes:
         *  -    Table widget:
         *         - Added fontStyles to all table configs
         *  -    Controllable widgets:
         *         - Changed the data structure of the connectedWidgets from string[] to {id:string,config?:object}[]
         *
         *  -     widget filters:
         *          - update the filters type for the applicable widgets to have operator and value
         */
        /* @ts-ignore falls through */
        case '1.1.7':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'table') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            config: {
                                ...migratedWidget.content.details.config,
                                fontStyles: {
                                    fontFamily: 'Poppins',
                                    fontSize: 14,
                                    fontWeight: 'normal',
                                },
                            },
                        }
                    }

                    if ('selectedDataSource' in migratedWidget.content.details) {
                        if (
                            Array.isArray(migratedWidget.content.details.selectedDataSource.filters) &&
                            migratedWidget.content.details.selectedDataSource.filters.length > 0
                        ) {
                            migratedWidget.content.details.selectedDataSource.filters =
                                migratedWidget.content.details.selectedDataSource.filters.map((item: any) => {
                                    return {
                                        field: item.field,
                                        operator: 'containsAny',
                                        value: item.values,
                                    }
                                })
                        }
                    }

                    if ('connectedWidgets' in migratedWidget.content.details) {
                        migratedWidget.content.details.connectedWidgets =
                            migratedWidget.content.details.connectedWidgets.map((item: any) => {
                                return {
                                    id: item,
                                }
                            })
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.1.8" to "1.1.9"
         * Changes:
         *  -    Report slide onboarding:
         *         - convert onboarding from array of steps to an object of {steps:[],config:{}}
         *  -    Report Master Settings:
         *         - added onboarding object
         */
        /* @ts-ignore falls through */
        case '1.1.8':
            newState.slides = newState.slides.map((_slide: any) => {
                const onboarding = structuredClone(_slide.onboarding) ?? []

                _slide.onboarding = {
                    steps: onboarding,
                    config: {
                        finishButtonText: 'Finish',
                        finishBehavior: 'close',
                    },
                }

                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'insight') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            fontStyles: {
                                fontFamily: 'Poppins',
                                fontSize: 14,
                            },
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

            newState.masterSettings.onboarding = {
                // hasSkipButton: true,
                // onFinishBehavior: 'next-slide',
                onFinishText: 'Finish',
                // onNextText: 'Next Slide',
                // skipText: 'Close',
            }
        /*
         * Version change:      "1.1.9" to "1.2.0"
         * Changes:
         *  -    insight widget:
         *         - added fontStyles to insight widget
         */
        /* @ts-ignore falls through */
        case '1.1.9':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'insight') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            fontStyles: {
                                fontFamily: 'Poppins',
                                fontSize: 14,
                            },
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })

            newState.masterSettings.onboarding = {
                // hasSkipButton: true,
                // onFinishBehavior: 'next-slide',
                onFinishText: 'Finish',
                // onNextText: 'Next Slide',
                // skipText: 'Close',
            }
        /*
         * Version change:      "1.2.0" to "1.2.1"
         * Changes:
         *  -    widget tooltip refactor:
         *        - updated widget tooltip to also include container style as well as a boolean to enable/disable the filter info
         */
        /* @ts-ignore falls through */
        case '1.2.0':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    migratedWidget.tooltip = {
                        ...migratedWidget.tooltip,
                        icon: {
                            type: migratedWidget.tooltip.icon.type,
                            size: migratedWidget.tooltip.icon.size,
                            color: migratedWidget.tooltip.icon.color,
                        },
                        container: {
                            mode: 'floating',
                            paddingX: migratedWidget.tooltip.icon.paddingX,
                            paddingY: migratedWidget.tooltip.icon.paddingY,
                            placement: migratedWidget.tooltip.icon.placement,
                        },
                        filterInfo: false,
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.2.1" to "1.2.2"
         * Changes:
         *  -    Insight widget:
         *        - refactor icon of insight messages to become an object where users can upload their own icon
         */
        /* @ts-ignore falls through */
        case '1.2.1':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'insight') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            idField: migratedWidget.content.details.idField ?? {
                                type: 'basic',
                                field: 'id',
                            },
                            labelField: migratedWidget.content.details.labelField ?? {
                                type: 'basic',
                                field: 'label',
                            },
                            insights: migratedWidget.content.details.insights.map((insight: any) => ({
                                ...insight,
                                message: {
                                    satisfied: {
                                        ...insight.message.satisfied,
                                        icon: undefined,
                                        actions: [],
                                    },
                                    unsatisfied: {
                                        ...insight.message.unsatisfied,
                                        icon: undefined,
                                        actions: [],
                                    },
                                },
                            })),
                        }
                    } else if (_widget.content.kind === 'dynamicControl') {
                        const { label, secondaryLabel, secondaryColor, styles, ...others } =
                            migratedWidget.content.details
                        const { color, colorScondary, ...othersStyles } = styles
                        migratedWidget.content.details = {
                            ...others,
                            fontStyle: othersStyles,
                            nonActiveConfig: {
                                color,
                                label,
                                icon: undefined,
                            },
                            activeConfig: {
                                enabled: true,
                                color: colorScondary ?? color,
                                label: secondaryLabel ?? label,
                                icon: undefined,
                            },
                        }
                    }
                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.2.2" to "1.2.3"
         * Changes:
         *  -    Combined Widget:
         *        - added two new properties, stackDirection and accordion, to combined widget in select mode
         */
        /* @ts-ignore falls through */
        case '1.2.2':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'combined' && _widget.content.details.mode === 'select') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            stackDirection: 'column',
                            accordion: {
                                enabled: true,
                                closeOthers: false,
                            },
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.2.3" to "1.2.4"
         * Changes:
         *  -    panel Widget:
         *        - rename layers to views
         */
        /* @ts-ignore falls through */
        case '1.2.3':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'panel') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            views: migratedWidget.content.details.layers,
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
        /*
         * Version change:      "1.2.4" to "1.2.5"
         * Changes:
         *  -    Network Widget:
         *        - add new property to network widget to allow control running layout algorithm after filtering
         *        - add dynamic group by feature
         *  -    Inisght Widget:
         *       - add new property to categories to interventionConfig
         *       - add new property to filterConfig to interventionConfig
         */
        /* @ts-ignore falls through */
        case '1.2.4':
            newState.slides = newState.slides.map((_slide: any) => {
                const updatedWidgets = _slide.widgets.map((_widget: any) => {
                    const migratedWidget = structuredClone(_widget)

                    if (_widget.content.kind === 'network') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            runLayoutAfterFilter: true,
                            groupBy: {
                                enabled: false,
                                placement: 'left',
                                fields: [],
                                selectedField: null,
                            },
                        }
                    } else if (_widget.content.kind === 'insight') {
                        migratedWidget.content.details = {
                            ...migratedWidget.content.details,
                            interventionConfig: {
                                ...migratedWidget.content.details.interventionConfig,
                                categories: [],
                                filterConfig: [],
                            },
                        }
                    }

                    return migratedWidget
                })

                _slide.widgets = structuredClone(updatedWidgets)

                return _slide
            })
    }

    return newState
}

export default migrateReportState
