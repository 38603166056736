import { useCallback, useRef, useEffect } from 'react'

// A generic hook that takes a callback function of any type, caches its results,
// and clears the cache when specified dependencies change.
export default function useCachedCallback<T extends (...args: any[]) => any>(
    callback: T,
    dependencies: ReadonlyArray<any> // An array of dependencies
): (...funcArgs: Parameters<T>) => ReturnType<T> {
    // Using a ref to store the cache, it won't trigger re-renders when updated
    const cacheRef = useRef<Map<string, ReturnType<T>>>(new Map())

    // Clear cache when dependencies change
    useEffect(() => {
        cacheRef.current.clear()
    }, [...dependencies]) // Spread dependencies into the dependency array of useEffect

    // Wrap the memoization logic in useCallback to ensure the function identity is stable
    const memoizedCallback = useCallback(
        (...args: Parameters<T>): ReturnType<T> => {
            // Serialize arguments to use as a cache key
            const cacheKey = JSON.stringify(args)
            if (cacheRef.current.has(cacheKey)) {
                // Return the cached result if it exists
                return cacheRef.current.get(cacheKey) as ReturnType<T>
            } else {
                // Call the original function and cache its result
                const result = callback(...args)
                cacheRef.current.set(cacheKey, result)
                return result
            }
        },
        [callback, ...dependencies]
    ) // Include dependencies to ensure memoization is based on them

    return memoizedCallback
}
