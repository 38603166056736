//* ======= Libraries
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Merge, PartialDeep } from 'type-fest'
import { Divider, Stack, Typography } from '@mui/material'
//* ======= Components and features
import PieChartDataConfigDialog from 'features/report-designer/widgets/chart-widget/settings/PieChartDataConfigDialog'
import FontPicker, { FontPickerValueType } from 'components/widgets/FontPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { ChartWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { PieChartType } from 'features/chart/Chart.asset'
import { GenerateChartOptionsParamsModeType } from 'features/chart/Chart.helper'
import { DECIMAL_PRECISION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { deepMergeCustom } from 'helpers/helpers'
//* ======= Assets and styles

const verticalAlignOptions = ['top', 'center', 'bottom']
const horizontalAlignOptions = ['left', 'center', 'right']

export type ReportPieChartWidgetSettingsStateType = Merge<
    Pick<ChartWidgetType, 'selectedDataSource' | 'type' | 'dataDefinition' | 'config' | 'options'>,
    PieChartType
>

type Props = {
    data: ReportPieChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onUpdate: (
        mode: GenerateChartOptionsParamsModeType,
        updatedValues: Merge<
            Partial<Pick<ChartWidgetType, 'selectedDataSource'>>,
            Partial<Pick<PieChartType, 'dataDefinition' | 'config'>>
        >
    ) => void
}

function PieChartWidgetSettings({ data, dataSources, onUpdate }: Props) {
    // Create an internal chart data on props change.
    const chartData = useMemo<ReportPieChartWidgetSettingsStateType>(() => {
        return {
            selectedDataSource: data.selectedDataSource,
            type: 'pie',
            dataDefinition: data.dataDefinition,
            config: data.config,
            options: data.options,
        }
    }, [data])

    const [isDataConfigDialogOpen, setIsDataConfigDialogOpen] = useState(false)

    const onChartConfigUpdate = (updatedConfig: PartialDeep<ReportPieChartWidgetSettingsStateType['config']>) => {
        const config = deepMergeCustom(structuredClone(chartData.config), updatedConfig)

        onUpdate('config', {
            config: config,
        })
    }

    const onFontPickerChange = useCallback(
        (configField: keyof ReportPieChartWidgetSettingsStateType['config'], values: FontPickerValueType) => {
            let updatedConfig: PartialDeep<ReportPieChartWidgetSettingsStateType['config']> = {}

            // Title and Legend
            if (configField === 'title' || configField === 'legend') {
                updatedConfig = {
                    ...updatedConfig,
                    [configField]: {
                        styles: {
                            fontFamily: values.fontFamily,
                            fontSize: values.fontSize,
                            fontWeight: values.isBold ? 'bold' : 'normal',
                            color: values.color,
                        },
                    },
                }
            }

            onChartConfigUpdate(updatedConfig)
        },
        [chartData]
    )

    const onChartDataUpdate = (
        updatedData: Pick<ReportPieChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>
    ) => {
        // We generate the options again and update store state.
        onUpdate('data', {
            selectedDataSource: updatedData.selectedDataSource,
            dataDefinition: updatedData.dataDefinition,
        })

        setIsDataConfigDialogOpen(false)
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                paddingX: theme.spacing(2),
            })}
        >
            {/* Configure data button
				========================================= */}
            <BaseButton
                label="Configure Data"
                onClick={(evt) => setIsDataConfigDialogOpen(true)}
                variant="outlined"
                sx={(theme) => ({
                    alignSelf: 'center',

                    width: '80%',
                    paddingY: theme.spacing(1.5),
                })}
            />

            <Divider />

            {/* Config settings
                ========================================= */}
            {chartData.options !== null ? (
                <>
                    {/* Decimal precision
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            fontSize={14}
                            noWrap
                            sx={(theme) => ({
                                flex: '1 0 0',

                                paddingY: theme.spacing(1),
                            })}
                        >
                            Decimal Precision:
                        </Typography>

                        <BaseSelectWithLabel
                            options={DECIMAL_PRECISION_OPTIONS}
                            value={chartData.config.decimalPrecision}
                            onChange={(value) =>
                                onChartConfigUpdate({
                                    decimalPrecision: value,
                                })
                            }
                            hiddenLabel
                            size="small"
                            sx={{
                                flex: '0 0 30%',
                            }}
                        />
                    </Stack>

                    {/* Title settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Title"
                        hasToggle
                        isToggledOff={chartData.config.title.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                title: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Title"
                                defaultValue={chartData.config.title.value}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            value: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Secondary title value
                                ========================================= */}
                            <BaseFilledTextField
                                label="Secondary Title"
                                defaultValue={chartData.config.title.secondaryValue}
                                onBlur={(evt) =>
                                    onChartConfigUpdate({
                                        title: {
                                            secondaryValue: evt.target.value.trim(),
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                                fullWidth
                            />

                            {/* Vertical align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Vertical Align"
                                options={verticalAlignOptions}
                                value={chartData.config.title.verticalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            verticalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Horizontal align
                                ========================================= */}
                            <BaseSelectWithLabel
                                label="Horizontal Align"
                                options={horizontalAlignOptions}
                                value={chartData.config.title.horizontalAlign}
                                onChange={(value) =>
                                    onChartConfigUpdate({
                                        title: {
                                            horizontalAlign: value,
                                        },
                                    })
                                }
                                disabled={chartData.config.title.isEnabled === false}
                                size="small"
                            />

                            {/* Title font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('title', values)}
                                disabled={chartData.config.title.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.title.styles?.fontFamily,
                                    fontSize: chartData.config.title.styles?.fontSize,
                                    color: chartData.config.title.styles?.color,
                                    isBold: chartData.config.title.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Legend settings
				        ========================================= */}
                    <StyledWidgetAccordion
                        title="Legend"
                        hasToggle
                        isToggledOff={chartData.config.legend.isEnabled === false}
                        onToggle={(isEnabled) =>
                            onChartConfigUpdate({
                                legend: {
                                    isEnabled: isEnabled,
                                },
                            })
                        }
                        hasBottomBorder
                    >
                        <Stack gap={1}>
                            {/* Legend font and color settings
						        ========================================= */}
                            <FontPicker
                                onChange={(values) => onFontPickerChange('legend', values)}
                                disabled={chartData.config.legend.isEnabled === false}
                                defaultValue={{
                                    fontFamily: chartData.config.legend.styles?.fontFamily,
                                    fontSize: chartData.config.legend.styles?.fontSize,
                                    color: chartData.config.legend.styles?.color,
                                    isBold: chartData.config.legend.styles?.fontWeight === 'bold',
                                }}
                                styleControls={false}
                            />
                        </Stack>
                    </StyledWidgetAccordion>
                </>
            ) : (
                false
            )}

            {/* (Out-of-flow) Data config dialog
				========================================= */}
            {isDataConfigDialogOpen && (
                <PieChartDataConfigDialog
                    isOpen={isDataConfigDialogOpen}
                    data={chartData}
                    dataSources={dataSources}
                    onConfirm={onChartDataUpdate}
                    onClose={() => setIsDataConfigDialogOpen(false)}
                />
            )}
        </Stack>
    )
}

export default PieChartWidgetSettings
