import { Box, Chip, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Chart from 'features/chart/Chart'
import { InsightItemType, NodeDictonaryType } from '../../InisghtWidgetInterventionAdvancedView'
import React, { useEffect, useMemo, useState } from 'react'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import {
    InsightWidgetType,
    ReportDataSourceAttributeType,
    ReportDataSourceRowType,
} from 'features/report-designer/types/reportDesigner.types'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import Color from 'color'
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    applyAggregation,
    convertNodeAttributeToKeyString,
    getDataSourceData,
} from 'features/report-designer/helpers/reportDesigner.helper'
import AdvancedInsightCorrelation from '../widgets/correlation/AdvancedInsightCorrelation'
import { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'

type InsightSummaryPanelType = {
    selectedDataSource: InsightWidgetType['selectedDataSource']
    categories: InsightWidgetType['interventionConfig']['categories']
    items: InsightItemType
    nodeDictionary: NodeDictonaryType
    setActiveView: (value: { category: string | null; insightId: string | null }) => void
    totalNodes: number
    viewMode: ReportDesignerStoreStateType['viewMode']
    correlationConfig: InsightWidgetType['interventionConfig']['correlationConfig']
    dataSourceFields: FlexibleSelectOptionType[]
    updateCorrelationConfig: (config: InsightWidgetType['interventionConfig']['correlationConfig']) => void
}

const InsightSummaryPanel = ({
    items,
    setActiveView,
    nodeDictionary,
    categories,
    totalNodes,
    selectedDataSource,
    viewMode,
    correlationConfig,
    dataSourceFields,
    updateCorrelationConfig,
}: InsightSummaryPanelType) => {
    const [issuesSummary, setIssuesSummary] = useState<{
        totalIssues: number
        highUrgencyIssues: number
    }>({
        totalIssues: 0,
        highUrgencyIssues: 0,
    })

    const [actionSummary, setActionSummary] = useState<{
        totalActions: number
        actionCategories: { name: string; count: number }[]
    }>({
        totalActions: 0,
        actionCategories: [],
    })

    useEffect(() => {
        const totalIssues = Object.values(items).reduce((acc, category) => {
            return acc + Object.values(category.issues).reduce((acc, insight) => acc + insight.nodes.length, 0)
        }, 0)

        const highUrgencyIssues = Object.values(items).reduce((acc, category) => {
            return (
                acc +
                Object.values(category.issues).reduce((acc, insight) => {
                    return acc + insight.nodes.filter((node) => node.severity === 'high').length
                }, 0)
            )
        }, 0)

        const _actionCategories = new Map<string, Set<string>>()

        for (let category of Object.values(items)) {
            for (let insight of Object.values(category.issues)) {
                for (let action of insight.actions) {
                    if (!_actionCategories.has(action.category)) {
                        _actionCategories.set(action.category, new Set())
                    }
                    // add all the nodes that are impacted by this action to the set
                    for (let node of insight.nodes) {
                        _actionCategories.get(action.category)!.add(node.id)
                    }
                }
            }
        }

        const actionCategories = Array.from(_actionCategories.entries()).map(([name, nodes]) => ({
            name,
            count: nodes.size,
        }))

        setActionSummary({
            totalActions: actionCategories.reduce((acc, x) => acc + x.count, 0),
            actionCategories,
        })

        setIssuesSummary({
            totalIssues,
            highUrgencyIssues,
        })
    }, [nodeDictionary, categories, items])

    const sunburstChartOptions = useMemo<EChartsCustomOptionType>(() => {
        const data = Object.entries(items).map(([categoryKey, category]) => {
            const totalIssues = Object.values(category.issues).reduce((acc, insight) => acc + insight.nodes.length, 0)
            return {
                name: category.title,
                itemStyle: {
                    color: category.color,
                },
                children: Object.entries(category.issues).map(([insightId, insight]) => ({
                    name: insight.text,
                    value: insight.nodes.length,
                    itemStyle: {
                        color: category.color
                            ? Color(category.color)
                                  .lighten((1 - insight.nodes.length / totalIssues) * 0.6)
                                  .toString()
                            : 'grey',
                    },
                })),
            }
        })

        return {
            tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
            },

            series: [
                {
                    type: 'sunburst',
                    data,
                    radius: ['10%', '95%'],
                    emphasis: {
                        focus: 'ancestor',
                        label: {
                            show: false,
                        },
                    },
                    levels: [
                        {},
                        {
                            // Level 1
                            itemStyle: {
                                borderWidth: 4,
                            },
                            label: {
                                show: false,
                            },
                        },
                        {
                            // Level 2
                            itemStyle: {
                                borderWidth: 1,
                            },
                            label: {
                                show: false,
                            },
                        },
                    ],
                },
            ],
        }
    }, [items])

    const volnurableNodes = useMemo(() => {
        return Object.values(nodeDictionary)
            .sort((a, b) => b.issues.length - a.issues.length)
            .filter((x) => x.issues.length > 2)
            .map((node) => {
                const _categories: {
                    name: string
                    numberOfIssues: number
                    icon?: string
                    color?: string
                    bgColor?: string
                }[] = []

                node.issues.forEach((issue) => {
                    const _category = categories.find((x) => x.id === issue.category) ?? {
                        title: issue.category,
                        icon: 'info',
                        bgColor: 'grey',
                        color: 'black',
                    }
                    if (_categories.find((x) => x.name === _category.title)) {
                        _categories.find((x) => x.name === _category.title)!.numberOfIssues++
                    } else {
                        _categories.push({
                            name: _category.title,
                            numberOfIssues: 1,
                            icon: _category.icon,
                            bgColor: _category.color ?? 'grey',
                            color: _category.color !== undefined && Color(_category.color).isDark() ? 'white' : 'black',
                        })
                    }
                })

                return {
                    id: node.id,
                    title: node.title,
                    issues: _categories,
                }
            })
    }, [nodeDictionary, categories])

    const issueCountPieChartOptions = useMemo<EChartsCustomOptionType>(() => {
        const data: Record<number, number> = {}
        Object.entries(nodeDictionary).forEach(([nodeId, node]) => {
            if (data[node.issues.length] === undefined) {
                data[node.issues.length] = 0
            }
            data[node.issues.length]++
        })

        if (totalNodes > Object.keys(nodeDictionary).length) {
            data[0] = totalNodes - Object.keys(nodeDictionary).length
        }
        return {
            tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
            },
            series: [
                {
                    type: 'pie',
                    data: Object.entries(data).map(([numberOfIssues, count]) => {
                        const numberOfIssuesInt = parseInt(numberOfIssues)
                        return {
                            name:
                                numberOfIssuesInt === 0
                                    ? 'No Issues'
                                    : numberOfIssuesInt === 1
                                    ? '1 Issue'
                                    : `${numberOfIssues} Issues`,
                            value: count,
                        }
                    }),
                },
            ],
        }
    }, [nodeDictionary, totalNodes])

    const top5Issues = useMemo(() => {
        const top5Issues: { category: string; text: string; icon: string | undefined; count: number }[] = []
        Object.values(items).forEach((category) => {
            Object.values(category.issues).forEach((insight) => {
                top5Issues.push({
                    category: category.title,
                    text: insight.text,
                    icon: insight.icon,
                    count: insight.nodes.length,
                })
            })
        })

        top5Issues.sort((a, b) => b.count - a.count).splice(5)

        return top5Issues
    }, [items])

    return (
        <Grid2 container sx={(theme) => ({})}>
            {/* Issue Summary   */}
            <Grid2 xs={12} md={6} p={2}>
                <Stack height="100%" component={Paper} direction="column" gap={1} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Issue Summary
                    </Typography>
                    {/* Total number of issues */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                            {issuesSummary.totalIssues}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            issues
                        </Typography>
                    </Stack>
                    <Divider flexItem />
                    {/* High urgency issues */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" component="span" color="error" fontWeight="medium">
                            {issuesSummary.highUrgencyIssues}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            high urgency issues
                        </Typography>
                    </Stack>

                    <Divider flexItem />
                    {/* Number of impacted students */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" component="span" fontWeight="medium">
                            {Object.keys(nodeDictionary).length}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            studnets impacted out of {totalNodes}
                        </Typography>
                    </Stack>

                    <Divider flexItem />

                    {/* Average issues per student */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" component="span" fontWeight="medium">
                            {totalNodes === 0 ? 0 : (issuesSummary.totalIssues / totalNodes).toFixed(2)}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            avg issues per student
                        </Typography>
                    </Stack>
                    <Divider flexItem />
                </Stack>
            </Grid2>

            {/* Action Summary */}
            <Grid2 xs={12} md={6} p={2}>
                <Stack height="100%" component={Paper} direction="column" gap={1} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Action Summary
                    </Typography>
                    {/* Total number of actions */}
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                            {actionSummary.totalActions}
                        </Typography>
                        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                            actions
                        </Typography>
                    </Stack>
                    <Divider flexItem />
                    {/* Action Categories */}
                    {actionSummary.actionCategories.map((category) => (
                        <React.Fragment key={category.name}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant="h5" component="span" fontWeight="medium">
                                    {category.count}
                                </Typography>
                                <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                                    {category.name}
                                </Typography>
                            </Stack>
                            <Divider flexItem />
                        </React.Fragment>
                    ))}
                </Stack>
            </Grid2>

            {/* Top 5 Issues */}
            <Grid2 xs={12} md={4} p={2}>
                <Stack height="100%" component={Paper} direction="column" gap={1} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Top 5 Issues
                    </Typography>
                    {top5Issues.map((issue) => (
                        <React.Fragment key={issue.text}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                                    {issue.count}
                                </Typography>
                                <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
                                    {issue.text}
                                </Typography>
                            </Stack>
                            <Divider flexItem />
                        </React.Fragment>
                    ))}
                </Stack>
            </Grid2>

            <Grid2 xs={12} md={8} p={2} sx={{ height: '320px' }}>
                <Stack height="100%" component={Paper} direction="column" gap={2} p={1} elevation={3}>
                    <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                        {/* Legend */}
                        <Stack direction="column" gap={2} width="100px" height="100%">
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: 'bold', width: '200px', flexGrow: 1 }}
                                gutterBottom
                            >
                                Issues by Category
                            </Typography>
                            {categories.map((category) => (
                                <Stack direction="row" gap={1} alignItems="center">
                                    <Box
                                        sx={{
                                            bgcolor: category.color,
                                            width: 30,
                                            height: 20,
                                            borderRadius: 1,
                                        }}
                                    />
                                    <Typography variant="caption">{category.title}</Typography>
                                </Stack>
                            ))}
                        </Stack>
                        <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                            <Chart type="sunburst" options={sunburstChartOptions} />
                        </Box>
                    </Stack>
                </Stack>
            </Grid2>

            <Grid2 xs={12} lg={6} p={2} height={430}>
                <Stack component={Paper} height="100%" width={'100%'} direction="column" gap={1} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Vulnerable students
                    </Typography>
                    <Box width="100%" flexGrow={1}>
                        <StyledDataGrid
                            rows={volnurableNodes}
                            columns={[
                                { field: 'title', width: 150, headerName: 'Name' },
                                {
                                    field: 'issues',
                                    headerName: 'Issues',
                                    renderCell: (params) => {
                                        return (
                                            <Stack
                                                direction="row"
                                                gap={1}
                                                sx={{ width: '100%', overflow: 'auto' }}
                                                className="u-scrollbar"
                                            >
                                                {params.value.map((issue: any) => (
                                                    <Tooltip title={issue.name} key={issue.name}>
                                                        <Chip
                                                            label={issue.numberOfIssues}
                                                            icon={
                                                                issue.icon ? (
                                                                    <DynamicIcon icon={issue.icon} />
                                                                ) : undefined
                                                            }
                                                            sx={{
                                                                backgroundColor: issue.bgColor,
                                                                color: issue.color,
                                                                '& .MuiChip-icon': {
                                                                    color: issue.color,
                                                                },
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ))}
                                            </Stack>
                                        )
                                    },
                                },
                            ]}
                            hasHeader={false}
                            hasToolbar={false}
                            hasSearch={false}
                            pagination={true}
                            autoPageSize={true}
                            rowSelection={false}
                            rowHeight={60}
                        />
                    </Box>
                </Stack>
            </Grid2>

            <Grid2 xs={12} lg={6} p={2} height={430}>
                <Stack component={Paper} height="100%" width={'100%'} direction="column" gap={1} p={1} elevation={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Issue Frequency
                    </Typography>
                    <Box sx={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>
                        <Chart type="pie" options={issueCountPieChartOptions} />
                    </Box>
                </Stack>
            </Grid2>

            <Grid2 xs={12} p={2} height={530}>
                <AdvancedInsightCorrelation
                    viewMode={viewMode}
                    selectedDataSource={selectedDataSource}
                    nodeDictionary={nodeDictionary}
                    correlationConfig={correlationConfig}
                    dataSourceFields={dataSourceFields}
                    updateCorrelationConfig={updateCorrelationConfig}
                />
            </Grid2>
        </Grid2>
    )
}

export default InsightSummaryPanel
