import React, { useState } from 'react';
import { TextField, Button, Stack, IconButton, Typography } from '@mui/material';
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { AnovaConfigType, AnovaGroupType } from 'features/network-viz/types/NetworkViz.types';


export const DefaultAnovaConfig: AnovaConfigType = {
    groups: [
        { field: '' },
        { field: '' },
        { field: '' },
    ],
}

interface AnovaConfigProps {
    config: AnovaConfigType;
    onUpdate: (config: AnovaConfigType) => void;
    nodeDataSchema: NetworkVizContextType['nodeDataSchema'];
    options: { label: string; value: any; section?: boolean }[];
}

const AnovaConfig: React.FC<AnovaConfigProps> = ({ config, onUpdate, nodeDataSchema, options }) => {
    const [groups, setGroups] = useState<AnovaGroupType[]>(config.groups);

    const updateField = (index: number, field: string) => {
        const newGroups = [...groups];
        newGroups[index] = { field };
        setGroups(newGroups);
        onUpdate({ groups: newGroups });
    };

    const addGroup = () => {
        if (groups.length < 5) {
            const newGroups = [...groups, { field: '' }];
            setGroups(newGroups);
            onUpdate({ groups: newGroups });
        }
    };

    const removeGroup = (index: number) => {
        if (groups.length > 3) {
            const newGroups = groups.filter((_, i) => i !== index);
            setGroups(newGroups);
            onUpdate({ groups: newGroups });
        }
    };

    return (
        <>
            <Grid2 xs={12} p={1}>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography>Groups</Typography>
                    {groups.length < 5 && (
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={addGroup}
                        >
                            Add Group
                        </Button>
                    )}
                </Stack></Grid2>
            {groups.map((group, index) => (
                <Grid2 xs={12} p={1} key={index}>
                    <Stack spacing={1} direction="row">
                        <BaseSelectWithLabel
                            fullWidth
                            options={options}
                            label={`Group ${index + 1}`}
                            value={group.field}
                            onChange={(field) => updateField(index, field)}
                        />
                        <IconButton
                            color="secondary"
                            disabled={groups.length < 4}
                            onClick={() => removeGroup(index)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </Grid2>
            ))}

        </>
    );
};

export default AnovaConfig;