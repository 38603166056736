//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PartialDeep } from 'type-fest'
import { LinearProgress, Stack, Typography, Tabs, Tab, Box, Slider } from '@mui/material'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import ImageUpload from 'components/ImageUpload/ImageUpload'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    ImageWidgetType,
    ReportWidgetSettingsBaseType,
} from 'features/report-designer/types/reportDesigner.types'
import { UploadImageService } from 'services/ReportApi'
import { IMAGE_FIT_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
//* ======= Assets and styles
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { StyledRangePicker } from 'features/StyledComponents/StyledRangePicker'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
import EditableTextField from 'components/base/EditableTextField'
import ReportWidgetSizePositionSettings from '../components/ReportWidgetSizePositionSettings'

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'image'>
    settings: Omit<ReportWidgetType, 'content'>
    details: ImageWidgetType
}

function ImageWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { pid, rid } = useParams()

    const { activeSlideId, updateWidget, updateWidgetStyles, updateImageWidgetContent } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updateImageWidgetContent: store.updateImageWidgetContent,
    }))

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'image') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<ImageWidgetType>) => {
        if (activeSlideId !== null && widget !== null) {
            updateImageWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    const onImageUpload = async (url: string, name: string) => {
        if (pid === undefined || rid === undefined) return

        updateDetails({
            status: 'uploading',
        })

        try {
            const result = await UploadImageService(pid, rid, url)

            if (result.success) {
                updateDetails({
                    status: 'ready',
                    source: result.data,
                    imageName: name,
                })
            } else {
                toast.error('Cannot upload the selected image!')

                updateDetails({
                    status: 'ready',
                })
            }
        } catch (error) {
            toast.error('Cannot upload the selected image!')

            updateDetails({
                status: 'ready',
            })
        }
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Image" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <EditableTextField
                                label="Widget Title"
                                value={widget.settings.title}
                                onBlur={(value) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: value,
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(conditionalVisibility) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility,
                                        },
                                    })
                                }}
                            />

                            {/* Tooltip
                                ========================================= */}
                            <ReportWidgetTooltipSettings
                                tooltip={widget.settings.tooltip}
                                onChange={(tooltip) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            tooltip,
                                        },
                                    })
                                }}
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            <Typography fontSize={14} noWrap textAlign="left">
                                Current file name:
                            </Typography>

                            {/* Image name
                                ========================================= */}
                            <Typography fontSize={14} noWrap textAlign="left">
                                {widget.details.imageName || '(N/A)'}
                            </Typography>

                            {/* Upload button
                                ========================================= */}
                            <BaseButton
                                label="Select File"
                                onClick={(evt) => document.getElementById('image-widget-upload-input')?.click()}
                                disabled={widget.details.status === 'uploading'}
                                variant="outlined"
                                startIcon={
                                    <FileUploadIcon
                                        sx={{
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                }
                                sx={{
                                    paddingY: 1.5,
                                }}
                            />

                            {/* Uploading status indicator
                                ========================================= */}
                            {widget.details.status === 'uploading' && <LinearProgress />}

                            {/* Image fit select
                                ========================================= */}
                            <BaseSelectWithLabel
                                options={IMAGE_FIT_OPTIONS}
                                value={widget.details.fit}
                                onChange={(value) =>
                                    updateDetails({
                                        fit: value,
                                    })
                                }
                                disabled={widget.details.source === ''}
                                label="Image fit"
                                size="small"
                            />

                            {/* Image Transparency
                                ========================================= */}
                            <Slider
                                value={widget.details.transparency === undefined ? 100 : widget.details.transparency}
                                onChange={(evt, newValue) =>
                                    updateDetails({
                                        transparency: newValue as number,
                                    })
                                }
                                valueLabelDisplay="auto"
                                min={0}
                                max={100}
                                step={1}
                                disabled={widget.details.source === ''}
                            />
                        </Stack>
                    ) : (
                        false
                    )}

                    {/* (Hidden) Image upload input
                        ========================================= */}
                    <ImageUpload
                        // This id is tied to logic. Change accordingly.
                        inputId="image-widget-upload-input"
                        onComplete={onImageUpload}
                    />
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default ImageWidgetSettings
