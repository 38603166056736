//* ======= Libraries
import React, { useMemo, useState } from 'react'
//* ======= Components and features
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import DialogContent from '@mui/material/DialogContent'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles
import { Question } from 'survey-core'
import { DialogActions, Tooltip } from '@mui/material'
import FreeSoloCreateOption from 'components/base/FreeSoloCreateOption'
import { AddQuestionService } from 'services/QuestionBankApi'
import SurveyQuestionPreview from '../SurveyQuestionPreview'

interface IAddQuestionDialog {
    question: Question | null
    onClose: () => void
}

function AddQuestionDialog({ question, onClose }: IAddQuestionDialog) {
    const fieldOptions = [
        {
            title: 'General',
        },
        {
            title: 'Demographics',
        },
        {
            title: 'Education',
        },
        {
            title: 'Office',
        },
        {
            title: 'Sport',
        },
        {
            title: 'Health',
        },
    ]

    const [category, setCategory] = useState<string | null>('General')
    const [description, setDescription] = useState<string>('')
    const [name, setName] = useState<string>(question ? question.name : '')

    let parsedQuestion: any | undefined = useMemo(() => {
        if (!question) return
        const jsonQuestion = question.toJSON()
        jsonQuestion.type = question.getType()
        jsonQuestion.readOnly = true
        return jsonQuestion
    }, [question])

    const isDataValid = () =>
        question != null && category != null && category.length > 3 && name != null && name.length > 3

    const addQuestion = () => {
        if (isDataValid() && category != null && question != null) {
            const jsonQuestion = question.toJSON()
            jsonQuestion.type = question.getType()
            AddQuestionService({
                question: jsonQuestion,
                category,
                name,
                description,
            }).then((res) => res.success && onClose())
        }
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Grid
                    container
                    minHeight={560}
                    sx={{
                        px: 3,

                        backgroundColor: 'common.bg_2',
                    }}
                >
                    {/* Title and fields
				========================================= */}
                    <Grid
                        item
                        xs={4}
                        sx={(theme) => ({
                            padding: theme.spacing(4, 2, 4, 1),

                            borderRight: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        <Stack gap={1}>
                            <Typography fontSize={30} fontWeight={600} color="primary" marginBottom={1.25}>
                                Add to Question Bank
                            </Typography>

                            {/* Name
						========================================= */}
                            <BaseFilledTextField
                                label="Name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                sx={{
                                    marginBottom: 1,
                                }}
                            />

                            {/* Category
						========================================= */}
                            <FreeSoloCreateOption
                                required={true}
                                options={fieldOptions}
                                value={category ? { title: category } : null}
                                setValue={(v) => setCategory(v)}
                                label="Category"
                            />

                            {/* Tags
                        ToDo
						========================================= */}
                            {/* <BaseSelect
                        options={selectOptions}
                        placeholder="Tags"
                        sx={{
                            marginBottom: 1,
                        }}
                    /> */}

                            {/* Public status
						========================================= */}
                            <RadioGroup
                                row
                                sx={{
                                    gap: 4,

                                    marginBottom: 1,
                                }}
                            >
                                <Tooltip title="Private Questions not supported yet">
                                    <FormControlLabel
                                        value="private"
                                        disabled
                                        control={<Radio />}
                                        label="Private"
                                    />
                                </Tooltip>
                                <FormControlLabel value="public" checked control={<Radio />} label="Public" />
                            </RadioGroup>

                            {/* Description
						========================================= */}
                            <Typography fontSize={16} fontWeight={400} color="secondary" marginTop="auto">
                                Short description (optional)
                            </Typography>
                            <BaseFilledTextField
                                hiddenLabel
                                multiline
                                rows={4}
                                defaultValue={description}
                                onBlur={(e) => setDescription(e.target.value)}
                            />
                        </Stack>
                    </Grid>

                    {/* Question preview
				========================================= */}
                    <Grid
                        item
                        xs={8}
                        sx={(theme) => ({
                            alignSelf: 'stretch',

                            padding: theme.spacing(4, 0, 4, 3),
                        })}
                    >
                        <Stack gap={3}>
                            {/* Header title
						========================================= */}
                            <Typography
                                fontSize={16}
                                fontWeight={600}
                                color="primary"
                                sx={(theme) => ({
                                    paddingBottom: 1,

                                    borderBottom: `1px solid ${theme.palette.common.border_2}`,
                                })}
                            >
                                <SurveyQuestionPreview question={parsedQuestion} />
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose}>Close</BaseButton>
                <BaseButton variant="contained" disabled={!isDataValid()} onClick={addQuestion}>
                    Add
                </BaseButton>
            </DialogActions>
        </React.Fragment>
    )
}

export default AddQuestionDialog
