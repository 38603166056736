import { SurveyInstanceApis as Apis, parseUrl } from './Apis'
import { NodeType } from './NodeService'
import { ReportType } from './ReportApi'
import { BaseResponseType, request } from './request'

//Get survey Instance token

export type GetSurveyInstanceTokenRequestType = {
    surveyId: number
    passcode: string
}

export type GetSurveyInstanceTokenResponseType = {
    success: boolean
    data: string
}

export const GetSurveyInstanceTokenService: (
    loginRequest: GetSurveyInstanceTokenRequestType,
    showMessage?: boolean
) => Promise<GetSurveyInstanceTokenResponseType> = async (loginRequest, showMessage = false) => {
    return request(Apis.GetToken, 'POST', undefined, loginRequest, showMessage)
}

/// Get Survey instance with token

export type SurveyInstanceType = {
    surveyInstanceId: number
    status: 'not_started' | 'awaitin_approval' | 'invited' | 'in_progress' | 'completed' | 'expired' | 'cenceled'
    report?: ReportType['state']
    hasReport?: boolean
    color: string
    surveyName: string
    surveyId: number
    avatar: string
    currentPage: number
    surveyJson: string
    response: any
    reportResponse: any
}

export type GetSurveyInstanceResponseType = {
    success: boolean
    data: SurveyInstanceType & { suggestions: { [key: string]: NodeType[] } }
}

export const GetSurveyInstance: (
    token: string,
    showMessage?: boolean,
    showError?: boolean
) => Promise<GetSurveyInstanceResponseType> = async (token, showMessage = false, showError = true) => {
    return request(parseUrl(Apis.GetInstance, { token }), 'GET', undefined, undefined, showMessage, '', showError)
}

export const GetSurveyInstancePublicService: (
    token: string,
    showMessage?: boolean,
    showError?: boolean
) => Promise<GetSurveyInstanceResponseType> = async (token, showMessage = false, showError = true) => {
    return request(parseUrl(Apis.GetPublicSurvey, { token }), 'GET', undefined, undefined, showMessage, '', showError)
}

//Save survey instance

export type SaveSurveyInstanceRequestType = {
    timeSpent: number
    currentPage: number
    response: any
}

export const SaveSurveyInstanceService: (
    token: string,
    saveRequest: SaveSurveyInstanceRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (token, saveRequest, showMessage = false) => {
    return request(parseUrl(Apis.SaveInstance, { token }), 'PUT', undefined, saveRequest, showMessage)
}

//Submit survey instance

export type SubmitSurveyInstanceRequestType = {
    timeSpent: number
    response: any
}

export const SubmitSurveyInstanceService: (
    token: string,
    submitRequest: SubmitSurveyInstanceRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (token, submitRequest, showMessage = false) => {
    return request(parseUrl(Apis.SubmitInstance, { token }), 'PUT', undefined, submitRequest, showMessage)
}

export const SubmitPublicSurveyInstanceService: (
    token: string,
    submitRequest: SubmitSurveyInstanceRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (token, submitRequest, showMessage = false) => {
    return request(parseUrl(Apis.SubmitPublicInstance, { token }), 'POST', undefined, submitRequest, showMessage)
}

export const SubmitOpenSurveyInstanceService: (
    pid: string | number,
    sid: string | number,
    submitRequest: SubmitSurveyInstanceRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, submitRequest, showMessage = false) => {
    return request(parseUrl(Apis.SubmitOpenSurveyInstance, { pid, sid }), 'POST', undefined, submitRequest, showMessage)
}

export const ChangeSurveyStatusService: (
    pid: string,
    sid: string | number,
    status: {
        status: 'not_started' | 'awaitin_approval' | 'invited' | 'in_progress' | 'completed' | 'expired' | 'cenceled'
    },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, status, showMessage = false) => {
    return request(parseUrl(Apis.ChangeStatus, { pid, sid }), 'PUT', undefined, status, showMessage)
}
