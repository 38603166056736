//* ======= Libraries
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    Stack,
    Box,
    Typography,
    MenuItem,
    IconButton,
    Checkbox,
    Tooltip,
    Select,
    Avatar,
    InputBase,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    styled,
    Switch,
    Link,
} from '@mui/material'
//* ======= Components and features
import StyledDialog from 'components/dialog/StyledDialog'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportDataSourceType,
    ReportSlideType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import {
    CreateReportCustomViewService,
    DeleteReportAccesService,
    DeleteReportCustomViewService,
    GetReportViewerAccountsService,
    ReportViewerAccountType,
    ResendReportAccesInviteService,
    ShareReportService,
    UpdateReportAccesService,
    UpdateReportCustomViewService,
    UpdateReportViewerAccountsService,
} from 'services/ReportApi'
import { isEmailValid } from 'helpers/validators'
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkIcon from '@mui/icons-material/Link'
import SearchIcon from '@mui/icons-material/Search'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EmailIcon from '@mui/icons-material/Email'
import { ProjectAccountType } from 'services/ProjectService'
import { update } from 'lodash'
import ToastHelper from 'helpers/ToastHelper'

type Props = {
    isOpen: boolean
    dataSources: ReportDesignerStoreStateType['dataSources']
    slides: ReportDesignerStoreStateType['slides']
    defaultCustomViews: ReportCustomViewType[]
    defaultReportAccess: ReportShareType[]
    token: string | null
    onConfirm?: () => void
    onClose: () => void
}

function ShareReportDialog({
    isOpen,
    dataSources,
    defaultReportAccess,
    slides,
    defaultCustomViews,
    token,
    onConfirm,
    onClose,
}: Props) {
    const { pid, rid } = useParams()

    const { updateCustomViews: saveCustomViews, updateReportAccess: saveReportAccess } = useReportStore((store) => ({
        updateCustomViews: store.updateCustomViews,
        updateReportAccess: store.updateReportAccess,
    }))

    // * invite user logic
    const [users, setUsers] = useState<ReportShareType[]>(defaultReportAccess || [])
    const [reportViewers, setReportViewers] = useState<ReportViewerAccountType[]>([])

    useEffect(() => {
        if (isOpen && pid && rid) {
            const fetchReportViewers = async () => {
                try {
                    const response = await GetReportViewerAccountsService(pid, rid)
                    if (response.success) {
                        setReportViewers(response.data)
                    }
                } catch {}
            }
            fetchReportViewers()
        } else {
            setUsers([])
        }
    }, [pid, rid, isOpen])

    const onInvite = async (data: ReportShareType[]) => {
        try {
            const response = await ShareReportService(pid!, rid!, data)
            if (response.success) {
                setUsers((pv) => {
                    const temp = [...pv]
                    for (let access of response.data) {
                        const index = temp.findIndex((x) => x.primaryKey === access.primaryKey)
                        if (index !== -1) {
                            temp[index].customViewId = access.customViewId
                        } else {
                            temp.push(access)
                        }
                    }
                    return temp
                })
                setViewMode('main')
                return
            }
        } catch {}
        toast.error('Cannot invite the selected users')
        return
    }

    const onDeleteReportAccess = async (raid: number) => {
        try {
            const response = await DeleteReportAccesService(pid!, rid!, raid)
            if (response.success) {
                toast.success('Access revoked successfully')
                setUsers((pv) => pv.filter((x) => x.primaryKey !== raid))
                return
            }
        } catch {}
        toast.error('Cannot Revoke the access')
        return
    }

    const onUpdateReportAccess = async (raid: number, value: number) => {
        try {
            const response = await UpdateReportAccesService(pid!, rid!, raid, { customViewId: value })
            if (response.success) {
                toast.success('Access updated successfully')
                setUsers((pv) => {
                    const tmp = [...pv]
                    const index = tmp.findIndex((x) => x.primaryKey === raid)
                    if (index !== -1) tmp[index].customViewId = value
                    return tmp
                })
                return
            }
        } catch {}
        toast.error('Cannot update the access')
        return
    }

    // * custom view logic
    const [customViews, setCustomViews] = useState<ReportCustomViewType[]>(defaultCustomViews)

    const [selectedCustomView, setSelectedCustomView] = useState<number>(-1)

    const [viewMode, setViewMode] = useState<'main' | 'customView' | 'invite'>('main')

    const onEnterCustomView = (viewIdx: number = -1) => {
        setViewMode('customView')
        setSelectedCustomView(viewIdx)
    }

    const onCreateCustomView = async (data: ReportCustomViewType) => {
        if (selectedCustomView === -1) {
            // * create
            try {
                const { title, primaryKey, ...rest } = data
                const response = await CreateReportCustomViewService(pid!, rid!, {
                    title: title,
                    state: JSON.stringify(rest),
                })
                if (response.success) {
                    data.primaryKey = response.data as number
                    setCustomViews((pv) => [...pv, data])
                    setSelectedCustomView(-1)
                    setViewMode('main')
                    return
                }
            } catch {}
            toast.error('Cannot create the custom view')
            return
        }
        // * update
        else {
            try {
                const { title, ...rest } = data
                const response = await UpdateReportCustomViewService(
                    pid!,
                    rid!,
                    customViews[selectedCustomView!].primaryKey,
                    {
                        title: title,
                        state: JSON.stringify(rest),
                    }
                )
                if (response.success) {
                    setCustomViews((pv) => {
                        const tmp = [...pv]
                        tmp[selectedCustomView!] = data
                        return tmp
                    })
                    setSelectedCustomView(-1)
                    setViewMode('main')
                    return
                }
            } catch {}
            toast.error('Cannot create the custom view')
            return
        }
    }

    const onDeleteCustomView = async (cvid: number) => {
        try {
            const response = await DeleteReportCustomViewService(pid!, rid!, cvid)
            if (response.success) {
                toast.success('Custom view deleted successfully')
                setCustomViews((pv) => pv.filter((x) => x.primaryKey !== cvid))
                return
            }
        } catch {}
        toast.error('Cannot delete the custom view')
        return
    }

    const onResendInvite = async (raid: number) => {
        try {
            const response = await ResendReportAccesInviteService(pid!, rid!, raid)
            if (response.success) {
                toast.success('Invite Email Sent successfully')
                return
            }
        } catch {}
        toast.error('Cannot re-send Invite Email')
        return
    }

    const saveReportViewerAccess = async (data: ReportViewerAccountType[]) => {
        const toast = new ToastHelper({
            errorMessage: 'Cannot update the report viewer access',
            loadingMessage: 'Updating report viewer access...',
            successMessage: 'Report viewer access updated successfully',
        })
        try {
            var response = await UpdateReportViewerAccountsService(
                pid!,
                rid!,
                data.map((x) => ({ email: x.account.account.email, hasAccess: x.hasAccess }))
            )
            if (response.success) {
                toast.success()
                onClose()
                return
            }
        } catch {}
        toast.fail()
    }

    const onSave = () => {
        setViewMode('main')

        // saveCustomViews({
        //     views: customViews,
        // })

        // saveReportAccess({
        //     data: users,
        // })

        saveReportViewerAccess(reportViewers)
    }

    const updateReportViewerAccess = (accountId: number, hasAccess: boolean) => {
        setReportViewers((pv) => {
            const tmp = [...pv]
            const index = tmp.findIndex((x) => x.account.primaryKey === accountId)
            if (index !== -1) tmp[index].hasAccess = hasAccess
            return tmp
        })
    }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',

                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),

                    padding: theme.spacing(2, 3),

                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Share Report
                </Typography>

                <Tooltip title="Close">
                    <IconButton
                        onClick={(evt) => onClose()}
                        size="small"
                        sx={{
                            gridColumn: 3,
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    height: '70vh',
                    padding: theme.spacing(2, 3),
                    overflow: 'hidden',
                })}
            >
                {viewMode === 'main' ? (
                    <ShareReportMainView
                        token={token}
                        updateReportViewerAccess={updateReportViewerAccess}
                        reportViewers={reportViewers}
                        onUpdateReportAccess={onUpdateReportAccess}
                        onDeleteReportAccess={onDeleteReportAccess}
                        users={users}
                        onEnterInvite={() => setViewMode('invite')}
                        onSave={onSave}
                        onClose={onClose}
                        customViews={customViews}
                        onResendInvite={onResendInvite}
                        onDeleteCustomView={onDeleteCustomView}
                        onEnterCustomView={onEnterCustomView}
                    />
                ) : viewMode === 'customView' ? (
                    <ShareReportCustomView
                        dataSources={dataSources}
                        slides={slides}
                        onDiscard={() => {
                            setSelectedCustomView(-1)
                            setViewMode('main')
                        }}
                        defaultData={customViews[selectedCustomView]}
                        onCreate={onCreateCustomView}
                    />
                ) : viewMode === 'invite' ? (
                    <ShareReportInviteView
                        customViews={customViews}
                        onCreate={onInvite}
                        onDiscard={() => setViewMode('main')}
                    />
                ) : (
                    false
                )}
            </Box>
        </StyledDialog>
    )
}

/* =========================================
 * Main view
 */

const StyledPermissionInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        width: '8ch',
        padding: theme.spacing(1, 2),
        fontSize: 16,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.text_4,

        '&:focus': {},
    },
}))

type ShareReportMainViewType = {
    token: string | null
    reportViewers: ReportViewerAccountType[]
    updateReportViewerAccess: (accountId: number, hasAccess: boolean) => void
    onEnterCustomView: (viewIdx: number) => void
    onDeleteCustomView: (cvid: number) => void
    onSave: () => void
    onClose: () => void
    onEnterInvite: () => void
    customViews: ReportCustomViewType[]
    users: ReportShareType[]
    onDeleteReportAccess: (raid: number) => void
    onUpdateReportAccess: (raid: number, value: number) => void
    onResendInvite: (raid: number) => void
}

function ShareReportMainView({
    token,
    users,
    onSave,
    onClose,
    onDeleteCustomView,
    onEnterCustomView,
    customViews,
    reportViewers,
    onEnterInvite,
    onDeleteReportAccess,
    onUpdateReportAccess,
    onResendInvite,
    updateReportViewerAccess,
}: ShareReportMainViewType) {
    const { pid } = useParams()
    return (
        <Stack
            gap={3}
            sx={{
                height: '100%',
                minHeight: 0,
            }}
        >
            {/* Body
                ========================================= */}
            <Stack
                gap={2}
                sx={(theme) => ({
                    flexGrow: 1,

                    padding: theme.spacing(2, 1),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {/* Temp share link
				    ========================================= */}
                {/* <Typography
                    component="a"
                    target="_blank"
                    href={process.env.REACT_APP_BASE_URL + 'report/' + token}
                    fontSize={16}
                    fontWeight={500}
                >
                    {process.env.REACT_APP_BASE_URL + 'report/' + token}
                </Typography> */}

                {/* Share with header
                    ========================================= */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    sx={(theme) => ({
                        paddingY: theme.spacing(1),
                        marginBottom: theme.spacing(2),

                        borderBottom: `1px solid ${theme.palette.common.border_2}`,
                    })}
                >
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Share With
                    </Typography>

                    {/* <BaseButton
                        label="Invite User"
                        onClick={(evt) => onEnterInvite()}
                        startIcon={<AddIcon />}
                    /> */}
                </Stack>

                {/* Project users list
                    ========================================= */}
                <Stack gap={1} marginBottom={2}>
                    {/* <Typography fontSize={16} fontWeight={500} color="common.text_1">
                        Project Users
                    </Typography> */}
                    {reportViewers.length > 0 ? (
                        /*  Shared user item
                            ========================================= */
                        reportViewers.map((_user, idx) => (
                            <Stack
                                key={_user.account.primaryKey}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={2}
                            >
                                <Stack direction="row" alignItems="center" gap={0.2}>
                                    <Avatar src={_user.account.account.avatar}>
                                        {_user.account.account.name?.at(0)}
                                    </Avatar>
                                    {/* User email
                                    ========================================= */}
                                    <Typography
                                        fontSize={18}
                                        color="common.text_1"
                                        noWrap
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        {_user.account.account.name}
                                    </Typography>
                                    <Typography fontSize={18} color="common.text_3" noWrap>
                                        ({_user.account.account.email})
                                    </Typography>
                                </Stack>

                                <Stack direction="row" gap={1}>
                                    <Typography fontSize={18} color="common.text_3" noWrap>
                                        {_user.account.dataAccessTitle || 'Full Access'}
                                    </Typography>
                                    <Link href={`/project/${pid}/users`} target="_blank">
                                        <LinkIcon />
                                    </Link>
                                </Stack>

                                {/* Controls
                                    ========================================= */}
                                <Stack direction="row" alignItems="center" gap={2}>
                                    <Tooltip title="Share">
                                        <Switch
                                            checked={_user.hasAccess}
                                            onChange={(e, checked) =>
                                                updateReportViewerAccess(_user.account.primaryKey, checked)
                                            }
                                        />
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        ))
                    ) : (
                        /*  Empty list message
                            ========================================= */
                        <Typography fontSize={14} textAlign="center">
                            (You haven't invited any users yet.)
                        </Typography>
                    )}
                </Stack>

                {/* Shared users list
                    ========================================= */}
                {false && (
                    <Stack gap={1} marginBottom={2}>
                        <Typography fontSize={16} fontWeight={500} color="common.text_1">
                            Guest Users
                        </Typography>

                        {users.length > 0 ? (
                            /*  Shared user item
                        ========================================= */
                            users.map((_user, idx) => (
                                <Stack
                                    key={_user.primaryKey}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={2}
                                >
                                    {/* User email
                                ========================================= */}
                                    <Typography
                                        fontSize={18}
                                        color="common.text_1"
                                        noWrap
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        {_user.email}
                                    </Typography>

                                    {/* Controls
                                ========================================= */}
                                    <Stack direction="row" alignItems="center" gap={2}>
                                        {/* Resend Invite
                                    ========================================= */}
                                        <Tooltip title="Resend Invite">
                                            <IconButton
                                                onClick={(evt) => onResendInvite(_user.primaryKey!)}
                                                sx={(theme) => ({
                                                    flexShrink: 0,
                                                })}
                                            >
                                                <EmailIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {/* Permissions select
                                    ========================================= */}
                                        <Select
                                            id="user-permission-select"
                                            value={_user.customViewId}
                                            onChange={(e) =>
                                                onUpdateReportAccess(_user.primaryKey!, parseInt(e.target.value + ''))
                                            }
                                            displayEmpty
                                            input={<StyledPermissionInput />}
                                            IconComponent={ExpandMoreIcon}
                                            sx={{
                                                '& .MuiSelect-icon': {
                                                    color: 'common.fill_4',
                                                },
                                            }}
                                        >
                                            <MenuItem value={-1}>Full</MenuItem>

                                            {customViews.map((_customView, idx) => (
                                                <MenuItem key={_customView.title} value={_customView.primaryKey}>
                                                    {_customView.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* Revoke access button
                                    ========================================= */}
                                        <Tooltip title="Revoke access">
                                            <IconButton
                                                onClick={(evt) => onDeleteReportAccess(_user.primaryKey!)}
                                                sx={(theme) => ({
                                                    flexShrink: 0,

                                                    color: theme.palette.common.ung_pink,
                                                })}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            ))
                        ) : (
                            /*  Empty list message
                        ========================================= */
                            <Typography fontSize={14} textAlign="center">
                                (You haven't invited any users yet.)
                            </Typography>
                        )}
                    </Stack>
                )}

                {/* Custom views header
                    ========================================= */}
                {false && (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            paddingY: theme.spacing(1),
                            marginBottom: theme.spacing(2),

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        <Typography fontSize={18} fontWeight={600} color="primary">
                            Custom Views
                        </Typography>

                        <BaseButton
                            label="Create New"
                            onClick={(evt) => onEnterCustomView(-1)}
                            startIcon={<AddIcon />}
                        />
                    </Stack>
                )}
                {/* Custom views list
                    ========================================= */}
                {false && (
                    <Stack gap={1}>
                        {customViews.length > 0 ? (
                            /*  Custom view item
                            ========================================= */
                            customViews.map((_view, idx) => (
                                <Stack
                                    key={_view.title}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={2}
                                >
                                    {/* View title
                                    ========================================= */}
                                    <Typography
                                        fontSize={18}
                                        color="common.text_1"
                                        noWrap
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        {_view.title}
                                    </Typography>

                                    {/* Controls
                                    ========================================= */}
                                    <Stack direction="row" alignItems="center" gap={2}>
                                        {/* Edit view button
                                        ========================================= */}
                                        <BaseButton
                                            label="Edit"
                                            onClick={() => onEnterCustomView(idx)}
                                            variant="contained"
                                            startIcon={<EditIcon />}
                                            sx={(theme) => ({
                                                paddingX: theme.spacing(3),
                                            })}
                                        />

                                        {/* Remove view button
                                        ========================================= */}
                                        <Tooltip title="Delete Custom View">
                                            <IconButton
                                                onClick={(evt) => onDeleteCustomView(_view.primaryKey)}
                                                sx={(theme) => ({
                                                    flexShrink: 0,

                                                    color: theme.palette.common.ung_pink,
                                                })}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            ))
                        ) : (
                            /*  Empty list message
                            ========================================= */
                            <Typography fontSize={14} textAlign="center">
                                (No custom views set.)
                            </Typography>
                        )}
                    </Stack>
                )}
            </Stack>

            {/* Actions
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flexShrink: 0,

                    padding: theme.spacing(0, 3, 1),
                })}
            >
                <BaseButton
                    label="Discard"
                    onClick={(evt) => onClose()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Save"
                    onClick={(evt) => onSave()}
                    variant="contained"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </Stack>
    )
}

/* =========================================
 * Invite user view
 */

export type ReportShareType = {
    primaryKey?: number
    email: string
    customViewId?: number
}

type ShareReportInviteViewType = {
    customViews: ReportCustomViewType[]
    onCreate: (data: ReportShareType[]) => void
    onDiscard: () => void
}

function ShareReportInviteView({ onDiscard, customViews, onCreate }: ShareReportInviteViewType) {
    const [data, setData] = useState<ReportShareType[]>([])

    const [email, setEmail] = useState<string>('')

    const addUser = () => {
        const temp = email
        isEmailValid(email) && setData((u) => [{ email: temp, customViewId: -1 }, ...u])
        setEmail('')
    }

    const removeUser = (index: number) => {
        setData((u) => {
            const temp = [...u]
            temp.splice(index, 1)
            return temp
        })
    }

    const updateUserRole = (index: number) => (customViewId: number) => {
        setData((u) => {
            const temp = [...u]
            temp[index].customViewId = customViewId
            return temp
        })
    }

    return (
        <Stack
            gap={3}
            sx={{
                height: '100%',
                minHeight: 0,
            }}
        >
            {/* Body
                ========================================= */}
            <Stack
                gap={2}
                sx={(theme) => ({
                    flexGrow: 1,

                    padding: theme.spacing(2, 3),
                })}
            >
                {/* Heading title
                    ========================================= */}
                <Typography
                    fontSize={24}
                    fontWeight={600}
                    color="primary"
                    sx={{
                        flexShrink: 0,

                        marginBottom: 2,
                    }}
                >
                    Invite User
                </Typography>

                {/* Search and add field
				    ========================================= */}
                <Stack
                    direction="row"
                    alignItems="stretch"
                    sx={{
                        flexShrink: 0,

                        marginBottom: 2,
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            py: 0.5,
                            paddingRight: 1,
                            paddingLeft: 2,
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                            backgroundColor: 'common.bg_4',
                        }}
                    >
                        <InputBase
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="users-list"
                            placeholder="Invite by email"
                            fullWidth
                            startAdornment={
                                <SearchIcon
                                    sx={{
                                        marginRight: 1,

                                        color: 'secondary.main',
                                    }}
                                />
                            }
                            sx={{
                                height: '100%',
                            }}
                        />
                    </Box>

                    <BaseButton
                        variant="contained"
                        size="large"
                        label="Add"
                        disabled={!isEmailValid(email)}
                        disableElevation
                        onClick={addUser}
                        sx={{
                            width: 120,
                            py: 1.5,

                            borderRadius: '10px',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                    />
                </Stack>

                {/* Users list
				    ========================================= */}
                <Stack
                    gap={1}
                    sx={{
                        flex: '1 0 0',

                        overflowY: 'auto',
                    }}
                    className="u-scrollbar"
                >
                    {data.map((_user, idx) => (
                        /*  List item
				            ========================================= */
                        <Stack key={_user.email} direction="row" alignItems="center" gap={1}>
                            {/* Avatar
				                ========================================= */}
                            <Avatar
                                alt="username"
                                sx={{
                                    flexShrink: 0,

                                    height: 40,
                                    width: 40,
                                }}
                            />

                            {/* Email
				                ========================================= */}
                            <Typography
                                fontSize={14}
                                fontWeight={400}
                                color="common.text_1"
                                noWrap
                                sx={{
                                    flex: '1 0 0',
                                }}
                            >
                                {_user.email}
                            </Typography>

                            {/* Controls
				                ========================================= */}
                            <Stack direction="row" alignItems="center" gap={2} marginLeft="auto">
                                {/* Permissions select
				                    ========================================= */}
                                <Select
                                    id="user-permission-select"
                                    value={_user.customViewId}
                                    onChange={(e) => updateUserRole(idx)(parseInt(e.target.value + ''))}
                                    displayEmpty
                                    input={<StyledPermissionInput />}
                                    IconComponent={ExpandMoreIcon}
                                    sx={{
                                        '& .MuiSelect-icon': {
                                            color: 'common.fill_4',
                                        },
                                    }}
                                >
                                    <MenuItem value={-1}>Full</MenuItem>

                                    {customViews.map((_view, idx) => (
                                        <MenuItem key={_view.title} value={_view.primaryKey}>
                                            {_view.title}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {/* Remove button
				                    ========================================= */}
                                <IconButton
                                    onClick={(evt) => removeUser(idx)}
                                    sx={(theme) => ({
                                        flexShrink: 0,

                                        color: theme.palette.common.ung_pink,
                                    })}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            </Stack>

            {/* Actions
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flexShrink: 0,

                    padding: theme.spacing(0, 3, 1),
                })}
            >
                <BaseButton
                    label="Back"
                    onClick={(evt) => onDiscard()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Invite"
                    onClick={(evt) => onCreate(data)}
                    disabled={data.length === 0}
                    variant="contained"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </Stack>
    )
}

/* =========================================
 * Create "custom view" view
 */

type ReportDatasourceFilterType = {
    field: string
    values: string[]
}

export type ReportCustomViewType = {
    primaryKey: number
    title: string
    excludedSlides: ReportSlideType['id'][]
    excludedWidgets: ReportWidgetType['id'][]
    excludedDatasources: ReportDataSourceType['id'][]
    datasourceFilters: Record<ReportDataSourceType['id'], ReportDatasourceFilterType[]>
}

type ShareReportCustomViewType = {
    dataSources: ReportDesignerStoreStateType['dataSources']
    slides: ReportDesignerStoreStateType['slides']
    defaultData: ReportCustomViewType | null
    onCreate: (data: ReportCustomViewType) => void
    onDiscard: () => void
}

function ShareReportCustomView({ onDiscard, dataSources, defaultData, slides, onCreate }: ShareReportCustomViewType) {
    const [data, setData] = useState<ReportCustomViewType>(
        defaultData || {
            primaryKey: -1,
            title: '',
            datasourceFilters: {},
            excludedSlides: [],
            excludedWidgets: [],
            excludedDatasources: [],
        }
    )

    const toggleWidget = (widgetId: ReportWidgetType['id']) => {
        setData((pv) => {
            const tmp = { ...pv }
            let index = tmp.excludedWidgets.findIndex((x) => x === widgetId)
            if (index !== -1) {
                tmp.excludedWidgets.splice(index, 1)
            } else {
                tmp.excludedWidgets.push(widgetId)
            }
            return tmp
        })
    }

    const toggleSlide = (slideId: ReportSlideType['id'], isEnabled?: boolean) => {
        setData((pv) => {
            const tmp = { ...pv }
            let index = tmp.excludedSlides.findIndex((x) => x === slideId)
            if (index !== -1) {
                tmp.excludedSlides.splice(index, 1)
            } else {
                tmp.excludedSlides.push(slideId)
            }
            return tmp
        })
    }

    const toggleDatasource = (datasourceId: ReportDataSourceType['id'], isEnabled?: boolean) => {
        setData((pv) => {
            const tmp = { ...pv }
            let index = tmp.excludedDatasources.findIndex((x) => x === datasourceId)
            if (index !== -1) {
                tmp.excludedDatasources.splice(index, 1)
            } else {
                tmp.excludedDatasources.push(datasourceId)
            }
            return tmp
        })
    }

    // * filters manipulation

    const addFilterItems = (datasourceId: ReportDataSourceType['id']) => {
        setData((pv) => {
            const tmp = { ...pv }
            if (tmp.datasourceFilters[datasourceId] === undefined) {
                tmp.datasourceFilters[datasourceId] = []
            }
            tmp.datasourceFilters[datasourceId].push({
                field: '',
                values: [],
            })
            return tmp
        })
    }

    const removeFilterItems = (datasourceId: ReportDataSourceType['id'], idx: number) => () => {
        setData((pv) => {
            const tmp = { ...pv }
            if (tmp.datasourceFilters[datasourceId] !== undefined) {
                tmp.datasourceFilters[datasourceId].splice(idx, 1)
            }

            return tmp
        })
    }

    const updateFilterItems =
        (datasourceId: ReportDataSourceType['id'], idx: number, field: keyof ReportDatasourceFilterType) =>
        (value: any) => {
            setData((pv) => {
                const tmp = { ...pv }
                tmp.datasourceFilters[datasourceId][idx][field] = value
                if (field === 'field') tmp.datasourceFilters[datasourceId][idx].values = []
                return tmp
            })
        }

    const getDatasourceValues = (datasource: ReportDataSourceType, field: string): string[] => {
        if (field == null || field === '') return []
        if (datasource.mode === 'network') {
            if (datasource.networkContext.nodeDataSchema.fields[field].type === 'string') {
                return datasource.networkContext.nodeDataSchema.fields[field].range as string[]
            }
            if (datasource.networkContext.nodeDataSchema.fields[field].type === 'number') {
                return datasource.networkContext.nodeDataSchema.fields[field].range.values as string[]
            }
        } else {
            const values = new Set<string>()
            for (let panel in datasource.data) {
                datasource.data[panel].forEach((row) => values.add(row[field] + ''))
            }
            return Array.from(values)
        }
        return []
    }

    return (
        <Stack
            gap={2}
            sx={{
                height: '100%',
                minHeight: 0,
            }}
        >
            {/* Body
                ========================================= */}
            <Stack
                gap={2}
                sx={(theme) => ({
                    flexGrow: 1,

                    padding: theme.spacing(2, 3, 1),
                })}
            >
                {/* Heading title
                    ========================================= */}
                <Typography
                    fontSize={24}
                    fontWeight={600}
                    color="primary"
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    {(defaultData ? 'Edit' : 'Create') + ' Custom View'}
                </Typography>

                {/* Identified switch
                    ========================================= */}
                {/* <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                        <Typography fontSize={14} fontWeight={400} color="common.text_1">
                            De-identified
                        </Typography>

                        <BaseSwitch />
                    </Stack> */}

                {/* Title
                    ========================================= */}
                <BaseFilledTextField
                    label="Title"
                    value={data.title}
                    onChange={(evt) => {
                        setData((prevState) => ({
                            ...prevState,
                            title: evt.target.value,
                        }))
                    }}
                    required
                    sx={{
                        flexShrink: 0,
                    }}
                />

                {/* View configs
                    ========================================= */}
                <Stack
                    gap={3}
                    sx={{
                        flex: '1 0 0',

                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                    className="u-scrollbar"
                >
                    {/* Slides and widgets accordion
                        ========================================= */}
                    <StyledWidgetAccordion title="Slides and Widgets" hasBottomBorder>
                        <Stack
                            gap={1}
                            sx={{
                                paddingLeft: 2,
                            }}
                        >
                            {/* Slides list
                                ========================================= */}
                            {slides.length === 0 ? (
                                /*  Empty list message
                                    ========================================= */
                                <Typography fontSize={14}>This report has no slides yet.</Typography>
                            ) : (
                                slides.map((_slide, _slideIdx, array) => (
                                    /*  Slide item
                                        ========================================= */
                                    <StyledWidgetAccordion
                                        key={_slide.id}
                                        title={_slide.title || `Slide ${_slideIdx + 1}`}
                                        hasToggle
                                        isToggledOff={data.excludedSlides.includes(_slide.id)}
                                        onToggle={(isEnabled) => toggleSlide(_slide.id, isEnabled)}
                                        hasBottomBorder={_slideIdx !== array.length - 1}
                                    >
                                        {/* Widgets list
                                            ========================================= */}
                                        <List
                                            disablePadding
                                            sx={{
                                                paddingLeft: 2,
                                            }}
                                        >
                                            {_slide.widgets.length === 0 ? (
                                                /*  Empty list message
                                                    ========================================= */
                                                <ListItem>
                                                    <Typography fontSize={14}>
                                                        This slide has no widgets yet.
                                                    </Typography>
                                                </ListItem>
                                            ) : (
                                                /*  Widget item
                                                    ========================================= */
                                                _slide.widgets.map((_widget, _widgetIdx) => {
                                                    const labelId = 'widget-' + _widget.id

                                                    return (
                                                        <ListItem key={_widget.id} disablePadding disableGutters>
                                                            <ListItemButton
                                                                role="checkbox"
                                                                onClick={(evt) => {
                                                                    toggleWidget(_widget.id)
                                                                }}
                                                                dense
                                                                sx={{
                                                                    paddingX: 1,
                                                                }}
                                                            >
                                                                <ListItemIcon
                                                                    sx={{
                                                                        minWidth: 0,
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            data.excludedWidgets.includes(
                                                                                _widget.id
                                                                            ) === false
                                                                        }
                                                                        edge="start"
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                    />
                                                                </ListItemIcon>

                                                                <Typography
                                                                    id={labelId}
                                                                    fontSize={14}
                                                                    textAlign="left"
                                                                    noWrap
                                                                    sx={{
                                                                        flex: '1 0 0',
                                                                    }}
                                                                >
                                                                    {_widget.title}
                                                                </Typography>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )
                                                })
                                            )}
                                        </List>
                                    </StyledWidgetAccordion>
                                ))
                            )}
                        </Stack>
                    </StyledWidgetAccordion>

                    {/* Data sources accordion
                        ========================================= */}
                    <StyledWidgetAccordion title="Data Sources" hasBottomBorder>
                        <Stack
                            gap={1}
                            sx={{
                                paddingLeft: 2,
                            }}
                        >
                            {/* Data sources list
                                ========================================= */}
                            {dataSources.length === 0 ? (
                                /*  Empty list message
                                    ========================================= */
                                <Typography fontSize={14}>This report has no data sources.</Typography>
                            ) : (
                                /*  Data source item
                                    ========================================= */
                                dataSources.map((_dataSource, _dataSourceIdx, array) => {
                                    const filters = data.datasourceFilters[_dataSource.id] || []

                                    const fields =
                                        (_dataSource.mode === 'network'
                                            ? Object.keys(_dataSource.networkContext.nodeDataSchema.fields)
                                            : _dataSource.fields) || []

                                    return (
                                        <StyledWidgetAccordion
                                            key={_dataSource.id}
                                            title={_dataSource.title}
                                            hasToggle
                                            isToggledOff={data.excludedDatasources.includes(_dataSource.id)}
                                            onToggle={(isEnabled) => toggleDatasource(_dataSource.id, isEnabled)}
                                            hasBottomBorder={_dataSourceIdx !== array.length - 1}
                                        >
                                            <Stack
                                                gap={2}
                                                sx={{
                                                    paddingTop: 1,
                                                    paddingLeft: 2,
                                                }}
                                            >
                                                {/* Filters header
                                                    ========================================= */}
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <Typography fontSize={14} fontWeight={500}>
                                                        Filters
                                                    </Typography>

                                                    <BaseButton
                                                        label="Add Filter"
                                                        onClick={(evt) => addFilterItems(_dataSource.id)}
                                                        startIcon={<AddIcon />}
                                                    />
                                                </Stack>

                                                {/* Filters list
                                                    ========================================= */}
                                                <Stack gap={1}>
                                                    {filters.map((filter, filterIdx) => (
                                                        /*  Filter item
                                                            ========================================= */
                                                        <Stack
                                                            key={filterIdx}
                                                            direction="row"
                                                            alignItems="center"
                                                            gap={1}
                                                        >
                                                            {/* Field select
                                                                ========================================= */}
                                                            <BaseSelectWithLabel
                                                                label="Field"
                                                                options={fields}
                                                                value={filter.field}
                                                                onChange={updateFilterItems(
                                                                    _dataSource.id,
                                                                    filterIdx,
                                                                    'field'
                                                                )}
                                                                fullWidth
                                                                sx={{
                                                                    flex: '1 0 0',
                                                                }}
                                                            />

                                                            {/* Value select
                                                                ========================================= */}
                                                            <BaseSelectWithLabel
                                                                label="Value"
                                                                options={getDatasourceValues(_dataSource, filter.field)}
                                                                value={filter.values}
                                                                onChange={updateFilterItems(
                                                                    _dataSource.id,
                                                                    filterIdx,
                                                                    'values'
                                                                )}
                                                                selectProps={{
                                                                    multiple: true,
                                                                }}
                                                                fullWidth
                                                                sx={{
                                                                    flex: '1 0 0',
                                                                }}
                                                            />

                                                            {/* Remove button
                                                                ========================================= */}
                                                            <Tooltip title="Remove filter">
                                                                <IconButton
                                                                    onClick={removeFilterItems(
                                                                        _dataSource.id,
                                                                        filterIdx
                                                                    )}
                                                                    sx={(theme) => ({
                                                                        flexShrink: 0,

                                                                        color: theme.palette.common.ung_pink,
                                                                    })}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </StyledWidgetAccordion>
                                    )
                                })
                            )}
                        </Stack>
                    </StyledWidgetAccordion>
                </Stack>
            </Stack>

            {/* Actions
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flexShrink: 0,

                    padding: theme.spacing(0, 3, 1),
                })}
            >
                <BaseButton
                    label="Back"
                    onClick={(evt) => onDiscard()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label={defaultData ? 'Update' : 'Create'}
                    onClick={(evt) => onCreate(data)}
                    disabled={data.title == null || data.title.length < 2}
                    variant="contained"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default ShareReportDialog
