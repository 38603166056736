//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import InputBase from '@mui/material/InputBase'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
//* ======= Assets and styles
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextEditor from 'features/text-editor/TextEditor'

// TODO: This component has a lot in common with 'SurveyNotificationInvitation'.
//       They can most probably merge or have shared sub-components; something for another time.

interface ISurveyNotificationTwoLevelInvitation {}

function SurveyNotificationTwoLevelInvitation({}: ISurveyNotificationTwoLevelInvitation) {
    const [selectedTemplate, setSelectedTemplate] = useState<'email' | 'sms'>('email')

    return (
        <Stack gap={1} height="100%">
            {/* Template selection
				========================================= */}
            <Stack direction="row" alignItems="flex-start" gap={6}>
                {/* Invitation
					========================================= */}
                <BaseButton
                    label="Email Template"
                    onClick={(e) => setSelectedTemplate('email')}
                    size="small"
                    sx={{
                        px: 1,
                        fontSize: 14,
                        fontWeight: 400,

                        color: selectedTemplate === 'email' ? 'primary.main' : 'common.fill_1',
                    }}
                />

                {/* Two level invitation
					========================================= */}
                <BaseButton
                    label="SMS Template"
                    onClick={(e) => setSelectedTemplate('sms')}
                    size="small"
                    sx={{
                        px: 1,
                        fontSize: 14,
                        fontWeight: 400,

                        color: selectedTemplate === 'sms' ? 'primary.main' : 'common.fill_1',
                    }}
                />
            </Stack>

            {/* Templates content
				========================================= */}
            {selectedTemplate === 'email' ? (
                <InvitationEmailTemplate />
            ) : selectedTemplate === 'sms' ? (
                <InvitationSMSTemplate />
            ) : (
                false
            )}

            {/* Footer controls
				========================================= */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                {/* View button
					========================================= */}
                {/* <BaseButton
                    label="View"
                    size="large"
                    sx={{
                        minWidth: 120,
                    }}
                /> */}

                {/* Send test email button
					========================================= */}
                {/* <BaseButton
                    label="Send Test Email"
                    variant="outlined"
                    size="large"
                    sx={{
                        minWidth: 186,
                    }}
                /> */}

                {/* Save button
					========================================= */}
                <BaseButton
                    label="Save"
                    variant="contained"
                    size="large"
                    sx={{
                        minWidth: 186,
                    }}
                />
            </Stack>
        </Stack>
    )
}

interface IInvitationEmailTemplate {}

function InvitationEmailTemplate({}: IInvitationEmailTemplate) {
    const fieldOptions = ['Name', 'Email', 'Organization']

    return (
        <Stack gap={1} paddingLeft={1} height={525}>
            {/* Enable switch
				========================================= */}
            <FormGroup aria-label="enabled" row>
                <FormControlLabel
                    control={<BaseSwitch color="primary" />}
                    label="Enable"
                    labelPlacement="start"
                    sx={{
                        gap: 2,

                        marginLeft: 0,

                        '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                            fontWeight: 400,
                        },
                    }}
                />
            </FormGroup>

            <Autocomplete
                id="approver-field"
                options={fieldOptions}
                renderInput={(params) => (
                    <InputBase
                        inputProps={{ ...params.inputProps }}
                        ref={params.InputProps.ref}
                        name="searchField"
                        placeholder="Approver's Email Field"
                        endAdornment={
                            <ExpandMoreIcon
                                sx={{
                                    // marginLeft: 0.5,

                                    color: 'secondary.main',
                                }}
                            />
                        }
                        fullWidth
                        sx={(theme) => ({
                            py: 1.5,
                            px: 2,

                            borderRadius: '10px',
                            border: `1px solid ${theme.palette.common.border_3}`,
                            backgroundColor: 'common.bg_4',

                            '&.Mui-focused': {
                                borderColor: 'primary.main',
                                backgroundColor: 'common.bg_3',
                            },
                        })}
                    />
                )}
                disablePortal
            />

            {/* Subject
                ========================================= */}
            <BaseFilledTextField label="Subject" />

            {/* Message (?)
                ========================================= */}
            <TextEditor
                keywords={['[user.name]', '[survey.name]', '[project.name]', '[link]']}
                onBlur={() => {}}
            />
        </Stack>
    )
}

interface IInvitationSMSTemplate {}

function InvitationSMSTemplate({}: IInvitationSMSTemplate) {
    const fieldOptions = ['Name', 'Email', 'Organization']

    return (
        <Stack gap={1} paddingLeft={1} height={525}>
            {/* Enable switch
                ========================================= */}
            <FormGroup aria-label="enabled" row>
                <FormControlLabel
                    control={<BaseSwitch color="primary" />}
                    label="Enable"
                    labelPlacement="start"
                    sx={{
                        gap: 2,

                        marginLeft: 0,

                        '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                            fontWeight: 400,
                        },
                    }}
                />
            </FormGroup>

            {/* Approver's email
                ========================================= */}
            <Autocomplete
                id="approver-field"
                options={fieldOptions}
                renderInput={(params) => (
                    <InputBase
                        inputProps={{ ...params.inputProps }}
                        ref={params.InputProps.ref}
                        name="searchField"
                        placeholder="Approver's Mobile Field"
                        endAdornment={
                            <ExpandMoreIcon
                                sx={{
                                    // marginLeft: 0.5,

                                    color: 'secondary.main',
                                }}
                            />
                        }
                        fullWidth
                        sx={(theme) => ({
                            py: 1.5,
                            px: 2,

                            borderRadius: '10px',
                            border: `1px solid ${theme.palette.common.border_3}`,
                            backgroundColor: 'common.bg_4',

                            '&.Mui-focused': {
                                borderColor: 'primary.main',
                                backgroundColor: 'common.bg_3',
                            },
                        })}
                    />
                )}
                disablePortal
            />

            {/* Message (?)
                ========================================= */}
            <TextEditor
                keywords={['[user.name]', '[survey.name]', '[project.name]', '[link]']}
                onBlur={() => {}}
            />
        </Stack>
    )
}

export default SurveyNotificationTwoLevelInvitation
