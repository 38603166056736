import { defaultFontPickerValues } from 'components/widgets/FontPicker'
import merge from 'lodash/merge'
import { NodeGroupStyleType } from '../types/NetworkViz.types'

export const NodeBaseSettings: NodeGroupStyleType = {
    visible: true,
    normal: {
        size: 10,
        shape: 'circle',
        color: '#2D77BCff',
        border: {
            enabled: false,
            settings: {
                color: '#000000ff',
                width: 1,
                style: 'solid',
                radius: 1,
            },
        },
        shadow: {
            enabled: false,
            settings: {
                color: '#000000ff',
                x: 0,
                y: 0,
                blur: 5,
            },
        },
    },
    label: {
        show: false,
        font: defaultFontPickerValues,
    },
    selected: {
        color: '#d25a45ff',
        sizeScale: 1.5,
        border: {
            enabled: true,
            settings: {
                color: '#ffffffff',
                width: 3,
                style: 'solid',
                radius: 1,
            },
        },
        shadow: {
            enabled: true,
            settings: {
                color: '#000000ff',
                x: 0,
                y: 0,
                blur: 5,
            },
        },
    },
    colorScale: {
        enabled: false,
        attribute: {
            source: 'info',
            field: '',
        },
        colorFrom: '#2D77BCff',
        colorTo: '#18C342ff',
    },
    sizeScale: {
        enabled: false,
        attribute: {
            source: 'info',
            field: '',
        },
        sizeFrom: 1,
        sizeTo: 10,
    },
}

//ToDo theme based coloring

/*
const NodeLightSettings: NodeGroupStyleType = {
    visible: true,
    normal: {
        color: '#2D77BCff',
        border: {
            settings: {
                color: '#000000ff',
                width: 1,
                style: 'solid',
                radius: 1,
            },
        },
        shadow: {
            settings: {
                color: '#eeeeeeff',
                x: 0,
                y: 0,
                blur: 5,
            },
        },
    },
    label: {
        show: false,
        font: defaultFontPickerValues,
    },
    selected: {
        color: '#d25a45ff',
        border: {
            settings: {
                color: '#000000ff',
                width: 1,
                style: 'solid',
                radius: 1,
            },
        },
        shadow: {
            settings: {
                color: '#eeeeeeff',
                x: 0,
                y: 0,
                blur: 5,
            },
        },
    },
    colorScale: {
        colorFrom: '#2D77BCff',
        colorTo: '#18C342ff',
    },
    sizeScale: {},
}

const NodeDarkSettings: NodeGroupStyleType = {
    visible: true,
    normal: {
        color: '#2D77BCff',
        border: {
            settings: {
                color: '#000000ff',
                width: 1,
                style: 'solid',
                radius: 1,
            },
        },
        shadow: {
            settings: {
                color: '#eeeeeeff',
                x: 0,
                y: 0,
                blur: 5,
            },
        },
    },
    selected: {
        color: '#d25a45ff',
        border: {
            enabled: false,
            settings: {
                color: '#ffffffff',
                width: 3,
                style: 'solid',
                radius: 1,
            },
        },
        shadow: {
            enabled: false,
            settings: {
                color: '#000000ff',
                x: 0,
                y: 0,
                blur: 5,
            },
        },
    },

    colorScale: {
        colorFrom: '#2D77BCff',
        colorTo: '#18C342ff',
    },
    label: {
        show: false,
        font: defaultFontPickerValues,
    },
    sizeScale: {},
}

export const DefaultLightNodeSettings = merge(NodeBaseSettings, NodeLightSettings)

export const DefaultDarkNodeSettings = merge(NodeBaseSettings, NodeDarkSettings)

*/

const LightColorPallet = [
    '#065535ff',
    '#ff7373ff',
    '#40e0d0ff',
    '#bada55ff',
    '#ffc0cbff',
    '#468499ff',
    '#c39797ff',
    '#d3ffceff',
    '#660066ff',
    '#daa520ff',
    '#ffa500ff',
    '#0a75adff',
    '#a0db8eff',
    '#008080ff',
    '#7fffd4ff',
    '#f0f8ffff',
    '#ffd700ff',
    '#800000ff',
    '#003366ff',
    '#b0e0e6ff',
    '#133337ff',
    '#ff4040ff',
    '#B319E4ff',
    '#B319E4ff',
    '#96BBE1ff',
    '#57E89Eff',
    '#16969Eff',
    '#F75825ff',
    '#643F36ff',
    '#D0A919ff',
    '#1853D9ff',
    '#95BFC1ff',
]

const DarkColorPallet = [
    '#065535ff',
    '#ff7373ff',
    '#40e0d0ff',
    '#bada55ff',
    '#ffc0cbff',
    '#468499ff',
    '#c39797ff',
    '#d3ffceff',
    '#660066ff',
    '#daa520ff',
    '#ffa500ff',
    '#0a75adff',
    '#a0db8eff',
    '#008080ff',
    '#7fffd4ff',
    '#f0f8ffff',
    '#ffd700ff',
    '#800000ff',
    '#003366ff',
    '#b0e0e6ff',
    '#133337ff',
    '#ff4040ff',
    '#B319E4ff',
    '#B319E4ff',
    '#96BBE1ff',
    '#57E89Eff',
    '#16969Eff',
    '#F75825ff',
    '#643F36ff',
    '#D0A919ff',
    '#1853D9ff',
    '#95BFC1ff',
]

export function getColorPallet(theme: 'light' | 'dark' = 'light') {
    if (theme === 'light') return LightColorPallet
    return DarkColorPallet
}
