//* ======= Libraries
import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import { StylesManager, Model, SurveyModel, QuestionHtmlModel, Serializer, ComponentCollection } from 'survey-core'
import { Survey } from 'survey-react-ui'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
//* ======= Custom logic
//* ======= Assets and styles
import SNALogo from 'assets/images/sna-toolbox-logo.png'
import 'survey-core/defaultV2.css'
import { registerNetworkQuestion } from 'features/survey-designer/custom-questions/NetworkQuestion'
import { registerAddressQuestion } from 'features/survey-designer/custom-questions/AddressQestion'
import { SurveyInstanceContext } from 'contexts/SurveyInstanceContext'
import SurveyLoading from './SurveyLoading'
import {
    SaveSurveyInstanceRequestType,
    SaveSurveyInstanceService,
    SubmitSurveyInstanceRequestType,
    SubmitSurveyInstanceService,
} from 'services/SurveyInstanceService'
import { useNavigate, useParams } from 'react-router-dom'
import Color from 'color'
import SurveySaved from './SurveySaved'
import { HorizontalAlign, SurveyPDF } from 'survey-pdf'
import PrivacyPolicyDialog from 'features/sign-in/PrivacyPolicyDialog'
import TermsOfServiceDialog from 'features/sign-in/TermsOfServiceDialog'
import SurveyCompleted from './SurveyCompleted'
import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha, useTheme } from '@mui/material/styles'
import { registerMarkdown } from 'features/survey-designer/helpers/FormattingHelpr'
import { LinearProgress } from '@mui/material'
import WaitIcon from '@mui/icons-material/HourglassTop'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorIcon from '@mui/icons-material/Error'
import { BaseResponseType } from 'services/request'
StylesManager.applyTheme('defaultV2')

function getBase64Image(url: string) {
    var img = document.createElement('img')
    img.src = url
    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    var ctx = canvas.getContext('2d')
    if (ctx) ctx.drawImage(img, 0, 0)
    var dataURL = canvas.toDataURL('image/png')
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
}

function a11yProps(index: number) {
    return {
        id: `survey-report-tab-${index}`,
        'aria-controls': `survey-report-tabpanel-${index}`,
    }
}

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`survey-report-tabpanel-${index}`}
            aria-labelledby={`survey-report-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
        </div>
    )
}

interface ISurveyPreviewContent {
    onSave?: (saveRequest: SaveSurveyInstanceRequestType) => Promise<BaseResponseType>
    onSubmit?: (submitRequest: SubmitSurveyInstanceRequestType) => Promise<BaseResponseType>
    redoable?: boolean
}

const saveSettings = {
    autosave: true,
    autosaveInterval: 60,
    onPageChanged: true,
    onAnswerChanged: true,
}

function SurveyPreviewContent({ onSave, onSubmit, redoable = false }: ISurveyPreviewContent) {
    const { token } = useParams()
    const navigator = useNavigate()
    const [viewOnly, setViewOnly] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [timeSpent, setTimeSpent] = useState<number>(0)
    const [saveAndExit, setSaveAndExit] = useState(false)
    const [showReportRefresh, setShowReportRefresh] = useState(false)
    const { surveyInstance, fetchSurveyInstance } = useContext(SurveyInstanceContext)
    const [reportCounter, setReportCounter] = useState(5)
    const [tabValue, setTabValue] = React.useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false)
    const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)

    const [submitStatus, setSubmitStatus] = useState<'in_progress' | 'error' | 'success' | null>(null)
    const [dataToSubmit, setDataToSubmit] = useState<any>(null)

    const theme = useTheme()
    const isViewportSmall = useMediaQuery(theme.breakpoints.down('md'))

    //on page load, fetch the result
    useEffect(() => {
        fetchSurveyInstance()
    }, [])

    const submitSurveyInstanceToSever = useCallback(
        async (data: SubmitSurveyInstanceRequestType) => {
            if (onSubmit) {
                try {
                    setSubmitStatus('in_progress')

                    const response = await onSubmit(data)
                    if (response.success) {
                        setSubmitStatus('success')
                        return true
                    }
                } catch (e) {}
                setSubmitStatus('error')
                return false
            }
            setSubmitStatus('error')
        },
        [onSubmit]
    )

    //when survey instance is ready, init the survey
    let survey: SurveyModel | undefined = useMemo(() => {
        if (!surveyInstance) return

        //register custom question
        registerNetworkQuestion()
        registerAddressQuestion()

        if (!['in_progress', 'invited', 'completed'].includes(surveyInstance.status)) return
        //create survey instance
        let survey = new Model(surveyInstance.surveyJson)

        //config survey
        survey.showTitle = false
        survey.showPrevButton = false
        survey.showNavigationButtons = false
        survey.surveyShowDataSaving = true
        survey.startTimer()

        //Enable Markdown to HTML with Showdown
        registerMarkdown(survey)

        //set the data
        if (surveyInstance.response && Object.keys(surveyInstance.response).length !== 0)
            survey.data = surveyInstance.response || {}

        setPageCount(survey.visiblePageCount)

        survey.onPageVisibleChanged.add((e: SurveyModel) => {
            setPageCount(e.visiblePageCount)
        })

        //set event listeners
        survey.onComplete.add(async function (localSurvey, event) {
            setDataToSubmit({
                response: localSurvey.data,
                timeSpent: localSurvey.timeSpent - timeSpent,
            })
            await submitSurveyInstanceToSever({
                response: localSurvey.data,
                timeSpent: localSurvey.timeSpent - timeSpent,
            })
        })

        if (surveyInstance.status === 'completed') {
            survey.mode = 'display'
            survey.currentPage = 0
            setViewOnly(true)
            setCurrentPage(0)
        } else {
            survey.currentPage = surveyInstance.currentPage
            setCurrentPage(surveyInstance.currentPage)
        }
        return survey
    }, [surveyInstance])

    const downloadPDF = () => {
        if (!surveyInstance) return
        if (surveyInstance.status == 'completed') {
            const surveyPDF = new SurveyPDF(surveyInstance.surveyJson, {
                htmlRenderAs: 'image',
                haveCommercialLicense: true,
                fontSize: 14,
                margins: {
                    top: 18,
                    bot: 18,
                },
                format: [210, 297],
            })
            let htmlQuestions = surveyPDF
                .getAllQuestions(true)
                .filter((p) => p.getType() == 'html') as QuestionHtmlModel[]
            const regex = /src=('|").*('|")/gm
            for (let q of htmlQuestions) {
                let m
                let tmpHtml = q.html
                while ((m = regex.exec(q.html)) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++
                    }

                    // The result can be accessed through the `m`-variable.
                    m.forEach((match, groupIndex) => {
                        if (match.includes('http')) {
                            const url = match.substring(5, match.length - 1)
                            console.log(url)
                        }
                    })
                }
            }
            surveyPDF.mode = 'display'
            surveyPDF.data = surveyInstance.response

            surveyPDF.onRenderFooter.add(function (survey, canvas) {
                canvas.drawImage({
                    base64: SNALogo,
                    horizontalAlign: HorizontalAlign.Left,
                    width: 140 * 0.6,
                    height: 30 * 0.6,
                    margins: {
                        left: (canvas.rect.yBot - canvas.rect.yTop) * 0.2,
                    },
                })
                canvas.drawText({
                    text: '  Generated via SNA Toolbox ',
                    fontSize: 10,
                    horizontalAlign: HorizontalAlign.Center,
                })
                canvas.drawText({
                    text: '\nhttps://www.snatoolbox.com',
                    fontSize: 10,
                    horizontalAlign: HorizontalAlign.Center,
                })
                canvas.drawText({
                    text: canvas.pageNumber + '/' + canvas.countPages,
                    fontSize: 10,
                    horizontalAlign: HorizontalAlign.Right,
                    margins: {
                        right: 12,
                    },
                })
            })

            surveyPDF.save(`${surveyInstance.surveyName}-${surveyInstance.surveyInstanceId}.pdf`)
        }
    }

    //go to next page
    const goNextPage = () => {
        let isNext = survey?.nextPage()
        if (isNext) {
            if (saveSettings.onPageChanged) {
                saveResult(currentPage + 1)
            }
            setCurrentPage((v) => v + 1)
        }
    }

    //go To prev page
    const gotBack = () => {
        let isPrev = survey?.prevPage()
        if (isPrev) {
            if (saveSettings.onPageChanged) {
                saveResult(currentPage - 1)
            }
            setCurrentPage((v) => v - 1)
        }
    }

    //Submit survey
    const submitSurvey = () => {
        let isCompleted = survey?.completeLastPage()
        if (isCompleted) {
            setCurrentPage(-1)
            //if survey has individual report, refresh the page after 5 secs
            if (surveyInstance?.hasReport) {
                setShowReportRefresh(true)
                var counter = 4
                var reportLoadCountdown = setInterval(function () {
                    setReportCounter(counter--)
                    if (counter === -1) {
                        clearInterval(reportLoadCountdown)
                        navigator(0)
                    }
                }, 1000)
            }
        }
    }

    //Save survey result
    const saveResult = (currentPage?: number, exit: boolean = false) => {
        if (viewOnly) return
        if (token != null && survey) {
            let ts = survey.timeSpent - timeSpent
            setTimeSpent(survey.timeSpent)

            onSave &&
                onSave({ currentPage: currentPage || 0, response: survey.data, timeSpent: ts }).then((res) => {
                    if (res.success && exit) {
                        setSaveAndExit(true)
                    }
                })
        }
    }

    return (
        <>
            {saveAndExit ? (
                <SurveySaved />
            ) : surveyInstance == null ? (
                <SurveyLoading />
            ) : surveyInstance.status === 'completed' && surveyInstance.hasReport === false ? (
                <SurveyCompleted />
            ) : (
                <Stack
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Header
				        ========================================= */}
                    <Stack
                        sx={{
                            flexShrink: 0,
                            alignItems: 'center',

                            backgroundColor: 'common.bg_1',
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={2}
                            sx={{
                                width: '100%',
                                maxWidth: 1400,
                                py: 2,
                                px: 3,
                            }}
                        >
                            {/* Survey pic
						        ========================================= */}
                            {surveyInstance?.avatar && surveyInstance.avatar.length > 0 && (
                                <img
                                    src={surveyInstance?.avatar}
                                    alt="survey logo"
                                    style={{
                                        height: 60,
                                        width: 120,
                                        marginRight: '16px',
                                        objectFit: 'contain',
                                        objectPosition: 'center',
                                    }}
                                />
                            )}

                            {/* Survey name
						        ========================================= */}
                            <Typography
                                sx={
                                    isViewportSmall
                                        ? {
                                              fontSize: '1.2rem',
                                              fontWeight: 400,
                                          }
                                        : {
                                              fontSize: '1.6rem',
                                              fontWeight: 600,
                                          }
                                }
                                color={surveyInstance?.color}
                            >
                                {surveyInstance?.surveyName}
                            </Typography>

                            {/* Page indicator
						        ========================================= */}
                            {(tabValue === 1 || surveyInstance.status !== 'completed' || !surveyInstance.hasReport) &&
                                survey &&
                                pageCount > 0 && (
                                    <Typography
                                        sx={
                                            isViewportSmall
                                                ? {
                                                      fontSize: '1rem',
                                                      fontWeight: 400,
                                                  }
                                                : {
                                                      fontSize: '1.2rem',
                                                      fontWeight: 600,
                                                  }
                                        }
                                        color={surveyInstance?.color}
                                        marginLeft="auto"
                                    >
                                        {`${!isViewportSmall ? 'Page ' : ''}${
                                            survey.currentPageNo + 1
                                        } of ${pageCount}`}
                                    </Typography>
                                )}
                        </Stack>
                    </Stack>

                    {submitStatus !== null && (
                        <Box>
                            {submitStatus === 'in_progress' ? (
                                <>
                                    <Stack
                                        sx={{
                                            backgroundColor: alpha(surveyInstance!.color, 0.9),
                                            color: Color(surveyInstance!.color).isDark() ? '#ffffff' : '#000000',
                                        }}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        py={1}
                                        gap={2}
                                    >
                                        <WaitIcon sx={{ fontSize: 38 }} />
                                        <Typography variant="h6">
                                            Saving your responses. Please wait and avoid closing this window.
                                        </Typography>
                                    </Stack>
                                    <LinearProgress />
                                </>
                            ) : submitStatus === 'error' ? (
                                <Stack
                                    sx={(theme) => ({
                                        backgroundColor: theme.palette.error.main,
                                        color: '#ffffff',
                                    })}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    py={2}
                                    gap={2}
                                >
                                    <ErrorIcon sx={{ fontSize: 38 }} />
                                    <Typography variant="h6">
                                        Oops! We couldn't save your responses. Please retry or check back in a bit.
                                    </Typography>
                                    <BaseButton
                                        variant="contained"
                                        onClick={() => {
                                            submitSurveyInstanceToSever(dataToSubmit)
                                        }}
                                    >
                                        Try Again
                                    </BaseButton>
                                </Stack>
                            ) : (
                                <Stack
                                    sx={(theme) => ({
                                        backgroundColor: theme.palette.success.main,
                                        color: '#ffffff',
                                    })}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    py={2}
                                    gap={2}
                                >
                                    <DoneAllIcon sx={{ fontSize: 38 }} />
                                    <Typography variant="h6">
                                        Success! Your responses are saved. It's safe to close this window now.
                                    </Typography>
                                    {redoable && (
                                        <BaseButton
                                            variant="contained"
                                            onClick={() => {
                                                setTimeSpent(0)
                                                setCurrentPage(0)
                                                setSubmitStatus(null)
                                                if (survey) {
                                                    survey.clear()
                                                }
                                            }}
                                        >
                                            Submit another response
                                        </BaseButton>
                                    )}
                                </Stack>
                            )}
                        </Box>
                    )}

                    {/* Body
				        ========================================= */}
                    <Stack
                        sx={{
                            flexGrow: 1,
                            alignSelf: 'center',

                            gap: 3,

                            minHeight: 0,
                            width: '100%',
                            maxWidth: 1400,
                            paddingTop: 1,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                        className="u-scrollbar-pink"
                    >
                        {/* Show Survey
					        ========================================= */}
                        {survey != null && (!viewOnly || !surveyInstance.hasReport) && (
                            <React.Fragment>
                                {/* Questions wrapper
					        ========================================= */}
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    {showReportRefresh && (
                                        <Card
                                            sx={{
                                                maxWidth: '550px',
                                                margin: 'auto',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <CardContent>
                                                <Typography textAlign="center">
                                                    You will be navigated to your report area in {reportCounter}{' '}
                                                    seconds.
                                                </Typography>
                                                <Typography textAlign="center">
                                                    Click{' '}
                                                    <BaseButton
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => navigator(0)}
                                                    >
                                                        here
                                                    </BaseButton>{' '}
                                                    if your report page does not open automaticly.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    )}
                                    <Survey model={survey} />
                                </Box>
                            </React.Fragment>
                        )}

                        {/* Show Report
					        ========================================= */}
                        {survey != null && viewOnly && surveyInstance.hasReport && (
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                {/* <AppBar position="static" sx={{
                                                backgroundColor: surveyInstance?.color,
                                                color: Color(surveyInstance?.color).lighten(1).toString(),

                                            }}>
                                                <Tabs
                                                    value={tabValue}
                                                    onChange={(e, v) => setTabValue(v)}
                                                    indicatorColor="secondary"
                                                    textColor="inherit"
                                                    variant="fullWidth"
                                                    aria-label="full width tabs example"
                                                >
                                                    <Tab label="Report" {...a11yProps(0)}
                                                        sx={{
                                                            ":hover": {
                                                                backgroundColor: Color(surveyInstance?.color).lighten(0.1).toString()
                                                            },
                                                        }}
                                                    />
                                                    <Tab label="Responses" {...a11yProps(1)}
                                                        sx={{
                                                            ":hover": {
                                                                backgroundColor: Color(surveyInstance?.color).lighten(0.1).toString()
                                                            },
                                                        }}
                                                    />
                                                </Tabs>
                                            </AppBar> */}
                                {/* <SwipeableViews
                                                    axis={'x'}
                                                    index={tabValue}
                                                    onChangeIndex={(v) => setTabValue(v)}
                                                >
                                                    <TabPanel value={tabValue} index={0}>
                                                        <SurveyReportPreview />
                                                    </TabPanel>
                                                    <TabPanel value={tabValue} index={1}>
                                                        <Survey model={survey} />
                                                    </TabPanel>
                                                </SwipeableViews> */}
                            </Box>
                        )}

                        {/* Page controls
					        ========================================= */}
                        {survey != null && currentPage !== -1 && (!viewOnly || tabValue == 1) && (
                            <Stack direction="row" alignItems="center" gap={2} paddingX={isViewportSmall ? 2 : 0}>
                                {/* Previous button
						            ========================================= */}
                                {currentPage !== 0 && (
                                    <BaseButton
                                        label="Previous"
                                        onClick={gotBack}
                                        variant="contained"
                                        size={isViewportSmall ? 'small' : 'large'}
                                        color="error"
                                        disableElevation
                                        sx={{
                                            backgroundColor: surveyInstance?.color,
                                            color: Color(surveyInstance?.color).isDark() ? '#ffffff' : '#000000',
                                            ':hover': {
                                                backgroundColor: Color(surveyInstance?.color).lighten(0.1).toString(),
                                            },
                                            width: 160,
                                            py: 1.75,
                                        }}
                                    />
                                )}

                                {/* Save and exit button
                                    ========================================= */}
                                {!isViewportSmall &&
                                    (!viewOnly ? (
                                        <BaseButton
                                            label="Save and Exit"
                                            variant="outlined"
                                            size="large"
                                            color="error"
                                            onClick={() => saveResult(survey?.currentPageNo, true)}
                                            sx={{
                                                color: surveyInstance?.color,
                                                width: 160,
                                                marginLeft: 'auto',
                                                py: 1.75,
                                            }}
                                        />
                                    ) : (
                                        <BaseButton
                                            label="Download PDF"
                                            variant="outlined"
                                            size="large"
                                            color="error"
                                            onClick={downloadPDF}
                                            sx={{
                                                color: surveyInstance?.color,
                                                width: 160,
                                                marginLeft: 'auto',
                                                py: 1.75,
                                            }}
                                        />
                                    ))}

                                {/* Next button
                                    ========================================= */}
                                {currentPage + 1 < pageCount ? (
                                    <BaseButton
                                        label="Next"
                                        variant="contained"
                                        size={isViewportSmall ? 'small' : 'large'}
                                        onClick={goNextPage}
                                        disableElevation
                                        sx={{
                                            backgroundColor: surveyInstance?.color,
                                            marginLeft: isViewportSmall ? 'auto' : '0px',
                                            color: Color(surveyInstance?.color).isDark() ? '#ffffff' : '#000000',
                                            ':hover': {
                                                backgroundColor: Color(surveyInstance?.color).lighten(0.1).toString(),
                                            },
                                            width: 160,
                                            py: 1.75,
                                        }}
                                    />
                                ) : (
                                    !viewOnly && (
                                        <BaseButton
                                            label="Submit"
                                            variant="contained"
                                            size={isViewportSmall ? 'small' : 'large'}
                                            onClick={submitSurvey}
                                            disableElevation
                                            sx={{
                                                backgroundColor: surveyInstance?.color,
                                                color: Color(surveyInstance?.color).isDark() ? '#ffffff' : '#000000',
                                                marginLeft: isViewportSmall ? 'auto' : '0px',
                                                width: 160,
                                                py: 1.75,
                                            }}
                                        />
                                    )
                                )}
                            </Stack>
                        )}

                        {/* Show Error
					        ========================================= */}
                        {survey == null &&
                            (surveyInstance.status != 'completed' || surveyInstance.hasReport != true) && (
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    Error
                                </Box>
                            )}

                        {/* Footer
                            ========================================= */}
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            sx={{
                                flexShrink: 0,

                                py: 2,
                                px: 3,

                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                backgroundColor: 'common.bg_1',
                            }}
                        >
                            <img
                                src={SNALogo}
                                alt="SNA logo"
                                style={{
                                    height: 30,
                                    width: 140,
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                }}
                            />

                            <Typography fontSize={10} fontWeight={400} color="common.text_3" marginLeft="auto">
                                Powered by SNA Toolbox
                            </Typography>

                            <Stack direction="row" justifyContent="center" gap={0} alignItems="center">
                                {/* Privacy policy button
						    ========================================= */}
                                <BaseButton
                                    label="Privacy Policy"
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 400,
                                    }}
                                    onClick={(evt) => setIsPrivacyPolicyDialogOpen(true)}
                                    color="primary"
                                />

                                {/* Terms of use button
						    ========================================= */}
                                <BaseButton
                                    label="Terms of Use"
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 400,
                                    }}
                                    onClick={(evt) => setIsTermsOfServiceDialogOpen(true)}
                                    color="primary"
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )}
            {/* Dialogs
				========================================= */}
            {(isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen) && (
                <StyledDialog
                    open={isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen}
                    onClose={() =>
                        isPrivacyPolicyDialogOpen
                            ? setIsPrivacyPolicyDialogOpen(false)
                            : setIsTermsOfServiceDialogOpen(false)
                    }
                    fullWidth
                    maxWidth="lg"
                >
                    {isPrivacyPolicyDialogOpen ? (
                        <PrivacyPolicyDialog onClose={() => setIsPrivacyPolicyDialogOpen(false)} />
                    ) : isTermsOfServiceDialogOpen ? (
                        <TermsOfServiceDialog onClose={() => setIsTermsOfServiceDialogOpen(false)} />
                    ) : (
                        false
                    )}
                </StyledDialog>
            )}
        </>
    )
}

export default SurveyPreviewContent
