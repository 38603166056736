import React, { useEffect, useState } from 'react'
import NetworkFilterSettingsItem from './NetworkFilterSettingsItem'
import BaseButton from 'components/base/BaseButton'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { v4 as uuidv4 } from 'uuid'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { FilterItemType } from 'features/network-viz/types/NetworkViz.types'
import { applyFilter } from './NetworkFilter.helpers'

export default function NetworkFilterSettings() {
    const context = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [filterItems, setFilterItems] = useState<FilterItemType[]>(context.filters)

    const addFilterItems = () => {
        setFilterItems([...filterItems, { id: uuidv4(), attribute: null, filterMode: 'hide' }])
    }

    const removeFilterItems = (id: string) => {
        setFilterItems((oldFilterItems) => oldFilterItems.filter((fi) => fi.id !== id))
    }

    const changeFilterItem = (index: number, newFilterItem: FilterItemType) => {
        setFilterItems((oldFI) => {
            oldFI[index] = newFilterItem
            return oldFI
        })
    }

    const applyFilters = async () => {
        const filterOutcome = await applyFilter(context, filterItems)
        if (filterOutcome !== null) {
            dispatchContext({
                type: 'FILTER_CHANGE',
                payload: {
                    edgeRenders: filterOutcome.edgeRenders,
                    nodeRenders: filterOutcome.nodeRenders,
                    filters: filterItems,
                    nodeAdjacentList: filterOutcome.nodeAdjacentList,
                    analytics: filterOutcome.analytics,
                },
            })
        }
    }

    const resetFilters = () => {
        setFilterItems([])
        dispatchContext({
            type: 'FILTER_CHANGE',
            payload: {
                edgeRenders: context.edgeRenders.map((e) => ({
                    ...e,
                    hide: false,
                    dim: false,
                })),
                nodeRenders: context.nodeRenders.map((n) => ({
                    ...n,
                    hide: false,
                    dim: false,
                    filterHighlight: false,
                })),
                filters: filterItems,
            },
        })
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={500}>
                    Filters
                </Typography>

                <BaseButton label="Add Filter" onClick={addFilterItems} startIcon={<AddIcon />} />
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={1} minWidth={0}>
                {filterItems.map((fi, index) => (
                    <React.Fragment key={fi.id}>
                        <NetworkFilterSettingsItem
                            key={index}
                            index={index}
                            filterItem={fi}
                            onDelete={() => removeFilterItems(fi.id)}
                            changeFilterItem={changeFilterItem}
                        />

                        <Divider />
                    </React.Fragment>
                ))}

                {/* Apply button
                    ========================================= */}
                {filterItems.length > 0 && (
                    <BaseButton
                        label="Apply"
                        onClick={applyFilters}
                        variant="contained"
                        disableElevation
                        sx={{
                            marginTop: 1,
                        }}
                    />
                )}

                {/* Reset button
                    ========================================= */}
                <BaseButton label="Reset" onClick={resetFilters} variant="outlined" />
            </Stack>
        </Stack>
    )
}
