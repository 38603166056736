import React from 'react'
import { Stack, Typography } from '@mui/material'
import { FallbackProps } from 'react-error-boundary'
import BaseButton from 'components/base/BaseButton'

type ErrorFallbackProps = FallbackProps & {
    message?: string
}

function ErrorFallback({ error, resetErrorBoundary, message = 'Something went wrong!' }: ErrorFallbackProps) {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            gap={4}
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <Typography fontSize={18}>{message}</Typography>

            <BaseButton
                label="Retry"
                onClick={resetErrorBoundary}
                variant="contained"
                sx={{
                    width: 120,
                    py: 1,
                    px: 2,
                }}
            />
        </Stack>
    )
}

export default ErrorFallback
