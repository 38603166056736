import React from 'react'

type Props = {
    inputId: string
    onComplete: (imageUrl: string, imageName: string) => void
}

function ImageUpload({ inputId, onComplete }: Props) {
    const onFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.files && evt.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                onComplete(reader?.result?.toString() || '', evt.target.files![0].name ?? 'uploaded-image')
            })
            reader.readAsDataURL(evt.target.files[0])
        }
    }

    return (
        /*  Hidden file input
            ========================================= */
        <input
            id={inputId}
            type="file"
            accept="image/*"
            onChange={onFileSelect}
            style={{
                display: 'none',
            }}
        />
    )
}

export default ImageUpload
