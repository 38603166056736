import { Divider, FormControl, FormControlLabel, Slider, Stack, Typography } from '@mui/material'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import { ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { tooltipPlacementOptions } from '../info-widget/InfoWidgetSettings'
import { PartialDeep } from 'type-fest'
import ColorPicker from 'components/widgets/ColorPicker'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'

type ReportWidgeTooltipSettingsProps = {
    tooltip: ReportWidgetType['tooltip']
    onChange: (tooltip: PartialDeep<ReportWidgetType['tooltip']>) => void
}

const ReportWidgetTooltipSettings = ({ tooltip, onChange }: ReportWidgeTooltipSettingsProps) => {
    return (
        <StyledWidgetAccordion
            title="Tooltip"
            hasBottomBorder
            hasToggle={true}
            isToggledOff={!tooltip.isEnabled}
            onToggle={(isEnabled) => {
                onChange({
                    isEnabled: isEnabled,
                })
            }}
        >
            <Stack gap={1}>
                <BaseFilledTextField
                    label="Content"
                    multiline
                    rows={3}
                    defaultValue={tooltip.text}
                    onBlur={(evt) =>
                        onChange({
                            text: evt.target.value,
                        })
                    }
                    size="small"
                    fullWidth
                    disabled={!tooltip.isEnabled}
                />

                <FormControlLabel
                    label="Show Filter Badge"
                    control={
                        <StyledSwitch
                            checked={tooltip.filterInfo}
                            onChange={(evt) => {
                                onChange({
                                    filterInfo: evt.target.checked,
                                })
                            }}
                        />
                    }
                    disabled={!tooltip.isEnabled}
                />

                <Divider />

                {/* Icon Type */}
                <BaseSelectWithLabel
                    size="small"
                    label="Icon Type"
                    options={[
                        {
                            label: 'Exclamation Mark',
                            value: 'exclamation',
                        },
                        {
                            label: 'Question Mark',
                            value: 'question',
                        },
                        {
                            label: 'Question Mark Outlined',
                            value: 'questionOutline',
                        },
                    ]}
                    value={tooltip.icon.type}
                    onChange={(value) => {
                        onChange({
                            icon: { type: value },
                        })
                    }}
                    disabled={!tooltip.isEnabled}
                />
                {/* Icon Size */}
                <BaseSelectWithLabel
                    size="small"
                    label="Icon Size"
                    options={[
                        {
                            label: 'Small',
                            value: 'small',
                        },
                        {
                            label: 'Medium',
                            value: 'medium',
                        },
                        {
                            label: 'Large',
                            value: 'large',
                        },
                    ]}
                    value={tooltip.icon.size}
                    onChange={(value) => {
                        onChange({
                            icon: { size: value },
                        })
                    }}
                    disabled={!tooltip.isEnabled}
                />

                {/* Icon Color */}
                <ColorPicker
                    value={tooltip.icon.color}
                    onChange={(newColor) => {
                        onChange({
                            icon: { color: newColor },
                        })
                    }}
                    disabled={!tooltip.isEnabled}
                />

                <Divider />
                {/* Container style */}

                <BaseSelectWithLabel
                    size="small"
                    label="Container Mode"
                    options={[
                        { label: 'Floating', value: 'floating' },
                        { label: 'inline', value: 'inline' },
                    ]}
                    value={tooltip.container.mode}
                    onChange={(value) => {
                        onChange({
                            container: {
                                mode: value,
                                ...(value === 'floating'
                                    ? { placement: 'top-end', paddingX: 1, paddingY: 1 }
                                    : { placement: 'top', alignment: 'right' }),
                            },
                        })
                    }}
                    disabled={!tooltip.isEnabled}
                />

                {tooltip.container.mode === 'floating' ? (
                    <>
                        <BaseSelectWithLabel
                            size="small"
                            label="Placement"
                            key="floating-placement"
                            options={tooltipPlacementOptions}
                            value={tooltip.container.placement}
                            onChange={(value) => {
                                onChange({
                                    container: { placement: value },
                                })
                            }}
                            disabled={!tooltip.isEnabled}
                        />

                        <StyledWidgetAccordion title="Padding" isDisabled={!tooltip.isEnabled}>
                            <Stack gap={1}>
                                {/* Padding Horizontal */}
                                <Stack gap={1}>
                                    <Typography>Horizontal</Typography>
                                    <ValueSliderPicker
                                        value={tooltip.container.paddingX}
                                        onChange={(value) => {
                                            onChange({
                                                container: { paddingX: value },
                                            })
                                        }}
                                        min={0}
                                        max={10}
                                        step={0.1}
                                        disabled={!tooltip.isEnabled}
                                    />
                                </Stack>

                                {/* Padding Vertical */}
                                <Stack gap={1}>
                                    <Typography>Vertical</Typography>
                                    <ValueSliderPicker
                                        value={tooltip.container.paddingY}
                                        onChange={(value) => {
                                            onChange({
                                                container: { paddingY: value },
                                            })
                                        }}
                                        min={0}
                                        max={10}
                                        step={0.1}
                                        disabled={!tooltip.isEnabled}
                                    />
                                </Stack>
                            </Stack>
                        </StyledWidgetAccordion>
                    </>
                ) : (
                    <>
                        <BaseSelectWithLabel
                            size="small"
                            label="Placement"
                            key="inline-placement"
                            options={[
                                {
                                    label: 'Top',
                                    value: 'top',
                                },
                                {
                                    label: 'Bottom',
                                    value: 'bottom',
                                },
                            ]}
                            value={tooltip.container.placement}
                            onChange={(value) => {
                                onChange({
                                    container: { placement: value },
                                })
                            }}
                        />

                        <BaseSelectWithLabel
                            size="small"
                            label="Alignment"
                            options={[
                                {
                                    label: 'Left',
                                    value: 'left',
                                },
                                {
                                    label: 'Center',
                                    value: 'center',
                                },
                                {
                                    label: 'Right',
                                    value: 'right',
                                },
                                {
                                    label: 'Justify',
                                    value: 'space-between',
                                },
                            ]}
                            value={tooltip.container.alignment}
                            onChange={(value) => {
                                onChange({
                                    container: { alignment: value },
                                })
                            }}
                        />
                    </>
                )}
            </Stack>
        </StyledWidgetAccordion>
    )
}

export default ReportWidgetTooltipSettings
