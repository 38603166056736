//* ======= Libraries
import React, { useState, useEffect } from 'react'
import { InlineEditorType } from 'ckeditor5-custom-build'
import { PartialDeep } from 'type-fest'
import { Box } from '@mui/material'
//* ======= Components and features
import EditorInline from 'components/Editor/EditorInline'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { ReportWidgetType, TextWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import './TextWidget.css'

type Props = {
    Id: ReportWidgetType['id']
    data: TextWidgetType
    isActive: boolean
    fontSizeScaleFactor: number
    onReady: () => void
}

function TextWidget({ Id, data, onReady, isActive, fontSizeScaleFactor }: Props) {
    const { viewMode, activeSlideId, updateTextWidgetContent } = useReportStore((store) => ({
        viewMode: store.viewMode,
        activeSlideId: store.activeSlideId,
        updateTextWidgetContent: store.updateTextWidgetContent,
    }))

    const [editorInstance, setEditorInstance] = useState<InlineEditorType | null>(null)

    // Update the editor value if the store value changes from somewhere else.
    useEffect(() => {
        if (editorInstance !== null) {
            const editorData = editorInstance.getData()

            if (data.value !== editorData) {
                editorInstance.setData(data.value)
            }
        }
    }, [data.value])

    const updateWidget = (data: PartialDeep<TextWidgetType>) => {
        if (activeSlideId === null) return

        updateTextWidgetContent({
            slideId: activeSlideId,
            widgetId: Id,
            data: data,
        })
    }

    const onEditorReady = (editor: InlineEditorType) => {
        setEditorInstance(editor)
    }

    const onEditorBlur = (editor: InlineEditorType) => {
        const editorData = editor.getData()

        updateWidget({
            value: editorData,
        })
    }

    useEffect(() => {
        onReady()
    }, [])

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
            // TODO: Temporary class to allow adding custom styles to this editor.
            //       The issue is described in the "TextWidget.css" file.
            className={data.scrollable ? 'TextWidgetEditorScroll' : 'TextWidgetEditorNoOverflow'}
        >
            <EditorInline
                data={data.value}
                onReady={(editor) => onEditorReady(editor)}
                onBlur={(event, editor) => onEditorBlur(editor)}
                isReadOnly={viewMode === 'preview'}
                fontSize={16 * fontSizeScaleFactor}
            />
        </Box>
    )
}

export default TextWidget
