const getNodeValue = (value: any, round: number) => {
    if (Array.isArray(value)) {
        let v = value[0]
        if (value.length > round) v = value[round]
        if (typeof v === 'object') {
            return v.value
        }
        return v
    }
    return value
}

export default getNodeValue
