import { useState, useMemo, useEffect } from 'react'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseButton from 'components/base/BaseButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { LAYOUT_ALGORITHEMS_2D, LAYOUT_ALGORITHEMS_3D } from 'features/network-viz/constants/NetworkViz.const'
import {
    NetworkVizInitialState,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import { LayoutKindType, LayoutSettingsType } from 'features/network-viz/types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import NetworkForceSettings from './components/NetworkForceSettings'
import NetworkForceAtlasSettings from './components/NetworkForceAtlasSettings'
import NetworkNoOverlapSettings from './components/NetworkNoOverlapSettings'
import NetworkSFDPSettings from './components/NetworkSFDPSettings'
import { IconButton, Tooltip } from '@mui/material'
import ResetIcon from '@mui/icons-material/Refresh'
import { useParams } from 'react-router'
export default function NetworkLayoutSettings() {
    const {
        networkVizKind,
        layoutKind,
        layoutSettings,
        nodes,
        edges,
        nodeRenders,
        edgeRenders,
        nodeDataSchema,
        edgeDataSchema,
        analytics,
    } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [localLayout, setLocalLayout] = useState<LayoutKindType>(layoutKind)
    const [settings, setSettings] = useState<LayoutSettingsType[keyof LayoutSettingsType]>(
        layoutSettings[layoutKind]
    )

    const saveSettings = (data: any) => {
        const worker = new WebWorker('workers/network/apply-alogorithm.js')
        dispatchContext({ type: 'STATUS_UPDATE', payload: 'loading' })
        worker
            .run({
                nodeRenders,
                edgeRenders,
                nodes,
                edges,
                analytics,
                layout: localLayout,
                layoutSetting: data,
                mode: networkVizKind === '3d' ? '3d' : '2d',
                analyticsUrl: process.env.REACT_APP_API_URL,
            })
            .then((res: any) => {
                dispatchContext({
                    type: 'LAYOUT_ALGORITHM_SETTINGS_EDIT',
                    payload: {
                        layoutKind: localLayout,
                        layoutSetting: { [localLayout]: data },
                        nodeRenders: res,
                    },
                })
            })
            .catch((e) => {
                //ToDo
            })
            .finally(() => {
                dispatchContext({ type: 'STATUS_UPDATE', payload: 'ready' })
            })
    }

    useEffect(() => {
        setSettings(layoutSettings[localLayout])
    }, [localLayout])

    const layoutOptions = useMemo(() => {
        return networkVizKind === '3d' ? LAYOUT_ALGORITHEMS_3D : LAYOUT_ALGORITHEMS_2D
    }, [networkVizKind])

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={500} marginTop={1}>
                    Layout
                </Typography>
                <Tooltip title="Reset to default">
                    <IconButton
                        onClick={() => {
                            setSettings(NetworkVizInitialState.layoutSettings[localLayout])
                        }}
                    >
                        <ResetIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={2} minWidth={0}>
                {/* Layout select
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Layout"
                    options={layoutOptions}
                    value={localLayout}
                    onChange={setLocalLayout}
                />

                {networkVizKind !== '3d' && localLayout === 'force' ? (
                    <NetworkForceSettings settings={settings as any} saveSettings={saveSettings} />
                ) : localLayout === 'force-atlas' ? (
                    <NetworkForceAtlasSettings settings={settings as any} saveSettings={saveSettings} />
                ) : localLayout === 'noverlap' ? (
                    <NetworkNoOverlapSettings settings={settings as any} saveSettings={saveSettings} />
                ) : localLayout === 'sfdp' ? (
                    <NetworkSFDPSettings
                        settings={settings as any}
                        analytics={analytics}
                        saveSettings={saveSettings}
                        edgeDataSchema={edgeDataSchema}
                        nodeDataSchema={nodeDataSchema}
                    />
                ) : (
                    <BaseButton label="Apply" onClick={(evt) => saveSettings({})} variant="outlined" />
                )}
            </Stack>
        </Stack>
    )
}
