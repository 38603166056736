//* ======= Libraries
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Stack,
    Select,
    MenuItem,
    Box,
    lighten,
    useTheme,
    alpha,
    Tabs,
    Tab,
    IconButton,
    Menu,
    Tooltip,
    Typography,
    Collapse,
} from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import {
    CombinedWidgetSelectType,
    CombinedWidgetType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import WidgetContent from '../WidgetContent'
import useReportStore, {
    ReportDesignerStoreStateType,
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import { getBaseDimensions, parseWidgetPosition } from 'features/report-designer/helpers/reportDesigner.helper'
import { scaleUpDimensions } from '../WidgetContainer'
//* ======= Assets and styles
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import BaseButton from 'components/base/BaseButton'
import { WidgetType } from 'services/WidgetApi'

type Props = {
    data: CombinedWidgetType
    isActive: boolean
    Id: ReportWidgetType['id']
    scaleFactor: number
    onReady: () => void
}

function CombinedWidget({ data, isActive, Id, onReady, scaleFactor }: Props) {
    const activeSlide = useReportActiveSlide()
    const { updateCombinedWidgetContent, activeSlideId, viewMode } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        viewMode: store.viewMode,
        updateCombinedWidgetContent: store.updateCombinedWidgetContent,
    }))

    const readyWidgets = useRef<Set<ReportWidgetType['id']>>(new Set())

    const onCombinedWidgetReady = (widgetId: ReportWidgetType['id']) => {
        if (combinedWidget?.content.kind === 'combined' && combinedWidget.content.details.mode === 'select') {
            onReady()
        } else {
            if (readyWidgets.current.size + 1 === widgets.length) {
                onReady()
            }
            readyWidgets.current.add(widgetId)
        }
    }

    const muiTheme = useTheme()

    const combinedWidget = useReportActiveWidget(Id)

    const [floatingMenuAnchorEl, setFloatingMenuAnchorEl] = useState<null | HTMLElement>(null)

    const handleFloatingMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFloatingMenuAnchorEl(event.currentTarget)
    }

    const handleFloatingMenuClose = (newValue?: string | number) => {
        setFloatingMenuAnchorEl(null)
        if (newValue) {
            handleChangeTab(newValue)
        }
    }

    const handleChangeTab = (newValue: string | number) => {
        if (activeSlideId === null) return
        updateCombinedWidgetContent({
            slideId: activeSlideId,
            widgetId: Id,
            data: { selectedWidgetId: newValue },
        })
    }

    const widgets = useMemo<{ icon: string | undefined; widget: ReportWidgetType }[]>(() => {
        const result: { widget: ReportWidgetType; icon: string | undefined }[] = []
        data.widgets.forEach((_widget) => {
            const widget = activeSlide?.widgets.find((_w) => _w.id === _widget.id)
            if (widget) {
                result.push({
                    widget: widget,
                    icon: _widget.icon,
                })
            }
        })
        return result
    }, [data.widgets, activeSlide?.widgets])

    const activeWidget = useMemo(() => {
        return widgets.find(({ widget: _widget }) => _widget.id === data.selectedWidgetId)?.widget
    }, [data.selectedWidgetId, widgets])

    const getOutlineValue = (widgetId: ReportWidgetType['id']) => {
        if (isActive && viewMode === 'design') {
            // Active (selected)
            if (widgetId === data.selectedWidgetId) {
                return `2px solid ${lighten(muiTheme.palette.primary.main, 0.5)}`
            }
            // Idle (un-selected)
            else {
                return `1px dashed ${alpha(muiTheme.palette.common.border_1, 0.25)}`
            }
        } else {
            return undefined
        }
    }

    if (data.mode === 'select')
        if (data.variant === 'stack')
            return (
                <CombinedWidgetList
                    combinedWidget={data}
                    widgets={widgets}
                    scaleFactor={scaleFactor}
                    isActive={isActive}
                    viewMode={viewMode}
                    onCombinedWidgetReady={onCombinedWidgetReady}
                />
            )
        else
            return (
                <Stack
                    justifyContent="center"
                    sx={(theme) => ({
                        width: '100%',
                        height: '100%',
                    })}
                >
                    {/* Active Widget Selector */}
                    {data.variant === 'tabs' ? (
                        <Box>
                            <Tabs
                                className="combined-widget-selector"
                                value={data.selectedWidgetId}
                                onChange={(event, newValue) => handleChangeTab(newValue)}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {widgets.map(({ icon, widget: _widget }) => (
                                    <Tab
                                        key={_widget.id}
                                        value={_widget.id}
                                        label={data.iconOnly && icon ? undefined : _widget.title}
                                        icon={
                                            icon && (
                                                <DynamicIcon
                                                    icon={icon}
                                                    sx={{ fontSize: data.fontStyle.size * scaleFactor * 1.5 }}
                                                />
                                            )
                                        }
                                        iconPosition={data.iconPosition ?? 'end'}
                                        sx={{
                                            fontWeight: data.fontStyle.weight,
                                            fontSize: data.fontStyle.size * scaleFactor,
                                            fontFamily: data.fontStyle.family,
                                            color: data.fontStyle.color,
                                            minHeight: 0,
                                        }}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                    ) : data.variant === 'drop-down' ? (
                        <Select
                            className="combined-widget-selector"
                            value={data.selectedWidgetId}
                            variant="standard"
                            fullWidth
                            sx={{
                                p: 1,
                            }}
                            onChange={(event) => handleChangeTab(event.target.value as string)}
                        >
                            {widgets.map(({ widget: _widget }) => (
                                <MenuItem key={_widget.id} value={_widget.id}>
                                    {_widget.title}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : data.variant === 'floating-menu' ? (
                        <>
                            <IconButton
                                className="combined-widget-selector"
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    zIndex: 1000,
                                }}
                                onClick={handleFloatingMenuButtonClick}
                            >
                                <ViewSidebarIcon />
                            </IconButton>
                            <Menu
                                anchorEl={floatingMenuAnchorEl}
                                keepMounted
                                open={Boolean(floatingMenuAnchorEl)}
                                onClose={() => handleFloatingMenuClose()}
                            >
                                {widgets.map(({ widget: _widget }) => (
                                    <MenuItem
                                        sx={(theme) => ({
                                            color:
                                                _widget.id === data.selectedWidgetId ? 'primary.main' : 'text.primary',
                                            bgcolor:
                                                _widget.id === data.selectedWidgetId
                                                    ? theme.palette.common.bg_4
                                                    : 'transparent',
                                        })}
                                        key={_widget.id}
                                        onClick={() => handleFloatingMenuClose(_widget.id)}
                                    >
                                        {_widget.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    ) : null}

                    {/* Active Widget Content , contetn will be full width and fit the rest of the container height*/}
                    {activeWidget && (
                        <Box
                            id={`widget-${activeWidget.id}`}
                            sx={{
                                width: '100%',
                                flexGrow: 1,
                                overflow: 'auto',
                            }}
                        >
                            <WidgetContent
                                widgetId={data.selectedWidgetId}
                                content={activeWidget.content}
                                isActive={isActive}
                                slideWidthScaleFactor={scaleFactor}
                                viewMode={viewMode}
                                title={activeWidget.title}
                                tooltip={activeWidget.tooltip}
                                onReady={() => onCombinedWidgetReady(data.selectedWidgetId)}
                            />
                        </Box>
                    )}
                </Stack>
            )
    else if (combinedWidget !== null && activeSlide !== null)
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}
            >
                {widgets.map(({ widget: _widget }) => {
                    return (
                        <Box
                            key={_widget.id}
                            sx={{
                                position: 'absolute',
                                top: _widget.position.top,
                                left: _widget.position.left,
                                width: _widget.dimensions.width + '%',
                                height: _widget.dimensions.height + '%',
                                outline: getOutlineValue(_widget.id),
                            }}
                            onClick={() => handleChangeTab(_widget.id)}
                        >
                            <WidgetContent
                                onReady={() => onCombinedWidgetReady(_widget.id)}
                                widgetId={_widget.id}
                                content={_widget.content}
                                isActive={isActive && _widget.id === data.selectedWidgetId}
                                slideWidthScaleFactor={scaleFactor}
                                viewMode={viewMode}
                                tooltip={_widget.tooltip}
                                title={_widget.title}
                            />
                        </Box>
                    )
                })}
            </Box>
        )
    else return null
}

type CombinedWidgetListProps = {
    combinedWidget: CombinedWidgetSelectType
    widgets: { icon: string | undefined; widget: ReportWidgetType }[]
    scaleFactor: number
    isActive: boolean
    viewMode: 'design' | 'preview'
    onCombinedWidgetReady: (widgetId: ReportWidgetType['id']) => void
}

function CombinedWidgetList({
    combinedWidget,
    widgets,
    isActive,
    scaleFactor,
    viewMode,
    onCombinedWidgetReady,
}: CombinedWidgetListProps) {
    const [collapsed, setCollapsed] = useState<ReportWidgetType['id'][]>([])

    const toggleCollapse = (widgetId: ReportWidgetType['id']) => {
        if (collapsed.includes(widgetId)) {
            if (combinedWidget.accordion.closeOthers) {
                setCollapsed(widgets.map(({ widget }) => widget.id).filter((id) => id !== widgetId))
            } else {
                setCollapsed((prev) => prev.filter((id) => id !== widgetId))
            }
        } else {
            setCollapsed((prev) => [...prev, widgetId])
        }
    }

    useEffect(() => {
        if (combinedWidget.accordion.closeOthers) {
            setCollapsed(widgets.map(({ widget }) => widget.id))
        }
    }, [combinedWidget.accordion.closeOthers])

    return (
        <Stack
            sx={(theme) => ({
                width: '100%',
                height: '100%',
                overflowY: 'auto',
            })}
            className="u-scrollbar"
            direction={combinedWidget.stackDirection}
            gap={1}
        >
            {widgets.map(({ widget: _widget }) => {
                const scaledWidgetSize = scaleUpDimensions(
                    _widget.dimensions.width,
                    _widget.dimensions.height,
                    scaleFactor
                )
                const size = {
                    width: combinedWidget.stackDirection === 'row' ? scaledWidgetSize.width : '100%',
                    height: combinedWidget.stackDirection === 'column' ? scaledWidgetSize.height : '100%',
                }

                const isOpen = !collapsed.includes(_widget.id)

                return (
                    <Stack
                        key={_widget.id}
                        sx={{
                            flexShrink: 0,
                        }}
                        direction={combinedWidget.stackDirection === 'column' ? 'column' : 'row'}
                    >
                        <BaseButton
                            variant="contained"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.common.bg_4,
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.common.bg_4, 0.8),
                                },

                                ...(combinedWidget.stackDirection === 'row'
                                    ? {
                                          width: '46px',
                                          height: '100%',
                                      }
                                    : {
                                          width: '100%',
                                          height: '46px',
                                      }),
                            })}
                            onClick={() => toggleCollapse(_widget.id)}
                        >
                            <Stack
                                direction={combinedWidget.stackDirection === 'row' ? 'column' : 'row'}
                                p={1}
                                gap={1}
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{
                                    height: combinedWidget.stackDirection === 'row' ? '100%' : '46px',
                                    width: combinedWidget.stackDirection === 'column' ? '100%' : '46px',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                        writingMode:
                                            combinedWidget.stackDirection === 'row' ? 'vertical-lr' : undefined,
                                        textAlign: 'center',
                                    }}
                                >
                                    {_widget.title}
                                </Typography>
                                <IconButton>
                                    {isOpen ? (
                                        <ArrowUpward
                                            sx={{
                                                transform:
                                                    combinedWidget.stackDirection === 'row'
                                                        ? 'rotate(270deg)'
                                                        : undefined,
                                            }}
                                        />
                                    ) : (
                                        <ArrowDownward
                                            sx={{
                                                transform:
                                                    combinedWidget.stackDirection === 'row'
                                                        ? 'rotate(270deg)'
                                                        : undefined,
                                            }}
                                        />
                                    )}
                                </IconButton>
                            </Stack>
                        </BaseButton>
                        <Collapse
                            in={isOpen}
                            key={combinedWidget.stackDirection}
                            orientation={combinedWidget.stackDirection === 'column' ? 'vertical' : 'horizontal'}
                        >
                            <Box sx={{ ...size }}>
                                <WidgetContent
                                    widgetId={_widget.id}
                                    content={_widget.content}
                                    isActive={isActive && _widget.id === combinedWidget.selectedWidgetId}
                                    slideWidthScaleFactor={scaleFactor}
                                    viewMode={viewMode}
                                    title={_widget.title}
                                    tooltip={_widget.tooltip}
                                    onReady={() => onCombinedWidgetReady(_widget.id)}
                                />
                            </Box>
                        </Collapse>
                    </Stack>
                )
            })}
        </Stack>
    )
}

export default CombinedWidget
