import { useEffect, useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowSelectionModel,
} from '@mui/x-data-grid-pro'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { EdgeRenderType, NodeType } from 'features/network-viz/types/NetworkViz.types'
import ToastHelper from 'helpers/ToastHelper'
import WebWorker from 'helpers/webWorkerHelper'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RestoreIcon from '@mui/icons-material/Restore'
import { set } from 'lodash'

export interface NetworkEditNodesModalProps {
    open: boolean
    setOpen: (val: boolean) => void
}

export function NetworkEditNodesModal({ open, setOpen }: NetworkEditNodesModalProps) {
    const { edges, edgeRenders, nodes, nodeRenders, nodeDataSchema } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()

    //states
    const [internalNodes, setInternalNodes] = useState<NodeType[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
    const [rowsToDelete, setRowsToDelete] = useState<Set<GridRowId>>(new Set())
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    }

    const handleDeleteClick = (id: GridRowId) => () => {
        setRowsToDelete((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(id)) {
                newSet.delete(id)
            } else {
                newSet.add(id)
            }
            return newSet
        })
    }

    const deleteSelectedRows = () => {
        setRowsToDelete((prev) => {
            const newSet = new Set(prev)
            for (const id of rowSelectionModel) {
                newSet.add(id)
            }
            return newSet
        })
    }

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })
    }

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow } as NodeType
        setInternalNodes((pv) => pv.map((row) => (row.id === newRow.id ? updatedRow : row)))
        return updatedRow
    }

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true
        }
    }

    const getRowClassName = (params: GridRowParams) => {
        return rowsToDelete.has(params.id) ? 'line-through' : ''
    }

    // set the internal nodes when the nodes change or the modal opens
    useEffect(() => {
        if (!open) return
        setInternalNodes(structuredClone(Object.values(nodes)))
        setRowSelectionModel([])
        setRowsToDelete(new Set())
        setRowModesModel({})
    }, [nodes, open, nodeDataSchema.fields])

    useEffect(() => {
        if (!open) return
        setColumns([
            {
                field: 'id',
                headerName: 'ID',
            },
            ...Object.keys(nodeDataSchema.fields)
                .filter((x) => x !== 'id')
                .map((e) => ({
                    field: e,
                    headerName: e,
                    editable: true,
                })),

            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
                    const isMarkedForDeletion = rowsToDelete.has(id)
                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ]
                    }

                    return [
                        // <GridActionsCellItem
                        //     icon={<EditIcon />}
                        //     label="Edit"
                        //     className="textPrimary"
                        //     onClick={handleEditClick(id)}
                        //     color="inherit"
                        // />,
                        <GridActionsCellItem
                            icon={isMarkedForDeletion ? <RestoreIcon /> : <DeleteIcon />}
                            label={isMarkedForDeletion ? 'Restore' : 'Delete'}
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ]
                },
            },
        ])
    }, [open, nodeDataSchema.fields, rowModesModel, rowsToDelete])

    const onClose = () => {
        setOpen(false)
    }

    const saveChanges = async () => {
        const deletedNodes = Array.from(rowsToDelete)

        // delete the nodes
        const updatedNodeRenders = nodeRenders.filter((x) => !deletedNodes.includes(x.id))
        const updatedNodes = structuredClone(nodes)
        for (const deltedNodes of deletedNodes) {
            delete updatedNodes[deltedNodes]
        }

        // delete the edges that are connected to the deleted nodes
        const updatedEdgeRenders: EdgeRenderType[] = []
        const updatedEdges = structuredClone(edges)
        for (const edge of edgeRenders) {
            if (deletedNodes.includes(edge.source) || deletedNodes.includes(edge.target)) {
                delete updatedEdges[edge.id]
                continue
            }
            updatedEdgeRenders.push(edge)
        }

        // compute the new node data schema
        const nodeDataSchemaWorker = new WebWorker('workers/network/generate-data-schema-function.js')
        const updatedNodeDataSchema = (await nodeDataSchemaWorker.run({
            data: Object.values(nodes),
            mode: 'node',
        })) as any

        // compute the new edge data schema
        const edgeDataSchemaWorker = new WebWorker('workers/network/generate-data-schema-function.js')
        const updatedEdgeDataSchema = (await edgeDataSchemaWorker.run({
            data: Object.values(edges),
            mode: 'edge',
        })) as any

        // update the context
        contextDispatch({
            type: 'NODE_UPDATE',
            payload: {
                nodes: updatedNodes,
                nodeRenders: updatedNodeRenders,
                nodeDataSchema: updatedNodeDataSchema.dataSchema,
                nodeInfoConfig: updatedNodeDataSchema.settings,
                edges: updatedEdges,
                edgeRenders: updatedEdgeRenders,
                edgeDataSchema: updatedEdgeDataSchema.dataSchema,
                edgeLegend: [],
                nodeLegend: [],
            },
        })

        onClose()
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>Edit Nodes</DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <Stack direction="column" height="100%">
                    <StyledDataGrid
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        rowSelectionModel={rowSelectionModel}
                        onRowSelectionModelChange={setRowSelectionModel}
                        processRowUpdate={processRowUpdate}
                        columns={columns}
                        rows={internalNodes}
                        density="standard"
                        checkboxSelection
                        getRowId={(r) => r.id}
                        getRowClassName={getRowClassName}
                        customToolbar={
                            rowSelectionModel.length > 0 && (
                                <BaseButton color="warning" variant="contained" onClick={deleteSelectedRows}>
                                    Delete Selected
                                </BaseButton>
                            )
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} color="warning" variant="outlined">
                    Close
                </BaseButton>
                <BaseButton
                    color="primary"
                    variant="contained"
                    disabled={Array.from(rowsToDelete).length === 0}
                    onClick={saveChanges}
                >
                    Save Changes
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
