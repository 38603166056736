//* ======= Libraries
import React, { useCallback } from 'react'
//* ======= Components and features
import TextWidget from 'features/report-designer/widgets/text-widget/TextWidget'
import ImageWidget from 'features/report-designer/widgets/image-widget/ImageWidget'
import ChartWidget from 'features/report-designer/widgets/chart-widget/ChartWidget'
import NetworkWidget from 'features/report-designer/widgets/network-widget/NetworkWidget'
import FilterWidget from 'features/report-designer/widgets/filter-widget/FilterWidget'
import TableWidget from 'features/report-designer/widgets/table-widget/TableWidget'
import VideoWidget from 'features/report-designer/widgets/video-widget/VideoWidget'
import InsightWidget from 'features/report-designer/widgets/insight-widget/InsightWidget'
import InfoWidget from 'features/report-designer/widgets/info-widget/InfoWidget'
import NavLinkWidget from 'features/report-designer/widgets/nav-link/NavLinkWidget'
import AIWidget from './ai-widget/AIWidget'
import HorizontalLineWidget from 'features/report-designer/widgets/horizontal-line-widget/HorizontalLineWidget'
import VerticalLineWidget from 'features/report-designer/widgets/vertical-line-widget/VerticalLineWidget'
import { Stack, SvgIcon, TooltipProps } from '@mui/material'
//* ======= Custom logic
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { ReportWidgetType, ReportWidgetContentType } from 'features/report-designer/types/reportDesigner.types'
import CombinedWidget from './combined-widget/CombinedWidget'
import { Box, Tooltip } from '@mui/material'
import { iconSizeDict } from './info-widget/InfoWidgetSettings'
//* ======= Assets and styles
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import DynamicControlWidget from './dynamic-control-widget/DynamicControlWidget'
import FilterBadge from './components/FilterBadge'
import PanelWidget from './panel-widget/PanelWidget'

type Props = {
    widgetId: ReportWidgetType['id']
    content: ReportWidgetContentType
    tooltip: ReportWidgetType['tooltip']
    viewMode: ReportDesignerStoreStateType['viewMode']
    title: string
    isActive: boolean
    slideWidthScaleFactor: number
    onReady: () => void
}

const TOOLTIP_PLACEMENTS: Record<
    Exclude<TooltipProps['placement'], undefined>,
    {
        top?: number | string
        left?: number | string
        right?: number | string
        bottom?: number | string
        transform?: string
    }
> = {
    top: { top: 0, left: '50%', transform: 'translateX(-50%)' },
    bottom: { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
    left: { top: '50%', left: 0, transform: 'translateY(-50%)' },
    right: { top: '50%', right: 0, transform: 'translateY(-50%)' },
    'top-end': { top: 0, right: 0, transform: 'translateY(0%)' },
    'top-start': { top: 0, left: 0, transform: 'translateY(0%)' },
    'bottom-end': { bottom: 0, right: 0, transform: 'translateY(0%)' },
    'bottom-start': { bottom: 0, left: 0, transform: 'translateY(0%)' },
    'left-end': { bottom: 0, left: 0, transform: 'translateX(0%)' },
    'left-start': { top: 0, left: 0, transform: 'translateX(0%)' },
    'right-end': { bottom: 0, right: 0, transform: 'translateX(0%)' },
    'right-start': { top: 0, right: 0, transform: 'translateX(0%)' },
}
function WidgetContent({
    onReady,
    widgetId,
    tooltip,
    viewMode,
    content,
    title,
    isActive,
    slideWidthScaleFactor,
}: Props) {
    const { activeSlideId, updateConnectedWidgets: store_updateConnectedWidgets } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateConnectedWidgets: store.updateConnectedWidgets,
    }))

    const updateConnectedWidgets = useCallback(
        (widgetId: ReportWidgetType['id'], selectedItem: string | null) => {
            if (activeSlideId === null) return

            store_updateConnectedWidgets({
                slideId: activeSlideId,
                sourceWidgetId: widgetId,
                selectedItem,
            })
        },
        [store_updateConnectedWidgets, activeSlideId]
    )

    let Content = null
    switch (content.kind) {
        case 'text':
            Content = (
                <TextWidget
                    key={widgetId}
                    onReady={onReady}
                    Id={widgetId}
                    data={content.details}
                    isActive={isActive}
                    fontSizeScaleFactor={slideWidthScaleFactor}
                />
            )
            break

        case 'image':
            Content = <ImageWidget key={widgetId} onReady={onReady} data={content.details} />
            break

        case 'chart':
            Content = (
                <ChartWidget
                    key={widgetId}
                    Id={widgetId}
                    onReady={onReady}
                    data={content.details}
                    scaleFactor={slideWidthScaleFactor}
                />
            )
            break

        case 'network':
            Content = (
                <NetworkWidget
                    key={widgetId}
                    Id={widgetId}
                    data={content.details}
                    onReady={onReady}
                    updateConnectedWidgets={updateConnectedWidgets}
                />
            )
            break

        case 'filter':
            Content = (
                <FilterWidget
                    key={widgetId}
                    Id={widgetId}
                    data={content.details}
                    isActive={isActive}
                    onReady={onReady}
                    fontSizeScaleFactor={slideWidthScaleFactor}
                />
            )
            break

        case 'table':
            Content = (
                <TableWidget
                    key={widgetId}
                    Id={widgetId}
                    data={content.details}
                    updateConnectedWidgets={updateConnectedWidgets}
                    title={title}
                    onReady={onReady}
                    isActive={isActive}
                    viewMode={viewMode}
                    fontSizeScaleFactor={slideWidthScaleFactor}
                />
            )
            break

        case 'insight':
            Content = (
                <InsightWidget
                    isActive={isActive}
                    key={widgetId}
                    Id={widgetId}
                    data={content.details}
                    onReady={onReady}
                    title={title}
                    fontSizeScaleFactor={slideWidthScaleFactor}
                />
            )
            break
        case 'ai':
            Content = <AIWidget key={widgetId} Id={widgetId} data={content.details} onReady={onReady} title={title} />
            break

        case 'video':
            Content = <VideoWidget key={widgetId} data={content.details} onReady={onReady} isActive={isActive} />
            break

        case 'info':
            Content = <InfoWidget key={widgetId} data={content.details} onReady={onReady} isActive={isActive} />
            break

        case 'navLink':
            Content = (
                <NavLinkWidget
                    key={widgetId}
                    data={content.details}
                    isActive={isActive}
                    fontSizeScaleFactor={slideWidthScaleFactor}
                    onReady={onReady}
                />
            )
            break

        case 'horizontalLine':
            Content = (
                <HorizontalLineWidget
                    key={widgetId}
                    data={content.details}
                    onReady={onReady}
                    scaleFactor={slideWidthScaleFactor}
                />
            )
            break

        case 'verticalLine':
            Content = (
                <VerticalLineWidget
                    key={widgetId}
                    data={content.details}
                    onReady={onReady}
                    scaleFactor={slideWidthScaleFactor}
                />
            )
            break

        case 'combined':
            Content = (
                <CombinedWidget
                    isActive={isActive}
                    key={widgetId}
                    data={content.details}
                    onReady={onReady}
                    Id={widgetId}
                    scaleFactor={slideWidthScaleFactor}
                />
            )
            break

        case 'dynamicControl':
            Content = (
                <DynamicControlWidget
                    isActive={isActive}
                    key={widgetId}
                    data={content.details}
                    onReady={onReady}
                    fontSizeScaleFactor={slideWidthScaleFactor}
                />
            )
            break
        case 'panel':
            Content = (
                <PanelWidget
                    key={widgetId}
                    data={content.details}
                    onReady={onReady}
                    scaleFactor={slideWidthScaleFactor}
                    widgetId={widgetId}
                    isActive={isActive}
                    viewMode={viewMode}
                />
            )
            break
        default:
            break
    }

    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                flexDirection:
                    tooltip.container.mode === 'inline' && tooltip.container.placement === 'bottom'
                        ? 'column-reverse'
                        : 'column',
            }}
        >
            {tooltip.isEnabled && (
                <Box
                    sx={
                        tooltip.container.mode === 'floating'
                            ? {
                                  position: 'absolute',
                                  ...TOOLTIP_PLACEMENTS[tooltip.container.placement || 'top-end'],
                                  paddingX: tooltip.container.paddingX,
                                  paddingY: tooltip.container.paddingY,
                                  zIndex: 2,
                              }
                            : {
                                  px: 1,
                              }
                    }
                >
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        justifyContent={
                            tooltip.container.mode === 'inline' ? tooltip.container.alignment : 'flex-start'
                        }
                    >
                        {tooltip.text.trim() !== '' && (
                            <Tooltip title={tooltip.text} arrow enterDelay={500} leaveDelay={200}>
                                <SvgIcon
                                    sx={{
                                        fontSize: iconSizeDict[tooltip.icon.size],
                                        color: tooltip.icon.color,
                                    }}
                                >
                                    {tooltip.icon.type === 'question' ? (
                                        <QuestionMarkIcon />
                                    ) : tooltip.icon.type === 'questionOutline' ? (
                                        <HelpOutlineIcon />
                                    ) : (
                                        <InfoOutlinedIcon />
                                    )}
                                </SvgIcon>
                            </Tooltip>
                        )}

                        {tooltip.filterInfo && 'filters' in content.details && content.details.filters !== null && (
                            <FilterBadge
                                filters={content.details.filters}
                                size={tooltip.icon.size}
                                color={tooltip.icon.color}
                                badgePosition={
                                    tooltip.container.mode === 'inline' && tooltip.container.placement === 'bottom'
                                        ? 'top'
                                        : 'bottom'
                                }
                            />
                        )}
                    </Stack>
                </Box>
            )}
            <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>{Content}</Box>
        </Stack>
    )
}

export default WidgetContent
