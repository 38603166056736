//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
//* ======= Custom logic
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

interface IPrivacyPolicyDialog {
    onClose: () => void
}

function PrivacyPolicyDialog({ onClose }: IPrivacyPolicyDialog) {
    return (
        <Stack
            gap={3}
            height="100%"
            py={3}
            px={3}
            sx={{
                overflowY: 'auto',
            }}
        >
            {/* Title and close button
				========================================= */}
            <Stack flexShrink={0} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={36} fontWeight={600} lineHeight="24px" color="primary">
                    SNA TOOLBOX PRIVACY POLICY
                </Typography>

                {/* Close button
					========================================= */}
                <IconButton
                    onClick={(e) => onClose()}
                    sx={{
                        padding: 1,
                    }}
                >
                    <CloseRoundedIcon
                        sx={{
                            height: '22px',
                            width: '22px',

                            // color: 'common.fill_2',
                        }}
                    />
                </IconButton>
            </Stack>

            {/* Content
				========================================= */}
            <Stack
                flexGrow={1}
                gap={2}
                sx={{
                    overflowY: 'auto',
                }}
                className="u-scrollbar"
            >
                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    <Box
                        component={'span'}
                        sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'common.text_1',
                        }}
                    >
                        SNA Toolbox Pty Ltd
                    </Box>
                    {' (ACN 615 827 999) ('}
                    <Box
                        component={'span'}
                        sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'common.text_1',
                        }}
                    >
                        “we”, “us” or “our”
                    </Box>
                    {
                        ') provides data collection and analysis services, including the SNA Toolbox VPNet and NetCollect tools.'
                    }
                </Typography>

                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    {'This document ('}
                    <Box
                        component={'span'}
                        sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'common.text_1',
                        }}
                    >
                        “Privacy Policy”
                    </Box>
                    {
                        ') sets out how we collect, use, process, store, share and disclose the Personal Information of users – including those operating programs using our services and the participants in such programs ('
                    }
                    <Box
                        component={'span'}
                        sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'common.text_1',
                        }}
                    >
                        “Users”
                    </Box>
                    {').'}
                </Typography>

                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    {'This Privacy Policy forms part of our terms and conditions - available '}
                    <Link
                        href="#"
                        sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'primary.main',
                        }}
                    >
                        here
                    </Link>
                    {
                        '. By accessing and using our products and services, you freely and expressly consent to the collection, use, processing, storage and disclosure of Personal Information by us as set out in this Privacy Policy.'
                    }
                </Typography>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={18} fontWeight={600} color="primary">
                    We are committed to protecting your privacy and respecting and upholding your rights
                    under:
                </Typography>

                {/* Unordered list
				    ========================================= */}
                <Box
                    component={'ul'}
                    sx={{
                        margin: 0,
                        marginLeft: 3.5,
                        paddingLeft: 3.5,
                    }}
                >
                    <li>
                        {/* Normal text
				            ========================================= */}
                        <Typography fontSize={18} fontWeight={300} color="common.text_1">
                            the Australian Privacy Principles (“APPs”) contained in the Privacy Act 1988 (Cth)
                        </Typography>
                    </li>
                    <li>
                        <Typography fontSize={18} fontWeight={300} color="common.text_1">
                            the General Data Protection Regulation (EU 2016/679) (the “GDPR”)
                        </Typography>
                    </li>
                    <li>
                        <Typography fontSize={18} fontWeight={300} color="common.text_1">
                            {'relevant privacy legislation applicable in your jurisdiction (collectively, “'}
                            <Box
                                component={'span'}
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    color: 'common.text_1',
                                }}
                            >
                                Privacy Laws
                            </Box>
                            {'”).'}
                        </Typography>
                    </li>
                </Box>

                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    If you require further information regarding our Privacy Policy or wish to make a privacy
                    complaint, please contact the SNA Toolbox Privacy Coordinator via the notice details set
                    out in Section 13 below.
                </Typography>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Your Information
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We refer to “you” a lot in this Privacy Policy. To better understand what information
                        is most relevant to you, see the following useful definitions of types of Users:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 3.5,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                <Box
                                    component={'span'}
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: 600,
                                        color: 'common.text_1',
                                    }}
                                >
                                    “Registered Users”:
                                </Box>
                                {
                                    ' You hold a registered account with us and you either directly create surveys, networks or applications or you are collaborating on, commenting on, or reviewing surveys, networks or applications within an account.'
                                }
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                <Box
                                    component={'span'}
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: 600,
                                        color: 'common.text_1',
                                    }}
                                >
                                    “Participants”:
                                </Box>
                                {' You participate in one of our network surveys.'}
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We will collect Personal Information only by lawful and fair means and not in an
                        unreasonably intrusive way. We will collect Personal Information directly from Users
                        to the extent necessary to provide our services, to carry out our administrative
                        functions, and as required by the Privacy Laws.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may also collect Personal Information from you when you fill in an application
                        form, communicate with us, visit our website, provide us with feedback, or complete
                        online surveys.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Users are expected to provide accurate, up to date and complete Personal Information
                        at the time it is collected.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        By providing any Personal Information to us, each User warrants to us that it has the
                        authority to do so.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Information about children under 18
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Registered Users must be at least 18 years of age to access our Site and Services to
                        operate any programs using our services.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Participants under at least 18 years of age may participate in surveys hosted by an
                        authorised Registered User, provided that the relevant Registered User has notified us
                        of the details of the program seeking our consent, provided sufficient information and
                        evidence to demonstrate that any Personal Information collected from such Participants
                        has been collected in accordance with all applicable Privacy Laws and met any other
                        conditions reasonably imposed by us.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Information we may collect about you
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Personal information is any information relating to an identified or identifiable
                        natural person (“Personal Information”). An identifiable natural person is one who can
                        be identified, directly or indirectly, in particular by reference to an identifier
                        such as a name, an identification number, location data, an online identifier or to
                        one or more factors specific to the physical, physiological, genetic, mental,
                        economic, cultural or social identity of that natural person.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        The type of Personal Information we collect from you may include, without limitation,
                        the following:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Full name
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Address
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Email address
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Telephone number(s)
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Date of birth
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Information collected directly from you through a transaction or inquiry, or
                                submission of a form or registration to Netcollect or SNA Toolbox
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Your device ID, device type, geo-location information, computer and connection
                                information, statistics on page views, traffic to and from the sites, ad data,
                                IP address and standard web log information
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Details of the products and services we have provided to you or that you have
                                enquired about, including any additional information necessary to deliver
                                those products and services and respond to your enquiries
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Any additional information relating to you that you provide to us directly
                                through our website or app or indirectly through your use of our website or
                                app or online presence or through other websites or accounts from which you
                                permit us to collect information
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Information you provide to us through surveys, including information about
                                your networks
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Any other personal information that may be required in order to facilitate
                                your dealings with us.
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        The type of Personal Information we collect from you may include, without limitation,
                        the following:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Registration information – the information required to register your Account
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                Billing information - If you make a payment to us, we require you to provide
                                your billing details, a name, address, email address and financial information
                                corresponding to your selected method of payment (e.g. a credit card number
                                and expiration date or a bank account number)
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may collect Sensitive Information from you which may include information or an
                        opinion about your:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                personal/demographic information
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                age
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                level of education
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                employment details such as employer
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                job role
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                job sector
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                racial or ethnic origin or ethnic origina
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                political membership
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                associations or opinions
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                religious or philosophical beliefs or affiliations
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                membership of a professional association or trade union
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                sexual orientation or practices
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                criminal record or
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                health information and genetic or biometric information.
                            </Typography>
                        </li>
                    </Box>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        4.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        What is our legal basis?
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Under the GDPR, we must have a legal basis to process Personal Information collected
                        from individuals residing in the European Union.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We rely on several legal bases to process your Personal Information, including:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                where it is necessary to provide you with access to, and use of, products,
                                services and websites;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                for our legitimate interests to provide, operate and improve our products,
                                services or Site;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                where you have freely and expressly consented to the processing of your
                                Personal Information by us, which you may withdraw at any time; or
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                where we are under a legal obligation to process your Personal Information.
                            </Typography>
                        </li>
                    </Box>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        5.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        How your information is used
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We use and disclose Personal Information for the purposes for which the information is
                        collected, or for a directly related purpose.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        This includes (but is not limited to):
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                operating the Site and providing the Services;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                disclosure as contemplated or required under any additional project terms that
                                we agree in relation to a specific project, program or initiative
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                administering, protecting, improving products and services (including
                                performing data analytics and for conducting research
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                responding to any inquiries or comments that users submit to us;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                any other purpose that you specifically consent to and
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                any use which is required or authorised by law.
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        In general survey responses are controlled and managed by the Registered User (the
                        person who sent or deployed that survey). In those instances we are only processing
                        those responses on behalf of the Registered User and each Participant.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We aggregate response data and activity so that we can identify trends, conduct
                        research and provide our Services. We also extract and analyse usage patterns – to
                        improve the Services and user experience.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        6.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Disclosure of Personal Information
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may disclose Personal Information to:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                the Registered User associated with the survey you have completed
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                any person or entity to whom you have consented to us disclosing your Personal
                                Information to
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                our legal advisors, and any other person or entity to whom we are required or
                                authorised to disclose your personal information to in accordance with the
                                law.
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If we no longer need your Personal Information for any of the purposes set out in this
                        Privacy Policy, or as otherwise required by the relevant Privacy Laws, we will take
                        such steps as are reasonable in the circumstances to destroy your Personal Information
                        or to de-identify it.{' '}
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        7.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Direct Marketing
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        In respect of Registered Users, where we:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                have your express consent - which you may withdraw at any time by contacting
                                us (see Section 14: Contact information)
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                have a legal basis or
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                are otherwise permitted by relevant Privacy Laws,
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        then we may use and process your Personal Information to send you information about
                        our Products and Services. At any time, you may opt out of receiving direct marketing
                        communications from us.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Unless you opt out, your consent to receive direct marketing communications from us
                        and to the handling of your Personal Information as detailed above will continue. You
                        can opt out by following the unsubscribe instructions included in the relevant
                        marketing communication, or by contacting us in writing (see Section 14: Contact
                        information).
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        8.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        SNA Toolbox website and other tools
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        When transmitting personal information from your computer to us, you must keep in mind
                        that the transmission of information over the Internet is not always completely secure
                        or error-free. Other than liability that cannot lawfully be excluded, we will not be
                        liable in any way in relation to any breach of security or any unintended loss or
                        disclosure of that information.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        9.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Cookies
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We and our partners use cookies and similar technologies on our websites. We use
                        certain cookies, as described here in our Privacy Policy, that you agree to when you
                        use our sites and, in the case of some cookies, for legitimate interests of delivering
                        and optimizing our services (where the cookie delivers essential functionality).
                        Cookies are small bits of data we store on the device you use to access our services
                        so we can recognize repeat users. Each cookie expires after a certain period of time,
                        depending on what we use it for. We use cookies and similar technologies for several
                        reasons:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                to gather metrics about your survey taking experience
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                to make our site easier to use
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                for security reasons
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                to provide you with personalized content
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                to improve our services
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                to advertise to you
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography
                        fontSize={18}
                        fontWeight={300}
                        color="common.text_1"
                        sx={{
                            textDecoration: 'underline',
                        }}
                    >
                        Google Analytics
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        In addition to the above, we have implemented on our websites and other services
                        certain Google Analytics features that support display advertising, including
                        re-targeting. Visitors to our Site may opt out of certain types of Google Analytics
                        tracking by viewing its Customer Ads Help Center. If you do not wish to participate in
                        Google Analytics, you may also download the Google Analytics opt-out browser add-on.
                        You can choose to remove or disable cookies via your browser settings.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        10.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Data Storage
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may hold your Personal Information in either electronic or hard copy. We take
                        reasonable steps to protect your Personal Information from misuse, interference and
                        loss, as well as unauthorised access, modification or disclosure and we use a number
                        of physical, administrative, personnel and technical measures to protect your Personal
                        Information. For example, SNA Toolbox restricts access to Personal Information only to
                        authorised personnel and stores Personal Information on password protected computers
                        and by using encryption technology.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        However, we cannot guarantee the security of any Personal Information transmitted over
                        the internet and therefore you disclose information and Personal Information to us at
                        your own risk. To the extent permitted under the Privacy Laws, we will not be liable
                        for any unauthorised access, modification or disclosure, or misuse of your Personal
                        Information.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        11.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Cross-border Disclosure
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may collect Personal Information from Users based anywhere in the world.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may disclose your Personal Information to third party recipients located in or
                        outside of Australia – these are service providers that enable us to provide our
                        website, products and services to you. As at the date of this Privacy Policy, such
                        third-party recipients are located in Australia (Recipients).
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        When participating in a survey or other data collection procedure, you consent to your
                        Personal Information being disclosed or transferred to such Recipients and you
                        acknowledge and agree that we have no obligation to take such steps as are reasonable
                        in the circumstances to ensure that the information that is transferred or disclosed
                        to the Recipients will be treated in a manner that is consistent with the Privacy
                        Laws. You also agree that insofar as the law allows, we have no liability to you or
                        anyone else for any breach by the Recipient of any Privacy Laws.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        12.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Access to Information
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Under the GDPR, an individual residing in the European Union has enhanced privacy
                        rights, including the right to:
                    </Typography>

                    {/* Unordered list
				        ========================================= */}
                    <Box
                        component={'ul'}
                        sx={{
                            margin: 0,
                            marginLeft: 0,
                            paddingLeft: 3.5,
                        }}
                    >
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                require us to correct any Personal Information held about you that is
                                inaccurate or incomplete;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                require the deletion of Personal Information concerning you in certain
                                situations;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                data portability for Personal Information you provide to us;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                object or withdraw your consent at any time to the processing of your Personal
                                Information;
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                object to decisions being taken by automated means which produce legal effects
                                concerning you or similarly significantly affect you; or
                            </Typography>
                        </li>
                        <li>
                            <Typography fontSize={18} fontWeight={300} color="common.text_1">
                                otherwise restrict our processing of your Personal Information in certain
                                circumstances.
                            </Typography>
                        </li>
                    </Box>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Subject to some exceptions provided by the relevant Privacy Laws, you may request
                        access to your Personal Information in our customer account database, or seek
                        correction of it, by contacting us. See Section 14: Contact information. Should we
                        decline you access to your Personal Information, we will provide a written explanation
                        setting out our reasons for doing so.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may charge a reasonable fee that is not excessive to cover the charges of
                        retrieving your Personal Information from our customer account database. We will not
                        charge you for making the request.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If you believe that we hold Personal Information about you that is not accurate,
                        complete or up-to-date then you may request that your Personal Information be amended.
                        We will respond to your request to correct your Personal Information within a
                        reasonable timeframe and you will not be charged a fee for correcting your Personal
                        Information.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If we no longer need your Personal Information for any of the purposes set out in this
                        Privacy Policy, or as otherwise required by the relevant Privacy Laws, we will take
                        such steps as are reasonable in the circumstances to destroy your Personal Information
                        or to de-identify it.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may charge a reasonable fee that is not excessive to cover the charges of
                        retrieving your Personal Information from our customer account database. We will not
                        charge you for making the request.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If you believe that we hold Personal Information about you that is not accurate,
                        complete or up-to-date then you may request that your Personal Information be amended.
                        We will respond to your request to correct your Personal Information within a
                        reasonable timeframe and you will not be charged a fee for correcting your Personal
                        Information.
                    </Typography>

                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If we no longer need your Personal Information for any of the purposes set out in this
                        Privacy Policy, or as otherwise required by the relevant Privacy Laws, we will take
                        such steps as are reasonable in the circumstances to destroy your Personal Information
                        or to de-identify it.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        13.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Third Party Sites
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        The Site may contain links to other third party websites including social media
                        networks. This Privacy Policy applies solely to information collected by us on our
                        Site.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        14.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Contact Information
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        {
                            'If you require further information regarding our Privacy Policy or wish to make aprivacy complaint, please contact SNA Toolbox’s Privacy Coordinator Dean Lusher by post at 1330 Malvern Rd, Malvern VIC 3144 or via email to: '
                        }
                        <Link href="">deanlusher@snatoolbox.com</Link>
                        {'.'}
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        15.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Notices and Revisions
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We reserve the right to modify this Privacy Policy in whole or in part from time to
                        time without notice. Non-material changes and clarifications will take immediate
                        effect, and material changes will take effect 30 days after the posting of the amended
                        Privacy Policy on the Site.
                    </Typography>
                </Stack>

                {/* Numbered title text
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        16.
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Enforcement
                    </Typography>
                </Stack>

                <Stack gap={2} paddingLeft={4}>
                    {/* Normal text
				        ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We will cooperate with the appropriate regulatory authorities, including local data
                        protection authorities, to resolve any complaints regarding the transfer of personally
                        identifiable information that cannot be resolved between us and the individual.
                    </Typography>
                </Stack>

                {/* Revision date
				    ========================================= */}
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontSize={18} fontWeight={600} color="primary">
                        Dated:
                    </Typography>
                    <Typography fontSize={18} fontWeight={600} color="common.text_1">
                        26 May 2021
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PrivacyPolicyDialog
