//* ======= Libraries
import React, { useCallback, useEffect, useMemo } from 'react'
import Color from 'color'
import { GridColDef } from '@mui/x-data-grid-pro'
import { Box, Chip, Icon, IconButton, Popover, Stack, Typography } from '@mui/material'
import { debounce } from 'lodash'
//* ======= Components and features
//* ======= Custom logic
import { ReportSlideType, ReportWidgetType, TableWidgetType } from 'features/report-designer/types/reportDesigner.types'
import {
    TableConfigCardViewDefaultValues,
    TableConfigChipView,
    TableParsedDataRow,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import TableWidgetCardView from './TableWidgetCardView'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Assets and styles
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

type GridDataRowType = {
    id: string | number
    _internal_id: string
    [key: string]: TableParsedDataRow['cells'][any] | string | number
}

type GridDataState = {
    columns: GridColDef[]
    rows: GridDataRowType[]
}

type Props = {
    widgetId?: ReportWidgetType['id']
    slideId?: ReportSlideType['id'] | null
    parsedData: TableWidgetType['parsedData']
    config: TableConfigChipView
    selectedRowId: TableWidgetType['selectedRowId']
    onSelectedRowChange?: (id: TableWidgetType['selectedRowId']) => void
    viewMode: ReportDesignerStoreStateType['viewMode']
    isActive: boolean
    fontSizeScaleFactor: number
    onReady?: () => void
}

function TableWidgetChipView({
    widgetId,
    slideId,
    parsedData,
    config,
    selectedRowId,
    onReady,
    isActive,
    viewMode,
    fontSizeScaleFactor,
    onSelectedRowChange,
}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

    const [searchQuery, setSearchQuery] = React.useState('')

    const inputRef = React.useRef<HTMLInputElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>, rowId: string) => {
        onSelectedRowChange && onSelectedRowChange(rowId)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onSearch = useCallback(
        debounce((query: string) => {
            if (query.trim() !== searchQuery) {
                setSearchQuery(query.trim())
            }
        }, 350),
        [searchQuery]
    )

    const data = useMemo<
        {
            id: string
            _internal_id: string
            title: string
            data: {
                [key: string]: string | number | any[]
            }
        }[]
    >(() => {
        if (!parsedData) return []
        const labelField = parsedData.columns.find((col) => col.fieldType === 'primary')?.field
        if (!labelField) return []

        return parsedData.rows.map((row) => {
            const rowData = {
                id: row._internal_id,
                _internal_id: row._internal_id,
                title: row.cells[labelField].value as string,
                data: {},
            }

            return rowData
        })
    }, [parsedData])

    const filteredData = useMemo(() => {
        if (searchQuery === '') return data
        return data.filter((row) => row.title.toLowerCase().includes(searchQuery.toLowerCase()))
    }, [data, searchQuery])

    useEffect(() => {
        onReady && onReady()
    }, [])

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <Stack gap={1} sx={(theme) => ({ width: '100%', height: '100%', padding: theme.spacing(1) })}>
            {config.hasSearch && (
                <BaseFilledTextField
                    ref={inputRef}
                    sx={{
                        '& .MuiFilledInput-root': {
                            borderRadius: 4,
                        },
                        flex: '0 0 auto',
                    }}
                    hiddenLabel
                    size="small"
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" sx={{ marginRight: 1 }} />,
                        endAdornment: searchQuery.length > 0 && (
                            <IconButton
                                onClick={(e) => {
                                    if (inputRef.current) inputRef.current.value = ''
                                    setSearchQuery('')
                                }}
                                size="small"
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    onChange={(e) => onSearch(e.target.value.trim())}
                    fullWidth
                />
            )}

            <Stack
                direction="row"
                sx={(theme) => ({
                    width: '100%',
                    flex: '1 0 0',
                    overflowY: 'auto',
                    overflowX: 'clip',
                    flexWrap: 'wrap',
                    gap: theme.spacing(config.gap),
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                })}
                className="u-scrollbar"
            >
                {filteredData.length === 0 ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Typography
                            noWrap
                            color="common.text_1"
                            sx={{
                                ...config.fontStyles,
                                fontSize: config.fontStyles.fontSize * fontSizeScaleFactor,
                            }}
                        >
                            {config.emptyDataMessage || 'No data'}
                        </Typography>
                    </Box>
                ) : (
                    filteredData.map((row) => {
                        const isSelected = row.id === selectedRowId
                        const bgColor = isSelected ? config.selectedChipColor : config.defaultChipColor
                        const highlightedColor = Color(bgColor).darken(0.1).toString()
                        const fontColor = Color(bgColor).isDark() ? 'white' : 'black'
                        return (
                            <Chip
                                key={row.id}
                                label={row.title}
                                onClick={(e) => handleClick(e, row.id)}
                                sx={{
                                    ...config.fontStyles,
                                    fontSize: config.fontStyles.fontSize * fontSizeScaleFactor,
                                    color: fontColor,
                                    backgroundColor: bgColor,
                                    '&:hover': {
                                        backgroundColor: highlightedColor,
                                    },
                                }}
                            />
                        )
                    })
                )}

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <TableWidgetCardView
                        parsedData={parsedData}
                        config={{
                            ...TableConfigCardViewDefaultValues,
                            hasSearch: false,
                            canChangeRows: false,
                            hasHeader: false,
                            fontStyles: config.fontStyles,
                        }}
                        selectedRowId={selectedRowId}
                        onSelectedRowChange={onSelectedRowChange}
                        isActive={isActive}
                        fontSizeScaleFactor={fontSizeScaleFactor}
                    />
                </Popover>
            </Stack>
        </Stack>
    )
}

export default TableWidgetChipView
