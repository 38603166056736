import React, { useRef, useState } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import BaseButton from 'components/base/BaseButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import StyledDialog from 'components/dialog/StyledDialog'
import { DialogTitle, ListItemIcon, ListItemText } from '@mui/material'
import { useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { toast } from 'react-toastify'
import SettingsIcon from '@mui/icons-material/Settings'
import AnalyticsSettings from '../network-analytics/AnalyticsSettings'
import ERGMDialog from '../network-analytics/ERGMDialog'
import ALAAMDialog from '../network-analytics/ALAAMDialog'
import StatisticalTestsDialog from '../network-analytics/StatisticalTests/StatisticalTestsDialog'
import AssortativityDialog from '../network-analytics/AssortativityDialog'

type Props = {}

export type AdvanceAnalyticsTypes = 'basic' | 'attribute_coefficient' | 'ergm' | 'alaam' | 'statistical_test'

const AdvanceAnalyticsOptions: { key: AdvanceAnalyticsTypes; label: string }[] = [
    // { key: 'attribute_coefficient', label: 'Attribute Coefficient' },
    { key: 'ergm', label: 'ERGMS' },
    { key: 'alaam', label: 'ALAAM' },
    { key: 'statistical_test', label: 'Statistical Test' },
]

export default function NetworkAnalyticsButton({}: Props) {
    const networkVizContext = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    //Analytics button and menu
    const [analyticsMenuOpen, setAnalyticsMenuOpen] = useState(false)
    const analyticsAnchorRef = useRef<HTMLDivElement>(null)

    const handleToggleAnalyticsMenu = () => {
        setAnalyticsMenuOpen((prevOpen) => !prevOpen)
    }

    const handleCloseAnalyticsMenu = (event: Event) => {
        if (analyticsAnchorRef.current && analyticsAnchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setAnalyticsMenuOpen(false)
    }

    //Inserset and Difference Dialog
    const [advanceAnalyticsDialog, setAdvanceAnalyticsDialog] = useState<false | AdvanceAnalyticsTypes>(false)

    const handleCloseAdvanceAnalyticsDialog = () => setAdvanceAnalyticsDialog(false)

    const handleAnalyticsMenuItemClick = (type: AdvanceAnalyticsTypes) => {
        setAdvanceAnalyticsDialog(type)
    }

    const [settingsOpen, setSettingsOpen] = useState<boolean>(false)
    const [assortativityOpen, setAssortativityOpen] = useState<boolean>(false)

    const refreshAnalytics = async () => {
        const { nodeRenders, edgeRenders } = networkVizContext

        const webWorker = new WebWorker('workers/network/apply-advance-analytics.js')

        const ToastCloseButton = ({ closeToast }: { closeToast?: () => void }) => (
            <BaseButton
                onClick={() => {
                    webWorker.terminate()
                    closeToast && closeToast()
                }}
                label="Cancel"
            />
        )

        const toastId = toast.loading('Calculating Network Analytics', {
            // add close button to toast
            closeButton: <ToastCloseButton />,
        })

        const options = {
            nodes: nodeRenders,
            edges: edgeRenders,
            allEdges: Object.values(networkVizContext.edges),
            allNodes: Object.values(networkVizContext.nodes),
            nodeInfo: networkVizContext.nodes,
            analyticsSettings: networkVizContext.analyticsSettings,
            mode: 'all',
        }

        const mode = 'basic'

        try {
            const response = (await webWorker.run({
                mode,
                options,
                analyticsUrl: process.env.REACT_APP_API_URL,
            })) as any

            dispatchContext({ type: 'ANALYTICS_UPDATE', payload: response })

            toast.update(toastId, {
                render: 'The preset saved successfully.',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        } catch (e) {
            console.error(e)
            toast.update(toastId, {
                render: 'Failed to save preset.',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        }
        // }
    }

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={analyticsAnchorRef} aria-label="split button">
                <BaseButton
                    label="Analytics"
                    onClick={refreshAnalytics}
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    disableElevation
                    size="large"
                    sx={{
                        flexShrink: 0,
                    }}
                />
                <BaseButton
                    size="small"
                    aria-controls={analyticsMenuOpen ? 'split-button-menu' : undefined}
                    aria-expanded={analyticsMenuOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggleAnalyticsMenu}
                >
                    <ArrowDropDownIcon />
                </BaseButton>
            </ButtonGroup>

            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={analyticsMenuOpen}
                anchorEl={analyticsAnchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseAnalyticsMenu}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {AdvanceAnalyticsOptions.map((option, index) => (
                                        <MenuItem
                                            key={option.key}
                                            onClick={(event) => handleAnalyticsMenuItemClick(option.key)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={(event) => setAssortativityOpen(true)}>
                                        <ListItemText>Assortativity</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={(event) => setSettingsOpen(true)}>
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Settings</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <StyledDialog
                open={advanceAnalyticsDialog !== false}
                onClose={handleCloseAdvanceAnalyticsDialog}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    Advance Analytics: {AdvanceAnalyticsOptions.find((x) => x.key === advanceAnalyticsDialog)?.label}{' '}
                </DialogTitle>
                {advanceAnalyticsDialog === 'ergm' ? (
                    <ERGMDialog onClose={handleCloseAdvanceAnalyticsDialog} />
                ) : advanceAnalyticsDialog === 'alaam' ? (
                    <ALAAMDialog onClose={handleCloseAdvanceAnalyticsDialog} />
                ) : advanceAnalyticsDialog === 'statistical_test' ? (
                    <StatisticalTestsDialog onClose={handleCloseAdvanceAnalyticsDialog} />
                ) : (
                    <></>
                )}
            </StyledDialog>

            <AssortativityDialog open={assortativityOpen} onClose={() => setAssortativityOpen(false)} />
            <AnalyticsSettings open={settingsOpen} onClose={() => setSettingsOpen(false)} />
        </React.Fragment>
    )
}
