import { exp } from 'mathjs'
import { parseUrl, ProjectApis as Apis } from './Apis'
import { BaseResponseType, request } from './request'

export type TagType = {
    key: string | null
    value: string
    primaryKey?: number
}

export type CreatorType = {
    name: string
    avatar?: string
}

export type ProjectType = {
    primaryKey?: number
    id?: number
    title: string
    category?: string
    description?: string
    smsSenderId?: string
    emailSenderId?: string
    type?: 'template' | 'data_collection' | 'data_visualisation' | 'full'
    status?: 'draft' | 'archived' | 'active' | 'deleted'
    role: string
    favourite?: boolean
    template?: boolean
    quickAccess?: boolean
    thumbnail?: string
    tags?: Array<TagType>
    archived?: boolean
    creator?: CreatorType
    rating?: number
    color?: string
    numberOfSurveys?: number
    numberOfReports?: number
    numberOfNodes?: number
    numberOfCompletedSurveys?: number
    createdOn?: Date
}

export type GetProjectsResponse = {
    success: boolean
    data: Array<ProjectType>
}

export const GetProjects: (showMessage?: boolean) => Promise<GetProjectsResponse> = async (showMessage = false) => {
    return request(Apis.GetAll, 'GET', undefined, undefined, showMessage)
}

export type GetProjectResponse = {
    success: boolean
    data: ProjectType
}

export const GetProject: (pid: number, showMessage?: boolean) => Promise<GetProjectResponse> = async (
    pid,
    showMessage = false
) => {
    return request(parseUrl(Apis.Get, { pid }), 'GET', undefined, undefined, showMessage)
}

export type ProjectAccountType = {
    primaryKey: number
    account: {
        primaryKey: number
        name: string
        email: string
        avatar: string
        username?: string
        isActive: boolean
    }
    role: {
        primaryKey: number
        roleName: string
    }
    dataAccessId?: number
    dataAccessTitle?: string
}

export type GetAccountsProjectResponse = {
    success: boolean
    data: ProjectAccountType[]
}

export const GetProjectAccounts: (
    pid: number | string,
    showMessage?: boolean
) => Promise<GetAccountsProjectResponse> = async (pid, showMessage = false) => {
    return request(parseUrl(Apis.GetProjectAccounts, { pid }), 'GET', undefined, undefined, showMessage)
}

export const GetProjectRoles: (
    pid: number | string,
    showMessage?: boolean
) => Promise<GetProjectPermissionsResponseType> = async (pid, showMessage = false) => {
    return request(parseUrl(Apis.GetProjectRoles, { pid }), 'GET', undefined, undefined, showMessage)
}

export type CreateProjectRequestType = {
    title: string
    category: string
    description?: string
    smsSenderId: string
    emailSenderId: string
    color?: string
    thumbnail?: string
    tags?: Array<TagType>
}

export type CreateProjectsResponse = {
    success: boolean
    data: ProjectType
}

export const CreateProject: (
    project: CreateProjectRequestType,
    showMessage?: boolean
) => Promise<CreateProjectsResponse> = async (project, showMessage = false) => {
    return request(Apis.Create, 'POST', { 'Content-Type': 'application/json' }, project, showMessage)
}

export type UpdateProjectRequestType = {
    title: string
    category: string
    description?: string
    smsSenderId: string
    emailSenderId: string
    color?: string
}

export const UpdateProject: (
    pid: string | number,
    project: UpdateProjectRequestType,
    showMessage?: boolean
) => Promise<CreateProjectsResponse> = async (pid, project, showMessage = false) => {
    return request(parseUrl(Apis.Update, { pid }), 'PUT', { 'Content-Type': 'application/json' }, project, showMessage)
}

export const UpdateProjectThumbnail: (
    pid: string | number,
    body: { dataURL: string },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, body, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateThumbnail, { pid }),
        'PUT',
        { 'Content-Type': 'application/json' },
        body,
        showMessage
    )
}

export const UpdateProjectTitleService: (
    pid: string | number,
    title: { title: string },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, title, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateTitle, { pid }),
        'PUT',
        { 'Content-Type': 'application/json' },
        title,
        showMessage
    )
}

export type ToggleFavRequest = {
    id: number
}

export const toggleProjectFav: (project: ToggleFavRequest, showMessage?: boolean) => Promise<BaseResponseType> = async (
    project,
    showMessage = false
) => {
    return request(
        Apis.ToggleFavourite.replaceAll('{pid}', project.id.toString()),
        'PUT',
        undefined,
        undefined,
        showMessage
    )
}

export type CreateProjectTagResponseType = {
    success: boolean
    data: {
        primaryKey: number
    }
}

export const CreateProjectTag: (
    pid: number,
    tag: TagType,
    showMessage?: boolean
) => Promise<CreateProjectTagResponseType> = async (pid, tag, showMessage = false) => {
    return request(parseUrl(Apis.AddTag, { pid }), 'POST', undefined, tag, showMessage)
}

export const RemoveProjectTag: (pid: number, tid: number, showMessage?: boolean) => Promise<BaseResponseType> = async (
    pid,
    tid,
    showMessage = false
) => {
    return request(parseUrl(Apis.RemoveTag, { pid, tid }), 'DELETE', undefined, undefined, showMessage)
}

export type ProjectAccountCreateType = {
    email: string
    roleID: number
}

export const CreateProjectAccount: (
    pid: number | string,
    projectAccount: ProjectAccountCreateType[],
    showMessage?: boolean
) => Promise<GetAccountsProjectResponse> = async (pid, projectAccount, showMessage = false) => {
    return request(parseUrl(Apis.AddUserAccess, { pid }), 'POST', undefined, projectAccount, showMessage)
}

export const RemoveProjectAccount: (
    pid: number | string,
    aid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, aid, showMessage = false) => {
    return request(parseUrl(Apis.RemoveUserAccess, { pid, aid }), 'DELETE', undefined, undefined, showMessage)
}

export const ResendProjectAccountInvitationService: (
    pid: number | string,
    aid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, aid, showMessage = false) => {
    return request(parseUrl(Apis.ResendUserAccessInvitation, { pid, aid }), 'GET', undefined, undefined, showMessage)
}

export const UpdateProjectAccountRoleService: (
    pid: number | string,
    aid: number | string,
    role: {
        roleID: number
    },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, aid, role, showMessage = false) => {
    return request(parseUrl(Apis.UpdateUserAccess, { pid, aid }), 'PUT', undefined, role, showMessage)
}

export const UpdateProjectAccountDataAccessViewService: (
    pid: number | string,
    aid: number | string,
    dataAccessView: {
        dataAccessID: number
    },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, aid, dataAccessView, showMessage = false) => {
    return request(parseUrl(Apis.UpdateUserDataAccessView, { pid, aid }), 'PUT', undefined, dataAccessView, showMessage)
}

export const DeleteProjectService: (
    pid: number | string,
    isForce: boolean,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, isForce, showMessage = false) => {
    return request(
        parseUrl(Apis.Delete, { pid, force: `force=${isForce}` }),
        'DELETE',
        undefined,
        undefined,
        showMessage
    )
}

export type SearchResourceType = {
    title: string
    primaryKey: number
}

export type SearchResponseType = {
    success: boolean
    data: SearchResourceType[]
}

export const SearchProjectsService: (
    pid: string | number | undefined | null,
    term: string,
    showMessage?: boolean
) => Promise<SearchResponseType> = async (pid, term, showMessage = false) => {
    return request(parseUrl(Apis.Search, { term }), 'GET', undefined, undefined, showMessage)
}

export type ProjectRoleWithPermissionType = {
    primaryKey: number
    roleName: string
    roleDescription: string
    projectRolePermissions: string[]
}

type GetProjectPermissionsResponseType = {
    success: boolean
    data: ProjectRoleWithPermissionType[]
}

export const GetProjectPermissionsService: () => Promise<GetProjectPermissionsResponseType> = async () => {
    return request(Apis.GetAllPermissions, 'GET')
}

export type AccessCondition = {
    field: string
    values: string[]
}

export type ProjectDataAcessType = {
    primaryKey: number
    title: string
    deIdentified: boolean
    accessConditions: Array<AccessCondition>
}

export type GetProjectDataAccessResponseType = {
    success: boolean
    data: ProjectDataAcessType[]
}

export const GetProjectDataAccess: (
    pid: number | string,
    showMessage?: boolean
) => Promise<GetProjectDataAccessResponseType> = async (pid, showMessage = false) => {
    return request(parseUrl(Apis.GetProjectDataAccess, { pid }), 'GET', undefined, undefined, showMessage)
}

export const AddProjectDataAccess: (
    pid: number | string,
    dataAccess: ProjectDataAcessType,
    showMessage?: boolean
) => Promise<GetProjectDataAccessResponseType> = async (pid, dataAccess, showMessage = false) => {
    return request(parseUrl(Apis.AddProjectDataAccess, { pid }), 'POST', undefined, dataAccess, showMessage)
}

export const AddProjectDataAccessBatchService: (
    pid: number | string,
    dataAccess: ProjectDataAcessType[],
    showMessage?: boolean
) => Promise<GetProjectDataAccessResponseType> = async (pid, dataAccess, showMessage = false) => {
    return request(parseUrl(Apis.AddProjectDataAccessBatch, { pid }), 'POST', undefined, dataAccess, showMessage)
}

export const DeleteProjectDataAccess: (
    pid: number | string,
    aid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, aid, showMessage = false) => {
    return request(parseUrl(Apis.DeleteProjectDataAccess, { pid, aid }), 'DELETE', undefined, undefined, showMessage)
}

export const UpdateProjectDataAccess: (
    pid: number | string,
    aid: number | string,
    dataAccess: ProjectDataAcessType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, aid, dataAccess, showMessage = false) => {
    return request(parseUrl(Apis.UpdateProjectDataAccess, { pid, aid }), 'PUT', undefined, dataAccess, showMessage)
}
