import Color from 'color'
var randomColor = require('randomcolor') // import the script

function getRandomColor() {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export const generateRandomColor = (background?: string, exclude?: string[]) => {
    let contrast = 1.1
    let failedAttempts = 0
    while (true) {
        const color = Color(getRandomColor())
        if (background) {
            if (color.contrast(Color(background)) < contrast) {
                continue
            }
        }
        if (failedAttempts < 1000 && exclude) {
            let exit = false
            for (let c of exclude) {
                console.log(`%c ${color.hex()}`, `background: ${color.hex()}; color: #ffffff`)
                console.log(`%c ${c}`, `background: ${c}; color: #ffffff`)
                console.log(color.contrast(Color(c)))
                if (color.contrast(Color(c)) < contrast) {
                    failedAttempts++
                    exit = true
                    continue
                }
            }
            if (exit) continue
        }

        return color.hexa()
    }
}

export const generateRandomColors = (n: number, theme?: 'light' | 'dark') => {
    return randomColor({
        count: n,
        luminosity: theme || 'random',
        hue: 'random',
    }).map((x: string) => x + 'ff')
}
