import { ClassStatType } from 'pages/allocation/Allocation.route'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
    Paper,
    Typography,
    Card,
    CardContent,
    Stack,
    Divider,
    Tooltip,
    List,
    ListItemText,
    ListItemButton,
    Icon,
    SxProps,
    alpha,
    Chip,
    Grid,
    Box,
} from '@mui/material'
import { ThumbDown, ThumbUp, Warning, Info } from '@mui/icons-material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

type ClassAllocationBoardProps = {
    classesData: Record<string, ClassStatType>
    changeGroup: (nodeId: string, from: string, to: string) => void
}

const ClassAllocationBoard = ({ classesData, changeGroup }: ClassAllocationBoardProps) => {
    const handleDrop = (student: ClassStatType['students']['string'], className: string) => {
        changeGroup(student.id, student.className, className)
    }

    const [selectedStudent, setSelectedStudent] = React.useState<ClassStatType['students']['string'] | null>(null)

    return (
        <DndProvider backend={HTML5Backend}>
            <Stack direction="row" spacing={2} sx={{ whiteSpace: 'nowrap', height: '100%', p: 1 }}>
                {Object.entries(classesData).map(([className, classData]) => (
                    <ClassColumn
                        key={className}
                        className={className}
                        selectedStudent={selectedStudent}
                        onSelect={setSelectedStudent}
                        classStats={classData}
                        onDrop={handleDrop}
                    />
                ))}
            </Stack>
        </DndProvider>
    )
}

type ClassColumnProps = {
    className: string
    classStats: ClassStatType
    onSelect?: (student: ClassStatType['students']['string']) => void
    selectedStudent: ClassStatType['students']['string'] | null
    onDrop: (student: any, className: string) => void
}

const ClassColumn = ({ className, onSelect, selectedStudent, classStats, onDrop }: ClassColumnProps) => {
    const [, ref] = useDrop({
        accept: 'STUDENT',
        drop: (item: any) => {
            onDrop(item.student, className)
        },
    })

    const getStudentItemStyle = (id: string): SxProps => {
        const defaultTextStyle = {
            color: 'text.primary',
        }

        if (selectedStudent) {
            // selected student
            if (selectedStudent.id === id) {
                return {
                    backgroundColor: alpha('#3f51b5', 0.2), // Muted blue
                    '&:hover': {
                        backgroundColor: alpha('#3f51b5', 0.4),
                    },
                    ...defaultTextStyle,
                }
            } else if (selectedStudent.connections[id]) {
                switch (selectedStudent.connections[id]) {
                    case 'positive':
                        return {
                            backgroundColor: alpha('#4caf50', 0.2), // Muted green
                            '&:hover': {
                                backgroundColor: alpha('#4caf50', 0.4),
                            },
                            ...defaultTextStyle,
                        }
                    case 'negative':
                        return {
                            backgroundColor: alpha('#f44336', 0.2), // Muted red
                            '&:hover': {
                                backgroundColor: alpha('#f44336', 0.4),
                            },
                            color: 'white',
                        }
                    case 'neutral':
                        return {
                            backgroundColor: alpha('#ffeb3b', 0.6), // Muted yellow
                            '&:hover': {
                                backgroundColor: alpha('#ffeb3b', 0.8),
                            },
                            ...defaultTextStyle,
                        }
                    default:
                        return defaultTextStyle
                }
            }
        }
        return defaultTextStyle
    }

    const evaluateDiversityForField = (students: ClassStatType['students'], field: string) => {
        let quarterCounts: Record<string, number> = {
            Q0: 0,
            Q1: 0,
            Q2: 0,
            Q3: 0,
        }

        Object.values(students).forEach((student) => {
            if (student.diversity[field]) {
                quarterCounts[student.diversity[field].quarter]++
            }
        })

        const totalStudents = Object.values(students).length
        let message = ''
        let icon = <Info />

        // Check conditions for diversity evaluation
        if (quarterCounts.Q0 > totalStudents * 0.25 && quarterCounts.Q1 > totalStudents * 0.25) {
            message = 'Diversity is shifted towards the lower end.'
            icon = <ThumbDown />
        } else if (quarterCounts.Q2 > totalStudents * 0.25 && quarterCounts.Q3 > totalStudents * 0.25) {
            message = 'Diversity is shifted towards the higher end.'
            icon = <ThumbDown />
        } else if (quarterCounts.Q1 > totalStudents * 0.25 && quarterCounts.Q2 > totalStudents * 0.25) {
            message = 'Diversity is medium.'
            icon = <Info />
        } else {
            message = 'good diversity.'
            icon = <ThumbUp />
        }

        return (
            <Tooltip title={message} key={field}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Icon color="action">{icon}</Icon>
                    <Typography variant="body2" sx={{ ml: 1 }}>
                        {field}
                    </Typography>
                </Box>
            </Tooltip>
        )
    }

    const getDiversityEvaluation = (students: ClassStatType['students']) => {
        const fields = Object.keys(Object.values(students)[0]?.diversity || {})
        const evaluations: any[] = []

        fields.forEach((field) => {
            evaluations.push(evaluateDiversityForField(students, field))
        })

        return evaluations
    }

    return (
        <Paper
            elevation={3}
            style={{ padding: '16px', minWidth: '250px', height: '100%', overflowY: 'auto' }}
            className="u-scrollbar"
            ref={ref}
        >
            <Card variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Class {Number.parseInt(className) + 1}
                    </Typography>
                    {/* <Typography variant="body2">Size: {Object.values(classStats.students).length}</Typography> */}
                    <Typography variant="body2">
                        Isolated Students:{' '}
                        {Object.values(classStats.students).filter((x) => x.positiveTies === 0).length}
                    </Typography>
                    <Typography variant="body2">
                        Negative Relations:{' '}
                        {Object.values(classStats.students).filter((x) => x.negativeTies > 0).length}
                    </Typography>
                    {getDiversityEvaluation(classStats.students)}
                </CardContent>
            </Card>
            <Typography>{selectedStudent?.name}</Typography>
            <Divider sx={{ mb: 2 }} />
            <List dense>
                {Object.entries(classStats.students).map(([studentId, student]) => (
                    <React.Fragment key={student.name + selectedStudent?.id}>
                        <StudentCard student={student} onSelect={onSelect} sx={getStudentItemStyle(student.id)} />
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </Paper>
    )
}

type StudentTooltipProps = {
    student: ClassStatType['students']['string']
}

const StudentTooltip = ({ student }: StudentTooltipProps) => {
    return (
        <Card elevation={3}>
            <CardContent>
                <Typography variant="h6">{student.name}</Typography>
                <Typography variant="body2">Class: {student.className}</Typography>
                <Typography variant="body2">Positive ties: {student.positiveTies}</Typography>
                <Typography variant="body2">Negative ties: {student.negativeTies}</Typography>
                {Object.entries(student.diversity).map(([field, data]) => (
                    <Typography key={field} variant="body2">
                        {field}: {data.value} (Q: {data.quarter})
                    </Typography>
                ))}
                {Object.entries(student.network).map(([network, value]) => (
                    <Typography key={network} variant="body2">
                        {network}: {value}
                    </Typography>
                ))}
            </CardContent>
        </Card>
    )
}

type StudentCardProps = {
    student: ClassStatType['students']['string']
    onSelect?: (student: ClassStatType['students']['string']) => void
    sx?: SxProps
}

const PositiveTiesIcon = ({ ties }: { ties: number }) => {
    if (ties === 0) {
        return (
            <Tooltip title="Isolated">
                <Icon color="warning">
                    <Warning />
                </Icon>
            </Tooltip>
        )
    } else if (ties > 2) {
        return (
            <Tooltip title="Well-connected">
                <Icon color="primary">
                    <ThumbUp />
                </Icon>
            </Tooltip>
        )
    }
    return null
}

const NegativeTiesIcon = ({ ties }: { ties: number }) => {
    if (ties > 0) {
        return (
            <Tooltip title="Has negative ties">
                <Icon color="error">
                    <ThumbDown />
                </Icon>
            </Tooltip>
        )
    }
    return null
}

const StudentCard = ({ student, sx, onSelect }: StudentCardProps) => {
    const [, ref] = useDrag({
        type: 'STUDENT',
        item: { student },
    })

    return (
        <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            key={student.name}
            title={<StudentTooltip student={student} />}
        >
            <ListItemButton ref={ref} sx={{ ...sx }} onClick={(e) => onSelect && onSelect(student)}>
                <Stack>
                    <Grid2 container gap={1}>
                        <Grid2>
                            <ListItemText primary={student.name} />
                        </Grid2>
                        {/* Add icons or chips here if needed */}
                        <Grid2>
                            <PositiveTiesIcon ties={student.positiveTies} />
                        </Grid2>
                        <Grid2>
                            <NegativeTiesIcon ties={student.negativeTies} />
                        </Grid2>
                    </Grid2>
                    <Grid2 container gap={1}>
                        {Object.entries(student.diversity).map(([field, data]) => (
                            <Grid2 key={field}>
                                <Tooltip title={field}>
                                    <Chip label={data.quarter} />
                                </Tooltip>
                            </Grid2>
                        ))}
                    </Grid2>
                </Stack>
            </ListItemButton>
        </Tooltip>
    )
}

export default ClassAllocationBoard
