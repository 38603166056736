import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

type ScrollingSubtitleProps = {
    subtitle: string
    isPlaying: boolean
}

const ScrollingSubtitle = ({ subtitle, isPlaying }: ScrollingSubtitleProps) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0)
    const [words, setWords] = useState<string[]>([])

    useEffect(() => {
        if (subtitle) {
            setWords(subtitle.split(' '))
            setCurrentWordIndex(0)
        }
    }, [subtitle])

    const getWordInterval = (word: string) => {
        // Adjust the interval calculation based on word length
        const baseInterval = 300 // Base interval in milliseconds
        const lengthFactor = 10 // Additional time per character
        return baseInterval + word.length * lengthFactor
    }

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        const displayNextWord = (index: number) => {
            if (index < words.length) {
                setCurrentWordIndex(index)
                timeoutId = setTimeout(() => displayNextWord(index + 1), getWordInterval(words[index]))
            }
        }

        if (isPlaying && words.length > 0) {
            displayNextWord(0)
        }

        return () => clearTimeout(timeoutId)
    }, [isPlaying, words])

    return (
        <>
            {isPlaying && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: '25%',
                        padding: 2,
                        width: '50%',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 0.4,
                            width: '100%',
                        }}
                    >
                        {words.slice(0, currentWordIndex + 1).map((word, index) => (
                            <Typography
                                key={index}
                                variant="body1"
                                align="center"
                                sx={{
                                    textAlign: 'center',
                                    animation: 'scroll-up 1s linear',
                                    opacity: index === currentWordIndex ? 1 : 0.5,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {word}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    )
}

export default ScrollingSubtitle
