import { useState, useCallback, RefObject } from 'react'
import useResizeObserver, { ObservedSize } from 'use-resize-observer'
import { debounce } from 'lodash'

type Props = {
    ref: RefObject<HTMLElement> | undefined
    wait: number
    roundingFunction?: (number: number) => number
    onResizeStart?: () => void
    onResizeEnd?: () => void
}

/*
 * Notes:
 *  1.  Invalid values to check for in the using component are:
 *      - "null" for the whole return value.
 *      - Either width or height properties be "-1".
 */
function useDebouncedResizeObserver({ ref, wait, roundingFunction, onResizeStart, onResizeEnd }: Props) {
    const [elementSize, setElementSize] = useState<{ width: number; height: number } | null>(null)

    const debouncedResize = useCallback(
        debounce(
            (elementSize: ObservedSize) => {
                setElementSize({
                    width: elementSize.width !== undefined ? elementSize.width : -1,
                    height: elementSize.height !== undefined ? elementSize.height : -1,
                })

                if (onResizeEnd) {
                    onResizeEnd()
                }
            },
            wait,
            { trailing: true }
        ),
        [wait, onResizeEnd]
    )

    const onResize = useCallback(
        (size: ObservedSize) => {
            if (onResizeStart) {
                onResizeStart()
            }
            debouncedResize(size)
        },
        [debouncedResize, onResizeStart]
    )

    useResizeObserver({ ref, onResize, round: roundingFunction })

    return elementSize
}

export default useDebouncedResizeObserver
