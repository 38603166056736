import { Icon, IconProps } from '@mui/material'
import { useMemo } from 'react'

type DynamicIconProps = {
    icon: string
} & IconProps

const DynamicIcon = ({ icon, ...iconProps }: DynamicIconProps) => {
    const snakeCaseIcon = useMemo(() => {
        if (icon == null || icon === '') return ''
        return (
            icon
                // First, insert an underscore before each uppercase letter.
                .replace(/([A-Z])/g, '_$1')
                // Then, convert the entire string to lowercase.
                .toLowerCase()
                // Finally, remove any leading underscore, if it exists.
                .replace(/^_/, '')
        )
    }, [icon])

    return <Icon {...iconProps}>{snakeCaseIcon}</Icon>
}

export default DynamicIcon
