//* ======= Libraries
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm, useWatch } from 'react-hook-form'
//* ======= Components and features
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import TemplateCard from 'components/template-card/TemplateCard'
//* ======= Custom logic
import { CreateReport, ReportType } from 'services/ReportApi'
//* ======= Assets and styles

interface ICreateReportDialog {
    onConfirm?: (id: number) => void
    onClose?: () => void
}

function CreateReportDialog({ onConfirm, onClose }: ICreateReportDialog) {
    const { pid } = useParams()

    // const [reportTitle, setReportTitle] = useState('')

    // const selectOptions = [
    //     {
    //         name: 'Fixed population',
    //         value: 'fixed',
    //     },
    //     {
    //         name: 'Snow Ball',
    //         value: 'snowball',
    //     },
    // ]

    //form
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ReportType>()

    const reportTitle = useWatch({
        control,
        name: 'title',
    })

    const createReport = () => {
        if (!pid) return
        handleSubmit(
            //Valid
            (data) => {
                CreateReport(Number.parseInt(pid), { ...data, state: null, projectID: Number.parseInt(pid) })
                    .then((res) => {
                        if (res.success && onConfirm && res.data) {
                            onConfirm(res.data)
                        }
                    })
                    .catch((e) => {})
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    // const setImage = (data: string) => {
    //     setValue('thumbnail', data);
    // }

    return (
        <Grid container>
            {/* Sidebar
				========================================= */}
            <Grid
                item
                xs={3}
                zeroMinWidth
                container
                direction="column"
                alignItems="flex-start"
                rowGap={2}
                sx={(theme) => ({
                    padding: 3,

                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light'
                            ? theme.palette.common.ung_blue_one
                            : theme.palette.common.bg_4,
                })}
            >
                <Typography fontSize={18} fontWeight={600} color="secondary">
                    Create new report
                </Typography>

                {/* Template card
				    ========================================= */}
                <TemplateCard
                    data={{
                        id: -1,
                        title: reportTitle,
                        // image: watch("thumbnail")
                    }}
                    hasMenuOnBoxed={false}
                />

                {/* Add profile image button
				    ========================================= */}
                <BaseButton
                    variant="outlined"
                    onClick={() => {
                        document.getElementById('select-profile-image')?.click()
                    }}
                >
                    Upload Image
                </BaseButton>
                {/* <PickCustomImage setCustomImage={setImage} circularCrop={false} /> */}
            </Grid>

            {/* Main content
				========================================= */}
            <Grid
                item
                xs={9}
                zeroMinWidth
                container
                direction="column"
                alignItems="flex-start"
                rowGap={2}
                sx={(theme) => ({
                    padding: 3,
                })}
            >
                {/* Report title
				    ========================================= */}
                <Typography fontSize={16} fontWeight={400} color="secondary" mb={0.5}>
                    Report Title
                </Typography>
                <Grid item zeroMinWidth container columnGap={6} mb={1.5}>
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="title"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseFilledTextField
                                    label="Report Title"
                                    required
                                    variant="filled"
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{
                                required: 'Report Title is required',
                                minLength: { value: 3, message: 'The title should be at least 3 characters' },
                                maxLength: {
                                    value: 50,
                                    message: 'The should not be more than 50 characters',
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Confirm and close buttons
				    ========================================= */}
                <Stack
                    alignSelf="flex-end"
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={3}
                >
                    <BaseButton
                        variant="outlined"
                        size="large"
                        label="Close"
                        onClick={(e) => onClose && onClose()}
                        sx={{
                            width: 180,
                            py: 1.5,
                        }}
                    />

                    <BaseButton
                        variant="contained"
                        size="large"
                        label="Create"
                        onClick={() => createReport()}
                        // endIcon={<ChevronRightIcon />}
                        sx={{
                            width: 180,
                            py: 1.5,
                        }}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default CreateReportDialog
