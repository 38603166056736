import moment from 'moment'

export const formatValue = (value: any, precision = 6) => {
    if (typeof value === 'number') {
        return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
    }

    if (typeof value === 'object' || Array.isArray(value)) {
        return JSON.stringify(value)
    }

    if (value instanceof Date) {
        const momentString = moment(value).format('DD/MM/YYYY')

        return momentString.replaceAll('/', '.')
    }

    return value
}

export const formatLabel = (data: string | number) => {
    if (data == null) return 'Unknown'

    try {
        const dataArray = data
            .toString()
            .replaceAll('#', '')
            .split(/[.,/\s-_]/)

        return dataArray
            .map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1)
            })
            .join(' ')
    } catch {
        return data.toString()
    }
}

export const formatFileName = (data: string) => {
    if (data == null) return 'Unkown'

    //remove extension

    let result = data

    const dotIndex = data.lastIndexOf('.')
    if (dotIndex !== -1) {
        result = data.substring(0, dotIndex)
    }

    return formatLabel(result)
}

export const buildAbbreviation = (title: String) => {
    if (title) {
        const items = title.split(/\s+/)
        let res = ''
        for (let item of items) {
            if (item && item.length > 0) {
                res += item.substring(0, 1)
            }
        }
        return res.toUpperCase()
    }
    return null
}
