//* ======= Libraries
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import PasswordField from 'components/PasswordField/PasswordField'
import SignInLayout from 'pages/sign-in/SignInLayout'
import LoginOptions from 'features/sign-in/LoginOptions'
//* ======= Custom logic
//* ======= Assets and styles
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { RootContext } from 'contexts/RootContext'
import { AuthMethod } from 'services/AuthenticationService'

interface ISignIn {}

function SignIn({}: ISignIn) {
    const { onLogin } = useContext(RootContext)
    const navigate = useNavigate()

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isRemember, setIsRemember] = useState<boolean>(false)
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false)
    const [avatar, setAvatar] = useState<string>()

    // Focus the fields
    useEffect(() => {
        document.getElementById('focus-target-field')?.focus()
    }, [isEmailConfirmed])

    const getAuthenticationMethod = () => {
        AuthMethod({
            username,
        }).then((result) => {
            if (result.success) {
                switch (result.data.method) {
                    case 'password':
                        setAvatar(result.data.avatar)
                        setIsEmailConfirmed(true)
                        break
                    case 'saml':
                        if (result.data.url) window.location.href = result.data.url
                        break
                    default:
                        break
                }
            }
        })
    }

    const signinUser = () => {
        onLogin({ userEmail: username, password })
    }

    const isCreateAccountDisabled = true

    return (
        <SignInLayout>
            {/* Main content
				========================================= */}
            <Stack gridRow={2} width="40%">
                {/* Title
					========================================= */}
                <Typography fontSize={36} fontWeight={400} color="common.text_1" mb={1}>
                    Sign In
                </Typography>

                {/* Create account/user email
					========================================= */}
                {isEmailConfirmed ? (
                    /*	Avatar and email
                        ========================================= */
                    <React.Fragment>
                        <Stack direction="row" alignItems="center" gap={1} marginBottom={1}>
                            <Avatar
                                src={avatar}
                                sx={{
                                    height: 45,
                                    width: 45,
                                }}
                            ></Avatar>

                            <Typography fontSize={16} fontWeight={400} noWrap color="common.text_1">
                                {username}
                            </Typography>
                        </Stack>
                        <BaseButton
                            onClick={() => {
                                setIsEmailConfirmed(false)
                                setAvatar(undefined)
                                setUsername('')
                                setPassword('')
                            }}
                            sx={{ alignSelf: 'start' }}
                            startIcon={<ArrowBackIcon />}
                        >
                            Sign as someone else
                        </BaseButton>
                    </React.Fragment>
                ) : (
                    !isCreateAccountDisabled && (
                        /*	Create account link
                        ========================================= */
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                            marginBottom={1}
                        >
                            <Typography fontSize={16} fontWeight={400} color="common.text_1">
                                New user?
                            </Typography>

                            <BaseButton
                                label="Create Account"
                                onClick={(evt) => navigate('/create-account')}
                                sx={{
                                    fontSize: 24,
                                    fontWeight: 700,
                                }}
                            />
                        </Stack>
                    )
                )}

                <Divider
                    sx={{
                        marginBottom: 3,

                        borderColor: 'common.border_1',
                    }}
                />

                {/* Email/password fields
					========================================= */}
                {isEmailConfirmed ? (
                    /*	Password field
                        ========================================= */
                    <PasswordField
                        id="focus-target-field"
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' && (evt.target as HTMLInputElement).value.trim() !== '') {
                                signinUser()
                            }
                        }}
                        sx={{
                            marginBottom: 1.5,
                        }}
                    />
                ) : (
                    /*	Email field
                        ========================================= */
                    <BaseFilledTextField
                        id="focus-target-field"
                        type="email"
                        label="Work Email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' && (evt.target as HTMLInputElement).value.trim() !== '') {
                                getAuthenticationMethod()
                            }
                        }}
                        sx={{
                            marginBottom: 1.5,
                        }}
                    />
                )}

                {/* Forgot password and remember me
					========================================= */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                    marginBottom={2}
                >
                    {/* Forgot password button
						========================================= */}
                    <BaseButton
                        label="Forgot Password"
                        onClick={(evt) => navigate('/forgot-password')}
                        sx={{
                            fontSize: 14,
                            fontWeight: 400,
                        }}
                    />

                    {/* Remember me checkbox
						========================================= */}
                    <FormGroup>
                        <FormControlLabel
                            label="Remember Me"
                            control={<Checkbox />}
                            labelPlacement="start"
                            sx={{
                                alignSelf: 'flex-start',

                                marginLeft: 0,
                            }}
                        />
                    </FormGroup>
                </Stack>

                {/* Next button
					========================================= */}
                <BaseButton
                    label={isEmailConfirmed ? 'Sign In' : 'Next'}
                    onClick={(evt) => {
                        if (isEmailConfirmed) {
                            signinUser()
                        } else {
                            getAuthenticationMethod()
                        }
                    }}
                    variant="contained"
                    sx={{
                        marginBottom: 3,
                        py: 2,
                    }}
                />

                {/* <Typography fontSize={16} fontWeight={400} color="common.text_1" mb={2}>
                    Or sign in with:
                </Typography> */}

                {/* Other sign in options
					========================================= */}
                {/* <Box>
                    <LoginOptions />
                </Box> */}
            </Stack>
        </SignInLayout>
    )
}

export default SignIn
