//* ======= Libraries
import React, { useContext, useEffect, useState } from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDialog from 'components/dialog/StyledDialog'
import PhoneInput from 'components/phone-input/PhoneInput'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import CountryCodesIndexed from 'assets/json/CountryCode'
import { UpdateAccountService, UpdaterAccountRequestType } from 'services/AuthenticationService'
import { Controller, useForm } from 'react-hook-form'
import ProfileImageUploadDialog from 'features/sign-in/ProfileImageUploadDialog'
import { MOBILE_REGEX } from 'helpers/validators'
import { RootContext } from 'contexts/RootContext'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { toast } from 'react-toastify'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'

const COUNTRIES = Object.keys(CountryCodesIndexed.byName)

interface ISettingsAccount {}

function SettingsAccount({}: ISettingsAccount) {
    const { user, getUser } = useContext(RootContext)
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
    const [uploadedImage, setUploadedImage] = useState(TempAvatar)

    const {
        control,
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdaterAccountRequestType>({
        mode: 'onBlur',
    })

    useEffect(() => {
        if (user) {
            setValue('name', user.name || '')
            setValue('avatar', user.avatar)
            if (user.avatar) {
                setUploadedImage(user.avatar)
            }
            setValue('company', user.company)
            setValue('country', user.country)
            setValue('phoneNumber', user.phoneNumber)
            setValue('position', user.position)
        }
    }, [user])

    const submitForm = () => {
        handleSubmit(
            //Valid
            (data) => {
                UpdateAccountService(data).then((res) => {
                    if (res.success) {
                        toast.success('Account Updated.')
                        getUser()
                    }
                })
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    const onProfileImageSelected = (filePath: string) => {
        setUploadedImage(filePath)
        setValue('avatar', filePath)
        setIsUploadDialogOpen(false)
    }

    return (
        <Stack gap={2} width="100%" maxWidth={400}>
            {user == null ? (
                <SnaCircularLoading />
            ) : (
                <React.Fragment>
                    {/* Profile image upload
				        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                        <Avatar
                            src={uploadedImage}
                            alt="avatar"
                            variant="circular"
                            sx={{
                                height: 60,
                                width: 60,
                            }}
                        />

                        <BaseButton
                            label="Edit Profile Image"
                            onClick={(evt) => setIsUploadDialogOpen(true)}
                        />
                    </Stack>

                    {/* Fullname
				        ========================================= */}
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <BaseFilledTextField
                                label="Fullname"
                                value={value}
                                fullWidth
                                required
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{
                            required: 'Fullname is required',
                            minLength: {
                                value: 2,
                                message: 'Fullname must be at least 2 characters',
                            },
                            pattern: {
                                message: 'Not a valid fullname. Fullname name must contain only letters',
                                value: /^(?=.*[a-z][a-z])[\s a-z ,.'-]+$/i,
                            },
                        }}
                    />

                    {/* Company name
				        ========================================= */}
                    <Controller
                        name="company"
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <BaseFilledTextField
                                label="Company Name"
                                value={value}
                                fullWidth
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{
                            minLength: {
                                value: 2,
                                message: 'Company name must be at least 2 characters',
                            },
                            pattern: {
                                message: 'Not a valid Company name. Fullname name must contain only letters',
                                value: /^(?=.*[a-z][a-z])[\s a-z ,.'-]+$/i,
                            },
                        }}
                    />

                    {/* Role
				        ========================================= */}
                    <Controller
                        name="position"
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <BaseFilledTextField
                                label="What's your role?"
                                value={value}
                                fullWidth
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />

                    {/* Country/Region
				        ========================================= */}
                    <Controller
                        name="country"
                        defaultValue={user?.country}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <BaseSelectWithLabel
                                label="Country"
                                options={COUNTRIES}
                                placeholder="Select"
                                value={value}
                                fullWidth
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? error.message : undefined}
                            />
                        )}
                    />

                    {/* <Stack gap={1} marginBottom={2.5}>
                            <Typography alignSelf="flex-start" fontSize={16} fontWeight={400}>
                                What is your role?
                            </Typography>
                            <FreeSoloCreateOption
                                label='What is your role?'
                                options={POSITIONS.map(p => ({ title: p }))} value={{ title: profile.position || '' }}
                                setValue={(v) => setProfileField('position')(v)} />
                        </Stack> */}

                    {/* Country and number
				        ========================================= */}
                    <Stack gap={1} marginBottom={6}>
                        <Typography alignSelf="flex-start" fontSize={16} fontWeight={400}>
                            Add your phone number
                        </Typography>

                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                // <BaseFilledTextField
                                //     label="Phone number"
                                //     value={value}
                                //     fullWidth
                                //     onChange={onChange}
                                //     onBlur={onBlur}
                                //     error={!!error}
                                //     helperText={error ? error.message : '+xx xxx xxx-xxx'}
                                // />
                                <PhoneInput
                                    label="Phone number"
                                    value={value}
                                    onChange={onChange}
                                    required={false}
                                    onBlur={onBlur}
                                    error={!!error}
                                    helperText={error ? error.message : '+xx xxx xxx-xxx'}
                                    fullWidth
                                />
                            )}
                            rules={{
                                pattern: {
                                    message: 'Not a valid mobile number',
                                    value: MOBILE_REGEX,
                                },
                            }}
                        />
                    </Stack>

                    {/* Save button
				        ========================================= */}
                    <BaseButton
                        label="Save"
                        variant="contained"
                        onClick={submitForm}
                        size="large"
                        sx={{
                            py: 1.5,
                        }}
                    />
                    {/* Profile image selection dialog
				        ========================================= */}
                    {isUploadDialogOpen && (
                        <StyledDialog
                            open={isUploadDialogOpen}
                            onClose={() => setIsUploadDialogOpen(false)}
                            fullWidth
                            maxWidth="sm"
                        >
                            <ProfileImageUploadDialog onImageSelect={onProfileImageSelected} />
                        </StyledDialog>
                    )}
                </React.Fragment>
            )}
        </Stack>
    )
}

export default SettingsAccount
