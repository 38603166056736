import React, { useEffect, useMemo, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Divider, Stack, Switch, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledDialog from 'components/dialog/StyledDialog'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import { InsightWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'

type AdvancedInsightCompareConfigDialogProps = {
    open: boolean
    onClose: () => void
    dataSourceFields: FlexibleSelectOptionType[]
    config: InsightWidgetType['interventionConfig']['compareConfig']
    onSave: (config: InsightWidgetType['interventionConfig']['compareConfig']) => void
}

const AdvancedInsightCompareConfigDialog = ({
    open,
    onClose,
    config,
    dataSourceFields,
    onSave,
}: AdvancedInsightCompareConfigDialogProps) => {
    const [internalConfig, setInternalConfig] =
        useState<InsightWidgetType['interventionConfig']['compareConfig']>(config)

    useEffect(() => {
        if (open) setInternalConfig(config)
    }, [open])

    const addCompareConfig = () => {
        setInternalConfig((prev) => [
            ...prev,
            {
                attribute: null,
                mode: 'text',
                label: '',
                normalize: false,
            },
        ])
    }

    const removeCompareConfig = (index: number) => {
        setInternalConfig((prev) => prev.filter((_, i) => i !== index))
    }

    const changeCompareConfigMode = (index: number, mode: 'text' | 'number') => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    if (item.mode === mode) return item
                    if (mode === 'text')
                        return {
                            attribute: item.attribute,
                            mode: 'text',
                            label: item.label,
                            normalize: item.normalize,
                        }
                    return {
                        attribute: item.attribute,
                        mode: 'number',
                        label: item.label,
                        biningMethod: 'quantile',
                        normalize: item.normalize,
                    }
                }
                return item
            })
        )
    }

    const changeCompareConfigAttribute = (
        index: number,
        attribute: InsightWidgetType['interventionConfig']['compareConfig'][number]['attribute']
    ) => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        attribute,
                        label: attribute?.field ?? '',
                    }
                }
                return item
            })
        )
    }

    const changeCompareConfigLabel = (index: number, label: string) => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        label,
                    }
                }
                return item
            })
        )
    }

    const changeCompareConfigNormalize = (index: number, normalize: boolean) => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        normalize,
                    }
                }
                return item
            })
        )
    }

    const changeCompareConfigBiningMethod = (
        index: number,
        biningMethod: Extract<
            InsightWidgetType['interventionConfig']['compareConfig'][number],
            { mode: 'number' }
        >['biningMethod']
    ) => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    if (item.mode === 'number') {
                        return {
                            ...item,
                            biningMethod,
                        }
                    }
                }
                return item
            })
        )
    }

    const addCustomBin = (index: number) => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    if (item.mode === 'number' && item.biningMethod === 'custom') {
                        const customBins = item.customBins ?? []
                        return {
                            ...item,
                            customBins: [
                                ...customBins,
                                {
                                    min: 0,
                                    max: 0,
                                    label: '',
                                },
                            ],
                        }
                    }
                }
                return item
            })
        )
    }

    const removeCustomBin = (index: number, binIndex: number) => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    if (item.mode === 'number' && item.biningMethod === 'custom') {
                        return {
                            ...item,
                            customBins: item.customBins?.filter((_, j) => j !== binIndex),
                        }
                    }
                }
                return item
            })
        )
    }

    const changeCustomBin = (
        index: number,
        binIndex: number,
        value: Partial<{
            min: number
            max: number
            label: string
        }>
    ) => {
        setInternalConfig((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    if (item.mode === 'number' && item.biningMethod === 'custom') {
                        return {
                            ...item,
                            customBins: item.customBins?.map((bin, j) => (j === binIndex ? { ...bin, ...value } : bin)),
                        }
                    }
                }
                return item
            })
        )
    }

    const isItemValid = (config: (typeof internalConfig)[number]) => {
        if (config.mode === 'number' && config.biningMethod === 'custom' && config.customBins) {
            return config.customBins.every((bin) => bin.min <= bin.max && bin.label.trim() !== '')
        }
        if (config.attribute === null || config.label.trim() === '') return false
        return true
    }

    const isAllItemsValid = useMemo(() => internalConfig.every(isItemValid), [internalConfig])

    return (
        <StyledDialog open={open} maxWidth="md" disableEscapeKeyDown fullWidth>
            <DialogTitle>
                <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Compare Configuration</Typography>
                    <BaseButton variant="text" color="primary" onClick={addCompareConfig}>
                        Add
                    </BaseButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" gap={2}>
                    {internalConfig.map((config, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Stack direction="row" gap={1}>
                                    <Stack direction="column" gap={1}>
                                        {isItemValid(config) ? null : (
                                            <Stack direction="row" gap={1}>
                                                <DynamicIcon icon="error" color="error" />
                                                <Typography color="error">Invalid Configuration</Typography>
                                            </Stack>
                                        )}
                                        <Stack key={index} direction="row" gap={1}>
                                            <FlexibleSelect
                                                hiddenLabel={true}
                                                options={dataSourceFields}
                                                value={config.attribute}
                                                onChange={(value) => {
                                                    changeCompareConfigAttribute(index, value)
                                                }}
                                                fullWidth
                                                size="small"
                                                sx={{
                                                    height: '67px',
                                                }}
                                            />
                                            <BaseFilledTextField
                                                label="Label"
                                                value={config.label}
                                                onChange={(evt) => changeCompareConfigLabel(index, evt.target.value)}
                                                fullWidth
                                                inputProps={{
                                                    style: {
                                                        height: '32.5px',
                                                    },
                                                }}
                                            />
                                            <BaseSelectWithLabel
                                                label="Mode"
                                                size="small"
                                                options={[
                                                    { label: 'Text', value: 'text' },
                                                    { label: 'Number', value: 'number' },
                                                ]}
                                                selectProps={{
                                                    style: {
                                                        height: '67px',
                                                    },
                                                }}
                                                value={config.mode}
                                                onChange={(value) => changeCompareConfigMode(index, value)}
                                                sx={{
                                                    width: '380px',
                                                }}
                                            />
                                            <Stack direction="column" gap={1}>
                                                <Typography variant="caption">Normalize</Typography>
                                                <Switch
                                                    checked={config.normalize}
                                                    onChange={(e, checked) => {
                                                        changeCompareConfigNormalize(index, checked)
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <BaseButton
                                        variant="text"
                                        color="secondary"
                                        onClick={() => removeCompareConfig(index)}
                                    >
                                        Remove
                                    </BaseButton>
                                </Stack>
                                <Divider flexItem />
                            </React.Fragment>
                        )
                    })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} variant="outlined" color="secondary">
                    Cancel
                </BaseButton>
                <BaseButton
                    onClick={() => {
                        if (isAllItemsValid) {
                            onSave(internalConfig)
                        }
                    }}
                    disabled={!isAllItemsValid}
                    variant="contained"
                    color="primary"
                >
                    Apply
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default AdvancedInsightCompareConfigDialog
