export const buildAbbreviation = (title: String) => {
    if (title) {
        const items = title.split(/\s+/)
        let res = ''
        for (let item of items) {
            if (item && item.length > 0) {
                res += item.substring(0, 1)
            }
        }
        return res.toUpperCase()
    }
    return null
}
