import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ShowIcon from '@mui/icons-material/Visibility'
import { ReactComponent as ICON_CSV } from 'assets/icons/csv.svg'
import { NetworkVizContextType, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { NetworkItemIdType, NetworkSelectedItemType, NodeIdType } from 'features/network-viz/types/NetworkViz.types'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'

interface NetworkAnalyticsCumminutyInfoProps {
    analytics: NetworkVizContextType['analytics']
    mode: 'strongly_connected_components' | 'weakly_connected_components' | 'greedy_modularity_communities'
    title: string
    onClose?: () => void
}

export default function NetworkAnalyticsCumminutyInfo({
    title,
    onClose,
    analytics,
    mode,
}: NetworkAnalyticsCumminutyInfoProps) {
    const dispatchContext = useNetworkVizDispatch()
    const [relationship, setRelationship] = useState('view')
    //ToDo
    const highlightNodes = (nodes: NodeIdType[]) => {}

    const onSelectedNodeChange = (id: NetworkItemIdType) => {
        dispatchContext({ type: 'SELECTED_ITEM_CHANGE', payload: { mode: 'node', id } })
    }

    const handleClose = () => {
        highlightNodes([])
        onClose && onClose()
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                maxHeight: 450,
                width: 350,
                padding: theme.spacing(1.5, 2),
            })}
        >
            {/* Title and close button
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={600} color="primary">
                    {title}
                </Typography>

                <IconButton onClick={handleClose} size="small">
                    <CloseRoundedIcon fontSize="small" />
                </IconButton>
            </Stack>

            {/* Relationship selector
                ========================================= */}
            <BaseSelectWithLabel
                label="Relationship"
                value={relationship}
                size="small"
                onChange={(v) => setRelationship(v)}
                options={Object.keys(analytics || {})}
            />

            {/* Body
                ========================================= */}
            <NetworkAnalyticsCumminutyInfoTable
                data={analytics ? analytics[relationship]?.[mode] : {}}
                highlightNodes={highlightNodes}
                onSelectedNodeChange={onSelectedNodeChange}
            />
        </Stack>
    )
}

interface NetworkAnalyticsCumminutyInfoTableProps {
    data?: any
    onSelectedNodeChange?: (id: NetworkItemIdType) => void
    highlightNodes?: (nodes: NodeIdType[]) => void
}

export function NetworkAnalyticsCumminutyInfoTable({
    data,
    highlightNodes,
    onSelectedNodeChange,
}: NetworkAnalyticsCumminutyInfoTableProps) {
    const [show, setShow] = useState(false)
    const [componentIndex, setComponentIndex] = useState<number>(0)

    useEffect(() => {
        setComponentIndex(0)
    }, [data])

    useEffect(() => {
        if (show) {
            highlightNodes && highlightNodes(data[componentIndex])
        }
    }, [componentIndex])

    return (
        <Stack
            gap={1}
            sx={{
                flexGrow: 1,

                minHeight: 0,
            }}
        >
            {Array.isArray(data) && data.length > 0 ? (
                <>
                    {/* Prev/Next component buttons and name
                            ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                        {/* Prev component button
                                ========================================= */}
                        <IconButton onClick={() => setComponentIndex((c) => c + -1)} disabled={componentIndex === 0}>
                            <ChevronLeft fontSize="small" sx={{}} />
                        </IconButton>

                        {/* Component name
                                ========================================= */}
                        <Typography fontSize={14}>Component {componentIndex + 1}</Typography>

                        {/* Next component button
                                ========================================= */}
                        <IconButton
                            onClick={() => setComponentIndex((c) => c + 1)}
                            disabled={componentIndex === data.length - 1}
                        >
                            <ChevronRight fontSize="small" />
                        </IconButton>
                    </Stack>

                    {/* Table
                            ========================================= */}
                    <TableContainer
                        sx={{
                            flexGrow: 1,

                            overflow: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <Table
                            size="medium"
                            stickyHeader
                            padding={'none'}
                            sx={{
                                borderCollapse: 'separate',
                                borderSpacing: '0 8px',
                            }}
                        >
                            <TableHead>
                                <TableRow
                                    sx={{
                                        '& .MuiTableCell-head': {
                                            padding: 1,
                                            fontSize: 14,
                                            fontWeight: 500,

                                            backgroundColor: 'common.bg_3',
                                        },
                                    }}
                                >
                                    <TableCell align="center">
                                        <Typography noWrap>Attribute</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography noWrap>Value</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {data && (
                                <TableBody>
                                    {Object.keys(data[componentIndex]).map((k: string, index) => (
                                        <TableRow
                                            onClick={() => {
                                                onSelectedNodeChange && onSelectedNodeChange(data[componentIndex][k])
                                            }}
                                            key={k}
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.common.bg_3,

                                                '&:hover': {
                                                    backgroundColor: theme.palette.common.bg_4,
                                                },

                                                '& .MuiTableCell-body': {
                                                    maxWidth: 200,
                                                    padding: theme.spacing(1),

                                                    borderBottom: 0,

                                                    '&:first-of-type': {
                                                        borderTopLeftRadius: '10px',
                                                        borderBottomLeftRadius: '10px',
                                                    },

                                                    '&:last-of-type': {
                                                        borderTopRightRadius: '10px',
                                                        borderBottomRightRadius: '10px',
                                                    },
                                                },
                                            })}
                                        >
                                            <TableCell align="center">
                                                <Typography noWrap>Node</Typography>
                                            </TableCell>

                                            <TableCell align="center">
                                                <Typography noWrap>{data[componentIndex][k]}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Typography>No Data</Typography>
            )}

            {/* Bottom controls
                    ========================================= */}
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={1}
                sx={{
                    flexShrink: 0,
                }}
            >
                <IconButton
                    onClick={() => {
                        if (show) {
                            highlightNodes && highlightNodes([])
                        } else {
                            highlightNodes && highlightNodes(data[componentIndex])
                        }
                        setShow((pv) => !pv)
                    }}
                >
                    <ShowIcon color={show ? 'primary' : 'secondary'} />
                </IconButton>

                {/* <IconButton>
                        <AddAPhotoIcon />
                    </IconButton>

                    <IconButton>
                        <ICON_CSV />
                    </IconButton>

                    <IconButton>
                        <SettingsIcon />
                    </IconButton> */}
            </Stack>
        </Stack>
    )
}
