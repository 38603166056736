//* ======= Libraries
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import NewProjectStrip from 'features/general-dashboard/NewProjectStrip'
import ProjectsPanel from 'features/general-dashboard/ProjectsPanel'
import Stack from '@mui/material/Stack'
import { ResourceType } from 'types/resources'
import { useNavigate, useParams } from 'react-router-dom'
import { GetSurveysService, SurveyBasicType } from 'services/SurveyService'
import { ProjectContext } from 'contexts/ProjectContext'
import CreateSurveyDialog from 'features/survey-create-dialog/CreateSurveyDialog'
import NavigableDialog from 'components/dialog/NavigableDialog'
import Badge from '@mui/material/Badge'
import Chip from '@mui/material/Chip'
import moment from 'moment'
import { PERMISSIONS } from 'helpers/constants'
import DashboardSkeleton from './DashboardSkeleton'
//* ======= Custom logic
//* ======= Assets and styles

export interface ICreateResourceDialogStateType {
    title: string
    component: ReactNode
    maxWidth: 'lg' | 'xl'
    onNext?: (id?: number) => void
    onBack?: () => void
    disableBackButton?: boolean
}

interface IProjectDashboard {}

function ProjectDashboard({}: IProjectDashboard) {
    const navigator = useNavigate()
    const { pid } = useParams()
    const { project, drawerContent, hasPermission } = useContext(ProjectContext)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [surveys, setSurveys] = useState<ResourceType[]>([])
    const [surveyTags, setSurveyTags] = useState<string[]>([])
    const [templates, setTemplates] = useState<ResourceType[]>([])
    const [templatesTags, setTemplateTags] = useState<string[]>([])

    const parseProjects = (allProjects: Array<SurveyBasicType>, isTemplate = false) => {
        const canDelete = hasPermission(PERMISSIONS.survey.DeleteSurvey)
        const canEditTag = hasPermission(PERMISSIONS.survey.ManageSurveyTag)
        const canRename = hasPermission(PERMISSIONS.survey.ManipulateSurveyTitle)

        const tempProjects: ResourceType[] = allProjects
            .filter((tp) => (!isTemplate && !tp.isTemplate) || (isTemplate && tp.isTemplate))
            .map((s) => ({
                id: s.id || -1,
                title: s.title,
                image: s.thumbnail,
                creator: s.creator,
                favourite: s.favourite,
                info: [
                    {
                        label: 'Status',
                        value: `${s.status}`,
                    },
                    {
                        label: 'Participants',
                        value: `# ${s.participants}`,
                    },
                    {
                        label: 'Rules',
                        value: `# ${s.rules}`,
                    },
                    {
                        label: 'Created on',
                        value: `${`${moment(s.createdOn).format('DD-MM-YYYY HH:mm')}`}`,
                    },
                ],
                canDelete,
                canEditTag,
                canRename,
                link: `/project/${pid}/surveys/${s.id}`,
                rating: s.rating,
                tags: s.tags,
            }))

        let tempTags = new Set<string>()
        for (let p of tempProjects) {
            if (p.tags)
                for (let t of p.tags) {
                    t.key && tempTags.add(t.key)
                }
        }

        return {
            surveys: tempProjects,
            tags: Array.from(tempTags),
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [surveys])

    //fetch data
    useEffect(() => {
        //if not pid, do nothing
        if (pid == null) return
        setIsLoading(true)
        GetSurveysService(Number.parseInt(pid))
            .then((response) => {
                if (response.success) {
                    //////////////////get all surveys//////////////
                    const allProjects = parseProjects(response.data)
                    setSurveys(allProjects.surveys)
                    setSurveyTags(allProjects.tags)
                    //////////////////get all templates//////////////
                    const allTemplates = parseProjects(response.data, true)
                    setTemplates(allTemplates.surveys)
                    setTemplateTags(allTemplates.tags)
                }
            })
            .catch((error) => {})
    }, [pid])

    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
    const [createDialogStep, setCreateDialogStep] = useState<number>(0)

    const handleCloseCreateDialog = () => setCreateDialogOpen(false)
    const handleOpenCreateDialog = () => setCreateDialogOpen(true)

    const CreateSurveyDialogs: ICreateResourceDialogStateType[] = [
        {
            title: 'Create Survey',
            disableBackButton: true,
            maxWidth: 'lg',
            component: (
                <CreateSurveyDialog
                    onClose={handleCloseCreateDialog}
                    onConfirm={(id?: number) => {
                        if (id) navigator('' + id)
                        handleCloseCreateDialog()
                    }}
                />
            ),
        },
    ]

    const dialogState = useMemo<ICreateResourceDialogStateType>(() => {
        return CreateSurveyDialogs[createDialogStep]
    }, [createDialogStep])

    if (project == null) return <DashboardSkeleton />

    const canCreateSurvey = hasPermission(PERMISSIONS.survey.CreateSurvey)

    return (
        <PageCard>
            <PageCard.Side>{project?.primaryKey && <MainDrawer content={drawerContent} />}</PageCard.Side>

            <PageCard.Body>
                <Stack spacing={1} width="100%" height="100%">
                    {canCreateSurvey && (
                        <NewProjectStrip
                            templates={templates}
                            tagCategories={templatesTags}
                            resourceName="Survey"
                            onCreate={handleOpenCreateDialog}
                        />
                    )}

                    <ProjectsPanel
                        isLoading={isLoading}
                        resources={surveys}
                        setResources={setSurveys}
                        tagCategories={surveyTags}
                        resourceName="survey"
                        sortOptions={['Title', 'status', 'Participants', 'Rules', 'Created on']}
                    />
                </Stack>
            </PageCard.Body>

            {/* Create project Dialog
				========================================= */}
            <NavigableDialog
                title={dialogState.title}
                open={createDialogOpen}
                onClose={handleCloseCreateDialog}
                backHandler={dialogState.onBack}
                disableBackButton={dialogState.disableBackButton}
                fullWidth={true}
                maxWidth={dialogState.maxWidth}
                scroll="body"
            >
                {dialogState.component}
            </NavigableDialog>
        </PageCard>
    )
}

export default ProjectDashboard
