import React, { useMemo, useState } from 'react'
import { EditNodeSettingsModal } from './EditNodeSettingsModal'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import SettingsIcon from '@mui/icons-material/Settings'

import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { List, ListItem, ListItemText } from '@mui/material'
import { formatLabel, formatValue } from 'features/network-viz/helpers/DataFormatter'
import getNodeValue from 'features/network-viz/helpers/NodeValueParser'
import {
    NodeGroupInfoType,
    NodeIdType,
    NodeInfoConfigType,
    NodeType,
} from 'features/network-viz/types/NetworkViz.types'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'

type NetworkSelectedNodeDetailsPropsType = {
    mode?: 'report' | 'network-viz'
    selectedNode: string | null
    analytics: NetworkVizContextType['analytics']
    nodeInfoConfig: NodeInfoConfigType
    nodes: Record<NodeIdType, NodeType>
    nodeGroupInfo?: NodeGroupInfoType
}

export function NetworkSelectedNodeInfoTable({
    mode = 'network-viz',
    selectedNode,
    analytics,
    nodeInfoConfig,
    nodes,
}: NetworkSelectedNodeDetailsPropsType) {
    //ToDo add feature to display data for recurring surveys
    const round = 0
    const [settingsOpen, setSettingsOpen] = useState<boolean>(false)
    const [showAnalytics, setShowAnalytics] = useState(false)
    const [relationship, setRelationship] = useState('view')

    const data = useMemo(() => {
        if (selectedNode === null || selectedNode === undefined || nodes[selectedNode] == null) return null
        const nodeId = selectedNode
        if (showAnalytics && analytics !== null && analytics[relationship] !== undefined) {
            if (analytics[relationship].nodes && analytics[relationship].nodes[nodeId]) {
                return Object.keys(analytics[relationship].nodes[nodeId])
                    .map((key) => {
                        const field = analytics[relationship].nodes[nodeId]
                        let label = formatLabel(key)
                        if (field == null || label == null || label.length == 0) {
                            return
                        }

                        // get value. If NA and hideNA flag, then skip
                        const value = formatValue(
                            //@ts-ignore
                            getNodeValue(field[key], round)
                        )
                        if (nodeInfoConfig.hideNA && value === 'NA') {
                            return
                        }
                        return { label, value }
                    })
                    .filter((x) => x != null)
            }
        } else {
            if (nodeInfoConfig?.fileds)
                return Object.keys(nodeInfoConfig.fileds)
                    .filter((f) => nodeInfoConfig.fileds[f].visible)
                    .map((key, index) => {
                        //if key is in the info section, then skip
                        if (
                            [
                                '_index',
                                nodeInfoConfig.image,
                                nodeInfoConfig.title,
                                nodeInfoConfig.subTitle,
                            ].includes(key)
                        ) {
                            return
                        }

                        //get field settings and label. If no label, skip
                        const field = nodeInfoConfig.fileds[key]
                        let label = field.label ? formatLabel(field.label) : formatLabel(key)
                        if (label == null || label.length === 0) {
                            return
                        }

                        // get value. If NA and hideNA flag, then skip
                        const value = formatValue(getNodeValue(nodes[nodeId]?.[key], round))
                        if (nodeInfoConfig.hideNA && value === 'NA') {
                            return
                        }
                        return { label, value }
                    })
                    .filter((x) => x != null)
        }
        return null
    }, [selectedNode, analytics, showAnalytics])

    return selectedNode == null || nodes[selectedNode] == null ? (
        <></>
    ) : (
        <React.Fragment>
            {/* Body
                ========================================= */}
            <Stack
                gap={1}
                sx={{
                    flexGrow: 1,

                    minHeight: 0,
                }}
            >
                {/* Details
                        ========================================= */}
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                        minWidth: 0,
                    }}
                >
                    {(nodeInfoConfig.image || nodeInfoConfig.title) && (
                        <Avatar
                            src={
                                nodeInfoConfig.image &&
                                getNodeValue(nodes[selectedNode][nodeInfoConfig.image], round)
                            }
                            alt={
                                nodeInfoConfig.title &&
                                getNodeValue(nodes[selectedNode][nodeInfoConfig.title], round)
                            }
                            sx={{
                                flexShrink: 0,
                            }}
                        />
                    )}

                    <Stack
                        sx={{
                            flexGrow: 1,

                            minWidth: 0,
                        }}
                    >
                        <Typography fontSize={14} fontWeight={500} noWrap>
                            {nodeInfoConfig.title &&
                                getNodeValue(nodes[selectedNode][nodeInfoConfig.title], round)}
                        </Typography>

                        <Typography fontSize={14} fontWeight={500} noWrap>
                            {nodeInfoConfig.subTitle &&
                                getNodeValue(nodes[selectedNode][nodeInfoConfig.subTitle], round)}
                        </Typography>
                    </Stack>
                </Stack>

                <List
                    dense
                    sx={{
                        flexGrow: 1,
                        padding: 0,
                        margin: 0,
                        overflow: 'auto',
                    }}
                    className="u-scrollbar"
                >
                    {Array.isArray(data) &&
                        data.map((item, index) => (
                            <ListItem
                                sx={{
                                    padding: 0,
                                    margin: 0,
                                }}
                                key={item?.label}
                            >
                                <ListItemText
                                    sx={{ marginY: '1px' }}
                                    primary={item?.label + ':'}
                                    secondary={item?.value}
                                />
                            </ListItem>
                        ))}
                </List>

                {/* Bottom controls
                        ========================================= */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    <div>
                        <StyledSwitch
                            disabled={analytics?.[relationship]?.nodes == null}
                            checked={showAnalytics}
                            onChange={() => setShowAnalytics((pv) => !pv)}
                            label="Info"
                            label2="Analytics"
                        />
                    </div>

                    {mode === 'network-viz' && (
                        <Tooltip title="Node Info setting">
                            <IconButton onClick={() => setSettingsOpen(true)}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </Stack>

            {settingsOpen && (
                <EditNodeSettingsModal open={settingsOpen} handleClose={() => setSettingsOpen(false)} />
            )}
        </React.Fragment>
    )
}
