import React, { useMemo, useState } from 'react'
import { Typography, Stack, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { useNetworkVizContext, useNetworkVizDispatch } from '../../context/NetworkVizContext'
import { EdgeRenderType, NodeRenderType } from '../../types/NetworkViz.types'
import { groupBy, unionBy } from 'lodash'
import WebWorker from 'helpers/webWorkerHelper'
import BaseButton from 'components/base/BaseButton'
import { toast } from 'react-toastify'
import BaseFilledTextField from 'components/base/BaseFilledTextField'

var lz = require('lz-string')

// Define the properties that this component expects
interface DifferenceDialogProps {
    onClose: () => void
}

const DifferenceDialog: React.FC<DifferenceDialogProps> = ({ onClose }) => {
    // Use the network visualization context
    const { presets, analyticsSettings, edges, ...networkVizContext } = useNetworkVizContext()

    // Use the network visualization dispatch
    const dispatchContext = useNetworkVizDispatch()

    // Define the state for this component
    const [networkA, setNetworkA] = useState<string>('')
    const [networkB, setNetworkB] = useState<string>('')
    const [relationName, setRelationName] = useState<string>('')
    const [isRunning, setIsRunning] = useState<boolean>(false)

    // Determine whether the "Run" button should be disabled
    const isRunDisabled = useMemo(() => networkA === '' || networkB === '', [networkA, networkB])

    // Calculate the networks
    const network = useMemo(() => {
        const _edges = Object.values(edges)
        const networks = groupBy(_edges, analyticsSettings.groupBy)
        return networks
    }, [analyticsSettings.groupBy, edges])

    // Calculate the network options
    const networkOptions = useMemo<string[]>(() => Object.keys(network), [network])

    // Handler for when the "Apply Advance Analytics" operation is performed
    const handleApplyAdvanceAnalytics = async () => {
        if (isRunDisabled) return
        setIsRunning(true)

        const worker = new WebWorker('workers/network/add-edges.js')

        const ToastCloseButton = ({ closeToast }: { closeToast?: () => void }) => (
            <BaseButton
                onClick={() => {
                    worker.terminate()
                    closeToast && closeToast()
                }}
                label="Cancel"
            />
        )

        const toastId = toast.loading('Calculating Network Analytics', {
            // add close button to toast
            closeButton: <ToastCloseButton />,
        })
        try {
            const network1 = network[networkA]
            const netwrok2 = network[networkB]

            // compute the difference between two networks
            // return only the edges that source and target exist in network1 but not in network2
            const difference = network1
                .filter((n1) => !netwrok2.some((n2) => n1.source === n2.source && n1.target === n2.target))
                .map((edge) => ({
                    id: `${edge.source}->${edge.target}`,
                    source: edge.source,
                    target: edge.target,
                }))

            const { nodeRenders, nodes, edgeStyle, edgeGroupBy, layoutKind, filters, nodeInteractionConfig } =
                networkVizContext
            const response = (await worker.run({
                nodeRenders,
                nodes,
                edges,
                edgeStyle,
                edgeGroupBy,
                newEdges: difference,
                relationship: relationName,
                sourceField: 'source',
                targetField: 'target',
                layoutKind,
                filters,
                isDirected: nodeInteractionConfig.directed,
                analyticsSettings,
            })) as any

            dispatchContext({
                type: 'EDGE_ADD',
                payload: {
                    edges: response.edges,
                    edgeRenders: response.edgeRenders,
                    edgeLegend: response.edgeLegend,
                    edgeStyle: response.edgeStyle,
                    edgeDataSchema: response.edgeSchema,
                    nodeRenders: response.nodeRenders,
                    nodeAdjacentList: response.nodeAdjacentList,
                    filters: response.filters,
                    analyticsSettings: response.analyticsSettings,
                },
            })

            toast.update(toastId, {
                render: 'The preset saved successfully.',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        } catch {
            toast.update(toastId, {
                render: 'Failed to run analytics.',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        }
        setIsRunning(false)
        handleClose()
    }

    // Handler for when the "Close Dialog" operation is performed
    const handleClose = () => {
        setNetworkA('')
        setNetworkB('')
        onClose()
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Typography variant="h6">A - B</Typography>
                <Typography>Returns a new graph that contains the edges that exist in A but not in B.</Typography>
                <BaseFilledTextField
                    label="Relationship Name"
                    value={relationName}
                    onChange={(e) => setRelationName(e.target.value)}
                    fullWidth
                    helperText="The name of the relationship between the nodes in the difference network."
                />
                <Typography>Select network A and B and press the run button.</Typography>
                <Stack direction="row" gap={3} spacing={2} mt={3}>
                    <BaseSelectWithLabel
                        value={networkA}
                        onChange={(value) => setNetworkA(value)}
                        fullWidth
                        label="A"
                        options={networkOptions.filter((option) => option !== networkB)}
                    />
                    <BaseSelectWithLabel
                        fullWidth
                        value={networkB}
                        onChange={(value) => setNetworkB(value)}
                        label="B"
                        options={networkOptions.filter((option) => option !== networkA)}
                    />
                </Stack>
            </DialogContent>

            <DialogActions>
                <BaseButton color="secondary" onClick={handleClose}>
                    Close
                </BaseButton>
                <BaseButton
                    variant="contained"
                    disabled={isRunDisabled || isRunning}
                    color="primary"
                    onClick={handleApplyAdvanceAnalytics}
                >
                    Run
                </BaseButton>
            </DialogActions>
        </React.Fragment>
    )
}

export default DifferenceDialog
