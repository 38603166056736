//* ======= Libraries
import React from 'react'
//* ======= Components and features
import StyledDialog from './StyledDialog'
import { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

interface INavigableDialog extends DialogProps {
    title?: string
    onClose?: () => void
    backHandler?: () => void
    disableBackButton?: boolean
}

function NavigableDialog({
    open,
    title,
    onClose,
    backHandler,
    disableBackButton = true,
    children,
    ...dialogProps
}: INavigableDialog) {
    return (
        <StyledDialog open={open} onClose={(event, reason) => onClose && onClose()} {...dialogProps}>
            {/* Header
				========================================= */}
            <DialogTitle
                component="div"
                sx={(theme) => ({
                    padding: theme.spacing(2.5, 3),

                    backgroundColor: theme.palette.common.bg_1,
                })}
            >
                <Box
                    sx={(theme) => ({
                        display: 'grid',
                        gridTemplateColumns: '80px 1fr 80px',
                        gridTemplateRows: '1fr',
                        gap: 1,
                        justifyItems: 'start',
                        alignItems: 'center',
                    })}
                >
                    {/* Back button
				        ========================================= */}
                    {backHandler && (
                        <BaseButton
                            label="Back"
                            onClick={(e) => backHandler()}
                            disabled={disableBackButton}
                            color="secondary"
                            startIcon={<ArrowBackIcon />}
                            sx={{
                                gridColumn: 1,
                            }}
                        />
                    )}

                    {/* Title
				        ========================================= */}
                    <Typography
                        component="h2"
                        fontSize={26}
                        fontWeight={600}
                        color="primary"
                        sx={{
                            gridColumn: 2,
                            justifySelf: 'center',
                        }}
                    >
                        {title}
                    </Typography>

                    {/* Close button
				        ========================================= */}
                    <IconButton
                        onClick={(e) => onClose && onClose()}
                        sx={{
                            gridColumn: 3,
                            justifySelf: 'end',

                            // padding: 0.5,
                        }}
                    >
                        <CloseRoundedIcon
                            sx={{
                                height: '20px',
                                width: '20px',

                                // color: 'common.fill_2',
                            }}
                        />
                    </IconButton>
                </Box>
            </DialogTitle>

            {/* Body
				========================================= */}
            {children}
        </StyledDialog>
    )
}

export default NavigableDialog
