import React, { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import './animation.css'

type Props = {
    size?: number
    withText?: boolean
    horizontal?: boolean
    message?: string
}

function SnaCircularLoading({ size = 64, withText = true, horizontal = false, message = 'Loading' }: Props) {
    const [loadingText, setLoadingText] = useState(message)
    useEffect(() => {
        let timeout = setInterval(() => {
            setLoadingText((pv) => {
                switch (pv) {
                    case message:
                        return `${message}.`
                    case `${message}.`:
                        return `${message}..`
                    case `${message}..`:
                        return `${message}...`
                    default:
                        return message
                }
            })
        }, 300)
        return () => clearInterval(timeout)
    }, [])
    return (
        <Stack direction={horizontal ? 'row' : 'column'} gap={1}>
            <svg
                id="eXnkhpmUtZM1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 147 148"
                width={size}
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
            >
                <g id="eXnkhpmUtZM2_tr" transform="translate(75.125099,74) rotate(2340)">
                    <g transform="scale(-1,1) translate(-74.125099,-72.982139)" clipPath="url(#eXnkhpmUtZM5)">
                        <g transform="matrix(0 1-1 0 147.107238-1.14296)">
                            <path
                                d="M140.2,90.5008c-7.2,7.2-17.8,9-26.7,5.3-4.2,0-8.4,1.6-11.6,4.7002-3.5,3.5-5,8.3-4.7,12.9c3.2,8.7,1.3,18.8-5.7,25.7-9.5,9.5-25,9.5-34.6,0-9.5-9.6-9.5-25,0-34.6c7-7.0002,17.1-8.8002,25.7-5.7002c4.6.3,9.3-1.3,12.8-4.8c3.2-3.2,4.8-7.4,4.8-11.6-2.5-6-2.5-12.7,0-18.7c0-4.3-1.7-8.5-5-11.8-3.7-3.5-8.6-5-13.3-4.5-5.6,1.9-11.8,1.7-17.3-.6-4.2,0-8.4,1.6-11.6,4.7-3.5,3.5-5,8.3-4.7,12.9c3.2,8.7,1.3,18.8-5.7,25.8-9.5,9.5-25,9.5-34.6,0-9.5-9.5-9.5-25,0-34.6c7-7,17-8.8,25.7-5.7c4.6.3,9.3-1.3,12.8-4.8c3.2-3.2,4.8-7.4,4.8-11.6-3.7-8.9-1.9-19.4,5.3-26.59997c9.5-9.5,25-9.6,34.6,0c7.2,7.19997,9,17.89997,5.2,26.79997c0,4.2,1.7,8.5,4.9,11.7c3.8,3.6,8.9,5,13.7,4.4c8.5-2.8,18.2-.8,25,5.9c9.8,9.8,9.8,25.3.2,34.8Z"
                                fill="#2d77bc"
                            />
                        </g>
                        <clipPath id="eXnkhpmUtZM5">
                            <rect width="147" height="148" rx="0" ry="0" fill="#fff" />
                        </clipPath>
                    </g>
                </g>
            </svg>

            {withText && (
                <Typography width={size} fontSize={14} fontWeight={400} color="common.text_1" alignSelf="left">
                    {loadingText}
                </Typography>
            )}
        </Stack>
    )
}

export default SnaCircularLoading
