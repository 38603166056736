//* ======= Libraries
import React, { memo, useCallback, useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import CustomCKEditor, { InlineEditorType } from 'ckeditor5-custom-build'
import { Box } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
//* ======= Assets and styles
import './Editor.css'
import { fontFamilyOptions } from 'components/widgets/FontPicker'

type Props = {
    // INITIAL data for the editor. IT IS NOT the current, dynamic data.
    data?: string | null | undefined
    onReady?: (editor: InlineEditorType) => void
    onChange?: (event: any, editor: InlineEditorType) => void
    onFocus?: (event: any, editor: InlineEditorType) => void
    onBlur?: (event: any, editor: InlineEditorType) => void
    onError?: (
        error: Error,
        details: {
            phase: 'initialization' | 'runtime'
            willEditorRestart?: boolean
        }
    ) => void
    config?: any
    isReadOnly?: boolean
    canGrow?: boolean
    fontSize?: number
}

// ToDo The toolbar is not positioned correctly when the editor is used for the first time
// ToDo it works only when the editor is focused for the second time
function EditorInline({
    data,
    onReady,
    onChange,
    onFocus,
    onBlur,
    onError,
    config,
    isReadOnly,
    canGrow = false,
    fontSize,
}: Props) {
    const wrapperRef = useRef<HTMLDivElement>(null)

    // set the position of the toolbar
    const adjustToolbarPosition = useCallback((editor: InlineEditorType) => {
        if (wrapperRef.current) {
            // width of the other items in the toolbar
            let right: string | number = 'auto'
            const width = Math.min(window.innerWidth * 0.8, 1400)
            // Find the next sibling of the "Show more items" button
            const toolbar = editor.ui.view.toolbar.element?.querySelector(
                'button[data-cke-tooltip-text="Show more items"] + div'
            ) as HTMLElement
            // if the toolbar is not rendered yet, try again after 500ms
            // this is needed for first render
            if (toolbar == null) {
                setTimeout(() => adjustToolbarPosition(editor), 500)
                return
            }
            // get bounding rect of the parent element
            const parentRect = wrapperRef.current.getBoundingClientRect()

            //calculate the left position of the toolbar
            let left: string | number = parentRect.width / 2 - width / 2
            // compute the absolute position of the toolbar in the window
            let absoluteLeft = parentRect.left + left
            // check if the toolbar is out of the screen on the left side
            const minMargin = 20
            if (absoluteLeft < 0) {
                // move the toolbar to the right
                left = -parentRect.left + minMargin
                right = 'auto'
            }
            // check if the toolbar is out of the screen on the right side
            if (absoluteLeft + width > window.innerWidth - minMargin * 2) {
                // move the toolbar to the left
                right = -(window.innerWidth - parentRect.right - minMargin)
                left = 'auto'
            }

            if (toolbar) {
                const currentStyles = toolbar.getAttribute('style') || '' // Get current styles

                // Convert style string to object
                const styleObj: Record<string, string> = currentStyles
                    .split(';')
                    .reduce((acc: Record<string, string>, style: string) => {
                        const [property, value] = style.split(':').map((s) => s.trim())
                        if (property && value) {
                            acc[property] = value
                        }
                        return acc
                    }, {})

                function toPixelValue(value: string | number): string {
                    if (typeof value === 'number') {
                        return `${value}px`
                    }
                    return value
                }

                // Update the necessary properties
                styleObj['max-width'] = `${width}px !important`
                styleObj['bottom'] = `40px !important`
                styleObj['top'] = `auto !important`
                styleObj['right'] = `${toPixelValue(right)} !important`
                styleObj['left'] = `${toPixelValue(left)} !important`

                // Convert style object back to string
                const newStyles = Object.entries(styleObj)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join('; ')

                toolbar.setAttribute('style', newStyles) // Set the updated styles
            }
        }
    }, [])

    const onEditorReady = (editor: InlineEditorType) => {
        onReady !== undefined && onReady(editor)
    }

    return (
        <Box
            ref={wrapperRef}
            sx={(theme) => ({
                height: canGrow ? 'auto' : '100%',
                minHeight: 0,
                maxHeight: canGrow ? undefined : '100%',
                fontSize: fontSize !== undefined ? fontSize : undefined,
            })}
            className={'EditorInline'}
        >
            {(!isReadOnly || data !== undefined) && (
                <CKEditor
                    editor={CustomCKEditor.InlineEditor}
                    // INITIAL data for the editor. IT IS NOT the current, dynamic data.
                    data={data}
                    onReady={(editor) => onEditorReady(editor)}
                    onChange={(event, editor) => onChange !== undefined && onChange(event, editor)}
                    onFocus={(event, editor) => {
                        adjustToolbarPosition(editor)
                        onFocus !== undefined && onFocus(event, editor)
                    }}
                    onBlur={(event, editor) => onBlur !== undefined && onBlur(event, editor)}
                    onError={(error, details) => {
                        console.log(error)
                        console.log(details)
                        onError !== undefined && onError(error, details)
                    }}
                    config={{
                        fontFamily: {
                            options: fontFamilyOptions,
                            supportAllValues: false,
                        },
                        ...(config || {}),
                    }}
                    disabled={isReadOnly}
                    disableWatchdog={true}
                    id="custom-ck-editor-inline"
                />
            )}
        </Box>
    )
}

export default EditorInline
