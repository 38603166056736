//* ======= Libraries
import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { enableMapSet, enablePatches } from 'immer'
import { LicenseInfo } from '@mui/x-license-pro'
//* ======= Components and features
import App from './App'
//* ======= Custom logic
import reportWebVitals from './reportWebVitals'
import './i18n/config'
//* ======= Assets and styles
import './index.css'
import { APP_VERSIONS } from 'helpers/constants'

const environment = process.env.NODE_ENV

//* Sentry initialization
Sentry.init({
    dsn: 'https://1c45a1fd5de64f46bc56a939683efd6a@o4504641351843840.ingest.sentry.io/4504682707353600',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay(), new Sentry.BrowserProfilingIntegration()],
    environment,
    release: APP_VERSIONS.APP,
    tracesSampleRate: environment === 'production' ? 0.1 : 1.0,
    replaysSessionSampleRate: environment === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    debug: environment === 'development',
    tracePropagationTargets: ['localhost'],
    profilesSampleRate: 1.0,
    enabled: environment === 'production',
})

//* Immer additional functionality activation
enableMapSet()
enablePatches()

//* MUI-X license activation
LicenseInfo.setLicenseKey(
    '7d1b3dfe31708ed1c568c871b2de69adTz05NDQ4NSxFPTE3NTI4ODU4MDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
