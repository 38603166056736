//* ======= Libraries
import React, { ReactNode, useMemo, useState } from 'react'
//* ======= Components and features
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ResourceType } from 'types/resources'
import { ContextMenuItemType } from 'types/ContextMenuType'
//* ======= Assets and styles
import MoreHorizIcon from '@mui/icons-material/MoreVert'
import TempAvatar from 'assets/images/profile-photo.svg'
import FolderImage from 'assets/icons/folder_image.svg'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
import { Tooltip, alpha } from '@mui/material'

export interface ITemplateCardBoxedFloatingButton {
    Icon?: ReactNode
    onClick?: () => void
}

type TemplateCardBoxedProps = {
    data: ResourceType
    isSelected?: boolean
    isFolder?: boolean | undefined
    showMenu?: boolean
    topLeftIcon?: ReactNode
    topRightButtonProps?: ITemplateCardBoxedFloatingButton
    clickHandler?: () => void
    contextMenu?: ContextMenuItemType[]
}

function TemplateCardBoxed({
    data,
    isSelected = false,
    isFolder = false,
    showMenu = false,
    topLeftIcon,
    topRightButtonProps,
    contextMenu,
    clickHandler,
}: TemplateCardBoxedProps) {
    const [itemMenu, setItemMenu] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    const abbreviation = useMemo(() => {
        return buildAbbreviation(data.title)
    }, [data?.title])

    return (
        <Stack
            sx={(theme) => ({
                position: 'relative',

                height: '235px',
                width: '100%',
                overflow: 'hidden',

                borderRadius: '10px',
                border: isSelected
                    ? `2px solid ${theme.palette.common.border_1}`
                    : `1px solid ${theme.palette.common.border_2}`,
                backgroundColor: 'common.bg_3',
            })}
        >
            {/* Icon/Image
				========================================= */}
            <BaseButton
                onClick={(e) => clickHandler && clickHandler()}
                sx={{
                    flexGrow: 1,

                    minHeight: 0,
                    width: '100%',
                    padding: 0,

                    backgroundColor: isFolder ? 'common.bg_5' : 'transparent',
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                        height: '100%',
                        width: '100%',

                        backgroundColor: 'transparent',
                    }}
                >
                    {isFolder ? (
                        <img
                            src={FolderImage}
                            alt="folder"
                            style={{
                                width: '25%',
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                        />
                    ) : data.image ? (
                        <img
                            src={data.image}
                            alt="item"
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                        />
                    ) : (
                        <Typography fontSize={50} fontWeight={400} color="common.text_1">
                            {abbreviation}
                        </Typography>
                    )}
                </Paper>
            </BaseButton>

            {/* (Out-of-flow) Badge icon
				========================================= */}
            {topLeftIcon && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        left: '10px',
                    }}
                >
                    {topLeftIcon}
                </Box>
            )}

            {/* (Out-of-flow) Favorite button
				========================================= */}
            {topRightButtonProps && (
                <IconButton
                    onClick={(e) => topRightButtonProps.onClick && topRightButtonProps.onClick()}
                    sx={(theme) => ({
                        position: 'absolute',
                        top: '8px',
                        right: '10px',
                        backgroundColor: alpha(theme.palette.common.bg_5, 0.5),
                        '&hover': {
                            backgroundColor: alpha(theme.palette.common.bg_5, 0.7),
                        },
                        color: 'common.fill_1',
                    })}
                >
                    {topRightButtonProps.Icon}
                </IconButton>
            )}

            {/* Name, controls and creator
				========================================= */}
            <Stack
                sx={{
                    flexShrink: 0,

                    padding: 1,

                    backgroundColor: 'common.bg_1',
                }}
            >
                {/* Project name and menu
					========================================= */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        // '32px' is the height of this element if menu is visible.
                        minHeight: showMenu ? 0 : '32px',
                    }}
                >
                    {/* Title */}
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Link href={data.link} underline="none">
                            <Tooltip title={data.title} enterDelay={200} arrow placement="top">
                                <Typography
                                    textAlign="start"
                                    fontSize={16}
                                    fontWeight={400}
                                    lineHeight="16px"
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    color="primary.main"
                                >
                                    {data.title}
                                </Typography>
                            </Tooltip>
                        </Link>
                    </Box>

                    {/* Menu
                        ========================================= */}
                    {showMenu && Array.isArray(contextMenu) && contextMenu.length > 0 && (
                        <>
                            <IconButton
                                onClick={(evt) => setItemMenu({ isOpen: true, anchorElement: evt.currentTarget })}
                                id="item-menu-button"
                                aria-controls={itemMenu.isOpen ? 'item-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={itemMenu.isOpen ? 'true' : undefined}
                                sx={{
                                    padding: 0.5,

                                    color: 'common.fill_1',
                                }}
                            >
                                <MoreHorizIcon />
                            </IconButton>

                            {/* (Out-of-flow) Item menu
						        ========================================= */}
                            <Menu
                                id="item-menu"
                                anchorEl={itemMenu.anchorElement}
                                open={itemMenu.isOpen}
                                onClose={() => setItemMenu((prevState) => ({ ...prevState, isOpen: false }))}
                                MenuListProps={{
                                    'aria-labelledby': 'item-menu-button',
                                }}
                            >
                                {contextMenu.map((cm) => (
                                    <MenuItem onClick={cm.action}>{cm.title}</MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </Stack>

                {/* Creator
					========================================= */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                        src={data.creator?.avatar || TempAvatar}
                        alt="creator"
                        sx={{
                            height: '24px',
                            width: '24px',
                        }}
                    />

                    <Typography fontSize={10} fontWeight={400} color="secondary">
                        {data.creator?.name}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default TemplateCardBoxed
