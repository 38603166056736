import { useEffect, useRef, useState } from 'react'
import { MultiDirectedGraph } from 'graphology'
import Sigma from 'sigma'
import getNodeProgramImage from 'sigma/rendering/webgl/programs/node.image'
import getNodeProgramFast from 'sigma/rendering/webgl/programs/node.fast'
import NodeProgramBorder from './components/node/node.border'
import saveAsPNG from './components/helper/saveAsPNG'
import Box from '@mui/material/Box'
import { SNAGraph } from '../Network2dPlusRenderer'
import usePreviousValue from 'hooks/usePreviousValue'

let isDragging: boolean = false
let draggedNode: string | null = null

const SigmaGraph: React.FC<SNAGraph> = ({
    action,
    nodes,
    edges,
    setSelectedItem,
    onNodeHover,
    // zoom,
    updateNodeLocation,
}: SNAGraph) => {
    // const { currentTimeFrame, timePanel, nodeDateDic } = useNetworkVizContext()

    const sigmaRef = useRef<HTMLDivElement>(null)
    const rendererRef = useRef<Sigma | null>(null)
    const lastActionId = usePreviousValue(action?.id || 0)

    //handle date display
    //! ToDo
    // useEffect(() => {
    //     if (rendererRef.current == null) return
    //     if (!timePanelState.enabled) {
    //         rendererRef.current.setSetting('nodeReducer', null)
    //     } else {
    //         rendererRef.current.setSetting('nodeReducer', (id, data) => {
    //             const res: Partial<NodeDisplayData> = { ...data }
    //             res.hidden = nodeDateDic[id] == null || nodeDateDic[id].isAfter(currentTimeFrame)
    //             return res
    //         })
    //     }
    // }, [currentTimeFrame, timePanelState.enabled, nodeDateDic])

    //action listener
    useEffect(() => {
        if (action && action.id > 0 && lastActionId !== action.id) {
            switch (action.content.type) {
                case 'fit':
                    rendererRef.current?.getCamera().animatedReset()
                    break
                case 'export-image':
                    saveImagePNG()
                    break
            }
        }
    }, [action?.id])

    const saveImagePNG = () => {
        if (rendererRef.current) {
            const layers = ['edges', 'nodes', 'edgeLabels', 'labels']
            saveAsPNG(rendererRef.current, layers)
        }
    }

    useEffect(() => {
        if (sigmaRef?.current) {
            sigmaRef.current.innerHTML = ''
            try {
                const graph = MultiDirectedGraph.from({
                    edges: edges,
                    nodes: nodes,
                    attributes: {},
                    options: {},
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let tempRenderer = new Sigma(graph, sigmaRef.current as HTMLElement, {
                    // We don't have to declare edgeProgramClasses here, because we only use the default ones ("line" and "arrow")
                    nodeProgramClasses: {
                        circle: getNodeProgramFast,
                        image: getNodeProgramImage(),
                        border: NodeProgramBorder,
                    },
                    allowInvalidContainer: true,
                    renderEdgeLabels: true,
                })
                // tempRenderer.getCamera().animatedZoom(zoom.value)
                tempRenderer.addListener('clickNode', (e: any) => {
                    setSelectedItem(e.node)
                })

                tempRenderer.addListener('clickEdge', (e: any) => {
                    setSelectedItem(e.edge)
                })

                // On mouse down on a node
                //  - we enable the drag mode
                //  - save in the dragged node in the state
                //  - highlight the node
                //  - disable the camera so its state is not updated
                tempRenderer.on('downNode', (e) => {
                    isDragging = true
                    draggedNode = e.node
                })

                tempRenderer.on('enterNode', (e) => {
                    onNodeHover(e.node)
                })

                tempRenderer.on('leaveNode', (e) => {
                    onNodeHover(null)
                })

                // On mouse move, if the drag mode is enabled, we change the position of the draggedNode
                tempRenderer.getMouseCaptor().on('mousemovebody', (e) => {
                    if (!isDragging || !draggedNode) return

                    // Get new position of node
                    const pos = tempRenderer.viewportToGraph(e)

                    graph.setNodeAttribute(draggedNode, 'x', pos.x)
                    graph.setNodeAttribute(draggedNode, 'y', pos.y)

                    // Prevent sigma to move camera:
                    e.preventSigmaDefault()
                    e.original.preventDefault()
                    e.original.stopPropagation()
                })

                // On mouse up, we reset the autoscale and the dragging mode
                tempRenderer.getMouseCaptor().on('mouseup', () => {
                    if (updateNodeLocation && draggedNode) {
                        let node = graph.getNodeAttributes(draggedNode)
                        if (node) updateNodeLocation(draggedNode, node.x, node.y)
                    }

                    isDragging = false
                    draggedNode = null
                })

                // Disable the autoscale at the first down interaction
                tempRenderer.getMouseCaptor().on('mousedown', () => {
                    if (!tempRenderer.getCustomBBox()) tempRenderer.setCustomBBox(tempRenderer.getBBox())
                })

                rendererRef.current = tempRenderer
            } catch (e) {}
        }
    }, [sigmaRef])

    useEffect(() => {
        if (rendererRef.current == null) return
        try {
            const graph = MultiDirectedGraph.from({
                edges: edges,
                nodes: nodes,
                attributes: {},
                options: {},
            })
            rendererRef.current.getGraph().clear()
            rendererRef.current.getGraph().import(graph)
            rendererRef.current.refresh()
        } catch (e) {
            console.log(e)
        }
    }, [nodes, edges])

    // useEffect(() => {
    //     if (rendererRef.current == null) return;
    //     const camera = rendererRef.current.getCamera();
    //     const { x, y } = rendererRef.current.getBBox();
    //     const { height, width } = rendererRef.current.getGraphDimensions();
    //     if (camera && zoom.center != null)
    //         camera.animate({
    //             x: (zoom.center[0] - x[0]) / width,
    //             y: (-1 * zoom.center[1] - y[0]) / height,
    //             ratio: .5
    //         });
    // }, [zoom])

    return (
        <>
            <Box
                ref={sigmaRef}
                sx={(theme) => ({
                    alignSelf: 'stretch',
                    height: '100%',
                    // width: '100%',
                })}
            />
        </>
    )
}

export default SigmaGraph
