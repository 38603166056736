//* ======= Libraries
//* ======= Components and features
import StyledDialog from './StyledDialog'
import { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles

interface IAlertDialog extends DialogProps {
    onClose: () => void
    title?: string
    message?: string
    confirmText?: string
    onConfirm?: () => void
    declineText?: string
}

/* *
 * Notes:
 *  1.  Alert dialog is not closable by backdrop clicks or pressing the ESC key.
 *      That's why 'onClose' is a required prop.
 */

function AlertDialog({
    open,
    onClose,
    title,
    message,
    confirmText = 'Yes',
    onConfirm,
    declineText = 'No',
    ...dialogProps
}: IAlertDialog) {
    return (
        <StyledDialog open={open} onClose={(event, reason) => undefined} {...dialogProps}>
            {/* Header
				========================================= */}
            <DialogTitle
                sx={(theme) => ({
                    padding: theme.spacing(2.5, 3),
                    fontSize: 36,
                    fontWeight: 600,
                    textAlign: 'center',

                    color: theme.palette.common.fuschia80,
                })}
            >
                {title}
            </DialogTitle>

            {/* Body
				========================================= */}
            <DialogContent>
                <DialogContentText textAlign="center" color="common.text_1">
                    {message}
                </DialogContentText>
            </DialogContent>

            {/* Actions
				========================================= */}
            <DialogActions
                disableSpacing
                sx={{
                    justifyContent: 'center',
                    gap: 2,

                    paddingBottom: 4,
                }}
            >
                {/* Decline
				    ========================================= */}
                <BaseButton
                    label={declineText}
                    onClick={(e) => onClose()}
                    variant="outlined"
                    size="large"
                    color="warning"
                    sx={{
                        minWidth: 186,
                    }}
                />

                {/* Confirm
				    ========================================= */}
                <BaseButton
                    label={confirmText}
                    onClick={(e) => onConfirm && onConfirm()}
                    variant="contained"
                    size="large"
                    color="warning"
                    disableElevation
                    sx={{
                        minWidth: 186,
                    }}
                />
            </DialogActions>
        </StyledDialog>
    )
}

export default AlertDialog
