//* ======= Libraries
import React, { useEffect, useState } from 'react'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import BaseSelect from 'components/base/BaseSelect'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
//* ======= Custom logic
//* ======= Assets and styles

export type BorderType = {
    color: string
    width: number
    radius: number
    style: 'solid' | 'dotted' | 'dash'
}

const defaultBorderValues: BorderType = {
    color: '#000000',
    width: 8,
    radius: 0,
    style: 'solid',
}

type Props = {
    defaultValue?: BorderType
    onChange: (v: BorderType) => void
    hasRadius?: boolean
    disabled?: boolean
    simple?: boolean
}

function BorderWidgetSettings({
    simple = false,
    defaultValue,
    onChange,
    disabled = false,
    hasRadius = true,
}: Props) {
    const [value, setValue] = useState<BorderType | null>(null)

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
        } else {
            setValue(defaultBorderValues)
        }
    }, [])

    const handleFieldUpdate = (field: string) => (value: any) => {
        setValue((prevValue: BorderType | null) => {
            if (prevValue == null) return null
            const temp = { ...prevValue, [field]: value }
            onChange(temp)
            return temp
        })
    }

    return (
        <React.Fragment>
            {value && (
                <Stack gap={1}>
                    {/* Width and style (?)
                        ========================================= */}
                    {!simple && (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                            <BaseSelect
                                placeholder="Width"
                                options={Array.from(Array(10).keys()).map((v) => ({
                                    name: 1 + v + ' px',
                                    value: 1 + v,
                                }))}
                                value={value?.width}
                                onChangeHandler={handleFieldUpdate('width')}
                                disabled={disabled}
                                isSlim
                                sx={{
                                    width: '50%',
                                }}
                            />

                            <BaseSelect
                                placeholder="Style"
                                options={['solid', 'dotted', 'dashed']}
                                value={value?.style}
                                onChangeHandler={handleFieldUpdate('style')}
                                disabled={disabled}
                                isSlim
                                sx={{
                                    width: '50%',
                                }}
                            />
                        </Stack>
                    )}

                    {/* Radius
                        ========================================= */}
                    {!simple && hasRadius && (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                            <Typography
                                fontSize={14}
                                fontWeight={400}
                                noWrap
                                sx={{
                                    width: '50%',
                                }}
                            >
                                Border Radius
                            </Typography>

                            <BaseSelect
                                placeholder="Radius"
                                disabled={disabled}
                                options={Array.from(Array(10).keys()).map((v) => ({
                                    name: 1 + v + ' px',
                                    value: 1 + v,
                                }))}
                                isSlim
                                sx={{
                                    width: '50%',
                                }}
                                value={value?.radius}
                                onChangeHandler={handleFieldUpdate('radius')}
                            />
                        </Stack>
                    )}
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        noWrap
                        sx={{
                            width: '50%',
                        }}
                    >
                        Color
                    </Typography>

                    {/* Border color
                        ========================================= */}
                    <ColorPicker
                        disabled={disabled}
                        value={value?.color}
                        onChange={handleFieldUpdate('color')}
                    />
                </Stack>
            )}
        </React.Fragment>
    )
}

export default BorderWidgetSettings
