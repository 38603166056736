//* ======= Libraries
import React, { useState, useEffect } from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import BaseButton from 'components/base/BaseButton'
import PasswordField from 'components/PasswordField/PasswordField'
//* ======= Custom logic
//* ======= Assets and styles
import SNALogo from 'assets/images/sna-toolbox-logo.png'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GetPublicSurveyService, PublicSurveyType } from 'services/SurveyService'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import Color from 'color'
import PageNotFoundImage from '../../assets/images/page-not-found.png'
import { GetSurveyInstanceTokenService } from 'services/SurveyInstanceService'

interface ISurveyPreviewLogin {}

function SurveyPreviewLogin({}: ISurveyPreviewLogin) {
    const { sid } = useParams()
    const navigator = useNavigate()
    const [passcode, setPasscode] = useState<string>('')
    const [survey, setSurvey] = useState<PublicSurveyType | null>()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (sid == null) {
            setSurvey(null)
        } else {
            GetPublicSurveyService(sid)
                .then((res) => {
                    res.success ? setSurvey(res.data) : setSurvey(null)
                })
                .catch((e) => setSurvey(null))
        }
    }, [sid])

    const login = () => {
        if (sid == null || passcode == null) return
        setLoading(true)
        setTimeout(() => {
            GetSurveyInstanceTokenService({ surveyId: Number(sid), passcode })
                .then((res) => {
                    res.success && navigator(`/survey/si/${res.data}`)
                })
                .finally(() => setLoading(false))
        }, 500)
    }

    return (
        <Stack
            sx={(theme) => ({
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',

                backgroundColor: theme.palette.common.bg_1,
            })}
            className="u-scrollbar-pink"
        >
            {/* Body
				========================================= */}
            <Stack
                sx={(theme) => ({
                    flexGrow: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,

                    width: '100%',
                    maxWidth: theme.breakpoints.values['lg'],
                    py: 2,
                    px: 3,
                })}
            >
                {survey != null ? (
                    <React.Fragment>
                        {/* Survey pic
					========================================= */}
                        {survey?.logo && (
                            <img
                                src={survey.logo}
                                alt="survey logo"
                                style={{
                                    height: 100,
                                    width: 200,
                                    objectFit: 'contain',
                                    marginBottom: 2,
                                }}
                            />
                        )}

                        {/* Title
					========================================= */}
                        <Typography fontSize={30} fontWeight={400} color="common.text_1" marginBottom={2}>
                            {survey.title} Login
                        </Typography>

                        <>
                            {/* Password
							========================================= */}
                            <PasswordField
                                label="Passcode"
                                value={passcode}
                                onChange={(e) => setPasscode(e.target.value)}
                                sx={{
                                    width: 400,
                                    marginBottom: 2,
                                }}
                            />

                            {/* Login button
							========================================= */}
                            <BaseButton
                                label="Login"
                                onClick={login}
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={loading || passcode.length == 0}
                                disableElevation
                                endIcon={
                                    loading ? (
                                        <span style={{ alignSelf: 'end' }}>
                                            <SnaCircularLoading withText={false} size={24} />
                                        </span>
                                    ) : null
                                }
                                sx={{
                                    backgroundColor: survey?.color,
                                    color: survey?.color ? Color(survey?.color).lighten(1).toString() : 'white',
                                    ':hover': {
                                        backgroundColor: survey?.color
                                            ? Color(survey?.color).darken(0.1).toString()
                                            : '',
                                    },
                                    ':disabled': {
                                        backgroundColor: survey?.color
                                            ? Color(survey?.color).lighten(0.3).toString()
                                            : '',
                                        color: '#eeee',
                                    },
                                    width: 400,
                                    marginBottom: 2,
                                    py: 1.75,
                                }}
                            />
                        </>

                        {/* Copyright text
					========================================= */}
                        <Typography fontSize={14} fontWeight={400} color="common.text_3">
                            Copyright 2022 SNA Toolbox Pty Ltd. All Rights Reserved.
                        </Typography>
                    </React.Fragment>
                ) : survey === undefined ? (
                    <React.Fragment>
                        <SnaCircularLoading />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Stack>
                            <img
                                src={PageNotFoundImage}
                                alt="Page Not Found"
                                style={{
                                    height: '500px',
                                }}
                            />
                            <Typography textAlign="center" variant="h2" color="secondary">
                                Survey Not Found
                            </Typography>
                        </Stack>
                    </React.Fragment>
                )}
            </Stack>

            {/* Footer
				========================================= */}
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={(theme) => ({
                    flexShrink: 0,
                    alignSelf: 'center',

                    width: '100%',
                    maxWidth: theme.breakpoints.values['lg'],
                    py: 2,
                    px: 3,

                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    backgroundColor: 'common.bg_1',
                })}
            >
                <img
                    src={SNALogo}
                    alt="SNA logo"
                    style={{
                        height: 30,
                        width: 140,
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />

                <Typography fontSize={12} fontWeight={400} color="common.text_3" marginTop={0.75}>
                    This survey was built using SNA Toolbox.
                </Typography>

                <Typography fontSize={10} fontWeight={400} color="common.text_3" marginLeft="auto">
                    SNA Toolbox Survey Software v3.3.2
                </Typography>

                <Link
                    href="#"
                    underline="none"
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,

                        color: 'primary.main',
                    }}
                >
                    More Information
                </Link>
            </Stack>
        </Stack>
    )
}

export default SurveyPreviewLogin
