//* ======= Libraries
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import ImageUpload from 'components/ImageUpload/ImageUpload'
//* ======= Custom logic
import { NodeType, updateNodeService } from 'services/NodeService'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EmailIcon from '@mui/icons-material/Email'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'

interface INodesSidebar {
    node: NodeType
    updateNode: (node: NodeType) => void
}

function NodesSidebar({ node, updateNode }: INodesSidebar) {
    const { pid, nid } = useParams()

    const img_input_id = 'node_img_input'
    const theme = useTheme()
    const isViewportSmall = useMediaQuery(theme.breakpoints.down('xl'))
    const [editMode, setEditMode] = useState(false)
    const [tempNode, setTempNode] = useState<NodeType>({
        primaryKey: node.primaryKey,
        title: node.title,
        avatar: node.avatar,
        respondents: node.respondents,
        others: { ...(node.others || {}) },
    })
    const [tmpOthers, setTmpOthers] = useState<{ key: string; value: string }[]>([])

    useEffect(() => {
        if (editMode) {
            setTempNode({
                primaryKey: node.primaryKey,
                title: node.title,
                avatar: node.avatar,
                respondents: node.respondents,
                others: { ...(node.others || {}) },
            })
        }
    }, [editMode])

    const saveChanges = async () => {
        if (pid == null || nid == null) return
        try {
            if (tempNode.others == null) tempNode.others = {}

            for (let item of tmpOthers) {
                tempNode.others[item.key] = item.value
            }

            const response = await updateNodeService(pid, nid, tempNode)
            if (response.success) {
                updateNode({ ...tempNode })
                setTmpOthers([])
                setEditMode(false)
                toast.success('Changes saved!')
                return
            } else {
                toast.error('Cannto save the changes')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const discardChanges = () => {
        setTempNode({ ...node })
        setTmpOthers([])
        setEditMode(false)
    }

    const addInfo = () => {
        setTmpOthers((pv) => [...pv, { key: '', value: '' }])
    }

    const removeInfo = (index: number) => () => {
        setTmpOthers((pv) => {
            const tmp = [...pv]
            tmp.splice(index, 1)
            return tmp
        })
    }

    const removeInfoFromNode = (key: string) => () => {
        setTempNode((pv) => {
            const tmp = { ...pv }
            tmp.others && delete tmp.others[key]
            return tmp
        })
    }

    return (
        <Box
            sx={(theme) => ({
                height: editMode ? 'calc(100% - 76px)' : '100%',
                // '208px' on small viewports and '296px' on larger ones.
                width: editMode ? '100%' : isViewportSmall ? '13rem' : '18.5rem',
                position: editMode ? 'absolute' : 'auto',
                backgroundColor: editMode ? 'rgba(0,0,0,.6)' : 'auto',
                zIndex: 99,
                overflowY: 'auto',
            })}
            className="u-scrollbar"
        >
            <Stack
                gap={1}
                height="100%"
                sx={{
                    padding: theme.spacing(2, 2, 3, 3),
                    backgroundColor: 'common.bg_1',
                    width: editMode ? '80%' : '100%',
                    maxWidth: editMode ? '600px' : '100%',
                }}
            >
                {/* Edit button
					========================================= */}
                <IconButton
                    onClick={() => {
                        if (editMode) {
                            discardChanges()
                        } else {
                            setEditMode(true)
                        }
                    }}
                    sx={{
                        alignSelf: 'flex-end',

                        color: 'primary.main',
                    }}
                >
                    {editMode ? <CloseRoundedIcon /> : <EditIcon />}
                </IconButton>

                {/* Avatar and username
					========================================= */}
                <Stack gap={1} alignItems="center" marginBottom={1}>
                    {/* Profile pic
						========================================= */}
                    <Avatar
                        src={editMode ? tempNode.avatar : node.avatar}
                        alt="profile"
                        sx={{
                            height: 64,
                            width: 64,
                        }}
                    >
                        {buildAbbreviation(node.title)}
                    </Avatar>
                    {editMode && (
                        <Stack direction="row" sx={{ marginTop: '-5px' }} gap={1}>
                            <BaseButton
                                color="warning"
                                onClick={() =>
                                    setTempNode((pv) => ({
                                        ...pv,
                                        avatar: '',
                                    }))
                                }
                            >
                                Remove
                            </BaseButton>
                            <BaseButton onClick={() => document.getElementById(img_input_id)?.click()}>
                                Change
                            </BaseButton>
                        </Stack>
                    )}
                    <ImageUpload
                        inputId={img_input_id}
                        onComplete={(v) => {
                            setTempNode((pv) => ({
                                ...pv,
                                avatar: v,
                            }))
                        }}
                    />

                    {/* Username
						========================================= */}
                    {editMode == true ? (
                        <BaseFilledTextField
                            size="small"
                            label="Title"
                            onChange={(e) => setTempNode((pv) => ({ ...pv, title: e.target.value }))}
                            defaultValue={node.title}
                        />
                    ) : (
                        <Typography fontSize={14} fontWeight={400} color="common.text_1" marginBottom={1}>
                            {node.title}
                        </Typography>
                    )}
                </Stack>

                {/* Contact
                    Only display if respondent is the node
					========================================= */}
                {node.respondents?.length == 1 && (
                    <Stack alignItems="flex-start" gap={1} marginBottom={2}>
                        <Typography fontSize={14} fontWeight={600} color="primary" marginBottom={0.5}>
                            Contact:
                        </Typography>

                        {/* Email
						========================================= */}
                        <Stack direction="row" alignItems="center" gap={1.5} width="100%">
                            <EmailIcon
                                sx={{
                                    width: 22,
                                    color: 'primary.main',
                                }}
                            />

                            {editMode == true ? (
                                <BaseFilledTextField
                                    fullWidth
                                    size="small"
                                    hiddenLabel
                                    onChange={(e) => {
                                        setTempNode((pv) => ({
                                            ...pv,
                                            respondents: [
                                                {
                                                    //@ts-ignore
                                                    ...pv.respondents[0],
                                                    email: e.target.value,
                                                },
                                            ],
                                        }))
                                    }}
                                    defaultValue={node.respondents[0].email}
                                />
                            ) : (
                                <Typography fontSize={14} fontWeight={400} color="common.text_1" marginBottom={1}>
                                    {node.respondents[0].email || 'NA'}
                                </Typography>
                            )}
                        </Stack>

                        {/* Phone
						========================================= */}
                        <Stack direction="row" alignItems="center" gap={1.5} width="100%">
                            <ContactPhoneIcon
                                sx={{
                                    width: 22,

                                    color: 'primary.main',
                                }}
                            />

                            {editMode == true ? (
                                <BaseFilledTextField
                                    fullWidth
                                    size="small"
                                    hiddenLabel
                                    defaultValue={node.respondents[0].phoneNumber}
                                    onChange={(e) => {
                                        setTempNode((pv) => ({
                                            ...pv,
                                            respondents: [
                                                {
                                                    //@ts-ignore
                                                    ...pv.respondents[0],
                                                    phoneNumber: e.target.value,
                                                },
                                            ],
                                        }))
                                    }}
                                />
                            ) : (
                                <Typography fontSize={14} fontWeight={400} color="common.text_1" marginBottom={1}>
                                    {node.respondents[0].phoneNumber || 'NA'}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                )}

                {/* Info
					========================================= */}
                <Stack alignItems="flex-start" gap={1} marginBottom={2}>
                    <Stack direction="row" alignItems="center" width="100%">
                        <Typography fontSize={14} fontWeight={600} color="primary" marginBottom={0.5} flexGrow={1}>
                            Info:
                        </Typography>
                        {editMode && (
                            <Tooltip
                                placement="right"
                                title="Add info"
                                sx={{
                                    color: 'primary.main',
                                }}
                            >
                                <IconButton onClick={addInfo}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>

                    {editMode == false &&
                        node.others &&
                        Object.keys(node.others).map((key, idx) => (
                            <Stack direction="row" alignItems="center" gap={2} width="100%">
                                <Typography
                                    alignSelf="start"
                                    fontSize={16}
                                    fontWeight={400}
                                    color="common.text_3"
                                    marginBottom={1}
                                >
                                    {key}
                                </Typography>
                                <Typography alignSelf="start" fontSize={14} fontWeight={400} color="common.text_1">
                                    {node.others && node.others[key]}
                                </Typography>
                            </Stack>
                        ))}

                    {/* newly added info */}
                    {editMode == true && (
                        <React.Fragment>
                            {tempNode.others &&
                                Object.keys(tempNode.others).map((key, idx) => (
                                    <Stack
                                        key={(tempNode.others && tempNode.others[key]) || idx}
                                        direction="row"
                                        alignItems="center"
                                        gap={2}
                                        width="100%"
                                    >
                                        <BaseFilledTextField
                                            sx={{ flex: 0.48 }}
                                            size="small"
                                            disabled
                                            hiddenLabel
                                            defaultValue={key}
                                        />
                                        <BaseFilledTextField
                                            sx={{ flex: 0.48 }}
                                            size="small"
                                            hiddenLabel
                                            defaultValue={(tempNode.others && tempNode.others[key]) || ''}
                                            onBlur={(e) => {
                                                setTempNode((pv) => ({
                                                    ...pv,
                                                    others: {
                                                        ...pv.others,
                                                        [key]: e.target.value,
                                                    },
                                                }))
                                            }}
                                        />
                                        <Tooltip
                                            placement="right"
                                            title="Remove"
                                            sx={{
                                                color: 'primary.main',
                                            }}
                                        >
                                            <IconButton onClick={removeInfoFromNode(key)} sx={{ flex: 0.04 }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                ))}

                            {tmpOthers.map((item, idx) => (
                                <Stack direction="row" alignItems="center" gap={2} width="100%">
                                    <BaseFilledTextField
                                        sx={{ flex: 0.48 }}
                                        size="small"
                                        hiddenLabel
                                        defaultValue={item.key}
                                        onChange={(e) => {
                                            setTmpOthers((pv) => {
                                                const tmp = [...pv]
                                                tmp[idx].key = e.target.value
                                                return tmp
                                            })
                                        }}
                                    />
                                    <BaseFilledTextField
                                        sx={{ flex: 0.48 }}
                                        size="small"
                                        hiddenLabel
                                        defaultValue={item.value}
                                        onChange={(e) => {
                                            setTmpOthers((pv) => {
                                                const tmp = [...pv]
                                                tmp[idx].value = e.target.value
                                                return tmp
                                            })
                                        }}
                                    />
                                    <Tooltip
                                        placement="right"
                                        title="Remove"
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                    >
                                        <IconButton onClick={removeInfo(idx)} sx={{ flex: 0.04 }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            ))}
                        </React.Fragment>
                    )}
                </Stack>
                {/* Save and discard Changes button
                 */}
                {editMode && (
                    <Stack direction="row" gap={1}>
                        <BaseButton sx={{ flex: 0.5 }} color="secondary" onClick={discardChanges}>
                            Discard
                        </BaseButton>
                        <BaseButton sx={{ flex: 0.5 }} variant="contained" onClick={saveChanges}>
                            Save
                        </BaseButton>
                    </Stack>
                )}
            </Stack>
        </Box>
    )
}

export default NodesSidebar
