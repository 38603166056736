import { toast } from 'react-toastify'
import axios, { AxiosRequestHeaders, ResponseType } from 'axios'
import { omitDeep } from 'helpers/omitDeep'

const BASE_API_URL = process.env.REACT_APP_API_URL

export type BaseResponseType = {
    success: boolean
    data?: number
}

const API = axios.create({
    baseURL: BASE_API_URL,
})

// setupTestAPI(API)

export async function request(
    endpoint: string,
    method: string = 'GET',
    headers?: AxiosRequestHeaders,
    body?: Record<string, any> | string,
    showMessage: boolean = false,
    sucessMessage?: string,
    showError: boolean = true,
    withCredentials?: boolean,
    responseType: ResponseType = 'json'
): Promise<any> {
    let success: boolean
    let data = omitDeep(body)

    return API({
        withCredentials: withCredentials == null ? true : withCredentials,
        url: endpoint,
        method,
        headers,
        responseType,
        data,
    })
        .then((response) => {
            if (responseType === 'blob') {
                return {
                    success: true,
                    data: response.data,
                }
            }
            if (showMessage) {
                if (sucessMessage) toast.success(sucessMessage)
                else if (response.data?.message) toast.success(response.data?.message)
                else toast.success(JSON.stringify(response.data))
            }
            return {
                success: true,
                data: response.data,
            }
        })
        .catch((e) => {
            if (e.response) {
                let errorMessage = e.response.data?.message

                if (!errorMessage) {
                    switch (e.response.status) {
                        case 401:
                            errorMessage = 'Unauthorized! Please login again.'
                            break
                        case 403:
                            errorMessage = "Forbidden! You don't have permission for this action."
                            break
                        case 404:
                            errorMessage = 'Not found! The resource you are looking for does not exist.'
                            break
                        default:
                            errorMessage = 'An unexpected error occurred!'
                            break
                    }
                }

                if (showError) {
                    toast.error(errorMessage)
                }

                return {
                    success: false,
                    data: e.response.data,
                }
            }
            if (showError) {
                toast.error('Server error! Please try again later.')
            }

            return {
                success: false,
            }
        })
}

export function upload(path: string, file: FormData, setUploadPercent: (percent: number) => void) {
    return new Promise<any>((resolve) => {
        const xhr = new XMLHttpRequest()

        xhr.upload.onprogress = (event) => {
            setUploadPercent((event.loaded / event.total) * 100)
        }

        xhr.onloadend = () => {
            return resolve(xhr.response)
        }

        xhr.open('POST', BASE_API_URL + path)
        xhr.send(file)
    })
}
