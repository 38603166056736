//* ======= Libraries
import React, { useEffect, useState } from 'react'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import StyledSlimInput from 'components/styled/StyledSlimInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
//* ======= Custom logic
//* ======= Assets and styles

export type ShadowType = {
    color: string
    x: number
    y: number
    blur: number
}

const defaultShadowValues: ShadowType = {
    color: '#000000ff',
    x: 5,
    y: 5,
    blur: 10,
}

type Props = {
    defaultValue?: ShadowType
    onChange: (v: ShadowType) => void
    disabled?: boolean
}

function ShadowPicker({ disabled = false, defaultValue, onChange }: Props) {
    const [value, setValue] = useState<ShadowType | null>(null)

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
        } else {
            setValue(defaultShadowValues)
        }
    }, [])

    const handleFieldUpdate = (field: string) => (value: any) => {
        setValue((prevValue: ShadowType | null) => {
            if (prevValue == null) return null
            const temp = { ...prevValue, [field]: value }
            onChange(temp)
            return temp
        })
    }

    return (
        <React.Fragment>
            {value && (
                <Stack gap={1}>
                    {/* Color picker
                        ========================================= */}
                    <ColorPicker
                        disabled={disabled}
                        value={value?.color}
                        onChange={handleFieldUpdate('color')}
                    />

                    {/* Axis and blur controls
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={1}>
                        {/* X-axis
                            ========================================= */}
                        <Stack gap={0.5}>
                            <Typography fontSize={14}>X</Typography>

                            <StyledSlimInput
                                disabled={disabled}
                                value={value.x}
                                onChange={(e) => handleFieldUpdate('x')(e.target.value)}
                                sx={{
                                    width: 56,
                                }}
                            />
                        </Stack>

                        {/* Y-axis
                            ========================================= */}
                        <Stack gap={0.5}>
                            <Typography fontSize={14}>Y</Typography>

                            <StyledSlimInput
                                disabled={disabled}
                                value={value.y}
                                onChange={(e) => handleFieldUpdate('y')(e.target.value)}
                                sx={{
                                    width: 56,
                                }}
                            />
                        </Stack>

                        {/* Blur
                            ========================================= */}
                        <Stack gap={0.5}>
                            <Typography fontSize={14}>Blur</Typography>

                            <StyledSlimInput
                                disabled={disabled}
                                value={value.blur}
                                onChange={(e) => handleFieldUpdate('blur')(e.target.value)}
                                sx={{
                                    width: 56,
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </React.Fragment>
    )
}

export default ShadowPicker
