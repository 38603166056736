import { Question } from 'survey-core'
import { TagType } from 'types/resources'
import { SurveyApis as Apis, parseUrl } from './Apis'
import { NodeType } from './NodeService'
import { CreatorType, SearchResponseType } from './ProjectService'
import { BaseResponseType, request } from './request'
import { Layouts } from 'react-grid-layout'
import { GetSurveyInstanceResponseType } from './SurveyInstanceService'

export type ReminderScheduleType = {
    afterInvite?: number
    beforeDeadline?: number
    weekdaysOnly?: boolean
    time?: Date
    hasMax?: boolean
    maxSend?: number
    minInterval?: number
}

export type SurveyScheduleType = {
    duration: number
    interval: number
    isAdvanced: boolean
    occurrence: number
    numberOfInstances?: number
    days?: number[][]
    minGap?: number
    manual?: { start: Date; end: Date }[]
}

export type SurveyBasicType = {
    id?: number
    title: string
    description?: string
    isTemplate?: boolean
    tags?: Array<TagType>
    creator?: CreatorType
    rules?: number
    participants?: number
    createdOn?: Date
    status?: string
    favourite?: boolean
    thumbnail?: string
    rating?: number
    schedule?: SurveyScheduleType
    reminderSchedule?: ReminderScheduleType
    notificationSummary?: {
        invitationSummaryText: string
        reminderSummaryText: string
    }
    recurring?: boolean
    hasReport?: boolean
    surveyInstaceStatus?: { status: string; count: number }[]
    numberOfParticipants?: number
    numberOfRules?: number
    languages?: string[]
    defaultLanguage?: string
    publicAccess?: boolean
    publicAccessLink?: string
    openParticipation?: boolean
    startDate?: Date
}

export type CreateSurveyRequestType = {
    title: string
    description?: string
    template?: number
}

//Create a new survey
//Template: provide template id to create from template
export const CreateSurveysService: (
    pid: number | string,
    survey: CreateSurveyRequestType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, survey, showMessage = false) => {
    return request(parseUrl(Apis.Create, { pid }), 'POST', undefined, survey, showMessage)
}

//Create a new survey profile for a given language
export const CreateSurveysProfileService: (
    pid: number | string,
    sid: number | string,
    language: string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, language, showMessage = false) => {
    return request(parseUrl(Apis.CreateSurveyProfile, { pid, sid }), 'POST', undefined, { language }, showMessage)
}

//update survey profile
export const UpdateSurveyProfileService: (
    pid: number | string,
    sid: number | string,
    profile: UpdateSurveyProfileRequestType,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<BaseResponseType> = async (pid, sid, profile, showMessage = false, sucessMessage) => {
    return request(
        parseUrl(Apis.UpdateSurveyProfile, { pid, sid }),
        'PUT',
        undefined,
        profile,
        showMessage,
        sucessMessage
    )
}

//update survey Reminder Schedule
export const UpdateSurveyReminderScheduleService: (
    pid: number | string,
    sid: number | string,
    schedule: any,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<BaseResponseType> = async (pid, sid, schedule, showMessage = false, sucessMessage) => {
    return request(
        parseUrl(Apis.UpdateSurveyReminderSchedule, { pid, sid }),
        'PUT',
        undefined,
        schedule,
        showMessage,
        sucessMessage
    )
}

export type UpdateSurveyProfileRequestType = {
    emailTemplate: string
    smsTemplate: string
    emailReminder: string
    smsReminder: string
    emailApproval: string
    smsApproval: string
    invitationSubject: string
    reminderSubject: string
    autoInviteEmail: boolean
    autoInviteSms: boolean
    autoReminderEmail: boolean
    autoReminderSms: boolean
    twoLevelApproval: boolean
    approverEmail: string
    approverSms: string
}

//Get Survey profile by language
export const GetSurveysProfileService: (
    pid: number | string,
    sid: number | string,
    language?: string,
    showMessage?: boolean
) => Promise<GetSurveyProfileResponseType> = async (pid, sid, language = 'en', showMessage = false) => {
    return request(
        parseUrl(Apis.GetSurveyProfile, { pid, sid, language: 'language=en' }),
        'GET',
        undefined,
        undefined,
        showMessage
    )
}

export type SurveyProfileType = UpdateSurveyProfileRequestType & { language: string; surveyProfileId: number }

export type GetSurveyProfileResponseType = {
    success: boolean
    data: SurveyProfileType
}

//Get All surveys of a given project
export const GetSurveysService: (pid: number | string, showMessage?: boolean) => Promise<GetSurveysResponse> = async (
    pid,
    showMessage = false
) => {
    return request(parseUrl(Apis.GetAll, { pid }), 'GET', undefined, undefined, showMessage)
}

export type GetSurveysResponse = {
    success: boolean
    data: Array<SurveyBasicType>
}

// Get Survey with Id
export const GetSurveyService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<GetSurveyResponse> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.Get, { pid, sid }), 'GET', undefined, undefined, showMessage)
}
export type GetSurveyResponse = {
    success: boolean
    data: SurveyBasicType
}

// update Survey title by Id
export const UpdateSurveyTitleService: (
    pid: number | string,
    sid: number | string,
    title: { title: string },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, title, showMessage = false) => {
    return request(parseUrl(Apis.UpdateTitle, { pid, sid }), 'PUT', undefined, title, showMessage)
}

//Delete a survey using survey id
//isForced set true to cascade delete
export const DeleteSurveyService: (
    pid: number | string,
    sid: number | string,
    isForced?: boolean,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, isForced = true, showMessage = false) => {
    return request(
        parseUrl(Apis.Delete, { pid, sid, force: isForced ? 'force=true' : 'force=false' }),
        'DELETE',
        undefined,
        undefined,
        showMessage
    )
}

//Delete all the participants in ids array
//isForced set true to cascade delete
export const DeleteSurveyParticipants: (
    pid: number | string,
    sid: number | string,
    ids: { id: number }[],
    isForced?: boolean,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, ids: { id: number }[], isForced = true, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteParticipants, { pid, sid, force: isForced ? 'force=true' : 'force=false' }),
        'DELETE',
        undefined,
        ids,
        showMessage
    )
}

//Add all the participants in ids array
export const CreateSurveyParticipants: (
    pid: number | string,
    sid: number | string,
    ids: { id: number }[],
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, ids: { id: number }[], showMessage = false) => {
    return request(parseUrl(Apis.CreateParticipants, { pid, sid }), 'POST', undefined, ids, showMessage)
}

//Get survey participants
export const GetSurveyParticipants: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<SurveyParticipantTypeResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.GetParticipants, { pid, sid }), 'GET', undefined, undefined, showMessage)
}

export type SurveyParticipantType = {
    primaryKey: number
    nodeAccountId: number
    surveyID: number
    account: {
        primaryKey: number
        name: string
        email: string
        phoneNumber: string
        avatar: string
    }
    node: {
        id: number
        title: string
    }
}

export type SurveyParticipantTypeResponseType = {
    success: boolean
    data: SurveyParticipantType[]
}

// Send reminder email for a give survey
export const SendSurveyReminderService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.SendReminder, { pid, sid }), 'POST', undefined, undefined, showMessage)
}

//update survey settings schedule
export const UpdateSurveySettingsScheduleService: (
    pid: number | string,
    sid: number | string,
    settings: SurveySettingsSchedyleType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, settings, showMessage = false) => {
    return request(parseUrl(Apis.UpdateSettingsSchedule, { pid, sid }), 'PUT', undefined, settings, showMessage)
}

export type SurveySettingsSchedyleType = {
    startDate: Date
    recurring: boolean
    scheduling: SurveyScheduleType
}

//update survey settings general
export const UpdateSurveySettingsGeneralService: (
    pid: number | string,
    sid: number | string,
    settings: SurveySettingsGeneralType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, settings, showMessage = false) => {
    return request(parseUrl(Apis.UpdateSettingsGeneral, { pid, sid }), 'PUT', undefined, settings, showMessage)
}

export type SurveySettingsGeneralType = {
    publicAccess: boolean
    publicLink: string
    openParticipation: boolean
}

//Get Survey settings
export const GetSurveysSettingsService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<GetSurveySettingsResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.GetSettings, { pid, sid }), 'GET', undefined, undefined, showMessage)
}

export type GetSurveySettingsType = {
    surveyId: number
    title: string
    settings: SurveySettingsSchedyleType
}
export type GetSurveySettingsResponseType = {
    success: boolean
    data: GetSurveySettingsType
}

//Get all survey questions
export const GetSurveyQuestionService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<GetSurveyQuestionResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.GetAllQuestions, { pid, sid }), 'GET', undefined, undefined, showMessage)
}

export type SurveyQuestionType = {}

export type GetSurveyQuestionResponseType = {
    success: boolean
    data: { Questions: Question[] }
}

//Get survey.js json
export const GetSurveyJsonService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<GetSurveyQuestionResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.GetSurveyJson, { pid, sid }), 'GET', undefined, undefined, showMessage)
}

//Save survey.js json
export const UpdateSurveyJsonService: (
    pid: number | string,
    sid: number | string,
    surveyJson: string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, surveyJson, showMessage = false) => {
    return request(parseUrl(Apis.UpdateSurveyJson, { pid, sid }), 'PUT', undefined, { surveyJson }, showMessage)
}

// * Rules

export type RuleActionKind = 'email' | 'survey' | 'slack' | 'giftcard' | 'stop_rest' | 'active_next' | 'node_action'

export type SurveyActionType = {
    surveys?: number[]
}

export type EmailActionType = {
    emails: string[]
    message: string
}

export type SlackActionType = {
    hook?: string
}

export type GiftCardActionType = {
    api?: string
    amount?: number
    isRandom?: boolean
    every?: number
    total?: number
}

export type RuleNodeActionType = {
    title: string
    description: string
    assignedTo?: number[]
    notifyAssignees: boolean
    dueDate: number
    priority: 'Low' | 'Medium' | 'High' | 'Critical'
}

export type ActionDetails =
    | SurveyActionType
    | EmailActionType
    | SlackActionType
    | GiftCardActionType
    | RuleNodeActionType

export type ActionType = {
    primaryKey: number | null
    type: RuleActionKind | null
    details: ActionDetails | null
    surveyRuleID: number | null
}

export type DetailedActionType =
    | { type: 'survey'; details: SurveyActionType }
    | { type: 'email'; details: EmailActionType }
    | { type: 'node_action'; details: RuleNodeActionType }
    | { type: 'slack'; details: SlackActionType }
    | { type: 'giftcard'; details: GiftCardActionType }
    | ({ type: 'stop_rest' | 'active_next'; details: null } & Omit<ActionType, 'type' | 'details'>)

export type OperandMode = 'question' | 'node attribute'

export type RuleQuestionType =
    | 'boolean'
    | 'file'
    | 'signaturepad'
    | 'checkbox'
    | 'tagbox'
    | 'comment'
    | 'imagepicker'
    | 'expression'
    | 'text'
    | 'multipletext'
    | 'dropdown'
    | 'radiogroup'
    | 'matrix'
    | 'matrixdropdown'
    | 'networkquestion'
    | 'ranking'
    | 'rating'
    | 'address_question'

export type RuleOperand = {
    mode: OperandMode
    questionKey: string
    questionType: RuleQuestionType
    column?: string
    columnType?: RuleQuestionType
    row?: string
}

export type RuleOperator =
    | 'empty'
    | 'notEmpty'
    | 'equals'
    | 'notEquals'
    | 'gt'
    | 'gte'
    | 'lt'
    | 'lte'
    | 'contains'
    | 'notContains'
    | 'anyOf'
    | 'allOf'

export type RuleConditionType = {
    aggregation: 'avg' | 'sum' | null
    lhsOperand: RuleOperand | null
    operator: RuleOperator | null
    rhsOperand: any | null
}

export type SurveyRuleType = {
    primaryKey: null | number
    condition: RuleConditionType[]
    type: 'individual' | 'aggregation'
    surveyID: null | number
    actionDTOs: DetailedActionType[]
}

export type GetSurveyRulesResponseType = {
    success: boolean
    data: SurveyRuleType[]
}

export const CreateSurveyRulesService: (
    pid: number | string,
    sid: number | string,
    rule: SurveyRuleType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, rule, showMessage = false) => {
    return request(parseUrl(Apis.CreateRule, { pid, sid }), 'POST', undefined, rule, showMessage)
}
export const GetSurveyRulesService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<GetSurveyRulesResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.GetRules, { pid, sid }), 'GET', undefined, undefined, showMessage)
}

export const UpdateSurveyRulesService: (
    pid: number | string,
    sid: number | string,
    rid: number | string,
    rule: SurveyRuleType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, rid, rule, showMessage = false) => {
    return request(parseUrl(Apis.UpdateRule, { pid, sid, rid }), 'PUT', undefined, rule, showMessage)
}

export const DeleteSurveyRulesService: (
    pid: number | string,
    sid: number | string,
    rid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, rid, rule, showMessage = false) => {
    return request(parseUrl(Apis.DeleteRule, { pid, sid, rid }), 'DELETE', undefined, undefined, showMessage)
}

////////////////////////////survey logs//////////////////////

export type BaseLogType = {
    id: number
    action: string
    details: string
    inputs: string
    status: boolean
}

export type AdminLogType = BaseLogType & {
    adminUser: {
        primaryKey: number
        name: string
        email: string
        phoneNumber: string
        avatar: string
    }
}

export type ParticipantLogType = BaseLogType & {
    participant: {
        primaryKey: number
        name: string
        email: string
        phoneNumber: string
        avatar: string
    }
    node: {
        id: number
        title: string
    }
}

export type GetSurveyLogsResponseType = {
    success: boolean
    data: {
        data: ParticipantLogType[]
        rowCount: number
    }
}

export type GetSurveyAdminLogsResponseType = {
    success: boolean
    data: {
        data: AdminLogType[]
        rowCount: number
    }
}

export type GetSurveyLogsRequestType = {
    pageNumber: number
    pageSize: number
    search?: string
    userNodeId?: number
    userId?: number
    status?: boolean
}

export const GetSurveyLogsService: (
    pid: number | string,
    sid: number | string,
    filter: GetSurveyLogsRequestType,
    showMessage?: boolean
) => Promise<GetSurveyLogsResponseType> = async (
    pid,
    sid,
    filter = { pageNumber: 0, pageSize: 25 },
    showMessage = false
) => {
    return request(parseUrl(Apis.GetLogs, { pid, sid }), 'POST', undefined, filter, showMessage)
}

export const GetSurveyAdminLogsService: (
    pid: number | string,
    sid: number | string,
    filter: GetSurveyLogsRequestType,
    showMessage?: boolean
) => Promise<GetSurveyAdminLogsResponseType> = async (
    pid,
    sid,
    filter = { pageNumber: 0, pageSize: 25 },
    showMessage = false
) => {
    return request(parseUrl(Apis.GetAdminLogs, { pid, sid }), 'POST', undefined, filter, showMessage)
}

////////////////////////////survey suggestions//////////////////////

export type GetSurveySuggestionsResponseType = {
    success: boolean
    data: { [key: string]: NodeType[] }
}

export const GetSurveySuggestionsService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<GetSurveySuggestionsResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.GetSurveySuggestions, { pid, sid }), 'GET', undefined, undefined, showMessage)
}

/////////////////////////////////////Tags//////////////////////////////
export type CreateSurveyTagResponseType = {
    success: boolean
    data: {
        primaryKey: number
    }
}

export const CreateSurveyTag: (
    pid: number | string,
    sid: number | string,
    tag: TagType,
    showMessage?: boolean
) => Promise<CreateSurveyTagResponseType> = async (pid, sid, tag, showMessage = false) => {
    return request(parseUrl(Apis.AddTag, { pid, sid }), 'POST', undefined, tag, showMessage)
}

export const RemoveSurveyTag: (
    pid: number | string,
    sid: number | string,
    tid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, tid, showMessage = false) => {
    return request(parseUrl(Apis.RemoveTag, { pid, sid, tid }), 'DELETE', undefined, undefined, showMessage)
}

// results

export type SurveyInstanceBasicType = {
    surveyInstanceId: number
    surveyName: string
    status: string
    round: number
    startDate: Date
    endDate: Date
    respondent: {
        email?: string
        name?: string
        phoneNumber?: string
        avatar?: string
        primaryKey: number
    }
    accessToken: string
    timeSpent: number
    passCode: string
    others?: { [ket: string]: any }
    reminderCount: number
}

export type GetSurveyResultsResponseType = {
    success: boolean
    data: SurveyInstanceBasicType[]
}

export const GetSurveyResults: (
    pid: number | string,
    sid: number | string,
    round?: number,
    showMessage?: boolean
) => Promise<GetSurveyResultsResponseType> = async (pid, sid, round, showMessage = false) => {
    return request(
        parseUrl(Apis.Result, { pid, sid, round: round == null ? '' : `round=${round}` }),
        'GET',
        undefined,
        undefined,
        showMessage
    )
}

// publish survey

export const PublishSurveyService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.Publish, { pid, sid }), 'PUT', undefined, undefined, showMessage)
}

// pause survey
export const PauseSurveyService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.Pause, { pid, sid }), 'PUT', undefined, undefined, showMessage)
}

// resume survey
export const ResumeSurveyService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, showMessage = false) => {
    return request(parseUrl(Apis.Resume, { pid, sid }), 'PUT', undefined, undefined, showMessage)
}

export const SearchSurveysService: (
    pid: string | number,
    term: string,
    showMessage?: boolean
) => Promise<SearchResponseType> = async (pid, term, showMessage = false) => {
    return request(parseUrl(Apis.Search, { term, pid }), 'GET', undefined, undefined, showMessage)
}

// Export

export type FileResponseType = {
    success: boolean
    data?: any
}
export const ExportDataDictionaryService: (
    pid: string | number,
    sid: string | number,
    showMessage?: boolean
) => Promise<FileResponseType> = async (pid, sid, showMessage = false) => {
    return request(
        parseUrl(Apis.ExportDataDictionary, { sid, pid }),
        'GET',
        undefined,
        undefined,
        showMessage,
        undefined,
        undefined,
        undefined,
        'blob'
    )
}

export type ExportSettingsType = {
    isAnonymous: boolean
    excludeIsloatedNodes: boolean
}

export const ExportSurveyResponsesService: (
    pid: string | number,
    sid: string | number,
    options: ExportSettingsType,
    showMessage?: boolean
) => Promise<FileResponseType> = async (pid, sid, options, showMessage = false) => {
    return request(
        parseUrl(Apis.ExportResponses, { sid, pid }),
        'POST',
        undefined,
        options,
        showMessage,
        undefined,
        undefined,
        undefined,
        'blob'
    )
}

export const ExportSurveyReportService: (
    pid: string | number,
    sid: string | number,
    random?: boolean,
    size?: number,
    showMessage?: boolean
) => Promise<FileResponseType> = async (pid, sid, random = false, size = 1, showMessage = false) => {
    var randomString = ''
    if (random) {
        randomString = `random=true&lenght=${size}`
    }
    return request(
        parseUrl(Apis.ExportReport, { sid, pid, random: randomString }),
        'POST',
        undefined,
        undefined,
        showMessage,
        undefined,
        undefined,
        undefined
    )
}

// Sruvey Report

//update survey hasReport
export const UpdateSurveyHasReportService: (
    pid: number | string,
    sid: number | string,
    hasReport: boolean,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<BaseResponseType> = async (pid, sid, hasReport, showMessage = false, sucessMessage) => {
    return request(
        parseUrl(Apis.UpdateSurveyHasReport, { pid, sid }),
        'PUT',
        undefined,
        { hasReport },
        showMessage,
        sucessMessage
    )
}

//update survey report
export const UpdateSurveyReportService: (
    pid: number | string,
    sid: number | string,
    state: string,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<BaseResponseType> = async (pid, sid, state, showMessage = false, sucessMessage) => {
    return request(
        parseUrl(Apis.UpdateSurveyReport, { pid, sid }),
        'PUT',
        undefined,
        { state },
        showMessage,
        sucessMessage
    )
}

export type GetSurveyReportResponseType = {
    success: boolean
    data: {
        report: any
    }
}

//Get survey report
export const GetSurveyReportService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<GetSurveyReportResponseType> = async (pid, sid, showMessage = false, sucessMessage) => {
    return request(
        parseUrl(Apis.GetSurveyReport, { pid, sid }),
        'GET',
        undefined,
        undefined,
        showMessage,
        sucessMessage
    )
}

// Get Publis Survey

export type PublicSurveyType = {
    color: string
    logo?: string
    title: string
    id: number
}

export type GetPublicSurveyResponseType = {
    success: boolean
    data: PublicSurveyType
}

export const GetPublicSurveyService: (
    sid: number | string,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<GetPublicSurveyResponseType> = async (sid, showMessage = false, sucessMessage) => {
    return request(parseUrl(Apis.GetSurveyInfo, { sid }), 'GET', undefined, undefined, showMessage, sucessMessage)
}

///Get survey analytics
export type SurveyAnalyticsType = {
    question: {
        name: string
        type: string
        [key: string]: any
    }
    responses: any
}

export type SurveyAnalyticsLayoutType = {
    grid: Layouts
    filteredQuestions: string[]
    filters: any[]
}

export type GetSurveyAnalyticsResponseType = {
    success: boolean
    data: {
        data: Record<string, SurveyAnalyticsType>
        layout: SurveyAnalyticsLayoutType
    }
}

export const GetSurveyAnalyticsService: (
    pid: number | string,
    sid: number | string,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<GetSurveyAnalyticsResponseType> = async (pid, sid, showMessage = false, sucessMessage) => {
    return request(
        parseUrl(Apis.GetSurveyAnalytics, { pid, sid }),
        'GET',
        undefined,
        undefined,
        showMessage,
        sucessMessage
    )
}

export const UpdateSurveyAnalyticsLayoutService: (
    pid: number | string,
    sid: number | string,
    layout: SurveyAnalyticsLayoutType,
    showMessage?: boolean,
    sucessMessage?: string
) => Promise<BaseResponseType> = async (pid, sid, layout, showMessage = false, sucessMessage) => {
    return request(
        parseUrl(Apis.UpdateSurveyAnalytics, { pid, sid }),
        'PUT',
        undefined,
        { request: JSON.stringify(layout) },
        showMessage,
        sucessMessage
    )
}

// export survey network

export type NetworkType = {
    nodes: {
        id: string | number
        key: string | number
        name?: string
        [key: string]: any
    }

    edges: {
        source: string | number
        target: string | number
        relationship?: string
        [key: string]: any
    }
}

type SurveyNetworkExportResponseType = {
    success: boolean
    data: NetworkType
}

export const ExportSurveyNetworkService: (
    pid: string | number,
    sid: string | number,
    options: ExportSettingsType,
    showMessage?: boolean
) => Promise<SurveyNetworkExportResponseType> = async (pid, sid, options, showMessage = false) => {
    return request(
        parseUrl(Apis.ExportNetwork, { sid, pid }),
        'POST',
        undefined,
        options,
        showMessage,
        undefined,
        undefined,
        undefined,
        undefined
    )
}

type SendTestEmailRequest = {
    type: 'invitation' | 'reminder'
}

export const SendTestEmailService: (
    pid: string | number,
    sid: string | number,
    data: SendTestEmailRequest,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, sid, data, showMessage = false) => {
    return request(
        parseUrl(Apis.SendTestEmail, { sid, pid }),
        'POST',
        undefined,
        data,
        showMessage,
        undefined,
        undefined,
        undefined,
        undefined
    )
}

// get open participation survey
export const getOpenParticipationSurveyService: (
    pid: string | number,
    sid: string | number,
    showMessage?: boolean,
    showError?: boolean
) => Promise<GetSurveyInstanceResponseType> = async (pid, sid, showMessage = false, showError = true) => {
    return request(
        parseUrl(Apis.GetOpenParticipationSurvey, { pid, sid }),
        'GET',
        undefined,
        undefined,
        showMessage,
        '',
        showError
    )
}
