//* ======= Libraries
import React, { useState, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { toast } from 'react-toastify'
//* ======= Custom logic
import {
    CreateProjectRequestType,
    UpdateProject,
    UpdateProjectRequestType,
    UpdateProjectThumbnail,
} from 'services/ProjectService'
//* ======= Assets and styles
import DeleteIcon from '@mui/icons-material/Delete'
import { ProjectContext } from 'contexts/ProjectContext'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import SettingsColorSelector from 'features/settings/SettingsColorSelector'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import ImageUpload from 'components/ImageUpload/ImageUpload'
import InfoIcon from '@mui/icons-material/Info'
import { useParams } from 'react-router-dom'
import ColorPicker from 'components/widgets/ColorPicker'
import { Box } from '@mui/material'

function ProjectSettings() {
    const { pid } = useParams()
    const { project, drawerContent, fetchProject } = useContext(ProjectContext)

    const selectOptions = [
        {
            label: 'Fixed population',
            value: 'fixed',
        },
        {
            label: 'Snow Ball',
            value: 'snowball',
        },
    ]

    //form
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateProjectRequestType>()

    const updateProject = () => {
        handleSubmit(
            //Valid
            (data) => {
                if (project && project.primaryKey)
                    UpdateProject(project?.primaryKey, data)
                        .then((res) => {
                            if (res.success) {
                                toast.success('Changes Saved!')
                            }
                        })
                        .catch((e) => {})
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    const setImage = (data: string) => {
        UpdateProjectThumbnail(pid!, { dataURL: data })
            .then((res) => {
                if (res.success) {
                    fetchProject()
                    toast.success('Changes Saved!')
                }
            })
            .catch((e) => {
                toast.error('Error saving changes!')
            })
    }

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                {project == null ? (
                    <SnaCircularLoading />
                ) : (
                    <Stack
                        gap={2}
                        height="100%"
                        width="100%"
                        py={4}
                        px={4}
                        sx={(theme) => ({
                            overflowY: 'auto',

                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                        })}
                        className="u-scrollbar"
                    >
                        {/* Project details
				        ========================================= */}
                        <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                            rowGap={2}
                            sx={(theme) => ({
                                paddingBottom: 3,
                                borderBottom: `1px solid ${theme.palette.common.border_2}`,
                            })}
                        >
                            {/* Image and save button
				            ========================================= */}
                            <Grid
                                item
                                zeroMinWidth
                                container
                                columnGap={2}
                                justifyContent="space-between"
                                alignItems="center"
                                mb={3}
                            >
                                {/* Image upload
				                ========================================= */}
                                <Stack direction="row" alignItems="center" gap={1.5}>
                                    <Avatar
                                        src={project.thumbnail}
                                        alt={project.title}
                                        sx={{
                                            height: 64,
                                            width: 64,
                                        }}
                                    />

                                    <BaseButton
                                        onClick={() => {
                                            document.getElementById('project-image-input')?.click()
                                        }}
                                    >
                                        Change Project Image
                                    </BaseButton>
                                    <Tooltip title="Remove project image">
                                        <IconButton
                                            onClick={() => {
                                                setImage('')
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                    <ImageUpload inputId="project-image-input" onComplete={setImage} />
                                </Stack>

                                {/* Save button
				                ========================================= */}
                                <BaseButton
                                    label="Save"
                                    onClick={updateProject}
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        width: 100,
                                    }}
                                />
                            </Grid>

                            {/* Project title
				            ========================================= */}

                            <Grid item zeroMinWidth container columnGap={6} mb={1.5}>
                                <Grid item zeroMinWidth xs>
                                    <Controller
                                        name="title"
                                        control={control}
                                        defaultValue={project?.title}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <BaseFilledTextField
                                                label="Project Title"
                                                required
                                                variant="filled"
                                                value={value}
                                                fullWidth
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                        rules={{
                                            required: 'Project Title is required',
                                            minLength: {
                                                value: 3,
                                                message: 'The title should be at least 3 characters',
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: 'The should not be more than 50 characters',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item zeroMinWidth xs>
                                    <Stack direction="row" alignItems="center" gap={1.5}>
                                        <Controller
                                            name="smsSenderId"
                                            control={control}
                                            defaultValue={project?.smsSenderId}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <BaseFilledTextField
                                                    label="SMS Sender ID"
                                                    required
                                                    variant="filled"
                                                    value={value}
                                                    fullWidth
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                            rules={{
                                                required: 'SMS Sender ID is required',
                                                minLength: {
                                                    value: 3,
                                                    message: 'The title should be at least 3 characters',
                                                },
                                                maxLength: {
                                                    value: 20,
                                                    message: 'The should not be more than 20 characters',
                                                },
                                                pattern: {
                                                    message: 'Letters only with no number, symbol, or space',
                                                    value: /^[a-zA-Z]+$/,
                                                },
                                            }}
                                        />
                                        <Tooltip
                                            arrow
                                            placement="left-end"
                                            title={
                                                <Typography>
                                                    The ability to use a custom Sender ID for SMS messages is a feature
                                                    that's supported only in certain countries. If you provide a Sender
                                                    ID, it will appear as the sender on the recipient's device. However,
                                                    please note: In countries or regions where custom Sender IDs are not
                                                    supported, messages might be delivered from a random phone number,
                                                    short code, or might not include the specified Sender ID. The actual
                                                    delivery outcome might vary based on the recipient's mobile carrier
                                                    and local regulations. We recommend checking the local regulations
                                                    and carrier restrictions of your target audience before using a
                                                    custom Sender ID to ensure optimal message delivery. Please ensure
                                                    your Sender ID is compliant with any local requirements and
                                                    understand that its appearance on the recipient's device cannot be
                                                    guaranteed universally.
                                                </Typography>
                                            }
                                        >
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                            </Grid>

                            {/* Project field
				            ========================================= */}
                            <Grid item zeroMinWidth container columnGap={6} mb={1.5}>
                                <Grid item zeroMinWidth xs>
                                    <Controller
                                        name="category"
                                        control={control}
                                        defaultValue={project?.category}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <BaseSelectWithLabel
                                                label="Project Category"
                                                options={selectOptions}
                                                required
                                                value={value}
                                                fullWidth
                                                onChange={onChange}
                                                error={!!error}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item zeroMinWidth xs>
                                    <Controller
                                        name="emailSenderId"
                                        control={control}
                                        defaultValue={project?.emailSenderId}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <BaseFilledTextField
                                                label="Outgoing Email Address"
                                                required
                                                variant="filled"
                                                value={value}
                                                fullWidth
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                        rules={{
                                            required: 'Outgoing Email Address is required',
                                            pattern: {
                                                message: 'Not a valid email address',
                                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Color Picker
				            ========================================= */}
                            <Grid item zeroMinWidth container columnGap={6}>
                                <Grid item zeroMinWidth xs>
                                    <Typography fontSize={16} fontWeight={400} color="secondary">
                                        Project color theme
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item zeroMinWidth container columnGap={6}>
                                <Grid item zeroMinWidth xs>
                                    <Box sx={{ maxWidth: '300px' }}>
                                        <Controller
                                            name="color"
                                            control={control}
                                            defaultValue={project?.color}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <ColorPicker isPopover={true} value={value} onChange={onChange} />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* Description and language
				            ========================================= */}
                            <Grid item zeroMinWidth container columnGap={6}>
                                <Grid item zeroMinWidth xs>
                                    <Typography fontSize={16} fontWeight={400} color="secondary">
                                        Description
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item zeroMinWidth container columnGap={6}>
                                {/* Description
				                ========================================= */}
                                <Grid item zeroMinWidth xs>
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue={project?.description}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <BaseFilledTextField
                                                hiddenLabel
                                                multiline
                                                rows={6}
                                                variant="filled"
                                                value={value}
                                                fullWidth
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
            </PageCard.Body>
        </PageCard>
    )
}

export default ProjectSettings
