//* ======= Libraries
import React, { useState, useEffect } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles

interface IDeleteNodeDialog {
    onConfirm?: (force?: boolean) => void
    onClose?: () => void
}

function DeleteNodeDialog({ onConfirm, onClose }: IDeleteNodeDialog) {
    const [isForce, setIsForce] = useState<boolean>(false)
    const [text, setText] = useState<string>('')
    useEffect(() => {
        setIsForce(false)
    }, [])

    return (
        <Stack
            gap={2}
            sx={{
                height: '100%',
                py: 3,
                px: 4,
            }}
        >
            {/* Title
				========================================= */}
            <Typography alignSelf="flex-start" fontSize={36} fontWeight={600} color="common.fuschia80">
                Delete Node
            </Typography>

            <Typography fontSize={16} fontWeight={400} color="common.text_1">
                Are you sure you want to remove the selected node?
            </Typography>

            <Typography fontSize={16} fontWeight={400} color="common.text_1">
                By pressing the remove button, all the selected candidates will be removed. Are you sure you
                want to perform this action?
            </Typography>

            <Typography paragraph fontSize={16} fontWeight={400} color="common.text_1" marginBottom={1}>
                {'Write '}
                <Box component="span" fontWeight={600}>
                    REMOVE ALL
                </Box>
                {
                    ' in the textbox below to proceed with the action in the textbox below to proceed with the action.'
                }
            </Typography>

            {/* Input
				========================================= */}
            <BaseFilledTextField
                hiddenLabel
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
                sx={{
                    alignSelf: 'stretch',
                }}
            />

            {/* Force delete checkbox
				========================================= */}
            <FormGroup
                sx={{
                    marginBottom: 1,
                }}
            >
                <FormControlLabel
                    label="Force Delete"
                    control={<Checkbox checked={isForce} onChange={() => setIsForce((f) => !f)} />}
                    sx={{
                        alignSelf: 'flex-start',

                        marginRight: 0,
                    }}
                />
            </FormGroup>

            {/* Controls
				========================================= */}
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                {/* Cancel button
					========================================= */}
                <BaseButton
                    label="Cancel"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    color="warning"
                    size="large"
                    width="186px"
                />

                {/* Publish button
					========================================= */}
                <BaseButton
                    label="Delete"
                    disabled={text !== 'REMOVE ALL'}
                    onClick={(e) => onConfirm && onConfirm(isForce)}
                    variant="contained"
                    color="warning"
                    size="large"
                    width="186px"
                />
            </Stack>
        </Stack>
    )
}

export default DeleteNodeDialog
