import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import Link from '@mui/material/Link'
import { GridColDef } from '@mui/x-data-grid-pro'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import BaseFilledTextField from 'components/base/BaseFilledTextField'

import SampleOneImage from 'assets/images/sample1.png'
import { ResourceType } from 'types/resources'
import TemplateCardBoxed from 'components/template-card/TemplateCardBoxed'
import SNALogo from 'assets/icons/sna_logo.svg'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { ExportSurveyNetworkService, GetSurveysService, SurveyBasicType } from 'services/SurveyService'
import { useParams } from 'react-router-dom'
import WebWorker from 'helpers/webWorkerHelper'
import {
    NetworkVizContextType,
    NetworkVizInitialState,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'

const SampleCsv = require('assets/sample/report.csv')

type Props = {
    opne: boolean
    onClose: () => void
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const Sample_Datasource: ResourceType[] = [
    {
        id: 1,
        title: 'Sample 1',
        image: SampleOneImage,
        creator: {
            name: 'SNA',
            avatar: SNALogo,
        },
    },
]

export default function AddDataSourceDialog({ opne, onClose }: Props) {
    const {
        nodes,
        nodeStyle,
        nodeGroupBy,
        edgeRenders,
        analytics,
        layoutKind,
        analyticsSettings,
        nodeRenders,
        edges,
        edgeStyle,
        edgeGroupBy,
        filters,
        nodeInteractionConfig,
    } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()

    const loadNetworkFromSurvey = async (surveyId: number) => {
        if (!pid) return
        setLoading(true)

        try {
            const res = await ExportSurveyNetworkService(pid, surveyId, {
                excludeIsloatedNodes: false,
                isAnonymous: false,
            })

            contextDispatch({ type: 'STATUS_UPDATE', payload: 'loading' })
            const addNodesWorker = new WebWorker('workers/network/add-nodes.js')

            const addNodeRes: any = await addNodesWorker.run({
                nodes,
                edgeRenders,
                nodeGroupBy,
                filters,
                analytics,
                nodeStyle,
                newNodes: res.data.nodes,
                idField: 'id',
                layoutKind,
            })

            contextDispatch({
                type: 'NODE_ADD',
                payload: {
                    nodes: addNodeRes.nodes,
                    nodeDataSchema: addNodeRes.nodeDataSchema,
                    nodeRenders: addNodeRes.nodeRenders,
                    nodeInfoConfig: addNodeRes.nodeInfoConfig,
                    nodeStyle: addNodeRes.nodeStyle,
                    nodeLegend: addNodeRes.nodeLegend,
                },
            })

            const addEdgesWorker = new WebWorker('workers/network/add-edges.js')

            const addEdgesRes: any = await addEdgesWorker.run({
                nodeRenders: addNodeRes.nodeRenders,
                nodes: addNodeRes.nodes,
                edges,
                edgeStyle,
                edgeGroupBy,
                newEdges: res.data.edges,
                relationship: 'relationship',
                sourceField: 'source',
                targetField: 'target',
                layoutKind,
                filters,
                isDirected: nodeInteractionConfig.directed,
                analyticsSettings: {
                    ...NetworkVizInitialState.analyticsSettings,
                    groupBy: 'relationship',
                },
                dynamicRelation: true,
            })

            contextDispatch({
                type: 'EDGE_ADD',
                payload: {
                    edges: addEdgesRes.edges,
                    edgeRenders: addEdgesRes.edgeRenders,
                    edgeLegend: addEdgesRes.edgeLegend,
                    edgeStyle: addEdgesRes.edgeStyle,
                    edgeDataSchema: addEdgesRes.edgeSchema,
                    nodeRenders: addEdgesRes.nodeRenders,
                    nodeAdjacentList: addEdgesRes.nodeAdjacentList,
                    filters: addEdgesRes.filters,
                    analyticsSettings: addEdgesRes.analyticsSettings,
                },
            })

            contextDispatch({ type: 'STATUS_UPDATE', payload: 'ready' })
        } catch (e) {
            contextDispatch({ type: 'STATUS_UPDATE', payload: 'faulty' })
        }

        onClose()
    }

    const loadNetworkFromSample = (sampleId: number) => {
        onClose()
    }

    const generateRandomNetwork = (n: number, e: number) => {
        onClose()
    }

    const { pid } = useParams()

    const [selectedItem, setSelectedItem] = useState<number>()
    const [randomSettings, setRandomSettings] = useState<{ nodes: string; edges: string }>({
        nodes: '100',
        edges: '2000',
    })

    const [selectedTab, setSelectedTab] = useState(0)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [hasData, setHasData] = useState<boolean>(false)
    const [headers, setHeaders] = useState<string[]>([])
    const [title, setTitle] = useState<string>('')
    const [rows, setRows] = useState<Array<{ [key: string]: any }>>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [idField, setIdField] = useState<string>('auto')
    const [timestampFeild, setTimestampFeild] = useState<string>('none')
    const [panelField, setPanelField] = useState<string>('none')
    const [surveys, setSurveys] = useState<SurveyBasicType[]>([])

    //fetch the surveys
    useEffect(() => {
        if (pid) {
            GetSurveysService(pid).then((res) => res.success && setSurveys(res.data))
        }
    }, [pid])

    //clear selected Item on tab change
    useEffect(() => {
        setSelectedItem(undefined)
    }, [selectedTab])

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue)
    }

    // select file
    function uploadAndParseNodes(event: React.ChangeEvent<HTMLInputElement>) {
        setLoading(true)
        if (event.target.files && event.target.files.length > 0) {
            const loadGraphWorker = new window.Worker(
                process.env.REACT_APP_BASE_URL + 'workers/report/parse-data-worker.js'
            )
            loadGraphWorker.postMessage({ fileName: event.target.files[0] })
            loadGraphWorker.onmessage = function (e) {
                const { data, headers } = e.data

                setColumns([...headers])
                setHeaders(headers.map((h: any) => h.field))
                setHasData(true)
                setRows(data)
                setLoading(false)
            }
        }
    }

    const handleAddDataSetClick = () => {
        if (selectedTab === 1 && selectedItem != null) {
            loadNetworkFromSurvey(selectedItem)
        }
        if (selectedTab === 2 && selectedItem != null) {
            loadNetworkFromSample(selectedItem)
        }
        if (selectedTab === 3) {
            const n: number = parseInt(randomSettings.nodes)
            const e: number = parseInt(randomSettings.edges)
            if (!isNaN(n) && !isNaN(e)) generateRandomNetwork(n, e)
        }
        // addDataSet(title,rows,idField,timestampFeild,panelField,columns.map(c=>c.field),onClose);
    }

    return (
        <StyledDialog open={opne} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>Add Datasource</DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="data-source tab">
                        <Tab label="From File" {...a11yProps(0)} />
                        <Tab label="From Surveys" {...a11yProps(1)} />
                        <Tab label="From Sample" {...a11yProps(2)} />
                        <Tab label="From Random" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                {/* Upload from files
                 */}

                {/* Upload and download buttons
				    ========================================= */}
                {selectedTab == 0 && (
                    <Stack direction="column" height="90%">
                        <Stack direction="row" alignItems="center" gap={2} marginBottom={1}>
                            <BaseButton
                                variant="contained"
                                size="large"
                                label="Select File"
                                onClick={() => {
                                    document.getElementById('select-data-csv')?.click()
                                }}
                            />
                            <input
                                id="select-data-csv"
                                type="file"
                                accept=".csv, .txt"
                                onChange={uploadAndParseNodes}
                                style={{ display: 'none' }}
                            />
                            <Link href={SampleCsv} target="_blank">
                                Download sample CSV file
                            </Link>
                        </Stack>
                        {hasData && (
                            <Stack flexGrow={1}>
                                <Stack direction="row" spacing={2} width="100%">
                                    <BaseFilledTextField
                                        sx={{ flex: 0.25 }}
                                        label="Report Title"
                                        required={true}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    <BaseSelectWithLabel
                                        sx={{ flex: 0.25 }}
                                        label="ID"
                                        required={true}
                                        value={idField}
                                        onChange={(v) => setIdField(v)}
                                        options={[{ label: 'Auto Generate', value: 'auto' }, ...headers]}
                                    />
                                    <BaseSelectWithLabel
                                        sx={{ flex: 0.25 }}
                                        label="Timestamp"
                                        value={timestampFeild}
                                        onChange={(v) => setTimestampFeild(v)}
                                        options={[{ label: 'NA', value: 'none' }, ...headers]}
                                    />
                                    <BaseSelectWithLabel
                                        sx={{ flex: 0.25 }}
                                        label="Panel name"
                                        value={panelField}
                                        onChange={(v) => setPanelField(v)}
                                        options={[{ label: 'NA', value: 'none' }, ...headers]}
                                    />
                                </Stack>
                                <StyledDataGrid
                                    columns={columns}
                                    rows={rows}
                                    density="compact"
                                    getRowId={(r) => r._index}
                                />
                            </Stack>
                        )}
                    </Stack>
                )}
                {selectedTab == 1 && (
                    <Grid container gap={2} mt="10px">
                        {surveys.map((s) => (
                            <Grid key={s.id || s.title} item xs={2.7}>
                                <TemplateCardBoxed
                                    isSelected={s.id == selectedItem}
                                    data={{
                                        id: s.id || -1,
                                        title: s.title,
                                        creator: s.creator,
                                        image: s.thumbnail,
                                    }}
                                    clickHandler={() => setSelectedItem(s.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}

                {selectedTab == 2 && (
                    <Grid container gap={2} mt="10px">
                        {Sample_Datasource.map((s) => (
                            <Grid xs={2.7}>
                                <TemplateCardBoxed
                                    isSelected={s.id == selectedItem}
                                    data={s}
                                    clickHandler={() => setSelectedItem(s.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}

                {selectedTab == 3 && (
                    <Grid container spacing={2} mt="10px">
                        <Grid item xs={6}>
                            <BaseFilledTextField
                                fullWidth
                                type="number"
                                label="Number of Nodes"
                                value={randomSettings.nodes}
                                onChange={(e) => setRandomSettings((rs) => ({ ...rs, nodes: e.target.value }))}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BaseFilledTextField
                                fullWidth
                                type="number"
                                label="Number of edges"
                                value={randomSettings.edges}
                                onChange={(e) => setRandomSettings((rs) => ({ ...rs, edges: e.target.value }))}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} color="warning" variant="outlined">
                    Close
                </BaseButton>
                <BaseButton onClick={handleAddDataSetClick} color="primary" variant="contained">
                    Add
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
