//* ======= Libraries
import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ToastHelper from 'helpers/ToastHelper'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { GridColDef } from '@mui/x-data-grid-pro'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import {
    CreateProjectAccount,
    GetProjectAccounts,
    GetProjectDataAccess,
    GetProjectRoles,
    ProjectAccountCreateType,
    ProjectAccountType,
    ProjectDataAcessType,
    ProjectRoleWithPermissionType,
    RemoveProjectAccount,
    ResendProjectAccountInvitationService,
    UpdateProjectAccountDataAccessViewService,
    UpdateProjectAccountRoleService,
} from 'services/ProjectService'
import { RootContext } from 'contexts/RootContext'
import StyledDialog from 'components/dialog/StyledDialog'
import AddUsersDialog from 'features/add-users-dialog/AddUsersDialog'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import ConfirmDialog from 'features/confirm-dialog/ConfirmDialog'
import { ProjectContext } from 'contexts/ProjectContext'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { Tooltip } from '@mui/material'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
import { use } from 'echarts'

type Props = {}

function ProjectUserManagment({}: Props) {
    const { pid } = useParams()
    const { updateProjectRole } = useContext(ProjectContext)
    const { user } = useContext(RootContext)

    const navigate = useNavigate()

    const [customViews, setCustomViews] = useState<ProjectDataAcessType[]>([])
    const [roles, setRoles] = useState<ProjectRoleWithPermissionType[]>([])
    const [accounts, setAccounts] = useState<ProjectAccountType[] | null>(null)
    const [item2Remove, setItem2Remove] = useState<number | null>(null)
    const [role2Update, setRole2Update] = useState<{
        id: number
        role: { roleID: number; roleName?: string }
        name: string
    } | null>(null)
    const [dataAccess2Update, setDataAccess2Update] = useState<{
        id: number
        dataAccess: { id: number; title?: string }
        name: string
    } | null>(null)
    const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false)
    const handleOpenAddUserDialog = () => setAddUserDialogOpen(true)
    const handleCloseAddUserDialog = () => setAddUserDialogOpen(false)

    const inviteUserAccount = (users: ProjectAccountCreateType[]) => {
        if (pid) {
            const toast = new ToastHelper({
                errorMessage: 'Error inviting users!',
                successMessage: 'Users invited!',
                loadingMessage: 'Inviting users...',
            })
            CreateProjectAccount(pid, users).then((res) => {
                if (res.success) {
                    setAccounts(res.data)
                    handleCloseAddUserDialog()
                    toast.success()
                } else {
                    toast.fail()
                }
            })
        }
    }

    const resendInvitation = useCallback(
        (id: number) => {
            if (pid) {
                const toast = new ToastHelper({
                    errorMessage: 'Error sending project invitation email!',
                    successMessage: 'Project Invitation Email sent!',
                    loadingMessage: 'Sending project invitation email...',
                })
                ResendProjectAccountInvitationService(pid, id).then((res) => {
                    if (res.success) {
                        toast.success()
                    } else {
                        toast.fail()
                    }
                })
            }
        },
        [pid]
    )

    const updateUserAccount = () => {
        if (pid && role2Update) {
            const toast = new ToastHelper({
                errorMessage: 'Error updating user role!',
                successMessage: 'User role updated!',
                loadingMessage: 'Updating user role...',
            })
            UpdateProjectAccountRoleService(pid, role2Update.id, { roleID: role2Update.role.roleID }).then((res) => {
                if (res.success) {
                    const accountToRemove = accounts?.find((x) => x.primaryKey === role2Update.id)
                    // if the user who's role is getting modified is the logged in user, change the role in project context
                    if (accountToRemove && user?.primaryKey === accountToRemove.account.primaryKey) {
                        // if user not admin or data owner, go back to project page
                        if (![1, 3].includes(role2Update.role.roleID)) {
                            toast.close()
                            navigate(`/project/${pid}`)
                            updateProjectRole(role2Update.role.roleName!)
                            return
                        }
                        updateProjectRole(role2Update.role.roleName!)

                        // get the update roles this user can assign
                        fetchProjectRoles()
                    }
                    setAccounts((pv: ProjectAccountType[] | null) => {
                        if (pv == null) return null
                        const tmp = [...pv]
                        const index = tmp.findIndex((x) => x.primaryKey === role2Update.id)
                        if (index !== -1) {
                            tmp[index].role = {
                                primaryKey: role2Update.role.roleID,
                                roleName: role2Update.role.roleName!,
                            }
                        }
                        return tmp
                    })
                    setRole2Update(null)
                    toast.success()
                } else {
                    toast.fail()
                }
            })
        }
    }

    const updateUserDataAccess = () => {
        if (pid && dataAccess2Update) {
            const toast = new ToastHelper({
                errorMessage: 'Error updating user data access view!',
                successMessage: 'User data access view updated!',
                loadingMessage: 'Updating user data access view...',
            })
            UpdateProjectAccountDataAccessViewService(pid, dataAccess2Update.id, {
                dataAccessID: dataAccess2Update.dataAccess.id,
            }).then((res) => {
                if (res.success) {
                    setAccounts((pv: ProjectAccountType[] | null) => {
                        if (pv == null) return null
                        const tmp = [...pv]
                        const index = tmp.findIndex((x) => x.primaryKey === dataAccess2Update.id)
                        if (index !== -1) {
                            tmp[index].dataAccessId = dataAccess2Update.dataAccess.id
                        }
                        return tmp
                    })
                    setDataAccess2Update(null)
                    toast.success()
                } else {
                    toast.fail()
                }
            })
        }
    }

    const removeUserAccount = () => {
        if (pid != null && item2Remove != null) {
            const toast = new ToastHelper({
                errorMessage: 'Error removing user!',
                successMessage: 'User removed!',
                loadingMessage: 'Removing user...',
            })
            RemoveProjectAccount(pid, item2Remove)
                .then((res) => {
                    if (res.success) {
                        toast.success()
                        const accountToRemove = accounts?.find((x) => x.primaryKey === item2Remove)
                        // if the user who's getting removed is the logged in user
                        if (accountToRemove && user?.primaryKey === accountToRemove.account.primaryKey) {
                            toast.close()
                            navigate('/')
                        } else {
                            setAccounts((pa) => (pa == null ? null : pa.filter((p) => p.primaryKey !== item2Remove)))
                        }
                    } else {
                        toast.fail()
                    }
                })
                .finally(() => setItem2Remove(null))
        }
    }

    const fetchProjectRoles = useCallback(() => {
        GetProjectRoles(pid!)
            .then((res) => {
                if (res.success) {
                    setRoles(res.data)
                }
            })
            .catch((e) => setRoles([]))
    }, [pid])

    useEffect(() => {
        if (pid) {
            GetProjectDataAccess(pid).then((response) => {
                if (response.success) {
                    setCustomViews(response.data)
                }
            })
        }
    }, [pid])

    useEffect(() => {
        if (pid) {
            GetProjectAccounts(pid)
                .then((res) => (res.success ? setAccounts(res.data) : setAccounts([])))
                .catch((e) => setAccounts([]))
            fetchProjectRoles()
        }
    }, [fetchProjectRoles, pid])

    const accountsRows = useMemo(() => {
        if (accounts === null) return []
        return (
            accounts
                // Map each item in 'accounts' to a new object for DataGrid row
                .map((x) => {
                    return {
                        id: x.primaryKey,
                        accountId: x.account.primaryKey,
                        name: x.account.name ? x.account.name : x.account.email,
                        avtar: x.account.avatar,
                        IsActive: x.account.isActive,
                        dataAccessId: x.dataAccessId,
                        email: x.account.email,
                        role: x.role.roleName,
                        roleID: x.role.primaryKey,
                    }
                })
                .sort((x, y) => {
                    // make sure the logged in user is always at the top
                    if (x.accountId === user?.primaryKey) return -1
                    if (y.accountId === user?.primaryKey) return 1
                    // sort by name
                    if (x.roleID > y.roleID) return 1
                    if (x.roleID < y.roleID) return -1
                    return 0
                })
        )
    }, [accounts, user?.primaryKey])

    const userColumns = useMemo<GridColDef<any>[]>(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                renderCell: (params) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Avatar
                                alt={params.row.name}
                                src={params.row.avtar}
                                sx={{
                                    height: 40,
                                    width: 40,
                                    marginRight: 2,
                                }}
                            >
                                {params.row.name && params.row.name.toUpperCase().substring(0, 1)}
                            </Avatar>
                            <Typography variant="body1">{params.row.name}</Typography>
                            {params.row.accountId === user?.primaryKey && (
                                <Typography color="common.text_3" variant="body1">
                                    (You)
                                </Typography>
                            )}
                        </Stack>
                    )
                },
            },
            {
                field: 'email',
                headerName: 'Email',
                renderCell: (params) => {
                    return (
                        <Typography color="common.text_3" variant="body1" textOverflow="ellipsis">
                            {params.row.email}
                        </Typography>
                    )
                },
            },
            {
                field: 'roleID',
                headerName: 'Role',
                renderCell: (params) => {
                    if (roles.find((x) => x.primaryKey === params.value)) {
                        return (
                            <Select
                                value={params.value}
                                onChange={(e) => {
                                    const roleID = e.target.value as number
                                    const role = roles.find((x) => x.primaryKey === roleID)
                                    if (role) {
                                        setRole2Update({
                                            id: params.id as number,
                                            role: { roleID: role.primaryKey, roleName: role.roleName },
                                            name: params.row.name,
                                        })
                                    }
                                }}
                                sx={{
                                    width: '16ch',
                                }}
                                input={<BasicSelectInput />}
                                IconComponent={ExpandMoreIcon}
                            >
                                {roles.map((x) => (
                                    <MenuItem key={x.primaryKey} value={x.primaryKey}>
                                        {x.roleName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )
                    } else {
                        return (
                            <Typography color="common.text_3" variant="body1">
                                {params.row.role}
                            </Typography>
                        )
                    }
                },
            },
            {
                field: 'dataAccessId',
                headerName: 'Data Access',
                renderCell: (params) => {
                    if (params.row.roleID !== 6)
                        return (
                            <Typography color="common.text_3" variant="body1">
                                Full Access
                            </Typography>
                        )
                    const value = params.value || -1
                    if (roles.find((x) => x.primaryKey === params.row.roleID)) {
                        return (
                            <Select
                                value={value}
                                onChange={(e) => {
                                    const dataAccessId = e.target.value as number
                                    const dataAccess =
                                        dataAccessId === -1
                                            ? {
                                                  primaryKey: -1,
                                                  title: 'Full Access',
                                              }
                                            : customViews.find((x) => x.primaryKey === dataAccessId)
                                    if (dataAccess) {
                                        setDataAccess2Update({
                                            id: params.id as number,
                                            dataAccess: {
                                                id: dataAccess.primaryKey,
                                                title: dataAccess.title,
                                            },
                                            name: params.row.name,
                                        })
                                    }
                                }}
                                sx={{
                                    width: '16ch',
                                }}
                                input={<BasicSelectInput />}
                                IconComponent={ExpandMoreIcon}
                            >
                                <MenuItem value={-1}>Full</MenuItem>
                                {customViews.map((x) => (
                                    <MenuItem key={x.primaryKey} value={x.primaryKey}>
                                        {x.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        )
                    } else {
                        return (
                            <Typography color="common.text_3" variant="body1">
                                {value === -1 ? 'Full' : customViews.find((x) => x.primaryKey === value)?.title}
                            </Typography>
                        )
                    }
                },
            },
            {
                field: 'IsActive',
                headerName: 'Active',
                renderCell: (params) => {
                    if (params.value) {
                        return (
                            <Tooltip title="Active">
                                <CheckCircleIcon color="success" />
                            </Tooltip>
                        )
                    } else {
                        return (
                            <Stack direction="row" alignItems="center">
                                <Tooltip title="Awaiting response">
                                    <PendingActionsIcon color="warning" />
                                </Tooltip>
                                {roles.find((x) => x.primaryKey === params.row.roleID) && (
                                    <BaseButton onClick={() => resendInvitation(params.row.id)}>
                                        Resend Invitation
                                    </BaseButton>
                                )}
                            </Stack>
                        )
                    }
                },
            },
            {
                field: 'action',
                headerName: 'Action',
                width: 100,
                type: 'action',
                renderCell: (params) => {
                    return (
                        <BaseButton
                            variant="contained"
                            color="warning"
                            disabled={!roles.find((x) => x.primaryKey === params.row.roleID)}
                            sx={{ width: '80px' }}
                            label={params.row.accountId === user?.primaryKey ? 'Leave' : 'Remove'}
                            onClick={() => setItem2Remove(params.row.id)}
                        />
                    )
                },
            },
        ],
        [roles, user, resendInvitation, customViews]
    )

    return (
        <Box>
            {/* Users list
						========================================= */}
            <Stack gap={1} marginBottom={4}>
                {/* List item
				    		========================================= */}

                {accounts == null ? (
                    <SnaCircularLoading />
                ) : (
                    <StyledDataGrid
                        density="comfortable"
                        sx={(theme) => ({
                            '& .MuiDataGrid-main': {
                                '& .MuiDataGrid-row': {
                                    backgroundColor: theme.palette.common.bg_1,
                                    position: 'relative',
                                    '&::before': {
                                        content: '""', // Necessary for the pseudo-element to show
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: '100%',
                                        borderBottom: `0.5px solid ${theme.palette.common.border_3}`,
                                    },
                                    '&:last-child': {
                                        '&::before': {
                                            content: 'none', // Remove the content (and thus the border) for the last row
                                        },
                                    },
                                },
                            },
                        })}
                        customToolbar={
                            <BaseButton
                                label="Invite User"
                                onClick={handleOpenAddUserDialog}
                                startIcon={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',

                                            padding: 0.5,

                                            borderRadius: '50%',
                                            backgroundColor: 'common.bg_4',
                                        }}
                                    >
                                        <AddIcon />
                                    </Box>
                                }
                            />
                        }
                        rowHeight={70}
                        hasToolbar={false}
                        rowSelection={false}
                        rows={accountsRows}
                        columns={userColumns}
                    />
                )}
            </Stack>

            <StyledDialog open={addUserDialogOpen} onClose={handleCloseAddUserDialog} maxWidth="md" fullWidth>
                <AddUsersDialog
                    roles={roles}
                    closeButtonLabel="Close"
                    onClose={handleCloseAddUserDialog}
                    onConfirm={inviteUserAccount}
                />
            </StyledDialog>
            {/* Revoke access dialog
             */}
            <ConfirmDialog
                title="Revoke Access"
                content="Are you sure you want to remove the selected user from this project?"
                actionLabel="Revoke Access"
                closeLabel="Cancel"
                open={item2Remove != null}
                onClose={() => setItem2Remove(null)}
                onConfirm={removeUserAccount}
            />

            {/* Update Role Dialog
             */}
            {role2Update && (
                <ConfirmDialog
                    title="Update Role"
                    content={`Are you sure you want to change role of ${role2Update.name} to ${role2Update.role.roleName} for this project?`}
                    actionLabel="Update Access"
                    closeLabel="Cancel"
                    open={role2Update != null}
                    onClose={() => setRole2Update(null)}
                    onConfirm={updateUserAccount}
                />
            )}

            {/* Update data access Dialog
             */}
            {dataAccess2Update && (
                <ConfirmDialog
                    title="Update Data Access View"
                    content={`Are you sure you want to change data access view of ${dataAccess2Update.name} to ${dataAccess2Update.dataAccess.title} for this project?`}
                    actionLabel="Update Data Access"
                    closeLabel="Cancel"
                    open={dataAccess2Update != null}
                    onClose={() => setDataAccess2Update(null)}
                    onConfirm={updateUserDataAccess}
                />
            )}
        </Box>
    )
}

export default ProjectUserManagment
