import { useMemo, useState } from 'react'
import { Box, Chip, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import {
    InsightWidgetType,
    ReportDataSourceType,
    ReportFilterCompareType,
    SelectedDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import BackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import { getDataSourceFieldValues } from 'features/report-designer/helpers/reportDesigner.helper'

// List of operators that require a set of values
const setOperators: ReportFilterCompareType['operator'][] = ['notContainsAny', 'containsAny']

type AdvancedInsightFilterPanelPropsType = {
    defaultFilters: ReportFilterCompareType[]
    filterConfig: InsightWidgetType['interventionConfig']['filterConfig']
    selectedDataSource: SelectedDataSourceType | null
    onFilterChange: (filters: ReportFilterCompareType[]) => void
    targetDataSource: ReportDataSourceType
    onBack: () => void
}

const AdvancedInsightFilterPanel = ({
    defaultFilters,
    onFilterChange,
    filterConfig,
    onBack,
    selectedDataSource,
    targetDataSource,
}: AdvancedInsightFilterPanelPropsType) => {
    const [filters, setFilters] = useState<ReportFilterCompareType[]>(
        defaultFilters.length === 0 ? [{ field: null, operator: 'eq', value: '' }] : defaultFilters
    )

    const handleAddFilter = () => {
        setFilters((prev) => [...prev, { field: null, operator: 'eq', value: '' }])
    }

    const handleFilterAttributeChange = (
        index: number,
        attribute: InsightWidgetType['interventionConfig']['filterConfig'][number]['attribute']
    ) => {
        const _filterConfig = filterConfig.find((field) => field.attribute === attribute)
        if (_filterConfig === undefined) return
        setFilters((prev) => {
            const newFilters = [...prev]
            if (_filterConfig.mode === 'text') {
                newFilters[index] = {
                    field: attribute,
                    operator: 'containsAny',
                    value: [],
                }
            } else if (_filterConfig.mode === 'number') {
                newFilters[index] = {
                    field: attribute,
                    operator: 'between',
                    value: [null, null],
                }
            }

            return newFilters
        })
    }

    const handleFilterValueChange = (index: number, value: any) => {
        setFilters((prev) => {
            const newFilters = [...prev]
            newFilters[index].value = value
            return newFilters
        })
    }

    const handleRemoveFilter = (index: number) => {
        setFilters((prev) => {
            if (prev.length === 1) return [{ field: null, operator: 'eq', value: '' }]
            return prev.filter((_, i) => i !== index)
        })
    }

    const isFilterValid = (filter: ReportFilterCompareType) => {
        if (
            filter.operator === 'between' &&
            (filter.value === null ||
                (filter.value[0] === null && filter.value[1] === null) ||
                (filter.value[0] !== null && filter.value[1] !== null && filter.value[0] > filter.value[1]))
        ) {
            return false
        } else if (filter.operator === 'containsAny' || filter.operator === 'notContainsAny') {
            if (filter.value === null || filter.value.length === 0) {
                return false
            }
        }
        return filter.field !== null && filter.value !== ''
    }

    const isAllFiltersValid = useMemo(() => {
        return filters.every(isFilterValid)
    }, [filters])

    return (
        <Stack direction="column" gap={1} sx={{ height: '100%', width: '100%' }}>
            {/* Header */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" p={1} flexShrink={0}>
                <Tooltip title="Close Filter Panel">
                    <IconButton size="small" onClick={onBack}>
                        <BackIcon />
                    </IconButton>
                </Tooltip>
                <Typography variant="h6">Filter</Typography>
                <Tooltip title="Close Filter Panel">
                    <IconButton size="small" onClick={onBack}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            {/* Filters */}
            <Stack direction="column" gap={1} p={1} sx={{ flexGrow: 1, overflowY: 'auto' }} className="u-scrollbar">
                {filters.map((_filter, index) => {
                    const isSetOperator = setOperators.includes(_filter.operator)
                    const isBetweenOperator = _filter.operator === 'between'
                    return (
                        <Stack key={index} direction="column" gap={0.5}>
                            <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
                                <Typography variant="caption">Field</Typography>
                                <IconButton size="small" onClick={() => handleRemoveFilter(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                            <BaseSelectWithLabel
                                size="small"
                                placeholder="Select Field"
                                hiddenLabel
                                options={filterConfig.map((field) => ({
                                    label: field.label,
                                    value: field.attribute,
                                }))}
                                value={_filter.field}
                                onChange={(field) => {
                                    handleFilterAttributeChange(index, field)
                                }}
                            />
                            {_filter.field !== null && (
                                <>
                                    <Typography variant="caption">
                                        Value {_filter.operator === 'between' ? 'between' : 'in'}
                                    </Typography>
                                    {isSetOperator ? (
                                        <BaseSelectWithLabel
                                            label="Value"
                                            options={
                                                selectedDataSource !== null && _filter.field !== null
                                                    ? getDataSourceFieldValues(
                                                          targetDataSource,
                                                          _filter.field,
                                                          selectedDataSource.preset
                                                      )
                                                    : []
                                            }
                                            value={_filter.value ?? []}
                                            onChange={(value) => {
                                                handleFilterValueChange(index, value)
                                            }}
                                            disabled={_filter.field === null}
                                            selectProps={{
                                                multiple: true,
                                            }}
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        />
                                    ) : isBetweenOperator ? (
                                        <>
                                            <Stack direction="row" gap={1} alignItems="center">
                                                <Typography sx={{ width: '10ch' }} variant="caption">
                                                    From
                                                </Typography>
                                                <BaseFilledTextField
                                                    size="small"
                                                    placeholder="No limit"
                                                    hiddenLabel
                                                    value={_filter.value === null ? '' : _filter.value[0] ?? ''}
                                                    inputProps={{
                                                        max: _filter.value?.[1],
                                                    }}
                                                    type="number"
                                                    onChange={(evt) => {
                                                        handleFilterValueChange(index, [
                                                            Number(evt.target.value) || null,
                                                            _filter.value?.[1] ?? null,
                                                        ])
                                                    }}
                                                    error={(_filter.value?.[0] ?? null) > (_filter.value?.[1] ?? null)}
                                                />
                                            </Stack>
                                            <Stack direction="row" gap={1} alignItems="center">
                                                <Typography variant="caption" sx={{ width: '10ch' }}>
                                                    To
                                                </Typography>
                                                <BaseFilledTextField
                                                    size="small"
                                                    hiddenLabel
                                                    placeholder="No limit"
                                                    value={_filter.value === null ? '' : _filter.value[1] ?? ''}
                                                    inputProps={{
                                                        min: _filter.value?.[0],
                                                    }}
                                                    type="number"
                                                    onChange={(evt) => {
                                                        handleFilterValueChange(index, [
                                                            _filter.value?.[0] ?? null,
                                                            Number(evt.target.value) || null,
                                                        ])
                                                    }}
                                                    error={(_filter.value?.[0] ?? null) > (_filter.value?.[1] ?? null)}
                                                />
                                            </Stack>
                                        </>
                                    ) : null}
                                </>
                            )}
                            {index === filters.length - 1 ? (
                                <>
                                    <Divider />
                                    <BaseButton
                                        disabled={!isFilterValid(_filter)}
                                        variant="outlined"
                                        onClick={handleAddFilter}
                                    >
                                        Add new condition
                                    </BaseButton>
                                </>
                            ) : (
                                <>
                                    <Divider sx={{ marginTop: '20px' }} />
                                    <Chip
                                        label="AND"
                                        sx={(theme) => ({
                                            width: '70px',
                                            marginTop: '-20px',
                                            alignSelf: 'center',
                                            bgcolor: theme.palette.common.bg_2,
                                        })}
                                    />
                                </>
                            )}
                        </Stack>
                    )
                })}
            </Stack>

            {/* Footer */}
            <Stack direction="row" justifyContent="space-between" p={1} flexShrink={0}>
                <BaseButton color="secondary" variant="outlined" onClick={() => onFilterChange([])}>
                    Clear All
                </BaseButton>
                <BaseButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        isAllFiltersValid && onFilterChange(filters.filter(isFilterValid))
                    }}
                    disabled={!isAllFiltersValid}
                >
                    Apply
                </BaseButton>
            </Stack>
        </Stack>
    )
}

export default AdvancedInsightFilterPanel
