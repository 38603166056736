//* ======= Libraries
import React, { useMemo } from 'react'
//* ======= Components and features
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select, { SelectProps } from '@mui/material/Select'
import { styled } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
//* ======= Custom logic
//* ======= Assets and styles
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// interface StyledInputBaseProps extends InputBaseProps {
//     width?: string | number
// }

interface IStyledInputBase extends InputBaseProps {
    isSlim?: boolean
}

export const SelectStyledInputBase = styled(InputBase, {
    shouldForwardProp: (prop) => prop !== 'isSlim',
})<IStyledInputBase>(({ theme, isSlim }) => ({
    '&.Mui-focused': {
        '& .MuiSelect-select': {
            borderRadius: 8,
        },
    },
    '&.Mui-error': {
        '& .MuiSelect-select': {
            borderColor: theme.palette.common.fuschia100,
        },
    },

    // Select
    '& .MuiSelect-select': {
        padding: isSlim ? theme.spacing(1, 1.5) : theme.spacing(2, 2.25),
        // fontSize: isSlim ? 14 : undefined,

        borderRadius: 8,
        border: '1px solid transparent',
        backgroundColor: theme.palette.common.bg_4,

        '&[aria-expanded=true]': {
            borderColor: theme.palette.common.border_3,
            backgroundColor: theme.palette.common.bg_1,
        },
    },
}))

export type BaseSelectOptionType = Array<{ name: string; value: any } | string>

interface BaseSelectProps extends SelectProps {
    options: BaseSelectOptionType
    onChangeHandler?: (value: any) => void
    error?: boolean
    helperText?: string
    width?: string | number
    maxHeight?: number | string
    placeholder?: string
    isSlim?: boolean
}

function BaseSelect({
    options,
    onChangeHandler,
    error,
    width,
    helperText = '',
    fullWidth,
    maxHeight,
    placeholder,
    isSlim,
    ...selectProps
}: BaseSelectProps) {
    const parsedOptions = useMemo(() => {
        const res: Array<{ name: string; value: any }> = []
        if (options?.length > 0) {
            options.forEach((option) => {
                if (typeof option == 'string') {
                    res.push({ name: option, value: option })
                } else {
                    res.push({ ...option })
                }
            })
        }
        return res
    }, [options])

    const handleChange = (value: any) => {
        if (typeof onChangeHandler !== 'undefined') {
            if (selectProps.multiple === true) {
                onChangeHandler(typeof value === 'string' ? value.split(',') : value)
            } else {
                onChangeHandler(value)
            }
        }
    }

    return (
        <FormControl
            variant="standard"
            error={error}
            fullWidth={fullWidth}
            sx={{
                width: width,
                ...selectProps.sx,
            }}
        >
            {/* ToDo @Pourya fix label display */}
            {selectProps.label && (
                <InputLabel htmlFor={'base-select-label'} variant="standard">
                    {selectProps.label}
                </InputLabel>
            )}

            <Select
                labelId="base-select-label"
                id="base-select"
                input={<SelectStyledInputBase fullWidth isSlim={isSlim} />}
                defaultValue={''}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                renderValue={(selectedValue: any) => {
                    if (selectProps.multiple === true) {
                        if (selectedValue.length === 0) {
                            return placeholder
                        } else {
                            return selectedValue
                                .map((_value: any) => {
                                    return parsedOptions.find((_option) => _option.value === _value)?.name
                                })
                                .join(', ')
                        }
                    } else {
                        if (selectedValue === '') {
                            return placeholder
                        } else {
                            return parsedOptions.find((_option) => _option.value === selectedValue)?.name
                        }
                    }
                }}
                MenuProps={{
                    PaperProps: {
                        className: 'u-scrollbar',
                        ...selectProps.MenuProps?.PaperProps,
                    },
                    sx: {
                        maxHeight: maxHeight,
                        ...selectProps.MenuProps?.sx,
                    },
                    ...selectProps.MenuProps,
                }}
                {...selectProps}
                onChange={(evt) => handleChange(evt.target.value)}
                sx={{
                    '& .MuiSelect-icon': {
                        right: '2px',

                        color: 'primary.main',
                    },

                    ...selectProps.sx,
                    // These hard-coded widths prevent 'overlap' of 'sx' styles
                    // in here and the parent 'FormControl'.
                    // This is a design mistake and should be fixed later.
                    width: '100%',
                    maxWidth: '100%',
                }}
            >
                {placeholder && (
                    <MenuItem disabled value="">
                        {placeholder}
                    </MenuItem>
                )}

                {parsedOptions.map((_option, idx) => (
                    <MenuItem key={_option.name + idx} value={_option.value || ''}>
                        {_option.name}
                    </MenuItem>
                ))}
            </Select>

            {helperText && (
                <FormHelperText
                    variant="filled"
                    sx={{
                        '&.Mui-error': (theme) => ({
                            color: theme.palette.common.fuschia100,
                        }),
                    }}
                >
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
}

export default BaseSelect
