//* ======= Libraries
import React, { useContext, useMemo, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import PublishSurveyDialog from 'features/survey-dashboard/PublishSurveyDialog'
import SendSurveyInvitation from 'features/survey-dashboard/SendSurveyInvitation'
import RemoveSurveyDialog from 'features/survey-dashboard/RemoveSurveyDialog'
import SendReminderSurveyDialog from 'features/survey-dashboard/SendReminderSurveyDialog'
//* ======= Custom logic
//* ======= Assets and styles
import { SurveyContext } from 'contexts/SurveyContex'
import moment from 'moment'
import SurveyOverview from 'features/survey-overview/SurveyOverview'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'
import ExportSurveyDialog from 'features/survey-dashboard/ExportSurveyDialog'
import ResumeSurveyDialog from 'features/survey-dashboard/ResumeSurveyDialog'
import PauseSurveyDialog from 'features/survey-dashboard/PauseSurveyDialog'
import { ProjectContext } from 'contexts/ProjectContext'
import { PERMISSIONS } from 'helpers/constants'

interface ISurveyDashboardDialog {
    type: 'publish' | 'send-invitation' | 'send-reminder' | 'delete' | 'export' | 'pause' | 'resume'
    isOpen: boolean
}

type StatusButtonType = {
    label: string
    dialogType: 'publish' | 'pause' | 'resume'
    color?: 'primary' | 'secondary' | 'warning'
}

/**
 * TODO:
 *  1.  MainDrawer needs to have a way to know its 'active' or 'selected' item.
 */

function SurveyDashboard() {
    const { drawerContent, survey } = useContext(SurveyContext)
    const { hasPermission } = useContext(ProjectContext)

    const surveyStatusActionButtonProps = useMemo<StatusButtonType>(() => {
        let result: StatusButtonType = {
            label: '',
            dialogType: 'publish',
        }

        switch (survey?.status) {
            case 'draft':
                result = {
                    label: 'Publish',
                    dialogType: 'publish',
                    color: 'primary',
                }
                break
            case 'published':
                result = {
                    label: 'Pause Survey',
                    dialogType: 'pause',
                    color: 'warning',
                }
                break
            case 'paused':
                result = {
                    label: 'Resume Survey',
                    dialogType: 'resume',
                    color: 'primary',
                }
                break
            default:
                break
        }

        return result
    }, [survey?.status])

    const [dialogState, setDialogState] = useState<ISurveyDashboardDialog>({
        type: 'publish',
        isOpen: false,
    })

    const canManageSurveyStatus = useMemo(() => {
        return hasPermission(PERMISSIONS.survey.ManageSurveyStatus)
    }, [hasPermission])

    const canAccessSurveyData = useMemo(() => {
        return hasPermission(PERMISSIONS.survey.AccessSurveyData)
    }, [hasPermission])

    const canDeleteSurvey = useMemo(() => {
        return hasPermission(PERMISSIONS.survey.DeleteSurvey)
    }, [hasPermission])

    const canManageNotification = useMemo(() => {
        return hasPermission(PERMISSIONS.survey.ManageSurveyNotifications)
    }, [hasPermission])

    if (survey === undefined) return <DashboardSkeleton />

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack
                    gap={2}
                    width="100%"
                    height="100%"
                    py={3.5}
                    px={4}
                    sx={(theme) => ({
                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* Details
					    ========================================= */}
                    <Stack direction="row" alignItems="center" gap={1} pb={1}>
                        {/* Avatar
					        ========================================= */}
                        {survey.creator?.name && (
                            <>
                                <Avatar
                                    src={survey.creator.avatar}
                                    sx={{
                                        height: 32,
                                        width: 32,
                                    }}
                                >
                                    {survey.creator.name?.substring(0, 1)?.toUpperCase()}
                                </Avatar>

                                {/* Creator
					        ========================================= */}
                                <Stack alignItems="flex-start" marginRight={3}>
                                    <Typography fontSize={10} fontWeight={400} color="secondary">
                                        Creator
                                    </Typography>

                                    <Typography fontSize={14} fontWeight={400} color="common.text_1">
                                        {survey.creator?.name || 'Unkown'}
                                    </Typography>
                                </Stack>
                            </>
                        )}
                        {/* Status
					        ========================================= */}
                        <Stack alignItems="flex-start" marginRight={3}>
                            <Typography fontSize={10} fontWeight={400} color="secondary">
                                Status
                            </Typography>

                            <Typography fontSize={14} fontWeight={400} color="common.text_1">
                                {survey.status}
                            </Typography>
                        </Stack>

                        {/* Creation date
					        ========================================= */}
                        <Stack flexGrow={1} alignItems="flex-start">
                            <Typography fontSize={10} fontWeight={400} color="secondary">
                                Created On
                            </Typography>

                            <Typography fontSize={14} fontWeight={400} color="common.text_1">
                                {moment(survey.createdOn).format('DD.MM.YYYY')}
                            </Typography>
                        </Stack>

                        {/* Delete button
					        ========================================= */}
                        {canDeleteSurvey && (
                            <BaseButton
                                label="Delete Survey"
                                onClick={(e) => setDialogState({ type: 'delete', isOpen: true })}
                                variant="contained"
                                size="large"
                                color="warning"
                                disableElevation
                            />
                        )}
                    </Stack>

                    {/* Overview
					    ========================================= */}
                    <SurveyOverview />

                    {/* Controls
					    ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
                        {/* Send test invitation button
					        ========================================= */}
                        {/* <BaseButton
                            label="Send Test Invitation"
                            onClick={(e) => setDialogState({ type: 'send-invitation', isOpen: true })}
                            variant="outlined"
                            size="large"
                            width="25%"
                        /> */}

                        {/* Send survey reminder button
					        ========================================= */}
                        {survey.status === 'published' && canManageNotification && (
                            <BaseButton
                                label="Send Manual Reminder"
                                onClick={(e) => setDialogState({ type: 'send-reminder', isOpen: true })}
                                variant="outlined"
                                size="large"
                                width="33%"
                            />
                        )}

                        {/* Export Data Dictionary
					        ========================================= */}

                        {canAccessSurveyData && (
                            <BaseButton
                                label="Export"
                                onClick={(e) => setDialogState({ type: 'export', isOpen: true })}
                                variant="outlined"
                                size="large"
                                color="primary"
                                disableElevation
                                width="33%"
                            />
                        )}

                        {/* Publish button
					        ========================================= */}
                        {canManageSurveyStatus && (
                            <BaseButton
                                label={surveyStatusActionButtonProps.label}
                                onClick={(e) =>
                                    setDialogState({
                                        type: surveyStatusActionButtonProps.dialogType,
                                        isOpen: true,
                                    })
                                }
                                variant="contained"
                                size="large"
                                disableElevation
                                color={surveyStatusActionButtonProps.color}
                                width="33%"
                            />
                        )}
                    </Stack>
                </Stack>
            </PageCard.Body>

            {/* Dialogs
                ========================================= */}
            <StyledDialog
                open={dialogState.isOpen}
                onClose={(event, reason) => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
            >
                {dialogState.type === 'publish' ? (
                    <PublishSurveyDialog
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : dialogState.type === 'send-invitation' ? (
                    <SendSurveyInvitation
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : dialogState.type === 'resume' ? (
                    <ResumeSurveyDialog
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : dialogState.type === 'pause' ? (
                    <PauseSurveyDialog
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : dialogState.type === 'delete' ? (
                    <RemoveSurveyDialog
                        onConfirm={() => undefined}
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : dialogState.type === 'send-reminder' ? (
                    <SendReminderSurveyDialog
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : dialogState.type === 'export' ? (
                    <ExportSurveyDialog
                        onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
                    />
                ) : (
                    false
                )}
            </StyledDialog>
        </PageCard>
    )
}

export default SurveyDashboard
