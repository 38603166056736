//* ======= Libraries
import ProjectContextProvider from 'contexts/ProjectContext'
import { Outlet } from 'react-router-dom'
//* ======= Components and features

export default function ProjectRoutes() {
    return (
        <ProjectContextProvider>
            <Outlet />
        </ProjectContextProvider>
    )
}
