import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import React, { useState } from 'react'

type Props = {
    buttonText?: string,
    dialogTitle?: string,
    dialogCloseLable?: string,
    dialogContet: React.ReactElement
}

function PopupDialog({ buttonText = 'show', dialogTitle, dialogCloseLable = "close", dialogContet }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false)
    const handleOpen = () => setIsOpen(true)
    return (
        <React.Fragment>
            <BaseButton onClick={handleOpen}>{buttonText}</BaseButton>
            <StyledDialog open={isOpen} onClose={handleClose}>
                {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
                <DialogContent>{dialogContet}</DialogContent>
                <DialogActions>
                    <BaseButton variant='outlined' color='warning' onClick={handleClose}>{dialogCloseLable}</BaseButton>
                </DialogActions>
            </StyledDialog>
        </React.Fragment>
    )
}

export default PopupDialog