import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    LinearProgress,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/MoreHoriz'
import AddIcon from '@mui/icons-material/Add'

import { useEffect, useMemo, useState } from 'react'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import NetworkVizContext, {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { applyFilter } from '../filter/NetworkFilter.helpers'
import GenericDragDropList from 'features/GenericDragDropList/GenericDragDropList'
import { NetworkKeyFrameType } from 'features/network-viz/types/NetworkViz.types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { v4 as uuidv4 } from 'uuid'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import EditorClassic from 'components/Editor/EditorClassic'
import DeleteIcon from '@mui/icons-material/Delete'
import useKeyframeAnimation, { isKeyframeApplicable, loadNetworkKeyFrame } from './useKeyframeAnimation'
import PlayIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import { ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import FlexibleSelect, {
    FlexibleSelectGroupedOptionType,
    FlexibleSelectOptionType,
    FlexibleSelectUngroupedOptionType,
} from 'components/group-field-selector/FlexibleSelect'
import ScrollingSubtitle from './ScrollingSubtitle'

export default function NetworkKeyFrameSettings() {
    const { keyFrames, ...networkVizContext } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const animationManager = useKeyframeAnimation()

    const [createDialogOpen, setCreateDialogOpen] = useState<{
        open: boolean
        keyframe: NetworkKeyFrameType | null
    }>({ open: false, keyframe: null })
    const handleOpenCreateDialog = (keyframe?: NetworkKeyFrameType) =>
        setCreateDialogOpen({
            open: true,
            keyframe: keyframe ?? null,
        })
    const handleCloseCreateDialog = () =>
        setCreateDialogOpen({
            open: false,
            keyframe: null,
        })

    const deleteKeyframe = (id: string) => dispatchContext({ type: 'KEYFRAME_DELETE', payload: id })

    const loadKeyFrame = async (keyframe: NetworkKeyFrameType) => {
        const newState = await loadNetworkKeyFrame(keyframe, {
            keyFrames,
            ...networkVizContext,
        })

        dispatchContext({ type: 'PRESET_LOAD', payload: newState })
    }

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={14} fontWeight={500}>
                    Key frames
                </Typography>
                <Stack direction="row">
                    {animationManager.isPlaying ? (
                        <IconButton onClick={() => animationManager.stopAnimation()} color="primary">
                            <StopIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            disabled={!animationManager.isReady}
                            onClick={() => animationManager.startAnimation()}
                            color="primary"
                        >
                            <PlayIcon />
                        </IconButton>
                    )}

                    <Tooltip title="Create">
                        <IconButton onClick={() => handleOpenCreateDialog()}>
                            <AddIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={1} minWidth={0}>
                {/* Preset items
                    ========================================= */}
                <DndProvider backend={HTML5Backend}>
                    <GenericDragDropList
                        items={keyFrames}
                        getKeyValue={(item, idx) => item.id + idx.toString() + item.title}
                        targetType="keyframes"
                        onItemMove={(id, index) =>
                            dispatchContext({
                                type: 'KEYFRAME_MOVE',
                                payload: {
                                    id: id.toString(),
                                    index,
                                },
                            })
                        }
                        renderItem={(keyframe) => (
                            <KeyframeItem
                                keyframe={keyframe}
                                deleteKeyframe={deleteKeyframe}
                                editKeyframe={handleOpenCreateDialog}
                                loadKeyframe={loadKeyFrame}
                                analytics={networkVizContext.analytics}
                                playKeyframe={animationManager.playKeyframe}
                                isPlaying={
                                    animationManager.isPlaying && animationManager.currentKeyframe === keyframe.id
                                }
                            />
                        )}
                    />
                </DndProvider>
            </Stack>

            <CreateEditKeyframeDialog
                keyframe={createDialogOpen.keyframe}
                open={createDialogOpen.open}
                onClose={handleCloseCreateDialog}
                onSave={(id, keyframe) => {
                    dispatchContext({
                        type: 'KEYFRAME_CREATE_OR_EDIT',
                        payload: {
                            id,
                            keyframe: keyframe,
                        },
                    })
                }}
            />

            <ScrollingSubtitle subtitle={animationManager.subTitle} isPlaying={animationManager.isPlaying} />
        </Stack>
    )
}

type KeyframeItemProps = {
    keyframe: NetworkKeyFrameType
    loadKeyframe: (keyframe: NetworkKeyFrameType) => void
    editKeyframe: (keyframe: NetworkKeyFrameType) => void
    analytics: NetworkVizContextType['analytics']
    deleteKeyframe: (id: string) => void
    playKeyframe: (id: string | number) => void
    isPlaying: boolean
}
const KeyframeItem = ({
    loadKeyframe,
    playKeyframe,
    deleteKeyframe,
    editKeyframe,
    keyframe,
    analytics,
    isPlaying,
}: KeyframeItemProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const isApplicable = useMemo(() => {
        return isKeyframeApplicable(keyframe, analytics)
    }, [keyframe, analytics])

    return (
        <Stack direction="column">
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={0.5} minWidth={0}>
                {/* Preset activation button
                            ========================================= */}
                <BaseButton
                    onClick={(evt) => loadKeyframe(keyframe)}
                    color="secondary"
                    sx={{
                        flexBasis: 0,
                        flexGrow: 1,

                        borderRadius: 0,
                    }}
                >
                    <Typography
                        fontSize={14}
                        noWrap
                        sx={{
                            flexGrow: 1,
                            textAlign: 'left',
                            textOverflow: 'ellipsis',
                            maxWidth: '180px',
                            color: isApplicable ? 'primary.main' : 'text.primary',
                            overflow: 'hidden',
                        }}
                    >
                        {keyframe.title}
                    </Typography>
                </BaseButton>

                {/* Preset delete button
                            ========================================= */}
                <IconButton
                    onClick={handleClick}
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id={'preset-menu-' + keyframe.id}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            playKeyframe(keyframe.id)
                        }}
                    >
                        Play
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            editKeyframe(keyframe)
                            handleClose()
                        }}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            deleteKeyframe(keyframe.id)
                            handleClose()
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </Stack>
            {isPlaying && <ProgressBar description={keyframe.description} />}
        </Stack>
    )
}

type ProgressBarProps = {
    description: string
}

const ProgressBar = ({ description }: ProgressBarProps) => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        let interval: NodeJS.Timeout
        const parser = new DOMParser()
        const doc = parser.parseFromString(description, 'text/html')
        let text = doc.body.textContent || ''
        const duration = text.split(' ').length * 400
        interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) return 100
                return prev + 100 / (duration / 100)
            })
        }, 100)
        return () => clearInterval(interval)
    }, [])

    return (
        <Box width="180px">
            <LinearProgress variant="determinate" value={progress} />
        </Box>
    )
}

type CreateEditKeyframeDialogProps = {
    keyframe: NetworkKeyFrameType | null
    open: boolean
    onSave: (id: string | null, keyframe: NetworkKeyFrameType) => void
    onClose: () => void
}

const CreateEditKeyframeDialog = ({ keyframe, onSave, open, onClose }: CreateEditKeyframeDialogProps) => {
    const networkContext = useNetworkVizContext()

    const getKeyFrame = () => {
        return (
            keyframe ?? {
                id: uuidv4(),
                title: '',
                description: '',
                duration: 60,
                rule: [],
                networkConfig: {
                    edgeStyle: networkContext.edgeStyle,
                    nodeStyle: networkContext.nodeStyle,
                    filter: networkContext.filters,
                    selectedNode: networkContext.selectedItem,
                    nodeGroupBy: networkContext.nodeGroupBy,
                    edgeGroupBy: networkContext.edgeGroupBy,
                    dimOneWayEdges: networkContext.dimOneWayEdges,
                    highlightReciprocatedEdges: networkContext.highlightReciprocatedEdges,
                    onlyShowReciprocatedEdges: networkContext.onlyShowReciprocatedEdges,
                },
            }
        )
    }

    const [internalKeyframe, setInternalKeyframe] = useState<NetworkKeyFrameType>(getKeyFrame())

    useEffect(() => {
        if (open) {
            setInternalKeyframe(getKeyFrame())
        }
    }, [open])

    const isEditMode = useMemo(() => keyframe !== null, [keyframe])

    const create = async () => {
        onSave(isEditMode ? keyframe!.id : null, internalKeyframe)
        onClose()
    }

    const addRule = () => {
        setInternalKeyframe((prev) => {
            return {
                ...prev,
                rule: [...prev.rule, { operator: 'eq', value: '', varaible: null }],
            }
        })
    }

    const updateNetworkConfig = () => {
        setInternalKeyframe((prev) => {
            return {
                ...prev,
                networkConfig: {
                    edgeStyle: networkContext.edgeStyle,
                    nodeStyle: networkContext.nodeStyle,
                    filter: networkContext.filters,
                    selectedNode: networkContext.selectedItem,
                    nodeGroupBy: networkContext.nodeGroupBy,
                    edgeGroupBy: networkContext.edgeGroupBy,
                    dimOneWayEdges: networkContext.dimOneWayEdges,
                    highlightReciprocatedEdges: networkContext.highlightReciprocatedEdges,
                    onlyShowReciprocatedEdges: networkContext.onlyShowReciprocatedEdges,
                },
            }
        })
    }

    const fields = useMemo(() => {
        const anlytics = networkContext.analytics
        if (anlytics === null) return []
        const result = []
        for (const networkName in anlytics) {
            if (networkName === 'view' || networkName === 'all') continue
            for (let metric in anlytics[networkName].graph) {
                result.push(`[${networkName}.graph.${metric}]`)
            }
        }
        return result
    }, [])

    return (
        <StyledDialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
            <DialogTitle>{isEditMode ? 'Edit Keyframe' : 'Create Keyframe'}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <Stack direction="row" gap={1}>
                        <BaseFilledTextField
                            label="Title"
                            value={internalKeyframe.title}
                            onChange={(e) => {
                                setInternalKeyframe((prev) => ({ ...prev, title: e.target.value }))
                            }}
                            fullWidth
                        />
                        <BaseFilledTextField
                            label="Duration"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Seconds</InputAdornment>,
                            }}
                            value={internalKeyframe.duration}
                            onChange={(e) => {
                                setInternalKeyframe((prev) => ({ ...prev, duration: parseInt(e.target.value) }))
                            }}
                            fullWidth
                        />
                    </Stack>
                    <Typography variant="h6">Description</Typography>

                    {isEditMode && <BaseButton onClick={updateNetworkConfig}>Update Network Config</BaseButton>}
                    <Box height="300px">
                        <EditorClassic
                            data={internalKeyframe.description}
                            onBlur={(event, editor) =>
                                setInternalKeyframe((prev) => ({ ...prev, description: editor.getData() }))
                            }
                            config={{
                                mention: {
                                    dropdownLimit: 100,
                                    feeds: [
                                        {
                                            marker: '[',
                                            feed: fields,
                                            minimumCharacters: 0,
                                        },
                                    ],
                                },
                            }}
                        />
                    </Box>
                    <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                        <Stack direction="row" gap={1} alignItems="center">
                            <Typography variant="h6">Rules:</Typography>
                            <Typography variant="subtitle1">To make this keyframe conditional, define rules</Typography>
                        </Stack>

                        <Tooltip title="Add Rule">
                            <IconButton onClick={addRule}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    {/* Rules */}
                    <Stack gap={1}>
                        {internalKeyframe.rule.length === 0 ? (
                            <Typography>No rules defined</Typography>
                        ) : (
                            internalKeyframe.rule.map((rule, index) => (
                                <Stack direction="column" gap={1} key={index}>
                                    <ConditionFieldSelector
                                        onChange={(field) => {
                                            setInternalKeyframe((prev) => {
                                                const newRule = [...prev.rule]
                                                newRule[index] = { ...newRule[index], varaible: field }
                                                return { ...prev, rule: newRule }
                                            })
                                        }}
                                        defaultField={rule.varaible}
                                        analytics={networkContext.analytics}
                                    />
                                    <Stack direction="row" gap={1}>
                                        {/* Operator */}
                                        <BaseSelectWithLabel
                                            label="Operator"
                                            fullWidth
                                            value={rule.operator}
                                            onChange={(value) => {
                                                setInternalKeyframe((prev) => {
                                                    const newRule = [...prev.rule]
                                                    newRule[index] = { ...newRule[index], operator: value }
                                                    return { ...prev, rule: newRule }
                                                })
                                            }}
                                            options={[
                                                {
                                                    value: 'eq',
                                                    label: '=',
                                                },
                                                {
                                                    value: 'neq',
                                                    label: '<>',
                                                },
                                                {
                                                    value: 'lt',
                                                    label: '<',
                                                },
                                                {
                                                    value: 'lte',
                                                    label: '<=',
                                                },
                                                {
                                                    value: 'gt',
                                                    label: '>',
                                                },
                                                {
                                                    value: 'gte',
                                                    label: '>=',
                                                },
                                                {
                                                    value: 'between',
                                                    label: 'Between',
                                                },
                                            ]}
                                        />
                                        {rule.operator === 'between' ? (
                                            <>
                                                <BaseFilledTextField
                                                    fullWidth
                                                    label="From"
                                                    defaultValue={Array.isArray(rule.value) ? rule.value[0] : null}
                                                    onBlur={(e) => {
                                                        setInternalKeyframe((prev) => {
                                                            const newRule = [...prev.rule]
                                                            newRule[index] = {
                                                                ...newRule[index],
                                                                value: Array.isArray(rule.value)
                                                                    ? [Number(e.target.value), rule.value[1]]
                                                                    : [Number(e.target.value), null],
                                                            }
                                                            return { ...prev, rule: newRule }
                                                        })
                                                    }}
                                                />
                                                <BaseFilledTextField
                                                    fullWidth
                                                    label="To"
                                                    defaultValue={Array.isArray(rule.value) ? rule.value[1] : null}
                                                    onBlur={(e) => {
                                                        setInternalKeyframe((prev) => {
                                                            const newRule = [...prev.rule]
                                                            newRule[index] = {
                                                                ...newRule[index],
                                                                value: Array.isArray(rule.value)
                                                                    ? [rule.value[0], Number(e.target.value)]
                                                                    : [null, Number(e.target.value)],
                                                            }
                                                            return { ...prev, rule: newRule }
                                                        })
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <BaseFilledTextField
                                                fullWidth
                                                label="Value"
                                                defaultValue={rule.value}
                                                onBlur={(e) => {
                                                    setInternalKeyframe((prev) => {
                                                        const newRule = [...prev.rule]
                                                        newRule[index] = {
                                                            ...newRule[index],
                                                            value: Number(e.target.value),
                                                        }
                                                        return { ...prev, rule: newRule }
                                                    })
                                                }}
                                            />
                                        )}

                                        <Tooltip title="Remove Rule">
                                            <IconButton
                                                onClick={() => {
                                                    setInternalKeyframe((prev) => {
                                                        const newRule = [...prev.rule]
                                                        newRule.splice(index, 1)
                                                        return { ...prev, rule: newRule }
                                                    })
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            ))
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                {/* <BaseButton color="secondary" onClick={() => localStorage.setItem('presetTemplates', '{}')}>
                    Clear
                </BaseButton> */}
                <BaseButton onClick={onClose}>Close</BaseButton>
                <BaseButton color="primary" onClick={create}>
                    {isEditMode ? 'Save' : 'Create'}
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

type ConditionFieldSelectorProps = {
    analytics: NetworkVizContextType['analytics']
    defaultField: ReportDataSourceAttributeType | null
    onChange: (field: ReportDataSourceAttributeType | null) => void
}
const ConditionFieldSelector = ({ analytics, defaultField, onChange }: ConditionFieldSelectorProps) => {
    const [selectedField, setSelectedField] = useState<Extract<
        ReportDataSourceAttributeType,
        { type: 'ergm' | 'alaam' | 'graph' }
    > | null>(
        defaultField && ['ergm', 'alaam', 'graph'].includes(defaultField.type)
            ? (defaultField as Extract<ReportDataSourceAttributeType, { type: 'ergm' | 'alaam' | 'graph' }>)
            : null
    )

    const fieldCategoryOptions = useMemo<FlexibleSelectGroupedOptionType[]>(() => {
        if (analytics === null) return []
        const result: FlexibleSelectGroupedOptionType[] = []
        for (const networkName in analytics) {
            if (networkName === 'view' || networkName === 'all') continue
            const networkOptions: FlexibleSelectGroupedOptionType = {
                group: networkName,
                items: [
                    {
                        label: 'Graph',
                        value: {
                            type: 'graph',
                            relationship: networkName,
                        },
                    },
                    {
                        label: 'ERGM',
                        value: {
                            type: 'ergm',
                            relationship: networkName,
                        },
                    },

                    {
                        label: 'ALAAM',
                        value: {
                            type: 'alaam',
                            relationship: networkName,
                        },
                    },
                ],
            }
            result.push(networkOptions)
        }
        return result
    }, [analytics])

    const attributeFields = useMemo<FlexibleSelectOptionType[]>(() => {
        if (analytics === null || selectedField === null) return []

        const networkAnalytics = analytics[selectedField.relationship]

        switch (selectedField.type) {
            case 'ergm': {
                if (networkAnalytics.ergm === undefined) return []
                const result: FlexibleSelectGroupedOptionType[] = []
                for (const _field of networkAnalytics.ergm.params_stats) {
                    result.push({
                        group: _field.name,
                        items: [
                            {
                                label: 'Converged',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'converged',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Log likelihood',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'log_likelihood',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Pseudo R-squared',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'pseudo_r_squared',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Coefficient',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'coef',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'P-value',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'p_values',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Z',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'z',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Standard Error',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'std_err',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Confidence Interval Lower Bound',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'conf_int_lb',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Confidence Interval Upper Bound',
                                value: {
                                    type: 'ergm',
                                    relationship: selectedField.relationship,
                                    metric: 'conf_int_ub',
                                    field: _field.name,
                                },
                            },
                        ],
                    })
                }
                return result
            }
            case 'alaam': {
                if (
                    networkAnalytics.alaam === undefined ||
                    selectedField.targetField === '' ||
                    networkAnalytics.alaam[selectedField.targetField] === undefined
                )
                    return []
                const result: FlexibleSelectGroupedOptionType[] = []
                for (const _field of networkAnalytics.alaam[selectedField.targetField].params_stats) {
                    result.push({
                        group: _field.name,
                        items: [
                            {
                                label: 'Converged',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    metric: 'converged',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Log likelihood',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'log_likelihood',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Pseudo R-squared',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'pseudo_r_squared',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Coefficient',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'coef',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'P-value',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'p_values',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Z',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'z',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Standard Error',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'std_err',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Confidence Interval Lower Bound',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'conf_int_lb',
                                    field: _field.name,
                                },
                            },
                            {
                                label: 'Confidence Interval Upper Bound',
                                value: {
                                    type: 'alaam',
                                    relationship: selectedField.relationship,
                                    targetField: selectedField.targetField,
                                    metric: 'conf_int_ub',
                                    field: _field.name,
                                },
                            },
                        ],
                    })
                }
                return result
            }
            case 'graph': {
                const result: FlexibleSelectUngroupedOptionType[] = []
                for (const metric in networkAnalytics.graph) {
                    result.push({
                        label: metric,
                        value: {
                            type: 'graph',
                            relationship: selectedField.relationship,
                            field: metric,
                        },
                    })
                }
                return result
            }
        }
        return []
    }, [analytics, selectedField])

    const targetAttributeFields = useMemo<FlexibleSelectUngroupedOptionType[]>(() => {
        if (analytics === null || selectedField === null || selectedField.type !== 'alaam') return []
        const networkAnalytics = analytics[selectedField.relationship]
        if (networkAnalytics === undefined || networkAnalytics.alaam === undefined) return []
        const result: FlexibleSelectUngroupedOptionType[] = []
        for (const targetField in networkAnalytics.alaam) {
            result.push({
                label: targetField,
                value: {
                    type: 'alaam',
                    relationship: selectedField.relationship,
                    targetField: targetField,
                },
            })
        }
        return result
    }, [analytics, selectedField])

    return (
        <Stack direction="row" gap={1} alignItems="center">
            {/* data category selector */}
            <FlexibleSelect
                value={
                    selectedField === null
                        ? null
                        : {
                              type: selectedField.type,
                              relationship: selectedField.relationship,
                          }
                }
                options={fieldCategoryOptions}
                hiddenLabel
                fullWidth
                onChange={(value) => {
                    setSelectedField({
                        type: value.type,
                        relationship: value.relationship,
                        field: '',
                    })
                    onChange(null)
                }}
            />
            {selectedField?.type === 'alaam' && (
                <FlexibleSelect
                    value={
                        selectedField === null
                            ? null
                            : {
                                  type: selectedField.type,
                                  relationship: selectedField.relationship,
                                  targetField: selectedField.targetField,
                              }
                    }
                    options={targetAttributeFields}
                    hiddenLabel
                    fullWidth
                    onChange={(value) => {
                        setSelectedField({
                            ...value,
                        })
                        onChange(null)
                    }}
                />
            )}
            <FlexibleSelect
                value={defaultField}
                options={attributeFields}
                hiddenLabel
                fullWidth
                onChange={(value) => {
                    setSelectedField(value)
                    onChange(value)
                }}
            />
        </Stack>
    )
}
