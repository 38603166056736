//* ======= Libraries
import React, { useState } from 'react'
import { Badge, Chip, Tooltip, alpha } from '@mui/material'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ResourceType } from 'types/resources'
import { ContextMenuItemType } from 'types/ContextMenuType'
//* ======= Assets and styles
import FolderIcon from '@mui/icons-material/Folder'
import StarsIcon from '@mui/icons-material/Stars'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import TempAvatar from 'assets/images/profile-photo.svg'

type TemplateCardWideProps = {
    data: ResourceType
    isFolder?: boolean | undefined
    clickHandler?: () => void
    contextMenu?: ContextMenuItemType[]
}

function TemplateCardWide({ data, isFolder = false, clickHandler, contextMenu }: TemplateCardWideProps) {
    const [itemMenu, setItemMenu] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    const tempAvatarTemplate = (
        <Avatar
            alt="admins"
            src={data.creator?.avatar}
            sx={{
                height: '20px',
                width: '20px',
            }}
        />
    )

    return (
        <Stack
            direction="row"
            alignItems="stretch"
            sx={(theme) => ({
                minHeight: '108px',
                width: '100%',
                overflow: 'hidden',

                borderRadius: '10px',
                border: `1px solid ${theme.palette.common.border_2}`,

                filter: isFolder ? `drop-shadow(1px 4px 0px ${alpha(theme.palette.primary.main, 0.15)})` : '',
                backgroundColor: 'common.bg_4',
            })}
        >
            {/* Item header
				========================================= */}
            <Stack
                flexShrink={0}
                direction="row"
                alignItems="center"
                gap={1}
                sx={{
                    width: '328px',
                    py: 2.25,
                    px: 2,
                    backgroundColor: isFolder ? 'primary.main' : '',
                    color: isFolder ? 'primary.contrastText' : 'primary.main',
                }}
            >
                {/* Icon
				    ========================================= */}
                {isFolder === false && data.favourite && (
                    <StarsIcon
                        color="primary"
                        sx={{
                            alignSelf: 'center',
                            flexShrink: 0,
                        }}
                    />
                )}

                {/* Header details
				    ========================================= */}
                <Stack
                    alignItems="flex-start"
                    flexGrow={1}
                    gap={1}
                    minWidth={0}
                    sx={{
                        alignSelf: isFolder ? 'flex-start' : 'center',

                        marginLeft: isFolder ? 4 : 0,
                    }}
                >
                    <Tooltip title={data.title} enterDelay={200} arrow placement="top">
                        <Typography noWrap width="100%">
                            {data.title}
                        </Typography>
                    </Tooltip>

                    {isFolder ? (
                        <FolderIcon
                            sx={{
                                width: 36,
                                height: 29,
                                marginTop: 1,
                            }}
                        />
                    ) : (
                        <>
                            <Typography noWrap fontSize={10} fontWeight={400}>
                                Creator
                            </Typography>

                            <Stack direction="row" alignItems="center" spacing={1} minWidth={0}>
                                <Avatar
                                    src={data.creator?.avatar || TempAvatar}
                                    alt="creator"
                                    sx={{
                                        height: '24px',
                                        width: '24px',
                                    }}
                                />

                                <Typography
                                    fontSize={10}
                                    fontWeight={400}
                                    noWrap
                                    color={isFolder ? 'primary.contrastText' : 'secondary'}
                                >
                                    {data.creator?.name}
                                </Typography>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>

            {/* Item body
				========================================= */}
            <Stack
                flexGrow={1}
                gap={0.25}
                sx={(theme) => ({
                    minWidth: 0,
                    padding: theme.spacing(2.25, 2, 2.25, 3),
                    backgroundColor: 'common.bg_1',
                })}
            >
                {/* Information and menu
				    ========================================= */}
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={1}>
                    <Typography color="primary">{isFolder ? 'Folder Information' : 'Information'}</Typography>

                    {Array.isArray(contextMenu) && contextMenu.length > 0 && (
                        <>
                            <IconButton
                                onClick={(evt) => setItemMenu({ isOpen: true, anchorElement: evt.currentTarget })}
                                id="item-menu-button"
                                aria-controls={itemMenu.isOpen ? 'item-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={itemMenu.isOpen ? 'true' : undefined}
                                sx={{
                                    padding: 0.5,

                                    color: 'common.fill_1',
                                }}
                            >
                                <MoreHorizIcon />
                            </IconButton>

                            {/* (Out-of-flow) Item menu
						========================================= */}
                            <Menu
                                id="item-menu"
                                anchorEl={itemMenu.anchorElement}
                                open={itemMenu.isOpen}
                                onClose={() => setItemMenu((prevState) => ({ ...prevState, isOpen: false }))}
                                MenuListProps={{
                                    'aria-labelledby': 'item-menu-button',
                                }}
                            >
                                {contextMenu.map((cm) => (
                                    <MenuItem onClick={cm.action}>{cm.title}</MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </Stack>

                {/* Details
				    ========================================= */}
                <Stack flexGrow={1} direction="row" alignItems="flex-start" gap={8}>
                    {Array.isArray(data.info) &&
                        data.info.map((item) => (
                            <Stack alignItems="flex-start" gap={0.5} minWidth={0}>
                                <Typography fontSize={10} fontWeight={400} color="primary">
                                    {item.label}
                                </Typography>
                                <Typography fontSize={14}>{item.value}</Typography>
                            </Stack>
                        ))}

                    {/* Tags
                        ========================================= */}
                    <Stack alignItems="flex-start" gap={0.5} minWidth={0}>
                        <Stack direction={'row'} spacing={1}>
                            {Array.isArray(data.tags) &&
                                data.tags
                                    .reverse()
                                    .slice(0, 2)
                                    .map((tag) => (
                                        <Chip key={tag.key + (tag.primaryKey?.toString() ?? '')} label={tag.value} />
                                    ))}
                            {Array.isArray(data.tags) && data.tags.length > 2 && (
                                <Badge color="secondary" badgeContent={data.tags.length - 3}>
                                    <Chip label={data.tags[2].value} />
                                </Badge>
                            )}
                        </Stack>
                    </Stack>

                    {/* Open button/link
				        ========================================= */}
                    <Stack
                        flexGrow={1}
                        alignSelf="stretch"
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        gap={1}
                    >
                        <BaseButton variant="contained" label="Open" onClick={clickHandler} />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default TemplateCardWide
