//* ======= Libraries
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SxProps, Theme } from '@mui/material'
//* ======= Components and features
import Autocomplete from '@mui/material/Autocomplete'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
//* ======= Custom logic
import { SearchResourceType, SearchResponseType } from 'services/ProjectService'
//* ======= Assets and styles
import SearchIcon from '@mui/icons-material/Search'

interface ISearchField {
    onSearch: (query: string) => void
    placeholder?: string
    navigationPath?: string
    searchApi?: (pid: string | number, term: string, showMessage?: boolean) => Promise<SearchResponseType>
    inputBgColor?: string
    sx?: SxProps<Theme>
}

/**
 * TODO:
 * 	1.	Could be customized by many options and props available to Autocomplete.
 * 	2.	'onSearch' can be used for 'Search' button mouse click.
 * 		At the moment, only the Autocomplete's 'onChange' is passing the values.
 */

function SearchField({
    onSearch,
    placeholder,
    inputBgColor,
    navigationPath = '',
    searchApi,
    ...otherProps
}: ISearchField) {
    const { pid } = useParams()
    const navigator = useNavigate()
    const [query, setQuery] = useState<SearchResourceType>({ primaryKey: 0, title: '' })
    const [searchOptions, setSearchOptions] = useState<SearchResourceType[]>([])

    const searchHandler = () => {
        onSearch(query.title)
    }

    useEffect(() => {
        if (searchApi && query.title.length > 1) {
            searchApi(pid || '', query.title)
                .then((res) => (res.success ? setSearchOptions(res.data) : setSearchOptions([])))
                .catch((e) => setSearchOptions([]))
        } else {
            setSearchOptions([])
        }
    }, [query])

    return (
        <Autocomplete
            id="searchField"
            options={searchOptions}
            value={query}
            freeSolo
            renderOption={(props, option) => (
                <li
                    {...props}
                    onClick={(e) => {
                        option.primaryKey &&
                            option.primaryKey != 0 &&
                            navigator(`${navigationPath}${option.primaryKey}`)
                    }}
                >
                    {option.title}
                </li>
            )}
            getOptionLabel={(option) => {
                if (typeof option == 'string') return option
                return option.title
            }}
            onInputChange={(e, value) => {
                setQuery((q) => ({ ...q, title: value }))
            }}
            onBlur={searchHandler}
            renderInput={(params) => (
                <InputBase
                    inputProps={{ ...params.inputProps }}
                    ref={params.InputProps.ref}
                    name="searchField"
                    onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                            e.currentTarget.blur()
                        }
                    }}
                    placeholder={placeholder}
                    endAdornment={
                        <IconButton
                            onClick={(e) => searchHandler()}
                            sx={{
                                marginLeft: 0.5,

                                color: 'secondary.main',
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    }
                    fullWidth
                    sx={(theme) => ({
                        py: 0.375,
                        paddingLeft: 2,
                        paddingRight: 1,

                        borderRadius: '10px',
                        border: `1px solid ${theme.palette.common.border_3}`,
                        backgroundColor: inputBgColor || 'common.bg_4',

                        '&.Mui-focused': {
                            borderColor: 'primary.main',
                            backgroundColor: 'common.bg_3',
                        },
                    })}
                />
            )}
            disablePortal
            {...otherProps}
        />
    )
}

export default SearchField
