import { EdgeType, NodeAttributeType } from 'features/network-viz/types/NetworkViz.types'
import { SenderreceiverFilterType } from './FilterSenderReceiverDialog'
import { ReportDataSourceRowType } from 'features/report-designer/types/reportDesigner.types'
import { applyAggregation } from 'features/report-designer/helpers/reportDesigner.helper'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'

/**
 * Applies sender and receiver filters to a collection of edges based on node attributes and analytics data.
 * This function filters edges by evaluating specified criteria against sender and receiver nodes.
 * Each edge is tested against all sender and receiver filters. An edge is included in the result if it passes all applicable filters.
 *
 * Filters can evaluate direct node attributes ('info'), perform numeric comparisons between sender and receiver attributes ('difference'),
 * or utilize aggregated analytics data ('analytic'). The function supports a range of comparison operators (e.g., 'eq', 'ne', 'gt', 'lt', 'gte', 'lte')
 * and handles attribute values that are constants, derived from node attributes, or aggregated across nodes.
 *
 * Note:
 * - If an attribute value or comparison value is 'null', the filter condition for that attribute is skipped and the edge will be excluded.
 * - The comparison logic intentionally uses non-strict comparison operators (== and !=) to allow for type coercion in evaluations.
 *
 * @param {EdgeType[]} edges - Array of edges to be filtered. Each edge should have a unique 'id', 'source', and 'target'.
 * @param {SenderreceiverFilterType[]} senderFilters - Filters to apply to the sender node of each edge.
 * @param {SenderreceiverFilterType[]} receiverFilters - Filters to apply to the receiver node of each edge.
 * @param {Object} nodes - Object mapping node IDs to their attributes. Used to access 'info' source attributes.
 * @param {Object} analytics - Analytics data for nodes, used for 'analytic' source attributes and aggregation.
 * @returns {EdgeType[]} - Array of edges that pass all the specified sender and receiver filters.
 */
export const applySenderReceiverFiltersToEdges = (
    edges: EdgeType[],
    senderFilters: SenderreceiverFilterType[],
    receiverFilters: SenderreceiverFilterType[],
    nodes: NetworkVizContextType['nodes'],
    analytics: NetworkVizContextType['analytics']
): EdgeType[] => {
    // Cache for aggregation results to avoid redundant calculations
    const cachedAggregations: Record<string, number | null> = {}

    // the entry point of the function that iterates over the edges and check if they pass the filters or not and return the result
    const filterEdges = (
        edges: EdgeType[],
        senderFilters: SenderreceiverFilterType[],
        receiverFilters: SenderreceiverFilterType[]
    ) => {
        // Create a map to count occurrences of each edge
        const newEdges: EdgeType[] = []
        for (const edge of edges) {
            const sender = edge.source
            const receiver = edge.target

            var passed = true

            for (const senderFilter of senderFilters) {
                const op1 = getFilterAttributeValue(sender, receiver, senderFilter)
                const operator = senderFilter.operator
                const op2 = getComparisonValue(sender, receiver, senderFilter)
                if (op1 === null || op2 === null || !checkCondition(op1, operator, op2)) {
                    passed = false
                    continue
                }
            }
            if (!passed) continue

            for (const receiverFilter of receiverFilters) {
                const op1 = getFilterAttributeValue(receiver, sender, receiverFilter)
                const operator = receiverFilter.operator
                const op2 = getComparisonValue(receiver, sender, receiverFilter)

                if (op1 === null || op2 === null || !checkCondition(op1, operator, op2)) {
                    passed = false
                    continue
                }
            }
            if (passed) newEdges.push(edge)
        }
        return newEdges
    }

    // get the value of a node attribute from the nodes object or the analytics object
    // returns null if the value is not found
    const getNodeValue = (nodeId: string, attribute: NodeAttributeType) => {
        if (attribute.source === 'info') {
            return nodes[nodeId][attribute.field]
        } else if (attribute.source === 'analytic') {
            if (analytics === null) return null
            return analytics[attribute.relationship].nodes[nodeId][attribute.field]
        }
    }

    // get the value of a filter attribute
    const getFilterAttributeValue = (op1: string, op2: string, filter: SenderreceiverFilterType) => {
        if (filter.attribute.value === null) return null
        try {
            if (filter.attribute.mode === 'attribute') {
                return getNodeValue(op1, filter.attribute.value)
            } else if (filter.attribute.mode === 'difference') {
                var value1 = Number(getNodeValue(op1, filter.attribute.value))
                var value2 = Number(getNodeValue(op2, filter.attribute.value))
                if (isNaN(value1) || isNaN(value2)) return null
                return value2 - value1
            }
        } catch {
            return null
        }
    }

    // get the value of the comparison attribute
    const getComparisonValue = (op1: string, op2: string, filter: SenderreceiverFilterType) => {
        if (filter.value.value === null) return null
        switch (filter.value.mode) {
            case 'constant':
                return filter.value.value
            case 'attribute':
                if (filter.value.node === 'receiver') {
                    return getNodeValue(op2, filter.value.value)
                } else {
                    return getNodeValue(op1, filter.value.value)
                }
            case 'aggregate':
                return _applyAggregation(filter.value.method, filter.value.value)
        }
    }

    // check if the condition is true or not
    const checkCondition = (op1: any, operator: string, op2: any) => {
        const n1 = Number(op1)
        const n2 = Number(op2)
        switch (operator) {
            case 'eq':
                // we dont use === because we want to compare the value not the type
                return op1 == op2
            case 'ne':
                // we dont use === because we want to compare the value not the type
                return op1 != op2
            case 'gt':
                if (isNaN(n1) || isNaN(n2)) return false
                return n1 > n2
            case 'lt':
                if (isNaN(n1) || isNaN(n2)) return false
                return n1 < n2
            case 'gte':
                if (isNaN(n1) || isNaN(n2)) return false

                return n1 >= n2
            case 'lte':
                if (isNaN(n1) || isNaN(n2)) return false
                return n1 <= n2
        }
    }

    // apply aggregation to the data
    const _applyAggregation = (
        method: 'avg' | 'min' | 'max' | 'q1' | 'median' | 'q3',
        attribute: NodeAttributeType
    ) => {
        // generate a key for the aggregation to be used as a cache key
        let key = `${method}-${attribute.source}`
        if (attribute.source === 'analytic') key += `-${attribute.relationship}`
        key += `-${attribute.field}`

        // if the aggregation is already calculated, return the result
        if (key in cachedAggregations) return cachedAggregations[key]

        // if the aggregation is not calculated, calculate it and cache the result

        // prepare the data for the aggregation
        let data: ReportDataSourceRowType[] = []

        for (let key in nodes) {
            const value = getNodeValue(key, attribute)
            if (value !== null && value !== undefined) {
                data.push({
                    [attribute.field]: value,
                })
            }
        }

        // apply the aggregation method
        const result = applyAggregation({
            method: method,
            field: attribute.field,
            data: data,
        })

        // cache the result
        cachedAggregations[key] = result

        return result
    }

    return filterEdges(edges, senderFilters, receiverFilters)
}
