import { Breadcrumbs, Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import BaseButton from 'components/base/BaseButton'
import React from 'react'
import {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from '../context/NetworkVizContext'

type Props = {}

function DrillDownBreadcrumb({}: Props) {
    const { level } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()

    const updateLevel = (level: NetworkVizContextType['level']) => {
        contextDispatch({ type: 'LEVEL_UPDATE', payload: level })
    }

    if (level === 'all') return null
    return (
        <Box sx={{ height: '48px', alignItems: 'center', display: 'flex' }}>
            <Breadcrumbs>
                <Link
                    underline="hover"
                    color="primary"
                    onClick={() => updateLevel('all')}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    All
                </Link>
                <Typography>{level}</Typography>
            </Breadcrumbs>
        </Box>
    )
}

export default DrillDownBreadcrumb
