import React, { useEffect, useMemo, useState } from 'react'
import {
    Typography,
    Stack,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormGroup,
    FormLabel,
    Tooltip,
    IconButton,
    FormHelperText,
} from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { NetworkVizContextType, useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import {
    ALAAMAnalyticsType,
    ALAAMBehaviorType,
    ALAAMCharacteristicType,
    ALAAMSettingsType,
    ERGMAnalyticsType,
    ERGMBehaviorType,
    ERGMCharacteristicType,
    ERGMSettingsType,
    EdgeRenderType,
    EdgeType,
    NodeRenderType,
} from '../types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import BaseButton from 'components/base/BaseButton'
import { toast } from 'react-toastify'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import InfoIcon from '@mui/icons-material/Info'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import DeleteIcon from '@mui/icons-material/Delete'
var lz = require('lz-string')

// Define the properties that this component expects
interface ALAAMDialogProps {
    onClose: () => void
}

const ALAAMCharacteristicInfo = {
    changeDensity: {
        name: 'Density',
        description:
            "This parameter measures the overall connectivity in the network, often referred to as 'density'. It quantifies the proportion of potential connections in the network that are actual connections.",
    },
    changeSenderALAAM: {
        name: 'Sender Effect',
        description:
            "This parameter measures the effect of sender's attributes on the likelihood of a tie. It represents how a node's attributes influence its tendency to form outgoing ties.",
    },
    changeReceiverALAAM: {
        name: 'Receiver Effect',
        description:
            "This parameter measures the effect of receiver's attributes on the likelihood of a tie. It represents how a node's attributes influence its tendency to receive incoming ties.",
    },
    changeContagionArc: {
        name: 'Contagion (Change Arc)',
        description:
            'This parameter measures the tendency for similar characteristics to cluster in the network. It represents how a tie between nodes is influenced by their similarities or differences in attributes.',
    },
}

const ALAAMBehaviorInfo = {
    Density: {
        name: 'Density',
        description: 'Indicates the proportion of potential connections in the network that are actual connections.',
    },
    Sender: {
        name: 'Sender Effect',
        description:
            "Indicates the effect of sender's attributes on the likelihood of a tie. Represents how a node's attributes influence its tendency to form outgoing ties.",
    },
    Receiver: {
        name: 'Receiver Effect',
        description:
            "Indicates the effect of receiver's attributes on the likelihood of a tie. Represents how a node's attributes influence its tendency to receive incoming ties.",
    },
    Contagion: {
        name: 'Contagion',
        description:
            'Indicates the tendency for similar characteristics to cluster in the network. Represents how a tie between nodes is influenced by their similarities or differences in attributes.',
    },
}

const ALAAMDialog: React.FC<ALAAMDialogProps> = ({ onClose }) => {
    const { pid, wid } = useParams()
    // Use the network visualization context
    const {
        nodeRenders,
        edgeRenders,
        analyticsSettings,
        nodeDataSchema,
        edgeDataSchema,
        nodes,
        edges,
        analytics,
        ...networkVizContext
    } = useNetworkVizContext()

    // Use the network visualization dispatch
    const dispatchContext = useNetworkVizDispatch()

    // Define the state for this component
    const [isRunning, setIsRunning] = useState<boolean>(false)

    const [localAlaamSettings, setLocalAlaamSettings] = useState<ALAAMSettingsType>(analyticsSettings.alaam)

    useEffect(() => {
        setLocalAlaamSettings({
            ...analyticsSettings.alaam,
            behavior: Object.keys(ALAAMBehaviorInfo) as ALAAMBehaviorType[],
            characteristics: Object.keys(ALAAMCharacteristicInfo) as ALAAMCharacteristicType[],
        })
    }, [analyticsSettings.alaam])

    const networkOptions = useMemo<string[]>(() => {
        const groupByField = edgeDataSchema.fields[analyticsSettings.groupBy]
        if (groupByField.type === 'string') {
            return groupByField.range
        } else if (groupByField.type === 'number') {
            return groupByField.range.values
        } else if (groupByField.type === 'date') {
            // convert Date objects to strings
            return groupByField.range.values.map((date) => date.toISOString())
        }
        return [] // return empty array as default
    }, [edgeDataSchema, analyticsSettings.groupBy])

    // Determine whether the "Run" button should be disabled
    const isRunDisabled = useMemo(
        () =>
            !(
                localAlaamSettings.characteristics.length > 0 &&
                localAlaamSettings.explanatoryAttributes.length > 0 &&
                localAlaamSettings.behavior.length > 0 &&
                localAlaamSettings.networks.length > 0 &&
                localAlaamSettings.targetAttributes.length > 0
            ),
        [localAlaamSettings]
    )

    // Handler for when the "Apply Advance Analytics" operation is performed
    const handleApplyAdvanceAnalytics = async () => {
        if (isRunDisabled) return

        const abortController = new AbortController()

        const ToastCloseButton = ({ closeToast }: { closeToast?: () => void }) => (
            <BaseButton
                onClick={() => {
                    abortController.abort()
                    closeToast && closeToast()
                }}
                label="Cancel"
            />
        )

        const toastId = toast.loading('Calculating Network Analytics', {
            // add close button to toast
            closeButton: <ToastCloseButton />,
        })

        setIsRunning(true)
        try {
            const nodeDict: NetworkVizContextType['nodes'] = {}

            const dic: Record<string, string> = {}

            for (const [id, node] of Object.entries(nodes)) {
                const randomId = uuidv4()
                dic[id] = randomId
                nodeDict[randomId] = {
                    id: randomId,
                    ...Object.fromEntries(
                        Object.entries(node).filter(
                            ([key]) =>
                                localAlaamSettings.targetAttributes.includes(key) ||
                                localAlaamSettings.explanatoryAttributes.some((x) => x.attribute === key)
                        )
                    ),
                }
            }

            const networks: Record<string, EdgeType[]> = {}

            for (const edge of Object.values(edges)) {
                const network = (edge[analyticsSettings.groupBy] + '').toLowerCase()
                if (!localAlaamSettings.networks.includes(network)) continue
                if (networks[network] === undefined) {
                    networks[network] = []
                }
                networks[network].push({ ...edge, source: dic[edge.source], target: dic[edge.target] })
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/analytics/alaam`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    characteristics: localAlaamSettings.characteristics,
                    behaviors: localAlaamSettings.behavior,
                    explanatoryAttributes: localAlaamSettings.explanatoryAttributes,
                    targetAttributes: localAlaamSettings.targetAttributes,
                    quantile: localAlaamSettings.quantile,
                    networks: networks,
                    nodes: nodeDict,
                }),
                credentials: 'include',
                signal: abortController.signal, // pass the abort signal to the fetch request
            })

            if (response.status === 200) {
                toast.update(toastId, {
                    render: 'ALAAM Calculation Complete.',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
                const alaams: Record<string, ALAAMAnalyticsType> = await response.json()
                const newAnalytics = analytics === null ? {} : structuredClone(analytics)
                Object.entries(alaams).forEach(([key, alaam]) => {
                    if (newAnalytics[key] === undefined) {
                        newAnalytics[key] = {
                            nodes: {},
                            errors: {},
                            isolates: [],
                            strongly_connected_components: [],
                            weakly_connected_components: [],
                            greedy_modularity_communities: {},
                            rich_club_coefficient: {},
                            graph: {},
                            schema: {},
                            alaam,
                        }
                    } else {
                        newAnalytics[key].alaam = alaams[key]
                    }
                })

                dispatchContext({ type: 'ANALYTICS_SETTINGS_EDIT', payload: { alaam: localAlaamSettings } })
                dispatchContext({ type: 'ANALYTICS_UPDATE', payload: newAnalytics })
                // handleClose()
            } else {
                toast.update(toastId, {
                    render: 'Failed to run analytics.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
            }
        } catch {
            if (abortController.signal.aborted) {
                // Fetch was aborted
                toast.update(toastId, {
                    render: 'Request was cancelled.',
                    type: 'warning',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
            } else {
                // Other error occurred
                toast.update(toastId, {
                    render: 'Failed to run analytics.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
            }
        }
        setIsRunning(false)
    }
    // Handler for when the "Close Dialog" operation is performed
    const handleClose = () => {
        onClose()
    }

    // the handleCharacteristicChange and handleBehaviorChange are defined as

    const handleCharacteristicChange = (event: React.ChangeEvent<HTMLInputElement>, value: ALAAMCharacteristicType) => {
        if (event.target.checked) {
            setLocalAlaamSettings((prev) => ({ ...prev, characteristics: [...prev.characteristics, value] }))
        } else {
            setLocalAlaamSettings((prev) => ({
                ...prev,
                characteristics: prev.characteristics.filter((item) => item !== value),
            }))
        }
    }

    const handleBehaviorChange = (event: React.ChangeEvent<HTMLInputElement>, value: ALAAMBehaviorType) => {
        if (event.target.checked) {
            setLocalAlaamSettings((prev) => ({ ...prev, behavior: [...prev.behavior, value] }))
        } else {
            setLocalAlaamSettings((prev) => ({
                ...prev,
                behavior: prev.behavior.filter((item) => item !== value),
            }))
        }
    }

    const handleAttributeChange = (values: string[]) => {
        setLocalAlaamSettings((prev) => ({
            ...prev,
            explanatoryAttributes: values.map((attribute) => ({
                attribute: attribute,
                type: prev.explanatoryAttributes.find((x) => x.attribute === attribute)?.type ?? 'auto',
            })),
        }))
    }

    const handleOutcomeAttributeChange = (values: string[]) => {
        setLocalAlaamSettings((prev) => ({ ...prev, targetAttributes: values }))
    }

    const handleNetworkChange = (values: string[]) => {
        setLocalAlaamSettings((prev) => ({ ...prev, networks: values }))
    }

    const handleQuantileChange = (values: ALAAMSettingsType['quantile']) => {
        setLocalAlaamSettings((prev) => ({ ...prev, quantile: values }))
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Grid2 container>
                    <Grid2 xs={12} px={2}>
                        <Typography variant="h6">
                            Assessment of Latent and Active Attributes Model (ALAAM) Settings
                        </Typography>
                        <Typography>
                            ALAAM is a statistical method for analyzing the effect of latent and active attributes in
                            social networks. You can select characteristics and behaviors to customize your ALAAM.
                        </Typography>
                        <Typography>
                            Choose the desired attributes, characteristics and behaviors, then press the 'Run' button.
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={6} p={2}>
                        <BaseSelectWithLabel
                            fullWidth
                            selectProps={{ multiple: true }}
                            label="Select Networks/Relationships"
                            helperText="Select the networks you want to analyze."
                            options={networkOptions}
                            value={localAlaamSettings.networks}
                            onChange={handleNetworkChange}
                        />
                    </Grid2>

                    <Grid2 xs={12} md={6} p={2}>
                        <BaseSelectWithLabel
                            fullWidth
                            label="Quantile"
                            selectProps={{ multiple: false }}
                            helperText="Select the quantile for thresholding the target variables."
                            options={[0.25, 0.5, 0.75]}
                            value={localAlaamSettings.quantile}
                            onChange={handleQuantileChange}
                        />
                    </Grid2>

                    <Grid2 xs={12} md={6} p={2}>
                        <BaseSelectWithLabel
                            fullWidth
                            selectProps={{ multiple: true }}
                            label="Target Variables"
                            helperText="Select the attributes you want to predict or explain in your network model."
                            options={nodeDataSchema.numericOptions}
                            value={localAlaamSettings.targetAttributes}
                            onChange={handleOutcomeAttributeChange}
                        />
                    </Grid2>

                    <Grid2 xs={12} md={6} p={2}>
                        <BaseSelectWithLabel
                            fullWidth
                            selectProps={{ multiple: true }}
                            label="Explanatory Variables"
                            helperText="Select the attributes you want to use to predict or explain the outcome variables."
                            options={Object.keys(nodeDataSchema.fields).sort((a, b) => a.localeCompare(b))}
                            value={localAlaamSettings.explanatoryAttributes.map((x) => x.attribute)}
                            onChange={handleAttributeChange}
                        />
                    </Grid2>

                    {/* <Grid2 xs={12} md={6} p={2}>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Select ERGM Characteristics</FormLabel>
                            <FormGroup>
                                {Object.entries(ALAAMCharacteristicInfo).map(
                                    ([value, { name, description }]) => (
                                        <FormControlLabel
                                            key={value}
                                            control={
                                                <Checkbox
                                                    checked={localAlaamSettings.characteristics.includes(
                                                        value as ALAAMCharacteristicType
                                                    )}
                                                    onChange={(event) =>
                                                        handleCharacteristicChange(
                                                            event,
                                                            value as ALAAMCharacteristicType
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <>
                                                    <span>{name}</span>
                                                    <Tooltip title={description}>
                                                        <IconButton size="small">
                                                            <InfoIcon color="info" fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            }
                                        />
                                    )
                                )}
                            </FormGroup>
                            <FormHelperText>
                                Select the network characteristics you want to consider in your model.
                            </FormHelperText>
                        </FormControl>
                    </Grid2> */}

                    {/* <Grid2 xs={12} md={6} p={2}>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Select ALAAM Behaviors</FormLabel>
                            <FormGroup>
                                {Object.entries(ALAAMBehaviorInfo).map(([value, { name, description }]) => (
                                    <FormControlLabel
                                        key={value}
                                        control={
                                            <Checkbox
                                                checked={localAlaamSettings.behavior.includes(
                                                    value as ALAAMBehaviorType
                                                )}
                                                onChange={(event) =>
                                                    handleBehaviorChange(event, value as ALAAMBehaviorType)
                                                }
                                            />
                                        }
                                        label={
                                            <>
                                                <span>{name}</span>
                                                <Tooltip title={description}>
                                                    <IconButton size="small">
                                                        <InfoIcon color="info" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    />
                                ))}
                            </FormGroup>
                            <FormHelperText>
                                Select the network behaviors you want to consider in your model.
                            </FormHelperText>
                        </FormControl>
                    </Grid2> */}

                    <Grid2 xs={12} p={2}>
                        <Stack
                            direction="column"
                            gap={1}
                            sx={{ height: '500px', overflowY: 'auto' }}
                            className="u-scrollbar"
                        >
                            <Typography variant="h6">ALAAM Attributes</Typography>
                            {localAlaamSettings.explanatoryAttributes.length === 0 && (
                                <Typography variant="body2" color="textSecondary">
                                    No attributes selected
                                </Typography>
                            )}
                            {localAlaamSettings.explanatoryAttributes.map((attribute) => {
                                console.log(attribute)
                                return (
                                    <Stack direction="row" gap={1} key={attribute.attribute}>
                                        <BaseFilledTextField
                                            label="Attribute"
                                            disabled
                                            value={attribute.attribute}
                                            fullWidth
                                        />
                                        <BaseSelectWithLabel
                                            fullWidth
                                            label="Data type"
                                            options={['auto', 'binary', 'categorical', 'numeric']}
                                            value={attribute.type}
                                            onChange={(value) => {
                                                setLocalAlaamSettings((prev) => ({
                                                    ...prev,
                                                    explanatoryAttributes: prev.explanatoryAttributes.map((x) =>
                                                        x.attribute === attribute.attribute ? { ...x, type: value } : x
                                                    ),
                                                }))
                                            }}
                                        />
                                        <Tooltip title="Remove attribute">
                                            <IconButton
                                                onClick={() => {
                                                    setLocalAlaamSettings((prev) => ({
                                                        ...prev,
                                                        explanatoryAttributes: prev.explanatoryAttributes.filter(
                                                            (x) => x.attribute !== attribute.attribute
                                                        ),
                                                    }))
                                                }}
                                            >
                                                <DeleteIcon color="warning" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Grid2>
                </Grid2>
            </DialogContent>

            <DialogActions>
                <BaseButton color="secondary" onClick={handleClose}>
                    Close
                </BaseButton>
                <BaseButton
                    variant="contained"
                    disabled={isRunDisabled || isRunning}
                    color="primary"
                    onClick={handleApplyAdvanceAnalytics}
                >
                    Run
                </BaseButton>
            </DialogActions>
        </React.Fragment>
    )
}

export default ALAAMDialog
