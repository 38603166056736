//* ======= Libraries
import React, { useContext, useEffect, useMemo, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import NewProjectStrip from 'features/general-dashboard/NewProjectStrip'
import Stack from '@mui/material/Stack'
import { ResourceType } from 'types/resources'
import { useNavigate, useParams } from 'react-router-dom'
import ProjectDrawerNavigation from './Navigation'
import { ProjectContext } from 'contexts/ProjectContext'
import CreateWidgetDialog from 'features/widget-create-dialog/CreateWidgetDialog'
import NavigableDialog from 'components/dialog/NavigableDialog'
import { ICreateResourceDialogStateType } from './ProjectDashboard.route'
import { GetWidgets, WidgetType } from 'services/WidgetApi'
import ProjectsPanel from 'features/general-dashboard/ProjectsPanel'
import moment from 'moment'
import Badge from '@mui/material/Badge'
import Chip from '@mui/material/Chip'
import DashboardSkeleton from './DashboardSkeleton'
import { PERMISSIONS } from 'helpers/constants'
//* ======= Custom logic
//* ======= Assets and styles

interface IProjectDashboard {}

function WidgetDashboard({}: IProjectDashboard) {
    const navigator = useNavigate()
    const { pid } = useParams()
    const { project, drawerContent, hasPermission } = useContext(ProjectContext)

    const [widgets, setWidgets] = useState<ResourceType[]>([])
    const [widgetTags, setWidgetTags] = useState<string[]>([])
    const [templates, setTemplates] = useState<ResourceType[]>([])
    const [templatesTags, setTemplateTags] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const parseWidgets = (allWidgets: Array<WidgetType>, isTemplate = false) => {
        const canDeleteWidget = hasPermission(PERMISSIONS.widget.DeleteWidget)
        const canEditWidgetTag = hasPermission(PERMISSIONS.widget.ManageWidgetTag)
        const canRename = hasPermission(PERMISSIONS.widget.UpdateWidget)
        const tempWidgets: ResourceType[] = allWidgets
            .filter((tp) => (!isTemplate && !tp.isTemplate) || (isTemplate && tp.isTemplate))
            .map((s) => ({
                id: s.primaryKey || -1,
                title: s.title,
                image: s.thumbnail,
                // favourite: s.favourite,
                info: [
                    {
                        label: 'Type',
                        value: `${s.type}`,
                    },
                    {
                        label: 'Last modified',
                        value: `${`${moment(s.createdOn).format('DD-MM-YYYY HH:mm')}`}`,
                    },
                ],
                canDelete: canDeleteWidget,
                canEditTag: canEditWidgetTag,
                canRename: canRename,
                link: `/project/${pid}/networks/${s.primaryKey}`,
                // rating: s.rating,
                tags: s.tags,
            }))

        let tempTags = new Set<string>()
        for (let p of tempWidgets) {
            if (p.tags)
                for (let t of p.tags) {
                    t.key && tempTags.add(t.key)
                }
        }

        return {
            widgets: tempWidgets,
            tags: Array.from(tempTags),
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [widgets])

    //fetch data
    useEffect(() => {
        //if not pid, do nothing
        if (pid == null) return

        setIsLoading(true)
        GetWidgets(pid)
            .then((response) => {
                if (response.success) {
                    // get all widgets
                    const allWidgets = parseWidgets(response.data)
                    setWidgets(allWidgets.widgets)
                    setWidgetTags(allWidgets.tags)
                    // get all templates
                    const allTemplates = parseWidgets(response.data, true)
                    setTemplates(allTemplates.widgets)
                    setTemplateTags(allTemplates.tags)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }, [])

    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
    const [createDialogStep, setCreateDialogStep] = useState<number>(0)

    const handleCloseCreateDialog = () => setCreateDialogOpen(false)
    const handleOpenCreateDialog = () => setCreateDialogOpen(true)

    const CreateProjectDialogs: ICreateResourceDialogStateType[] = [
        {
            title: 'Create Widget',
            disableBackButton: true,
            maxWidth: 'lg',
            component: (
                <CreateWidgetDialog
                    onClose={handleCloseCreateDialog}
                    onConfirm={(id?: number) => {
                        if (id) navigator('' + id)
                        handleCloseCreateDialog()
                    }}
                />
            ),
        },
    ]

    const dialogState = useMemo<ICreateResourceDialogStateType>(() => {
        return CreateProjectDialogs[createDialogStep]
    }, [createDialogStep])

    if (project == null) return <DashboardSkeleton />

    const canCreateWidget = hasPermission(PERMISSIONS.widget.CreateWidget)

    return (
        <PageCard>
            <PageCard.Side>{project?.primaryKey && <MainDrawer content={drawerContent} />}</PageCard.Side>

            <PageCard.Body>
                <Stack spacing={1} width="100%" height="100%">
                    {canCreateWidget && (
                        <NewProjectStrip
                            templates={templates}
                            tagCategories={templatesTags}
                            onCreate={handleOpenCreateDialog}
                            resourceName="Widget"
                        />
                    )}

                    <ProjectsPanel
                        resources={widgets}
                        isLoading={isLoading}
                        setResources={setWidgets}
                        tagCategories={widgetTags}
                        resourceName="widget"
                    />
                </Stack>
            </PageCard.Body>

            {/* Create Widget Dialog
				========================================= */}
            <NavigableDialog
                title={dialogState.title}
                open={createDialogOpen}
                onClose={handleCloseCreateDialog}
                backHandler={dialogState.onBack}
                disableBackButton={dialogState.disableBackButton}
                fullWidth={true}
                maxWidth={dialogState.maxWidth}
                scroll="body"
            >
                {dialogState.component}
            </NavigableDialog>
        </PageCard>
    )
}

export default WidgetDashboard
