import { useEffect, useState } from 'react'
import { Autocomplete, Box, Popper, Typography } from '@mui/material'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { NodeType } from 'features/network-viz/types/NetworkViz.types'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import SearchIcon from '@mui/icons-material/Search'
import { isSelectedItemEquals } from '../helpers/NetworkViz.helper'
export interface SearchProps {
    size?: 'small' | 'normal'
    container: HTMLDivElement | null
}
// ToDo add feature for a compact view when size is small
export default function BasicNetworkSearchField({ size = 'normal', container }: SearchProps) {
    const { nodes, nodeRenders, selectedItem, nodeInfoConfig, networkShrink, nodeGroupInfo } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [value, setValue] = useState<NodeType | null>(null)
    const [options, setOptions] = useState<readonly NodeType[]>([])

    useEffect(() => {
        let tmpOptions: NodeType[]
        if (networkShrink?.enabled) {
            tmpOptions = nodeRenders
                .filter((x) => x.hide !== true)
                .map((x) => ({
                    id: x.id,
                    [nodeInfoConfig.title || 'label']: x.groupKey + ':' + x.id,
                }))
        } else {
            tmpOptions = nodeRenders.filter((x) => x.hide !== true).map((x) => nodes[x.id])
        }
        setOptions(tmpOptions)
        setValue(tmpOptions.find((x) => isSelectedItemEquals(selectedItem, { mode: 'node', id: x.id })) || null)
    }, [nodeRenders])

    useEffect(() => {
        const nodeId = value === null ? null : value.id
        if (!isSelectedItemEquals(selectedItem, nodeId ? { mode: 'node', id: nodeId } : null)) {
            setValue(options.find((x) => isSelectedItemEquals(selectedItem, { mode: 'node', id: x.id })) || null)
        }
    }, [selectedItem])

    return (
        <Autocomplete
            id="node-search"
            className="node-search"
            sx={{ width: 250 }}
            getOptionLabel={(option) =>
                typeof option === 'string'
                    ? option
                    : nodeInfoConfig.title && nodeInfoConfig.title !== ''
                    ? option[nodeInfoConfig.title]
                    : `Node ${option.id}`
            }
            filterOptions={(options, params) => {
                if (params.inputValue.length < 2) return []
                const result: NodeType[] = []
                for (let node of options) {
                    for (let key in node) {
                        if ((node[key] + '').trim().toLowerCase().includes(params.inputValue.trim().toLowerCase())) {
                            result.push(node)
                            break
                        }
                    }
                }
                return result
            }}
            options={options}
            value={value}
            noOptionsText="No Node"
            onChange={(event: any, newValue: NodeType | null) => {
                setValue(newValue)
                dispatchContext({
                    type: 'SELECTED_ITEM_CHANGE',
                    payload: newValue === null ? null : { mode: 'node', id: newValue.id },
                })
            }}
            size="small"
            renderInput={(params) => <BaseFilledTextField {...params} label="Search for a node" fullWidth />}
            forcePopupIcon={false}
            blurOnSelect
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Typography>
                            {nodeInfoConfig.title && nodeInfoConfig.title !== ''
                                ? option[nodeInfoConfig.title]
                                : `Node ${option.id}`}
                        </Typography>
                    </li>
                )
            }}
            PopperComponent={(props) => <Popper {...props} />}
        />
    )
}
