import { Stack, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import { useState } from 'react'
import { LayoutSettingsType } from '../../../types/NetworkViz.types'

type NetworkForceSettingsProps = {
    settings: LayoutSettingsType['force']
    saveSettings: (settings: LayoutSettingsType['force']) => void
}

function NetworkForceSettings({ settings, saveSettings }: NetworkForceSettingsProps) {
    const [_settings, setSettings] = useState(settings)

    const updateSettings = (filed: keyof LayoutSettingsType['force']) => (value: number) => {
        setSettings(pv => ({ ...pv, [filed]: value }))
    }

    return (
        <Stack gap={2}>
            <Stack>
                <Typography>Attraction</Typography>
                <ValueSliderPicker
                    min={0}
                    max={100}
                    step={0.0001}
                    value={_settings.attraction}
                    onChange={updateSettings('attraction')}
                />
            </Stack>
            <Stack>
                <Typography>Gravity</Typography>
                <ValueSliderPicker
                    min={0}
                    max={100}
                    step={0.1}
                    value={_settings.gravity}
                    onChange={updateSettings('gravity')}
                />
            </Stack>
            <Stack>
                <Typography>Inertia</Typography>
                <ValueSliderPicker
                    min={0}
                    max={1}
                    step={0.1}
                    value={_settings.inertia}
                    onChange={updateSettings('inertia')}
                />
            </Stack>
            <Stack>
                <Typography>Max Move</Typography>
                <ValueSliderPicker
                    min={10}
                    max={100}
                    step={1}
                    value={_settings.maxMove}
                    onChange={updateSettings('maxMove')}
                />
            </Stack>
            <Stack>
                <Typography>Max Iteration</Typography>
                <ValueSliderPicker
                    min={20}
                    max={100}
                    step={10}
                    value={_settings.maxIteration}
                    onChange={updateSettings('maxIteration')}
                />
            </Stack>

            <BaseButton
                label="Apply"
                onClick={(evt) => saveSettings(_settings)}
                variant="outlined"
            />
        </Stack>
    )
}

export default NetworkForceSettings