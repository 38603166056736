//* ======= Libraries
import React, { useState, useRef } from 'react'
import { Stack, Box, Typography, IconButton, Skeleton } from '@mui/material'
//* ======= Components and features
import ReportSlide from 'features/report-designer/slide/ReportSlide'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, {
    ReportDesignerStoreStateType,
    useReportActiveSlide,
    useReportSlideOnboarding,
} from 'features/report-designer/store/reportDesignerStore'
//* ======= Assets and styles
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import useResizeObserver from 'use-resize-observer'
import { HelpButton } from '../view/ReportView_Temp'

let resizeTimoutRef: NodeJS.Timeout | null = null

function ReportSlideContainer() {
    const {
        status,
        errorMessage,
        viewMode,
        layoutSettings,
        updateViewMode,
        updateLayoutSettingsSidebarView,
        updateSlide,
    } = useReportStore((store) => ({
        status: store.status,
        errorMessage: store.errorMessage,
        viewMode: store.viewMode,
        layoutSettings: store.layoutSettings,
        updateViewMode: store.updateViewMode,
        updateLayoutSettingsSidebarView: store.updateLayoutSettingsSidebarView,
        updateSlide: store.updateSlide,
    }))
    const activeSlide = useReportActiveSlide()

    const [isResizing, setIsResizing] = useState(false)

    const slideWrapperRef = useRef<HTMLDivElement>(null)

    const [slideWrapperDimensions, setSlideWrapperDimensions] = useState<{ width: number; height: number } | null>(null)

    useResizeObserver({
        ref: slideWrapperRef,
        onResize: (size) => {
            if (resizeTimoutRef) clearTimeout(resizeTimoutRef)
            setIsResizing(true)
            setSlideWrapperDimensions({ width: size.width ?? -1, height: size.height ?? -1 })
            resizeTimoutRef = setTimeout(() => {
                setIsResizing(false)
            }, 1000)
        },
        round: (number) => Math.floor(number),
    })

    // On click inside empty areas, change settings sidebar view and de-select active widget.
    const onEmptyAreaClick = (
        evt: React.MouseEvent<HTMLDivElement | HTMLParagraphElement, MouseEvent>,
        targetSettingsView: ReportDesignerStoreStateType['layoutSettings']['settingsSidebar']['view']
    ) => {
        if (evt.target === evt.currentTarget && ['pending', 'faulty'].includes(status) === false) {
            if (layoutSettings.settingsSidebar.view !== targetSettingsView) {
                updateLayoutSettingsSidebarView({
                    view: targetSettingsView,
                })
            }
        } else {
            // Ignore click
        }
    }

    const updateZoomLevel = (step: 1 | -1) => {
        if (activeSlide === null) return

        const newZoomLevel = step === 1 ? activeSlide.zoomLevel + 1 : activeSlide.zoomLevel - 1

        // Prevent updating zoom level beyond its bounds.
        if (newZoomLevel < 0 || newZoomLevel > 4) return

        updateSlide({
            slideId: activeSlide.id,
            data: {
                zoomLevel: newZoomLevel,
            },
        })
    }

    return (
        <Stack
            id={'report-slide-container'}
            onClick={(evt) => onEmptyAreaClick(evt, 'report')}
            sx={(theme) => ({
                width: '100%',
                minWidth: 0,
                height: '100%',
                minHeight: 0,
                // Using padding instead of margin will cause a small overflow which then shows a
                // scrollbar on certain situations.
                marginBottom: theme.spacing(1.5),
                position: 'relative',
                // ToDo: Check if this is necessary - there is an issue with a backdrop in the on-boarding widget, where as it's inside this container,
                // ToDo: it wont cover the right side toolbar
                isolation: 'isolate',
            })}
        >
            {status === 'pending' ? (
                /*  "pending" view
                    ========================================= */
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={(theme) => ({
                        flexGrow: 1,

                        padding: theme.spacing(1),
                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    <SnaCircularLoading />
                </Stack>
            ) : status === 'faulty' ? (
                /*  "faulty" view
                    ========================================= */
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    sx={(theme) => ({
                        flexGrow: 1,

                        padding: theme.spacing(1),

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    <ErrorOutlineIcon
                        sx={(theme) => ({
                            height: 32,
                            width: 32,

                            color: theme.palette.common.fuschia80,
                        })}
                    />

                    <Typography fontSize={14} textAlign="center" whiteSpace="pre-line">
                        {errorMessage}
                    </Typography>
                </Stack>
            ) : (
                /*  Main view
                    We currently don't show anything if activeSlide doesn't have a valid value.
                    Change if necessary.
                    ========================================= */
                activeSlide !== null && (
                    <>
                        {/* Title and preview button
                            ========================================= */}
                        {viewMode === 'design' && (
                            <Stack
                                onClick={(evt) => onEmptyAreaClick(evt, 'slide')}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={2}
                                sx={{
                                    flex: '0 0 0',

                                    paddingBottom: 1.5,
                                }}
                            >
                                <Typography
                                    onClick={(evt) =>
                                        onEmptyAreaClick(
                                            evt as React.MouseEvent<HTMLParagraphElement, MouseEvent>,
                                            'slide'
                                        )
                                    }
                                    variant="body2"
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',

                                        cursor: 'default',
                                    }}
                                >
                                    {activeSlide.title || 'Blank Slide'}
                                </Typography>

                                <HelpButton />

                                <BaseButton
                                    label="Preview"
                                    className="fullscreen-toggle-button"
                                    onClick={(evt) => updateViewMode({ mode: 'preview' })}
                                    startIcon={<FullscreenIcon />}
                                    variant="outlined"
                                />
                            </Stack>
                        )}

                        {/* if isResizing is true, show loading indicator
                            ========================================= */}
                        {isResizing && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 20,
                                }}
                            >
                                <SnaCircularLoading withText={false} />
                            </Box>
                        )}

                        {/* Slide wrapper (overflow parent)
                            ========================================= */}
                        <Box
                            ref={slideWrapperRef}
                            // This ID is tied to logic. Change accordingly.
                            id="report_slide_container"
                            onClick={(evt) => onEmptyAreaClick(evt, 'slide')}
                            sx={(theme) => ({
                                flexGrow: 1,

                                display: 'grid',
                                justifyItems: 'center',
                                opacity: isResizing ? 0 : 1,

                                minWidth: 0,
                                minHeight: 0,
                                overflow: 'auto',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Slide
                                ========================================= */}
                            <ReportSlide wrapperDimensions={slideWrapperDimensions} isResizing={isResizing} />
                        </Box>

                        {/* Slide controls
                            ========================================= */}
                        {viewMode === 'design' && (
                            <Stack
                                onClick={(evt) => onEmptyAreaClick(evt, 'report')}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                sx={{
                                    flex: '0 0 0',
                                }}
                            >
                                {/* Zoom
                                    ========================================= */}
                                <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
                                    <IconButton
                                        onClick={(evt) => updateZoomLevel(-1)}
                                        disabled={activeSlide.zoomLevel === 0}
                                    >
                                        <ZoomOutIcon
                                            sx={{
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    </IconButton>

                                    <Typography fontSize={12} fontWeight={500}>
                                        {activeSlide.zoomLevel * 25 + 100}%
                                    </Typography>

                                    <IconButton
                                        onClick={(evt) => updateZoomLevel(1)}
                                        disabled={activeSlide.zoomLevel === 4}
                                    >
                                        <ZoomInIcon
                                            sx={{
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        )}
                    </>
                )
            )}
        </Stack>
    )
}

export default ReportSlideContainer
