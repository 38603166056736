import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import { GridColDef } from '@mui/x-data-grid-pro'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import {
    ReportDataSourceCalcualtedValueType,
    ReportDataSourceType,
    ReportNetworkDataSourceType,
    ReportStaticDataSourceType,
    ReportSurveyDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import useReportStore from '../store/reportDesignerStore'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { Box, IconButton, Tooltip } from '@mui/material'
import FlexibleSelect, {
    FlexibleSelectGroupedOptionType,
    FlexibleSelectOptionType,
} from 'components/group-field-selector/FlexibleSelect'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import * as math from 'mathjs'
import ExpressionEditor, { ExpressionEditorSuggestionType } from 'features/ExpressionEditor/ExpressionEditor'
import {
    collectExpressionVariableVariables,
    computeCalculatedAttributes,
    getExpressionVariableOptions,
} from '../helpers/reportDesigner.helper'
import { cloneDeep } from 'lodash'

const SampleCsv = require('assets/sample/report.csv')

type Props = {
    open: boolean
    dataSourceId: ReportDataSourceType['id'] | null
    onClose: () => void
}

type CalculatedNodeAttributeType = ReportDataSourceCalcualtedValueType & { error?: string }

export default function EditReportDataSourceDialog({ open, dataSourceId, onClose }: Props) {
    const { dataSources, updateDataSource } = useReportStore((store) => ({
        dataSources: store.dataSources,
        updateDataSource: store.updateDataSource,
    }))

    const [datasource, setDatasource] = useState<ReportDataSourceType | null>(null)

    const [calculatedNodeAttributes, setCalculatedNodeAttributes] = useState<CalculatedNodeAttributeType[]>([])

    useEffect(() => {
        if (dataSourceId) {
            const dataSource = dataSources.find((ds) => ds.id === dataSourceId)
            if (dataSource) {
                setDatasource(dataSource)
                setCalculatedNodeAttributes(dataSource.calcuatedValues || [])
            } else {
                setDatasource(null)
            }
        }
    }, [dataSourceId, dataSources])

    const handleSaveChangesClick = () => {
        if (datasource) {
            updateDataSource({
                dataSourceId: datasource.id,
                data: {
                    calcuatedValues: calculatedNodeAttributes,
                },
            })
        }
        onClose()
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>Edit Datasource </DialogTitle>

            <DialogContent sx={{ height: '80vh' }}>
                {datasource === null ? (
                    <SnaCircularLoading />
                ) : (
                    <Box height="100%">
                        {datasource.mode === 'network' ? (
                            <NetworkDatasourceView
                                datasource={datasource}
                                calculatedNodeAttributes={calculatedNodeAttributes}
                                setCalculatedNodeAttributes={setCalculatedNodeAttributes}
                            />
                        ) : (
                            <NormalDatasourceView datasource={datasource} />
                        )}
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <BaseButton onClick={onClose} color="warning" variant="outlined">
                    Discard and Close
                </BaseButton>

                <BaseButton onClick={handleSaveChangesClick} color="primary" variant="contained">
                    Save Changes
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

const NetworkDatasourceView = ({
    datasource,
    calculatedNodeAttributes,
    setCalculatedNodeAttributes,
}: {
    datasource: ReportNetworkDataSourceType
    calculatedNodeAttributes: CalculatedNodeAttributeType[]
    setCalculatedNodeAttributes: Dispatch<SetStateAction<CalculatedNodeAttributeType[]>>
}) => {
    const [preset, setPreset] = useState<string>('default')
    const [dataMode, setDataMode] = useState<
        | {
              mode: 'node'
          }
        | {
              mode: 'graph'
          }
        | {
              mode: 'node_analytics'
              network: string
          }
        | {
              mode: 'calculatedNodeAttribute'
          }
        | {
              mode: 'ergm'
              network: string
          }
        | {
              mode: 'alaam'
              network: string
              targetAttribute: string
          }
    >({
        mode: 'node',
    })

    const [rows, setRows] = useState<any[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])

    const allFields = useMemo<ExpressionEditorSuggestionType[]>(() => {
        return getExpressionVariableOptions(datasource, preset)
    }, [datasource, preset])

    const presetOptions = useMemo(() => {
        return [
            ...Object.keys(datasource.presets).map((key) => ({
                value: key,
                label: key,
            })),
        ]
    }, [datasource.presets])

    const dataModeOptions = useMemo(() => {
        const result: FlexibleSelectOptionType[] = []

        result.push({
            label: 'Node Attributes',
            value: {
                mode: 'node',
            },
        })

        result.push({
            label: 'Graph Attributes',
            value: {
                mode: 'graph',
            },
        })

        result.push({
            label: 'Calculated Node Attributes',
            value: {
                mode: 'calculatedNodeAttribute',
            },
        })

        if (preset) {
            const presetData = datasource.presets[preset]
            if (presetData) {
                // Node Analytics
                const nodeAnalytics: FlexibleSelectGroupedOptionType = {
                    group: 'Node Analytics',
                    items: [],
                }
                for (let networkName in presetData.analytics) {
                    nodeAnalytics.items.push({
                        label: networkName,
                        value: {
                            mode: 'node_analytics',
                            network: networkName,
                        },
                    })
                }
                result.push(nodeAnalytics)

                // ERGM and ALAAM
                const ergm: FlexibleSelectGroupedOptionType = {
                    group: 'ERGM',
                    items: [],
                }
                const alaam: FlexibleSelectGroupedOptionType = {
                    group: 'ALAAM',
                    items: [],
                }
                for (let networkName in presetData.analytics) {
                    if (presetData.analytics[networkName].ergm) {
                        ergm.items.push({
                            label: networkName,
                            value: {
                                mode: 'ergm',
                                network: networkName,
                            },
                        })
                    }
                    if (presetData.analytics[networkName].alaam) {
                        for (let targetAttribute in presetData.analytics[networkName].alaam) {
                            alaam.items.push({
                                label: `${networkName} - ${targetAttribute}`,
                                value: {
                                    mode: 'alaam',
                                    network: networkName,
                                    targetAttribute: targetAttribute,
                                },
                            })
                        }
                    }
                }
                if (ergm.items.length > 0) {
                    result.push(ergm)
                }
                if (alaam.items.length > 0) {
                    result.push(alaam)
                }
            }
        }
        return result
    }, [preset, datasource])

    useEffect(() => {
        if (preset) {
            const presetData = datasource.presets[preset]
            const nodesData = datasource.networkContext.nodes
            if (presetData) {
                const _rows: any[] = []
                const _columns: GridColDef[] = []
                switch (dataMode.mode) {
                    case 'node':
                        const nodes = presetData.nodeRenders.filter((node) => node.hide !== true)
                        for (const node of nodes) {
                            const nodeData = structuredClone(nodesData[node.id])
                            _rows.push(nodeData)
                        }
                        _columns.push(
                            {
                                field: 'id',
                                headerName: 'Node ID',
                            },
                            ...Object.keys(presetData.nodeDataSchema.fields)
                                .filter((x) => x !== 'id')
                                .map((key) => ({
                                    field: key,
                                    headerName: key,
                                }))
                        )

                        break
                    case 'graph':
                        const analytics = presetData.analytics
                        const fieldKeys = new Set<string>()
                        if (analytics) {
                            for (const networkKey in analytics) {
                                const data = analytics[networkKey]
                                _rows.push({
                                    id: networkKey,
                                    ...data.graph,
                                })
                                Object.keys(data.graph).forEach((key) => {
                                    fieldKeys.add(key)
                                })
                            }
                            _columns.push(
                                {
                                    field: 'id',
                                    headerName: 'Network',
                                },
                                ...Array.from(fieldKeys).map((key) => ({
                                    field: key,
                                    headerName: key,
                                }))
                            )
                        }
                        break
                    case 'node_analytics':
                        const network = dataMode.network
                        const nodeAnalytics = presetData.analytics?.[network]
                        if (nodeAnalytics) {
                            for (const nodeId in nodeAnalytics.nodes) {
                                _rows.push({
                                    id: nodeId,
                                    ...nodeAnalytics.nodes[nodeId],
                                })
                            }
                            _columns.push(
                                {
                                    field: 'id',
                                    headerName: 'Node ID',
                                },
                                ...Object.keys(nodeAnalytics.schema).map((key) => ({
                                    field: key,
                                    headerName: key,
                                }))
                            )
                        }

                        break
                    case 'calculatedNodeAttribute':
                        const data = computeCalculatedAttributes({
                            calculatedAttributes: calculatedNodeAttributes,
                            preset,
                            datasource,
                        })
                        _rows.push(...data)
                        _columns.push(
                            {
                                field: 'id',
                                headerName: 'Node ID',
                            },
                            ...calculatedNodeAttributes.map((attr) => ({
                                field: attr.name,
                                headerName: attr.name,
                            }))
                        )
                        break
                    case 'ergm':
                        const ergm = presetData.analytics?.[dataMode.network]?.ergm
                        if (ergm) {
                            ergm.params_stats.forEach((param) => {
                                _rows.push({
                                    id: param.name,
                                    ...param,
                                })
                            })
                            _columns.push(
                                {
                                    field: 'id',
                                    headerName: 'Name',
                                },

                                {
                                    field: 'coef',
                                    headerName: 'Coefficient',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'z',
                                    headerName: 'Z',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'p_values',
                                    headerName: 'P Values',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'std_err',
                                    headerName: 'Std Err',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'conf_int',
                                    headerName: 'Conf Int',
                                    valueFormatter: ({ value }) => {
                                        if (Array.isArray(value)) {
                                            return value.map((v) => v?.toFixed(4) ?? '-').join(', ')
                                        }
                                    },
                                }
                            )
                        }
                        break
                    case 'alaam':
                        const alaam = presetData.analytics?.[dataMode.network]?.alaam?.[dataMode.targetAttribute]
                        if (alaam) {
                            alaam.params_stats.forEach((param) => {
                                _rows.push({
                                    id: param.name,
                                    ...param,
                                })
                            })
                            _columns.push(
                                {
                                    field: 'id',
                                    headerName: 'Name',
                                },

                                {
                                    field: 'coef',
                                    headerName: 'Coefficient',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'z',
                                    headerName: 'Z',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'p_values',
                                    headerName: 'P Values',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'std_err',
                                    headerName: 'Std Err',
                                    valueFormatter: ({ value }) => {
                                        return value?.toFixed(4) ?? '-'
                                    },
                                },
                                {
                                    field: 'conf_int',
                                    headerName: 'Conf Int',
                                    valueFormatter: ({ value }) => {
                                        if (Array.isArray(value)) {
                                            return value.map((v) => v?.toFixed(4) ?? '-').join(', ')
                                        }
                                    },
                                }
                            )
                        }
                        break
                }

                setRows(_rows)
                setColumns(_columns)
            } else {
                setRows([])
                setColumns([])
            }
        }
    }, [preset, dataMode, datasource, calculatedNodeAttributes])

    const handleAddCalculatedNodeAttribute = () => {
        setCalculatedNodeAttributes((prev) => [
            ...prev,
            {
                expression: '',
                name: '',
            },
        ])
    }

    const updateCalculatedNodeName = (index: number, name: string) => {
        setCalculatedNodeAttributes((prev) => {
            const newValues = cloneDeep(prev)
            newValues[index].name = name
            return newValues
        })
    }

    const updateCalculatedNodeAttributeExpression = (index: number, expression: string) => {
        let isValidExpression = {
            isValid: true,
            error: '',
        }
        try {
            const parsedExpression = math.parse(expression)

            // Function to recursively collect variables from the parsed expression

            const checkVariableExists = (varName: string) => {
                const splitVarName = varName.split('.')
                let currentItems = structuredClone(allFields)
                if (splitVarName[0] === 'info' && splitVarName.length !== 2) return false
                if (splitVarName[0] === 'analytics' && splitVarName.length !== 3) return false

                for (let i = 0; i < splitVarName.length; i++) {
                    const newItems = currentItems.find((item) => item.name === splitVarName[i])
                    if (newItems) {
                        currentItems = newItems.subItems || []
                    } else {
                        return false
                    }
                }
                return true
            }

            const variablesInExpression = collectExpressionVariableVariables(parsedExpression)

            for (const varName of variablesInExpression) {
                if (!checkVariableExists(varName)) {
                    isValidExpression = {
                        isValid: false,
                        error: `Variable "${varName}" does not exist`,
                    }
                    break
                }
            }
        } catch (e: any) {
            isValidExpression = {
                isValid: false,
                error: e.message,
            }
        }

        setCalculatedNodeAttributes((prev) => {
            const newValues = cloneDeep(prev)
            newValues[index].expression = expression
            newValues[index].error = isValidExpression.isValid ? undefined : isValidExpression.error
            return newValues
        })
    }

    const handleRemoveCalculatedNodeAttribute = (index: number) => {
        setCalculatedNodeAttributes((prev) => {
            const newValues = [...prev]
            newValues.splice(index, 1)
            return newValues
        })
    }

    return (
        <Stack sx={{ height: '100%', width: '100%' }} gap={1}>
            {/* Header */}
            <Stack gap={1} sx={{ width: '100%', flex: '0 0 auto' }}>
                {/* preset and mode selector */}
                <Stack direction="row" gap={1}>
                    <BaseSelectWithLabel
                        options={presetOptions}
                        value={preset}
                        onChange={(value) => setPreset(value)}
                        fullWidth
                        label="Preset"
                    />
                    <FlexibleSelect
                        options={dataModeOptions}
                        value={dataMode}
                        onChange={(value) => setDataMode(value)}
                        fullWidth
                        label="Data Mode"
                    />
                </Stack>
                {dataMode.mode === 'calculatedNodeAttribute' && (
                    <StyledWidgetAccordion title="Calculated Node Attributes">
                        <Stack gap={1}>
                            <Stack gap={1} justifyContent="space-between" alignItems="center" direction="row">
                                <Typography variant="body2">
                                    Calculated Node Attributes are attributes that are calculated based on the network
                                    structure and other node attributes.
                                </Typography>
                                <Tooltip title="Add Calculated Node Attribute">
                                    <IconButton onClick={handleAddCalculatedNodeAttribute}>
                                        <AddIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                            {calculatedNodeAttributes.map((attr, index) => {
                                return (
                                    <Stack direction="row" justifyContent="space-between" gap={1} key={index}>
                                        <BaseFilledTextField
                                            label="Name"
                                            sx={{
                                                width: '200px',
                                            }}
                                            defaultValue={attr.name}
                                            onBlur={(e) => {
                                                updateCalculatedNodeName(index, e.target.value)
                                            }}
                                        />

                                        <ExpressionEditor
                                            variables={allFields}
                                            id={`id-${index}`}
                                            defaultExpression={attr.expression}
                                            onChange={(value) => {
                                                updateCalculatedNodeAttributeExpression(index, value)
                                            }}
                                            error={attr.error}
                                        />

                                        <Tooltip title="Remove Calculated Node Attribute">
                                            <IconButton
                                                onClick={() => {
                                                    handleRemoveCalculatedNodeAttribute(index)
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </StyledWidgetAccordion>
                )}
            </Stack>

            <Box sx={{ width: '100%', flex: '1 0 0', overflow: 'clip' }}>
                <StyledDataGrid rows={rows} columns={columns} pagination={true} autoPageSize />
            </Box>
        </Stack>
    )
}

const NormalDatasourceView = ({
    datasource,
}: {
    datasource: ReportStaticDataSourceType | ReportSurveyDataSourceType
}) => {
    const [rows, setRows] = useState<any[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])

    useEffect(() => {
        if (datasource.mode === 'static') {
            const _rows = datasource.data[0].map((row: any, index: number) => ({
                __index: index,
                ...row,
            }))
            const _columns = Object.keys(_rows[0]).map((key) => ({
                field: key,
                headerName: key,
            }))
            setRows(_rows)
            setColumns(_columns)
        }
    }, [datasource])
    return (
        <Box sx={{ width: '100%', flex: '1 0 0', overflow: 'clip' }}>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                pagination={true}
                autoPageSize
                getRowId={(row) => row.__index}
            />
        </Box>
    )
}
