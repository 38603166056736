//* ======= Libraries
import { useContext, useEffect, lazy, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import Select from '@mui/material/Select'
import BaseButton from 'components/base/BaseButton'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
//* ======= Custom logic
//* ======= Assets and styles
import FilterListIcon from '@mui/icons-material/FilterList'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useParams } from 'react-router-dom'
import { SurveyContext } from 'contexts/SurveyContex'
import {
    GetSurveyAnalyticsService,
    SurveyAnalyticsType,
    UpdateSurveyAnalyticsLayoutService,
} from 'services/SurveyService'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Paper, Typography } from '@mui/material'
import { SurveyInstanceProgressBar } from 'features/survey-overview/SurveyOverview'
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import useDebouncedResizeObserver from 'hooks/useDebouncedResizeObserver'
import { toast } from 'react-toastify'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { set } from 'lodash'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'

const StyledLabelChip = styled(Chip)(({ theme }) => ({
    py: theme.spacing(0.25),
    px: theme.spacing(0.5),

    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.text_4,

    '& .MuiChip-deleteIcon': {
        color: theme.palette.common.fill_4,

        '&:hover': {
            color: theme.palette.common.fill_4,
        },
    },
}))

const BaseAnalyticsWidget = lazy(() => import('features/survey-analytics/BaseAnalyticsWidget'))

interface ISurveyAnalytics {}

type BreakpointType = 'lg' | 'md' | 'sm' | 'xs' | 'xxs'

const cols: Record<BreakpointType, number> = { lg: 3, md: 3, sm: 2, xs: 2, xxs: 1 }
const colSizes: Record<BreakpointType, number> = { lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }

function SurveyAnalytics({}: ISurveyAnalytics) {
    const { sid, pid } = useParams()
    const { drawerContent, survey } = useContext(SurveyContext)
    const [analytics, setAnalytics] = useState<Record<string, SurveyAnalyticsType> | null>()
    const [loading, setLoading] = useState(false)

    const [isFilterPaneVisible, setIsFilterPaneVisible] = useState(false)

    const [filteredQuestions, setFilteredQuestions] = useState<string[]>([])
    const [allQuestions, setAllQuestions] = useState<string[]>([])
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([])

    const [layouts, setLayouts] = useState<ReactGridLayout.Layouts>()

    const contentContainerRef = useRef<HTMLDivElement>(null)
    const contentContainerSize = useDebouncedResizeObserver({ wait: 100, ref: contentContainerRef })

    useEffect(() => {
        if (sid == null || pid == null) return
        setLoading(true)

        GetSurveyAnalyticsService(pid, sid)
            .then((res) => {
                if (res.success) {
                    const fetchedAnalytics = res.data.data

                    const _allQuestions = Object.keys(fetchedAnalytics)
                    setAllQuestions(_allQuestions)

                    const _filteredQuestions = res.data.layout?.filteredQuestions || []
                    setFilteredQuestions(_filteredQuestions)

                    const _selectedQuestions = _allQuestions.filter((q) => !_filteredQuestions.includes(q))
                    setSelectedQuestions(_selectedQuestions)
                    const fetchedQuestionNames = new Set(_selectedQuestions)

                    let layoutState = res.data.layout?.grid

                    if (layoutState && Object.keys(layoutState).length > 0) {
                        // check for missing or new questions and update layout
                        const removedQuestions: string[] = []
                        const newQuestions: string[] = []
                        const existingQuestionNames = new Set(
                            layoutState!['lg']?.map((layout) => layout.i) || []
                        )

                        fetchedQuestionNames.forEach((name) => {
                            if (!existingQuestionNames.has(name)) {
                                newQuestions.push(name)
                            }
                        })

                        existingQuestionNames.forEach((name) => {
                            if (!fetchedQuestionNames.has(name)) {
                                removedQuestions.push(name)
                            }
                        })

                        if (removedQuestions.length > 0 || newQuestions.length > 0) {
                            for (let bp in cols) {
                                const breakpoint = bp as BreakpointType
                                if (removedQuestions.length > 0) {
                                    layoutState![breakpoint] = layoutState![breakpoint]?.filter(
                                        (layout) => !removedQuestions.includes(layout.i)
                                    )
                                }
                                if (newQuestions.length > 0) {
                                    const layoutForNewQuestion = generateLayoutForQuestion(
                                        newQuestions[0],
                                        layoutState
                                    )
                                    layoutState![breakpoint]?.push(...layoutForNewQuestion[breakpoint])
                                }
                            }
                        }
                    } else {
                        layoutState = generateLayouts(Object.values(fetchedAnalytics))
                    }

                    setLayouts(layoutState)

                    setAnalytics(fetchedAnalytics)
                } else {
                    setAnalytics(null)
                }
            })
            .catch(() => setAnalytics(null))
            .finally(() => setLoading(false))
    }, [])

    const generateLayouts = (analytics: any[]) => {
        const layouts: ReactGridLayout.Layouts = { lg: [], md: [], sm: [], xs: [], xxs: [] }

        for (let bp in cols) {
            let breakpoint = bp as keyof typeof cols
            let colSize = colSizes[breakpoint]
            let totalColumns = cols[breakpoint]

            layouts[breakpoint] = analytics.map((item, idx) => {
                // Calculate the number of items per row
                let itemsPerRow = Math.floor(totalColumns / colSize)
                let rowIndex = Math.floor(idx / itemsPerRow)
                let colIndex = idx % itemsPerRow

                return {
                    i: item.question.name,
                    x: colIndex * colSize,
                    y: rowIndex,
                    w: colSize,
                    h: 1,
                }
            })
        }

        return layouts
    }

    const generateLayoutForQuestion = (name: string, layouts: ReactGridLayout.Layouts) => {
        const layoutForNewQuestion: ReactGridLayout.Layouts = { lg: [], md: [], sm: [], xs: [], xxs: [] }

        for (let bp in cols) {
            const breakpoint = bp as BreakpointType
            const colSize = colSizes[breakpoint]

            // get the largest y-coordinate of the existing items to place the new item after them
            const maxY = Math.max(0, ...(layouts![breakpoint].map((item) => item.y) || [0]))

            layoutForNewQuestion[breakpoint].push({
                i: name,
                x: 0,
                y: maxY + 1, // place it after the last item
                w: colSize,
                h: 1,
            })
        }

        return layoutForNewQuestion
    }

    const resetLayout = () => {
        const newLayout = generateLayouts(Object.values(analytics!))
        setSelectedQuestions([...allQuestions])
        setLayouts(newLayout)
    }

    const saveLayout = async () => {
        try {
            const response = await UpdateSurveyAnalyticsLayoutService(pid!, sid!, {
                grid: layouts!,
                filteredQuestions: allQuestions.filter((q) => !selectedQuestions.includes(q)),
                filters: [],
            })
            if (response.success) {
                toast.success('Layout saved')
                return
            }
        } catch (e) {}
        toast.error('Failed to save Layout')
    }

    if (survey === undefined) return <DashboardSkeleton />

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body noPaddingRight>
                {loading || layouts === undefined ? (
                    <Box sx={{ height: '100%' }}>
                        <SnaCircularLoading />
                    </Box>
                ) : analytics == null ? (
                    <Box sx={{ height: '100%' }}>Error</Box>
                ) : (
                    <Grid container height="100%">
                        {/* Main content
                                ========================================= */}
                        <Grid
                            item
                            xs={isFilterPaneVisible ? 10 : 12}
                            height="100%"
                            sx={{ userSelect: 'none' }}
                        >
                            <Stack gap={1} height="100%" ref={contentContainerRef}>
                                {/* Header
                                        ========================================= */}

                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                    sx={(theme) => ({
                                        py: 1,
                                        px: 3,

                                        // @Theme conditional
                                        backgroundColor:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.common.bg_1
                                                : theme.palette.common.bg_3,
                                    })}
                                >
                                    {/* Left-side
                                            ========================================= */}
                                    <Stack direction="row" alignItems="center" gap={1.5}>
                                        {/* Reset filter button
                                                ========================================= */}
                                        <BaseButton
                                            label="Reset Filter"
                                            variant="contained"
                                            onClick={resetLayout}
                                        />

                                        {/* Question select
                                                ========================================= */}
                                        <BaseSelectWithLabel
                                            id="question-select"
                                            hiddenLabel
                                            size="small"
                                            selectProps={{
                                                multiple: true,
                                            }}
                                            value={selectedQuestions}
                                            onChange={(value) => setSelectedQuestions(value)}
                                            options={allQuestions}
                                            sx={{
                                                width: '220px',

                                                '& .MuiSelect-icon': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                        />
                                    </Stack>

                                    {/* Right-side
                                            ========================================= */}
                                    <Stack direction="row" alignItems="center" gap={1.5}>
                                        {/* Save button
                                                ========================================= */}
                                        <BaseButton label="Save" variant="contained" onClick={saveLayout} />

                                        {/* Show filter pane button
                                                ========================================= */}
                                        {/* <BaseButton
                                            label="Filter"
                                            onClick={(e) => setIsFilterPaneVisible((prevState) => !prevState)}
                                            endIcon={
                                                isFilterPaneVisible ? (
                                                    <ChevronRightIcon />
                                                ) : (
                                                    <FilterListIcon />
                                                )
                                            }
                                            sx={{
                                                color: isFilterPaneVisible ? 'primary.main' : 'common.fill_1',
                                            }}
                                        /> */}
                                    </Stack>
                                </Stack>

                                <Stack key="progress" component={Paper} p={2} mx={1}>
                                    <Typography>Survey Progress</Typography>
                                    <SurveyInstanceProgressBar data={survey.surveyInstaceStatus} />
                                </Stack>
                                {/* Grid
                                        ========================================= */}
                                <ResponsiveGridLayout
                                    className="layout"
                                    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                    layouts={layouts}
                                    cols={cols}
                                    rowHeight={300}
                                    width={contentContainerSize?.width || 1200}
                                    compactType="vertical"
                                    draggableHandle=".drag-handle"
                                    onLayoutChange={(layout, layouts) => {
                                        setLayouts(layouts)
                                    }}
                                >
                                    {selectedQuestions.map((name, idx) => (
                                        <Paper key={name}>
                                            <BaseAnalyticsWidget
                                                data={analytics[name]}
                                                index={idx}
                                                onClose={() => {
                                                    setSelectedQuestions((pv) => pv.filter((x) => x !== name))
                                                }}
                                            />
                                        </Paper>
                                    ))}
                                </ResponsiveGridLayout>
                            </Stack>
                        </Grid>

                        {/* (Conditional) Sidebar
                                ========================================= */}
                        {isFilterPaneVisible && (
                            <Grid item xs={2} height="100%" paddingLeft={1}>
                                <Stack
                                    sx={(theme) => ({
                                        height: '100%',
                                        py: 1,
                                        paddingLeft: 2,
                                        paddingRight: 1,
                                        overflowY: 'auto',

                                        // @Theme conditional
                                        backgroundColor:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.common.bg_1
                                                : theme.palette.common.bg_3,
                                    })}
                                    className="u-scrollbar"
                                >
                                    {/* <FilterContent fields={filterFields} /> */}

                                    {/* Apply button
                                            ========================================= */}
                                    <BaseButton
                                        label="Apply"
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            alignSelf: 'center',

                                            width: '70%',
                                            marginTop: 3,
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                )}
            </PageCard.Body>
        </PageCard>
    )
}

export default SurveyAnalytics
