import React, { ChangeEvent } from 'react'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'

interface IValueSliderPicker {
    disabled?: boolean
    value: number
    onChange: (value: number) => void
    min?: number
    max?: number
    step?: number
}

function ValueSliderPicker({
    disabled = false,
    min = 0,
    max = 100,
    step = 1,
    value = 0,
    onChange,
}: IValueSliderPicker) {
    const [internalValue, setInternalValue] = React.useState(value)

    const onSliderChange = (newValue: number | number[]) => {
        onChange(Number(newValue))
    }

    const onInputChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(Number(evt.target.value))
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={3}
            sx={{
                minWidth: 0,
                // paddingLeft: 1
            }}
        >
            <Slider
                disabled={disabled}
                defaultValue={internalValue}
                onChangeCommitted={(e, v) => onSliderChange(v)}
                min={min}
                max={max}
                step={step}
                sx={{
                    flexGrow: 1,
                }}
            />

            <BaseFilledTextField
                disabled={disabled}
                type="number"
                hiddenLabel
                size="small"
                value={value}
                onChange={onInputChange}
                inputProps={{
                    min: min,
                    max: max,
                    step: step,
                }}
                sx={{
                    flexShrink: 0,

                    minWidth: 0,
                    width: 100,
                }}
            />
        </Stack>
    )
}

export default ValueSliderPicker
