import React, { useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel';
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext';
import BaseFilledTextField from 'components/base/BaseFilledTextField';
import { Stack } from '@mui/material';
import { ChiSquareConfigType } from 'features/network-viz/types/NetworkViz.types';

interface ChiSquareConfigProps {
    config: ChiSquareConfigType; // Replace 'any' with the specific type for the Chi-square config
    onUpdate: (updatedConfig: Partial<ChiSquareConfigType>) => void; // Replace 'any' with the specific type for the Chi-square config
    options: { label: string; value: any; section?: boolean }[]
    nodeDataSchema: NetworkVizContextType['nodeDataSchema']

}


export const DefaultChiSquareConfig: ChiSquareConfigType = {
    group1: {
        field: '',
        bins: null,
    },
    group2: {
        field: '',
        bins: null,
    },
}

const ChiSquareConfig: React.FC<ChiSquareConfigProps> = ({ config, onUpdate, nodeDataSchema, options }) => {
    const [group1Options, setGroup1Options] = useState(options);
    const [group2Options, setGroup2Options] = useState(options);

    const isNumeric = (field: string) => field.startsWith('analytic_') ||
        nodeDataSchema.fields[field.slice('attribute_'.length)]?.type === 'number'

    return (
        <>
            <Grid2 xs={12} md={6} p={1}>
                <Stack spacing={1} direction='row'>
                    <BaseSelectWithLabel
                        fullWidth
                        options={group1Options}
                        label="Group 1" value={config.group1.field}
                        onChange={(field) => {
                            const bins = isNumeric(field) ? 4 : null;
                            onUpdate({ group1: { field, bins } });
                            setGroup2Options(options.filter(option => option.value !== field));
                        }}
                    />
                    {isNumeric(config.group1.field) && (
                        <BaseFilledTextField
                            label='Bins number'
                            value={config.group1.bins}
                            onChange={e => {
                                const value = parseInt(e.target.value, 10);
                                onUpdate({
                                    group1: {
                                        ...config.group1,
                                        bins: isNaN(value) ? null : value,
                                    }
                                })
                            }}
                            type="number"
                        />
                    )}

                </Stack>

            </Grid2>
            <Grid2 xs={12} md={6} p={1}>
                <Stack spacing={1} direction='row'>
                    <BaseSelectWithLabel
                        fullWidth
                        options={group2Options}
                        label="Group 2" value={config.group2.field}
                        onChange={(field) => {
                            const bins = isNumeric(field) ? 4 : null;
                            onUpdate({ group2: { field, bins } });
                            setGroup1Options(options.filter(option => option.value !== field));
                        }}
                    />
                    {isNumeric(config.group2.field) && (
                        <BaseFilledTextField
                            label='Bins number'
                            value={config.group2.bins}
                            onChange={e => {
                                const value = parseInt(e.target.value, 10);
                                onUpdate({
                                    group2: {
                                        ...config.group2,
                                        bins: isNaN(value) ? null : value,
                                    }
                                })
                            }}
                            type="number"
                        />
                    )}

                </Stack>
            </Grid2>

        </>
    );
};

export default ChiSquareConfig;