//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { Stack, Box, Typography, IconButton, Divider, Tooltip, Collapse } from '@mui/material'
//* ======= Components and features
import Chart from 'features/chart/Chart'
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ColorPicker from 'components/widgets/ColorPicker'
import StyledDialog from 'components/dialog/StyledDialog'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { SelectedDataSourceType } from 'features/report-designer/types/reportDesigner.types'
import { generateChartOptions, getChartTypeInitialValues } from 'features/chart/Chart.helper'
import { AGGREGATION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { ReportBarChartWidgetSettingsStateType } from 'features/report-designer/widgets/chart-widget/settings/BarChartWidgetSettings'
import {
    getChartAttributes,
    getDataSourceData,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { BarChartDataDefinitionType, BarChartOptionsType } from 'features/chart/Chart.asset'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import TuneIcon from '@mui/icons-material/Tune'

type Props = {
    isOpen: boolean
    data: ReportBarChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onConfirm: (data: Pick<ReportBarChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>) => void
    onClose: () => void
}

function BarChartDataConfigDialog({ isOpen = false, data, dataSources, onConfirm, onClose = () => undefined }: Props) {
    const [chartData, setChartData] = useState<ReportBarChartWidgetSettingsStateType>(data)

    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])
    const [chartOptions, setChartOptions] = useState<BarChartOptionsType | null>(chartData.options)

    // Prevent user from applying any changes if the selected data configurations are not valid
    const isChartConfigValid = useMemo(() => {
        if (
            chartData.selectedDataSource === null ||
            chartData.selectedDataSource.id === -1 ||
            chartData.dataDefinition.xAxisField.field === '' ||
            chartData.dataDefinition.series.length === 0
        ) {
            return false
        }

        for (let _series of chartData.dataDefinition.series) {
            if (_series.aggregationMethod !== 'count' && _series.attribute.field === '') return false
        }

        return true
    }, [chartData])

    // After selected data source changes, update and show its fields.
    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, chartData.selectedDataSource))
    }, [chartData.selectedDataSource])

    // Update dialog's chart options after chartData state changes.
    useEffect(() => {
        if (isChartConfigValid) {
            setChartOptions((prevState) => {
                const newOptions = generateChartOptions({
                    type: 'bar',
                    mode: 'data',
                    prevOptions: prevState,
                    filteredData: getDataSourceData(
                        dataSources,
                        chartData.selectedDataSource,
                        getChartAttributes(chartData.type, chartData.dataDefinition)
                    ),
                    dataDefinition: chartData.dataDefinition,
                    config: chartData.config,
                })

                return { ...newOptions }
            })
        } else {
            setChartOptions(null)
        }
    }, [chartData, isChartConfigValid])

    const updateSelectedDataSource = (selectedDataSource: SelectedDataSourceType) => {
        const initialValues = getChartTypeInitialValues(chartData.type)

        if (initialValues !== null) {
            setChartData((prevState) => {
                // If the selected data source was changed (based on some of its properties that are considered important),
                // we need to reset some chart state properties to their initial values:
                let hasDataSourceChanged = false
                if (
                    prevState.selectedDataSource === null ||
                    prevState.selectedDataSource.id !== selectedDataSource.id ||
                    prevState.selectedDataSource.preset !== selectedDataSource.preset ||
                    prevState.selectedDataSource.type !== selectedDataSource.type
                ) {
                    hasDataSourceChanged = true
                }

                return {
                    ...prevState,
                    selectedDataSource: {
                        ...prevState.selectedDataSource,
                        ...selectedDataSource,
                    },
                    dataDefinition: hasDataSourceChanged
                        ? (initialValues.dataDefinition as BarChartDataDefinitionType)
                        : { ...prevState.dataDefinition },
                    options: hasDataSourceChanged ? null : { ...prevState.options },
                }
            })
        }
    }

    const onXAxisFieldChange = (xAxisField: ReportBarChartWidgetSettingsStateType['dataDefinition']['xAxisField']) => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                xAxisField,
            },
        }))
    }

    const addSeries = () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                series: [
                    ...prevState.dataDefinition.series,
                    {
                        aggregationMethod: 'avg',
                        attribute: {
                            field: '',
                            type: 'basic',
                        },
                        color: { isEnabled: false, value: undefined },
                    },
                ],
            },
        }))
    }

    const deleteSeries = (seriesListIndex: number) => () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                series: prevState.dataDefinition.series.filter((_series, _idx) => seriesListIndex !== _idx),
            },
        }))
    }

    const updateSeries =
        (seriesListIndex: number) =>
        (field: keyof ReportBarChartWidgetSettingsStateType['dataDefinition']['series'][number]) =>
        (value: any) => {
            setChartData((prevState) => {
                const updatedSeriesList = structuredClone(prevState.dataDefinition.series)

                updatedSeriesList[seriesListIndex][field] = value

                return {
                    ...prevState,
                    dataDefinition: {
                        ...prevState.dataDefinition,
                        series: [...updatedSeriesList],
                    },
                }
            })
        }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',

                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),

                    padding: theme.spacing(2, 3),

                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Bar Chart Data Configuration
                </Typography>

                <IconButton
                    onClick={(evt) => onClose()}
                    size="small"
                    sx={{
                        gridColumn: 3,
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 1 auto',

                    display: 'grid',
                    gridTemplateColumns: '10fr 9fr',
                    placeContent: 'stretch',
                    columnGap: 2,

                    height: '70vh',
                    padding: theme.spacing(3),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {/* Content wrapper
                    ========================================= */}
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        paddingRight: theme.spacing(2),
                        overflowY: 'auto',

                        borderRight: `1px solid ${theme.palette.common.border_3}`,
                    })}
                    className="u-scrollbar"
                >
                    {/* Data source selector
                        ========================================= */}
                    <ReportDataSourceSelector
                        dataSources={dataSources}
                        selectedDataSource={chartData.selectedDataSource}
                        onChange={updateSelectedDataSource}
                    />

                    <Divider />

                    {/* Field select
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            X-Axis:
                        </Typography>

                        <FlexibleSelect
                            label="Field"
                            options={dataSourceFields}
                            value={dataSourceFields.length !== 0 ? chartData.dataDefinition.xAxisField : undefined}
                            onChange={onXAxisFieldChange}
                            disabled={dataSourceFields.length === 0}
                            hiddenLabel
                            required
                            fullWidth
                            sx={{
                                maxWidth: 480,
                            }}
                        />
                    </Stack>

                    <Divider />

                    {/* Series header
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Series:
                        </Typography>

                        {/* Add series button
                            ========================================= */}
                        <BaseButton
                            label="Add Series"
                            onClick={(evt) => addSeries()}
                            disabled={dataSourceFields.length === 0}
                            startIcon={<AddIcon />}
                        />
                    </Stack>

                    {/* Series list
                        ========================================= */}
                    <Stack gap={2}>
                        {/* Series items
                            ========================================= */}
                        {chartData.dataDefinition.series.map((_series, idx, array) => (
                            <React.Fragment key={idx}>
                                <BarChartSeriesItem
                                    series={_series}
                                    fieldOptions={dataSourceFields}
                                    onUpdate={updateSeries(idx)}
                                    onDelete={deleteSeries(idx)}
                                />

                                {idx !== array.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Stack>

                {/* Chart wrapper
                    ========================================= */}
                {chartOptions === null ? (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: '100%',
                        }}
                    >
                        Invalid/Empty data.
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Chart type={chartData.type} options={chartOptions} clearOnChange={true} />
                    </Box>
                )}
            </Box>

            {/* Footer
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    padding: theme.spacing(2, 3),
                })}
            >
                <BaseButton
                    label="Discard"
                    onClick={(evt) => onClose()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Apply"
                    onClick={(evt) => onConfirm(chartData)}
                    disabled={isChartConfigValid === false}
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </StyledDialog>
    )
}

type BarChartSeriesItemProps = {
    series: ReportBarChartWidgetSettingsStateType['dataDefinition']['series'][number]
    fieldOptions: FlexibleSelectOptionType[]
    onUpdate: (
        field: keyof ReportBarChartWidgetSettingsStateType['dataDefinition']['series'][number]
    ) => (value: any) => void
    onDelete: () => void
}

const BarChartSeriesItem = ({ series, fieldOptions, onUpdate, onDelete }: BarChartSeriesItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const [title, setTitle] = useState(series.title || '')

    return (
        <Stack>
            {/* Primary/Required fields
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                {/* Aggregation method field
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Aggregation Method"
                    options={AGGREGATION_OPTIONS}
                    value={series.aggregationMethod}
                    onChange={onUpdate('aggregationMethod')}
                    required
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Field field
                    ========================================= */}
                <FlexibleSelect
                    label="Field"
                    options={fieldOptions}
                    value={fieldOptions.length !== 0 ? series.attribute : ''}
                    onChange={onUpdate('attribute')}
                    disabled={series.aggregationMethod === 'count'}
                    required
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Extra options expand button
                    ========================================= */}
                <Tooltip title="Extra Options">
                    <IconButton
                        onClick={(evt) => setIsExpanded((prevState) => !prevState)}
                        color={isExpanded ? 'primary' : undefined}
                        sx={{
                            flexShrink: 0,
                        }}
                    >
                        <TuneIcon />
                    </IconButton>
                </Tooltip>

                <Divider orientation="vertical" />

                {/* Delete button
                    ========================================= */}
                <Tooltip title="Delete">
                    <IconButton
                        onClick={(evt) => onDelete()}
                        sx={(theme) => ({
                            flexShrink: 0,

                            color: theme.palette.common.ung_pink,
                        })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            {/* Extra options collapsible area
                ========================================= */}
            <Collapse in={isExpanded}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={4} marginTop={2}>
                    {/* Title
                        ========================================= */}
                    <BaseFilledTextField
                        label="Series Title"
                        helperText="Optional. If not set, an auto generated title will be used based on the selected aggregation method and field."
                        value={title}
                        onChange={(evt) => setTitle(evt.target.value)}
                        onBlur={(evt) => onUpdate('title')(evt.target.value.trim())}
                        sx={{
                            flex: '1 0 0',
                        }}
                    />

                    {/* Color
                        ========================================= */}
                    <Box
                        sx={{
                            flex: '1 0 0',
                        }}
                    >
                        <StyledWidgetAccordion
                            title="Color"
                            hasToggle
                            isToggledOff={series.color.isEnabled === false}
                            onToggle={(isEnabled) =>
                                onUpdate('color')({
                                    ...series.color,
                                    isEnabled: isEnabled,
                                })
                            }
                        >
                            <ColorPicker
                                value={series.color.value}
                                onChange={(newColor) =>
                                    onUpdate('color')({
                                        ...series.color,
                                        value: newColor,
                                    })
                                }
                                disabled={series.color.isEnabled === false}
                                isPopover={true}
                            />
                        </StyledWidgetAccordion>
                    </Box>
                </Stack>
            </Collapse>
        </Stack>
    )
}

export default BarChartDataConfigDialog
