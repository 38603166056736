import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemButton,
    IconButton,
    Tooltip,
    ListItemText,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    FormControlLabel,
} from '@mui/material'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import {
    ControllableWidgetType,
    determineWidgetInteractionCompatibility,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import {
    ChartWidgetType,
    NetworkWidgetType,
    ReportConnectedWidgetConfigFilterItemType,
    ReportConnectedWidgetConfigType,
    ReportControllingWidgetType,
    ReportDataSourceType,
    ReportWidgetType,
    SelectedDataSourceType,
    TableWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ConfigIcon from '@mui/icons-material/Settings'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import AddIcon from '@mui/icons-material/Add'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import useCachedCallback from 'helpers/useCachedCallback'

type ConnectedWidgetsSettingsProps = {
    selectedWidgetId: ReportWidgetType['id'] | undefined
    slideWidgets: ReportWidgetType[]
    connectedWidgets: ReportControllingWidgetType['connectedWidgets']
    onConnectedWidgetsUpdate: (connectedWidgets: ReportControllingWidgetType['connectedWidgets']) => void
}

type ConnectedWidgetsDialogStateType = {
    controlledWidgetType: ControllableWidgetType
    connectedWidgetConfig: ReportConnectedWidgetConfigType
    sourceWidgetDatasourceFields: FlexibleSelectOptionType[]
    targetWidgetDatasourceFields: FlexibleSelectOptionType[]
    relationShipOptions: string[]
}

const ConnectedWidgetsSettings = ({
    selectedWidgetId,
    slideWidgets,
    connectedWidgets,
    onConnectedWidgetsUpdate,
}: ConnectedWidgetsSettingsProps) => {
    const { dataSources } = useReportStore((state) => ({
        dataSources: state.dataSources,
    }))

    const [settingDialogState, setSettingDialogState] = useState<ConnectedWidgetsDialogStateType | false>(false)

    const controllableWidgets = useMemo(() => {
        const result: ControllableWidgetType[] = []

        const selectedWidget = slideWidgets.find((_widget) => _widget.id === selectedWidgetId)

        if (!selectedWidget) return []

        slideWidgets.forEach((_widget) => {
            const compatibilityMode = determineWidgetInteractionCompatibility(selectedWidget, _widget)
            if (compatibilityMode !== false) {
                result.push(compatibilityMode)
            }
        })
        return result.sort((a, b) => a.title.localeCompare(b.title))
    }, [slideWidgets, selectedWidgetId])

    const toggleControllableWidget = (widgetId: string | number) => {
        const newConnectedWidgets = structuredClone(connectedWidgets)

        const widgetIndex = newConnectedWidgets.findIndex((x) => x.id === widgetId)

        if (widgetIndex === -1) {
            newConnectedWidgets.push({
                id: widgetId!,
            })
        } else {
            newConnectedWidgets.splice(widgetIndex, 1)
        }

        onConnectedWidgetsUpdate(newConnectedWidgets)
    }

    const getWidgetFileds = useCachedCallback(
        (widgetId?: string | number) => {
            if (widgetId === undefined) return []
            const selectedWidget = slideWidgets.find((_widget) => _widget.id === widgetId)
            if (selectedWidget === undefined) return []
            switch (selectedWidget.content.kind) {
                case 'chart':
                    const chartDetails = selectedWidget.content.details as ChartWidgetType
                    return getDataSourceFields(dataSources, chartDetails.selectedDataSource)
                case 'table':
                    const tableDetails = selectedWidget.content.details as TableWidgetType
                    return getDataSourceFields(dataSources, tableDetails.selectedDataSource)
                case 'network':
                    const networkDetails = selectedWidget.content.details as NetworkWidgetType
                    return getDataSourceFields(dataSources, {
                        id: networkDetails.networkDatasourceId,
                        type: 'nodes',
                        datasourceType: 'network',
                        filters: [],
                        preset: networkDetails.selectedPreset,
                    })
                default:
                    return []
            }
        },
        [slideWidgets, dataSources]
    )

    const getRelationShipOptions = useCachedCallback(
        (widgetId?: string | number) => {
            if (widgetId === undefined) return []
            const selectedWidget = slideWidgets.find((_widget) => _widget.id === widgetId)
            if (selectedWidget === undefined) return []
            let selectedDatasource: SelectedDataSourceType | null = null
            switch (selectedWidget.content.kind) {
                case 'chart':
                    const chartDetails = selectedWidget.content.details as ChartWidgetType
                    selectedDatasource = chartDetails.selectedDataSource
                    break
                case 'table':
                    const tableDetails = selectedWidget.content.details as TableWidgetType
                    selectedDatasource = tableDetails.selectedDataSource
                    break
                case 'network':
                    const networkDetails = selectedWidget.content.details as NetworkWidgetType
                    selectedDatasource = {
                        id: networkDetails.networkDatasourceId,
                        type: 'nodes',
                        datasourceType: 'network',
                        filters: [],
                        preset: networkDetails.selectedPreset,
                    }
                    break
                default:
                    return []
            }
            if (selectedDatasource === null) return []
            const dataSource = dataSources.find((x) => x.id === selectedDatasource?.id)
            if (dataSource?.mode === 'network') {
                return [...Object.keys(dataSource.networkContext.analyticsSettings.networks)]
            }
            return []
        },
        [slideWidgets, dataSources]
    )

    const sourceWidgetDatasourceFields = useMemo(() => {
        return getWidgetFileds(selectedWidgetId)
    }, [selectedWidgetId, getWidgetFileds])

    return (
        <StyledWidgetAccordion title="Controllable Widgets" defaultExpanded={true}>
            {controllableWidgets.length === 0 ? (
                /*  Empty list message
                                        ========================================= */
                <Typography fontSize={14} noWrap textAlign="center" sx={{ flexShrink: 0 }}>
                    (No Widgets Available.)
                </Typography>
            ) : (
                /*  Controllable widgets list
                                        ========================================= */
                <List disablePadding>
                    {controllableWidgets.map((_widget) => {
                        const labelId = `controllableWidgets-${_widget.id}`
                        const connectedWidget = connectedWidgets.find((x) => x.id === _widget.id)
                        const isSelected = connectedWidget !== undefined
                        const mode =
                            _widget.configurable && connectedWidget?.config
                                ? connectedWidget.config.mode
                                : _widget.defaultBehavior.mode
                        return (
                            <ListItem
                                key={_widget.id}
                                disablePadding
                                dense
                                secondaryAction={
                                    _widget.configurable !== false && (
                                        <Tooltip title="Settings" enterDelay={500} enterNextDelay={500}>
                                            <IconButton
                                                edge="end"
                                                aria-label="comments"
                                                disabled={!isSelected}
                                                onClick={() => {
                                                    let config = connectedWidgets.find(
                                                        (x) => x.id === _widget.id
                                                    )?.config

                                                    if (config === undefined) {
                                                        config =
                                                            _widget.defaultBehavior.mode === 'filter'
                                                                ? {
                                                                      mode: 'filter',
                                                                      filterOutSelectedNode: false,
                                                                      filters: [
                                                                          {
                                                                              type: 'compare',
                                                                              field: _widget.defaultBehavior
                                                                                  .filterAttribute,
                                                                              operator: 'eq',
                                                                          },
                                                                      ],
                                                                  }
                                                                : { mode: 'select' }
                                                    }

                                                    setSettingDialogState({
                                                        controlledWidgetType: _widget,
                                                        connectedWidgetConfig: config,
                                                        sourceWidgetDatasourceFields,
                                                        targetWidgetDatasourceFields: getWidgetFileds(_widget.id),
                                                        relationShipOptions: getRelationShipOptions(_widget.id),
                                                    })
                                                }}
                                            >
                                                <ConfigIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                            >
                                <ListItemButton
                                    role="checkbox"
                                    onClick={(evt) => toggleControllableWidget(_widget.id)}
                                    dense
                                    sx={{
                                        paddingX: 1,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                        }}
                                    >
                                        <Checkbox
                                            checked={isSelected}
                                            edge="start"
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={_widget.title} secondary={`Mode : ${mode}`} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            )}

            {/*  Connected widgets settings dialog
                            ========================================= */}
            {settingDialogState !== false && (
                <ConnectedWidgetsSettingDialog
                    state={settingDialogState}
                    onSaved={(config) => {
                        const newConnectedWidgets = structuredClone(connectedWidgets)
                        const widgetIndex = newConnectedWidgets.findIndex(
                            (x) => x.id === settingDialogState.controlledWidgetType.id
                        )
                        if (widgetIndex !== -1) {
                            newConnectedWidgets[widgetIndex].config = config
                        }
                        onConnectedWidgetsUpdate(newConnectedWidgets)
                        setSettingDialogState(false)
                    }}
                    onClose={() => {
                        setSettingDialogState(false)
                    }}
                />
            )}
        </StyledWidgetAccordion>
    )
}

type ConnectedWidgetsSettingsDialogProps = {
    state: ConnectedWidgetsDialogStateType
    onSaved: (connectedWidget: ReportConnectedWidgetConfigType) => void
    onClose: () => void
}

const ConnectedWidgetsSettingDialog = ({
    state: {
        connectedWidgetConfig,
        controlledWidgetType,
        sourceWidgetDatasourceFields,
        targetWidgetDatasourceFields,
        relationShipOptions,
    },
    onSaved,
    onClose,
}: ConnectedWidgetsSettingsDialogProps) => {
    const [internalConfig, setInternalConfig] = useState<ReportConnectedWidgetConfigType>(connectedWidgetConfig)

    useEffect(() => {
        setInternalConfig(connectedWidgetConfig)
    }, [connectedWidgetConfig])

    const isConfigValid = useMemo(() => {
        if (internalConfig.mode === 'filter') {
            if (internalConfig.filters.length === 0) return false
            return internalConfig.filters.every((filter) => {
                if (filter.type === 'compare') {
                    return filter.field !== null
                } else {
                    return filter.relationship !== null
                }
            })
        }
        return true
    }, [internalConfig])

    if (controlledWidgetType.configurable === false) return null

    const handleModeChange = (mode: ReportConnectedWidgetConfigType['mode']) => {
        if (mode === 'filter') {
            setInternalConfig({
                mode: 'filter',
                filterOutSelectedNode: false,
                filters: [{ type: 'compare', field: null, operator: 'eq' }],
            })
        } else {
            setInternalConfig({ mode: 'select' })
        }
    }

    const addFilter = () => {
        setInternalConfig((pv) => {
            const tmp = structuredClone(pv)
            if (tmp.mode === 'filter') {
                tmp.filters.push({ type: 'compare', field: null, operator: 'eq' })
            }
            return tmp
        })
    }

    const removeFilter = (index: number) => {
        setInternalConfig((pv) => {
            const tmp = structuredClone(pv)
            if (tmp.mode === 'filter') {
                tmp.filters.splice(index, 1)
            }
            return tmp
        })
    }

    const updateFilter = (index: number, updatedFilter: ReportConnectedWidgetConfigFilterItemType) => {
        setInternalConfig((pv) => {
            const tmp = structuredClone(pv)
            if (tmp.mode === 'filter') {
                tmp.filters[index] = { ...updatedFilter }
            }
            return tmp
        })
    }

    return (
        <StyledDialog open={true} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Connected Widget Config</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <BaseSelectWithLabel
                        label="Mode"
                        fullWidth
                        options={controlledWidgetType.configurable.modes}
                        value={internalConfig.mode}
                        onChange={(value) => handleModeChange(value as ReportConnectedWidgetConfigType['mode'])}
                    />

                    {internalConfig.mode === 'filter' && (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={internalConfig.filterOutSelectedNode}
                                        onChange={(e, checked) => {
                                            setInternalConfig((pv) => {
                                                const tmp = structuredClone(pv)
                                                if (tmp.mode === 'filter') {
                                                    tmp.filterOutSelectedNode = checked
                                                }
                                                return tmp
                                            })
                                        }}
                                    />
                                }
                                label="Filter out selected node"
                            />

                            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                                <Typography variant="h6">Filters</Typography>
                                <Tooltip title="Add Filter" enterDelay={500} enterNextDelay={500}>
                                    <IconButton onClick={addFilter}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                            <Stack gap={1}>
                                {internalConfig.filters.map((filter, index) => (
                                    <Stack direction="row" gap={1} alignItems="center" key={index}>
                                        {relationShipOptions.length > 0 && (
                                            <BaseSelectWithLabel
                                                label="Type"
                                                fullWidth
                                                options={[
                                                    { label: 'Compare', value: 'compare' },
                                                    { label: 'Connected', value: 'connected' },
                                                ]}
                                                value={filter.type}
                                                onChange={(value) =>
                                                    updateFilter(
                                                        index,
                                                        value === 'compare'
                                                            ? {
                                                                  type: 'compare',
                                                                  operator: 'eq',
                                                                  field: null,
                                                              }
                                                            : {
                                                                  type: 'connected',
                                                                  connectionMode: 'any',
                                                                  relationship: null,
                                                              }
                                                    )
                                                }
                                            />
                                        )}

                                        {filter.type === 'compare' ? (
                                            <>
                                                <FlexibleSelect
                                                    fullWidth
                                                    label="Field"
                                                    options={sourceWidgetDatasourceFields}
                                                    value={filter.field}
                                                    onChange={(value) =>
                                                        updateFilter(index, { ...filter, field: value })
                                                    }
                                                />
                                                <BaseSelectWithLabel
                                                    label="Operator"
                                                    fullWidth
                                                    options={[
                                                        { label: 'Equal', value: 'eq' },
                                                        { label: 'Not Equal', value: 'neq' },
                                                        { label: 'Greater Than', value: 'gt' },
                                                        { label: 'Greater Than or Equal', value: 'gte' },
                                                        { label: 'Less Than', value: 'lt' },
                                                        { label: 'Less Than or Equal', value: 'lte' },
                                                    ]}
                                                    value={filter.operator}
                                                    onChange={(value) =>
                                                        updateFilter(index, { ...filter, operator: value })
                                                    }
                                                />
                                            </>
                                        ) : filter.type === 'connected' ? (
                                            <>
                                                <BaseSelectWithLabel
                                                    fullWidth
                                                    value={filter.relationship}
                                                    label="Relationship"
                                                    options={relationShipOptions.map((x) => ({ label: x, value: x }))}
                                                    onChange={(value) =>
                                                        updateFilter(index, { ...filter, relationship: value })
                                                    }
                                                />
                                                <BaseSelectWithLabel
                                                    label="Connection Mode"
                                                    fullWidth
                                                    value={filter.connectionMode}
                                                    onChange={(value) =>
                                                        updateFilter(index, { ...filter, connectionMode: value })
                                                    }
                                                    options={[
                                                        { label: 'Any Direction', value: 'any' },
                                                        { label: 'Incoming', value: 'in' },
                                                        { label: 'Outgoing', value: 'out' },
                                                        { label: 'Reciprocated', value: 'reciprocated' },
                                                    ]}
                                                />
                                            </>
                                        ) : null}

                                        <IconButton onClick={() => removeFilter(index)}>X</IconButton>
                                    </Stack>
                                ))}
                            </Stack>
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose}>Cancel</BaseButton>
                <BaseButton disabled={!isConfigValid} onClick={() => isConfigValid && onSaved(internalConfig)}>
                    Save
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default ConnectedWidgetsSettings
