//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import PasswordField from 'components/PasswordField/PasswordField'
//* ======= Custom logic
//* ======= Assets and styles
import { ResetPasswordRequestType, UpdatePasswordService } from 'services/AuthenticationService'
import { Controller, useForm } from 'react-hook-form'
import { PASSWORD_REGEX } from 'helpers/validators'
import { toast } from 'react-toastify'

interface ISettingsPassword {}

function SettingsPassword({}: ISettingsPassword) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordRequestType>({})

    const submitForm = () => {
        handleSubmit(
            //Valid
            (data) => {
                UpdatePasswordService(data)
                    .then((res) => {
                        res.success && toast.success('Password updated!')
                    })
                    .catch((e) => {})
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    return (
        <Stack gap={2} width="100%" maxWidth={400}>
            <Typography alignSelf="flex-start" fontSize={16} fontWeight={400} color="primary">
                Update Password
            </Typography>

            {/* Current password
                ========================================= */}
            <Controller
                name="token"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <PasswordField
                        label="Current Password"
                        required
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{
                    required: 'Current Password is required',
                }}
            />

            {/* New password
                ========================================= */}
            <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <PasswordField
                        label="New Password"
                        required
                        hasComplexityBar
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{
                    required: 'New Password is required',
                    minLength: {
                        message: 'Password must be at least 8 charecters',
                        value: 8,
                    },
                    pattern: {
                        message:
                            'Password must be 8 or more characters with a mix of letters, numbers & symbols',
                        value: PASSWORD_REGEX,
                    },
                }}
            />

            {/* Save button
				========================================= */}
            <BaseButton
                label="Save"
                onClick={submitForm}
                variant="contained"
                size="large"
                sx={{
                    marginTop: 3,
                    py: 1.5,
                }}
            />
        </Stack>
    )
}

export default SettingsPassword
