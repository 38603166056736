//* ======= Libraries
import { useContext, useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles
import { useParams } from 'react-router-dom'
import { ExportDataDictionaryService, ExportSurveyResponsesService } from 'services/SurveyService'
import moment from 'moment'
import { SurveyContext } from 'contexts/SurveyContex'

interface IExportSurveyDialog {
    onClose?: () => void
}

function ExportSurveyDialog({ onClose }: IExportSurveyDialog) {
    const { pid, sid } = useParams()
    const { survey } = useContext(SurveyContext)
    const [verificationText, setVerificationText] = useState('')

    if (survey === undefined) return null

    const exportDataDictionary = () => {
        if (pid == null || sid == null) return
        ExportDataDictionaryService(pid, sid).then((res) => {
            if (res.success) {
                // create file link in browser's memory
                const href = URL.createObjectURL(res.data)

                // create "a" HTLM element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute(
                    'download',
                    `${survey.title}-data-dictionary-${moment().toLocaleString()}.xlsx`
                ) //or any other extension
                document.body.appendChild(link)
                link.click()

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
            }
        })
    }

    const exportResult = () => {
        if (pid == null || sid == null) return
        ExportSurveyResponsesService(pid, sid, { excludeIsloatedNodes: false, isAnonymous: false }).then(
            (res) => {
                if (res.success) {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(res.data)

                    // create "a" HTLM element with href to file & click
                    const link = document.createElement('a')
                    link.href = href
                    link.setAttribute('download', `${survey.title}-result-${moment().toLocaleString()}.xlsx`) //or any other extension
                    document.body.appendChild(link)
                    link.click()

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)
                }
            }
        )
    }

    return (
        <Stack gap={2} minWidth={0} py={5} px={6} width={752}>
            {/* Title
				========================================= */}

            <Typography alignSelf="flex-start" fontSize={36} fontWeight={400} color="common.text_1">
                Export Survey Data
            </Typography>
            <Typography fontSize={16} fontWeight={400} color="common.text_1">
                Are you sure you want to download sensitive survey data?
            </Typography>
            <Typography paragraph fontSize={16} fontWeight={600} color="common.text_1" marginBottom={1}>
                By pressing the "Export" button, you will be downloading sensitive data that may include
                personal information of the survey participants. This is a critical action and should be
                handled with the utmost caution.
            </Typography>
            <Typography paragraph fontSize={16} fontWeight={600} color="common.text_1" marginBottom={1}>
                <Box component="span" color="error.main">
                    WARNING:
                </Box>
                Ensure you have proper authorization and a legitimate purpose to access and handle this data.
                Be cautious about where and how you store the downloaded data. Unauthorized distribution or
                leakage of this information can have severe legal and ethical consequences.
            </Typography>
            <Typography paragraph fontSize={16} fontWeight={600} color="common.text_1" marginBottom={1}>
                We strongly advise against downloading this data unless you are 100% certain and aware of the
                responsibilities and risks associated with handling sensitive information.
            </Typography>
            <Typography paragraph fontSize={16} fontWeight={600} color="common.text_1" marginBottom={1}>
                If you understand the risks and are sure you want to proceed with this action, write{' '}
                <Box component="span" color="primary.main">
                    EXPORT
                </Box>{' '}
                in the textbox below to confirm and proceed with the data export.
            </Typography>
            {/* Input
				========================================= */}
            <BaseFilledTextField
                hiddenLabel
                fullWidth
                value={verificationText}
                onChange={(e) => setVerificationText(e.target.value)}
                sx={{
                    alignSelf: 'stretch',
                    marginBottom: 2,
                }}
            />
            {/* Controls
				========================================= */}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {/* Close button
					========================================= */}
                <BaseButton
                    sx={{ justifySelf: 'left' }}
                    label="Close"
                    onClick={(e) => onClose && onClose()}
                    variant="outlined"
                    size="large"
                />

                <Box>
                    {/* Export Data Dictionary
					        ========================================= */}

                    <BaseButton
                        sx={{ justifySelf: 'right' }}
                        label="Export Data Dictionary"
                        disabled={verificationText.toLocaleLowerCase() !== 'export'}
                        onClick={exportDataDictionary}
                        variant="outlined"
                        size="large"
                    />
                    {/* Export Data Dictionary
					        ========================================= */}
                    {survey.status !== 'draft' && (
                        <BaseButton
                            sx={{ justifySelf: 'right', marginLeft: 2 }}
                            label="Export Result"
                            disabled={verificationText.toLocaleLowerCase() !== 'export'}
                            onClick={exportResult}
                            variant="contained"
                            size="large"
                        />
                    )}
                </Box>
            </Stack>
        </Stack>
    )
}

export default ExportSurveyDialog
