//* ======= Libraries
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Stack,
    Box,
    Typography,
    IconButton,
    Tooltip,
    Divider,
    FormControl,
    Switch,
    FormControlLabel,
    Select,
    MenuItem,
    Autocomplete,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
//* ======= Components and features
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import {
    InsightMessageType,
    InsightWidgetType,
    ReportDataSourceAttributeType,
    ReportDataSourceType,
    ReportNetworkDataSourceType,
    SelectedDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown'

import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { AGGREGATION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import FlexibleSelect, {
    FlexibleSelectGroupedOptionType,
    FlexibleSelectOptionType,
    FlexibleSelectUngroupedOptionType,
} from 'components/group-field-selector/FlexibleSelect'
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import {
    formatValueAsLabel,
    getExpressionVariableOptions,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { NODE_ANALYTICS_METRICS } from 'features/network-viz/constants/NetworkViz.const'
import { GetInterventionsService, InterventionType } from 'services/InterventionService'
import GenericDragDropList from 'features/GenericDragDropList/GenericDragDropList'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ColorPicker from 'components/widgets/ColorPicker'
import DynamicIconSelector from 'features/dynamic-icon-slector/DynamicIconSelector'
import ExpressionEditor, { ExpressionEditorSuggestionType } from 'features/ExpressionEditor/ExpressionEditor'
import { re } from 'mathjs'
import usePreviousValue from 'hooks/usePreviousValue'

type Props = {
    isOpen: boolean
    data: InsightWidgetType
    onConfirm: (data: InsightWidgetType) => void
    onClose: () => void
}

const OPERATOR_LABEL_DICTIONARY = {
    lt: '<',
    lte: '<=',
    gt: '>',
    gte: '>=',
    eq: '=',
    neq: '<>',
    between: 'between',
}

function InsightDataConfigDialog({ isOpen = false, data, onConfirm, onClose = () => undefined }: Props) {
    const { dataSources } = useReportStore((store) => ({
        dataSources: store.dataSources,
    }))

    const [interventions, setInterventions] = useState<InterventionType[]>([])
    const [selectedDataSource, setSelectedDataSource] = useState<ReportDataSourceType | null>()
    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])
    const [groupByFields, setGroupByFields] = useState<FlexibleSelectOptionType[]>([])

    const [insightData, setInsightData] = useState<InsightWidgetType>(data)
    const [insightSummaryTextRecord, setInsightSummaryTextRecord] = useState<Record<string, string>>({})

    const [insightIdToEdit, setInsightIdToEdit] = useState<string | null>(null)

    const fieldToString = (item: ReportDataSourceAttributeType | null) => {
        if (!item) return ''
        switch (item.type) {
            case 'basic':
                return item.field
            case 'analytic':
            case 'graph':
                return item.relationship + '.' + item.field
            case 'custom':
                return item.field
            case 'ergm':
                return item.type + '.' + item.relationship + '.' + item.field + '.' + item.metric
            case 'alaam':
                return (
                    item.type + '.' + item.relationship + '.' + item.targetField + '.' + item.field + '.' + item.metric
                )
            default:
                return ''
        }
    }

    const getInsightSummaryText = (_insight: InsightWidgetType['insights'][number]) => {
        let ruleText = ''
        if (_insight && Array.isArray(_insight.rules)) {
            _insight.rules.forEach(
                (rule) =>
                    (ruleText += `(${
                        'aggregationMethod' in rule
                            ? rule.aggregationMethod + ' of ' + fieldToString(rule.varaible)
                            : fieldToString(rule.varaible)
                    } ${OPERATOR_LABEL_DICTIONARY[rule.operator]} ${rule.value}) and `)
            )
            ruleText = ruleText.substring(0, ruleText.length - ' and '.length)
        } else {
            ruleText = 'New Rule'
        }
        return ruleText
    }

    // Load interventions on mount
    // generate insight summary text
    useEffect(() => {
        const tmpInsightSummaryTextRecord: Record<string, string> = {}
        for (let _insight of insightData.insights) {
            tmpInsightSummaryTextRecord[_insight.id] = getInsightSummaryText(_insight)
        }
        setInsightSummaryTextRecord(tmpInsightSummaryTextRecord)

        GetInterventionsService().then((res) => {
            if (res.success) {
                setInterventions(res.data)
            }
        })
    }, [])

    const updateDataSource = (dataSource: SelectedDataSourceType) => {
        setInsightData((pv) => ({
            ...pv,
            selectedDataSource: dataSource,
        }))
    }

    useEffect(() => {
        if (insightData.selectedDataSource === null) {
            setSelectedDataSource(null)
            setDataSourceFields([])
            setGroupByFields([])
        } else {
            const dataSource = dataSources.find((x) => x.id === insightData.selectedDataSource?.id)
            if (dataSource) {
                setSelectedDataSource(dataSource)
                setGroupByFields(getDataSourceFieldsForGroupBy(dataSource, insightData.selectedDataSource))
                setDataSourceFields(
                    getDataSourceFieldsForInsightWidget(dataSource, insightData.selectedDataSource, insightData.mode)
                )
            }
        }
    }, [insightData.selectedDataSource, dataSources])

    // * insight manipulation

    const removeInsight = (id: string) => () => {
        setInsightData((pv) => ({
            ...pv,
            insights: pv.insights.filter((x) => x.id !== id),
        }))
    }

    const findInsightIndexById = useCallback(
        (id: string) => {
            return insightData.insights.findIndex((x) => x.id === id)
        },
        [insightData.insights]
    )

    const updateInsight = async (_insight: InsightWidgetType['insights'][number]) => {
        const insightId = insightIdToEdit

        // Prepare updated insights array
        const newInsights = structuredClone(insightData.insights) // Assuming insightData is the current state
        if (insightId === 'new') {
            newInsights.push(_insight)
        } else if (insightId) {
            const index = findInsightIndexById(insightId)
            if (index !== -1) newInsights[index] = _insight
        }

        // Update state with new insights and cached data
        setInsightData((pv) => ({
            ...pv,
            insights: newInsights,
        }))

        setInsightIdToEdit(null)
    }

    const messages = useMemo(() => {
        const result: {
            id: string
            insightId: string
            varient: 'satisfied' | 'unsatisfied'
            title: string
            mode: 'neutral' | 'positive' | 'negative'
            order: number
        }[] = []

        // get all satisfied and unsatisfied messages
        for (let insight of insightData.insights) {
            result.push({
                id: `${insight.id}-satisfied`,
                insightId: insight.id,
                varient: 'satisfied',
                title: insight.message.satisfied.text,
                mode: insight.message.satisfied.mode,
                order: insight.message.satisfied.order,
            })

            if (insight.message.unsatisfied.enabled) {
                result.push({
                    id: `${insight.id}-unsatisfied`,
                    insightId: insight.id,
                    varient: 'unsatisfied',
                    title: insight.message.unsatisfied.text,
                    mode: insight.message.unsatisfied.mode,
                    order: insight.message.unsatisfied.order,
                })
            }
        }

        // sort by order
        result.sort((a, b) => a.order - b.order)

        return result
    }, [insightData.insights])

    const updateMessagesOrder = useCallback(
        (dragId: string | number, newIndex: number) => {
            const tmpMessages = structuredClone(messages)
            const dragIndex = tmpMessages.findIndex((x) => x.id === dragId)

            // Validate indices and ensure they are different to proceed with reordering
            if (dragIndex === -1 || dragIndex === newIndex) return

            // Reorder widgets
            const [dragWidget] = tmpMessages.splice(dragIndex, 1)
            tmpMessages.splice(newIndex, 0, dragWidget)

            // Update order numbers
            let lastActiveMessageIndex = tmpMessages.length

            setInsightData((pv) => ({
                ...pv,
                insights: pv.insights.map((insight) => {
                    const satisfiedIndex = tmpMessages.findIndex(
                        (x) => (x.insightId === insight.id && x.varient === 'satisfied') ?? -1
                    )
                    const unsatisfiedIndex = tmpMessages.findIndex(
                        (x) => (x.insightId === insight.id && x.varient === 'unsatisfied') ?? -1
                    )

                    return {
                        ...insight,
                        message: {
                            satisfied: {
                                ...insight.message.satisfied,
                                order: satisfiedIndex === -1 ? lastActiveMessageIndex++ : satisfiedIndex + 1,
                            },
                            unsatisfied: {
                                ...insight.message.unsatisfied,
                                order: unsatisfiedIndex === -1 ? lastActiveMessageIndex++ : unsatisfiedIndex + 1,
                            },
                        },
                    }
                }),
            }))
        },
        [messages]
    )

    const updateCategoryOrder = useCallback(
        (dragId: string | number, newIndex: number) => {
            const tmpCategories = structuredClone(data.interventionConfig.categories)
            const dragIndex = tmpCategories.findIndex((x) => x.id === dragId)

            // Validate indices and ensure they are different to proceed with reordering
            if (dragIndex === -1 || dragIndex === newIndex) return

            // Reorder categories
            const [dragWidget] = tmpCategories.splice(dragIndex, 1)
            tmpCategories.splice(newIndex, 0, dragWidget)

            setInsightData((pv) => ({
                ...pv,
                interventionConfig: {
                    ...pv.interventionConfig,
                    categories: tmpCategories,
                },
            }))
        },
        [data.interventionConfig.categories]
    )

    const addCategory = () => {
        setInsightData((pv) => ({
            ...pv,
            interventionConfig: {
                ...pv.interventionConfig,
                categories: [
                    ...pv.interventionConfig.categories,
                    {
                        id: uuidv4(),
                        title: 'New Category',
                        icon: '',
                    },
                ],
            },
        }))
    }

    const updateCategory =
        (id: string) => (updatedCategory: Partial<InsightWidgetType['interventionConfig']['categories'][number]>) => {
            setInsightData((pv) => ({
                ...pv,
                interventionConfig: {
                    ...pv.interventionConfig,
                    categories: pv.interventionConfig.categories.map((category) =>
                        category.id === id ? { ...category, ...updatedCategory } : category
                    ),
                },
            }))
        }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {insightIdToEdit === null ? (
                // * Main view
                <>
                    {/* Header
                ========================================= */}
                    <Box
                        sx={(theme) => ({
                            flex: '0 0 auto',
                            display: 'grid',
                            // 34px is the width of the close button. Change accordingly.
                            gridTemplateColumns: '34px 1fr auto',
                            placeContent: 'center',
                            columnGap: theme.spacing(1),
                            padding: theme.spacing(2, 3),
                            borderBottom: `1px solid ${theme.palette.common.border_3}`,
                        })}
                    >
                        <Typography
                            fontSize={20}
                            fontWeight={500}
                            textAlign="center"
                            sx={{
                                gridColumn: 2,
                            }}
                        >
                            Insight Widget Data Configuration
                        </Typography>

                        <IconButton
                            onClick={(evt) => onClose()}
                            size="small"
                            sx={{
                                gridColumn: 3,
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>

                    {/* Content
                ========================================= */}
                    <Box
                        sx={(theme) => ({
                            flex: '1 1 auto',

                            display: 'grid',
                            gridTemplateColumns: '10fr 9fr',
                            placeContent: 'stretch',
                            columnGap: 2,

                            height: '70vh',
                            padding: theme.spacing(3),
                            overflowY: 'auto',
                        })}
                        className="u-scrollbar"
                    >
                        {/* Content wrapper
                    ========================================= */}
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                height: '100%',
                                paddingRight: theme.spacing(2),
                                overflowY: 'auto',
                                borderRight: `1px solid ${theme.palette.common.border_3}`,
                            })}
                            className="u-scrollbar"
                        >
                            {/* Data Selector settings
                                ========================================= */}
                            <Typography>Select Datasource</Typography>
                            <ReportDataSourceSelector
                                dataSources={dataSources}
                                onChange={updateDataSource}
                                selectedDataSource={insightData.selectedDataSource}
                                hideFilterSelection={true}
                                shouldIncludeDataType={false}
                            />

                            {insightData.mode === 'group' && (
                                <FlexibleSelect
                                    fullWidth
                                    label="Group By"
                                    options={groupByFields}
                                    value={insightData.groupBy}
                                    hasClearButton={true}
                                    onChange={(value) => {
                                        setInsightData((pv) => ({
                                            ...pv,
                                            groupBy: value,
                                        }))
                                    }}
                                />
                            )}

                            {/* ID and label fields - (conditional, only when group by is not selected)
                                ========================================= */}

                            {insightData.mode !== 'group' && insightData.mode !== 'ergm/alaam' && (
                                <Stack direction="row" gap={1}>
                                    <FlexibleSelect
                                        fullWidth
                                        label="ID Field"
                                        options={dataSourceFields}
                                        value={insightData.idField}
                                        required={true}
                                        onChange={(value) => {
                                            setInsightData((pv) => ({
                                                ...pv,
                                                idField: value,
                                            }))
                                        }}
                                    />

                                    <FlexibleSelect
                                        fullWidth
                                        label="Label Field"
                                        options={dataSourceFields}
                                        value={insightData.labelField}
                                        required={true}
                                        onChange={(value) => {
                                            setInsightData((pv) => ({
                                                ...pv,
                                                labelField: value,
                                                insights: pv.insights.map((insight) => ({
                                                    ...insight,
                                                    message: {
                                                        ...insight.message,
                                                        satisfied: {
                                                            ...insight.message.satisfied,
                                                            columns: [
                                                                {
                                                                    ...insight.message.satisfied.columns[0],
                                                                    field: value,
                                                                },
                                                                ...insight.message.satisfied.columns.slice(1),
                                                            ],
                                                        },
                                                        unsatisfied: {
                                                            ...insight.message.unsatisfied,
                                                            columns: [
                                                                {
                                                                    ...insight.message.unsatisfied.columns[0],
                                                                    field: value,
                                                                },
                                                                ...insight.message.unsatisfied.columns.slice(1),
                                                            ],
                                                        },
                                                    },
                                                })),
                                            }))
                                        }}
                                    />
                                </Stack>
                            )}

                            {/* Header
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" gap={1}>
                                <Typography>Insights</Typography>
                                <Tooltip title="Add new insight">
                                    <IconButton
                                        onClick={() => setInsightIdToEdit('new')}
                                        disabled={
                                            selectedDataSource == null ||
                                            (insightData.mode !== 'group' &&
                                                insightData.mode !== 'ergm/alaam' &&
                                                (insightData.idField == null || insightData.labelField == null))
                                        }
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            {/* Insights wrapper
                                ========================================= */}
                            <Stack gap={1} sx={{ width: '100%', flexGrow: 1 }}>
                                {insightData.insights.map((insight, idx) => (
                                    <InsightListItem
                                        key={insight.id}
                                        insight={insight}
                                        title={insightSummaryTextRecord[insight.id]}
                                        removeRow={removeInsight}
                                        enterEditMode={(id: string) => () => setInsightIdToEdit(id)}
                                    />
                                ))}
                            </Stack>
                        </Stack>

                        {/* Show all Messages and let user update their order
                            ========================================= */}
                        <Stack
                            sx={{
                                height: '100%',
                            }}
                        >
                            {insightData.mode === 'intervention' && (
                                <>
                                    <Stack direction="row" gap={1} justifyContent="space-between">
                                        <Typography>Categories:</Typography>
                                        <Tooltip title="Add new category">
                                            <IconButton onClick={addCategory}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>

                                    <GenericDragDropList
                                        items={insightData.interventionConfig.categories}
                                        onItemMove={updateCategoryOrder}
                                        targetType="insight-categories"
                                        getKeyValue={(item, idx) => item.id + idx.toString()}
                                        renderItem={(item) => (
                                            <Stack direction="row" gap={1} key={item.id}>
                                                <BaseFilledTextField
                                                    label="Category"
                                                    size="small"
                                                    value={item.title}
                                                    onChange={(evt) =>
                                                        updateCategory(item.id)({ title: evt.target.value })
                                                    }
                                                    fullWidth
                                                />
                                                <Box sx={{ width: '100%', height: '50px' }}>
                                                    <DynamicIconSelector
                                                        defaultIcon={item.icon}
                                                        onSelectIcon={(icon) => updateCategory(item.id)({ icon })}
                                                        label="Icon"
                                                    />
                                                </Box>
                                                <Box sx={{ width: '60px', height: '50px' }}>
                                                    <ColorPicker
                                                        varient="minimal"
                                                        value={item.color}
                                                        onChange={(color) => updateCategory(item.id)({ color })}
                                                        isPopover={true}
                                                    />
                                                </Box>
                                            </Stack>
                                        )}
                                    />

                                    <Divider sx={{ p: 2, m: 2 }} />
                                </>
                            )}
                            <Typography>Messages:</Typography>

                            <GenericDragDropList
                                items={messages}
                                onItemMove={updateMessagesOrder}
                                targetType="insight-message"
                                getKeyValue={(item, idx) => item.id + idx.toString()}
                                renderItem={(item) => (
                                    <Stack direction="row" gap={1} key={item.id}>
                                        <Typography>{item.title}</Typography>
                                        {
                                            item.mode === 'neutral' ? (
                                                <ThumbsUpDownIcon />
                                            ) : item.mode === 'positive' ? (
                                                <ThumbUpIcon />
                                            ) : (
                                                <ThumbDownIcon />
                                            ) // negative
                                        }
                                    </Stack>
                                )}
                            />
                        </Stack>
                    </Box>

                    {/* Footer
                        ========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            flex: '0 0 auto',

                            padding: theme.spacing(2, 3),
                        })}
                    >
                        <BaseButton
                            label="Discard"
                            onClick={(evt) => onClose()}
                            variant="outlined"
                            color="secondary"
                            sx={(theme) => ({
                                minWidth: 180,
                                paddingY: 1,

                                color: theme.palette.common.fill_1,
                            })}
                        />

                        <BaseButton
                            label="Apply"
                            onClick={(evt) => onConfirm({ ...insightData, version: insightData.version + 1 })}
                            variant="contained"
                            color="primary"
                            sx={{
                                minWidth: 180,
                                paddingY: 1,
                            }}
                        />
                    </Stack>
                </>
            ) : (
                // * Insight Editor
                selectedDataSource && (
                    <InsightBuilder
                        mode={insightData.mode}
                        insight={
                            insightIdToEdit === 'new'
                                ? {
                                      id: uuidv4(),
                                      message: {
                                          satisfied: {
                                              mode: 'neutral',
                                              text: '',
                                              columns: [
                                                  {
                                                      field: insightData.labelField,
                                                      label: 'Title',
                                                      width: null,
                                                      sort: 'asc',
                                                  },
                                              ],
                                              order: insightData.insights.length + 1,
                                              icon: undefined,
                                              actions: [],
                                          },
                                          unsatisfied: {
                                              enabled: false,
                                              mode: 'neutral',
                                              text: '',
                                              columns: [
                                                  {
                                                      field: insightData.labelField,
                                                      label: 'Title',
                                                      width: null,
                                                      sort: 'asc',
                                                  },
                                              ],
                                              order: insightData.insights.length + 2,
                                              icon: undefined,
                                              actions: [],
                                          },
                                      },
                                      rules: [
                                          {
                                              operator: 'lte',
                                              value: '',
                                              varaible: null,
                                          },
                                      ],
                                  }
                                : insightData.insights.find((x) => x.id === insightIdToEdit)!
                        }
                        interventions={interventions}
                        targetDataSource={selectedDataSource}
                        dataSourceFields={dataSourceFields}
                        onDiscard={() => setInsightIdToEdit(null)}
                        onSave={updateInsight}
                        categories={insightData.interventionConfig.categories}
                        selectedDataSource={insightData.selectedDataSource}
                    />
                )
            )}
        </StyledDialog>
    )
}

const getDataSourceFieldsForInsightWidget = (
    targetDataSource: ReportDataSourceType,
    selectedDataSource: SelectedDataSourceType,
    mode: InsightWidgetType['mode']
): FlexibleSelectOptionType[] => {
    if (mode === 'ergm/alaam') {
        return []
    } else {
        // Network
        if (targetDataSource.mode === 'network') {
            const result: FlexibleSelectGroupedOptionType[] = []

            const attributesGroup: FlexibleSelectGroupedOptionType = {
                group: 'Node Attributes',
                items: [],
            }

            for (let _field in targetDataSource.presets[selectedDataSource.preset!]?.nodeDataSchema.fields) {
                attributesGroup.items.push({
                    label: formatValueAsLabel(_field),
                    value: {
                        type: 'basic',
                        field: _field,
                    },
                })
            }

            result.push(attributesGroup)

            if (
                targetDataSource.presets[selectedDataSource.preset!]?.analytics?.view &&
                targetDataSource.presets[selectedDataSource.preset!]?.analytics?.all
            ) {
                for (let _relation in targetDataSource.presets[selectedDataSource.preset!]?.analytics) {
                    const analyticsGroup: FlexibleSelectGroupedOptionType = {
                        group: `Node Analytics - ${_relation}`,
                        items: NODE_ANALYTICS_METRICS.map((_metric) => ({
                            label: _metric.label,
                            value: {
                                type: 'analytic',
                                relationship: _relation,
                                field: _metric.value,
                            },
                        })),
                    }

                    result.push(analyticsGroup)

                    // ToDo find out what it does
                    // if (mode === 'group') {
                    //     const graphGroup: FlexibleSelectGroupedOptionType = {
                    //         group: `Graph Analytics - ${_relation}`,
                    //         items: Object.keys(
                    //             targetDataSource.presets[selectedDataSource.preset!].analytics![_relation].graph
                    //         ).map((_analytic: any) => ({
                    //             label: _analytic,
                    //             value: {
                    //                 type: 'graph',
                    //                 relationship: _relation,
                    //                 field: _analytic,
                    //             },
                    //         })),
                    //     }
                    //     result.push(graphGroup)
                    // }
                }
            }

            return result
        }
        // Other data source modes
        else {
            const result: FlexibleSelectUngroupedOptionType[] = targetDataSource.fields.map((_field) => ({
                label: formatValueAsLabel(_field),
                value: {
                    type: 'basic',
                    field: _field,
                },
            }))

            return result
        }
    }
}

const getDataSourceFieldsForGroupBy = (
    targetDataSource: ReportDataSourceType,
    selectedDataSource: SelectedDataSourceType
): FlexibleSelectOptionType[] => {
    if (targetDataSource.mode === 'network') {
        const result: FlexibleSelectUngroupedOptionType[] = [
            {
                label: 'Aggregate All',
                value: undefined,
            },
        ]

        for (let _field in targetDataSource.presets[selectedDataSource.preset!]?.nodeDataSchema.fields) {
            result.push({
                label: formatValueAsLabel(_field),
                value: {
                    type: 'basic',
                    field: _field,
                },
            })
        }

        return result
    } else {
        const result: FlexibleSelectUngroupedOptionType[] = targetDataSource.fields.map((_field) => ({
            label: formatValueAsLabel(_field),
            value: {
                type: 'basic',
                field: _field,
            },
        }))

        return result
    }
}

export default InsightDataConfigDialog

type InsightListItemProps = {
    insight: InsightWidgetType['insights'][number]
    removeRow: (id: InsightWidgetType['insights'][number]['id']) => () => void
    enterEditMode: (id: InsightWidgetType['insights'][number]['id']) => () => void
    title: string
}

const InsightListItem = ({ insight, removeRow, enterEditMode, title }: InsightListItemProps) => {
    return (
        <Stack direction="row" gap={1} sx={{ width: '100%', height: '50px' }}>
            {/* Summary
                        ========================================= */}
            <Typography sx={{ flexGrow: 1, alignSelf: 'center' }}>{title}</Typography>
            {/* Edit button
                        ========================================= */}
            <Tooltip title="Edit">
                <IconButton color="primary" onClick={enterEditMode(insight.id)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            {/* Delete button
                        ========================================= */}
            <Tooltip title="Delete">
                <IconButton color="secondary" onClick={removeRow(insight.id)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    )
}

type InsightBuilderProps = {
    insight: InsightWidgetType['insights'][number]
    dataSourceFields: FlexibleSelectOptionType[]
    onSave: (insight: InsightWidgetType['insights'][number]) => void
    onDiscard: () => void
    targetDataSource: ReportDataSourceType
    selectedDataSource: SelectedDataSourceType | null
    isNew?: boolean
    interventions: InterventionType[]
    categories: InsightWidgetType['interventionConfig']['categories']
    mode: InsightWidgetType['mode']
}

const InsightBuilder = ({
    mode,
    insight,
    dataSourceFields,
    onSave,
    onDiscard,
    interventions,
    selectedDataSource,
    targetDataSource,
    categories,
}: InsightBuilderProps) => {
    const [_insight, setInsight] = useState(insight)
    const [dataChanged, setDataChanged] = useState(0)

    const addRule = () => {
        setInsight((pv) => ({
            ...pv,
            rules: [
                ...pv?.rules,
                {
                    value: '',
                    operator: 'eq',
                    varaible: null,
                },
            ],
        }))
        setDataChanged((pv) => pv + 1)
    }

    const removeRule = (idx: number) => {
        setInsight((pv) => ({
            ...pv,
            rules: pv?.rules.filter((x, _idx) => _idx !== idx),
        }))
        setDataChanged((pv) => pv + 1)
    }

    const updateRule =
        (idx: number, filed: keyof InsightWidgetType['insights'][number]['rules'][number]) => (value: any) => {
            setInsight((pv) => {
                const tmp = structuredClone(pv)
                tmp.rules[idx] = {
                    ...tmp.rules[idx],
                    [filed]: value,
                }
                return tmp
            })
            setDataChanged((pv) => pv + 1)
        }

    const updateMessage =
        (
            filed: keyof InsightWidgetType['insights'][number]['message'],
            subField: Exclude<keyof InsightWidgetType['insights'][number]['message']['satisfied'], 'order'>
        ) =>
        (value: any) => {
            setInsight((pv) => {
                const tmp = structuredClone(pv)
                tmp.message[filed][subField] = value
                return tmp
            })
            setDataChanged((pv) => pv + 1)
        }

    const updateRuleVariable = (idx: number) => (value: any) => {
        setDataChanged((pv) => pv + 1)
        setInsight((pv) => {
            const tmp = structuredClone(pv)
            tmp.rules[idx].varaible = value

            return tmp
        })
    }

    const updateAggregationMethod = (idx: number) => (value: any) => {
        setDataChanged((pv) => pv + 1)
        setInsight((pv) => {
            const tmp = structuredClone(pv)
            tmp.rules[idx].aggregationMethod = value

            return tmp
        })
    }

    const addAction = (mode: keyof InsightWidgetType['insights'][number]['message']) => {
        setInsight((pv) => {
            const tmp = structuredClone(pv)
            tmp.message[mode].actions.push({
                category: '',
                description: '',
                icon: '',
                title: '',
            })

            return tmp
        })
        setDataChanged((pv) => pv + 1)
    }

    const removeAction = (mode: keyof InsightWidgetType['insights'][number]['message'], idx: number) => {
        setInsight((pv) => {
            const tmp = structuredClone(pv)
            tmp.message[mode].actions = tmp.message[mode].actions.filter((x, _idx) => _idx !== idx)

            return tmp
        })
        setDataChanged((pv) => pv + 1)
    }

    const updateAction = (
        mode: keyof InsightWidgetType['insights'][number]['message'],
        idx: number,
        updatedAction: Partial<InsightMessageType['actions'][number]>
    ) => {
        setInsight((pv) => {
            const tmp = structuredClone(pv)
            tmp.message[mode].actions[idx] = {
                ...tmp.message[mode].actions[idx],
                ...updatedAction,
            }

            return tmp
        })
        setDataChanged((pv) => pv + 1)
    }

    const dataValid = useMemo(() => {
        if (_insight.message.satisfied.text.trim() === '' && _insight.message.unsatisfied.text.trim() === '')
            return false
        for (let rule of _insight.rules) {
            if (rule.value === '' || rule.varaible === null) return false
        }
        return true
    }, [dataChanged])

    const allFields = useMemo<ExpressionEditorSuggestionType[]>(() => {
        return getExpressionVariableOptions(targetDataSource)
    }, [targetDataSource])

    useEffect(() => {
        console.log(_insight)
    }, [_insight])

    return (
        <>
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',
                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),
                    padding: theme.spacing(2, 3),
                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Tooltip title="Back">
                    <IconButton onClick={onDiscard}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Insight builder
                </Typography>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 1 auto',
                    height: '70vh',
                    padding: theme.spacing(3),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {/* Content wrapper
                    ========================================= */}
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        paddingRight: theme.spacing(2),
                        overflowY: 'auto',
                    })}
                    className="u-scrollbar"
                >
                    {/* Category
                        ========================================= */}
                    {mode === 'intervention' && (
                        <BaseSelectWithLabel
                            label="Category"
                            options={categories.map((x) => ({ value: x.id, label: x.title }))}
                            fullWidth
                            value={_insight.category}
                            onChange={(value) => setInsight((pv) => ({ ...pv, category: value }))}
                        />
                    )}
                    {/* Header
                                ========================================= */}
                    <Stack direction="row" justifyContent="space-between" gap={1}>
                        <Typography>Rules</Typography>
                        <Tooltip title="Add new rule">
                            <IconButton onClick={addRule}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    {/* Rules wrapper
                                ========================================= */}
                    <Stack gap={3} sx={{ width: '100%' }} className="u-scrollbar">
                        {_insight.rules.map((rule, idx) => (
                            <Stack direction="row" gap={1} key={`rule${idx}`}>
                                <Typography alignSelf="center" width="80px">
                                    V{idx + 1}
                                </Typography>
                                <Stack direction="column" gap={1} flexGrow={1}>
                                    <Stack direction="row" gap={1}>
                                        {/* Select Variable */}
                                        {mode === 'ergm/alaam' ? (
                                            <ErgmAlaamFieldSelector
                                                dataSource={targetDataSource}
                                                value={rule.varaible}
                                                onChange={updateRuleVariable(idx)}
                                                selectedDataSource={selectedDataSource}
                                            />
                                        ) : (
                                            <>
                                                <FlexibleSelect
                                                    fullWidth
                                                    onChange={updateRuleVariable(idx)}
                                                    label="Field"
                                                    options={dataSourceFields}
                                                    value={rule.varaible}
                                                />

                                                {mode === 'group' &&
                                                    ['basic', 'analytic'].includes(rule.varaible?.type || '') && (
                                                        <BaseSelectWithLabel
                                                            fullWidth
                                                            onChange={updateAggregationMethod(idx)}
                                                            label="Aggregation Method"
                                                            options={AGGREGATION_OPTIONS}
                                                            value={rule.aggregationMethod}
                                                        />
                                                    )}
                                            </>
                                        )}
                                    </Stack>
                                    <Stack direction="row" gap={1}>
                                        {/* Operator */}
                                        <BaseSelectWithLabel
                                            label="Operator"
                                            fullWidth
                                            value={rule.operator}
                                            onChange={updateRule(idx, 'operator')}
                                            options={[
                                                {
                                                    value: 'eq',
                                                    label: '=',
                                                },
                                                {
                                                    value: 'neq',
                                                    label: '<>',
                                                },
                                                {
                                                    value: 'lt',
                                                    label: '<',
                                                },
                                                {
                                                    value: 'lte',
                                                    label: '<=',
                                                },
                                                {
                                                    value: 'gt',
                                                    label: '>',
                                                },
                                                {
                                                    value: 'gte',
                                                    label: '>=',
                                                },
                                                {
                                                    value: 'between',
                                                    label: 'Between',
                                                },
                                            ]}
                                        />
                                        {rule.operator === 'between' ? (
                                            <>
                                                <BaseFilledTextField fullWidth label="From" />
                                                <BaseFilledTextField fullWidth label="To" />
                                            </>
                                        ) : (
                                            <BaseFilledTextField
                                                fullWidth
                                                label="Value"
                                                value={rule.value}
                                                onChange={(e) => updateRule(idx, 'value')(e.target.value)}
                                            />
                                        )}
                                    </Stack>
                                    <Divider />
                                </Stack>
                                <Tooltip title="Delete Rule">
                                    <IconButton
                                        sx={{ height: '42px', alignSelf: 'center' }}
                                        onClick={() => removeRule(idx)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        ))}
                    </Stack>
                    {/* Message wrapper
                                ========================================= */}
                    <Stack direction="row" gap={1} pr={6}>
                        <Box sx={{ width: '150px' }}>
                            <Typography>Then: </Typography>
                        </Box>
                        <Stack gap={1} sx={{ flexGrow: 1 }}>
                            <Stack direction="row" gap={1}>
                                <BaseFilledTextField
                                    label="Title"
                                    defaultValue={_insight.message.satisfied.text}
                                    fullWidth
                                    onBlur={(e) => updateMessage('satisfied', 'text')(e.target.value)}
                                />

                                <Box sx={{ width: '350px', flexShrink: 0 }}>
                                    <DynamicIconSelector
                                        label="Icon"
                                        defaultIcon={_insight.message.satisfied.icon}
                                        onSelectIcon={(icon) => updateMessage('satisfied', 'icon')(icon)}
                                    />
                                </Box>

                                <BaseSelectWithLabel
                                    label="Type"
                                    sx={{ width: '160px', flexShrink: 0 }}
                                    value={_insight.message.satisfied.mode}
                                    onChange={updateMessage('satisfied', 'mode')}
                                    options={['neutral', 'positive', 'negative']}
                                />
                            </Stack>

                            <ExpressionEditor
                                id="satisfied-severity"
                                label="Severity"
                                onChange={(value) => updateMessage('satisfied', 'severity')(value)}
                                defaultExpression={_insight.message.satisfied.severity}
                                variables={allFields}
                            />

                            <BaseFilledTextField
                                label="Description"
                                fullWidth
                                multiline
                                rows={3}
                                sx={{ flexGrow: 1 }}
                                defaultValue={_insight.message.satisfied.description}
                                onBlur={(e) => updateMessage('satisfied', 'description')(e.target.value)}
                            />

                            {/* Actions */}
                            <Stack direction="column" gap={1}>
                                <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
                                    <Typography>Actions:</Typography>
                                    <Tooltip title="Add new action">
                                        <IconButton onClick={() => addAction('satisfied')}>
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>

                                {_insight.message.satisfied.actions.map((action, idx) => (
                                    <Stack direction="row" gap={1} key={idx} sx={{ py: 1 }}>
                                        <Stack direction="column" gap={1} flexGrow={1}>
                                            <Stack direction="row" gap={1}>
                                                <BaseFilledTextField
                                                    label="Title"
                                                    fullWidth
                                                    defaultValue={action.title}
                                                    onBlur={(e) => {
                                                        updateAction('satisfied', idx, { title: e.target.value })
                                                    }}
                                                />
                                                <Autocomplete
                                                    options={['Support', 'Monitor', 'Encourage', 'Intervene']}
                                                    fullWidth
                                                    freeSolo
                                                    defaultValue={action.category}
                                                    onInputChange={(e, value) => {
                                                        updateAction('satisfied', idx, { category: value })
                                                    }}
                                                    renderInput={(params) => (
                                                        <BaseFilledTextField {...params} label="Category" />
                                                    )}
                                                />
                                                <Box sx={{ width: '350px', flexShrink: 0 }}>
                                                    <DynamicIconSelector
                                                        label="Icon"
                                                        defaultIcon={action.icon}
                                                        onSelectIcon={(icon) =>
                                                            updateAction('satisfied', idx, { icon })
                                                        }
                                                    />
                                                </Box>
                                            </Stack>
                                            <BaseFilledTextField
                                                label="Description"
                                                fullWidth
                                                multiline
                                                rows={3}
                                                onBlur={(e) => {
                                                    updateAction('satisfied', idx, { description: e.target.value })
                                                }}
                                                defaultValue={action.description}
                                            />
                                        </Stack>
                                        <Tooltip title="Delete Action">
                                            <IconButton
                                                sx={{ height: '42px', alignSelf: 'center' }}
                                                onClick={() => removeAction('satisfied', idx)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="row" gap={1} pr={6}>
                        <Box sx={{ width: '150px' }}>
                            <StyledSwitch
                                label="Otherwise:"
                                checked={_insight.message.unsatisfied.enabled}
                                onChange={(e, checked) => {
                                    setInsight((pv) => ({
                                        ...pv,
                                        message: {
                                            ...pv.message,
                                            unsatisfied: {
                                                ...pv.message.unsatisfied,
                                                enabled: checked,
                                            },
                                        },
                                    }))
                                }}
                            />
                        </Box>
                        <Stack gap={1} sx={{ flexGrow: 1 }}>
                            <Stack direction="row" gap={1}>
                                <BaseFilledTextField
                                    label="Title"
                                    disabled={!_insight.message.unsatisfied.enabled}
                                    fullWidth
                                    defaultValue={_insight.message.unsatisfied.text}
                                    onBlur={(e) => updateMessage('unsatisfied', 'text')(e.target.value)}
                                />

                                <Box sx={{ width: '350px', flexShrink: 0 }}>
                                    <DynamicIconSelector
                                        label="Icon"
                                        disabled={!_insight.message.unsatisfied.enabled}
                                        defaultIcon={_insight.message.unsatisfied.icon}
                                        onSelectIcon={(icon) => updateMessage('unsatisfied', 'icon')(icon)}
                                    />
                                </Box>

                                <BaseSelectWithLabel
                                    label="Type"
                                    sx={{ width: '160px', flexShrink: 0 }}
                                    disabled={!_insight.message.unsatisfied.enabled}
                                    value={_insight.message.unsatisfied.mode}
                                    onChange={updateMessage('unsatisfied', 'mode')}
                                    options={['neutral', 'positive', 'negative']}
                                />
                            </Stack>

                            <BaseFilledTextField
                                label="Description"
                                fullWidth
                                multiline
                                disabled={!_insight.message.unsatisfied.enabled}
                                rows={3}
                                sx={{ flexGrow: 1 }}
                                defaultValue={_insight.message.unsatisfied.description}
                                onBlur={(e) => updateMessage('unsatisfied', 'description')(e.target.value)}
                            />

                            {/* Actions */}
                            <Stack direction="column" gap={1}>
                                <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
                                    <Typography>Actions:</Typography>
                                    <Tooltip title="Add new action">
                                        <span>
                                            <IconButton
                                                disabled={!_insight.message.unsatisfied.enabled}
                                                onClick={() => addAction('unsatisfied')}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Stack>

                                {_insight.message.unsatisfied.actions.map((action, idx) => (
                                    <Stack direction="row" gap={1} key={idx} sx={{ py: 1 }}>
                                        <Stack direction="column" gap={1} flexGrow={1}>
                                            <Stack direction="row" gap={1}>
                                                <BaseFilledTextField
                                                    label="Title"
                                                    fullWidth
                                                    defaultValue={action.title}
                                                    disabled={!_insight.message.unsatisfied.enabled}
                                                    onBlur={(e) => {
                                                        updateAction('unsatisfied', idx, { title: e.target.value })
                                                    }}
                                                />
                                                <Autocomplete
                                                    options={['Support', 'Monitor', 'Encourage', 'Intervene']}
                                                    fullWidth
                                                    freeSolo
                                                    disabled={!_insight.message.unsatisfied.enabled}
                                                    defaultValue={action.category}
                                                    onInputChange={(e, value) => {
                                                        updateAction('unsatisfied', idx, { category: value })
                                                    }}
                                                    renderInput={(params) => (
                                                        <BaseFilledTextField {...params} label="Category" />
                                                    )}
                                                />
                                                <Box sx={{ width: '350px', flexShrink: 0 }}>
                                                    <DynamicIconSelector
                                                        label="Icon"
                                                        defaultIcon={action.icon}
                                                        disabled={!_insight.message.unsatisfied.enabled}
                                                        onSelectIcon={(icon) =>
                                                            updateAction('unsatisfied', idx, { icon })
                                                        }
                                                    />
                                                </Box>
                                            </Stack>
                                            <BaseFilledTextField
                                                label="Description"
                                                fullWidth
                                                multiline
                                                rows={3}
                                                disabled={!_insight.message.unsatisfied.enabled}
                                                onBlur={(e) => {
                                                    updateAction('unsatisfied', idx, { description: e.target.value })
                                                }}
                                                defaultValue={action.description}
                                            />
                                        </Stack>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip title="Delete Action">
                                                <span>
                                                    <IconButton
                                                        disabled={!_insight.message.unsatisfied.enabled}
                                                        sx={{ height: '42px', alignSelf: 'center' }}
                                                        onClick={() => removeAction('unsatisfied', idx)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>

            {/* Footer
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    padding: theme.spacing(2, 3),
                })}
            >
                <BaseButton
                    label="Discard"
                    onClick={onDiscard}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,
                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Save"
                    onClick={(evt) => onSave(_insight)}
                    variant="contained"
                    disabled={!dataValid}
                    color="primary"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </>
    )
}

type ErgmAlaamFieldSelectorProps = {
    dataSource: ReportDataSourceType
    selectedDataSource: SelectedDataSourceType | null
    onChange: (value: Extract<ReportDataSourceAttributeType, { type: 'ergm' | 'alaam' }> | null) => void
    value: ReportDataSourceAttributeType | null
}

const ErgmAlaamFieldSelector = ({ dataSource, selectedDataSource, onChange, value }: ErgmAlaamFieldSelectorProps) => {
    const [selected, setSelected] = useState<Extract<ReportDataSourceAttributeType, { type: 'ergm' | 'alaam' }> | null>(
        null
    )

    useEffect(() => {
        if (value?.type === 'ergm' || value?.type === 'alaam') setSelected(value)
    }, [])

    const networkFields = useMemo<FlexibleSelectGroupedOptionType[]>(() => {
        if (dataSource.mode !== 'network' || selectedDataSource === null) return []
        const ergmGroup: FlexibleSelectGroupedOptionType = {
            group: 'ERGM',
            items: [],
        }
        const alaamGroup: FlexibleSelectGroupedOptionType = {
            group: 'ALAAM',
            items: [],
        }

        const analytics = dataSource.presets[selectedDataSource.preset ?? 'default'].analytics

        if (analytics === undefined) return []

        for (let _relation in analytics) {
            const ergm = analytics[_relation].ergm

            if (ergm !== undefined) {
                ergmGroup.items.push({
                    label: _relation,
                    value: {
                        type: 'ergm',
                        relationship: _relation,
                    },
                })
            }

            const alaam = analytics[_relation].alaam

            if (alaam !== undefined) {
                alaamGroup.items.push({
                    label: _relation,
                    value: {
                        type: 'alaam',
                        relationship: _relation,
                    },
                })
            }
        }
        return [ergmGroup, alaamGroup].filter((group) => group.items.length > 0)
    }, [dataSource])

    const selectedTargetFiled = useMemo<string | null>(() => {
        if (selected === null) return null
        if (selected.type === 'alaam') {
            return selected.targetField === '' ? null : selected.targetField
        }
        return null
    }, [selected])

    const targetFieldOptions = useMemo<FlexibleSelectUngroupedOptionType[]>(() => {
        if (dataSource.mode !== 'network' || selected?.type !== 'alaam') return []
        const analytics = dataSource.presets[selectedDataSource?.preset ?? 'default'].analytics
        if (analytics == null) return []
        const alaam = analytics[selected.relationship]?.alaam
        if (alaam == null) return []
        const result: FlexibleSelectUngroupedOptionType[] = []

        for (let key in alaam) {
            result.push({
                label: key,
                value: key,
            })
        }

        return result
    }, [dataSource, selectedDataSource, selected?.relationship, selected?.type])

    const fieldOptions = useMemo<FlexibleSelectOptionType[]>(() => {
        if (dataSource.mode !== 'network') return []
        const analytics = dataSource.presets[selectedDataSource?.preset ?? 'default'].analytics
        if (analytics == null) return []
        if (selected?.type === 'ergm') {
            const ergm = analytics[selected.relationship]?.ergm
            if (ergm == null) return []
            const result: FlexibleSelectGroupedOptionType[] = []

            for (let _field of ergm.params_stats) {
                result.push({
                    group: _field.name,
                    items: [
                        {
                            label: 'Converged',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'converged',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Log likelihood',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'log_likelihood',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Pseudo R-squared',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'pseudo_r_squared',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Coefficient',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'coef',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'P-value',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'p_values',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Z',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'z',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Standard Error',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'std_err',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Confidence Interval Lower Bound',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'conf_int_lb',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Confidence Interval Upper Bound',
                            value: {
                                type: 'ergm',
                                relationship: selected.relationship,
                                metric: 'conf_int_ub',
                                field: _field.name,
                            },
                        },
                    ],
                })
            }
            return result
        } else if (selected?.type === 'alaam') {
            if (selectedTargetFiled === null) return []
            const alaam = analytics[selected.relationship]?.alaam
            if (alaam == null || alaam[selectedTargetFiled] === undefined) return []
            const result: FlexibleSelectGroupedOptionType[] = []

            for (let _field of alaam[selectedTargetFiled].params_stats) {
                result.push({
                    group: _field.name,
                    items: [
                        {
                            label: 'Converged',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                metric: 'converged',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Log likelihood',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'log_likelihood',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Pseudo R-squared',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'pseudo_r_squared',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Coefficient',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'coef',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'P-value',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'p_values',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Z',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'z',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Standard Error',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'std_err',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Confidence Interval Lower Bound',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'conf_int_lb',
                                field: _field.name,
                            },
                        },
                        {
                            label: 'Confidence Interval Upper Bound',
                            value: {
                                type: 'alaam',
                                relationship: selected.relationship,
                                targetField: selectedTargetFiled,
                                metric: 'conf_int_ub',
                                field: _field.name,
                            },
                        },
                    ],
                })
            }
            return result
        }
        return []
    }, [dataSource, selectedDataSource, selected?.relationship, selected?.type, selectedTargetFiled])

    return (
        <Stack direction="row" gap={1} sx={{ width: '100%' }}>
            <FlexibleSelect
                fullWidth
                label="Network"
                options={networkFields}
                value={
                    selected === null
                        ? null
                        : {
                              type: selected.type,
                              relationship: selected.relationship,
                          }
                }
                onChange={(value) => {
                    setSelected({
                        ...value,
                        field: '',
                        targetField: '',
                    })
                    onChange(null)
                }}
            />
            {selected?.type === 'alaam' && (
                <FlexibleSelect
                    fullWidth
                    label="Target Attribute"
                    options={targetFieldOptions}
                    value={selected.targetField}
                    onChange={(value) => {
                        setSelected((prev) => {
                            if (prev === null) return prev
                            return {
                                ...prev,
                                targetField: value,
                                metric: '',
                                field: '',
                            }
                        })
                        onChange(null)
                    }}
                />
            )}
            <FlexibleSelect
                fullWidth
                label="Metric"
                options={fieldOptions}
                value={selected}
                onChange={(value) => {
                    setSelected(value)
                    onChange(value)
                }}
            />
        </Stack>
    )
}
