import { e } from 'mathjs'
import { NetworkVizContextType } from '../context/NetworkVizContext'
import {
    EdgeRenderType,
    NetworkSelectedItemType,
    NodeAttributeType,
    NodeGroupInfoType,
    NodeRenderType,
} from '../types/NetworkViz.types'
import { groupBy, isEqual, set } from 'lodash'

export function fitNetwork() {}

function normalizeNum(num: number, oldMin: number, oldMax: number, newMin: number, newMax: number): number {
    if (oldMax === oldMin) {
        // Handle the special case where all values are the same
        // For example, return the midpoint of newMin and newMax
        return (newMin + newMax) / 2
    } else {
        let normalizedNum = ((num - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin
        return normalizedNum
    }
}

function getNodeValue(
    id: string,
    nodeAttribute: NodeAttributeType,
    nodes: NetworkVizContextType['nodes'],
    analytics: NetworkVizContextType['analytics']
) {
    if (nodeAttribute.source === 'info') {
        return nodes[id][nodeAttribute.field] ?? 0
    }
    if (nodeAttribute.source === 'analytic') {
        return analytics?.[nodeAttribute.relationship]?.nodes[id][nodeAttribute.field] ?? 0
    }
}

export function shrinkNetwork(
    context: Pick<
        NetworkVizContextType,
        | 'nodeRenders'
        | 'edgeRenders'
        | 'nodeStyle'
        | 'edgeStyle'
        | 'networkShrink'
        | 'nodeGroupInfo'
        | 'nodes'
        | 'analytics'
    >
): Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'> {
    const nodeGroupInfo = context.nodeGroupInfo
    if (nodeGroupInfo == null) throw new Error('nodeGroupInfo is null')

    // expand the network first
    const { nodeRenders: tmpNodes, edgeRenders: tmpEdges } = expandNetwork({
        nodeRenders: context.nodeRenders,
        edgeRenders: context.edgeRenders,
    })

    let nodeToGroup: Record<string, string> = {}
    const groupedNodes: NodeRenderType[] = []
    const groupedNodesDictionary = groupBy(tmpNodes, 'groupKey')

    const nodeSizeRange = {
        max: Number.MIN_SAFE_INTEGER,
        min: Number.MAX_SAFE_INTEGER,
    }

    const nodeValues: Record<string, number> = {}

    const edgeDensity: Record<string, number> = {}
    const nodeCount: number = tmpNodes.filter((node) => node.hide !== true).length

    const nodeScaleBy = context.networkShrink.nodeSize.scaledBy

    for (let key in nodeGroupInfo) {
        let length = nodeGroupInfo[key].nodes.length
        let value: number
        if (length === 0) {
            value = 0
        } else if (nodeScaleBy.source === 'info' && nodeScaleBy.field === 'Number of nodes') {
            value = length
        } else {
            value = 0
            for (let _nodeId of nodeGroupInfo[key].nodes) {
                value += getNodeValue(_nodeId, nodeScaleBy, context.nodes, context.analytics)
            }
            value /= length
        }
        nodeValues[key] = value
        nodeSizeRange.max = Math.max(nodeSizeRange.max, value)
        nodeSizeRange.min = Math.min(nodeSizeRange.min, value)
    }

    for (let key in nodeGroupInfo) {
        const style = context.nodeStyle[key] || context.nodeStyle.default

        let tmpNode: NodeRenderType = {
            id: key,
            key: key,
            hide: true,
            name: `Group: ${key}`,
            nodeColor: 'red',
            highlighted: undefined,
            itemStyle: {
                color: style.normal.color,
            },
            label: {
                show: false,
            },
            nodeVal: 0,
            attributes: {
                color: style.normal.color,
                size: 0,
                x: 0,
                y: 0,
                type: 'circle',
            },
            symbolSize: 0,
            symbol: 'path://m127,0c70.14,0,127,56.86,127,127s-56.86,127-127,127S0,197.14,0,127,56.86,0,127,0ZM58.25,182v-14.9c0-3.82.53-7.49,1.6-11s2.67-6.88,4.81-10.08c-1.68-.31-3.44-.5-5.27-.57s-3.74-.11-5.73-.11c-11,0-19.86,2.06-26.58,6.19-6.72,4.12-10.08,9.47-10.08,16.04v14.44h41.25Zm123.75,0v-14.9c0-4.89-1.3-9.36-3.9-13.41s-6.34-7.6-11.23-10.66-10.73-5.35-17.53-6.88c-6.8-1.53-14.25-2.29-22.34-2.29s-15.32.76-22.11,2.29c-6.8,1.53-12.64,3.82-17.53,6.88s-8.67,6.61-11.34,10.66c-2.67,4.05-4.01,8.52-4.01,13.41v14.9h110Zm55,0v-14.44c0-6.72-3.36-12.11-10.08-16.16s-15.58-6.07-26.58-6.07c-1.83,0-3.63.04-5.39.11s-3.48.27-5.16.57c1.99,3.06,3.48,6.34,4.47,9.85s1.49,7.26,1.49,11.23v14.9h41.25Zm-133.03-26.35c6.95-2.29,14.63-3.44,23.03-3.44s16.08,1.15,23.03,3.44c6.95,2.29,11.19,4.97,12.72,8.02h-71.27c1.38-3.06,5.54-5.73,12.49-8.02Zm-37.24-24.86c3.51-3.59,5.27-7.91,5.27-12.95s-1.76-9.55-5.27-13.06-7.87-5.27-13.06-5.27-9.36,1.76-12.95,5.27-5.39,7.87-5.39,13.06,1.8,9.36,5.39,12.95c3.59,3.59,7.91,5.39,12.95,5.39s9.55-1.8,13.06-5.39Zm146.67,0c3.51-3.59,5.27-7.91,5.27-12.95s-1.76-9.55-5.27-13.06-7.87-5.27-13.06-5.27-9.36,1.76-12.95,5.27-5.39,7.87-5.39,13.06,1.8,9.36,5.39,12.95c3.59,3.59,7.91,5.39,12.95,5.39s9.55-1.8,13.06-5.39Zm-66.8-11.8c5.27-5.35,7.91-11.84,7.91-19.48s-2.64-14.32-7.91-19.59-11.8-7.91-19.59-7.91-14.13,2.64-19.48,7.91-8.02,11.8-8.02,19.59,2.67,14.13,8.02,19.48c5.35,5.35,11.84,8.02,19.48,8.02s14.32-2.67,19.59-8.02Zm-26.12-12.95c-1.76-1.76-2.64-3.93-2.64-6.53s.88-4.77,2.64-6.53,3.93-2.64,6.53-2.64,4.77.88,6.53,2.64c1.76,1.76,2.64,3.93,2.64,6.53s-.88,4.77-2.64,6.53c-1.76,1.76-3.93,2.64-6.53,2.64s-4.77-.88-6.53-2.64Z',
            x: 0,
            y: 0,
            innerNodes: groupedNodesDictionary[key],
        }

        if (groupedNodesDictionary[key].length > 0) {
            let numberOfNodes = 0
            for (let n of groupedNodesDictionary[key]) {
                nodeToGroup[n.id] = key
                if (n.hide) continue
                numberOfNodes++
                tmpNode.nodeVal += n.nodeVal
                tmpNode.x += n.x
                tmpNode.y += n.y

                tmpNode.attributes.x += n.attributes.x
                tmpNode.attributes.y += n.attributes.y
                tmpNode.attributes.size += n.attributes.size
            }

            if (numberOfNodes > 0) {
                tmpNode.nodeVal /= numberOfNodes
                tmpNode.x /= numberOfNodes
                tmpNode.y /= numberOfNodes
                tmpNode.attributes.x /= numberOfNodes
                tmpNode.attributes.y /= numberOfNodes
            }

            if (numberOfNodes > context.networkShrink.nodeSize.threshold) {
                tmpNode.hide = false
            }
        }

        let size = 10
        if (context.networkShrink.nodeSize.scaled) {
            size = normalizeNum(
                nodeValues[key],
                nodeSizeRange.min,
                nodeSizeRange.max,
                context.networkShrink.nodeSize.range.min,
                context.networkShrink.nodeSize.range.max
            )
        } else {
            size = context.nodeStyle.default.normal.size
        }

        tmpNode.symbolSize = size
        tmpNode.attributes.size = size

        groupedNodes.push(tmpNode)
    }

    const edgeMap: Record<string, EdgeRenderType> = {}

    for (let edge of tmpEdges) {
        // update edgeDensity by counting number of edges in each group
        if (edge.hide !== true) {
            const key = edge.groupKey || 'default'
            edgeDensity[key] = edgeDensity[key] ? edgeDensity[key] + 1 : 1
        }
        // Find the groupKey that the source and target node belong to.
        let sourceGroupKey = nodeToGroup[edge.source]
        let targetGroupKey = nodeToGroup[edge.target]

        // Create a combined key for the new edge.
        let edgeKey = sourceGroupKey + '-' + targetGroupKey + '-' + edge.groupKey

        // If both nodes of the edge are in the same group
        if (sourceGroupKey === targetGroupKey) {
            if (edgeMap[edgeKey]) {
                edgeMap[edgeKey].innerEdges?.push(edge)
            } else {
                const style =
                    edge.groupKey != null && edge.groupKey in context.edgeStyle
                        ? context.edgeStyle[edge.groupKey]
                        : context.edgeStyle.default

                edgeMap[edgeKey] = {
                    source: sourceGroupKey,
                    target: targetGroupKey,
                    innerEdges: [edge],
                    groupKey: edge.groupKey,
                    hide: true,
                    id: edgeKey,
                    lineStyle: {
                        color: style.normal.color,
                        type: style.normal.type || 'solid',
                        width: 1,
                    },
                    attributes: {
                        color: style.normal.color,
                        size: 1,
                    },
                }
            }
        } else if (edgeMap[edgeKey]) {
            // If this edge already exists, aggregate the values.
            edgeMap[edgeKey].innerEdges!.push(edge)
            if (edge.hide !== true) {
                edgeMap[edgeKey].lineStyle.width++
                edgeMap[edgeKey].attributes!.size!++
                edgeMap[edgeKey].hide = false
            }
        } else {
            const style =
                edge.groupKey != null && edge.groupKey in context.edgeStyle
                    ? context.edgeStyle[edge.groupKey]
                    : context.edgeStyle.default
            // Otherwise, create a new edge.
            edgeMap[edgeKey] = {
                source: sourceGroupKey,
                target: targetGroupKey,
                innerEdges: [edge],
                groupKey: edge.groupKey,
                hide: edge.hide,
                id: edgeKey,
                lineStyle: {
                    color: style.normal.color,
                    type: style.normal.type || 'solid',
                    width: edge.hide !== true ? 1 : 0,
                },
                attributes: {
                    color: style.normal.color,
                    size: edge.hide !== true ? 1 : 0,
                },
            }
        }
    }

    // compute edge density
    for (const edgeGroup in edgeDensity) {
        const numberOfEdges = edgeDensity[edgeGroup]
        const density = numberOfEdges / (nodeCount * (nodeCount - 1))
        edgeDensity[edgeGroup] = density
    }

    const groupedEdges = Object.values(edgeMap)

    const edgeSizeRange = {
        max: Number.MIN_SAFE_INTEGER,
        min: Number.MAX_SAFE_INTEGER,
    }

    // find max and min
    for (let edge of groupedEdges) {
        // compute density between two groups
        const numberOfEdges = edge.lineStyle.width
        const density =
            numberOfEdges / (nodeGroupInfo[edge.source].nodes.length * nodeGroupInfo[edge.target].nodes.length)

        edge.tooltip = `<b>${
            edge.groupKey
        }</b> <br/> <b>Edges: </b> ${numberOfEdges} <br/> <b>Density: </b> ${density.toFixed(3)}`

        const relativeDensity = density / edgeDensity[edge.groupKey || 'default']
        if (
            context.networkShrink.edgeWidth.threshold !== false &&
            (edge.innerEdges == null || relativeDensity < context.networkShrink.edgeWidth.threshold)
        ) {
            edge.hide = true
        }

        if (edgeSizeRange.max < density) {
            edgeSizeRange.max = density
        }
        if (edgeSizeRange.min > density) {
            edgeSizeRange.min = density
        }

        edge.lineStyle.width = density
        edge.attributes!.size = density
    }

    // scale edges based on density
    groupedEdges.forEach((edge) => {
        let size = 1
        if (context.networkShrink.edgeWidth.scaled) {
            size = normalizeNum(
                edge.lineStyle.width,
                edgeSizeRange.min,
                edgeSizeRange.max,
                context.networkShrink.edgeWidth.range.min,
                context.networkShrink.edgeWidth.range.max
            )
        } else {
            size = context.edgeStyle.default.normal.width
        }
        edge.lineStyle.width = size
        edge.attributes!.size = size
    })

    // generate node tooltip
    groupedNodes.forEach((node) => (node.tooltip = generateNodeTooltip(node, nodeGroupInfo)))

    return {
        nodeRenders: groupedNodes,
        edgeRenders: Object.values(edgeMap),
    }
}

export function expandNetwork(
    context: Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'>
): Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'> {
    const tmpNodes =
        typeof structuredClone !== 'undefined'
            ? structuredClone(context.nodeRenders)
            : JSON.parse(JSON.stringify(context.nodeRenders))

    const tmpEdges =
        typeof structuredClone !== 'undefined'
            ? structuredClone(context.edgeRenders)
            : JSON.parse(JSON.stringify(context.edgeRenders))

    const nodeRenders: NetworkVizContextType['nodeRenders'] = []
    const edgeRenders: NetworkVizContextType['edgeRenders'] = []

    for (let node of tmpNodes) {
        nodeRenders.push(...expandNode(node))
    }

    for (let edge of tmpEdges) {
        edgeRenders.push(...expandEdge(edge))
    }

    return {
        nodeRenders,
        edgeRenders,
    }
}

export function shrinkGroup(
    context: Pick<
        NetworkVizContextType,
        'nodeRenders' | 'edgeRenders' | 'nodeStyle' | 'edgeStyle' | 'networkShrink' | 'nodeGroupInfo'
    >,
    groupKey: string
): Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'> {
    const nodeGroupInfo = context.nodeGroupInfo
    if (nodeGroupInfo == null) throw new Error('nodeGroupInfo is null')

    const nodeSizeRange = {
        max: Number.MIN_SAFE_INTEGER,
        min: Number.MAX_SAFE_INTEGER,
    }

    let nodesToGroup: NodeRenderType[] = []
    let nodesToKeep: NodeRenderType[] = []
    let edgesToKeep: EdgeRenderType[] = []

    context.nodeRenders.forEach((node) => {
        if (node.groupKey === groupKey) {
            nodesToGroup.push(node)
        } else {
            nodesToKeep.push(node)
        }
    })

    // Calculate nodeSizeRange
    for (let key in nodeGroupInfo) {
        const length = nodeGroupInfo[key].nodes.length
        nodeSizeRange.max = Math.max(nodeSizeRange.max, length)
        nodeSizeRange.min = Math.min(nodeSizeRange.min, length)
    }

    const style = context.nodeStyle[groupKey] || context.nodeStyle.default

    let groupedNode: NodeRenderType = {
        id: groupKey,
        key: groupKey,
        hide: true,
        name: `Group: ${groupKey}`,
        nodeColor: 'red',
        highlighted: undefined,
        itemStyle: {
            color: style.normal.color,
        },
        label: {
            show: false,
        },
        nodeVal: 0,
        attributes: {
            color: style.normal.color,
            size: 0,
            x: 0,
            y: 0,
            type: 'circle',
        },
        symbolSize: 0,
        symbol: 'roundRect',
        x: 0,
        y: 0,
        innerNodes: nodesToGroup,
    }

    let nodeToGroup: Record<string, string> = {}

    if (nodesToGroup.length > 0) {
        let numberOfNodes = 0
        for (let n of nodesToGroup) {
            nodeToGroup[n.id] = groupKey
            if (n.hide) continue
            numberOfNodes++
            groupedNode.nodeVal += n.nodeVal
            groupedNode.x += n.x
            groupedNode.y += n.y

            groupedNode.attributes.x += n.attributes.x
            groupedNode.attributes.y += n.attributes.y
            groupedNode.attributes.size += n.attributes.size
        }

        if (numberOfNodes > 0) {
            groupedNode.nodeVal /= numberOfNodes
            groupedNode.x /= numberOfNodes
            groupedNode.y /= numberOfNodes
            groupedNode.attributes.x /= numberOfNodes
            groupedNode.attributes.y /= numberOfNodes
            groupedNode.hide = false
        }
    }

    let size = 10
    if (context.networkShrink.nodeSize.scaled) {
        size = normalizeNum(
            nodeGroupInfo[groupKey].nodes.length,
            nodeSizeRange.min,
            nodeSizeRange.max,
            context.networkShrink.nodeSize.range.min,
            context.networkShrink.nodeSize.range.max
        )
    } else {
        size = context.nodeStyle.default.normal.size
    }

    groupedNode.symbolSize = size
    groupedNode.attributes.size = size

    const edgeMap: Record<string, EdgeRenderType> = {}

    for (let edge of context.edgeRenders) {
        // Find the groupKey that the source and target node belong to.
        let sourceGroupKey = nodeToGroup[edge.source] ?? edge.source
        let targetGroupKey = nodeToGroup[edge.target] ?? edge.target

        if (sourceGroupKey !== groupKey && targetGroupKey !== groupKey) {
            edgesToKeep.push(edge)
            continue
        }

        // Create a combined key for the new edge.
        let edgeKey = sourceGroupKey + '-' + targetGroupKey + '-' + edge.groupKey

        // If both source and target of the edge are in the given group
        if (sourceGroupKey === groupKey && targetGroupKey === groupKey) {
            if (edgeMap[edgeKey]) {
                edgeMap[edgeKey].innerEdges?.push(edge)
            } else {
                const style =
                    edge.groupKey != null && edge.groupKey in context.edgeStyle
                        ? context.edgeStyle[edge.groupKey]
                        : context.edgeStyle.default

                edgeMap[edgeKey] = {
                    source: sourceGroupKey,
                    target: targetGroupKey,
                    innerEdges: [edge],
                    groupKey: edge.groupKey,
                    hide: true,
                    id: edgeKey,
                    lineStyle: {
                        color: style.normal.color,
                        type: style.normal.type || 'solid',
                        width: 1,
                    },
                    attributes: {
                        color: style.normal.color,
                        size: 1,
                    },
                }
            }
        } else if (edgeMap[edgeKey]) {
            edgeMap[edgeKey].innerEdges!.push(edge)
            if (edge.hide !== true) {
                edgeMap[edgeKey].lineStyle.width++
                edgeMap[edgeKey].attributes!.size!++
                edgeMap[edgeKey].hide = false
            }
        } else {
            const style =
                edge.groupKey != null && edge.groupKey in context.edgeStyle
                    ? context.edgeStyle[edge.groupKey]
                    : context.edgeStyle.default
            edgeMap[edgeKey] = {
                source: sourceGroupKey === groupKey ? sourceGroupKey : sourceGroupKey,
                target: targetGroupKey === groupKey ? targetGroupKey : targetGroupKey,
                innerEdges: [edge],
                groupKey: edge.groupKey,
                hide: edge.hide,
                id: edgeKey,
                lineStyle: {
                    color: style.normal.color,
                    type: style.normal.type || 'solid',
                    width: edge.hide ? 0 : 1,
                },
                attributes: {
                    color: style.normal.color,
                    size: edge.hide ? 0 : 1,
                },
            }
        }
    }

    const edgeSizeRange = {
        max: Number.MIN_SAFE_INTEGER,
        min: Number.MAX_SAFE_INTEGER,
    }

    const finalEdges = [...edgesToKeep, ...Object.values(edgeMap)]

    // find max and min
    finalEdges.forEach((edge) => {
        // compute density between two groups
        if (edge.innerEdges == null || edge.innerEdges.length === 0) return

        const sourceGroupInfo = nodeGroupInfo[edge.source]
        const targetGroupInfo = nodeGroupInfo[edge.target]

        if (targetGroupInfo == null && sourceGroupInfo == null) return

        const numberOfEdges = edge.innerEdges.length
        const density = numberOfEdges / (sourceGroupInfo?.nodes.length ?? 1 * targetGroupInfo?.nodes.length ?? 1)

        if (edgeSizeRange.max < density) {
            edgeSizeRange.max = density
        }
        if (edgeSizeRange.min > density) {
            edgeSizeRange.min = density
        }

        if (edgeMap[edge.id] != null) {
            edge.tooltip = `<b>${
                edge.groupKey
            }</b> <br/> <b>Edges: </b> ${numberOfEdges} <br/> <b>Density: </b> ${density.toFixed(3)}`

            if (
                context.networkShrink.edgeWidth.threshold !== false &&
                (edge.innerEdges == null || density < context.networkShrink.edgeWidth.threshold)
            ) {
                edge.hide = true
            }

            edge.lineStyle.width = density
            edge.attributes!.size = density
        }
    })

    finalEdges.forEach((edge) => {
        if (edgeMap[edge.id] == null) return
        let size = 1
        if (context.networkShrink.edgeWidth.scaled) {
            size = normalizeNum(
                edge.lineStyle.width,
                edgeSizeRange.min,
                edgeSizeRange.max,
                context.networkShrink.edgeWidth.range.min,
                context.networkShrink.edgeWidth.range.max
            )
        } else {
            size = context.edgeStyle.default.normal.width
        }
        edge.lineStyle.width = size
        edge.attributes!.size = size
    })

    // Join back the nodes and edges
    const finalNodes = [...nodesToKeep, groupedNode]

    return {
        nodeRenders: finalNodes,
        edgeRenders: finalEdges,
    }
}

export function expandGroup(
    groupKey: string,
    context: Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'>
): Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'> {
    // Find the group node and remove it from the node list
    const groupNode = context.nodeRenders.find((node) => node.key === groupKey)
    if (!groupNode || groupNode.innerNodes === undefined || groupNode.innerNodes.length === 0) {
        throw new Error(`Group ${groupKey} not found or has no inner nodes`)
    }
    const newNodes = context.nodeRenders.filter((node) => node.key !== groupKey)

    // Expand the group node into its inner nodes
    const expandedNodes = expandNode(groupNode)
    // Add the expanded nodes back to the main list of nodes
    newNodes.push(...expandedNodes)

    // Create a new array to store the updated edges
    const newEdges: NetworkVizContextType['edgeRenders'] = []

    // For each edge in the original network
    context.edgeRenders.forEach((edge) => {
        // If the edge does not have any inner edges (i.e., it was not part of the grouped nodes)
        if (edge.innerEdges == null || edge.innerEdges.length === 0) {
            // Keep the edge as it is
            newEdges.push(edge)
        }
        // If the edge's source and target are both the group node,
        // it means this edge is an internal edge within the group node
        else if (edge.source === groupKey && edge.target === groupKey) {
            // Add all the internal edges to the edge list
            newEdges.push(...edge.innerEdges)
        }
        // If the edge's source is the group node and the target is outside,
        // it means this edge is an outbound edge from the group node
        else if (edge.source === groupKey) {
            // Split the grouped edge into its original edges, keeping the target as the outside node
            newEdges.push(
                ...edge.innerEdges.map((x) => ({
                    ...x,
                    target: edge.target, // keep the same target
                }))
            )
        }
        // If the edge's target is the group node and the source is outside,
        // it means this edge is an inbound edge to the group node
        else if (edge.target === groupKey) {
            // Split the grouped edge into its original edges, keeping the source as the outside node
            newEdges.push(
                ...edge.innerEdges.map((x) => ({
                    ...x,
                    source: edge.source, // keep the same source
                }))
            )
        }
        // If the edge is unrelated to the group node (i.e., both its source and target are outside nodes)
        else {
            // Keep the edge as it is
            newEdges.push(edge)
        }
    })

    // Return the updated nodes and edges
    return {
        nodeRenders: newNodes,
        edgeRenders: newEdges,
    }
}

function expandNode(node: NetworkVizContextType['nodeRenders'][number]): NetworkVizContextType['nodeRenders'] {
    if (node.innerNodes == null || node.innerNodes.length === 0) {
        return [node]
    }
    const expandedNodes: NetworkVizContextType['nodeRenders'] = []
    for (let innerNode of node.innerNodes) {
        expandedNodes.push(...expandNode(innerNode))
    }
    return expandedNodes
}

function expandEdge(edge: NetworkVizContextType['edgeRenders'][number]): NetworkVizContextType['edgeRenders'] {
    if (edge.innerEdges == null || edge.innerEdges.length === 0) {
        return [edge]
    }
    const expandedEdges: NetworkVizContextType['edgeRenders'] = []
    for (let innerEdge of edge.innerEdges) {
        expandedEdges.push(...expandEdge(innerEdge))
    }
    return expandedEdges
}

export function generateNodeTooltip(node: NodeRenderType, nodeGroupInfo?: NodeGroupInfoType) {
    let label = node.name

    if (node.groupKey != null && node.groupKey !== 'default') {
        label += `<br/> ${node.groupKey}`
    }

    const nodeGroupInfoId = node.id

    if (Array.isArray(node.innerNodes) && nodeGroupInfo && nodeGroupInfo[nodeGroupInfoId]) {
        label += `<br/>Nodes: ${nodeGroupInfo[nodeGroupInfoId].nodes.length}`
    }

    if (Array.isArray(node.innerNodes) && nodeGroupInfo && nodeGroupInfo[nodeGroupInfoId]) {
        let edgeTable = '<table><tr><th>Network</th><th>Edges</th><th>Density</th></tr>'
        for (let edgeGroup in nodeGroupInfo[nodeGroupInfoId].edges) {
            const group = nodeGroupInfo[nodeGroupInfoId].edges[edgeGroup]
            edgeTable += `<tr><td><b>${edgeGroup}</b></td><td>${group.count}</td><td>${group.density.toFixed(
                3
            )}</td></tr>`
        }
        label += `<br/> ${edgeTable}`
    }

    return label
}

export function generateEdgeTooltip(edge: EdgeRenderType) {}

export function isSelectedItemEquals(
    selectedItem1: NetworkSelectedItemType | null,
    selectedItem2: NetworkSelectedItemType | null
) {
    return isEqual(selectedItem1, selectedItem2)
}

// Recursive function to update properties where they match the default value
export function updateUnchangedProperties(target: any, defaultValue: any, newValue: any): void {
    for (let prop in defaultValue) {
        if (typeof target[prop] === 'object' && target[prop] !== null) {
            updateUnchangedProperties(target[prop], defaultValue[prop], newValue[prop])
        } else if (isEqual(target[prop], defaultValue[prop])) {
            set(target, prop, newValue[prop])
        }
    }
}

export async function convertImagesToBase64(nodeRenders: NodeRenderType[]) {
    function isValidUrl(url: string) {
        try {
            new URL(url)
            return true
        } catch (_) {
            return false
        }
    }

    async function fetchImageAsBase64(url: string) {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
        })
        const blob = await response.blob()
        const reader = new FileReader()

        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
    }

    return Promise.all(
        nodeRenders.map(async (x) => {
            if (x.symbol?.startsWith('image://')) {
                const imageUrl = x.symbol.slice('image://'.length)
                if (isValidUrl(imageUrl)) {
                    try {
                        const base64Data = await fetchImageAsBase64(imageUrl)
                        return { ...x, symbol: 'image://' + base64Data }
                    } catch (error) {
                        console.error('Error converting image to base64:', error)
                        return x // Return original if there's an error
                    }
                } else {
                    console.warn(`Invalid URL: ${imageUrl}`)
                    return x // Return original if the URL is not valid
                }
            }
            return x // Return original if symbol doesn't start with 'image://'
        })
    )
}
