//* ======= Libraries
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Stack, Box, Typography, IconButton, Tooltip } from '@mui/material'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import PasswordField from 'components/PasswordField/PasswordField'
import StyledDialog from 'components/dialog/StyledDialog'
import PrivacyPolicyDialog from 'features/sign-in/PrivacyPolicyDialog'
import TermsOfServiceDialog from 'features/sign-in/TermsOfServiceDialog'
//* ======= Custom logic
import { isEmailValid } from 'helpers/validators'
import { ReportInfoType, RequestReportPasscodeService } from 'services/ReportApi'
//* ======= Assets and styles
import SNALogo from 'assets/images/sna-toolbox-logo.png'
import ReportDefaultImage from 'assets/images/report-default.png'

type Props = {
    reportInfo: ReportInfoType
    onConfirm: (email: string, passcode: string) => void
    isPending?: boolean
    error?: string
}

function ReportViewLogin({ reportInfo, onConfirm, isPending = false, error = '' }: Props) {
    const { token } = useParams()

    const [step, setStep] = useState<'email' | 'passcode'>('email')

    const [email, setEmail] = useState('')
    const [passcode, setPasscode] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false)
    const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)

    const requestPasscode = async () => {
        setIsLoading(true)

        if (token != null && email.trim() !== '' && isEmailValid(email)) {
            try {
                const response = RequestReportPasscodeService(token, { email })

                if ((await response).success) {
                    setStep('passcode')
                } else {
                    toast.error('Cannot locate any report!')
                }
            } catch (e) {
                toast.error('Cannot locate any report!')
            }
        }

        setIsLoading(false)
    }

    return (
        <Stack
            alignItems="center"
            sx={(theme) => ({
                height: '100%',

                backgroundColor: theme.palette.common.bg_2,
            })}
        >
            {/* Wrapper
                ========================================= */}
            <Stack
                gap={2}
                sx={(theme) => ({
                    width: '100%',
                    maxWidth: theme.breakpoints.values['lg'],
                    height: '100%',
                    paddingTop: theme.spacing(4),
                    overflowY: 'auto',

                    backgroundColor: theme.palette.common.bg_1,
                })}
                className="u-scrollbar"
            >
                {/* Content
                    ========================================= */}
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {/* Report image and title
                        ========================================= */}
                    <Stack
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            flexShrink: 0,

                            minWidth: 400,
                            width: '70%',
                            padding: theme.spacing(1, 2),
                        })}
                    >
                        <img
                            src={reportInfo.thumbnail || ReportDefaultImage}
                            alt={reportInfo.title}
                            style={{
                                width: '30%',
                                height: 'auto',
                                maxHeight: '20vh',
                                objectFit: 'contain',
                            }}
                        />

                        <Typography
                            fontSize={24}
                            fontWeight={700}
                            textAlign="center"
                            noWrap
                            sx={{
                                width: '100%',
                                color: reportInfo.color,
                            }}
                        >
                            {reportInfo.title}
                        </Typography>
                    </Stack>

                    {/* Content
                        ========================================= */}
                    <Stack
                        gap={2}
                        sx={(theme) => ({
                            minWidth: 400,
                            width: '70%',
                            padding: theme.spacing(3),
                        })}
                    >
                        {step === 'email' ? (
                            <Typography
                                textAlign="center"
                                noWrap
                                sx={{
                                    width: '100%',
                                    marginBottom: 2,
                                }}
                            >
                                Please enter your email and we'll send a passcode to access this report.
                            </Typography>
                        ) : step === 'passcode' ? (
                            <Typography
                                textAlign="center"
                                sx={{
                                    width: '100%',
                                    marginBottom: 2,
                                }}
                            >
                                We've just sent you an email containing the passcode. Please enter it below:
                            </Typography>
                        ) : (
                            false
                        )}

                        {/* Email
                	        ========================================= */}
                        <Stack justifyContent="center" alignItems="center" gap={2}>
                            <BaseFilledTextField
                                id="email"
                                label="Email"
                                value={email}
                                onChange={(evt) => setEmail(evt.target.value.trim())}
                                disabled={step === 'passcode' || isPending || isLoading}
                                error={isEmailValid(email) === false}
                                sx={{
                                    width: '50%',
                                }}
                            />
                        </Stack>

                        {/* Passcode
                	        ========================================= */}
                        {step === 'passcode' && (
                            <>
                                <Stack justifyContent="center" alignItems="center" gap={2}>
                                    <PasswordField
                                        label="Passcode"
                                        value={passcode}
                                        onChange={(evt) => setPasscode(evt.target.value)}
                                        disabled={isPending || isLoading}
                                        sx={{
                                            width: '50%',
                                        }}
                                    />
                                </Stack>

                                {/* Info text
                	                ========================================= */}
                                <Stack direction="row" justifyContent="center">
                                    <Typography
                                        variant="caption"
                                        sx={(theme) => ({
                                            maxWidth: 400,
                                        })}
                                    >
                                        Closing the browser window logs you out of the system and all access to data and
                                        information will be terminated. To access the report again, request a new
                                        passcode via the login link.
                                    </Typography>
                                </Stack>
                            </>
                        )}

                        {/* Error message
                	        ========================================= */}
                        {error && (
                            <Typography
                                textAlign="center"
                                color="error"
                                noWrap
                                sx={{
                                    width: '50%',
                                    marginTop: 1,
                                }}
                            >
                                {error}
                            </Typography>
                        )}
                    </Stack>

                    {/* Controls
                        ========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            flexShrink: 0,

                            padding: theme.spacing(2, 3),
                        })}
                    >
                        {/* Back button
                            ========================================= */}
                        {step === 'passcode' && (
                            <BaseButton
                                label="Back"
                                onClick={() => setStep('email')}
                                variant="outlined"
                                color="secondary"
                                sx={(theme) => ({
                                    minWidth: 180,
                                    paddingY: 1,

                                    color: theme.palette.common.fill_1,
                                })}
                            />
                        )}

                        {/* Request passcode and access report buttons
                            ========================================= */}
                        {step === 'email' ? (
                            /*  Request passcode button
                                ========================================= */
                            <BaseButton
                                label="Request Single-Use Passcode"
                                onClick={(evt) => requestPasscode()}
                                disabled={
                                    email.trim() === '' || isEmailValid(email) === false || isPending || isLoading
                                }
                                variant="contained"
                                color="primary"
                                sx={(theme) => ({
                                    minWidth: 180,
                                    paddingY: 1,
                                })}
                            />
                        ) : step === 'passcode' ? (
                            /*  Access report button
                                ========================================= */
                            <BaseButton
                                label="Access Report"
                                onClick={(evt) => onConfirm(email, passcode)}
                                disabled={
                                    email.trim() === '' ||
                                    isEmailValid(email) === false ||
                                    passcode === '' ||
                                    isPending ||
                                    isLoading
                                }
                                variant="contained"
                                color="primary"
                                sx={(theme) => ({
                                    minWidth: 180,
                                    paddingY: 1,
                                })}
                            />
                        ) : (
                            false
                        )}
                    </Stack>
                </Stack>

                {/* Footer
                    ========================================= */}
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                        flexShrink: 0,
                        marginTop: 'auto',

                        py: 2,
                        px: 3,

                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        backgroundColor: 'common.bg_1',
                    }}
                >
                    <img
                        src={SNALogo}
                        alt="SNA logo"
                        style={{
                            height: 30,
                            width: 140,
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                    />

                    <Typography fontSize={10} fontWeight={400} color="common.text_3" marginLeft="auto">
                        Powered by SNA Toolbox
                    </Typography>

                    <Stack direction="row" justifyContent="center" gap={0} alignItems="center">
                        {/* Privacy policy button
                            ========================================= */}
                        <BaseButton
                            label="Privacy Policy"
                            sx={{
                                fontSize: 10,
                                fontWeight: 400,
                            }}
                            onClick={(evt) => setIsPrivacyPolicyDialogOpen(true)}
                            color="primary"
                        />

                        {/* Terms of use button
                            ========================================= */}
                        <BaseButton
                            label="Terms of Use"
                            sx={{
                                fontSize: 10,
                                fontWeight: 400,
                            }}
                            onClick={(evt) => setIsTermsOfServiceDialogOpen(true)}
                            color="primary"
                        />
                    </Stack>
                </Stack>
            </Stack>

            {/* (Out-of-flow) Privacy policy and terms of service dialogs
				========================================= */}
            {(isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen) && (
                <StyledDialog
                    open={isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen}
                    onClose={() =>
                        isPrivacyPolicyDialogOpen
                            ? setIsPrivacyPolicyDialogOpen(false)
                            : setIsTermsOfServiceDialogOpen(false)
                    }
                    fullWidth
                    maxWidth="lg"
                >
                    {isPrivacyPolicyDialogOpen ? (
                        <PrivacyPolicyDialog onClose={() => setIsPrivacyPolicyDialogOpen(false)} />
                    ) : isTermsOfServiceDialogOpen ? (
                        <TermsOfServiceDialog onClose={() => setIsTermsOfServiceDialogOpen(false)} />
                    ) : (
                        false
                    )}
                </StyledDialog>
            )}
        </Stack>
    )
}

export default ReportViewLogin
