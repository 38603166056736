import { Box, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import PageCard from 'components/layouts/PageCard'
import { RootContext } from 'contexts/RootContext'
import MainDrawer from 'features/drawer/MainDrawer'
import GeneralDrawerNavigation from 'pages/general-dashboard/Navigation'
import { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    FactoryResetPermissionsService,
    GetAllPermissionsService,
    ProjectPermissionType,
    ProjectRolePermissionType,
    ProjectRoleType,
    UpdatePermissionsService,
} from 'services/SuperAdminService'
import SaveIcon from '@mui/icons-material/Save'
import LockResetIcon from '@mui/icons-material/LockReset'
import AlertDialog from 'components/dialog/AlertDialog'

// ToDo - Add Search functionality
// ToDo - Add filter functionality
// ToDo - Group by Resrouce Type
// ToDo - Add tooltip or Info icon for description to roles
// ToDo - Fix header
function PermissionEditor() {
    const [permissions, setPermissions] = useState<ProjectPermissionType[]>([])
    const [roles, setRoles] = useState<ProjectRoleType[]>([])
    const [rolePermission, setRolePermission] = useState<ProjectRolePermissionType[]>([])
    const [openFactoryResetDialog, setOpenFactoryResetDialog] = useState(false) // State to track factory reset dialog
    const [hasChanged, setHasChanged] = useState(false) // State to track changes

    const { user } = useContext(RootContext)

    const fetchPermissions = () => {
        GetAllPermissionsService()
            .then((res) => {
                if (res.success) {
                    const { projectPermissions, projectRoleProjectPermissions, projectRoles } = res.data
                    setPermissions(projectPermissions.sort((a, b) => a.primaryKey - b.primaryKey))
                    setRoles(projectRoles)
                    setRolePermission(projectRoleProjectPermissions)
                } else {
                    toast.error('Error while fetching permissions. Please try again later.')
                }
            })
            .catch((err) => {
                toast.error('Error while fetching permissions. Please try again later.')
            })
    }

    const getCategory = (id: number) => {
        if (id <= 7) return 'Project'
        else if (id <= 32) return 'Survey'
        else if (id <= 39) return 'Widget'
        else if (id <= 48) return 'Node'
        else if (id <= 55) return 'Report'

        return 'Other'
    }

    useEffect(() => {
        if (user?.roles?.includes('superadmin')) fetchPermissions()
    }, [user])

    const saveChanges = () => {
        var toastId = toast.loading('Updating permissions...')
        UpdatePermissionsService({
            projectRoleProjectPermissions: rolePermission,
        })
            .then((res) => {
                if (res.success) {
                    toast.update(toastId, {
                        type: 'success',
                        isLoading: false,
                        autoClose: 1000,
                        closeButton: null,
                        render: 'Permissions updated successfully',
                    })
                    setHasChanged(false)
                } else {
                    toast.update(toastId, {
                        render: 'Error while updating permissions.',
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000,
                        closeButton: null,
                    })
                }
            })
            .catch((err) => {
                toast.update(toastId, {
                    render: 'Error while updating permissions.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
            })
    }

    if (user == null) return null

    const drawerContent = GeneralDrawerNavigation(user.roles)

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>
            <PageCard.Body>
                <Stack spacing={2}>
                    {/* Header */}
                    <Box sx={{ backgroundColor: 'common.bg_1' }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                            <BaseButton
                                endIcon={<LockResetIcon />}
                                onClick={(e) => setOpenFactoryResetDialog(true)}
                            >
                                Factory Rest
                            </BaseButton>
                            <BaseButton
                                endIcon={<SaveIcon />}
                                disabled={!hasChanged}
                                onClick={(e) => saveChanges()}
                            >
                                Save Changes
                            </BaseButton>
                        </Stack>
                    </Box>
                    {/* Permission Table */}
                    <Box sx={{ flexGrow: 1, backgroundColor: 'common.bg_1' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Resource</TableCell>
                                    <TableCell>Permissions</TableCell>
                                    {roles.map((role) => (
                                        <TableCell key={role.primaryKey}>{role.roleName}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permissions.map((permission) => (
                                    <TableRow key={permission.primaryKey}>
                                        <TableCell>{getCategory(permission.primaryKey)}</TableCell>
                                        <TableCell>{permission.permissionName}</TableCell>
                                        {roles.map((role) => (
                                            <TableCell key={role.primaryKey}>
                                                <Checkbox
                                                    checked={rolePermission.some(
                                                        (rp) =>
                                                            rp.projectPermissionID ===
                                                                permission.primaryKey &&
                                                            rp.projectRoleID === role.primaryKey
                                                    )}
                                                    onChange={(e) => {
                                                        const checked = e.target.checked
                                                        setHasChanged(true)
                                                        // Logic to handle the checkbox change.
                                                        if (checked) {
                                                            setRolePermission((pv) => [
                                                                ...pv,
                                                                {
                                                                    projectPermissionID:
                                                                        permission.primaryKey,
                                                                    projectRoleID: role.primaryKey,
                                                                },
                                                            ])
                                                        } else {
                                                            // Remove permission for role
                                                            setRolePermission((pv) =>
                                                                pv.filter(
                                                                    (rp) =>
                                                                        rp.projectPermissionID !==
                                                                            permission.primaryKey ||
                                                                        rp.projectRoleID !== role.primaryKey
                                                                )
                                                            )
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Stack>
            </PageCard.Body>

            {/* Confirm Dialog */}
            <AlertDialog
                title="Confirm Factory Reset"
                open={openFactoryResetDialog}
                message="Are you sure you want to reset all permissions to default?"
                confirmText='Reset Permissions to "Default"'
                onClose={() => {
                    setOpenFactoryResetDialog(false)
                }}
                onConfirm={() => {
                    var toastId = toast.loading('Resetting permissions...')
                    FactoryResetPermissionsService()
                        .then((res) => {
                            if (res.success) {
                                toast.update(toastId, {
                                    render: 'Permissions reset to default.',
                                    type: 'success',
                                    isLoading: false,
                                    autoClose: 1000,
                                    closeButton: null,
                                })
                                fetchPermissions()
                                setOpenFactoryResetDialog(false)
                            } else {
                                toast.update(toastId, {
                                    render: 'Error while resetting permissions.',
                                    type: 'error',
                                    isLoading: false,
                                    autoClose: 1000,
                                    closeButton: null,
                                })
                            }
                        })
                        .catch((err) => {
                            toast.update(toastId, {
                                render: 'Error while resetting permissions.',
                                type: 'error',
                                isLoading: false,
                                autoClose: 1000,
                                closeButton: null,
                            })
                        })
                }}
            ></AlertDialog>
        </PageCard>
    )
}

export default PermissionEditor
