//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Typography, Divider, FormControlLabel, Switch, Slider } from '@mui/material'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import BorderPicker from 'components/widgets/BorderPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    TextWidgetType,
    ReportWidgetSettingsBaseType,
} from 'features/report-designer/types/reportDesigner.types'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { tooltipPlacementOptions } from '../info-widget/InfoWidgetSettings'
import { StyledSliderPicker } from 'features/StyledComponents/StyledSlider'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
//* ======= Assets and styles

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'text'>
    settings: Omit<ReportWidgetType, 'content'>
    details: TextWidgetType
}

function TextWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { activeSlideId, updateWidget, updateWidgetStyles, updateTextWidgetContent } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateWidget: store.updateWidget,
        updateTextWidgetContent: store.updateTextWidgetContent,
        updateWidgetStyles: store.updateWidgetStyles,
    }))

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'text') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        height: '100%',
                        paddingTop: theme.spacing(0.5),
                        paddingX: theme.spacing(2),
                        overflowY: 'auto',
                        // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                        overflowX: 'hidden',
                    })}
                    className="u-scrollbar"
                >
                    {/* Heading
                        ========================================= */}
                    <Typography fontSize={16} noWrap sx={{ flexShrink: 0 }}>
                        Text Settings
                    </Typography>

                    <Divider
                        sx={{
                            marginBottom: 1.5,
                        }}
                    />

                    {/* Container settings
                        ========================================= */}
                    <Stack gap={2}>
                        {/* Title
                            ========================================= */}
                        <BaseFilledTextField
                            label="Widget Title"
                            defaultValue={widget.settings.title}
                            onBlur={(evt) =>
                                updateWidget({
                                    slideId: activeSlideId,
                                    widgetId: widget.settings.id,
                                    data: {
                                        title: evt.target.value.trim(),
                                    },
                                })
                            }
                            size="small"
                            fullWidth
                        />

                        {/* Background color
                            ========================================= */}
                        <StyledWidgetAccordion
                            title="Background Color"
                            hasToggle
                            isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                            onToggle={(isEnabled) =>
                                updateStyles({
                                    backgroundColor: {
                                        isEnabled: isEnabled,
                                        color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                    },
                                })
                            }
                            hasBottomBorder
                        >
                            <ColorPicker
                                value={widget.settings.styles.backgroundColor.color}
                                onChange={(newColor) =>
                                    updateStyles({
                                        backgroundColor: {
                                            color: newColor,
                                        },
                                    })
                                }
                                disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                            />
                        </StyledWidgetAccordion>

                        {/* Border
                            ========================================= */}
                        <StyledWidgetAccordion
                            title="Border"
                            hasToggle
                            isToggledOff={widget.settings.styles.border.isEnabled === false}
                            onToggle={(isEnabled) =>
                                updateStyles({
                                    border: {
                                        isEnabled: isEnabled,
                                    },
                                })
                            }
                            hasBottomBorder
                        >
                            <BorderPicker
                                value={widget.settings.styles.border}
                                onChange={(value) =>
                                    updateStyles({
                                        border: {
                                            width: value.width,
                                            style: value.style,
                                            color: value.color,
                                            radius: value.radius,
                                        },
                                    })
                                }
                                disabled={widget.settings.styles.border.isEnabled === false}
                            />
                        </StyledWidgetAccordion>

                        {/* Auto height
                            ========================================= */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={widget.settings.dimensions.autoHeight}
                                    onChange={(evt, checked) =>
                                        updateWidget({
                                            slideId: activeSlideId,
                                            widgetId: widget.settings.id,
                                            data: {
                                                dimensions: {
                                                    autoHeight: checked,
                                                },
                                            },
                                        })
                                    }
                                />
                            }
                            label="Auto Height"
                        />

                        {/* Scrollable
                            ========================================= */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={widget.details.scrollable}
                                    disabled={widget.settings.dimensions.autoHeight}
                                    onChange={(evt, checked) =>
                                        updateTextWidgetContent({
                                            slideId: activeSlideId,
                                            widgetId: widget.settings.id,
                                            data: {
                                                scrollable: checked,
                                            },
                                        })
                                    }
                                />
                            }
                            label="Scrollable"
                        />

                        {/* Conditional visibility
                                ========================================= */}
                        <ReportConditionalVisibilitySettings
                            conditionalVisibility={widget.settings.conditionalVisibility}
                            onChange={(conditionalVisibility) => {
                                updateWidget({
                                    slideId: activeSlideId,
                                    widgetId: widget.settings.id,
                                    data: {
                                        conditionalVisibility,
                                    },
                                })
                            }}
                        />

                        {/* Tooltip
                            ========================================= */}
                        <ReportWidgetTooltipSettings
                            tooltip={widget.settings.tooltip}
                            onChange={(tooltip) => {
                                updateWidget({
                                    slideId: activeSlideId,
                                    widgetId: widget.settings.id,
                                    data: {
                                        tooltip,
                                    },
                                })
                            }}
                        />
                    </Stack>
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default TextWidgetSettings
