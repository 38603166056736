import React, { useMemo, useState } from 'react'
import { EditNodeSettingsModal } from './EditNodeSettingsModal'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import SettingsIcon from '@mui/icons-material/Settings'

import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { Chip, List, ListItem, ListItemText, Tab, Tabs } from '@mui/material'
import { formatLabel, formatValue } from 'features/network-viz/helpers/DataFormatter'
import getNodeValue from 'features/network-viz/helpers/NodeValueParser'
import {
    NetworkItemIdType,
    NodeGroupInfoType,
    NodeIdType,
    NodeInfoConfigType,
    NodeType,
} from 'features/network-viz/types/NetworkViz.types'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import { Box } from '@mui/system'
import BaseButton from 'components/base/BaseButton'

type NetworkSelectedGroupInfoTablePropsType = {
    mode?: 'report' | 'network-viz'
    selectedGroup: NodeGroupInfoType[string] | undefined
    analytics: NetworkVizContextType['analytics']
    nodeInfoConfig: NodeInfoConfigType
    nodes: Record<NodeIdType, NodeType>
    drillInto: (group: NetworkItemIdType) => void
    groupKey: string
}

export function NetworkSelectedGroupInfoTable({
    mode = 'network-viz',
    selectedGroup,
    groupKey,
    analytics,
    nodeInfoConfig,
    nodes,
    drillInto,
}: NetworkSelectedGroupInfoTablePropsType) {
    //ToDo add feature to display data for recurring surveys
    const round = 0
    type TabType = 'nodes' | 'info' | 'analytics'
    const [selectedTab, setSelectedTab] = useState<TabType>('nodes')
    const [relationship, setRelationship] = useState('view')

    const data = useMemo(() => {
        if (selectedGroup == null) return null
        const innerNodes = selectedGroup.nodes

        switch (selectedTab) {
            case 'nodes':
                const result: string[] = []
                for (let nodeId of innerNodes) {
                    result.push(nodeInfoConfig.title ? nodes[nodeId][nodeInfoConfig.title] : `Node ${nodeId}`)
                }
                return result
            case 'analytics':
                if (analytics && analytics[relationship].nodes) {
                    let fieldSums: Record<string, number> = {}
                    let fieldCounts: Record<string, number> = {}

                    innerNodes.forEach((nodeId) => {
                        Object.keys(analytics[relationship].nodes[nodeId])
                            .filter(
                                (key) =>
                                    ![
                                        'greedy_modularity_community',
                                        'weakly_connected_component',
                                        'strongly_connected_component',
                                    ].includes(key)
                            )
                            .forEach((key) => {
                                const field = analytics[relationship].nodes[nodeId]
                                let label = formatLabel(key)
                                if (field == null || label == null || label.length === 0) {
                                    return
                                }

                                // get value. If NA and hideNA flag, then skip
                                const value = formatValue(
                                    //@ts-ignore
                                    getNodeValue(field[key], round)
                                )
                                if (nodeInfoConfig.hideNA && value === 'NA') {
                                    return
                                }

                                if (fieldSums[label] === undefined) {
                                    fieldSums[label] = 0
                                    fieldCounts[label] = 0
                                }

                                fieldSums[label] += value
                                fieldCounts[label] += 1
                            })
                    })

                    // Now compute the averages
                    return [
                        { label: 'Number of Nodes', value: formatValue(innerNodes.length) },
                        ...Object.keys(selectedGroup.edges).flatMap((label) => {
                            return [
                                {
                                    label: 'Number of ' + label + ' Ties',
                                    value: formatValue(selectedGroup.edges[label].count),
                                },
                                {
                                    label: 'Density of ' + label + ' Ties',
                                    value: formatValue(selectedGroup.edges[label].density),
                                },
                            ]
                        }),
                        ...Object.keys(fieldSums).map((label) => {
                            return {
                                label: 'Avg ' + label,
                                value: formatValue(fieldSums[label] / fieldCounts[label]),
                            }
                        }),
                    ]
                }
                break
            case 'info':
                return []
        }
    }, [selectedGroup, analytics, selectedTab])

    return selectedGroup == null ? (
        <></>
    ) : (
        <React.Fragment>
            {/* Body
                ========================================= */}
            <Stack
                gap={1}
                sx={{
                    flexGrow: 1,

                    minHeight: 0,
                }}
            >
                {/* Details
                        ========================================= */}

                <Box sx={{}}>
                    <Tabs
                        value={selectedTab}
                        onChange={(event: React.SyntheticEvent, newValue: TabType) =>
                            setSelectedTab(newValue)
                        }
                        aria-label="basic tabs example"
                    >
                        <Tab label="Nodes" value="nodes" />
                        <Tab label="Summary" value="analytics" />
                    </Tabs>
                </Box>

                {selectedTab === 'nodes' ? (
                    <Box
                        sx={{
                            flexGrow: 1,
                            padding: 0,
                            margin: 0,
                            overflow: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {Array.isArray(data) &&
                            (data as string[]).map((item, index) => (
                                <Tooltip key={index} title={item}>
                                    <Chip label={item} sx={{ margin: 0.5, width: '45%' }} />
                                </Tooltip>
                            ))}
                    </Box>
                ) : selectedTab === 'analytics' ? (
                    <List
                        dense
                        sx={{
                            flexGrow: 1,
                            padding: 0,
                            margin: 0,
                            overflow: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {Array.isArray(data) &&
                            (data as { label: string; value: any }[]).map((item, index) => (
                                <ListItem
                                    sx={{
                                        padding: 0,
                                        margin: 0,
                                    }}
                                    key={item?.label}
                                >
                                    <ListItemText
                                        sx={{ marginY: '1px' }}
                                        primary={item?.label + ':'}
                                        secondary={item?.value}
                                    />
                                </ListItem>
                            ))}
                    </List>
                ) : (
                    <></>
                )}

                {/* Bottom controls
                        ========================================= */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    <BaseButton onClick={(e) => drillInto(groupKey)}>Drill Into</BaseButton>
                </Stack>
            </Stack>
        </React.Fragment>
    )
}
