import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
    Button,
    Dialog,
    Typography,
    Box,
    DialogTitle,
    DialogActions,
    DialogContent,
    Stack,
    Avatar,
    Tooltip,
} from '@mui/material'
import {
    AdminCreateUserService,
    AdminDeleteUserService,
    AdminGetAllUsersService,
    AdminResendUserInvitationService,
    AdminUpdateUserRoleService,
    CreateUserRequestType,
    UserManagmentAccountType,
} from 'services/SuperAdminService'
import { GridColDef } from '@mui/x-data-grid-pro'
import { RootContext } from 'contexts/RootContext'
import GeneralDrawerNavigation from 'pages/general-dashboard/Navigation'
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import BaseButton from 'components/base/BaseButton'
import AddIcon from '@mui/icons-material/Add'
import StyledDialog from 'components/dialog/StyledDialog'
import { Controller, useForm } from 'react-hook-form'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import ToastHelper from 'helpers/ToastHelper'
import { EMAIL_REGEX } from 'helpers/validators'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { buildAbbreviation } from 'helpers/TextFormatterHelpers'
import CheckIcon from '@mui/icons-material/Check'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { ResendProjectAccountInvitationService } from 'services/ProjectService'

const ROLE_ID_MAP: Record<string, number> = {
    superadmin: 0,
    admin: 1,
}

const UserManagment: React.FC = () => {
    const [users, setUsers] = useState<UserManagmentAccountType[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [userToDelete, setUserToDelete] = useState<null | string | number>(null)
    const [userToEdit, setUserToEdit] = useState<null | CreateUserRequestType>(null)
    const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false)

    const fetchUsers = async () => {
        setLoading(true)
        try {
            const data = await AdminGetAllUsersService()
            if (data.success) {
                setUsers(data.data)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const resendInvitation = async (id: string | number) => {
        const toast = new ToastHelper({
            errorMessage: 'Failed to resend invitation',
            successMessage: 'Invitation resent successfully',
            loadingMessage: 'Resending invitation...',
        })
        try {
            const data = await AdminResendUserInvitationService(id)
            if (data.success) {
                toast.success()
            } else {
                toast.fail()
            }
        } catch (e) {
            toast.fail()
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'avatar',
                headerName: 'Avatar',
                renderCell: (params) => (
                    <Avatar src={params.value as string} alt="Avatar">
                        {buildAbbreviation(params.row.name || params.row.email)}
                    </Avatar>
                ),
            },
            { field: 'name', headerName: 'Name' },
            { field: 'username', headerName: 'Username' },
            { field: 'email', headerName: 'Email' },
            { field: 'phoneNumber', headerName: 'Phone Number' },
            { field: 'company', headerName: 'Company' },
            { field: 'position', headerName: 'Position' },
            { field: 'country', headerName: 'Country' },
            {
                field: 'isActive',
                headerName: 'Activated',
                type: 'boolean',
                renderCell: (params) => {
                    if (params.value) {
                        return (
                            <Tooltip title="Active">
                                <CheckIcon fontSize="small" color="secondary" />
                            </Tooltip>
                        )
                    } else {
                        return (
                            <Stack direction="row" alignItems="center">
                                <Tooltip title="Awaiting response">
                                    <PendingActionsIcon fontSize="small" color="warning" />
                                </Tooltip>
                                <BaseButton onClick={() => resendInvitation(params.id)}>
                                    Resend Invitation
                                </BaseButton>
                            </Stack>
                        )
                    }
                },
            },
            { field: 'isProfileCompeleted', headerName: 'Profile Completed', type: 'boolean' },
            { field: 'isEmailVerified', headerName: 'Email Verified', type: 'boolean' },
            { field: 'isMobileVerified', headerName: 'Mobile Verified', type: 'boolean' },
            {
                field: 'roles',
                headerName: 'Roles',
                renderCell: (params) => (params.value as string[]).join(', '),
            },

            {
                field: 'actions',
                headerName: 'Actions',
                type: 'actions',
                renderCell: (params) => (
                    <Box>
                        <Button color="primary" onClick={() => handleEditOpen(params.row)}>
                            Edit
                        </Button>
                        <Button color="secondary" onClick={() => setUserToDelete(params.id)}>
                            Delete
                        </Button>
                    </Box>
                ),
            },
        ],
        []
    )

    const handleEditOpen = (user: UserManagmentAccountType) => {
        setUserToEdit({
            email: user.email!,
            roles: user.roles.map((x) => ROLE_ID_MAP[x]).filter((x) => x != null),
        })
    }

    const handleDeleteConfirm = () => {
        if (userToDelete) {
            const toast = new ToastHelper({
                errorMessage: 'Failed to delete User',
                successMessage: 'User deleted successfully',
                loadingMessage: 'Deleting User...',
            })
            // Add the delete logic here
            AdminDeleteUserService(userToDelete)
                .then((res) => {
                    if (res.success) {
                        setUsers((prev) => prev.filter((user) => user.primaryKey !== userToDelete))
                        setUserToDelete(null)
                        toast.success()
                    } else {
                        toast.fail()
                    }
                })
                .catch((e) => toast.fail())
        }
    }

    const handleEditUserRole = async () => {
        if (userToEdit) {
            const toast = new ToastHelper({
                errorMessage: 'Failed to update User roles',
                successMessage: 'User roles updated successfully',
                loadingMessage: 'Updating User roles...',
            })

            // find user by email
            const user = users.find((x) => x.email === userToEdit.email)
            if (user == null) {
                toast.fail('User not found')
                return
            }
            try {
                var response = await AdminUpdateUserRoleService(user.primaryKey, { roles: userToEdit.roles })

                if (response.success) {
                    setUsers((prev) => {
                        const index = prev.findIndex((x) => x.primaryKey === user?.primaryKey)
                        if (index === -1) return prev
                        return [
                            ...prev.slice(0, index),
                            {
                                ...prev[index],
                                roles: userToEdit.roles.map(
                                    (x) =>
                                        Object.keys(ROLE_ID_MAP).find((y) => ROLE_ID_MAP[y] === x) as string
                                ),
                            },
                            ...prev.slice(index + 1),
                        ]
                    })
                    setUserToEdit(null)
                    toast.success()
                    return
                }
            } catch {}
            toast.fail()
        }
    }

    const { user: loggedInUser } = useContext(RootContext)

    if (loggedInUser == null) return null

    const drawerContent = GeneralDrawerNavigation(loggedInUser.roles)

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>
            <PageCard.Body>
                <Box sx={{ backgroundColor: 'common.bg_1', height: '100%' }}>
                    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
                        <StyledDataGrid
                            rows={users}
                            columns={columns}
                            loading={loading}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                            hasToolbar={false}
                            getRowId={(row) => row.primaryKey}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns below, the other columns will remain visible
                                        phoneNumber: false,
                                        company: false,
                                        position: false,
                                        country: false,
                                    },
                                },
                            }}
                            customToolbar={
                                <BaseButton
                                    variant="text"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => setCreateDialogOpen(true)}
                                >
                                    Create New User
                                </BaseButton>
                            }
                        />
                    </Box>
                    <StyledDialog open={userToDelete !== null} onClose={() => setUserToDelete(null)}>
                        <DialogTitle>Edit User Roles</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Are you sure you want to update the role of following user:
                            </Typography>
                            <Typography fontWeight="bold">
                                {users.find((x) => x.primaryKey === userToDelete)?.email}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <BaseButton variant="contained" color="warning" onClick={handleDeleteConfirm}>
                                Confirm Delete
                            </BaseButton>
                            <BaseButton variant="outlined" onClick={() => setUserToDelete(null)}>
                                Cancel
                            </BaseButton>
                        </DialogActions>
                    </StyledDialog>
                    <StyledDialog open={userToEdit !== null} onClose={() => setUserToEdit(null)}>
                        <DialogTitle>Edit User Role Dialog</DialogTitle>
                        <DialogContent>
                            <Stack gap={1}>
                                <Typography>
                                    Are you sure you want to update roles of the following user:
                                </Typography>

                                <Typography fontWeight="bold">{userToEdit?.email}</Typography>
                                <BaseSelectWithLabel
                                    label="Roles"
                                    fullWidth
                                    selectProps={{
                                        multiple: true,
                                    }}
                                    options={[
                                        { value: 0, label: 'Super Admin' },
                                        { value: 1, label: 'Admin' },
                                    ]}
                                    value={userToEdit?.roles || []}
                                    onChange={(values) => {
                                        setUserToEdit((prev) => {
                                            if (prev == null) return null
                                            return {
                                                ...prev,
                                                roles: values,
                                            }
                                        })
                                    }}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <BaseButton variant="outlined" onClick={() => setUserToEdit(null)}>
                                Cancel
                            </BaseButton>
                            <BaseButton variant="contained" onClick={handleEditUserRole}>
                                Update
                            </BaseButton>
                        </DialogActions>
                    </StyledDialog>

                    <CreateUserDialog
                        open={isCreateDialogOpen}
                        onClose={() => {
                            fetchUsers()
                            setCreateDialogOpen(false)
                        }}
                    />
                </Box>
            </PageCard.Body>
        </PageCard>
    )
}

export default UserManagment

type ICreateUserDialog = {
    open: boolean
    onClose: () => void
}

function CreateUserDialog({ open, onClose }: ICreateUserDialog) {
    // form initialization
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm<CreateUserRequestType>({ mode: 'onBlur' })

    const createUser = () => {
        const toast = new ToastHelper({
            errorMessage: 'Failed to create User',
            successMessage: 'User created successfully',
            loadingMessage: 'Creating User...',
        })
        handleSubmit(
            //Valid
            (data) => {
                AdminCreateUserService(data)
                    .then((res) => {
                        if (res.success) {
                            toast.success()
                            onClose()
                        } else {
                            toast.fail()
                        }
                    })
                    .catch((e) => {
                        toast.fail()
                    })
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    useEffect(() => {
        if (open) {
            reset()
        }
    }, [open])

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Register a new User</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Email"
                                required
                                {...field}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        )}
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: EMAIL_REGEX,
                                message: 'Invalid Email format',
                            },
                        }}
                    />

                    <Controller
                        name="roles"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <BaseSelectWithLabel
                                label="Roles"
                                selectProps={{
                                    multiple: true,
                                }}
                                options={[
                                    { value: 0, label: 'Super Admin' },
                                    { value: 1, label: 'Admin' },
                                ]}
                                required
                                {...field}
                                error={!!errors.roles}
                                helperText={errors.roles?.message}
                            />
                        )}
                        rules={{
                            validate: (value) => (value.length > 0 ? true : 'At least one role is required.'),
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton variant="outlined" color="warning" onClick={onClose}>
                    Cancel
                </BaseButton>
                <BaseButton
                    disabled={!isDirty || !isValid}
                    variant="contained"
                    color="primary"
                    onClick={createUser}
                >
                    Create
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
