//* ======= Libraries
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { Box, IconButton, Tooltip, Stack, Paper, Typography } from '@mui/material'
//* ======= Components and features
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import { NetworkAnalyticsInfoTable } from 'features/network-viz/floating-components/network-info/network-analytics-info/components/NetworkAnalyticsInfo'
import { NetworkAnalyticsInfoTop10Table } from 'features/network-viz/floating-components/network-info/network-analytics-info/components/NetworkAnalyticsInfoTop10'
import { NetworkSelectedNodeInfoTable } from 'features/network-viz/floating-components/network-info/selected-node-info/NetworkSelectedNodeInfoTable'
import { NetworkAnalyticsCumminutyInfoTable } from 'features/network-viz/floating-components/network-info/network-analytics-info/components/NetworkAnalyticsCumminutyInfo'
import ReportNetworkInfoWrapperComponent from 'features/report-designer/report-analytics-panel/ReportNetwokrInfoWrapper'
//* ======= Custom logic
import {
    NetworkWidgetType,
    ReportDataSourceType,
    ReportNetworkDataSourceType,
} from '../types/reportDesigner.types'
//* ======= Assets and styles
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import GroupIcon from '@mui/icons-material/Group'

const ReportNetworkAnalyticsItems: {
    key: keyof NetworkWidgetType['networkAnalyticsPanel']['components']
    label: string
    icon: ReactElement
}[] = [
    {
        key: 'selectedNode',
        label: 'Selected Node',
        icon: <AssignmentIndIcon />,
    },
    {
        key: 'graph',
        label: 'Graph Analytics',
        icon: <AnalyticsIcon />,
    },
    { key: 'top10', label: 'Top 10', icon: <SortByAlphaIcon /> },
    { key: 'scc', label: 'Strongly Connected Component', icon: <GroupAddOutlinedIcon /> },
    { key: 'wcc', label: 'Weakly Connected Component', icon: <GroupRemoveOutlinedIcon /> },
    { key: 'gmc', label: 'Greedy Modularity Communities', icon: <GroupIcon /> },
]

type Props = {
    widget: NetworkWidgetType
    dataSources: ReportDataSourceType[]
    title: string
    isExpanded: boolean
    toggleExpanded: () => void
}

function ReportAnalyticsPanel({ widget, dataSources, title, isExpanded, toggleExpanded }: Props) {
    const networkContext = useMemo(() => {
        const dataSource = dataSources.find(
            (x) => x.mode === 'network' && x.id === widget.networkDatasourceId
        ) as ReportNetworkDataSourceType
        if (dataSource == null) return null
        let presetKey = widget.selectedPreset || 'default'
        return {
            nodes: dataSource.networkContext.nodes,
            nodeRenders: dataSource.presets[presetKey].nodeRenders,
            nodeInfoConfig: dataSource.networkContext.nodeInfoConfig,
            analytics: widget.analytics || null,
        }
    }, [dataSources, widget.selectedPreset, widget.networkDatasourceId, widget.analytics])

    useEffect(() => {
        if (widget.selectedNode == null && openWidget === 'selectedNode') {
            setOpenWidget(null)
        }
    }, [widget.selectedNode])

    const [openWidget, setOpenWidget] = useState<
        keyof NetworkWidgetType['networkAnalyticsPanel']['components'] | null
    >(null)

    const toggleOpenWidget = (key: keyof NetworkWidgetType['networkAnalyticsPanel']['components']) => {
        setOpenWidget((pv) => (pv === key ? null : key))
    }

    //ToDo Fix string and any type
    const getNetworkWidget = (
        type: keyof NetworkWidgetType['networkAnalyticsPanel']['components'],
        value: any
    ) => {
        if (networkContext == null) return <>Loading</>
        let content = <></>

        switch (type) {
            case 'selectedNode':
                content = (
                    <NetworkSelectedNodeInfoTable
                        analytics={networkContext.analytics!}
                        nodeInfoConfig={networkContext.nodeInfoConfig}
                        nodes={networkContext.nodes}
                        selectedNode={widget.selectedNode}
                        mode="report"
                    />
                )
                break
            case 'graph':
                content = <NetworkAnalyticsInfoTable analytics={networkContext.analytics} />
                break
            case 'top10':
                //ToDo fix this
                content = (
                    <>ToDo</>
                    // <NetworkAnalyticsInfoTop10Table
                    //     analytics={networkContext.analytics!}
                    //     selectedAttribute={
                    //         (value as NetworkWidgetType['networkAnalyticsPanel']['components']['top10']).field
                    //     }
                    //     mode={
                    //         (value as NetworkWidgetType['networkAnalyticsPanel']['components']['top10']).sort
                    //     }
                    //     nodeRenders={networkContext.nodeRenders}
                    //     nodes={networkContext.nodes}
                    //     selectedNode={widget.selectedNode}
                    // />
                )
                break
            case 'gmc':
                content = (
                    <NetworkAnalyticsCumminutyInfoTable
                        data={networkContext.analytics!.greedy_modularity_communities}
                    />
                )
                break
            case 'wcc':
                content = (
                    <NetworkAnalyticsCumminutyInfoTable
                        data={networkContext.analytics!.weakly_connected_components}
                    />
                )
                break
            case 'scc':
                content = (
                    <NetworkAnalyticsCumminutyInfoTable
                        data={networkContext.analytics!.strongly_connected_components}
                    />
                )
                break
        }
        return (
            <Box
                className="u-scrollbar"
                sx={{ padding: 2, overflowY: 'auto', maxHeight: '30vh', width: '300px' }}
            >
                {content}
            </Box>
        )
    }

    return (
        <Stack
            gap={1}
            component={Paper}
            elevation={2}
            sx={(theme) => ({
                flexShrink: 0,
                position: 'relative',

                width: isExpanded ? 300 : 50,

                height: '100%',
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(2),
                overflowY: 'hidden',
                overflowX: 'hidden',

                transition: `width 150ms linear`,
                // @Theme conditional
                backgroundColor:
                    theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
            })}
        >
            {/* Expand/collapse button and title
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={1} flexShrink={0}>
                <Tooltip title={isExpanded ? 'Collapse Panel' : 'Expand Network Analytics'}>
                    <IconButton
                        onClick={(evt) => toggleExpanded()}
                        sx={{
                            margin: isExpanded === false ? '0 auto' : undefined,
                        }}
                    >
                        <ChevronLeftIcon
                            sx={{
                                width: 28,
                                height: 28,

                                transition: `transform 200ms ease-in-out`,
                                transform: isExpanded ? 'rotate(-180deg)' : undefined,
                            }}
                        />
                    </IconButton>
                </Tooltip>

                {isExpanded && (
                    <Typography noWrap paddingRight={1}>
                        {title}
                    </Typography>
                )}
            </Stack>

            {/* Content
                ========================================= */}
            {isExpanded ? (
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        flexGrow: 1,

                        paddingX: theme.spacing(2),
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        pointerEvents: isExpanded ? 'auto' : 'none',

                        transition: 'opacity 150ms linear',
                        opacity: isExpanded ? 1 : 0,
                    })}
                    className="u-scrollbar"
                >
                    {networkContext !== null && networkContext.analytics !== null ? (
                        <>
                            {Object.entries(widget.networkAnalyticsPanel.components)
                                .filter((entry) => entry[1].show)
                                .map(([key, value], idx) => {
                                    return (
                                        <StyledWidgetAccordion
                                            hasBottomBorder={true}
                                            title={value.title}
                                            key={key}
                                        >
                                            {key === 'selectedNode' ? (
                                                /*  Selected node
                                                ========================================= */
                                                <Stack gap={1}>
                                                    <NetworkSelectedNodeInfoTable
                                                        analytics={networkContext.analytics!}
                                                        nodeInfoConfig={networkContext.nodeInfoConfig}
                                                        nodes={networkContext.nodes}
                                                        selectedNode={widget.selectedNode}
                                                        mode="report"
                                                    />
                                                </Stack>
                                            ) : key === 'graph' ? (
                                                /*  Graph info
                                                ========================================= */
                                                <Stack gap={1}>
                                                    <NetworkAnalyticsInfoTable
                                                        analytics={networkContext.analytics}
                                                    />
                                                </Stack>
                                            ) : key === 'top10' ? (
                                                /*  Top 10 Component settings
                                                ========================================= */
                                                // <NetworkAnalyticsInfoTop10Table
                                                //     analytics={networkContext.analytics!}
                                                //     selectedAttribute={
                                                //         (
                                                //             value as NetworkWidgetType['networkAnalyticsPanel']['components']['top10']
                                                //         ).field
                                                //     }
                                                //     mode={
                                                //         (
                                                //             value as NetworkWidgetType['networkAnalyticsPanel']['components']['top10']
                                                //         ).sort
                                                //     }
                                                //     nodeRenders={networkContext.nodeRenders}
                                                //     nodes={networkContext.nodes}
                                                //     selectedNode={widget.selectedNode}
                                                // />
                                                //ToDo fix this
                                                <>ToDo</>
                                            ) : key === 'gmc' ? (
                                                <NetworkAnalyticsCumminutyInfoTable
                                                    data={
                                                        networkContext.analytics!
                                                            .greedy_modularity_communities
                                                    }
                                                />
                                            ) : key === 'wcc' ? (
                                                <NetworkAnalyticsCumminutyInfoTable
                                                    data={
                                                        networkContext.analytics!.weakly_connected_components
                                                    }
                                                />
                                            ) : key === 'scc' ? (
                                                <NetworkAnalyticsCumminutyInfoTable
                                                    data={
                                                        networkContext.analytics!
                                                            .strongly_connected_components
                                                    }
                                                />
                                            ) : null}
                                        </StyledWidgetAccordion>
                                    )
                                })}
                        </>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                <Stack
                    gap={1}
                    sx={{
                        pointerEvents: isExpanded === false ? 'auto' : 'none',

                        transition: 'opacity 150ms linear',
                        opacity: isExpanded === false ? 1 : 0,
                    }}
                >
                    {networkContext !== null && networkContext.analytics !== null ? (
                        <>
                            {ReportNetworkAnalyticsItems.map((item, idx) => {
                                let component = widget.networkAnalyticsPanel.components[item.key]

                                if (!component.show) return <></>

                                return (
                                    <ReportNetworkInfoWrapperComponent
                                        key={item.key}
                                        openWidget={openWidget}
                                        toggleOpen={toggleOpenWidget}
                                        content={getNetworkWidget(item.key, component)}
                                        icon={item.icon}
                                        title={item.label}
                                        id={item.key}
                                    />
                                )
                            })}

                            {/* {Object.entries(widget.networkAnalyticsPanel.components)
                                    .filter((entry) => entry[1].show)
                                    .map(([key, value], idx) => (
                                        <ReportNetowkrInfoWrapperComponent
                                            content={getNetworkWidget(key, value)}
                                            icon={<InventoryIcon />}
                                            title={key}
                                            key={key}
                                        />
                                    ))} */}
                        </>
                    ) : (
                        false
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default ReportAnalyticsPanel
