//* ======= Libraries
import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
//* ======= Custom logic
//* ======= Assets and styles
import { ProjectContext } from 'contexts/ProjectContext'
import { useNavigate, useParams } from 'react-router-dom'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'
import { GetNodeActionsService, NodeActionBasicType, NodeActionType } from 'services/NodeActionService'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro'
import { Avatar, AvatarGroup, Chip, Link, SvgIcon, Tooltip } from '@mui/material'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import { ActionFilterMenu, ActionFilterType, PRIORITY_OPTIONS, STATUS_OPTIONS } from 'features/nodes/NodeActions'
import moment from 'moment'
import { RootContext } from 'contexts/RootContext'
import EventIcon from '@mui/icons-material/Event'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import WarningIcon from '@mui/icons-material/Warning'
import { toast } from 'react-toastify'

function NodeActionDashboard() {
    const { pid } = useParams()
    const navigator = useNavigate()
    const { project, drawerContent } = useContext(ProjectContext)
    const { user } = useContext(RootContext)

    const [actions, setActions] = useState<NodeActionBasicType[]>([])
    const actionsRef = useRef<NodeActionBasicType[]>(actions) // Create a ref

    const [actionFilter, setActionFilter] = useState<ActionFilterType>({
        assignedToMe: true,
        status: ['Pending', 'InProgress'],
        priority: ['Low', 'Medium', 'High', 'Critical'],
    })

    const getStatusOption = (status: NodeActionType['status']) =>
        STATUS_OPTIONS.find((option) => option.value === status)
    const getPriorityOption = (priority: NodeActionType['priority']) =>
        PRIORITY_OPTIONS.find((option) => option.value === priority)

    // Update the ref whenever actions change
    useEffect(() => {
        actionsRef.current = actions
    }, [actions])

    const getDueDateChip = (dueDate: NodeActionType['dueDate']) => {
        if (!dueDate) return null

        const diff = moment(dueDate).diff(moment(), 'days')
        if (diff > 3) return null

        let label = ''
        let tooltip = ''
        let IconComponent = EventIcon
        let backgroundColor: string = '#B0B0B0' // Default color for no due date
        let textColor: string = '#FFF'

        if (diff < 0) {
            tooltip = `Overdue by ${Math.abs(diff)} day${Math.abs(diff) > 1 ? 's' : ''}`
            label = 'Overdue'
            IconComponent = EventBusyIcon
            backgroundColor = '#F44336' // Red color for overdue
        } else if (diff === 0) {
            tooltip = `Due today`
            label = 'Due'
            IconComponent = WarningIcon
            backgroundColor = '#FFC107' // Yellow color for due today
        } else if (diff < 3) {
            tooltip = `Due in ${diff} day${diff > 1 ? 's' : ''}`
            label = 'Due Soon'
            IconComponent = WarningIcon
            backgroundColor = '#FF9800' // Orange color for due soon
        }
        return (
            <Tooltip title={tooltip}>
                <Chip
                    icon={<IconComponent sx={{ color: textColor }} />}
                    label={label}
                    size="small"
                    sx={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                        '& .MuiChip-icon': {
                            color: textColor,
                        },
                    }}
                />
            </Tooltip>
        )
    }

    const columns = useMemo<GridColDef<NodeActionBasicType>[]>(
        () => [
            {
                field: 'node',
                headerName: 'Node',
                renderCell: (params) => {
                    return (
                        <Link href={`/project/${pid}/nodes/${params.row.node?.id}`} underline="none">
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Avatar src={params.row.node?.avatar} alt={params.row.node?.title}>
                                    {params.row.node?.title?.[0]?.toUpperCase()}
                                </Avatar>
                                <Typography fontSize={14} fontWeight={500} color="secondary">
                                    {params.row.node?.title}
                                </Typography>
                            </Stack>
                        </Link>
                    )
                },
            },
            {
                field: 'title',
                headerName: 'Action Title',
            },
            {
                field: 'status',
                headerName: 'Status',
                renderCell: (params) => {
                    const option = getStatusOption(params.row.status)!
                    return (
                        <Tooltip title={option.label}>
                            <Chip
                                icon={option.icon}
                                label={option.label}
                                size="small"
                                sx={{
                                    backgroundColor: option.color,
                                    color: option.textColor,
                                    '& .MuiChip-icon': {
                                        color: option.textColor,
                                    },
                                }}
                            />
                        </Tooltip>
                    )
                },
            },
            {
                field: 'priority',
                headerName: 'Priority',
                renderCell: (params) => {
                    const option = getPriorityOption(params.row.priority)!
                    return (
                        <Tooltip title={option.label}>
                            <Chip
                                icon={option.icon}
                                label={option.label}
                                size="small"
                                sx={{
                                    backgroundColor: option.color,
                                    color: option.textColor,
                                    '& .MuiChip-icon': {
                                        color: option.textColor,
                                    },
                                }}
                            />
                        </Tooltip>
                    )
                },
            },
            {
                field: 'dueDate',
                headerName: 'Due Date',

                renderCell(params) {
                    return (
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography>{params.value ? moment(params.value).format('DD MMM') : '-'}</Typography>
                            {getDueDateChip(params.row.dueDate)}
                        </Stack>
                    )
                },
            },
            {
                field: 'endDate',
                headerName: 'Resolved Date',
                valueFormatter: (params) => {
                    return params.value ? moment(params.value).format('DD MMM') : '-'
                },
            },
            {
                field: 'assignedTo',
                headerName: 'Assigned To',
                renderCell: (params) => {
                    return (
                        <AvatarGroup max={3}>
                            {params.row.assignedTo?.map((user, idx) => (
                                <Tooltip key={idx} title={user.name}>
                                    <Avatar key={idx} alt={user.name} src={user.avatar}>
                                        {user.name?.[0]?.toUpperCase()}
                                    </Avatar>
                                </Tooltip>
                            ))}
                        </AvatarGroup>
                    )
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: 'actions',
                getActions: ({ row }) => {
                    return [
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Open">
                                    <OpenInBrowserIcon />
                                </Tooltip>
                            }
                            label="Open"
                            className="textPrimary"
                            onClick={() => {
                                window.open(
                                    `/project/${pid}/nodes/${row.node.id}?action=${row.primaryKey}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                )
                            }}
                            color="inherit"
                        />,
                    ]
                },
            },
        ],
        []
    )

    const compareAndUpdateActions = (newActions: NodeActionBasicType[]) => {
        // Checking for new actions or updates
        newActions.forEach((newAction) => {
            const oldAction = actionsRef.current.find((x) => x.primaryKey === newAction.primaryKey)
            if (!oldAction) {
                toast.info(`New action created: ${newAction.title}`, {
                    autoClose: 5000,
                    closeButton: true,
                })
                return
            }
            if (oldAction.status !== newAction.status) {
                const { label, color, textColor, icon } = getStatusOption(newAction.status)!
                toast(
                    <ToastContent
                        nodeName={newAction.node?.title ?? ''}
                        action={newAction.title}
                        label={label}
                        icon={icon}
                        color={color}
                        textColor={textColor}
                    />,
                    {
                        autoClose: 5000,
                        closeButton: true,
                        hideProgressBar: true,
                        style: { backgroundColor: color },
                    }
                )
            } else if (oldAction.priority !== newAction.priority) {
                toast.info(`Action ${newAction.title} priority changed to ${newAction.priority}`, {
                    autoClose: 5000,
                    closeButton: true,
                })
            }
        })
        // Checking for deleted actions
        actionsRef.current.forEach((oldAction) => {
            const matchingNewAction = newActions.find((x) => x.primaryKey === oldAction.primaryKey)
            if (!matchingNewAction) {
                toast.warn(`Action ${oldAction.title} has been deleted`, {
                    autoClose: 5000,
                    closeButton: true,
                })
            }
        })
        setActions(newActions)
    }

    useEffect(() => {
        if (pid == null) return

        // Function to fetch data
        const fetchData = (compare: boolean = false) => {
            GetNodeActionsService(pid).then((res) => {
                if (res.success) {
                    if (compare) compareAndUpdateActions(res.data)
                    else setActions(res.data)
                }
            })
        }

        // Initial fetch
        fetchData()

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                fetchData(true)
            }
        }

        // Refresh every 10 seconds, but only if tab is active
        const intervalId = setInterval(() => {
            if (document.visibilityState === 'visible') {
                fetchData(true)
            }
        }, 10000)

        // Set up the visibility change listener
        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            clearInterval(intervalId)
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [pid])

    const filteredActions = useMemo(() => {
        return actions.filter((action) => {
            // if (actionFilter.assignedToMe && !action.assignedTo?.some((at) => at.name === user?.name)) return false
            if (!actionFilter.status.includes(action.status)) return false
            if (!actionFilter.priority.includes(action.priority)) return false
            return true
        })
    }, [actions, actionFilter, user])

    if (project == null) return <DashboardSkeleton />

    return (
        <PageCard>
            <PageCard.Side>{project?.primaryKey && <MainDrawer content={drawerContent} />}</PageCard.Side>

            <PageCard.Body>
                <Stack
                    height="100%"
                    sx={(theme) => ({
                        paddingBottom: 2,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* Title and head controls
						========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            py: 1.25,
                            px: 3,

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Title
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography fontSize={20} fontWeight={500} color="secondary">
                                Action List
                            </Typography>
                        </Stack>

                        {/* Controls
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            <ActionFilterMenu
                                initialFilter={actionFilter}
                                onApply={(filters) => setActionFilter(filters)}
                            />
                        </Stack>
                    </Stack>

                    {/* Action list
						========================================= */}
                    <Box
                        flexGrow={1}
                        sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingRight: 2,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <StyledDataGrid
                            disableRowSelectionOnClick
                            rows={filteredActions}
                            columns={columns}
                            getRowId={(row) => row.primaryKey}
                            hasToolbar={false}
                        />
                    </Box>
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}

type ToastContentProps = {
    nodeName: string
    action: string
    label: string
    icon: React.ReactElement
    color: string
    textColor: string
}

const ToastContent: React.FC<ToastContentProps> = ({ nodeName, action, label, icon, color, textColor }) => {
    return (
        <Box display="flex" alignItems="center" padding="8px" bgcolor={color} color={textColor} width="100%">
            <SvgIcon fontSize="large" color="inherit" sx={{ marginRight: 1 }}>
                {icon}
            </SvgIcon>
            <Box>
                <Typography variant="body1" component="span" fontWeight="bold">
                    {nodeName}
                </Typography>
                <Typography variant="body2" component="span">
                    : {action} status updated to{' '}
                </Typography>
                <Typography variant="body1" component="span" fontWeight="bold">
                    {label}
                </Typography>
            </Box>
        </Box>
    )
}

export default NodeActionDashboard
