import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import StyledDialog from 'components/dialog/StyledDialog'
import { TagType } from 'services/ProjectService'
import DeleteIcon from '@mui/icons-material/Delete'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseButton from 'components/base/BaseButton'
import FreeSoloCreateOption from 'components/base/FreeSoloCreateOption'

export interface TagEditorDialogInterface {
    open: boolean
    onClose: () => void
    tags: TagType[]
    tagCategories: string[]
    onDelete: (id: number, callback?: () => void) => void
    onAdd: (tag: TagType, callback?: () => void) => void
}

export default function TagEditorDialog({
    tagCategories,
    open,
    onClose,
    tags,
    onDelete,
    onAdd,
}: TagEditorDialogInterface) {
    //store the local value
    const [local, setLocal] = useState<TagType>({ key: '', value: '' })

    const handleAddTag = () => {
        onAdd(local, () => setLocal({ key: '', value: '' }))
    }

    return (
        <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Typography>Tag Manager</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" maxWidth={'100%'} flexWrap="wrap">
                    {Array.isArray(tags) &&
                        tags.map((tag, index) => (
                            <Chip
                                sx={{ marginBottom: 2, marginRight: 2 }}
                                key={tag.primaryKey}
                                label={`${tag.key}: ${tag.value}`}
                                onDelete={() => tag.primaryKey && onDelete(tag.primaryKey)}
                                deleteIcon={<DeleteIcon />}
                            />
                        ))}
                </Stack>
                <Grid container spacing={1} mt={2}>
                    <Grid item xs={5}>
                        <FreeSoloCreateOption
                            options={tagCategories.map((t) => ({ title: t }))}
                            value={local.key ? { title: local.key } : null}
                            setValue={(v) => setLocal((lv) => ({ ...lv, key: v }))}
                            label="Category"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <BaseFilledTextField
                            label="Value"
                            fullWidth
                            value={local.value}
                            onChange={(e) => setLocal((lv) => ({ ...lv, value: e.target.value }))}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <BaseButton
                            variant="contained"
                            fullWidth
                            sx={{ height: 56 }}
                            disabled={!local || !local.key || local.key == '' || !local.value || local.value == ''}
                            onClick={handleAddTag}
                        >
                            Add
                        </BaseButton>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </StyledDialog>
    )
}
