//* ======= Libraries
import React from 'react'
import { isEqual } from 'lodash'
import { Stack, Typography, FormControlLabel, Checkbox, RadioGroup, Radio, Divider } from '@mui/material'
//* ======= Components and features
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import { TableDefinitionModeTopK } from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import { DECIMAL_PRECISION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { ReportDataSortOrderType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import BaseFilledTextField from 'components/base/BaseFilledTextField'

type Props = {
    definition: TableDefinitionModeTopK
    fieldOptions: FlexibleSelectOptionType[]
    onUpdate: (data: TableDefinitionModeTopK) => void
    disabled: boolean
}

function TableDefinitionTopKSettings({ fieldOptions, definition, onUpdate, disabled }: Props) {
    const updateDefinitionField = (
        field: keyof TableDefinitionModeTopK,
        value: TableDefinitionModeTopK[keyof TableDefinitionModeTopK]
    ) => {
        const needsUpdate = isEqual(definition[field], value) === false

        if (needsUpdate) {
            onUpdate({
                ...definition,
                [field]: value,
            })
        }
    }

    return (
        <Stack gap={2}>
            {/* ID field and precision
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={2}>
                {/* ID field
                    ========================================= */}
                <FlexibleSelect
                    label="ID Field"
                    value={definition.idField}
                    options={fieldOptions}
                    onChange={(value) => updateDefinitionField('idField', value)}
                    disabled={disabled}
                    required
                    fullWidth
                />

                {/* Decimal precision field
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Decimals"
                    options={DECIMAL_PRECISION_OPTIONS}
                    value={definition.decimalPrecision}
                    onChange={(value) => updateDefinitionField('decimalPrecision', value)}
                    disabled={disabled}
                    size="small"
                    fullWidth
                />
            </Stack>

            {/* Value field
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                {/* Field select
                    ========================================= */}
                <FlexibleSelect
                    label="Sort By"
                    value={definition.value.field}
                    options={fieldOptions}
                    onChange={(value) =>
                        updateDefinitionField('value', {
                            ...definition.value,
                            field: value,
                        })
                    }
                    disabled={disabled}
                    required
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Field title
                    ========================================= */}
                <BaseFilledTextField
                    label="Field Title"
                    defaultValue={definition.value.title}
                    onBlur={(evt) =>
                        updateDefinitionField('value', {
                            ...definition.value,
                            title: evt.target.value.trim(),
                        })
                    }
                    disabled={disabled}
                    size="small"
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Field visibility checkbox
                    ========================================= */}
                <FormControlLabel
                    label="Show column"
                    control={
                        <Checkbox
                            checked={definition.value.isVisible}
                            onChange={(evt, checked) =>
                                updateDefinitionField('value', {
                                    ...definition.value,
                                    isVisible: checked,
                                })
                            }
                            disabled={disabled}
                        />
                    }
                    sx={{
                        flex: '0 0 auto',

                        '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                        },
                    }}
                />
            </Stack>

            {/* Label field
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                {/* Field select
                    ========================================= */}
                <FlexibleSelect
                    label="Label Field"
                    options={fieldOptions}
                    value={definition.label.field}
                    onChange={(value) =>
                        updateDefinitionField('label', {
                            ...definition.label,
                            field: value,
                        })
                    }
                    disabled={disabled}
                    hasClearButton
                    clearedReturnValue={null}
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Field title
                    ========================================= */}
                <BaseFilledTextField
                    label="Field Title"
                    defaultValue={definition.label.title}
                    onBlur={(evt) =>
                        updateDefinitionField('label', {
                            ...definition.label,
                            title: evt.target.value.trim(),
                        })
                    }
                    disabled={disabled}
                    size="small"
                    sx={{
                        flex: '1 0 0',
                    }}
                />
            </Stack>

            {/* Rank
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                <Typography
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    Rank Column:
                </Typography>

                {/* Field title
                    ========================================= */}
                <BaseFilledTextField
                    label="Field Title"
                    defaultValue={definition.rank.title}
                    onBlur={(evt) =>
                        updateDefinitionField('rank', {
                            ...definition.rank,
                            title: evt.target.value.trim(),
                        })
                    }
                    disabled={disabled}
                    size="small"
                    sx={{
                        flex: '1 0 0',
                    }}
                />

                {/* Rank column visibility checkbox
                    ========================================= */}
                <FormControlLabel
                    label="Show column"
                    control={
                        <Checkbox
                            checked={definition.rank.isVisible}
                            onChange={(evt, checked) =>
                                updateDefinitionField('rank', {
                                    ...definition.rank,
                                    isVisible: checked,
                                })
                            }
                            disabled={disabled}
                        />
                    }
                    sx={{
                        flex: '0 0 auto',

                        '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                        },
                    }}
                />
            </Stack>

            <Divider />

            {/* Limit
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={2}>
                <Typography
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    Row Limit:
                </Typography>

                <BaseFilledTextField
                    type="number"
                    hiddenLabel
                    size="small"
                    defaultValue={definition.limit}
                    onBlur={(evt) => {
                        const inputElement = evt.currentTarget

                        const limit = Number(evt.target.value)

                        if (limit < 1) {
                            inputElement.value = '1'

                            updateDefinitionField('limit', 1)
                        } else if (limit > 10000) {
                            inputElement.value = '10000'

                            updateDefinitionField('limit', 10000)
                        } else {
                            const wholeValue = Math.floor(limit)

                            if (limit !== wholeValue) {
                                inputElement.value = wholeValue.toString()
                            }

                            updateDefinitionField('limit', wholeValue)
                        }
                    }}
                    disabled={disabled}
                    inputProps={{
                        min: 1,
                        max: 10000,
                        step: 1,
                    }}
                    sx={{
                        flex: '1 0 0',
                    }}
                />
            </Stack>

            {/* Sort order radios
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={2}>
                <Typography
                    component="label"
                    id="top10-sort-order-radio-group"
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    Sort Order:
                </Typography>

                <RadioGroup
                    row
                    aria-labelledby="top10-sort-order-radio-group"
                    value={definition.sortOrder}
                    onChange={(evt) => updateDefinitionField('sortOrder', evt.target.value as ReportDataSortOrderType)}
                    sx={{
                        flex: '0 0 auto',

                        minWidth: 0,
                    }}
                >
                    <FormControlLabel
                        label={'Top ' + definition.limit}
                        value="desc"
                        disabled={disabled}
                        control={<Radio />}
                    />
                    <FormControlLabel
                        label={'Bottom ' + definition.limit}
                        value="asc"
                        disabled={disabled}
                        control={<Radio />}
                    />
                </RadioGroup>
            </Stack>
        </Stack>
    )
}

export default TableDefinitionTopKSettings
