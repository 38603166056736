//* ======= Libraries
import { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material'
//* ======= Components and features
import MainHeader from 'features/header/MainHeader'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
//* ======= Custom logic
import { NO_HEADER_ROUTES } from 'helpers/constants'
//* ======= Assets and styles
import CloseIcon from '@mui/icons-material/Close'

export default function PanelRoutes() {
    const location = useLocation()

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [showSmallScreenWarning, setShowSmallScreenWarning] = useState<boolean | null>(null)

    // if screen smaller than md, set showSmallScreenWarning to true, if not set to false by user
    useEffect(() => {
        if (showSmallScreenWarning === false) return

        if (isSmallScreen) {
            setShowSmallScreenWarning(true)
        } else {
            setShowSmallScreenWarning(null)
        }
    }, [isSmallScreen, showSmallScreenWarning])

    const isNoHeaderRoute = useMemo(() => {
        for (const key in NO_HEADER_ROUTES) {
            if (Object.prototype.hasOwnProperty.call(NO_HEADER_ROUTES, key)) {
                const path = NO_HEADER_ROUTES[key]

                if (location.pathname.includes(path)) {
                    return true
                }
            }
        }

        return false
    }, [location.pathname])

    return (
        <Stack
            component="main"
            spacing={1}
            sx={(theme) => ({
                minWidth: '800px',
                height: '100%',
                // @Theme conditional
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.bg_4 : theme.palette.common.bg_1,
            })}
        >
            {/* 
                These routes should be full-height and have no header on top.
                TODO:
                This implementation feels very wrong. Fix later.
             */}

            <>
                {/* Header
                        ========================================= */}
                {!isNoHeaderRoute && <MainHeader />}

                {/* Header
                        Display a warning message if the screen is too small.
                        ========================================= */}
                {showSmallScreenWarning && (
                    <Stack
                        direction="row"
                        gap={1}
                        sx={(theme) => ({ p: 1, height: '64px', bgcolor: theme.palette.common.bg_2 })}
                    >
                        <Typography variant="body1" sx={{ textAlign: 'center', flexGrow: 1 }}>
                            This website is best viewed on a desktop for the full experience.
                        </Typography>
                        <Tooltip title="Close">
                            <IconButton onClick={() => setShowSmallScreenWarning(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}

                {/* Route body
                        ========================================= */}
                <Box
                    sx={(theme) => ({
                        flexGrow: 1,

                        minHeight: 0,
                        // padding: theme.spacing(0, 3),
                    })}
                >
                    <Outlet />
                </Box>
            </>
        </Stack>
    )
}
