//* ======= Libraries
import React from 'react'
import SurveyInstanceContextProvider from '../../contexts/SurveyInstanceContext'
import SurveyDesigner from 'features/survey-designer/SurveyDesigner'
//* ======= Components and features

interface SurveyDesignerPagePropsType {}

export default function SurveyDesignerPage({}: SurveyDesignerPagePropsType) {
    return (
        <SurveyInstanceContextProvider>
            <SurveyDesigner key="fixed" />
        </SurveyInstanceContextProvider>
    )
}
