import React, { useEffect, useState } from 'react'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Stack,
    Switch,
    Typography,
} from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import SettingIcon from '@mui/icons-material/Settings'
import {
    NetworkVizContextType,
    NetworkVizInitialState,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import { expandNetwork, shrinkNetwork } from 'features/network-viz/helpers/NetworkViz.helper'
import StyledDialog from 'components/dialog/StyledDialog'
import { CheckBox } from '@mui/icons-material'
import RangeSliderSelector from 'components/widgets/RangeSliderSelector'
import {
    NetworkShrinkType,
    NodeAttributeType,
    NodeGroupInfoType,
} from 'features/network-viz/types/NetworkViz.types'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import NodeAttributeSelector from 'features/network-viz/helpers/NodeAttributeSelector'

function NetworkShrinkButton() {
    const {
        nodeStyle,
        nodeGroupInfo,
        edgeStyle,
        nodeRenders,
        edgeRenders,
        networkShrink,
        analytics,
        nodeDataSchema,
        nodes,
    } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [settingDialogOpen, setSettingDialogOpen] = useState(false)

    const handleSettingDialogOpen = () => setSettingDialogOpen(true)
    const handleSettingDialogClose = () => {
        setSettingDialogOpen(false)
        setLocalNetworkShrink(structuredClone(networkShrink) as NetworkShrinkType)
    }

    const [localNetworkShrink, setLocalNetworkShrink] = useState<NetworkShrinkType>(
        NetworkVizInitialState.networkShrink
    )

    useEffect(() => {
        setLocalNetworkShrink(structuredClone(networkShrink) as NetworkShrinkType)
    }, [])

    const toggleShrinkNetwork = () => {
        let updatedNetworkRenders: Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'>

        if (networkShrink.enabled) {
            updatedNetworkRenders = {
                ...expandNetwork({ nodeRenders, edgeRenders }),
            }
        } else {
            updatedNetworkRenders = shrinkNetwork({
                nodeRenders,
                edgeRenders,
                nodeStyle,
                edgeStyle,
                networkShrink,
                nodeGroupInfo,
                analytics,
                nodes,
            })
        }

        dispatchContext({
            type: 'TOGGLE_NETWORK_SHRINK',
            payload: updatedNetworkRenders,
        })
    }

    const handleUpdateSetting = () => {
        const { enabled, ...rest } = localNetworkShrink
        dispatchContext({
            type: 'UPDATE_NETWORK_SHRINK',
            payload: rest,
        })
        setSettingDialogOpen(false)
    }

    return (
        <>
            <Stack direction="row">
                <BaseButton onClick={toggleShrinkNetwork} sx={{ flexGrow: 1 }}>
                    {networkShrink.enabled ? 'Expand' : 'Collapse'} Nodes
                </BaseButton>
                <IconButton onClick={handleSettingDialogOpen}>
                    <SettingIcon />
                </IconButton>
            </Stack>

            {localNetworkShrink && (
                <StyledDialog
                    open={settingDialogOpen}
                    onClose={handleSettingDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Shrink Network Settings</DialogTitle>
                    <DialogContent>
                        <Stack gap={1} spacing={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={localNetworkShrink.nodeSize.scaled}
                                        onChange={(e) => {
                                            setLocalNetworkShrink((pv) => ({
                                                ...pv,
                                                nodeSize: {
                                                    ...pv.nodeSize,
                                                    scaled: e.target.checked,
                                                },
                                            }))
                                        }}
                                    />
                                }
                                label="Enable Node Size Scaling"
                            />
                            <NodeAttributeSelector
                                label="Node Size Scaled By"
                                attribute={localNetworkShrink.nodeSize.scaledBy}
                                analytics={analytics}
                                nodeAttributeOptions={['Number of nodes', ...nodeDataSchema.numericOptions]}
                                disabled={!localNetworkShrink.nodeSize.scaled}
                                onChange={(v) => {
                                    setLocalNetworkShrink((pv) => ({
                                        ...pv,
                                        nodeSize: {
                                            ...pv.nodeSize,
                                            scaledBy: v as NodeAttributeType,
                                        },
                                    }))
                                }}
                            />
                            <Typography>Node Size Range</Typography>
                            <RangeSliderSelector
                                min={1}
                                max={100}
                                onChange={(values) => {
                                    setLocalNetworkShrink((pv) => ({
                                        ...pv,
                                        nodeSize: {
                                            ...pv.nodeSize,
                                            range: {
                                                min: values[0],
                                                max: values[1],
                                            },
                                        },
                                    }))
                                }}
                                values={[
                                    localNetworkShrink.nodeSize.range.min,
                                    localNetworkShrink.nodeSize.range.max,
                                ]}
                                disabled={!localNetworkShrink.nodeSize.scaled}
                            />

                            <BaseFilledTextField
                                type="number"
                                value={localNetworkShrink.nodeSize.threshold}
                                label="Minimum number of nodes"
                                disabled={localNetworkShrink.edgeWidth.threshold === false}
                                helperText="Define the minimum number of nodes required to display a group."
                                inputProps={{
                                    min: 0,
                                }}
                                onChange={(e) => {
                                    setLocalNetworkShrink((pv) => ({
                                        ...pv,
                                        nodeSize: {
                                            ...pv.nodeSize,
                                            threshold: Number.parseFloat(e.target.value),
                                        },
                                    }))
                                }}
                            />

                            <Divider />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={localNetworkShrink.edgeWidth.scaled}
                                        onChange={(e) => {
                                            setLocalNetworkShrink((pv) => ({
                                                ...pv,
                                                edgeWidth: {
                                                    ...pv.edgeWidth,
                                                    scaled: e.target.checked,
                                                },
                                            }))
                                        }}
                                    />
                                }
                                label="Enable Edge Width Scaling"
                            />
                            <Typography>Edge Width Range</Typography>
                            <RangeSliderSelector
                                min={0.01}
                                max={30}
                                onChange={(values) => {
                                    setLocalNetworkShrink((pv) => ({
                                        ...pv,
                                        edgeWidth: {
                                            ...pv.edgeWidth,
                                            range: {
                                                min: values[0],
                                                max: values[1],
                                            },
                                        },
                                    }))
                                }}
                                values={[
                                    localNetworkShrink.edgeWidth.range.min,
                                    localNetworkShrink.edgeWidth.range.max,
                                ]}
                                disabled={!localNetworkShrink.edgeWidth.scaled}
                            />

                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={localNetworkShrink.edgeWidth.threshold !== false}
                                            onChange={(e) => {
                                                setLocalNetworkShrink((pv) => ({
                                                    ...pv,
                                                    edgeWidth: {
                                                        ...pv.edgeWidth,
                                                        threshold: e.target.checked ? 1 : false,
                                                    },
                                                }))
                                            }}
                                        />
                                    }
                                    label="Enable Relative Edge Density Threshold Filtering"
                                />
                                <FormHelperText>
                                    Enable this feature to apply a density-based filter to the edges.
                                    ((|edges|/(|Source Nodes| * |Target Nodes|)/Average Density)).
                                </FormHelperText>
                            </FormControl>

                            <BaseFilledTextField
                                type="number"
                                value={localNetworkShrink.edgeWidth.threshold}
                                label="Minimum Density"
                                disabled={localNetworkShrink.edgeWidth.threshold === false}
                                helperText="Define the minimum density of connections required to display edges between groups."
                                inputProps={{
                                    min: 0,
                                }}
                                onChange={(e) => {
                                    setLocalNetworkShrink((pv) => ({
                                        ...pv,
                                        edgeWidth: {
                                            ...pv.edgeWidth,
                                            threshold: Number.parseFloat(e.target.value),
                                        },
                                    }))
                                }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <BaseButton variant="contained" color="warning" onClick={handleSettingDialogClose}>
                            Discard and Close
                        </BaseButton>
                        <BaseButton variant="contained" color="primary" onClick={handleUpdateSetting}>
                            Save
                        </BaseButton>
                    </DialogActions>
                </StyledDialog>
            )}
        </>
    )
}

export default NetworkShrinkButton
