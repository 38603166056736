//* ======= Libraries
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Box, Stack } from '@mui/material'
//* ======= Components and features
import ReportSettings from 'features/report-designer/ReportSettings'
import ReportSlideSettings from 'features/report-designer/slide/ReportSlideSettings'
import ReportWidgetSettings from 'features/report-designer/widgets/ReportWidgetSettings'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
//* ======= Assets and styles
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

function ReportSettingsSidebar() {
    const { status, layoutSettings, activeSlideId } = useReportStore((store) => ({
        status: store.status,
        layoutSettings: store.layoutSettings,
        activeSlideId: store.activeSlideId,
    }))

    return (
        <Stack
            sx={(theme) => ({
                height: '100%',
            })}
            className="u-scrollbar"
        >
            {status === 'pending' ? (
                /*  "pending" view
                    ========================================= */
                <Box
                    sx={{
                        height: '100%',
                        paddingX: 1.5,
                        paddingBottom: 1,

                        '& > span': {
                            height: '100%',
                        },
                    }}
                >
                    <Skeleton height="100%" />
                </Box>
            ) : status === 'faulty' ? (
                /*  "faulty" view
                    ========================================= */
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    sx={{
                        height: '100%',
                    }}
                >
                    <ErrorOutlineIcon
                        sx={(theme) => ({
                            height: 32,
                            width: 32,

                            color: theme.palette.common.fuschia80,
                        })}
                    />
                </Stack>
            ) : /*  Main view
                    ========================================= */
            layoutSettings.settingsSidebar.view === 'report' ? (
                /*  Report settings
                    ========================================= */
                <ReportSettings />
            ) : layoutSettings.settingsSidebar.view === 'slide' ? (
                /*  Slide settings
                    ========================================= */
                <ReportSlideSettings
                    // The key is needed to trigger a re-render on active slide change.
                    key={activeSlideId}
                />
            ) : layoutSettings.settingsSidebar.view === 'widget' ? (
                /*  Widget settings
                    ========================================= */
                <ReportWidgetSettings />
            ) : (
                false
            )}
        </Stack>
    )
}

export default ReportSettingsSidebar
