import PageCard from 'components/layouts/PageCard'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import NetworkControlButtons from './floating-components/network-control-buttons/NetworkControlButtons'
import BasicNetworkSearchField from './floating-components/BasicNetworkSearchField'
// import NetworkSearchField from './floating-components/NetworkSearchField'
import NetworkRenderer from './network-renderer/NetworkRenderer'
import { NetworkVizContextType, useNetworkVizContext, useNetworkVizDispatch } from './context/NetworkVizContext'
import { useEffect, useRef } from 'react'
import { applyFilter } from './network-viz-settings/filter/NetworkFilter.helpers'
import usePreviousValue from 'hooks/usePreviousValue'
import { isEqual } from 'lodash'
import NetworkEdgeLegend from './floating-components/NetworkEdgeLegend'
import NetworkNodeLegend from './floating-components/NetworkNodeLegend'
import { NetworkWidgetType, ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import SizeScaleMenu from './floating-components/SizeScaleMenu'
import GroupbyMenu from './floating-components/GroupbyMenu'

export interface NetworkVizReportViewProps {
    context: NetworkVizContextType | null
    filters: NetworkVizContextType['filters']
    legend: NetworkWidgetType['legend']
    sizeSacle: NetworkWidgetType['sizeScale']
    hasSearch?: boolean
    focusMode?: NetworkWidgetType['focusMode']
    onSizeScaleChange: (sizeScale: ReportDataSourceAttributeType | null) => void
    onShowLabelsChange: (showLabels: boolean) => void
    groupBy: NetworkWidgetType['groupBy']
    onGroupByChange: (groupBy: ReportDataSourceAttributeType | null) => void
    filterBehavior: {
        updateLayout: boolean
    }
}

export default function NetworkVizReportView({
    filters,
    legend,
    sizeSacle,
    hasSearch = false,
    focusMode = false,
    groupBy,
    onGroupByChange,
    onSizeScaleChange,
    context: intialContext,
    onShowLabelsChange,
    filterBehavior,
}: NetworkVizReportViewProps) {
    const dispatchContext = useNetworkVizDispatch()
    const context = useNetworkVizContext()
    const previousFilters = usePreviousValue(filters)

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (intialContext) {
            dispatchContext({ type: 'STATUS_UPDATE', payload: 'loading' })
            if (filters.length > 0) {
                applyFilter(intialContext, [...filters, ...intialContext.filters], filterBehavior.updateLayout).then(
                    (result) => {
                        dispatchContext({
                            type: 'LOAD_NETWORK',
                            payload: { ...intialContext, ...result, status: 'ready' },
                        })
                        dispatchContext({ type: 'ACTION', payload: { type: 'fit' } })
                    }
                )
            } else {
                dispatchContext({ type: 'LOAD_NETWORK', payload: { ...intialContext, status: 'ready' } })
            }
        }
    }, [intialContext])

    useEffect(() => {
        if (context.status === 'ready' && !isEqual(previousFilters, filters)) {
            dispatchContext({ type: 'STATUS_UPDATE', payload: 'loading' })
            const handleFilters = async () => {
                if (filters.length === 0) {
                    // create a dictionary from current nodeRenders
                    const nodeRendersDic = context.nodeRenders.reduce((acc, cur) => {
                        acc[cur.id] = cur
                        return acc
                    }, {} as Record<string, any>)

                    // When no filter is applied, reset the nodeRenders to the initial state
                    // However, we need to keep the label and symbolSize of the nodes that are changed by the user in the UI
                    dispatchContext({
                        type: 'FILTER_CHANGE',
                        payload: {
                            edgeRenders: intialContext?.edgeRenders || [],
                            nodeRenders:
                                intialContext?.nodeRenders.map((nr) => ({
                                    ...nr,
                                    label: nodeRendersDic[nr.id]?.label,
                                    symbolSize: nodeRendersDic[nr.id]?.symbolSize,
                                })) || [],
                            analytics: intialContext?.analytics,
                            filters: [...context.filters],
                            nodeAdjacentList: intialContext?.nodeAdjacentList,
                        },
                    })
                } else {
                    const result = await applyFilter(
                        context,
                        [
                            ...context.filters,
                            ...filters.filter(
                                (x) => !('values' in x) || !Array.isArray(x.values) || x.values.length > 0
                            ),
                        ],
                        filterBehavior.updateLayout
                    )
                    dispatchContext({
                        type: 'FILTER_CHANGE',
                        payload: {
                            ...result,
                        },
                    })
                    dispatchContext({ type: 'ACTION', payload: { type: 'fit' } })
                }
                dispatchContext({ type: 'STATUS_UPDATE', payload: 'ready' })
            }
            handleFilters()
        }
    }, [filters])

    useEffect(() => {
        if (focusMode) {
            dispatchContext({
                type: 'NODE_INTERACTION_EDIT',
                payload: {
                    nodeInteractionConfig: {
                        ...context.nodeInteractionConfig,
                        hideNonAdjacentNodesOnSelect: focusMode
                            ? true
                            : context.nodeInteractionConfig.hideNonAdjacentNodesOnSelect,
                        dimOtherNodesOnSelect: focusMode,
                    },
                },
            })
        }
    }, [focusMode])

    return (
        <PageCard>
            <PageCard.Body noPaddingRight>
                {/* Page wrapper
                        ========================================= */}
                <Stack
                    className="network-viz"
                    ref={containerRef}
                    direction="row"
                    gap={1}
                    sx={(theme) => ({
                        position: 'relative',
                        height: '100%',
                    })}
                >
                    {/* Main content
                            ========================================= */}
                    <Box
                        sx={(theme) => ({
                            flexShrink: 0,

                            minWidth: 0,
                            height: '100%',
                            width: '100%',
                            padding: 1,
                        })}
                    >
                        <NetworkRenderer />
                    </Box>

                    {/* (Overlay slot) Analytics, search, and right-side action buttons
                            ========================================= */}

                    <Box
                        sx={(theme) => ({
                            position: 'absolute',
                            top: theme.spacing(1),
                            left: theme.spacing(1),
                            zIndex: 1,
                            // Subtract the same value of "top" from full height.
                            maxHeight: `calc(100% - ${theme.spacing(1)})`,
                            minWidth: 0,
                        })}
                    >
                        {hasSearch && <BasicNetworkSearchField size="small" container={containerRef.current} />}

                        {groupBy.placement === 'left' && (
                            <GroupbyMenu options={groupBy} onGroupbyChange={onGroupByChange} />
                        )}

                        {sizeSacle.placement === 'left' && (
                            <SizeScaleMenu options={sizeSacle} onSizeScaleChange={onSizeScaleChange} />
                        )}
                    </Box>

                    {sizeSacle.placement === 'right' && (
                        <Stack
                            sx={(theme) => ({
                                position: 'absolute',
                                top: theme.spacing(1),
                                right: theme.spacing(1),
                                zIndex: 1,
                                // Subtract the same value of "top" from full height.
                                maxHeight: `calc(100% - ${theme.spacing(1)})`,
                                minWidth: 0,
                            })}
                            alignItems={'flex-end'}
                        >
                            {groupBy.placement === 'right' && (
                                <GroupbyMenu options={groupBy} onGroupbyChange={onGroupByChange} />
                            )}

                            {sizeSacle.placement === 'right' && (
                                <SizeScaleMenu options={sizeSacle} onSizeScaleChange={onSizeScaleChange} />
                            )}
                        </Stack>
                    )}

                    {/* (Overlay slot) Bottom controls
                            ========================================= */}
                    <Stack
                        direction="row"
                        sx={(theme) => ({
                            position: 'absolute',
                            bottom: theme.spacing(1),
                            right: theme.spacing(1),
                            zIndex: 1,
                            width: 'calc(100% - 16px)',
                        })}
                    >
                        <Stack
                            sx={{
                                width: 'calc(100% - 120px)',
                            }}
                            direction="column"
                            className="network-viz-legend"
                            overflow={'hidden'}
                            alignItems={'flex-start'}
                            px={2}
                        >
                            {legend.node && (
                                <Box maxHeight={'200px'} flexShrink={0}>
                                    <NetworkNodeLegend />
                                </Box>
                            )}
                            {legend.edge && (
                                <Box flexShrink={0} height="30px">
                                    <NetworkEdgeLegend />
                                </Box>
                            )}
                        </Stack>
                        <Box sx={{ width: '104px', marginTop: 'auto' }}>
                            <NetworkControlButtons
                                viewMode="preview"
                                onShowLabelsChange={onShowLabelsChange}
                                nodeRenders={context.nodeRenders}
                                nodeStyle={context.nodeStyle}
                            />
                        </Box>
                    </Stack>
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}
