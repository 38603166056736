//* ======= Libraries
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
//* ======= Components and features
import ImageUploadCropper from 'components/ImageUploadCropper/ImageUploadCropper'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import TemplateCard from 'components/template-card/TemplateCard'
//* ======= Custom logic
import { CreateProject, CreateProjectRequestType } from 'services/ProjectService'
//* ======= Assets and styles
import { EMAIL_REGEX } from 'helpers/validators'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import SettingsColorSelector from 'features/settings/SettingsColorSelector'
import { Box } from '@mui/material'
import ColorPicker from 'components/widgets/ColorPicker'

interface ICreateProjectDialog {
    onConfirm?: (id: number) => void
    onClose?: () => void
}

function CreateProjectDialog({ onConfirm, onClose }: ICreateProjectDialog) {
    const selectOptions = [
        {
            label: 'Fixed population',
            value: 'fixed',
        },
        {
            label: 'Snow Ball',
            value: 'snowball',
        },
    ]

    //form
    const {
        control,
        register,
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateProjectRequestType>()

    const createProject = () => {
        handleSubmit(
            //Valid
            (data) => {
                CreateProject(data)
                    .then((res) => {
                        if (res.success && onConfirm && res.data.primaryKey) {
                            onConfirm(res.data.primaryKey)
                        }
                    })
                    .catch((e) => {})
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    const setImage = (data: string) => {
        setValue('thumbnail', data)
    }

    return (
        <Grid container>
            {/* Sidebar
				========================================= */}
            <Grid
                item
                xs={3}
                zeroMinWidth
                container
                direction="column"
                alignItems="flex-start"
                rowGap={2}
                sx={(theme) => ({
                    padding: 3,

                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.common.ung_blue_one : theme.palette.common.bg_4,
                })}
            >
                <Typography fontSize={18} fontWeight={600} color="secondary">
                    Create new project
                </Typography>

                {/* Template card
				    ========================================= */}
                <TemplateCard
                    data={{
                        id: -1,
                        title: watch('title'),
                        image: watch('thumbnail'),
                    }}
                    hasMenuOnBoxed={false}
                />

                {/* Add profile image button
				    ========================================= */}
                <BaseButton
                    variant="outlined"
                    onClick={() => {
                        document.getElementById('profile-image-input')?.click()
                    }}
                >
                    Upload Image
                </BaseButton>

                <ImageUploadCropper inputId="profile-image-input" onCropComplete={setImage} circularCrop={false} />
            </Grid>

            {/* Main content
				========================================= */}
            <Grid
                item
                xs={9}
                zeroMinWidth
                container
                direction="column"
                alignItems="flex-start"
                rowGap={2}
                sx={(theme) => ({
                    padding: 3,

                    // @Theme conditional
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.common.bg_2 : theme.palette.common.bg_3,
                })}
            >
                <Grid item zeroMinWidth container columnGap={6} mb={1.5}>
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="title"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseFilledTextField
                                    label="Project Title"
                                    required
                                    variant="filled"
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{
                                required: 'Project Title is required',
                                minLength: { value: 3, message: 'The title should be at least 3 characters' },
                                maxLength: {
                                    value: 50,
                                    message: 'The should not be more than 50 characters',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="smsSenderId"
                            control={control}
                            defaultValue="SNAToolbox"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseFilledTextField
                                    label="SMS Sender ID"
                                    required
                                    variant="filled"
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{
                                required: 'SMS Sender ID is required',
                                minLength: { value: 3, message: 'The title should be at least 3 characters' },
                                maxLength: {
                                    value: 20,
                                    message: 'The should not be more than 20 characters',
                                },
                                pattern: {
                                    message: 'Letters only with no number, symbol, or space',
                                    value: /^[a-zA-Z]+$/,
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Project field
				    ========================================= */}
                <Grid item zeroMinWidth container columnGap={6} mb={1.5}>
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="category"
                            control={control}
                            defaultValue="fixed"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseSelectWithLabel
                                    label="Project Category"
                                    options={selectOptions}
                                    required
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="emailSenderId"
                            control={control}
                            defaultValue="admin@snatoolbox.com"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseFilledTextField
                                    label="Outgoing Email Address"
                                    required
                                    variant="filled"
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{
                                required: 'Outgoing Email Address is required',
                                pattern: {
                                    message: 'Not a valid email address',
                                    value: EMAIL_REGEX,
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Color Picker
				    ========================================= */}
                <Grid item zeroMinWidth container columnGap={6}>
                    <Grid item zeroMinWidth xs>
                        <Typography fontSize={16} fontWeight={400} color="secondary">
                            Project color theme
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item zeroMinWidth container columnGap={6}>
                    <Grid item zeroMinWidth xs>
                        <Box sx={{ maxWidth: '300px' }}>
                            <Controller
                                name="color"
                                control={control}
                                defaultValue="#2d77bc"
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <ColorPicker isPopover={true} value={value} onChange={onChange} />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>

                {/* Description and language
				    ========================================= */}
                <Grid item zeroMinWidth container columnGap={6}>
                    <Grid item zeroMinWidth xs>
                        <Typography fontSize={16} fontWeight={400} color="secondary">
                            Description
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item zeroMinWidth container columnGap={6}>
                    {/* Description
				        ========================================= */}
                    <Grid item zeroMinWidth xs>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <BaseFilledTextField
                                    hiddenLabel
                                    multiline
                                    rows={6}
                                    variant="filled"
                                    value={value}
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                {/* Confirm and close buttons
				    ========================================= */}
                <Stack alignSelf="flex-end" direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                    <BaseButton
                        variant="outlined"
                        size="large"
                        label="Close"
                        onClick={(e) => onClose && onClose()}
                        sx={{
                            width: 180,
                            py: 1.5,
                        }}
                    />

                    <BaseButton
                        variant="contained"
                        size="large"
                        label="Create"
                        onClick={() => createProject()}
                        sx={{
                            width: 180,
                            py: 1.5,
                        }}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default CreateProjectDialog
