//* ======= Libraries
import React, { useContext, useEffect, useState } from 'react'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
//* ======= Assets and styles
import { InputAdornment } from '@mui/material'

import { UpdateSurveySettingsGeneralService } from 'services/SurveyService'
import { useParams } from 'react-router-dom'
import { SurveyContext } from 'contexts/SurveyContex'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { toast } from 'react-toastify'

interface ISurveySettingsGeneral {}

function SurveySettingsGeneral({}: ISurveySettingsGeneral) {
    const { pid, sid } = useParams()
    const { survey, updateSurvey } = useContext(SurveyContext)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isPublic, setIsPublic] = useState<boolean>(false)
    const [isOpenParticipation, setIsOpenParticipation] = useState<boolean>(false)
    const [publicLink, setPublicLink] = useState<string>('')

    const isValid = () => !isPublic || publicLink.length > 3

    const saveChanges = () => {
        if (isValid() && pid != null && sid != null) {
            UpdateSurveySettingsGeneralService(
                pid,
                sid,
                {
                    publicAccess: isPublic,
                    publicLink: publicLink,
                    openParticipation: isOpenParticipation,
                },
                false
            ).then((res) => {
                if (res.success) {
                    updateSurvey({
                        publicAccess: isPublic,
                        publicAccessLink: publicLink,
                        openParticipation: isOpenParticipation,
                    })
                    toast.success('Survey settings updated successfully!')
                }
            })
        }
    }

    useEffect(() => {
        if (survey?.publicAccess === true) {
            setIsPublic(true)
        } else {
            setIsPublic(false)
        }
        setIsPublic(survey?.publicAccess ?? false)
        setIsOpenParticipation(survey?.openParticipation ?? false)
        setPublicLink(survey?.publicAccessLink || '')
        setTimeout(() => setIsLoading(false), 500)
    }, [survey])

    return (
        <Stack gap={3} height="100%">
            {/* DateTime picker and recurring switch
                ========================================= */}
            {isLoading ? (
                <SnaCircularLoading />
            ) : (
                <Stack gap={1}>
                    <FormGroup aria-label="is_open" row>
                        <FormControlLabel
                            control={<BaseSwitch color="primary" />}
                            label="Open Participation"
                            labelPlacement="start"
                            checked={isOpenParticipation}
                            onChange={(e) => setIsOpenParticipation((pv) => !pv)}
                            sx={{
                                gap: 1,

                                marginLeft: 0,

                                '& .MuiFormControlLabel-label': {
                                    fontSize: 14,
                                    fontWeight: 400,

                                    color: 'common.text_1',
                                },
                            }}
                        />
                    </FormGroup>

                    {isOpenParticipation && (
                        <>
                            <FormGroup aria-label="is_public" row>
                                <FormControlLabel
                                    control={<BaseSwitch color="primary" />}
                                    label="Is Public"
                                    labelPlacement="start"
                                    checked={isPublic}
                                    onChange={(e) => setIsPublic((pv) => !pv)}
                                    sx={{
                                        gap: 1,

                                        marginLeft: 0,

                                        '& .MuiFormControlLabel-label': {
                                            fontSize: 14,
                                            fontWeight: 400,

                                            color: 'common.text_1',
                                        },
                                    }}
                                />
                            </FormGroup>

                            <BaseFilledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {process.env.REACT_APP_BASE_URL}survey/
                                        </InputAdornment>
                                    ),
                                }}
                                value={publicLink}
                                onChange={(e) => setPublicLink(e.target.value)}
                                label="Link"
                                sx={{ width: '500px' }}
                            />
                        </>
                    )}
                    {/* Save button
                ========================================= */}
                    <BaseButton
                        label="Save"
                        disabled={!isValid()}
                        variant="contained"
                        onClick={saveChanges}
                        size="large"
                        sx={{
                            alignSelf: 'flex-start',
                            width: 186,
                            py: 1.75,
                        }}
                    />
                </Stack>
            )}
        </Stack>
    )
}

export default SurveySettingsGeneral
