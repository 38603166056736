import React, { useEffect, useMemo, useState } from 'react'
import { Typography, Stack, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { useNetworkVizContext, useNetworkVizDispatch } from '../../context/NetworkVizContext'
import { EdgeRenderType, EdgeType, NodeRenderType } from '../../types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import BaseButton from 'components/base/BaseButton'
import { toast } from 'react-toastify'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { Dictionary, groupBy } from 'lodash'

var lz = require('lz-string')

// Define the properties that this component expects
interface IntersectionDialogProps {
    onClose: () => void
}

const IntersectionDialog: React.FC<IntersectionDialogProps> = ({ onClose }) => {
    // Use the network visualization context
    const { edges, analyticsSettings, ...networkVizContext } = useNetworkVizContext()

    // Use the network visualization dispatch
    const dispatchContext = useNetworkVizDispatch()

    // Define the state for this component
    const [intersection, setIntersection] = useState<string[]>([])
    const [isRunning, setIsRunning] = useState<boolean>(false)
    const [relationName, setRelationName] = useState<string>('')

    const [network, setNetwork] = useState<Dictionary<EdgeType[]>>({})
    const [networkOptions, setNetworkOptions] = useState<string[]>([])

    useEffect(() => {
        const _edges = Object.values(edges)
        const networks = groupBy(_edges, analyticsSettings.groupBy)
        setNetworkOptions(Object.keys(networks))
        setNetwork(networks)
    }, [analyticsSettings.groupBy, edges])

    // Determine whether the "Run" button should be disabled
    const isRunDisabled = useMemo(() => intersection.length < 2, [intersection])

    // Handler for when the "Apply Advance Analytics" operation is performed
    const handleApplyAdvanceAnalytics = async () => {
        if (isRunDisabled) return

        type EdgeKey = string

        const getEdgeKey = (edge: EdgeType): EdgeKey => {
            return `${edge.source}->${edge.target}`
        }

        // Create a map to count occurrences of each edge
        const edgeCounts: Map<EdgeKey, number> = new Map()

        const networks: EdgeType[][] = intersection.map((networkName) => network[networkName])

        // Find the edges that exist in all networks
        const intersectionEdges: EdgeType[] = []

        // Loop through each network
        for (const network of networks) {
            // Create a set for the current network
            const currentNetworkSet: Map<EdgeKey, EdgeType> = new Map()

            // Loop through each edge in the network
            for (const edge of network) {
                const edgeKey = getEdgeKey(edge)

                // Add to the set for the current network
                currentNetworkSet.set(edgeKey, {
                    source: edge.source,
                    target: edge.target,
                    id: '-1',
                })
            }

            // Loop through the set and update counts in the global map
            for (const [edgeKey, edge] of currentNetworkSet) {
                const newCount = (edgeCounts.get(edgeKey) || 0) + 1
                edgeCounts.set(edgeKey, newCount)

                // Check if this edge appears in all networks
                if (newCount === networks.length) {
                    // Find the edge from one of the networks and add to result
                    intersectionEdges.push(edge)
                }
            }
        }

        // Create a worker to add the edges
        const worker = new WebWorker('workers/network/add-edges.js')

        const ToastCloseButton = ({ closeToast }: { closeToast?: () => void }) => (
            <BaseButton
                onClick={() => {
                    worker.terminate()
                    closeToast && closeToast()
                }}
                label="Cancel"
            />
        )

        const toastId = toast.loading('Calculating Network Analytics', {
            // add close button to toast
            closeButton: <ToastCloseButton />,
        })

        setIsRunning(true)
        try {
            const { nodeRenders, nodes, edgeStyle, edgeGroupBy, layoutKind, filters, nodeInteractionConfig } =
                networkVizContext
            const response = (await worker.run({
                nodeRenders,
                nodes,
                edges,
                edgeStyle,
                edgeGroupBy,
                newEdges: intersectionEdges,
                relationship: relationName,
                sourceField: 'source',
                targetField: 'target',
                layoutKind,
                filters,
                isDirected: nodeInteractionConfig.directed,
                analyticsSettings,
            })) as any

            dispatchContext({
                type: 'EDGE_ADD',
                payload: {
                    edges: response.edges,
                    edgeRenders: response.edgeRenders,
                    edgeLegend: response.edgeLegend,
                    edgeStyle: response.edgeStyle,
                    edgeDataSchema: response.edgeSchema,
                    nodeRenders: response.nodeRenders,
                    nodeAdjacentList: response.nodeAdjacentList,
                    filters: response.filters,
                    analyticsSettings: response.analyticsSettings,
                },
            })

            toast.update(toastId, {
                render: 'Successfully calculated the intersection network.',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        } catch {
            toast.update(toastId, {
                render: 'Failed to calculate the intersection network.',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        }
        setIsRunning(false)
        handleClose()
    }

    // Handler for when the "Close Dialog" operation is performed
    const handleClose = () => {
        setIntersection([])
        onClose()
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Typography variant="h6">A ∩ B</Typography>
                <Typography>Returns a new graph that contains the edges that exist in both A and B.</Typography>
                <BaseFilledTextField
                    label="Relationship Name"
                    value={relationName}
                    onChange={(e) => setRelationName(e.target.value)}
                    fullWidth
                    helperText="The name of the relationship between the nodes in the intersection network."
                />
                <Typography>Select network A and B and press the run button.</Typography>
                <Stack direction="row" gap={3} spacing={2} mt={3}>
                    <BaseSelectWithLabel
                        selectProps={{ multiple: true }}
                        value={intersection}
                        onChange={(e) => setIntersection(e)}
                        fullWidth
                        label="Networks"
                        options={networkOptions}
                    />
                </Stack>
            </DialogContent>

            <DialogActions>
                <BaseButton color="secondary" onClick={handleClose}>
                    Close
                </BaseButton>
                <BaseButton
                    variant="contained"
                    disabled={isRunDisabled || isRunning}
                    color="primary"
                    onClick={handleApplyAdvanceAnalytics}
                >
                    Run
                </BaseButton>
            </DialogActions>
        </React.Fragment>
    )
}

export default IntersectionDialog
