//* ======= Libraries
import React, { useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { PartialDeep } from 'type-fest'
import { Box } from '@mui/material'
//* ======= Components and features
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import usePreviousValue from 'hooks/usePreviousValue'
import {
    convertNodeAttributeToKeyString,
    getDataSourceData,
} from 'features/report-designer/helpers/reportDesigner.helper'
import {
    FilterWidgetType,
    ReportControllingWidgetType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles

type Props = {
    Id: ReportWidgetType['id']
    data: FilterWidgetType
    isActive: boolean
    fontSizeScaleFactor: number
    onReady: () => void
}

function FilterWidget({ Id, data, isActive, fontSizeScaleFactor, onReady }: Props) {
    const { viewMode, dataSources, activeSlideId, updateFilterWidgetContent, addWidgetFilter, removeWidgetFilter } =
        useReportStore((store) => ({
            viewMode: store.viewMode,
            dataSources: store.dataSources,
            activeSlideId: store.activeSlideId,
            updateFilterWidgetContent: store.updateFilterWidgetContent,
            addWidgetFilter: store.addWidgetFilter,
            removeWidgetFilter: store.removeWidgetFilter,
        }))

    const [fieldOptions, setFieldOptions] = useState<BaseSelectWithLabelOptionType>()
    const previousConnectedWidgets = usePreviousValue(data.connectedWidgets)

    useEffect(() => {
        const panelData = getDataSourceData(dataSources, data.selectedDataSource, [data.field])
        if (panelData === undefined) {
            setFieldOptions(undefined)
            return
        }

        setFieldOptions(
            uniq(panelData.map((x) => x[convertNodeAttributeToKeyString(data.field)]))
                .map((x) => ({
                    value: x,
                    label: x + '',
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
        )
    }, [data.selectedDataSource, data.field, dataSources])

    const updateWidget = (data: FilterWidgetType) => {
        if (activeSlideId === null) return

        updateFilterWidgetContent({
            slideId: activeSlideId,
            widgetId: Id,
            data: data,
            disableTracking: true,
        })
    }

    const onChange = (value: any) => {
        updateWidget({
            ...data,
            value: value,
        })

        updateControlledWidgetsFitler(value, data.connectedWidgets)
    }

    const updateControlledWidgetsFitler = (value: any, widgets: ReportControllingWidgetType['connectedWidgets']) => {
        if (activeSlideId === null) return

        for (let { id } of widgets) {
            // add the filters to the connected widgets
            // value can be an array or a single value, based on the isMultiple prop
            // if value is not set, set the filter to null
            addWidgetFilter({
                slideId: activeSlideId,
                widgetId: id,
                data: {
                    [Id.toString()]:
                        value === '' ||
                        value === null ||
                        value === undefined ||
                        (Array.isArray(value) && value.length === 0)
                            ? null
                            : [
                                  Array.isArray(value)
                                      ? {
                                            value: value,
                                            field: data.field,
                                            operator: 'containsAny',
                                        }
                                      : {
                                            value: value,
                                            field: data.field,
                                            operator: 'eq',
                                        },
                              ],
                },
            })
        }
    }

    //ToDo
    //on controlled Widgets change, update the widgets
    useEffect(() => {
        //remove the filter from de-selected widgets
        if (previousConnectedWidgets) {
            if (activeSlideId === null) return

            for (let { id } of previousConnectedWidgets) {
                if (data.connectedWidgets.some((x) => x.id === id) === false) {
                    removeWidgetFilter({
                        slideId: activeSlideId,
                        widgetId: Id,
                        filterWidgetId: id,
                    })
                }
            }
        }

        //add
        updateControlledWidgetsFitler(
            data.value,
            data.connectedWidgets.filter((x) => !previousConnectedWidgets?.includes(x))
        )
    }, [data.connectedWidgets.length])

    useEffect(() => {
        onReady()
    }, [])

    return (
        <Box
            sx={(theme) => ({
                height: '100%',
            })}
        >
            <BaseSelectWithLabel
                placeholder={data.label}
                options={fieldOptions}
                value={data.value ?? ''}
                onChange={onChange}
                disabled={isActive === false && viewMode !== 'preview'}
                hasClearButton={true}
                hiddenLabel
                fullWidth
                size="small"
                selectProps={{
                    multiple: data.isMultiple,
                    MenuProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                fontSize: data.fontSize * fontSizeScaleFactor,
                            },
                        },
                    },
                }}
                sx={{
                    '& .MuiInputBase-root': {
                        fontSize: data.fontSize * fontSizeScaleFactor,
                    },
                }}
            />
        </Box>
    )
}

export default FilterWidget
