//* ======= Libraries
import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import CustomCKEditor, { ClassicEditorType } from 'ckeditor5-custom-build'
import { Box } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
//* ======= Assets and styles
import './Editor.css'
import { fontFamilyOptions } from 'components/widgets/FontPicker'

type Props = {
    // INITIAL data for the editor. IT IS NOT the current, dynamic data.
    data?: string | null | undefined
    onReady?: (editor: ClassicEditorType) => void
    onChange?: (event: any, editor: ClassicEditorType) => void
    onFocus?: (event: any, editor: ClassicEditorType) => void
    onBlur?: (event: any, editor: ClassicEditorType) => void
    onError?: (
        error: Error,
        details: {
            phase: 'initialization' | 'runtime'
            willEditorRestart?: boolean
        }
    ) => void
    config?: any
    isReadOnly?: boolean
    canGrow?: boolean
    fontSize?: number
}

function EditorClassic({
    data,
    onReady,
    onChange,
    onFocus,
    onBlur,
    onError,
    isReadOnly,
    config,
    canGrow = false,
    fontSize,
}: Props) {
    const onEditorReady = (editor: ClassicEditorType) => {
        // // Access the CKEditor's container element
        // const editorContainer = editor.ui.view.editable.element!.parentElement
        // // Add the custom class name to the container element
        // editorContainer!.classList.add('EditorClassic')

        // const toolbarElement = editor.ui.view.toolbar.element

        // editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
        //     if (isReadOnly) {
        //         toolbarElement.style.display = 'none'
        //     } else {
        //         toolbarElement.style.display = 'flex'
        //     }
        // })

        onReady !== undefined && onReady(editor)
    }

    return (
        <Box
            sx={(theme) => ({
                height: canGrow ? 'auto' : '100%',
                minHeight: 0,
                maxHeight: canGrow ? undefined : '100%',
                fontSize: fontSize !== undefined ? fontSize : undefined,
            })}
            className="EditorClassic"
        >
            <CKEditor
                editor={CustomCKEditor.ClassicEditor}
                // INITIAL data for the editor. IT IS NOT the current, dynamic data.
                data={data}
                onReady={(editor) => onEditorReady(editor)}
                onChange={(event, editor) => onChange !== undefined && onChange(event, editor)}
                onFocus={(event, editor) => onFocus !== undefined && onFocus(event, editor)}
                onBlur={(event, editor) => onBlur !== undefined && onBlur(event, editor)}
                onError={(error, details) => onError !== undefined && onError(error, details)}
                config={{
                    fontFamily: {
                        options: fontFamilyOptions,
                        supportAllValues: false,
                    },
                    ...(config || {}),
                }}
                disabled={isReadOnly}
                id="custom-ck-editor-classic"
            />
        </Box>
    )
}

export default EditorClassic
