//* ======= Libraries
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
//* ======= Components and features
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import SignInLayout from 'pages/sign-in/SignInLayout'
//* ======= Custom logic
import { ResendEmailVerificationService, VerifyEmailService } from 'services/AuthenticationService'
import { RootContext } from 'contexts/RootContext'
//* ======= Assets and styles

function VerifyEmail() {
    const [codeArray, setcode] = useState<string[]>(['', '', '', '', '', ''])
    const { user } = useContext(RootContext)
    const navigat = useNavigate()

    const verifyEmail = () => {
        VerifyEmailService(code).then((res) => res.success && navigat('/complete-profile'))
    }

    const resendVerification = () => {
        ResendEmailVerificationService().then((res) => res.success && toast.success('Verification Email sent!'))
    }

    const code = useMemo(() => codeArray.join(''), [codeArray])

    useEffect(() => {
        user?.isEmailVerified && navigat('/dashboard')
    }, [user, navigat])

    return (
        <SignInLayout>
            {/* Main content
				========================================= */}
            <Stack gridRow={2} width="40%">
                {/* Title
				========================================= */}
                <Typography fontSize={36} fontWeight={400} color="common.text_1" mb={3}>
                    Email Verification
                </Typography>
                {/* Message
				========================================= */}
                <Typography fontSize={18} fontWeight={400} textAlign="justify" color="common.text_1">
                    We have sent code to your email:
                </Typography>
                <Typography fontSize={18} fontWeight={400} textAlign="justify" color="common.text_1" mb={3}>
                    {user?.email}
                </Typography>
                <Stack direction="row" gap={2}>
                    {codeArray.map((c, index) => (
                        <BaseFilledTextField
                            value={c}
                            type="text"
                            hiddenLabel
                            name={`verification-code-${index}`}
                            autoFocus={index === 0}
                            onKeyDown={(e) => {
                                //@ts-ignore
                                if (e.key === 'Backspace' && e.target.value === '' && index !== 0) {
                                    e.preventDefault()
                                    const nextSibling = document.querySelector(
                                        `input[name=verification-code-${index - 1}]`
                                    )
                                    if (nextSibling !== null) {
                                        //@ts-ignore
                                        nextSibling.focus()
                                    }
                                } else if ((e.key === 'v' || e.key === 'V') && e.ctrlKey) {
                                    e.preventDefault()
                                    const pasteValue = async () => {
                                        if (navigator.clipboard.readText === undefined) return
                                        const text = await navigator.clipboard.readText()
                                        if (text) {
                                            const lastIndex = Math.min(codeArray.length, index + text.length)
                                            const temp = [...codeArray]
                                            for (let i = index; i < lastIndex; i++) {
                                                temp[i] = text[i - index]
                                            }
                                            setcode(temp)
                                            const nextSibling = document.querySelector(
                                                `input[name=verification-code-${lastIndex}]`
                                            )
                                            if (nextSibling !== null) {
                                                //@ts-ignore
                                                nextSibling.focus()
                                            } else {
                                                window.blur()
                                            }
                                        }
                                    }
                                    pasteValue()
                                } else if (e.key.length === 1) {
                                    setcode((pv) => {
                                        const temp = [...pv]
                                        temp[index] = e.key
                                        return temp
                                    })

                                    //handle focusing next input

                                    //last input
                                    if (index + 1 === codeArray.length) {
                                        // e.target.blur();
                                    }
                                    //find next input and focus
                                    else {
                                        const nextSibling = document.querySelector(
                                            `input[name=verification-code-${index + 1}]`
                                        )
                                        if (nextSibling !== null) {
                                            //@ts-ignore
                                            nextSibling.focus()
                                        }
                                    }
                                }
                            }}
                            inputProps={{
                                maxLength: 1,
                                style: {
                                    textAlign: 'center',
                                },
                            }}
                        />
                    ))}
                </Stack>

                <Typography fontSize={14} fontWeight={400} textAlign="center" color="common.text_1" mb={3} mt={1}>
                    Didn't receive code? <BaseButton onClick={resendVerification}>Resend</BaseButton>
                </Typography>

                {/* Verify button
				========================================= */}
                <BaseButton
                    label="Verify"
                    onClick={verifyEmail}
                    disabled={!code || code.length < 6}
                    variant="contained"
                    sx={{
                        py: 2,
                    }}
                />
            </Stack>
        </SignInLayout>
    )
}

export default VerifyEmail
