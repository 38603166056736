import {
    Box,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    LinearProgressProps,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material'
import StyledDialog from 'components/dialog/StyledDialog'
import { SurveyInstanceBasicType } from 'services/SurveyService'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import BaseButton from 'components/base/BaseButton'
import { RefObject, createRef, useEffect, useRef, useState } from 'react'
import { Model } from 'survey-core'
import { SAMPLE_SURVEY_RESPONSE } from './SampleSurveyResponse'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { set } from 'lodash'

type SurveyVerificationStatus = {
    title: string
    accessToken: string
    status: 'Pending' | 'In progress' | 'Failed' | 'Passed'
    error?: string
    abortController: AbortController | null
}

type SurveyVerificationDialogProps = {
    surveys: SurveyInstanceBasicType[]
    isOpen: boolean
    onClose: () => void
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}

const SurveyVerificationDialog = ({ isOpen, surveys, onClose }: SurveyVerificationDialogProps) => {
    const [surveyStatuses, setSurveyStatuses] = useState<SurveyVerificationStatus[]>([])
    const [isRunning, setIsRunning] = useState(false)
    const [progress, setProgress] = useState(0)
    const [summaryMessage, setSummaryMessage] = useState<{
        message: string
        success: boolean
    } | null>(null) // Add this state at the beginning of your component

    const surveyRefs = useRef<Array<RefObject<HTMLLIElement>>>([])

    const updateSurveyStatus = (token: string, updatedValue: Partial<SurveyVerificationStatus>) => {
        if (!isOpen) return
        setSurveyStatuses((prevStatuses) => {
            const tmp = [...prevStatuses]
            const surveyIndex = tmp.findIndex((x) => x.accessToken === token)
            if (surveyIndex !== -1) {
                tmp[surveyIndex] = { ...tmp[surveyIndex], ...updatedValue }
            }
            return tmp
        })
    }

    useEffect(() => {
        if (isOpen) {
            surveyRefs.current = surveys.map((_, index) => surveyRefs.current[index] ?? createRef())

            // Initialize survey statuses as "Pending"
            const initialStatuses: SurveyVerificationStatus[] = surveys.map((survey) => ({
                title: `${survey.respondent.name}`,
                accessToken: survey.accessToken,
                status: 'Pending',
                abortController: null,
            }))
            setSurveyStatuses(initialStatuses)
            setIsRunning(false)
            setProgress(0)
            setSummaryMessage(null)
        }
    }, [surveys, isOpen])

    const verifySurvey = async (token: string) => {
        if (!isOpen) return // If the dialog is not open anymore, exit early

        const abortController = new AbortController()
        let error = 'Cannot fetch survey'
        try {
            updateSurveyStatus(token, {
                status: 'In progress',
                error: undefined,
                abortController,
            })
            // Verify fetch survey
            const apiUrl = `${process.env.REACT_APP_API_URL}/project/1/surveyInstance/${token}/test`
            const surveyResponse = await fetch(apiUrl, {
                signal: abortController.signal,
            })

            if (!isOpen) return // If the dialog is not open anymore, exit early

            if (surveyResponse.ok) {
                error = 'Cannot Create Survey Model'
                // Verify survey model
                const surveyData = await surveyResponse.json()
                new Model(surveyData.surveyJson)
                error = 'Cannot Submit Survey'

                // Verify Submit survey
                const submitUrl = `${process.env.REACT_APP_API_URL}/project/1/surveyInstance/${token}/submitTest`
                const submitResponse = await fetch(submitUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(SAMPLE_SURVEY_RESPONSE),
                    signal: abortController.signal,
                })
                if (!isOpen) return // If the dialog is not open anymore, exit early

                if (submitResponse.ok) {
                    updateSurveyStatus(token, {
                        status: 'Passed',
                        abortController: null,
                    })
                    return { success: true, token }
                }
            }
        } catch {}
        updateSurveyStatus(token, {
            status: 'Failed',
            error,
            abortController: null,
        })
        return { success: false, token }
    }

    const runTest = async () => {
        setSummaryMessage(null)
        setIsRunning(true)

        const batchSize = 10 // Change this to your desired batch size

        for (let i = 0; i < surveys.length; i += batchSize) {
            const batch = surveys.slice(i, i + batchSize)
            setProgress((i * 100) / surveys.length)
            // Scroll to the last item of the active batch
            const lastItemIndex = Math.min(i + batchSize - 1, surveys.length - 1)

            surveyRefs.current[lastItemIndex]?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            })

            await Promise.all(batch.map((survey) => verifySurvey(survey.accessToken)))

            if (!isOpen) return // If the dialog is not open anymore, exit early
        }

        setIsRunning(false)
    }

    useEffect(() => {
        if (!isRunning) generateSummaryMessage()
    }, [isRunning])

    // Generate a summary
    const generateSummaryMessage = () => {
        const untestedSurveys = surveyStatuses.filter(
            (survey) => survey.status === 'In progress' || survey.status === 'Pending'
        )
        if (untestedSurveys.length > 0) {
            setSummaryMessage(null)
            return
        }

        const failedSurveys = surveyStatuses.filter((survey) => survey.status === 'Failed')
        if (failedSurveys.length === 0) {
            setSummaryMessage({ message: 'All surveys have been verified successfully!', success: true })
        } else {
            setSummaryMessage({
                success: false,
                message: `${failedSurveys.length} survey${failedSurveys.length > 1 ? 's' : ''} failed: ${failedSurveys
                    .map((survey) => survey.title)
                    .join(', ')}`,
            })
        }
    }

    const closeDialog = () => {
        // cancel all pending requests
        // cancel all pending requests
        surveyStatuses.forEach((status) => {
            if (status.abortController) {
                status.abortController.abort()
            }
        })
        setSurveyStatuses([])
        onClose()
    }

    return (
        <StyledDialog open={isOpen} aria-labelledby="progress-dialog-title" fullWidth>
            <DialogTitle id="progress-dialog-title">
                Verifying Surveys
                {isRunning && <LinearProgressWithLabel value={progress} />}
                {summaryMessage && (
                    <Stack
                        sx={(theme) => ({
                            backgroundColor: summaryMessage.success
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                            color: '#ffffff',
                        })}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        py={2}
                        gap={2}
                    >
                        {summaryMessage.success ? (
                            <DoneAllIcon sx={{ fontSize: 24 }} />
                        ) : (
                            <ErrorIcon sx={{ fontSize: 24 }} />
                        )}
                        <Typography variant="body2">{summaryMessage.message}</Typography>
                    </Stack>
                )}
            </DialogTitle>
            <DialogContent className="u-scrollbar">
                <List>
                    {surveyStatuses.map((survey, index) => (
                        <ListItem key={survey.accessToken} ref={surveyRefs.current[index]}>
                            <ListItemText
                                primary={survey.title}
                                secondary={survey.status === 'Failed' ? survey.error : null}
                            />

                            {survey.status === 'Pending' && <HourglassTopIcon />}
                            {survey.status === 'In progress' && <CircularProgress size={20} />}
                            {survey.status === 'Passed' && <CheckCircleIcon style={{ color: 'green' }} />}
                            {survey.status === 'Failed' && <ErrorIcon style={{ color: 'red' }} />}
                            <BaseButton
                                isLoading={survey.status === 'In progress'}
                                disabled={isRunning}
                                onClick={async () => {
                                    setIsRunning(true)
                                    await verifySurvey(survey.accessToken)
                                    setIsRunning(false)
                                }}
                            >
                                Test Survey
                            </BaseButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={closeDialog}>Close</BaseButton>
                <BaseButton variant="contained" isLoading={isRunning} onClick={runTest} disabled={isRunning}>
                    Test All Surveys
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default SurveyVerificationDialog
