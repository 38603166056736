//* ======= Libraries
import React, { useMemo } from 'react'
import { EChartsCoreOption } from 'echarts'
import { uniq } from 'lodash'
import { alpha } from '@mui/material/styles'
//* ======= Components and features
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import BaseButton from 'components/base/BaseButton'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
//* ======= Custom logic
import { BasicNodeType, NodeType } from 'services/NodeService'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
import Chart from 'features/chart/Chart'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
//* ======= Assets and styles

interface INodeRelations {
    node: NodeType
    navigationHandlers: {
        toOverview: () => void
        toSurveys: () => void
        toActions: () => void
    }
}

function NodeRelations({ node, navigationHandlers }: INodeRelations) {
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue)
    }

    const categories: string[] = useMemo(() => {
        if (node?.network) {
            return uniq(node.network.map((n) => n.relation)).sort()
        }
        return []
    }, [node?.network])

    const relations: {
        primaryKey: number
        source: BasicNodeType
        target: BasicNodeType
    }[] = useMemo(() => {
        if (node?.network && categories && categories[selectedTabIndex]) {
            return node?.network.filter((n) => n.relation === categories[selectedTabIndex])
        }
        return []
    }, [node?.network, selectedTabIndex, categories])

    const network: EChartsCustomOptionType | null = useMemo(() => {
        if (relations) {
            const links: any[] = []
            const nodes: { [key: number]: any } = {
                [node.primaryKey]: {
                    id: node.primaryKey + '',
                    name: node.title,
                    symbolSize: 20,
                    label: { show: true },
                    itemStyle: {
                        color: '#8ac588',
                    },
                },
            }
            relations.forEach((rel) => {
                links.push({
                    source: rel.source.id + '',
                    target: rel.target.id + '',
                })
                if (!nodes[rel.source.id]) {
                    nodes[rel.source.id] = {
                        id: rel.source.id + '',
                        name: rel.source.title,
                        symbolSize: 15,
                    }
                }
                if (!nodes[rel.target.id]) {
                    nodes[rel.target.id] = {
                        id: rel.target.id + '',
                        name: rel.target.title,
                        symbolSize: 15,
                    }
                }
            })
            return {
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: { show: true },
                    },
                },
                animationDuration: 1500,
                animationEasingUpdate: 'quinticInOut',
                series: [
                    {
                        type: 'graph',
                        layout: 'force',
                        data: Object.values(nodes),
                        links: links,
                        roam: 'scale',
                        label: {
                            position: 'right',
                        },
                        force: {
                            repulsion: 100,
                        },
                        lineStyle: {
                            curveness: 0.3,
                        },
                        edgeSymbol: ['circle', 'arrow'],
                        edgeSymbolSize: [4, 10],
                        emphasis: {
                            focus: 'adjacency',
                            lineStyle: {
                                width: 3,
                            },
                        },
                    },
                ],
            }
        }
        return null
    }, [relations])

    return (
        <Grid container height="100%" paddingRight={1}>
            {/* Main content
                ========================================= */}
            <Grid item xs={12} height="100%">
                <Stack gap={1} height="100%">
                    {/* Top row
                        ========================================= */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{
                            py: 1,
                            px: 2,

                            backgroundColor: 'common.bg_1',
                        }}
                    >
                        {/* Panel selection buttons
                            ========================================= */}
                        <Stack direction="row" alignItems="center" gap={3}>
                            {/* Overview button
                                ========================================= */}
                            <BaseButton
                                label="Overview"
                                onClick={() => navigationHandlers.toOverview()}
                                sx={{
                                    width: 120,
                                    fontSize: 20,

                                    color: 'common.fill_2',
                                }}
                            />

                            {/* Surveys button
                                ========================================= */}
                            <BaseButton
                                label="Surveys"
                                onClick={() => navigationHandlers.toSurveys()}
                                sx={{
                                    width: 120,
                                    fontSize: 20,

                                    color: 'common.fill_2',
                                }}
                            />

                            {/* Relations button
                                ========================================= */}
                            <BaseButton
                                label="Relations"
                                sx={{
                                    width: 120,
                                    fontSize: 20,
                                }}
                            />
                            {/* Actions button
                                ========================================= */}
                            <BaseButton
                                label="Actions"
                                onClick={() => navigationHandlers.toActions()}
                                sx={{
                                    width: 120,
                                    fontSize: 20,

                                    color: 'common.fill_2',
                                }}
                            />
                        </Stack>

                        {/* Search
					        ========================================= */}
                        {/* <SearchField
                            options={['Something']}
                            placeholder="Search"
                            sx={{
                                marginLeft: 'auto',
                                marginRight: 3,
                            }}
                        /> */}

                        {/* Filter button
                            ========================================= */}
                        {/* <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <FilterListIcon />
                        </IconButton> */}

                        {/* ??? button
                            ========================================= */}
                        {/* <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <ViewColumnIcon />
                        </IconButton> */}

                        {/* Download button
                            ========================================= */}
                        {/* <IconButton
                            sx={{
                                borderRadius: '4px',
                                color: 'common.fill_2',
                            }}
                        >
                            <DownloadIcon />
                        </IconButton> */}
                    </Stack>

                    {/* Content
                        ========================================= */}
                    <Stack
                        // Enable if full-height effect is desired.
                        flexGrow={1}
                        gap={2}
                        sx={{
                            paddingBottom: 2,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {/* Graph and grid
                            ========================================= */}
                        <Stack
                            flexGrow={1}
                            gap={3}
                            sx={{
                                padding: 3,
                                paddingBottom: 4,

                                backgroundColor: 'common.bg_1',
                            }}
                        >
                            {/* Graph
                                ========================================= */}
                            <Box
                                sx={{
                                    height: 246,
                                }}
                            >
                                {network && <Chart type="graph" options={network} />}
                            </Box>

                            {/* Tabs and data grid
                                ========================================= */}
                            <Stack sx={{ flexGrow: 1 }}>
                                {/* Tabs
                                    ========================================= */}
                                <Box
                                    sx={{
                                        py: 0.5,
                                        px: 1,

                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        backgroundColor: 'primary.main',
                                    }}
                                >
                                    <Tabs
                                        value={selectedTabIndex}
                                        onChange={handleTabChange}
                                        aria-label="survey logs tabs"
                                        sx={(theme) => ({
                                            '& .MuiTabs-flexContainer': {
                                                gap: 2,
                                            },

                                            '& .MuiTab-root': {
                                                minWidth: 90,
                                                py: 0.5,
                                                px: 1.25,
                                                fontSize: 14,
                                                fontWeight: 600,

                                                borderRadius: '100rem',
                                                backgroundColor: alpha(theme.palette.common.bg_1, 0.2),
                                                color: 'common.text_4',

                                                '&.Mui-selected': {
                                                    backgroundColor: 'common.bg_1',
                                                    color: 'primary.main',
                                                },
                                            },

                                            '& .MuiTabs-indicator': {
                                                display: 'none',
                                            },
                                        })}
                                    >
                                        {categories.map((c, idx) => (
                                            <Tab key={idx} label={c} id={`relations-tab-${idx}`} />
                                        ))}
                                    </Tabs>
                                </Box>

                                {/* Data grid
                                    ========================================= */}
                                <Box
                                    sx={(theme) => ({
                                        padding: theme.spacing(1.5, 1),
                                        flexGrow: 1,
                                        border: `1px solid ${theme.palette.common.border_2}`,
                                        borderTop: 0,
                                    })}
                                >
                                    <StyledDataGrid
                                        rows={relations}
                                        getRowId={(row) => row.primaryKey}
                                        sx={{ flexGrow: 1 }}
                                        columns={[
                                            {
                                                field: 'primaryKey',
                                                headerName: 'ID',
                                            },
                                            {
                                                field: 'source',
                                                headerName: 'Source',
                                                renderCell: (params) => {
                                                    return (
                                                        <Stack direction="row" gap={1}>
                                                            <Avatar src={params?.value?.avatar}>
                                                                {buildAbbreviation(params?.value?.title)}
                                                            </Avatar>
                                                            <Typography alignSelf="center">
                                                                {params?.value?.title}
                                                            </Typography>
                                                        </Stack>
                                                    )
                                                },
                                            },
                                            {
                                                field: 'target',
                                                headerName: 'Target',
                                                renderCell: (params) => {
                                                    return (
                                                        <Stack direction="row" gap={1}>
                                                            <Avatar src={params?.value?.avatar}>
                                                                {buildAbbreviation(params?.value?.title)}
                                                            </Avatar>
                                                            <Typography alignSelf="center">
                                                                {params?.value?.title}
                                                            </Typography>
                                                        </Stack>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                </Box>
                            </Stack>
                        </Stack>

                        {/* Footer buttons
                            ========================================= */}
                        {false && (
                            <Stack
                                flexShrink={0}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                gap={3}
                                sx={{
                                    marginTop: 'auto',
                                }}
                            >
                                {/* Reminder button
                                ========================================= */}
                                <BaseButton
                                    label="Reminder"
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        width: 180,
                                    }}
                                />

                                {/* Contact button
                                ========================================= */}
                                <BaseButton
                                    label="Contact"
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        width: 180,
                                    }}
                                />

                                {/* Withdraw button
                                ========================================= */}
                                <BaseButton
                                    label="Withdraw"
                                    variant="contained"
                                    size="large"
                                    color="warning"
                                    sx={{
                                        width: 180,
                                    }}
                                />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default NodeRelations
