import React, { useState, useEffect } from 'react'
import { TextFieldProps } from '@mui/material/TextField'
import BaseFilledTextField from './BaseFilledTextField'

// Assuming StyledTextField is your customized version of MUI TextField
type EditableTextFieldProps = Omit<TextFieldProps, 'value' | 'onChange' | 'onBlur'> & {
    value: string | number | null | undefined
    onChange?: (value: string | undefined) => void
    onBlur?: (value: string | undefined) => void
}

const EditableTextField: React.FC<EditableTextFieldProps> = ({ value, onChange, onBlur, ...otherProps }) => {
    const [inputValue, setInputValue] = useState(value?.toString())

    // Update input value state when the value prop changes externally
    useEffect(() => {
        setInputValue(value?.toString())
    }, [value])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
        onChange?.(event.target.value)
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(inputValue?.trim())
    }

    return <BaseFilledTextField {...otherProps} value={inputValue} onChange={handleChange} onBlur={handleBlur} />
}

export default EditableTextField
