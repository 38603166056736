//* ======= Libraries
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import DialogContent from '@mui/material/DialogContent'
import InputBase from '@mui/material/InputBase'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles
import { GetQuestionsService, QuestionBankType } from 'services/QuestionBankApi'
import { Card, CardContent, CardHeader, DialogActions } from '@mui/material'

const StyledSelectInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        width: '100%',
        padding: theme.spacing(1.5, 3),
        fontSize: 16,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        borderRadius: 5,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.common.bg_3,
        color: theme.palette.primary.main,

        '&:focus': {},
    },
}))

interface IQuestionBankDialog {
    onConfirm: (question: QuestionBankType) => void
    onClose: () => void
}

/**
 * TODO:
 * 	1.	We may want to show an Accordion component for the Preview section items.
 */

function QuestionBankDialog({ onClose, onConfirm }: IQuestionBankDialog) {
    const [questions, setQuestions] = useState<QuestionBankType[]>([])

    const [category, setCategory] = useState<string>()

    const [selectedQuestion, setSelectedQuestion] = useState<QuestionBankType | null>(null)

    useEffect(() => {
        GetQuestionsService(category).then((res) => res.success && setQuestions(res.data))
    }, [category])

    const listItems = [
        {
            label: 'All Categories',
            isSelected: true,
        },
        {
            label: 'Community',
            isSelected: false,
        },
        {
            label: 'Customer Feedback',
            isSelected: false,
        },
        {
            label: 'Customer Satisfaction',
            isSelected: false,
        },
        {
            label: 'Demographics',
            isSelected: false,
        },
        {
            label: 'Education',
            isSelected: false,
        },
        {
            label: 'Events',
            isSelected: false,
        },
        {
            label: 'Healthcare',
            isSelected: false,
        },
    ]

    const searchFieldOptions = ['Grumpy user', 'Happy user', 'Lazy user']

    const selectOptions = [
        {
            name: 'None',
            value: '',
        },
        {
            name: 'One',
            value: 1,
        },
        {
            name: 'Two',
            value: 2,
        },
        {
            name: 'Long Three',
            value: 3,
        },
    ]

    return (
        <React.Fragment>
            <DialogContent>
                <Grid
                    container
                    height={600}
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 2,

                        backgroundColor: 'common.bg_2',
                    }}
                >
                    {/* Sidebar
				========================================= */}
                    <Grid
                        item
                        xs={3}
                        px={3}
                        sx={(theme) => ({
                            height: '100%',
                            borderRight: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* List of options
					========================================= */}
                        <List
                            dense
                            disablePadding
                            sx={(theme) => ({
                                '& .MuiListItem-root': {
                                    '&:not(:last-of-type)': {
                                        marginBottom: 0.5,
                                    },

                                    '& .MuiListItemButton-root': {
                                        paddingLeft: 1,
                                    },
                                },
                            })}
                        >
                            {listItems.map((_option, idx) => (
                                <ListItem key={idx} disableGutters disablePadding>
                                    <ListItemButton dense disableGutters>
                                        <ListItemText
                                            primary={_option.label}
                                            primaryTypographyProps={{
                                                fontSize: 18,
                                                fontWeight: _option.isSelected ? 600 : 400,

                                                color: _option.isSelected ? 'primary.main' : 'secondary.main',
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    {/* Main content
				    ========================================= */}
                    <Grid
                        item
                        xs={9}
                        px={3}
                        sx={{
                            height: '100%',
                            overflow: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <Stack height="100%" gap={2}>
                            {/* Search and filters
						========================================= */}
                            <Stack direction="row" alignItems="center" gap={1}>
                                {/* Search
							========================================= */}
                                {/* <SearchField
                            freeSolo
                            options={searchFieldOptions}
                            onChange={(evt, value) => value && console.log(value)}
                            placeholder="Search"
                            width="265px"
                        /> */}

                                {/* Category select
							========================================= */}
                                {/* <Select
                            id="category-select"
                            defaultValue={''}
                            displayEmpty
                            input={<StyledSelectInput />}
                            IconComponent={ExpandMoreIcon}
                            sx={{
                                width: '120px',
                                marginLeft: 'auto',

                                '& .MuiSelect-icon': {
                                    color: 'primary.main',
                                },
                            }}
                        >
                            {selectOptions.map((_option, idx) => (
                                <MenuItem key={_option.name + idx} value={_option.value || ''}>
                                    {_option.name}
                                </MenuItem>
                            ))}
                        </Select> */}

                                {/* Tags select
							========================================= */}
                                {/* <Select
                            id="tags-select"
                            defaultValue={''}
                            displayEmpty
                            input={<StyledSelectInput />}
                            IconComponent={ExpandMoreIcon}
                            sx={{
                                width: '110px',

                                '& .MuiSelect-icon': {
                                    color: 'primary.main',
                                },
                            }}
                        >
                            {selectOptions.map((_option, idx) => (
                                <MenuItem key={_option.name + idx} value={_option.value || ''}>
                                    {_option.name}
                                </MenuItem>
                            ))}
                        </Select> */}
                            </Stack>

                            {/* Items grid
						========================================= */}
                            <Grid container spacing={2}>
                                {questions.map((q, idx) => (
                                    <Grid item xs={6}>
                                        <Card
                                            onClick={() => setSelectedQuestion(q)}
                                            key={idx}
                                            sx={{
                                                border:
                                                    selectedQuestion?.primaryKey == q.primaryKey ? '1px solid' : 'none',
                                                borderColor: 'common.border_1',
                                                height: '200px',
                                                backgroundColor: 'common.bg_1',
                                            }}
                                        >
                                            <CardHeader title={q.name} subheader={q.createdBy?.name} />

                                            <CardContent sx={{ overflow: 'auto' }}>
                                                <Typography>{q.description}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </Grid>

                    {/* Preview sidebar
				========================================= */}
                    {/* <Grid
                item
                xs={3}
                sx={(theme) => ({
                    height: '100%',
                    paddingLeft: 2,
                    paddingRight: 1,

                    borderLeft: `1px solid ${theme.palette.common.border_2}`,
                })}
            >
                <Stack gap={2} height="100%"> */}
                    {/* Preview slides
						========================================= */}
                    {/* <Stack
                        flexGrow={1}
                        sx={{
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <Typography fontSize={30} fontWeight={600} color="primary">
                            Preview
                        </Typography> */}

                    {/* Slides
							========================================= */}
                    {/* {Array.from(Array(3)).map((_, idx) => (
                            <Stack key={idx} gap={1} marginBottom={1}> */}
                    {/* Page count
									========================================= */}
                    {/* <Stack direction="row" alignItems="center" gap={0.5}>
                                    <Typography fontSize={14} fontWeight={400} color="common.text_3">
                                        {'page ' + (idx + 1)}
                                    </Typography>

                                    <Box
                                        flexGrow={1}
                                        height="1px"
                                        sx={{
                                            backgroundColor: 'common.bg_5',
                                        }}
                                    ></Box>

                                    <ExpandMoreIcon
                                        sx={{
                                            height: '18px',
                                            width: '18px',
                                            color: 'common.fill_2',
                                        }}
                                    />
                                </Stack> */}

                    {/* (Iterable) Slide
									========================================= */}
                    {/* <Box
                                    sx={{
                                        height: '180px',

                                        borderRadius: '5px',
                                        backgroundColor: 'common.bg_1',
                                    }}
                                ></Box>
                            </Stack>
                        ))}
                    </Stack> */}

                    {/* Add button
						========================================= */}
                    {/* <BaseButton
                        label="Add (X) Questions"
                        variant="contained"
                        size="large"
                        startIcon={<AddIcon />}
                        sx={{
                            py: 1.5,
                            fontWeight: 400,
                        }}
                    />
                </Stack>
            </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <BaseButton variant="outlined" color="secondary" onClick={onClose}>
                    Close
                </BaseButton>
                <BaseButton
                    variant="contained"
                    disabled={selectedQuestion == null}
                    onClick={() => selectedQuestion && onConfirm(selectedQuestion)}
                >
                    Insert
                </BaseButton>
            </DialogActions>
        </React.Fragment>
    )
}

export default QuestionBankDialog
