import { Stack, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import ValueSliderPicker from 'components/widgets/ValueSliderPicker'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { useState } from 'react'
import { LayoutSettingsType } from '../../../types/NetworkViz.types'

type NetworkForceAtlasSettingsProps = {
    settings: LayoutSettingsType['force-atlas']
    saveSettings: (settings: LayoutSettingsType['force-atlas']) => void
}

function NetworkForceAtlasSettings({ settings, saveSettings }: NetworkForceAtlasSettingsProps) {
    const [_settings, setSettings] = useState(settings)

    const updateSettings = (filed: keyof LayoutSettingsType['force-atlas']) => (value: number) => {
        setSettings(pv => ({ ...pv, [filed]: value }))
    }


    const toggleSettings = (filed: 'strongGravityMode' | 'outboundAttractionDistribution' | 'linLogMode' | 'barnesHutOptimize' | 'adjustSize') => () => {
        setSettings(pv => ({ ...pv, [filed]: !pv[filed] }))
    }

    return (
        <Stack gap={2}>
            <StyledSwitch label='Adjust Sizes' checked={_settings.adjustSize} onChange={toggleSettings('adjustSize')} />

            <StyledSwitch label='Barnes-Hut Optimize' checked={_settings.barnesHutOptimize} onChange={toggleSettings('barnesHutOptimize')} />
            {_settings.barnesHutOptimize &&
                <Stack>

                    <Typography>Attraction</Typography>
                    <ValueSliderPicker
                        min={0}
                        max={1}
                        step={0.001}
                        value={_settings.barnesHutTheta}
                        onChange={updateSettings('barnesHutTheta')}
                    />
                </Stack>
            }

            <StyledSwitch label='Ling-Long Mode' checked={_settings.linLogMode} onChange={toggleSettings('linLogMode')} />
            <StyledSwitch label='Outbound Attraction Distribution' checked={_settings.outboundAttractionDistribution} onChange={toggleSettings('outboundAttractionDistribution')} />
            <StyledSwitch label='Strong Gravity Mode' checked={_settings.strongGravityMode} onChange={toggleSettings('strongGravityMode')} />
            <Stack>
                <Typography>Gravity</Typography>
                <ValueSliderPicker
                    min={0}
                    max={100}
                    step={0.1}
                    value={_settings.gravity}
                    onChange={updateSettings('gravity')}
                />
            </Stack>


            <Stack>
                <Typography>Max Iteration</Typography>
                <ValueSliderPicker
                    min={20}
                    max={100}
                    step={10}
                    value={_settings.maxIteration}
                    onChange={updateSettings('maxIteration')}
                />
            </Stack>

            <BaseButton
                label="Apply"
                onClick={(evt) => saveSettings(_settings)}
                variant="outlined"
            />
        </Stack>
    )
}

export default NetworkForceAtlasSettings