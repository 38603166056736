import { NetworkVizContextType } from './NetworkVizContext'
import {
    NodeType,
    NodeStyleType,
    EdgeType,
    EdgeArrowType,
    EdgeStyleType,
    LayoutSettingsType,
    LayoutKindType,
    FilterItemType,
    TimePanelType,
    NodeRenderType,
    EdgeRenderType,
    DataSchemaType,
    NodeInteractionConfigType,
    NetworkVizKindType,
    NodeIdType,
    NodeInfoConfigType,
    NetworkStatusKindType,
    NodeAdjacentListType,
    GraphActionType,
    EdgeGroupStyleType,
    NodeAttributeType,
    NetworkShrinkType,
    NodeGroupInfoType,
    EdgeIdType,
    NetworkKeyFrameType as NetworkKeyFrameType,
} from '../types/NetworkViz.types'
import { GraphLegendType } from 'features/GraphStudio/types'
import { isEqual } from 'lodash'

export type NetworkVizContextActionType =
    /*
     * Load Network
     * ========================================= */
    | {
          type: 'LOAD_NETWORK'
          payload: Partial<NetworkVizContextType>
      }
    /*
    /*
    /*
     * Node actions
     * ========================================= */
    | {
          type: 'NODE_ADD'
          payload: {
              nodes: Record<NodeIdType, NodeType>
              nodeRenders: NodeRenderType[]
              nodeDataSchema: DataSchemaType
              nodeInfoConfig: NodeInfoConfigType
              nodeStyle: NodeStyleType
              nodeLegend: GraphLegendType['nodes']
          }
      }
    | {
          type: 'NODE_UPDATE'
          payload: {
              nodes: Record<NodeIdType, NodeType>
              nodeRenders: NodeRenderType[]
              nodeDataSchema: DataSchemaType
              nodeInfoConfig: NodeInfoConfigType
              nodeLegend: GraphLegendType['nodes']
              edgeRenders: EdgeRenderType[]
              edges: Record<EdgeIdType, EdgeType>
              edgeDataSchema: DataSchemaType
              edgeLegend: GraphLegendType['edges']
          }
      }
    | {
          type: 'NODE_GROUP_BY_EDIT'
          payload: {
              nodeRenders: NodeRenderType[]
              nodeStyle: NodeStyleType
              nodeGroupBy: NodeAttributeType[]
              nodeGroupInfo: NodeGroupInfoType
              nodeLegend: GraphLegendType['nodes']
          }
      }
    | { type: 'NODE_STYLE_EDIT'; payload: { nodeStyle: NodeStyleType; nodeRenders: NodeRenderType[] } }
    | { type: 'NODE_RENDER_EDIT'; payload: { nodeRenders: NodeRenderType[] } }
    | { type: 'NODE_INTERACTION_EDIT'; payload: { nodeInteractionConfig: NodeInteractionConfigType } }
    | { type: 'NODE_INFO_CONFIG_EDIT'; payload: NodeInfoConfigType }
    | {
          type: 'NODE_SELECT_HIGHLIGHT_CHANGE'
          payload: { nodeRenders: NodeRenderType[]; edgeRenders: EdgeRenderType[] }
      }
    | { type: 'NODE_ADJACENCY_EDIT'; payload: { nodeAdjacentList: NodeAdjacentListType } }
    | { type: 'NODE_POSITION_UPDATE'; payload: { id: NodeIdType; x: number; y: number } }

    /*
    /*
     * Edges actions
     * ========================================= */
    | {
          type: 'EDGE_ADD'
          payload: {
              edges: NetworkVizContextType['edges']
              edgeRenders: EdgeRenderType[]
              edgeDataSchema: DataSchemaType
              edgeStyle: EdgeStyleType
              edgeLegend: GraphLegendType['edges']
              nodeRenders: NodeRenderType[]
              nodeAdjacentList: NodeAdjacentListType
              filters: FilterItemType[]
              analyticsSettings: NetworkVizContextType['analyticsSettings']
          }
      }
    | {
          type: 'EDGE_REMOVE'
          payload: {
              edges: NetworkVizContextType['edges']
              edgeRenders: EdgeRenderType[]
              edgeDataSchema: DataSchemaType
              edgeStyle: EdgeStyleType
              edgeLegend: GraphLegendType['edges']
              nodeRenders: NodeRenderType[]
              nodeAdjacentList: NodeAdjacentListType
              filters: FilterItemType[]
              analyticsSettings: NetworkVizContextType['analyticsSettings']
          }
      }
    | {
          type: 'EDGE_GROUP_BY_EDIT'
          payload: {
              edgeRenders: EdgeRenderType[]
              edgeStyle: EdgeStyleType
              edgeGroupBy: string | null
              edgeLegend: GraphLegendType['edges']
          }
      }
    | { type: 'EDGE_STYLE_EDIT'; payload: { edgeStyle: EdgeStyleType; edgeRenders: EdgeRenderType[] } }
    | { type: 'EDGE_STYLE_VISSIBLE_TOGGLE'; payload: { name: string } }
    | {
          type: 'EDGE_RELATION_TYPE_EDIT'
          payload: { group: string; relationType: EdgeGroupStyleType['relationType'] }
      }
    | { type: 'EDGE_ARROW_SETTINGS_EDIT'; payload: EdgeArrowType }
    | { type: 'EDGE_ONLY_SHOW_RECIPOCATED_UPDATE'; payload: boolean }
    | { type: 'EDGE_HIGHLIGHT_RECIPOCATED_UPDATE'; payload: boolean }
    | { type: 'EDGE_DIM_ONE_WAY_UPDATE'; payload: boolean }
    /*
    /*
     * Layout actions
     * ========================================= */
    | { type: 'LAYOUT_KIND_EDIT'; payload: { layoutKind: LayoutKindType; nodeRenders: NodeRenderType[] } }
    | {
          type: 'LAYOUT_ALGORITHM_SETTINGS_EDIT'
          payload: {
              layoutKind: LayoutKindType
              layoutSetting: Partial<LayoutSettingsType>
              nodeRenders: NodeRenderType[]
          }
      }
    //ToDo add layout setting update
    /*
    /*
     * Filter actions
     * ========================================= */
    | {
          type: 'FILTER_CHANGE'
          payload: {
              filters?: FilterItemType[]
              nodeAdjacentList?: NodeAdjacentListType
              nodeRenders: NodeRenderType[]
              edgeRenders: EdgeRenderType[]
              analytics?: NetworkVizContextType['analytics']
          }
      }
    /*
    /*
     * time panel actions
     * ========================================= */
    | {
          type: 'TIME_PANEL_EDIT'
          payload: TimePanelType
      }
    /*
    /*
     * presets actions
     * ========================================= */
    | {
          type: 'PRESET_CREATE_OR_EDIT'
          payload: { key: string; state: string }
      }
    | {
          type: 'PRESET_DELETE'
          payload: string
      }
    | {
          type: 'PRESET_LOAD'
          payload: Partial<NetworkVizContextType>
      }
    /*
    /*
     * keyframes actions
     * ========================================= */
    | {
          type: 'KEYFRAME_CREATE_OR_EDIT'
          payload: { id: string | null; keyframe: NetworkKeyFrameType }
      }
    | {
          type: 'KEYFRAME_DELETE'
          payload: string
      }
    | {
          type: 'KEYFRAME_LOAD'
          payload: NetworkKeyFrameType
      }
    | {
          type: 'KEYFRAME_MOVE'
          payload: {
              id: string
              index: number
          }
      }
    /*
    /*
     * ANALYTICS
     * ========================================= */
    | {
          type: 'ANALYTICS_UPDATE'
          payload: NetworkVizContextType['analytics']
      }
    | {
          type: 'ANALYTICS_GRAPH_EDIT'
          payload: Record<string, number>
      }
    | {
          type: 'ANALYTICS_NETWORK_MANIPULATION'
          payload: {
              nodeRenders?: NodeRenderType[]
              edgeRenders: EdgeRenderType[]
              //   analytics: NetworkVizContextType['analytics']
              edgeGroupBy?: null
              selectedNode: null
              filters?: []
              //   edgeDataSchema: DataSchemaType
              //   nodeDatSchema?: DataSchemaType
          }
      }
    | {
          type: 'ANALYTICS_SETTINGS_EDIT'
          payload: Partial<NetworkVizContextType['analyticsSettings']>
      }
    | { type: 'STATISTICAL_TEST_UPDATE'; payload: NetworkVizContextType['statisticalTests'] }
    /* *
    /* *
     * Network Viz Kind
     * ========================================= */
    | {
          type: 'NETWORK_VIZ_KIND'
          payload: NetworkVizKindType
      }
    /*
    /*
     * Others
     * ========================================= */
    | {
          type: 'STATUS_UPDATE'
          payload: NetworkStatusKindType
      }
    | {
          type: 'ACTION'
          payload: GraphActionType['content']
      }
    | {
          type: 'TOGGLE_NETWORK_SHRINK'
          payload: Pick<NetworkVizContextType, 'nodeRenders' | 'edgeRenders'>
      }
    | {
          type: 'EXPAND_COLLAPSE_GROUP'
          payload: {
              nodeGroupInfo: NodeGroupInfoType
              nodeRenders: NodeRenderType[]
              edgeRenders: EdgeRenderType[]
          }
      }
    | {
          type: 'UPDATE_NETWORK_SHRINK'
          payload: Omit<NetworkShrinkType, 'enabled'>
      }
    | { type: 'SELECTED_ITEM_CHANGE'; payload: NetworkVizContextType['selectedItem'] }
    | { type: 'LEVEL_UPDATE'; payload: NetworkVizContextType['level'] }

//? (Undo/Redo stack actions)

function networkVizContextReducer(
    state: NetworkVizContextType,
    action: NetworkVizContextActionType
): NetworkVizContextType {
    // "faulty" state actions
    if (state.status === 'faulty') {
        return state
    }
    // All other state status actions
    else {
        let newState: NetworkVizContextType = structuredClone(state)

        switch (action.type) {
            /*
             * Network Viz actions
             * ========================================= */
            case 'LOAD_NETWORK':
                return { ...newState, ...action.payload }

            /*
             * Edges actions
             * ========================================= */
            case 'EDGE_ADD':
            case 'EDGE_REMOVE':
            case 'EDGE_GROUP_BY_EDIT':
                const { edgeLegend, ...edgeAddRest } = action.payload
                return {
                    ...newState,
                    ...edgeAddRest,
                    legend: {
                        ...newState.legend,
                        edges: edgeLegend,
                    },
                }
            case 'EDGE_STYLE_EDIT':
                return { ...newState, ...action.payload }
            case 'EDGE_STYLE_VISSIBLE_TOGGLE': {
                const { name } = action.payload
                const edgeStyle = newState.edgeStyle
                edgeStyle[name].visible = !edgeStyle[name].visible
                return { ...newState, edgeStyle }
            }
            case 'EDGE_RELATION_TYPE_EDIT': {
                if (newState.edgeStyle[action.payload.group])
                    newState.edgeStyle[action.payload.group].relationType = action.payload.relationType
                return newState
            }
            case 'EDGE_ARROW_SETTINGS_EDIT':
                return { ...newState, edgeArrowType: action.payload }
            case 'EDGE_HIGHLIGHT_RECIPOCATED_UPDATE':
                return { ...newState, highlightReciprocatedEdges: action.payload }
            case 'EDGE_ONLY_SHOW_RECIPOCATED_UPDATE':
                return { ...newState, onlyShowReciprocatedEdges: action.payload }
            case 'EDGE_DIM_ONE_WAY_UPDATE':
                return { ...newState, dimOneWayEdges: action.payload }
            /*
             * Nodes actions
             * ========================================= */
            case 'NODE_ADD':
            case 'NODE_GROUP_BY_EDIT':
                const { nodeLegend, ...nodeAddRest } = action.payload
                return {
                    ...newState,
                    ...nodeAddRest,
                    legend: {
                        ...newState.legend,
                        nodes: nodeLegend,
                    },
                }
            case 'NODE_UPDATE': {
                const { nodeLegend, edgeLegend, ...nodeAddRest } = action.payload
                return {
                    ...newState,
                    ...nodeAddRest,
                    legend: {
                        edges: edgeLegend,
                        nodes: nodeLegend,
                    },
                }
            }
            case 'SELECTED_ITEM_CHANGE':
                return {
                    ...newState,
                    selectedItem: isEqual(action.payload, newState.selectedItem) ? null : action.payload,
                }
            case 'NODE_SELECT_HIGHLIGHT_CHANGE':
                return {
                    ...newState,
                    ...action.payload,
                }
            case 'NODE_INFO_CONFIG_EDIT':
                return { ...newState, nodeInfoConfig: { ...action.payload } }
            case 'NODE_STYLE_EDIT':
            case 'NODE_RENDER_EDIT':
            case 'NODE_INTERACTION_EDIT':
            case 'NODE_ADJACENCY_EDIT':
                return { ...newState, ...action.payload }
            case 'NODE_POSITION_UPDATE':
                const index = newState.nodeRenders.findIndex((x) => x.id === action.payload.id)
                if (index !== -1) {
                    const newNodeRenders = JSON.parse(JSON.stringify(newState.nodeRenders))
                    newNodeRenders[index] = {
                        ...newState.nodeRenders[index],
                        x: action.payload.x,
                        y: -action.payload.y,
                        fx: action.payload.x,
                        fy: action.payload.y,
                        attributes: {
                            ...newState.nodeRenders[index].attributes,
                            x: action.payload.x,
                            y: action.payload.y,
                        },
                    }
                    return { ...newState, nodeRenders: newNodeRenders }
                }
                return { ...newState }

            /*
             * Layout actions
             * ========================================= */
            case 'LAYOUT_KIND_EDIT':
                return {
                    ...state,
                    ...action.payload,
                }
            case 'LAYOUT_ALGORITHM_SETTINGS_EDIT':
                const { layoutSetting, ...layoutAlgorithemsRest } = action.payload
                return {
                    ...state,
                    ...layoutAlgorithemsRest,
                    layoutSettings: {
                        ...state.layoutSettings,
                        ...layoutSetting,
                    },
                }
            /*
             * preset actions
             * ========================================= */
            case 'PRESET_CREATE_OR_EDIT':
                return {
                    ...newState,
                    presets: { ...newState.presets, [action.payload.key]: action.payload.state },
                }
            case 'PRESET_LOAD':
                return {
                    ...newState,
                    ...action.payload,
                }
            case 'PRESET_DELETE':
                delete newState.presets[action.payload]
                return {
                    ...newState,
                }
            /*
             * Analytics actions
             * ========================================= */
            case 'ANALYTICS_UPDATE':
                return {
                    ...newState,
                    analytics: action.payload,
                }
            // ToDo fix this
            // case 'ANALYTICS_GRAPH_EDIT':
            // return {
            //     ...newState,
            //     analytics: {
            //         ...newState.analytics,
            //         graph: {
            //             ...newState.analytics.graph,
            //             ...action.payload,
            //         },
            //     },
            // }
            case 'ANALYTICS_NETWORK_MANIPULATION':
                return {
                    ...newState,
                    ...action.payload,
                }
            case 'ANALYTICS_SETTINGS_EDIT': {
                return {
                    ...newState,
                    analyticsSettings: {
                        ...newState.analyticsSettings,
                        ...action.payload,
                    },
                }
            }
            case 'STATISTICAL_TEST_UPDATE': {
                return {
                    ...newState,
                    statisticalTests: action.payload,
                }
            }

            /*
             * Keyframe actions
             * ========================================= */
            case 'KEYFRAME_CREATE_OR_EDIT':
                if (action.payload.id === null) {
                    return {
                        ...newState,
                        keyFrames: [...newState.keyFrames, action.payload.keyframe],
                    }
                } else {
                    return {
                        ...newState,
                        keyFrames: newState.keyFrames.map((kf) =>
                            kf.id === action.payload.id ? action.payload.keyframe : kf
                        ),
                    }
                }
            case 'KEYFRAME_DELETE':
                return {
                    ...newState,
                    keyFrames: newState.keyFrames.filter((kf) => kf.id !== action.payload),
                }
            case 'KEYFRAME_LOAD':
                return newState
            case 'KEYFRAME_MOVE':
                const { id, index: kfIndex } = action.payload
                const keyFrames = structuredClone(newState.keyFrames)
                const currentIndex = keyFrames.findIndex((kf) => kf.id === id)
                const currentKeyFrame = keyFrames[currentIndex]
                if (currentIndex !== -1) {
                    keyFrames.splice(currentIndex, 1)
                    keyFrames.splice(kfIndex, 0, currentKeyFrame)
                }
                return {
                    ...newState,
                    keyFrames,
                }

            /*
             * other actions
             * ========================================= */
            case 'NETWORK_VIZ_KIND':
                return {
                    ...newState,
                    networkVizKind: action.payload,
                }
            case 'LEVEL_UPDATE':
                return {
                    ...newState,
                    level: action.payload,
                    selectedItem: null,
                }
            case 'FILTER_CHANGE':
                const data = {
                    ...newState,
                    ...action.payload,
                }
                return data
            case 'STATUS_UPDATE':
                return {
                    ...newState,
                    status: action.payload,
                }
            case 'ACTION':
                return {
                    ...newState,
                    action: {
                        id: (newState.action?.id || 0) + 1,
                        content: action.payload,
                    },
                }
            case 'EXPAND_COLLAPSE_GROUP': {
                return {
                    ...newState,
                    ...action.payload,
                }
            }
            case 'TOGGLE_NETWORK_SHRINK': {
                const nodeGroupInfo = newState.nodeGroupInfo
                for (let group in nodeGroupInfo) {
                    nodeGroupInfo[group].collapsed = !newState.networkShrink.enabled
                }
                return {
                    ...newState,
                    networkShrink: {
                        ...newState.networkShrink,
                        enabled: !newState.networkShrink.enabled,
                    },
                    nodeGroupInfo,
                    ...action.payload,
                    selectedItem: null,
                    level: 'all',
                    action: {
                        id: (newState.action?.id || 0) + 1,
                        content: {
                            type: 'fit',
                        },
                    },
                }
            }
            case 'UPDATE_NETWORK_SHRINK': {
                return {
                    ...newState,
                    networkShrink: {
                        ...newState.networkShrink,
                        ...action.payload,
                    },
                }
            }

            default:
                return state
        }
    }
}

export default networkVizContextReducer
