import { Tab, Tabs, Box, Stack, Typography, Card, CardContent, CardActions, Tooltip, IconButton } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import BaseButton from 'components/base/BaseButton'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import {
    convertNodeAttributeToKeyString,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { InsightWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { debounce, groupBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { alpha } from '@mui/material'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import { GridCallbackDetails } from '@mui/x-data-grid-pro'
import { InsightItemType } from '../../InisghtWidgetInterventionAdvancedView'
import BackIcon from '@mui/icons-material/ArrowBack'
import { InsightInterventionTableColumnSelector } from '../../../InisghtWidgetInterventionView'
import ResetIcon from '@mui/icons-material/Refresh'
import CreateTaskDialog from '../../../CreateTaskDialog'

type InsightDetailsPanelType = {
    items: InsightItemType[string]['issues'][string]
    widgetId: ReportWidgetType['id']
    insightWidget: InsightWidgetType
    fontSize: number
    fontScaleFactor: number
    isActive: boolean
    id: string
    back: () => void
}

const InsightDetailsPanel = ({
    items,
    back,
    fontSize,
    fontScaleFactor,
    isActive,
    insightWidget,
    widgetId,
    id,
}: InsightDetailsPanelType) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [columnWidth, setColumnWidth] = useState<number>(insightWidget.interventionConfig.columnWidth)
    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])
    const [selectedActionTab, setSelectedActionTab] = useState<string | null>(null)

    const [showCreateTaskDialog, setShowCreateTaskDialog] = useState<{
        isOpen: boolean
        actionIndex: number | null
    }>({ isOpen: false, actionIndex: null })

    const activeInsight = useMemo(() => {
        if (items === undefined) return null
        return insightWidget.insights.find((x) => x.id === items.insightId) ?? null
    }, [insightWidget.insights, items])

    const { dataSources, updateInsightWidgetContent, activeSlideId, viewMode } = useReportStore((store) => ({
        dataSources: store.dataSources,
        updateInsightWidgetContent: store.updateInsightWidgetContent,
        activeSlideId: store.activeSlideId,
        viewMode: store.viewMode,
    }))

    const [isColumnConfigOpen, setIsColumnConfigOpen] = useState(false)

    useEffect(() => {
        const selectedInsightMessage = activeInsight?.message[items.condition]

        const columns = selectedInsightMessage?.columns
        if (columns === undefined) {
            setDataGridColumns([])
            setSortModel([])
            return
        }

        setDataGridColumns(
            columns
                .filter((x) => x.field !== null)
                .map((x) => ({
                    field: convertNodeAttributeToKeyString(x.field!),
                    headerName: x.label,
                    width: x.width ?? undefined,
                }))
        )
    }, [activeInsight, items])

    const showActionDescription = useMemo(
        () => insightWidget.interventionConfig.showActions || insightWidget.interventionConfig.showDescription,
        [insightWidget.interventionConfig.showActions, insightWidget.interventionConfig.showDescription]
    )

    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, insightWidget.selectedDataSource))
    }, [dataSources, insightWidget.selectedDataSource])

    const [dataGridColumns, setDataGridColumns] = useState<GridColDef[]>([])
    const [sortModel, setSortModel] = useState<GridSortModel>()

    const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
        // on the initial load, the sort model change will call with an empty model that can override the state sort model.
        // therefore, we added a check to only update the sort model when the model is not empty.
        if (model.length !== 0) {
            setSortModel(model)
            // update the sort column in the store when the view mode is design
            if (viewMode === 'design') {
                if (widgetId !== undefined && activeSlideId != null) {
                    const sortColumn = model[0]
                    updateInsightWidgetContent({
                        widgetId,
                        slideId: activeSlideId,
                        data: {
                            insights: insightWidget.insights.map((x) =>
                                x.id === id
                                    ? {
                                          ...x,
                                          message: {
                                              ...x.message,
                                              [items.condition]: {
                                                  ...x.message[items.condition],
                                                  columns: x.message[items.condition].columns.map((y) =>
                                                      y.field &&
                                                      convertNodeAttributeToKeyString(y.field) === sortColumn.field
                                                          ? { ...y, sort: sortColumn.sort }
                                                          : { ...y, sort: false }
                                                  ),
                                              },
                                          },
                                      }
                                    : x
                            ),
                        },
                    })
                }
            }
        }
    }

    useEffect(() => {
        if (dataGridColumns.length > 0) {
            const selectedInsightMessage = activeInsight?.message[items.condition]
            const columns = selectedInsightMessage?.columns
            if (columns === undefined) return
            const sortColumn = columns.find((x) => x.sort !== false)
            if (sortColumn && sortColumn.sort) {
                setSortModel([{ field: convertNodeAttributeToKeyString(sortColumn.field!), sort: sortColumn.sort }])
            }
        }
    }, [dataGridColumns])

    // Cleanup function to remove event listeners
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMoveVertical)
        document.removeEventListener('mouseup', handleMouseUp)
    }

    // Function to handle the vertical divider movement
    const handleMouseDownVertical = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        document.addEventListener('mousemove', handleMouseMoveVertical)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMoveVertical = useCallback((event: any) => {
        // Calculate the new position of the divider based on the cursor's position
        if (activeSlideId && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect()
            const newDividerXPosition = ((event.clientX - containerRect.left) / containerRect.width) * 100
            const width = Math.min(Math.max(newDividerXPosition, 0), 100)
            setColumnWidth(width)
            updateColumnWidth(width)
        }
    }, [])

    const updateColumnWidth = useCallback(
        debounce((width: number) => {
            if (activeSlideId === null || widgetId === undefined) return
            updateInsightWidgetContent({
                slideId: activeSlideId,
                widgetId,
                data: {
                    interventionConfig: {
                        columnWidth: width,
                    },
                },
            })
        }, 300), // Adjust the debounce delay as needed
        [activeSlideId, widgetId]
    )

    const groupedActionList = useMemo(() => {
        if (items === undefined) return null
        const _actionList = items.actions
        const groupedActions = groupBy(_actionList, 'category')
        setSelectedActionTab(Object.keys(groupedActions)?.[0] ?? null)
        return groupedActions
    }, [items])

    const resetColumnsWidth = () => {
        if (widgetId !== undefined && activeSlideId != null && viewMode === 'design') {
            updateInsightWidgetContent({
                widgetId,
                slideId: activeSlideId,
                data: {
                    insights: insightWidget.insights.map((x) =>
                        id.startsWith(x.id)
                            ? {
                                  ...x,
                                  message: {
                                      ...x.message,
                                      [items.condition]: {
                                          ...x.message[items.condition],
                                          columns: x.message[items.condition].columns.map((y) => ({
                                              ...y,
                                              width: undefined,
                                          })),
                                      },
                                  },
                              }
                            : x
                    ),
                },
            })
        }
    }

    return (
        <Stack direction="row" sx={{ height: '100%', pt: 2 }} ref={containerRef}>
            {items === undefined ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography>No data</Typography>
                    <BaseButton onClick={back} startIcon={<BackIcon />}>
                        Back
                    </BaseButton>
                </Box>
            ) : (
                <>
                    {/* Nodes Table */}
                    <Box
                        sx={{
                            width: showActionDescription ? `${columnWidth}%` : '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        className="impacted-table"
                    >
                        {insightWidget.interventionConfig.tableTitle.enabled && (
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: insightWidget.interventionConfig.tableTitle.fontWeight,
                                    fontSize: insightWidget.interventionConfig.tableTitle.fontSize * fontScaleFactor,
                                    fontFamily: insightWidget.interventionConfig.tableTitle.fontFamily,
                                    color: insightWidget.interventionConfig.tableTitle.color,
                                }}
                            >
                                {insightWidget.interventionConfig.tableTitle.text} ({items.nodes.length})
                            </Typography>
                        )}

                        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                            <StyledDataGrid
                                rows={items.nodes}
                                columns={dataGridColumns}
                                density="compact"
                                hideFooter
                                hasSearch={insightWidget.interventionConfig.showSearch}
                                hasColumnSelector={false}
                                hasFilter={false}
                                hasExport={insightWidget.interventionConfig.showExport}
                                sortModel={sortModel}
                                onSortModelChange={handleSortModelChange}
                                sortingOrder={['asc', 'desc']}
                                onColumnWidthChange={(params) => {
                                    if (widgetId !== undefined && activeSlideId != null && viewMode === 'design') {
                                        const newColumnWidth = params.width
                                        updateInsightWidgetContent({
                                            widgetId,
                                            slideId: activeSlideId,
                                            data: {
                                                insights: insightWidget.insights.map((x) =>
                                                    id.startsWith(x.id)
                                                        ? {
                                                              ...x,
                                                              message: {
                                                                  ...x.message,
                                                                  [items.condition]: {
                                                                      ...x.message[items.condition],
                                                                      columns: x.message[items.condition].columns.map(
                                                                          (y) =>
                                                                              y.field &&
                                                                              convertNodeAttributeToKeyString(
                                                                                  y.field
                                                                              ) === params.colDef.field
                                                                                  ? {
                                                                                        ...y,
                                                                                        width: newColumnWidth,
                                                                                    }
                                                                                  : y
                                                                      ),
                                                                  },
                                                              },
                                                          }
                                                        : x
                                                ),
                                            },
                                        })
                                    }
                                }}
                                rowHeight={fontSize * 3}
                                customToolbar={
                                    viewMode === 'design' ? (
                                        <>
                                            <Tooltip title="Configure Columns">
                                                <IconButton onClick={() => setIsColumnConfigOpen(true)}>
                                                    <DynamicIcon icon="settings" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Reset Columns Width">
                                                <IconButton onClick={() => resetColumnsWidth()}>
                                                    <ResetIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    ) : null
                                }
                                sx={{ fontSize: fontSize, fontFamily: insightWidget.fontStyles.fontFamily }}
                            />
                        </Box>
                    </Box>
                    {showActionDescription && (
                        <>
                            {/* Vertical divider */}
                            {isActive && (
                                <Box
                                    sx={(theme) => ({
                                        width: '4px',
                                        bgcolor: alpha(theme.palette.primary.dark, 0.5),
                                        cursor: 'col-resize !important',
                                        '&:hover': { bgcolor: theme.palette.primary.dark },
                                    })}
                                    onMouseDown={handleMouseDownVertical}
                                />
                            )}

                            {/* Description and actions*/}

                            <Stack sx={{ width: `${100 - columnWidth}%`, height: '100%' }} p={1} gap={5}>
                                {/* Description */}
                                {insightWidget.interventionConfig.showDescription &&
                                    items.description != null &&
                                    items.description!.length > 0 && (
                                        <Box sx={{ flexShrink: 0 }} className="u-scrollbar description">
                                            <Typography
                                                sx={{
                                                    fontWeight: insightWidget.interventionConfig.tableTitle.fontWeight,
                                                    fontSize:
                                                        insightWidget.interventionConfig.tableTitle.fontSize *
                                                        fontScaleFactor,
                                                    fontFamily: insightWidget.interventionConfig.tableTitle.fontFamily,
                                                    color: insightWidget.interventionConfig.tableTitle.color,
                                                }}
                                            >
                                                Issue
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: fontSize,
                                                    fontFamily: insightWidget.fontStyles.fontFamily,
                                                }}
                                            >
                                                {items.description}
                                            </Typography>
                                        </Box>
                                    )}

                                {/* Actions */}
                                {insightWidget.interventionConfig.showActions &&
                                    groupedActionList !== null &&
                                    Object.keys(groupedActionList).length > 0 && (
                                        <Stack
                                            gap={0}
                                            sx={{ overflowY: 'auto', flexGrow: 1 }}
                                            className="u-scrollbar actions"
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: insightWidget.interventionConfig.tableTitle.fontWeight,
                                                    fontSize:
                                                        insightWidget.interventionConfig.tableTitle.fontSize *
                                                        fontScaleFactor,
                                                    fontFamily: insightWidget.interventionConfig.tableTitle.fontFamily,
                                                    color: insightWidget.interventionConfig.tableTitle.color,
                                                }}
                                            >
                                                Actions
                                            </Typography>

                                            <Tabs
                                                value={selectedActionTab}
                                                onChange={(evt, value) => setSelectedActionTab(value)}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                sx={{ width: '100%', my: 2 }}
                                            >
                                                {Object.keys(groupedActionList).map((key) => (
                                                    <Tab
                                                        value={key}
                                                        label={key}
                                                        sx={{
                                                            fontWeight:
                                                                insightWidget.interventionConfig.tabTextStyle
                                                                    .fontWeight,
                                                            fontSize:
                                                                insightWidget.interventionConfig.tabTextStyle.fontSize *
                                                                fontScaleFactor,
                                                            fontFamily:
                                                                insightWidget.interventionConfig.tabTextStyle
                                                                    .fontFamily,
                                                            color: insightWidget.interventionConfig.tabTextStyle.color,
                                                            minHeight: '36px',
                                                            height: '36px',
                                                            padding: 0,
                                                        }}
                                                    />
                                                ))}
                                            </Tabs>

                                            <Grid2 container>
                                                {selectedActionTab !== null &&
                                                    groupedActionList[selectedActionTab]?.map((intervention) => (
                                                        <Grid2 xs={12}>
                                                            <Card
                                                                variant="outlined"
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    m: 2,
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: fontSize,
                                                                                fontFamily:
                                                                                    insightWidget.fontStyles.fontFamily,
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            {intervention.title}
                                                                        </Typography>

                                                                        <DynamicIcon
                                                                            color="primary"
                                                                            icon={intervention.icon}
                                                                        />
                                                                    </Stack>

                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: fontSize,
                                                                            fontFamily:
                                                                                insightWidget.fontStyles.fontFamily,
                                                                        }}
                                                                    >
                                                                        {intervention.description}
                                                                    </Typography>
                                                                </CardContent>
                                                                <CardActions>
                                                                    <BaseButton
                                                                        size="small"
                                                                        sx={{
                                                                            fontSize: fontSize,
                                                                            fontFamily:
                                                                                insightWidget.fontStyles.fontFamily,
                                                                        }}
                                                                        onClick={() => {
                                                                            setShowCreateTaskDialog({
                                                                                isOpen: true,
                                                                                actionIndex:
                                                                                    items.actions.indexOf(intervention),
                                                                            })
                                                                        }}
                                                                    >
                                                                        Create Task
                                                                    </BaseButton>
                                                                </CardActions>
                                                            </Card>
                                                        </Grid2>
                                                    ))}
                                            </Grid2>
                                        </Stack>
                                    )}
                            </Stack>
                        </>
                    )}
                </>
            )}

            <InsightInterventionTableColumnSelector
                columns={activeInsight?.message[items.condition].columns ?? []}
                updateColumns={(columns) => {
                    if (activeSlideId) {
                        updateInsightWidgetContent({
                            widgetId,
                            slideId: activeSlideId,
                            data: {
                                insights: insightWidget.insights.map((x) =>
                                    x.id === activeInsight?.id
                                        ? {
                                              ...x,
                                              message: {
                                                  ...x.message,
                                                  [items.condition]: {
                                                      ...x.message[items.condition],
                                                      columns,
                                                  },
                                              },
                                          }
                                        : x
                                ),
                            },
                        })
                    }
                }}
                dataSourceFields={dataSourceFields}
                isOpen={isColumnConfigOpen}
                onClose={() => {
                    setIsColumnConfigOpen(false)
                }}
            />

            {activeInsight &&
                activeInsight.message[items.condition].actions &&
                activeInsight.message[items.condition].actions.length > 0 && (
                    <CreateTaskDialog
                        isOpen={showCreateTaskDialog.isOpen}
                        onClose={() => {
                            setShowCreateTaskDialog({ isOpen: false, actionIndex: null })
                        }}
                        inisght={activeInsight.message[items.condition]}
                        actionIndex={showCreateTaskDialog.actionIndex ?? 0}
                        taskId="1"
                        nodes={items.nodes}
                        columns={dataGridColumns}
                        sortModel={sortModel}
                    />
                )}
        </Stack>
    )
}

export default InsightDetailsPanel
