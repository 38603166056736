//* ======= Libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Question, QuestionMatrixModel, QuestionMatrixDropdownModel, QuestionMultipleTextModel } from 'survey-core'
import { ReactSortable } from 'react-sortablejs'

//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import {
    DetailedActionType,
    EmailActionType,
    GiftCardActionType,
    RuleActionKind,
    RuleConditionType,
    RuleNodeActionType,
    RuleOperand,
    RuleOperator,
    RuleQuestionType,
    SlackActionType,
    SurveyActionType,
    SurveyRuleType,
} from 'services/SurveyService'
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import AddIcon from '@mui/icons-material/Add'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { isEmailValid } from 'helpers/validators'
import { AvatarGroup, InputBase, Menu, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import TextEditor from 'features/text-editor/TextEditor'
import { toast } from 'react-toastify'
import { ProjectAccountType } from 'services/ProjectService'
import CloseIcon from '@mui/icons-material/Close'

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    gap: theme.spacing(2.5),

    padding: theme.spacing(0.5, 2.5),

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-180deg)',
        color: theme.palette.primary.main,
    },

    '& .MuiAccordionSummary-content': {
        margin: 0,

        color: theme.palette.common.text_1,

        // '&.Mui-expanded': {
        //     color: theme.palette.primary.main,
        // },
    },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(3),

    minWidth: 0,
    padding: theme.spacing(1, 2.5, 4, 8),
}))

interface ISurveyRulesItem {
    defaultValue: SurveyRuleType
    saveChanges: (value: SurveyRuleType) => void
    accounts: ProjectAccountType[]
    questions: Question[]
    deleteRule: () => void
}

type SelectOptionsType = {
    label: string
    value: any
}[]

type InputType =
    | 'select'
    | 'number'
    | 'multiSelect'
    | 'switch'
    | 'textInput'
    | 'singleSelect'
    | 'multiSelect'
    | 'orderList'
    | null

const ACTIONS = [
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'node_action',
        label: 'Node Action',
    },
    // {
    //     value: 'sms',
    //     label: 'SMS',
    // },
    // {
    //     value: 'giftcard',
    //     label: 'Gift Card',
    // },
    // {
    //     value: 'slack',
    //     label: 'Slack',
    // },
    // {
    //     value: 'survey',
    //     label: 'Add to Survey',
    // },
    // {
    //     value: 'stop_rest',
    //     label: 'Cancel upcoming surveys',
    // },
    // {
    //     value: 'active_next',
    //     label: 'Activate Next round survey',
    // },
]

const oprandToString = (operand: RuleConditionType['lhsOperand']) => {
    if (operand == null) return 'undefined'
    let result = operand.questionKey
    if (operand.row != null) {
        result += `.${operand.row}`
    }
    if (operand.column != null) {
        result += `.${operand.column}`
    }
    return result
}

function SurveyRulesItem({ defaultValue, accounts, saveChanges, deleteRule, questions }: ISurveyRulesItem) {
    // Define state variables to manage UI interactions and data.
    // isExpanded: a boolean to control whether the rule component is expanded.
    const [isExpanded, setIsExpanded] = useState(false)
    // rule: a state variable to store the currently selected or defined rule.
    const [rule, setRule] = useState<SurveyRuleType>()

    // Use effect hook to set the initial value of rule state variable.
    useEffect(() => {
        setRule(defaultValue)
        // If the rule is not defined, expand the rule component.
        if (defaultValue.primaryKey == null) {
            setIsExpanded(true)
        }
    }, [defaultValue])

    // Define select options for some dropdown list in the UI.
    const selectOptions = [
        {
            value: '',
            label: 'None',
        },
        {
            value: '1',
            label: 'One',
        },
        {
            value: '2',
            label: 'Two',
        },
    ]

    // A memoized version of getQuestionNames.
    const leftHandSideOptions = useMemo(() => {
        // A function to convert questions into options that can be used in a select input.
        const getQuestionNames = (questions: Question[]): { label: string; value: RuleOperand }[] => {
            let questionOptions: { label: string; value: RuleOperand }[] = []
            questions.forEach((question) => {
                // For 'matrix' type
                if (question.type === 'matrix') {
                    const matrixQuestion = question as QuestionMatrixModel
                    matrixQuestion.rows?.forEach((row) => {
                        const rowKey = typeof row === 'object' ? row.value || row.text : row
                        questionOptions.push({
                            label: `${question.name}.${rowKey}`,
                            value: {
                                mode: 'question',
                                questionKey: question.name,
                                questionType: question.type as RuleQuestionType,
                                row: rowKey,
                            },
                        })
                    })
                    // For 'matrixdropdown' type
                } else if (question.type === 'matrixdropdown') {
                    const matrixQuestion = question as QuestionMatrixDropdownModel
                    matrixQuestion.rows?.forEach((row) => {
                        const rowKey = typeof row === 'object' ? row.value || row.text : row
                        matrixQuestion.columns?.forEach((column) => {
                            const columnKey = typeof column === 'object' ? column.name || column.text : column

                            questionOptions.push({
                                label: `${question.name}.${rowKey}.${columnKey}`,
                                value: {
                                    mode: 'question',
                                    questionType: question.type as RuleQuestionType,
                                    questionKey: question.name,
                                    row: rowKey,
                                    column: columnKey,
                                    columnType: column.cellType ?? matrixQuestion.cellType ?? 'dropdown',
                                },
                            })
                        })
                    })
                    // For 'multipletext' type
                } else if (question.type === 'multipletext') {
                    const multipletextQuestion = question as QuestionMultipleTextModel
                    multipletextQuestion.items?.forEach((item) => {
                        questionOptions.push({
                            label: `${question.name}.${item.name}`,
                            value: {
                                mode: 'question',
                                questionType: question.type as RuleQuestionType,
                                questionKey: question.name,
                                row: item.name,
                            },
                        })
                    })
                    // For other types
                } else {
                    questionOptions.push({
                        label: question.name,
                        value: {
                            mode: 'question',
                            questionType: question.type as RuleQuestionType,
                            questionKey: question.name,
                        },
                    })
                }
            })
            return questionOptions
        }

        return getQuestionNames(questions)
    }, [questions])

    const questionNames = useMemo(() => {
        // A function to convert questions into options that can be used in a select input.
        return [...questions.map((q) => q.name)]
    }, [questions])

    // A memoized value that generates a text representation of the current rule.
    const ruleText = useMemo(() => {
        if (rule && Array.isArray(rule.condition)) {
            let res = 'On survey completion  and '
            rule.condition.forEach((condition) => {
                let label = oprandToString(condition.lhsOperand)

                return (res += `(${condition.aggregation ? condition.aggregation + '(' + label + ')' : label} 
                ${condition.operator} ${
                    ['empty', 'not_empty'].includes(condition.operator || '') ? '' : condition.rhsOperand
                } ) and `)
            })
            return res.substring(0, res.length - ' and '.length)
        } else {
            return 'On survey completion'
        }
    }, [rule])

    // A function to add a new condition to the current rule.
    const addCondition = () => {
        setRule((r) =>
            r
                ? {
                      ...r,
                      condition: [
                          ...r.condition,
                          { aggregation: null, operator: null, lhsOperand: null, rhsOperand: null },
                      ],
                  }
                : undefined
        )
    }

    // A function to remove a specific condition from the current rule.
    const removeCondition = (index: number) => {
        setRule((r) =>
            r ? { ...r, condition: [...r.condition.slice(0, index), ...r.condition.slice(index + 1)] } : undefined
        )
    }

    // A function to update a specific condition within the current rule.
    const updateCondition = (index: number, value: Partial<RuleConditionType>) => {
        setRule((r) =>
            r
                ? {
                      ...r,
                      condition: [
                          ...r.condition.slice(0, index),
                          { ...r.condition[index], ...value },
                          ...r.condition.slice(index + 1),
                      ],
                  }
                : undefined
        )
    }

    // A function to add a new action to the current rule.
    const addAction = () => {
        setRule((currentRule) => {
            if (currentRule === undefined) {
                return undefined
            }

            const newAction: DetailedActionType = {
                type: 'email',
                details: {
                    emails: [],
                    message: '',
                },
            }
            const updatedActions = [...currentRule.actionDTOs, newAction]

            return { ...currentRule, actionDTOs: updatedActions }
        })
    }

    // A function to update an action type within the current rule.
    const updateActionType = (index: number, type: RuleActionKind) => {
        setRule((rule) => {
            if (rule === undefined) return undefined
            const tmpRule = { ...rule }
            if (tmpRule.actionDTOs) {
                tmpRule.actionDTOs[index].type = type
                switch (type) {
                    case 'email':
                        tmpRule.actionDTOs[index].details = { emails: [], message: '' } as EmailActionType
                        break
                    case 'survey':
                        tmpRule.actionDTOs[index].details = { surveys: [] } as SurveyActionType
                        break
                    case 'slack':
                        tmpRule.actionDTOs[index].details = { hook: '' } as SlackActionType
                        break
                    case 'giftcard':
                        tmpRule.actionDTOs[index].details = {
                            api: '',
                            amount: 0,
                            isRandom: false,
                            every: 0,
                            total: 0,
                        } as GiftCardActionType
                        break
                    case 'node_action':
                        tmpRule.actionDTOs[index].details = {
                            title: '',
                            priority: 'Medium',
                            assignedTo: [],
                            notifyAssignees: false,
                            dueDate: 15,
                            description: '',
                        } as RuleNodeActionType
                        break
                    case 'stop_rest':
                    case 'active_next':
                    default:
                        tmpRule.actionDTOs[index].details = null
                }
            }
            return tmpRule
        })
    }

    // A function to update the details of a specific action within the current rule.
    const updateActionDetails = (index: number, field: string) => (value: any) => {}

    const updateNodeActionDetails = (index: number) => (updatedAction: Partial<RuleNodeActionType>) => {
        setRule((rule) => {
            if (rule === undefined) return undefined
            const tmpRule = { ...rule }
            if (tmpRule.actionDTOs) {
                tmpRule.actionDTOs[index].details = {
                    ...tmpRule.actionDTOs[index].details,
                    ...updatedAction,
                } as RuleNodeActionType
            }
            return tmpRule
        })
    }
    // A function to remove a specific action from the current rule.
    const removeAction = (index: number) => {
        setRule((r) =>
            r ? { ...r, actionDTOs: [...r.actionDTOs.slice(0, index), ...r.actionDTOs.slice(index + 1)] } : undefined
        )
    }

    const isValid = (rule: SurveyRuleType | undefined) => {
        if (!rule) return false
        if (rule.actionDTOs.length === 0) {
            toast.error('Please add at least one action')
            return false
        }
        for (let action of rule.actionDTOs) {
            switch (action.type) {
                case 'email':
                    if (action.details.message === '') {
                        toast.error('Please add a message')
                        return false
                    }
                    if (action.details.emails.length === 0) {
                        toast.error('Please add at least one email')
                        return false
                    }
                    for (let email of action.details.emails) {
                        if (!isEmailValid(email)) {
                            toast.error('Please fill all the emails')
                            return false
                        }
                    }
                    break
            }
        }
        for (let condition of rule.condition) {
            if (!condition.lhsOperand || !condition.operator) {
                toast.error('Please fill all the conditions')
                return false
            }
            if (
                !['empty', 'not_empty'].includes(condition.operator) &&
                (!condition.rhsOperand || (Array.isArray(condition.rhsOperand) && condition.rhsOperand.length === 0))
            ) {
                toast.error('Please fill all the conditions')
                return false
            }
        }
        return true
    }
    return (
        <Accordion
            expanded={isExpanded}
            onChange={(event) => {
                if (!isExpanded) {
                    setIsExpanded(true)
                }
            }}
            TransitionProps={{ unmountOnExit: true }}
            disableGutters
            square
            sx={(theme) => ({
                boxShadow: 0,
                borderRadius: '10px',
                // @Theme conditional
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_2,
                backgroundImage: 'unset',

                '&:before': {
                    height: 0,

                    backgroundColor: 'unset',
                },
            })}
        >
            {/* Summary
                ========================================= */}
            <StyledAccordionSummary>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '30% 1fr auto',
                        columnGap: 1,
                        alignItems: 'center',

                        width: '100%',
                    }}
                >
                    {/* (Conditional) Label
						========================================= */}
                    {/* <Typography fontSize={16} fontWeight={400} color="primary">
                        New Rule
                    </Typography> */}

                    {/* (Conditional) Summary main text
						========================================= */}
                    <Typography fontSize={10} fontWeight={400} noWrap>
                        {ruleText}
                    </Typography>

                    {/* (Conditional) Actions (?)
						========================================= */}
                    <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1} gridColumn="2">
                        {Array.isArray(rule?.actionDTOs) &&
                            rule?.actionDTOs.map(
                                (item) =>
                                    item.type && (
                                        <Typography
                                            sx={{
                                                py: 0.75,
                                                px: 1.5,
                                                fontSize: 10,
                                                fontWeight: 400,

                                                borderRadius: '10px',
                                                backgroundColor: 'common.bg_4',
                                            }}
                                        >
                                            {item.type}
                                        </Typography>
                                    )
                            )}
                    </Stack>

                    {/* Action button
						========================================= */}
                    {isExpanded ? (
                        <Stack direction="row">
                            <BaseButton
                                onClick={() => {
                                    if (defaultValue.primaryKey == null) deleteRule()
                                    else setRule(defaultValue)
                                    setIsExpanded(false)
                                }}
                                sx={{
                                    gridColumn: '3',
                                    color: 'common.fuschia80',
                                }}
                            >
                                Discard changes
                            </BaseButton>
                            <BaseButton
                                onClick={() => {
                                    if (isValid(rule)) {
                                        saveChanges(rule!)
                                        setIsExpanded(false)
                                    }
                                }}
                            >
                                Save changes
                            </BaseButton>
                        </Stack>
                    ) : (
                        <IconButton
                            onClick={(event) => {
                                deleteRule()
                                event.stopPropagation()
                            }}
                            sx={{
                                gridColumn: '3',
                                color: 'common.fuschia80',
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Box>
            </StyledAccordionSummary>

            {/* Details
                ========================================= */}
            <StyledAccordionDetails>
                {/* Initial condition title
                    ========================================= */}
                <Typography fontSize={16} fontWeight={400} color="common.text_1">
                    On survey completion{rule?.condition && rule?.condition.length > 0 && ' and '}
                </Typography>

                {Array.isArray(rule?.condition) &&
                    rule?.condition.map((condition, index) => {
                        return (
                            <RuleConditionItem
                                key={`condition ${index}`}
                                condition={condition}
                                questions={questions}
                                index={index}
                                lhsOptions={leftHandSideOptions}
                                removeCondition={removeCondition}
                                type={rule.type}
                                updateCondition={updateCondition}
                            />
                        )
                    })}

                {/* Add condition button
                    ========================================= */}
                <BaseButton
                    label="Add Condition"
                    startIcon={<AddIcon />}
                    onClick={addCondition}
                    sx={{
                        px: 4,
                        borderRadius: '26px',
                        backgroundColor: 'common.bg_4',
                    }}
                />

                {/* Result action select
                    ========================================= */}
                {Array.isArray(rule?.actionDTOs) &&
                    rule?.actionDTOs.map((item, index) => (
                        <React.Fragment>
                            <Stack direction="row" alignItems="flex-start" gap={2.5} minWidth={0} width="100%">
                                <Typography
                                    flexShrink={0}
                                    fontSize={16}
                                    fontWeight={400}
                                    color="common.text_1"
                                    marginTop={1}
                                >
                                    {index === 0 ? 'Then' : 'And\u00A0'}
                                </Typography>

                                {/* (Iterable) Action box
                            ========================================= */}
                                <Stack flexGrow={1} alignItems="flex-start" minWidth={0} gap={3}>
                                    {/* Action selection
                                ========================================= */}

                                    <Stack direction="row" alignItems="flex-start" gap={2.5} minWidth={0} width="100%">
                                        <Select
                                            id="action-select"
                                            value={item.type}
                                            onChange={(e) => updateActionType(index, e.target.value as RuleActionKind)}
                                            defaultValue={''}
                                            displayEmpty
                                            input={<BasicSelectInput />}
                                            IconComponent={ExpandMoreIcon}
                                            renderValue={(selectedItem: any) => {
                                                if (selectedItem === '') {
                                                    return 'Select action'
                                                } else {
                                                    return ACTIONS.find((_option) => _option.value === selectedItem)
                                                        ?.label
                                                }
                                            }}
                                            sx={{
                                                width: 380,

                                                '& .MuiInput-input': {
                                                    py: 1.25,
                                                    fontSize: 18,
                                                },

                                                '& .MuiSelect-icon': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                Select action
                                            </MenuItem>

                                            {ACTIONS.map((_option, idx) => (
                                                <MenuItem
                                                    key={_option.label + idx}
                                                    value={_option.value || ''}
                                                    disabled={_option.value === 'giftcard'}
                                                >
                                                    {_option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <IconButton
                                            onClick={() => removeAction(index)}
                                            sx={{
                                                gridColumn: '3',
                                                color: 'common.fuschia80',
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>

                                    {item.type === 'node_action' ? (
                                        <NodeActionPanel
                                            accounts={accounts}
                                            questions={questionNames}
                                            action={item.details}
                                            updateAction={updateNodeActionDetails(index)}
                                        />
                                    ) : item.type === 'email' ? (
                                        /* Email/SMS action
                                        ========================================= */
                                        <EmailActionPanel
                                            questions={questionNames}
                                            action={item.details}
                                            addEmail={(email: string) => {
                                                setRule((rule) => {
                                                    if (rule == null) return undefined
                                                    let tmp = { ...rule }
                                                    const item = tmp.actionDTOs[index]
                                                    if (
                                                        item &&
                                                        item.type === 'email' &&
                                                        !item.details.emails.includes(email)
                                                    ) {
                                                        let tmpDetails = { ...item.details }
                                                        item.details = {
                                                            ...tmpDetails,
                                                            emails: [...tmpDetails.emails, email],
                                                        }
                                                    }

                                                    return tmp
                                                })
                                            }}
                                            removeEmail={(email: string) => {
                                                setRule((rule) => {
                                                    if (rule == null) return undefined
                                                    let tmp = { ...rule }
                                                    const item = tmp.actionDTOs[index]
                                                    if (item && item.type === 'email' && 'emails' in item.details) {
                                                        let tmpDetails = { ...item.details }
                                                        item.details = {
                                                            ...tmpDetails,
                                                            emails: tmpDetails.emails.filter((e) => e !== email),
                                                        }
                                                    }

                                                    return tmp
                                                })
                                            }}
                                            updateMessage={(message: string) => {
                                                setRule((rule) => {
                                                    if (rule == null) return undefined
                                                    let tmp = { ...rule }
                                                    const item = tmp.actionDTOs[index]
                                                    if (item && item.type === 'email' && 'message' in item.details) {
                                                        let tmpDetails = { ...item.details }
                                                        item.details = {
                                                            ...tmpDetails,
                                                            message,
                                                        }
                                                    }

                                                    return tmp
                                                })
                                            }}
                                        />
                                    ) : item.type === 'giftcard' ? (
                                        /* Gift card action
                                            ========================================= */
                                        <Stack gap={2.5} minWidth={0} width="100%">
                                            {/* Account linking
                                            ========================================= */}
                                            <Stack direction="row" alignItems="center" gap={3}>
                                                {/* Payment account
                                                ========================================= */}
                                                <Select
                                                    id="payment-account-select"
                                                    defaultValue={''}
                                                    displayEmpty
                                                    input={<BasicSelectInput />}
                                                    IconComponent={ExpandMoreIcon}
                                                    renderValue={(selectedItem: any) => {
                                                        if (selectedItem === '') {
                                                            return 'Select'
                                                        } else {
                                                            const selectedOption = selectOptions.find(
                                                                (_option) => _option.value === selectedItem
                                                            )

                                                            return (
                                                                selectedOption && (
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            gap: 1,
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            src={TempAvatar}
                                                                            alt={selectedOption.label}
                                                                        />

                                                                        <Typography
                                                                            fontSize={12}
                                                                            fontWeight={400}
                                                                            color="common.text_1"
                                                                            noWrap
                                                                        >
                                                                            {selectedOption.label}
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            )
                                                        }
                                                    }}
                                                    sx={{
                                                        width: 380,

                                                        '& .MuiInput-input': {
                                                            py: 1.25,
                                                            fontSize: 18,

                                                            backgroundColor: 'common.bg_1',
                                                        },

                                                        '& .MuiSelect-icon': {
                                                            color: 'primary.main',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        Select
                                                    </MenuItem>

                                                    {selectOptions.map((_option, idx) => (
                                                        <MenuItem
                                                            key={_option.label + idx}
                                                            value={_option.value || ''}
                                                            sx={{
                                                                gap: 1,
                                                            }}
                                                        >
                                                            <Avatar src={TempAvatar} alt={_option.label} />

                                                            <Typography
                                                                fontSize={10}
                                                                fontWeight={400}
                                                                color="common.text_1"
                                                            >
                                                                {_option.label}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                                {/* Link button
                                                 ========================================= */}
                                                <BaseButton label="Link Account" variant="contained" size="large" />
                                            </Stack>

                                            {/* Amount and count
                                            ========================================= */}
                                            <Stack direction="row" alignItems="center" gap={3}>
                                                {/* Amount field
                                                ========================================= */}
                                                <BaseFilledTextField
                                                    type="number"
                                                    hiddenLabel
                                                    placeholder="Amount"
                                                    sx={{
                                                        width: 380,
                                                        marginRight: 2,
                                                    }}
                                                />

                                                {/* Random switch
                                                ========================================= */}
                                                <FormGroup
                                                    aria-label="random"
                                                    row
                                                    sx={{
                                                        marginRight: 3,
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={<BaseSwitch color="primary" />}
                                                        label="Random"
                                                        labelPlacement="start"
                                                        sx={{
                                                            gap: 1,

                                                            '& .MuiFormControlLabel-label': {
                                                                fontSize: 14,
                                                                fontWeight: 400,
                                                            },
                                                        }}
                                                    />
                                                </FormGroup>

                                                {/* Minimum count (?)
                                                ========================================= */}
                                                <Select
                                                    id="minimum-amount-select"
                                                    defaultValue={''}
                                                    displayEmpty
                                                    input={<BasicSelectInput />}
                                                    IconComponent={ExpandMoreIcon}
                                                    renderValue={(selectedItem: any) => {
                                                        if (selectedItem === '') {
                                                            return 'Minimum'
                                                        } else {
                                                            return selectOptions.find(
                                                                (_option) => _option.value === selectedItem
                                                            )?.label
                                                        }
                                                    }}
                                                    sx={{
                                                        width: 100,

                                                        '& .MuiInput-input': {
                                                            py: 1.25,
                                                            fontSize: 18,
                                                        },

                                                        '& .MuiSelect-icon': {
                                                            color: 'primary.main',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        Minimum
                                                    </MenuItem>

                                                    {selectOptions.map((_option, idx) => (
                                                        <MenuItem key={_option.label + idx} value={_option.value || ''}>
                                                            {_option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                                <Typography fontSize={16} fontWeight={400}>
                                                    out of
                                                </Typography>

                                                {/* Maximum count (?)
                                                ========================================= */}
                                                <Select
                                                    id="maximum-amount-select"
                                                    defaultValue={''}
                                                    displayEmpty
                                                    input={<BasicSelectInput />}
                                                    IconComponent={ExpandMoreIcon}
                                                    renderValue={(selectedItem: any) => {
                                                        if (selectedItem === '') {
                                                            return 'Maximum'
                                                        } else {
                                                            return selectOptions.find(
                                                                (_option) => _option.value === selectedItem
                                                            )?.label
                                                        }
                                                    }}
                                                    sx={{
                                                        width: 100,

                                                        '& .MuiInput-input': {
                                                            py: 1.25,
                                                            fontSize: 18,
                                                        },

                                                        '& .MuiSelect-icon': {
                                                            color: 'primary.main',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        Maximum
                                                    </MenuItem>

                                                    {selectOptions.map((_option, idx) => (
                                                        <MenuItem key={_option.label + idx} value={_option.value || ''}>
                                                            {_option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Stack>
                                        </Stack>
                                    ) : item.type === 'survey' ? (
                                        /* Survey select action
                                                ========================================= */
                                        <Select
                                            id="survey-select"
                                            multiple
                                            value={(item.details as SurveyActionType)?.surveys || []}
                                            onChange={updateActionDetails(index, 'surveys')}
                                            displayEmpty
                                            input={<BasicSelectInput />}
                                            IconComponent={ExpandMoreIcon}
                                            renderValue={(selectedValues: any) => {
                                                if (selectedValues.length === 0) {
                                                    return 'Select Surveys'
                                                } else {
                                                    return selectedValues
                                                        .map((_value: any) => {
                                                            return selectOptions.find(
                                                                (_option) => _option.value === _value
                                                            )?.label
                                                        })
                                                        .join(', ')
                                                }
                                            }}
                                            MenuProps={{
                                                sx: {
                                                    maxHeight: 300,
                                                },
                                            }}
                                            sx={{
                                                width: 380,

                                                '& .MuiInput-input': {
                                                    py: 1.25,
                                                    fontSize: 18,
                                                },

                                                '& .MuiSelect-icon': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                Select Surveys
                                            </MenuItem>

                                            {selectOptions.map((_option, idx) => (
                                                <MenuItem key={_option.label + idx} value={_option.value || ''}>
                                                    <Checkbox
                                                        checked={
                                                            Array.isArray(
                                                                (item.details as SurveyActionType)?.surveys
                                                            ) &&
                                                            (item.details as SurveyActionType)?.surveys?.findIndex(
                                                                (v) => v === parseInt(_option.value)
                                                            ) !== -1
                                                        }
                                                    />

                                                    <ListItemText
                                                        primary={_option.label}
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 400,

                                                            color: 'common.text_1',
                                                        }}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : item.type === 'slack' ? (
                                        /* Slack link action
                                                    ========================================= */
                                        <BaseFilledTextField
                                            value={(item.details as SlackActionType)?.hook || ''}
                                            onChange={updateActionDetails(index, 'hook')}
                                            hiddenLabel
                                            placeholder="Enter the Webhook URLs for Your Workspace"
                                            sx={{
                                                width: 620,
                                            }}
                                        />
                                    ) : null}
                                </Stack>
                            </Stack>
                            <Divider sx={{ height: 1, width: 1 }} />
                        </React.Fragment>
                    ))}

                {/* Add action button
                    ========================================= */}
                <BaseButton
                    label="Add Action"
                    onClick={addAction}
                    startIcon={<AddIcon />}
                    sx={{
                        marginLeft: 7.5,
                        px: 5,
                        borderRadius: '26px',
                        backgroundColor: 'common.bg_4',
                    }}
                />
            </StyledAccordionDetails>
        </Accordion>
    )
}

const COMPARISON_OPERATORS: { value: RuleOperator; label: string }[] = [
    { value: 'empty', label: 'Is Empty' },
    { value: 'notEmpty', label: 'Is Not Empty' },
    { value: 'equals', label: '=' },
    { value: 'notEquals', label: '≠' },
    { value: 'gt', label: '>' },
    { value: 'gte', label: '≥' },
    { value: 'lt', label: '<' },
    { value: 'lte', label: '≤' },
    { value: 'contains', label: 'Contains' },
    { value: 'notContains', label: 'Does Not Contain' },
    { value: 'anyOf', label: 'Any Of' },
    { value: 'allOf', label: 'All Of' },
]

type ConditionProps = {
    questions: Question[]
    condition: RuleConditionType
    updateCondition: (index: number, value: Partial<RuleConditionType>) => void
    type: SurveyRuleType['type']
    index: number
    removeCondition: (index: number) => void
    lhsOptions: SelectOptionsType
}

function RuleConditionItem({
    questions,
    removeCondition,
    type,
    condition,
    updateCondition,
    index,
    lhsOptions,
}: ConditionProps) {
    const [operators, setOperators] = useState<Array<{ value: RuleConditionType['operator']; label: string }>>([])

    const [inputType, setInputType] = useState<InputType>(null)
    const [inputOptions, setInputOptions] = useState<SelectOptionsType>([])

    function getOperators(lhsOperand: RuleOperand) {
        const questionType = lhsOperand.columnType ?? lhsOperand.questionType
        let tmpOperators: RuleOperator[] = []
        switch (questionType) {
            case 'file':
            case 'signaturepad':
                tmpOperators = ['empty', 'notEmpty']
                break
            case 'boolean':
                tmpOperators = ['empty', 'notEmpty', 'equals', 'notEquals']
                break
            case 'matrixdropdown':
            case 'checkbox':
            case 'tagbox':
                tmpOperators = ['empty', 'notEmpty', 'equals', 'notEquals', 'contains', 'notContains', 'anyOf', 'allOf']
                break
            case 'comment':
            case 'expression':
            case 'multipletext':
            case 'text':
                tmpOperators = [
                    'empty',
                    'notEmpty',
                    'equals',
                    'notEquals',
                    'contains',
                    'notContains',
                    'gt',
                    'gte',
                    'lt',
                    'lte',
                ]
                break
            case 'dropdown':
            case 'radiogroup':
            case 'matrix':
                tmpOperators = ['empty', 'notEmpty', 'equals', 'notEquals', 'anyOf', 'gt', 'gte', 'lt', 'lte']
                break
            case 'imagepicker':
                tmpOperators = ['empty', 'notEmpty', 'equals', 'notEquals', 'anyOf']
                break
            case 'networkquestion':
                tmpOperators = ['empty', 'notEmpty', 'gt', 'gte', 'lt', 'lte']
                break
            case 'ranking':
            case 'address_question':
                tmpOperators = ['empty', 'notEmpty', 'equals', 'notEquals']
                break
            case 'rating':
                tmpOperators = ['empty', 'notEmpty', 'equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
                break
            default:
                tmpOperators = []
        }

        return COMPARISON_OPERATORS.filter((x) => tmpOperators.includes(x.value))
    }

    const updateLhsOprand = (lhsOperand: RuleConditionType['lhsOperand']) => {
        // Reset selected operator when question type changes
        updateCondition(index, {
            operator: 'empty',
            lhsOperand,
            rhsOperand: null,
        })
        // Update operators based on the new question type
        updateOperatorsAndInputOptions(lhsOperand, 'empty')
        setInputType(null)
    }

    useEffect(() => {
        updateOperatorsAndInputOptions(condition.lhsOperand, condition.operator)
    }, [])

    function extractChoices(question: Question, columnName?: string): SelectOptionsType {
        let choices: any[] = []

        switch (question.type) {
            case 'rating':
                const min = question.rateMin == null ? 0 : question.rateMin
                const max = question.rateMax == null ? 5 : question.rateMax
                const step = question.rateStep == null ? 1 : question.rateStep
                for (let i = min; i <= max; i += step) {
                    choices.push(i)
                }
                break
            case 'boolean':
                const labelTrue = question.labelTrue == null ? 'Yes' : question.labelTrue
                const labelFalse = question.labelFalse == null ? 'No' : question.labelFalse
                choices.push({
                    text: labelTrue,
                    value: true,
                })
                choices.push({
                    text: labelFalse,
                    value: false,
                })
                break
            case 'matrix':
                choices = question.columns // Use columns as choices for matrix
                break
            case 'matrixdropdown':
                const column = Array.isArray(question.columns) && question.columns.find((x) => x.name === columnName)

                if (column && column.cellType) {
                    return extractChoices(column)
                } else {
                    choices = question.choices // Otherwise, use default choices
                }
                break
            default:
                if (question.choices) {
                    choices = question.choices
                }
                break
        }

        const formattedChoices = choices.map((choice: any) => {
            // If the choice is an object, assume it has "value" and "text" attributes
            if (typeof choice === 'object') {
                return { label: 'text' in choice ? choice.text : choice.value, value: choice.value }
            }

            // If the choice is a primitive (string or number), use it for both key and value
            return { label: choice, value: choice }
        })

        // Check if "none" or "other" should be included and add them to the list
        if (question.showNoneItem) {
            formattedChoices.push({ label: 'none', value: 'None' })
        }
        if (question.showOtherItem) {
            formattedChoices.push({ label: 'other', value: 'Other' })
        }

        return formattedChoices
    }

    const updateOperatorsAndInputOptions = (
        lhsOperand: RuleConditionType['lhsOperand'],
        operator: RuleConditionType['operator']
    ) => {
        if (lhsOperand == null) {
            setOperators([])
            setInputOptions([])
            setInputType(null)
            return
        }

        setOperators(getOperators(lhsOperand))
        setInputType(getInputType(lhsOperand, operator))

        const question = questions.find((q) => q.name === lhsOperand.questionKey)

        if (question == null) {
            setInputOptions([])
        } else {
            setInputOptions(extractChoices(question, lhsOperand.column))
        }
    }

    const updateOpreator = (op: RuleOperator) => {
        if (condition.lhsOperand?.questionType == null) return
        const newInputType = getInputType(condition.lhsOperand, op)
        if (inputType === newInputType) {
            updateCondition(index, { operator: op })
        } else {
            setInputType(newInputType)
            let rhsOperand = null
            switch (newInputType) {
                case 'multiSelect':
                    rhsOperand = []
                    break
                case 'orderList':
                    rhsOperand = Array.isArray(inputOptions) ? [...inputOptions] : []
                    break
                default:
                    rhsOperand = null
            }
            updateCondition(index, { operator: op, rhsOperand })
        }
    }

    type OptionalOperatorInputType = {
        [K in RuleOperator]?: InputType
    }

    const questionOperatorToInputType: Record<RuleQuestionType, OptionalOperatorInputType> = {
        boolean: { empty: null, notEmpty: null, equals: 'switch', notEquals: 'switch' },
        checkbox: {
            empty: null,
            notEmpty: null,
            equals: 'multiSelect',
            notEquals: 'multiSelect',
            contains: 'singleSelect',
            notContains: 'singleSelect',
            anyOf: 'multiSelect',
            allOf: 'multiSelect',
        },
        comment: {
            empty: null,
            notEmpty: null,
            equals: 'textInput',
            notEquals: 'textInput',
            contains: 'textInput',
            notContains: 'textInput',
            gt: 'textInput',
            lt: 'textInput',
            gte: 'textInput',
            lte: 'textInput',
        },
        dropdown: {
            empty: null,
            notEmpty: null,
            equals: 'singleSelect',
            notEquals: 'singleSelect',
            gt: 'singleSelect',
            gte: 'singleSelect',
            lt: 'singleSelect',
            lte: 'singleSelect',
            anyOf: 'multiSelect',
        },
        expression: {
            empty: null,
            notEmpty: null,
            equals: 'textInput',
            notEquals: 'textInput',
            contains: 'textInput',
            notContains: 'textInput',
            gt: 'textInput',
            lt: 'textInput',
            gte: 'textInput',
            lte: 'textInput',
        },
        file: { empty: null, notEmpty: null },
        imagepicker: {
            empty: null,
            notEmpty: null,
            equals: 'singleSelect',
            notEquals: 'singleSelect',
            anyOf: 'multiSelect',
        },
        networkquestion: {
            empty: null,
            notEmpty: null,
            gt: 'number',
            lt: 'number',
            gte: 'number',
            lte: 'number',
        },
        radiogroup: {
            empty: null,
            notEmpty: null,
            equals: 'singleSelect',
            notEquals: 'singleSelect',
            gt: 'singleSelect',
            gte: 'singleSelect',
            lt: 'singleSelect',
            lte: 'singleSelect',
            anyOf: 'multiSelect',
        },
        ranking: { empty: null, notEmpty: null, equals: 'orderList', notEquals: 'orderList' },
        rating: {
            empty: null,
            notEmpty: null,
            equals: 'singleSelect',
            notEquals: 'singleSelect',
            gt: 'singleSelect',
            lt: 'singleSelect',
            gte: 'singleSelect',
            lte: 'singleSelect',
        },
        signaturepad: { empty: null, notEmpty: null },
        matrix: {
            empty: null,
            notEmpty: null,
            equals: 'singleSelect',
            notEquals: 'singleSelect',
            gt: 'singleSelect',
            gte: 'singleSelect',
            lt: 'singleSelect',
            lte: 'singleSelect',
            anyOf: 'multiSelect',
        },
        tagbox: {
            empty: null,
            notEmpty: null,
            equals: 'multiSelect',
            notEquals: 'multiSelect',
            contains: 'singleSelect',
            notContains: 'singleSelect',
            anyOf: 'multiSelect',
            allOf: 'multiSelect',
        },
        multipletext: {
            empty: null,
            notEmpty: null,
            equals: 'textInput',
            notEquals: 'textInput',
            contains: 'textInput',
            notContains: 'textInput',
            gt: 'textInput',
            lt: 'textInput',
            gte: 'textInput',
            lte: 'textInput',
        },
        text: {
            empty: null,
            notEmpty: null,
            equals: 'textInput',
            notEquals: 'textInput',
            contains: 'textInput',
            notContains: 'textInput',
            gt: 'textInput',
            lt: 'textInput',
            gte: 'textInput',
            lte: 'textInput',
        },
        address_question: {
            empty: null,
            notEmpty: null,
            equals: 'textInput',
            notEquals: 'textInput',
        },
        matrixdropdown: {},
    }

    function getInputType(lhsOperand: RuleOperand, operator: RuleOperator | null): InputType {
        const questionType = lhsOperand.columnType ?? lhsOperand.questionType
        if (operator === null || questionOperatorToInputType[questionType] == null) return null
        return questionOperatorToInputType[questionType][operator] || null
    }

    // Define select options for some dropdown list in the UI.
    // ToDo temp, remove later
    const selectOptions = [
        {
            value: '',
            label: 'None',
        },
        {
            value: '1',
            label: 'One',
        },
        {
            value: '2',
            label: 'Two',
        },
    ]

    return (
        <Stack alignItems="flex-start" gap={3} width="100%">
            {/* Condition definition
                                ========================================= */}
            <Stack direction="row" alignItems="center" gap={2.5}>
                <Typography fontSize={16} fontWeight={400} color="common.text_1">
                    {index === 0 ? 'If\u00A0\u00A0\u00A0\u00A0\u00A0' : 'And'}
                </Typography>

                {/* If
                                    ========================================= */}
                {type === 'aggregation' && (
                    <BaseSelectWithLabel
                        id="if-condition-select"
                        defaultValue={''}
                        value={condition.aggregation}
                        options={selectOptions}
                        onChange={(value) => updateCondition(index, { aggregation: value })}
                        sx={{
                            width: 380,

                            '& .MuiInput-input': {
                                py: 1.25,
                                fontSize: 18,
                            },

                            '& .MuiSelect-icon': {
                                color: 'primary.main',
                            },
                        }}
                    />
                )}

                {/* Select question or node attribute
                                    ========================================= */}
                <BaseSelectWithLabel
                    id="question-select"
                    label="Question/Attribute"
                    sx={{
                        width: 380,

                        '& .MuiInput-input': {
                            py: 1.25,
                            fontSize: 18,
                        },

                        '& .MuiSelect-icon': {
                            color: 'primary.main',
                        },
                    }}
                    value={condition.lhsOperand}
                    onChange={updateLhsOprand}
                    options={lhsOptions}
                />

                {/* Condition operator
                                    ========================================= */}

                <BaseSelectWithLabel
                    id="condition-operator-select"
                    label="Operator"
                    sx={{
                        width: 200,

                        '& .MuiInput-input': {
                            py: 1.25,
                            fontSize: 18,
                        },

                        '& .MuiSelect-icon': {
                            color: 'primary.main',
                        },
                    }}
                    value={condition.operator}
                    onChange={updateOpreator}
                    options={operators}
                />
                <IconButton
                    onClick={() => removeCondition(index)}
                    sx={{
                        gridColumn: '3',
                        color: 'common.fuschia80',
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </Stack>

            {/* Question box
                                ========================================= */}
            {inputType && condition.lhsOperand && (
                <Stack
                    alignItems="flex-start"
                    gap={2}
                    sx={{
                        minWidth: '50%',
                        py: 3.75,
                        px: 4.5,

                        borderRadius: '10px',
                        backgroundColor: 'common.bg_1',
                    }}
                >
                    <Typography fontSize={16} fontWeight={400} color="common.text_1">
                        {oprandToString(condition.lhsOperand)}
                    </Typography>

                    <RuleInput
                        type={getInputType(condition.lhsOperand, condition.operator)}
                        options={inputOptions}
                        value={condition.rhsOperand}
                        onChange={(value) => updateCondition(index, { rhsOperand: value })}
                    />
                </Stack>
            )}
        </Stack>
    )
}

type RuleInputProps = {
    type: InputType
    value: any
    options?: SelectOptionsType
    onChange: (newValue: any) => void
}

const RuleInput = ({ options, type, value, onChange }: RuleInputProps) => {
    switch (type) {
        case 'switch':
            return (
                <BaseSelectWithLabel
                    hiddenLabel
                    fullWidth
                    options={options}
                    value={value}
                    onChange={(value) => onChange(value)}
                />
            )
        case 'multiSelect':
            // Here you should return your Multiselect component
            return (
                <BaseSelectWithLabel
                    fullWidth
                    options={options || []}
                    value={value}
                    hiddenLabel
                    selectProps={{
                        multiple: true,
                    }}
                    onChange={(value) => onChange(value)}
                />
            )
        case 'textInput':
            return (
                <BaseFilledTextField fullWidth hiddenLabel value={value} onChange={(e) => onChange(e.target.value)} />
            )
        case 'singleSelect':
            // Here you should return your SingleSelect component
            return (
                <BaseSelectWithLabel
                    hiddenLabel
                    fullWidth
                    options={options}
                    value={value}
                    onChange={(value) => onChange(value)}
                />
            )
        case 'orderList':
            // Here you should return your OrderList component
            // ToDo repalce this with a custom component and style it to looks like survey js logic input
            return (
                <ReactSortable list={value} setList={onChange}>
                    {value && value.map((item: any) => <div key={item.value}>{item.label}</div>)}
                </ReactSortable>
            )
        case 'number':
            return (
                <BaseFilledTextField
                    hiddenLabel
                    fullWidth
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
            )

        default:
            return null
    }
}

type EmailActionProps = {
    action: EmailActionType
    addEmail: (email: string) => void
    removeEmail: (email: string) => void
    updateMessage: (message: string) => void
    questions: string[]
}
const EmailActionPanel = ({ action, updateMessage, addEmail, removeEmail, questions }: EmailActionProps) => {
    const [email, setEmail] = useState<string>('')

    const questionKeywords = useMemo(() => {
        const result: string[] = [...questions.map((question) => `[question.${question}]`)]
        return result
    }, [questions])

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                flexGrow: 1,

                // padding: theme.spacing(2, 3),
            })}
        >
            <Box sx={{ width: '50vW' }}>
                <TextEditor
                    keywords={['[user.name]', '[survey.name]', '[project.name]', '[rule]', ...questionKeywords]}
                    defaultValue={action.message || ''}
                    onBlur={updateMessage}
                />
            </Box>

            {/* Search and add field
				    ========================================= */}
            <Stack
                direction="row"
                alignItems="stretch"
                sx={{
                    flexShrink: 0,

                    marginBottom: 2,
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '100%',
                        py: 0.5,
                        paddingRight: 1,
                        paddingLeft: 2,
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        backgroundColor: 'common.bg_4',
                    }}
                >
                    <InputBase
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="users-list"
                        placeholder="Add email"
                        fullWidth
                        startAdornment={
                            <SearchIcon
                                sx={{
                                    marginRight: 1,

                                    color: 'secondary.main',
                                }}
                            />
                        }
                        sx={{
                            height: '100%',
                        }}
                    />
                </Box>

                <BaseButton
                    variant="contained"
                    size="large"
                    label="Add"
                    disabled={!isEmailValid(email) || action.emails.includes(email)}
                    disableElevation
                    onClick={() => {
                        addEmail(email)
                        setEmail('')
                    }}
                    sx={{
                        width: 120,
                        py: 1.5,

                        borderRadius: '10px',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                />
            </Stack>

            {/* Users list
				    ========================================= */}
            <Stack
                gap={1}
                sx={{
                    overflowY: 'auto',
                }}
                className="u-scrollbar"
            >
                {action.emails?.map((email, idx) => (
                    /*  List item
				            ========================================= */
                    <Stack key={email} direction="row" alignItems="center" gap={1}>
                        {/* Avatar
				                ========================================= */}
                        <Avatar
                            alt="username"
                            sx={{
                                flexShrink: 0,

                                height: 40,
                                width: 40,
                            }}
                        />

                        {/* Email
				                ========================================= */}
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="common.text_1"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            {email}
                        </Typography>

                        {/* Controls
				                ========================================= */}
                        <Stack direction="row" alignItems="center" gap={2} marginLeft="auto">
                            {/* Remove button
				                    ========================================= */}
                            <IconButton
                                onClick={(evt) => removeEmail(email)}
                                sx={(theme) => ({
                                    flexShrink: 0,

                                    color: theme.palette.common.ung_pink,
                                })}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}

type NodeActionProps = {
    action: RuleNodeActionType
    updateAction: (action: Partial<RuleNodeActionType>) => void
    accounts: ProjectAccountType[]
    questions: string[]
}

const NodeActionPanel = ({ accounts, action, updateAction, questions }: NodeActionProps) => {
    const questionKeywords = useMemo(() => {
        const result: string[] = [...questions.map((question) => `[question.${question}]`)]
        return result
    }, [questions])

    return (
        <Stack
            gap={2}
            sx={(theme) => ({
                flexGrow: 1,
            })}
        >
            <BaseFilledTextField
                label="Action Title"
                value={action.title}
                onChange={(e) => updateAction({ title: e.target.value })}
            />
            <BaseSelectWithLabel
                label="Priority"
                value={action.priority}
                options={['Low', 'Medium', 'High', 'Critical']}
                onChange={(value) => updateAction({ priority: value })}
            />
            <BaseFilledTextField
                label="Response Time (days)"
                value={action.dueDate}
                type="number"
                inputProps={{ min: 1 }}
                onChange={(e) => {
                    updateAction({ dueDate: Number.parseInt(e.target.value) })
                }}
            />

            <FormControlLabel
                checked={action.notifyAssignees}
                onChange={(e, checked) => updateAction({ notifyAssignees: checked })}
                label="Immediately notify assignees by Email"
                control={<Checkbox />}
            />

            <Stack>
                <Typography>Assign To:</Typography>
                <AssignedUsers
                    accounts={accounts}
                    selectedAccounts={action.assignedTo ?? []}
                    onRemoveAccount={(id: number) =>
                        updateAction({
                            assignedTo: action.assignedTo?.filter((x) => x !== id) ?? [],
                        })
                    }
                    onAddAccount={(id: number) =>
                        updateAction({
                            assignedTo: [...(action.assignedTo ?? []), id],
                        })
                    }
                />
            </Stack>
            <Stack>
                <Typography>Description:</Typography>
                <Box sx={{ width: '50vW' }}>
                    <TextEditor
                        keywords={['[user.name]', '[survey.name]', '[project.name]', '[rule]', ...questionKeywords]}
                        defaultValue={action.description}
                        onBlur={(value: string) => updateAction({ description: value })}
                    />
                </Box>
            </Stack>
        </Stack>
    )
}

type AssignedUsersProps = {
    accounts: ProjectAccountType[]
    selectedAccounts: number[]
    onRemoveAccount: (accountId: number) => void
    onAddAccount: (accountId: number) => void
    size?: number
    max?: number
}

export const AssignedUsers = ({
    accounts,
    selectedAccounts,
    onRemoveAccount,
    onAddAccount,
    size = 52,
    max = 6,
}: AssignedUsersProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const isSelected = (account: ProjectAccountType) => {
        return selectedAccounts.includes(account.primaryKey)
    }

    return (
        <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
                <AvatarGroup total={selectedAccounts.length} max={max} sx={{ zIndex: 2 }}>
                    {selectedAccounts.map((accountId) => {
                        const account = accounts.find((x) => x.primaryKey === accountId)
                        if (account == null) return null
                        return (
                            <Box
                                position="relative"
                                key={account.primaryKey}
                                sx={{
                                    '&:hover .delete-icon-box': {
                                        visibility: 'visible',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <Avatar sx={{ width: size, height: size }} src={account.account.avatar}>
                                    {account.account.name[0]}
                                </Avatar>

                                <IconButton
                                    className="delete-icon-box"
                                    sx={(theme) => ({
                                        visibility: 'hidden',
                                        position: 'absolute',
                                        top: -4,
                                        right: -4,
                                        bgcolor: theme.palette.common.bg_5,
                                        '&:hover': {
                                            bgcolor: theme.palette.common.bg_4,
                                        },
                                    })}
                                    onClick={() => onRemoveAccount(account.primaryKey)}
                                >
                                    <CloseIcon sx={{ fontSize: 12 }} color="action" />
                                </IconButton>
                            </Box>
                        )
                    })}
                </AvatarGroup>
                <Avatar sx={{ width: size, height: size, ml: selectedAccounts.length > 0 ? -1 : 0 }}>
                    <IconButton onClick={handleClick}>
                        <GroupAddIcon color="action" />
                    </IconButton>
                </Avatar>
            </Box>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {accounts.map((item) => (
                    <MenuItem
                        key={item.primaryKey}
                        onClick={() => {
                            isSelected(item) ? onRemoveAccount(item.primaryKey) : onAddAccount(item.primaryKey)
                        }}
                    >
                        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3} width="100%">
                            <Stack direction="row" gap={1} alignItems="center">
                                <Avatar src={item.account.avatar}>{item.account.name[0]}</Avatar>
                                {item.account.name} ({item.role.roleName})
                            </Stack>

                            {isSelected(item) ? (
                                <BaseButton color="warning" label="Remove" endIcon={<CloseIcon />} />
                            ) : (
                                <BaseButton color="primary" label="Add" endIcon={<AddIcon />} />
                            )}
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

export default SurveyRulesItem
