import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { GetProject, ProjectRoleWithPermissionType, ProjectType } from '../services/ProjectService'
import { NavigationPathType, NavigationDrawerType } from 'types/NavigationTypes'
import { RootContext } from './RootContext'
import ProjectDrawerNavigation from 'pages/project-dashboard/Navigation'

interface ProjectContextTypes {
    project?: ProjectType
    role?: ProjectRoleWithPermissionType
    isLoading: boolean
    drawerContent: NavigationDrawerType
    fetchProject: () => void
    updateProjectRole: (role: string) => void
    hasPermission: (permission: string | string[]) => boolean
}

const ProjectContext = createContext<ProjectContextTypes>({
    project: undefined,
    role: undefined,
    updateProjectRole: (role: string) => {},
    isLoading: false,
    drawerContent: { quickAccess: false, sections: [] },
    fetchProject: () => null,
    hasPermission: (permission: string | string[]) => false,
})

export { ProjectContext }

interface ProjectContextProviderTypes {
    children: React.ReactNode
}

export default function ProjectContextProvider({ children }: ProjectContextProviderTypes) {
    const { setPath, permissions } = useContext(RootContext)
    const { pid, sid } = useParams()
    const location = useLocation()

    const [drawerContent, setDrawerContent] = useState<NavigationDrawerType>({
        quickAccess: false,
        sections: [],
    })

    const [project, setProject] = useState<ProjectType>()
    const [role, setRole] = useState<ProjectRoleWithPermissionType>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (sid === undefined && project && role) {
            const path: NavigationPathType[] = [
                { link: '/', title: 'All Projects' },
                { title: project.title, link: `/project/${project.primaryKey}` },
            ]

            const pathes = location.pathname.split('/')
            const lastItemIndex = pathes.length - 1

            if (pathes[lastItemIndex] === 'nodes') {
                path.push({ title: 'All Nodes' })
            } else if (pathes[lastItemIndex] === 'reports') {
                path.push({ title: 'All Reports' })
            } else if (pathes[lastItemIndex] === 'networks') {
                path.push({ title: 'All Networks' })
            } else if (pathes[lastItemIndex] === 'surveys') {
                path.push({ title: 'All Surveys' })
            } else if (pathes[lastItemIndex] === 'users') {
                path.push({ title: 'User Managment' })
            } else if (pathes[lastItemIndex] === 'allocations') {
                path.push({ title: 'All Allocations' })
            } else if (pathes[lastItemIndex] === 'actions') {
                path.push({ title: 'Actions' })
            } else if (pathes[lastItemIndex - 1] === 'nodes') {
                path.push({ title: 'All Nodes', link: `/project/${project.primaryKey}/nodes` })
            } else if (pathes[lastItemIndex - 1] === 'networks') {
                path.push({ title: 'All Networks', link: `/project/${project.primaryKey}/networks` })
            } else if (pathes[lastItemIndex - 1] === 'reports') {
                path.push({ title: 'All Reports', link: `/project/${project.primaryKey}/reports` })
            } else if (pathes[lastItemIndex - 1] === 'allocations') {
                path.push({ title: 'All Allocations', link: `/project/${project.primaryKey}/allocations` })
            }
            setPath(path)
            if (project.primaryKey) setDrawerContent(ProjectDrawerNavigation(project.primaryKey, project.title, role))
        }
    }, [project, sid, role, location.pathname, setPath])

    useEffect(() => {
        if (project && permissions) {
            setRole(permissions.find((role) => role.roleName === project.role))
        }
    }, [project, permissions])

    const fetchProject = () => {
        if (pid) {
            setIsLoading(true)
            GetProject(Number.parseInt(pid))
                .then((result) => result.success && setProject(result.data))
                .finally(() => setIsLoading(false))
        }
    }

    const updateProjectRole = (role: string) => {
        if (project) {
            setProject({
                ...project,
                role,
            })
        }
    }

    const hasPermission = (permission: string | string[]) => {
        if (role === undefined) return false

        if (typeof permission === 'string') {
            return role.projectRolePermissions.includes(permission) || false
        } else if (Array.isArray(permission)) {
            return permission.some((p) => role.projectRolePermissions.includes(p))
        }

        return false
    }

    useEffect(fetchProject, [pid])

    return (
        <ProjectContext.Provider
            value={{
                role,
                updateProjectRole,
                drawerContent,
                project,
                fetchProject,
                isLoading,
                hasPermission,
            }}
        >
            {children}
        </ProjectContext.Provider>
    )
}
