//* ======= Libraries
import React, { ReactNode } from 'react'
//* ======= Components and features
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
//* ======= Assets and styles

type LiteTemplateCardProps = {
    name: string
    countBadge?: number
    topRightComponent?: ReactNode
    image?: string | null
    onClick?: () => void
}

function LiteTemplateCard({ name, countBadge, topRightComponent, image, onClick }: LiteTemplateCardProps) {
    return (
        <BaseButton
            onClick={onClick}
            color="secondary"
            sx={(theme) => ({
                flexShrink: 0,

                height: '136px',
                width: '200px',
                padding: 0,

                overflow: 'hidden',
                borderRadius: '10px',
                border: `1px solid ${theme.palette.common.border_2}`,
            })}
        >
            <Stack
                sx={(theme) => ({
                    position: 'relative',

                    minHeight: 0,
                    height: '100%',
                    width: '100%',

                    backgroundColor: 'common.bg_3',
                })}
            >
                {/* Badge
					========================================= */}
                {countBadge && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '12px',

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            height: '24px',
                            width: '24px',
                            fontWeight: 400,

                            borderRadius: '50%',
                            backgroundColor: 'primary.main',
                            color: 'common.text_4',
                        }}
                    >
                        {countBadge}
                    </Box>
                )}

                {/* Top right component
					========================================= */}
                {topRightComponent && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                        }}
                    >
                        {topRightComponent}
                    </Box>
                )}

                {/* Image
					========================================= */}
                {image ? (
                    <img
                        src={image}
                        alt={name}
                        style={{
                            // '42px' is the exact height of 'Name' box.
                            height: 'calc(100% - 42px)',
                            objectFit: 'cover',
                            objectPosition: 'center',

                            backgroundColor: 'transparent',
                        }}
                    />
                ) : (
                    <Paper
                        elevation={0}
                        sx={{
                            flexGrow: 1,

                            width: '100%',

                            backgroundColor: 'transparent',
                        }}
                    />
                )}

                {/* Name
					========================================= */}
                <Box
                    sx={{
                        flexShrink: 0,

                        display: 'flex',
                        alignItems: 'center',

                        height: '42px',
                        px: '12px',

                        backgroundColor: 'common.bg_1',
                    }}
                >
                    <Typography textAlign="start" fontSize={14} fontWeight={400} color="secondary" noWrap>
                        {name}
                    </Typography>
                </Box>
            </Stack>
        </BaseButton>
    )
}

export default LiteTemplateCard
