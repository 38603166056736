//* ======= Libraries
import React, { useState, useMemo, useEffect } from 'react'
import { Stack, Box, IconButton, Tooltip, tooltipClasses, ClickAwayListener, Typography, Divider } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { InfoWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import EmergencyRecordingRoundedIcon from '@mui/icons-material/EmergencyRecordingRounded'
import BaseButton from 'components/base/BaseButton'
import ReactPlayer from 'react-player'
import StyledDialog from 'components/dialog/StyledDialog'

type Props = {
    data: InfoWidgetType
    isActive: boolean
    onReady: () => void
}

function InfoWidget({ data, isActive, onReady }: Props) {
    const { viewMode } = useReportStore((store) => ({
        viewMode: store.viewMode,
    }))

    const isVideoSet = useMemo(() => data.video.source.trim() !== '', [data.video.source])

    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false)

    const onButtonClick = () => {
        if (isVideoSet) {
            setIsVideoDialogOpen(true)
        } else {
            setIsTooltipOpen((prevState) => !prevState)
        }
    }

    useEffect(() => {
        onReady()
    }, [])

    let Icon = null

    switch (data.icon.type) {
        case 'exclamation':
            Icon = (
                <InfoOutlinedIcon
                    sx={(theme) => ({
                        width: '100%',
                        height: '100%',
                    })}
                />
            )

            break

        case 'question':
            Icon = (
                <QuestionMarkIcon
                    sx={(theme) => ({
                        width: '100%',
                        height: '100%',
                    })}
                />
            )

            break

        case 'questionOutline':
            Icon = (
                <HelpOutlineIcon
                    sx={(theme) => ({
                        width: '100%',
                        height: '100%',
                    })}
                />
            )

            break

        case 'video':
            Icon = (
                <EmergencyRecordingRoundedIcon
                    sx={(theme) => ({
                        width: '90%',
                        height: '90%',
                    })}
                />
            )

            break

        default:
            break
    }

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={(theme) => ({
                width: '100%',
                height: '100%',
            })}
        >
            {data.tooltip.shouldShowOnHover ? (
                /*  Hover view
                    ========================================= */
                <Tooltip
                    title={data.text.value}
                    disableFocusListener
                    disableTouchListener
                    placement={data.tooltip.placement}
                    arrow
                    PopperProps={{
                        popperOptions: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -8],
                                    },
                                },
                            ],
                        },
                        sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                                fontFamily: data.text.fontFamily,
                                fontSize: data.text.fontSize,
                                whiteSpace: 'pre-wrap',

                                backgroundColor: data.tooltip.backgroundColor.isEnabled
                                    ? data.tooltip.backgroundColor.color
                                    : undefined,
                                color: data.text.color,
                            },
                        },
                    }}
                >
                    <Box
                        sx={(theme) => ({
                            display: 'grid',
                            placeItems: 'center',

                            width: '100%',
                            height: '100%',

                            color: data.icon.color || theme.palette.secondary.main,
                        })}
                    >
                        {Icon}
                    </Box>
                </Tooltip>
            ) : (
                /*  Clickable view
                    ========================================= */
                <ClickAwayListener onClickAway={(evt) => setIsTooltipOpen(false)}>
                    <Tooltip
                        title={isVideoSet ? 'Learn More' : data.text.value}
                        open={isVideoSet ? undefined : isTooltipOpen}
                        onClose={isVideoSet ? undefined : (evt) => setIsTooltipOpen(false)}
                        disableFocusListener
                        disableHoverListener={isVideoSet ? undefined : true}
                        disableTouchListener
                        placement={isVideoSet ? 'bottom' : data.tooltip.placement}
                        arrow
                        PopperProps={{
                            popperOptions: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -8],
                                        },
                                    },
                                ],
                            },
                            sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                    fontFamily: isVideoSet ? 'Poppins' : data.text.fontFamily,
                                    fontSize: isVideoSet ? 12 : data.text.fontSize,
                                    whiteSpace: 'pre-wrap',

                                    backgroundColor: data.tooltip.backgroundColor.isEnabled
                                        ? data.tooltip.backgroundColor.color
                                        : undefined,
                                    color: isVideoSet ? undefined : data.text.color,
                                },
                            },
                        }}
                    >
                        <IconButton
                            onClick={(evt) => onButtonClick()}
                            sx={(theme) => ({
                                width: '100%',
                                height: '100%',
                                padding: 0,
                                // Allow the first click on the widget to select the widget and
                                // set activeWidgetId and if that's set, allow interaction.
                                pointerEvents: viewMode === 'design' ? (isActive ? 'auto' : 'none') : undefined,

                                color: data.icon.color || theme.palette.secondary.main,
                            })}
                        >
                            {Icon}
                        </IconButton>
                    </Tooltip>
                </ClickAwayListener>
            )}

            {/* (Out-of-flow) Video dialog
                ========================================= */}
            {isVideoDialogOpen && (
                <StyledDialog
                    open={isVideoDialogOpen}
                    // Prevent backdrop clicks and ESC key from closing the dialog by accident.
                    onClose={() => undefined}
                    fullWidth={true}
                    maxWidth="lg"
                >
                    {/* Content
                        ========================================= */}
                    <Stack
                        gap={1}
                        sx={(theme) => ({
                            flex: '1 0 auto',

                            maxHeight: '70vh',
                            padding: theme.spacing(3),
                            overflow: 'hidden',
                        })}
                    >
                        {/* Text
                            ========================================= */}
                        <Typography
                            sx={(theme) => ({
                                flex: '0 0 auto',

                                fontFamily: data.text.fontFamily,
                                fontSize: data.text.fontSize,
                                whiteSpace: 'pre-wrap',

                                color: data.text.color,
                            })}
                        >
                            {data.text.value}
                        </Typography>

                        <Divider
                            sx={(theme) => ({
                                marginBottom: theme.spacing(2),
                            })}
                        />

                        {/* Player wrapper
                            ========================================= */}
                        <Box
                            sx={{
                                aspectRatio: '16 / 9',
                                width: '100%',
                            }}
                        >
                            <ReactPlayer url={data.video.source} controls light width="100%" height="100%" />
                        </Box>
                    </Stack>

                    {/* Footer
                        ========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={2}
                        sx={(theme) => ({
                            flex: '0 0 auto',

                            padding: theme.spacing(2, 3),
                        })}
                    >
                        <BaseButton
                            label="Close"
                            onClick={(evt) => setIsVideoDialogOpen(false)}
                            variant="outlined"
                            color="secondary"
                            sx={(theme) => ({
                                minWidth: 180,
                                paddingY: 1,

                                color: theme.palette.common.fill_1,
                            })}
                        />
                    </Stack>
                </StyledDialog>
            )}
        </Stack>
    )
}

export default InfoWidget
