//* ======= Libraries
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { isEqual } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Stack, Box, Typography, IconButton, Tooltip, Divider } from '@mui/material'
//* ======= Components and features
import FlexibleSelect, {
    FlexibleSelectGroupedOptionType,
    FlexibleSelectOptionType,
} from 'components/group-field-selector/FlexibleSelect'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import {
    TableDefinitionColumn,
    TableDefinitionModeEachRow,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import TableDefinitionColumnItem, {
    TableDefinitionColumnItemDragTypeValue,
} from 'features/report-designer/widgets/table-widget/views/TableDefinitionColumnItem'
import {
    DECIMAL_PRECISION_OPTIONS,
    SORT_ORDER_OPTIONS,
} from 'features/report-designer/helpers/reportDesigner.constants'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import { ReportDataSourceType, SelectedDataSourceType } from 'features/report-designer/types/reportDesigner.types'

type Props = {
    definition: TableDefinitionModeEachRow
    selectedDataSource: SelectedDataSourceType | null
    dataSources: ReportDataSourceType[]
    fieldOptions: FlexibleSelectOptionType[]
    onUpdate: (data: TableDefinitionModeEachRow) => void
    disabled: boolean
}

function TableDefinitionEachRowSettings({
    selectedDataSource,
    dataSources,
    definition,
    fieldOptions,
    onUpdate,
    disabled,
}: Props) {
    // We need a clone of columns list to handle drag&drop actions internally (on item move)
    // and then update the actual store state only once (on item move end).
    const [columnsListClone, setColumnsListClone] = useState<TableDefinitionColumn[]>([])

    // Sync columns list clone with the definition's list on change.
    useEffect(() => {
        setColumnsListClone(definition.columns)
    }, [definition.columns])

    const updateDefinitionField = (
        field: keyof TableDefinitionModeEachRow,
        value: TableDefinitionModeEachRow[keyof TableDefinitionModeEachRow]
    ) => {
        const needsUpdate = isEqual(definition[field], value) === false

        if (needsUpdate) {
            onUpdate({
                ...definition,
                [field]: value,
            })
        }
    }

    /* =========================================
     * Columns list methods
     */

    const addColumn = () => {
        onUpdate({
            ...definition,
            columns: [
                ...definition.columns,
                {
                    id: uuidv4(),
                    field: null,
                    advanced: {
                        dataType: 'string',
                        valueMapping: {
                            isEnabled: false,
                            categories: [],
                        },
                    },
                    // We purposefully don't add an "aggregation" property because this data definition "mode" doesn't need one
                    // and also because the presence of that property will show a select component in "DefinitionColumnItem".
                },
            ],
        })
    }

    const updateColumn = (data: TableDefinitionColumn) => {
        onUpdate({
            ...definition,
            columns: definition.columns.map((_column) => {
                if (_column.id === data.id) {
                    return { ...data }
                } else {
                    return _column
                }
            }),
        })
    }

    const removeColumn = (columnId: TableDefinitionColumn['id']) => {
        onUpdate({
            ...definition,
            columns: definition.columns.filter((_column) => _column.id !== columnId),
        })
    }

    /* =========================================
     * Columns list items drag and drop
     */

    // Helps with visual drop cursor style when dragging list items. Doesn't do anything else.
    const [, dropTarget] = useDrop(() => ({ accept: TableDefinitionColumnItemDragTypeValue }))

    const findColumnCloneIndexById = useCallback(
        (id: TableDefinitionColumn['id']) => {
            return definition.columns.findIndex((_column) => _column.id === id)
        },
        [definition.columns]
    )

    // Update columns list clone with the new positions.
    const onColumnMove = useCallback(
        (columnId: TableDefinitionColumn['id'], newIndex: number) => {
            const targetIndex = findColumnCloneIndexById(columnId)

            const targetColumn = columnsListClone[targetIndex]

            if (targetColumn !== undefined) {
                const updatedColumns = [...columnsListClone]
                updatedColumns.splice(targetIndex, 1)
                updatedColumns.splice(newIndex, 0, targetColumn)

                setColumnsListClone(updatedColumns)
            }
        },
        [findColumnCloneIndexById, columnsListClone]
    )

    // Update store state with the updated column positions.
    const onColumnMoveEnd = () => {
        onUpdate({
            ...definition,
            columns: [...columnsListClone],
        })
    }

    const compareWithOption: FlexibleSelectOptionType[] = useMemo(() => {
        if (selectedDataSource === null) {
            return []
        }
        const dataSource = dataSources.find((ds) => ds.id === selectedDataSource.id)
        if (dataSource === undefined) {
            return []
        }

        const panels = Object.keys(dataSource.data).filter((key) => key !== selectedDataSource.panel)

        if (panels.length > 0) {
            return [
                {
                    group: 'panels',
                    items: panels.map((panel) => ({
                        value: {
                            type: 'panel',
                            field: panel,
                        },
                        label: panel,
                    })),
                },
                ...fieldOptions,
            ]
        } else {
            return fieldOptions
        }
    }, [selectedDataSource, fieldOptions, dataSources])

    return (
        <Stack gap={2}>
            {/* ID field and precision
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={2}>
                {/* ID field
                    ========================================= */}
                <FlexibleSelect
                    label="ID Field"
                    value={definition.idField}
                    options={fieldOptions}
                    onChange={(value) => updateDefinitionField('idField', value)}
                    disabled={disabled}
                    required
                    fullWidth
                />
                {/* Sort field
                    ========================================= */}
                <FlexibleSelect
                    label="Compare with"
                    value={definition.compareWith || null}
                    options={compareWithOption}
                    onChange={(value) => updateDefinitionField('compareWith', value)}
                    disabled={disabled}
                    hasClearButton
                    clearedReturnValue={null}
                    fullWidth
                />
                {/* Sort field
                    ========================================= */}
                <FlexibleSelect
                    label="Sort By"
                    value={definition.sortField}
                    options={fieldOptions}
                    onChange={(value) => updateDefinitionField('sortField', value)}
                    disabled={disabled}
                    hasClearButton
                    clearedReturnValue={null}
                    fullWidth
                />
            </Stack>

            {/* Sort
                ========================================= */}
            <Stack direction="row" alignItems="center" gap={2}>
                {/* Decimal precision field
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Decimals"
                    options={DECIMAL_PRECISION_OPTIONS}
                    value={definition.decimalPrecision}
                    onChange={(value) => updateDefinitionField('decimalPrecision', value)}
                    disabled={disabled}
                    size="small"
                    fullWidth
                />

                {/* Sort order
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Sort Order"
                    value={definition.sortOrder}
                    options={SORT_ORDER_OPTIONS}
                    onChange={(value) => updateDefinitionField('sortOrder', value)}
                    disabled={disabled}
                    fullWidth
                />
            </Stack>

            <Divider />

            {/* Header config
                ========================================= */}
            <Stack gap={2}>
                <Typography>Header Config:</Typography>

                <Stack direction="row" alignItems="center" gap={1}>
                    {/* Avatar Field
                        ========================================= */}
                    <FlexibleSelect
                        label="Avatar"
                        value={definition.header.avatar}
                        options={fieldOptions}
                        onChange={(value) =>
                            updateDefinitionField('header', {
                                ...definition.header,
                                avatar: value,
                            })
                        }
                        disabled={disabled}
                        hasClearButton
                        clearedReturnValue={null}
                        fullWidth
                        sx={{
                            maxWidth: '50%',
                        }}
                    />

                    {/* Title field
                        ========================================= */}
                    <FlexibleSelect
                        label="Title"
                        value={definition.header.field}
                        options={fieldOptions}
                        onChange={(value) =>
                            updateDefinitionField('header', {
                                ...definition.header,
                                field: value,
                            })
                        }
                        disabled={disabled}
                        required
                        fullWidth
                        sx={{
                            maxWidth: '50%',
                        }}
                    />

                    {/* Subtitle Field
                        ========================================= */}
                    <FlexibleSelect
                        label="Subtitle"
                        value={definition.header.secondaryField}
                        options={fieldOptions}
                        onChange={(value) =>
                            updateDefinitionField('header', {
                                ...definition.header,
                                secondaryField: value,
                            })
                        }
                        disabled={disabled}
                        hasClearButton
                        clearedReturnValue={null}
                        fullWidth
                        sx={{
                            maxWidth: '50%',
                        }}
                    />
                </Stack>
            </Stack>

            <Divider />

            {/* Columns header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                <Typography
                    textAlign="left"
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    Columns:
                </Typography>

                {/* Add column button
                    ========================================= */}
                <Tooltip title="Add Column">
                    {/* Intermediary element to capture Tooltip on button's disabled state. */}
                    <Box>
                        <IconButton onClick={(evt) => addColumn()} disabled={disabled}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Stack>

            {/* Columns list
                ========================================= */}
            <Stack ref={dropTarget} gap={1.5}>
                {columnsListClone.length > 0 ? (
                    columnsListClone.map((_column, idx, array) => (
                        <React.Fragment key={_column.id}>
                            <TableDefinitionColumnItem
                                data={_column}
                                fieldOptions={fieldOptions}
                                onUpdate={updateColumn}
                                onRemove={removeColumn}
                                getPositionIndex={findColumnCloneIndexById}
                                onMove={onColumnMove}
                                onMoveEnd={onColumnMoveEnd}
                                disabled={disabled}
                            />

                            {/* Divider
                                ========================================= */}
                            {idx !== array.length - 1 && <Divider />}
                        </React.Fragment>
                    ))
                ) : (
                    /*  Empty list message
                        ========================================= */
                    <Typography fontSize={14} textAlign="center">
                        (No columns defined.)
                    </Typography>
                )}
            </Stack>
        </Stack>
    )
}

export default TableDefinitionEachRowSettings
