//* ======= Libraries
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Stack, Box, Typography, IconButton, SvgIcon, Tooltip } from '@mui/material'
//* ======= Components and features
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ReportWidgetType, ChartWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import DragHandleIcon from '@mui/icons-material/DragHandle'
import WidgetsIcon from '@mui/icons-material/Widgets'
import TextIcon from '@mui/icons-material/Abc'
import ImageIcon from '@mui/icons-material/Image'
import MultilineChartIcon from '@mui/icons-material/MultilineChart'
import NetworkIcon from '@mui/icons-material/Grain'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import TableIcon from '@mui/icons-material/TableChart'
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt'
import VideoIcon from '@mui/icons-material/Movie'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked'
import DeleteIcon from '@mui/icons-material/Delete'
import ShapeLineIcon from '@mui/icons-material/ShapeLine'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

type CustomDragItemType = {
    dragId: ReportWidgetType['id']
}

export const ReportWidgetsListItemDragTypeValue = 'WIDGETS_LIST_ITEM'

type Props = {
    widgetData: ReportWidgetType
    onClick: (widgetId: ReportWidgetType['id']) => void
    onDelete: (widgetId: ReportWidgetType['id']) => void
    getPositionIndex: (id: ReportWidgetType['id']) => number
    onMove: (id: ReportWidgetType['id'], newIndex: number) => void
    onMoveEnd?: (id: ReportWidgetType['id'], newIndex: number) => void
    onHide: (id: ReportWidgetType['id']) => void
}

function ReportSlideWidgetsListItem({
    widgetData,
    onClick,
    onDelete,
    getPositionIndex,
    onMove,
    onMoveEnd,
    onHide,
}: Props) {
    const [{ isDragging }, dragSource, dragPreview] = useDrag<CustomDragItemType, unknown, { isDragging: boolean }>(
        {
            type: ReportWidgetsListItemDragTypeValue,
            item: () => ({
                dragId: widgetData.id,
            }),
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        },
        [widgetData.id, onMove]
    )

    const [, dropTarget] = useDrop<CustomDragItemType>(
        {
            accept: ReportWidgetsListItemDragTypeValue,
            hover: (item, monitor) => {
                if (item.dragId !== widgetData.id) {
                    const newIndex = getPositionIndex(widgetData.id)

                    onMove(item.dragId, newIndex)
                }
            },
            drop: (item, monitor) => {
                if (onMoveEnd !== undefined) {
                    const newIndex = getPositionIndex(widgetData.id)

                    onMoveEnd(item.dragId, newIndex)
                }
            },
        },
        [getPositionIndex, onMove, onMoveEnd]
    )

    const contentInfo: { label: string; Icon: typeof SvgIcon } = {
        label: 'Widget',
        Icon: WidgetsIcon,
    }
    switch (widgetData.content.kind) {
        case 'text':
            contentInfo.label = 'Text'
            contentInfo.Icon = TextIcon

            break

        case 'image':
            contentInfo.label = 'Image'
            contentInfo.Icon = ImageIcon

            break

        case 'chart':
            if ((widgetData.content.details as ChartWidgetType).type === 'line') {
                contentInfo.label = 'Line'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'bar') {
                contentInfo.label = 'Bar'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'pie') {
                contentInfo.label = 'Pie'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'scatter') {
                contentInfo.label = 'Scatter'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'radar') {
                contentInfo.label = 'Radar'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'boxplot') {
                contentInfo.label = 'Boxplot'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'gauge') {
                contentInfo.label = 'Gauge'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'pictorialBar') {
                contentInfo.label = 'Pictorial Bar'
            } else if ((widgetData.content.details as ChartWidgetType).type === 'wordCloud') {
                contentInfo.label = 'Word Cloud'
            } else {
                contentInfo.label = 'Chart'
            }

            contentInfo.Icon = MultilineChartIcon

            break

        case 'network':
            contentInfo.label = 'Network'
            contentInfo.Icon = NetworkIcon

            break

        case 'filter':
            contentInfo.label = 'Filter'
            contentInfo.Icon = ManageSearchIcon

            break

        case 'table':
            contentInfo.label = 'Table'
            contentInfo.Icon = TableIcon

            break
        case 'insight':
            contentInfo.label = 'Insight'
            contentInfo.Icon = PsychologyAltIcon

            break

        case 'video':
            contentInfo.label = 'Video'
            contentInfo.Icon = VideoIcon

            break

        case 'info':
            contentInfo.label = 'Info'
            contentInfo.Icon = InfoOutlinedIcon

            break

        case 'navLink':
            contentInfo.label = 'Nav Link'
            contentInfo.Icon = DatasetLinkedIcon

            break

        case 'horizontalLine':
            contentInfo.label = 'Horizontal Line'
            contentInfo.Icon = ShapeLineIcon

            break

        case 'verticalLine':
            contentInfo.label = 'Vertical Line'
            contentInfo.Icon = ShapeLineIcon

            break

        default:
            break
    }

    return (
        <Stack
            ref={(node: HTMLDivElement) => dragPreview(dropTarget(node))}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
                opacity: isDragging ? 0.1 : 1,
            })}
        >
            {/* Drag handle
                ========================================= */}
            <Box
                ref={dragSource}
                sx={(theme) => ({
                    flexShrink: 0,

                    width: 40,
                    height: '100%',
                    paddingX: theme.spacing(1),
                    overflow: 'hidden',
                    cursor: 'grab',
                })}
            >
                <DragHandleIcon
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Box>

            {/* Widget button
                ========================================= */}
            <BaseButton
                onClick={(evt) => onClick(widgetData.id)}
                disableElevation
                color="secondary"
                sx={(theme) => ({
                    flexGrow: 1,

                    minWidth: 0,
                    paddingY: theme.spacing(0.5),

                    borderRadius: 0,
                    color: theme.palette.common.text_1,
                })}
            >
                <Stack
                    gap={0.25}
                    alignItems="flex-start"
                    sx={{
                        minWidth: 0,
                        width: '100%',
                    }}
                >
                    {/* Widget title
                        ========================================= */}
                    <Tooltip title={widgetData.title} enterDelay={500} arrow leaveDelay={0}>
                        <Typography fontSize={14} fontWeight={500} width="100%" noWrap textAlign="left">
                            {widgetData.title}
                        </Typography>
                    </Tooltip>

                    <Stack direction="row" alignItems="center" gap={1}>
                        {/* Widget kind icon
                            ========================================= */}
                        <contentInfo.Icon
                            color="secondary"
                            sx={{
                                width: 20,
                                height: 20,
                            }}
                        />

                        {/* Widget kind label
                            ========================================= */}
                        <Typography
                            fontSize={10}
                            fontWeight={500}
                            noWrap
                            textAlign="left"
                            color="secondary"
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            {contentInfo.label}
                        </Typography>
                    </Stack>
                </Stack>
            </BaseButton>

            <Stack gap={1}>
                <Tooltip
                    title={widgetData.designerHide === true ? 'Show' : 'Hide'}
                    enterDelay={500}
                    arrow
                    leaveDelay={0}
                >
                    <IconButton
                        onClick={(evt) => onHide(widgetData.id)}
                        size="small"
                        edge="end"
                        sx={(theme) => ({
                            color: theme.palette.common.text_2,
                        })}
                    >
                        {widgetData.designerHide === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                </Tooltip>
                {/* Delete button
                ========================================= */}
                <IconButton
                    onClick={(evt) => onDelete(widgetData.id)}
                    size="small"
                    edge="end"
                    sx={(theme) => ({
                        color: theme.palette.common.ung_pink,
                    })}
                >
                    <DeleteIcon />
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default ReportSlideWidgetsListItem
