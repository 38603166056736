import { assign } from 'lodash'
import { PartialDeep } from 'type-fest'
import { toast } from 'react-toastify'
import { deepMergeCustom } from 'helpers/helpers'
import { ReportDataSourceRowType, ChartWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { formatValueAsLabel } from 'features/report-designer/helpers/reportDesigner.helper'
import {
    ChartTypeType,
    ChartDataDefinitionType,
    ChartBaseConfigType,
    ChartBaseAxesConfigType,
    ChartConfigType,
    ChartBaseConfigOptionsType,
    ChartBaseAxesConfigOptionsType,
    ChartOptionsType,
    ChartType,
    // Line
    LineChartDataDefinitionType,
    LineChartConfigType,
    LineChartOptionsType,
    LineChartDefaultDataDefinition,
    LineChartDefaultConfig,
    LineChartDefaultOptions,
    // Bar
    BarChartDataDefinitionType,
    BarChartConfigType,
    BarChartOptionsType,
    BarChartDefaultDataDefinition,
    BarChartDefaultConfig,
    BarChartDefaultOptions,
    // Pie
    PieChartDataDefinitionType,
    PieChartConfigType,
    PieChartOptionsType,
    PieChartDefaultDataDefinition,
    PieChartDefaultConfig,
    PieChartDefaultOptions,
    // Scatter
    ScatterChartDataDefinitionType,
    ScatterChartConfigType,
    ScatterChartOptionsType,
    ScatterChartDefaultDataDefinition,
    ScatterChartDefaultConfig,
    ScatterChartDefaultOptions,
    // Radar
    RadarChartDataDefinitionType,
    RadarChartConfigType,
    RadarChartOptionsType,
    RadarChartDefaultDataDefinition,
    RadarChartDefaultConfig,
    RadarChartDefaultOptions,
    // Boxplot
    BoxplotChartDataDefinitionType,
    BoxplotChartConfigType,
    BoxplotChartOptionsType,
    BoxplotChartDefaultDataDefinition,
    BoxplotChartDefaultConfig,
    BoxplotChartDefaultOptions,
    // Gauge
    GaugeChartDataDefinitionType,
    GaugeChartConfigType,
    GaugeChartOptionsType,
    GaugeChartDefaultDataDefinition,
    GAUGE_CHART_CONFIG_BASE_VALUES,
    GaugeChartDefaultConfig,
    GaugeChartDefaultOptions,
    // Pictorial bar
    PictorialBarChartDataDefinitionType,
    PictorialBarChartConfigType,
    PictorialBarChartOptionsType,
    PictorialBarChartDefaultDataDefinition,
    PictorialBarChartDefaultConfig,
    PictorialBarChartDefaultOptions,
    // Word cloud
    WordCloudChartDataDefinitionType,
    WordCloudChartConfigType,
    WordCloudChartOptionsType,
    WordCloudChartDefaultDataDefinition,
    WordCloudChartDefaultConfig,
    WordCloudChartDefaultOptions,
    // Graph
    GraphChartDataDefinitionType,
    GraphChartConfigType,
    GraphChartOptionsType,
    GraphChartDefaultDataDefinition,
    GraphChartDefaultConfig,
    GraphChartDefaultOptions,
    // Treemap
    TreemapChartDataDefinitionType,
    TreemapChartConfigType,
    TreemapChartOptionsType,
    TreemapChartDefaultDataDefinition,
    TreemapChartDefaultConfig,
    TreemapChartDefaultOptions,
} from 'features/chart/Chart.asset'
import {
    ParseBarChartData,
    ParseBoxplotChartData,
    ParseGaugeChartData,
    ParseLineChartData,
    ParsePictorialBarChartData,
    ParsePieChartData,
    ParseRadarChartData,
    ParseScatterChartData,
    ParseWordCloudChartData,
    ParseTreemapChartData,
} from './Chart.data.helper'

/* =========================================
 * Returns default/initial values of a chart type.
 */
export const getChartTypeInitialValues = (type: ChartTypeType) => {
    let defaults: ChartType | null = null

    switch (type) {
        case 'line':
            defaults = {
                type: 'line',
                dataDefinition: LineChartDefaultDataDefinition,
                config: LineChartDefaultConfig,
                options: LineChartDefaultOptions,
            }

            break

        case 'bar':
            defaults = {
                type: 'bar',
                dataDefinition: BarChartDefaultDataDefinition,
                config: BarChartDefaultConfig,
                options: BarChartDefaultOptions,
            }

            break

        case 'pie':
            defaults = {
                type: 'pie',
                dataDefinition: PieChartDefaultDataDefinition,
                config: PieChartDefaultConfig,
                options: PieChartDefaultOptions,
            }

            break

        case 'scatter':
            defaults = {
                type: 'scatter',
                dataDefinition: ScatterChartDefaultDataDefinition,
                config: ScatterChartDefaultConfig,
                options: ScatterChartDefaultOptions,
            }

            break

        case 'radar':
            defaults = {
                type: 'radar',
                dataDefinition: RadarChartDefaultDataDefinition,
                config: RadarChartDefaultConfig,
                options: RadarChartDefaultOptions,
            }

            break

        case 'boxplot':
            defaults = {
                type: 'boxplot',
                dataDefinition: BoxplotChartDefaultDataDefinition,
                config: BoxplotChartDefaultConfig,
                options: BoxplotChartDefaultOptions,
            }

            break

        case 'gauge':
            defaults = {
                type: 'gauge',
                dataDefinition: GaugeChartDefaultDataDefinition,
                config: GaugeChartDefaultConfig,
                options: GaugeChartDefaultOptions,
            }

            break

        case 'pictorialBar':
            defaults = {
                type: 'pictorialBar',
                dataDefinition: PictorialBarChartDefaultDataDefinition,
                config: PictorialBarChartDefaultConfig,
                options: PictorialBarChartDefaultOptions,
            }

            break

        case 'wordCloud':
            defaults = {
                type: 'wordCloud',
                dataDefinition: WordCloudChartDefaultDataDefinition,
                config: WordCloudChartDefaultConfig,
                options: WordCloudChartDefaultOptions,
            }

            break

        case 'graph':
            // TODO
            defaults = {
                type: 'graph',
                dataDefinition: GraphChartDefaultDataDefinition,
                config: GraphChartDefaultConfig,
                options: GraphChartDefaultOptions,
            }

            break

        case 'treemap':
            defaults = {
                type: 'treemap',
                dataDefinition: TreemapChartDefaultDataDefinition,
                config: TreemapChartDefaultConfig,
                options: TreemapChartDefaultOptions,
            }

            break

        default:
            break
    }

    return defaults
}

/* =========================================
 * Get partial [ChartOptionsType] options based on chart config.
 */

const getChartTitleOptionsByConfig = (titleConfig: ChartBaseConfigType['title']) => {
    const titleOptions: ChartBaseConfigOptionsType['title'] = {
        show: titleConfig.isEnabled,
        top: titleConfig.verticalAlign,
        left: titleConfig.horizontalAlign,
        // text
        text: titleConfig.value,
        textStyle: {
            fontFamily: titleConfig.styles?.fontFamily,
            fontSize: titleConfig.styles?.fontSize,
            fontWeight: titleConfig.styles?.fontWeight,
            overflow:
                titleConfig.styles?.overflow === 'none'
                    ? 'none'
                    : titleConfig.styles?.overflow === 'truncate'
                    ? 'truncate'
                    : titleConfig.styles?.overflow === 'wrap'
                    ? 'break'
                    : 'none',
            color: titleConfig.styles?.color,
        },
        // subtext
        subtext: titleConfig.secondaryValue,
        subtextStyle: {
            fontFamily: titleConfig.styles?.fontFamily,
            fontSize: titleConfig.styles?.fontSize
                ? titleConfig.styles.fontSize > 14
                    ? Math.floor(titleConfig.styles.fontSize * (2 / 3))
                    : titleConfig.styles.fontSize - 2
                : undefined,
            fontWeight: titleConfig.styles?.fontWeight,
            overflow:
                titleConfig.styles?.overflow === 'none'
                    ? 'none'
                    : titleConfig.styles?.overflow === 'truncate'
                    ? 'truncate'
                    : titleConfig.styles?.overflow === 'wrap'
                    ? 'break'
                    : 'none',
            color: titleConfig.styles?.color,
        },
    }

    return titleOptions
}

const getChartLegendOptionsByConfig = (legendConfig: ChartBaseConfigType['legend']) => {
    const legendOptions: ChartBaseConfigOptionsType['legend'] = {
        show: legendConfig.isEnabled,
        type: 'scroll',
        orient: legendConfig.orientation,
        top: legendConfig.verticalAlign,
        left: legendConfig.horizontalAlign,
        textStyle: {
            fontFamily: legendConfig.styles?.fontFamily,
            fontSize: legendConfig.styles?.fontSize,
            fontWeight: legendConfig.styles?.fontWeight,
            overflow:
                legendConfig.styles?.overflow === 'none'
                    ? 'none'
                    : legendConfig.styles?.overflow === 'truncate'
                    ? 'truncate'
                    : legendConfig.styles?.overflow === 'wrap'
                    ? 'break'
                    : 'none',
            color: legendConfig.styles?.color,
        },
    }

    return legendOptions
}

const getChartBaseXAxisOptionsByConfig = (xAxisConfig: ChartBaseAxesConfigType['xAxisOptions']) => {
    const xAxisOptions: ChartBaseAxesConfigOptionsType['xAxis'] = {
        show: xAxisConfig.isEnabled,
        // bounds
        min: xAxisConfig.bounds.min ?? undefined,
        max: xAxisConfig.bounds.max ?? undefined,
        // name
        name: xAxisConfig.name,
        nameTextStyle: {
            fontFamily: xAxisConfig.labels.styles?.fontFamily,
            fontSize: xAxisConfig.labels.styles?.fontSize,
            fontWeight: xAxisConfig.labels.styles?.fontWeight,
            color: xAxisConfig.labels.styles?.color,
        },
        // axis label
        axisLabel: {
            show: xAxisConfig.labels.isEnabled,
            fontFamily: xAxisConfig.labels.styles?.fontFamily,
            fontSize: xAxisConfig.labels.styles?.fontSize,
            fontWeight: xAxisConfig.labels.styles?.fontWeight,
            overflow:
                xAxisConfig.labels.styles?.overflow === 'none'
                    ? 'none'
                    : xAxisConfig.labels.styles?.overflow === 'truncate'
                    ? 'truncate'
                    : xAxisConfig.labels.styles?.overflow === 'wrap'
                    ? 'break'
                    : 'none',
            color: xAxisConfig.labels.styles?.color,
            interval: xAxisConfig.labels.forceAllVisible ? 0 : 'auto',
            rotate: xAxisConfig.labels.rotation,
        },
    }

    return xAxisOptions
}

const getChartBaseYAxisOptionsByConfig = (yAxisConfig: ChartBaseAxesConfigType['yAxisOptions']) => {
    const yAxisOptions: ChartBaseAxesConfigOptionsType['yAxis'] = {
        show: yAxisConfig.isEnabled,
        // bounds
        min: yAxisConfig.bounds.min ?? undefined,
        max: yAxisConfig.bounds.max ?? undefined,
        // name
        name: yAxisConfig.name,
        nameTextStyle: {
            fontFamily: yAxisConfig.labels.styles?.fontFamily,
            fontSize: yAxisConfig.labels.styles?.fontSize,
            fontWeight: yAxisConfig.labels.styles?.fontWeight,
            color: yAxisConfig.labels.styles?.color,
        },
        // axis label
        axisLabel: {
            show: yAxisConfig.labels.isEnabled,
            fontFamily: yAxisConfig.labels.styles?.fontFamily,
            fontSize: yAxisConfig.labels.styles?.fontSize,
            fontWeight: yAxisConfig.labels.styles?.fontWeight,
            overflow:
                yAxisConfig.labels.styles?.overflow === 'none'
                    ? 'none'
                    : yAxisConfig.labels.styles?.overflow === 'truncate'
                    ? 'truncate'
                    : yAxisConfig.labels.styles?.overflow === 'wrap'
                    ? 'break'
                    : 'none',
            color: yAxisConfig.labels.styles?.color,
            interval: yAxisConfig.labels.forceAllVisible ? 0 : 'auto',
            rotate: yAxisConfig.labels.rotation,
        },
    }

    return yAxisOptions
}

export type GenerateChartOptionsParamsModeType = 'all' | 'data' | 'config'

type GenerateChartOptionsParams = {
    type: ChartTypeType
    mode: GenerateChartOptionsParamsModeType
    // "null" value for options should represent a chart with no data.
    prevOptions: ChartOptionsType | null
    // Optional: Based on the "mode", some arguments might be optional.
    filteredData: ReportDataSourceRowType[] | undefined
    // Optional: This is needed for calcuation of benchmark series, e.g. in radar chart, for calculating the average series
    unfilteredData?: ReportDataSourceRowType[]
    // Optional: Based on the "mode", some arguments might be optional.
    dataDefinition: ChartDataDefinitionType
    // Optional: Based on the "mode", some arguments might be optional.
    config: ChartConfigType
}

/* =========================================
 * Generate a complete chart options object based on data definitions and config.
 * The result can directly be used in Chart component.
 */

/*
 * Notes:
 *  1.  For every chart, the eventual "options" object of type [ChartOptionsType] is produced by following these steps:
 *          a)  Each chart type has its own default "options" object which is of type [ChartOptionsType] and
 *              contains the initial values.
 *              The [ChartOptionsType] is a manually-created sub-type of "EChartsCustomOptionType" based on Echarts' documentation.
 *
 *              This object consists of smaller partial objects. The 2 main, smaller objects are:
 *                  -   A [ChartType] data-related options object which contains all the properties that will be affected while
 *                      processing [ChartDataDefinitionType] values.
 *                  -   A [ChartType] config options object which contains the values that are changed while processing
 *                      [ChartConfigType] values.
 *
 *              There can be some overlapping values between the two partial objects, since some chart types' use-cases
 *              require updating both data definitions and config options.
 *
 *              The "options" object also has some "static" values that won't be assigned again in the process of
 *              generating new options (although it is completely possible to do so).
 *              The static values represent the options that users won't need to know about or change.
 *
 *              This initial options object is used as the base value for later generator functions.
 *
 *          b)  Based on [ChartDataDefinitionType], each chart will process user-selected data definitions and generate
 *              a partial [ChartOptionsType] which has the data-related properties filled with new values.
 *
 *              In this step, there are chart types that require more than just the data definition values to be able to
 *              process data. These charts' data generator functions will also need to integrate [ChartConfigType] into
 *              their process.
 *
 *          c)  The partial [ChartOptionsType] from previous step is then used as the basis for generating config-related options
 *              based on user-selected [ChartConfigType] values.
 *
 *              The result is another partial [ChartOptionsType] object which is then merged with the previous "options" object
 *              and forms the final and complete [ChartOptionsType].
 *
 *
 *  2.  The actual Chart component only needs the final options object in a form that is compatible with "EChartsCustomOptionType".
 */

export const generateChartOptions = ({
    type,
    mode,
    prevOptions,
    filteredData,
    unfilteredData,
    dataDefinition,
    config,
}: GenerateChartOptionsParams): ChartOptionsType | null => {
    try {
        let options: ChartOptionsType = {}

        // "null" value for options should represent a chart with no data.
        if (prevOptions === null) {
            const chartInitialValues = getChartTypeInitialValues(type)

            if (chartInitialValues !== null && chartInitialValues.options !== null) {
                options = structuredClone(chartInitialValues.options)
            }
        } else {
            options = structuredClone(prevOptions)
        }

        switch (type) {
            case 'line':
                options = generateLineChartOptions({
                    mode,
                    prevOptions: options as LineChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as LineChartDataDefinitionType,
                    config: config as LineChartConfigType,
                })

                break

            case 'bar':
                options = generateBarChartOptions({
                    mode,
                    prevOptions: options as BarChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as BarChartDataDefinitionType,
                    config: config as BarChartConfigType,
                })

                break

            case 'pie':
                options = generatePieChartOptions({
                    mode,
                    prevOptions: options as PieChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as PieChartDataDefinitionType,
                    config: config as PieChartConfigType,
                })

                break

            case 'scatter':
                options = generateScatterChartOptions({
                    mode,
                    prevOptions: options as ScatterChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as ScatterChartDataDefinitionType,
                    config: config as ScatterChartConfigType,
                })

                break

            case 'radar':
                options = generateRadarChartOptions({
                    mode,
                    prevOptions: options as RadarChartOptionsType,
                    filteredData,
                    unfilteredData,
                    dataDefinition: dataDefinition as RadarChartDataDefinitionType,
                    config: config as RadarChartConfigType,
                })

                break

            case 'boxplot':
                options = generateBoxplotChartOptions({
                    mode,
                    prevOptions: options as BoxplotChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as BoxplotChartDataDefinitionType,
                    config: config as BoxplotChartConfigType,
                })

                break

            case 'gauge':
                options = generateGaugeChartOptions({
                    mode,
                    prevOptions: options as GaugeChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as GaugeChartDataDefinitionType,
                    config: config as GaugeChartConfigType,
                })

                break

            case 'pictorialBar':
                options = generatePictorialBarChartOptions({
                    mode,
                    prevOptions: options as PictorialBarChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as PictorialBarChartDataDefinitionType,
                    config: config as PictorialBarChartConfigType,
                })

                break

            case 'wordCloud':
                options = generateWordCloudChartOptions({
                    mode,
                    prevOptions: options as WordCloudChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as WordCloudChartDataDefinitionType,
                    config: config as WordCloudChartConfigType,
                })

                break
            case 'treemap':
                options = generateTreemapChartOptions({
                    mode,
                    prevOptions: options as TreemapChartOptionsType,
                    filteredData,
                    dataDefinition: dataDefinition as TreemapChartDataDefinitionType,
                    config: config as TreemapChartConfigType,
                })

                break
            default:
                break
        }

        return structuredClone(options)
    } catch (error: any) {
        console.error(error)

        if ('message' in error) {
            toast.error(error.message)
        }
    }

    return null
}

type GenerateSingleChartOptionsParams<
    T extends ChartOptionsType,
    K extends ChartDataDefinitionType,
    U extends ChartConfigType
> = {
    mode: GenerateChartOptionsParams['mode']
    prevOptions: T
    filteredData: GenerateChartOptionsParams['filteredData']
    unfilteredData?: GenerateChartOptionsParams['unfilteredData']
    dataDefinition: K
    config: U
}

/*
 * Line chart
 * =========================================
 */

export type LineChartConfigOptionsType = ChartBaseConfigOptionsType &
    ChartBaseAxesConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
    }>

const generateLineChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<LineChartOptionsType, LineChartDataDefinitionType, LineChartConfigType>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: LineChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseLineChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: deepMergeCustom(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: deepMergeCustom(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
            // X-Axis settings
            xAxis: deepMergeCustom(
                structuredClone(options.xAxis),
                getChartBaseXAxisOptionsByConfig(config.xAxisOptions)
            ),
            // Y-Axis settings
            yAxis: deepMergeCustom(
                structuredClone(options.yAxis),
                getChartBaseYAxisOptionsByConfig(config.yAxisOptions)
            ),
        }
    }

    return options
}

/*
 * Bar chart
 * =========================================
 */

export type BarChartConfigOptionsType = ChartBaseConfigOptionsType &
    ChartBaseAxesConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
    }>

const generateBarChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<BarChartOptionsType, BarChartDataDefinitionType, BarChartConfigType>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: BarChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseBarChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
            // X-Axis settings
            xAxis: assign(structuredClone(options.xAxis), getChartBaseXAxisOptionsByConfig(config.xAxisOptions)),
            // Y-Axis settings
            yAxis: assign(structuredClone(options.yAxis), getChartBaseYAxisOptionsByConfig(config.yAxisOptions)),
        }
    }

    return options
}

/*
 * Pie chart
 * =========================================
 */

export type PieChartConfigOptionsType = ChartBaseConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
    }>

const generatePieChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<PieChartOptionsType, PieChartDataDefinitionType, PieChartConfigType>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: PieChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParsePieChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
        }
    }

    return options
}

/*
 * Scatter chart
 * =========================================
 */

export type ScatterChartConfigOptionsType = ChartBaseConfigOptionsType &
    ChartBaseAxesConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
    }>

const generateScatterChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<
    ScatterChartOptionsType,
    ScatterChartDataDefinitionType,
    ScatterChartConfigType
>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: ScatterChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseScatterChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
            // X-Axis settings
            xAxis: assign(structuredClone(options.xAxis), getChartBaseXAxisOptionsByConfig(config.xAxisOptions)),

            // Y-Axis settings
            yAxis: assign(structuredClone(options.yAxis), getChartBaseYAxisOptionsByConfig(config.yAxisOptions)),
        }
    }

    return options
}

/*
 * Radar chart
 * =========================================
 */

export type RadarChartConfigOptionsType = ChartBaseConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
        //? Static option. Only assigned inside initial/default options object.
        grid: {
            containLabel: boolean
        }
        radar: {
            //? Static option. Only assigned inside initial/default options object.
            radius: (number | string)[] | number | string
            shape: 'polygon' | 'circle'
            splitArea: {
                show: boolean
                areaStyle: {
                    color: string[] | undefined
                    //? Static option. Only assigned inside initial/default options object.
                    opacity: number
                }
            }
            splitLine: {
                show: boolean
            }
            axisLine: {
                show: boolean
            }
        }
        series: {
            symbol: string
            symbolSize: number
        }
    }>

const generateRadarChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    unfilteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<RadarChartOptionsType, RadarChartDataDefinitionType, RadarChartConfigType>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: RadarChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseRadarChartData({
                dataDefinition,
                unfilteredData,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        const radarOptions = { ...options.radar }

        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
            // Radar settings
            radar: {
                ...options.radar,
                shape: config.shape,
                splitArea: {
                    ...radarOptions.splitArea,
                    show: config.splitArea.isEnabled,
                    areaStyle: {
                        ...radarOptions.splitArea?.areaStyle,
                        color: config.splitArea.colors,
                    },
                },
                splitLine: {
                    ...radarOptions.splitLine,
                    show: config.hasSplitLines,
                },
                axisLine: {
                    ...radarOptions.axisLine,
                    show: config.hasAxisLines,
                },
            },
            series: {
                ...options.series,
                symbol: config.symbols.shape,
                symbolSize: config.symbols.size,
            },
        }
    }

    return options
}

/*
 * Boxplot chart
 * =========================================
 */

export type BoxplotChartConfigOptionsType = ChartBaseConfigOptionsType &
    ChartBaseAxesConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
        //? Static option. Only assigned inside initial/default options object.
        legend: {
            selected: {
                detail: boolean
            }
        }
    }>

const generateBoxplotChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<
    BoxplotChartOptionsType,
    BoxplotChartDataDefinitionType,
    BoxplotChartConfigType
>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: BoxplotChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseBoxplotChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
            // X-Axis settings
            xAxis: assign(structuredClone(options.xAxis), getChartBaseXAxisOptionsByConfig(config.xAxisOptions)),

            // Y-Axis settings
            yAxis: assign(structuredClone(options.yAxis), getChartBaseYAxisOptionsByConfig(config.yAxisOptions)),
        }
    }

    return options
}

/*
 * Gauge chart
 * =========================================
 */

export type GaugeChartConfigOptionsType = ChartBaseConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
        series: {
            radius: string | number
            startAngle: number
            endAngle: number
            center: (string | number)[]
            pointer: {
                show: boolean
            }
            progress: {
                show: boolean
                //? Static option. Only assigned inside initial/default options object.
                overlap: boolean
                //? Static option. Only assigned inside initial/default options object.
                clip: boolean
                roundCap: boolean
                itemStyle: {
                    borderWidth: number
                    borderType: 'solid' | 'dotted' | 'dashed'
                    borderColor: string | undefined
                }
            }
            axisLine: {
                show: boolean
                roundCap: boolean
                lineStyle: {
                    width: number
                    color: [number, string][]
                }
            }
            splitNumber: number
            splitLine: {
                show: boolean
                length: number
                distance: number
                lineStyle: {
                    //? Static option. Only assigned inside initial/default options object.
                    width: number
                    color: string | undefined
                }
            }
            //? Static option. Only assigned inside initial/default options object.
            axisTick: {
                show: boolean
                length: string | number
            }
            axisLabel: {
                show: boolean
                distance: number
                //? Static option. Only assigned inside initial/default options object.
                width: string | number
                fontFamily: string
                fontSize: string | number
                fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
                overflow: 'break' | 'breakAll' | 'truncate' | 'none'
                color: string | undefined
            }
            detail: {
                show: boolean
                //? Static option. Only assigned inside initial/default options object.
                valueAnimation: boolean
                offsetCenter: (string | number)[]
                fontFamily: string
                fontSize: string | number
                fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number
                color: string | undefined
            }
        }
    }>

const generateGaugeChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<GaugeChartOptionsType, GaugeChartDataDefinitionType, GaugeChartConfigType>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: GaugeChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseGaugeChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        const gaugeSeriesOptions = { ...options.series }

        // Creating the correct series options based on selected "viewMode".
        let viewModeDependantSeriesOptions: Partial<GaugeChartOptionsType['series']> = {
            radius: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.radius,
            startAngle: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.startAngle,
            endAngle: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.endAngle,
            center: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.center,
            pointer: {
                show: false,
            },
            progress: {
                show: true,
            },
            axisLine: {
                lineStyle: {
                    width: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.axisLineWidth,
                },
            },
            splitLine: {
                length: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.splitLineLength,
                distance: -40,
            },
            axisLabel: {
                distance: -20,
            },
        }
        switch (config.viewMode) {
            case 'gauge':
                viewModeDependantSeriesOptions = {
                    radius: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.radius,
                    startAngle: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.startAngle,
                    endAngle: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.endAngle,
                    center: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.center,
                    pointer: {
                        show: false,
                    },
                    progress: {
                        show: true,
                    },
                    axisLine: {
                        lineStyle: {
                            // Calculated inside data generation function.
                            width: gaugeSeriesOptions?.axisLine?.lineStyle?.width,
                        },
                    },
                    splitLine: {
                        length: GAUGE_CHART_CONFIG_BASE_VALUES.gauge.splitLineLength,
                        // Calculated inside data generation function.
                        distance: gaugeSeriesOptions?.splitLine?.distance,
                    },
                    axisLabel: {
                        distance: gaugeSeriesOptions?.axisLabel?.distance,
                    },
                }

                break

            case 'ring':
                viewModeDependantSeriesOptions = {
                    radius: GAUGE_CHART_CONFIG_BASE_VALUES.ring.radius,
                    startAngle: GAUGE_CHART_CONFIG_BASE_VALUES.ring.startAngle,
                    endAngle: GAUGE_CHART_CONFIG_BASE_VALUES.ring.endAngle,
                    center: GAUGE_CHART_CONFIG_BASE_VALUES.ring.center,
                    pointer: {
                        show: false,
                    },
                    progress: {
                        show: true,
                    },
                    axisLine: {
                        lineStyle: {
                            // Calculated inside data generation function.
                            width: gaugeSeriesOptions?.axisLine?.lineStyle?.width,
                        },
                    },
                    splitLine: {
                        length: GAUGE_CHART_CONFIG_BASE_VALUES.ring.splitLineLength,
                        // Calculated inside data generation function.
                        distance: gaugeSeriesOptions?.splitLine?.distance,
                    },
                    axisLabel: {
                        distance: gaugeSeriesOptions?.axisLabel?.distance,
                    },
                }

                break

            case 'speedometer':
                viewModeDependantSeriesOptions = {
                    radius: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.radius,
                    startAngle: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.startAngle,
                    endAngle: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.endAngle,
                    center: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.center,
                    pointer: {
                        show: true,
                    },
                    progress: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            // Static
                            width: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.axisLineWidth,
                        },
                    },
                    splitLine: {
                        length: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.splitLineLength,
                        // Static
                        distance: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.splitLineDistance,
                    },
                    axisLabel: {
                        // Static
                        distance: GAUGE_CHART_CONFIG_BASE_VALUES.speedometer.axisLabelDistance,
                    },
                }

                break

            default:
                break
        }

        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
            series: {
                ...gaugeSeriesOptions,
                radius: viewModeDependantSeriesOptions.radius,
                startAngle: viewModeDependantSeriesOptions.startAngle,
                endAngle: viewModeDependantSeriesOptions.endAngle,
                center: viewModeDependantSeriesOptions.center,
                pointer: {
                    ...gaugeSeriesOptions?.pointer,
                    show: viewModeDependantSeriesOptions.pointer?.show,
                },
                progress: {
                    ...gaugeSeriesOptions?.progress,
                    show: viewModeDependantSeriesOptions.progress?.show,
                    roundCap: config.progressBar.roundCap,
                    itemStyle: config.progressBar.borderStyle.isEnabled
                        ? {
                              ...gaugeSeriesOptions?.progress?.itemStyle,
                              borderWidth: config.progressBar.borderStyle.width,
                              borderType: config.progressBar.borderStyle.type,
                              borderColor: config.progressBar.borderStyle.color,
                          }
                        : undefined,
                },
                axisLine: {
                    ...gaugeSeriesOptions.axisLine,
                    show: config.axisLine.isEnabled,
                    roundCap: config.axisLine.roundCap,
                    lineStyle: {
                        ...gaugeSeriesOptions?.axisLine,
                        width: viewModeDependantSeriesOptions.axisLine?.lineStyle?.width,
                        color: gaugeSeriesOptions.axisLine?.lineStyle?.color,
                    },
                },
                splitNumber: config.splitNumber,
                splitLine: {
                    ...gaugeSeriesOptions?.splitLine,
                    show: config.splitLine.isEnabled,
                    length: viewModeDependantSeriesOptions.splitLine?.length,
                    distance: viewModeDependantSeriesOptions.splitLine?.distance,
                    lineStyle: {
                        ...gaugeSeriesOptions?.splitLine?.lineStyle,
                        color: config.splitLine.color,
                    },
                },
                axisLabel: {
                    ...gaugeSeriesOptions?.axisLabel,
                    show: config.labels.isEnabled,
                    distance: viewModeDependantSeriesOptions.axisLabel?.distance,
                    fontFamily: config.labels.styles.fontFamily,
                    fontSize: config.labels.styles.fontSize,
                    fontWeight: config.labels.styles.fontWeight,
                    overflow:
                        config.labels.styles?.overflow === 'none'
                            ? 'none'
                            : config.labels.styles?.overflow === 'truncate'
                            ? 'truncate'
                            : config.labels.styles?.overflow === 'wrap'
                            ? 'break'
                            : 'none',
                    color: config.labels.styles.color,
                },
                detail: {
                    ...gaugeSeriesOptions?.detail,
                    show: config.valueLabel.isEnabled,
                    offsetCenter: [0, `${config.valueLabel.verticalOffset}%`],
                    fontFamily: config.valueLabel.styles.fontFamily,
                    fontSize: config.valueLabel.styles.fontSize,
                    fontWeight: config.valueLabel.styles.fontWeight,
                    // Calculated inside data generation function.
                    color: gaugeSeriesOptions.detail?.color,
                },
            },
        }
    }

    return options
}

/*
 * Pictorial bar chart
 * =========================================
 */

export type PictorialBarChartConfigOptionsType = ChartBaseConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
        //? Static option. Only assigned inside initial/default options object.
        xAxis: {
            splitLine: {
                show: boolean
            }
            axisLabel: {
                show: boolean
            }
            axisTick: {
                show: boolean
            }
            axisLine: {
                show: boolean
            }
        }
    }>

const generatePictorialBarChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<
    PictorialBarChartOptionsType,
    PictorialBarChartDataDefinitionType,
    PictorialBarChartConfigType
>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: PictorialBarChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParsePictorialBarChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
        }
    }

    return options
}

/*
 * Treemap bar chart
 * =========================================
 */

export type TreemapChartConfigOptionsType = ChartBaseConfigOptionsType &
    PartialDeep<{
        //? Static option. Only assigned inside initial/default options object.
        tooltip: {
            trigger: 'axis' | 'item' | 'none'
            formatter: string
            confine: boolean
            appendToBody: boolean
        }
        //? Static option. Only assigned inside initial/default options object.
        xAxis: {
            splitLine: {
                show: boolean
            }
            axisLabel: {
                show: boolean
            }
            axisTick: {
                show: boolean
            }
            axisLine: {
                show: boolean
            }
        }
    }>

const generateTreemapChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<
    TreemapChartOptionsType,
    TreemapChartDataDefinitionType,
    TreemapChartConfigType
>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: TreemapChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseTreemapChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
        }
    }

    return options
}

/*
 * Word cloud chart
 * =========================================
 */

export type WordCloudChartConfigOptionsType = ChartBaseConfigOptionsType &
    PartialDeep<{
        // TBD
    }>

const generateWordCloudChartOptions = ({
    mode,
    prevOptions,
    filteredData,
    dataDefinition,
    config,
}: GenerateSingleChartOptionsParams<
    WordCloudChartOptionsType,
    WordCloudChartDataDefinitionType,
    WordCloudChartConfigType
>) => {
    // The default options may have some properties that are NOT configurable by the user.
    // Therefore, we need to merge any generated options with these default values and NOT overwrite them.
    let options: WordCloudChartOptionsType = structuredClone(prevOptions)

    // Data generation
    if ((mode === 'all' || mode === 'data') && filteredData !== undefined) {
        options = {
            ...options,
            ...ParseWordCloudChartData({
                dataDefinition,
                filteredData,
                prevOptions,
                config,
            }),
        }
    }

    // Config generation
    if (mode === 'all' || mode === 'config') {
        options = {
            ...options,
            // Title settings
            title: assign(structuredClone(options.title), getChartTitleOptionsByConfig(config.title)),
            // Legend settings
            legend: assign(structuredClone(options.legend), getChartLegendOptionsByConfig(config.legend)),
        }
    }

    return options
}

/* =========================================
 * General helpers
 */

export const generateChartName = ({
    type,
    dataDefinition,
}: {
    type: ChartTypeType
    dataDefinition: ChartDataDefinitionType
}) => {
    const chartTypeName = type.charAt(0).toUpperCase() + type.slice(1)

    switch (type) {
        case 'line': {
            const chartDefinition = dataDefinition as LineChartDataDefinitionType

            if (chartDefinition.xAxisField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.xAxisField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'bar': {
            const chartDefinition = dataDefinition as BarChartDataDefinitionType

            if (chartDefinition.xAxisField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.xAxisField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'pie': {
            const chartDefinition = dataDefinition as PieChartDataDefinitionType

            if (chartDefinition.categoryField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.categoryField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'scatter': {
            const chartDefinition = dataDefinition as ScatterChartDataDefinitionType

            if (chartDefinition.xAxisField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.xAxisField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'radar': {
            const chartDefinition = dataDefinition as RadarChartDataDefinitionType

            if (chartDefinition.series.length > 0 && chartDefinition.series[0].attribute.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.series[0].attribute.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'boxplot': {
            const chartDefinition = dataDefinition as BoxplotChartDataDefinitionType

            if (chartDefinition.categoryField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.categoryField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'gauge': {
            const chartDefinition = dataDefinition as GaugeChartDataDefinitionType

            if (chartDefinition.indicator.field.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.indicator.field.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'pictorialBar': {
            const chartDefinition = dataDefinition as PictorialBarChartDataDefinitionType

            if (chartDefinition.categoryField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.categoryField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'wordCloud': {
            const chartDefinition = dataDefinition as WordCloudChartDataDefinitionType

            if (chartDefinition.categoryField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.categoryField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        case 'graph': {
            // const chartDefinition = dataDefinition as GraphChartDataDefinitionType

            return `New ${chartTypeName} Chart}`
        }
        case 'treemap': {
            const chartDefinition = dataDefinition as TreemapChartDataDefinitionType

            if (chartDefinition.valueField && chartDefinition.valueField.field.trim() !== '') {
                return `New ${chartTypeName} Chart - ${formatValueAsLabel(chartDefinition.valueField.field)}`
            } else {
                return `New ${chartTypeName} Chart`
            }
        }

        default:
            return '(Unknown Chart)'
    }
}

export const splitChartOptions = ({
    type,
    selectedDataSource,
    dataDefinition,
    filters,
    config,
    options,
}: ChartWidgetType) => {
    if (options === null) return null

    const splitOptionsList: ChartOptionsType[] = []

    switch (type) {
        case 'radar':
            if (options.series !== undefined) {
                const radarSeriesOptions = structuredClone(options.series)

                if (radarSeriesOptions.data !== undefined && radarSeriesOptions.data.length !== 0) {
                    for (const _dataItem of radarSeriesOptions.data) {
                        splitOptionsList.push({
                            ...options,
                            title: {
                                ...options.title,
                                show: false,
                            },
                            series: {
                                ...radarSeriesOptions,
                                // TODO: Why can't we use "_dataItem.name" here?
                                name: '(series name)',
                                data: [_dataItem],
                            },
                        })
                    }
                }
            }

            break

        case 'gauge':
            if (options.series !== undefined) {
                const gaugeConfig = { ...(config as GaugeChartConfigType) }
                const gaugeSeriesOptions = structuredClone(options.series)

                if (gaugeSeriesOptions.data !== undefined && gaugeSeriesOptions.data.length !== 0) {
                    for (const _dataItem of gaugeSeriesOptions.data) {
                        splitOptionsList.push({
                            ...options,
                            title: {
                                ...options.title,
                                show: false,
                            },
                            series: {
                                ...gaugeSeriesOptions,
                                data: [_dataItem],
                                // We remove 10% from the radius of charts to better fit the smaller space.
                                // The "radius" option is in string format with a "%" sign at the end (e.g. "75%").
                                // We remove that % sign, cast it to Number, remove 10 then add the % sign again in string format.
                                radius:
                                    Number(GAUGE_CHART_CONFIG_BASE_VALUES[gaugeConfig.viewMode].radius.slice(0, -1)) -
                                    10 +
                                    '%',
                                axisLine: {
                                    ...gaugeSeriesOptions.axisLine,
                                    lineStyle: {
                                        ...gaugeSeriesOptions.axisLine?.lineStyle,
                                        width: GAUGE_CHART_CONFIG_BASE_VALUES[gaugeConfig.viewMode].axisLineWidth,
                                    },
                                },
                                splitLine: {
                                    ...gaugeSeriesOptions.splitLine,
                                    distance: GAUGE_CHART_CONFIG_BASE_VALUES[gaugeConfig.viewMode].splitLineDistance,
                                },
                                axisLabel: {
                                    ...gaugeSeriesOptions.axisLabel,
                                    distance:
                                        gaugeConfig.viewMode === 'speedometer'
                                            ? GAUGE_CHART_CONFIG_BASE_VALUES['speedometer'].splitLineDistance
                                            : // Custom value for "gauge" and "ring" view modes to better fit the smaller space.
                                              -20,
                                },
                            },
                        })
                    }
                }
            }

            break

        default:
            break
    }

    return splitOptionsList
}
