import { useMemo, useState } from 'react'
import NetworkNodeSettingsEdit from './NetworkNodeSettingsEdit'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import NodeIcon from '@mui/icons-material/Circle'
import AddIcon from '@mui/icons-material/Add'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import NetworkAddNodeModal from './NetworkAddNodeModal'
import WebWorker from 'helpers/webWorkerHelper'
import { formatLabel } from 'features/network-viz/helpers/DataFormatter'
import { NodeAttributeType } from 'features/network-viz/types/NetworkViz.types'
import NetworkShrinkButton from './NetworkShrinkButton'
import { expandGroup, shrinkGroup } from 'features/network-viz/helpers/NetworkViz.helper'
import GridOnIcon from '@mui/icons-material/GridOn'
import { NetworkEditNodesModal } from './NetworkEditNodesModal'

export default function NetworkNodesSettings() {
    const [groupForEdit, setGroupForEdit] = useState<string>('')
    const [showAddNodeModal, setShowAddNodeModal] = useState<boolean>(false)
    const [showEditNodeModal, setShowEditNodeModal] = useState<boolean>(false)

    const {
        nodeGroupBy,
        nodeDataSchema,
        nodeStyle,
        nodes,
        edgeStyle,
        networkShrink,
        nodeRenders,
        edgeRenders,
        analytics,
        nodeGroupInfo,
    } = useNetworkVizContext()

    const [_nodeGroupBy, setNodeGroupBy] = useState<NodeAttributeType[]>(nodeGroupBy)

    const analyticsGroupByOptions = useMemo<BaseSelectWithLabelOptionType>(() => {
        const result: BaseSelectWithLabelOptionType = []
        if (analytics !== null)
            for (let key in analytics) {
                if (key === 'view') continue
                result.push(
                    ...[
                        { label: `Node Analytics - ${key}`, value: `Node Analytics - ${key}`, section: true },
                        {
                            value: {
                                source: 'analytic',
                                relationship: key,
                                field: 'greedy_modularity_community',
                            },
                            label: 'Greedy Modularity Community',
                        },
                        {
                            value: {
                                source: 'analytic',
                                relationship: key,
                                field: 'weakly_connected_component',
                            },
                            label: 'Weakly Connected Component',
                        },
                        {
                            value: {
                                source: 'analytic',
                                relationship: key,
                                field: 'strongly_connected_component',
                            },
                            label: 'Strongly Connected Component',
                        },
                    ]
                )
            }
        return result
    }, [analytics])

    const dispatchContext = useNetworkVizDispatch()

    const applyNodeGroupBy = (groupBys: NodeAttributeType[]) => {
        const oldGroupBy = [..._nodeGroupBy]
        setNodeGroupBy(groupBys)
        const worker = new WebWorker('workers/network/group-by-nodes.js')
        worker
            .run({
                nodeRenders,
                edgeRenders,
                nodes,
                groupBys,
                nodeStyle,
                analytics,
                dataSchema: nodeDataSchema.fields,
            })
            .then((res: any) => {
                const { nodeRenders, nodeStyle, nodeLegend, nodeGroupInfo } = res
                dispatchContext({
                    type: 'NODE_GROUP_BY_EDIT',
                    payload: {
                        nodeGroupBy: groupBys,
                        nodeRenders,
                        nodeStyle,
                        nodeLegend,
                        nodeGroupInfo,
                    },
                })
            })
            .catch((e) => {
                //revert the changes
                setNodeGroupBy(oldGroupBy)
                //ToDo
            })
            .finally(() => {})
    }

    const handleExpandGroup = (group: string) => {
        const nodeToExpandIndex = nodeRenders.findIndex(
            (x) => x.id === group && Array.isArray(x.innerNodes) && x.innerNodes.length > 0
        )
        if (nodeToExpandIndex !== -1) {
            const expanedNodes = expandGroup(group, { nodeRenders, edgeRenders })
            dispatchContext({
                type: 'EXPAND_COLLAPSE_GROUP',
                payload: {
                    ...expanedNodes,
                    nodeGroupInfo: {
                        ...nodeGroupInfo,
                        [group]: {
                            ...nodeGroupInfo[group],
                            collapsed: false,
                        },
                    },
                },
            })
        }
    }

    const collapseGroup = (group: string) => {
        const newNetwork = shrinkGroup(
            {
                nodeRenders,
                edgeRenders,
                nodeStyle,
                edgeStyle,
                networkShrink,
                nodeGroupInfo,
            },
            group
        )
        dispatchContext({
            type: 'EXPAND_COLLAPSE_GROUP',
            payload: {
                ...newNetwork,
                nodeGroupInfo: {
                    ...nodeGroupInfo,
                    [group]: {
                        ...nodeGroupInfo[group],
                        collapsed: true,
                    },
                },
            },
        })
    }

    return groupForEdit === '' ? (
        /*  Main view
            ========================================= */
        <Stack
            gap={2}
            sx={(theme) => ({
                minWidth: 0,
            })}
        >
            {/* Header
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <BaseButton onClick={(evt) => setShowEditNodeModal(true)} startIcon={<GridOnIcon />}>
                    Nodes
                </BaseButton>

                <BaseButton
                    label="Add Node"
                    onClick={(evt) => {
                        setShowAddNodeModal(true)
                    }}
                    startIcon={<AddIcon />}
                />
            </Stack>

            {/* Content
                ========================================= */}
            <Stack gap={1} minWidth={0}>
                {/* Group by select
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Group By"
                    selectProps={{
                        multiple: true,
                    }}
                    options={[
                        { label: 'Node Attributes', value: 'Node Attributes', section: true },
                        ...nodeDataSchema.groupByOptions.map((x) => ({
                            label: formatLabel(x),
                            value: {
                                source: 'info',
                                field: x,
                            },
                        })),
                        ...analyticsGroupByOptions,
                    ]}
                    value={_nodeGroupBy || []}
                    size="small"
                    onChange={applyNodeGroupBy}
                    InputLabelProps={{ shrink: !!nodeGroupBy }}
                    disabled={networkShrink.enabled}
                />
                {/* All nodes item
                    ========================================= */}
                {nodeGroupBy?.length === 0 ? null : (
                    <Stack direction="row" gap={1} justifyContent="space-between">
                        {!networkShrink.enabled && (
                            <Tooltip title="Clear Groups">
                                <IconButton onClick={(evt) => applyNodeGroupBy([])} size="small">
                                    <ClearAllIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <NetworkShrinkButton />
                    </Stack>
                )}
                {/* All nodes item
                    ========================================= */}
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontSize={14} noWrap>
                        All Nodes
                    </Typography>

                    <IconButton
                        onClick={(evt) => setGroupForEdit('default')}
                        size="small"
                        sx={(theme) => ({
                            marginLeft: 'auto',
                        })}
                    >
                        <EditIcon />
                    </IconButton>
                </Stack>
                {/* Items
                    ========================================= */}
                {Object.keys(nodeStyle)
                    .filter((k) => k !== 'default')
                    .map((key) => {
                        return (
                            <Stack
                                key={key}
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{
                                    minWidth: 0,
                                }}
                            >
                                <NodeIcon
                                    sx={{
                                        flexShrink: 0,
                                        color: nodeStyle[key].normal.color,
                                    }}
                                />

                                {/* TODO:
                                    Text can become very long (e.g. URLs). Clip the string to desired
                                    length before passing it to here.
                                */}
                                <Typography
                                    fontSize={14}
                                    sx={{
                                        flexGrow: 1,
                                        textOverflow: 'clip',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {key}
                                </Typography>

                                {/* {nodeGroupInfo[key]?.collapsed ? (
                                    <Tooltip title="Expand">
                                        <IconButton
                                            onClick={(evt) => handleExpandGroup(key)}
                                            size="small"
                                            sx={(theme) => ({
                                                flexShrink: 0,
                                            })}
                                        >
                                            <ExpandIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Shrink">
                                        <IconButton
                                            onClick={(evt) => collapseGroup(key)}
                                            size="small"
                                            sx={(theme) => ({
                                                flexShrink: 0,
                                            })}
                                        >
                                            <CollapseIcon />
                                        </IconButton>
                                    </Tooltip>
                                )} */}

                                <IconButton
                                    onClick={(evt) => setGroupForEdit(key)}
                                    size="small"
                                    sx={(theme) => ({
                                        flexShrink: 0,
                                    })}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Stack>
                        )
                    })}
            </Stack>
            <NetworkEditNodesModal open={showEditNodeModal} setOpen={setShowEditNodeModal} />
            <NetworkAddNodeModal open={showAddNodeModal} setOpen={setShowAddNodeModal} />
        </Stack>
    ) : (
        /*  Edit view
            ========================================= */
        <NetworkNodeSettingsEdit onClose={() => setGroupForEdit('')} groupForEdit={groupForEdit} />
    )
}
