import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { ReportSavedStateType } from 'features/report-designer/types/reportDesigner.types'
import { ReportCustomViewType, ReportShareType } from 'features/share-report-dialog/ShareReportDialog'
import { CreatorType } from 'types/resources'
import { parseUrl, ReportApis as Apis } from './Apis'
import { ProjectAccountType, SearchResponseType, TagType } from './ProjectService'
import { BaseResponseType, request } from './request'

export type ReportType = {
    reportId?: number
    primaryKey?: number
    projectID?: number
    state: string | null
    title: string
    thumbnail?: string
    allMember?: boolean
    accessToken?: string
    isTemplate?: boolean
    reportCustomAccess?: {
        primaryKey: number
        title: string
        state: Omit<ReportCustomViewType, 'title' | 'primaryKey'>
    }[]
    reportAccess?: ReportShareType[]
    createdOn?: Date
    createdBy?: CreatorType
    tags?: TagType[]
}

export type GetReporstResponse = {
    success: boolean
    data: Array<ReportType>
}

export const GetReports: (pid: string | number, showMessage?: boolean) => Promise<GetReporstResponse> = async (
    pid,
    showMessage = false
) => {
    return request(parseUrl(Apis.GetReports, { pid }), 'GET', undefined, undefined, showMessage)
}

export type GetReportResponse = {
    success: boolean
    data: ReportType
}

export const GetReport: (
    pid: string | number,
    rid: string | number,
    showMessage?: boolean
) => Promise<GetReportResponse> = async (pid, rid, showMessage = false) => {
    return request(parseUrl(Apis.GetReportByID, { pid, rid }), 'GET', undefined, undefined, showMessage)
}

export const GetSampleReport: (token: string, showMessage?: boolean) => Promise<GetReportResponse> = async (
    token,
    showMessage = false
) => {
    const link = parseUrl(Apis.GetSampleReport, { token })
    return request(link, 'GET', undefined, undefined, showMessage)
}

export const GetReportWithTokenService: (
    token: string,
    body: { email: string; passcode: string },
    showMessage?: boolean
) => Promise<GetReportResponse> = async (token, body, showMessage = false) => {
    return request(parseUrl(Apis.GetReportByToken, { token }), 'POST', undefined, body, showMessage)
}

export type ReportInfoType = {
    title: string
    color: string
    thumbnail: string
}

export type GetReportInfoResponse = {
    success: boolean
    data: ReportInfoType
}

export const GetReportInfoWithTokenService: (
    token: string,
    showMessage?: boolean
) => Promise<GetReportInfoResponse> = async (token, body, showMessage = false) => {
    return request(parseUrl(Apis.GetReportByToken, { token }), 'GET', undefined, undefined, showMessage)
}

export const RequestReportPasscodeService: (
    token: string,
    body: { email: string },
    showMessage?: boolean
) => Promise<GetReportResponse> = async (token, body, showMessage = false) => {
    return request(parseUrl(Apis.RequestReportPasscode, { token }), 'POST', undefined, body, showMessage)
}

export const CreateReport: (
    pid: string | number,
    report: ReportType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, report, showMessage = false) => {
    return request(parseUrl(Apis.CreatReport, { pid }), 'POST', undefined, report, showMessage)
}

type CloneReportResponseType = {
    success: boolean
    data: ReportType
}

export const CloneReportService: (
    pid: string | number,
    rid: string | number,
    showMessage?: boolean
) => Promise<CloneReportResponseType> = async (pid, rid, showMessage = false) => {
    return request(parseUrl(Apis.CloneReport, { pid, rid }), 'POST', undefined, undefined, showMessage)
}

export type UpdateReportType = {
    primaryKey: number
    thumbnail?: string
    state: string
}

export const UpdateReport: (
    pid: string | number,
    report: UpdateReportType,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, report, showMessage = false) => {
    return request(parseUrl(Apis.UpdateReport, { pid }), 'PUT', undefined, report, showMessage)
}

export const DeleteReport: (
    pid: string | number,
    rid: string | number,
    force: boolean,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, force, showMessage = false) => {
    return request(parseUrl(Apis.DeleteReport, { pid, rid, force }), 'DELETE', undefined, undefined, showMessage)
}

export type CreateReportTagResponseType = {
    success: boolean
    data: {
        primaryKey: number
    }
}

export const CreateReportTag: (
    pid: number | string,
    rid: number | string,
    tag: TagType,
    showMessage?: boolean
) => Promise<CreateReportTagResponseType> = async (pid, rid, tag, showMessage = false) => {
    return request(parseUrl(Apis.AddTag, { pid, rid }), 'POST', undefined, tag, showMessage)
}

export const RemoveReportTag: (
    pid: number | string,
    rid: number | string,
    tid: number,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, tid, showMessage = false) => {
    return request(parseUrl(Apis.RemoveTag, { pid, rid, tid }), 'DELETE', undefined, undefined, showMessage)
}

export const SearchReportsService: (
    pid: string | number,
    term: string,
    showMessage?: boolean
) => Promise<SearchResponseType> = async (pid, term, showMessage = false) => {
    return request(parseUrl(Apis.Search, { pid, term }), 'GET', undefined, undefined, showMessage)
}

type UploadResponseType = {
    success: boolean
    data: string
}

export const UploadImageService: (
    pid: string | number,
    rid: string | number,
    base64: string,
    showMessage?: boolean
) => Promise<UploadResponseType> = async (pid, rid, base64, showMessage = false) => {
    return request(parseUrl(Apis.UploadImage, { pid, rid }), 'POST', undefined, { base64 }, showMessage)
}

export const CreateReportCustomViewService: (
    pid: number | string,
    rid: number | string,
    customView: { title: string; state: string },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, customView, showMessage = false) => {
    return request(parseUrl(Apis.CreatReportCustomAccess, { pid, rid }), 'POST', undefined, customView, showMessage)
}

export const UpdateReportCustomViewService: (
    pid: number | string,
    rid: number | string,
    cvi: number | string,
    customView: { title: string; state: string },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, cvi, customView, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateReportCustomAccess, { pid, rid, cvi }),
        'PUT',
        undefined,
        customView,
        showMessage
    )
}

export const DeleteReportCustomViewService: (
    pid: number | string,
    rid: number | string,
    cvi: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, cvi, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteReportCustomAccess, { pid, rid, cvi }),
        'DELETE',
        undefined,
        undefined,
        showMessage
    )
}

type ShareReportResponseType = {
    success: boolean
    data: ReportShareType[]
}

export const ShareReportService: (
    pid: number | string,
    rid: number | string,
    users: ReportShareType[],
    showMessage?: boolean
) => Promise<ShareReportResponseType> = async (pid, rid, users, showMessage = false) => {
    return request(parseUrl(Apis.ShareReport, { pid, rid }), 'POST', undefined, users, showMessage)
}

export const DeleteReportAccesService: (
    pid: number | string,
    rid: number | string,
    raid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, raid, showMessage = false) => {
    return request(parseUrl(Apis.DeleteReportAccess, { pid, rid, raid }), 'DELETE', undefined, undefined, showMessage)
}

export const ResendReportAccesInviteService: (
    pid: number | string,
    rid: number | string,
    raid: number | string,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, raid, showMessage = false) => {
    return request(
        parseUrl(Apis.ResendReportAccessInvite, { pid, rid, raid }),
        'POST',
        undefined,
        undefined,
        showMessage
    )
}

export const UpdateReportAccesService: (
    pid: number | string,
    rid: number | string,
    raid: number | string,
    body: { customViewId: number },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, raid, body, showMessage = false) => {
    return request(parseUrl(Apis.UpdateReportAccess, { pid, rid, raid }), 'PUT', undefined, body, showMessage)
}

export type ReportViewerAccountType = {
    account: ProjectAccountType
    hasAccess: boolean
}
export type GetReportViewerAccountsResponseType = {
    success: boolean
    data: ReportViewerAccountType[]
}

export const GetReportViewerAccountsService: (
    pid: number | string,
    rid: number | string,
    showMessage?: boolean
) => Promise<GetReportViewerAccountsResponseType> = async (pid, rid, showMessage = false) => {
    return request(parseUrl(Apis.GetReportViewersAccounts, { pid, rid }), 'GET', undefined, undefined, showMessage)
}

export type UpdateReportViewerAccountsRequestType = {
    email: string
    hasAccess: boolean
}

export const UpdateReportViewerAccountsService: (
    pid: number | string,
    rid: number | string,
    body: UpdateReportViewerAccountsRequestType[],
    showMessage?: boolean
) => Promise<GetReportViewerAccountsResponseType> = async (pid, rid, body, showMessage = false) => {
    return request(parseUrl(Apis.UpdateReportViewersAccounts, { pid, rid }), 'PUT', undefined, body, showMessage)
}

export const UpdateReportTitleService: (
    pid: string | number,
    rid: string | number,
    title: { title: string },
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, rid, title, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateTitle, { pid, rid }),
        'PUT',
        { 'Content-Type': 'application/json' },
        title,
        showMessage
    )
}
