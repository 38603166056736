import merge from 'lodash/merge'
import { EdgeGroupStyleType } from '../types/NetworkViz.types'

export const EdgeBaseSettings: EdgeGroupStyleType = {
    visible: true,
    relationType: 'neutral',
    normal: {
        curveness: null,
        width: 0.5,
        type: 'solid',
        color: '#00000077',
    },
    selectedIn: {
        width: 1,
        type: 'solid',
        color: '#1f77b477',
    },
    selectedOut: {
        width: 1,
        type: 'solid',
        color: '#ff7f0e77',
    },
    colorScale: {
        enabled: false,
        colorFrom: '#2D77BCff',
        colorTo: '#18C342ff',
    },
    sizeScale: {
        enabled: false,
        sizeFrom: 1,
        sizeTo: 10,
    },
}

//ToDo theme based coloring
/*
const EdgeLightSettings: EdgeGroupStyleType = {
    visible: true,
    normal: {
        curveness: 0.1,
        width: 1,
        type: 'solid',
        color: '#00000077',
        shadow: {
            enabled: true,
            settings: {
                color: '#eeeeeeff',
                x: 2,
                y: 2,
                blur: 1,
            },
        },
    },
    selected: {
        width: 1,
        type: 'solid',
        color: '#00000077',
        shadow: {
            enabled: true,
            settings: {
                color: '#eeeeeeff',
                x: 2,
                y: 2,
                blur: 1,
            },
        },
    },
    colorScale: {
        enabled: true,
         colorFrom: '#2D77BCff',
        colorTo: '#18C342ff',
    },
    sizeScale: {
        enabled: true,
    },
}

const EdgeDarkSettings: EdgeGroupStyleType = {
    visible: true,
    normal: {
        curveness: 0.1,
        width: 1,
        type: 'solid',
        color: '#00000077',
        shadow: {
            enabled: true,
            settings: {
                color: '#eeeeeeff',
                x: 2,
                y: 2,
                blur: 1,
            },
        },
    },
    selected: {
        width: 1,
        type: 'solid',
        color: '#00000077',
        shadow: {
            enabled: true,
            settings: {
                color: '#eeeeeeff',
                x: 2,
                y: 2,
                blur: 1,
            },
        },
    },
    colorScale: {
        enabled: true,
    },
    sizeScale: {
        enabled: true,
    },
}

export const DefaultLightEdgeSettings = merge(EdgeBaseSettings, EdgeLightSettings)

export const DefaultDarkEdgeSettings = merge(EdgeBaseSettings, EdgeDarkSettings)
*/

const LightColorPallet = [
    '#065535ff',
    '#ff7373ff',
    '#40e0d0ff',
    '#bada55ff',
    '#ffc0cbff',
    '#468499ff',
    '#c39797ff',
    '#d3ffceff',
    '#660066ff',
    '#daa520ff',
    '#ffa500ff',
    '#0a75adff',
    '#a0db8eff',
    '#008080ff',
    '#7fffd4ff',
    '#f0f8ffff',
    '#ffd700ff',
    '#800000ff',
    '#003366ff',
    '#b0e0e6ff',
    '#133337ff',
    '#ff4040ff',
    '#B319E4ff',
    '#B319E4ff',
    '#96BBE1ff',
    '#57E89Eff',
    '#16969Eff',
    '#F75825ff',
    '#643F36ff',
    '#D0A919ff',
    '#1853D9ff',
    '#95BFC1ff',
]

const DarkColorPallet = [
    '#065535ff',
    '#ff7373ff',
    '#40e0d0ff',
    '#bada55ff',
    '#ffc0cbff',
    '#468499ff',
    '#c39797ff',
    '#d3ffceff',
    '#660066ff',
    '#daa520ff',
    '#ffa500ff',
    '#0a75adff',
    '#a0db8eff',
    '#008080ff',
    '#7fffd4ff',
    '#f0f8ffff',
    '#ffd700ff',
    '#800000ff',
    '#003366ff',
    '#b0e0e6ff',
    '#133337ff',
    '#ff4040ff',
    '#B319E4ff',
    '#B319E4ff',
    '#96BBE1ff',
    '#57E89Eff',
    '#16969Eff',
    '#F75825ff',
    '#643F36ff',
    '#D0A919ff',
    '#1853D9ff',
    '#95BFC1ff',
]

export function getEdgeColorPallet(theme: 'light' | 'dark' = 'light') {
    if (theme === 'light') return LightColorPallet
    return DarkColorPallet
}
