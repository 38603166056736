//* ======= Libraries
import React, { useContext, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import BaseButton from 'components/base/BaseButton'
import SurveySettingsGeneral from 'features/survey-settings/SurveySettingsGeneral'
import SurveySettingsScheduling from 'features/survey-settings/SurveySettingsScheduling'
//* ======= Custom logic
//* ======= Assets and styles
import FilterListIcon from '@mui/icons-material/FilterList'
import DownloadIcon from '@mui/icons-material/Download'
import PrintIcon from '@mui/icons-material/Print'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { SurveyContext } from 'contexts/SurveyContex'

interface ISurveySettings {}

function SurveySettings({}: ISurveySettings) {
    const { drawerContent } = useContext(SurveyContext)

    const [selectedPanel, setSelectedPanel] = useState(0)

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack
                    sx={{
                        height: '100%',
                        paddingBottom: 1,
                        backgroundColor: 'common.bg_1',
                    }}
                >
                    {/* Header title and controls
						========================================= */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            py: 1,
                            paddingLeft: 3,
                            paddingRight: 2,
                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Panel selection
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={3}>
                            {/* General
								========================================= */}
                            <BaseButton
                                label="General"
                                onClick={(e) => setSelectedPanel(0)}
                                sx={{
                                    px: 2,
                                    fontSize: 20,
                                    fontWeight: 500,

                                    color: selectedPanel === 0 ? 'primary.main' : 'common.text_3',
                                }}
                            />

                            {/* Scheduling
								========================================= */}
                            <BaseButton
                                label="Scheduling"
                                onClick={(e) => setSelectedPanel(1)}
                                sx={{
                                    px: 2,
                                    fontSize: 20,
                                    fontWeight: 500,

                                    color: selectedPanel === 1 ? 'primary.main' : 'common.text_3',
                                }}
                            />
                        </Stack>

                        {/* Controls
							========================================= */}
                        {selectedPanel === 0 ? (
                            <>
                                {/* Filter button
									========================================= */}
                                <IconButton
                                    sx={{
                                        marginLeft: 'auto',

                                        borderRadius: '4px',
                                        color: 'common.fill_2',
                                    }}
                                >
                                    <FilterListIcon />
                                </IconButton>

                                {/* Download button
									========================================= */}
                                <IconButton
                                    sx={{
                                        borderRadius: '4px',
                                        color: 'common.fill_2',
                                    }}
                                >
                                    <DownloadIcon />
                                </IconButton>

                                {/* Print button
									========================================= */}
                                <IconButton
                                    sx={{
                                        borderRadius: '4px',
                                        color: 'common.fill_2',
                                    }}
                                >
                                    <PrintIcon />
                                </IconButton>
                            </>
                        ) : selectedPanel === 2 ? (
                            <>
                                <Typography
                                    fontSize={14}
                                    fontWeight={400}
                                    color="common.text_1"
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                >
                                    Import schedule from file
                                </Typography>

                                {/* Sample CSV button
									========================================= */}
                                <BaseButton label="Sample CSV" />

                                {/* Import button
									========================================= */}
                                <BaseButton label="Import" variant="contained" endIcon={<InsertDriveFileIcon />} />

                                {/* Print button
									========================================= */}
                                <IconButton
                                    sx={{
                                        borderRadius: '4px',
                                        color: 'common.fill_2',
                                    }}
                                >
                                    <PrintIcon />
                                </IconButton>
                            </>
                        ) : (
                            false
                        )}
                    </Stack>

                    {/* Main content
						========================================= */}
                    <Stack
                        flexGrow={1}
                        sx={{
                            padding: 3,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        {selectedPanel === 0 ? (
                            <SurveySettingsGeneral />
                        ) : selectedPanel === 1 ? (
                            <SurveySettingsScheduling />
                        ) : (
                            false
                        )}
                    </Stack>
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}

export default SurveySettings
