//* ======= Libraries
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
//* ======= Components and features
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
//* ======= Assets and styles
import { SurveyContext } from 'contexts/SurveyContex'
import { SurveyScheduleType, UpdateSurveySettingsScheduleService } from 'services/SurveyService'
import { DialogActions, InputAdornment } from '@mui/material'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment, { Moment } from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { DatePicker, TimePicker, TimeField } from '@mui/x-date-pickers'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { useParams } from 'react-router-dom'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { toast } from 'react-toastify'

const localizer = momentLocalizer(moment)

export type CalendarEventType = {
    title: string
    allDay: boolean
    start: Date
    end: Date
}

function addDays(date: Date, days: number) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}

const defaultTime = moment().hour(9).minute(0).second(0).millisecond(0)

function SurveySettingsScheduling() {
    const { pid, sid } = useParams()
    const { survey, updateSurvey } = useContext(SurveyContext)
    const [addEventDialogState, setAddEventDialogState] = useState<null | { start?: Date; end?: Date }>(null)
    const [isRecurring, setIsRecurring] = useState(true)
    const [startDate, setStartDate] = useState<Moment | null>(null)
    const [startTime, setStartTime] = useState<Moment | null>(defaultTime)
    const [manualEvents, setManualEvents] = useState<CalendarEventType[]>([])
    const [schedule, setSchedule] = useState<SurveyScheduleType>({
        duration: 10,
        interval: 5,
        isAdvanced: false,
        occurrence: 3,
    })
    const [selectedPanel, setSelectedPanel] = useState(0)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const handleOpenAddEventDialog = (start: Date, end?: Date) => setAddEventDialogState({ start, end })
    const handleCloseAddEventDialog = () => setAddEventDialogState(null)

    const addEvent = () => {
        if (addEventDialogState?.end != null && addEventDialogState?.start != null) {
            //@ts-ignore
            setManualEvents((pv) => [
                ...pv,
                {
                    allDay: true,
                    end: addEventDialogState.end,
                    start: addEventDialogState.start,
                    title: 'tbd',
                },
            ])
            handleCloseAddEventDialog()
        }
    }

    const isScheduleValid = useCallback(
        () =>
            schedule?.occurrence != null &&
            schedule.occurrence > 0 &&
            schedule?.duration != null &&
            schedule.duration > 0 &&
            schedule?.interval != null &&
            schedule.interval > 0,
        [schedule]
    )
    const isDataInvalid = () => startDate == null || startTime == null || (isRecurring && !isScheduleValid())

    const events = useMemo<CalendarEventType[]>(() => {
        const result = [...manualEvents]
        if (startDate && isScheduleValid()) {
            for (let i = 0; i < schedule.occurrence; i++) {
                let tempDate = startDate.toDate()
                result.push({
                    allDay: true,
                    start: addDays(tempDate, i * schedule.interval),
                    end: addDays(tempDate, i * schedule.interval + schedule.duration - 1),
                    title: 'tbd',
                })
            }
        }

        return result
            .sort((e1, e2) => e1.start.getTime() - e2.start.getTime())
            .map((e, index) => ({ ...e, title: `Round ${index + 1}`, id: index + 1 }))
    }, [manualEvents, schedule, startDate, isScheduleValid])

    const updateSchedule = (field: string) => (value: any) =>
        setSchedule((s) => ({ ...s, [field]: parseInt(value) || 0 }))

    useEffect(() => {
        if (survey?.recurring === true) {
            setIsRecurring(true)
        } else {
            setIsRecurring(false)
        }

        if (survey?.startDate) {
            setStartDate(moment(survey.startDate))
            setStartTime(moment(survey.startDate))
        }

        if (survey?.schedule != null) {
            setSchedule(survey.schedule)
        }
        setTimeout(() => setIsLoading(false), 500)
    }, [survey?.schedule, survey?.recurring, survey?.startDate])

    const saveChanges = () => {
        if (!isDataInvalid() && sid != null && pid != null && startDate && startTime) {
            const toastId = toast.loading('Saving changes...')
            const date = startDate.toDate()
            date.setHours(startTime.toDate().getHours(), startTime.toDate().getMinutes(), 0, 0)
            UpdateSurveySettingsScheduleService(pid, sid, {
                recurring: isRecurring,
                scheduling: schedule,
                startDate: date,
            })
                .then((res) => {
                    if (res.success) {
                        updateSurvey({
                            schedule,
                            recurring: isRecurring,
                            startDate: date,
                        })
                        toast.update(toastId, {
                            render: 'Changes saved!',
                            type: 'success',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    } else {
                        toast.update(toastId, {
                            render: 'Failed to save changes!',
                            type: 'error',
                            isLoading: false,
                            autoClose: 1000,
                            closeButton: null,
                        })
                    }
                })
                .catch((e) => {
                    toast.update(toastId, {
                        render: 'Failed to save changes!',
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000,
                        closeButton: null,
                    })
                })
        }
    }

    return (
        <Stack gap={3} height="100%">
            {isLoading ? (
                <SnaCircularLoading />
            ) : (
                <React.Fragment>
                    {/* DateTime picker and recurring switch
                ========================================= */}
                    <Stack gap={1}>
                        <Typography>Start Date and Time</Typography>

                        <Stack direction="row" alignItems="center" gap={3.5}>
                            {/* DateTime picker
                        ========================================= */}
                            <DatePicker
                                value={startDate}
                                onChange={(v) => setStartDate(v)}
                                slotProps={{
                                    textField: {
                                        hiddenLabel: true,
                                        sx: {
                                            width: 390,
                                        },
                                    },
                                }}
                            />

                            <TimeField
                                value={startTime}
                                onChange={(v) => setStartTime(v)}
                                slotProps={{
                                    textField: {
                                        hiddenLabel: true,
                                        sx: {
                                            width: 390,
                                        },
                                    },
                                }}
                            />

                            {/* Recurring switch
                        ========================================= */}
                            {/* <FormGroup aria-label="recurring" row>
                                <FormControlLabel
                                    checked={isRecurring}
                                    onChange={() => setIsRecurring((v) => !v)}
                                    control={<BaseSwitch color="primary" />}
                                    label="Recurring"
                                    labelPlacement="start"
                                    sx={{
                                        gap: 1,

                                        marginLeft: 0,

                                        '& .MuiFormControlLabel-label': {
                                            fontSize: 14,
                                            fontWeight: 400,

                                            color: 'common.text_1',
                                        },
                                    }}
                                />
                            </FormGroup> */}
                        </Stack>
                    </Stack>

                    {/* Basic scheduling
                ========================================= */}
                    {isRecurring && (
                        <Grid container alignItems="center" columnGap={3}>
                            {/* Repeat every days field
                    ========================================= */}
                            <Grid item xs={4}>
                                <Stack gap={1}>
                                    <Typography>Repeats every</Typography>

                                    <BaseFilledTextField
                                        type="number"
                                        hiddenLabel
                                        fullWidth
                                        value={schedule.interval}
                                        onChange={(v) => updateSchedule('interval')(v.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ marginLeft: 1 }} position="start">
                                                    Days
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            {/* Occurence field
                    ========================================= */}
                            <Grid item xs={4}>
                                <Stack gap={1}>
                                    <Typography>For</Typography>

                                    <BaseFilledTextField
                                        type="number"
                                        hiddenLabel
                                        fullWidth
                                        value={schedule.occurrence}
                                        onChange={(v) => updateSchedule('occurrence')(v.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ marginLeft: 1 }} position="start">
                                                    Occurence
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            {/* Survey instance expiry days field
                    ========================================= */}
                            <Grid item xs={3}>
                                <Stack gap={1}>
                                    <Typography>Survey instance expiry</Typography>

                                    <BaseFilledTextField
                                        type="number"
                                        hiddenLabel
                                        fullWidth
                                        value={schedule.duration}
                                        onChange={(v) => updateSchedule('duration')(v.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ marginLeft: 1 }} position="start">
                                                    Days
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            {/* Advanced switch /// ToDo disabled for now
                    ========================================= */}
                            {/*     <Grid item xs="auto">
                    
                <FormGroup
                    aria-label="advanced"
                    row
                    sx={{
                        marginTop: 4,
                    }}
                >
                    <FormControlLabel
                        control={<BaseSwitch color="primary" />}
                        checked={schedule?.isAdvanced}
                        onChange={() => updateSchedule('isAdvanced')(!schedule?.isAdvanced)}
                        label="Advanced"
                        labelPlacement="start"
                        sx={{
                            gap: 1,

                            marginLeft: 0,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                                fontWeight: 400,

                                // TODO: Could switch colors based on Switch state.
                                // color: checked ? 'primary.main' : 'common.text_1',
                                color: 'primary.main',
                            },
                        }}
                    />
                </FormGroup>
            </Grid>
            */}
                            <Grid item xs={12} mt={2}>
                                {/* Panel selection
							========================================= */}
                                <Stack direction="row" alignItems="center" gap={3}>
                                    {/* Calendar
								========================================= */}
                                    <BaseButton
                                        label="List"
                                        onClick={(e) => setSelectedPanel(0)}
                                        sx={{
                                            px: 2,
                                            fontSize: 20,
                                            fontWeight: 500,

                                            color: selectedPanel === 0 ? 'primary.main' : 'common.text_3',
                                        }}
                                    />

                                    {/* Scheduling
								========================================= */}
                                    <BaseButton
                                        label="Calendar"
                                        onClick={(e) => setSelectedPanel(1)}
                                        sx={{
                                            px: 2,
                                            fontSize: 20,
                                            fontWeight: 500,

                                            color: selectedPanel === 1 ? 'primary.main' : 'common.text_3',
                                        }}
                                    />
                                </Stack>
                                <Divider sx={{ marginBottom: 2 }} />

                                {selectedPanel === 1 && (
                                    <Calendar
                                        localizer={localizer}
                                        views={{ month: true }}
                                        events={events}
                                        onSelectSlot={(info) => {
                                            handleOpenAddEventDialog(info.start, info.end)
                                        }}
                                        selectable
                                        startAccessor="start"
                                        endAccessor="end"
                                        style={{ minHeight: 550 }}
                                    />
                                )}

                                {selectedPanel === 0 && (
                                    <Stack style={{ minHeight: 320 }} gap={1}>
                                        <BaseButton
                                            variant="contained"
                                            sx={{
                                                width: '150px',
                                                alignSelf: 'end',
                                                marginBottom: '-40px',
                                                zIndex: 10,
                                            }}
                                            onClick={() =>
                                                handleOpenAddEventDialog(
                                                    defaultTime.toDate(),
                                                    addDays(defaultTime.toDate(), 5)
                                                )
                                            }
                                        >
                                            Add Survey
                                        </BaseButton>
                                        <StyledDataGrid
                                            style={{ minHeight: 300 }}
                                            key={events.length}
                                            density="compact"
                                            rows={events}
                                            columns={[
                                                {
                                                    field: 'title',
                                                    width: 100,
                                                    headerName: 'Title',
                                                },
                                                {
                                                    field: 'id',
                                                    width: 100,
                                                    headerName: 'Round',
                                                },
                                                {
                                                    field: 'start',
                                                    width: 150,
                                                    headerName: 'Start Date',
                                                    valueFormatter: (v) =>
                                                        v.value instanceof Date && moment(v.value).format('DD-MM-YYYY'),
                                                },
                                                {
                                                    field: 'end',
                                                    headerName: 'End Date',
                                                    width: 150,
                                                    valueFormatter: (v) =>
                                                        v.value instanceof Date && moment(v.value).format('DD-MM-YYYY'),
                                                },
                                            ]}
                                        />
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {/* Advanced row
                ========================================= */}
                    {schedule?.isAdvanced && (
                        <Grid container alignItems="center" columnGap={3}>
                            {/* Number of instances field
                    ========================================= */}
                            <Grid item xs={3}>
                                <Stack gap={1}>
                                    <Typography>Number of instances</Typography>

                                    <BaseFilledTextField type="number" hiddenLabel fullWidth />
                                </Stack>
                            </Grid>

                            {/* Minimum gap field
                    ========================================= */}
                            <Grid item xs={3}>
                                <Stack gap={1}>
                                    <Typography>Minimum gap</Typography>

                                    <BaseFilledTextField type="number" hiddenLabel fullWidth />
                                </Stack>
                            </Grid>
                        </Grid>
                    )}

                    {/* Save button
                ========================================= */}
                    <BaseButton
                        label="Save"
                        variant="contained"
                        onClick={saveChanges}
                        disabled={startDate == null || startTime == null || (isRecurring && !isScheduleValid())}
                        size="large"
                        sx={{
                            alignSelf: 'flex-start',
                            width: 186,
                            py: 1.75,
                        }}
                    />

                    {/* Add Survey Dialog
                ========================================= */}

                    <StyledDialog open={addEventDialogState != null} onClose={handleCloseAddEventDialog}>
                        <DialogTitle>Add Survey Round</DialogTitle>
                        <DialogContent>
                            <Stack gap={2}>
                                <DatePicker
                                    value={addEventDialogState?.start}
                                    onChange={(v) => {
                                        setAddEventDialogState((pv) => {
                                            if (pv) {
                                                return { ...pv, start: v || undefined }
                                            }
                                            return { start: v || undefined }
                                        })
                                    }}
                                    slotProps={{
                                        textField: {
                                            hiddenLabel: true,
                                            sx: {
                                                width: 390,
                                            },
                                        },
                                    }}
                                />
                                <DatePicker
                                    value={addEventDialogState?.end}
                                    onChange={(v) => {
                                        setAddEventDialogState((pv) => {
                                            if (pv) {
                                                return { ...pv, end: v || undefined }
                                            }
                                            return { end: v || undefined }
                                        })
                                    }}
                                    slotProps={{
                                        textField: {
                                            hiddenLabel: true,
                                            sx: {
                                                width: 390,
                                            },
                                        },
                                    }}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <BaseButton onClick={handleCloseAddEventDialog}>Close</BaseButton>
                            <BaseButton variant="contained" onClick={addEvent}>
                                Add
                            </BaseButton>
                        </DialogActions>
                    </StyledDialog>
                </React.Fragment>
            )}
        </Stack>
    )
}

export default SurveySettingsScheduling
