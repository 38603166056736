import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate, useLocation } from 'react-router-dom'
import ErrorFallback from 'components/layouts/ErrorFallback'

type WithErrorBoundaryProps = {
    errorRecovery:
        | {
              type: 'navigate'
              path: string
          }
        | {
              type: 'callback'
              callbackFunction: () => void
          }
    children: React.ReactNode
}

function WithErrorBoundary({ errorRecovery, children }: WithErrorBoundaryProps) {
    const navigate = useNavigate()
    const location = useLocation()

    const onErrorResetHandler = () => {
        if (errorRecovery.type === 'navigate') {
            return () =>
                navigate(errorRecovery.path, {
                    replace: true,
                })
        } else if (errorRecovery.type === 'callback') {
            return () => errorRecovery.callbackFunction()
        }
    }

    return (
        <ErrorBoundary
            FallbackComponent={(errorProps) => <ErrorFallback {...errorProps} />}
            onReset={onErrorResetHandler}
            // TODO: Take a look at this property and see if you can improve 'reset' detection.
            resetKeys={[location]}
        >
            {children}
        </ErrorBoundary>
    )
}

export default WithErrorBoundary
