import React from 'react'
import { styled } from '@mui/material'
import TextField, { TextFieldProps } from '@mui/material/TextField'

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
    // Label
    '& .MuiInputLabel-root': {
        '&.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        '&.Mui-error': {
            color: theme.palette.common.fuschia100,
        },
    },
    // Input
    '& .MuiFilledInput-root': {
        borderRadius: 8,
        border: '1px solid transparent',
        backgroundColor: theme.palette.common.bg_4,

        '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.bg_3,
        },
        '&.Mui-error': {
            borderColor: theme.palette.common.fuschia100,
        },
        '&::before': {
            display: 'none',
        },
        '&::after': {
            display: 'none',
        },
    },
    // Helper text
    '& .MuiFormHelperText-root': {
        '&.Mui-error': {
            color: theme.palette.common.fuschia100,
        },
    },
}))

const BaseFilledTextField = React.forwardRef(({ ...fieldProps }: TextFieldProps, ref) => {
    return (
        <StyledTextField
            inputRef={ref}
            variant="filled"
            {...fieldProps}
            inputProps={{
                className: 'u-scrollbar',
                ...fieldProps.inputProps,
            }}
        />
    )
})

BaseFilledTextField.displayName = 'BaseFilledTextField'

export default BaseFilledTextField
