import AutorenewIcon from '@mui/icons-material/Autorenew'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import BaseButton from 'components/base/BaseButton'
import { LAYOUT_ALGORITHEMS_2D, LAYOUT_ALGORITHEMS_3D } from 'features/network-viz/constants/NetworkViz.const'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { LayoutKindType } from 'features/network-viz/types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'

export interface NetworkAlgorithmsControlButtonProps {}

export default function NetworkAlgorithmsControlButton({}: NetworkAlgorithmsControlButtonProps) {
    const { nodeRenders, edgeRenders, networkVizKind, layoutSettings } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const applyLayout = (layout: LayoutKindType) => {
        const worker = new WebWorker('workers/network/apply-alogorithm.js')
        dispatchContext({ type: 'STATUS_UPDATE', payload: 'loading' })
        worker
            .run({
                nodeRenders,
                edgeRenders,
                layout,
                layoutSetting: layoutSettings[layout],
                mode: networkVizKind === '3d' ? '3d' : '2d',
                analyticsUrl: process.env.REACT_APP_API_URL,
            })
            .then((res: any) => {
                dispatchContext({
                    type: 'LAYOUT_KIND_EDIT',
                    payload: { layoutKind: layout, nodeRenders: res },
                })
            })
            .catch((e) => {
                //ToDo
            })
            .finally(() => {
                dispatchContext({ type: 'STATUS_UPDATE', payload: 'ready' })
            })
        handleClose()
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const layoutOptions = useMemo(() => {
        return networkVizKind === '3d' ? LAYOUT_ALGORITHEMS_3D : LAYOUT_ALGORITHEMS_2D
    }, [networkVizKind])

    return (
        <>
            <BaseButton
                onClick={handleClick}
                disableElevation
                sx={(theme) => ({
                    height: 48,
                    minWidth: 0,
                    width: 48,

                    borderRadius: 0,
                    // @Theme conditional
                    backgroundColor: open
                        ? theme.palette.primary.main
                        : theme.palette.mode === 'light'
                        ? theme.palette.common.bg_3
                        : theme.palette.common.bg_4,
                    color: open ? theme.palette.common.fill_4 : theme.palette.primary.main,

                    '&:hover': {
                        // @Theme conditional
                        backgroundColor: open
                            ? theme.palette.primary.main
                            : theme.palette.mode === 'light'
                            ? theme.palette.common.bg_3
                            : theme.palette.common.bg_4,
                    },
                })}
            >
                <AutorenewIcon />
            </BaseButton>

            <Menu
                id="graph-algorithms-control-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {layoutOptions.map((algor) => (
                    <MenuItem
                        key={algor.value}
                        sx={(theme) => ({
                            height: 40,
                            width: 180,
                            fontSize: 14,

                            // @Theme conditional
                            backgroundColor:
                                layoutOptions === algor.value && theme.palette.mode === 'light'
                                    ? theme.palette.common.bg_4
                                    : layoutOptions === algor.value && theme.palette.mode === 'dark'
                                    ? theme.palette.common.bg_5
                                    : undefined,
                        })}
                        onClick={() => applyLayout(algor.value)}
                    >
                        {algor.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
