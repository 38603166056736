//* ======= Libraries
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { v4 as uuidv4 } from 'uuid'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
    Stack,
    Box,
    IconButton,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Stepper,
    Step,
    StepLabel,
    MobileStepper,
    Button,
} from '@mui/material'
import FileSaver from 'file-saver'
//* ======= Components and features
import ReportSlidesListItem, {
    ReportSlidesListItemDragTypeValue,
} from 'features/report-designer/slides-list/ReportSlidesListItem'
import ReportSlidesListItemBasic from 'features/report-designer/slides-list/ReportSlidesListItemBasic'
//* ======= Custom logic
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { ReportSlideType } from 'features/report-designer/types/reportDesigner.types'
import { defaultReportSlideValues } from 'features/report-designer/helpers/reportDesignerDefaultValues'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { SaveSlide } from '../helpers/reportDesigner.helper'
import moment from 'moment'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import WidgetContent from '../widgets/WidgetContent'
import ReportDataSourceSelector from '../data-sources/ReportDataSourceSelector'
import BaseSelect from 'components/base/BaseSelect'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'

function ReportSlidesList() {
    const { status, viewMode, activeSlideId, slides, setActiveSlideId, addSlide, removeSlide, relocateSlide } =
        useReportStore((store) => ({
            status: store.status,
            viewMode: store.viewMode,
            activeSlideId: store.activeSlideId,
            slides: store.slides,
            setActiveSlideId: store.setActiveSlideId,
            addSlide: store.addSlide,
            removeSlide: store.removeSlide,
            relocateSlide: store.relocateSlide,
        }))

    // We need a clone of slides list to handle drag&drop actions internally (onSlideMove)
    // and then update the actual store state only once (onSlideMoveEnd).
    const [slidesListClone, setSlidesListClone] = useState<ReportDesignerStoreStateType['slides']>([])

    useEffect(() => {
        setSlidesListClone(slides)
    }, [slides])

    const onSlideSelect = async (slideId: ReportSlideType['id']) => {
        if (activeSlideId !== slideId) {
            setActiveSlideId({ slideId: slideId })
        }
    }

    const onSlideAdd = () => {
        addSlide({
            slide: {
                ...defaultReportSlideValues,
                id: uuidv4(),
            },
        })
    }

    const onSlideRemove = (slideId: ReportSlideType['id']) => {
        // There should be at least one slide in a report.
        if (slides.length === 1) {
            return
        }

        removeSlide({
            slideId: slideId,
        })
    }

    // ToDo since the widge ids are changed during update, the connected widgets should be updated
    const onDuplicate = (slideId: ReportSlideType['id']) => {
        const targetSlideIndex = slidesListClone.findIndex((_slide) => _slide.id === slideId)

        if (targetSlideIndex !== -1) {
            const targetSlide = structuredClone(slidesListClone[targetSlideIndex]) as ReportSlideType

            addSlide({
                slide: {
                    ...targetSlide,
                    id: uuidv4(),
                    title: `Copy of ${targetSlide.title}`,
                    widgets: targetSlide.widgets.map((_widget) => ({
                        ..._widget,
                        id: uuidv4(),
                    })),
                },
                insertionPosition: targetSlideIndex + 1,
            })
        }
    }

    const onExport = (slideId: ReportSlideType['id']) => {
        const targetSlideIndex = slidesListClone.findIndex((_slide) => _slide.id === slideId)

        if (targetSlideIndex !== -1) {
            const targetSlide = structuredClone(slidesListClone[targetSlideIndex]) as ReportSlideType

            const data = SaveSlide(targetSlide)

            FileSaver.saveAs(
                new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }),
                `${data.title.trim() === '' ? 'Slide' : data.title}-${moment().format('DD-MM-YYYY hh:mm:ss')}.json`
            )
        }
    }

    /* =========================================
     * List items drag and drop
     */

    // Helps with visual drop cursor style when dragging list items. Doesn't do anything else.
    const [, dropTarget] = useDrop(() => ({ accept: ReportSlidesListItemDragTypeValue }))

    const findSlideCloneIndexById = useCallback(
        (id: ReportSlideType['id']) => {
            return slidesListClone.findIndex((_slide) => _slide.id === id)
        },
        [slidesListClone]
    )

    const onSlideMove = useCallback(
        (slideId: ReportSlideType['id'], newIndex: number) => {
            const targetIndex = findSlideCloneIndexById(slideId)

            const targetSlide = slidesListClone[targetIndex]

            if (targetSlide !== undefined) {
                const updatedSlides = [...slidesListClone]
                updatedSlides.splice(targetIndex, 1)
                updatedSlides.splice(newIndex, 0, targetSlide)

                setSlidesListClone(updatedSlides)
            }
        },
        [findSlideCloneIndexById, slidesListClone]
    )

    const onSlideMoveEnd = useCallback(
        (slideId: ReportSlideType['id'], newIndex: number) => {
            const previousIndex = slides.findIndex((_slide) => _slide.id === slideId)

            if (previousIndex !== -1) {
                relocateSlide({
                    previousIndex: previousIndex,
                    newIndex: newIndex,
                })
            }
        },
        [slides]
    )

    const [showImportSlideDialog, setShowImportSlideDialog] = useState(false)

    const handleOpenSlide = () => {
        setShowImportSlideDialog(true)
    }

    const handleCloseSlide = () => {
        setShowImportSlideDialog(false)
    }

    return (
        <Stack
            gap={3}
            sx={{
                minHeight: 0,
                height: '100%',
                paddingX: 2,
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
            className="u-scrollbar"
        >
            {status === 'pending' ? (
                /*  "pending" view
                    ========================================= */
                <Box
                    sx={{
                        height: '100%',
                        paddingBottom: 1,

                        '& > span': {
                            height: '100%',
                        },
                    }}
                >
                    <Skeleton height="100%" />
                </Box>
            ) : status === 'faulty' ? (
                /*  "faulty" view
                    ========================================= */
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    sx={{
                        height: '100%',
                    }}
                >
                    <ErrorOutlineIcon
                        sx={(theme) => ({
                            height: 32,
                            width: 32,

                            color: theme.palette.common.fuschia80,
                        })}
                    />
                </Stack>
            ) : (
                /*  Main view
                    ========================================= */
                <>
                    {/* Slides list
                		========================================= */}
                    <Stack ref={dropTarget} gap={viewMode === 'design' ? 3 : 1.5}>
                        {slidesListClone.map((_slide, idx, array) => {
                            // Design mode
                            if (viewMode === 'design') {
                                return (
                                    /*  Slide item
                                        ========================================= */
                                    <ReportSlidesListItem
                                        key={_slide.id}
                                        id={_slide.id}
                                        title={_slide.title}
                                        thumbnail={_slide.thumbnail}
                                        slide={_slide}
                                        isActive={activeSlideId === _slide.id}
                                        hasRemoveButton={viewMode === 'design' && array.length > 1}
                                        onSelect={() => onSlideSelect(_slide.id)}
                                        onRemove={() => onSlideRemove(_slide.id)}
                                        getPositionIndex={findSlideCloneIndexById}
                                        onMove={onSlideMove}
                                        onMoveEnd={onSlideMoveEnd}
                                        onDuplicate={() => onDuplicate(_slide.id)}
                                        onExport={() => {
                                            onExport(_slide.id)
                                        }}
                                    />
                                )
                            }
                            // Preview mode
                            else {
                                return (
                                    /*  Slide basic item
                                        ========================================= */
                                    <ReportSlidesListItemBasic
                                        key={_slide.id}
                                        id={_slide.id}
                                        title={_slide.title}
                                        thumbnail={_slide.thumbnail}
                                        isActive={activeSlideId === _slide.id}
                                        onSelect={() => onSlideSelect(_slide.id)}
                                        getPositionIndex={findSlideCloneIndexById}
                                    />
                                )
                            }
                        })}
                    </Stack>

                    {/* Add new slide button
                		========================================= */}
                    {viewMode === 'design' && (
                        <>
                            <IconButton
                                onClick={(evt) => onSlideAdd()}
                                color="primary"
                                sx={(theme) => ({
                                    height: 100,
                                    width: '100%',

                                    borderRadius: '10px',
                                    border: '1px solid transparent',
                                    backgroundColor: theme.palette.common.bg_4,

                                    '&:hover': {
                                        borderColor: theme.palette.common.border_2,
                                    },
                                })}
                            >
                                <AddIcon
                                    sx={{
                                        height: 36,
                                        width: 36,
                                    }}
                                />
                            </IconButton>
                            <BaseButton onClick={handleOpenSlide} variant="text" color="primary" fullWidth>
                                Import Slide
                            </BaseButton>

                            <ImportSlideDialog isOpen={showImportSlideDialog} onClose={handleCloseSlide} />
                        </>
                    )}
                </>
            )}
        </Stack>
    )
}

export default ReportSlidesList

type ImportSlideDialogProps = {
    isOpen: boolean
    onClose: () => void
}

const ImportSlideDialog: React.FC<ImportSlideDialogProps> = ({ isOpen, onClose }) => {
    const { addSlide, dataSources } = useReportStore((store) => ({
        addSlide: store.addSlide,
        dataSources: store.dataSources,
    }))

    const [datasourceMap, setDatasourceMap] = useState<{
        [key: string | number]: {
            type: string
            mappedTo: string | null
        }
    }>({})
    const [data, setData] = useState<ReportSlideType | null>(null)
    const [error, setError] = useState<string | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!isOpen) {
            setData(null)
            setError(null)
            setDatasourceMap({})
        }
    }, [isOpen])

    const onFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const files = evt.target.files

        if (files && files.length > 0) {
            const file = files[0]
            // read the file and store the data
            const reader = new FileReader()
            reader.onload = (e) => {
                try {
                    const result = JSON.parse(e.target?.result as string) as ReportSlideType

                    const _datasourceMap: {
                        [key: string | number]: {
                            type: string
                            mappedTo: string | null
                        }
                    } = {}
                    result.widgets.forEach((widget) => {
                        switch (widget.content.kind) {
                            case 'chart':
                            case 'filter':
                            case 'table':
                            case 'insight':
                                const selectedDatasource = widget.content.details.selectedDataSource
                                if (selectedDatasource?.id && !datasourceMap[selectedDatasource.id]) {
                                    _datasourceMap[selectedDatasource.id] = {
                                        type: selectedDatasource.datasourceType,
                                        mappedTo: null,
                                    }
                                }
                                break
                            case 'network':
                                const selectedNetwork = widget.content.details.networkDatasourceId
                                if (selectedNetwork && !datasourceMap[selectedNetwork]) {
                                    _datasourceMap[selectedNetwork] = {
                                        type: 'network',
                                        mappedTo: null,
                                    }
                                }
                                break
                        }
                    })
                    setDatasourceMap(_datasourceMap)
                    setData(result)
                } catch (err) {
                    setError('Invalid file format')
                }
            }
            reader.readAsText(file)
            setError(null)
        }
    }

    const onFileSelect = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    const onSlideAdd = () => {
        if (data) {
            addSlide({
                slide: {
                    ...data,
                    id: uuidv4(),
                    widgets: data.widgets.map((widget) => {
                        const _widget = structuredClone(widget)
                        switch (_widget.content.kind) {
                            case 'chart':
                            case 'filter':
                            case 'table':
                            case 'insight':
                                let id = _widget.content.details.selectedDataSource?.id
                                if (id && datasourceMap[id]?.mappedTo != null) {
                                    _widget.content.details.selectedDataSource!.id = datasourceMap[id].mappedTo!
                                }
                                break
                            case 'network':
                                let networkId = _widget.content.details.networkDatasourceId
                                if (networkId && datasourceMap[networkId]?.mappedTo != null) {
                                    _widget.content.details.networkDatasourceId = datasourceMap[networkId].mappedTo!
                                }
                                break
                        }
                        return _widget
                    }),
                },
            })
            onClose()
        }
    }

    return (
        <StyledDialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>Import Slide</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <BaseButton onClick={onFileSelect} variant="outlined" fullWidth>
                        Select file
                    </BaseButton>
                    <input
                        ref={inputRef}
                        type="file"
                        accept=".json"
                        style={{ display: 'none' }}
                        onChange={onFileChange}
                    />

                    {data !== null && (
                        <Stack gap={1}>
                            <BaseFilledTextField
                                label="Title"
                                value={data.title}
                                fullWidth
                                size="small"
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        title: e.target.value,
                                    })
                                }}
                            />

                            <Typography variant="body2" color="textSecondary">
                                Data source Mapping
                            </Typography>

                            {Object.entries(datasourceMap).map(([key, value]) => {
                                return (
                                    <Stack key={key} gap={1}>
                                        <Typography variant="body2" color="textSecondary">
                                            {key}
                                        </Typography>

                                        <BaseSelectWithLabel
                                            value={value.mappedTo}
                                            options={dataSources.map((ds) => ds.id)}
                                            label="Select data source"
                                            onChange={(v) => {
                                                setDatasourceMap((pv) => ({
                                                    ...pv,
                                                    [key]: {
                                                        ...pv[key],
                                                        mappedTo: v,
                                                    },
                                                }))
                                            }}
                                        />
                                    </Stack>
                                )
                            })}
                        </Stack>
                    )}

                    {error && <Typography color="error">{error}</Typography>}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} variant="text" color="secondary">
                    Cancel
                </BaseButton>
                <BaseButton disabled={data === null} onClick={onSlideAdd} variant="contained" color="primary">
                    Import
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
