//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { Stack, Box, Typography, IconButton, Divider, Stepper, Step, StepLabel, Tooltip } from '@mui/material'
//* ======= Components and features
import Chart from 'features/chart/Chart'
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import StyledDialog from 'components/dialog/StyledDialog'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { generateChartOptions, getChartTypeInitialValues } from 'features/chart/Chart.helper'
import {
    getChartAttributes,
    getDataSourceData,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { AGGREGATION_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { ReportRadarChartWidgetSettingsStateType } from 'features/report-designer/widgets/chart-widget/settings/RadarChartWidgetSettings'
import { RadarChartDataDefinitionType, RadarChartOptionsType } from 'features/chart/Chart.asset'
import { SelectedDataSourceType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ColorPicker from 'components/widgets/ColorPicker'

const radarChartDataConfigurationSteps = ['Set Indicators', 'Set Series']

// We remove  "Count" options since they have no use-case here.
const radarSeriesAggregationsOptions = AGGREGATION_OPTIONS.filter((_option) => _option.value !== 'count')

type Props = {
    isOpen: boolean
    data: ReportRadarChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onConfirm: (data: Pick<ReportRadarChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>) => void
    onClose: () => void
}

function RadarChartDataConfigDialog({
    isOpen = false,
    data,
    dataSources,
    onConfirm,
    onClose = () => undefined,
}: Props) {
    const [chartData, setChartData] = useState<ReportRadarChartWidgetSettingsStateType>(data)

    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])
    const [chartOptions, setChartOptions] = useState<RadarChartOptionsType | null>(chartData.options)

    // Stepper state
    const [activeStep, setActiveStep] = useState(0)

    // Prevent users from moving on to settings series data if indicators don't have valid values.
    const areIndicatorsValid = useMemo(() => {
        if (
            chartData.selectedDataSource === null ||
            chartData.selectedDataSource.id === -1 ||
            chartData.dataDefinition.indicators.length < 3
        )
            return false

        for (let _indicator of chartData.dataDefinition.indicators) {
            if (_indicator.field.field === '') return false
        }

        return true
    }, [chartData])

    // Prevent user from applying any changes if the selected data configurations are not valid
    const isChartConfigValid = useMemo(() => {
        if (
            chartData.selectedDataSource === null ||
            chartData.selectedDataSource.id === -1 ||
            chartData.dataDefinition.indicators.length < 3 ||
            chartData.dataDefinition.series.length === 0
        ) {
            return false
        }

        for (let indicator of chartData.dataDefinition.indicators) {
            if (indicator.field.field === '') return false
        }

        for (let _series of chartData.dataDefinition.series) {
            if (_series.aggregationMethod !== 'count' && _series.attribute.field === '') return false
        }

        return true
    }, [chartData])

    // After selected data source changes, update and show its fields.
    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, chartData.selectedDataSource))
    }, [chartData.selectedDataSource])

    // Update dialog's chart options after chartData state changes.
    useEffect(() => {
        if (isChartConfigValid) {
            setChartOptions((prevState) => {
                const newOptions = generateChartOptions({
                    type: 'radar',
                    mode: 'data',
                    prevOptions: prevState,
                    filteredData: getDataSourceData(
                        dataSources,
                        chartData.selectedDataSource,
                        getChartAttributes(chartData.type, chartData.dataDefinition)
                    ),
                    dataDefinition: chartData.dataDefinition,
                    config: chartData.config,
                })

                return { ...newOptions }
            })
        } else {
            setChartOptions(null)
        }
    }, [chartData, isChartConfigValid])

    const updateSelectedDataSource = (selectedDataSource: SelectedDataSourceType) => {
        const initialValues = getChartTypeInitialValues(chartData.type)

        if (initialValues !== null) {
            setChartData((prevState) => {
                // If the selected data source was changed (based on some of its properties that are considered important),
                // we need to reset some chart state properties to their initial values:
                let hasDataSourceChanged = false
                if (
                    prevState.selectedDataSource === null ||
                    prevState.selectedDataSource.id !== selectedDataSource.id ||
                    prevState.selectedDataSource.preset !== selectedDataSource.preset ||
                    prevState.selectedDataSource.type !== selectedDataSource.type
                ) {
                    hasDataSourceChanged = true
                }

                return {
                    ...prevState,
                    selectedDataSource: {
                        ...prevState.selectedDataSource,
                        ...selectedDataSource,
                    },
                    dataDefinition: hasDataSourceChanged
                        ? (initialValues.dataDefinition as RadarChartDataDefinitionType)
                        : { ...prevState.dataDefinition },
                    options: hasDataSourceChanged ? null : { ...prevState.options },
                }
            })

            // Force the user to set indicators from the beginning.
            setActiveStep(0)
        }
    }

    /* =========================================
     * Indicators
     */

    const addIndicator = () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                // TODO: What should the initial "max" value be?
                indicators: [
                    ...prevState.dataDefinition.indicators,
                    {
                        field: {
                            field: '',
                            type: 'basic',
                        },
                        max: 10,
                        title: '',
                    },
                ],
            },
        }))
    }

    const deleteIndicator = (indicatorListIndex: number) => () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                indicators: prevState.dataDefinition.indicators.filter((_indicator, idx) => idx !== indicatorListIndex),
            },
        }))
    }

    const updateIndicatorFields = (
        indicatorListIndex: number,
        field: keyof ReportRadarChartWidgetSettingsStateType['dataDefinition']['indicators'][number],
        value: any
    ) => {
        setChartData((prevState) => {
            const newIndicatorsList = structuredClone(prevState.dataDefinition.indicators)

            newIndicatorsList[indicatorListIndex] = {
                ...newIndicatorsList[indicatorListIndex],
                [field]: value,
            }

            return {
                ...prevState,
                dataDefinition: {
                    ...prevState.dataDefinition,
                    indicators: [...newIndicatorsList],
                },
            }
        })
    }

    /* =========================================
     * Series
     */

    const addSeries = () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                series: [
                    ...prevState.dataDefinition.series,
                    {
                        aggregationMethod: 'avg',
                        attribute: {
                            type: 'custom',
                            field: 'Total',
                        },
                        color: {
                            isEnabled: true,
                            value: '#2D77BC',
                        },
                    },
                ],
            },
        }))
    }

    const deleteSeries = (seriesListIndex: number) => () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                series: prevState.dataDefinition.series.filter((_series, idx) => idx !== seriesListIndex),
            },
        }))
    }

    const updateSeriesFields = (
        seriesListIndex: number,
        data: Partial<ReportRadarChartWidgetSettingsStateType['dataDefinition']['series'][number]>
    ) => {
        setChartData((prevState) => {
            const updatedSeriesList = structuredClone(prevState.dataDefinition.series)

            updatedSeriesList[seriesListIndex] = {
                ...updatedSeriesList[seriesListIndex],
                ...data,
            }

            return {
                ...prevState,
                dataDefinition: {
                    ...prevState.dataDefinition,
                    series: [...updatedSeriesList],
                },
            }
        })
    }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',

                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),

                    padding: theme.spacing(2, 3),

                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Radar Chart Data Configuration
                </Typography>

                <IconButton
                    onClick={(evt) => onClose()}
                    size="small"
                    sx={{
                        gridColumn: 3,
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 1 auto',

                    display: 'grid',
                    gridTemplateColumns: '10fr 9fr',
                    placeContent: 'stretch',
                    columnGap: 2,

                    height: '70vh',
                    padding: theme.spacing(3),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {/* Content wrapper
                    ========================================= */}
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        paddingRight: theme.spacing(2),
                        overflowY: 'auto',

                        borderRight: `1px solid ${theme.palette.common.border_3}`,
                    })}
                    className="u-scrollbar"
                >
                    {/* Data source selector
                        ========================================= */}
                    <ReportDataSourceSelector
                        dataSources={dataSources}
                        selectedDataSource={chartData.selectedDataSource}
                        onChange={updateSelectedDataSource}
                    />

                    <Divider />

                    {/* Stepper header
                        ========================================= */}
                    <Stepper
                        activeStep={activeStep}
                        sx={{
                            paddingY: 1,
                        }}
                    >
                        {radarChartDataConfigurationSteps.map((_step) => (
                            <Step key={_step}>
                                <StepLabel>{_step}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {/* Stepper content
                        ========================================= */}
                    {activeStep === 0 ? (
                        /*  Indicators step
                            ========================================= */
                        <>
                            {/* Indicators header
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    textAlign="left"
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Indicators:
                                </Typography>

                                {/* Add indicator button
                                    ========================================= */}
                                <BaseButton
                                    label="Add Indicator"
                                    onClick={(evt) => addIndicator()}
                                    startIcon={<AddIcon />}
                                />
                            </Stack>

                            {/* Indicators list
                                ========================================= */}
                            <Stack gap={2}>
                                {chartData.dataDefinition.indicators.map((_indicator, idx) => (
                                    <Stack
                                        key={idx}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        {/* Field field
                                            ========================================= */}
                                        <FlexibleSelect
                                            label="Field"
                                            options={dataSourceFields}
                                            value={dataSourceFields.length !== 0 ? _indicator.field : undefined}
                                            onChange={(value) => updateIndicatorFields(idx, 'field', value)}
                                            disabled={dataSourceFields.length === 0}
                                            required
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        />

                                        {/* Max field
                                            ========================================= */}
                                        <BaseFilledTextField
                                            type="number"
                                            label="Max"
                                            defaultValue={_indicator.max}
                                            onBlur={(evt) =>
                                                updateIndicatorFields(idx, 'max', Number(evt.target.value))
                                            }
                                            disabled={dataSourceFields.length === 0}
                                            // TODO: Define these for safer input values.
                                            // inputProps={{
                                            //     min: values[0],
                                            //     max: max,
                                            //     step: step,
                                            // }}
                                            required
                                            sx={{
                                                width: 120,
                                            }}
                                        />

                                        {/* Title field
                                            ========================================= */}
                                        <BaseFilledTextField
                                            label="Title"
                                            defaultValue={_indicator.title}
                                            onBlur={(evt) =>
                                                updateIndicatorFields(idx, 'title', evt.target.value.trim())
                                            }
                                            disabled={dataSourceFields.length === 0}
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        />

                                        {/* Delete button
                                            ========================================= */}
                                        <Tooltip title="Delete">
                                            {/* Intermediary div to handle MUI Tooltip "disabled content" error. */}
                                            <Box>
                                                <IconButton
                                                    onClick={deleteIndicator(idx)}
                                                    disabled={chartData.dataDefinition.indicators.length < 4}
                                                    sx={(theme) => ({
                                                        flexShrink: 0,

                                                        color: theme.palette.common.ung_pink,
                                                    })}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </Stack>
                                ))}
                            </Stack>
                        </>
                    ) : (
                        /*  Series step
                            ========================================= */
                        <>
                            {/* Series header
                                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <Typography
                                    textAlign="left"
                                    noWrap
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Series:
                                </Typography>

                                {/* Add indicator button
                                    ========================================= */}
                                <BaseButton
                                    label="Add Series"
                                    onClick={(evt) => addSeries()}
                                    disabled={dataSourceFields.length === 0}
                                    startIcon={<AddIcon />}
                                />
                            </Stack>

                            {/* Series list
                                ========================================= */}
                            <Stack gap={2}>
                                {chartData.dataDefinition.series.map((_series, idx) => (
                                    <Stack
                                        key={idx}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        {/* Field field
                                            ========================================= */}
                                        <FlexibleSelect
                                            label="Field"
                                            options={[
                                                {
                                                    label: 'Total',
                                                    value: {
                                                        type: 'custom',
                                                        field: 'Total',
                                                    },
                                                },
                                                {
                                                    label: 'Each Row',
                                                    value: {
                                                        type: 'custom',
                                                        field: 'Each Row',
                                                    },
                                                },
                                                ...dataSourceFields,
                                            ]}
                                            value={_series.attribute}
                                            onChange={(value) =>
                                                updateSeriesFields(idx, {
                                                    attribute: value,
                                                })
                                            }
                                            required
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        />

                                        {/* Label and aggregation method fields
                                            ========================================= */}
                                        {_series.attribute.type === 'custom' &&
                                        _series.attribute.field === 'Each Row' ? (
                                            /*  Label field
                                                ========================================= */
                                            <FlexibleSelect
                                                label="Label"
                                                options={dataSourceFields}
                                                value={dataSourceFields.length !== 0 ? _series.fieldLabel : undefined}
                                                onChange={(value) =>
                                                    updateSeriesFields(idx, {
                                                        fieldLabel: value,
                                                    })
                                                }
                                                disabled={dataSourceFields.length === 0}
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            />
                                        ) : (
                                            /*  Aggregation method field
                                                ========================================= */
                                            <BaseSelectWithLabel
                                                label="Aggregation Method"
                                                options={radarSeriesAggregationsOptions}
                                                value={_series.aggregationMethod}
                                                onChange={(value) =>
                                                    updateSeriesFields(idx, {
                                                        aggregationMethod: value,
                                                    })
                                                }
                                                required
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            />
                                        )}

                                        {/* (Conditional) Color picker
                                            ========================================= */}
                                        {_series.attribute.type === 'custom' && _series.attribute.field === 'Total' && (
                                            <Box
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            >
                                                <ColorPicker
                                                    value={_series.color.value}
                                                    onChange={(newColor) =>
                                                        updateSeriesFields(idx, {
                                                            color: {
                                                                ..._series.color,
                                                                value: newColor,
                                                            },
                                                        })
                                                    }
                                                    disabled={_series.color.isEnabled === false}
                                                    isPopover
                                                />
                                            </Box>
                                        )}

                                        {/* Delete button
                                            ========================================= */}
                                        <Tooltip title="Delete">
                                            <IconButton
                                                onClick={deleteSeries(idx)}
                                                sx={(theme) => ({
                                                    flexShrink: 0,

                                                    color: theme.palette.common.ung_pink,
                                                })}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                ))}
                            </Stack>
                        </>
                    )}
                </Stack>

                {/* Chart wrapper
                    ========================================= */}
                {chartOptions === null ? (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: '100%',
                        }}
                    >
                        Invalid/Empty data.
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Chart type={chartData.type} options={chartOptions} clearOnChange={true} />
                    </Box>
                )}
            </Box>

            {/* Footer
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    padding: theme.spacing(2, 3),
                })}
            >
                {/* Step back button
                    ========================================= */}
                {activeStep === 1 && (
                    <BaseButton
                        label="Back"
                        onClick={(evt) => setActiveStep(0)}
                        variant="outlined"
                        color="secondary"
                        sx={(theme) => ({
                            minWidth: 180,
                            paddingY: 1,
                            marginRight: 'auto',

                            color: theme.palette.common.fill_1,
                        })}
                    />
                )}

                <BaseButton
                    label="Discard"
                    onClick={(evt) => onClose()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                {/* Apply/Next Step button
                    ========================================= */}
                <BaseButton
                    label={activeStep === 0 ? 'Next' : 'Apply'}
                    onClick={(evt) => (activeStep === 0 ? setActiveStep(1) : onConfirm(chartData))}
                    disabled={activeStep === 0 ? areIndicatorsValid === false : isChartConfigValid === false}
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </StyledDialog>
    )
}

export default RadarChartDataConfigDialog
