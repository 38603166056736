//* ======= Libraries
import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo, useContext } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useParams } from 'react-router-dom'
import { Stack, Box, IconButton, Tooltip, Typography, useMediaQuery, useTheme, Divider } from '@mui/material'
//* ======= Components and features
import ReportViewLogin from 'features/report-designer/view/ReportViewLogin'
import ReportLeftSidebar from 'features/report-designer/layout-containers/ReportLeftSidebar'
import ReportSlideContainer from 'features/report-designer/layout-containers/ReportSlideContainer'
import ReportViewFullscreen from 'features/report-designer/view/ReportViewFullscreen'
import StyledDialog from 'components/dialog/StyledDialog'
import TermsOfServiceDialog from 'features/sign-in/TermsOfServiceDialog'
import PrivacyPolicyDialog from 'features/sign-in/PrivacyPolicyDialog'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import { throttle } from 'helpers/helpers'
import { GetSampleReport } from 'services/ReportApi'
//* ======= Assets and styles
import SNALogo from 'assets/images/sna-toolbox-logo.png'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { RootContext } from 'contexts/RootContext'

function ReportViewDemo() {
    const { setPath } = useContext(RootContext)

    const { token } = useParams()

    const {
        status,
        title,
        layoutSettings,
        slides,
        activeSlideId,
        resetReport,
        updateStatus,
        updateLayoutLeftSidebar,
        setActiveSlideId,
        loadReport,
    } = useReportStore((store) => ({
        status: store.status,
        title: store.title,
        layoutSettings: store.layoutSettings,
        slides: store.slides,
        activeSlideId: store.activeSlideId,
        resetReport: store.resetReport,
        updateStatus: store.updateStatus,
        updateLayoutLeftSidebar: store.updateLayoutLeftSidebar,
        setActiveSlideId: store.setActiveSlideId,
        loadReport: store.loadReport,
    }))

    const muiTheme = useTheme()
    const isViewportSmall = useMediaQuery(muiTheme.breakpoints.down('md'))

    const fullscreenContainerRef = useRef<HTMLDivElement>(null)
    const [isFullscreen, setIsFullscreen] = useState(false)

    const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false)
    const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)

    // Left sidebar collapse/expand state
    const [isLeftSidebarCollapsed, setIsLeftSidebarCollapsed] = useState(true)

    // Handle left sidebar overlay and collapse/expand on window resize
    const windowWidthRef = useRef(0)
    const onWindowResize = useCallback(
        throttle(() => {
            if (window.innerWidth < muiTheme.breakpoints.values.xl) {
                updateLayoutLeftSidebar({
                    settings: {
                        isOverlayed: true,
                    },
                })

                if (windowWidthRef.current > muiTheme.breakpoints.values.xl && isLeftSidebarCollapsed === false) {
                    setIsLeftSidebarCollapsed(true)
                }
            } else {
                updateLayoutLeftSidebar({
                    settings: {
                        isOverlayed: false,
                    },
                })
            }

            windowWidthRef.current = window.innerWidth
        }, 200),
        []
    )

    const currentSlideNumber = useMemo(() => {
        return slides.findIndex((x) => x.id === activeSlideId) + 1
    }, [slides, activeSlideId])

    const fetchReport = async () => {
        if (token === undefined) return

        try {
            const response = await GetSampleReport(token)

            if (response.success === false) {
                updateStatus({
                    status: 'faulty',
                    errorMessage: 'Unable to fetch report data!',
                })

                return
            }

            // Load report data and update store
            loadReport({
                responseData: response.data,
                viewMode: 'preview',
                reportId: 'sample',
            })
        } catch (error) {
            updateStatus({
                status: 'faulty',
                errorMessage: 'Unable to fetch report data!',
            })
        }
    }

    // Fetch report data and update store
    useEffect(() => {
        /*
         * This check ensures that inside the report designer page, we can "reset" everything
         * and re-fetch data from server by simply dispatching an action to the store and setting
         * the "status" property to "pending".
         * This can be useful if we want to get out of a faulty state or any other situation which requires
         * a reset of the report page.
         */
        if (status === 'pending') {
            fetchReport()
        }
    }, [status])

    // Left toolbar collapse/expande logic
    useEffect(() => {
        if (window.innerWidth < muiTheme.breakpoints.values.xl) {
            updateLayoutLeftSidebar({
                settings: {
                    isOverlayed: true,
                },
            })
        }

        window.addEventListener('resize', onWindowResize)

        return () => {
            // Reset the report store when leaving this route/component.
            resetReport()

            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    // Fullscreen view effect
    useLayoutEffect(() => {
        if (isFullscreen) {
            if (fullscreenContainerRef.current !== null) {
                fullscreenContainerRef.current.requestFullscreen()

                function onFullscreenChange() {
                    if (Boolean(document.fullscreenElement) === false) {
                        setIsFullscreen(false)
                    }
                }

                document.addEventListener('fullscreenchange', onFullscreenChange)

                return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
            }
        } else {
            if (Boolean(document.fullscreenElement) === true) {
                document.exitFullscreen()
            }
        }
    }, [isFullscreen])

    useEffect(() => {
        setPath((p) => [...p, { title: title }])
    }, [title, setPath])

    const navigateSlides = (step: 1 | -1) => {
        const activeSlideIndex = slides.findIndex((_slide) => _slide.id === activeSlideId)

        if (activeSlideIndex === -1) {
            return
        }

        let newActiveSlideId: ReportDesignerStoreStateType['activeSlideId'] = null

        if (step === 1) {
            if (activeSlideIndex !== slides.length - 1) {
                newActiveSlideId = slides[activeSlideIndex + 1].id
            }
        } else if (step === -1) {
            if (activeSlideIndex !== 0) {
                newActiveSlideId = slides[activeSlideIndex - 1].id
            }
        }

        if (newActiveSlideId === null) {
            return
        }

        setActiveSlideId({
            slideId: newActiveSlideId,
        })
    }

    return (
        <Stack
            sx={(theme) => ({
                height: '100%',
            })}
        >
            {isFullscreen ? (
                /*  Fullscreen view
                    ========================================= */
                <Box
                    ref={fullscreenContainerRef}
                    sx={{
                        height: '100%',
                    }}
                >
                    <ReportViewFullscreen onExitFullscreen={() => setIsFullscreen(false)} />
                </Box>
            ) : (
                /*  Normal view
                    ========================================= */
                <>
                    {/* Body
                        ========================================= */}
                    <Stack
                        direction="row"
                        gap={1.5}
                        sx={(theme) => ({
                            flexGrow: 1,
                            alignSelf: 'center',

                            position: 'relative',

                            width: '100%',
                            maxWidth: theme.breakpoints.values.xl,
                            minHeight: 0,
                            height: '100%',
                            paddingTop: 1,
                            paddingLeft: layoutSettings.leftSidebar.isOverlayed ? 1 : 0,
                            paddingRight: 1,
                        })}
                    >
                        <DndProvider backend={HTML5Backend}>
                            {/* Left sidebar
                                ========================================= */}
                            <Box
                                sx={(theme) => ({
                                    position: layoutSettings.leftSidebar.isOverlayed ? 'absolute' : undefined,
                                    top: layoutSettings.leftSidebar.isOverlayed ? 0 : undefined,
                                    left: layoutSettings.leftSidebar.isOverlayed ? 0 : undefined,
                                    zIndex: layoutSettings.leftSidebar.isOverlayed ? 5 : undefined,

                                    flexShrink: 0,

                                    height: '100%',
                                    paddingY: theme.spacing(2),

                                    boxShadow: 1,
                                    // @Theme conditional
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? theme.palette.common.bg_1
                                            : theme.palette.common.bg_3,
                                })}
                            >
                                {/* Sidebar content
                                    ========================================= */}
                                <Box
                                    sx={(theme) => ({
                                        height: '100%',
                                        width:
                                            layoutSettings.leftSidebar.isOverlayed && isLeftSidebarCollapsed
                                                ? 0
                                                : layoutSettings.leftSidebar.width,
                                        pointerEvents:
                                            layoutSettings.leftSidebar.isOverlayed && isLeftSidebarCollapsed
                                                ? 'none'
                                                : 'auto',

                                        transition: `width ${layoutSettings.leftSidebar.transitionDuration}ms ease-in-out, opacity 0.125s linear`,
                                        opacity:
                                            layoutSettings.leftSidebar.isOverlayed && isLeftSidebarCollapsed ? 0 : 1,
                                    })}
                                >
                                    <ReportLeftSidebar />
                                </Box>

                                {/* Expand/Collapse button
                                    ========================================= */}
                                {layoutSettings.leftSidebar.isOverlayed && (
                                    <Tooltip
                                        title={isLeftSidebarCollapsed ? 'Expand Slides List' : 'Collpase Slides List'}
                                        enterDelay={200}
                                        arrow
                                        placement="right"
                                    >
                                        <IconButton
                                            onClick={(evt) => setIsLeftSidebarCollapsed((prevState) => !prevState)}
                                            sx={(theme) => ({
                                                position: 'absolute',
                                                top: '50%',
                                                right: 0,
                                                zIndex: 5,

                                                padding: theme.spacing(0.5),
                                                paddingLeft: isLeftSidebarCollapsed
                                                    ? theme.spacing(1.5)
                                                    : theme.spacing(0.5),
                                                overflow: 'hidden',

                                                transition: `transform ${
                                                    layoutSettings.leftSidebar.transitionDuration + 100
                                                }ms ease-in-out`,
                                                transform: isLeftSidebarCollapsed
                                                    ? 'translateY(-50%) translateX(65%)'
                                                    : 'translateY(-50%) translateX(50%)',
                                                boxShadow: 2,
                                                // @Theme conditional
                                                backgroundColor:
                                                    theme.palette.mode === 'light'
                                                        ? theme.palette.common.bg_2
                                                        : theme.palette.common.bg_4,

                                                '&:hover': {
                                                    // @Theme conditional
                                                    backgroundColor:
                                                        theme.palette.mode === 'light'
                                                            ? theme.palette.common.bg_1
                                                            : theme.palette.common.bg_3,
                                                },
                                            })}
                                        >
                                            <ChevronLeftIcon
                                                sx={{
                                                    height: 32,
                                                    width: 32,

                                                    transition: `transform ${
                                                        layoutSettings.leftSidebar.transitionDuration + 100
                                                    }ms ease-in-out`,
                                                    transform: isLeftSidebarCollapsed ? 'rotate(-180deg)' : undefined,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>

                            {/* Main area
                                ========================================= */}
                            <Stack
                                sx={(theme) => ({
                                    flexGrow: 1,

                                    minWidth: 0,
                                    minHeight: 0,
                                    overflowY: 'auto',
                                })}
                                className="u-scrollbar"
                            >
                                {/* Slide container
                                    ========================================= */}
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        minHeight: 0,
                                    }}
                                >
                                    <ReportSlideContainer />
                                </Box>

                                {/* Bottom controls
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    gap={1.5}
                                    sx={{
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        marginY: 1,
                                        paddingX: 1,
                                    }}
                                >
                                    {/* Slide indicator
                                        ========================================= */}
                                    {slides.length > 0 && (
                                        <Typography
                                            sx={{
                                                marginRight: 'auto',
                                                fontSize: isViewportSmall ? '1rem' : '1.2rem',
                                                fontWeight: isViewportSmall ? 400 : 600,
                                            }}
                                            // color={reportInfo.color}
                                        >
                                            {`${
                                                isViewportSmall === false
                                                    ? `Slide ${currentSlideNumber}`
                                                    : currentSlideNumber
                                            } of ${slides.length}`}
                                        </Typography>
                                    )}

                                    {/* Fullscreen button
                                        ========================================= */}
                                    <Tooltip title="Fullscreen" arrow placement="bottom">
                                        <IconButton
                                            onClick={(evt) => {
                                                setIsFullscreen(true)
                                            }}
                                        >
                                            <FullscreenIcon
                                                sx={{
                                                    width: 32,
                                                    height: 32,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Divider orientation="vertical" />

                                    {/* Previous slide button
                                        ========================================= */}
                                    <Tooltip
                                        title={currentSlideNumber === 1 ? '' : 'Previous Slide'}
                                        arrow
                                        placement="bottom"
                                    >
                                        <IconButton
                                            onClick={(evt) => navigateSlides(-1)}
                                            disabled={currentSlideNumber === 1}
                                            sx={(theme) => ({
                                                border: `1px solid ${theme.palette.common.border_2}`,

                                                '&.Mui-disabled': {
                                                    borderColor: 'transparent',
                                                },
                                            })}
                                        >
                                            <ChevronLeftIcon
                                                sx={{
                                                    width: 32,
                                                    height: 32,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    {/* Next slide button
                                        ========================================= */}
                                    <Tooltip
                                        title={currentSlideNumber === slides.length ? '' : 'Next Slide'}
                                        arrow
                                        placement="bottom"
                                    >
                                        <IconButton
                                            onClick={(evt) => navigateSlides(1)}
                                            disabled={currentSlideNumber === slides.length}
                                            sx={(theme) => ({
                                                border: `1px solid ${theme.palette.common.border_2}`,

                                                '&.Mui-disabled': {
                                                    borderColor: 'transparent',
                                                },
                                            })}
                                        >
                                            <ChevronLeftIcon
                                                sx={{
                                                    width: 32,
                                                    height: 32,

                                                    transform: 'rotate(180deg)',
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>

                                {/* Footer
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                    sx={{
                                        flexShrink: 0,

                                        py: 2,
                                        px: 3,

                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        backgroundColor: 'common.bg_1',
                                    }}
                                >
                                    <img
                                        src={SNALogo}
                                        alt="SNA logo"
                                        style={{
                                            height: 30,
                                            width: 140,
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                        }}
                                    />

                                    <Typography fontSize={10} fontWeight={400} color="common.text_3" marginLeft="auto">
                                        Powered by SNA Toolbox
                                    </Typography>

                                    <Stack direction="row" justifyContent="center" gap={0} alignItems="center">
                                        {/* Privacy policy button
						                    ========================================= */}
                                        <BaseButton
                                            label="Privacy Policy"
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 400,
                                            }}
                                            onClick={(evt) => setIsPrivacyPolicyDialogOpen(true)}
                                            color="primary"
                                        />

                                        {/* Terms of use button
						                    ========================================= */}
                                        <BaseButton
                                            label="Terms of Use"
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 400,
                                            }}
                                            onClick={(evt) => setIsTermsOfServiceDialogOpen(true)}
                                            color="primary"
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </DndProvider>
                    </Stack>
                </>
            )}

            {/* (Out-of-flow) Privacy policy and terms of service dialogs
				========================================= */}
            {(isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen) && (
                <StyledDialog
                    open={isPrivacyPolicyDialogOpen || isTermsOfServiceDialogOpen}
                    onClose={() =>
                        isPrivacyPolicyDialogOpen
                            ? setIsPrivacyPolicyDialogOpen(false)
                            : setIsTermsOfServiceDialogOpen(false)
                    }
                    fullWidth
                    maxWidth="lg"
                >
                    {isPrivacyPolicyDialogOpen ? (
                        <PrivacyPolicyDialog onClose={() => setIsPrivacyPolicyDialogOpen(false)} />
                    ) : isTermsOfServiceDialogOpen ? (
                        <TermsOfServiceDialog onClose={() => setIsTermsOfServiceDialogOpen(false)} />
                    ) : (
                        false
                    )}
                </StyledDialog>
            )}
        </Stack>
    )
}

export default ReportViewDemo
