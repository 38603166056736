//* ======= Libraries
import React from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
//* ======= Custom logic
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

interface ITermsOfServiceDialog {
    onClose: () => void
}

function TermsOfServiceDialog({ onClose }: ITermsOfServiceDialog) {
    return (
        <Stack
            gap={3}
            height="100%"
            py={3}
            px={3}
            sx={{
                overflowY: 'auto',
            }}
        >
            {/* Title and close button
				========================================= */}
            <Stack flexShrink={0} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography fontSize={36} fontWeight={600} lineHeight="24px" color="primary">
                    TERMS OF USE
                </Typography>

                {/* Close button
					========================================= */}
                <IconButton
                    onClick={(e) => onClose()}
                    sx={{
                        padding: 1,
                    }}
                >
                    <CloseRoundedIcon
                        sx={{
                            height: '22px',
                            width: '22px',

                            // color: 'common.fill_2',
                        }}
                    />
                </IconButton>
            </Stack>

            {/* Content
				========================================= */}
            <Stack
                flexGrow={1}
                gap={2}
                sx={{
                    overflowY: 'auto',
                }}
                className="u-scrollbar"
            >
                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    “We” are SNA Toolbox Pty Ltd. We operate this website https://www.snatoolbox.com/ (“Site”)
                    and provide data collection and analysis services utilising proprietary and third party
                    technologies, including the VPNet and SNA Toolbox tools (“Services”).
                </Typography>

                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    “You” are a user of the Site and/or the Services. If you use the Site and/or Services on
                    behalf of a company, organisation or other entity, then “you” also includes that company,
                    organisation or other entity.
                </Typography>

                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    A “Participant” is any individual that participates in a network survey in relation to the
                    Services. Other defined terms are set out at the end of these Terms of Use.
                </Typography>

                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    If you access the Services as a registered user with an account (“Registered User”), then
                    the terms set out in clauses 9 to 11. below apply specifically to you.
                </Typography>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    These Terms
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        These Terms of Use, together with our Privacy Policy, apply to your use of the Site
                        and Services. By using the Site or the Services, you agree to these Terms of Use,
                        together with our Privacy Policy.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If you do not agree with these Terms of Use, you must not access the Site or Services.
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Privacy
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        {
                            'We are committed to protecting user privacy and personal information. Please see our '
                        }
                        <Link
                            href="#"
                            sx={{
                                fontSize: 18,
                                fontWeight: 600,
                                color: 'primary.main',
                            }}
                        >
                            Privacy Policy
                        </Link>
                        {
                            ' for further details about our practices relating to the collection, use, disclosure and storage of personal information.'
                        }
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    User and Participant Content
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        The Services involve the collection and analysis of content and information provided
                        by others that is not owned by SNA Toolbox. Such content is the sole responsibility of
                        the Registered User that makes it available.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Correspondingly, you are responsible for your own Content and you must ensure that you
                        have all the rights and permissions needed to use that Content. We are not responsible
                        for any actions you take with respect to your Content, including sharing it publicly.
                        Please do not use content from the Services unless you have first obtained the
                        permission of its owner and are otherwise authorized by law to do so.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You must take your own precautions to ensure that the telecommunications equipment and
                        computer systems used by you to access and use the Site does not expose your
                        telecommunications equipment and computer systems to any viruses, malicious computer
                        code or other forms of interference which may damage your computer system. We accept
                        no responsibility for any loss or damage to you or anyone else which may arise out of,
                        or in connection with, your access to, and use of, the Site.
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Linking to the Site
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You must not establish a link to the Site in such a way as to suggest any form of
                        association, approval or endorsement on our part where none exists.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You must not establish a link to the Site from any website that is not owned by you.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        This Site must not be framed on any other website, and you must not create a link to
                        any part of this Site other than the home page. We reserve the right to withdraw
                        linking permission at any time without written notice.
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Liability
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        To the maximum extent permitted by law, we exclude all:
                    </Typography>
                </Stack>

                {/* Indent box
				    ========================================= */}
                <Stack gap={2} paddingLeft={3.5}>
                    {/* Labeled text
                        ========================================= */}
                    <Stack direction="row" alignItems="flex-start" gap={2}>
                        <Typography fontSize={20} fontWeight={600} color="primary">
                            (a)
                        </Typography>
                        <Typography fontSize={18} fontWeight={300} color="common.text_1">
                            conditions, guarantees or warranties expressed or implied by law; and
                        </Typography>
                    </Stack>

                    {/* Labeled text
                        ========================================= */}
                    <Stack direction="row" alignItems="flex-start" gap={2}>
                        <Typography fontSize={20} fontWeight={600} color="primary">
                            (b)
                        </Typography>
                        <Typography fontSize={18} fontWeight={300} color="common.text_1">
                            any liability to you however arising (and whether arising under statute,
                            negligence or otherwise) for any personal injury or death to you or any third
                            person, or for any special, direct, indirect or consequential loss or damage
                            (including, but not limited to, loss of income or revenue, loss or interruption of
                            business, loss of profits, revenue or contracts, loss of anticipated savings, loss
                            of data, loss of use, loss of privacy or loss of goodwill), arising out of, or in
                            connection with, access and/or use of the Material, the Site and/or Services.
                        </Typography>
                    </Stack>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Without limiting the generality of the foregoing, you agree that in no event shall our
                        maximum aggregate liability exceed $1,000. You acknowledge and agree that the
                        limitations of liability contained in this clause are a fair and reasonable allocation
                        of the commercial risk between the parties.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        To the maximum extent permitted by law, all typographical, clerical or other errors or
                        omissions in information issued by us will be subject to correction without any
                        liability on our part.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        4.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Nothing contained in these Terms of Use excludes, restricts or modifies the
                        application of any condition, warranty or other obligation, the exercise of any right
                        or remedy, or the imposition of any liability under the Competition and Consumer Act
                        2010 (Cth) or any other national, state or territory legislation where to do so is
                        unlawful.
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Indemnity
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        {
                            'You agree to indemnify us and our officers, directors, employee, contractors and associated entities (collectively, the '
                        }
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Indemnified”
                        </Box>

                        {
                            ') and to keep indemnified and hold harmless the Indemnified from and against any and all actions, claims, demands, losses, damages, taxes, liabilities, costs and/or expenses that may be incurred by, or sustained by, the Indemnified arising out of, or in connection with, your access to, and use of, the Materials, the Site and/or Services, or any breach by you of these Terms of Use and all applicable laws.'
                        }
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        For the avoidance of doubt, if you are a Registered User, this indemnity includes all
                        liability incurred by the Indemnified in relation to your use of personal information
                        collected from Participants.
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    General
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Although we do our best to provide the most up to date information as it becomes
                        available, we cannot warrant the accuracy or completeness of any information provided
                        in connection with the Services.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We reserve the right to make changes to these Terms without notice to you. Any
                        amendments to these Terms will have immediate effect from the time that they are
                        published on the Site.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Any provision of these Terms which is void or unenforceable may be severed from them
                        without affecting the enforceability of other provisions.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        4.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        A failure or delay by us to exercise a power or right under these Terms does not
                        operate as a waiver of that power or right, and the exercise of a power or right by us
                        does not preclude our future ability to exercise that or any other power or right.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        5.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        The Terms are governed by, and must be construed according to, the law of the State of
                        Victoria, Australia and the parties submit to the exclusive jurisdiction of the courts
                        in that State.
                    </Typography>
                </Stack>

                {/* Section header text
				    ========================================= */}
                <Typography fontSize={30} fontWeight={600} color="primary" marginTop={3}>
                    REGISTERED USER TERMS
                </Typography>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Special conditions
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        These clauses 9 to 11 apply to Registered Users who access or use the Services to
                        generate surveys and/or collect data from Participants.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If a Registered User has entered into a specific project or services agreement with
                        SNA Toolbox for the Services, then that agreement shall prevail over these Terms of
                        Use to the extent of any inconsistency.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Your continued use of the Site and/or Services is an acknowledgement by you that you
                        accept these Terms of Use as legally binding.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        4.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You are responsible for your conduct, Content, and communications with others while
                        using the Services. You must comply with the following requirements when using the
                        Services:
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        5.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You may not misuse our Services by interfering with their normal operation, or by
                        attempting to access them using a method other than through the interfaces and
                        instructions that we provide.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        6.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You may not circumvent or attempt to circumvent any limitations that we impose on your
                        account (such as by opening up a new account to conduct a survey that we have closed
                        for a Terms violation).
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        7.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You may not use the Services to infringe the intellectual property rights of others,
                        to harass, abuse or defame any other person or to commit any unlawful or inappropriate
                        activity.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        8.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        If your use of the Services requires you to comply with industry-specific regulations
                        applicable to such use, you will be solely responsible for such compliance, unless we
                        have agreed another arrangement with you. You may not use the Services in a way that
                        would subject SNA Toolbox to those industry-specific regulations without obtaining our
                        prior written agreement.
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Registrations and Accounts
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        {
                            'To access the survey services and related content, you must first register with SNA Toolbox. To become a registered member you must open a membership account with us ('
                        }
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Account”
                        </Box>

                        {') by providing us with Registration Data.'}
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We reserve the right to refuse registration requests in our sole discretion.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You have sole responsibility for any activity that occurs via your use of the Services
                        You must notify us immediately if you become aware of any security or data breach or
                        any unauthorised access to the Services.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        4.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may, in our absolute discretion, terminate the Services or restrict your access to
                        the Services where you have breached these Terms of Use or for misconduct, security
                        concerns, or otherwise at its discretion (acting reasonably). Under these
                        circumstances, you may be prevented from accessing all or parts of the Services or any
                        other content associated with your Account. We will not be liable to you or any third
                        party if this occurs.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        5.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        We may impose further limits or restrictions on the use you may make of the Services.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        6.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Further, we may, for any reason, at any time and without notice to you change or
                        remove functionalities or aspects of our Site and Services.
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Intellectual Property
                </Typography>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        1.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You acknowledge that we or our licensors are the owners of all Intellectual Property
                        Rights in the Site and in all Material published on the Site, and we retain all
                        rights, title and interest in the Site and Material (including Intellectual Property
                        Rights contained therein) irrespective of any license we may grant to you to access,
                        and use, the Site.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        2.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        You must not in any form or by any means reproduce, modify, distribute, store,
                        transmit, publish, use or display the Material on another website or create derivative
                        works from any part of the Site or the Material or commercialize any information
                        obtained from any part of the Site or Material without our prior written consent.
                    </Typography>
                </Stack>

                {/* Numbered text
				    ========================================= */}
                <Stack direction="row" alignItems="flex-start" gap={2}>
                    <Typography fontSize={20} fontWeight={600} color="primary">
                        3.
                    </Typography>
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        {
                            '11.3. By uploading, posting, transmitting or otherwise making available any content or material via the Site and/or Services ('
                        }
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Your Content”
                        </Box>

                        {
                            '), you represent and warrant that you either own the Intellectual Property Rights in Your Content or have the necessary permission to upload, post, transmit or otherwise make available Your Content'
                        }
                    </Typography>
                </Stack>

                {/* Section header text
				    ========================================= */}
                <Typography fontSize={30} fontWeight={600} color="primary" marginTop={3}>
                    DEFINITIONS & INTERPRETATION
                </Typography>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Definitions
                </Typography>

                {/* Normal text
					========================================= */}
                <Typography fontSize={18} fontWeight={300} color="common.text_1">
                    In these Terms of Use:
                </Typography>

                {/* Indent box
				    ========================================= */}
                <Stack gap={2} paddingLeft={3.5}>
                    {/* Normal text
					    ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Intellectual Property Rights”
                        </Box>

                        {
                            ' means all present and future intellectual or industrial property rights (whether or not registered) throughout the world, including, without limitation, in respect of patents, copyright, moral rights, trade names, trade marks, logos, systems, circuit layout, designs, software, plant breeder’s rights, domain names, trade secrets and confidential information;'
                        }
                    </Typography>

                    {/* Normal text
					    ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Material”
                        </Box>

                        {
                            ' means any information, data, source codes, drawings, content, text or images in any form (whether visible or not), audio recordings, video recordings, lists, sound, video, links, layout, look and feel, control features and interfaces contained on the Site, or otherwise displayed, uploaded or published on, or via, the Site;'
                        }
                    </Typography>

                    {/* Normal text
					    ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Privacy Policy”
                        </Box>

                        {' means our privacy policy available at the Privacy link on SNA Toolbox website.'}
                    </Typography>

                    {/* Normal text
					    ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Registration Data”
                        </Box>

                        {
                            ' means information provided by you to us for the purposes of your registration to access certain Services;'
                        }
                    </Typography>

                    {/* Normal text
					    ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        <Box
                            component={'span'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 700,
                                color: 'common.text_1',
                            }}
                        >
                            “Site”
                        </Box>

                        {' means this website and its associated subdomains, systems and networks.'}
                    </Typography>
                </Stack>

                {/* Title text
				    ========================================= */}
                <Typography fontSize={24} fontWeight={600} color="primary" marginTop={2}>
                    Interpretation
                </Typography>

                <Stack gap={2} paddingLeft={3.5}>
                    {/* Normal text
					    ========================================= */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1">
                        Any reference in these Terms of Use to the singular includes the plural, to any gender
                        includes all genders, to any act or statute includes any Act or statute which
                        supersedes, replaces or modifies any earlier Act or statute, to persons includes all
                        bodies and associations both corporate and incorporated and vice versa. Paragraph
                        headings are for reference purposes only and all references to clauses are to clauses
                        in these Terms of Use unless otherwise specified.
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default TermsOfServiceDialog
