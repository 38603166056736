import { useEffect } from 'react'
import { NetworkWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import usePreviousValue from 'hooks/usePreviousValue'
import { isEqual } from 'lodash'
import { NetworkSelectedItemType } from 'features/network-viz/types/NetworkViz.types'
import { isSelectedItemEquals } from 'features/network-viz/helpers/NetworkViz.helper'

type Props = {
    onUpdate: (widgetData: NetworkWidgetType, disableTraking?: boolean) => void
    data: NetworkWidgetType
    updateConnectedWidgets: (selectedNode: NetworkWidgetType['selectedNode']) => void
    onReady: () => void
}

function NetworkStateHandler({ data, onUpdate, updateConnectedWidgets, onReady }: Props) {
    const context = useNetworkVizContext()
    const dispatchNetworkContext = useNetworkVizDispatch()
    const prevSeletedItem = usePreviousValue(context.selectedItem)
    const prevDataSeletedNode = usePreviousValue(data.selectedNode)

    useEffect(() => {
        if (context.status === 'ready' && isEqual(data.analytics, context.analytics) === false) {
            // TODO: After changing the PartialDeep updater function to whole object, the "...data" may need testing.
            onUpdate({ ...data, analytics: context.analytics }, true)
        }
    }, [context.status, context.analytics])

    // useEffect(() => {
    //     if (context.status === 'ready') onReady()
    // }, [context.])

    useEffect(() => {
        if (context.status === 'ready') onReady()
    }, [context.status])

    // * set networkVizContext options in the settings
    useEffect(() => {
        if (
            !isSelectedItemEquals(prevSeletedItem ?? null, context.selectedItem) &&
            !isSelectedItemEquals(
                data.selectedNode === null ? null : { mode: 'node', id: data.selectedNode },
                context.selectedItem
            )
        ) {
            updateConnectedWidgets(context.selectedItem?.id ?? null)
        }
    }, [context.selectedItem])

    //Update the network selected node if data changes from report state
    useEffect(() => {
        const item: NetworkSelectedItemType | null =
            data.selectedNode === null ? null : { mode: 'node', id: data.selectedNode }
        if (data.selectedNode !== prevDataSeletedNode && !isSelectedItemEquals(item, context.selectedItem)) {
            dispatchNetworkContext({
                type: 'SELECTED_ITEM_CHANGE',
                payload: data.selectedNode ? { mode: 'node', id: data.selectedNode } : null,
            })
        }
    }, [dispatchNetworkContext, data.selectedNode])

    // * update display mode
    useEffect(() => {
        dispatchNetworkContext({
            type: 'NETWORK_VIZ_KIND',
            payload: data.displayMode,
        })
    }, [data.displayMode])

    return <></>
}

export default NetworkStateHandler
