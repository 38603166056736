import * as customECharts from 'echarts/core'
import 'echarts-wordcloud'
import {
    // Line
    LineChart,
    LineSeriesOption,
    // Bar
    BarChart,
    BarSeriesOption,
    // Pie
    PieChart,
    PieSeriesOption,
    // Scatter
    ScatterChart,
    ScatterSeriesOption,
    // Radar
    RadarChart,
    RadarSeriesOption,
    // Boxplot
    BoxplotChart,
    BoxplotSeriesOption,
    // Gauge
    GaugeChart,
    GaugeSeriesOption,
    // Pictorial bar
    PictorialBarChart,
    PictorialBarSeriesOption,
    // Graph
    GraphChart,
    GraphSeriesOption,
    // Treemap
    TreemapChart,
    TreemapSeriesOption,
    // Sunburst
    SunburstChart,
    SunburstSeriesOption,
    //Heatmap
    HeatmapChart,
    HeatmapSeriesOption,
} from 'echarts/charts'

import {
    // Title
    TitleComponent,
    TitleComponentOption,
    // Tooltip
    TooltipComponent,
    TooltipComponentOption,
    // Grid
    GridComponent,
    GridComponentOption,
    // Dataset
    DatasetComponent,
    DatasetComponentOption,
    // Data zoom
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    DataZoomComponentOption,
    // Legend
    LegendComponent,
    LegendComponentOption,
    // Radar
    RadarComponent,
    RadarComponentOption,
    // Built-in transform (filter, sort)
    TransformComponent,
    ToolboxComponentOption,
} from 'echarts/components'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { SVGRenderer, CanvasRenderer } from 'echarts/renderers'
import { WordCloudSeriesOption } from 'echarts'

export type EchartsCustomSeriesType =
    | LineSeriesOption
    | BarSeriesOption
    | PieSeriesOption
    | ScatterSeriesOption
    | RadarSeriesOption
    | BoxplotSeriesOption
    | GaugeSeriesOption
    | PictorialBarSeriesOption
    | GraphSeriesOption
    | TreemapSeriesOption
    | WordCloudSeriesOption
    | SunburstSeriesOption
    | HeatmapSeriesOption

// Create an Option type with only the required components and charts via ComposeOption
export type EChartsCustomOptionType = echarts.ComposeOption<
    // Charts options
    | EchartsCustomSeriesType
    // Components options
    | TitleComponentOption
    | TooltipComponentOption
    | TooltipComponentOption
    | ToolboxComponentOption
    | GridComponentOption
    | DatasetComponentOption
    | DataZoomComponentOption
    | LegendComponentOption
    | RadarComponentOption
>

// Register the required components
customECharts.use([
    // Charts
    LineChart,
    BarChart,
    PieChart,
    ScatterChart,
    RadarChart,
    BoxplotChart,
    GaugeChart,
    PictorialBarChart,
    GraphChart,
    TreemapChart,
    SunburstChart,
    // Components
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    LegendComponent,
    RadarComponent,
    TransformComponent,
    // Features
    LabelLayout,
    UniversalTransition,
    // Renderers
    CanvasRenderer,
    SVGRenderer,
])

export default customECharts
