import React, { useEffect, useMemo, useRef, useState } from 'react'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

export type MultiSelectItem = {
    primaryKey: number
    title: string
    [key: string]: any
}
interface ChipMultiSelectPrposType {
    placeholder: string
    suggestions: MultiSelectItem[]
    defaultValue?: MultiSelectItem[]
    allowArbitary?: boolean
    onChange: (newVal: any) => void
    readOnly?: boolean
    regex?: RegExp
    chipLocation?: 'after' | 'before'
}

const filter = createFilterOptions<MultiSelectItem>()

export default function ChipMultiSelect({
    chipLocation = 'before',
    readOnly = false,
    placeholder,
    suggestions,
    defaultValue = [],
    allowArbitary = false,
    onChange,
    regex,
}: ChipMultiSelectPrposType) {
    const [values, setValues] = useState<MultiSelectItem[]>([])
    const ref2 = useRef<HTMLInputElement>(null)

    //set default value if any
    useEffect(() => {
        setValues(defaultValue)
    }, [])

    const handleDelete = (index: number) => () => {
        setValues((v) => {
            const updatedValue = [...v.slice(0, index), ...v.slice(index + 1)]
            onChange(updatedValue)
            return updatedValue
        })
    }

    const options = useMemo(() => {
        if (!Array.isArray(suggestions)) return []
        if (!values) return suggestions
        return suggestions.filter((s) => values.findIndex((v) => v.title == s.title) == -1)
    }, [values, suggestions])

    const validateValue = (v: string) => {
        if (!regex) return true
        return v.match(regex)
    }

    return (
        <Stack direction={chipLocation == 'before' ? 'column' : 'column-reverse'} width="100%">
            <Grid container spacing={2} sx={{ marginY: 1 }}>
                {values.map((v, index) => (
                    <Grid item>
                        <Chip label={v.title} onDelete={handleDelete(index)} />
                    </Grid>
                ))}
            </Grid>
            <Autocomplete
                sx={{ mt: 1 }}
                options={options}
                getOptionLabel={(option) => {
                    if (typeof option == 'string') return option
                    else if (option.primaryKey == -1) return option.category || ''
                    else return option.title
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    const { inputValue } = params
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title)
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            primaryKey: -1,
                            title: inputValue,
                        })
                    }
                    return filtered
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.primaryKey == -1 ? 'Add ' : ''} {option.title}
                    </li>
                )}
                onChange={(event, value) => {
                    if (typeof value == 'string') {
                        if (validateValue(value)) {
                            let suggestion = suggestions?.find(
                                (s) => s.title.toLowerCase() == value.toLocaleLowerCase()
                            )
                            if (suggestion != null) {
                                //@ts-ignore
                                setValues((v) => {
                                    const updatedValue = [...v, suggestion]
                                    onChange(updatedValue)
                                    return updatedValue
                                })
                            } else {
                                setValues((v) => {
                                    const updatedValue = [...v, { title: value, primaryKey: -1 }]
                                    onChange(updatedValue)
                                    return updatedValue
                                })
                            }
                        }
                    } else {
                        if (value != null) {
                            let isValid = validateValue(value.title)

                            if (isValid)
                                setValues((v) => {
                                    const updatedValue = [...v, value]
                                    onChange(updatedValue)
                                    return updatedValue
                                })
                        }
                    }

                    setTimeout(() => {
                        if (ref2.current) {
                            ref2.current.blur()
                            const event = new KeyboardEvent('keydown', {
                                key: 'Escape',
                            })
                            ref2.current.dispatchEvent(event)
                        }
                    }, 10)
                }}
                value={''}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo={allowArbitary}
                readOnly={readOnly}
                renderInput={(params) => <TextField {...params} placeholder={placeholder} inputRef={ref2} />}
            />
        </Stack>
    )
}
