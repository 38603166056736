//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import StyledDialog from 'components/dialog/StyledDialog'
import ScheduleNotificationDialog from './ScheduleNotificationDialog'
import TextEditor from 'features/text-editor/TextEditor'
//* ======= Custom logic
//* ======= Assets and styles
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import { SendTestEmailService, SurveyProfileType } from 'services/SurveyService'
import { useParams } from 'react-router-dom'
import ToastHelper from 'helpers/ToastHelper'

interface ISurveyNotificationReminder {
    surveyProfile: SurveyProfileType
    setSurveyProfile: (name: string) => (value?: any) => void
}

function SurveyNotificationReminder({
    surveyProfile,
    setSurveyProfile,
    onSave,
    disabledChange,
}: ISurveyNotificationReminder & { onSave: () => void; disabledChange: boolean }) {
    const [selectedTemplate, setSelectedTemplate] = useState<'email' | 'sms'>('email')
    const [isDialogShown, setIsDialogShown] = useState(false)

    const { pid, sid } = useParams()

    const sendTestEmail = async () => {
        if (!pid || !sid) return
        const toast = new ToastHelper({
            loadingMessage: 'Sending test email...',
            successMessage: 'Test email sent successfully',
            errorMessage: 'Error sending test email',
        })
        try {
            const response = await SendTestEmailService(pid, sid, { type: 'reminder' })

            if (response.success) {
                toast.success()
                return
            }
        } catch {}
        toast.fail()
    }

    return (
        <Stack gap={1} height="100%">
            {/* <BaseButton
                label="Recurring"
                onClick={() => setIsDialogShown(true)}
                variant="contained"
                size="large"
                startIcon={<ScheduleSendIcon />}
                sx={{
                    alignSelf: 'flex-start',

                    marginTop: 1,
                    fontWeight: 400,
                }}
            /> */}
            {/* Template selection
				========================================= */}
            <Stack direction="row" alignItems="flex-start" gap={6}>
                {/* Invitation
					========================================= */}
                <BaseButton
                    label="Email Template"
                    onClick={(e) => setSelectedTemplate('email')}
                    size="small"
                    sx={{
                        px: 1,
                        fontSize: 14,
                        fontWeight: 400,

                        color: selectedTemplate === 'email' ? 'primary.main' : 'common.fill_1',
                    }}
                />

                {/* Two level invitation
					========================================= */}
                <BaseButton
                    label="SMS Template"
                    onClick={(e) => setSelectedTemplate('sms')}
                    size="small"
                    sx={{
                        px: 1,
                        fontSize: 14,
                        fontWeight: 400,

                        color: selectedTemplate === 'sms' ? 'primary.main' : 'common.fill_1',
                    }}
                />
            </Stack>

            {/* Templates content
				========================================= */}
            {selectedTemplate === 'email' ? (
                <ReminderEmailTemplate setSurveyProfile={setSurveyProfile} surveyProfile={surveyProfile} />
            ) : selectedTemplate === 'sms' ? (
                <ReminderSMSTemplate setSurveyProfile={setSurveyProfile} surveyProfile={surveyProfile} />
            ) : (
                false
            )}

            {/* Footer controls
				========================================= */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                {/* View button
					========================================= */}
                {/* <BaseButton
                    label="View"
                    size="large"
                    sx={{
                        minWidth: 120,
                    }}
                /> */}

                {/* Send test email button
					========================================= */}
                <BaseButton
                    label="Send Test Email"
                    variant="outlined"
                    size="large"
                    onClick={sendTestEmail}
                    disabled={!disabledChange}
                    sx={{
                        minWidth: 186,
                    }}
                />

                {/* Save button
					========================================= */}
                <BaseButton
                    label="Save"
                    variant="contained"
                    disabled={disabledChange}
                    onClick={onSave}
                    size="large"
                    sx={{
                        minWidth: 186,
                    }}
                />
            </Stack>
            {isDialogShown && (
                <StyledDialog open={isDialogShown} onClose={() => setIsDialogShown(false)} fullWidth maxWidth="sm">
                    <ScheduleNotificationDialog
                        onConfirm={() => setIsDialogShown(false)}
                        onClose={() => setIsDialogShown(false)}
                    />
                </StyledDialog>
            )}
        </Stack>
    )
}

function ReminderEmailTemplate({ setSurveyProfile, surveyProfile }: ISurveyNotificationReminder) {
    return (
        <Stack gap={1} paddingLeft={1} height={525}>
            {/* enabled button
				========================================= */}
            <FormGroup aria-label="enabled" row>
                <FormControlLabel
                    control={<BaseSwitch color="primary" />}
                    label="Enable"
                    labelPlacement="start"
                    checked={surveyProfile.autoReminderEmail}
                    onChange={setSurveyProfile('autoReminderEmail')}
                    sx={{
                        gap: 2,
                        marginLeft: 0,
                        '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                            fontWeight: 400,
                        },
                    }}
                />
            </FormGroup>

            {surveyProfile.autoReminderEmail && (
                <React.Fragment>
                    {/* Subject
				========================================= */}
                    <BaseFilledTextField
                        label="Subject"
                        value={surveyProfile.reminderSubject}
                        onChange={(e) => setSurveyProfile('reminderSubject')(e)}
                    />

                    {/* Text editor
				========================================= */}
                    <Box flexGrow={1}>
                        <TextEditor
                            keywords={['[user.name]', '[survey.name]', '[project.name]', '[link]']}
                            defaultValue={surveyProfile.emailReminder}
                            onBlur={(value: any) => setSurveyProfile('emailReminder')(value)}
                        />
                    </Box>
                </React.Fragment>
            )}
        </Stack>
    )
}

function ReminderSMSTemplate({ surveyProfile, setSurveyProfile }: ISurveyNotificationReminder) {
    return (
        <Stack gap={1} paddingLeft={1} height={525}>
            {/* enabled button
				========================================= */}
            <FormGroup aria-label="enabled" row>
                <FormControlLabel
                    control={<BaseSwitch color="primary" />}
                    label="Enable"
                    labelPlacement="start"
                    checked={surveyProfile.autoReminderSms}
                    onChange={setSurveyProfile('autoReminderSms')}
                    sx={{
                        gap: 2,
                        marginLeft: 0,
                        '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                            fontWeight: 400,
                        },
                    }}
                />
            </FormGroup>

            {surveyProfile.autoReminderEmail && (
                <React.Fragment>
                    {/* Text editor
				========================================= */}
                    <Box flexGrow={1}>
                        <TextEditor
                            keywords={['[user.name]', '[survey.name]', '[project.name]', '[link]']}
                            defaultValue={surveyProfile.smsReminder}
                            onBlur={(value: any) => setSurveyProfile('smsReminder')(value)}
                        />
                    </Box>
                </React.Fragment>
            )}
        </Stack>
    )
}

export default SurveyNotificationReminder
