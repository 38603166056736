import { useEffect, useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import NodesIcon from '@mui/icons-material/BubbleChart'
import GroupIcon from '@mui/icons-material/Commit'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import FilterIcon from '@mui/icons-material/FilterAlt'
import AdditionalSettingsIcon from '@mui/icons-material/PlaylistAdd'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import BuildIcon from '@mui/icons-material/Build'
import NetworkNodesSettings from './node/NetworkNodesSettings'
import NetworkEdgesSettings from './edge/NetworkEdgesSettings'
import NetworkPresetSettings from './preset/NetworkPresetSettings'
import NetworkFilterSettings from './filter/NetworkFilterSettings'
import { Box, Stack, Tooltip } from '@mui/material'
import NetworkLayoutSettings from './layout/NetworkLayoutSettings'
import NetworkTimepanelSetting from './time-panel/NetworkTimepanelSetting'
import BaseButton from 'components/base/BaseButton'
import NetworkInteractionSettings from './interaction/NetworkInteractionSettings'
import { NetworkSettingsKindType } from '../types/NetworkViz.types'
import VideoSettingsIcon from '@mui/icons-material/VideoSettings'
import NetworkKeyFrameSettings from './keyframe/NetworkKeyframeSettings'

export default function NetworkVizSettings() {
    const [settingsType, setSettingsType] = useState<NetworkSettingsKindType | null>(null)

    const SETTINGS_TYPES: Record<string, NetworkSettingsKindType> = {
        nodes: 'nodes',
        edges: 'edges',
        layout: 'layout',
        filters: 'filters',
        interact: 'interact',
        timelapse: 'timelapse',
        presets: 'presets',
        keyframes: 'keyframes',
    }

    const buttonsList = [
        {
            id: SETTINGS_TYPES.nodes,
            label: 'Nodes Settings',
            icon: NodesIcon,
        },
        {
            id: SETTINGS_TYPES.edges,
            label: 'Edges Settings',
            icon: GroupIcon,
        },
        {
            id: SETTINGS_TYPES.layout,
            label: 'Layout Settings',
            icon: BlurOnIcon,
        },
        {
            id: SETTINGS_TYPES.interact,
            label: 'Interaction Settings',
            icon: BuildIcon,
        },
        {
            id: SETTINGS_TYPES.filters,
            label: 'Filter Settings',
            icon: FilterIcon,
        },
        {
            id: SETTINGS_TYPES.timelapse,
            label: 'Timelapse Settings',
            icon: TimelapseIcon,
        },
        {
            id: SETTINGS_TYPES.presets,
            label: 'Preset Settings',
            icon: AdditionalSettingsIcon,
        },
        {
            id: SETTINGS_TYPES.keyframes,
            label: 'Keyframes Settings',
            icon: VideoSettingsIcon,
        },
    ]

    return (
        <Stack
            direction="row"
            alignItems="flex-start"
            gap={0.25}
            sx={(theme) => ({
                minHeight: 0,
                height: '100%',
                // Allowing the background of this element to be clickable.
                pointerEvents: 'none',

                '& > *': {
                    // Resetting pointer/click ability for children.
                    pointerEvents: 'auto',
                },
            })}
        >
            {/* Buttons list
                ========================================= */}
            <Stack
                gap={1}
                sx={(theme) => ({
                    maxHeight: '100%',
                })}
            >
                {buttonsList.map(({ id: _buttonId, label: _buttonLabel, icon: ButtonIcon }) => (
                    <Tooltip
                        key={_buttonId}
                        title={settingsType === _buttonId ? 'Close' : _buttonLabel}
                        placement="right"
                        disableInteractive
                    >
                        <BaseButton
                            onClick={(evt) => {
                                if (settingsType !== _buttonId) {
                                    setSettingsType(_buttonId)
                                } else {
                                    setSettingsType(null)
                                }
                            }}
                            disableElevation
                            sx={(theme) => ({
                                height: 48,
                                minWidth: 0,
                                width: 48,

                                borderRadius: 0,
                                // @Theme conditional
                                backgroundColor:
                                    settingsType === _buttonId
                                        ? theme.palette.primary.main
                                        : theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_3
                                        : theme.palette.common.bg_4,
                                color:
                                    settingsType === _buttonId
                                        ? theme.palette.common.fill_4
                                        : theme.palette.primary.main,

                                '&:hover': {
                                    // @Theme conditional
                                    backgroundColor:
                                        settingsType === _buttonId
                                            ? theme.palette.primary.main
                                            : theme.palette.mode === 'light'
                                            ? theme.palette.common.bg_3
                                            : theme.palette.common.bg_4,
                                },
                            })}
                        >
                            {settingsType === _buttonId ? (
                                <CloseRoundedIcon
                                    sx={(theme) => ({
                                        color: theme.palette.common.fill_4,
                                    })}
                                />
                            ) : (
                                <ButtonIcon />
                            )}
                        </BaseButton>
                    </Tooltip>
                ))}
            </Stack>

            {/* Settings panel
                ========================================= */}
            {settingsType !== null && (
                <Box
                    sx={(theme) => ({
                        height: '100%',
                        maxHeight: '100%',
                        width: 300,
                        padding: theme.spacing(1, 2, 3),
                        overflowY: 'auto',
                        filter: ' alpha(opacity = 95)',
                        mozOpacity: 0.95,
                        /* Mozilla */
                        opacity: 0.95,
                        border: `1px solid ${theme.palette.common.border_2}`,
                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                    className="u-scrollbar"
                >
                    {settingsType === SETTINGS_TYPES.nodes ? (
                        <NetworkNodesSettings />
                    ) : settingsType === SETTINGS_TYPES.edges ? (
                        <NetworkEdgesSettings />
                    ) : settingsType === SETTINGS_TYPES.layout ? (
                        <NetworkLayoutSettings />
                    ) : settingsType === SETTINGS_TYPES.filters ? (
                        <NetworkFilterSettings />
                    ) : settingsType === SETTINGS_TYPES.timelapse ? (
                        <NetworkTimepanelSetting />
                    ) : settingsType === SETTINGS_TYPES.presets ? (
                        <NetworkPresetSettings />
                    ) : settingsType === SETTINGS_TYPES.interact ? (
                        <NetworkInteractionSettings />
                    ) : settingsType === SETTINGS_TYPES.keyframes ? (
                        <NetworkKeyFrameSettings />
                    ) : (
                        false
                    )}
                </Box>
            )}
        </Stack>
    )
}
