import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledDialog from 'components/dialog/StyledDialog'
import { PanelWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { useEffect, useMemo, useState } from 'react'
import PanelWidgetTransfer from './PanelWidgetTransfer'

type EditPanelLayerDialogProps = {
    widgetId: ReportWidgetType['id']
    open: boolean
    onClose: () => void
    layerName: string
    layer: PanelWidgetType['views'][string]
    onSave: (
        layerKey: string,
        updatedLayer: {
            name: string
            parent: string | null
            widgets: ReportWidgetType['id'][]
        }
    ) => void
    parents: string[]
}

const EditPanelLayerDialog = ({
    widgetId,
    layer,
    layerName,
    open,
    onClose,
    onSave,
    parents,
}: EditPanelLayerDialogProps) => {
    const [data, setData] = useState<{
        name: string
        parent: string | null
    }>({
        name: layerName,
        parent: layer.parent,
    })

    const [selectedWidgets, setSelectedWidgets] = useState<ReportWidgetType['id'][]>(layer.widgets)

    const handleUpdateLayer = () => {
        onSave(layerName, {
            name: data.name,
            parent: data.parent,
            widgets: selectedWidgets,
        })
    }

    useEffect(() => {
        if (open) {
            setData({
                name: layerName,
                parent: layer.parent,
            })
        }
    }, [open])

    const isValid = useMemo(() => {
        return true
    }, [data.name, data.parent, selectedWidgets])

    return (
        <StyledDialog open={open} maxWidth={'lg'} fullWidth disableEscapeKeyDown>
            <DialogTitle>Add Panel Layer</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <BaseFilledTextField
                        label="Layer Name"
                        required
                        defaultValue={data.name}
                        disabled={layerName === 'root'}
                        fullWidth
                        onBlur={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
                    />

                    {/* //ToDo 
                        Changing parent has some complexities such as what to do if user change parent to another option which is actuly 
                        a child of the current layer. This will create a circular reference. On way to address it is to prevent user from 
                        selecting a child layer as parent. 
                        for now, we are disabling edit of the parent field.
                    */}
                    <BaseSelectWithLabel
                        label="Parent"
                        value={data.parent}
                        disabled={true}
                        fullWidth
                        options={parents}
                        onChange={(value) => setData((prev) => ({ ...prev, parent: value as string }))}
                    />

                    <PanelWidgetTransfer
                        widgetId={widgetId}
                        widgets={selectedWidgets}
                        onAddWidgets={(widgetId) => setSelectedWidgets((prev) => [...prev, ...widgetId])}
                        onRemoveWidgets={(widgetId) =>
                            setSelectedWidgets((prev) => prev.filter((_widgetId) => !widgetId.includes(_widgetId)))
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton color="secondary" onClick={onClose}>
                    Cancel
                </BaseButton>
                <BaseButton disabled={!isValid} color="primary" variant="contained" onClick={handleUpdateLayer}>
                    Save
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default EditPanelLayerDialog
