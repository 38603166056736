//* ======= Libraries
import { useContext, useEffect, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import NewProjectStrip from 'features/general-dashboard/NewProjectStrip'
import ProjectsPanel from 'features/general-dashboard/ProjectsPanel'
import Stack from '@mui/material/Stack'
import { ResourceType } from 'types/resources'
import { useParams } from 'react-router-dom'
import { ProjectContext } from 'contexts/ProjectContext'
import { BasicAllocationType, GetAllocationsService } from 'services/AllocationApi'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'
import CreateAllocationDialog from './Allocation.dialog'
//* ======= Custom logic
//* ======= Assets and styles

function AllocationDashboard() {
    const { pid } = useParams()
    const { project, drawerContent } = useContext(ProjectContext)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [allocations, setAllocations] = useState<ResourceType[]>([])

    useEffect(() => {
        setIsLoading(false)
    }, [allocations])

    //fetch data
    useEffect(() => {
        //if not pid, do nothing
        if (pid == null) return
        setIsLoading(true)
        GetAllocationsService(pid)
            .then((response) => {
                if (response.success) {
                    setAllocations(
                        response.data.map((x) => ({
                            id: x.primaryKey,
                            title: x.title,
                            link: `${x.primaryKey}`,
                            canDelete: true,
                        }))
                    )
                }
            })
            .catch((error) => {})
    }, [pid])

    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)

    const handleCloseCreateDialog = () => setCreateDialogOpen(false)
    const handleOpenCreateDialog = () => setCreateDialogOpen(true)

    const onCreate = (newAllocation: BasicAllocationType) => {
        setAllocations((prev) => [
            ...prev,
            {
                id: newAllocation.primaryKey,
                title: newAllocation.title,
                canDelete: true,
                link: `${newAllocation.primaryKey}`,
            },
        ])
        handleCloseCreateDialog()
    }

    if (project == null) return <DashboardSkeleton />

    // ToDo: Add Allocation permissions
    // const canCreateSurvey = hasPermission(PERMISSIONS.survey.CreateSurvey)
    const canCreateAllocation = true

    return (
        <PageCard>
            <PageCard.Side>{project?.primaryKey && <MainDrawer content={drawerContent} />}</PageCard.Side>

            <PageCard.Body>
                <Stack spacing={1} width="100%" height="100%">
                    {canCreateAllocation && (
                        <NewProjectStrip
                            templates={[]}
                            tagCategories={[]}
                            resourceName="Allocation"
                            onCreate={handleOpenCreateDialog}
                        />
                    )}

                    <ProjectsPanel
                        isLoading={isLoading}
                        resources={allocations}
                        setResources={setAllocations}
                        tagCategories={[]}
                        resourceName="allocation"
                        sortOptions={['Title', 'status', 'Participants', 'Rules', 'Created on']}
                    />
                </Stack>
            </PageCard.Body>

            {/* Create Allocation Dialog
				========================================= */}
            <CreateAllocationDialog
                open={createDialogOpen}
                onClose={handleCloseCreateDialog}
                onCreate={onCreate}
                mode="create"
            />
        </PageCard>
    )
}

export default AllocationDashboard
