import { useContext, useEffect, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'
import { Question } from 'survey-core'
import * as Survey from 'survey-core'
import { PropertyGridEditorCollection } from 'survey-creator-core'
import DynamicFilterContent, { DynamicFilterItemType } from 'features/filter-content/FilterContentDynamic'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import { SurveyContext } from 'contexts/SurveyContex'

interface FilterDialogProps {
    question: Question
    onChange: (newVal: any) => void
}

export function FilterDialog({ question, onChange }: FilterDialogProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const closeDialog = () => setIsOpen(false)
    const openDialog = () => setIsOpen(true)

    const { nodeColumns } = useContext(SurveyContext)

    const [value, setValue] = useState<DynamicFilterItemType[]>([])
    useEffect(() => {
        // setValue(question.obj.target_filter)
        setValue(question.value)
    }, [])

    const isEmpty = (v: any) => v == null || v.trim() === ''

    const saveChanges = () => {
        let filtredValues = value.filter((v) => !isEmpty(v.field) && !isEmpty(v.operator) && !isEmpty(v.value))
        onChange(filtredValues)
        closeDialog()
    }

    return (
        <Stack direction="row" width="100%">
            <BaseButton variant="outlined" onClick={openDialog} fullWidth>
                Set Filter
            </BaseButton>
            <StyledDialog open={isOpen} onClose={closeDialog} maxWidth="md" fullWidth>
                <DialogContent>
                    <DynamicFilterContent
                        fields={nodeColumns || []}
                        defaultValue={value}
                        onChange={(v) => {
                            setValue(v)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <BaseButton onClick={closeDialog}>Close</BaseButton>
                    <BaseButton onClick={saveChanges}>Apply</BaseButton>
                </DialogActions>
            </StyledDialog>
        </Stack>
    )
}

export default function registerFilterDialog() {
    var filterDialogWidget = {
        name: 'filterdialog',
        widgetIsLoaded: function () {
            return true //We do not have external scripts
        },
        isFit: function (question: any) {
            return question.getType() === 'filterdialog'
        },
        init() {
            Survey.Serializer.addClass('filterdialog', [], undefined, 'empty')
        },
        render: function (questionBase: any) {
            function onChange(newVal: any) {
                questionBase.value = newVal || null
            }
            return <FilterDialog question={questionBase} onChange={onChange} />
        },
    }
    Survey.CustomWidgetCollection.Instance.add(filterDialogWidget, 'customtype')
    var widget = Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('filterdialog')
    //Hide from toolbox
    widget.showInToolbox = false

    PropertyGridEditorCollection.register({
        fit: function (prop: any) {
            return prop.type === 'filter'
        },
        getJSON: function (obj: any, prop: any, options: any) {
            return {
                type: 'filterdialog',
            }
        },
    })
}
