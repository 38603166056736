//* ======= Libraries
import React from 'react'
import { styled } from '@mui/material'
//* ======= Components and features
import Switch, { SwitchProps } from '@mui/material/Switch'

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-disabled': {
            opacity: 0.7,
        },

        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.secondary.main,
        },

        '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}))

interface IBaseSwitch extends SwitchProps {}

function BaseSwitch({ ...switchProps }: IBaseSwitch) {
    return <StyledSwitch {...switchProps} />
}

export default BaseSwitch
