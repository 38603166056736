//* ======= Libraries
import React from 'react'
import { styled } from '@mui/material/styles'
//* ======= Components and features
import Input, { InputProps } from '@mui/material/Input'
//* ======= Custom logic
//* ======= Assets and styles

interface IBasicSelectInput extends InputProps {}

const StyledBasicSelectInput = styled((props: IBasicSelectInput) => <Input disableUnderline {...props} />)(
    ({ theme }) => ({
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 2),
            fontSize: 14,
            fontWeight: 400,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',

            borderRadius: '10px',
            backgroundColor: theme.palette.common.bg_4,
            color: theme.palette.common.text_1,

            '&:focus': {
                borderRadius: '10px',
            },
        },
    })
)

function BasicSelectInput({ ...props }: IBasicSelectInput) {
    return <StyledBasicSelectInput {...props} />
}

export default BasicSelectInput
