//* ======= Libraries
import React, { useState, useEffect, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { PartialDeep } from 'type-fest'
import { Stack, Box, Tabs, Tab, Divider, Typography } from '@mui/material'
//* ======= Components and features
import ReportSlideWidgetsListItem, {
    ReportWidgetsListItemDragTypeValue,
} from 'features/report-designer/slide/ReportSlideWidgetsListItem'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ColorPicker from 'components/widgets/ColorPicker'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
//* ======= Custom logic
import useReportStore, { useReportActiveSlide } from 'features/report-designer/store/reportDesignerStore'
import { ReportSlideType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import { deepMergeCustom } from 'helpers/helpers'
import BaseButton from 'components/base/BaseButton'
import ReportOnboardingDialog from './OnboardingDialog'
import EditableTextField from 'components/base/EditableTextField'
//* ======= Assets and styles

function ReportSlideSettings() {
    const {
        updateSlide,
        removeWidget,
        updateWidgetLayer,
        setActiveWidgetId,
        toggleWidgetDesinerHide,
        changeSlideWidgetsDesignerHide,
    } = useReportStore((store) => ({
        updateSlide: store.updateSlide,
        removeWidget: store.removeWidget,
        updateWidgetLayer: store.updateWidgetLayer,
        setActiveWidgetId: store.setActiveWidgetId,
        toggleWidgetDesinerHide: store.toggleWidgetDesignerHide,
        changeSlideWidgetsDesignerHide: store.updateSlideWidgetsDesignerHide,
    }))

    const activeSlide = useReportActiveSlide()

    // We need a clone of widgets list to handle drag&drop actions internally (onWidgetItemMove)
    // and then update the actual store state only once (onWidgetItemMoveEnd).
    const [widgetsListClone, setWidgetsListClone] = useState<ReportSlideType['widgets']>(activeSlide?.widgets || [])

    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    const [openOnboardingDialog, setOpenOnboardingDialog] = useState(false)

    useEffect(() => {
        if (activeSlide !== null) {
            setWidgetsListClone(activeSlide.widgets)
        }
    }, [activeSlide])

    const updateStyles = (styles: PartialDeep<ReportSlideType['styles']>) => {
        if (activeSlide !== null) {
            const updatedStyles = structuredClone(activeSlide.styles)

            deepMergeCustom(updatedStyles, styles)

            updateSlide({
                slideId: activeSlide.id,
                data: {
                    styles: updatedStyles,
                },
            })
        }
    }

    const updateHeader = (data: PartialDeep<ReportSlideType['header']>) => {
        if (activeSlide !== null) {
            updateSlide({
                slideId: activeSlide.id,
                data: {
                    header: data,
                },
            })
        }
    }

    const onWidgetItemClick = (widgetId: ReportWidgetType['id']) => {
        setActiveWidgetId({
            widgetId: widgetId,
        })
    }

    const onWidgetItemDelete = (widgetId: ReportWidgetType['id']) => {
        if (activeSlide !== null) {
            removeWidget({
                slideId: activeSlide.id,
                widgetId: widgetId,
            })
        }
    }

    const onWidgetItemHide = (widgetId: ReportWidgetType['id']) => {
        if (activeSlide !== null) {
            toggleWidgetDesinerHide({
                slideId: activeSlide.id,
                widgetId: widgetId,
            })
        }
    }

    /* =========================================
     * List items drag and drop
     */

    // Helps with visual drop cursor style when dragging list items. Doesn't do anything else.
    const [, dropTarget] = useDrop(() => ({ accept: ReportWidgetsListItemDragTypeValue }))

    const findWidgetCloneIndexById = useCallback(
        (id: ReportWidgetType['id']) => {
            return widgetsListClone.findIndex((_widget) => _widget.id === id)
        },
        [widgetsListClone]
    )

    // Update widgets clone list with the new positions.
    const onWidgetItemMove = useCallback(
        (widgetId: ReportWidgetType['id'], newIndex: number) => {
            const targetIndex = findWidgetCloneIndexById(widgetId)

            const targetWidget = widgetsListClone[targetIndex]

            if (targetWidget !== undefined) {
                const updatedWidgets = [...widgetsListClone]
                updatedWidgets.splice(targetIndex, 1)
                updatedWidgets.splice(newIndex, 0, targetWidget)

                setWidgetsListClone(updatedWidgets)
            }
        },
        [findWidgetCloneIndexById, widgetsListClone]
    )

    // Update context state with the updated widget positions.
    const onWidgetItemMoveEnd = useCallback(
        (widgetId: ReportWidgetType['id'], newIndex: number) => {
            if (activeSlide !== null) {
                const previousIndex = activeSlide.widgets.findIndex((_widget) => _widget.id === widgetId)

                if (previousIndex !== -1) {
                    updateWidgetLayer({
                        slideId: activeSlide.id,
                        previousIndex: previousIndex,
                        newIndex: newIndex,
                    })
                }
            }
        },
        [activeSlide]
    )

    return (
        <>
            {activeSlide !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    <Box
                        sx={{
                            flexShrink: 0,

                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <Tabs
                            value={currentTabIndex}
                            onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                            variant="fullWidth"
                        >
                            <Tab label="Slide" />
                            <Tab label="Widgets" />
                        </Tabs>
                    </Box>

                    {currentTabIndex === 0 ? (
                        /*  Slide settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <EditableTextField
                                label="Slide Title"
                                value={activeSlide.title}
                                onBlur={(v) =>
                                    updateSlide({
                                        slideId: activeSlide.id,
                                        data: {
                                            title: v,
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={activeSlide.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={activeSlide.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={activeSlide.styles.backgroundColor.isEnabled === false}
                                    isPopover={true}
                                />
                            </StyledWidgetAccordion>

                            {/* Header
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Header"
                                hasToggle
                                isToggledOff={
                                    activeSlide.header.isEnabled === false || activeSlide.header.isEnabled === null
                                }
                                onToggle={(isEnabled) =>
                                    updateHeader({
                                        isEnabled: isEnabled,
                                    })
                                }
                                hasBottomBorder
                            >
                                <Stack gap={1}>
                                    {/* Override settings switch
                                        ========================================= */}
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={2}
                                        sx={(theme) => ({
                                            marginBottom: theme.spacing(1),
                                        })}
                                    >
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Override settings:
                                        </Typography>

                                        <BaseSwitch
                                            checked={activeSlide.header.isOverridden}
                                            onChange={(evt, checked) =>
                                                updateHeader({
                                                    isOverridden: checked,
                                                })
                                            }
                                            disabled={Boolean(activeSlide.header.isEnabled) === false}
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flex: '0 0 auto',
                                            }}
                                        />
                                    </Stack>

                                    {/* Height
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            sx={(theme) => ({
                                                flex: '1 0 0',

                                                paddingY: theme.spacing(1),
                                            })}
                                        >
                                            Height (%):
                                        </Typography>

                                        <EditableTextField
                                            type="number"
                                            value={activeSlide.header.height}
                                            onBlur={(value) => {
                                                updateHeader({
                                                    height:
                                                        Number(value) > 50 ? 50 : Number(value) < 8 ? 8 : Number(value),
                                                })

                                                if (Number(value) > 50) {
                                                    value = '50'
                                                } else if (Number(value) < 8) {
                                                    value = '8'
                                                }
                                            }}
                                            disabled={
                                                Boolean(activeSlide.header.isEnabled) === false ||
                                                activeSlide.header.isOverridden === false
                                            }
                                            inputProps={{
                                                min: 8,
                                                max: 50,
                                                step: 1,
                                            }}
                                            hiddenLabel
                                            size="small"
                                            sx={(theme) => ({
                                                flex: '0 0 40%',
                                            })}
                                        />
                                    </Stack>
                                </Stack>
                            </StyledWidgetAccordion>

                            {/* Onboarding
                                ========================================= */}
                            <BaseButton variant="outlined" size="small" onClick={() => setOpenOnboardingDialog(true)}>
                                Onboarding
                            </BaseButton>

                            <ReportOnboardingDialog
                                open={openOnboardingDialog}
                                onClose={() => setOpenOnboardingDialog(false)}
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Widgets list tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(1),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <BaseButton
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                        changeSlideWidgetsDesignerHide({ hide: false, slideId: activeSlide.id })
                                    }
                                >
                                    Show All
                                </BaseButton>
                                <BaseButton
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                        changeSlideWidgetsDesignerHide({ hide: true, slideId: activeSlide.id })
                                    }
                                >
                                    Hide All
                                </BaseButton>
                            </Stack>
                            {/* Widgets list
                                The list is in reverse to align with widgets' layer positions.
                                Inverting the order is done with CSS only: flex-direction: column-reverse.
                                ========================================= */}
                            <Stack ref={dropTarget} direction="column-reverse" gap={1.5}>
                                {widgetsListClone.length > 0 ? (
                                    widgetsListClone.map((_widget, idx, array) =>
                                        _widget.hide === true ? null : (
                                            <React.Fragment key={_widget.id}>
                                                {/* Widgets list item
                                                ========================================= */}
                                                <ReportSlideWidgetsListItem
                                                    widgetData={_widget}
                                                    onClick={onWidgetItemClick}
                                                    onHide={onWidgetItemHide}
                                                    onDelete={onWidgetItemDelete}
                                                    getPositionIndex={findWidgetCloneIndexById}
                                                    onMove={onWidgetItemMove}
                                                    onMoveEnd={onWidgetItemMoveEnd}
                                                />

                                                {/* Divider
                                                ========================================= */}
                                                {idx !== array.length - 1 && <Divider />}
                                            </React.Fragment>
                                        )
                                    )
                                ) : (
                                    /*  Empty list message
                                        ========================================= */
                                    <Typography fontSize={14} textAlign="center">
                                        (Add widgets to see the list.)
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default ReportSlideSettings
