import { DialogActions, DialogContent, DialogTitle, Divider, Stack, Tooltip, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledDialog from 'components/dialog/StyledDialog'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import { InsightWidgetType, ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import React, { useEffect, useMemo, useState } from 'react'

type AdvancedInsightFilterConfigDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSave: (config: InsightWidgetType['interventionConfig']['filterConfig']) => void
    dataSourceFields: FlexibleSelectOptionType[]
    filterConfig: InsightWidgetType['interventionConfig']['filterConfig']
}

const AdvancedInsightFilterConfigDialog: React.FC<AdvancedInsightFilterConfigDialogProps> = ({
    isOpen,
    onClose,
    onSave,
    dataSourceFields,
    filterConfig,
}) => {
    const [internalFilterConfig, setInternalFilterConfig] = useState<
        InsightWidgetType['interventionConfig']['filterConfig']
    >([])

    useEffect(() => {
        if (isOpen) {
            setInternalFilterConfig(
                filterConfig.length === 0
                    ? [
                          {
                              attribute: null,
                              label: '',
                              mode: 'text',
                          },
                      ]
                    : filterConfig
            )
        }
    }, [isOpen])

    const handleAddFilter = () => {
        setInternalFilterConfig((prev) => {
            return [
                ...prev,
                {
                    attribute: null,
                    label: '',
                    mode: 'text',
                },
            ]
        })
    }

    const handleRemoveFilter = (index: number) => {
        setInternalFilterConfig((prev) => {
            if (prev.length === 1)
                return [
                    {
                        attribute: null,
                        label: '',
                        mode: 'text',
                    },
                ]
            const newConfig = [...prev]
            newConfig.splice(index, 1)
            return newConfig
        })
    }

    const updateFilter = (
        index: number,
        value: Partial<InsightWidgetType['interventionConfig']['filterConfig'][number]>
    ) => {
        setInternalFilterConfig((prev) => {
            const newConfig = [...prev]
            newConfig[index] = {
                ...newConfig[index],
                ...value,
            }
            return newConfig
        })
    }

    const isItemValid = (item: InsightWidgetType['interventionConfig']['filterConfig'][0]) => {
        return item.attribute !== null && item.label !== ''
    }

    const isAllItemsValid = useMemo(() => {
        return internalFilterConfig.every(isItemValid)
    }, [internalFilterConfig])

    return (
        <StyledDialog open={isOpen} maxWidth="md" disableEscapeKeyDown fullWidth>
            <DialogTitle>
                <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Filter Configuration</Typography>
                    <BaseButton variant="text" color="primary" onClick={handleAddFilter}>
                        Add
                    </BaseButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" gap={2}>
                    {internalFilterConfig.map((config, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Stack direction="row" gap={1}>
                                    <Stack direction="column" gap={1}>
                                        {isItemValid(config) ? null : (
                                            <Stack direction="row" gap={1}>
                                                <DynamicIcon icon="error" color="error" />
                                                <Typography color="error">Invalid Configuration</Typography>
                                            </Stack>
                                        )}
                                        <Stack key={index} direction="row" gap={1}>
                                            <FlexibleSelect
                                                hiddenLabel={true}
                                                options={dataSourceFields}
                                                value={config.attribute}
                                                onChange={(value) => {
                                                    updateFilter(index, {
                                                        attribute: value,
                                                        label: value.field,
                                                    })
                                                }}
                                                fullWidth
                                                size="small"
                                                sx={{
                                                    height: '67px',
                                                }}
                                            />
                                            <BaseFilledTextField
                                                label="Label"
                                                value={config.label}
                                                onChange={(evt) =>
                                                    updateFilter(index, {
                                                        label: evt.target.value,
                                                    })
                                                }
                                                fullWidth
                                                inputProps={{
                                                    style: {
                                                        height: '32.5px',
                                                    },
                                                }}
                                            />
                                            <BaseSelectWithLabel
                                                label="Mode"
                                                size="small"
                                                options={[
                                                    { label: 'Text', value: 'text' },
                                                    { label: 'Number', value: 'number' },
                                                ]}
                                                selectProps={{
                                                    style: {
                                                        height: '67px',
                                                    },
                                                }}
                                                value={config.mode}
                                                onChange={(value) =>
                                                    updateFilter(index, {
                                                        mode: value,
                                                    })
                                                }
                                                sx={{
                                                    width: '380px',
                                                }}
                                            />
                                        </Stack>
                                    </Stack>

                                    <BaseButton
                                        variant="text"
                                        color="secondary"
                                        onClick={() => handleRemoveFilter(index)}
                                    >
                                        Remove
                                    </BaseButton>
                                </Stack>
                                <Divider flexItem />
                            </React.Fragment>
                        )
                    })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} variant="outlined" color="secondary">
                    Cancel
                </BaseButton>
                <BaseButton
                    onClick={() => {
                        if (isAllItemsValid) {
                            onSave(internalFilterConfig)
                        }
                    }}
                    disabled={!isAllItemsValid}
                    variant="contained"
                    color="primary"
                >
                    Apply
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default AdvancedInsightFilterConfigDialog
