import React from 'react'
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import SignInLayout from 'pages/sign-in/SignInLayout'
import PageNotFoundImage from '../../assets/images/page-not-found.png'
import DynamicIconSelector from 'features/dynamic-icon-slector/DynamicIconSelector'

type Props = {}

export default function PageNotFound({}: Props) {
    const navigator = useNavigate()
    return (
        <SignInLayout>
            <Stack gridRow={2} width="40%">
                <img
                    src={PageNotFoundImage}
                    alt="Page Not Found"
                    style={{
                        width: '100%',
                    }}
                />
                {/* Title
					========================================= */}
                <Typography fontSize={36} fontWeight={400} color="common.text_1" mb={1}>
                    Something's wrong here...
                </Typography>

                <Typography fontSize={18} fontWeight={300} color="common.text_1" mb={1}>
                    This page is missing or you entered the link incorrectly
                </Typography>

                {/* Go to Home button
					========================================= */}
                <BaseButton
                    label={'Back to home'}
                    onClick={(evt) => {
                        navigator('/dashboard')
                    }}
                    variant="contained"
                    sx={{
                        marginBottom: 3,
                        py: 2,
                    }}
                />
            </Stack>
        </SignInLayout>
    )
}
