//* ======= Libraries
import React, { useState } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BaseButton from 'components/base/BaseButton'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

interface INodeConflictResolutionDialog {
    onConfirm?: () => void
    onClose?: () => void
}

function NodeConflictResolutionDialog({ onConfirm, onClose }: INodeConflictResolutionDialog) {
    const [isMergeView, setIsMergeView] = useState(false)

    return (
        <Stack height="100%">
            {/* Header
				========================================= */}
            <Box
                sx={(theme) => ({
                    flexShrink: 0,

                    display: 'grid',
                    gridTemplateColumns: '80px 1fr 80px',
                    gridTemplateRows: '1fr',
                    gap: 1,
                    justifyItems: 'start',
                    alignItems: 'center',

                    py: 2,
                    px: 3,
                })}
            >
                {/* Back button
					========================================= */}
                {isMergeView && (
                    <BaseButton
                        label="Back"
                        onClick={(e) => setIsMergeView(false)}
                        disabled={!isMergeView}
                        color="secondary"
                        startIcon={<ArrowBackIcon />}
                        sx={{
                            gridColumn: 1,
                        }}
                    />
                )}

                {/* Title
					========================================= */}
                <Typography
                    component="h2"
                    fontSize={30}
                    fontWeight={600}
                    color="primary"
                    sx={{
                        gridColumn: 2,
                        justifySelf: 'center',
                    }}
                >
                    Conflict Resolution
                </Typography>

                {/* Close button
					========================================= */}
                <IconButton
                    onClick={(e) => onClose && onClose()}
                    sx={{
                        gridColumn: 3,
                        justifySelf: 'end',

                        // padding: 0.5,
                    }}
                >
                    <CloseRoundedIcon
                        sx={{
                            height: '20px',
                            width: '20px',
                        }}
                    />
                </IconButton>
            </Box>

            {/* Body
				========================================= */}
            <Stack
                sx={{
                    flexGrow: 1,

                    gap: 1.5,

                    paddingTop: 2,
                    paddingBottom: 3,
                    px: 4,
                }}
            >
                {isMergeView ? (
                    <ConflictMergeView onMerge={() => setIsMergeView(false)} />
                ) : (
                    <ConflictsListView onCloseDialog={onConfirm} onMergeItem={() => setIsMergeView(true)} />
                )}
            </Stack>
        </Stack>
    )
}

interface IConflictsListView {
    onCloseDialog?: () => void
    onMergeItem?: () => void
}

function ConflictsListView({ onCloseDialog, onMergeItem }: IConflictsListView) {
    const keepAllNodes = () => {
        onCloseDialog && onCloseDialog()
    }

    return (
        <>
            {/* Description
				========================================= */}
            <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet in ducimus repellat soluta
                eum ab, dicta tenetur suscipit consectetur laborum quo omnis earum enim repudiandae impedit.
                Quaerat, vel. Sapiente, consectetur!
            </Typography>

            {/* Controls and search
				========================================= */}
            <Stack direction="row" alignItems="center" gap={2}>
                {/* Advanced switch
					========================================= */}
                <FormGroup aria-label="advanced-mode" row>
                    <FormControlLabel
                        control={<BaseSwitch color="primary" />}
                        label="Advanced"
                        labelPlacement="end"
                        sx={{
                            gap: 1,
                            marginRight: 0,

                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                                fontWeight: 400,

                                color: 'common.text_1',
                            },
                        }}
                    />
                </FormGroup>

                {/* Similarity threshold field (Type?)
					========================================= */}
                <BaseFilledTextField
                    hiddenLabel
                    placeholder="Similarity Threshold"
                    sx={{
                        marginLeft: 'auto',

                        '& .MuiFilledInput-root': {
                            '& .MuiFilledInput-input': {
                                py: 1.5,
                            },
                        },
                    }}
                />

                {/* Search
					========================================= */}
                {/* <SearchField options={['Something']} placeholder="Search" /> */}
            </Stack>

            {/* Data grid
				========================================= */}
            <Box
                sx={{
                    height: 500,
                }}
            >
                (DATA GRID GOES HERE!)
            </Box>

            {/* Footer controls
				========================================= */}
            <Stack flexShrink={0} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                {/* Prev/Next conflict buttons
					========================================= */}
                <Stack direction="row" alignItems="center" gap={3}>
                    <BaseButton label="PREVIOUS" startIcon={<ArrowBackIcon />} />

                    <Typography fontSize={25} fontWeight={600} color="primary">
                        {`${1} out of ${200}`}
                    </Typography>

                    <BaseButton label="NEXT" endIcon={<ArrowForwardIcon />} />
                </Stack>

                {/* Keep/Merge buttons
					========================================= */}
                <Stack direction="row" alignItems="center" gap={2.5}>
                    {/* Keep all button
						========================================= */}
                    <BaseButton
                        label="Keep All"
                        onClick={() => keepAllNodes()}
                        variant="outlined"
                        size="large"
                        width="186px"
                    />

                    {/* Merge selected button
						========================================= */}
                    <BaseButton
                        label="Merge Selected"
                        onClick={() => onMergeItem && onMergeItem()}
                        disabled={false}
                        variant="contained"
                        size="large"
                        width="186px"
                    />
                </Stack>
            </Stack>
        </>
    )
}

interface IConflictMergeView {
    onMerge?: () => void
}

function ConflictMergeView({ onMerge }: IConflictMergeView) {
    const selectOptions = [
        {
            value: '',
            label: 'None',
        },
        {
            value: '1',
            label: 'One',
        },
        {
            value: '2',
            label: 'Two',
        },
    ]

    const mergeFields = () => {
        onMerge && onMerge()
    }

    return (
        <>
            {/* Conflicted fields
				========================================= */}
            <Stack
                alignItems="flex-start"
                gap={2}
                sx={{
                    height: 560,
                    paddingTop: 3,
                    paddingBottom: 3,
                    px: 5,
                    overflowY: 'auto',

                    backgroundColor: 'common.bg_3',
                }}
                className="u-scrollbar"
            >
                {/* Profile pic (or radio group?)
					========================================= */}
                <Avatar
                    src={TempAvatar}
                    alt="profile"
                    sx={{
                        height: 56,
                        width: 56,
                        marginBottom: 1,
                    }}
                />

                {/* Fields
					========================================= */}
                {Array.from(Array(5)).map((_, idx) => (
                    <Stack key={idx} gap={1}>
                        <Typography fontSize={16} fontWeight={400}>
                            {`Conflict field ${idx + 1}`}
                        </Typography>

                        <Select
                            defaultValue={''}
                            displayEmpty
                            input={<BasicSelectInput />}
                            IconComponent={ExpandMoreIcon}
                            sx={{
                                width: 380,

                                '& .MuiInput-input': {
                                    py: 2,
                                    fontSize: 18,
                                },

                                '& .MuiSelect-icon': {
                                    color: 'primary.main',
                                },
                            }}
                        >
                            {selectOptions.map((_option, idx) => (
                                <MenuItem key={_option.label + idx} value={_option.value || ''}>
                                    {_option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                ))}
            </Stack>

            {/* Footer buttons
				========================================= */}
            <Stack direction="row" justifyContent="flex-end" gap={1}>
                <BaseButton
                    label="Merge"
                    onClick={() => mergeFields()}
                    variant="contained"
                    size="large"
                    width="186px"
                />
            </Stack>
        </>
    )
}

export default NodeConflictResolutionDialog
