import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Question } from 'survey-core'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import * as Survey from 'survey-core'
import { NodeType } from 'services/NodeService'
import { SurveyInstanceContext } from '../../../contexts/SurveyInstanceContext'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { Clear } from '@mui/icons-material'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { DynamicFilterItemType } from 'features/filter-content/FilterContentDynamic'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useMediaQuery, useTheme } from '@mui/material'
import { mod } from 'mathjs'

interface NetworkQuestionProps {
    question: Question & {
        suggstions?: NodeType[]
        target_placeholder: string
        target_allowArbitary: boolean
        target_nodeType: 'affilation' | 'participant'
        target_allowMultiple: boolean
        target_privateMode: boolean
        target_enableNominationLimit: boolean
        target_nominationLimit: number
    }
    onChange: (newVal: any) => void
    regex?: RegExp
}

const filter = createFilterOptions<NodeType>()

export type MultiSelectItem = {
    primaryKey: number
    title: string
    [key: string]: any
}

export default function NetworkQuestion({ question, onChange, regex }: NetworkQuestionProps) {
    const { suggestions } = useContext(SurveyInstanceContext)
    const [open, setOpen] = useState(false)
    const [values, setValues] = useState<MultiSelectItem[]>([])
    const ref2 = useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useState('')

    const theme = useTheme()
    const isViewportSmall = useMediaQuery(theme.breakpoints.down('md'))

    //set default value if any
    useEffect(() => {
        if (Array.isArray(question.value)) setValues(question.value)
    }, [])

    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setSearchQuery('')
        setOpen(false)
    }

    const handleDelete = (index: number) => () => {
        setValues((v) => {
            const updatedValue = [...v.slice(0, index), ...v.slice(index + 1)]
            onChange(updatedValue)
            return updatedValue
        })
    }

    const cleanedSuggestions = useMemo(() => {
        if (!suggestions || !Array.isArray(suggestions[question.name])) return []
        const freq: { [key: string]: number } = {}
        const tmp = [...suggestions[question.name]]
        for (let i = 0; i < tmp.length; i++) {
            const item = tmp[i]
            if (freq[item.title]) {
                tmp[i].title += ' ' + freq[item.title]++
            } else {
                freq[item.title] = 2
            }
        }
        tmp.sort((a, b) => {
            if (a.title < b.title) return -1
            else if (a.title > b.title) return 1
            else return 0
        })
        return tmp
    }, [suggestions])

    const options = useMemo(() => {
        if (!Array.isArray(cleanedSuggestions)) return []
        if (!values) return cleanedSuggestions

        return cleanedSuggestions.filter((s) => values.findIndex((v) => v.title === s.title) === -1)
    }, [values, cleanedSuggestions])

    const validateValue = (value: any) => {
        return true
    }

    const isDisbaled = useMemo(() => {
        return (
            question.readOnly ||
            (question.target_enableNominationLimit && question.target_nominationLimit <= values.length)
        )
    }, [question.readOnly, question.target_enableNominationLimit, question.target_nominationLimit, values.length])

    const arbitary = useMemo(() => {
        return question.target_allowArbitary === true
    }, [question.target_allowArbitary])

    return (
        <Stack direction="column" width="100%">
            <Stack direction={'column-reverse'} width="100%">
                {values.length > 0 && (
                    <BaseButton
                        width="105px"
                        startIcon={<Clear />}
                        color="warning"
                        onClick={() => {
                            setValues((v) => {
                                const updatedValue: MultiSelectItem[] = []
                                onChange(updatedValue)
                                return updatedValue
                            })
                        }}
                    >
                        Clear All
                    </BaseButton>
                )}
                <Grid2
                    container
                    spacing={2}
                    className={'u-scrollbar'}
                    sx={{
                        marginY: 1,
                        maxHeight: '500px',
                        overflowY: 'auto',
                    }}
                >
                    {values.map((v, index) => (
                        <Grid2 key={v.primaryKey}>
                            <Chip label={v.title} onDelete={handleDelete(index)} />
                        </Grid2>
                    ))}
                </Grid2>
                <Stack direction={isViewportSmall ? 'column' : 'row'} spacing={1}>
                    <Autocomplete
                        size="small"
                        options={options}
                        disabled={isDisbaled}
                        getOptionLabel={(option) => {
                            if (typeof option == 'string') return option
                            else if (option.primaryKey === -1) return option.category || ''
                            else return option.title
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params)
                            const { inputValue } = params
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                                (option) => inputValue.toLowerCase() === option.title.toLowerCase()
                            )
                            if (arbitary && inputValue !== '' && !isExisting) {
                                filtered.push({
                                    primaryKey: -1,
                                    title: inputValue,
                                })
                            }
                            return filtered
                        }}
                        renderOption={(props, option) => (
                            <li {...props}>
                                {option.primaryKey === -1 ? 'Add ' : ''} {option.title}
                            </li>
                        )}
                        onChange={(event, value) => {
                            event.preventDefault()
                            if (typeof value == 'string') {
                                if (validateValue(value)) {
                                    let suggestion = suggestions[question.name]?.find(
                                        (s) => s.title.toLowerCase() === value.toLocaleLowerCase()
                                    )
                                    if (suggestion != null) {
                                        //@ts-ignore
                                        setValues((v) => {
                                            const updatedValue = [suggestion, ...v]
                                            onChange(updatedValue)
                                            return updatedValue
                                        })
                                    } else {
                                        setValues((v) => {
                                            const updatedValue = [{ title: value, primaryKey: -1 }, ...v]
                                            onChange(updatedValue)
                                            return updatedValue
                                        })
                                    }
                                }
                            } else {
                                if (value != null) {
                                    let isValid = validateValue(value.title)

                                    if (isValid)
                                        setValues((v) => {
                                            const updatedValue = [value, ...v]
                                            onChange(updatedValue)
                                            return updatedValue
                                        })
                                }
                            }

                            setTimeout(() => {
                                if (ref2.current) {
                                    ref2.current.blur()
                                    const event = new KeyboardEvent('keydown', {
                                        key: 'Escape',
                                    })
                                    ref2.current.dispatchEvent(event)
                                }
                            }, 10)
                        }}
                        value={''}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableCloseOnSelect={true}
                        freeSolo={arbitary}
                        readOnly={question.readOnly}
                        renderInput={(params) => (
                            <TextField {...params} placeholder={question.target_placeholder} inputRef={ref2} />
                        )}
                        sx={{ flexGrow: 1 }}
                    />
                    <BaseButton
                        startIcon={<ListAltIcon />}
                        sx={{ alignSelf: isViewportSmall ? 'flex-start' : 'center' }}
                        onClick={handleOpen}
                    >
                        Show List
                    </BaseButton>
                </Stack>
            </Stack>
            <StyledDialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{question.title || question.name}</DialogTitle>
                <DialogContent>
                    <Grid2
                        className={'u-scrollbar'}
                        container
                        spacing={2}
                        sx={{
                            marginY: 1,
                            maxHeight: '100px',
                            overflowY: 'auto',
                        }}
                    >
                        {values.map((v: any, index: any) => (
                            <Grid2>
                                <Chip label={v.title} onDelete={handleDelete(index)} />
                            </Grid2>
                        ))}
                    </Grid2>
                    <Divider />
                    <Box sx={{ height: '50vh' }}>
                        <StyledDataGrid
                            disableMultipleRowSelection={true}
                            hasToolbar={false}
                            sx={{
                                '.MuiDataGrid-row': {
                                    cursor: isDisbaled ? 'not-allowed' : 'pointer',
                                    userSelect: 'none',
                                },
                            }}
                            disableRowSelectionOnClick={isDisbaled}
                            onRowClick={(params) => {
                                // wont add more if readOnly or limit reached
                                if (isDisbaled) return

                                const item = options.find((x) => x.primaryKey === params.id)
                                if (item) {
                                    setValues((v) => {
                                        const updatedValue = [item, ...v]
                                        onChange(updatedValue)
                                        return updatedValue
                                    })
                                }
                            }}
                            columns={[{ field: 'title', headerName: 'Name' }]}
                            getRowId={(r) => r.primaryKey}
                            rows={options}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <BaseButton variant="contained" onClick={handleClose}>
                        Close
                    </BaseButton>
                </DialogActions>
            </StyledDialog>
        </Stack>
    )
}

export function registerNetworkQuestion() {
    Survey.SvgRegistry.registerIconFromSvg(
        'network',
        '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>'
    )

    var networkQuestionWidget = {
        name: 'networkquestion',
        title: 'Network Nomination',
        iconName: 'icon-network',
        widgetIsLoaded: function () {
            return true //We do not have external scripts
        },
        isFit: function (question: any) {
            return question.getType() === 'networkquestion'
        },
        init() {
            Survey.Serializer.addClass('networkquestion', [], undefined, 'empty')

            ///Target Node

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_placeholder:string',
                default: 'Please type in names or select by clicking show all options button',
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_hint:string',
                default: 'Enter at least 2 characters to see the options',
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_createMessage:string',
                default: 'Click here to add',
                dependsOn: ['target_allowArbitary'],
                visibleIf: function (obj: any) {
                    return obj.allowArbitrary
                },
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_allowArbitary:boolean',
                default: 'false',
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_nodeType:string',
                default: 'participant',
                category: 'Target Node',
                dependsOn: ['target_allowArbitary'],
                visibleIf: function (obj: any) {
                    return obj.target_allowArbitary
                },
                choices: ['participant', 'affiliation'],
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_allowMultiple:boolean',
                default: 'true',
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_enableNominationLimit:boolean',
                default: false,
                dependsOn: ['target_allowMultiple'],
                visibleIf: function (obj: any) {
                    return obj.target_allowMultiple
                },
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_nominationLimit:number',
                default: 10,
                dependsOn: ['target_enableNominationLimit', 'target_allowMultiple'],
                visibleIf: function (obj: any) {
                    return obj.target_enableNominationLimit && obj.target_allowMultiple
                },
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_privateMode:boolean',
                default: 'false',
                category: 'Target Node',
            })

            Survey.Serializer.addProperty('networkquestion', {
                name: 'target_filter:filter',
                default: [],
                category: 'Target Node',
                onSetValue: (question: Question, value: DynamicFilterItemType[]) => {
                    question.setPropertyValue('target_filter', value || [])
                },
            })
        },
        render: function (questionBase: any) {
            const dependentQuestions = questionBase
                .getSurvey()
                .getAllQuestions()
                .filter(
                    (x: any) =>
                        x.getType() === 'paneldynamic' &&
                        x.networkDetailsMode &&
                        x.networkQuestion &&
                        x.networkQuestion.includes(questionBase.name)
                )

            function onChange(newVal: any) {
                const newValueIds = newVal
                    ? newVal.map((v: any) => ({
                          id: v.primaryKey === -1 ? v.title : v.primaryKey,
                          title: v.title,
                      }))
                    : []
                questionBase.value = newVal || null
                dependentQuestions.forEach((q: any) => {
                    const panels = q.panels
                    const panelsToRemove: any = []
                    panels.forEach((p: any, index: any) => {
                        if (!newValueIds.some(({ id }: any) => id === p.id)) {
                            panelsToRemove.push(index)
                        }
                    })
                    const panelsToAdd = newValueIds.filter(({ id }: any) => {
                        return !panels.find((p: any) => p.id === id)
                    })

                    panelsToRemove.forEach((p: any) => {
                        q.removePanel(p)
                    })

                    panelsToAdd.forEach(({ id, title }: any) => {
                        const panel = q.addPanel()
                        panel.id = id
                        const question = panel.addNewQuestion('html', 'panelId', 0)
                        question.html = `<p>${title}</p>`
                        question.value = id
                    })
                })
            }

            return <NetworkQuestion onChange={onChange} question={questionBase} />
        },
    }
    Survey.CustomWidgetCollection.Instance.add(networkQuestionWidget, 'customtype')
}
