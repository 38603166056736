//* ======= Libraries
import React, { useContext, useEffect, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import BaseButton from 'components/base/BaseButton'
import SurveyNotificationInvitation from 'features/survey-notifications/SurveyNotificationInvitation'
import SurveyNotificationTwoLevelInvitation from 'features/survey-notifications/SurveyNotificationTwoLevelInvitation'
import SurveyNotificationReminder from 'features/survey-notifications/SurveyNotificationReminder'
//* ======= Custom logic
//* ======= Assets and styles
import { useParams } from 'react-router-dom'
import { SurveyContext } from 'contexts/SurveyContex'
import { GetSurveysProfileService, SurveyProfileType, UpdateSurveyProfileService } from 'services/SurveyService'

interface ISurveyNotifications {}

/**
 * TODO:
 *  1.  Other templates design is still not finalized; only the 'invitation'
 *      is implemented.
 */

function SurveyNotifications({}: ISurveyNotifications) {
    const { sid, pid } = useParams()
    const { drawerContent } = useContext(SurveyContext)
    const [selectedPanel, setSelectedPanel] = useState(0)
    const [isChanged, setIsChanged] = useState(false)
    const [surveyProfile, _setSurveyProfile] = useState<SurveyProfileType>()

    const setSurveyProfile = (name: string) => (value?: any) => {
        setIsChanged(true)
        if (
            ['autoInviteEmail', 'autoInviteSms', 'autoReminderEmail', 'autoReminderSms', 'twoLevelApproval'].includes(
                name
            )
        ) {
            // @ts-ignore
            _setSurveyProfile((sp) => (sp == null ? undefined : { ...sp, [name]: !sp[name] }))
        } else {
            _setSurveyProfile((sp) => (sp == null ? undefined : { ...sp, [name]: value?.target?.value || value }))
        }
    }

    useEffect(() => {
        if (!sid || !pid) {
            return
        }
        GetSurveysProfileService(parseInt(pid), parseInt(sid)).then((res) => {
            if (res.success) _setSurveyProfile(res.data)
        })
    }, [sid, pid])

    const updateSurveyProfile = () => {
        if (!pid || !sid || !surveyProfile) return
        UpdateSurveyProfileService(
            parseInt(pid),
            parseInt(sid),
            surveyProfile,
            true,
            'Survey Updated successfully'
        ).then((res) => res.success && setIsChanged(false))
    }

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            {surveyProfile != null && (
                <PageCard.Body>
                    <Stack
                        gap={3}
                        height="100%"
                        sx={(theme) => ({
                            paddingTop: 2,
                            paddingBottom: 3,
                            px: 2,

                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                        })}
                    >
                        {/* Panel switch buttons
						========================================= */}
                        <Stack direction="row" alignItems="flex-start" gap={5}>
                            {/* Invitation
							========================================= */}
                            <BaseButton
                                label="Invitation"
                                onClick={(e) => setSelectedPanel(0)}
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 500,

                                    color: selectedPanel === 0 ? 'primary.main' : 'common.text_3',
                                }}
                            />

                            {/* Two level invitation
                            ToDo Version 2
							========================================= */}
                            {/* <BaseButton
                                label="Two Level Invitation"
                                onClick={(e) => setSelectedPanel(1)}
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 500,

                                    color: selectedPanel === 1 ? 'primary.main' : 'common.text_3',
                                }}
                            /> */}

                            {/* Reminder
							========================================= */}
                            <BaseButton
                                label="Reminder"
                                onClick={(e) => setSelectedPanel(2)}
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 500,

                                    color: selectedPanel === 2 ? 'primary.main' : 'common.text_3',
                                }}
                            />
                        </Stack>

                        {/* Panels
						========================================= */}
                        <Box flexGrow={1}>
                            {selectedPanel === 0 ? (
                                <SurveyNotificationInvitation
                                    surveyProfile={surveyProfile}
                                    setSurveyProfile={setSurveyProfile}
                                    disabledChange={!isChanged}
                                    onSave={updateSurveyProfile}
                                />
                            ) : selectedPanel === 1 ? (
                                <SurveyNotificationTwoLevelInvitation />
                            ) : selectedPanel === 2 ? (
                                <SurveyNotificationReminder
                                    surveyProfile={surveyProfile}
                                    setSurveyProfile={setSurveyProfile}
                                    disabledChange={!isChanged}
                                    onSave={updateSurveyProfile}
                                />
                            ) : (
                                false
                            )}
                        </Box>
                    </Stack>
                </PageCard.Body>
            )}
        </PageCard>
    )
}

export default SurveyNotifications
