//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { Stack, Box, Typography, IconButton, Divider, Tooltip } from '@mui/material'
import { isEqual } from 'lodash'
//* ======= Components and features
import Chart from 'features/chart/Chart'
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import StyledDialog from 'components/dialog/StyledDialog'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    convertNodeAttributeToKeyString,
    getChartAttributes,
    getDataSourceData,
    getDataSourceFields,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { generateChartOptions, getChartTypeInitialValues } from 'features/chart/Chart.helper'
import {
    ReportDataSourceAttributeType,
    SelectedDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import { ReportTreemapChartWidgetSettingsStateType } from './TreemapChartWidgetSettings'
import { TreemapChartDataDefinitionType, TreemapChartOptionsType } from 'features/chart/Chart.asset'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import AddIcon from '@mui/icons-material/Add'

type Props = {
    isOpen: boolean
    data: ReportTreemapChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onConfirm: (data: Pick<ReportTreemapChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>) => void
    onClose: () => void
}

function TreemapChartDataConfigDialog({
    isOpen = false,
    data,
    dataSources,
    onConfirm,
    onClose = () => undefined,
}: Props) {
    const [chartData, setChartData] = useState<ReportTreemapChartWidgetSettingsStateType>(data)

    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])
    const [chartOptions, setChartOptions] = useState<TreemapChartOptionsType | null>(chartData.options)

    // Prevent user from applying any changes if the selected data configurations are not valid
    const isChartConfigValid = useMemo(() => {
        if (
            chartData.selectedDataSource === null ||
            chartData.selectedDataSource.id === -1 ||
            chartData.dataDefinition.valueField === null ||
            chartData.dataDefinition.labelField === null ||
            chartData.dataDefinition.groupByFields.length === 0
        ) {
            return false
        }

        return true
    }, [chartData])

    // After selected data source changes, update and show its fields.
    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, chartData.selectedDataSource))
    }, [chartData.selectedDataSource])

    // Update dialog's chart options after chartData state changes.
    useEffect(() => {
        if (isChartConfigValid) {
            setChartOptions((prevState) => {
                const newOptions = generateChartOptions({
                    type: 'treemap',
                    mode: 'data',
                    prevOptions: prevState,
                    filteredData: getDataSourceData(
                        dataSources,
                        chartData.selectedDataSource,
                        getChartAttributes(chartData.type, chartData.dataDefinition)
                    ),
                    dataDefinition: chartData.dataDefinition,
                    config: chartData.config,
                })

                return { ...newOptions }
            })
        } else {
            setChartOptions(null)
        }
    }, [chartData, isChartConfigValid])

    const updateSelectedDataSource = (selectedDataSource: SelectedDataSourceType) => {
        const initialValues = getChartTypeInitialValues(chartData.type)

        if (initialValues !== null) {
            setChartData((prevState) => {
                // If the selected data source was changed (based on some of its properties that are considered important),
                // we need to reset some chart state properties to their initial values:
                let hasDataSourceChanged = false
                if (
                    prevState.selectedDataSource === null ||
                    prevState.selectedDataSource.id !== selectedDataSource.id ||
                    prevState.selectedDataSource.preset !== selectedDataSource.preset ||
                    prevState.selectedDataSource.type !== selectedDataSource.type
                ) {
                    hasDataSourceChanged = true
                }

                return {
                    ...prevState,
                    selectedDataSource: {
                        ...prevState.selectedDataSource,
                        ...selectedDataSource,
                    },
                    dataDefinition: hasDataSourceChanged
                        ? (initialValues.dataDefinition as TreemapChartDataDefinitionType)
                        : { ...prevState.dataDefinition },
                    options: hasDataSourceChanged ? null : { ...prevState.options },
                }
            })
        }
    }

    const onDataDefinitionChange = (value: Partial<ReportTreemapChartWidgetSettingsStateType['dataDefinition']>) => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                ...value,
            },
        }))
    }

    const onAddGroupByField = () => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                groupByFields: [
                    ...prevState.dataDefinition.groupByFields,
                    {
                        type: 'basic',
                        field: '',
                    },
                ],
            },
        }))
    }

    const onUpdateGroupByField = (index: number, value: ReportDataSourceAttributeType) => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                groupByFields: prevState.dataDefinition.groupByFields.map((field, i) => (i === index ? value : field)),
            },
        }))
    }

    const onRemoveGroupByField = (index: number) => {
        setChartData((prevState) => ({
            ...prevState,
            dataDefinition: {
                ...prevState.dataDefinition,
                groupByFields: prevState.dataDefinition.groupByFields.filter((_, i) => i !== index),
            },
        }))
    }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',

                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),

                    padding: theme.spacing(2, 3),

                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Treemap Chart Data Configuration
                </Typography>

                <IconButton
                    onClick={(evt) => onClose()}
                    size="small"
                    sx={{
                        gridColumn: 3,
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 1 auto',

                    display: 'grid',
                    gridTemplateColumns: '10fr 9fr',
                    placeContent: 'stretch',
                    columnGap: 2,

                    height: '70vh',
                    padding: theme.spacing(3),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {/* Content wrapper
                    ========================================= */}
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        paddingRight: theme.spacing(2),
                        overflowY: 'auto',

                        borderRight: `1px solid ${theme.palette.common.border_3}`,
                    })}
                    className="u-scrollbar"
                >
                    {/* Data source selector
                        ========================================= */}
                    <ReportDataSourceSelector
                        dataSources={dataSources}
                        selectedDataSource={chartData.selectedDataSource}
                        onChange={updateSelectedDataSource}
                    />

                    <Divider />

                    {/* Label Field select
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Label Field:
                        </Typography>

                        <FlexibleSelect
                            label="Field"
                            options={dataSourceFields}
                            value={dataSourceFields.length !== 0 ? chartData.dataDefinition.labelField : undefined}
                            onChange={(value) =>
                                onDataDefinitionChange({
                                    labelField: value,
                                })
                            }
                            disabled={dataSourceFields.length === 0}
                            required
                            fullWidth
                            sx={{
                                maxWidth: 480,
                            }}
                        />
                    </Stack>

                    {/* Value Field select
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Value Field:
                        </Typography>

                        <FlexibleSelect
                            label="Field"
                            options={dataSourceFields}
                            value={dataSourceFields.length !== 0 ? chartData.dataDefinition.valueField : undefined}
                            onChange={(value) =>
                                onDataDefinitionChange({
                                    valueField: value,
                                })
                            }
                            disabled={dataSourceFields.length === 0}
                            required
                            fullWidth
                            sx={{
                                maxWidth: 480,
                            }}
                        />
                    </Stack>

                    {/* Group by fields select
                        ========================================= */}
                    <Stack gap={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                            <Typography
                                textAlign="left"
                                noWrap
                                sx={{
                                    flex: '1 0 0',
                                }}
                            >
                                Group by Fields
                            </Typography>

                            <Tooltip title="Add">
                                <IconButton onClick={onAddGroupByField} size="small">
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                    {chartData.dataDefinition.groupByFields.map((field, idx) => {
                        const key = convertNodeAttributeToKeyString(field)
                        return (
                            <Stack key={key} direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                <FlexibleSelect
                                    label="Fields"
                                    options={dataSourceFields}
                                    value={field}
                                    onChange={(value) => onUpdateGroupByField(idx, value)}
                                    disabled={dataSourceFields.length === 0}
                                    required
                                    fullWidth
                                    sx={{
                                        maxWidth: 480,
                                    }}
                                />
                                <Tooltip title="Remove">
                                    <IconButton onClick={() => onRemoveGroupByField(idx)} size="small">
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        )
                    })}
                </Stack>

                {/* Chart wrapper
                    ========================================= */}
                {chartOptions === null ? (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: '100%',
                        }}
                    >
                        Invalid/Empty data.
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Chart type={chartData.type} options={chartOptions} clearOnChange={true} />
                    </Box>
                )}
            </Box>

            {/* Footer
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    padding: theme.spacing(2, 3),
                })}
            >
                <BaseButton
                    label="Discard"
                    onClick={(evt) => onClose()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Apply"
                    onClick={(evt) => onConfirm(chartData)}
                    disabled={isChartConfigValid === false}
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </StyledDialog>
    )
}

export default TreemapChartDataConfigDialog
