//* ======= Libraries
import { useMemo, useState } from 'react'
import {
    Stack,
    Box,
    Paper,
    Typography,
    IconButton,
    Avatar,
    Tooltip,
    Divider,
    Grow,
    Popper,
    Autocomplete,
    ClickAwayListener,
    InputBase,
    autocompleteClasses,
} from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import { TableConfigCardView } from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
//* ======= Assets and styles
import BackIcon from '@mui/icons-material/ChevronLeft'
import NextIcon from '@mui/icons-material/ChevronRight'
import SearchIcon from '@mui/icons-material/Search'

export type TableDataHeaderType = {
    id: string | number
    title: string
    subtitle?: string
    avatar?: string
}

type TableDataHeaderProps = {
    activeRowIndex: number | null
    data: TableDataHeaderType[]
    headerStyle?: TableConfigCardView['headerStyle']
    canChangeRows?: boolean
    hasSearch?: boolean
    disabled?: boolean
    updateActiveRowIndex: (index: number) => void
}

export default function TableDataHeader({
    activeRowIndex,
    updateActiveRowIndex,
    data,
    disabled = false,
    canChangeRows = false,
    hasSearch = false,
    headerStyle = {
        color: 'common.text_1',
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 'bold',
    },
}: TableDataHeaderProps) {
    const activeDataRow = useMemo(() => {
        return activeRowIndex !== null
            ? data[activeRowIndex]
            : {
                  title: '',
                  subtitle: '',
                  avatar: '',
              }
    }, [activeRowIndex, data])

    const [rowSelectionPopper, setRowSelectionPopper] = useState<{
        isOpen: boolean
        anchorElement: HTMLElement | null
    }>({ isOpen: false, anchorElement: null })

    const navigateRows = (step: 1 | -1) => {
        if (step === 1) {
            const updatedIndex = Math.min(data.length - 1, (activeRowIndex ?? 0) + 1)

            updateActiveRowIndex(updatedIndex)
        } else {
            const updatedIndex = Math.max(0, (activeRowIndex ?? 0) - 1)

            updateActiveRowIndex(updatedIndex)
        }
    }

    const iconSize = useMemo(() => {
        return Math.max(18, headerStyle.fontSize)
    }, [headerStyle.fontSize])

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{
                    flexShrink: 0,

                    minWidth: 0,
                }}
            >
                {/* Previous row button
                    ========================================= */}
                {canChangeRows && data.length > 1 && (
                    <Tooltip title="Previous">
                        {/* Intermediary element to capture Tooltip on button's disabled state. */}
                        <Box
                            sx={{
                                flexShrink: 0,
                            }}
                        >
                            <IconButton
                                onClick={(evt) => navigateRows(-1)}
                                disabled={activeRowIndex === null || activeRowIndex === 0}
                                sx={{ p: 0 }}
                            >
                                <BackIcon sx={{ fontSize: iconSize }} />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* Header content
                    ========================================= */}
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                    sx={(theme) => ({
                        flex: '1 0 0',
                        minWidth: 0,
                    })}
                >
                    {/* Avatar
                        ========================================= */}
                    {activeDataRow.avatar && (
                        <Avatar
                            src={activeDataRow.avatar}
                            sx={{
                                flexShrink: 0,
                            }}
                        />
                    )}

                    <Stack
                        sx={(theme) => ({
                            gap: theme.spacing(0.5),
                            flexGrow: 1,
                            minWidth: 0,
                        })}
                    >
                        {/* Title
                            ========================================= */}
                        <Tooltip
                            title={activeDataRow.title}
                            enterDelay={500}
                            enterNextDelay={500}
                            placement="top"
                            arrow
                        >
                            <Typography
                                noWrap
                                sx={(theme) => ({
                                    width: '100%',
                                    fontFamily: headerStyle.fontFamily,
                                    fontSize: headerStyle.fontSize,
                                    fontWeight: headerStyle.fontWeight,
                                    textAlign: 'center',

                                    color: headerStyle.color,
                                })}
                            >
                                {activeDataRow.title}
                            </Typography>
                        </Tooltip>

                        {/* Subtitle
                            ========================================= */}
                        {activeDataRow.subtitle !== undefined && (
                            <Typography
                                noWrap
                                sx={(theme) => ({
                                    width: '100%',
                                    fontFamily: headerStyle.fontFamily,
                                    fontSize: Math.min(headerStyle.fontSize, Math.max(10, headerStyle.fontSize * 0.6)),
                                    textAlign: 'center',
                                    color: headerStyle.color,
                                })}
                            >
                                {activeDataRow.subtitle}
                            </Typography>
                        )}
                    </Stack>

                    {/* Search button
                        ========================================= */}
                    {canChangeRows && hasSearch && data.length && (
                        <Tooltip title="Search">
                            <IconButton
                                className="search-rows"
                                onClick={(evt) => {
                                    setRowSelectionPopper((prevState) => ({
                                        ...prevState,
                                        isOpen: !prevState.isOpen,
                                        anchorElement: evt.currentTarget,
                                    }))
                                }}
                                sx={(theme) => ({})}
                            >
                                <SearchIcon
                                    sx={(theme) => ({
                                        color: headerStyle.color,
                                        fontSize: iconSize,
                                    })}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>

                {/* Next row button
                    ========================================= */}
                {canChangeRows && data.length > 1 && (
                    <Tooltip title="Next">
                        {/* Intermediary element to capture Tooltip on button's disabled state. */}
                        <Box
                            sx={{
                                flexShrink: 0,
                            }}
                        >
                            <IconButton
                                onClick={(evt) => navigateRows(1)}
                                disabled={activeRowIndex === data.length - 1}
                                sx={{ p: 0 }}
                            >
                                <NextIcon sx={{ fontSize: iconSize }} />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}
            </Stack>
            <Divider
                sx={{
                    marginBottom: 0.5,
                }}
            />

            {/* (Out-of-flow) Search rows autocomplete popper
                ========================================= */}
            <Popper
                id="search-rows-autocomplete-popper"
                open={rowSelectionPopper.isOpen}
                anchorEl={rowSelectionPopper.anchorElement}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 0],
                            },
                        },
                    ],
                }}
                placement="top-end"
                sx={{
                    zIndex: 13000,
                }}
                transition
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        timeout={250}
                        style={{
                            transformOrigin: 'bottom right',
                        }}
                    >
                        <Paper
                            elevation={8}
                            sx={(theme) => ({
                                backgroundImage: 'unset',
                                // @Theme conditional
                                backgroundColor:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_2
                                        : theme.palette.common.bg_4,
                            })}
                        >
                            <ClickAwayListener
                                onClickAway={() =>
                                    setRowSelectionPopper((prevState) => ({
                                        ...prevState,
                                        isOpen: false,
                                    }))
                                }
                            >
                                <Stack
                                    alignItems="center"
                                    gap={1}
                                    sx={(theme) => ({
                                        padding: theme.spacing(1),

                                        borderRadius: '4px',
                                        border: `1px solid ${theme.palette.common.border_2}`,
                                    })}
                                >
                                    <Autocomplete
                                        options={data}
                                        onChange={(evt, value, reason) => {
                                            if (reason === 'selectOption') {
                                                const selectedOptionIndex = data.findIndex(
                                                    (_item) => _item.title === value?.title
                                                )

                                                if (selectedOptionIndex !== -1) {
                                                    updateActiveRowIndex(selectedOptionIndex)

                                                    // onActiveRowChange(
                                                    //     cardsData[selectedOptionIndex] !== undefined
                                                    //         ? cardsData[selectedOptionIndex].id
                                                    //         : null
                                                    // )
                                                }
                                            }
                                        }}
                                        onClose={(evt, reason) => {
                                            if (reason === 'escape' || reason === 'selectOption') {
                                                setRowSelectionPopper((prevState) => ({
                                                    ...prevState,
                                                    isOpen: false,
                                                }))

                                                if (rowSelectionPopper.anchorElement !== null) {
                                                    rowSelectionPopper.anchorElement.focus()
                                                }
                                            }
                                        }}
                                        getOptionLabel={(option) => {
                                            if (typeof option === 'string') return option

                                            return option.title
                                        }}
                                        renderOption={(props, option, state) => <li {...props}>{option.title}</li>}
                                        noOptionsText="No Options."
                                        open
                                        autoHighlight
                                        selectOnFocus
                                        renderInput={(params) => (
                                            <InputBase
                                                ref={params.InputProps.ref}
                                                inputProps={params.inputProps}
                                                autoFocus
                                                name="search-rows-input"
                                                placeholder="Search..."
                                                endAdornment={
                                                    <SearchIcon
                                                        sx={{
                                                            color: 'secondary.main',
                                                            fontSize: iconSize,
                                                        }}
                                                    />
                                                }
                                                sx={(theme) => ({
                                                    gap: theme.spacing(0.5),

                                                    width: 300,
                                                    padding: theme.spacing(0.5, 1),

                                                    borderRadius: '4px',
                                                    border: `1px solid ${theme.palette.common.border_2}`,
                                                    backgroundColor: theme.palette.common.bg_3,

                                                    '&.Mui-focused': {
                                                        borderColor: theme.palette.primary.main,
                                                        backgroundColor: theme.palette.common.bg_2,
                                                    },
                                                })}
                                            />
                                        )}
                                        PopperComponent={AutoCompletePopperComponent}
                                        ListboxProps={{
                                            className: 'u-scrollbar',
                                        }}
                                        disablePortal
                                        sx={(theme) => ({
                                            // width: 500,
                                        })}
                                    />
                                </Stack>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

type AutoCompletePopperComponentProps = {
    anchorEl?: any
    disablePortal?: boolean
    open: boolean
}

function AutoCompletePopperComponent(props: AutoCompletePopperComponentProps) {
    const { disablePortal, anchorEl, open, ...other } = props
    return (
        <Box
            {...other}
            sx={(theme) => ({
                [`&.${autocompleteClasses.popperDisablePortal}`]: {
                    position: 'relative',
                },

                [`& .${autocompleteClasses.paper}`]: {
                    margin: 0,

                    boxShadow: 'none',
                    backgroundColor: 'unset',
                    color: 'inherit',
                },

                [`& .${autocompleteClasses.listbox}`]: {
                    padding: 0,

                    [`& .${autocompleteClasses.option}`]: {
                        minHeight: 'auto',
                        padding: theme.spacing(1, 1.5),

                        borderBottom: `1px solid  ${theme.palette.common.border_2}`,

                        '&[aria-selected="true"]': {
                            backgroundColor: theme.palette.common.bg_3,
                        },

                        [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
                            backgroundColor: theme.palette.primary.main,
                        },
                    },
                },
            })}
        ></Box>
    )
}
