import {
    Badge,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material'
import { FilterRecordType, ReportFilterCompareType } from 'features/report-designer/types/reportDesigner.types'
import { useMemo, useState } from 'react'
import FilterIcon from '@mui/icons-material/FilterList'

type FilterBadgeProps = {
    filters: FilterRecordType | null
    size?: 'small' | 'large' | 'medium'
    color?: string
    badgePosition?: 'top' | 'bottom'
}

export const ReportFilterOperatorDictionary = {
    eq: '=',
    neq: '!=',
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    containsAny: 'In',
    notContainsAny: 'Not In',
    between: 'Between',
}

const getFilterSummaryText = (_filter: ReportFilterCompareType) => {
    let text = _filter.field!.field + ' ' + ReportFilterOperatorDictionary[_filter.operator] + ' ' + _filter.value

    return text
}

const FilterBadge = ({ filters, size = 'medium', color, badgePosition = 'bottom' }: FilterBadgeProps) => {
    const filterItems = useMemo(() => {
        if (filters === null) return []

        const _filters: string[] = []

        Object.entries(filters).forEach(([key, _filterItems]) => {
            if (_filterItems === null) return

            Object.entries(_filterItems).forEach(([label, _filterItem]) => {
                if (_filterItem.field === null) return
                _filters.push(getFilterSummaryText(_filterItem))
            })
        })

        return _filters
    }, [filters])

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            {filterItems.length > 0 && (
                <Box>
                    <IconButton onClick={handleClick} sx={{ padding: 0, margin: 0 }}>
                        <Badge
                            variant="dot"
                            color="info"
                            anchorOrigin={{
                                vertical: badgePosition,
                                horizontal: 'right',
                            }}
                        >
                            <FilterIcon fontSize={size} sx={{ color: color }} />
                        </Badge>
                    </IconButton>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        container={document.getElementById('report-slide-container')}
                    >
                        <List dense>
                            {filterItems.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={item} />
                                </ListItem>
                            ))}
                        </List>
                    </Popover>
                </Box>
            )}
        </>
    )
}

export default FilterBadge
