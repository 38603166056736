import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Dialog,
    Typography,
    Box,
    DialogTitle,
    DialogActions,
    DialogContent,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'

import { GridColDef } from '@mui/x-data-grid-pro'
import { RootContext } from 'contexts/RootContext'
import GeneralDrawerNavigation from 'pages/general-dashboard/Navigation'
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import BaseButton from 'components/base/BaseButton'
import AddIcon from '@mui/icons-material/Add'
import StyledDialog from 'components/dialog/StyledDialog'
import { Controller, useForm } from 'react-hook-form'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ToastHelper from 'helpers/ToastHelper'
import {
    CreateInterventionService,
    DeleteInterventionService,
    GetInterventionsService,
    InterventionType,
    UpdateInterventionService,
} from 'services/InterventionService'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import InfoIcon from '@mui/icons-material/Info'
import CheckIcon from '@mui/icons-material/DoneAll'
import WarningIcon from '@mui/icons-material/Warning'
import QueryStatsIcon from '@mui/icons-material/QueryStats'

const InterventionProviders: React.FC = () => {
    const [interventions, setInterventions] = useState<InterventionType[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [interventionToDelete, setInterventionToDelete] = useState<null | string | number>(null)
    const [interventionToEdit, setInterventionToEdit] = useState<null | InterventionType>(null)
    const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false)

    const handleCloseAddEditDialog = () => {
        setInterventionToEdit(null)
        setCreateDialogOpen(false)
        fetchInterventions()
    }

    const fetchInterventions = async () => {
        setLoading(true)
        try {
            const data = await GetInterventionsService()
            if (data.success) {
                setInterventions(data.data)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchInterventions()
    }, [])

    const columns: GridColDef[] = [
        { field: 'title', headerName: 'Title' },
        { field: 'type', headerName: 'Type' },
        {
            field: 'link',
            headerName: 'Icon',
            renderCell: (params) => {
                switch (params.row.link) {
                    case 'Info':
                        return <InfoIcon color="info" />
                    case 'Check':
                        return <CheckIcon color="success" />
                    case 'Warning':
                        return <WarningIcon color="warning" />
                    case 'Monitor':
                        return <QueryStatsIcon color="info" />
                    default:
                        return null
                }
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Box>
                    <Button color="primary" onClick={() => handleEditOpen(params.row as InterventionType)}>
                        Edit
                    </Button>
                    <Button color="secondary" onClick={() => setInterventionToDelete(params.id)}>
                        Delete
                    </Button>
                </Box>
            ),
        },
    ]

    const handleEditOpen = (intervention: InterventionType) => {
        setInterventionToEdit(intervention)
    }

    const handleDeleteConfirm = () => {
        if (interventionToDelete) {
            // Add the delete logic here
            DeleteInterventionService(interventionToDelete).then((res) => {
                if (res.success) {
                    setInterventions((prev) =>
                        prev.filter((intervention) => intervention.primaryKey !== interventionToDelete)
                    )
                    setInterventionToDelete(null)
                }
            })
        }
    }

    const { user } = useContext(RootContext)

    if (user == null) return null

    const drawerContent = GeneralDrawerNavigation(user.roles)

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>
            <PageCard.Body>
                <Box sx={{ backgroundColor: 'common.bg_1', height: '100%' }}>
                    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
                        <StyledDataGrid
                            rows={interventions}
                            columns={columns}
                            loading={loading}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                            hasToolbar={false}
                            getRowId={(row) => row.primaryKey}
                            customToolbar={
                                <BaseButton
                                    variant="text"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => setCreateDialogOpen(true)}
                                >
                                    Create New intervention
                                </BaseButton>
                            }
                        />
                    </Box>
                    <Dialog open={interventionToDelete !== null} onClose={() => setInterventionToDelete(null)}>
                        <DialogTitle>Delete Intervention</DialogTitle>
                        <DialogContent>
                            <Typography>Are you sure you want to delete this intervention?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="warning" onClick={handleDeleteConfirm}>
                                Confirm Delete
                            </Button>
                            <Button variant="outlined" onClick={() => setInterventionToDelete(null)}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <CreateInterventionDialog
                        open={isCreateDialogOpen || interventionToEdit !== null}
                        defaultValues={interventionToEdit}
                        onClose={handleCloseAddEditDialog}
                    />
                </Box>
            </PageCard.Body>
        </PageCard>
    )
}

export default InterventionProviders

type ICreateInterventionDialog = {
    defaultValues: InterventionType | null
    open: boolean
    onClose: () => void
}

function CreateInterventionDialog({ defaultValues, open, onClose }: ICreateInterventionDialog) {
    // form initialization
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm<InterventionType>({ mode: 'onBlur' })

    console.log({ errors, isDirty, isValid })

    const createIntervention = () => {
        const toast = new ToastHelper({
            errorMessage: 'Failed to create Intervention',
            successMessage: 'Intervention created successfully',
            loadingMessage: 'Creating Intervention...',
        })

        handleSubmit(
            //Valid
            (data) => {
                if (defaultValues) {
                    UpdateInterventionService(defaultValues.primaryKey, data)
                        .then((res) => {
                            if (res.success) {
                                toast.success()
                                onClose()
                            } else {
                                toast.fail()
                            }
                        })
                        .catch((e) => {
                            toast.fail()
                        })
                } else {
                    CreateInterventionService(data)
                        .then((res) => {
                            if (res.success) {
                                toast.success()
                                onClose()
                            } else {
                                toast.fail()
                            }
                        })
                        .catch((e) => {
                            toast.fail()
                        })
                }
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    useEffect(() => {
        if (open) {
            reset(
                defaultValues ?? {
                    primaryKey: undefined,
                    title: '',
                    description: '',
                    provider: '',
                    link: '',
                    deliveryMode: '',
                    type: '',
                    targetAudience: '',
                    cost: '',
                }
            )
        }
    }, [open])

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Register a new Identity Provider</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Title"
                                required
                                {...field}
                                error={!!errors.title}
                                helperText={errors.title?.message}
                            />
                        )}
                        rules={{
                            required: 'Title is required',
                            minLength: {
                                value: 3,
                                message: 'Title must be at least 3 characters',
                            },
                        }}
                    />

                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Description"
                                required
                                multiline
                                rows={4}
                                {...field}
                                error={!!errors.description}
                                helperText={errors.description?.message}
                            />
                        )}
                        rules={{
                            required: 'Description is required',
                            minLength: {
                                value: 3,
                                message: 'Description must be at least 3 characters',
                            },
                        }}
                    />

                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <BaseSelectWithLabel
                                label="Type"
                                options={['Support', 'Encourage', 'Monitor', 'Intervene']}
                                {...field}
                                error={!!errors.type}
                                helperText={errors.type?.message}
                            />
                        )}
                    />

                    <Controller
                        name="link"
                        control={control}
                        render={({ field }) => (
                            <BaseSelectWithLabel
                                label="Icon"
                                options={['Info', 'Check', 'Warning', 'Monitor']}
                                {...field}
                                error={!!errors.link}
                                helperText={errors.link?.message}
                            />
                        )}
                    />

                    <Controller
                        name="provider"
                        control={control}
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Provider"
                                {...field}
                                error={!!errors.provider}
                                helperText={errors.provider?.message}
                            />
                        )}
                    />

                    <Controller
                        name="deliveryMode"
                        control={control}
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Delivery Mode"
                                {...field}
                                error={!!errors.deliveryMode}
                                helperText={errors.deliveryMode?.message}
                            />
                        )}
                    />

                    <Controller
                        name="targetAudience"
                        control={control}
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Target Audience"
                                {...field}
                                error={!!errors.targetAudience}
                                helperText={errors.targetAudience?.message}
                            />
                        )}
                    />

                    <Controller
                        name="cost"
                        control={control}
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Cost"
                                {...field}
                                error={!!errors.cost}
                                helperText={errors.cost?.message}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton variant="outlined" color="warning" onClick={onClose}>
                    Cancel
                </BaseButton>
                <BaseButton
                    disabled={!isDirty || !isValid}
                    variant="contained"
                    color="primary"
                    onClick={createIntervention}
                >
                    {defaultValues ? 'Update' : 'Create'}
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
