import { responsiveFontSizes } from '@mui/material'
import { createTheme, PaletteOptions } from '@mui/material/styles'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import type {} from '@mui/lab/themeAugmentation'

// Use the fullscreen element if in fullscreen mode, otherwise just the document's body
function getTargetContainer() {
    return document.fullscreenElement ?? document.body
}

export type DirectionType = 'ltr' | 'rtl'

const COLOR_TOKENS = {
    white: '#FFFFFF',
    black: '#000000',
    // Silver (higher numbers decrease intensity and move towards the opposite color)
    silver_1: '#F8F8F8',
    silver_2: '#F4F3F3',
    silver_3: '#E9E9E9',
    silver_4: '#C4C4C4',
    // Slate (higher numbers decrease intensity and move towards the opposite color)
    slate_1: '#1A1D25',
    slate_2: '#222631',
    slate_3: '#2F333D',
    slate_4: '#414654',
}

declare module '@mui/material/styles/createPalette' {
    interface CommonColors {
        // Colors
        primaryDark: string
        primaryHover: string
        primaryActive: string
        secondaryDark: string
        secondaryHover: string
        secondaryActive: string
        // Fuschia
        fuschia60: string
        fuschia80: string
        fuschia100: string
        // Green
        green: string
        // Yellow
        yellow: string
        // Orange
        orange: string
        // Red
        red: string
        // Ungrouped
        ung_blue_one: string
        ung_blue_two: string
        ung_pink: string

        /*  Theme parts
            ========================================= */

        /*
         * Static
         */
        static_white: string
        static_black: string

        /*
         * Background (higher numbers decrease intensity and move towards the opposite color)
         */
        bg_1: string
        bg_2: string
        bg_3: string
        bg_4: string
        bg_5: string

        /*
         * Text (higher numbers decrease intensity and move towards the opposite color)
         */
        text_1: string
        text_2: string
        text_3: string
        text_4: string

        /*
         * Border (higher numbers decrease intensity and move towards the opposite color)
         */
        border_1: string
        border_2: string
        border_3: string
        border_4: string

        /*
         * Border (higher numbers decrease intensity and move towards the opposite color)
         */
        fill_1: string
        fill_2: string
        fill_3: string
        fill_4: string
    }
}

const palette: { [key: string]: PaletteOptions } = {
    light: {
        mode: 'light',
        primary: {
            main: '#217BBF',
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#58585A',
            // light: will be calculated from palette.secondary.main,
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#F178B6',
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#CC0938',
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
            contrastText: '#FFFFFF',
        },
        common: {
            // Colors
            primaryDark: '#185B8E',
            primaryHover: '#1C6FAE',
            primaryActive: '#1C6FAE',
            secondaryDark: '#C4C4C4',
            secondaryHover: '#C4C4C4',
            secondaryActive: '#BFBFC3',

            // Fuschia
            fuschia60: '#FCDDEC',
            fuschia80: '#F178B6',
            fuschia100: '#EF5DA8',

            // Green
            green: '#8FCC2B',
            // Yellow
            yellow: '#FFC90C',
            // Orange
            orange: '#FF720C',
            // Red
            red: '#FF1A0C',

            // Ungrouped
            ung_blue_one: '#E5F4FF',
            ung_blue_two: '#AEDCFF',
            ung_pink: '#F29FB3',

            /*  Theme parts
                ========================================= */

            /*
             * Static
             */
            static_white: COLOR_TOKENS.white,
            static_black: COLOR_TOKENS.black,

            /*
             * Background (higher numbers decrease intensity and move towards the opposite color)
             */
            bg_1: COLOR_TOKENS.white /* #FFFFFF */,
            bg_2: COLOR_TOKENS.silver_1 /* #F8F8F8 */,
            bg_3: COLOR_TOKENS.silver_2 /* #F4F3F3 */,
            bg_4: COLOR_TOKENS.silver_3 /* #E9E9E9 */,
            bg_5: COLOR_TOKENS.silver_4 /* #C4C4C4 */,

            /*
             * Text (higher numbers decrease intensity and move towards the opposite color)
             */
            text_1: COLOR_TOKENS.black /* #000000 */,
            text_2: COLOR_TOKENS.slate_4 /* #C4C4C4 */,
            text_3: COLOR_TOKENS.silver_4 /* #E9E9E9 */,
            text_4: COLOR_TOKENS.white /* #FFFFFF */,

            /*
             * Border (higher numbers decrease intensity and move towards the opposite color)
             */
            border_1: COLOR_TOKENS.black /* #000000 */,
            border_2: COLOR_TOKENS.silver_4 /* #C4C4C4 */,
            border_3: COLOR_TOKENS.silver_3 /* #E9E9E9 */,
            border_4: COLOR_TOKENS.white /* #FFFFFF */,

            /*
             * Border (higher numbers decrease intensity and move towards the opposite color)
             */
            fill_1: COLOR_TOKENS.black /* #000000 */,
            fill_2: COLOR_TOKENS.silver_4 /* #C4C4C4 */,
            fill_3: COLOR_TOKENS.silver_3 /* #E9E9E9 */,
            fill_4: COLOR_TOKENS.white /* #FFFFFF */,
        },
    },
    dark: {
        mode: 'dark',
        primary: {
            main: '#217BBF',
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#58585A',
            // light: will be calculated from palette.secondary.main,
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#F178B6',
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#CC0938',
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
            contrastText: '#FFFFFF',
        },
        common: {
            // Colors
            primaryDark: '#185B8E',
            primaryHover: '#1C6FAE',
            primaryActive: '#1C6FAE',
            secondaryDark: '#C4C4C4',
            secondaryHover: '#C4C4C4',
            secondaryActive: '#BFBFC3',

            // Fuschia
            fuschia60: '#FCDDEC',
            fuschia80: '#F178B6',
            fuschia100: '#EF5DA8',

            // Green
            green: '#8FCC2B',
            // Yellow
            yellow: '#FFC90C',
            // Orange
            orange: '#FF720C',
            // Red
            red: '#FF1A0C',
            // Black

            // Ungrouped
            ung_blue_one: '#E5F4FF',
            ung_blue_two: '#AEDCFF',
            ung_pink: '#F29FB3',

            /*  Theme parts
                ========================================= */

            /*
             * Static
             */
            static_white: COLOR_TOKENS.white,
            static_black: COLOR_TOKENS.black,

            /*
             * Background (higher numbers decrease intensity and move towards the opposite color)
             */
            bg_1: COLOR_TOKENS.slate_1 /* #1A1D25 */,
            // TODO: This bg_2 is an extra slot to match '5' shades of bg, same as the light theme.
            // add a color between slate_1 and slate_2 in here if needed.
            bg_2: COLOR_TOKENS.slate_1 /* #1A1D25 */,
            bg_3: COLOR_TOKENS.slate_2 /* #222631 */,
            bg_4: COLOR_TOKENS.slate_3 /* #2F333D */,
            bg_5: COLOR_TOKENS.slate_4 /* #414654 */,

            /*
             * Text (higher numbers decrease intensity and move towards the opposite color)
             */
            text_1: COLOR_TOKENS.white /* #FFFFFF */,
            text_2: COLOR_TOKENS.silver_4 /* #414654 */,
            text_3: COLOR_TOKENS.slate_4 /* #2F333D */,
            text_4: COLOR_TOKENS.slate_1 /* #1A1D25 */,

            /*
             * Border (higher numbers decrease intensity and move towards the opposite color)
             */
            border_1: COLOR_TOKENS.white /* #FFFFFF */,
            border_2: COLOR_TOKENS.slate_4 /* #414654 */,
            border_3: COLOR_TOKENS.slate_3 /* #2F333D */,
            border_4: COLOR_TOKENS.slate_1 /* #1A1D25 */,

            /*
             * Border (higher numbers decrease intensity and move towards the opposite color)
             */
            fill_1: COLOR_TOKENS.white /* #FFFFFF */,
            fill_2: COLOR_TOKENS.slate_4 /* #414654 */,
            fill_3: COLOR_TOKENS.slate_3 /* #2F333D */,
            fill_4: COLOR_TOKENS.slate_1 /* #1A1D25 */,
        },
    },
}

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xxl: true
    }
}

const getTheme = (mode: 'dark' | 'light' = 'light', direction: DirectionType = 'ltr', font = 'Poppins') =>
    responsiveFontSizes(
        createTheme({
            palette: palette[mode],
            components: {
                MuiUseMediaQuery: {
                    defaultProps: {
                        noSsr: true,
                    },
                },
                MuiMenu: {
                    defaultProps: {
                        container: getTargetContainer,
                    },
                },
                MuiPopper: {
                    defaultProps: {
                        container: getTargetContainer,
                    },
                },
                MuiTooltip: {
                    defaultProps: {
                        PopperProps: {
                            container: getTargetContainer,
                        },
                    },
                },
                // TODO: Do dialogs misbehave when called inside fullscreen elements? If so, test this code.
                // MuiDialog: {
                //     defaultProps: {
                //         container: getTargetContainer
                //     }
                // }
            },
            direction: direction,
            typography: {
                fontFamily: font,
                h1: {
                    fontSize: 40,
                    fontWeight: 600,
                },
                h2: {
                    fontSize: 34,
                    fontWeight: 600,
                },
                body1: {
                    fontSize: 16,
                    fontWeight: 400,
                },
                body2: {
                    fontSize: 14,
                    fontWeight: 400,
                },
                caption: {
                    fontSize: 12,
                    fontWeight: 400,
                },
                button: {
                    // Adding this value only affects the 'medium' sized buttons!
                    // Using the default scaling for now (13/14/15 - small/medium/large).
                    // fontSize: 16,
                    textTransform: 'none',
                },
            },
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1536,
                    xxl: 1920,
                },
            },
        })
    )

export default getTheme

const dark_survey_theme = `body {
    /* SurveyJS Creator V2 */
    --primary: #217bbf;
    --primary-light: rgba(26, 183, 250, 0.1);
    --foreground: #ededed;
    --primary-foreground: #ffffff;
    --secondary: #217bbf;
    --background: #2f333d;
    --background-dim: #222631;
    --background-dim-light: #414654;
  }
  `

const light_survey_theme = `body {
    /* SurveyJS Creator V2 */
    --primary: #217bbf;
    --primary-light: rgba(26, 183, 250, 0.8);
    --foreground: #000000;
    --primary-foreground: #ffffff;
    --secondary: #217bbf;
    --background: #ffffff;
    --background-dim: #f6f6f6;
    --background-dim-light: #f8f8f8;
    --background-for-editors: #EBEBEB;
  }
  .sd-item__decorator{
    background-color:#fbfbfb;
    box-shadow: inset 0px 0px 2px var(--border-inside, rgba(0, 0, 0, 0.7));
  }
  `

export const applySurveyTheme = (theme: 'dark' | 'light') => {
    const style = document.getElementById('survey-desigener-style')
    if (style) {
        if (theme === 'dark') {
            style.innerHTML = dark_survey_theme
        } else {
            style.innerHTML = light_survey_theme
        }
    }
}
