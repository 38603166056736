import Stack from '@mui/material/Stack'
import PollIcon from '@mui/icons-material/Poll'
import InventoryIcon from '@mui/icons-material/InventoryOutlined'
import MergeIcon from '@mui/icons-material/Merge'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import {
    NetworkVizContextType,
    useNetworkVizContext,
    useNetworkVizDispatch,
} from 'features/network-viz/context/NetworkVizContext'
import NetowkrInfoWrapperComponent from './components/NetowkrInfoWrapperComponent'
import NetworkAnalyticsInfoTop10 from './components/NetworkAnalyticsInfoTop10'
import NetworkAnalyticsInfo from './components/NetworkAnalyticsInfo'
import NetworkAnalyticsCumminutyInfo from './components/NetworkAnalyticsCumminutyInfo'
import NetworkLegendsContent from './components/NetworkLegendegendsContent'
import { SvgIcon } from '@mui/material'

import { ReactComponent as ErgmIcon } from 'assets/icons/ergm-icon.svg'
import { ReactComponent as AlaamIcon } from 'assets/icons/alaam-icon.svg'
import NetworkERGMInfo from './components/NetworkErgmInfo'
import NetworkALAAMInfo from './components/NetworkAlaamInfo'

import { NetworkItemIdType } from 'features/network-viz/types/NetworkViz.types'

export interface GraphActionProps {
    preview?: boolean
}

export default function NetworkAnalyticsContainer({ preview = false }: GraphActionProps) {
    const {
        analytics,
        legend,
        filters,
        nodes,
        selectedItem,
        analyticsSettings,
        nodeDataSchema,
        nodeRenders,
    } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const onSelectedNodeChange = (id: NetworkItemIdType) => {
        dispatchContext({ type: 'SELECTED_ITEM_CHANGE', payload: { mode: 'node', id } })
    }

    return (
        <Stack
            gap={1}
            sx={(theme) => ({
                alignSelf: 'flex-end',
                maxHeight: '100%',
            })}
        >
            <NetowkrInfoWrapperComponent
                icon={<PollIcon />}
                content={<NetworkAnalyticsInfo title="Graph Analytics" analytics={analytics} />}
            />

            {/* Top 10 */}
            <NetowkrInfoWrapperComponent
                icon={<SortByAlphaIcon />}
                content={
                    <NetworkAnalyticsInfoTop10
                        analytics={analytics}
                        filters={filters}
                        nodeDataSchema={nodeDataSchema}
                        nodeRenders={nodeRenders}
                        nodes={nodes}
                        onSelectedNodeChange={onSelectedNodeChange}
                        selectedItem={selectedItem}
                    />
                }
            />

            <NetowkrInfoWrapperComponent
                icon={<InventoryIcon />}
                content={
                    <NetworkAnalyticsCumminutyInfo
                        title="Strong Connected Component"
                        analytics={analytics}
                        mode="strongly_connected_components"
                    />
                }
            />

            <NetowkrInfoWrapperComponent
                icon={<MergeIcon />}
                content={
                    <NetworkAnalyticsCumminutyInfo
                        title="Weakly Connected Component"
                        analytics={analytics}
                        mode="weakly_connected_components"
                    />
                }
            />

            <NetowkrInfoWrapperComponent
                icon={<FileCopyIcon />}
                content={
                    <NetworkAnalyticsCumminutyInfo
                        title="Greedy Modularity Community"
                        analytics={analytics}
                        mode="greedy_modularity_communities"
                    />
                }
            />

            <NetowkrInfoWrapperComponent
                icon={<SvgIcon component={ErgmIcon} inheritViewBox />}
                content={<NetworkERGMInfo title="ERGM" analytics={analytics} />}
            />

            <NetowkrInfoWrapperComponent
                icon={<SvgIcon component={AlaamIcon} inheritViewBox />}
                content={
                    <NetworkALAAMInfo
                        title="ALAAM"
                        analytics={analytics}
                        alaamSettings={analyticsSettings.alaam}
                    />
                }
            />

            <NetowkrInfoWrapperComponent
                icon={<FormatListBulletedIcon />}
                content={<NetworkLegendsContent legendContent={legend} />}
            />
        </Stack>
    )
}
