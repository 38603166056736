export const frag = "precision mediump float;\n"
    + "\n"
    + "varying vec4 v_color;\n"
    + "varying vec4 vb_color;\n"
    + "varying float v_border;\n"
    + "varying float v_shadow;\n"
    + "\n"
    + "const float radius = 0.5;\n"
    + "\n"
    + "void main(void) {\n"
    + "  vec4 transparent = vec4(0.0, 0.0, 0.0, 0.0);\n"
    + "  vec4 shadowColor = vec4(0.0, 0.0, 0.0, 1.0);\n"
    + "  float distToCenter = length(gl_PointCoord - vec2(0.5, 0.5));\n"
    + "  float shadowStart = (radius - v_border) * (1.0 - v_shadow * .2);\n"
    + "  float halfRadius = 0.35 * (1.0 - v_shadow * .2);\n"
    + "\n"
    + "  float t = 0.0;\n"
    + "  if (distToCenter < halfRadius - v_border)\n"
    + "    gl_FragColor = v_color;\n"
    + "  else if (distToCenter < halfRadius)\n"
    + "    gl_FragColor = mix(vb_color, v_color, (halfRadius - distToCenter) / v_border);\n"
    + "  else if (distToCenter < shadowStart)\n"
    + "    gl_FragColor = vb_color;\n"
    + "  else if (distToCenter < radius)\n"
    + "    if (v_shadow == 1.0)\n"
    + "         gl_FragColor = mix(transparent, shadowColor, (1.0 - (distToCenter-shadowStart)/(radius-shadowStart))/1.2 );"
    + "     else\n"
    + "         gl_FragColor = mix(transparent, vb_color, (radius - distToCenter) / v_border);\n"
    + "  else\n"
    + "    gl_FragColor = transparent;\n"
    + "}";


export const vert = "attribute vec2 a_position;\n" +
    "attribute float a_size;\n" +
    "attribute vec4 a_color;\n" +
    "attribute vec4 b_color;\n" +
    "attribute float shadow;\n" +
    "\n" +
    "uniform float u_ratio;\n" +
    "uniform float u_scale;\n" +
    "uniform mat3 u_matrix;\n" +
    "\n" +
    "varying vec4 v_color;\n" +
    "varying vec4 vb_color;\n" +
    "varying float v_border;\n" +
    "varying float v_shadow;\n" +
    "\n" +
    "const float bias = 255.0 / 254.0;\n" +
    "\n" +
    "void main() {\n" +
    "  gl_Position = vec4(\n" +
    "    (u_matrix * vec3(a_position, 1)).xy,\n" +
    "    0,\n" +
    "    1\n" +
    "  );\n" +
    "\n" +
    "  // Multiply the point size twice:\n" +
    "  //  - x SCALING_RATIO to correct the canvas scaling\n" +
    "  //  - x 2 to correct the formulae\n" +
    "  gl_PointSize = a_size * u_ratio * u_scale * 2.0;\n" +
    "\n" +
    "  v_border = (1.0 / u_ratio) * (0.5 / a_size);\n" +
    "\n" +
    "  // Extract shadow:\n" +
    "  v_shadow = shadow;\n" +
    "  // Extract the color:\n" +
    "  v_color = a_color;\n" +
    "  v_color.a *= bias;\n" +
    "  vb_color = b_color;\n" +
    "  vb_color.a *= bias;\n" +
    "}";