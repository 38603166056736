//* ======= Libraries
import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import WithErrorBoundary from 'components/wrappers/WithErrorBoundary'
//* ======= Custom logic
import NetworkVizContext from 'features/network-viz/context/NetworkVizContext'
//* ======= Assets and styles
import NetworkVizDesigner from 'features/network-viz/NetworkVizDesigner'

export interface NetworkVizDesignerPageProps {}

export default function NetworkVizDesignerPage({}: NetworkVizDesignerPageProps) {
    const location = useLocation()
    const currentPathRef = useRef<string>(location.pathname)

    return (
        <PageCard>
            <PageCard.Body noPaddingRight>
                <WithErrorBoundary
                    errorRecovery={{
                        type: 'navigate',
                        path: currentPathRef.current,
                    }}
                >
                    <NetworkVizContext>
                        <NetworkVizDesigner />
                    </NetworkVizContext>
                </WithErrorBoundary>
            </PageCard.Body>
        </PageCard>
    )
}
