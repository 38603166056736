//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { GridColDef } from '@mui/x-data-grid-pro'
import {
    Stack,
    Box,
    Avatar,
    Tab,
    Tabs,
    Typography,
    Link,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
//* ======= Components and features
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseButton from 'components/base/BaseButton'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
//* ======= Custom logic
import {
    ReportDataSourceDataType,
    ReportNetworkDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
import { GetSurveysService, SurveyBasicType } from 'services/SurveyService'
import { GetWidget, GetWidgets, WidgetType } from 'services/WidgetApi'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
//* ======= Assets and styles

const SampleCsv = require('assets/sample/report.csv')

type Props = {
    open: boolean
    datasourceId: string
    onClose: () => void
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function AddReportDataSourceDialog({ datasourceId, open, onClose }: Props) {
    const { pid } = useParams()

    const addDataSource = useReportStore((store) => store.addDataSource)

    const [selectedTab, setSelectedTab] = useState(0)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [hasData, setHasData] = useState<boolean>(false)
    const [headers, setHeaders] = useState<string[]>([])
    const [title, setTitle] = useState<string>('')
    const [rows, setRows] = useState<Array<{ [key: string]: any }>>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [idField, setIdField] = useState<string>('auto')
    const [panelField, setPanelField] = useState<string>('none')
    const [surveys, setSurveys] = useState<SurveyBasicType[] | null>(null)
    const [selectedSurvey, setSelectedSurvey] = useState<SurveyBasicType | null>(null)
    const [networkVizOptions, setNetworkVizOptions] = useState<WidgetType[] | null>(null)
    const [selectedNetwork, setSelectedNetwork] = useState<WidgetType | null>(null)

    //on load, fectch the surveys
    useEffect(() => {
        if (pid != null) {
            fetchSurveys(pid)
            getnetworkViz(pid)
        } else {
            setSurveys([])
        }
    }, [pid])

    // fetch surveys from server
    const fetchSurveys = async (pid: string) => {
        try {
            var res = await GetSurveysService(pid)
            if (res.success) {
                setSurveys(res.data)
                return
            }
        } catch (e) {}
        setSurveys([])
    }

    // fetch surveys from server
    const getnetworkViz = async (pid: string) => {
        try {
            var res = await GetWidgets(pid)
            if (res.success) {
                setNetworkVizOptions(res.data)
                return
            }
        } catch (e) {}
        setNetworkVizOptions([])
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue)
    }

    const onCloseHandler = () => {
        setColumns([])
        setHeaders([])
        setSelectedNetwork(null)
        setHasData(false)
        setIdField('auto')
        setRows([])
        setTitle('')
        setPanelField('')
        setSelectedSurvey(null)
        onClose()
    }

    // read data from file
    function uploadAndParseFile(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files.length > 0) {
            setLoading(true)
            const worker = new WebWorker('workers/report/parse-data-worker.js')
            worker
                .run({ fileName: event.target.files[0] })
                .then((res: any) => {
                    const { data, headers } = res
                    setColumns([...headers])
                    setHeaders(headers.map((h: any) => h.field))
                    setHasData(true)
                    setRows(data)
                })
                .catch((e) => {
                    toast.error('Failed to load the selected file.')
                })
                .finally(() => setLoading(false))
        }
    }

    // add data set
    const handleAddDataSetClick = () => {
        switch (selectedTab) {
            case 0:
                addDataSetFromFile()
                break
            case 1:
                if (selectedSurvey) addDatasetFromSurvey()
                break
            case 2:
                if (selectedNetwork) addDatasetFromNetwork()
                break
        }
    }

    const addDataSetFromFile = () => {
        const worker = new WebWorker<ReportDataSourceDataType>('workers/report/add-data-source-worker.js')

        setLoading(true)

        worker
            .run({ data: rows, idField, panelField })
            .then((data) => {
                addDataSource({
                    dataSource: {
                        id: datasourceId,
                        mode: 'static',
                        title: title,
                        data: data,
                        fields: columns.map((x) => x.field),
                    },
                })

                onCloseHandler()
            })
            .catch((e) => {
                toast.error('Cannot add the dataset.')
            })
            .finally(() => setLoading(false))
    }

    const addDatasetFromSurvey = () => {
        onCloseHandler()
    }

    const addDatasetFromNetwork = async () => {
        if (selectedNetwork === null) return
        try {
            const response = await fetchNetworkDataSource(pid!, selectedNetwork.primaryKey!)

            addDataSource({
                dataSource: {
                    id: datasourceId,
                    ...response,
                    data: {},
                    fields: [],
                },
            })

            onCloseHandler()
        } catch (e) {
            toast.error('Cannot load the selected Network Viz Widget')
        }
    }

    //Check if the add button is enabled
    const addEnabled = useMemo(() => {
        if (selectedTab === 0 && (title.length < 2 || !hasData)) return false
        if (selectedTab === 1 && selectedSurvey === null) return false
        if (selectedTab === 2 && selectedNetwork === null) return false
        return true
    }, [title, hasData, selectedTab, selectedSurvey, selectedNetwork])

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>Add Datasource</DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="data-source tab">
                        <Tab label="From File" {...a11yProps(0)} />
                        <Tab disabled={true} label="From Surveys" {...a11yProps(1)} />
                        <Tab label="From Networks" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                {/* Upload from files
                 */}

                {/* Upload and download buttons
				    ========================================= */}
                {selectedTab === 0 && (
                    <Stack direction="column" height="90%">
                        <Stack direction="row" alignItems="center" gap={2} marginBottom={1}>
                            <BaseButton
                                variant="contained"
                                size="large"
                                label="Select File"
                                onClick={() => {
                                    document.getElementById('select-node-csv')?.click()
                                }}
                            />
                            <input
                                id="select-node-csv"
                                type="file"
                                accept=".csv, .txt"
                                onChange={uploadAndParseFile}
                                style={{ display: 'none' }}
                            />
                            <Link href={SampleCsv} target="_blank">
                                Download sample CSV file
                            </Link>
                        </Stack>
                        {hasData && (
                            <Stack flexGrow={1}>
                                <Stack direction="row" spacing={2} width="100%">
                                    <BaseFilledTextField
                                        sx={{ flex: 0.33 }}
                                        label="Dataset Title"
                                        required={true}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    <BaseSelectWithLabel
                                        sx={{ flex: 0.33 }}
                                        label="ID"
                                        helperText="If your data has an identification field for each subject, please select that field"
                                        required={true}
                                        value={idField}
                                        onChange={(v) => setIdField(v)}
                                        options={[{ label: 'Auto Generate', value: 'auto' }, ...headers]}
                                    />

                                    <BaseSelectWithLabel
                                        sx={{ flex: 0.33 }}
                                        label="Panel Field"
                                        helperText="If there are more than one measurement per each subject, please slect the field that indicate the panel"
                                        value={panelField}
                                        onChange={(v) => setPanelField(v)}
                                        options={[{ label: 'NA', value: 'none' }, ...headers]}
                                    />
                                </Stack>
                                <StyledDataGrid
                                    columns={columns}
                                    rows={rows}
                                    density="compact"
                                    getRowId={(r) => r._index}
                                />
                            </Stack>
                        )}
                    </Stack>
                )}

                {selectedTab === 1 && (
                    <Stack direction="column" height="90%" mt={2}>
                        {surveys === null ? (
                            <SnaCircularLoading />
                        ) : surveys.length === 0 ? (
                            <Typography variant="h5">
                                This project does not have any survey, or you do not have access to any of them.
                            </Typography>
                        ) : (
                            <List>
                                {surveys.map((survey) => (
                                    <ListItemButton
                                        key={survey.id}
                                        selected={selectedSurvey?.id === survey.id}
                                        onClick={() => setSelectedSurvey(survey)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar src={survey.thumbnail} variant="rounded">
                                                {buildAbbreviation(survey.title)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={survey.title}
                                            secondary={'Created by ' + survey.creator?.name}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        )}
                    </Stack>
                )}

                {selectedTab === 2 && (
                    <Stack direction="column" height="90%" mt={2}>
                        {networkVizOptions === null ? (
                            <SnaCircularLoading />
                        ) : networkVizOptions.length === 0 ? (
                            <Typography variant="h5">
                                This project does not have any Network Viz, or you do not have access to any of them.
                            </Typography>
                        ) : (
                            <List>
                                {networkVizOptions.map((nv) => (
                                    <ListItemButton
                                        key={nv.primaryKey}
                                        selected={selectedNetwork?.primaryKey === nv.primaryKey}
                                        onClick={() => setSelectedNetwork(nv)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar variant="rounded">{buildAbbreviation(nv.title)}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={nv.title}
                                            secondary={'Created by ' + nv.createdBy?.name}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        )}
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onCloseHandler} color="warning" variant="outlined">
                    Close
                </BaseButton>
                <BaseButton
                    onClick={handleAddDataSetClick}
                    color="primary"
                    variant="contained"
                    disabled={!addEnabled || isLoading}
                >
                    Add
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export const fetchNetworkDataSource = async (
    projectId: number | string,
    networkId: number
): Promise<Pick<ReportNetworkDataSourceType, 'title' | 'mode' | 'networkContext' | 'networkVizId' | 'presets'>> => {
    const response = await GetWidget(projectId, networkId)
    if (!response.success) throw new Error('bad response')

    const worker = new WebWorker('workers/network/zip-worker.js')

    const data = (await worker.run({
        mode: 'un-zip',
        data: response.data.state,
    })) as any

    let state: NetworkVizContextType

    if (data.version) {
        state = data.state
    } else {
        state = data
    }

    const presets: ReportNetworkDataSourceType['presets'] = {
        default: {
            analytics: state.analytics,
            filters: state.filters,
            nodeDataSchema: state.nodeDataSchema,
            edgeStyle: state.edgeStyle,
            nodeStyle: state.nodeStyle,
            nodeGroupBy: state.nodeGroupBy,
            nodeRenders: state.nodeRenders,
            edgeRenders: state.edgeRenders,
            edgeDataSchema: state.edgeDataSchema,
            nodeAdjacentList: state.nodeAdjacentList,
            networkShrink: state.networkShrink,
            dimOneWayEdges: state.dimOneWayEdges,
            highlightReciprocatedEdges: state.highlightReciprocatedEdges,
            keyFrames: state.keyFrames,
            onlyShowReciprocatedEdges: state.onlyShowReciprocatedEdges,
        },
    }

    for (let presetKey in state.presets) {
        const presetWorker = new WebWorker('workers/network/zip-worker.js')
        const presetContext = (await presetWorker.run({
            mode: 'un-zip',
            data: state.presets[presetKey],
        })) as Partial<NetworkVizContextType>
        presets[presetKey] = {
            analytics: presetContext.analytics!,
            filters: presetContext.filters!,
            nodeDataSchema: presetContext.nodeDataSchema!,
            nodeRenders: presetContext.nodeRenders!,
            nodeStyle: presetContext.nodeStyle!,
            edgeStyle: presetContext.edgeStyle!,
            nodeGroupBy: presetContext.nodeGroupBy!,
            edgeRenders: presetContext.edgeRenders!,
            edgeDataSchema: presetContext.edgeDataSchema!,
            nodeAdjacentList: presetContext.nodeAdjacentList!,
            networkShrink: presetContext.networkShrink!,
            dimOneWayEdges: presetContext.dimOneWayEdges!,
            highlightReciprocatedEdges: presetContext.highlightReciprocatedEdges!,
            keyFrames: presetContext.keyFrames!,
            onlyShowReciprocatedEdges: presetContext.onlyShowReciprocatedEdges!,
        }
    }

    return {
        mode: 'network',
        title: response.data.title,
        networkVizId: response.data.primaryKey!,
        networkContext: state,
        presets,
    }
}
