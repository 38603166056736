//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import Color from 'color'
import { GridColDef, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { Avatar, Chip, Stack } from '@mui/material'
//* ======= Components and features
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
//* ======= Custom logic
import { ReportSlideType, ReportWidgetType, TableWidgetType } from 'features/report-designer/types/reportDesigner.types'
import {
    TableConfigGridView,
    TableParsedDataRow,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import { ParsedTableDataRowTiesNode } from 'features/report-designer/widgets/table-widget/helpers/TableWidget.helper'
import TableNodeChip from './TableNodeChip'
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
//* ======= Assets and styles

type GridDataRowType = {
    id: string | number
    _internal_id: string
    [key: string]: TableParsedDataRow['cells'][any] | string | number
}

type GridDataState = {
    columns: GridColDef[]
    rows: GridDataRowType[]
}

type Props = {
    widgetId?: ReportWidgetType['id']
    slideId?: ReportSlideType['id'] | null
    parsedData: TableWidgetType['parsedData']
    config: TableConfigGridView
    selectedRowId: TableWidgetType['selectedRowId']
    onSelectedRowChange?: (id: TableWidgetType['selectedRowId']) => void
    viewMode: ReportDesignerStoreStateType['viewMode']
    isActive: boolean
    fontSizeScaleFactor: number
    onReady?: () => void
}

function TableWidgetGridView({
    widgetId,
    slideId,
    parsedData,
    config,
    selectedRowId,
    onReady,
    isActive,
    viewMode,
    onSelectedRowChange,
    fontSizeScaleFactor,
}: Props) {
    const { updateTableWidgetConfig } = useReportStore((store) => ({
        updateTableWidgetConfig: store.updateTableWidgetConfig,
    }))

    const [gridData, setGridData] = useState<GridDataState>({ columns: [], rows: [] })

    const [gridRowSelectionModel, setGridRowSelectionModel] = useState<GridRowSelectionModel>([])

    const apiRef = useGridApiRef()

    const fontSize = useMemo(() => {
        return fontSizeScaleFactor * config.fontStyles.fontSize
    }, [fontSizeScaleFactor, config.fontStyles.fontSize])

    const generateGridData = (parsedData: TableWidgetType['parsedData']): GridDataState => {
        if (parsedData === null) {
            return {
                columns: [],
                rows: [],
            }
        }

        const gridColumns: GridColDef[] = []

        for (const _column of parsedData.columns) {
            const width = config.columnsWidth[_column.field] ?? undefined
            switch (_column.fieldType) {
                case 'avatar':
                    gridColumns.push({
                        field: _column.field,
                        headerName: _column.title,
                        width,
                        type: _column.type,
                        renderCell: ({ value }) => (
                            <Avatar
                                src={value}
                                sx={{
                                    height: '95%',
                                    objectFit: 'contain',
                                }}
                            />
                        ),
                    })

                    break

                case 'list':
                    gridColumns.push({
                        field: _column.field,
                        headerName: _column.title,
                        width,
                        type: _column.type,
                        renderCell: ({ row, value }) => {
                            return (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    flexWrap="wrap"
                                    gap={1}
                                    sx={(theme) => ({
                                        width: '100%',
                                        minWidth: 0,
                                        paddingY: theme.spacing(1),
                                    })}
                                >
                                    {Array.isArray(value?.value) && value.value.length > 0 ? (
                                        value.value.map((_node: ParsedTableDataRowTiesNode) => (
                                            <TableNodeChip
                                                color={_node.color ?? '#eeeeee'}
                                                name={_node.nodeLabel + '' ?? `Node ${_node.id}`}
                                                selectedNode={row.nodeLabel}
                                                tooltip={_node.tooltip}
                                                fontSize={fontSize}
                                                fontFamily={config.fontStyles.fontFamily}
                                                mode={
                                                    _node.relationship === 'in'
                                                        ? 'in'
                                                        : _node.relationship === 'out'
                                                        ? 'out'
                                                        : 'both'
                                                }
                                                key={_node.id}
                                            />
                                        ))
                                    ) : (
                                        <>None</>
                                    )}
                                </Stack>
                            )
                        },
                    })

                    break

                case 'primary':
                case 'secondary':
                case 'dynamic':
                    gridColumns.push({
                        field: _column.field,
                        // TODO: We need to move descriptions to an outside floating button on the
                        //       bottom right side of the table which shows a popover menu, listing
                        //       all the columns marked for description.
                        //       This floating button should apply to both grid and card view.
                        headerName:
                            _column.description !== undefined && _column.description.trim() !== ''
                                ? '*' + _column.title
                                : _column.title,
                        type: _column.type,
                        width,
                        valueGetter: (params) => {
                            return params.value?.value
                        },
                        description: _column.description,
                        headerAlign: 'left',
                        align: 'left',
                    })

                    break

                default:
                    break
            }
        }

        const gridRows = parsedData.rows.map((_row) => {
            const { cells, id, _internal_id } = _row
            const data = {
                id,
                _internal_id,
                ...cells,
            }
            return data
        })

        return {
            columns: gridColumns,
            rows: gridRows,
        }
    }

    useEffect(() => {
        if (apiRef.current !== null) {
            onReady && onReady()
        }
    }, [apiRef])

    // Update grid data only if "parsedData" changes.
    useEffect(() => {
        const newGridData = generateGridData(parsedData)

        setGridData({
            columns: [...newGridData.columns],
            rows: [...newGridData.rows],
        })
    }, [parsedData, config.columnsWidth])

    // If selected row changes, update the table selected row and scroll to that row
    useEffect(() => {
        if (selectedRowId !== null) {
            const targetRow = gridData.rows.find((_row) => _row._internal_id === selectedRowId)

            // If grid data contains the selected row
            if (targetRow !== undefined) {
                // If a row was already selected
                if (gridRowSelectionModel.length !== 0) {
                    // gridRowSelectionModel state always contains exactly ONE element since
                    // we have disabled multi row selection for the grid.
                    const currentSelectedGridRowId = gridRowSelectionModel[0]

                    // If the store state selected row and grid selection are different, update the grid selection model state
                    // and scroll to that row.
                    if (currentSelectedGridRowId !== targetRow.id) {
                        setGridRowSelectionModel([targetRow.id])

                        // This check prevents an error that happens if you switch from Card view with a selected row
                        // into Grid view, and the "apiRef" is still not initialized with the right value.
                        if (apiRef.current !== null) {
                            const targetRowIndex = gridData.rows.findIndex((_row) => _row.id === targetRow.id)

                            apiRef.current.scrollToIndexes({
                                rowIndex: targetRowIndex,
                            })
                        }
                    } else {
                        // The store state selected row and grid selection are the same. Do nothing.
                    }
                }
                // If there were no selected rows before, update the grid selection model state
                // and scroll to that row.
                else {
                    setGridRowSelectionModel([targetRow.id])

                    // This check prevents an error that happens if you switch from Card view with a selected row
                    // into Grid view, and the "apiRef" is still not initialized with the right value.
                    if (apiRef.current !== null) {
                        const targetRowIndex = gridData.rows.findIndex((_row) => _row.id === targetRow.id)

                        apiRef.current.scrollToIndexes({
                            rowIndex: targetRowIndex,
                        })
                    }
                }
            }
            // If the selected row doesn't exist in grid data
            else {
                // Since the selected row is invalid for current grid data, if any rows were selected before,
                // we clear selection.
                if (gridRowSelectionModel.length !== 0) {
                    setGridRowSelectionModel([])
                }
            }
        }
        // No selected row
        else {
            if (gridRowSelectionModel.length !== 0) {
                setGridRowSelectionModel([])
            }
        }
    }, [selectedRowId, gridData.rows])

    return (
        <Stack
            sx={(theme) => ({
                height: '100%',
                minHeight: 0,
                padding: theme.spacing(1),
            })}
        >
            <StyledDataGrid
                {...gridData}
                apiRef={apiRef}
                getRowId={(row: TableParsedDataRow) => row.id}
                rowSelectionModel={gridRowSelectionModel}
                onRowSelectionModelChange={(model) => {
                    if (model.length === 0) {
                        setGridRowSelectionModel([])

                        onSelectedRowChange && onSelectedRowChange(null)
                    } else {
                        const currentSelectedRowId = model[0]
                        const stateSelectedRowId = gridRowSelectionModel[0]

                        if (currentSelectedRowId === stateSelectedRowId) {
                            setGridRowSelectionModel([])

                            onSelectedRowChange && onSelectedRowChange(null)
                        } else {
                            setGridRowSelectionModel([currentSelectedRowId])

                            const targetRow = gridData.rows.find((_row) => _row.id === currentSelectedRowId)!

                            onSelectedRowChange && onSelectedRowChange(targetRow._internal_id)
                        }
                    }
                }}
                // Selecting multiple rows doesn't make sense when we connect widgets together by one selected node.
                disableMultipleRowSelection={true}
                getRowHeight={({ model }) => {
                    // If even one cell in a row has a list value, then we allow the row to
                    // expand its height based on the content.
                    for (const [_cellKey, _cellValue] of Object.entries(model)) {
                        if (Array.isArray(_cellValue.value)) {
                            return 'auto'
                        }
                    }

                    return null
                }}
                density="compact"
                hasSearch={config.hasSearch}
                hasHeader={config.hasHeader}
                hasToolbar={false}
                onColumnWidthChange={(params) => {
                    if (widgetId !== undefined && slideId != null && viewMode === 'design') {
                        const newColumnWidth = params.width
                        const tmpConfig = structuredClone(config)
                        tmpConfig.columnsWidth[params.colDef.field] = newColumnWidth
                        updateTableWidgetConfig({
                            slideId: slideId,
                            widgetId: widgetId,
                            data: tmpConfig,
                        })
                    }
                }}
                disableColumnResize={!isActive}
                hideFooter
                rowHeight={fontSize * 3}
                sx={{
                    fontSize: fontSize,
                    fontFamily: config.fontStyles.fontFamily,
                }}
            />
        </Stack>
    )
}

export default TableWidgetGridView
