//* ======= Libraries
import React, { useState, FocusEvent } from 'react'
//* ======= Components and features
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
//* ======= Assets and styles

type Props = {
    label: string
    defaultValue: string
    onChange: (validatedUrl: string) => void
    size?: 'small' | 'medium'
    fullWidth?: boolean
}

function AbsoluteUrlInput({ label, defaultValue, onChange, size = 'small', fullWidth = true }: Props) {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const validateUrl = (url: string) => {
        try {
            const urlObj = new URL(url) // Throws an error for invalid URLs
            if (urlObj.protocol === 'http:' || urlObj.protocol === 'https:') {
                return true
            }
        } catch {}
        return false
    }

    const onBlurHandler = (evt: FocusEvent<HTMLInputElement>) => {
        const enteredUrl = evt.target.value.trim()

        if (validateUrl(enteredUrl)) {
            setErrorMessage(null)

            onChange(enteredUrl)
        } else {
            setErrorMessage('Please enter an absolute URL starting with "http://" or "https://".')

            onChange('')
        }
    }

    return (
        <BaseFilledTextField
            label={label}
            defaultValue={defaultValue}
            onBlur={onBlurHandler}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            placeholder="https://example.com"
            size={size}
            fullWidth={fullWidth}
        />
    )
}

export default AbsoluteUrlInput
