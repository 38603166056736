//* ======= Libraries
import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ToastHelper from 'helpers/ToastHelper'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { GridColDef } from '@mui/x-data-grid-pro'
import BaseButton from 'components/base/BaseButton'
import { Chip, DialogContent, IconButton, Tooltip } from '@mui/material'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import {
    AccessCondition,
    AddProjectDataAccess,
    AddProjectDataAccessBatchService,
    CreateProjectAccount,
    DeleteProjectDataAccess,
    GetProjectAccounts,
    GetProjectDataAccess,
    GetProjectRoles,
    ProjectAccountCreateType,
    ProjectAccountType,
    ProjectDataAcessType,
    ProjectRoleWithPermissionType,
    RemoveProjectAccount,
    ResendProjectAccountInvitationService,
    UpdateProjectAccountRoleService,
    UpdateProjectDataAccess,
} from 'services/ProjectService'
import {
    CreateReportCustomViewService,
    DeleteReportCustomViewService,
    UpdateReportCustomViewService,
} from 'services/ReportApi'
import {
    GetNodeOtherFiledsWithUniqueValuesService,
    NodeOtherFiledsWithUniqueValuesType,
} from 'services/NodeService'
import { RootContext } from 'contexts/RootContext'
import StyledDialog from 'components/dialog/StyledDialog'
import AddUsersDialog from 'features/add-users-dialog/AddUsersDialog'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import ConfirmDialog from 'features/confirm-dialog/ConfirmDialog'
import { ProjectContext } from 'contexts/ProjectContext'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { set } from 'lodash'

function ProjectDataAccessManagment() {
    const { pid } = useParams()
    const [customViews, setCustomViews] = useState<ProjectDataAcessType[] | null>(null)
    const [selectedCustomView, setSelectedCustomView] = useState<number | null>(null)
    const [otherFields, setOtherFields] = useState<NodeOtherFiledsWithUniqueValuesType[]>([])
    const [item2Remove, setItem2Remove] = useState<number | null>(null)
    const [showWizardDialog, setShowWizardDialog] = useState(false)

    useEffect(() => {
        if (pid == null) return
        GetNodeOtherFiledsWithUniqueValuesService(pid).then((response) => {
            if (response.success) {
                setOtherFields(response.data)
            }
        })
        GetProjectDataAccess(pid).then((response) => {
            if (response.success) {
                setCustomViews(response.data)
            }
        })
    }, [pid])

    if (customViews === null) return <SnaCircularLoading />

    const onEnterCustomView = (viewIdx: number | null = null) => {
        setSelectedCustomView(viewIdx)
    }

    const onCreateCustomView = async (data: ProjectDataAcessType) => {
        const toast = new ToastHelper({
            successMessage: 'Custom view created successfully',
            errorMessage: 'Cannot create the custom view',
            loadingMessage: 'Creating the custom view...',
        })
        if (selectedCustomView === -1) {
            // * create
            try {
                const response = await AddProjectDataAccess(pid!, data)
                if (response.success) {
                    setCustomViews(response.data)
                    setSelectedCustomView(null)
                    toast.success()
                    return
                }
            } catch {}
            toast.fail()
            return
        }
        // * update
        else {
            try {
                const response = await UpdateProjectDataAccess(pid!, data.primaryKey, data)
                if (response.success) {
                    setCustomViews((pv) => {
                        const tmp = [...pv!]
                        tmp[selectedCustomView!] = data
                        return tmp
                    })
                    setSelectedCustomView(null)
                    toast.success()
                    return
                }
            } catch {}
            toast.fail()
            return
        }
    }

    const onCreateWizardCustomView = async (dataAccesses: ProjectDataAcessType[]) => {
        const toast = new ToastHelper({
            successMessage: 'Custom views created successfully',
            errorMessage: 'Cannot create the custom views',
            loadingMessage: 'Creating the custom views...',
        })
        try {
            const response = await AddProjectDataAccessBatchService(pid!, dataAccesses)
            if (response.success) {
                setCustomViews(response.data)
                setShowWizardDialog(false)
                toast.success()
                return
            }
        } catch {}
        toast.fail()
    }

    const onDeleteCustomView = async () => {
        if (item2Remove === null) return
        const toast = new ToastHelper({
            errorMessage: 'Cannot delete the custom view',
            loadingMessage: 'Deleting the custom view...',
            successMessage: 'Custom view deleted successfully',
        })
        try {
            const response = await DeleteProjectDataAccess(pid!, item2Remove)
            if (response.success) {
                toast.success()
                setCustomViews((pv) => pv!.filter((x) => x.primaryKey !== item2Remove))
                setItem2Remove(null)
                return
            }
        } catch {}
        toast.fail()
        return
    }

    return (
        <Box>
            {/* Custom views header
                    ========================================= */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    paddingY: theme.spacing(1),
                    marginBottom: theme.spacing(2),

                    borderBottom: `1px solid ${theme.palette.common.border_2}`,
                })}
            >
                <Typography fontSize={18} fontWeight={600} color="primary">
                    Custom Views
                </Typography>

                <BaseButton
                    sx={{ marginLeft: 'auto' }}
                    label="Create Multiple"
                    onClick={(evt) => setShowWizardDialog(true)}
                    startIcon={<AddIcon />}
                />
                <BaseButton
                    label="Create New"
                    onClick={(evt) => onEnterCustomView(-1)}
                    startIcon={<AddIcon />}
                />
            </Stack>

            {/* Custom views list
                    ========================================= */}
            <Stack gap={1}>
                {customViews.length > 0 ? (
                    /*  Custom view item
                            ========================================= */
                    customViews.map((_view, idx) => (
                        <Stack
                            key={_view.title}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={2}
                        >
                            {/* View title
                                    ========================================= */}
                            <Typography
                                fontSize={18}
                                color="common.text_1"
                                noWrap
                                sx={{
                                    flex: '1 0 0',
                                }}
                            >
                                {_view.title}
                            </Typography>

                            {/* Controls
                                    ========================================= */}
                            <Stack direction="row" alignItems="center" gap={2}>
                                {/* Edit view button
                                        ========================================= */}
                                <BaseButton
                                    label="Edit"
                                    onClick={() => onEnterCustomView(idx)}
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    sx={(theme) => ({
                                        paddingX: theme.spacing(3),
                                    })}
                                />

                                {/* Remove view button
                                        ========================================= */}
                                <Tooltip title="Delete Custom View">
                                    <IconButton
                                        onClick={(evt) => setItem2Remove(_view.primaryKey)}
                                        sx={(theme) => ({
                                            flexShrink: 0,

                                            color: theme.palette.common.ung_pink,
                                        })}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    ))
                ) : (
                    /*  Empty list message
                            ========================================= */
                    <Typography fontSize={14} textAlign="center">
                        (No custom views set.)
                    </Typography>
                )}
            </Stack>

            {/* Create Wizard view dialog
                    ========================================= */}
            <StyledDialog
                open={showWizardDialog}
                fullWidth
                maxWidth="lg"
                onClose={() => setShowWizardDialog(false)}
            >
                <DialogContent>
                    <CreateWizrdDataAccessDialog
                        fields={otherFields}
                        onCreate={onCreateWizardCustomView}
                        onDiscard={() => setShowWizardDialog(false)}
                    />
                </DialogContent>
            </StyledDialog>

            {/* Custom view dialog
                    ========================================= */}
            <StyledDialog
                open={selectedCustomView !== null}
                fullWidth
                maxWidth="lg"
                onClose={() => onEnterCustomView()}
            >
                <DialogContent>
                    <CustomDataAccessDialog
                        defaultData={
                            selectedCustomView === null || selectedCustomView === -1
                                ? null
                                : customViews[selectedCustomView]
                        }
                        fields={otherFields}
                        onCreate={onCreateCustomView}
                        onDiscard={() => onEnterCustomView()}
                    />
                </DialogContent>
            </StyledDialog>

            {/* Delete custom view dialog
             */}
            <ConfirmDialog
                title="Delete Custom View"
                content="Are you sure you want to remove the selected custom view?"
                actionLabel="Delete View"
                closeLabel="Cancel"
                open={item2Remove != null}
                onClose={() => setItem2Remove(null)}
                onConfirm={onDeleteCustomView}
            />
        </Box>
    )
}

type CustomDataAccessDialogProps = {
    defaultData: ProjectDataAcessType | null
    fields: NodeOtherFiledsWithUniqueValuesType[]
    onCreate: (data: ProjectDataAcessType) => void
    onDiscard: () => void
}

function CustomDataAccessDialog({ fields, onDiscard, defaultData, onCreate }: CustomDataAccessDialogProps) {
    const [data, setData] = useState<ProjectDataAcessType>(
        defaultData || {
            primaryKey: -1,
            title: '',
            deIdentified: false,
            accessConditions: [],
        }
    )

    // * filters manipulation

    const addFilterItems = () => {
        setData((pv) => ({
            ...pv,
            accessConditions: [
                ...pv.accessConditions,
                {
                    field: '',
                    values: [],
                },
            ],
        }))
    }

    const removeFilterItems = (idx: number) => () => {
        setData((pv) => {
            const tmp = { ...pv }
            tmp.accessConditions.splice(idx, 1)
            return tmp
        })
    }

    const updateFilterItems = (idx: number, field: keyof AccessCondition) => (value: any) => {
        setData((pv) => {
            const tmp = { ...pv }
            tmp.accessConditions[idx][field] = value
            if (field === 'field') tmp.accessConditions[idx].values = []
            return tmp
        })
    }

    const isValid = useMemo(() => {
        if (data.title === '') return false
        for (let i = 0; i < data.accessConditions.length; i++) {
            if (data.accessConditions[i].field === '') return false
            if (data.accessConditions[i].values.length === 0) return false
        }
        return true
    }, [data])

    return (
        <Stack
            gap={2}
            sx={{
                height: '100%',
                minHeight: 0,
            }}
        >
            {/* Body
                ========================================= */}
            <Stack
                gap={2}
                sx={(theme) => ({
                    flexGrow: 1,
                })}
            >
                {/* Heading title
                    ========================================= */}
                <Typography
                    fontSize={24}
                    fontWeight={600}
                    color="primary"
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    {(defaultData ? 'Edit' : 'Create') + ' Custom View'}
                </Typography>

                {/* Identified switch
                    ========================================= */}
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                    <Typography fontSize={14} fontWeight={400} color="common.text_1">
                        De-identified
                    </Typography>

                    <BaseSwitch
                        checked={data.deIdentified}
                        onChange={(e, c) => setData((prev) => ({ ...prev, deIdentified: c }))}
                    />
                </Stack>

                {/* Title
                    ========================================= */}
                <BaseFilledTextField
                    label="Title"
                    value={data.title}
                    onChange={(evt) => {
                        setData((prevState) => ({
                            ...prevState,
                            title: evt.target.value,
                        }))
                    }}
                    required
                    sx={{
                        flexShrink: 0,
                    }}
                />

                {/* View configs
                    ========================================= */}
                <Stack gap={3} className="u-scrollbar">
                    <Stack
                        gap={2}
                        sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                        }}
                    >
                        {/* Filters header
                         ========================================= */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                            <Typography fontSize={14} fontWeight={500}>
                                Filters
                            </Typography>

                            <BaseButton
                                label="Add Filter"
                                onClick={(evt) => addFilterItems()}
                                startIcon={<AddIcon />}
                            />
                        </Stack>

                        {/* Filters list
                        ========================================= */}
                        <Stack gap={1}>
                            {data.accessConditions.map((filter, filterIdx) => (
                                /*  Filter item
                                                            ========================================= */
                                <Stack key={filterIdx} direction="row" alignItems="center" gap={1}>
                                    {/* Field select
                                                                ========================================= */}
                                    <BaseSelectWithLabel
                                        label="Field"
                                        options={fields.map((field) => field.key)}
                                        value={filter.field}
                                        onChange={updateFilterItems(filterIdx, 'field')}
                                        fullWidth
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    />

                                    {/* Value select
                                                                ========================================= */}
                                    <BaseSelectWithLabel
                                        label="Value"
                                        options={
                                            fields.find((x) => x.key === filter.field)?.uniqueValues || []
                                        }
                                        value={filter.values}
                                        onChange={updateFilterItems(filterIdx, 'values')}
                                        selectProps={{
                                            multiple: true,
                                        }}
                                        fullWidth
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    />

                                    {/* Remove button
                                                                ========================================= */}
                                    <Tooltip title="Remove filter">
                                        <IconButton
                                            onClick={removeFilterItems(filterIdx)}
                                            sx={(theme) => ({
                                                flexShrink: 0,

                                                color: theme.palette.common.ung_pink,
                                            })}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            {/* Actions
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flexShrink: 0,

                    padding: theme.spacing(0, 3, 1),
                })}
            >
                <BaseButton
                    label="Back"
                    onClick={(evt) => onDiscard()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label={defaultData ? 'Update' : 'Create'}
                    onClick={(evt) => onCreate(data)}
                    disabled={!isValid}
                    variant="contained"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </Stack>
    )
}

type CreateWizrdDataAccessDialogProps = {
    fields: NodeOtherFiledsWithUniqueValuesType[]
    onCreate: (fields: ProjectDataAcessType[], deIdentified: boolean) => void
    onDiscard: () => void
}

function CreateWizrdDataAccessDialog({ fields, onDiscard, onCreate }: CreateWizrdDataAccessDialogProps) {
    const [data, setData] = useState<string[]>([])
    const [deIdentified, setDeIdentified] = useState(false)
    // * filters manipulation

    const fieldKeys = useMemo(() => fields.map((field) => field.key), [fields])

    // A function to compute cartesian product of two arrays

    const accessesToCreate = useMemo(() => {
        if (data.length === 0) return []
        function cartesianProduct<T>(sets: T[][]): T[][] {
            const product: T[][] = []
            const helper = (arr: T[], i: number) => {
                if (i === sets.length) {
                    product.push(arr)
                    return
                }
                for (let j = 0; j < sets[i].length; j++) {
                    helper([...arr, sets[i][j]], i + 1)
                }
            }
            helper([], 0)
            return product
        }

        const productArrays: { field: string; value: string }[][] = data.map((field) => {
            const fieldData = fields.find((x) => x.key === field)
            if (!fieldData) return []
            return fieldData.uniqueValues.map((value) => ({ field, value }))
        })

        // Compute cartesian product
        const combinations: any[][] = cartesianProduct(productArrays)

        // Transform into desired structure
        var result = combinations.map(
            (combo, idx): ProjectDataAcessType => ({
                primaryKey: -1,
                title: combo.map((c: { field: string; value: string }) => `${c.field}:${c.value}`).join(', '),
                deIdentified,
                accessConditions: combo.map((c) => ({ field: c.field, values: [c.value] })),
            })
        )
        return result
    }, [data, deIdentified, fields])

    const isValid = useMemo(() => {
        for (let i = 0; i < data.length; i++) {
            if (data[i] === '') return false
        }
        if (accessesToCreate.length === 0) return false
        return true
    }, [data, accessesToCreate])

    return (
        <Stack
            gap={2}
            sx={{
                height: '100%',
                minHeight: 0,
            }}
        >
            {/* Body
                ========================================= */}
            <Stack
                gap={2}
                sx={(theme) => ({
                    flexGrow: 1,
                })}
            >
                {/* Heading title
                    ========================================= */}
                <Typography
                    fontSize={24}
                    fontWeight={600}
                    color="primary"
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    Custom Wizard Custom View
                </Typography>

                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                    <Typography fontSize={14} fontWeight={400} color="common.text_1">
                        De-identified
                    </Typography>

                    <BaseSwitch checked={deIdentified} onChange={(e, c) => setDeIdentified(c)} />
                </Stack>

                {/* View configs
                    ========================================= */}
                <Stack gap={3} className="u-scrollbar">
                    <Stack
                        gap={2}
                        sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                        }}
                    >
                        <BaseSelectWithLabel
                            label="Fields"
                            options={fieldKeys}
                            selectProps={{
                                multiple: true,
                            }}
                            value={data}
                            onChange={(v) => setData(v)}
                            fullWidth
                            sx={{
                                flex: '1 0 0',
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>

            {/* Summary of actions that will be created */}
            <Stack direction="row" gap={1} style={{ flexWrap: 'wrap' }}>
                {accessesToCreate.map((access) => (
                    <Chip key={access.title} label={access.title} />
                ))}
            </Stack>

            {/* Actions
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flexShrink: 0,

                    padding: theme.spacing(0, 3, 1),
                })}
            >
                <BaseButton
                    label="Back"
                    onClick={(evt) => onDiscard()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label={'Create'}
                    onClick={(evt) => onCreate(accessesToCreate, deIdentified)}
                    disabled={!isValid}
                    variant="contained"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default ProjectDataAccessManagment
