import { Box, Checkbox, Stack, Tooltip, Typography } from '@mui/material'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import DescriptionIcon from '@mui/icons-material/Description'
import StyledDialog from 'components/dialog/StyledDialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import BaseButton from 'components/base/BaseButton'
import WebWorker from 'helpers/webWorkerHelper'
import { toast } from 'react-toastify'
import { UpdateWidget } from 'services/WidgetApi'
import { useParams } from 'react-router-dom'
import * as echarts from 'echarts'
import { APP_VERSIONS } from 'helpers/constants'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { NodeType } from '../types/NetworkViz.types'
import { convertImagesToBase64 } from '../helpers/NetworkViz.helper'

function NetworkActionBar() {
    const { pid, wid } = useParams()
    const networkVizContext = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [snapshotImageFormat, setSnapshotImageFormat] = useState<'svg' | 'png'>('png')
    const [snapshotDialogOpen, setSanpshotDialogOpen] = useState(false)
    const [exportDataDialogOpen, setExportDataDialogOpen] = useState(false)
    const [networkAnalyticsToExport, setNetworkAnalyticsToExport] = useState<string[]>([])

    const onExportDataDialogClose = () => {
        setExportDataDialogOpen(false)
        setNetworkAnalyticsToExport([])
    }
    const onExportDataDialogOpen = () => setExportDataDialogOpen(true)

    const onExportDialogClose = () => {
        setSanpshotDialogOpen(false)
        setSnapshotImageFormat('png')
    }

    const saveState = async () => {
        const toastId = toast.loading('Save in progress')
        const worker = new WebWorker('workers/network/zip-worker.js')
        try {
            const zip = await worker.run({
                mode: 'zip',
                data: {
                    state: {
                        ...networkVizContext,
                        nodeRenders: networkVizContext.nodeRenders.map((x) => ({
                            ...x,
                            highlighted: undefined,
                        })),
                        selectedNode: null,
                    },
                    version: APP_VERSIONS.NETWORK,
                },
            })

            // generate thubmsnail image
            var div = document.createElement('div')
            div.setAttribute('style', 'width:100%;height:100%;position:fixed;zIndex:999;top:0;left:0')
            document.body.appendChild(div)
            let chart = echarts.init(div, undefined, { renderer: 'canvas' })

            chart.setOption({
                animation: false,
                series: [
                    {
                        layout: 'none',
                        type: 'graph',
                        data: await convertImagesToBase64(networkVizContext.nodeRenders.filter((x) => x.hide !== true)),
                        links: networkVizContext.edgeRenders.filter((x) => x.hide !== true),
                        edgeSymbol: [
                            networkVizContext.edgeArrowType.arrow.source,
                            networkVizContext.edgeArrowType.arrow.target,
                        ],
                        zoom: 4,
                        edgeSymbolSize: networkVizContext.edgeArrowType.size,
                        labelLayout: {
                            hideOverlap: true,
                        },
                    },
                ],
            })

            const thumbnail = chart.getDataURL({
                pixelRatio: 0.3,
            })

            document.body.removeChild(div)

            const response = await UpdateWidget(pid!, {
                primaryKey: parseInt(wid!),
                thumbnail,
                state: zip,
            })
            if (!response.success) throw 'error'

            toast.update(toastId, {
                render: 'The Network Viz saved successfully.',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        } catch (e) {
            toast.update(toastId, {
                render: 'Failed to save Network Viz.',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        }
    }

    const exportNetworkImage = () => {
        dispatchContext({
            type: 'ACTION',
            payload: {
                exportFormat: snapshotImageFormat,
                type: 'export-image',
            },
        })
        onExportDialogClose()
    }

    const s2ab = (s: string): ArrayBuffer => {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
        return buf
    }

    const exportNetworkAnalytics = () => {
        if (!networkVizContext.analytics) return toast.error('No analytics data found')

        const toastId = toast.loading('Exporting data...')
        try {
            const wb = XLSX.utils.book_new()

            const nodesRecord: Record<string, NodeType> = structuredClone(networkVizContext.nodes)
            const graphArray: Record<string, any>[] = []

            for (let analyticGroup of networkAnalyticsToExport) {
                const { nodes, graph } = networkVizContext.analytics![analyticGroup]

                Object.keys(nodes).forEach((nodeId) => {
                    const nodeAnalytics = nodes[nodeId]
                    for (let key of Object.keys(nodeAnalytics)) {
                        nodesRecord[nodeId][`${analyticGroup}.${key}`] = nodeAnalytics[key]
                    }
                })

                graphArray.push({
                    name: analyticGroup,
                    ...graph,
                })
            }

            const nodesWS = XLSX.utils.json_to_sheet(Object.values(nodesRecord))
            XLSX.utils.book_append_sheet(wb, nodesWS, 'nodes')

            // create a worksheet for Graph
            const graphWS = XLSX.utils.json_to_sheet(graphArray)
            XLSX.utils.book_append_sheet(wb, graphWS, 'networks')

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
            saveAs(new Blob([s2ab(wbout)]), `analytics-${new Date().getTime()}.xlsx`)

            toast.update(toastId, {
                render: 'The Network data exported successfully.',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
            onExportDataDialogClose()
        } catch {
            toast.update(toastId, {
                render: 'Failed to export data.',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
                closeButton: null,
            })
        }
    }

    const actions = [
        { icon: <RedoIcon />, name: 'Redo' },
        { icon: <UndoIcon />, name: 'Undo' },
        { name: 'divider' },
        { icon: <CameraAltIcon />, name: 'Snapshot', action: () => setSanpshotDialogOpen(true) },
        { icon: <DescriptionIcon />, name: 'Export Data', action: onExportDataDialogOpen },
        { icon: <SaveIcon />, name: 'Save', action: saveState },
    ]

    return (
        <Stack direction="column-reverse" alignItems="center" gap={1}>
            {actions.map((action) =>
                action.name === 'divider' ? (
                    <Box key={action.name} sx={{ border: '1px solid', borderColor: 'divider', width: '100%' }} />
                ) : (
                    <Tooltip key={action.name} title={action.name} placement="right">
                        <BaseButton
                            onClick={action.action}
                            disableElevation
                            sx={(theme) => ({
                                height: 48,
                                minWidth: 0,
                                width: 48,

                                borderRadius: 0,
                                // @Theme conditional
                                backgroundColor:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_3
                                        : theme.palette.common.bg_4,
                                color: theme.palette.primary.main,

                                '&:hover': {
                                    // @Theme conditional
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? theme.palette.common.bg_3
                                            : theme.palette.common.bg_4,
                                },
                            })}
                        >
                            {action.icon}
                        </BaseButton>
                    </Tooltip>
                )
            )}

            {/* Export image Dialog */}
            <StyledDialog maxWidth="sm" fullWidth open={snapshotDialogOpen} onClose={onExportDialogClose}>
                <DialogTitle>Network Snapshot</DialogTitle>
                <DialogContent>
                    <FormControl>
                        <FormLabel id="image-type-radio-buttons-group">Format</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="image-type-radio-buttons-group"
                            name="image-type-radio-buttons-group"
                            value={snapshotImageFormat}
                            onChange={(v) => setSnapshotImageFormat(v.target.value === 'svg' ? 'svg' : 'png')}
                        >
                            <FormControlLabel value="png" control={<Radio />} label="PNG" />
                            <FormControlLabel
                                disabled={networkVizContext.networkVizKind !== '2d+'}
                                value="svg"
                                control={<Radio />}
                                label="SVG"
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <BaseButton onClick={onExportDialogClose} label="Close" />
                    <BaseButton variant="contained" onClick={exportNetworkImage} label="Export" />
                </DialogActions>
            </StyledDialog>

            <StyledDialog maxWidth="sm" fullWidth open={exportDataDialogOpen} onClose={onExportDataDialogClose}>
                <DialogTitle>Export Network Analytics</DialogTitle>
                <DialogContent>
                    <BaseSelectWithLabel
                        fullWidth
                        selectProps={{
                            multiple: true,
                        }}
                        value={networkAnalyticsToExport}
                        options={Object.keys(networkVizContext.analytics || {})}
                        onChange={(v) => setNetworkAnalyticsToExport(v)}
                        label="Select Network Analytics"
                    />
                </DialogContent>
                <DialogActions>
                    <BaseButton onClick={onExportDataDialogClose} label="Close" />
                    <BaseButton variant="contained" onClick={exportNetworkAnalytics} label="Export" />
                </DialogActions>
            </StyledDialog>
        </Stack>
    )
}

export default NetworkActionBar
