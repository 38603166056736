//* ======= Libraries
import React, { useContext, useEffect, useMemo, useState } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import GeneratePdfInvitesDialog from 'features/survey-results/GeneratePdfInvitesDialog'
//* ======= Custom logic
//* ======= Assets and styles
import TempAvatar from 'assets/images/profile-photo.svg'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import BiotechIcon from '@mui/icons-material/Biotech'
import { SurveyContext } from 'contexts/SurveyContex'
import { GetSurveyResults, SurveyBasicType, SurveyInstanceBasicType } from 'services/SurveyService'
import { useNavigate, useParams } from 'react-router-dom'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import moment from 'moment'
import { ChangeSurveyStatusService, GetSurveyInstance } from 'services/SurveyInstanceService'
import { buildAbbreviation } from 'features/GraphStudio/helper/DataFormatter'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'
import { Model } from 'survey-core'
import { toast } from 'react-toastify'
import SurveyVerificationDialog from 'features/survey-verification-dialog/SurveyVerificationDialog'

const getNumberOfRounds = (survey: SurveyBasicType) => {
    try {
        if (survey.schedule) {
            if (survey.schedule.isAdvanced) {
                return (
                    (survey.schedule.numberOfInstances || 1) * survey.schedule.occurrence +
                    (survey.schedule.manual?.length || 0)
                )
            } else {
                return survey.schedule.occurrence + (survey.schedule.manual?.length || 0)
            }
        }
    } catch {}
    return 1
}
const TempRowCandidateView = ({ username, avatar }: { username: string; avatar: string | undefined }) => {
    return (
        <Stack direction="row" alignItems="center" gap={2}>
            <Avatar src={avatar || TempAvatar} alt={username} />

            <Typography fontSize={10} fontWeight={400} color="common.text_1">
                {username}
            </Typography>
        </Stack>
    )
}

interface ISurveyResults {}

/**
 * TODO:
 * 	1.	'aria-controls' is missing for each tab's container.
 */

function SurveyResults({}: ISurveyResults) {
    const { drawerContent, survey } = useContext(SurveyContext)

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

    const [isPDFDialogOpen, setIsPDFDialogOpen] = useState(false)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue)
    }

    const { pid, sid } = useParams()
    const [loading, setLoading] = useState(true)

    const [result, setResult] = useState<SurveyInstanceBasicType[]>([])
    const [isVerificationDialogOpen, setIsVerificationDialogOpen] = useState(false)

    const round = useMemo(() => (survey?.recurring ? 1 + selectedTabIndex : undefined), [selectedTabIndex])

    useEffect(() => {
        if (!pid || !sid) return
        setLoading(true)
        GetSurveyResults(pid, sid, round)
            .then((res) => res.success && setResult(res.data))
            .finally(() => setLoading(false))
    }, [round])

    const activateSurvey = (id: string | number) => () => {
        if (pid) {
            ChangeSurveyStatusService(pid, id, { status: 'invited' }).then((res) => {
                if (res.success) {
                    setResult((res) => {
                        const temp = [...res]
                        const index = temp.findIndex((t) => t.surveyInstanceId == id)
                        if (index !== -1) {
                            temp[index].status = 'invited'
                        }
                        return temp
                    })
                }
            })
        }
    }

    if (survey === undefined) return <DashboardSkeleton />

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack
                    height="100%"
                    sx={(theme) => ({
                        paddingBottom: 2,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* Tabs and head controls
						========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            // py: 1.25,
                            paddingLeft: 0,
                            paddingRight: 3,

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Tabs
							========================================= */}
                        {/* ToDo fix the style (issue if number of rounds more than 2/3 wont fit) */}
                        <Box width="60%">
                            {survey.recurring && (
                                <Tabs
                                    value={selectedTabIndex}
                                    onChange={handleTabChange}
                                    aria-label="survey results round tabs"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    sx={{
                                        '& .MuiTab-root': {
                                            py: 2,
                                            px: 3,
                                            fontSize: 20,
                                            fontWeight: 500,
                                            color: 'secondary.main',
                                        },
                                    }}
                                >
                                    {Array.from([...Array(getNumberOfRounds(survey))]).map((i, idx) => (
                                        <Tab label={'Round ' + (idx + 1)} id={'survey-results-round-tab-' + idx} />
                                    ))}
                                </Tabs>
                            )}
                        </Box>
                        {/* Controls
							========================================= */}

                        <Stack direction="row" alignItems="center" gap={1} m={1}>
                            {/* Generate PDF button
								========================================= */}
                            <BaseButton
                                label="Generate PDF Invites"
                                onClick={(e) => setIsPDFDialogOpen(true)}
                                variant="outlined"
                                size="large"
                                endIcon={<PictureAsPdfIcon />}
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            />

                            <BaseButton
                                label="Verify Survey Links"
                                onClick={(e) => setIsVerificationDialogOpen(true)}
                                variant="outlined"
                                size="large"
                                endIcon={<BiotechIcon />}
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            />

                            {/* Search
								========================================= */}
                            {/* <SearchField
                                options={['Something']}
                                placeholder="Search"
                                sx={{
                                    marginRight: 1,
                                }}
                            /> */}

                            {/* Filter button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <FilterListIcon />
                            </IconButton> */}

                            {/* Refresh button
								========================================= */}
                            {/* <Tooltip title="Refresh">
                                <IconButton
                                    sx={{
                                        borderRadius: '4px',
                                        color: 'common.fill_2',
                                    }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip> */}

                            {/* Download button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <DownloadIcon />
                            </IconButton> */}

                            {/* Print button
								========================================= */}
                            {/* <IconButton
                                sx={{
                                    borderRadius: '4px',
                                    color: 'common.fill_2',
                                }}
                            >
                                <PrintIcon />
                            </IconButton> */}
                        </Stack>
                    </Stack>

                    {/* Table / Tabs panel
						========================================= */}
                    <Box
                        flexGrow={1}
                        sx={{
                            paddingLeft: 3,
                            paddingRight: 2,
                            overflowY: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <SurveyResultRound loading={loading} instances={result} activateSurvey={activateSurvey} />
                    </Box>
                </Stack>
            </PageCard.Body>

            {/* Dialog
				========================================= */}

            <SurveyVerificationDialog
                surveys={result}
                isOpen={isVerificationDialogOpen}
                onClose={() => setIsVerificationDialogOpen(false)}
            />
            <StyledDialog
                open={isPDFDialogOpen}
                onClose={(event, reason) => setIsPDFDialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <GeneratePdfInvitesDialog
                    instances={result}
                    onConfirm={() => undefined}
                    onClose={() => setIsPDFDialogOpen(false)}
                />
            </StyledDialog>
        </PageCard>
    )
}

export default SurveyResults

/*	Round panel
    ======================================== */

interface ISurveyResultRound {
    loading: boolean
    instances: SurveyInstanceBasicType[]
    activateSurvey: (id: string | number) => () => void
}

function SurveyResultRound({ loading, instances, activateSurvey }: ISurveyResultRound) {
    const navigator = useNavigate()

    return (
        <StyledDataGrid
            rows={instances}
            columns={[
                { field: 'surveyInstanceId', headerName: 'Id' },
                {
                    field: 'respondent',
                    headerName: 'Respondent',
                    width: 40,
                    renderCell: (params) => {
                        return <Avatar src={params?.value?.avatar}>{buildAbbreviation(params?.value?.name)}</Avatar>
                    },
                },
                {
                    field: 'respondentName',
                    valueGetter: ({ id }) => {
                        const item = instances.find((item) => item.surveyInstanceId === id)
                        return item?.respondent?.name || 'Anonymous User'
                    },
                    headerName: 'Name',
                },
                {
                    field: 'email',
                    headerName: 'Email',
                    valueGetter: ({ id }) => {
                        const item = instances.find((item) => item.surveyInstanceId === id)
                        return item?.respondent?.email
                    },
                },
                { field: 'status', headerName: 'Status' },
                {
                    field: 'startDate',
                    headerName: 'Start Date',
                    valueFormatter: (v) => moment(v.value).format('DD.MM.YYYY'),
                },
                {
                    field: 'endDate',
                    headerName: 'End Date',
                    valueFormatter: (v) => moment(v.value).format('DD.MM.YYYY'),
                },
                {
                    field: 'timeSpent',
                    headerName: 'Time Spent',
                    valueFormatter: (v) => {
                        return v.value / 60 > 0 ? `${Math.round(v.value / 60)} mins` : `${v.value} secs`
                    },
                },
                { field: 'passCode', headerName: 'Pass Code' },
                {
                    field: 'accessToken',
                    headerName: 'Actions',
                    sortable: false,
                    width: 150,
                    renderCell: (params) => {
                        return (
                            <Stack gap={1} direction="row">
                                {/* <Button onClick={() => navigator(`/survey/si/${params.value}?view`)}>View</Button> */}
                                {params.row.status == 'not_started' ? (
                                    <Button onClick={activateSurvey(params.id)}>Activate</Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            window.open(`${process.env.REACT_APP_BASE_URL}survey/si/${params.value}`)
                                        }
                                    >
                                        Open
                                    </Button>
                                )}
                            </Stack>
                        )
                    },
                },
            ]}
            getRowId={(r) => r.surveyInstanceId}
            loading={loading}
        />
    )
}
