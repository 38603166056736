import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Dialog,
    Typography,
    Box,
    DialogTitle,
    DialogActions,
    DialogContent,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import {
    CreateIDPRequestType,
    CreateIDPService,
    DeleteIDPService,
    GetAllIDPsService,
    IDPType,
} from 'services/SuperAdminService'
import { GridColDef } from '@mui/x-data-grid-pro'
import { RootContext } from 'contexts/RootContext'
import GeneralDrawerNavigation from 'pages/general-dashboard/Navigation'
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import BaseButton from 'components/base/BaseButton'
import AddIcon from '@mui/icons-material/Add'
import StyledDialog from 'components/dialog/StyledDialog'
import { Controller, useForm } from 'react-hook-form'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSwitch from 'components/base/BaseSwitch'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ToastHelper from 'helpers/ToastHelper'
import { set } from 'lodash'

const IdentityProviders: React.FC = () => {
    const [idps, setIdps] = useState<IDPType[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [idpToDelete, setIdpToDelete] = useState<null | string | number>(null)
    const [idpToEdit, setIdpToEdit] = useState<null | IDPType>(null)
    const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false)

    const fetchIDPs = async () => {
        setLoading(true)
        try {
            const data = await GetAllIDPsService()
            if (data.success) {
                setIdps(data.data)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchIDPs()
    }, [])

    const columns: GridColDef[] = [
        { field: 'organizationDomain', headerName: 'Domain' },
        { field: 'entityId', headerName: 'Entity Id' },
        { field: 'metadataUrl', headerName: 'Metadata Url' },
        { field: 'loadMetadata', headerName: 'Load Metadata', type: 'boolean' },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Box>
                    <Button color="primary" onClick={() => handleEditOpen(params.row as IDPType)}>
                        Edit
                    </Button>
                    <Button color="secondary" onClick={() => setIdpToDelete(params.id)}>
                        Delete
                    </Button>
                </Box>
            ),
        },
    ]

    const handleEditOpen = (idp: IDPType) => {
        setIdpToEdit(idp)
    }

    const handleDeleteConfirm = () => {
        if (idpToDelete) {
            // Add the delete logic here
            DeleteIDPService(idpToDelete).then((res) => {
                if (res.success) {
                    setIdps((prev) => prev.filter((idp) => idp.primaryKey !== idpToDelete))
                    setIdpToDelete(null)
                }
            })
        }
    }

    const { user } = useContext(RootContext)

    if (user == null) return null

    const drawerContent = GeneralDrawerNavigation(user.roles)

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>
            <PageCard.Body>
                <Box sx={{ backgroundColor: 'common.bg_1', height: '100%' }}>
                    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
                        <StyledDataGrid
                            rows={idps}
                            columns={columns}
                            loading={loading}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                            hasToolbar={false}
                            getRowId={(row) => row.primaryKey}
                            customToolbar={
                                <BaseButton
                                    variant="text"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => setCreateDialogOpen(true)}
                                >
                                    Create New IDP
                                </BaseButton>
                            }
                        />
                    </Box>
                    <Dialog open={idpToDelete !== null} onClose={() => setIdpToDelete(null)}>
                        <Typography>Are you sure you want to delete this IDP?</Typography>
                        <Button variant="contained" color="secondary" onClick={handleDeleteConfirm}>
                            Confirm Delete
                        </Button>
                        <Button variant="contained" onClick={() => setIdpToDelete(null)}>
                            Cancel
                        </Button>
                    </Dialog>
                    <Dialog open={idpToEdit !== null} onClose={() => setIdpToEdit(null)}>
                        {/* Add edit form fields here */}
                        <Typography>Edit IDP</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                console.log(`Save edits for IDP with id: ${idpToEdit?.primaryKey}`)
                                setIdpToEdit(null)
                            }}
                        >
                            Save
                        </Button>
                        <Button variant="contained" onClick={() => setIdpToEdit(null)}>
                            Cancel
                        </Button>
                    </Dialog>

                    <CreateIDPDialog
                        open={isCreateDialogOpen}
                        onClose={() => {
                            fetchIDPs()
                            setCreateDialogOpen(false)
                        }}
                    />
                </Box>
            </PageCard.Body>
        </PageCard>
    )
}

export default IdentityProviders

type ICreateIDPDialog = {
    open: boolean
    onClose: () => void
}

const DOMAIN_REGEX = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
const ENTITY_ID_REGEX = /^((http|https):\/\/|urn:)[^ "]+$/

function CreateIDPDialog({ open, onClose }: ICreateIDPDialog) {
    const [fileName, setFileName] = useState<string>('')

    // form initialization
    const {
        control,
        setValue,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm<CreateIDPRequestType>({ mode: 'onBlur' })

    const createIDP = () => {
        const toast = new ToastHelper({
            errorMessage: 'Failed to create IDP',
            successMessage: 'IDP created successfully',
            loadingMessage: 'Creating IDP...',
        })
        handleSubmit(
            //Valid
            (data) => {
                CreateIDPService(data)
                    .then((res) => {
                        if (res.success) {
                            toast.success()
                            onClose()
                        } else {
                            toast.fail()
                        }
                    })
                    .catch((e) => {
                        toast.fail()
                    })
            },
            //Invalid
            (e) => {
                console.log(e)
            }
        )()
    }

    useEffect(() => {
        if (open) {
            reset()
            setFileName('')
        }
    }, [open])

    // Create a ref for the file input
    const metadataFileRef = React.useRef<HTMLInputElement>(null)

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]

            // Using FileReader to read the file's content
            const reader = new FileReader()

            reader.onload = (event) => {
                // event.target.result contains the base64-encoded file's content
                if (typeof event.target?.result === 'string') {
                    // Remove the "data:" prefix to get just the base64 content
                    setValue('metadataFile', event.target.result)
                }
            }
            setFileName(file.name)
            reader.readAsDataURL(file) // Reads the file content as text
        } else {
            setValue('metadataFile', '')
        }
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Register a new Identity Provider</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Controller
                        name="organizationDomain"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Organization Domain"
                                required
                                {...field}
                                error={!!errors.organizationDomain}
                                helperText={errors.organizationDomain?.message}
                            />
                        )}
                        rules={{
                            required: 'Organization Domain is required',
                            pattern: {
                                value: DOMAIN_REGEX,
                                message: 'Invalid domain format',
                            },
                        }}
                    />

                    <Controller
                        name="entityId"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <BaseFilledTextField
                                label="Entity ID"
                                required
                                {...field}
                                error={!!errors.entityId}
                                helperText={errors.entityId?.message}
                            />
                        )}
                        rules={{
                            required: 'Entity ID is required',
                            pattern: {
                                value: ENTITY_ID_REGEX,
                                message: 'Invalid Entity ID format',
                            },
                        }}
                    />

                    {/* Metadata URL and file input */}
                    <Stack alignItems="flex-start">
                        <BaseButton
                            variant="outlined"
                            color="primary"
                            startIcon={<AttachFileIcon />}
                            onClick={() => metadataFileRef.current?.click()}
                        >
                            {fileName ? fileName : 'Upload Metadata File*'}
                        </BaseButton>
                        <input
                            type="file"
                            ref={metadataFileRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </Stack>

                    <Controller
                        name="loadMetadata"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <FormGroup aria-label="advanced-mode" row>
                                <FormControlLabel
                                    label="Load Metadata"
                                    control={
                                        <BaseSwitch
                                            checked={field.value}
                                            color="primary"
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    labelPlacement="end"
                                    sx={{
                                        gap: 1,
                                        marginRight: 0,

                                        '& .MuiFormControlLabel-label': {
                                            fontSize: 16,
                                            fontWeight: 400,

                                            color: 'common.text_1',
                                        },
                                    }}
                                />
                            </FormGroup>
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton variant="outlined" color="warning" onClick={onClose}>
                    Cancel
                </BaseButton>
                <BaseButton
                    disabled={!isDirty || !isValid || !fileName}
                    variant="contained"
                    color="primary"
                    onClick={createIDP}
                >
                    Create
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
