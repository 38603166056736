import React, { useMemo, useState } from 'react'
import { EditNodeSettingsModal } from './EditNodeSettingsModal'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import SettingsIcon from '@mui/icons-material/Settings'
import DownloadIcon from '@mui/icons-material/Download'

import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import { formatLabel, formatValue } from 'features/network-viz/helpers/DataFormatter'
import getNodeValue from 'features/network-viz/helpers/NodeValueParser'
import { EdgeIdType, EdgeType } from 'features/network-viz/types/NetworkViz.types'
import StyledDialog from 'components/dialog/StyledDialog'

type NetworkSelectedEdgeDetailsPropsType = {
    selectedEdge: string | null
    edges: Record<EdgeIdType, EdgeType>
}

export function NetworkSelectedEdgeInfoTable({ selectedEdge, edges }: NetworkSelectedEdgeDetailsPropsType) {
    //ToDo add feature to display data for recurring surveys
    const round = 0

    const data = useMemo(() => {
        if (selectedEdge === null || selectedEdge === undefined || edges[selectedEdge] == null) return null
        const edgeId = selectedEdge

        const result: { label: string; value: string; isImage: boolean }[] = []

        const edge = edges[edgeId]
        if (edge == null) return result

        for (const key in edge) {
            if (key === 'source' || key === 'target') continue

            let value = edge[key]
            if (Array.isArray(value) && value.length > 0) {
                value = value[0]
            }
            const isImage =
                value && typeof value === 'object' && value.content != null && value.content.startsWith('data:image')

            result.push({
                label: formatLabel(key),
                value: isImage ? value.content : formatValue(edge[key], round),
                isImage,
            })
        }
        return result
    }, [selectedEdge, edges])

    return selectedEdge == null || edges[selectedEdge] == null ? (
        <></>
    ) : (
        <React.Fragment>
            {/* Body
                ========================================= */}
            <Stack
                gap={1}
                sx={{
                    flexGrow: 1,

                    minHeight: 0,
                }}
            >
                {/* Details
                        ========================================= */}
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Stack
                        sx={{
                            flexGrow: 1,

                            minWidth: 0,
                        }}
                    >
                        <Typography fontSize={14} fontWeight={500} noWrap>
                            {edges[selectedEdge].source} {'->'} {edges[selectedEdge].target}
                        </Typography>
                    </Stack>
                </Stack>

                <List
                    dense
                    sx={{
                        flexGrow: 1,
                        padding: 0,
                        margin: 0,
                        overflow: 'auto',
                    }}
                    className="u-scrollbar"
                >
                    {Array.isArray(data) &&
                        data.map((item, index) => (
                            <ListItem
                                sx={{
                                    padding: 0,
                                    margin: 0,
                                }}
                                key={item?.label}
                            >
                                <ListItemText
                                    sx={{ marginY: '1px' }}
                                    primary={item?.label + ':'}
                                    secondary={
                                        item.isImage ? <ZoomableImage src={item.value} alt={item.label} /> : item.value
                                    }
                                />
                            </ListItem>
                        ))}
                </List>
            </Stack>
        </React.Fragment>
    )
}

type ZoomableImagePropsType = {
    src: string
    alt: string
}

const ZoomableImage = ({ src, alt }: ZoomableImagePropsType) => {
    const [zoomed, setZoomed] = useState(false)

    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <img
                src={src}
                alt={alt}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    cursor: 'pointer',
                }}
                onClick={() => setZoomed(!zoomed)}
            />
            {zoomed && (
                <StyledDialog open={true} onClose={() => setZoomed(false)}>
                    <Box sx={{ maxWidth: '100%', maxHeight: '100%', p: 0, m: 0, position: 'relative' }}>
                        <img
                            src={src}
                            alt={alt}
                            style={{
                                maxHeight: '100%',
                                maxWidth: '100%',
                                objectFit: 'contain',
                            }}
                        />
                        {/* Download button */}
                        <IconButton
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                            }}
                            onClick={() => {
                                const a = document.createElement('a')
                                a.href = src
                                a.download = alt
                                a.click()
                            }}
                        >
                            <Avatar sx={{ backgroundColor: 'primary.main' }}>
                                <DownloadIcon />
                            </Avatar>
                        </IconButton>
                    </Box>
                </StyledDialog>
            )}
        </Stack>
    )
}
