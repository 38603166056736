//* ======= Libraries
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Box,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@mui/material'
//* ======= Components and features
import AddReportDataSourceDialog, {
    fetchNetworkDataSource,
} from 'features/report-designer/data-sources/AddReportDataSourceDialog'
import EditReportDataSourceDialog from './EditReportDataSourceDialog'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { ReportDataSourceType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { useParams } from 'react-router-dom'

function ReportDataSources() {
    const { pid } = useParams()
    const { status, dataSources, removeDataSource, updateDataSource } = useReportStore((store) => ({
        status: store.status,
        dataSources: store.dataSources,
        removeDataSource: store.removeDataSource,
        updateDataSource: store.updateDataSource,
    }))

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [activeDataSource, setActiveDataSource] = React.useState<null | ReportDataSourceType>(null)
    const menuOpen = Boolean(anchorEl)

    const menuHandleClick = (dataSource: ReportDataSourceType) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setActiveDataSource(dataSource)
    }

    const menuHandleClose = () => {
        setAnchorEl(null)
        setActiveDataSource(null)
    }

    const [isAddDialogOpen, setIsAddDialogOpen] = useState<false | string>(false)

    const [editDialogState, setEditDialogState] = useState<{
        isOpen: boolean
        dataSourceId: ReportDataSourceType['id'] | null
    }>({
        isOpen: false,
        dataSourceId: null,
    })

    const handleDeleteDataSource = () => {
        if (activeDataSource !== null) {
            removeDataSource({ dataSourceId: activeDataSource.id })
        }

        menuHandleClose()
    }

    const handleSyncDataSource = async () => {
        if (activeDataSource !== null) {
            switch (activeDataSource.mode) {
                case 'network':
                    const updateNetwork = await fetchNetworkDataSource(pid!, activeDataSource.networkVizId)

                    updateDataSource({
                        dataSourceId: activeDataSource.id,
                        data: updateNetwork,
                    })

                    break

                default:
                    break
            }
        }
        menuHandleClose()
    }

    return (
        <Stack
            gap={1}
            sx={{
                height: '100%',
                paddingX: 1,
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
            className="u-scrollbar"
        >
            {status === 'pending' ? (
                /*  "pending" view
                    ========================================= */
                <Box
                    sx={{
                        height: '100%',
                        paddingX: 1,
                    }}
                >
                    <Skeleton
                        height="100%"
                        style={{
                            display: 'block',
                        }}
                    />
                </Box>
            ) : status === 'faulty' ? (
                /*  "faulty" view
                    ========================================= */
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    sx={{
                        height: '100%',
                    }}
                >
                    <ErrorOutlineIcon
                        sx={(theme) => ({
                            height: 32,
                            width: 32,

                            color: theme.palette.common.fuschia80,
                        })}
                    />
                </Stack>
            ) : (
                /*  Main view
                    ========================================= */
                <>
                    {/* Heading and add button
                        ========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        <Typography fontSize={14} noWrap>
                            Data Sources:
                        </Typography>

                        {/* Add data source button
                            ========================================= */}
                        <IconButton
                            onClick={(evt) => {
                                const newId = uuidv4()

                                setIsAddDialogOpen(newId)
                            }}
                        >
                            <AddIcon
                                sx={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        </IconButton>
                    </Stack>

                    {/* Data sources list
                        ========================================= */}
                    <Stack gap={1}>
                        {dataSources.length > 0 ? (
                            dataSources.map((_dataSource, idx) => (
                                <ListItem
                                    key={_dataSource.id}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="open-datasource-menu"
                                            onClick={menuHandleClick(_dataSource)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={(evt) =>
                                            setEditDialogState({
                                                isOpen: true,
                                                dataSourceId: _dataSource.id,
                                            })
                                        }
                                        dense
                                        disableGutters
                                        sx={{
                                            paddingX: 1,
                                        }}
                                    >
                                        <ListItemText
                                            primary={_dataSource.title}
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                noWrap: true,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        ) : (
                            <Typography
                                fontSize={14}
                                noWrap
                                sx={{
                                    paddingX: 1,
                                }}
                            >
                                No datasets.
                            </Typography>
                        )}
                    </Stack>

                    {/* Menu
                        ========================================= */}
                    <Menu
                        id="datasource-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={menuHandleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {activeDataSource?.mode === 'network' && (
                            <MenuItem onClick={handleSyncDataSource}>
                                <ListItemIcon>
                                    <CloudSyncIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Sync</ListItemText>
                            </MenuItem>
                        )}
                        <MenuItem onClick={() => activeDataSource?.id && setIsAddDialogOpen(activeDataSource.id + '')}>
                            <ListItemIcon>
                                <SwapHorizIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Replace</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleDeleteDataSource}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </Menu>

                    {/* Dialogs
                        ========================================= */}

                    {isAddDialogOpen !== false && (
                        <AddReportDataSourceDialog
                            datasourceId={isAddDialogOpen}
                            open={true}
                            onClose={() => setIsAddDialogOpen(false)}
                        />
                    )}

                    <EditReportDataSourceDialog
                        open={editDialogState.isOpen}
                        dataSourceId={editDialogState.dataSourceId}
                        onClose={() => setEditDialogState({ isOpen: false, dataSourceId: null })}
                    />
                </>
            )}
        </Stack>
    )
}

export default ReportDataSources
