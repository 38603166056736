//* ======= Libraries
import React, { useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import NewProjectStrip from 'features/general-dashboard/NewProjectStrip'
import ProjectsPanel from 'features/general-dashboard/ProjectsPanel'
import Stack from '@mui/material/Stack'
import { GetProjects, ProjectType } from 'services/ProjectService'
import { ResourceType } from 'types/resources'
import { RootContext } from 'contexts/RootContext'
import GeneralDrawerNavigation from './Navigation'
import { Badge, Chip } from '@mui/material'
import NavigableDialog from 'components/dialog/NavigableDialog'
import { ICreateResourceDialogStateType } from 'pages/project-dashboard/ProjectDashboard.route'
import CreateProjectDialog from 'features/create-project-dialog/CreateProjectDialog'
import { useNavigate } from 'react-router-dom'
import { use } from 'echarts'
import { PERMISSIONS } from 'helpers/constants'
//* ======= Custom logic
//* ======= Assets and styles

interface IGeneralDashboard {}

function GeneralDashboard({}: IGeneralDashboard) {
    const navigator = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [projects, setProjects] = useState<ResourceType[]>([])
    const [projectTags, setProjectTags] = useState<string[]>([])
    const [templates, setTemplates] = useState<ResourceType[]>([])
    const [templatesTags, setTemplateTags] = useState<string[]>([])
    const { user, setPath, permissions } = useContext(RootContext)

    const parseProjects = (allProjects: Array<ProjectType>, isTemplate = false) => {
        const getPermissions = (role: string) => {
            const permission = permissions.find((p) => p.roleName === role)
            return {
                canDelete: permission?.projectRolePermissions.includes(PERMISSIONS.project.DeleteProject) || false,
                canEditTag: permission?.projectRolePermissions.includes(PERMISSIONS.project.ManageProjectTag) || false,
                canRename:
                    permission?.projectRolePermissions.includes(PERMISSIONS.project.UpdateProjectSettings) || false,
            }
        }

        const tempProjects: ResourceType[] = allProjects
            .filter(
                (tp) =>
                    !tp.archived && ((!isTemplate && tp.type !== 'template') || (isTemplate && tp.type === 'template'))
            )
            .map((p) => ({
                ...p,
                image: p.thumbnail,
                id: p.id || -1,
                info: [
                    {
                        label: 'Nodes',
                        value: `# ${p.numberOfNodes}`,
                    },
                    {
                        label: 'Surveys',
                        value: `# ${p.numberOfSurveys}`,
                    },
                    {
                        label: 'Completed Surveys',
                        value: `# ${p.numberOfCompletedSurveys}`,
                    },
                    {
                        label: 'Reports',
                        value: `# ${p.numberOfReports}`,
                    },
                    {
                        label: 'Last modified',
                        value: `${moment(p.createdOn).format('DD-MM-YYYY HH:mm')}`,
                    },
                ],
                ...getPermissions(p.role),
                link: '/project/' + p.id,
            }))

        let tempTags = new Set<string>()
        for (let p of tempProjects) {
            if (p.tags)
                for (let t of p.tags) {
                    t.key && tempTags.add(t.key)
                }
        }

        return {
            projects: tempProjects,
            tags: Array.from(tempTags),
        }
    }

    useEffect(() => {
        setIsLoading(true)
        if (user === null || permissions.length === 0) return
        setPath([{ title: 'All Projects', link: '/' }])
        GetProjects()
            .then((response) => {
                if (response.success) {
                    // if user is not admin and there is only one project, redirect to that project
                    if (!user.roles.includes('admin') && response.data.length === 1) {
                        navigator('/project/' + response.data[0].id)
                    }
                    // get all projects
                    const allProjects = parseProjects(response.data)
                    setProjects(allProjects.projects)
                    setProjectTags(allProjects.tags)
                    // get all templates
                    const allTemplates = parseProjects(response.data, true)
                    setTemplates(allTemplates.projects)
                    setTemplateTags(allTemplates.tags)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }, [user, permissions])

    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
    const [createDialogStep, setCreateDialogStep] = useState<number>(0)

    const handleCloseCreateDialog = () => setCreateDialogOpen(false)
    const handleOpenCreateDialog = () => setCreateDialogOpen(true)

    const CreateProjectDialogs: ICreateResourceDialogStateType[] = [
        {
            title: 'Create Project',
            disableBackButton: true,
            maxWidth: 'lg',
            component: (
                <CreateProjectDialog
                    onClose={handleCloseCreateDialog}
                    onConfirm={(id?: number) => {
                        if (id) navigator('/project/' + id + '/surveys')
                        handleCloseCreateDialog()
                    }}
                />
            ),
        },
    ]

    const dialogState = useMemo<ICreateResourceDialogStateType>(() => {
        return CreateProjectDialogs[createDialogStep]
    }, [createDialogStep])

    if (user === null) return null

    const drawerContent = GeneralDrawerNavigation(user.roles)

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack spacing={1} width="100%" height="100%">
                    {user.roles.includes('admin') && (
                        <NewProjectStrip
                            templates={templates}
                            tagCategories={templatesTags}
                            onCreate={handleOpenCreateDialog}
                            resourceName="Project"
                        />
                    )}
                    <ProjectsPanel
                        resources={projects}
                        setResources={setProjects}
                        tagCategories={projectTags}
                        resourceName="project"
                        isLoading={isLoading}
                        sortOptions={['Title', 'Nodes', 'Surveys', 'Completed Surveys', 'Reports', 'Created on']}
                    />
                </Stack>
            </PageCard.Body>

            {/* Create project Dialog
				========================================= */}
            <NavigableDialog
                title={dialogState.title}
                open={createDialogOpen}
                onClose={handleCloseCreateDialog}
                backHandler={dialogState.onBack}
                disableBackButton={dialogState.disableBackButton}
                fullWidth={true}
                maxWidth={dialogState.maxWidth}
                scroll="body"
            >
                {dialogState.component}
            </NavigableDialog>
        </PageCard>
    )
}

export default GeneralDashboard
