import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react'
import * as Survey from 'survey-core'
import 'survey-core/survey.i18n.js'
import 'survey-creator-core/survey-creator-core.i18n.js'
import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'
import { GetSurveyJsonService, UpdateSurveyJsonService } from 'services/SurveyService'
import { useNavigate, useParams } from 'react-router-dom'
import { registerAddressQuestion } from 'features/survey-designer/custom-questions/AddressQestion'
import { registerNetworkQuestion } from 'features/survey-designer/custom-questions/NetworkQuestion'
import { SurveyInstanceContext } from 'contexts/SurveyInstanceContext'
import registerFilterEditor from './custom-properties/FilterDialog'
import StyledDialog from 'components/dialog/StyledDialog'
import AddQuestionDialog from './question-bank/AddQuestionDialog'
import QuestionBankDialog from './question-bank/QuestionBankDialog'
import { QuestionBankType } from 'services/QuestionBankApi'
import registerHtmlEditor from './custom-properties/HtmlEditor'
import { registerMarkdown } from './helpers/FormattingHelpr'
import { HorizontalAlign, SurveyPDF } from 'survey-pdf'
import SNALogo from 'assets/images/sna-toolbox-logo.png'
import { SurveyContext } from 'contexts/SurveyContex'
import { ProjectContext } from 'contexts/ProjectContext'
import { PERMISSIONS } from 'helpers/constants'
import { Box } from '@mui/system'

const creatorOptions = {
    showLogicTab: true,
    isAutoSave: false,
    showTranslationTab: true,
    haveCommercialLicense: true,
}

let saveTimeout: NodeJS.Timeout

const registerOnNewLineOption = (creator: SurveyCreator) => {
    creator.onDefineElementMenuItems.add((sender, options: any) => {
        if (options.obj.startWithNewLine === undefined) return
        var question = options.obj
        var barItem = {
            id: 'startWithNewLine',
            css: new Survey.ComputedUpdater(() => (question.startWithNewLine ? 'sv-action-bar-item--secondary' : '')),
            title: 'On new line',
            iconName: new Survey.ComputedUpdater(() => {
                if (question.startWithNewLine) {
                    return 'icon-new-line'
                }
                return 'icon-one-line'
            }),
            iconSize: 20,
            action: () => {
                question.startWithNewLine = !question.startWithNewLine
            },
        }
        var index = -1
        for (var i = 0; i < options.items.length; i++) {
            if (options.items[i].id === 'delete') {
                index = i
                break
            }
        }
        if (index > -1) {
            options.items.splice(index, 0, barItem)
        } else {
            options.items.push(barItem)
        }
    })
}

const registerAddToNode = () => {
    const classes = [
        'image',
        'text',
        'expression',
        'comment',
        'file',
        'boolean',
        'radiogroup',
        'rating',
        'address_question',
        'dropdown',
    ]

    classes.forEach((className) => {
        Survey.Serializer.addProperty(className, {
            name: 'addToNode:boolean',
            displayName: 'Update Node',
            default: false,
            category: 'general',
        })

        Survey.Serializer.addProperty(className, {
            name: 'nodeField',
            displayName: 'Node Field',
            default: 'others',
            category: 'general',
            dependsOn: ['addToNode'],
            visibleIf: function (obj: any) {
                return obj.addToNode
            },
            choices: ['others', 'name', 'email', 'mobile'],
        })
    })
}

const registerPanelDynamicNetowrkDetails = () => {
    Survey.Serializer.addProperty('paneldynamic', {
        name: 'networkDetailsMode:boolean',
        default: false,
        category: 'general',
        visibleIndex: 0,
        onSetValue: function (obj: any, value: any) {
            obj.setPropertyValue('networkDetailsMode', value)
            if (value) {
                obj.setPropertyValue('allowAddPanel', false)
                obj.setPropertyValue('allowRemovePanel', false)
                obj.setPropertyValue('panelCount', 0)
            }
        },
    })

    Survey.Serializer.addProperty('paneldynamic', {
        name: 'networkQuestion:multiplevalues',
        choices: (obj: any, choicesCallback: any) => {
            const result = obj
                .getSurvey()
                .getAllQuestions()
                .filter((x: any) => x.getType() === 'networkquestion')
                .map((x: any) => x.name)
            choicesCallback(result)
        },
        default: [],
        category: 'general',
        visibleIndex: 1,
    })

    // Properties to override
    const propertiesToOverride = [
        'panelCount',
        'confirmDelete',
        'maxPanelCount',
        'minPanelCount',
        'allowAddPanel',
        'allowRemovePanel',
        'panelAddText',
        'panelRemoveText',
        'confirmDeleteText',
    ]

    propertiesToOverride.forEach((prop) => {
        const property = Survey.Serializer.getProperty('paneldynamic', prop)
        if (!property) return
        property.addDependedProperty('networkDetailsMode')
        property.enableIf = function (obj: any) {
            return obj.networkDetailsMode !== true
        }
        property.visibleIf = function (obj: any) {
            return obj.networkDetailsMode !== true
        }
    })
}

export default function SurveyDesigner() {
    const modified = useRef<boolean>(false)
    const { fetchSuggestions } = useContext(SurveyInstanceContext)
    const { survey } = useContext(SurveyContext)
    const { hasPermission } = useContext(ProjectContext)
    const { pid, sid } = useParams()

    const [openAddQuestionFromBank, setOpenAddQuestionFromBank] = useState<boolean>(false)
    const handleCloseQuestionBank = () => setOpenAddQuestionFromBank(false)
    const handleOpeneQuestionBank = () => setOpenAddQuestionFromBank(true)

    const [openAddQuestionToBank, setOpenAddQuestionToBank] = useState<Survey.Question | null>(null)
    const handleCloseAddQuestion = () => setOpenAddQuestionToBank(null)
    const handleOpeneAddQuestion = (question: Survey.Question) => setOpenAddQuestionToBank(question)
    const [lastSaved, setLastSaved] = useState<Date>()

    const canEditSurveyQuestions = useMemo(() => {
        return hasPermission(PERMISSIONS.survey.ManipulateSurveyQuestions)
    }, [hasPermission])

    const surveyCreator = useMemo<SurveyCreator>(() => {
        Survey.CustomWidgetCollection.Instance.clear()
        registerFilterEditor()
        registerHtmlEditor()
        registerAddressQuestion()
        registerNetworkQuestion()
        registerAddToNode()
        registerPanelDynamicNetowrkDetails()

        let creator = new SurveyCreator({
            ...creatorOptions,
            showJSONEditorTab: canEditSurveyQuestions,
            showLogicTab: canEditSurveyQuestions,
            showTranslationTab: canEditSurveyQuestions,
            showDesignerTab: canEditSurveyQuestions,
        })

        //register markdown
        creator.onDesignerSurveyCreated.add((sender, options) => {
            registerMarkdown(options.survey)
        })

        creator.onTestSurveyCreated.add((sender, options) => {
            registerMarkdown(options.survey)
        })

        creator.onModified.add(() => {
            modified.current = true
        })

        creator.toolbox.changeCategories([
            {
                name: 'image',
                category: 'Read-only',
            },
            {
                name: 'html',
                category: 'Read-only',
            },
            {
                name: 'expression',
                category: 'Read-only',
            },
            {
                name: 'dropdown',
                category: 'Select',
            },
            {
                name: 'checkbox',
                category: 'Select',
            },
            {
                name: 'radiogroup',
                category: 'Select',
            },
            {
                name: 'rating',
                category: 'Select',
            },
            {
                name: 'imagepicker',
                category: 'Select',
            },
            {
                name: 'paneldynamic',
                category: 'Panels',
            },
            {
                name: 'ranking',
                category: 'Select',
            },
            {
                name: 'panel',
                category: 'Panels',
            },
            {
                name: 'paneldynamic',
                category: 'Panels',
            },
            {
                name: 'matrix',
                category: 'Matrix',
            },
            {
                name: 'multipletext',
                category: 'Matrix',
            },
            {
                name: 'matrixdropdown',
                category: 'Matrix',
            },
            {
                name: 'matrixdynamic',
                category: 'Matrix',
            },
            {
                name: 'address_question',
                category: 'Select',
            },
            {
                name: 'networkquestion',
                category: 'Network',
            },
        ])
        //Select the order table component
        creator.selectedElement = creator.survey.getAllQuestions()[0]
        //Show property grid
        creator.rightContainerActiveItem('property-grid')

        registerOnNewLineOption(creator)

        //Add an option to get a pdf version of the survey
        creator.toolbarItems.push(
            new Survey.Action({
                id: 'download-pdf',
                visible: true,
                title: 'Download PDF',
                showTitle: false,
                iconName: 'icon-pdf',
                css: 'sv-action-bar-item--secondary',
                action: function () {
                    downloadPDF()
                },
            })
        )

        /***********
         * Question Bank Configuration
         ************/
        //add a new property to each question toolbar
        creator.onDefineElementMenuItems.add((sender, options) => {
            var question: any = options.obj
            var barItem = {
                id: 'addQuestionBank',
                css: 'sv-action-bar-item--secondary',
                title: 'Question Bank',
                iconName: 'add-box',
                iconSize: 24,
                action: () => {
                    handleOpeneAddQuestion(question)
                },
            }
            options.items.push(barItem)
        })
        //Add an option to insert from question bank in the top bar
        if (canEditSurveyQuestions)
            creator.toolbarItems.push(
                new Survey.Action({
                    id: 'add-from-question-bank',
                    visible: true,
                    title: 'Insert from question bank',
                    showTitle: false,
                    iconName: 'add-box',
                    css: 'sv-action-bar-item--secondary',
                    action: function () {
                        handleOpeneQuestionBank()
                    },
                })
            )
        else {
            // if user can't edit survey questions, fetch the suggestion as the onActiveTabChanged will not be triggered
            fetchSuggestions()
        }

        //when tab changed, it tab is survey, fetch suggestions
        creator.onActiveTabChanged.add((sender, options) => {
            if (options.tabName === 'test' && pid && sid) {
                fetchSuggestions()
            }
            return true
        })

        creator.autoSaveDelay = 2000

        creator.showSaveButton = true

        return creator
    }, [])

    const saveSurvey = (saveNo: any, callback: any) => {
        if (!pid || !sid) {
            callback(saveNo, false)
            return
        }
        UpdateSurveyJsonService(Number.parseInt(pid), Number.parseInt(sid), surveyCreator.text)
            .then((res) => {
                if (res.success) {
                    modified.current = false
                    callback(saveNo, true)
                    setLastSaved(new Date())
                } else {
                    callback(saveNo, false)
                }
            })
            .catch((err) => {
                callback(saveNo, false)
            })
    }

    const configureSurveyCreator = () => {
        //auto save survey
        surveyCreator.saveSurveyFunc = (saveNo: any, callback: any) => {
            saveSurvey(saveNo, callback)
        }
    }

    const beforeunloadEventListener = (ev: BeforeUnloadEvent) => {
        if (modified.current) {
            ev.preventDefault()
            return (ev.returnValue = 'Are you sure you want to close?')
        }
    }

    const downloadPDF = () => {
        const surveyPDF = new SurveyPDF(surveyCreator.text, {
            htmlRenderAs: 'image',
            haveCommercialLicense: true,
            fontSize: 14,
            margins: {
                top: 18,
                bot: 18,
            },
            format: [210, 297],
        })
        let htmlQuestions = surveyPDF
            .getAllQuestions(true)
            .filter((p) => p.getType() === 'html') as Survey.QuestionHtmlModel[]
        const regex = /src=('|").*('|")/gm
        for (let q of htmlQuestions) {
            let m
            while ((m = regex.exec(q.html)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    if (match.includes('http')) {
                        const url = match.substring(5, match.length - 1)
                        console.log(url)
                    }
                })
            }
        }

        surveyPDF.onRenderFooter.add(function (survey, canvas) {
            canvas.drawImage({
                base64: SNALogo,
                horizontalAlign: HorizontalAlign.Left,
                width: 140 * 0.6,
                height: 30 * 0.6,
                margins: {
                    left: (canvas.rect.yBot - canvas.rect.yTop) * 0.2,
                },
            })
            canvas.drawText({
                text: '  Generated via SNA Toolbox ',
                fontSize: 10,
                horizontalAlign: HorizontalAlign.Center,
            })
            canvas.drawText({
                text: '\nhttps://www.snatoolbox.com',
                fontSize: 10,
                horizontalAlign: HorizontalAlign.Center,
            })
            canvas.drawText({
                text: canvas.pageNumber + '/' + canvas.countPages,
                fontSize: 10,
                horizontalAlign: HorizontalAlign.Right,
                margins: {
                    right: 12,
                },
            })
        })

        surveyPDF.save(`${survey?.title || 'survey'}.pdf`)
    }

    useEffect(() => {
        window.addEventListener('beforeunload', beforeunloadEventListener)

        configureSurveyCreator()
        if (sid && pid) {
            GetSurveyJsonService(parseInt(pid), parseInt(sid)).then((res) => {
                if (res.success && res.data != null) {
                    surveyCreator.JSON = res.data
                }
            })
        }
        return () => {
            window.removeEventListener('beforeunload', beforeunloadEventListener)
        }
    }, [pid, sid, surveyCreator])

    const insertQuestion = (question: QuestionBankType) => {
        let addedQuestion = surveyCreator.currentPage?.addNewQuestion(question.question.type, question.name + ' copy')
        addedQuestion && addedQuestion.fromJSON(question.question)
        handleCloseQuestionBank()
    }

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <svg style={{ display: 'none' }}>
                <symbol viewBox="0 0 20 20" height="20" width="20" id="icon-new-line">
                    <path d="M18 0H2C0.9 0 0 0.9 0 2V8C0 9.1 0.9 10 2 10H18C19.1 10 20 9.1 20 8V2C20 0.9 19.1 0 18 0ZM18 8H2V2H18V8ZM12 6H4V4H12V6ZM18 12H2C0.9 12 0 12.9 0 14V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V14C20 12.9 19.1 12 18 12ZM18 20H2V14H18V20ZM12 18H4V16H12V18Z" />
                </symbol>
                <symbol viewBox="0 0 20 20" height="20" width="20" id="icon-one-line">
                    <path d="M18 0H2C0.9 0 0 0.9 0 2V10C0 11.1 0.9 12 2 12H18C19.1 12 20 11.1 20 10V2C20 0.9 19.1 0 18 0ZM18 10H2V2H18V10ZM12 7H4V5H12V7Z" />
                </symbol>
                <symbol height="24" viewBox="0 0 24 24" width="24" id="add-box">
                    <g>
                        <rect fill="none" height="24" width="24" />
                    </g>
                    <g>
                        <g />
                        <g>
                            <path d="M17,19.22H5V7h7V5H5C3.9,5,3,5.9,3,7v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-7h-2V19.22z" />
                            <path d="M19,2h-2v3h-3c0.01,0.01,0,2,0,2h3v2.99c0.01,0.01,2,0,2,0V7h3V5h-3V2z" />
                            <rect height="2" width="8" x="7" y="9" />
                            <polygon points="7,12 7,14 15,14 15,12 12,12" />
                            <rect height="2" width="8" x="7" y="15" />
                        </g>
                    </g>
                </symbol>
                <symbol height="24" viewBox="0 -960 960 960" width="24" id="icon-pdf">
                    <path d="M331-431h37v-83h48q15.725 0 26.362-10.638Q453-535.275 453-551v-48q0-15.725-10.638-26.362Q431.725-636 416-636h-85v205Zm37-120v-48h48v48h-48Zm129 120h84q15 0 26-10.638 11-10.637 11-26.362v-131q0-15.725-11-26.362Q596-636 581-636h-84v205Zm37-37v-131h47v131h-47Zm133 37h37v-83h50v-37h-50v-48h50v-37h-87v205ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-620h60v620h620v60H140Zm120-740v560-560Z" />
                </symbol>
            </svg>
            <SurveyCreatorComponent creator={surveyCreator} key="nn" />
            <StyledDialog open={openAddQuestionFromBank} onClose={handleCloseQuestionBank} fullWidth maxWidth="lg">
                <QuestionBankDialog onClose={handleCloseQuestionBank} onConfirm={insertQuestion} />
            </StyledDialog>
            <StyledDialog open={openAddQuestionToBank != null} onClose={handleCloseAddQuestion} fullWidth maxWidth="lg">
                <AddQuestionDialog onClose={handleCloseAddQuestion} question={openAddQuestionToBank} />
            </StyledDialog>
        </Box>
    )
}
