//* ======= Libraries
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { isEqual } from 'lodash'
import {
    Stack,
    Box,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Typography,
    FormHelperText,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
//* ======= Components and features
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
import {
    TableDefinitionAdvancedNumericType,
    TableDefinitionAdvancedStringType,
    TableDefinitionAdvancedType,
    TableDefinitionColumn,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import {
    AGGREGATION_OPTIONS,
    DECIMAL_PRECISION_OPTIONS,
} from 'features/report-designer/helpers/reportDesigner.constants'
//* ======= Assets and styles
import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import AddIcon from '@mui/icons-material/Add'
import ColorPicker from 'components/widgets/ColorPicker'

export const TableDefinitionColumnItemDragTypeValue = 'TABLE_DEFINITION_COLUMN_ITEM'

type CustomDragItemType = {
    dragId: TableDefinitionColumn['id']
}

type Props = {
    data: TableDefinitionColumn
    fieldOptions: FlexibleSelectOptionType[]
    onUpdate: (data: TableDefinitionColumn) => void
    onRemove: (columnId: TableDefinitionColumn['id']) => void
    getPositionIndex: (id: TableDefinitionColumn['id']) => number
    onMove: (id: TableDefinitionColumn['id'], newIndex: number) => void
    onMoveEnd: () => void
    disabled?: boolean
}

function TableDefinitionColumnItem({
    data,
    fieldOptions,
    onUpdate,
    onRemove,
    getPositionIndex,
    onMove,
    onMoveEnd,
    disabled = false,
}: Props) {
    const [{ isDragging }, dragSource, dragPreview] = useDrag<CustomDragItemType, unknown, { isDragging: boolean }>(
        {
            type: TableDefinitionColumnItemDragTypeValue,
            item: () => ({
                dragId: data.id,
            }),
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: disabled === false,
        },
        [data.id, onMove]
    )

    const [, dropTarget] = useDrop<CustomDragItemType>(
        {
            accept: TableDefinitionColumnItemDragTypeValue,
            hover: (item, monitor) => {
                if (item.dragId !== data.id) {
                    const newIndex = getPositionIndex(data.id)

                    onMove(item.dragId, newIndex)
                }
            },
            drop: (item, monitor) => {
                onMoveEnd()
            },
        },
        [getPositionIndex, onMove, onMoveEnd]
    )

    const updateAdvancedDataType = (type: TableDefinitionAdvancedType['dataType']) => {
        if (type !== data.advanced.dataType) {
            onUpdate({
                ...data,
                advanced:
                    type === 'number'
                        ? {
                              dataType: 'number',
                              range: {
                                  min: 0,
                                  max: 100,
                              },
                              valueMapping: {
                                  isEnabled: false,
                                  bins: [],
                              },
                              rankingSettings: {
                                  isEnabled: false,
                                  limit: 5,
                                  mode: 'both',
                              },
                              valuePolarity: 'positive',
                          }
                        : {
                              dataType: 'string',
                              valueMapping: {
                                  isEnabled: false,
                                  categories: [],
                              },
                          },
            })
        }
    }

    const updateAdvancedRange = (field: keyof TableDefinitionAdvancedNumericType['range'], value: number) => {
        if (data.advanced.dataType !== 'number') return
        onUpdate({
            ...data,
            advanced: {
                ...data.advanced,
                range: {
                    ...data.advanced.range,
                    [field]: value,
                },
            },
        })
    }

    const updateAdvancedValuePolarity = (value: TableDefinitionAdvancedNumericType['valuePolarity']) => {
        if (data.advanced.dataType !== 'number') return
        onUpdate({
            ...data,
            advanced: {
                ...data.advanced,
                valuePolarity: value,
            },
        })
    }

    const updateAdvancedRankingSettings = (value: Partial<TableDefinitionAdvancedNumericType['rankingSettings']>) => {
        if (data.advanced.dataType !== 'number') return
        onUpdate({
            ...data,
            advanced: {
                ...data.advanced,
                rankingSettings: {
                    ...data.advanced.rankingSettings,
                    ...value,
                },
            },
        })
    }

    const updateAdvancedMapping = (value: boolean) => {
        if (data.advanced.dataType === 'number')
            onUpdate({
                ...data,
                advanced: {
                    ...data.advanced,
                    valueMapping: {
                        ...data.advanced.valueMapping,
                        isEnabled: value,
                    },
                },
            })
        else {
            onUpdate({
                ...data,
                advanced: {
                    ...data.advanced,
                    valueMapping: {
                        ...data.advanced.valueMapping,
                        isEnabled: value,
                    },
                },
            })
        }
    }

    const addAdvancedMappingRow = (id?: string) => {
        if (data.advanced.dataType === 'number') {
            const newRow = {
                id: uuidv4(),
                upperBound: null,
                value: null,
                color: '#000000ff',
            }

            const newMap = structuredClone(data.advanced.valueMapping.bins)
            const index = id ? newMap.findIndex((row) => row.id === id) : 0
            newMap.splice(index, 0, newRow)

            onUpdate({
                ...data,
                advanced: {
                    ...data.advanced,
                    valueMapping: {
                        ...data.advanced.valueMapping,
                        bins: newMap,
                    },
                },
            })
        } else if (data.advanced.dataType === 'string') {
            const newRow = {
                id: uuidv4(),
                key: '',
                value: '',
                color: '#000000ff',
            }

            const newMap = [...data.advanced.valueMapping.categories]
            const index = id ? newMap.findIndex((row) => row.id === id) : 0
            newMap.splice(index, 0, newRow)

            onUpdate({
                ...data,
                advanced: {
                    ...data.advanced,
                    valueMapping: {
                        ...data.advanced.valueMapping,
                        categories: newMap,
                    },
                },
            })
        }
    }

    const deleteAdvancedMappingRow = (id: string) => {
        if (data.advanced.dataType === 'number')
            onUpdate({
                ...data,
                advanced: {
                    ...data.advanced,
                    valueMapping: {
                        ...data.advanced.valueMapping,
                        bins: data.advanced.valueMapping.bins.filter((row) => row.id !== id),
                    },
                },
            })
        else if (data.advanced.dataType === 'string')
            onUpdate({
                ...data,
                advanced: {
                    ...data.advanced,
                    valueMapping: {
                        ...data.advanced.valueMapping,
                        categories: data.advanced.valueMapping.categories.filter((row) => row.id !== id),
                    },
                },
            })
    }

    const updateAdvancedMappingNumericRow = (
        id: string,
        field: keyof TableDefinitionAdvancedNumericType['valueMapping']['bins'][number],
        value: string | number
    ) => {
        if (data.advanced.dataType !== 'number') return
        onUpdate({
            ...data,
            advanced: {
                ...data.advanced,
                valueMapping: {
                    ...data.advanced.valueMapping,
                    bins: data.advanced.valueMapping.bins.map((row) => {
                        if (row.id === id) {
                            return {
                                ...row,
                                [field]: value,
                            }
                        } else {
                            return row
                        }
                    }),
                },
            },
        })
    }

    const updateAdvancedMappingStringRow = (
        id: string,
        field: keyof TableDefinitionAdvancedStringType['valueMapping']['categories'][number],
        value: string
    ) => {
        if (data.advanced.dataType !== 'string') return
        onUpdate({
            ...data,
            advanced: {
                ...data.advanced,
                valueMapping: {
                    ...data.advanced.valueMapping,
                    categories: data.advanced.valueMapping.categories.map((row, i) => {
                        if (row.id === id) {
                            return {
                                ...row,
                                [field]: value,
                            }
                        } else {
                            return row
                        }
                    }),
                },
            },
        })
    }

    const updateDataField = (
        field: keyof TableDefinitionColumn,
        value: TableDefinitionColumn[keyof TableDefinitionColumn]
    ) => {
        const needsUpdate = isEqual(data[field], value) === false

        if (needsUpdate) {
            onUpdate({
                ...data,
                [field]: value,
            })
        }
    }

    return (
        <Stack
            ref={(node: HTMLDivElement) => dragPreview(dropTarget(node))}
            direction="row"
            alignItems="center"
            gap={2}
        >
            {/* Drag handle
                ========================================= */}
            <Box
                ref={dragSource}
                sx={(theme) => ({
                    flex: '0 0 auto',
                    alignSelf: 'stretch',

                    width: 42,
                    height: '100%',
                    paddingX: theme.spacing(1),
                    overflow: 'hidden',
                    cursor: 'grab',
                })}
            >
                <DragHandleIcon
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Box>

            {/* Fields
                ========================================= */}
            <Stack
                sx={(theme) => ({
                    flex: '1 0 0',

                    gap: theme.spacing(1.5),
                })}
            >
                {/* Aggregation, value, and title fields
                    ========================================= */}
                <Stack direction="row" alignItems="center" gap={1.5}>
                    {/* Aggregation method
                        NOTE:
                        We manually add the "aggregation" property and its default value when adding a new column.
                        At the moment of this writing, only data definition "mode" of "aggregation"
                        should have this field and it is "undefined" in other modes.
                        ========================================= */}
                    {data.aggregation !== undefined && (
                        <BaseSelectWithLabel
                            label="Aggregation Method"
                            value={data.aggregation}
                            options={AGGREGATION_OPTIONS}
                            onChange={(value) => updateDataField('aggregation', value)}
                            disabled={disabled}
                            required
                            fullWidth
                            size="small"
                            sx={{
                                maxWidth: '50%',
                            }}
                        />
                    )}

                    {/* Field
                        ========================================= */}
                    <FlexibleSelect
                        label="Value"
                        options={fieldOptions}
                        value={data.field}
                        onChange={(value) => updateDataField('field', value)}
                        disabled={disabled}
                        required
                        fullWidth
                        sx={{
                            maxWidth: '50%',
                        }}
                    />

                    {/* Title field
                        ========================================= */}
                    <BaseFilledTextField
                        label="Title"
                        defaultValue={data.title}
                        onBlur={(evt) => updateDataField('title', evt.target.value.trim())}
                        disabled={disabled}
                        fullWidth
                        size="small"
                        sx={{
                            maxWidth: '50%',
                        }}
                    />
                </Stack>

                {/* Description and decimal precision
                    ========================================= */}
                <Stack direction="row" alignItems="center" gap={1.5}>
                    {/* Description
                        ========================================= */}
                    <BaseFilledTextField
                        label="Description"
                        defaultValue={data.description}
                        onBlur={(evt) => updateDataField('description', evt.target.value.trim())}
                        disabled={disabled}
                        fullWidth
                        size="small"
                    />

                    {/* Decimal precision field
                        ========================================= */}
                    <BaseSelectWithLabel
                        label="Decimals"
                        options={[{ label: '(default)', value: undefined }, ...DECIMAL_PRECISION_OPTIONS]}
                        value={data.decimalPrecision}
                        onChange={(value) => updateDataField('decimalPrecision', value)}
                        disabled={disabled}
                        size="small"
                        fullWidth
                        sx={{
                            maxWidth: '32%',
                        }}
                    />
                </Stack>
                <StyledWidgetAccordion title="Advanced">
                    <Stack gap={1}>
                        {/* Type radio group*/}
                        <FormControl>
                            <FormLabel>Data Type</FormLabel>
                            <RadioGroup
                                row
                                value={data.advanced.dataType}
                                onChange={(e, value) =>
                                    updateAdvancedDataType(value as TableDefinitionAdvancedType['dataType'])
                                }
                            >
                                <FormControlLabel value="number" control={<Radio />} label="Number" />
                                <FormControlLabel value="string" control={<Radio />} label="Text" />
                            </RadioGroup>
                        </FormControl>

                        {/* numeric settings */}
                        {data.advanced.dataType === 'number' && (
                            <>
                                {/* value Polarity*/}
                                <FormControl>
                                    <FormLabel>Value Direction</FormLabel>
                                    <RadioGroup
                                        row
                                        value={data.advanced.valuePolarity}
                                        onChange={(e, value) =>
                                            updateAdvancedValuePolarity(
                                                value as TableDefinitionAdvancedNumericType['valuePolarity']
                                            )
                                        }
                                    >
                                        <FormControlLabel value="positive" control={<Radio />} label="Positive" />
                                        <FormControlLabel value="negative" control={<Radio />} label="Negative" />
                                    </RadioGroup>
                                    <FormHelperText>
                                        Choose whether a higher value in this field indicates a positive or negative
                                        outcome.
                                    </FormHelperText>
                                </FormControl>
                                {/* Range */}
                                {/* // ToDo add logic to validate max<= min  */}
                                <Stack>
                                    <FormLabel>Range</FormLabel>
                                    <Stack direction={'row'} gap={1}>
                                        <BaseFilledTextField
                                            label="Min"
                                            type="number"
                                            defaultValue={data.advanced.range.min}
                                            onBlur={(e) => updateAdvancedRange('min', Number(e.target.value))}
                                        />
                                        <BaseFilledTextField
                                            label="Max"
                                            type="number"
                                            defaultValue={data.advanced.range.max}
                                            onBlur={(e) => updateAdvancedRange('max', Number(e.target.value))}
                                        />
                                    </Stack>
                                </Stack>

                                {/* Ranking */}
                                <FormControl>
                                    <FormControlLabel
                                        checked={data.advanced.rankingSettings.isEnabled}
                                        onChange={(e, checked) =>
                                            updateAdvancedRankingSettings({
                                                isEnabled: checked,
                                            })
                                        }
                                        control={<Checkbox />}
                                        label="Ranking"
                                    />
                                    {data.advanced.rankingSettings.isEnabled && (
                                        <Stack direction={'row'} gap={1}>
                                            <BaseFilledTextField
                                                label="Limit"
                                                type="number"
                                                defaultValue={data.advanced.rankingSettings.limit}
                                                onBlur={(e) =>
                                                    updateAdvancedRankingSettings({
                                                        limit: Number(e.target.value),
                                                    })
                                                }
                                            />
                                            <BaseSelectWithLabel
                                                label="Mode"
                                                options={[
                                                    { label: 'Top', value: 'top' },
                                                    { label: 'Bottom', value: 'bottom' },
                                                    { label: 'Both', value: 'both' },
                                                ]}
                                                value={data.advanced.rankingSettings.mode}
                                                onChange={(value) =>
                                                    updateAdvancedRankingSettings({
                                                        mode: value as TableDefinitionAdvancedNumericType['rankingSettings']['mode'],
                                                    })
                                                }
                                                disabled={disabled}
                                                size="small"
                                                fullWidth
                                            />
                                        </Stack>
                                    )}
                                </FormControl>
                            </>
                        )}
                        {/* Data maping */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.advanced.valueMapping.isEnabled}
                                    onChange={(e, checked) => updateAdvancedMapping(checked)}
                                />
                            }
                            label="Data Mapping"
                        />
                        {data.advanced.valueMapping.isEnabled && (
                            <Stack direction="column" gap={1}>
                                <Stack direction={'row'} gap={1} justifyContent="space-between" alignItems="center">
                                    <Typography>Map</Typography>
                                    <Tooltip title="Add Map">
                                        <IconButton onClick={() => addAdvancedMappingRow()}>
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                {data.advanced.dataType === 'number'
                                    ? data.advanced.valueMapping.bins.map((row) => (
                                          <Stack direction={'row'} gap={1} key={row.id}>
                                              <BaseFilledTextField
                                                  label="Upper Bound"
                                                  type="number"
                                                  defaultValue={row.upperBound}
                                                  onBlur={(e) => {
                                                      updateAdvancedMappingNumericRow(
                                                          row.id,
                                                          'upperBound',
                                                          Number(e.target.value)
                                                      )
                                                  }}
                                              />
                                              <BaseFilledTextField
                                                  label="Value"
                                                  defaultValue={row.value}
                                                  onBlur={(e) => {
                                                      updateAdvancedMappingNumericRow(row.id, 'value', e.target.value)
                                                  }}
                                              />
                                              <ColorPicker
                                                  isPopover={true}
                                                  value={row.color}
                                                  varient="minimal"
                                                  onChange={(value) => {
                                                      updateAdvancedMappingNumericRow(
                                                          row.id,
                                                          'color',
                                                          value ?? '#000000ff'
                                                      )
                                                  }}
                                              />
                                              <Tooltip title="Delete">
                                                  <IconButton onClick={() => deleteAdvancedMappingRow(row.id)}>
                                                      <DeleteIcon />
                                                  </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Add" onClick={() => addAdvancedMappingRow(row.id)}>
                                                  <IconButton>
                                                      <AddIcon />
                                                  </IconButton>
                                              </Tooltip>
                                          </Stack>
                                      ))
                                    : data.advanced.valueMapping.categories.map((row) => (
                                          <Stack direction={'row'} gap={1} key={row.id}>
                                              <BaseFilledTextField
                                                  label="Key"
                                                  defaultValue={row.key}
                                                  onBlur={(e) => {
                                                      updateAdvancedMappingStringRow(row.id, 'key', e.target.value)
                                                  }}
                                              />
                                              <BaseFilledTextField
                                                  label="Value"
                                                  defaultValue={row.value}
                                                  onBlur={(e) => {
                                                      updateAdvancedMappingStringRow(row.id, 'value', e.target.value)
                                                  }}
                                              />
                                              <ColorPicker
                                                  isPopover={true}
                                                  value={row.color}
                                                  varient="minimal"
                                                  onChange={(value) => {
                                                      updateAdvancedMappingStringRow(
                                                          row.id,
                                                          'color',
                                                          value ?? '#000000ff'
                                                      )
                                                  }}
                                              />
                                              <Tooltip title="Delete">
                                                  <IconButton onClick={() => deleteAdvancedMappingRow(row.id)}>
                                                      <DeleteIcon />
                                                  </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Add" onClick={() => addAdvancedMappingRow(row.id)}>
                                                  <IconButton>
                                                      <AddIcon />
                                                  </IconButton>
                                              </Tooltip>
                                          </Stack>
                                      ))}
                            </Stack>
                        )}
                    </Stack>
                </StyledWidgetAccordion>
            </Stack>

            {/* Delete button
                ========================================= */}
            <Tooltip title="Delete">
                {/* Intermediary element to capture Tooltip on button's disabled state. */}
                <Box>
                    <IconButton
                        onClick={(evt) => onRemove(data.id)}
                        disabled={disabled}
                        sx={(theme) => ({
                            flexShrink: 0,

                            color: theme.palette.common.ung_pink,
                        })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Tooltip>
        </Stack>
    )
}

export default TableDefinitionColumnItem
