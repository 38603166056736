//* ======= Libraries
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Stack, Box } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { VideoWidgetType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import YouTubeIcon from '@mui/icons-material/YouTube'

type Props = {
    data: VideoWidgetType
    isActive: boolean
    onReady: () => void
}

function VideoWidget({ data, isActive, onReady }: Props) {
    const viewMode = useReportStore((store) => store.viewMode)

    useEffect(() => {
        onReady()
    }, [])

    return data.source === '' ? (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
                padding: 1,
            }}
        >
            <YouTubeIcon
                sx={(theme) => ({
                    width: '90%',
                    height: '90%',

                    color: theme.palette.common.fill_2,
                })}
            />
        </Stack>
    ) : (
        /*	Player wrapper
			========================================= */
        <Box
            sx={{
                width: '100%',
                height: '100%',
                // Allow the first click on the widget to select the widget and
                // set activeWidgetId and if that's set, allow interaction.
                pointerEvents: viewMode === 'design' ? (isActive ? 'auto' : 'none') : undefined,
            }}
        >
            <ReactPlayer url={data.source} controls light width="100%" height="100%" />
        </Box>
    )
}

export default VideoWidget
