import React, { useMemo, useState } from 'react'
import { Box, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import BaseButton from 'components/base/BaseButton'
import SvgIcon from '@mui/material/SvgIcon'

interface ButtonSelectMenuOption {
    label: string
    value: any
    color?: string
    icon?: React.ReactNode
}

interface ButtonSelectMenuProps {
    value: any
    options: ButtonSelectMenuOption[]
    tooltip: string
    disabled?: boolean
    onChange?: (value: any) => void
}

const ButtonSelectMenu: React.FC<ButtonSelectMenuProps> = ({ disabled = false, value, options, tooltip, onChange }) => {
    const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null)

    const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled) return
        setDropdownAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = (option?: ButtonSelectMenuOption) => {
        setDropdownAnchorEl(null)
        if (option) {
            if (onChange) {
                onChange(option.value)
            }
        }
    }

    const selectedOption = useMemo(() => {
        return options.find((o) => o.value === value) ?? options[0]
    }, [value, options])

    return (
        <>
            <Tooltip title={tooltip}>
                <BaseButton
                    variant="contained"
                    style={{ backgroundColor: selectedOption.color }}
                    startIcon={selectedOption.icon}
                    endIcon={!disabled && <ArrowDropDownIcon />}
                    onClick={handleDropdownClick}
                >
                    {selectedOption.label}
                </BaseButton>
            </Tooltip>
            <Menu
                anchorEl={dropdownAnchorEl}
                keepMounted
                open={Boolean(dropdownAnchorEl)}
                onClose={() => handleDropdownClose()}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} onClick={() => handleDropdownClose(option)}>
                        <Stack direction="row" alignItems="center" gap={3} width="100%">
                            {option.icon ? (
                                <SvgIcon style={{ color: option.color }}>{option.icon}</SvgIcon>
                            ) : (
                                option.color && <Box width={24} height={24} bgcolor={option.color} />
                            )}
                            <Typography>{option.label}</Typography>
                            {option.value === selectedOption.value && <CheckIcon />}
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default ButtonSelectMenu
