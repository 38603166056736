//* ======= Libraries
import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import MainDrawer from 'features/drawer/MainDrawer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import ToastHelper from 'helpers/ToastHelper'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { GridColDef } from '@mui/x-data-grid-pro'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import {
    CreateProjectAccount,
    GetProjectAccounts,
    GetProjectRoles,
    ProjectAccountCreateType,
    ProjectAccountType,
    ProjectRoleWithPermissionType,
    RemoveProjectAccount,
    ResendProjectAccountInvitationService,
    UpdateProjectAccountRoleService,
} from 'services/ProjectService'
import { RootContext } from 'contexts/RootContext'
import StyledDialog from 'components/dialog/StyledDialog'
import AddUsersDialog from 'features/add-users-dialog/AddUsersDialog'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import ConfirmDialog from 'features/confirm-dialog/ConfirmDialog'
import { ProjectContext } from 'contexts/ProjectContext'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { IconButton, Tab, Tabs, Tooltip } from '@mui/material'
import BasicSelectInput from 'components/inputs/BasicSelectInput'
import ProjectUserManagment from 'features/project-user/ProjectUserManagment'
import ProjectDataAccessManagment from 'features/project-user/ProjectDataAccessManagment'

interface IProjectUsers {}

function a11yProps(index: number) {
    return {
        id: `project-user-tab-${index}`,
        'aria-controls': `project-user-tabpanel-${index}`,
    }
}

function ProjectUsers({}: IProjectUsers) {
    const { pid } = useParams()
    const { drawerContent, updateProjectRole } = useContext(ProjectContext)
    const { user } = useContext(RootContext)

    const navigate = useNavigate()

    const [roles, setRoles] = useState<ProjectRoleWithPermissionType[]>([])
    const [accounts, setAccounts] = useState<ProjectAccountType[] | null>(null)
    const [item2Remove, setItem2Remove] = useState<number | null>(null)
    const [item2Update, setItem2Update] = useState<{
        id: number
        role: { roleID: number; roleName?: string }
        name: string
    } | null>(null)
    const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false)
    const handleOpenAddUserDialog = () => setAddUserDialogOpen(true)
    const handleCloseAddUserDialog = () => setAddUserDialogOpen(false)
    const [tab, setTab] = useState<0 | 1>(0)
    const handleTabChange = (event: React.SyntheticEvent, newValue: 0 | 1) => setTab(newValue)

    const inviteUserAccount = (users: ProjectAccountCreateType[]) => {
        if (pid) {
            const toast = new ToastHelper({
                errorMessage: 'Error inviting users!',
                successMessage: 'Users invited!',
                loadingMessage: 'Inviting users...',
            })
            CreateProjectAccount(pid, users).then((res) => {
                if (res.success) {
                    setAccounts(res.data)
                    handleCloseAddUserDialog()
                    toast.success()
                } else {
                    toast.fail()
                }
            })
        }
    }

    const resendInvitation = useCallback(
        (id: number) => {
            if (pid) {
                const toast = new ToastHelper({
                    errorMessage: 'Error sending project invitation email!',
                    successMessage: 'Project Invitation Email sent!',
                    loadingMessage: 'Sending project invitation email...',
                })
                ResendProjectAccountInvitationService(pid, id).then((res) => {
                    if (res.success) {
                        toast.success()
                    } else {
                        toast.fail()
                    }
                })
            }
        },
        [pid]
    )

    const updateUserAccount = () => {
        if (pid && item2Update) {
            const toast = new ToastHelper({
                errorMessage: 'Error updating user role!',
                successMessage: 'User role updated!',
                loadingMessage: 'Updating user role...',
            })
            UpdateProjectAccountRoleService(pid, item2Update.id, { roleID: item2Update.role.roleID }).then((res) => {
                if (res.success) {
                    const accountToRemove = accounts?.find((x) => x.primaryKey === item2Update.id)
                    // if the user who's role is getting modified is the logged in user, change the role in project context
                    if (accountToRemove && user?.primaryKey === accountToRemove.account.primaryKey) {
                        // if user not admin or data owner, go back to project page
                        if (![1, 3].includes(item2Update.role.roleID)) {
                            toast.close()
                            navigate(`/project/${pid}`)
                            updateProjectRole(item2Update.role.roleName!)
                            return
                        }
                        updateProjectRole(item2Update.role.roleName!)

                        // get the update roles this user can assign
                        fetchProjectRoles()
                    }
                    setAccounts((pv: ProjectAccountType[] | null) => {
                        if (pv == null) return null
                        const tmp = [...pv]
                        const index = tmp.findIndex((x) => x.primaryKey === item2Update.id)
                        if (index !== -1) {
                            tmp[index].role = {
                                primaryKey: item2Update.role.roleID,
                                roleName: item2Update.role.roleName!,
                            }
                        }
                        setItem2Update(null)
                        return tmp
                    })
                    toast.success()
                } else {
                    toast.fail()
                }
            })
        }
    }

    const removeUserAccount = () => {
        if (pid != null && item2Remove != null) {
            const toast = new ToastHelper({
                errorMessage: 'Error removing user!',
                successMessage: 'User removed!',
                loadingMessage: 'Removing user...',
            })
            RemoveProjectAccount(pid, item2Remove)
                .then((res) => {
                    if (res.success) {
                        toast.success()
                        const accountToRemove = accounts?.find((x) => x.primaryKey === item2Remove)
                        // if the user who's getting removed is the logged in user
                        if (accountToRemove && user?.primaryKey === accountToRemove.account.primaryKey) {
                            toast.close()
                            navigate('/')
                        } else {
                            setAccounts((pa) => (pa == null ? null : pa.filter((p) => p.primaryKey !== item2Remove)))
                        }
                    } else {
                        toast.fail()
                    }
                })
                .finally(() => setItem2Remove(null))
        }
    }

    const fetchProjectRoles = useCallback(() => {
        GetProjectRoles(pid!)
            .then((res) => {
                if (res.success) {
                    setRoles(res.data)
                }
            })
            .catch((e) => setRoles([]))
    }, [pid])

    useEffect(() => {
        if (pid) {
            GetProjectAccounts(pid)
                .then((res) => (res.success ? setAccounts(res.data) : setAccounts([])))
                .catch((e) => setAccounts([]))
            fetchProjectRoles()
        }
    }, [fetchProjectRoles, pid])

    const accountsRows = useMemo(() => {
        if (accounts === null) return []
        return (
            accounts
                // Map each item in 'accounts' to a new object for DataGrid row
                .map((x) => {
                    return {
                        id: x.primaryKey,
                        accountId: x.account.primaryKey,
                        name: x.account.name ? x.account.name : x.account.email,
                        avtar: x.account.avatar,
                        IsActive: x.account.isActive,
                        email: x.account.email,
                        role: x.role.roleName,
                        roleID: x.role.primaryKey,
                    }
                })
                .sort((x, y) => {
                    // make sure the logged in user is always at the top
                    if (x.accountId === user?.primaryKey) return -1
                    if (y.accountId === user?.primaryKey) return 1
                    // sort by name
                    if (x.roleID > y.roleID) return 1
                    if (x.roleID < y.roleID) return -1
                    return 0
                })
        )
    }, [accounts, user?.primaryKey])

    const userColumns = useMemo<GridColDef<any>[]>(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                renderCell: (params) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Avatar
                                alt={params.row.name}
                                src={params.row.avtar}
                                sx={{
                                    height: 40,
                                    width: 40,
                                    marginRight: 2,
                                }}
                            >
                                {params.row.name && params.row.name.toUpperCase().substring(0, 1)}
                            </Avatar>
                            <Typography variant="body1">{params.row.name}</Typography>
                            {params.row.accountId === user?.primaryKey && (
                                <Typography color="common.text_3" variant="body1">
                                    (You)
                                </Typography>
                            )}
                        </Stack>
                    )
                },
            },
            {
                field: 'email',
                headerName: 'Email',
                renderCell: (params) => {
                    return (
                        <Typography color="common.text_3" variant="body1">
                            {params.row.email}
                        </Typography>
                    )
                },
            },
            {
                field: 'roleID',
                headerName: 'Role',
                renderCell: (params) => {
                    if (roles.find((x) => x.primaryKey === params.value)) {
                        return (
                            <Select
                                value={params.value}
                                onChange={(e) => {
                                    const roleID = e.target.value as number
                                    const role = roles.find((x) => x.primaryKey === roleID)
                                    if (role) {
                                        setItem2Update({
                                            id: params.id as number,
                                            role: { roleID: role.primaryKey, roleName: role.roleName },
                                            name: params.row.name,
                                        })
                                    }
                                }}
                                sx={{
                                    width: '16ch',
                                }}
                                input={<BasicSelectInput />}
                                IconComponent={ExpandMoreIcon}
                            >
                                {roles.map((x) => (
                                    <MenuItem key={x.primaryKey} value={x.primaryKey}>
                                        {x.roleName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )
                    } else {
                        return (
                            <Typography color="common.text_3" variant="body1">
                                {params.row.role}
                            </Typography>
                        )
                    }
                },
            },
            {
                field: 'IsActive',
                headerName: 'Active',
                renderCell: (params) => {
                    if (params.value) {
                        return (
                            <Tooltip title="Active">
                                <CheckCircleIcon color="success" />
                            </Tooltip>
                        )
                    } else {
                        return (
                            <Stack direction="row" alignItems="center">
                                <Tooltip title="Awaiting response">
                                    <PendingActionsIcon color="warning" />
                                </Tooltip>
                                {roles.find((x) => x.primaryKey === params.row.roleID) && (
                                    <BaseButton onClick={() => resendInvitation(params.row.id)}>
                                        Resend Invitation
                                    </BaseButton>
                                )}
                            </Stack>
                        )
                    }
                },
            },
            {
                field: 'action',
                headerName: 'Action',
                type: 'action',
                renderCell: (params) => {
                    return (
                        <BaseButton
                            variant="contained"
                            color="warning"
                            disabled={!roles.find((x) => x.primaryKey === params.row.roleID)}
                            sx={{ width: '80px' }}
                            label={params.row.accountId === user?.primaryKey ? 'Leave' : 'Remove'}
                            onClick={() => setItem2Remove(params.row.id)}
                        />
                    )
                },
            },
        ],
        [roles, user, resendInvitation]
    )

    return (
        <PageCard>
            <PageCard.Side>
                <MainDrawer content={drawerContent} />
            </PageCard.Side>

            <PageCard.Body>
                <Stack
                    gap={2}
                    height="100%"
                    width="100%"
                    sx={(theme) => ({
                        paddingTop: 3.5,
                        paddingBottom: 2,
                        px: 4,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* Title and add user button
				        ========================================= */}

                    <Tabs value={tab} onChange={handleTabChange} aria-label="User management tab">
                        <Tab label="User Management" {...a11yProps(0)} />
                        <Tab label="Data Access" {...a11yProps(1)} />
                    </Tabs>

                    {tab === 0 && <ProjectUserManagment />}
                    {tab === 1 && <ProjectDataAccessManagment />}
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}

export default ProjectUsers
