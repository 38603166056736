import { type } from 'os'
import { parseUrl, NodeActionAPIs as Apis } from './Apis'
import { BaseResponseType, request } from './request'

export type NodeActionBasicType = {
    primaryKey: number
    title: string
    node: {
        id: number
        avatar: string
        title: string
    }
    status: 'Pending' | 'InProgress' | 'Resolved' | 'DECLINED'
    priority: 'Low' | 'Medium' | 'High' | 'Critical'
    dueDate?: string
    endDate?: string
    assignedTo?: [
        {
            name: string
            avatar: string
        }
    ]
}

export type NodeActionNoteType = {
    primaryKey: number
    note: string
    type: 'Event' | 'Comment'
    creator: {
        name: string
        avatar: string
    }
    createdDate: string
}

export type NodeActionAccountType = {
    primaryKey: number
    projectAccountID: number
    creator: {
        primaryKey: number
        name: string
        avatar: string
    }
}

export type NodeActionType = {
    primaryKey: number
    title: string
    description?: string
    status: 'Pending' | 'InProgress' | 'Resolved' | 'DECLINED'
    priority: 'Low' | 'Medium' | 'High' | 'Critical'
    dueDate?: string | null
    endDate?: string
    assignedTo?: NodeActionAccountType[]
    notes?: NodeActionNoteType[]
}

export type GetNodeActionsResponseType = {
    success: boolean
    data: NodeActionBasicType[]
}

// * Get All the actions for a given project
export const GetNodeActionsService: (
    pid: string | number,
    showMessage?: boolean
) => Promise<GetNodeActionsResponseType> = async (pid, showMessage = false) => {
    return request(parseUrl(Apis.GetAll, { pid }), 'GET', undefined, undefined, showMessage, undefined, undefined, true)
}

// * Get a specific action
export type NodeActionResponseType = {
    success: boolean
    data: NodeActionType
}

export const GetNodeActionService: (
    pid: string | number,
    nid: string | number,
    aid: string | number,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, showMessage = false) => {
    return request(
        parseUrl(Apis.Get, { pid, nid, aid }),
        'GET',
        undefined,
        undefined,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Update the status of a specific action
export type UpdateNodeActionStatusRequestType = {
    status: 'Pending' | 'InProgress' | 'Resolved' | 'DECLINED'
}
export const UpdateNodeActionStatusService: (
    pid: string | number,
    nid: string | number,
    aid: string | number,
    data: UpdateNodeActionStatusRequestType,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, data, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateNodeActionStatus, { pid, nid, aid }),
        'PUT',
        undefined,
        data,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Update title, priority, or due date of a specific action
export type UpdateNodeActionRequestType = {
    title: string
    priority: 'Low' | 'Medium' | 'High' | 'Critical'
    dueDate?: string | null
    description?: string
}

export const UpdateNodeActionService: (
    pid: string | number,
    nid: string | number,
    aid: string | number,
    data: UpdateNodeActionRequestType,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, data, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateNodeAction, { pid, nid, aid }),
        'PUT',
        undefined,
        data,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Delete a specific action
export const DeleteNodeActionService: (
    pid: string | number,
    nid: string | number,
    aid: string | number,
    showMessage?: boolean
) => Promise<BaseResponseType> = async (pid, nid, aid, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteNodeAction, { pid, nid, aid }),
        'DELETE',
        undefined,
        undefined,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Create a new action for a given node
export type CreateNodeActionRequestType = {
    title: string
    priority: 'Low' | 'Medium' | 'High' | 'Critical'
    assignedTo?: number[]
    dueDate?: string | null
    description?: string
}

export const CreateNodeActionService: (
    pid: string | number,
    nid: string | number,
    data: CreateNodeActionRequestType,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, data, showMessage = false) => {
    return request(
        parseUrl(Apis.CreateNodeAction, { pid, nid }),
        'POST',
        undefined,
        data,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Add a note to a specific action
export type AddNodeActionNoteRequestType = {
    note: string
}

export const AddNodeActionNoteService: (
    pid: number | string,
    nid: number | string,
    aid: number | string,
    data: AddNodeActionNoteRequestType,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, data, showMessage = false) => {
    return request(
        parseUrl(Apis.AddNodeActionNote, { pid, nid, aid }),
        'POST',
        undefined,
        data,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Update a note of a specific action
export type UpdateNodeActionNoteRequestType = {
    note: string
}

export const UpdateNodeActionNoteService: (
    pid: number | string,
    nid: number | string,
    aid: number | string,
    noteid: number | string,
    data: UpdateNodeActionNoteRequestType,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, noteid, data, showMessage = false) => {
    return request(
        parseUrl(Apis.UpdateNodeActionNote, { pid, nid, aid, noteid }),
        'PUT',
        undefined,
        data,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Delete a note from a specific action
export const DeleteNodeActionNoteService: (
    pid: number | string,
    nid: number | string,
    aid: number | string,
    noteid: number | string,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, noteid, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteNodeActionNote, { pid, nid, aid, noteid }),
        'DELETE',
        undefined,
        undefined,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Add an assignee to a specific action
export type AddNodeActionAssigneeRequestType = {
    projectAccountID: number
}

export const AddNodeActionAssigneeService: (
    pid: number | string,
    nid: number | string,
    aid: number | string,
    data: AddNodeActionAssigneeRequestType,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, data, showMessage = false) => {
    return request(
        parseUrl(Apis.AddNodeActionAssignee, { pid, nid, aid }),
        'POST',
        undefined,
        data,
        showMessage,
        undefined,
        undefined,
        true
    )
}

// * Delete an assignee from a specific action
export const DeleteNodeActionAssigneeService: (
    pid: number | string,
    nid: number | string,
    aid: number | string,
    assigneeid: number | string,
    showMessage?: boolean
) => Promise<NodeActionResponseType> = async (pid, nid, aid, assigneeid, showMessage = false) => {
    return request(
        parseUrl(Apis.DeleteNodeActionAssignee, { pid, nid, aid, assigneeid }),
        'DELETE',
        undefined,
        undefined,
        showMessage,
        undefined,
        undefined,
        true
    )
}
