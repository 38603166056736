//* ======= Libraries
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { debounce, isEqual } from 'lodash'
import { Merge } from 'type-fest'
import {
    Stack,
    Box,
    Paper,
    InputBase,
    List,
    ListItemButton,
    ListItemText,
    Typography,
    Popper,
    Grow,
    ClickAwayListener,
    IconButton,
    Tooltip,
    ListSubheader,
} from '@mui/material'
//* ======= Components and features
import {
    FlexibleSelectOptionType,
    FlexibleSelectOptionItemType,
    FlexibleSelectGroupedOptionType,
    FlexibleSelectUngroupedOptionType,
} from 'components/group-field-selector/FlexibleSelect'
//* ======= Custom logic
//* ======= Assets and styles
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded'
import SearchIcon from '@mui/icons-material/Search'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'

type SelectedItemType = Merge<
    {
        group: FlexibleSelectGroupedOptionType['group'] | null
        groupListIndex: number | null
        itemsListIndex: number | null
    },
    FlexibleSelectOptionItemType
>

type MenuProps = {
    width?: number
    maxContentHeight?: number
}

type Props = {
    containerElement?: React.RefObject<HTMLElement> | HTMLElement
    anchorElement: HTMLElement
    isOpen: boolean
    options: FlexibleSelectOptionType[]
    selectedItem: SelectedItemType
    onClose: () => void
    onSelectItem: (value: any) => void
    disabled?: boolean
    hasClearButton?: boolean
    onClearValue: () => void
    menuProps?: MenuProps
}

function FlexibleSelectMenu({
    containerElement,
    anchorElement,
    isOpen,
    options,
    selectedItem,
    onClose,
    onSelectItem,
    disabled,
    hasClearButton,
    onClearValue,
    menuProps = {
        maxContentHeight: 260,
    },
}: Props) {
    const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(
        selectedItem.groupListIndex !== -1 ? selectedItem.groupListIndex : null
    )

    const [query, setQuery] = useState('')

    const isGroupedOption = (option: FlexibleSelectOptionType) => {
        if (Object.prototype.hasOwnProperty.call(option, 'group')) {
            return true
        } else {
            return false
        }
    }

    const searchResultOptions = useMemo(() => {
        const result: FlexibleSelectOptionType[] = []

        // If there was a search query, we filter the options based on their "items" which match the query.
        if (query !== '') {
            for (const _option of options) {
                // Filter out disabled groups on search.
                if (_option.disabled === true) {
                    continue
                }

                // Grouped option
                if (isGroupedOption(_option)) {
                    const groupedOption = _option as FlexibleSelectGroupedOptionType

                    const matchingItems: FlexibleSelectGroupedOptionType['items'] = []

                    for (const _item of groupedOption.items) {
                        if (_item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()) === true) {
                            matchingItems.push(_item)
                        }
                    }

                    if (matchingItems.length !== 0) {
                        result.push({
                            ...groupedOption,
                            items: [...matchingItems],
                        })
                    }
                }
                // Ungrouped option
                else {
                    const ungroupedOption = _option as FlexibleSelectUngroupedOptionType

                    if (ungroupedOption.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()) === true) {
                        result.push(ungroupedOption)
                    }
                }
            }
        }

        return result
    }, [options, query])

    const [currentPanelIndex, setCurrentPanelIndex] = useState(selectedGroupIndex !== null ? 2 : 1)
    // This state creates a delay before unmounting panels that are not visible so the transition animation is more detectable
    // by the user. Without it, the content of previous panels would be immediately unmounted and create a blank space.
    const [keepOffPanelsMounted, setKeepOffPanelsMounted] = useState(false)

    /* =========================================
     * Refs
     */

    // Search input field. Since the input is uncontrolled, we need this ref to make changes to its value.
    const searchInputRef = useRef<HTMLInputElement>(null)
    // Panels' container element ref which acts as the anchor for "Slide" transitions.
    const menuPanelsContainerRef = useRef<HTMLDivElement>(null)
    // Search result panel's sliding wrapper element. Used for calculating the height of upper-layer parent's height.
    const searchPanelRef = useRef<HTMLDivElement>(null)
    const groupsSectionRefs = useRef<{
        // Group panel's sliding wrapper element. Used for calculating the height of upper-layer parent's height.
        panel: HTMLDivElement | null
        // Group panel's overflow container. Used for scrolling purposes.
        overflowContainer: HTMLUListElement | null
        // Selected group element which resides inside groups panel. Used for scrolling purposes.
        selectedElement: HTMLLIElement | null
    }>({
        panel: null,
        overflowContainer: null,
        selectedElement: null,
    })
    const itemsSectionRefs = useRef<{
        // Items panel's sliding wrapper element. Used for calculating the height of upper-layer parent's height.
        panel: HTMLDivElement | null
        // Items panel's overflow container. Used for scrolling purposes.
        overflowContainer: HTMLUListElement | null
        // Selected item element which resides inside items panel. Used for scrolling purposes.
        selectedElement: HTMLLIElement | null
    }>({
        panel: null,
        overflowContainer: null,
        selectedElement: null,
    })

    // Set the selected group index based on selected value, which updates the panel UI.
    useEffect(() => {
        if (isOpen) {
            // If a "grouped" option is selected, then "selectedItem.groupListIndex" has a non-null value.
            // Setting "selectedGroupIndex" updates the panels UI to "Items" list.
            if (selectedItem.groupListIndex !== null) {
                if (selectedItem.groupListIndex !== selectedGroupIndex) {
                    setSelectedGroupIndex(selectedItem.groupListIndex)
                }
            }
            // Otherwise, we set the panel UI to the "Groups" list.
            // If there is an "ungrouped" value selected, then "selectedItem.itemsListIndex" has a non-null value
            // and UI will scroll to it. If not, we still stay on the "Groups" list.
            else {
                setSelectedGroupIndex(null)
            }
        }

        return () => {}
    }, [isOpen])

    // Update current panel index state after search query or selected group values change.
    // After updating current panel index, another effect handles updating the height for the panels container.
    useEffect(() => {
        if (query !== '') {
            // Search panel index
            setCurrentPanelIndex(0)
        } else if (selectedGroupIndex === null) {
            // Groups panel index
            setCurrentPanelIndex(1)
        } else if (selectedGroupIndex !== null) {
            // Items panel index
            setCurrentPanelIndex(2)
        }

        setKeepOffPanelsMounted(true)

        // Unmount off-screen panels after a delay.
        const unmountTimerId = setTimeout(() => {
            setKeepOffPanelsMounted(false)
        }, 500)

        return () => {
            clearTimeout(unmountTimerId)
        }
    }, [query, selectedGroupIndex])

    // Update panels container height after any change that affects the height of content for each panels.
    useEffect(() => {
        if (menuPanelsContainerRef.current !== null) {
            // Search panel index
            if (currentPanelIndex === 0) {
                if (searchPanelRef.current !== null) {
                    menuPanelsContainerRef.current.style.height = searchPanelRef.current.offsetHeight + 'px'
                }
            }
            // Groups panel index
            else if (currentPanelIndex === 1) {
                if (groupsSectionRefs.current.panel !== null) {
                    menuPanelsContainerRef.current.style.height = groupsSectionRefs.current.panel.offsetHeight + 'px'
                }
            }
            // Items panel index
            else if (currentPanelIndex === 2) {
                if (itemsSectionRefs.current.panel !== null) {
                    menuPanelsContainerRef.current.style.height = itemsSectionRefs.current.panel.offsetHeight + 'px'
                }
            }
        }
    }, [query, currentPanelIndex])

    const closeMenu = () => {
        // Close the menu and trigger closing animation.
        onClose()

        // Reset search query, which updates the panel UI.
        if (query !== '') {
            setQuery('')
        }
    }

    const clearValue = () => {
        onClearValue()

        // Reset search query, which updates the panel UI.
        if (query !== '') {
            setQuery('')
        }
    }

    const selectItem = (selectedValue: FlexibleSelectOptionItemType['value']) => {
        onSelectItem(selectedValue)

        // Reset search query, which updates the panel UI.
        if (query !== '') {
            setQuery('')
        }
    }

    const onSearch = useCallback(
        debounce((searchQuery: string) => {
            if (searchQuery.trim() !== query) {
                setQuery(searchQuery.trim())
            }
        }, 250),
        [query]
    )

    const scrollSelectedValueToView = ({
        target,
        isSmooth = false,
    }: {
        target: 'group' | 'item'
        isSmooth?: boolean
    }) => {
        if (isOpen === true) {
            // Scroll to the selected group element
            if (
                target === 'group' &&
                groupsSectionRefs.current.overflowContainer !== null &&
                groupsSectionRefs.current.selectedElement !== null
            ) {
                // Calculate the desired scroll position
                const selectedGroupRect = groupsSectionRefs.current.selectedElement.getBoundingClientRect()
                const overflowContainerRect = groupsSectionRefs.current.overflowContainer.getBoundingClientRect()
                const overflowContainerHeight = groupsSectionRefs.current.overflowContainer.clientHeight

                const scrollPosition =
                    selectedGroupRect.top -
                    overflowContainerRect.top -
                    overflowContainerHeight / 2 +
                    selectedGroupRect.height / 2

                groupsSectionRefs.current.overflowContainer.scrollTo({
                    top: scrollPosition,
                    behavior: isSmooth ? 'smooth' : 'auto',
                })
            }
            // Scroll to the selected item element
            else if (
                target === 'item' &&
                itemsSectionRefs.current.overflowContainer !== null &&
                itemsSectionRefs.current.selectedElement !== null
            ) {
                // Calculate the desired scroll position
                const selectedItemRect = itemsSectionRefs.current.selectedElement.getBoundingClientRect()
                const overflowContainerRect = itemsSectionRefs.current.overflowContainer.getBoundingClientRect()
                const overflowContainerHeight = itemsSectionRefs.current.overflowContainer.clientHeight

                const scrollPosition =
                    selectedItemRect.top -
                    overflowContainerRect.top -
                    overflowContainerHeight / 2 +
                    selectedItemRect.height / 2

                // Scroll the overflow parent to the new scroll position
                itemsSectionRefs.current.overflowContainer.scrollTo({
                    top: scrollPosition,
                    behavior: isSmooth ? 'smooth' : 'auto',
                })
            }
        }
    }

    return (
        <Popper
            id="flexible-select-popper"
            open={isOpen}
            container={
                containerElement !== undefined && containerElement !== null
                    ? 'current' in containerElement === true
                        ? (containerElement as React.RefObject<HTMLElement>).current
                        : (containerElement as HTMLElement)
                    : undefined
            }
            anchorEl={anchorElement}
            popperOptions={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 2],
                        },
                    },
                ],
            }}
            placement="bottom"
            transition
            sx={(theme) => ({
                // This is needed for scenarios where the select is rendered inside a dialog or modal.
                zIndex: theme.zIndex.tooltip,
            })}
        >
            {({ TransitionProps }) => (
                <Grow
                    {...TransitionProps}
                    onEntered={(node, isAppearing) => {
                        if (selectedGroupIndex === null) {
                            scrollSelectedValueToView({
                                target: 'group',
                                isSmooth: true,
                            })
                        } else {
                            scrollSelectedValueToView({
                                target: 'item',
                                isSmooth: true,
                            })
                        }
                    }}
                    style={{
                        transformOrigin: 'top center',
                    }}
                >
                    <Paper
                        elevation={8}
                        sx={(theme) => ({
                            backgroundImage: 'unset',
                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light' ? theme.palette.common.bg_2 : theme.palette.common.bg_4,
                        })}
                    >
                        <ClickAwayListener onClickAway={(evt) => closeMenu()}>
                            <Stack
                                sx={(theme) => ({
                                    gap: theme.spacing(1.5),

                                    width:
                                        menuProps?.width !== undefined
                                            ? menuProps.width
                                            : anchorElement.offsetWidth > 340
                                            ? anchorElement.offsetWidth
                                            : 340,
                                    minHeight: 0,
                                    padding: theme.spacing(1.5, 1),
                                    overflow: 'hidden',

                                    borderRadius: '4px',
                                    border: `1px solid ${theme.palette.common.border_2}`,
                                })}
                            >
                                {/* Search input and reset value button
                                    ========================================= */}
                                <Stack direction="row" alignItems="center" gap={1}>
                                    {/* Search input
                                        ========================================= */}
                                    <InputBase
                                        inputRef={searchInputRef}
                                        name="group-field-select-search-input"
                                        onChange={(evt) => {
                                            onSearch(evt.target.value.trim())
                                        }}
                                        disabled={disabled}
                                        placeholder="Search..."
                                        startAdornment={
                                            <SearchIcon
                                                sx={{
                                                    pointerEvents: 'none',

                                                    color: 'secondary.main',
                                                }}
                                            />
                                        }
                                        endAdornment={
                                            query !== '' && (
                                                <IconButton
                                                    onClick={(evt) => {
                                                        setQuery('')

                                                        searchInputRef.current!.value = ''
                                                    }}
                                                >
                                                    <ClearRoundedIcon fontSize="small" />
                                                </IconButton>
                                            )
                                        }
                                        sx={(theme) => ({
                                            flex: '1 0 0',

                                            gap: theme.spacing(0.5),

                                            width: '100%',
                                            padding: theme.spacing(0.5, 1),

                                            borderRadius: '4px',
                                            border: `1px solid ${theme.palette.common.border_2}`,
                                            backgroundColor: theme.palette.common.bg_3,

                                            '&.Mui-focused': {
                                                borderColor: theme.palette.primary.main,
                                                backgroundColor: theme.palette.common.bg_2,
                                            },

                                            '& .MuiInputBase-input': {
                                                // Creating more vertical padding so that when the clear button appears,
                                                // the input doesn't jump. The default MUI paddings were "4px 0 5px".
                                                paddingY: theme.spacing(1),
                                            },
                                        })}
                                    />

                                    {/* Reset value button
                                        ========================================= */}
                                    {isOpen && hasClearButton && (
                                        <Tooltip title="Reset value">
                                            {/* Intermediary div to handle MUI Tooltip "disabled content" error. */}
                                            <Box
                                                sx={{
                                                    flex: '0 0 auto',
                                                }}
                                            >
                                                <IconButton
                                                    onClick={(evt) => clearValue()}
                                                    disabled={disabled || selectedItem.itemsListIndex === null}
                                                >
                                                    <RestartAltRoundedIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </Stack>

                                {/* Panels
                                    ========================================= */}
                                <Box
                                    ref={menuPanelsContainerRef}
                                    sx={(theme) => ({
                                        position: 'relative',
                                        overflow: 'hidden',

                                        // We assign the initial height for this container when the menu opens.
                                        // Updating height after panels change, is handled inside an effect.
                                        height:
                                            // Search panel height assignment
                                            currentPanelIndex === 0
                                                ? searchPanelRef.current !== null
                                                    ? searchPanelRef.current.offsetHeight
                                                    : undefined
                                                : // Groups panel height assignment
                                                currentPanelIndex === 1
                                                ? groupsSectionRefs.current.panel !== null
                                                    ? groupsSectionRefs.current.panel.offsetHeight
                                                    : undefined
                                                : // Items panel height assignment
                                                currentPanelIndex === 2
                                                ? itemsSectionRefs.current.panel !== null
                                                    ? itemsSectionRefs.current.panel.offsetHeight
                                                    : undefined
                                                : undefined,

                                        transition: theme.transitions.create('height', {
                                            duration: theme.transitions.duration.shortest,
                                            easing: theme.transitions.easing.easeInOut,
                                        }),
                                    })}
                                >
                                    {/* Search result
                                        ========================================= */}
                                    <Box
                                        ref={searchPanelRef}
                                        sx={(theme) => ({
                                            position: 'absolute',
                                            top: 0,
                                            left: currentPanelIndex > 0 ? '-100%' : currentPanelIndex < 0 ? '100%' : 0,

                                            width: '100%',

                                            transition: theme.transitions.create('left', {
                                                duration: theme.transitions.duration.enteringScreen,
                                                easing: theme.transitions.easing.easeInOut,
                                            }),
                                        })}
                                    >
                                        {(currentPanelIndex === 0 || keepOffPanelsMounted === true) && (
                                            <List
                                                disablePadding
                                                sx={{
                                                    position: 'relative',

                                                    maxHeight:
                                                        menuProps?.maxContentHeight !== undefined
                                                            ? menuProps.maxContentHeight
                                                            : undefined,
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden',
                                                }}
                                                className="u-scrollbar"
                                            >
                                                {searchResultOptions.length !== 0 ? (
                                                    /*  Main view
                                                        ========================================= */
                                                    searchResultOptions.map((_option, idx) => {
                                                        // Grouped option
                                                        if (isGroupedOption(_option)) {
                                                            const groupedOption =
                                                                _option as FlexibleSelectGroupedOptionType

                                                            const isGroupSelected =
                                                                groupedOption.group === selectedItem.group

                                                            return (
                                                                <React.Fragment key={groupedOption.group + idx}>
                                                                    {/* Group subheader
                                                                        ========================================= */}
                                                                    <ListSubheader
                                                                        disableGutters
                                                                        sx={(theme) => ({
                                                                            // Same as the parent "Paper".
                                                                            // @Theme conditional
                                                                            backgroundColor:
                                                                                theme.palette.mode === 'light'
                                                                                    ? theme.palette.common.bg_4
                                                                                    : theme.palette.common.bg_5,
                                                                        })}
                                                                    >
                                                                        <Typography
                                                                            noWrap
                                                                            sx={(theme) => ({
                                                                                padding: theme.spacing(1),

                                                                                borderLeft: `4px solid ${
                                                                                    isGroupSelected
                                                                                        ? theme.palette.primary.main
                                                                                        : theme.palette.divider
                                                                                }`,
                                                                            })}
                                                                        >
                                                                            {groupedOption.group}
                                                                        </Typography>
                                                                    </ListSubheader>

                                                                    {/* Group items list
                                                                        ========================================= */}
                                                                    {groupedOption.items.map((_item, idx, array) => {
                                                                        const isItemSelected =
                                                                            isGroupSelected === true &&
                                                                            isEqual(_item.value, selectedItem.value)

                                                                        return (
                                                                            <ListItemButton
                                                                                key={_item.label + idx}
                                                                                component="li"
                                                                                onClick={(evt) =>
                                                                                    selectItem(_item.value)
                                                                                }
                                                                                selected={isItemSelected}
                                                                                disabled={disabled || _item.disabled}
                                                                                sx={(theme) => ({
                                                                                    paddingY: theme.spacing(0.75),
                                                                                    // "2" is the default left padding.
                                                                                    paddingLeft: theme.spacing(2 + 1.5),
                                                                                })}
                                                                            >
                                                                                <ListItemText primary={_item.label} />
                                                                            </ListItemButton>
                                                                        )
                                                                    })}
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        // Ungrouped option
                                                        else {
                                                            const ungroupedOption =
                                                                _option as FlexibleSelectUngroupedOptionType

                                                            const isItemSelected = isEqual(
                                                                ungroupedOption.value,
                                                                selectedItem.value
                                                            )

                                                            return (
                                                                <ListItemButton
                                                                    key={ungroupedOption.label + idx}
                                                                    component="li"
                                                                    onClick={(evt) => selectItem(ungroupedOption.value)}
                                                                    selected={isItemSelected}
                                                                    disabled={disabled || ungroupedOption.disabled}
                                                                    sx={(theme) => ({
                                                                        paddingY: theme.spacing(0.75),
                                                                    })}
                                                                >
                                                                    <ListItemText primary={ungroupedOption.label} />
                                                                </ListItemButton>
                                                            )
                                                        }
                                                    })
                                                ) : (
                                                    /*  Empty list view
                                                        ========================================= */
                                                    <Typography
                                                        sx={(theme) => ({
                                                            paddingY: theme.spacing(1),
                                                            textAlign: 'center',

                                                            color: theme.palette.text.secondary,
                                                        })}
                                                    >
                                                        No results.
                                                    </Typography>
                                                )}
                                            </List>
                                        )}
                                    </Box>

                                    {/* Groups list
                                        ========================================= */}
                                    <Box
                                        ref={(node: HTMLDivElement) => (groupsSectionRefs.current.panel = node)}
                                        sx={(theme) => ({
                                            position: 'absolute',
                                            top: 0,
                                            left: currentPanelIndex > 1 ? '-100%' : currentPanelIndex < 1 ? '100%' : 0,

                                            width: '100%',

                                            transition: theme.transitions.create('left', {
                                                duration: theme.transitions.duration.enteringScreen,
                                                easing: theme.transitions.easing.easeInOut,
                                            }),
                                        })}
                                    >
                                        {(currentPanelIndex === 1 || keepOffPanelsMounted === true) && (
                                            <List
                                                ref={(node) => (groupsSectionRefs.current.overflowContainer = node)}
                                                disablePadding
                                                sx={{
                                                    maxHeight:
                                                        menuProps?.maxContentHeight !== undefined
                                                            ? menuProps.maxContentHeight
                                                            : undefined,
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden',
                                                }}
                                                className="u-scrollbar"
                                            >
                                                {options.length !== 0 ? (
                                                    /*  Main view
                                                        ========================================= */
                                                    options.map((_option, idx, array) => {
                                                        // Grouped option
                                                        if (isGroupedOption(_option)) {
                                                            const groupOption =
                                                                _option as FlexibleSelectGroupedOptionType

                                                            const isGroupSelected = idx === selectedItem.groupListIndex

                                                            return (
                                                                <ListItemButton
                                                                    key={groupOption.group + idx}
                                                                    component="li"
                                                                    ref={
                                                                        isGroupSelected
                                                                            ? (node) =>
                                                                                  (groupsSectionRefs.current.selectedElement =
                                                                                      node)
                                                                            : null
                                                                    }
                                                                    onClick={(evt) => setSelectedGroupIndex(idx)}
                                                                    selected={isGroupSelected}
                                                                    disabled={disabled || groupOption.disabled}
                                                                    sx={(theme) => ({
                                                                        position: 'relative',

                                                                        paddingY: theme.spacing(0.75),

                                                                        '&:hover, &:focus, &:active':
                                                                            groupOption.disabled === true
                                                                                ? undefined
                                                                                : {
                                                                                      '& > span:first-of-type': {
                                                                                          width: 6,
                                                                                          height: '100%',

                                                                                          backgroundColor:
                                                                                              theme.palette.primary
                                                                                                  .main,
                                                                                      },
                                                                                  },
                                                                    })}
                                                                >
                                                                    {/* Left indicator bar
                                                                        ========================================= */}
                                                                    <Box
                                                                        component="span"
                                                                        sx={(theme) => ({
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: 0,

                                                                            width: 2,
                                                                            height: '65%',

                                                                            transition: theme.transitions.create(
                                                                                ['width', 'height', 'background-color'],
                                                                                {
                                                                                    duration:
                                                                                        theme.transitions.duration
                                                                                            .shortest,
                                                                                    easing: theme.transitions.easing
                                                                                        .sharp,
                                                                                }
                                                                            ),
                                                                            transform: 'translateY(-50%)',

                                                                            backgroundColor: theme.palette.divider,
                                                                        })}
                                                                    />

                                                                    <ListItemText
                                                                        primary={groupOption.group}
                                                                        secondary={groupOption.description}
                                                                    />

                                                                    <ChevronRightRoundedIcon />
                                                                </ListItemButton>
                                                            )
                                                        }
                                                        // Ungrouped option
                                                        else {
                                                            const ungroupedOption =
                                                                _option as FlexibleSelectUngroupedOptionType

                                                            const isItemSelected =
                                                                selectedItem.groupListIndex === null &&
                                                                idx === selectedItem.itemsListIndex

                                                            return (
                                                                <ListItemButton
                                                                    key={ungroupedOption.label + idx}
                                                                    component="li"
                                                                    ref={
                                                                        isItemSelected
                                                                            ? (node) =>
                                                                                  (groupsSectionRefs.current.selectedElement =
                                                                                      node)
                                                                            : null
                                                                    }
                                                                    onClick={(evt) => selectItem(ungroupedOption.value)}
                                                                    selected={isItemSelected}
                                                                    disabled={disabled || ungroupedOption.disabled}
                                                                    sx={(theme) => ({
                                                                        paddingY: theme.spacing(0.75),
                                                                    })}
                                                                >
                                                                    <ListItemText primary={ungroupedOption.label} />
                                                                </ListItemButton>
                                                            )
                                                        }
                                                    })
                                                ) : (
                                                    /*  Empty list view
                                                        ========================================= */
                                                    <Typography
                                                        sx={(theme) => ({
                                                            paddingY: theme.spacing(1),
                                                            textAlign: 'center',

                                                            color: theme.palette.text.secondary,
                                                        })}
                                                    >
                                                        No options.
                                                    </Typography>
                                                )}
                                            </List>
                                        )}
                                    </Box>

                                    {/* Group items list
                                        ========================================= */}
                                    <Stack
                                        ref={(node) => (itemsSectionRefs.current.panel = node)}
                                        sx={(theme) => ({
                                            position: 'absolute',
                                            top: 0,
                                            left: currentPanelIndex > 2 ? '-100%' : currentPanelIndex < 2 ? '100%' : 0,

                                            gap: theme.spacing(1),

                                            width: '100%',

                                            transition: theme.transitions.create('left', {
                                                duration: theme.transitions.duration.enteringScreen,
                                                easing: theme.transitions.easing.easeInOut,
                                            }),
                                        })}
                                    >
                                        {(currentPanelIndex === 2 || keepOffPanelsMounted === true) &&
                                            selectedGroupIndex !== null && (
                                                <>
                                                    {/* Selected group and back button
                                                        ========================================= */}
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="flex-start"
                                                        gap={2}
                                                    >
                                                        {/* Selected group
                                                            ========================================= */}
                                                        <Typography
                                                            sx={(theme) => ({
                                                                flex: '1 0 0',

                                                                padding: theme.spacing(1, 0, 1, 1),

                                                                borderLeft: `4px solid ${theme.palette.primary.main}`,
                                                            })}
                                                        >
                                                            {
                                                                (
                                                                    options[
                                                                        selectedGroupIndex
                                                                    ] as FlexibleSelectGroupedOptionType
                                                                ).group
                                                            }
                                                        </Typography>

                                                        {/* Back to groups panel button
                                                            ========================================= */}
                                                        <IconButton
                                                            onClick={(evt) => setSelectedGroupIndex(null)}
                                                            disabled={disabled}
                                                            sx={(theme) => ({
                                                                flex: '0 0 auto',

                                                                color: theme.palette.common.fill_1,
                                                            })}
                                                        >
                                                            <KeyboardBackspaceRoundedIcon />
                                                        </IconButton>
                                                    </Stack>

                                                    {/* Items list
                                                        ========================================= */}
                                                    <List
                                                        ref={(node) =>
                                                            (itemsSectionRefs.current.overflowContainer = node)
                                                        }
                                                        disablePadding
                                                        sx={(theme) => ({
                                                            maxHeight:
                                                                menuProps?.maxContentHeight !== undefined
                                                                    ? menuProps.maxContentHeight
                                                                    : undefined,
                                                            overflowY: 'auto',
                                                            overflowX: 'hidden',
                                                        })}
                                                        className="u-scrollbar"
                                                    >
                                                        {(
                                                            options[
                                                                selectedGroupIndex
                                                            ] as FlexibleSelectGroupedOptionType
                                                        ).items.length !== 0 ? (
                                                            /*  Main view
                                                                ========================================= */
                                                            (
                                                                options[
                                                                    selectedGroupIndex
                                                                ] as FlexibleSelectGroupedOptionType
                                                            ).items.map((_item, idx) => {
                                                                const isItemSelected =
                                                                    selectedGroupIndex ===
                                                                        selectedItem.groupListIndex &&
                                                                    idx === selectedItem.itemsListIndex

                                                                return (
                                                                    <ListItemButton
                                                                        key={_item.label + idx}
                                                                        component="li"
                                                                        ref={
                                                                            isItemSelected
                                                                                ? (node) =>
                                                                                      (itemsSectionRefs.current.selectedElement =
                                                                                          node)
                                                                                : null
                                                                        }
                                                                        onClick={(evt) => selectItem(_item.value)}
                                                                        selected={isItemSelected}
                                                                        disabled={disabled || _item.disabled}
                                                                        sx={(theme) => ({
                                                                            paddingY: theme.spacing(0.75),
                                                                        })}
                                                                    >
                                                                        <ListItemText primary={_item.label} />
                                                                    </ListItemButton>
                                                                )
                                                            })
                                                        ) : (
                                                            /*  Empty list view
                                                                ========================================= */
                                                            <Typography
                                                                sx={(theme) => ({
                                                                    paddingY: theme.spacing(1),
                                                                    textAlign: 'center',

                                                                    color: theme.palette.text.secondary,
                                                                })}
                                                            >
                                                                No options.
                                                            </Typography>
                                                        )}
                                                    </List>
                                                </>
                                            )}
                                    </Stack>
                                </Box>
                            </Stack>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

export default FlexibleSelectMenu
