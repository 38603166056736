import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

import { useEffect, useState } from 'react'
import { styled } from '@mui/material'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import { NodeIdType } from 'features/network-viz/types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
    minWidth: 0,

    '&.Mui-focused': {
        '& .MuiSelect-select': {
            borderRadius: 0,
        },
    },

    // Select
    '& .MuiSelect-select': {
        alignSelf: 'stretch',

        display: 'flex',
        alignItems: 'center',

        padding: theme.spacing(1.25, 1.5),
        fontSize: 18,
        fontWeight: 600,

        borderRadius: 0,
        border: 0,
        backgroundColor: theme.palette.common.bg_3,
        color: theme.palette.secondary.main,

        '&[aria-expanded=true]': {
            backgroundColor: theme.palette.common.bg_1,
        },
    },
}))

export interface SearchProps {
    size?: 'small' | 'normal'
}
// ToDo add feature for a compact view when size is small
export default function NetworkSearchField({ size = 'normal' }: SearchProps) {
    const { nodes, nodeRenders, nodeDataSchema } = useNetworkVizContext()
    const dispatchContext = useNetworkVizDispatch()

    const [loading, setLoading] = useState<boolean>(false)
    const [searchResult, setSearchResult] = useState<NodeIdType[]>([])
    const [searchResultIndex, setSearchResultIndex] = useState<number>(-1)

    const [query, setQuery] = useState<string>('')
    const [selectedKey, setSelectedKey] = useState<string>('All')

    const onSearchClick = async () => {
        setLoading(true)
        const worker = new WebWorker<NodeIdType[]>('workers/network/search-node.js')
        try {
            const response = await worker.run({ nodes, nodeRenders, query, selectedKey })
            setSearchResult(response)
            if (response.length > 0) {
                setSearchResultIndex(0)
            } else {
                setSearchResultIndex(-1)
            }
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    useEffect(() => {
        if (searchResultIndex == null) {
            setQuery('')
            setSelectedKey('All')
        } else {
            dispatchContext({
                type: 'SELECTED_ITEM_CHANGE',
                payload: searchResultIndex === -1 ? null : { mode: 'node', id: searchResult[searchResultIndex] + '' },
            })
        }
    }, [searchResultIndex])

    return (
        <Stack
            direction="row"
            alignItems="stretch"
            sx={(theme) => ({
                overflow: 'hidden',

                borderRadius: '10px',
                border: `1px solid ${theme.palette.common.border_3}`,
                backgroundColor: theme.palette.common.bg_3,
            })}
        >
            {/* Select
                ========================================= */}
            <Select
                value={selectedKey}
                onChange={(e) => setSelectedKey(e.target.value)}
                input={<StyledInputBase />}
                IconComponent={ExpandMoreIcon}
                size="small"
                sx={(theme) => ({
                    borderRight: `1px solid ${theme.palette.common.border_3}`,

                    '& .MuiSelect-icon': {
                        right: '4px',

                        color: 'primary.main',
                    },
                })}
            >
                <MenuItem
                    value="All"
                    sx={{
                        height: 40,
                        fontSize: 14,
                    }}
                >
                    All
                </MenuItem>

                {Object.keys(nodeDataSchema.fields).map(
                    (key) =>
                        !['key', 'id'].includes(key) && (
                            <MenuItem
                                key={key}
                                sx={{
                                    height: 40,
                                    fontSize: 14,
                                }}
                                value={key}
                            >
                                {key}
                            </MenuItem>
                        )
                )}
            </Select>

            {/* Input field
                ========================================= */}
            <Input
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        onSearchClick()
                    }
                }}
                size="small"
                disableUnderline
                endAdornment={
                    <InputAdornment
                        position="end"
                        sx={{
                            marginLeft: 0,
                        }}
                    >
                        <IconButton onClick={onSearchClick}>
                            <SearchIcon
                                sx={(theme) => ({
                                    color: theme.palette.secondary.main,
                                })}
                            />
                        </IconButton>
                    </InputAdornment>
                }
                sx={(theme) => ({
                    width: 200,
                    fontSize: 18,
                    fontWeight: 600,

                    borderRight: `1px solid ${theme.palette.common.border_3}`,
                    color: theme.palette.secondary.main,

                    '& .MuiInput-input': {
                        padding: theme.spacing(0.25, 0.5),
                        paddingLeft: theme.spacing(2),
                    },
                })}
            />

            {/* Prev/Next result buttons
                ========================================= */}
            <Stack>
                {/* Previous result button
                    ========================================= */}
                <IconButton
                    onClick={() => {
                        setSearchResultIndex((pv) => pv! - 1)
                    }}
                    disabled={searchResultIndex === -1 || searchResultIndex === 0}
                    size="small"
                    sx={{
                        paddingY: 0,
                        paddingX: 1,

                        borderRadius: 0,
                    }}
                >
                    <KeyboardArrowUp
                        color={searchResultIndex === -1 || searchResultIndex === 0 ? 'disabled' : 'primary'}
                    />
                </IconButton>

                {/* Next result button
                    ========================================= */}
                <IconButton
                    onClick={() => {
                        if (searchResultIndex !== searchResult.length - 1) {
                            setSearchResultIndex((pv) => pv + 1)
                        }
                    }}
                    disabled={searchResultIndex === -1 || searchResultIndex === searchResult.length - 1}
                    size="small"
                    sx={{
                        paddingY: 0,
                        paddingX: 1,

                        borderRadius: 0,
                    }}
                >
                    <KeyboardArrowDown
                        color={
                            searchResultIndex === -1 || searchResultIndex === searchResult.length - 1
                                ? 'disabled'
                                : 'primary'
                        }
                    />
                </IconButton>
            </Stack>
        </Stack>
    )
}
