//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { uniq } from 'lodash'
import { Stack, Box, Typography, IconButton, Divider, Tooltip, Collapse } from '@mui/material'
//* ======= Components and features
import Chart from 'features/chart/Chart'
import ReportDataSourceSelector from 'features/report-designer/data-sources/ReportDataSourceSelector'
import StyledDialog from 'components/dialog/StyledDialog'
import ColorPicker from 'components/widgets/ColorPicker'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    convertNodeAttributeToKeyString,
    formatValueAsLabel,
    getChartAttributes,
    getDataSourceData,
    getDataSourceFields,
    groupDataSourcePanelToMap,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { PictorialBarChartDataDefinitionType, PictorialBarChartOptionsType } from 'features/chart/Chart.asset'
import { generateChartOptions, getChartTypeInitialValues } from 'features/chart/Chart.helper'
import { sortDataGroupMap } from 'features/chart/Chart.data.helper'
import { AGGREGATION_OPTIONS, SORT_ORDER_OPTIONS } from 'features/report-designer/helpers/reportDesigner.constants'
import { ReportPictorialBarChartWidgetSettingsStateType } from 'features/report-designer/widgets/chart-widget/settings/PictorialBarChartWidgetSettings'
import { SelectedDataSourceType } from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import TuneIcon from '@mui/icons-material/Tune'

type Props = {
    isOpen: boolean
    data: ReportPictorialBarChartWidgetSettingsStateType
    dataSources: ReportDesignerStoreStateType['dataSources']
    onConfirm: (
        data: Pick<ReportPictorialBarChartWidgetSettingsStateType, 'selectedDataSource' | 'dataDefinition'>
    ) => void
    onClose: () => void
}

function PictorialBarChartDataConfigDialog({
    isOpen = false,
    data,
    dataSources,
    onConfirm,
    onClose = () => undefined,
}: Props) {
    const [chartData, setChartData] = useState<ReportPictorialBarChartWidgetSettingsStateType>(data)

    const [dataSourceFields, setDataSourceFields] = useState<FlexibleSelectOptionType[]>([])
    const [chartOptions, setChartOptions] = useState<PictorialBarChartOptionsType | null>(chartData.options)

    // Prevent user from applying any changes if the selected data configurations are not valid
    const isChartConfigValid = useMemo(() => {
        if (
            chartData.selectedDataSource === null ||
            chartData.selectedDataSource.id === -1 ||
            chartData.dataDefinition.categoryField.field === ''
        ) {
            return false
        }

        if (
            chartData.dataDefinition.aggregationMethod !== 'count' &&
            chartData.dataDefinition.valueField?.field.trim() === ''
        ) {
            return false
        }

        return true
    }, [chartData])

    const getSortedCategoriesList = (
        field: ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categoryField'],
        order: ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categoryOrder']
    ) => {
        const filteredDataSourceData = getDataSourceData(dataSources, chartData.selectedDataSource, [field])

        if (filteredDataSourceData !== undefined && chartData.selectedDataSource !== null) {
            const dataGroups = groupDataSourcePanelToMap(filteredDataSourceData, convertNodeAttributeToKeyString(field))
            const sortedDataCategories = sortDataGroupMap(dataGroups, order)

            const sortedCategoriesList: ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categories'] =
                []

            for (const [_category] of sortedDataCategories) {
                const refinedCategoryName = formatValueAsLabel(_category)

                const targetDataDefinitionCategory = chartData.dataDefinition.categories.find(
                    (_item) => _item.key === refinedCategoryName
                )
                const categorySymbol = targetDataDefinitionCategory?.symbol || 'circle'
                const categoryColor = targetDataDefinitionCategory?.color || '#2D77BC'

                sortedCategoriesList.push({
                    key: refinedCategoryName,
                    symbol: categorySymbol,
                    color: categoryColor,
                })
            }

            return sortedCategoriesList
        } else {
            return null
        }
    }

    // After selected data source changes, update and show its fields.
    useEffect(() => {
        setDataSourceFields(getDataSourceFields(dataSources, chartData.selectedDataSource))
    }, [chartData.selectedDataSource])

    // Update dialog's chart options after chartData state changes.
    useEffect(() => {
        if (isChartConfigValid) {
            setChartOptions((prevState) => {
                const newOptions = generateChartOptions({
                    type: 'pictorialBar',
                    mode: 'data',
                    prevOptions: prevState,
                    filteredData: getDataSourceData(
                        dataSources,
                        chartData.selectedDataSource,
                        getChartAttributes(chartData.type, chartData.dataDefinition)
                    ),
                    dataDefinition: chartData.dataDefinition,
                    config: chartData.config,
                })

                return { ...newOptions }
            })
        } else {
            setChartOptions(null)
        }
    }, [chartData, isChartConfigValid])

    const updateSelectedDataSource = (selectedDataSource: SelectedDataSourceType) => {
        const initialValues = getChartTypeInitialValues(chartData.type)

        if (initialValues !== null) {
            setChartData((prevState) => {
                // If the selected data source was changed (based on some of its properties that are considered important),
                // we need to reset some chart state properties to their initial values:
                let hasDataSourceChanged = false
                if (
                    prevState.selectedDataSource === null ||
                    prevState.selectedDataSource.id !== selectedDataSource.id ||
                    prevState.selectedDataSource.preset !== selectedDataSource.preset ||
                    prevState.selectedDataSource.type !== selectedDataSource.type
                ) {
                    hasDataSourceChanged = true
                }

                return {
                    ...prevState,
                    selectedDataSource: {
                        ...prevState.selectedDataSource,
                        ...selectedDataSource,
                    },
                    dataDefinition: hasDataSourceChanged
                        ? (initialValues.dataDefinition as PictorialBarChartDataDefinitionType)
                        : { ...prevState.dataDefinition },
                    options: hasDataSourceChanged ? null : { ...prevState.options },
                }
            })
        }
    }

    const onDataDefinitionFieldChange = (
        field: keyof Pick<
            ReportPictorialBarChartWidgetSettingsStateType['dataDefinition'],
            'aggregationMethod' | 'valueField' | 'categoryOrder'
        >,
        value: any
    ) => {
        if (field === 'categoryOrder') {
            const sortedCategoriesList = getSortedCategoriesList(
                chartData.dataDefinition.categoryField,
                value as ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categoryOrder']
            )

            if (sortedCategoriesList !== null) {
                setChartData((prevState) => ({
                    ...prevState,
                    dataDefinition: {
                        ...prevState.dataDefinition,
                        categoryOrder:
                            value as ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categoryOrder'],
                        categories: [...sortedCategoriesList],
                    },
                }))
            }
        } else {
            setChartData((prevState) => ({
                ...prevState,
                dataDefinition: {
                    ...prevState.dataDefinition,
                    [field]: value,
                },
            }))
        }
    }

    const onCategoryFieldChange = (
        value: ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categoryField']
    ) => {
        const filteredDataSourceData = getDataSourceData(dataSources, chartData.selectedDataSource, [value])

        if (filteredDataSourceData !== undefined && chartData.selectedDataSource !== null) {
            const sortedCategoriesList = getSortedCategoriesList(value, chartData.dataDefinition.categoryOrder)

            if (sortedCategoriesList !== null) {
                setChartData((prevState) => ({
                    ...prevState,
                    dataDefinition: {
                        ...prevState.dataDefinition,
                        categoryField: value,
                        categories: [...sortedCategoriesList],
                    },
                }))
            }
        }
    }

    const onCategoryConfigChange = (
        categoryKey: ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categories'][number]['key'],
        field: keyof ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categories'][number],
        value: any
    ) => {
        setChartData((prevState) => {
            return {
                ...prevState,
                dataDefinition: {
                    ...prevState.dataDefinition,
                    categories: prevState.dataDefinition.categories.map((_category) => {
                        if (_category.key === categoryKey) {
                            return {
                                ..._category,
                                [field]: value,
                            }
                        } else {
                            return _category
                        }
                    }),
                },
            }
        })
    }

    return (
        <StyledDialog
            open={isOpen}
            // Prevent backdrop clicks and ESC key from closing the dialog by accident.
            onClose={() => undefined}
            fullWidth={true}
            maxWidth="xl"
        >
            {/* Header
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '0 0 auto',

                    display: 'grid',
                    // 34px is the width of the close button. Change accordingly.
                    gridTemplateColumns: '34px 1fr auto',
                    placeContent: 'center',
                    columnGap: theme.spacing(1),

                    padding: theme.spacing(2, 3),

                    borderBottom: `1px solid ${theme.palette.common.border_3}`,
                })}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    sx={{
                        gridColumn: 2,
                    }}
                >
                    Pictorial Bar Chart Data Configuration
                </Typography>

                <IconButton
                    onClick={(evt) => onClose()}
                    size="small"
                    sx={{
                        gridColumn: 3,
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Box>

            {/* Content
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 1 auto',

                    display: 'grid',
                    gridTemplateColumns: '10fr 9fr',
                    placeContent: 'stretch',
                    columnGap: 2,

                    height: '70vh',
                    padding: theme.spacing(3),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {/* Content wrapper
                    ========================================= */}
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        paddingRight: theme.spacing(2),
                        overflowY: 'auto',

                        borderRight: `1px solid ${theme.palette.common.border_3}`,
                    })}
                    className="u-scrollbar"
                >
                    {/* Data source selector
                        ========================================= */}
                    <ReportDataSourceSelector
                        dataSources={dataSources}
                        selectedDataSource={chartData.selectedDataSource}
                        onChange={updateSelectedDataSource}
                    />

                    <Divider />

                    {/* Category field select
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Category Field:
                        </Typography>

                        <FlexibleSelect
                            label="Field"
                            options={dataSourceFields}
                            value={dataSourceFields.length !== 0 ? chartData.dataDefinition.categoryField : undefined}
                            onChange={onCategoryFieldChange}
                            disabled={dataSourceFields.length === 0}
                            required
                            fullWidth
                            sx={{
                                maxWidth: 480,
                            }}
                        />
                    </Stack>

                    {/* Aggregation field select
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Aggregation Method:
                        </Typography>

                        <BaseSelectWithLabel
                            label="Aggregation Method"
                            options={AGGREGATION_OPTIONS}
                            value={chartData.dataDefinition.aggregationMethod}
                            onChange={(value) => onDataDefinitionFieldChange('aggregationMethod', value)}
                            required
                            fullWidth
                            sx={{
                                maxWidth: 480,
                            }}
                        />
                    </Stack>

                    {/* Value field select
                        ========================================= */}
                    {chartData.dataDefinition.aggregationMethod !== 'count' && (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                            <Typography
                                textAlign="left"
                                noWrap
                                sx={{
                                    flex: '1 0 0',
                                }}
                            >
                                Value Field:
                            </Typography>

                            <FlexibleSelect
                                label="Field"
                                options={dataSourceFields}
                                value={dataSourceFields.length !== 0 ? chartData.dataDefinition.valueField : undefined}
                                onChange={(value) => onDataDefinitionFieldChange('valueField', value)}
                                disabled={dataSourceFields.length === 0}
                                fullWidth
                                sx={{
                                    maxWidth: 480,
                                }}
                            />
                        </Stack>
                    )}

                    <Divider />

                    {/* Categories header
                        ========================================= */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography
                            textAlign="left"
                            noWrap
                            sx={{
                                flex: '1 0 0',
                            }}
                        >
                            Categories:
                        </Typography>

                        <BaseSelectWithLabel
                            label="Sort Order"
                            options={SORT_ORDER_OPTIONS}
                            value={dataSourceFields.length !== 0 ? chartData.dataDefinition.categoryOrder : ''}
                            onChange={(value) => onDataDefinitionFieldChange('categoryOrder', value)}
                            disabled={dataSourceFields.length === 0}
                            required
                            fullWidth
                            sx={{
                                maxWidth: 300,
                            }}
                        />
                    </Stack>

                    {/* Categories
                        ========================================= */}
                    {chartData.dataDefinition.categories.map((_category, idx, array) => (
                        // TODO: We need to add list virtualization (windowing) for this part because the list may be big!
                        <React.Fragment key={_category.key}>
                            <PictorialBarChartCategoryItem data={_category} onUpdate={onCategoryConfigChange} />

                            {idx !== array.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </Stack>

                {/* Chart wrapper
                    ========================================= */}
                {chartOptions === null ? (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: '100%',
                        }}
                    >
                        Invalid/Empty data.
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Chart type={chartData.type} options={chartOptions} clearOnChange={true} />
                    </Box>
                )}
            </Box>

            {/* Footer
                ========================================= */}
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={(theme) => ({
                    flex: '0 0 auto',

                    padding: theme.spacing(2, 3),
                })}
            >
                <BaseButton
                    label="Discard"
                    onClick={(evt) => onClose()}
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({
                        minWidth: 180,
                        paddingY: 1,

                        color: theme.palette.common.fill_1,
                    })}
                />

                <BaseButton
                    label="Apply"
                    onClick={(evt) => onConfirm(chartData)}
                    disabled={isChartConfigValid === false}
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: 180,
                        paddingY: 1,
                    }}
                />
            </Stack>
        </StyledDialog>
    )
}

const pictorialBarSymbolsList = [
    {
        label: 'Circle',
        value: 'circle',
    },
    {
        label: 'Rectangle',
        value: 'roundRect',
    },
    {
        label: 'Triangle',
        value: 'triangle',
    },
    {
        label: 'Diamond',
        value: 'diamond',
    },
    {
        label: 'Male',
        value: 'path://M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-37q-6.1 0-10.7 3.775T7.4 20.3q2.05 0 3.375-.975Q12.1 18.35 13.55 15.5q.7-1.4 2.025-2.2 1.325-.8 2.925-.8h11q1.6 0 2.925.8 1.325.8 2.025 2.2 1.45 3 2.9 3.9 1.45.9 3.25.9-1.3-5.75-5.875-9.525Q30.15 7 24 7Zm0 34q7.1 0 12.05-4.975Q41 31.05 41 24v-.6q-3.8 0-5.875-1.975T31.75 16.9q-.35-.7-.925-1.05-.575-.35-1.275-.35H18.5q-.75 0-1.325.35t-.925 1.05q-1.3 2.6-3.45 4.575Q10.65 23.45 7 23.45V24q0 7.05 4.975 12.025Q16.95 41 24 41Zm-6.3-12.15q-1.15 0-1.925-.775Q15 27.3 15 26.15t.775-1.925q.775-.775 1.925-.775t1.925.775q.775.775.775 1.925t-.775 1.925q-.775.775-1.925.775Zm12.65 0q-1.15 0-1.925-.775-.775-.775-.775-1.925t.775-1.925q.775-.775 1.925-.775t1.925.775q.775.775.775 1.925t-.775 1.925q-.775.775-1.925.775ZM24 12.5Z',
    },
    {
        label: 'Female',
        value: 'path://M17.7 32.85q-1.15 0-1.925-.775Q15 31.3 15 30.15t.775-1.925q.775-.775 1.925-.775t1.925.775q.775.775.775 1.925t-.775 1.925q-.775.775-1.925.775Zm12.65 0q-1.15 0-1.925-.775-.775-.775-.775-1.925t.775-1.925q.775-.775 1.925-.775t1.925.775q.775.775.775 1.925t-.775 1.925q-.775.775-1.925.775ZM24 45q7.1 0 12.05-4.95Q41 35.1 41 28q0-1.3-.2-2.55-.2-1.25-.5-2.3-1 .25-2.175.35-1.175.1-2.425.1-4.85 0-9.175-2-4.325-2-7.375-5.7-1.7 4.05-4.875 7.075Q11.1 26 7 27.65V28q0 7.1 4.95 12.05Q16.9 45 24 45Zm-4.6-33.35q4.4 5.15 8.125 7.05 3.725 1.9 8.175 1.9 1.2 0 1.9-.05t1.55-.3q-2.25-4.05-6.125-6.65Q29.15 11 24 11q-1.35 0-2.55.2-1.2.2-2.05.45ZM7.45 24.1q2.4-.9 5.475-4.075Q16 16.85 17.3 12.35q-4.35 1.95-6.575 4.975Q8.5 20.35 7.45 24.1ZM19.4 11.65Zm-2.1.7Zm-11.45 7.2q1.5-3.2 4-5.7t5.7-4q-.95-1.3-2.4-2.075Q11.7 7 9.95 7q-2.9 0-4.925 2.025T3 13.95q0 1.75.775 3.2.775 1.45 2.075 2.4ZM24 48q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 32.15 4 28q0-1.45.2-2.875t.6-2.725q-2.15-1.3-3.475-3.525Q0 16.65 0 13.95 0 9.8 2.9 6.9 5.8 4 9.95 4q2.65 0 4.875 1.275T18.4 8.8q1.3-.4 2.725-.6Q22.55 8 24 8q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 23.85 44 28t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 48 24 48ZM9.85 13.85Z',
    },
    {
        label: 'Book',
        value: 'path://M23.333 16.208v-2.25q1.375-.583 2.813-.875 1.437-.291 3.021-.291 1.083 0 2.125.166 1.041.167 2.041.417v2.083q-1-.375-2.021-.562-1.02-.188-2.145-.188-1.584 0-3.042.396-1.458.396-2.792 1.104Zm0 9.167v-2.292q1.375-.583 2.813-.854 1.437-.271 3.021-.271 1.083 0 2.125.167 1.041.167 2.041.417v2.083q-1-.375-2.021-.563-1.02-.187-2.145-.187-1.584 0-3.042.375-1.458.375-2.792 1.125Zm0-4.583v-2.25q1.375-.584 2.813-.875 1.437-.292 3.021-.292 1.083 0 2.125.167 1.041.166 2.041.416v2.084q-1-.375-2.021-.563-1.02-.187-2.145-.187-1.584 0-3.042.396-1.458.395-2.792 1.104ZM10.5 27.208q2.125 0 4.167.5 2.041.5 4 1.5V11.875q-1.834-1.167-3.917-1.792-2.083-.625-4.25-.625-1.542 0-3.063.355-1.52.354-2.979.937v17.542q1.334-.542 2.917-.813 1.583-.271 3.125-.271Zm10.958 2q2-1 3.938-1.5 1.937-.5 4.104-.5 1.542 0 3.146.25 1.604.25 2.896.709V10.75q-1.375-.667-2.917-.979-1.542-.313-3.125-.313-2.167 0-4.208.625-2.042.625-3.834 1.792Zm-1.416 4.125q-2.084-1.583-4.521-2.437-2.438-.854-5.021-.854-1.583 0-3.125.396-1.542.395-3.042 1.02-.916.459-1.791-.083-.875-.542-.875-1.583V10.208q0-.541.27-1.041.271-.5.813-.75 1.792-.875 3.75-1.313 1.958-.437 4-.437 2.542 0 4.979.687 2.438.688 4.563 2.063 2.125-1.375 4.52-2.063 2.396-.687 4.938-.687 2.042 0 3.979.437 1.938.438 3.729 1.313.542.25.834.75.291.5.291 1.041v19.584q0 1.083-.895 1.646-.896.562-1.813.02-1.458-.666-3-1.041-1.542-.375-3.125-.375-2.583 0-4.979.875t-4.479 2.416Zm-8.5-13.791Z',
    },
    {
        label: 'Soccer Ball',
        value: 'path://M20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.479Q5.958 10.5 8.229 8.229t5.313-3.583Q16.583 3.333 20 3.333q3.458 0 6.479 1.313 3.021 1.312 5.292 3.583t3.583 5.292q1.313 3.021 1.313 6.479 0 3.417-1.313 6.458-1.312 3.042-3.583 5.313t-5.292 3.583Q23.458 36.667 20 36.667Zm8.458-20.625 2.667-.917.75-2.542q-1.417-2.125-3.417-3.645-2-1.521-4.5-2.313l-2.583 1.708v2.709Zm-16.916 0 7.083-5V8.333l-2.542-1.708q-2.5.792-4.521 2.313-2.02 1.52-3.395 3.645L9 15.125ZM9.458 29.208l2.334-.25 1.5-2.583-2.542-7.708L8 17.708l-1.875 1.5q0 2.917.687 5.313.688 2.396 2.646 4.687ZM20 33.875q1.125 0 2.229-.187 1.104-.188 2.271-.521l1.292-2.834-1.25-2.125h-9.084l-1.25 2.125 1.334 2.834q1.041.333 2.187.521 1.146.187 2.271.187Zm-4.208-8.458h8.291l2.459-7.292L20 13.458l-6.625 4.667Zm14.75 3.791q1.958-2.291 2.646-4.687.687-2.396.687-5.313L32 17.917l-2.708.75-2.542 7.708 1.458 2.583Z',
    },
    {
        label: 'Basketball',
        value: 'path://M6.167 18.625h5.375q-.25-1.875-1.084-3.521-.833-1.646-2.041-2.854-.875 1.375-1.5 2.958-.625 1.584-.75 3.417Zm22.291 0h5.375q-.125-1.833-.75-3.417-.625-1.583-1.5-2.958-1.291 1.292-2.083 2.896-.792 1.604-1.042 3.479ZM8.417 27.708q1.291-1.291 2.083-2.875.792-1.583 1.042-3.458H6.167q.125 1.833.75 3.396.625 1.562 1.5 2.937Zm23.166 0q.875-1.375 1.5-2.937.625-1.563.75-3.396h-5.375q.25 1.875 1.042 3.458.792 1.584 2.083 2.875Zm-17.208-9.083h4.25V6.167q-2.458.291-4.583 1.291t-3.834 2.667q1.709 1.667 2.771 3.833 1.063 2.167 1.396 4.667Zm7 0h4.25q.333-2.5 1.417-4.667 1.083-2.166 2.75-3.833-1.667-1.667-3.813-2.667-2.146-1-4.604-1.291Zm-2.75 15.208V21.375h-4.25q-.333 2.5-1.396 4.667-1.062 2.166-2.771 3.833 1.709 1.625 3.75 2.646 2.042 1.021 4.667 1.312Zm2.75 0q2.625-.291 4.687-1.312 2.063-1.021 3.73-2.646-1.667-1.667-2.75-3.833-1.084-2.167-1.417-4.667h-4.25ZM20 20.125Zm0 16.542q-3.458 0-6.5-1.313-3.042-1.312-5.292-3.562T4.646 26.5Q3.333 23.458 3.333 20t1.313-6.5q1.312-3.042 3.562-5.292T13.5 4.646q3.042-1.313 6.5-1.313t6.5 1.313q3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5t-1.313 6.5q-1.312 3.042-3.562 5.292T26.5 35.354q-3.042 1.313-6.5 1.313Z',
    },
    {
        label: 'Vial',
        value: 'path://M7.542 35q-1.875 0-2.646-1.708-.771-1.709.479-3.167l9.958-11.5V7.792h-2q-.583 0-.979-.417t-.396-1q0-.583.396-.979T13.333 5h13.334q.583 0 .979.396t.396.979q0 .625-.396 1.021-.396.396-.979.396h-2v10.833l9.958 11.5q1.25 1.458.479 3.167Q34.333 35 32.458 35Zm-.334-2.792h25.584L21.875 19.625V7.792h-3.75v11.833ZM19.958 20Z',
    },
    {
        label: 'DNA',
        value: 'path://M8.333 38.333v-1.666q0-5.834 2.605-9.417 2.604-3.583 6.937-7.25-4.333-3.667-6.937-7.25-2.605-3.583-2.605-9.417V1.667h2.792v1.666q0 .459.021.855.021.395.062.812h17.584q.041-.417.062-.812.021-.396.021-.855V1.667h2.792v1.666q0 5.834-2.605 9.417-2.604 3.583-6.937 7.25 4.333 3.667 6.937 7.25 2.605 3.583 2.605 9.417v1.666h-2.792v-1.666q0-.459-.021-.855-.021-.395-.062-.812H11.208q-.041.417-.062.812-.021.396-.021.855v1.666Zm5.25-26.666h12.834q.625-.917 1.083-1.855.458-.937.792-2.02H11.708q.334 1.083.792 2.02.458.938 1.083 1.855ZM20 18.208q1.125-.958 2.167-1.875 1.041-.916 1.958-1.875h-8.25q.917.959 1.937 1.875 1.021.917 2.188 1.875Zm-4.125 7.334h8.25q-.917-.959-1.958-1.875-1.042-.917-2.167-1.875-1.125.958-2.167 1.875-1.041.916-1.958 1.875Zm-4.167 6.666h16.584q-.334-1.083-.792-2.02-.458-.938-1.083-1.855H13.583q-.625.917-1.083 1.855-.458.937-.792 2.02Z',
    },
    {
        label: 'Happy',
        value: 'path://M26 17.958q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm-12 0q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm6 11.167q2.792 0 5.104-1.521 2.313-1.521 3.354-4.062h-2.375q-.958 1.625-2.562 2.52-1.604.896-3.521.896-1.875 0-3.5-.896-1.625-.895-2.542-2.52h-2.416q1.083 2.541 3.375 4.062 2.291 1.521 5.083 1.521Zm0 7.542q-3.458 0-6.5-1.313-3.042-1.312-5.292-3.562T4.646 26.5Q3.333 23.458 3.333 20t1.313-6.5q1.312-3.042 3.562-5.292T13.5 4.646q3.042-1.313 6.5-1.313t6.5 1.313q3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5t-1.313 6.5q-1.312 3.042-3.562 5.292T26.5 35.354q-3.042 1.313-6.5 1.313ZM20 20Zm0 13.875q5.833 0 9.854-4.021 4.021-4.021 4.021-9.854 0-5.833-4.021-9.854Q25.833 6.125 20 6.125q-5.833 0-9.854 4.021Q6.125 14.167 6.125 20q0 5.833 4.021 9.854 4.021 4.021 9.854 4.021Z',
    },
    {
        label: 'Very Happy',
        value: 'path://M20 29.125q2.792 0 5.104-1.521 2.313-1.521 3.354-4.062H11.542q1.083 2.541 3.375 4.062 2.291 1.521 5.083 1.521Zm-7.292-11.167 1.875-1.833 1.834 1.833L18 16.375l-3.417-3.417-3.458 3.417Zm10.917 0 1.833-1.833 1.834 1.833 1.583-1.583-3.417-3.417-3.416 3.417ZM20 36.667q-3.458 0-6.5-1.313-3.042-1.312-5.292-3.562T4.646 26.5Q3.333 23.458 3.333 20t1.313-6.5q1.312-3.042 3.562-5.292T13.5 4.646q3.042-1.313 6.5-1.313t6.5 1.313q3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5t-1.313 6.5q-1.312 3.042-3.562 5.292T26.5 35.354q-3.042 1.313-6.5 1.313ZM20 20Zm0 13.875q5.833 0 9.854-4.021 4.021-4.021 4.021-9.854 0-5.833-4.021-9.854Q25.833 6.125 20 6.125q-5.833 0-9.854 4.021Q6.125 14.167 6.125 20q0 5.833 4.021 9.854 4.021 4.021 9.854 4.021Z',
    },
    {
        label: 'Dissatisfied',
        value: 'path://M26 17.958q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm-12 0q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm6 4.625q-2.792 0-5.083 1.584-2.292 1.583-3.375 4.166h2.416q.917-1.666 2.542-2.604 1.625-.937 3.5-.937 1.917 0 3.521.937 1.604.938 2.562 2.604h2.375q-1.041-2.625-3.333-4.187-2.292-1.563-5.125-1.563Zm0 14.084q-3.458 0-6.5-1.313-3.042-1.312-5.292-3.562T4.646 26.5Q3.333 23.458 3.333 20t1.313-6.5q1.312-3.042 3.562-5.292T13.5 4.646q3.042-1.313 6.5-1.313t6.5 1.313q3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5t-1.313 6.5q-1.312 3.042-3.562 5.292T26.5 35.354q-3.042 1.313-6.5 1.313ZM20 20Zm0 13.875q5.833 0 9.854-4.021 4.021-4.021 4.021-9.854 0-5.833-4.021-9.854Q25.833 6.125 20 6.125q-5.833 0-9.854 4.021Q6.125 14.167 6.125 20q0 5.833 4.021 9.854 4.021 4.021 9.854 4.021Z',
    },
    {
        label: 'Very Dissatisfied',
        value: 'path://M20 36.667q-3.458 0-6.5-1.313-3.042-1.312-5.292-3.583t-3.562-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.562-5.292T13.5 4.646q3.042-1.313 6.5-1.313t6.479 1.313q3.021 1.312 5.292 3.562t3.583 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.583 5.313t-5.292 3.583Q23.458 36.667 20 36.667Zm0-2.792q5.75 0 9.812-4.063Q33.875 25.75 33.875 20q0-5.792-4.063-9.833Q25.75 6.125 20 6.125q-5.792 0-9.833 4.042Q6.125 14.208 6.125 20q0 5.75 4.042 9.812 4.041 4.063 9.833 4.063Zm2.875-15.917.667-.375q.083.875.75 1.459.666.583 1.541.583 1 0 1.667-.667.667-.666.667-1.666 0-.542-.25-1.042t-.709-.833l1.125-.625-.833-1.459-5.458 3.125Zm-5.75 0 .833-1.5-5.458-3.125-.833 1.459 1.125.625q-.459.333-.709.833-.25.5-.25 1.042 0 1 .667 1.666.667.667 1.667.667.875 0 1.541-.583.667-.584.75-1.459ZM20 21.75q-2.917 0-5.104 1.896-2.188 1.896-3.229 4.687h16.666q-1.083-2.75-3.25-4.666Q22.917 21.75 20 21.75ZM20 20Z',
    },
]

type PictorialBarChartCategoryItemProps = {
    data: ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categories'][number]
    onUpdate: (
        categoryKey: ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categories'][number]['key'],
        field: keyof ReportPictorialBarChartWidgetSettingsStateType['dataDefinition']['categories'][number],
        value: any
    ) => void
}

function PictorialBarChartCategoryItem({ data, onUpdate }: PictorialBarChartCategoryItemProps) {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <Stack>
            {/* Category title and symbol select
                ========================================= */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                {/* Category title
                    ========================================= */}
                <Typography
                    textAlign="left"
                    noWrap
                    sx={{
                        flex: '1 0 0',
                    }}
                >
                    {data.key}
                </Typography>

                {/* Symbol select
                    ========================================= */}
                <BaseSelectWithLabel
                    label="Symbol"
                    options={pictorialBarSymbolsList}
                    value={data.symbol}
                    onChange={(value) => onUpdate(data.key, 'symbol', value)}
                    fullWidth
                    sx={{
                        maxWidth: 280,
                    }}
                />

                {/* Extra options expand button
                    ========================================= */}
                <Tooltip title="Extra Options">
                    <IconButton
                        onClick={(evt) => setIsExpanded((prevState) => !prevState)}
                        color={isExpanded ? 'primary' : undefined}
                        sx={{
                            flexShrink: 0,
                        }}
                    >
                        <TuneIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            {/* Extra options collapsible area
                ========================================= */}
            <Collapse in={isExpanded}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={4} marginTop={2}>
                    {/* Color
                        ========================================= */}
                    <Box
                        sx={{
                            // Visually observed and aligned with surrounding elements.
                            width: 334,
                            marginLeft: 'auto',
                        }}
                    >
                        <ColorPicker
                            value={data.color}
                            onChange={(newColor) => onUpdate(data.key, 'color', newColor)}
                            isPopover={true}
                        />
                    </Box>
                </Stack>
            </Collapse>
        </Stack>
    )
}

export default PictorialBarChartDataConfigDialog
