import { KeyValueType } from 'types/resources'

export const LAYOUT_ALGORITHEMS_2D: {
    label: string
    value: any
}[] = [
    {
        value: 'circular',
        label: 'Circular',
    },
    {
        value: 'random',
        label: 'Random',
    },
    {
        value: 'force',
        label: 'Force',
    },
    {
        value: 'force-atlas',
        label: 'Force Atlas2',
    },
    {
        value: 'noverlap',
        label: 'No Overlap',
    },
    {
        value: 'kamadakawai',
        label: 'Kamada Kawai',
    },
    {
        value: 'sfdp',
        label: 'Spring-block',
    },
    {
        value: 'frl',
        label: 'Fruchterman-Reingold',
    },
    {
        value: 'arf',
        label: 'ARF spring-block ',
    },
    {
        value: 'planar',
        label: 'Planar graph',
    },
    {
        value: 'spring',
        label: 'Spring',
    },
]

export const LAYOUT_ALGORITHEMS_3D: {
    label: string
    value: any
}[] = [
    {
        value: 'random',
        label: 'Random',
    },
    {
        value: 'force',
        label: 'Force',
    },
    {
        value: 'kamadakawai',
        label: 'Kamada Kawai',
    },
    {
        value: 'spring',
        label: 'Spring',
    },
]

export const NODE_ANALYTICS_METRICS: KeyValueType[] = [
    { value: 'degree', label: 'Degree' },
    { value: 'in_degree', label: 'In degree' },
    { value: 'out_degree', label: 'Out degree' },
    { value: 'clustering', label: 'Clustering' },
    { value: 'reciprocity', label: 'Reciprocity' },
    { value: 'degree_centrality', label: 'Degree centrality' },
    { value: 'in_degree_centrality', label: 'In degree centrality' },
    { value: 'out_degree_centrality', label: 'Out degree centrality' },
    { value: 'closeness_centrality', label: 'Closeness centrality' },
    { value: 'betweenness_centrality', label: 'Betweenness centrality' },
    { value: 'eigenvector_centrality', label: 'Eigenvector centrality' },
    { value: 'greedy_modularity_community', label: 'Greedy Modularity Community' },
    { value: 'weakly_connected_component', label: 'weakly Connected Component' },
    { value: 'strongly_connected_component', label: 'Strongly Connected Component' },
]
