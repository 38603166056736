import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import SignInLayout from 'pages/sign-in/SignInLayout'
import UnauthorizedImage from '../../assets/images/unauthorized.png'
import { Box } from '@mui/material'

export default function PageUnauthorized() {
    const { pid } = useParams()
    const navigate = useNavigate()

    return (
        <SignInLayout>
            <Stack gridRow={2} width="40%">
                {/* Unauthorized Image */}
                <img
                    src={UnauthorizedImage}
                    alt="Unauthorized Access"
                    style={{
                        width: '100%',
                        flexGrow: 1,
                    }}
                />

                <Box>
                    {/* Notification Title */}
                    <Typography fontSize={36} fontWeight={400} color="common.text_1" mb={1}>
                        Unauthorized Access
                    </Typography>

                    {/* Notification Message */}
                    <Typography fontSize={18} fontWeight={300} color="common.text_1" mb={1}>
                        Sorry, you lack the required permissions to view this page. Please contact your
                        administrator for assistance.
                    </Typography>

                    {/* Conditional Button: Navigate based on 'pid' */}
                    {pid ? (
                        <BaseButton
                            label={'Return to Project Home'}
                            onClick={() => navigate(`/project/${pid}`)}
                            variant="contained"
                            sx={{ marginBottom: 3, py: 2 }}
                        />
                    ) : (
                        <BaseButton
                            label={'Return to Home'}
                            onClick={() => navigate('/dashboard')}
                            variant="contained"
                            sx={{ marginBottom: 3, py: 2 }}
                        />
                    )}
                </Box>
            </Stack>
        </SignInLayout>
    )
}
