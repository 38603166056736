import { Box, Chip, IconButton, Stack, Tooltip } from '@mui/material'
import { ProviderProps } from '@reactour/tour'
import { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
import {
    ReportDataAggregationMethodType,
    ImageWidgetType,
    ReportMasterSettingsType,
} from 'features/report-designer/types/reportDesigner.types'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import * as DOMPurify from 'dompurify'
import { ReportDesignerStoreActionsType } from '../store/reportDesignerActions'

export const ASPECT_RATIO_OPTIONS: BaseSelectWithLabelOptionType = [
    {
        label: '16 : 9',
        value: '16:9',
    },
    {
        label: '4 : 3',
        value: '4:3',
    },
]

export const IMAGE_FIT_OPTIONS: { label: string; value: ImageWidgetType['fit'] }[] = [
    {
        label: 'Contain',
        value: 'contain',
    },
    {
        label: 'Fill',
        value: 'fill',
    },
    {
        label: 'Cover',
        value: 'cover',
    },
    {
        label: 'Scale-down',
        value: 'scale-down',
    },
]

export const SORT_ORDER_OPTIONS: BaseSelectWithLabelOptionType = [
    {
        label: 'Ascending',
        value: 'asc',
    },
    {
        label: 'Descending',
        value: 'desc',
    },
]

export const AGGREGATION_OPTIONS: { label: string; value: ReportDataAggregationMethodType }[] = [
    {
        label: 'Average',
        value: 'avg',
    },
    {
        label: 'Count',
        value: 'count',
    },
    {
        label: 'Total',
        value: 'sum',
    },
    {
        label: 'Maximum',
        value: 'max',
    },
    {
        label: 'Minimum',
        value: 'min',
    },
    {
        label: 'Median',
        value: 'median',
    },
    {
        label: 'Q1',
        value: 'q1',
    },
    {
        label: 'Q3',
        value: 'q3',
    },
]

export const DECIMAL_PRECISION_OPTIONS: BaseSelectWithLabelOptionType = [0, 1, 2, 3, 4, 5, 6]

export const getReportOnboardingProps = (
    config: ReportMasterSettingsType['onboarding'],
    updateReportTour: ReportDesignerStoreActionsType['updateReportTour'],
    updateReportTourStep: ReportDesignerStoreActionsType['updateReportTourStep']
): Partial<ProviderProps> => {
    return {
        disableKeyboardNavigation: true,
        disableDotsNavigation: true,
        showDots: false,
        disableFocusLock: true,
        showCloseButton: true,
        showBadge: true,
        scrollSmooth: true,
        onClickMask: () => {
            return
        },
        onClickClose: () => {
            updateReportTour({
                data: {
                    isOpen: false,
                    step: 0,
                },
            })
        },
        components: {
            Content: ({ content }) => {
                const html = { __html: DOMPurify.sanitize(content?.toString() ?? '<p>Loading...</p>') }

                return (
                    <Box
                        sx={{
                            minWidth: '100px',
                            maxHeight: '40vh',
                            overflow: 'auto',
                        }}
                        className="u-scrollbar"
                    >
                        <div dangerouslySetInnerHTML={html} />
                    </Box>
                )
            },
            Navigation: ({ steps, currentStep, setCurrentStep, setIsOpen }) => {
                const step = steps?.[currentStep] as any
                if (step?.hideButtons === true) return null

                const last = currentStep === steps.length - 1

                let title = 'Next'

                if (last) {
                    title = config.onFinishText || 'Close'
                }

                return (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        {/* Prev Button */}
                        <Tooltip
                            title="Previous"
                            PopperProps={{
                                disablePortal: true,
                            }}
                        >
                            {/* Intermediary div to handle MUI Tooltip "disabled content" error. */}
                            <Box
                                sx={{
                                    flex: '0 0 auto',
                                }}
                            >
                                <IconButton
                                    disabled={currentStep === 0}
                                    onClick={() => {
                                        updateReportTourStep({ data: 'prev' })
                                    }}
                                >
                                    <ArrowForwardIcon sx={{ transform: 'rotate(180deg)' }} />
                                </IconButton>
                            </Box>
                        </Tooltip>

                        {/* Badge */}
                        {/* <Chip
                            label={`${currentStep + 1} / ${steps.length}`}
                            color="primary"
                            sx={{ flex: '0 0 auto' }}
                        /> */}

                        {/* Next Button */}
                        <Tooltip
                            title={title}
                            PopperProps={{
                                disablePortal: true,
                            }}
                        >
                            {/* Intermediary div to handle MUI Tooltip "disabled content" error. */}
                            <Box
                                sx={{
                                    flex: '0 0 auto',
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        if (last) {
                                            updateReportTour({
                                                data: {
                                                    isOpen: false,
                                                    step: 0,
                                                },
                                            })
                                        } else {
                                            updateReportTourStep({ data: 'next' })
                                        }
                                    }}
                                >
                                    {last ? <CheckCircleOutlineOutlinedIcon color="success" /> : <ArrowForwardIcon />}
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Stack>
                )
            },
        },
        padding: {
            popover: [5, 10],
        },
        styles: {
            popover: (base) => ({
                ...base,
                borderRadius: 10,
                width: '40vw',
                maxWidth: '500px',
            }),
            maskArea: (base) => ({ ...base, rx: 10 }),
            maskWrapper: (base) => ({ ...base, zIndex: 1000 }),
        },
        badgeContent: ({ totalSteps, currentStep }) => `${currentStep + 1} / ${totalSteps}`,
    }
}
