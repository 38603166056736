import React, { useEffect } from 'react'

import Chart from 'features/chart/Chart'
import { Box, Stack } from '@mui/material'
import { EChartsCustomOptionType } from 'app/echarts/custom-echarts'
import { ClassStatType } from 'pages/allocation/Allocation.route'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { BarSeriesOption } from 'echarts/charts'
import { YAXisOption } from 'echarts/types/dist/shared'
import { generateRandomColors } from 'helpers/colorHelpers'

interface GroupAllocationBarChartProps {
    data: Record<string, ClassStatType> | null
}

const GroupAllocationBarChart: React.FC<GroupAllocationBarChartProps> = ({ data }) => {
    const [chartOption, setChartOption] = React.useState<EChartsCustomOptionType | null>(null)
    const [network, setNetwork] = React.useState<string | null>(null)

    useEffect(() => {
        if (data === null) return
        if (network === null) setNetwork(Object.keys(data[Object.keys(data)[0]].network)[0])
    }, [data])

    useEffect(() => {
        if (data === null || network === null) return

        const groupNames = Object.keys(data)
        const sourceData: (number | string)[][] = [['group', 'density', 'reciprocity', 'isolatedNodes']]

        const metrics = ['density', 'reciprocity', 'isolatedNodes']

        const colors = [
            '#5470C6',
            '#91CC75',
            '#FAC858',
            '#EE6666',
            '#73C0DE',
            '#3BA272',
            '#FC8452',
            '#9A60B4',
            '#EA7CCC',
        ]
        if (colors.length < groupNames.length) {
            const newColors = generateRandomColors(groupNames.length - colors.length, 'dark')
            colors.push(...newColors)
        }

        groupNames.forEach((group) => {
            sourceData.push([
                group,
                data[group].network[network].density,
                data[group].network[network].reciprocity,
                data[group].network[network].isolatedNodes,
            ])
        })

        const barChartOption: EChartsCustomOptionType = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            dataset: {
                source: sourceData,
            },
            grid: [
                { left: '2%', width: '30%', bottom: '3%', containLabel: true },
                { left: '34%', width: '30%', bottom: '3%', containLabel: true },
                { left: '66%%', width: '30%', bottom: '3%', containLabel: true },
            ],
            xAxis: [
                { type: 'category', gridIndex: 0 },
                { type: 'category', gridIndex: 1 },
                { type: 'category', gridIndex: 2 },
            ],
            yAxis: [
                { name: 'Density', min: 0, max: 1, gridIndex: 0, show: false },
                { name: 'Reciprocity', gridIndex: 1, show: false },
                { name: 'Isolated Nodes', gridIndex: 2, show: false },
            ],
            title: [
                { text: 'Density', left: '10%', top: '4%' },
                { text: 'Reciprocity', left: '42%', top: '4%' },
                { text: 'Isolated Nodes', left: '70%', top: '4%' },
            ],

            series: metrics.map((metric, metricIndex) => {
                return {
                    name: metric,
                    type: 'bar',
                    xAxisIndex: metricIndex,
                    yAxisIndex: metricIndex,
                    itemStyle: {
                        color: (params) => colors[params.dataIndex],
                    },
                    encode: {
                        x: 'group',
                        y: metric,
                    },
                }
            }),
        }

        setChartOption(barChartOption)
    }, [data, network])

    return (
        <Stack height="100%" p={1}>
            <BaseSelectWithLabel
                label="Network"
                value={network}
                size="small"
                options={data ? Object.keys(data[Object.keys(data)[0]].network) : []}
                onChange={(value) => setNetwork(value)}
                sx={{ width: '100%' }}
            />
            <Box sx={{ width: '100%', flexGrow: 1 }}>{chartOption && <Chart options={chartOption} type="bar" />}</Box>
        </Stack>
    )
}

export default GroupAllocationBarChart
