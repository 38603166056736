import PageCard from 'components/layouts/PageCard'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import NetworkVizSettings from './network-viz-settings/NetworkVizSettings'
import NetworkKindSelector from './floating-components/NetworkKindSelector'
import NetworkControlButtons from './floating-components/network-control-buttons/NetworkControlButtons'
import NetworkSearchField from './floating-components/NetworkSearchField'
import NetworkAnalyticsButton from './floating-components/NetworkAnalyticsButton'
import NetworkAnalyticsContainer from './floating-components/network-info/network-analytics-info/NetworkAnalyticsContainer'
import NetworkRenderer from './network-renderer/NetworkRenderer'
import NetworkActionBar from './floating-components/NetworkActionBar'
import { NetworkVizContextType, useNetworkVizContext, useNetworkVizDispatch } from './context/NetworkVizContext'
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { GetWidget } from 'services/WidgetApi'
import WebWorker from 'helpers/webWorkerHelper'
import { NetworkSelectedNodeDetails } from './floating-components/network-info/selected-node-info/NetworkSelectedNodeInfo'
import migrateNetworkVizState from './helpers/networkViz.migration'
import { Typography } from '@mui/material'
import DrillDownBreadcrumb from './floating-components/DrillDownBreadcrumb'
import { RootContext } from 'contexts/RootContext'

export interface NetworkVizDesignerProps {}

export default function NetworkVizDesigner({ ...props }: NetworkVizDesignerProps) {
    const { pid, wid } = useParams()
    const dispatchContext = useNetworkVizDispatch()
    const { status, nodeStyle, nodeRenders, title } = useNetworkVizContext()
    const { setPath } = useContext(RootContext)
    const loadNetworkViz = async () => {
        try {
            const response = await GetWidget(pid!, wid!)
            if (!response.success) throw new Error('bad response')

            const worker = new WebWorker('workers/network/zip-worker.js')

            if (response.data.state === undefined) {
                dispatchContext({
                    type: 'LOAD_NETWORK',
                    payload: {
                        title: response.data.title,
                        id: response.data.primaryKey,
                        status: 'ready',
                    },
                })
                return
            }
            const context = (await worker.run({
                mode: 'un-zip',
                data: response.data.state,
            })) as any

            let updatedState
            if (context.version) {
                updatedState = await migrateNetworkVizState(context.state, context.version)
            } else {
                updatedState = await migrateNetworkVizState(context, undefined)
            }

            dispatchContext({ type: 'LOAD_NETWORK', payload: { ...updatedState, status: 'ready' } })
        } catch (e) {
            dispatchContext({ type: 'STATUS_UPDATE', payload: 'faulty' })
        }
    }

    useEffect(() => {
        if (status === 'pending') loadNetworkViz()
    }, [status])

    useEffect(() => {
        if (title !== '') {
            setPath((p) => {
                const newPath = [...p]
                // if last path is not the same as the title, add it to the path
                if (newPath[newPath.length - 1].title !== title) {
                    newPath.push({ title })
                }
                return newPath
            })
        }
    }, [title, setPath])

    return (
        <PageCard>
            <PageCard.Body noPaddingRight>
                {/* Page wrapper
                        ========================================= */}
                <Stack
                    className="network-viz"
                    direction="row"
                    gap={1}
                    sx={(theme) => ({
                        position: 'relative',
                        height: '100%',

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* (Overlay slot) Graph settings and type
                            ========================================= */}
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        gap={1}
                        sx={(theme) => ({
                            position: 'absolute',
                            top: theme.spacing(1),
                            left: theme.spacing(1),
                            zIndex: 1,

                            flexShrink: 0,

                            minWidth: 0,
                            // Subtract the same value of "top" from full height.
                            height: `calc(100% - ${theme.spacing(1)})`,
                            // Allowing the background of this element to be clickable.
                            pointerEvents: 'none',

                            '& > *': {
                                // Resetting pointer/click ability for children.
                                pointerEvents: 'auto',
                            },
                        })}
                    >
                        <NetworkVizSettings />

                        <Stack gap={1}>
                            <Stack direction={'row'} gap={1}>
                                <NetworkKindSelector />
                                <DrillDownBreadcrumb />
                            </Stack>
                            <NetworkSelectedNodeDetails />
                        </Stack>
                    </Stack>

                    {/* Main content
                            ========================================= */}
                    <Box
                        sx={(theme) => ({
                            flexShrink: 0,

                            minWidth: 0,
                            height: '100%',
                            width: '100%',
                            padding: 1,
                        })}
                    >
                        <NetworkRenderer />
                    </Box>

                    {/* (Overlay slot) Analytics, search, and right-side action buttons
                            ========================================= */}
                    <Stack
                        direction="row"
                        gap={1}
                        sx={(theme) => ({
                            position: 'absolute',
                            top: theme.spacing(1),
                            right: theme.spacing(1),
                            zIndex: 1,

                            flexShrink: 0,

                            // Subtract the same value of "top" from full height.
                            maxHeight: `calc(100% - ${theme.spacing(1)})`,
                            minWidth: 0,
                            // Allowing the background of this element to be clickable.
                            pointerEvents: 'none',

                            '& > *': {
                                // Resetting pointer/click ability for children.
                                pointerEvents: 'auto',
                            },
                        })}
                    >
                        <Stack
                            direction="column"
                            alignItems="flex-start"
                            gap={1}
                            sx={(theme) => ({
                                minHeight: 0,
                                height: '100%',
                                // Allowing the background of this element to be clickable.
                                pointerEvents: 'none',

                                '& > *': {
                                    // Resetting pointer/click ability for children.
                                    pointerEvents: 'auto',
                                },
                            })}
                        >
                            {/* Analytics button and search field
                                ========================================= */}
                            <Stack direction="row" alignItems="center" gap={2} sx={(theme) => ({ flexShrink: 0 })}>
                                <NetworkAnalyticsButton />

                                <NetworkSearchField />
                            </Stack>

                            {/* Analytics list
                            ========================================= */}
                            <NetworkAnalyticsContainer />
                        </Stack>
                    </Stack>

                    {/* (Overlay slot) Bottom controls
                            ========================================= */}
                    <Stack
                        direction="row"
                        gap={1}
                        sx={(theme) => ({
                            position: 'absolute',
                            bottom: theme.spacing(1),
                            right: theme.spacing(1),
                            zIndex: 1,

                            flexShrink: 0,

                            minWidth: 0,
                        })}
                    >
                        <NetworkControlButtons viewMode="design" nodeStyle={nodeStyle} nodeRenders={nodeRenders} />
                    </Stack>

                    {/* (Overlay slot) Floating action button
                            ========================================= */}
                    <Box
                        sx={(theme) => ({
                            flexShrink: 0,

                            position: 'absolute',
                            bottom: theme.spacing(1),
                            left: theme.spacing(1),
                        })}
                    >
                        <NetworkActionBar />
                    </Box>

                    <Box
                        sx={(theme) => ({
                            flexShrink: 0,

                            position: 'absolute',
                            bottom: 0,
                            left: 64,
                            width: 'calc(100% - 234px)',
                            // Allowing the background of this element to be clickable.
                            // This property is automatically reset to 'auto' when MUI components
                            // take over (inside 'GraphtActionButton').
                            pointerEvents: 'none',
                            '& > *': {
                                // Resetting pointer/click ability for children.
                                pointerEvents: 'auto',
                            },
                        })}
                    >
                        {/* <GraphDisplayTimePanel /> */}
                    </Box>
                </Stack>
            </PageCard.Body>
        </PageCard>
    )
}
