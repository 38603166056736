//* ======= Libraries
import { useState, useEffect, useMemo } from 'react'
//* ======= Components and features
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
//* ======= Custom logic
//* ======= Assets and styles
import { useParams } from 'react-router-dom'
import {
    Avatar,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormLabel,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Radio,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
} from '@mui/material'
import { auto } from '@popperjs/core'
import { GetWidget, GetWidgets, WidgetType } from 'services/WidgetApi'
import SnaCircularLoading from 'features/sna-circular-loading/SnaCircularLoading'
import { buildAbbreviation } from 'helpers/TextFormatterHelpers'
import WebWorker from 'helpers/webWorkerHelper'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import ToastHelper from 'helpers/ToastHelper'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import {
    convertNodeAttributeToKeyString,
    formatValueAsLabel,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { NODE_ANALYTICS_METRICS } from 'features/network-viz/constants/NetworkViz.const'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { AllocationConfigType, BasicAllocationType, CreateAllocationService } from 'services/AllocationApi'
import FlexibleSelect, { FlexibleSelectOptionType } from 'components/group-field-selector/FlexibleSelect'

type DiversityFieldType = {
    attribute: ReportDataSourceAttributeType
    type: 'numeric' | 'categorical'
    weight: number
}

type OnboardWizardProps = {
    mode: 'create' | 'edit'
    open: boolean
    onCreate: (result: BasicAllocationType) => void
    onClose: () => void
    defaultConfig?: {
        title: string
        config: AllocationConfigType
    }
}

const CreateAllocationDialog = ({ mode, open, onClose, onCreate, defaultConfig }: OnboardWizardProps) => {
    const { pid } = useParams()

    const [activeStep, setActiveStep] = useState(0)
    const steps = ['Import Data', 'Class Configuration', 'Configure Networks', 'Configure Diversity', 'Set Weights']
    const [config, setConfig] = useState<AllocationConfigType>({
        selectedNetworkViz: null,
        optimalClassSize: 5,
        groupBy: [],
        networks: [],
        diversityFields: [],
    })

    const [title, setTitle] = useState<string>('')
    const [networkVizOptions, setNetworkVizOptions] = useState<WidgetType[] | null>(null)
    const [networkData, setNetworkData] = useState<(NetworkVizContextType & { id: number }) | null>(null)
    const [groupingFields, setGroupBy] = useState<ReportDataSourceAttributeType[]>([])

    const intitState = async () => {
        setConfig(
            defaultConfig?.config ?? {
                selectedNetworkViz: null,
                optimalClassSize: 5,
                groupBy: [],
                networks: [],
                diversityFields: [],
            }
        )

        if (defaultConfig?.config) {
            try {
                let id = defaultConfig.config.selectedNetworkViz
                if (id) {
                    const state = await loadNetworkViz(id)
                    setActiveStep(1)
                    setNetworkData({ ...state, id })
                }
            } catch {}
        }

        setTitle(defaultConfig?.title ?? '')
    }
    useEffect(() => {
        if (open) intitState()
    }, [open])

    // fetch surveys from server
    const getnetworkViz = async (pid: string) => {
        try {
            var res = await GetWidgets(pid)
            if (res.success) {
                setNetworkVizOptions(res.data)
                return
            }
        } catch (e) {}
        setNetworkVizOptions([])
    }

    // effects and memos

    useEffect(() => {
        if (!pid) return
        getnetworkViz(pid)
    }, [pid])

    const fieldOptions = useMemo(() => {
        if (networkData === null) return []
        const fields: FlexibleSelectOptionType[] = []

        const nodeFields: FlexibleSelectOptionType = {
            group: 'Node Fields',
            items: [],
        }
        for (let field in networkData.nodeDataSchema.fields) {
            nodeFields.items.push({
                label: formatValueAsLabel(field),
                value: {
                    type: 'basic',
                    field: field,
                },
            })
        }

        fields.push(nodeFields)

        if (networkData.analytics?.view && networkData.analytics?.all) {
            for (let relation in networkData.analytics) {
                const analyticsFields: FlexibleSelectOptionType = {
                    group: `Node Analytics - ${relation}`,
                    items: [],
                }
                analyticsFields.items.push(
                    ...NODE_ANALYTICS_METRICS.map((x) => ({
                        label: x.label,
                        value: {
                            type: 'analytic',
                            relationship: relation,
                            field: x.value,
                        },
                    }))
                )
                fields.push(analyticsFields)
            }
        }

        return fields
    }, [networkData])

    // gorup by
    const addGroupBy = () => {
        setGroupBy((prev) => [
            ...prev,
            {
                type: 'basic',
                field: '',
            },
        ])
    }

    const removeGroupBy = (index: number) => {
        setGroupBy((prev) => prev.filter((_, idx) => idx !== index))
    }

    const updateGroupBy = (index: number) => (value: ReportDataSourceAttributeType) => {
        setGroupBy((prev) => prev.map((x, idx) => (idx === index ? value : x)))
    }

    // Diversity fields
    const addDiversityField = () => {
        setConfig((prev) => ({
            ...prev,
            diversityFields: [
                ...prev.diversityFields,
                {
                    attribute: {
                        type: 'basic',
                        field: '',
                    },
                    type: 'categorical',
                    weight: 1,
                },
            ],
        }))
    }

    const removeDivsersityField = (index: number) => {
        setConfig((prev) => ({ ...prev, diversityFields: prev.diversityFields.filter((_, idx) => idx !== index) }))
    }

    const updateDivsersityAttribute = (index: number) => (value: DiversityFieldType['attribute']) => {
        let type: DiversityFieldType['type'] = 'categorical'
        if (value.type === 'analytic' || networkData?.nodeDataSchema.numericOptions.includes(value.field)) {
            type = 'numeric'
        }
        setConfig((prev) => ({
            ...prev,
            diversityFields: prev.diversityFields.map((x, idx) =>
                idx === index ? { ...x, attribute: value, type: type } : x
            ),
        }))
    }

    const updateDivsersityType = (index: number) => (value: DiversityFieldType['type']) => {
        setConfig((prev) => ({
            ...prev,
            diversityFields: prev.diversityFields.map((x, idx) => (idx === index ? { ...x, type: value } : x)),
        }))
    }

    // stepper handlers

    const nextEnabled = useMemo(() => {
        switch (activeStep) {
            case 0:
                return title.length > 2 && config.selectedNetworkViz !== null
            case 1:
                if (config.optimalClassSize < 2) return false
                for (let item of groupingFields) {
                    if (item.field === '') return false
                }
                return true
            case 3:
                for (let item of config.diversityFields) {
                    if (item.attribute.field === '') return false
                }
                return true
            default:
                return true
        }
    }, [activeStep, config, groupingFields, title])

    const onNext = () => {
        switch (activeStep) {
            case 0:
                onImportNetworkNext()
                break
            case 1:
            case 2:
            case 3:
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
                break
            case 4:
                createAllocation()
                break
            default:
                return
        }
    }

    const onBack = () => {
        setActiveStep((prevActiveStep) => Math.max(0, prevActiveStep - 1))
    }

    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                // import data step
                return (
                    <Stack direction="column" height="90%" mt={2}>
                        <BaseFilledTextField
                            label="Title"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        {networkVizOptions === null ? (
                            <SnaCircularLoading />
                        ) : networkVizOptions.length === 0 ? (
                            <Typography variant="h5">
                                This project does not have any Network Viz, or you do not have access to any of them.
                            </Typography>
                        ) : (
                            <List>
                                {networkVizOptions.map((nv) => (
                                    <ListItemButton
                                        key={nv.primaryKey}
                                        selected={config.selectedNetworkViz === nv.primaryKey}
                                        onClick={() =>
                                            setConfig((prev) => ({ ...prev, selectedNetworkViz: nv.primaryKey! }))
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar variant="rounded">{buildAbbreviation(nv.title)}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={nv.title}
                                            secondary={'Created by ' + nv.createdBy?.name}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        )}
                    </Stack>
                )
            // group by step
            case 1:
                return (
                    <Stack gap={2}>
                        <Typography variant="h6">Class Configuration</Typography>
                        <Divider />
                        <BaseFilledTextField
                            label="Optimal Class Size"
                            type="number"
                            size="small"
                            fullWidth
                            inputProps={{ min: 2 }}
                            value={config.optimalClassSize}
                            onChange={(e) =>
                                setConfig((prev) => ({
                                    ...prev,
                                    optimalClassSize: Math.max(2, Number(e.target.value)),
                                }))
                            }
                        />
                        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
                            <Typography variant="body1">Group By</Typography>
                            <Tooltip title="Add new field">
                                <IconButton onClick={addGroupBy}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Divider />
                        {groupingFields.map((item, idx) => (
                            <Stack key={idx} direction="row" gap={2} alignItems="center">
                                <FlexibleSelect
                                    label="Field"
                                    fullWidth
                                    options={fieldOptions}
                                    value={item}
                                    onChange={updateGroupBy(idx)}
                                />
                                <Tooltip title="Remove field">
                                    <IconButton onClick={() => removeGroupBy(idx)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        ))}
                    </Stack>
                )
            // select networks step
            case 2:
                return (
                    <List>
                        {config.networks.map((network) => (
                            <ListItem key={network.name} divider>
                                <Checkbox
                                    edge="start"
                                    checked={network.selected}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={() =>
                                        setConfig((prev) => ({
                                            ...prev,
                                            networks: prev.networks.map((n) =>
                                                n.name === network.name ? { ...n, selected: !n.selected } : n
                                            ),
                                        }))
                                    }
                                />
                                <ListItemText primary={network.name} />

                                <Stack direction="row" gap={1} alignItems="center">
                                    <FormLabel component="legend">Mode:</FormLabel>
                                    <RadioGroup
                                        row
                                        value={network.mode}
                                        onChange={(event) =>
                                            setConfig((prev) => ({
                                                ...prev,
                                                networks: prev.networks.map((n) =>
                                                    n.name === network.name
                                                        ? {
                                                              ...n,
                                                              mode:
                                                                  event.target.value === 'positive'
                                                                      ? 'positive'
                                                                      : 'negative',
                                                          }
                                                        : n
                                                ),
                                            }))
                                        }
                                    >
                                        <FormControlLabel
                                            value="positive"
                                            control={<Radio />}
                                            label="Positive"
                                            disabled={!network.selected}
                                        />
                                        <FormControlLabel
                                            value="negative"
                                            control={<Radio />}
                                            label="Negative"
                                            disabled={!network.selected}
                                        />
                                    </RadioGroup>
                                </Stack>
                            </ListItem>
                        ))}
                    </List>
                )
            // select diversity fields step
            case 3:
                return (
                    <Stack gap={2}>
                        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Select Diversity Fields</Typography>
                            <Tooltip title="Add new field">
                                <IconButton onClick={addDiversityField}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Divider />
                        {config.diversityFields.map((item, idx) => (
                            <Stack key={idx} direction="row" gap={2} alignItems="center">
                                <FlexibleSelect
                                    label="Field"
                                    fullWidth
                                    options={fieldOptions}
                                    value={item.attribute}
                                    onChange={(value) => {
                                        updateDivsersityAttribute(idx)(value)
                                    }}
                                />
                                <BaseSelectWithLabel
                                    label="Data Type"
                                    fullWidth
                                    disabled={item.attribute.field === ''}
                                    options={[
                                        { label: 'Numeric', value: 'numeric' },
                                        { label: 'Categorical', value: 'categorical' },
                                    ]}
                                    value={item.attribute.field === '' ? '' : item.type}
                                    onChange={(value) => {
                                        updateDivsersityType(idx)(value)
                                    }}
                                />
                                <Tooltip title="Remove field">
                                    <IconButton onClick={() => removeDivsersityField(idx)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        ))}
                    </Stack>
                )

            // set weights step
            case 4:
                const activeNetworks = config.networks.filter((x) => x.selected)
                return (
                    <Stack gap={2}>
                        <Typography variant="h6">Set Weights</Typography>
                        <Divider />

                        {activeNetworks.length > 0 && (
                            <Stack direction="column" gap={2} alignItems="center">
                                <Typography variant="h6">Networks Weight</Typography>
                                <Grid2 container width={'100%'}>
                                    {activeNetworks.map((item, idx) => {
                                        return (
                                            <Grid2 key={idx} xs={6} md={4}>
                                                <Box p={1}>
                                                    <BaseFilledTextField
                                                        label={item.name}
                                                        type="number"
                                                        size="small"
                                                        fullWidth
                                                        value={item.weight}
                                                        onChange={(e) => {
                                                            setConfig((prev) => {
                                                                const newNetworks = [...prev.networks]
                                                                newNetworks[idx].weight = Number(e.target.value)
                                                                return {
                                                                    ...prev,
                                                                    networks: newNetworks,
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            </Grid2>
                                        )
                                    })}
                                </Grid2>
                            </Stack>
                        )}
                        <Divider />
                        {config.diversityFields.length > 0 && (
                            <Stack direction="column" gap={2} alignItems="center">
                                <Typography variant="h6">Diversity Fields Weight</Typography>
                                <Grid2 container width={'100%'}>
                                    {config.diversityFields.map((item, idx) => (
                                        <Grid2 key={idx} xs={6} md={4}>
                                            <Box p={1}>
                                                <BaseFilledTextField
                                                    label={item.attribute.field}
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    value={item.weight}
                                                    onChange={(e) => {
                                                        setConfig((prev) => {
                                                            const diversityFields = [...prev.diversityFields]
                                                            diversityFields[idx].weight = Number(e.target.value)
                                                            return {
                                                                ...prev,
                                                                diversityFields,
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Box>
                                        </Grid2>
                                    ))}
                                </Grid2>
                            </Stack>
                        )}
                    </Stack>
                )
            default:
                return 'Unknown step'
        }
    }

    // stepper actions

    const loadNetworkViz = async (id: number) => {
        if (pid == null) throw new Error('Cannot load network viz')
        const response = await GetWidget(pid, id)
        if (response.success) {
            const worker = new WebWorker('workers/network/zip-worker.js')

            const data = (await worker.run({
                mode: 'un-zip',
                data: response.data.state,
            })) as any

            let state: NetworkVizContextType

            if (data.version) {
                state = data.state
            } else {
                state = data
            }
            return state
        }
        throw new Error('Failed to load network viz')
    }

    const onImportNetworkNext = async () => {
        if (pid == null || config.selectedNetworkViz == null) return
        if (networkData?.id === config.selectedNetworkViz) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            return
        }
        const toast = new ToastHelper({
            errorMessage: 'Failed to import network viz',
            successMessage: 'Network viz imported successfully',
            loadingMessage: 'Importing network viz...',
        })
        // fetch network viz
        try {
            let state = await loadNetworkViz(config.selectedNetworkViz)

            setNetworkData({ ...state, id: config.selectedNetworkViz })
            setConfig((prev) => ({
                ...prev,
                networks: Object.entries(state.analyticsSettings?.networks ?? {}).map(([key, value]) => ({
                    name: key,
                    selected: true,
                    weight: 1,
                    mode: value.relationType === 'negative' ? 'negative' : 'positive',
                })),
            }))
            toast.success()
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } catch {
            toast.fail()
        }
    }

    const createAllocation = () => {
        if (networkData === null) return

        const toast = new ToastHelper({
            errorMessage: 'Failed to create class allocation',
            successMessage: 'Class allocation created successfully',
            loadingMessage: 'Creating class allocation...',
        })
        //parse data
        const networks: Record<
            string,
            {
                source: string
                target: string
            }[]
        > = {}

        for (let network of config.networks) {
            if (!network.selected) continue
            networks[network.name.toLowerCase()] = []
        }

        for (let edge of Object.values(networkData.edges)) {
            const rel = edge.relationship.toLowerCase()
            if (networks[rel]) {
                networks[rel].push({
                    source: edge.source,
                    target: edge.target,
                })
            }
        }

        const requriedFields = groupingFields.map((x) => x)
        requriedFields.push(...config.diversityFields.map((x) => x.attribute))

        const nodes: Record<string, any> = Object.values(networkData.nodes).map((node) => {
            const result: Record<string, any> = { id: node.id }
            for (let attribute of requriedFields) {
                const key = convertNodeAttributeToKeyString(attribute)
                if (attribute.type === 'basic') {
                    result[key] = node[attribute.field]
                }
                if (attribute.type === 'analytic') {
                    result[key] = networkData.analytics?.[attribute.relationship]?.nodes[node.id]?.[attribute.field]
                }
            }
            return result
        })

        CreateAllocationService(pid!, {
            title: title,
            config: {
                selectedNetworkViz: config.selectedNetworkViz,
                optimalClassSize: config.optimalClassSize,
                groupBy: groupingFields,
                networks: config.networks
                    .filter((x) => x.selected)
                    .map((x) => ({
                        name: x.name.toLowerCase(),
                        mode: x.mode,
                        weight: x.weight,
                    })),
                diversityFields: config.diversityFields,
            },
            data: {
                nodes,
                networks,
            },
        })
            .then((response) => {
                if (response.success) {
                    toast.success()
                    onCreate(response.data)
                } else {
                    toast.fail()
                }
            })
            .catch(() => {
                toast.fail()
            })
    }

    return (
        <StyledDialog open={open} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant="h6">Onboard new node</Typography>
            </DialogTitle>
            <DialogContent sx={{ height: '80vh', overflowY: auto }}>
                <Stack gap={1}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box p={1}>{getStepContent(activeStep)}</Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose}>Cancel</BaseButton>
                {activeStep !== 0 && (
                    <BaseButton onClick={onBack} disabled={activeStep === 0}>
                        Back
                    </BaseButton>
                )}
                <BaseButton variant="contained" color="primary" disabled={!nextEnabled} onClick={onNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default CreateAllocationDialog
