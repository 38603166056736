import React, { useEffect, useMemo } from 'react'
import { MenuItem, Menu, Stack, Tooltip, Typography, IconButton } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'
import { isEqual } from 'lodash'
import { NetworkWidgetType, ReportDataSourceAttributeType } from 'features/report-designer/types/reportDesigner.types'
import { convertNodeAttributeToKeyString } from 'features/report-designer/helpers/reportDesigner.helper'
import { useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { NodeAttributeType, NodeGroupStyleType } from '../types/NetworkViz.types'
import CloseIcon from '@mui/icons-material/Close'
interface SizeScaleMenuProps {
    options: NetworkWidgetType['sizeScale']
    onSizeScaleChange?: (sizeScale: ReportDataSourceAttributeType | null) => void
}

const convertNodeAttributeToReoportDataSourceAttributeType = (
    attribute: NodeAttributeType
): ReportDataSourceAttributeType | null => {
    if (attribute === null || attribute.field === '') {
        return null
    } else if (attribute.source === 'analytic') {
        return {
            type: 'analytic',
            field: attribute.field,
            relationship: attribute.relationship,
        }
    } else if (attribute.source === 'info') {
        return {
            type: 'basic',
            field: attribute.field,
        }
    } else {
        return null
    }
}

const SizeScaleMenu: React.FC<SizeScaleMenuProps> = ({ options, onSizeScaleChange }) => {
    const { nodes, nodeRenders, nodeStyle, nodeDataSchema, analytics, status } = useNetworkVizContext()
    const dispatchNetworkContext = useNetworkVizDispatch()

    const [sizeScaleMenuAnchorEl, setSizeScaleMenuAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleSizeScaleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSizeScaleMenuAnchorEl(event.currentTarget)
    }

    const handleSizeScaleMenuClose = () => {
        setSizeScaleMenuAnchorEl(null)
    }

    const applySizeScale = (newValue: ReportDataSourceAttributeType | null) => {
        const newGNS = structuredClone(nodeStyle)

        let updatedSizeScale: Partial<NodeGroupStyleType['sizeScale']> = {}

        onSizeScaleChange && onSizeScaleChange(newValue)

        if (newValue === null) {
            updatedSizeScale = {
                enabled: false,
            }
        } else if (newValue.type === 'analytic') {
            updatedSizeScale = {
                enabled: true,
                attribute: {
                    field: newValue.field,
                    relationship: newValue.relationship,
                    source: 'analytic',
                },
            }
        } else if (newValue.type === 'basic') {
            updatedSizeScale = {
                enabled: true,
                attribute: {
                    field: newValue.field,
                    source: 'info',
                },
            }
        } else {
            return
        }

        for (let g in newGNS) {
            newGNS[g].sizeScale = {
                ...newGNS[g].sizeScale,
                ...updatedSizeScale,
            }
        }

        const worker = new WebWorker('workers/network/change-node-style-worker.js')

        worker
            .run({
                nodes,
                nodeRenders,
                dataSchema: nodeDataSchema.fields,
                analytics,
                nodeStyle: newGNS,
            })
            .then((res: any) => {
                dispatchNetworkContext({
                    type: 'NODE_STYLE_EDIT',
                    payload: { nodeRenders: res, nodeStyle: newGNS },
                })
            })
            .catch((e) => {})
            .finally(() => {})

        handleSizeScaleMenuClose()
    }

    const currentSizeScaleAttribute = useMemo<ReportDataSourceAttributeType | null>(() => {
        if (nodeStyle.default.sizeScale.enabled !== true) return null
        return convertNodeAttributeToReoportDataSourceAttributeType(nodeStyle.default.sizeScale.attribute)
    }, [nodeStyle.default.sizeScale.attribute, nodeStyle.default.sizeScale.enabled])

    return (
        <>
            {options.enabled && options.fields.length > 0 && (
                <Stack direction={'row'} className="size-scale">
                    <Tooltip title="Scale Node Size By">
                        <BaseButton
                            color="secondary"
                            startIcon={<BubbleChartIcon color="secondary" />}
                            sx={{
                                maxWidth: 150,
                            }}
                            onClick={handleSizeScaleMenuButtonClick}
                        >
                            <Typography
                                sx={{
                                    maxWidth: 150,
                                    overflow: 'hidden',
                                    textAlign: 'start',
                                }}
                                noWrap
                                textOverflow="ellipsis"
                            >
                                {currentSizeScaleAttribute === null
                                    ? 'Scale nodes'
                                    : options.fields.find((x) => isEqual(x.field, currentSizeScaleAttribute))?.label}
                            </Typography>
                        </BaseButton>
                    </Tooltip>
                    {currentSizeScaleAttribute !== null && (
                        <Tooltip title="Reset Node Size Scale">
                            <IconButton
                                color="secondary"
                                onClick={() => applySizeScale(null)}
                                sx={{
                                    minWidth: 0,
                                    width: 36,
                                    height: 36,
                                    borderRadius: '50%',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Menu
                        PopoverClasses={{
                            paper: 'size-scale',
                        }}
                        anchorEl={sizeScaleMenuAnchorEl}
                        keepMounted
                        open={Boolean(sizeScaleMenuAnchorEl)}
                        onClose={() => handleSizeScaleMenuClose()}
                    >
                        <MenuItem
                            sx={(theme) => ({
                                color: currentSizeScaleAttribute === null ? 'primary.main' : 'text.primary',
                                bgcolor: currentSizeScaleAttribute === null ? theme.palette.common.bg_4 : 'transparent',
                            })}
                            onClick={() => applySizeScale(null)}
                        >
                            No Scale
                        </MenuItem>
                        {options.fields.map((_item) => {
                            const isSelected = isEqual(currentSizeScaleAttribute, _item.field)
                            const key = convertNodeAttributeToKeyString(_item.field)
                            return (
                                <MenuItem
                                    sx={(theme) => ({
                                        color: isSelected ? 'primary.main' : 'text.primary',
                                        bgcolor: isSelected ? theme.palette.common.bg_4 : 'transparent',
                                    })}
                                    key={key}
                                    onClick={() => applySizeScale(_item.field)}
                                >
                                    {_item.label}
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Stack>
            )}
        </>
    )
}

export default SizeScaleMenu
