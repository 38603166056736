// Editors
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
import { InlineEditor } from '@ckeditor/ckeditor5-editor-inline'
// Plugins
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import { Autosave } from '@ckeditor/ckeditor5-autosave'
import { Bold, Italic, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { FontBackgroundColor, FontColor, FontFamily, FontSize } from '@ckeditor/ckeditor5-font'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line'
import { DataFilter, DataSchema, GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support'
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent'
import { Image } from '@ckeditor/ckeditor5-image'
import { ImageCaption } from '@ckeditor/ckeditor5-image'
import { ImageResize } from '@ckeditor/ckeditor5-image'
import { ImageStyle } from '@ckeditor/ckeditor5-image'
import { ImageToolbar } from '@ckeditor/ckeditor5-image'
import { ImageUpload } from '@ckeditor/ckeditor5-image'
import { ImageInsert } from '@ckeditor/ckeditor5-image'
import { AutoImage } from '@ckeditor/ckeditor5-image'
// import { TextPartLanguage } from '@ckeditor/ckeditor5-language'
import { AutoLink, Link } from '@ckeditor/ckeditor5-link'
import { List, ListProperties, TodoList } from '@ckeditor/ckeditor5-list'
import { Mention } from '@ckeditor/ckeditor5-mention'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office'
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format'
import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload'

import {
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
} from '@ckeditor/ckeditor5-special-characters'
import {
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
} from '@ckeditor/ckeditor5-table'
import { TextTransformation } from '@ckeditor/ckeditor5-typing'

// Plugins to include in the build.
const plugins = [
    Alignment,
    Autoformat,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    DataFilter,
    DataSchema,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListProperties,
    Mention,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    // TextPartLanguage,
    TextTransformation,
    TodoList,
    Underline,
]

// Editor configuration.
const config = {
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'alignment',
            'indent',
            'outdent',
            '|',
            'fontFamily',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'highlight',
            'removeFormat',
            '|',
            'horizontalLine',
            'numberedList',
            'bulletedList',
            'todoList',
            'insertTable',
            'link',
            'blockQuote',
            'subscript',
            'superscript',
            'specialCharacters',
            '|',
            'heading',
            // 'textPartLanguage',
        ],
    },
    language: 'en',
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties'],
    },
}

class ClassicEditorCustom extends ClassicEditor {
    public static override builtinPlugins = [
        ...plugins,
        Base64UploadAdapter,
        AutoImage,
        Image,
        ImageResize,
        ImageCaption,
        ImageInsert,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
    ]

    public static override defaultConfig = {
        ...config,
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'alignment',
                'indent',
                'outdent',
                '|',
                'fontFamily',
                'fontSize',
                'fontColor',
                'fontBackgroundColor',
                'highlight',
                'removeFormat',
                '|',
                'horizontalLine',
                'numberedList',
                'bulletedList',
                'todoList',
                'imageInsert',
                'insertTable',
                'link',
                'imageUpload',
                'imageInsert',
                'blockQuote',
                'subscript',
                'superscript',
                'specialCharacters',
                '|',
                'heading',
                // 'textPartLanguage',
            ],
        },
        image: {
            toolbar: [
                'imageTextAlternative',
                'toggleImageCaption',
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:side',
                'linkImage',
                'imageResize',
            ],
        },
    }
}

class InlineEditorCustom extends InlineEditor {
    public static override builtinPlugins = plugins

    public static override defaultConfig = config
}

// The same name as Webpack config's output library name.
const CustomCKEditor = {
    ClassicEditor: ClassicEditorCustom,
    InlineEditor: InlineEditorCustom,
}

export default CustomCKEditor

export type ClassicEditorType = ClassicEditorCustom
export type InlineEditorType = InlineEditorCustom
