export default class WebWorker<T> {
    url: string
    worker: Worker
    constructor(url: string) {
        this.url = url
        this.worker = new window.Worker(process.env.REACT_APP_BASE_URL + url)
    }

    run(message: any) {
        return new Promise<T>((resolve, reject) => {
            this.worker.postMessage(message)
            //Todo check this later
            const parent = this
            this.worker.onmessage = function (e) {
                parent.terminate()
                resolve(e.data)
            }

            this.worker.onerror = (e) => {
                parent.terminate()
                reject(`WebWorker ${this.url} onerror: ${e.message}`)
            }

            this.worker.onmessageerror = (e) => {
                parent.terminate()
                reject(`WebWorker ${this.url} onmessageerror`)
            }
        })
    }

    terminate() {
        this.worker.terminate()
    }
}
